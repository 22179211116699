<div
    *ngIf="!!rootUser || isSuperUser"
    class="banner"
    [ngClass]="{
        'not-impersonating': isSuperUser,
        impersonating: !isSuperUser
    }"
    role="banner"
>
    <!-- HOME button -->
    <div class="menu-button menu-button--home">
        <button
            class="btn btn-link sf-btn-link"
            aria-label="Go home"
            (click)="switchBack()"
        >
            <fa-icon *ngIf="isSuperUser" [icon]="['far', 'home']"></fa-icon>
            <fa-icon
                *ngIf="!isSuperUser"
                [icon]="['far', 'sign-out-alt']"
                flip="horizontal"
            ></fa-icon>
        </button>
    </div>

    <!-- LOG IN AS -->
    <div class="menu-button menu-button--user-login">
        <span>Logged in as: </span>
        <button
            id="loginUserSelectorButton"
            class="btn btn-link sf-btn-link selected-value"
            (click)="toggleUserSearchMenu()"
        >
            {{ loggedInUser }}
            <span class="caret"></span>
        </button>
    </div>
    <div
        class="support-menu support-menu--user-search"
        *ngIf="showUserSearchMenu"
        sfClickOff
        (onClickOff)="clickAwayUserSearchMenu()"
    >
        <div class="support-menu__search-box-container">
            <input
                type="text"
                id="supportSearchInput"
                class="support-menu__search-box"
                [(ngModel)]="userSearch"
                aria-label="Search"
                autocomplete="off"
                (keydown.enter)="enterKeyInput()"
                (keydown.arrowDown)="arrowDownInput()"
                (keydown.escape)="clickAwayUserSearchMenu()"
                (ngModelChange)="searchUsersDebouncedSubject.next(userSearch)"
            />
        </div>
        <ul *ngIf="foundUsers" class="support-menu__items">
            <li class="support-menu__item" *ngFor="let user of foundUsers">
                <button
                    class="btn btn-link sf-btn-link"
                    (click)="loginAs(user)"
                    [id]="'luser' + user.encryptedID"
                    (focus)="onUserFocus(user)"
                    (keydown.escape)="clickAwayUserSearchMenu()"
                    (keydown.arrowDown)="arrowDown(user)"
                    (keydown.arrowUp)="arrowUp(user)"
                >
                    {{ user.name.first }} {{ user.name.last }} ({{
                        user.username
                    }})
                </button>
            </li>
        </ul>
        <span *ngIf="!foundUsers">
            <fa-icon [spin]="true" icon="spinner"></fa-icon>
        </span>
    </div>

    <!-- Spacer -->
    <div class="flex-grow-1"></div>

    <!-- Universal Search -->
    <sf-universal-search-new *ngIf="isSuperUser" id="newUniversalSearch"></sf-universal-search-new>

    <!-- 'Additional Hacks' control -->
    <div class="menu-button menu-button--actions">
        <button
            *ngIf="availableActions && availableActions.length"
            class="btn btn-link sf-btn-link menu-button"
            (click)="toggleActionMenu()"
            aria-label="More actions"
            (keydown.escape)="clickAwayActionsMenu()"
        >
            <fa-icon icon="ellipsis-h"></fa-icon>
        </button>
    </div>
    <div
        class="support-menu support-menu--actions"
        *ngIf="showPageActionsMenu"
        sfClickOff
        (onClickOff)="clickAwayActionsMenu()"
    >
        <ul class="support-menu__items">
            <li
                class="support-menu__item"
                *ngFor="let action of availableActions"
            >
                <button
                    class="btn btn-link sf-btn-link"
                    (click)="action.action()"
                    (keydown.escape)="clickAwayActionsMenu()"
                >
                    <fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
                    {{ action.label }}
                </button>
            </li>
        </ul>
    </div>
</div>
