import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "sf-customer-survey-scale",
    templateUrl: "./customer-survey-scale.component.html",
    styleUrls: ["./customer-survey-scale.component.scss"]
})
export class CustomerSurveyScaleComponent implements OnInit {
    /** I/O **/
    @Input()
    questionText: string;
    @Input()
    minValue: number;
    @Input()
    minLabel: string;
    @Input()
    maxValue: number;
    @Input()
    maxLabel: string;
    @Output()
    ratingSelected = new EventEmitter<number>();

    /** Private Variables **/

    /** Public Variables **/
    scaleRange: number[];

    /** View Children **/

    constructor() {}

    /** Lifecycle Hooks **/
    ngOnInit() {
        this.scaleRange = this._createRange(this.maxValue + 1, this.minValue);
    }

    /** Public Methods **/

    /**  Private Methods  **/
    private _createRange(size: number, startAt: number = 0): number[] {
        return [...Array(size).keys()].map((i) => i + startAt);
    }
}
