<div class="logo">
    <div class="logo-back">
        <button class="btn btn-link" (click)="clickBack()">
            <fa-icon icon="chevron-left"></fa-icon>
            <span> Back to Login</span>
        </button>
    </div>
</div>
<div class="login-container">
    <h1 class="head-text">
        Forgot Password?
    </h1>
    <div *ngIf="!passwordSent">
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <ul *ngIf="formErrorMessages.length > 1">
                <li *ngFor="let message of formErrorMessages">
                    <span [innerHTML]="message"></span>
                </li>
            </ul>
            <div *ngIf="formErrorMessages.length == 1">
                <span [innerHTML]="formErrorMessages[0]"></span>
            </div>
        </div>
        <div class="explain">
            Having trouble logging in? No problem. We can help.
        </div>
        <div class="explain extra-bottom">
            Please enter the required information below.
            Instructions to reset your password will be sent to your email address.
        </div>
        <div class="explain double-extra-bottom">
            Still need help?
            <button class="btn btn-link" (click)="clickContact()">Contact Support</button>
        </div>
        <form [formGroup]="loginForm">
            <div class="row">
                <div class="form-group col-md-12">
                    <input type="text" id="username" name="username" placeholder="Username" class="form-control"
                            aria-label="Username"
                            autofocus formControlName="username"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <input type="text" id="email" name="email" placeholder="Email" class="form-control"
                            aria-label="Email"
                            formControlName="email"/>
                </div>
            </div>
            <div class="button-row">
                <button [disabled]="processing" class="btn btn-primary" (click)="submitForm()">
                    <span *ngIf="!processing">Submit</span>
                    <fa-icon *ngIf="processing" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                </button>
            </div>
        </form>
        <div class="link-container">
            <button class="btn btn-link" (click)="clickForgotUsername()">Forgot username?</button>
        </div>
    </div>
    <div *ngIf="passwordSent">
        <div class="success-row">
            <span [innerHTML]="successMessage"></span>
        </div>
        <div class="explain double-extra-bottom">
            Still need help?
            <button class="btn btn-link" (click)="clickContact()">Contact Support</button>
        </div>
    </div>
</div>
