import { noop } from "angular";
import {MonoTypeOperatorFunction, OperatorFunction, throwError, timer} from "rxjs";
import {concatMap, retryWhen} from "rxjs/operators";

/**
 * Retry an observable with an exponential backoff strategy.
 * See https://angularindepth.com/posts/1260/power-of-rxjs-when-using-exponential-backoff
 * See the _checkConnection method in TrayService for an example.
 *
 * @param {object} options - Optional parameters for retry configuration.
 * @param {number} options.maxRetries - The maximum number of retries. Default is 3.
 * @param {number} options.initialInterval - The initial interval between retries in milliseconds. Default is 1000.
 * @param {number} options.maxInterval - The maximum interval between retries in milliseconds. Default is 10000.
 * @param retryCallback
 * @return {MonoTypeOperatorFunction<number>} An operator function that retries the observable.
 */
export function retryBackoff(
    {
        maxRetries = 3,
        initialInterval = 1000,
        maxInterval = 10000
    },
    retryCallback?: (attempt: number) => void
): MonoTypeOperatorFunction<boolean> {
    return (obs$) => {
        return obs$.pipe(
            retryWhen(
                concatMap((error, attempt) => {
                    if (attempt >= maxRetries) {
                        return throwError(error);
                    }

                    const delayTime = Math.min(Math.pow(2, attempt) * initialInterval, maxInterval);
                    if (retryCallback) {
                        retryCallback(attempt);
                    }
                    return timer(delayTime);
                })
            )
        );
    };
}
