import { Component, OnInit } from "@angular/core";
import { BaseModalComponent } from "@sf/common";

@Component({
    selector: "sf-notarization-type-info-modal",
    templateUrl: "./notarization-type-info-modal.component.html",
    styleUrls: ["./notarization-type-info-modal.component.scss"]
})
export class NotarizationTypeInfoModalComponent extends BaseModalComponent {
    constructor() {
        super();
    }

    ngOnInit(): void {}
}
