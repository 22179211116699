import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { SubmitterGlobalSearchService } from "../../services/submitter-global-search.service";
import { fromEvent, Subject } from "rxjs";
import { delay, finalize, takeUntil, throttleTime } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PackageStatusDialogService } from "@sf/erecord";
import { SubmitterReceiptDataDialogService } from "../../services/submitter-receipt-data-dialog.service";
import { SubmitterReceiptDataDialogComponent } from "../../dialogs/submitter-receipt-data-dialog/submitter-receipt-data-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubmitterPackage } from "../../interfaces/submitter-package.interface";
import { SubmitterPackageSearchResult } from "../../interfaces/submitter-package-search-result.interface";

const PLACEHOLDER_TEXT = "Search for packages";

@Component({
    selector: "sf-submitter-global-search",
    templateUrl: "./submitter-global-search.component.html",
    styleUrls: ["./submitter-global-search.component.scss"]
})
export class SubmitterGlobalSearchComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input()
    paperOnly = false;
    @Input()
    useCustomResultSelect = false;
    @Input()
    hideMagnifyingGlass = false;

    /**
     * autoFocus should not be used if this component is present on page load since
     * that interferes with a11y skip link, but if the component is shown because of
     * a user interaction, go ahead
     **/
    @Input()
    autoFocus = false;
    @Output()
    resultSelect = new EventEmitter();
    @Output()
    preserveGlobalSearch: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private globalSearchService: SubmitterGlobalSearchService,
        private router: Router,
        private __packageStatusDialogService: PackageStatusDialogService,
        private _modalService: NgbModal,
        private _receiptDataDialogService: SubmitterReceiptDataDialogService
    ) {}
    @ViewChild("globalsearch__input") inputField: ElementRef;
    @ViewChildren("globalsearch__results")
    searchResultsContainer: QueryList<ElementRef>;

    /* Private Variables */
    private destroy$ = new Subject<void>();
    private _dialogIsOpen = false;

    /* Public Variables */
    searchForm: FormGroup;
    placeholder = PLACEHOLDER_TEXT;
    searchResults: SubmitterPackage[] = [];
    hasMoreSearchResults: boolean = false;
    searching = false;
    resultsShouldBeOpen = false;
    focusedIndex = -1;
    previousSearch: string;
    noResultsFound: boolean;

    ngOnInit() {
        this.searchForm = new FormGroup({
            searchValue: new FormControl("", [])
        });
        this.preserveGlobalSearch.emit(false);
    }

    ngAfterViewInit(): void {
        fromEvent(document, "keydown")
            .pipe(takeUntil(this.destroy$))
            .subscribe(this._listenForSearchKeys.bind(this));

        this.searchForm
            .get("searchValue")
            .statusChanges.pipe(throttleTime(250), takeUntil(this.destroy$))
            .subscribe(() => {
                this._search(this.searchForm.get("searchValue").value);
            });

        if (this.autoFocus) {
            this.inputField.nativeElement.focus();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    handleKeydown($event: KeyboardEvent) {
        let searchValue: string = this.searchForm.get("searchValue").value;

        // on enter
        if ($event.key === "Enter" && searchValue) {
            if (this.focusedIndex > -1 && this.resultsShouldBeOpen) {
                if (this.useCustomResultSelect) {
                    this.resultSelect.emit(
                        this.searchResults[this.focusedIndex]
                    );
                    this._resetSearch();
                } else {
                    this.navigateToPackage(
                        this.searchResults[this.focusedIndex].id
                    );
                }
            } else {
                this._search(this.searchForm.get("searchValue").value);
            }
            return;
        }

        // on escape
        if ($event.key === "Escape" && searchValue) {
            this._resetSearch();
            return;
        }

        // on up
        if (
            $event.key === "ArrowUp" ||
            ($event.key === "Up" && this.resultsShouldBeOpen)
        ) {
            $event.preventDefault();
            if (this.focusedIndex > 0) {
                this.focusedIndex -= 1;
                this._scrollIntoView(this.focusedIndex);
            }
            return;
        }

        // on down
        if (
            $event.key === "ArrowDown" ||
            ($event.key === "Down" && this.resultsShouldBeOpen)
        ) {
            $event.preventDefault();
            if (this.focusedIndex < this.searchResults.length - 1) {
                this.focusedIndex += 1;
                this._scrollIntoView(this.focusedIndex);
            }
            return;
        }

        // on shift tab
        if (
            $event.shiftKey &&
            $event.key === "Tab" &&
            this.resultsShouldBeOpen
        ) {
            $event.preventDefault();
            $event.stopPropagation();
            if (this.focusedIndex > -1) {
                this.focusedIndex -= 1;
                this._scrollIntoView(this.focusedIndex);
            }
            return;
        }

        // on tab
        if ($event.key === "Tab" && this.resultsShouldBeOpen) {
            if (this.focusedIndex < this.searchResults.length - 1) {
                $event.preventDefault();
                $event.stopPropagation();
                this.focusedIndex += 1;
                this._scrollIntoView(this.focusedIndex);
            } else {
                this._resetSearch();
            }
            return;
        }
    }

    navigateToPackage(packageId: string) {
        this.router.navigate(["submitter/package/" + packageId + "/details"]);
    }

    openPackageStatusDialog(pkg: SubmitterPackage) {
        let closed$ = new Subject<void>();
        this._dialogIsOpen = true;

        if (this._receiptDataDialogService.shouldOpenReceiptDataDialog(pkg)) {
            let modalRef = this._modalService.open(
                SubmitterReceiptDataDialogComponent
            );
            modalRef.componentInstance.pkg = pkg;
            modalRef.result.finally(() => {
                closed$.next();
                closed$.complete();
            });
            this.preserveGlobalSearch.emit(true);
        } else {
            this.__packageStatusDialogService.open(pkg.id, closed$);
            this.preserveGlobalSearch.emit(true);
        }

        closed$.pipe(delay(100), takeUntil(this.destroy$)).subscribe(() => {
            this._dialogIsOpen = false;
            this.preserveGlobalSearch.emit(false);

            // make sure input has focus again so keydown events work properly
            this.inputField.nativeElement.focus();
        });
    }

    /**
     * Only reset the search if no dialogs are open, this way the dialog will be dismissed and the results will remain open
     */
    clickAway() {
        if (!this._dialogIsOpen) {
            this._resetSearch();
        }
    }

    onFocus() {
        this.placeholder = "";
    }

    onBlur() {
        this.placeholder = PLACEHOLDER_TEXT;
    }

    searchResultSelect(pkg: SubmitterPackage, $event: MouseEvent) {
        if (this.useCustomResultSelect) {
            $event.preventDefault();
            this.resultSelect.emit(pkg);
            this._resetSearch();
        }
    }

    private _resetSearch() {
        this.placeholder = PLACEHOLDER_TEXT;
        this.searchForm.setValue({ searchValue: "" }, { emitEvent: false });
        this.previousSearch = "";
        this.searchResults = [];
        this.hasMoreSearchResults = false;
        this.searching = false;
        this.noResultsFound = false;
        this.resultsShouldBeOpen = false;
        this.focusedIndex = -1;
    }

    private _search(searchValue: string) {
        if (searchValue && searchValue.length) {
            this.focusedIndex = -1;
            this.previousSearch = searchValue;
            this.noResultsFound = false;
            this.searching = true;

            this.globalSearchService
                .searchPackages(searchValue, this.paperOnly)
                .pipe(finalize(() => (this.searching = false)))
                .subscribe((result: SubmitterPackageSearchResult) => {
                    this.searchResults = result.packages;
                    this.hasMoreSearchResults = result.hasMore;
                    this.noResultsFound = this.searchResults.length === 0;
                    this.resultsShouldBeOpen = true;
                });
        } else {
            this._resetSearch();
        }
    }

    private _scrollIntoView(index: number) {
        let resultElement = this.searchResultsContainer.toArray()[index];
        resultElement.nativeElement.scrollIntoView({ block: "nearest" });
    }

    /**
     * put focus on search input on a ctrl/cmd+shift key press
     * @param $event
     * @private
     */
    private _listenForSearchKeys($event: KeyboardEvent) {
        if (($event.ctrlKey || $event.metaKey) && $event.key === "f") {
            $event.preventDefault();
            this.inputField.nativeElement.focus();
        }
    }
}
