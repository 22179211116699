import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SigningDocumentService } from "./signing-document.service";
import { DigitalCertificateModalComponent } from "../components/digital-certificate-modal/digital-certificate-modal.component";
import { tap } from "rxjs/operators";
import { DynamicScriptLoaderService, SessionService } from "@sf/common";
import { DigitalSignatureRequirement } from "../interfaces/digital-signature-requirement";
import { Pkcs12ReaderService } from "./pkcs12-reader.service";
import { Observable } from "rxjs";
import { EsignUserService } from "./esign-user.service";

declare let forge: any;
let pkcs12Reader: any;

@Injectable({
    providedIn: "root"
})
export class DigitalCertificateService {
    private certificateStorageName =
        this._sessionService.getUserID() + ".pkcs12";
    p12OnServer = sf.liveConfig.DocBuilderConfig.p12OnServer;

    constructor(
        private _sessionService: SessionService,
        private _modal: NgbModal,
        private _esignUserService: EsignUserService,
        private _signingDocumentService: SigningDocumentService,
        private _pkcs12ReaderService: Pkcs12ReaderService,
        _dynamicScriptLoader: DynamicScriptLoaderService
    ) {
        _dynamicScriptLoader.load("forge").then(() => {
            pkcs12Reader = this._pkcs12ReaderService.getPKCS12Reader(forge);
        });
    }

    showCertificateModal(
        digitalSignatureRequirement: DigitalSignatureRequirement
    ): Observable<{ password: string; p12Raw: string }> {
        if (!this.p12OnServer && !digitalSignatureRequirement.p12Raw) {
            digitalSignatureRequirement.p12Raw = this.getP12Raw();
        }
        let options: NgbModalOptions = {};
        if (
            digitalSignatureRequirement.hasP12 ||
            digitalSignatureRequirement.p12Raw
        ) {
            options.windowClass = "passphrase-modal";
        }

        let passphraseModal = this._modal.open(
            DigitalCertificateModalComponent,
            options
        );
        let component =
            passphraseModal.componentInstance as DigitalCertificateModalComponent;
        component.digitalSignatureRequirement = digitalSignatureRequirement;

        return component.result$.pipe(
            tap((result: any) => {
                localStorage?.setItem(
                    this.certificateStorageName,
                    result.p12Raw
                );
                this._signingDocumentService.setSigningPassword(
                    result.password
                );
                if (this.p12OnServer && result.password) {
                    digitalSignatureRequirement.hasP12 = true;
                    digitalSignatureRequirement.hasPassword = true;
                }
                passphraseModal.close();
            })
        );
    }

    getSignature(p12Raw: string, password: string, tamperSeal: string) {
        let p12Reader = new pkcs12Reader(p12Raw, password);
        return this._pkcs12ReaderService.getSignature(p12Reader, tamperSeal);
    }

    getP12Raw() {
        return localStorage?.getItem(this.certificateStorageName);
    }

    getUserHasP12(): Observable<any> {
        return this._esignUserService.getUserHasP12();
    }
}
