<sf-modal-header title="Event has been made private" [showDismiss]="false"></sf-modal-header>
<div class="modal-body">
    {{onWorkflowPage ? "This" : "The following"}} event has been marked
    as private by the event creator or an admin user. You no longer have
    access to this event.
    <ng-container *ngIf="!onWorkflowPage">
        <br>
        <br>
        Event Name: {{eventName}}
    </ng-container>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
