import { Injectable, Type } from "@angular/core";
import { ArrayField, DynamicField, GroupField } from "../interfaces";
import { AbstractControl } from "@angular/forms";
import { DynamicFormGroup } from "../controls";
import { FormBuilder } from "../interfaces";
import { DynamicFormArray } from "../controls";
import { buildForm, buildFormControl } from "../helpers";

@Injectable({
    providedIn: "root"
})
export class DynamicFormBuilder implements FormBuilder {
    build(fields: DynamicField[]): DynamicFormGroup {
        return buildForm(fields);
    }

    group(field: GroupField): DynamicFormGroup {
        return buildFormControl(field) as DynamicFormGroup;
    }

    array(field: ArrayField): DynamicFormArray {
        return buildFormControl(field) as DynamicFormArray;
    }

    field(field: DynamicField): AbstractControl {
        return buildFormControl(field);
    }
}
