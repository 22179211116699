import {
    Component,
    Input,
    OnInit,
    SecurityContext,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { CurrentOrganizationService, PaymentAccount } from "@sf/userorg/common";
import { dayjs, ModalButton, TemplateRendererComponent } from "@sf/common";
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridSizeChangedEvent,
    ICellRendererParams,
    RowNode
} from "@ag-grid-community/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "sf-packages-using-account-archived-dialog",
    templateUrl: "./packages-using-account-archived-dialog.component.html",
    styleUrls: ["./packages-using-account-archived-dialog.component.scss"]
})
export class PackagesUsingAccountArchivedDialogComponent implements OnInit {
    @Input()
    account: PaymentAccount;
    @Input()
    packages: any[];

    @ViewChild("packageNameTemplate", { static: true })
    packageNameTemplate: TemplateRef<any>;

    modalTitle: string;
    primaryButton: ModalButton;
    gridOptions: GridOptions;
    gridDivStyle: any = {
        height: "90px" //minimum for two rows
    };

    private _gridApi: GridApi;
    private _gridColumnDefs: Partial<ColDef>[];
    private _rowHeight: number = 30;
    private _headerHeight: number = 35;
    private _orgID: string = null;

    constructor(
        private _activeModal: NgbActiveModal,
        private _currentOrgService: CurrentOrganizationService,
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this._orgID = this._currentOrgService.getCurrentOrganizationID();
        this.modalTitle = "Remove Account - In Use";
        this.primaryButton = {
            text: "OK",
            callback: this._activeModal.close.bind(this)
        };

        //setup columns
        this._gridColumnDefs = [
            {
                colId: "packageName",
                field: "name",
                headerName: "Package",
                checkboxSelection: false,
                headerCheckboxSelection: false,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.packageNameTemplate
                },
                comparator: this._packageNameComparator
            },
            {
                colId: "packageStatus",
                field: "status",
                headerName: "Status",
                width: 100,
                cellRenderer: (params: ICellRendererParams) => {
                    let sanitized = this._sanitizer.sanitize(
                        SecurityContext.HTML,
                        params.value.toLowerCase()
                    );
                    return (
                        '<div class="pkgStatus"><button class="btn badge ' +
                        sanitized +
                        '">' +
                        sanitized +
                        "</button></div>"
                    );
                }
            },
            {
                colId: "packageRecipient",
                field: "recipient",
                headerName: "Recipient"
            },
            {
                colId: "packageDate",
                field: "createdDate",
                headerName: "Date Created",
                cellRenderer: (params: ICellRendererParams) => {
                    let sanitized = this._sanitizer.sanitize(
                        SecurityContext.HTML,
                        params.value
                    );
                    let date: dayjs.Dayjs = dayjs(sanitized);
                    return date.format("ddd M/D/YYYY h:mm A");
                },
                comparator: this._packageDateComparator
            }
        ];

        //setup grid
        this.gridOptions = {
            columnDefs: this._gridColumnDefs,
            context: this,
            defaultColDef: {
                sortable: true
            },
            rowHeight: this._rowHeight,
            suppressCellFocus: true,
            suppressRowClickSelection: true,
            onGridSizeChanged: (event: GridSizeChangedEvent) => {
                if (this._gridApi) {
                    this._gridApi.sizeColumnsToFit();
                }
            },
            onGridReady: (event: GridReadyEvent) => {
                this._gridApi = event.api;
                //set initial sort of grid
                event.columnApi.applyColumnState({
                    state: [{ colId: "packageName", sort: "asc" }]
                });
            }
        };

        if (this.packages.length <= 8) {
            this.gridDivStyle.height =
                this.packages.length * this._rowHeight +
                this._headerHeight +
                10 +
                "px";
        } else {
            this.gridDivStyle.height = "300px";
        }
    }

    private _packageDateComparator(date1: string, date2: string) {
        let m1: dayjs.Dayjs = dayjs(date1),
            m2: dayjs.Dayjs = dayjs(date2);

        if (m1.isSame(m2)) return 0;
        if (m1.isBefore(m2)) return -1;
        if (m1.isAfter(m2)) return 1;
    }

    private _packageNameComparator(
        name1: string,
        name2: string,
        node1: RowNode,
        node2: RowNode
    ) {
        // seems what was done here before was problematic after the agGrid update, plus not needed
        if (name1 == name2) return 0;
        return name1 > name2 ? 1 : -1;
    }
}
