import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalsModule, SfCommonModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { EEligibilityStatusByJurisdictionComponent } from "./e-eligibility-status-by-jurisdiction/e-eligibility-status-by-jurisdiction.component";
import { EEligibilityDataService } from "./e-eligibility-data.service";
import { EEligibilityChangeHistoryDialogComponent } from "./e-eligibility-change-history-dialog/e-eligibility-change-history-dialog.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SelectModule } from "@sf/common";
import { EEligibilityEditNoteDialogComponent } from "./e-eligibility-edit-note-dialog/e-eligibility-edit-note-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ModalsModule,
        NgbModule,
        SfFormsModule,
        SfCommonModule,
        FormsModule,
        SelectModule,
        FontAwesomeModule,
        AgGridModule
    ],
    declarations: [
        EEligibilityStatusByJurisdictionComponent,
        EEligibilityChangeHistoryDialogComponent,
        EEligibilityEditNoteDialogComponent
    ],
    providers: [EEligibilityDataService]
})
export class EEligibilityModule {}
