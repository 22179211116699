import { Injectable } from "@angular/core";
import { DynamicScriptLoaderService } from "../dynamic-script-loader.service";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from "@angular/router";
import { from, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DynamicScriptLoadResolverService implements Resolve<any> {
    constructor(private _dynamicScripts: DynamicScriptLoaderService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return from(this._dynamicScripts.load(...route.data.scripts));
    }
}
