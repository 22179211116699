export enum SharedDocStatusConstants {
    DATA_ENTRY = "Data Entry",
    DATA_REVIEW = "Review",
    NEEDS_POA = "Needs POA",
    SIGN = "Sign",
    DRAFT = "Draft",
    CONVERTED = "Converted",
    READY = "Ready",
    SENT = "Sent",
    SENT_REMOTE = "SentRemote",
    RECEIVED = "Received",
    REVIEWING = "Reviewing",
    REVIEWED = "Reviewed",
    ACCEPTED = "Accepted",
    REJECTED = "Rejected",
    BILLED_REJECTED = "Billed Rejected",
    RECORDING = "Recording",
    RECORDED = "Recorded",
    MAINTENANCE = "Maintenance",
    VOIDED = "Voided",
    REMOVED = "Removed",
    UNKNOWN = "Unknown",
    CANCELED = "Canceled",
    FINALIZING = "Finalizing",
    INITIALIZE = "Initialize"
}
