import { Injectable, ViewContainerRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class GlobalViewContainerService {
    private _containers: {
        [name: string]: BehaviorSubject<ViewContainerRef>;
    } = {};

    constructor() {}

    register(name: string, container: ViewContainerRef) {
        if (!this._containers[name]) {
            this._containers[name] = new BehaviorSubject(container);
        } else {
            this._containers[name].next(container);
        }
    }

    get(name: string) {
        if (!this._containers[name]) {
            this._containers[name] = new BehaviorSubject<ViewContainerRef>(
                undefined
            );
        }
        return this._containers[name].pipe(filter((ref) => !!ref));
    }
}
