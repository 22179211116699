import { AsyncValidatorFn, FormControl, ValidatorFn } from "@angular/forms";
import { Type } from "@angular/core";
import { ConditionalType, FieldType } from "../enums";
import { ConditionUpdateType, NewConditionalRule, RootField } from "./index";
import { InputComponent } from "../components";
import { DateRangePreset } from "@sf/common";
import { DataLocation } from "@sf/esign/common";

export const ROOT_FIELD_KEY = "rootField";

export interface FieldOption {
    option: string | number;
    label: string;
    description?: string;
    dataLocations?: DataLocation[];
}

export interface FieldViewState {
    visible: boolean;
    visibleFieldsMap?: {
        [path: string]: boolean;
    };
    arrayFields?: string[];
    parent?: string;
    expanded?: boolean;
    options?: FieldOption[];
}

export interface SelectFieldViewState extends FieldViewState {
    options: FieldOption[];
    externalFilter?: {
        product: string;
        method: string;
        defaultValue?: string;
    };
}

export interface GroupFieldViewState extends FieldViewState {
    visibleFieldsMap: {
        [path: string]: boolean;
    };
}

export interface ArrayFieldViewState extends FieldViewState {
    arrayFields: string[];
    visibleFieldsMap: {
        [path: string]: boolean;
    };
}

export interface DateRangeFieldViewState extends FieldViewState {
    dateRangePresets: DateRangePreset[];
}

export interface FormViewerValidator {
    identifier: string;
    type: "async" | "sync";
    validators?: ValidatorFn[];
    asyncValidators?: AsyncValidatorFn[];
}

export interface ValidatorState {
    [identifier: string]: FormViewerValidator;
}

export interface FieldFormState {
    disabled: boolean;
    validators: FormViewerValidator[];
}

export interface StateOperation {
    type: ConditionUpdateType;
    fieldId: string;
    update: Partial<FieldFormState | FieldViewState>;
}

export interface FieldState {
    id: string;
    type: FieldType;
    component?: Type<InputComponent>;
    label: string;
    path: string;
    fullPath: string;
    value: any | null;
    defaults: {
        value: any;
        fieldViewState: FieldViewState | null;
        formState: FieldFormState;
        label: string;
    };
    conditionals: NewConditionalRule[];
    expandable?: boolean;
    maxLength?: number;
    repeatableFieldState?: RepeatableFieldDefinition;
    collapsedMessage?:
        | string
        | string[]
        | ((control: FormControl, fieldStates?: FieldState[]) => string)
        | ((control: FormControl, fieldStates?: FieldState[]) => string[]);
    placeholder?: string | null;
    toolTip?: string;
    showToolTipIcon?: boolean;
    sortableTable?: boolean;
    showLabel?: boolean;
    phoneValueFormat?: string;
}

export interface RepeatableFieldDefinition {
    viewState: {
        [path: string]: FieldViewState;
    };
    formState: {
        [path: string]: FieldFormState;
    };
    fieldState: {
        [path: string]: FieldState;
    };
    conditionals: {
        [path: string]: NewConditionalRule[];
    };
}

export interface RootFieldState {
    type: FieldType.ROOT;
    defaults: {
        fieldViewState: FieldViewState;
    };
}

export interface FieldStateWrapper {
    [ROOT_FIELD_KEY]?: RootFieldState;
    [path: string]: FieldState | RootFieldState;
}

export interface ViewState {
    [ROOT_FIELD_KEY]?: FieldViewState;
    [path: string]: FieldViewState;
}

export interface FormState {
    [path: string]: FieldFormState;
}

export interface ConditionalChange {
    viewState?: Partial<FieldViewState>;
    formState?: Partial<FieldFormState>;
}

export interface FormViewerConditional {
    type: ConditionalType;
    value: any;
    fieldPath: string;
    changeToApply: Partial<ConditionalChange>;
    fieldsToChange: string[];
}

export interface DynamicFormState {
    // selectedId?: string | number; // which FormViewer record has been selected
    // loaded: boolean; // has the FormViewer list been loaded
    // error?: string | null; // last none error (if any)
    value?: any;
    rootField: RootField;
    viewState: ViewState;
    formState: FormState;
    fieldState: FieldStateWrapper;
    conditionals: Conditionals;
}

export interface Conditionals {
    [path: string]: NewConditionalRule[];
}
