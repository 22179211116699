import { Component, OnInit } from "@angular/core";
import { UserResult } from "../../universal-search.interfaces";
import { UniversalSearchResultComponent } from "../universal-search-result/universal-search-result.component";

@Component({
    selector: "sf-user-result",
    templateUrl: "./user-result.component.html",
    styleUrls: ["./user-result.component.scss"]
})
export class UserResultComponent
    extends UniversalSearchResultComponent
    implements OnInit
{
    item: UserResult;

    ngOnInit(): void {
        this.item = this.result as UserResult;
    }
}
