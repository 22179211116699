<div *ngIf="!loaded || loadFailed" class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<sf-new-organization *ngIf="tokenType == 'INVITE_ORGANIZATION'">
</sf-new-organization>
<sf-new-user *ngIf="tokenType == 'INVITE_USER'">
</sf-new-user>
<sf-defer-accept *ngIf="tokenType == 'SIGN_MSA'">
</sf-defer-accept>
<sf-service-nonuser *ngIf="tokenType == 'INVITE_SERVICE' && !loginRequired">
</sf-service-nonuser>
<div *ngIf="loginRequired" class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
    <div class="top-gap">
        You need to log in to accept this invitation. Redirecting now...
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </div>
</div>
<sf-verify-email *ngIf="tokenType == 'EMAIL_VERIFICATION'">
</sf-verify-email>
