import { Injectable } from "@angular/core";
import { Location } from "@angular/common";

@Injectable({
    providedIn: "root"
})
export class ExternalPageService {
    private _externalPages: string[] = [
        "/login",
        "/login/reset",
        "/login/contact",
        "/login/change-password",
        "/login/forgot-username",
        "/login/forgot-password",
        "/login/second-factor",
        "/login/loan-authentication",
        "/login/package-download",
        "/zendeskChat",
        "/sign-event/external",
        "/epc",
        "/consumer-portal",
        "/invitations/api-token-accept",
        "/invitations/accept-invite",
        "/invitations/cancel-invitation"
    ];

    constructor(private _location: Location) {}

    isPageExternal(page: string): boolean {
        return this._externalPages.some((extPage) => page.startsWith(extPage));
    }

    isCurrentPageExternal(): boolean {
        let url = this._location.path().replace("/sf/ui", "");

        // Remove obsolete 'app/' fragment
        url = url.replace("app/", "");

        url = url.split("?")[0]; // remove query string, if any
        return this.isPageExternal(url);
    }
}
