import { ValidatorFn, Validators } from "@angular/forms";

export enum ConditionalType {
    EQUAL = "EQUAL",
    EQUALANDVISIBLE = "EQUALANDVISIBLE",
    CONTAINS = "CONTAINS",
    CONTAINSANDVISIBLE = "CONTAINSANDVISIBLE",
    NOTNULL = "NOTNULL",
    NOTEMPTY = "NOTEMPTY",
    NOTEMPTYANDVISIBLE = "NOTEMPTYANDVISIBLE",
    ISNULL = "ISNULL",
    EXPRESSION = "EXPRESSION",
    FUNCTION = "FUNCTION"
}

export enum ValidatorType {
    Required = "required"
}

export const ValidatorMapping: { [type in ValidatorType]: ValidatorFn } = {
    [ValidatorType.Required]: Validators.required
};
