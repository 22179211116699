import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UserArchiveService {
    constructor(private _rpcClient: RpcClientService) {}

    canArchiveUser(username: string): Observable<boolean> {
        return this._rpcClient.makeRequest("capc", "canArchiveUser", {
            username: username
        });
    }

    archiveUser(username: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "archiveUser", {
            username: username
        });
    }

    unarchiveUser(username: string) {
        return this._rpcClient.makeRequest("userorg", "unarchiveUser", {
            username: username
        });
    }
}
