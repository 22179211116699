import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { transformLinkWithParams } from "@sf/common";
import { Navigation, NavListResolverService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class OrgReportNavResolverService extends NavListResolverService {
    constructor() {
        super();
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Navigation {
        this.navigation.parent = {
            label: "Organization",
            link: transformLinkWithParams(
                ["/admin/organization/:orgID/config/contact"],
                route
            )
        };
        this.navigation.items = [];
        this.navigation.items.push(
            {
                label: "Reports",
                link: transformLinkWithParams(
                    ["/admin/organization/:orgID/reports"],
                    route
                )
            },
            {
                label: "Scheduled Reports",
                link: transformLinkWithParams(
                    ["/admin/organization/:orgID/scheduled-reports"],
                    route
                )
            },
            {
                label: "Report Settings",
                link: transformLinkWithParams(
                    ["/admin/organization/:orgID/submitter/report-settings"],
                    route
                )
            }
        );

        return this.navigation;
    }
}
