import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalButton } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Address, UserOrgService } from "@sf/userorg/common";
import { AddressVerificationService } from "@sf/userorg/common";
import { SfValidators } from "@sf/common";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-edit-personal-contact-address-settings-personal-dialog",
    templateUrl:
        "./edit-personal-contact-address-settings-personal-dialog.component.html",
    styleUrls: [
        "./edit-personal-contact-address-settings-personal-dialog.component.scss"
    ]
})
export class EditPersonalContactAddressSettingsPersonalDialogComponent
    implements OnInit, OnDestroy
{
    @Input() addressData: Address;
    @Input() title = "Contact Address";
    @Input() message?: string;

    /** Private Variables **/
    private _onDestroy: Subject<void> = new Subject<void>();

    /** Public Variables **/
    editForm: FormGroup;
    primary: ModalButton;
    secondary: ModalButton;

    warning: string = null;
    nameTooltip: string = "";

    timeZoneOptions: any[] = [];
    userOptions: any[] = [];

    showAddressCheckbox = false;
    showAddressRadios = false;
    invalidAddressMessage: string = null;
    verifiedAddress: any = null;

    constructor(
        private _fb: FormBuilder,
        private _activeModal: NgbActiveModal,
        private _modalService: NgbModal,
        private _userorgService: UserOrgService,
        private _addressVerificationService: AddressVerificationService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Save",
            disabled: false,
            callback: this.saveChanges.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.editForm = this._fb.group({
            street1: [""],
            street2: [""],
            city: [""],
            state: [""],
            zipCode: ["", [SfValidators.zipCodeValidator]],
            addressIsCorrect: [false],
            addressRadio: ["recommended"]
        });

        this.catchFormChanges();

        // patch form
        this.editForm.patchValue({
            street1: this.addressData.streets[0],
            street2: this.addressData.streets[1],
            city: this.addressData.city,
            state: this.addressData.state,
            zipCode: this.addressData.zipCode
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    catchFormChanges() {
        this.editForm
            .get("street1")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.resetInvalidAddress();
            });
        this.editForm
            .get("street2")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.resetInvalidAddress();
            });
        this.editForm
            .get("city")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.resetInvalidAddress();
            });
        this.editForm
            .get("zipCode")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.resetInvalidAddress();
            });
    }

    private resetInvalidAddress() {
        this.invalidAddressMessage = null;
        this.editForm.patchValue({
            addressRadio: "",
            addressIsCorrect: false
        });
    }

    buildReturnData(): Address {
        let streets = [
            this.editForm.controls.street1.value, this.editForm.controls.street2.value
        ];
        let street = streets.join("\n");
        let street1 = this.editForm.controls.street1.value;
        let street2 = this.editForm.controls.street2.value;
        let city = this.editForm.controls.city.value;
        let state = this.editForm.controls.state.value;
        let zipCode = this.editForm.controls.zipCode.value;

        return {
            streets: streets,
            city: city,
            state: state,
            zipCode: zipCode
        };
    }

    saveChanges() {
        let addressData = this.buildReturnData();

        // basic check for missing fields, etc
        this.warning = this.validate(addressData);

        if (this.warning) {
            this.invalidAddressMessage = null;
            return;
        }

        if (this.invalidAddressMessage) {
            let isChecked = this.editForm.controls.addressIsCorrect.value;
            let radio = this.editForm.controls.addressRadio.value;
            if ((this.showAddressCheckbox && isChecked) || (this.showAddressRadios && radio == "entered")) {
                // user accepts invalid address
                this._activeModal.close(addressData);
            } else if (this.showAddressRadios && radio == "recommended") {
                //update with recommended address
                window.setTimeout(() => {
                    addressData.streets[0] = this.verifiedAddress.street1;
                    if (this.verifiedAddress.secondary) {
                        addressData.streets[0] = addressData.streets[0] + " " + this.verifiedAddress.secondary;
                    }
                    addressData.streets[1] = this.verifiedAddress.street2;
                    addressData.city = this.verifiedAddress.city;
                    addressData.state = this.verifiedAddress.state;
                    addressData.zipCode = this.verifiedAddress.zip;
                    addressData.streets = [
                        addressData.streets[0], addressData.streets[1]
                    ];
                    if (this.verifiedAddress.fips) {
                        addressData.county = this.verifiedAddress.fips;
                    }
                    this._activeModal.close(addressData);
                });
            } else {
                // re-validate in case user made changes
                this.verifyAddress(addressData);
            }
        } else {
            // validate the address
            this.verifyAddress(addressData);
        }
    }

    abort() {
        this._activeModal.close(false);
    }

    validate(addressData: Address) {
        if (this.editForm.invalid && (addressData.city.trim().length < 1 || addressData.streets[0].trim().length < 1)) {
            return "Please correct invalid or missing data.";
        }
        if (this.editForm.invalid) {
            return "Please correct invalid or missing data.";
        }
        /*  // all is optional
        if (!addressData.state || addressData.state.length === 0) {
            return "Please select a state.";
        }
        */
        return null;
    }

    verifyAddress(addressData: Address) {
        this.showAddressCheckbox = false;
        this.showAddressRadios = false;
        this.invalidAddressMessage = null;
        this.verifiedAddress = null;

        if (addressData.streets[0] && addressData.city && addressData.state && addressData.zipCode) {
            let inputAddress = {
                street: addressData.streets[0],
                street2: addressData.streets[1],
                city: addressData.city,
                state: addressData.state, // should just be a state abbreviation
                zipCode: addressData.zipCode
            };
            if (this._addressVerificationService.isPOBox(inputAddress)) {
                this.invalidAddressMessage = "Please enter your entity's physical address, not a post office box.";
                this.showAddressCheckbox = false;
                return;
            }
            this._addressVerificationService
                .verifyAddress(inputAddress)
                .pipe(first())
                .subscribe((verifiedAddress: any) => {
                    if (verifiedAddress) {
                        //if the same, accept it
                        if (this._isEqual(verifiedAddress.street1, addressData.streets[0]) &&
                                (!verifiedAddress.street2 ||
                                        this._isEqual(verifiedAddress.street2, addressData.streets[1])) &&
                                this._isEqual(verifiedAddress.city, addressData.city) &&
                                this._isEqual(verifiedAddress.state, addressData.state) &&
                                this._isEqual(verifiedAddress.zip, addressData.zipCode)) {
                            this._activeModal.close(addressData);
                        } else {
                            if (this._addressVerificationService.isPOBox(verifiedAddress)) {
                                this.invalidAddressMessage =
                                        "Please enter your entity's physical address, not a post office box.";
                                this.showAddressCheckbox = false;
                            } else {
                                this.verifiedAddress = verifiedAddress;
                                this.invalidAddressMessage = "Address validation recommends this address:<br/>" +
                                        this.parseVerifiedAddress(verifiedAddress);
                                this.showAddressRadios = true;
                                this.editForm
                                    .get("addressRadio")
                                    .patchValue("recommended");
                            }
                        }
                    } else {
                        this.invalidAddressMessage =
                                "The address information entered is not a recognized address.<br/>Are you sure it is correct?";
                        this.showAddressCheckbox = true;
                    }
                });
        } else {
            //no address
            this._activeModal.close(addressData);
        }
    }

    private _isEqual(val1: any, val2: any): boolean {
        return JSON.stringify(val1) === JSON.stringify(val2);
    }

    parseVerifiedAddress(verifiedAddress: any) {
        let result = verifiedAddress.street1;
        if (verifiedAddress.street2) {
            result = result + "<br/>" + verifiedAddress.street2;
        }
        result = result + "<br/>" + verifiedAddress.city + ", " + verifiedAddress.state + "&nbsp;&nbsp;" +
                verifiedAddress.zip;

        return result;
    }

    stateChanged(selection: any) {
        this.editForm.controls.state.setValue(selection ? selection.abbrev : null);
        this.resetInvalidAddress();
    }
}
