import { Injectable } from "@angular/core";
import { SfValidators } from "@sf/common";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class InvitationSharedService {
    constructor() {}

    /**
     * returns array of error messages if there was a problem
     */
    validateNewUserForm(
        form: FormGroup,
        titleRequired: boolean,
        phoneRequired: boolean,
        passwordHas9Characters: boolean,
        passwordHas3of4: boolean,
        passwordsMatch: boolean
    ): string[] {
        let firstName: string = form.controls.firstName.value;
        let middleName: string = null;
        if (form.controls.middleName)
            middleName = form.controls.middleName.value;
        let lastName: string = form.controls.lastName.value;
        let title: string = form.controls.title.value;
        let email: string = form.controls.email.value;

        let hasUserID = false;
        let userID: string = null;
        let password: string = null;
        let confirm: string = null;
        if (form.controls.userID) {
            hasUserID = true;
            userID = form.controls.userID.value;
        }
        if (form.controls.password) {
            password = form.controls.password.value;
        }
        if (form.controls.confirm) {
            confirm = form.controls.confirm.value;
        }

        let formErrorMessages: string[] = [];

        if (!this.isNotBlank(firstName)) {
            formErrorMessages.push("Enter your First Name");
        }
        if (!this.isNotBlank(lastName)) {
            formErrorMessages.push("Enter your Last Name");
        }
        if (titleRequired && !this.isNotBlank(title)) {
            formErrorMessages.push("Enter your Job Title");
        }
        if (phoneRequired) {
            let phone: string = form.controls.phone.value;
            let extension = form.controls.extension.value;
            phone = SfValidators.getNumericDigits(phone);

            if (!phone) {
                formErrorMessages.push("Enter a Phone Number");
            } else if (phone.length != 10) {
                formErrorMessages.push("Phone Number must contain 10 digits");
            }
            if (extension && !SfValidators.NUMERIC_REGEXP.test(extension)) {
                formErrorMessages.push(
                    "Extension (optional) must be all numbers"
                );
            }
            if (extension && extension.length > 9) {
                formErrorMessages.push(
                    "Extension cannot be longer than 9 digits"
                );
            }
        }
        if (!email) {
            formErrorMessages.push("Enter your Email Address");
        }
        if (hasUserID) {
            if (!userID) {
                formErrorMessages.push("Enter your desired Username");
            } else if (userID.length < 7) {
                formErrorMessages.push(
                    "Your Username must be at least 7 characters long"
                );
            } else if (userID.endsWith(" ")) {
                formErrorMessages.push(
                    "Your Username cannot end with a 'space' character"
                );
            } else if (!SfValidators.USERNAME_REGEXP.test(userID)) {
                formErrorMessages.push(
                    "Usernames may consist only of letters, numbers, underscores (_), hyphens (-), and periods (.)"
                );
            }

            if (!password) {
                formErrorMessages.push("Enter your desired Password");
            } else if (
                !passwordHas9Characters ||
                !passwordHas3of4 ||
                !passwordsMatch
            ) {
                formErrorMessages.push("Password does not meet requirements");
            } else if (password.endsWith(" ")) {
                formErrorMessages.push(
                    "Your Password cannot end with a 'space' character"
                );
            } else if (password.toLowerCase() == userID.toLowerCase()) {
                formErrorMessages.push(
                    "Password cannot be the same as your username"
                );
            }

            if (!confirm) {
                formErrorMessages.push("Confirm your desired Password");
            }
        }

        if (!formErrorMessages.length && form.controls.email.errors) {
            formErrorMessages.push("Enter a valid Email Address");
        }

        if (!formErrorMessages.length && form.invalid) {
            formErrorMessages.push("Please correct input errors");
        }

        return formErrorMessages;
    }

    private isNotBlank(val: string): boolean {
        if (!val) return false;
        return !!val.trim().length;
    }
}
