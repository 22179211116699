import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first, map } from "rxjs/operators";
import { GrowlService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { HttpClient } from "@angular/common/http";
import { ImageUpdateItem } from "@sf/userorg/common";
import { ImageUpdateType } from "@sf/userorg/common";
import {
    Dimensions,
    ImageCroppedEvent,
    ImageTransform
} from "ngx-image-cropper";
import { ProfilePictureCompressorService } from "../../services/profile-picture-compressor.service";

@Component({
    selector: "sf-upload-profile-picture-dialog",
    templateUrl: "./upload-profile-picture-dialog.component.html",
    styleUrls: ["./upload-profile-picture-dialog.component.scss"]
})
export class UploadProfilePictureDialogComponent
    implements OnInit, AfterViewChecked
{
    @Input() imageUpdateItem: ImageUpdateItem;

    @ViewChild("imgFileUpload")
    imgFileUpload: ElementRef;

    /** Public Variables **/
    loading: boolean = false;
    profilePicFile: any;
    profilePicFilePreview: any;
    profilePicFileSet: boolean = false;
    errorMessage: string;

    croppedImage: any = "";
    scale: number = 1;
    showCropper: boolean = false;
    containWithinAspectRatio: boolean = false;
    transform: ImageTransform = {};
    previousZoomStep: number = 0;
    isTiff: boolean = false;

    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    constructor(
        private _activeModal: NgbActiveModal,
        private _growlService: GrowlService,
        private _userOrgService: UserOrgService,
        private _profilePicCompressorService: ProfilePictureCompressorService,
        private _http: HttpClient,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // Modal Buttons
        this.primary = {
            text: "Upload",
            disabled: true,
            callback: this.doUploadPic.bind(this)
        };
        this.secondary = {
            text: "Choose File",
            disabled: false,
            callback: this.chooseFile.bind(this)
        };
        this.tertiary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };
    }

    ngAfterViewChecked() {
        this._cd.detectChanges();
    }

    chooseFile() {
        this.imgFileUpload.nativeElement.click();
    }

    imageCropped(event: ImageCroppedEvent) {
        // version 7.0 (and later) of the image cropper no longer uses base 64, it uses blob by default
        this.croppedImage = event.blob;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        // cropper ready
    }

    loadImageFailed() {
        this.errorMessage =
            "Could not load that image file. It may be an unsupported image type. Please choose a different file.";
        this.loading = false;
        this.profilePicFilePreview = null;
    }

    zoomSlider(value: number) {
        const adjustScaleValue: number = value - this.previousZoomStep;
        this.previousZoomStep = value;
        this.scale += adjustScaleValue;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    uploadProfilePic(fileEvent: any) {
        if (
            fileEvent &&
            fileEvent.target &&
            fileEvent.target.files &&
            fileEvent.target.files[0]
        ) {
            this._resetImage();
            if (fileEvent.target.files[0].size > 2100000) {
                this.errorMessage =
                    "That file is too big to upload. Please choose a smaller file.";
                return;
            } else {
                this.loading = true;
                let reader = new FileReader();
                this.profilePicFile = fileEvent.target.files[0];
                if (fileEvent.target.files[0].type === "image/tiff") {
                    this.isTiff = true;
                    this.showCropper = true;
                }

                reader.onload = () => {
                    this.profilePicFileSet = true;
                    this.loading = false;
                    this.profilePicFilePreview = reader.result;
                    this.primary.disabled = false;
                };

                reader.readAsDataURL(fileEvent.target.files[0]);
            }
        }
    }

    doUploadPic() {
        if (!this.profilePicFileSet) {
            return;
        }

        let imageToUpload = this.croppedImage
            ? this.croppedImage
            : this.profilePicFile;

        if (imageToUpload.size > 2100000) {
            this.primary.disabled = true;
            this._profilePicCompressorService
                .compress(imageToUpload)
                .pipe(first())
                .subscribe(
                    (data) => {
                        imageToUpload = data;
                        this._postFileData(imageToUpload);
                    },
                    () => {
                        this.primary.disabled = false;
                    }
                );
        } else {
            this._postFileData(imageToUpload);
        }
    }

    handleImageUpload(response: any) {
        if (this.imageUpdateItem.imageType === ImageUpdateType.USER) {
            this._userOrgService
                .uploadProfilePicture(response)
                .subscribe(() => {
                    // if a user does not have a profile pic uploaded, we need to set the url first
                    this._setImageURL(response);
                });
        } else if (
            this.imageUpdateItem.imageType === ImageUpdateType.ORGANIZATION
        ) {
            this._userOrgService
                .uploadOrganizationProfilePicture(
                    response.fileID,
                    response.type,
                    this.imageUpdateItem.orgID
                )
                .subscribe(() => {
                    // if an organization does not have a profile pic/logo uploaded, we need to set the url first
                    this._setImageURL(response);
                });
        }
    }

    abort() {
        this._activeModal.close(false);
    }

    private _setImageURL(response: any) {
        if (!this.imageUpdateItem.imageURL) {
            this.imageUpdateItem.imageURL =
                "/sf/getPNG.jsp?photoID=" + response.fileID;
        } else {
            // to get the new image to load
            this.imageUpdateItem.imageURL =
                this.imageUpdateItem.imageURL + ("&c=" + Date.now());
        }
        this.profilePicFilePreview = null;
        this.imageUpdateItem.imageFileID = response.fileID;
        this._activeModal.close(this.imageUpdateItem);
    }

    private _resetImage() {
        this.scale = 1;
        this.transform = {};
        this.errorMessage = null;
        this.isTiff = false;
        this.croppedImage = "";
        this.showCropper = false;
    }

    private _postFileData(imageToUpload: any) {
        let fd = new FormData();
        fd.append("file", imageToUpload, this.profilePicFile.name);

        this.primary.disabled = true;
        this._http
            .post("/sf/uploadFile?postFileType=photo", fd, {
                responseType: "text"
            })
            .pipe(map((response) => JSON.parse(response)))
            .subscribe(
                (response) => {
                    this.handleImageUpload(response);
                },
                () => {
                    this._growlService.error("Error in uploading image.");
                    this.primary.disabled = false;
                }
            );
    }
}
