import { Pipe, PipeTransform } from "@angular/core";
import { dayjs } from "../plugins/dayjs/index";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

@Pipe({
    name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
    // Defaults to 3-character tinezone abbreviations
    transform(
        timestamp: any,
        timezone?: string,
        format?: string,
        twoCharacterAbbrev: boolean = false
    ): string {
        let result: string;
        let resultObj = dayjs(timestamp);
        if (timezone) {
            resultObj = resultObj.tz(timezone || dayjs.tz.guess());
        }
        result = resultObj.format(format || "h:mm A z");

        if (twoCharacterAbbrev) {
            result = this._twoCharacterAbbrev(result);
        }

        return result;
    }

    // Display timezone as two characters, per Simplifile Marketing style guide.
    // Timestamp format must use 'zz'
    private _twoCharacterAbbrev(timestamp: string): string {
        return timestamp.replace(/([A-Z])([A-Z])([A-Z])$/, "$1$3");
    }
}
