import * as deepmerge from "deepmerge";
export const noop: any = () => {};

const promise: Promise<any> = (() => Promise.resolve(0))();

declare const Zone: any;

export function scheduleMicroTask(fn: Function) {
    if (typeof Zone === "undefined") {
        // use promise to schedule microTask instead of use Zone
        promise.then(() => {
            // tslint:disable-next-line:no-unused-expression
            fn && fn.apply(null, null);
        });
    } else {
        Zone.current.scheduleMicroTask("scheduleMicrotask", fn);
    }
}

export function deepMergeOverrideArrays<T1, T2>(
    target: Partial<T1>,
    source: Partial<T2>,
    options?: deepmerge.Options
): T1 & T2 {
    return deepmerge(target, source, {
        ...options,
        arrayMerge: overwriteMerge
    });
}

export function deepMergeWithArrays<T>(
    target: T,
    source: Partial<T>,
    options: any = {}
): T {
    return deepmerge(target, source, {
        ...options,
        arrayMerge: combineMerge
    });
}

// Copied from https://www.npmjs.com/package/deepmerge
const emptyTarget = (value: any) => (Array.isArray(value) ? [] : {});
const clone = (value: any, options: any) =>
    deepmerge(emptyTarget(value), value, options);

function combineMerge(target: any[], source: any[], options: any) {
    const destination = target.slice();

    source.forEach((e, i) => {
        if (typeof destination[i] === "undefined") {
            const cloneRequested = options.clone !== false;
            const shouldClone = cloneRequested && options.isMergeableObject(e);
            destination[i] = shouldClone ? clone(e, options) : e;
        } else if (options.isMergeableObject(e)) {
            destination[i] = deepmerge(target[i], e, options);
        } else if (target.indexOf(e) === -1) {
            destination.push(e);
        }
    });
    return destination;
}

function overwriteMerge(target: any[], source: any[], options: any) {
    return source;
}

export function b64ToBlob(b64String: string, type?: string): Blob {
    const byteChars = atob(b64String);
    const byteNums = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
        byteNums[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNums);
    return new Blob([byteArray], { type: type || "application/pdf" });
}

export function debounce(func: Function, wait: number, immediate = false) {
    let timeout: ReturnType<typeof setTimeout>;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        }, wait);
        if (immediate && !timeout) {
            func.apply(context, args);
        }
    };
}

export function sample(array: Array<any>): any {
    const len = array == null ? 0 : array.length;
    return len ? array[Math.floor(Math.random() * len)] : undefined;
}

export function differenceBy(
    arr1: Array<any>,
    arr2: Array<any>,
    prop: any
): Array<any> {
    let arrays = [arr1, arr2];
    return arrays.reduce((a, b) =>
        a.filter((c) => !b.some((d) => d[prop] == c[prop]))
    );
}

export function isNullOrEmpty(val: any) {
    return val === undefined || val == null || val.length <= 0;
}
