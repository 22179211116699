import { IconProp } from "@fortawesome/fontawesome-svg-core";

export function toggleActiveItemSelection(): { type: string; payload: any } {
    return {
        type: "TOGGLE_ACTIVE_ITEM_SELECTION",
        payload: undefined
    };
}

export function toggleItemSelection(itemId: string | number) {
    return {
        type: "TOGGLE_ITEM_SELECTION",
        payload: itemId
    };
}

export function toggleGroupSelection(groupId: string | number) {
    return {
        type: "TOGGLE_GROUP_SELECTION",
        payload: groupId
    };
}

export function toggleAllItemSelection(): { type: string; payload: any } {
    return {
        type: "TOGGLE_ALL_ITEM_SELECTION",
        payload: undefined
    };
}

export function toggleFilteredItemSelection(): { type: string; payload: any } {
    return {
        type: "TOGGLE_FILTERED_ITEM_SELECTION",
        payload: undefined
    };
}

export function clearSelection(): { type: string; payload: any } {
    return {
        type: "CLEAR_SELECTION",
        payload: undefined
    };
}

export function setActiveItem(itemId: string) {
    return {
        type: "SET_ACTIVE_ITEM",
        payload: itemId
    };
}

export function setItemFilter(filter: string) {
    return {
        type: "SET_ITEM_FILTER",
        payload: filter
    };
}

export function setHandleFilterExternally(handleFilterExternally: boolean) {
    return {
        type: "HANDLE_FILTER_EXTERNALLY",
        payload: handleFilterExternally
    };
}

export function setItems(items: any[]) {
    return {
        type: "SET_ITEMS",
        payload: items
    };
}

export function setSelectedItems(
    selectedItems: string | number | (string | number)[]
) {
    return {
        type: "SET_SELECTED_ITEMS",
        payload: selectedItems
    };
}

export function setIdKey(idKey: string) {
    return {
        type: "SET_ID_KEY",
        payload: idKey
    };
}

export function setGroupIdKey(groupIdKey: string) {
    return {
        type: "SET_GROUP_ID_KEY",
        payload: groupIdKey
    };
}

export function setBindAsHtml(bindAsHtml: boolean) {
    return {
        type: "SET_BIND_AS_HTML",
        payload: bindAsHtml
    };
}

export function setBottomMetadataKey(key: string) {
    return {
        type: "SET_BOTTOM_METADATA_KEY",
        payload: key
    };
}

export function setRightMetadataKey(key: string) {
    return {
        type: "SET_RIGHT_METADATA_KEY",
        payload: key
    };
}

export function setRightMetadataIsIcon(isIcon: boolean) {
    return {
        type: "SET_RIGHT_METADATA_IS_ICON",
        payload: isIcon
    };
}

export function setDisabledKey(disabledKey: string) {
    return {
        type: "SET_DISABLED_KEY",
        payload: disabledKey
    };
}

export function setLabelKey(labelKey: string) {
    return {
        type: "SET_LABEL_KEY",
        payload: labelKey
    };
}

export function setIsSingleSelect(isSingleSelect: boolean) {
    return {
        type: "SET_IS_SINGLE_SELECT",
        payload: isSingleSelect
    };
}

export function setHasFilter(hasFilter: boolean) {
    return {
        type: "SET_HAS_FILTER",
        payload: hasFilter
    };
}

export function setFilterIcon(filterIcon: IconProp) {
    return {
        type: "SET_FILTER_ICON",
        payload: filterIcon
    };
}

export function setHasFooter(hasFooter: boolean) {
    return {
        type: "SET_HAS_FOOTER",
        payload: hasFooter
    };
}

export function setHasSelectAll(hasSelectAll: boolean) {
    return {
        type: "SET_HAS_SELECT_ALL",
        payload: hasSelectAll
    };
}

export function setIsRequired(isRequired: boolean) {
    // this is to support both a 'required' attribute and a 'required="true"' attribute both when using a form control and when not using a form control
    // when just the 'required' attribute is present, isRequired comes in undefined and overwrites the default 'null' value - so if it is not null, is falsy, and is not equal to false,
    // we know the 'required' attribute is present and the isRequired state should be true
    let required =
        !!isRequired ||
        (isRequired !== null && !isRequired && isRequired !== false);
    return {
        type: "SET_IS_REQUIRED",
        payload: required
    };
}

export function setIsDirty(isDirty: boolean) {
    return {
        type: "SET_IS_DIRTY",
        payload: isDirty
    };
}

export function setIsDisabled(isDisabled: boolean) {
    return {
        type: "SET_IS_DISABLED",
        payload: isDisabled
    };
}

export function setIsReadonly(isReadonly: boolean) {
    return {
        type: "SET_IS_READONLY",
        payload: isReadonly
    };
}

export function openDropdown(): { type: string; payload: any } {
    return {
        type: "OPEN_DROPDOWN",
        payload: undefined
    };
}

export function closeDropdown(): { type: string; payload: any } {
    return {
        type: "CLOSE_DROPDOWN",
        payload: undefined
    };
}

export function toggleShowOnlySelected(): { type: string; payload: any } {
    return {
        type: "TOGGLE_SHOW_ONLY_SELECTED",
        payload: undefined
    };
}

export function setShowOnlySelected(showOnlySelected: boolean) {
    return {
        type: "SET_SHOW_ONLY_SELECTED",
        payload: showOnlySelected
    };
}

export function setShowSelectedToggle(showSelected: boolean) {
    return {
        type: "SET_SHOW_SELECTED_TOGGLE",
        payload: showSelected
    };
}

export function setAdditionalPropertiesToFilter(filterProperties: string[]) {
    return {
        type: "SET_ADDITIONAL_PROPERTIES_TO_FILTER",
        payload: filterProperties
    };
}

export function setTitleKey(titleKey: string) {
    return {
        type: "SET_TITLE_KEY",
        payload: titleKey
    };
}

export function setGroupTitleKey(groupTitleKey: string) {
    return {
        type: "SET_GROUP_TITLE_KEY",
        payload: groupTitleKey
    };
}

export function setShowIconInDisplay(showIconInDisplay: boolean) {
    return {
        type: "SET_SHOW_ICON_IN_DISPLAY",
        payload: showIconInDisplay
    };
}

export function setMaxPopupHeight(maxPopupHeight: number) {
    return {
        type: "SET_MAX_POPUP_HEIGHT",
        payload: maxPopupHeight
    };
}

export function setShowRecentSelections(showRecentSelections: boolean) {
    return {
        type: "SET_SHOW_RECENT_SELECTIONS",
        payload: showRecentSelections
    };
}

export function setRecentlySelectedItems(recentlySelectedItems: string[]) {
    return {
        type: "SET_RECENTLY_SELECTED_ITEMS",
        payload: recentlySelectedItems
    };
}

export function setShowMainListWithRecents(showMainListWithRecents: boolean) {
    return {
        type: "SET_SHOW_MAIN_LIST_WITH_RECENTS",
        payload: showMainListWithRecents
    };
}

export function setLimitDropdownItems(limitDropDownItems: number) {
    return {
        type: "SET_LIMIT_DROPDOWN_ITEMS",
        payload: limitDropDownItems
    };
}
