<div *ngIf="orgLoaded">
    <div *ngIf="noSalesTax">
        This organization does not use sales tax
    </div>
    <div *ngIf="payments">
        <form [formGroup]="salesTaxGroup">
            <fieldset>
                <div class="section">
                    <span class="title">Sales Tax Rates</span>
                    <span *ngIf="isSuperUser">
                        &nbsp;&nbsp;&nbsp;
                        <button class="btn btn-link sf-btn-link" (click)="updateSalesTax()">Update Tax Rates</button>
                        &nbsp;
                        <button class="btn btn-link sf-btn-link"
                                ngbTooltip="Tax rates are automatically updated monthly. Click
                                    'Update Tax Rates' to manually update rates for this organization."
                                placement="right" tooltipClass="ttClass">
                            <fa-icon icon="info-circle"></fa-icon>
                        </button>
                        <span *ngIf="processing">&nbsp;
                            <fa-icon icon="spinner" [spin]="true"></fa-icon>
                            <span>Updating...</span>
                        </span>
                    </span>
                    <hr/>
                    <div class="wrapper">
                        <label>
                            {{payments['Sales Tax'].show_sales_tax_rate_submission.label}}
                        </label>
                    </div>
                    <div class="wrapper">
                        <label>
                            {{payments['Sales Tax'].show_sales_tax_rate_license.label}}
                        </label>
                    </div>
                </div>
                <div class="section" *ngIf="isSuperUser">
                    <span class="title">In-Application Display Settings</span>
                    <hr/>
                    <div class="wrapper" *ngIf="payments['Sales Tax'].sales_tax_grouped.label">
                        <label>
                            <input type="checkbox" formControlName="groupOnPackage" (change)="save()"/>
                            {{payments['Sales Tax'].sales_tax_grouped.label}}
                        </label>
                    </div>
                    <div class="wrapper" *ngIf="payments['Sales Tax'].reports_group_sales_tax.label">
                        <label>
                            <input type="checkbox" formControlName="groupOnReports" (change)="save()"/>
                            {{payments['Sales Tax'].reports_group_sales_tax.label}}
                        </label>
                    </div>

                </div>
            </fieldset>
        </form>
    </div>
</div>
