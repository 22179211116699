<div #rootFieldContainer>
<sf-custom-root-field
    [ngClass]="{'two-col':rootFieldWidth > 450 && rootFieldWidth <= 700, 'three-col':rootFieldWidth > 700}"
    [formId]="formId"
    [orgID]="orgID"
    [userType]="userType"
    [onSave]="onSave.asObservable()"
    [docLeftData]="docLeftData" #fieldHost>
</sf-custom-root-field>
</div>
