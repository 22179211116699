import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, startWith, switchMap } from "rxjs/operators";
import { GlobalViewContainerService } from "@sf/common";
import { NotificationBannerComponent } from "./notification-banner.component";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class NotificationBannerService {
    constructor(
        private _router: Router,
        private _viewContainer: GlobalViewContainerService,
        private _factory: ComponentFactoryResolver
    ) {
        _router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(true),
                switchMap(() => _viewContainer.get("notificationBanner"))
            )
            .subscribe((viewContainer) => {
                if (!viewContainer.length) {
                    const factory = _factory.resolveComponentFactory(
                        NotificationBannerComponent
                    );
                    viewContainer.createComponent(factory);
                }
            });
    }
}
