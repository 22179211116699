import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { EncryptionService } from "@sf/common";

@Component({
    selector: "sf-security",
    templateUrl: "./security.component.html",
    styleUrls: ["./security.component.scss"]
})
export class SecurityComponent implements OnInit, OnDestroy {
    private _onDestroy: Subject<boolean> = new Subject<boolean>();

    userName: string = null;

    constructor(
        private _route: ActivatedRoute,
        private _encryptionService: EncryptionService
    ) {}

    ngOnInit() {
        this._route.paramMap
            .pipe(takeUntil(this._onDestroy))
            .subscribe((paramMap: ParamMap) => {
                this.userName = this._encryptionService.decodeUnscramble(
                    paramMap.get("userID")
                );
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next(true);
        this._onDestroy.complete();
    }
}
