<div *ngIf="field.label && !field.sortableTable" class="group-header">
    <h4>{{field.label}}</h4>
    <hr />
</div>
<div [ngClass]="{'form-array-fields': !field.sortableTable}" #arrayDragContainer>
    <ng-container #arrayHost></ng-container>
</div>
<ng-container *ngIf="!field.sortableTable">
<div class="actions" [ngClass]="">
    <button class="btn btn-primary" (click)="addItem()" *ngIf="!(disabled$ | async)">Add Item</button>
</div>
</ng-container>
