import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "@sf/common";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType,
    OrganizationSuspensionService,
    PaymentAccountsService,
    SuspendedService,
    UserOrgService
} from "@sf/userorg/common";
import { first } from "rxjs/operators";

/**
 * This guard loads when the Submitter module loads.
 * It will add any banners that are specific to the Submitter service that need to be displayed for the user.
 */
// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class SubmitterNotificationBannerGuard implements CanActivate {
    bannerCategoryChecked$: Observable<BannerNotificationCategory>;
    bannerRecheckCategory$: Observable<BannerNotificationCategory>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userorgService: UserOrgService,
        private bannerNotificationService: BannerNotificationService,
        private sessionService: SessionService,
        private orgSuspensionService: OrganizationSuspensionService,
        private paymentService: PaymentAccountsService,
    ) {
        this.bannerCategoryChecked$ =
            this.bannerNotificationService.bannerCategoryChecked;
        this.bannerCategoryChecked$.subscribe((category) => {
            this.categoryWasChecked(category);
        });

        this.bannerRecheckCategory$ = this.bannerNotificationService.bannerRecheckQueue;
        this.bannerRecheckCategory$.subscribe((category) => {
            this.recheckCategory(category);
        });
    }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): |Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        this.checkPendingPayments();
        return true;
    }

    recheckCategory(category: BannerNotificationCategory) {
        switch (category) {
            case BannerNotificationCategory.PENDING_PAYMENTS:
                this.checkPendingPayments(true);
                break;
            default:
                break;
        }
    }

    categoryWasChecked(category: BannerNotificationCategory) {
        // don't care
    }

    checkPendingPayments(clearCache?: boolean) {
        this.bannerNotificationService.removeBannerOfCategory(BannerNotificationCategory.PENDING_PAYMENTS);

        let organizationIDs: string[] = this.sessionService.getOrganizationIDsWithPermission(
            "organization_payment_approval");
        if (organizationIDs.length == 0) {
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.PENDING_PAYMENTS);
            return;
        }

        // this.submitterPaymentService
        //     .hasEcheckBankAccount(organizationIDs)
        //     .then((results: string[]) => {
        let orgIDs: string[] = organizationIDs;
        this.orgSuspensionService.isAnyServiceSuspended()
            .pipe(first())
            .subscribe((suspensions: SuspendedService) => {
                // we don't show this banner if there is a suspended service
                if (orgIDs.length > 0 && !suspensions) {
                    // this is being called once a pending payment has been approved
                    this.paymentService
                        .getPendingPaymentCounts(orgIDs, clearCache)
                        .subscribe((countInfo: any) => {
                            if (countInfo.isDelinquent) {
                                let delinquentPaymentsLabel = "You have past due payments. " +
                                    "Click here to review and approve pending payments to avoid service disruption.";
                                let paymentUrl = "/submitter/payments/pending-payments";

                                this.bannerNotificationService.push({
                                    type: BannerNotificationType.DANGER,
                                    category: BannerNotificationCategory.PENDING_PAYMENTS,
                                    message: delinquentPaymentsLabel,
                                    destinationUrl: paymentUrl,
                                    clickHandler: () => {
                                        this.router.navigate([paymentUrl], {
                                            relativeTo: this.route
                                        });
                                    },
                                    closeHandler: () => {
                                    }
                                });
                            }
                            this.bannerNotificationService.markCategoryChecked(
                                BannerNotificationCategory.PENDING_PAYMENTS);
                        });
                } else {
                    this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.PENDING_PAYMENTS);
                }
            });
        // });
    }
}
