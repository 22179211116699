import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErecordHighlightService } from "./services/erecord-highlight.service";
import { GroupBarComponent } from "./components/group-bar/group-bar.component";
import { DocLeftEventsService } from "./services/doc-left-events.service";

@NgModule({
    declarations: [GroupBarComponent],
    imports: [CommonModule],
    providers: [ErecordHighlightService, DocLeftEventsService],
    exports: [GroupBarComponent]
})
export class ErecordViewerCoreModule {}
