import { Component, Input, OnInit } from "@angular/core";
import {
    ContractExtended,
    ContractFee,
    ContractFeeCalcType,
    ContractFeeType,
    ContractService
} from "@sf/userorg/common";
import { ModalButton } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { formatCurrency } from "@angular/common";
import { SubmitterOrganizationService } from "@sf/submitter/common";

interface FormattedFee {
    name: string;
    amount: string;
}

interface LocalName {
    firstName: string;
    lastName: string;
    userId: string;
    email: string;
}

// prettier-ignore
@Component({
    selector: "sf-contract-details-dialog",
    templateUrl: "./contract-details-dialog.component.html",
    styleUrls: ["./contract-details-dialog.component.scss"]
})
export class ContractDetailsDialogComponent implements OnInit {
    @Input() contract: ContractExtended;
    @Input() orgID: string;

    primary: ModalButton;
    fees: FormattedFee[];
    names: LocalName[];

    constructor(
            private modalService: NgbModal,
            private contractService: ContractService,
            private activeModal: NgbActiveModal,
            private submitterOrganizationService: SubmitterOrganizationService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        // get list of people requested to sign
        if (this.contract.pendingApproval && this.orgID) {
            this.contractService.getContractSignerNames(this.orgID, this.contract.contractType)
                .subscribe((names: any[]) => {
                    this.names = names;
                });
        }

        this.processFees();
    }

    processFees() {
        if (this.contract.contractFees) {
            this.fees = [];
            let keys: string[] = Object.keys(this.contract.contractFees);
            keys.forEach((keyString) => {
                let key: ContractFeeType = keyString as ContractFeeType;
                let feeObj = this.contract?.contractFees[key];
                let feeAmountNumber = this.getFeeAmountNumber(feeObj);
                if (this.isVisibleFee(feeObj.feeType, feeAmountNumber)) {
                    let feeName = this.buildFeeName(feeObj.feeType);
                    let feeAmount = this.formatAmount(feeObj, feeAmountNumber);
                    let formattedFee = {
                        name: feeName,
                        amount: feeAmount
                    };
                    this.fees.push(formattedFee);
                }
            });
        }
    }

    getFeeAmountNumber(feeObj: ContractFee): number {
        if (!feeObj.contractFeeTiers[0] || !feeObj.contractFeeTiers[0].feeAmount) {
            return null;
        }

        let amount: string = (feeObj.contractFeeTiers[0].feeAmount).toString();
        let numbers = amount.replace(/\$/g,'');
        numbers = numbers.replace(/%/g,'');
        if (!numbers) {
            return null;
        }

        let realNumber: number = parseFloat(numbers);
        return realNumber;
    }

    formatAmount(feeObj: ContractFee, realNumber: number): string {
        if (feeObj.feeCalcType == ContractFeeCalcType.PERCENTAGE) {
            return "" + realNumber + "%";
        }

        let places = 2;
        if (feeObj.feeType == ContractFeeType.RECIPIENT_VENDOR_REVSHARE ||
                feeObj.feeType == ContractFeeType.LENDER_VENDOR_REVSHARE ||
                feeObj.feeType == ContractFeeType.NOTARY_VENDOR_REVSHARE ||
                feeObj.feeType == ContractFeeType.ESIGN_EVENTS_VENDOR_REVSHARE ||
                feeObj.feeType == ContractFeeType.SUBMITTER_VENDOR_REVSHARE ||
                feeObj.feeType == ContractFeeType.RECIPIENT_VENDOR_EXCLUSIVE_REVSHARE) {
            places = 3;
        }
        let digitsInfo = "1.2-" + places;
        let moneyPart = formatCurrency(realNumber, "en-US", "$", "USD", digitsInfo);
        let postPart = this.buildPost(feeObj.feeType);
        return moneyPart + " " + postPart;
    }

    buildPost(feeType: ContractFeeType): string {
        let text: string = "";
        switch (feeType) {
            case ContractFeeType.RENEWAL_FEE:
            case ContractFeeType.SETTLEMENT_RENEWAL_FEE:
            case ContractFeeType.LENDER_ANNUAL_FEE:
            case ContractFeeType.DOCUMENT_BUILDER_RENEWAL_FEE:
            case ContractFeeType.ESE_RENEWAL_FEE:
                if (this.contract.renewalFrequency && this.contract.renewalFrequency == "MONTHLY") {
                    text = "per month";
                } else {
                    text = "per year";
                }
                break;
            case ContractFeeType.SUBMISSION_FEE:
            case ContractFeeType.RECORDING_FEE:
            case ContractFeeType.PAPER_RECORDING_FEE:
            case ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE:
            case ContractFeeType.INSTATE_ERECORD_SUBMISSION_FEE:
            case ContractFeeType.OUTOFSTATE_ERECORD_SUBMISSION_FEE:
            case ContractFeeType.INSTATE_PAPER_SUBMISSION_FEE:
            case ContractFeeType.OUTOFSTATE_PAPER_SUBMISSION_FEE:
            case ContractFeeType.EMBEDDED_EMPLOYEE_FEE:
            case ContractFeeType.PAPER_SUBMISSION_FEE:
                if (this.contract.perPackageFees) {
                    text = "per package";
                } else {
                    text = "per document";
                }
                break;
            case ContractFeeType.PAPER_MAIL_FEE:
                text = "per document";
                break;
            default:
                break;
        }
        return text;
    }

    buildFeeName(feeType: ContractFeeType): string {
        switch (feeType) {
            case ContractFeeType.DISBURSEMENT_FEE:
                return "Disbursement Fee";
            case ContractFeeType.LICENSE_FEE:
            case ContractFeeType.DOCUMENT_BUILDER_LICENSE_FEE:
            case ContractFeeType.ESE_LICENSE_FEE:
                return "Initial License Fee";
            case ContractFeeType.RENEWAL_FEE:
            case ContractFeeType.SETTLEMENT_RENEWAL_FEE:
            case ContractFeeType.LENDER_ANNUAL_FEE:
            case ContractFeeType.DOCUMENT_BUILDER_RENEWAL_FEE:
            case ContractFeeType.ESE_RENEWAL_FEE:
                return "Renewal License Fee";
            case ContractFeeType.SUBMISSION_FEE:
                return "Submission Fee";
            case ContractFeeType.PAPER_SUBMISSION_FEE:
                return "Paper Submission Fee";
            case ContractFeeType.NFS_RETRY_FEE:
                return "NSF Retry Fee";
            case ContractFeeType.RECORDING_FEE:
            case ContractFeeType.PAPER_RECORDING_FEE:
                return "Recording Fee";
            case ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE:
                return "Exclusive Submission Fee";
            case ContractFeeType.LENDER_LOAN_CREATE_FEE:
            case ContractFeeType.SETTLEMENT_LOAN_CREATE_FEE:
                return "Loan Creation Fee";
            case ContractFeeType.LENDER_LOAN_ACCEPT_FEE:
            case ContractFeeType.SETTLEMENT_LOAN_ACCEPT_FEE:
                return "Loan Accept Fee";
            case ContractFeeType.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE:
                return "Verified without Secure Disbursement Fee";
            case ContractFeeType.REVSHARE_FEE:
                return "Revenue Share Fee";
            case ContractFeeType.DOCUMENT_BUILDER_DOCUMENT_FEE:
            case ContractFeeType.TRUSTEE_DOCUMENT_FEE:
                return "Document Fee";
            case ContractFeeType.DOCUMENT_BUILDER_FILE_TRANSFER_FEE:
                return "File Transfer Fee";
            case ContractFeeType.LENDER_VENDOR_REVSHARE:
            case ContractFeeType.NOTARY_VENDOR_REVSHARE:
            case ContractFeeType.ESIGN_EVENTS_VENDOR_REVSHARE:
            case ContractFeeType.SUBMITTER_VENDOR_REVSHARE:
            case ContractFeeType.RECIPIENT_VENDOR_REVSHARE:
                return "Revenue Share";
            case ContractFeeType.RECIPIENT_VENDOR_EXCLUSIVE_REVSHARE:
                return "Exclusive Revenue Share";
            case ContractFeeType.ESE_NO_ENOTARIZATION:
                return "No eNotarization Fee";
            case ContractFeeType.ESE_IPEN:
                return "IPEN Fee";
            case ContractFeeType.ESE_RON:
            case ContractFeeType.DOCUMENT_BUILDER_RON_FEE:
                return "RON Fee";
            case ContractFeeType.INSTATE_ERECORD_SUBMISSION_FEE:
                return "In-State eRecord Fee";
            case ContractFeeType.OUTOFSTATE_ERECORD_SUBMISSION_FEE:
                return "Out-of-State eRecord Fee";
            case ContractFeeType.INSTATE_PAPER_SUBMISSION_FEE:
                return "In-State Paper Fee";
            case ContractFeeType.OUTOFSTATE_PAPER_SUBMISSION_FEE:
                return "Out-of-State Paper Fee";
            case ContractFeeType.PAPER_MAIL_FEE:
                return "Paper Mail Fee";
            case ContractFeeType.EMBEDDED_EMPLOYEE_FEE:
                return "Embedded Employee Fee";
            case ContractFeeType.LIEN_RELEASE_FEE:
                return "Automated Document Request Fee";
            case ContractFeeType.SUBSCRIPTION_MINIMUM:
                return "Monthly Subscription Minimum";
            default:
                break;
        }

        return feeType as string;
    }

    isVisibleFee(feeType: ContractFeeType, amountNumber: number): boolean {
        // we only potentially hide fees if they are zero
        if (!amountNumber) {
            if (feeType == ContractFeeType.PAPER_SUBMISSION_FEE) {
                // old paper submission fee on new contract
                return false;
            }
            if (feeType == ContractFeeType.DOCUMENT_BUILDER_FILE_TRANSFER_FEE) {
                // new fee on old contract
                return false;
            }
            if (feeType == ContractFeeType.RECORDING_FEE) {
                // new fee on old contract
                return false;
            }
            if (feeType == ContractFeeType.PAPER_RECORDING_FEE) {
                // new fee on old contract
                return false;
            }
        }

        return true;
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
