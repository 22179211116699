import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-phone-support-question-dialog",
    templateUrl: "./phone-support-question-dialog.component.html",
    styleUrls: ["./phone-support-question-dialog.component.scss"]
})
export class PhoneSupportQuestionDialogComponent implements OnInit {
    @Input()
    supportQuestion: string;
    @Input()
    supportAnswer: string;

    title: string = "Confirm Phone Support Question";
    primary: ModalButton;
    secondary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        //define buttons
        this.primary = {
            text: "OK",
            callback: this.close.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            callback: this.cancel.bind(this)
        };
    }

    cancel(): void {
        this.activeModal.dismiss();
    }

    close(): void {
        this.activeModal.close(true);
    }
}
