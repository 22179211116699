import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

// prettier-ignore
@Pipe({
    name: "highlight"
})
export class HighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, args: any): any {
        if (typeof value != "string") {
            return value;
        }

        // deal with any existing html
        value = value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                     .replace(/"/g, "&quot;").replace(/'/g, "&#039;");

        if (typeof args != "string" || !args) {
            return value;
        }

        args = this.escapeRegExp(args);

        // look for match
        const re = new RegExp(args, "gi");
        let match = value.match(re);

        if (!match) {
            return value;
        }

        // there may be multiple matches with mixed case
        // for example: when searching for 'r' in 'Ray Lardie', there is an upper-case match and lower-case match
        // We'll only highlight one of the matches. It looks more natural that way, and not so complicated.
        let newValue = value;
        let newArgs = this.escapeRegExp(match[0]);
        let re2 = new RegExp(newArgs, "g");
        newValue = newValue.replace(re2, "<mark>" + match[0] + "</mark>");

        let sanitized = this.sanitizer.bypassSecurityTrustHtml(newValue);

        return sanitized;
    }

    escapeRegExp(text: string): string {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }
}
