import { Injectable } from "@angular/core";
import { Organization } from "@sf/userorg/common";

export interface HierarchyOrganization extends Organization {
    childIDs: string[];
}

interface TreeThing {
    organization: Partial<Organization>;
    children: TreeThing[];
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationHierarchyHelperService {
    sortKey: string;
    sortAscending: boolean;
    newList: Organization[];

    constructor() {}

    // order a list of organizations by hierarchy
    buildTrees(
        allOrganizationData: HierarchyOrganization[],
        sortKey: string,
        sortAscending: boolean
    ): Organization[] {
        let roots: TreeThing[] = [];

        this.newList = [];
        this.sortKey = sortKey;
        this.sortAscending = sortAscending;

        // put into tree
        if (allOrganizationData) {
            allOrganizationData.forEach((org: HierarchyOrganization) => {
                let me = this.findInTree(roots, org.id);
                if (!me) {
                    me = this.createEntry(org.id);
                    roots.push(me);
                }
                me.organization = org;
                if (org.childIDs) {
                    for (
                        let iChild = 0;
                        iChild < org.childIDs.length;
                        iChild++
                    ) {
                        let childID = org.childIDs[iChild];
                        let child = this.findInRoot(roots, childID);
                        if (child) {
                            this.removeRoot(roots, childID);
                            me.children.push(child);
                        } else {
                            child = this.findInTree(roots, childID);
                            if (!child) {
                                child = this.createEntry(childID);
                                me.children.push(child);
                            } else {
                                // it's fine where it is
                            }
                        }
                    }
                }
            });
        }

        // build newList
        this.depthFirst(roots, 0);

        return this.newList;
    }

    createEntry(orgID: string) {
        let obj: TreeThing = {
            organization: { id: orgID },
            children: []
        };
        return obj;
    }

    findInTree(roots: TreeThing[], orgID: string): TreeThing {
        let entry = null;
        for (let i = 0; i < roots.length; i++) {
            entry = roots[i];
            if (entry.organization.id == orgID) return entry;
            if (entry.children) {
                entry = this.findInTree(entry.children, orgID);
                if (entry) return entry;
            }
        }
        return null;
    }

    findInRoot(roots: TreeThing[], orgID: string): TreeThing {
        let entry = null;
        for (let i = 0; i < roots.length; i++) {
            entry = roots[i];
            if (entry.organization.id == orgID) return entry;
        }
        return null;
    }

    removeRoot(roots: TreeThing[], orgID: string) {
        let index = -1;
        let entry;
        for (let i = 0; i < roots.length; i++) {
            entry = roots[i];
            if (entry.organization.id == orgID) index = i;
        }
        if (index != -1) roots.splice(index, 1);
    }

    sortSiblings(list: TreeThing[]) {
        if (!list) {
            return null;
        }
        list = list.sort((a, b) => {
            let aorg = a.organization;
            let borg = b.organization;
            let aorgAny: any = aorg;
            let borgAny: any = borg;
            let avalue: string = aorgAny[this.sortKey];
            let bvalue: string = borgAny[this.sortKey];
            if (!avalue) {
                avalue = "";
            }
            if (!bvalue) {
                bvalue = "";
            }
            let comp: number = 0;
            if ((typeof avalue == "string") && (typeof bvalue == "string")) {
                let alower = avalue.toLowerCase();
                comp = alower.localeCompare(bvalue.toLowerCase());
            } else if ((typeof avalue == "number") && (typeof bvalue == "number")) {
                let anumber: number = avalue as number;
                let bnumber: number = bvalue as number;
                comp = anumber - bnumber;
            } else {
                comp = 0;
            }
            if (!this.sortAscending) {
                return -comp;
            }
            return comp;
        });
        return list;
    }

    depthFirst(children: TreeThing[], level: number) {
        let entry;
        children = this.sortSiblings(children);
        for (let i = 0; i < children.length; i++) {
            entry = children[i];
            if (
                entry.organization &&
                (entry.organization.name || entry.children.length)
            ) {
                this.newList.push(entry.organization as Organization);
                entry.organization.indentLevel = level;
            }
            if (entry.children && entry.children.length)
                this.depthFirst(entry.children, level + 1);
        }
    }
}
