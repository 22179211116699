import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./select.component";
import { SfSelectMaintainPositionDirective } from "./common/sf-select-maintain-position.directive";
import { SfSelectCloseOnOffClickDirective } from "./common/sf-select-close-on-off-click.directive";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SfCommonModule } from "../common.module";
import { SfSelectTestComponent } from "./testing/sf-select-test/sf-select-test.component";

@NgModule({
    declarations: [
        SelectComponent,
        SfSelectMaintainPositionDirective,
        SfSelectCloseOnOffClickDirective,
        SfSelectTestComponent
    ],
    imports: [CommonModule, FontAwesomeModule, SfCommonModule],
    exports: [SelectComponent]
})
export class SelectModule {}
