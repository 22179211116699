<div>Legal entity type</div>
<div class="select-div">
    <sf-select [options]="entityTypes" trackBy="id" labelBy="id"
               (select)="updateEntityType($event.$selection?.id);" placeholder="None"
               [hasClearButton]="true" [isSingleSelect]="true" [selectedOption]="entityType">
    </sf-select>
</div>
<div>State where legal entity was organized/created</div>
<div class="select-div">
    <sf-select [options]="states" trackBy="abbrev" labelBy="name"
           (select)="updateEntityState($event.$selection?.abbrev);" placeholder="None"
           [hasClearButton]="true" [isSingleSelect]="true" [selectedOption]="entityState">
    </sf-select>
</div>
