<sf-modal-header title="Unarchive User"></sf-modal-header>
<div class="modal-body">
<div class="extra-bottom">
        <span>Are you sure you want to unarchive this user?</span>
    </div>
    <div class="extra-bottom">
        <b>{{displayName}}</b>
    </div>
    <div *ngIf="emailShared" class="extra-bottom">
        <span class="notice">User's email address: {{user.email}}, is shared with another user.
            If you continue, you should make the email addresses unique.</span>
    </div>
    <div class="extra-bottom">
        <span>Please note: After you unarchive this user, you will need to set up user roles for them and reset their password.</span>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
