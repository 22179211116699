import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ChatUploadService {
    constructor(private http: HttpClient) {}

    addAttachmentsRCAPI(
        emailId: string,
        caseId: string,
        caseAttachments: File[]
    ): Observable<any> {
        const path = "/sf/userorg/RCAPIAddAttachments";

        const metadata = {
            EmailId: emailId,
            LinkedEntityId: caseId,
            SourceName: "Simplifile"
        };
        const formData = new FormData();
        formData.append("metadata", JSON.stringify(metadata));
        for (let i = 0; i < caseAttachments.length; i++) {
            formData.append(
                "Files",
                caseAttachments[i],
                caseAttachments[i].name
            );
        }

        return this.http.post<any>(path, formData).pipe((res) => {
            return res;
        });
    }
}
