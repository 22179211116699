import { Component, Input } from "@angular/core";
import { PageData } from "../../interfaces/page-data.interface";
import { PageHeaderComponent } from "../../interfaces/page-header-component.interface";

@Component({
    selector: "sf-simple-header",
    templateUrl: "./simple-header.component.html",
    styleUrls: ["./simple-header.component.scss"]
})
export class SimpleHeaderComponent implements PageHeaderComponent {
    /* Public Variables */
    @Input() pageData: PageData;

    constructor() {}
}
