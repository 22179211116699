import { Injectable } from "@angular/core";
import { ICONS } from "./icons.constant";
import { camelCase } from "../helpers/string";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

/**
 * @deprecated You no longer need to use this service to get access to icons. Pass the icon params into fa-icon. e.g., `<fa-icon [icon]="['far', 'arrow-circle-left']"></fa-icon>`
 */
@Injectable({
    providedIn: "root"
})
export class IconsService {
    constructor() {}

    /**
     * @deprecated You no longer need to use this service to get access to icons. Pass the icon params into fa-icon. e.g., `<fa-icon [icon]="['far', 'arrow-circle-left']"></fa-icon>`
     */
    get(name: string): IconDefinition {
        if (!ICONS[camelCase(name)]) {
            throw new Error(
                `The provided icon (${name}) does not exist. Please add it to the icon set to use.`
            );
        }
        return ICONS[camelCase(name)];
    }
}
