import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "orgSelector"
})
export class OrgSelectorPipe implements PipeTransform {
    transform(
        input: {
            name?: string;
            label: string;
            id?: string;
            option: string;
            thisUser?: boolean;
        }[],
        search?: { text: string }
    ): any {
        if (!input) return input; // no collection of items, so don't filter an empty list

        let out: any[] = []; // array of new items to return
        if (!search || !search.text || !search.text.length) {
            // no filter text, so show all orgs for user
            input.forEach((item) => {
                // iterate the items
                if (item.thisUser) {
                    out.push(item); // matched add back to the list
                }
            });
        } else {
            let escaped = search.text.replace(/[[\]{}()*+?\\^$|#]/g, "\\$&");
            let ri = new RegExp(escaped, "i"); // get the regex ready - ignore case.
            input.forEach((item) => {
                // iterate the items
                let data =
                    (item.name ? item.name : item.label) +
                    (item.id ? item.id : item.option);
                if (ri.test(data)) {
                    // search on name and/or ID
                    out.push(item); // matched add back to the list
                }
            });
        }
        return out; // return the filtered array
    }
}
