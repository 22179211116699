import { Component, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-print-license-dialog",
    templateUrl: "./print-license-dialog.component.html",
    styleUrls: ["./print-license-dialog.component.scss"]
})
export class PrintLicenseDialogComponent implements OnInit {
    primary: ModalButton;
    secondary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Print",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.doCancel.bind(this)
        };
    }

    doPrimary() {
        this.activeModal.close(true);
    }

    doCancel() {
        this.activeModal.close(false);
    }
}
