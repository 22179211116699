import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class AcceptLicenseService {
    constructor(private _rpcClient: RpcClientService) {}

    acceptContract(
        organizationID: string,
        documentID: string,
        addendumDocumentID: string
    ) {
        return this._rpcClient.makeRequest("esign", "acceptContract", {
            organizationID: organizationID,
            documentID: documentID,
            addendumDocumentID: addendumDocumentID
        });
    }

    getSignContractInfo(contractID: string) {
        return this._rpcClient.makeRequest("esign", "getSignContractInfo", {
            contractId: contractID
        });
    }

    getWetSignContractInfo(contractID: string, addendumID: string) {
        return this._rpcClient.makeRequest("esign", "getWetSignContractInfo", {
            contractId: contractID,
            addendumId: addendumID
        });
    }
}
