<div
    class="global-search__input-wrapper"
    [formGroup]="searchForm"
    sfClickOff
    (onClickOff)="clickAway()"
    (keydown)="handleKeydown($event)"
>
    <input
        #globalsearch__input
        class="search-input form-control"
        formControlName="searchValue"
        type="text"
        maxlength="255"
        ngbTooltip="Type here to find packages based on names, IDs, recording data, and other related fields."
        triggers="hover"
        placement="bottom"
        placeholder="{{ placeholder }}"
        focus="onFocus()"
        blur="onBlur($event)"
    />
    <fa-icon
        *ngIf="!hideMagnifyingGlass"
        icon="search"
        [hidden]="searchForm.get('searchValue').value"
    ></fa-icon>
    <fa-icon icon="spinner" [hidden]="!searching">
        <ng-template let-icons>
            <fa-icon [icon]="icons.spinner" [spin]="true"></fa-icon>
        </ng-template>
    </fa-icon>
</div>
<div class="global-search__results-container" *ngIf="resultsShouldBeOpen">
    <header class="packages-header">
        Packages
        <span class="badge countBadge"
            >{{ searchResults.length
            }}{{ hasMoreSearchResults ? "+" : "" }}</span
        >
    </header>
    <ul class="search-results">
        <li *ngIf="noResultsFound" class="no-results">
            No packages found for "{{ previousSearch }}"
        </li>
        <li
            #globalsearch__results
            *ngFor="let package of searchResults; let i = index"
            class="package-result"
            [ngClass]="{ selected: i === focusedIndex }"
            (click)="searchResultSelect(package, $event)"
        >
            <a
                [routerLink]="['/submitter/package', package.id, 'details']"
                class="text-decoration-none"
            >
                <div class="package-data">
                    <span class="package-name btn-link">{{
                        package.name || "Untitled Package"
                    }}</span>
                    <small
                        ><strong>Submitter:</strong>
                        {{ package.submitter }}</small
                    >
                    <small
                        ><strong>Recipient:</strong>
                        {{ package.recipientNameWithState }}</small
                    >
                    <small
                        ><strong>Documents:</strong>
                        {{ package.documents.length }}</small
                    >
                </div>
            </a>
            <sf-pill
                role="button"
                class="sf-pill package-status"
                (click)="openPackageStatusDialog(package)"
                value="{{ package | sfStatus }}"
            >
                {{ package | sfStatus | titlecase }}
            </sf-pill>
        </li>
    </ul>
</div>
