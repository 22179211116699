import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { InputComponent } from "../../components";
import { FieldState } from "../../interfaces";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { COMPONENT_FROM_FIELD } from "../../helpers/fields.helpers";

import { filter, pluck, scan, startWith, takeUntil } from "rxjs/operators";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { ArrayFieldItemWrapperComponent } from "../array-field-item-wrapper/array-field-item-wrapper.component";
import { SortableFieldComponent } from "../sortable-field/sortable-field.component";
import { Plugins, Sortable, SortableSortedEvent } from "@shopify/draggable";

@Component({
    selector: "sf-array-field",
    templateUrl: "./array-field.component.html",
    styleUrls: ["./array-field.component.scss"]
})
export class ArrayFieldComponent
    extends InputComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    private _destroy$ = new Subject<void>();

    collapsedMessageDisplay: string = "";

    @Input() useColumnLayout: boolean;
    @ViewChild("arrayHost", { read: ViewContainerRef })
    arrayHost: ViewContainerRef;
    @ViewChild("arrayDragContainer") arrayDragContainer: ElementRef;
    fields$: Observable<FieldState[]>;

    disabled$: Observable<boolean>;

    arrayFields = new BehaviorSubject<any>(undefined);

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit() {
        super.ngOnInit();

        this.disabled$ = this.formControl$.pipe(
            takeUntil(this._destroy$),
            filter((control) => !!control),
            pluck("disabled"),
            startWith(true)
        );
    }

    ngAfterViewInit() {
        if (this.field.sortableTable) {
            this._createSortableTable(this.field);
        } else {
            this._formFacade
                .getFieldsForArray(this.field.fullPath)
                .pipe(
                    scan(
                        (previous, curr) => [
                            previous[1].map((field) => field.id),
                            curr
                        ],
                        [[], []]
                    ),
                    takeUntil(this._destroy$)
                )
                .subscribe(([previous, current]) => {
                    this.arrayFields.next(current);
                    this.loadComponents(current, previous);
                });
        }

        const sortable = new Sortable(this.arrayDragContainer.nativeElement, {
            draggable: "sf-array-field-item-wrapper",
            handle: ".drag-handle",
            sortAnimation: {
                duration: 200,
                easingFunction: "ease-in-out"
            },
            plugins: [Plugins.SortAnimation],
            mirror: {
                constrainDimensions: true,
                xAxis: false
            }
        });

        sortable.on("sortable:stop", (sortedEvent: SortableSortedEvent) => {
            const movedField =
                this.arrayFields.getValue()[sortedEvent.oldIndex];

            this._facade.updateFieldPositionInArray(
                movedField.fullPath,
                movedField.fullPath,
                sortedEvent.newIndex
            );
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        let nextIndex = 0;
        let currentFields = [];

        for (let field of controls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                this._createNewComponent(field, nextIndex);
            }
            nextIndex++;
        }

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this.arrayHost.remove(index);
                previous.splice(index, 1);
            }
        }
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                ArrayFieldItemWrapperComponent
            );

        const component = this.arrayHost.createComponent(
            componentFactory,
            index
        );
        const instance = component.instance;

        instance.field = field;
        instance.controlPath = field.fullPath;
        instance.disabled$ = this.disabled$;
    }

    addItem() {
        this._formFacade.addFieldToArray(this.field.fullPath, this.controlPath);
    }

    private _createSortableTable(field: FieldState) {
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                SortableFieldComponent
            );

        const component = this.arrayHost.createComponent(componentFactory);
        const instance = component.instance;
        instance.useColumnLayout = this.useColumnLayout;
        instance.field = field;
        instance.controlPath = field.fullPath;
        instance.disabled$ = this.disabled$;

        component.changeDetectorRef.detectChanges();
    }
}
