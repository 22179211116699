import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-accept-license-dialog",
    templateUrl: "./accept-license-dialog.component.html",
    styleUrls: ["./accept-license-dialog.component.scss"]
})
export class AcceptLicenseDialogComponent implements OnInit {
    acceptForm: FormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    messages: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal
    ) {
        this.acceptForm = formBuilder.group({
            documentWasRead: [false]
        });
    }

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Confirm",
            disabled: true,
            callback: this.acceptInvitation.bind(this)
        };
        this.secondary = {
            text: "Take me back",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.acceptForm.get("documentWasRead").valueChanges.subscribe((val) => {
            let documentWasRead = val;
            this.primary.disabled = !documentWasRead;
        });
    }

    acceptInvitation() {
        this.activeModal.close(true);
    }

    abort() {
        this.activeModal.close(false);
    }
}
