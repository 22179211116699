import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeeDetailsModalComponent } from "./fees/fee-details-modal/fee-details-modal.component";
import { ModalsModule, SfCommonModule, SfFormsModule } from "@sf/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RejectionReasonsComponent } from "./rejection-reasons/rejection-reasons.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PackageStatusModalComponent } from "./package-status/package-status-modal.component";
import { TrayappPrintDialogComponent } from "./print/trayapp-print-dialog.component";
import { StatusIconComponent } from "./status-icon/status-icon.component";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        SfCommonModule,
        ModalsModule,
        SfFormsModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        FontAwesomeModule,
        NgbTooltipModule
    ],
    declarations: [
        FeeDetailsModalComponent,
        RejectionReasonsComponent,
        PackageStatusModalComponent,
        TrayappPrintDialogComponent,
        StatusIconComponent
    ],
    exports: [RejectionReasonsComponent, StatusIconComponent]
})
export class ErecordModule {}
