export const valueMatchesCondition = (
    value: number,
    condition: string
): boolean => {
    const GT = ">";
    const GTE = ">=";
    const LT = "<";
    const LTE = "<=";

    const GT_index = condition.indexOf(GT);
    const GTE_index = condition.indexOf(GTE);
    const LT_index = condition.indexOf(LT);
    const LTE_index = condition.indexOf(LTE);

    let rightSide;
    if (GTE_index > -1) {
        rightSide = parseInt(condition.substr(GTE.length));
        return value >= rightSide;
    } else if (GT_index > -1) {
        rightSide = parseInt(condition.substr(GT.length));
        return value > rightSide;
    } else if (LTE_index > -1) {
        rightSide = parseInt(condition.substr(LTE.length));
        return value <= rightSide;
    } else if (LT_index > -1) {
        rightSide = parseInt(condition.substr(LT.length));
        return value < rightSide;
    }
};
