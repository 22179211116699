import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { dayjs, GrowlService, ModalButton } from "@sf/common";
import { LicenseUploadData } from "@sf/userorg/main";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-name-change-upload-dialog",
    templateUrl: "./name-change-upload-dialog.component.html",
    styleUrls: ["./name-change-upload-dialog.component.scss"]
})
export class NameChangeUploadDialogComponent implements OnInit {
    @Input() orgID: string;

    maxSignDate = dayjs();
    primary: ModalButton;
    secondary: ModalButton;
    title = "Name Change Amendment Upload";

    dialogData: LicenseUploadData = {
        executedDate: null,
        signatureName: null,
        signatureTitle: null,
        file: null
    };

    @ViewChild("file") file: any;

    constructor(
        private activeModal: NgbActiveModal,
        private growlService: GrowlService
    ) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Upload",
            disabled: false,
            callback: this.ok.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };
    }

    openFileExplorer() {
        this.file.nativeElement.click();
    }

    onFileAdded() {
        let files = Array.from(this.file.nativeElement.files);
        if (!files || !files.length) {
            return; // probably they had previously selected a file, and now they canceled
        }
        // only one file should be selected - but just in case, take the last one
        let file: File = null;
        files.forEach((fileItem: File) => {
            file = fileItem;
        });

        // check file extension
        let filename = file.name;
        let fileExtPattern = /\.[0-9a-z]+$/i;
        let fileExt = fileExtPattern.exec(file.name);
        let invalidExtension = (!fileExt);
        if (fileExt) {
            let lowerExt = fileExt.toString().toLowerCase();
            if (!lowerExt.includes(".pdf") && !lowerExt.includes(".tif") && !lowerExt.includes(".tiff")) {
                invalidExtension = true;
            }
        }
        if (invalidExtension) {
            this.growlService.error("Only PDF and TIF files are allowed.");
            return;
        }

        this.dialogData.file = file;
    }

    validateInputs() {
        let message = null;

        if (!this.dialogData.file) {
            message = "Select a file to upload";
        }
        if (
            !this.dialogData.signatureName ||
            this.dialogData.signatureName.trim().length == 0
        ) {
            message = "Signer Name is required";
        }
        if (!this.dialogData.executedDate) {
            message = "Signature Date is required";
        }

        if (!message) {
            if (!this.maxSignDate.isSameOrAfter(this.dialogData.executedDate)) {
                message = "Signature date cannot be in the future";
            }
        }

        return message;
    }

    ok() {
        let message = this.validateInputs();
        if (message) {
            this.growlService.error(message);
            return;
        }

        this.activeModal.close(this.dialogData);
    }

    cancel() {
        this.activeModal.close(null);
    }
}
