/*
    Attempts to get the user's operating system from the user-agent
 */

export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "MacOS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
}

/**
 * Determines whether local storage can be used
 */
export function localStorageAvailable() {
    /* private browsing mode may disable localStorage */
    let test = "__test__";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export function parents(el: Node, selector: string = undefined) {
    const parents = [];

    while ((el = el.parentNode) && el !== document) {
        const matches = (el as HTMLElement).matches
            ? (el as HTMLElement).matches(selector)
            : (el as any).matches.msMatchesSelector?.(selector);
        if (!selector || matches) parents.unshift(el);
    }
    return parents as HTMLElement[];
}
