const orange = "#f66604";
const pink = "#f600ff";
const blue = "#48d4ff";
const green = "#4fff00";
export const EE_LOG_COLORS = {
    orange: `color:${orange}`,
    pink: `color:${pink}`,
    blue: `color:${blue}`,
    green: `color:${green}`
};
