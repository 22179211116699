import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DigitalSignatureRequirement } from "../../interfaces/digital-signature-requirement";
import { EsignUserService } from "../../services/esign-user.service";
import {
    BaseModalComponent,
    GrowlService,
    UserSettingsService
} from "@sf/common";
import { Subject } from "rxjs";
import { dayjs } from "@sf/common";

@Component({
    selector: "sf-digital-certificate-modal",
    templateUrl: "./digital-certificate-modal.component.html",
    styleUrls: ["./digital-certificate-modal.component.scss"]
})
export class DigitalCertificateModalComponent
    extends BaseModalComponent
    implements AfterViewInit
{
    certificateForm: FormGroup;
    passphraseError: string;
    digitalSignatureRequirement: DigitalSignatureRequirement;
    p12OnServer = sf.liveConfig.DocBuilderConfig.p12OnServer;

    private subject = new Subject<{ password: string; p12Raw: string }>();
    result$ = this.subject.asObservable();

    @ViewChild("password")
    passwordEl: ElementRef;

    constructor(
        private _eSignUserService: EsignUserService,
        private _userSettingsService: UserSettingsService,
        private _growl: GrowlService
    ) {
        super();
    }

    ngOnInit(): void {
        this.certificateForm = new FormGroup({
            password: new FormControl("", Validators.required)
        });
        if (
            !this.digitalSignatureRequirement.hasP12 &&
            !this.digitalSignatureRequirement.p12Raw
        ) {
            this._addCertificateControl();
        }

        this.primary = {
            text: "Verify",
            disabled: true,
            callback: () => {
                this.verifyPassphrase();
            }
        };
        this.secondary = {
            text: "Cancel",
            callback: () => {
                return true;
            }
        };

        this.certificateForm.valueChanges.subscribe((value) => {
            this.primary.disabled =
                !value.password ||
                (!value.certificate &&
                    !(
                        this.digitalSignatureRequirement.hasP12 ||
                        this.digitalSignatureRequirement.p12Raw
                    ));
        });
    }

    ngOnDestroy() {
        this.subject.complete();
    }

    ngAfterViewInit() {
        // if the certificate doesn't need to be selected then set focus on password input
        if (!this.certificateForm.controls.certificate) {
            this.passwordEl.nativeElement.focus();
        }
    }

    updateCertificate(event: any) {
        let keyfile = event.target.files[0];
        let reader = new FileReader();

        reader.onload = (e) => {
            let result = reader.result as string;
            this.digitalSignatureRequirement.p12Raw =
                result.split("base64,")[1];
        };

        if (keyfile) {
            reader.readAsDataURL(keyfile);
        }
    }

    verifyPassphrase() {
        this.passphraseError = null;
        let password = this.certificateForm.value.password;

        this._eSignUserService
            .validateAndSaveDigitalCertificate(
                this.digitalSignatureRequirement.p12Raw,
                password
            )
            .subscribe((error) => {
                if (error) {
                    if (error.indexOf("temporarily") != -1) {
                        this._growl.error(error);
                    } else {
                        this.passphraseError = error;
                        this.certificateForm.controls.password.reset();
                        this.passwordEl.nativeElement.focus();
                        return;
                    }
                }
                this.digitalSignatureRequirement.hasP12 = true;
                this.digitalSignatureRequirement.hasPassword = true;
                this.subject.next({
                    password: password,
                    p12Raw: this.digitalSignatureRequirement.p12Raw
                });
            });
    }

    selectNewCertificate() {
        this.digitalSignatureRequirement.p12Raw = null;
        this.passphraseError = null;
        this._addCertificateControl();
    }

    private _addCertificateControl() {
        this.certificateForm.addControl(
            "certificate",
            new FormControl("", Validators.required)
        );
    }
}
