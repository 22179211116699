import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LoginService } from "../../services/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserLockedDialogComponent } from "../../modals/user-locked-dialog/user-locked-dialog.component";
import {
    APP_BOOTSTRAP_SERVICE,
    AppBootstrapService,
    Auth,
    IeNotSupportedDialogComponent,
    QueryStringService,
    SessionService,
    SocketService
} from "@sf/common";
import { of, Subject } from "rxjs";
import { mapTo, switchMap, takeUntil } from "rxjs/operators";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
    formErrorMessages: string[] = [];
    formSeriousWarningMessage: string = null;
    formWarningMessage: string = null;
    formSuccessMessage: string = null;
    isEncompass = false;
    loginForm: FormGroup;
    currentTermsVersion = "7.18.2018";
    errorParameter: string = null;
    processing = false;
    alreadyAuthenticated = false;
    marketPage = "https://simplifile.com/";

    _$onDestroy$ = new Subject<void>();

    constructor(
            private formBuilder: FormBuilder,
            private router: Router,
            private route: ActivatedRoute,
            private modalService: NgbModal,
            private loginService: LoginService,
            private queryStringService: QueryStringService,
            @Inject(APP_BOOTSTRAP_SERVICE)
            private _appBootstrap: AppBootstrapService,
            private _socketService: SocketService,
            private sessionService: SessionService
    ) {
        this.loginForm = formBuilder.group({
            username: [""],
            password: [""],
            remember: [false]
        });
    }

    ngOnInit() {
        this.getRememberedUsername();
        this.focusToUsername();

        // see if username provided in query string
        let userParam = this.queryStringService.getQueryParam("u");
        if (userParam) {
            this.loginForm.patchValue({
                username: userParam
            });
            this.focusToPassword();
        }

        // see if password provided in query string
        let passwordParam = this.queryStringService.getQueryParam("p");
        if (passwordParam) {
            this.loginForm.patchValue({
                password: passwordParam
            });
            /* This seems to be a security risk
            // if there is a username AND a password, then try to log in with those
            if (userParam) {
                this.submitForm();
            }
            */
        }

        // try to detect if we are loading inside Encompass. This is our best effort.
        let restrictedParam = this.queryStringService.getQueryParam("restricted");
        this.isEncompass = !!restrictedParam;

        // look for error message parameter
        this.errorParameter = this.queryStringService.getQueryParam("err");
        if (this.errorParameter) {
            let errorMessage = null;
            let successMessage = null;
            if (this.errorParameter == "expired") {
                errorMessage = "Your session has expired";
            } else if (this.errorParameter == "connection") {
                errorMessage = "Connection lost. Please log in again.";
            } else if (this.errorParameter ==  "roles") {
                errorMessage = "Please log in to accept your new roles";
            } else if (this.errorParameter == "password-changed") {
                successMessage = "Your password has been changed. Please log in with your new password.";
            } else if (this.errorParameter == "restrictedExpired") {
                this.isEncompass = true;
                let appParameter = this.queryStringService.getQueryParam("app");
                errorMessage = "Your session has expired.";
                if (appParameter) {
                    errorMessage += " Please log in by re-opening the loan in " + appParameter + ".";
                }
            } else {
                errorMessage = decodeURIComponent(this.errorParameter);
            }
            if (errorMessage) {
                this.formErrorMessages.push(errorMessage);
            }
            if (successMessage) {
                this.formSuccessMessage = successMessage;
            }
        }

        // browser version detection - unless we are in ancient Encompass
        if (!this.isEncompass) {
            let oldTLS = false;
            let version = Number(window.bowser.version);
            if (window.bowser.chrome) {
                oldTLS = window.bowser.version < 30;
            } else if (window.bowser.firefox) {
                oldTLS = window.bowser.version < 27;
            } else if (window.bowser.msie) {
                oldTLS = window.bowser.version < 11;
            } else if (window.bowser.safari) {
                oldTLS = window.bowser.version < 7;
            } else if (window.bowser.opera) {
                oldTLS = window.bowser.version < 17;
            }
            if (isNaN(version)) {
                oldTLS = true;
            }
            if (oldTLS) {
                this.formErrorMessages.push(
                    `Your web browser (${window.bowser.name} ${window.bowser.version}) is out of date and will not work well on this site. Please download an updated version of a supported browser such as Chrome or Edge.`
                );
            }
        }

        // check for unsupported Internet Explorer - unless we are in ancient Encompass
        if (!this.isEncompass) {
            let isIE = window.bowser.name.search(/Internet Explorer/i) >= 0;
            if (isIE) {
                // We SHOULD be supporting Firefox too
                this.formSeriousWarningMessage = `Internet Explorer is no longer supported as of
                    <a class="warning-anchor" href="https://techcommunity.microsoft.com/t5/windows-it-pro-blog/internet-explorer-11-desktop-app-retirement-faq/ba-p/2366549">June 14, 2022</a>.
                    To avoid issues using Simplifile, please immediately start using a supported browser such as
                    <a class="warning-anchor" href="https://www.google.com/chrome">Chrome</a> or
                    <a class="warning-anchor" href="https://www.microsoft.com/en-us/edge">Edge</a>.`;
                // show dialog
                setTimeout(() => {
                    this.showIEBadDialog();
                }, 400);
            }
        }

        // get the url for the parent marketing page
        let currentUrl = window.location.href;
        let sfPos = currentUrl.indexOf("/sf/");
        this.marketPage = currentUrl.substring(0, sfPos);
    }

    ngOnDestroy() {
        this._$onDestroy$.next();
        this._$onDestroy$.complete();
    }

    showIEBadDialog() {
        const modalRef = this.modalService.open(IeNotSupportedDialogComponent, {
            backdrop: "static",             // block close by clicking out
            keyboard: false,                // block Esc key
            windowClass: "ie-bad-dialog"    // specify size
        });
        modalRef.result.then(() => {
            // nothing
        }, () => {
            // nothing
        });
    }

    getTermsDate() {
        this.loginService.getTermsOfUseRevisionDate()
            .subscribe((date: string) => {
                this.currentTermsVersion = date;
            });
    }

    getRememberedUsername() {
        this.loginService.getRememberedUsername()
            .subscribe((username: string) => {
                if (username) {
                    this.loginForm.patchValue({
                        remember: true,
                        username: username
                    });
                    this.focusToPassword();
                }
                this.getTermsDate();
            });
    }

    clickBack() {
        window.location.replace(this.marketPage);
    }

    focusToUsername() {
        this.focusElement("username");
    }

    focusToPassword() {
        this.focusElement("password");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    clickForgotUsername() {
        let newUrl = "/login/forgot-username";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickForgotPassword() {
        let newUrl = "/login/forgot-password";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickContact() {
        let newUrl = "/login/contact";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }

    clickRegister() {
        /**
         * not allowing this to work in multiple environments, mainly to keep the NetSuite sandbox clean
         * if that ever changes, one can just uncomment the lines below and remove their counterparts
         * and then it would be prevented only on localhost
         **/
        let newUrl = "https://simplifile.com/get-connected/";
        //let newUrl = window.location.origin + "/get-connected/";

        //if (window.location.hostname == "localhost") {
        if (window.location.hostname != "simplifile.com" && window.location.hostname != "www.simplifile.com") {
            this.formErrorMessages = [
                "The 'sign-up' option is only available on the Simplifile Production system"
            ];
            return;
        }

        window.location = newUrl;
    }

    submitForm() {
        this.formErrorMessages = [];

        let username: string = this.loginForm.controls.username.value;
        let password: string = this.loginForm.controls.password.value;
        let rememberUsername: boolean = this.loginForm.controls.remember.value;

        // this is optional - only provided if user logs in while accepting an invitation
        let tokenID = this.queryStringService.getQueryParam("token");

        if (!username) {
            this.formErrorMessages.push("Please enter your username or email");
        }
        if (!password) {
            this.formErrorMessages.push("Please enter your password");
        }
        if (this.formErrorMessages.length) {
            return;
        }

        this.processing = true;
        let redirect = this.getRedirectParam();
        this.loginService.login(username, password, rememberUsername, tokenID, redirect)
            .subscribe((results: any) => {
                if (results) {
                    let nextPage = results.nextPage.replace("app/", "");
                    let forceNextPage: boolean = results.forceNextPage;
                    if (redirect && !forceNextPage) {
                        nextPage = redirect;
                    }
                    if (results.dcPreflight) {
                        if (results.dcPreflightHttp) {
                            this.sendDCPreflight(results.dcPreflight, results.dcPreflightHttp, true);
                        }
                    }
                    this.goToNextPage(nextPage);
                } else {
                    this.processing = false;
                    this.formErrorMessages.push("Login failed.");
                }
            }, (errorResult: any) => {
                this.processing = false;
                this.loginForm.patchValue({
                    password: ""
                });
                let message = "Login Failed";
                if (errorResult && errorResult.error && errorResult.error.errorMessage) {
                    message = errorResult.error.errorMessage;
                }
                if (message && (message.startsWith("Too many failed") || message.startsWith("Too many login"))) {
                    this.userLockedOut();
                } else {
                    this.formErrorMessages.push(message);
                }
            });
    }

    sendDCPreflight(url: string, http: boolean, ws: boolean) {
        this.loginService.sendDCPreflight(url)
                .subscribe((results: any) => {
                    this.saveDCPreflight(true, "http", "");
                }, (errorResult: any) => {
                    let message = (errorResult.message ? errorResult.message : "");
                    this.saveDCPreflight(false, "http", message);
                });
    }

    saveDCPreflight(success: boolean, type: string, notes: string) {
        this.loginService.saveDCPreflight(success, type, notes)
                .subscribe((results: any) => {
                }, (errorResult: any) => {
                });
    }


    goToNextPage(nextPage: string) {
        if (nextPage.startsWith("/sf/ui/login/") && !nextPage.endsWith("security-questions")) {
            // user is not logged in, so just go
            let nextPageInternal = nextPage.replace("/sf/ui", "");
            this.router.navigateByUrl(nextPageInternal);
        } else if (nextPage.startsWith("/sf/ui")) {
            // user is logged in, so launch the socket
            this._socketService.authMessages
                .pipe(takeUntil(this._$onDestroy$), switchMap((auth: Auth) => {
                    if (auth.data.authenticated) {
                        return this._appBootstrap.bootstrapAfterLogin(auth).pipe(mapTo(auth));
                    } else {
                        return of(auth);
                    }
                }))
                .subscribe((auth: Auth) => {
                    // don't do screwy observable filter stuff here, or you can get in an infinite wait
                    if (auth.data.authenticated) {
                        nextPage = this.checkPageType(nextPage);
                        let nextPageInternal = nextPage.replace("/sf/ui", "");
                        this.router.navigateByUrl(nextPageInternal);
                    } else {
                        // we've had cases where the socket authentication failed
                        this.processing = false;
                        this.formErrorMessages.push("Error in network connection. Please try again.");
                    }
                }, (error: any) => {
                    this.processing = false;
                    this.formErrorMessages.push("Network connection error. Please try again. " + error);
                });
            this._socketService.reconnect();
        } else {
            // not a normal case
            window.location.assign(nextPage);
        }
    }

    userLockedOut() {
        const modalRef = this.modalService.open(UserLockedDialogComponent);
        modalRef.result.then(
            () => {
                // nothing
            },
            () => {
                // nothing
            }
        );
    }

    getRedirectParam(): string {
        let redirect = this.queryStringService.getQueryParam("fr");
        if (redirect) {
            let path = redirect;
            path = decodeURIComponent(path);
            if (!path.startsWith("/")) {
                path = "/" + path;
            }
            redirect = "/sf" + path;
        }
        return redirect;
    }

    // make sure super-user pages for super-users
    // This is just a convenience to catch cases when a super-user impersonates a user and then the session times out.
    checkPageType(nextPage: string): string {
        if (this.sessionService.isSuperUser()) {
            if (!nextPage.startsWith("/sf/ui/admin")) {
                // super-user can't start out in non-admin page
                return this.sessionService.getHomePageUrl();
            }
        } else {
            if (nextPage.startsWith("/sf/ui/admin")) {
                // normal user can't start out in admin page
                return this.sessionService.getHomePageUrl();
            }
        }
        return nextPage;
    }
}
