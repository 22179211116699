/**
 * This is a 'drop-down' selector of organizations
 * It allows filtering for any organization that the currently logged-in user belongs to.
 * Single-select only. For multi-select, use organization-multi-selector or organization-multi-select-dialog.
 */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { OrgSelectorPipe } from "../../pipes/org-selector.pipe";
import { Location } from "@angular/common";
import { Subject } from "rxjs";
import { OrganizationHierarchyService } from "../../services/organization-hierarchy.service";
import {
    Organization,
    SelectableOrganization,
    UserOrgService
} from "@sf/userorg/common";
import { ActiveService, SessionOrganization, SessionService } from "@sf/common";

// prettier-ignore
@Component({
    selector: "sf-organization-filter-selector",
    templateUrl: "./organization-filter-selector.component.html",
    styleUrls: ["./organization-filter-selector.component.scss"]
})
export class OrganizationFilterSelectorComponent
    implements OnInit, OnChanges, OnDestroy
{
    /* Inputs */
    @Input()
    selectedOrgID: string; // (optional) (single select) OrganizationID initially selected
    @Input()
    selectableOrganizations: Partial<Organization>[]; // (optional) if provided, these are the items that will appear in the list
    @Input()
    selectableOrgServices: string[]; // array of services that displayed orgs must have (ex: ["collaboration"])
    @Input()
    selectableOrgPermissions: string[]; // array of permissions that the current user must have in each organization
    @Input()
    autoInitialSelect: boolean; // initially select an item - determined by selectedOrgID, else first item in list
    @Input()
    searchLimit: number; // (optional - default is 25) max number of organizations to show in list
    @Input()
    canClearSelectedOrg: boolean; // whether to show an 'X' to clear selection
    @Input()
    hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input()
    includeNoneOption: SelectableOrganization; // (optional) item to include at the top of the list
    @Input()
    required: boolean = null; // if required in form
    @Input()
    placeholder: string; // (optional) placeholder - will be defaulted
    @Input()
    windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'

    /* Outputs */
    @Output()
    select: EventEmitter<any> = new EventEmitter(); // when an org is selected - passes an 'org' parameter when called

    /** Private Variables **/
    private _$ngOnDestroy: Subject<void> = new Subject();

    /** Public Variables **/
    organizations: Partial<Organization>[] = [];
    selectedOrganization: Partial<Organization> = null;
    selectableItems: SelectableOrganization[] = [];
    constructor(
        private userorgService: UserOrgService,
        private hierarchyService: OrganizationHierarchyService,
        private sessionService: SessionService,
        private orgSelectorPipe: OrgSelectorPipe,
        private location: Location
    ) {}

    ngOnInit() {
        if (!this.placeholder) {
            this.placeholder = "Select Organization...";
        }
        if (!this.windowClass) {
            this.windowClass = "not-full-width";
        }
        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        if (this.selectableOrganizations) {
            this.organizations = this.selectableOrganizations;
            this.selectableItems = [];
            this.selectableOrganizations.forEach((organization) => {
                let item: SelectableOrganization = {
                    id: organization.id,
                    label: organization.name + " (" + organization.id + ")",
                    selected: false
                };
                this.selectableItems.push(item);
            });
        } else {
            let orgData: SessionOrganization[] = this.sessionService.getAllOrganizations();
            this.newOrganizationsReceived(orgData);

            // figure out which organization should be initially selected
            /*
            let currentItem: SelectableOrganization = null;
            if (this.selectedOrgID) {
                currentItem = this.selectableItems.find((org) => {
                    return org.id === this.selectedOrgID;
                });
            }
            */
            if (this.organizations.length > 0 && (this.autoInitialSelect || this.organizations.length == 1)) {
                if (this.selectedOrgID) {
                    // select the org with the selected ID
                    this.handleOrgSelection({
                        $selection: {
                            id: this.selectedOrgID,
                            label: null
                        }
                    });
                } else {
                    // select the first org in the list
                    this.selectedOrgID = this.selectableItems[0].id;
                    this.handleOrgSelection({
                        $selection: {
                            id: this.selectableItems[0].id,
                            label: this.selectableItems[0].label
                        }
                    });
                }
            } else if (this.selectedOrgID) {
                // if we have a selected ID, select that one
                this.handleOrgSelection({
                    $selection: {
                        id: this.selectedOrgID,
                        label: null
                    }
                });
            }
        }

        this.sessionService.sessionResetEvent.subscribe(() => {
            let orgData: SessionOrganization[] = this.sessionService.getAllOrganizations();
            this.newOrganizationsReceived(orgData);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedOrgID && changes.selectedOrgID.currentValue && changes.selectedOrgID.currentValue !==
                changes.selectedOrgID.previousValue) {
            let org: Partial<Organization> = this.findOrganizationInList(changes.selectedOrgID.currentValue);
            this.selectedOrganization = org; // may be null, which is fine
            if (this.select && org) {
                this.select.emit(org);
            }
        }
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
    }

    getNumber(count: number) {
        return new Array(count);
    }

    getOrgs(event?: any) {
    }

    handleOrgSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            let selection: SelectableOrganization = selectionObject;
            let org: Partial<Organization> = null;
            if (selection && selection.id) {
                org = this.findOrganizationInList(selection.id);
            }
            this.selectedOrganization = org; // may be null, which is fine
            if (this.select) {
                this.select.emit(org);
            }
        }
    }

    private findOrganizationInList(orgID: string): Partial<Organization> {
        let org: Partial<Organization> = this.organizations.find((org) => {
            return org.id === orgID;
        });
        return org;
    }

    filterOrgServices(orgList: any) {
        let newOrgList: any[] = [];
        // filter by org services
        if (this.selectableOrgServices && this.selectableOrgServices.length) {
            orgList.forEach((org: any) => {
                let hasService = false;
                this.selectableOrgServices.forEach((serviceID) => {
                    org.activeServices.forEach((orgService: ActiveService) => {
                        if (orgService.id === serviceID) {
                            hasService = true;
                        }
                    });
                });
                if (hasService) {
                    newOrgList.push(org);
                }
            });
            orgList = newOrgList;
        }
        return orgList;
    }

    filterOrgPermissions(orgList: any) {
        let newOrgList: any[] = [];
        // filter by org permissions
        if (this.selectableOrgPermissions && this.selectableOrgPermissions.length) {
            orgList.forEach((org: any) => {
                let hasPermission = false;
                this.selectableOrgPermissions.forEach((requiredPermission) => {
                    if (this.sessionService.hasPermission(requiredPermission, org.id)) {
                        hasPermission = true;
                    }
                });
                if (hasPermission) {
                    newOrgList.push(org);
                }
            });
            orgList = newOrgList;
        }
        return orgList;
    }

    /*
    buildIndentString(count: number) {
        let text = "";
        for (let i = 0; i < count; i++) {
            //text += "\xA0   ";
            text += ".   ";
        }
        return text;
    }
    */

    // build list of orgs - only called once
    newOrganizationsReceived(allOrganizationData: Partial<Organization>[]) {
        // put into hierarchy order
        allOrganizationData = this.hierarchyService.buildTrees(allOrganizationData, "name", true);

        // filter by services
        allOrganizationData = this.filterOrgServices(allOrganizationData);

        // filter by permissions
        allOrganizationData = this.filterOrgPermissions(allOrganizationData);

        this.organizations = allOrganizationData;

        // build object to display
        let results: SelectableOrganization[] = [];
        allOrganizationData.forEach((org) => {
            let indent = "";
            /*
            if (org.indentLevel) {
                indent = this.buildIndentString(org.indentLevel);
            }
            */
            let item = {
                label: indent + org.name + " (" + org.id + ")",
                id: org.id,
                selected: false
            };
            results.push(item);
        });

        if (this.includeNoneOption) {
            results.unshift(this.includeNoneOption);
        }

        this.selectableItems = results;
    }
}
