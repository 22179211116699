import {
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild
} from "@angular/core";

@Component({
    selector: "sf-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent {
    @Input() message: string;
    @ViewChild("spinner") spinner: ElementRef;

    private _previousFocus: HTMLElement;

    @HostListener("keydown", ["$event"])
    preventInteraction($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    constructor() {}

    ngAfterViewInit() {
        this._previousFocus = document.activeElement as HTMLElement;
        this.spinner.nativeElement.focus();
    }

    ngOnDestroy() {
        this._previousFocus?.focus();
    }
}
