import { EventEmitter, Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { GrowlService } from "@sf/common";
import { RpcClientService } from "@sf/common";
import { User } from "../interfaces/user.interface";
import { PhoneSupportQuestionDialogComponent } from "../dialogs/phone-support-question-dialog/phone-support-question-dialog.component";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class LockoutService {
    userUnlocked$: EventEmitter<string>;

    constructor(
        private modalService: NgbModal,
        private growlService: GrowlService,
        private rpcService: RpcClientService
    ) {
        this.userUnlocked$ = new EventEmitter<string>();
    }

    doUserUnlock(user: Partial<User>, isOrgAdmin?: boolean): Promise<boolean> {
        //return this.angularJSService.doUserUnlock(user);
        if (isOrgAdmin) {
            return this.doTheUnlock(user);
        } else {
            return new Promise((resolve: any, reject: any) => {
                this.isPhoneSupportAnswerRequired(user.username).subscribe((isRequired: boolean) => {
                    if (isRequired) {
                        this.hasPhoneSupportAnswer(user.username).subscribe((hasAnswer: boolean) => {
                            if (!hasAnswer) {
                                this.doTheUnlock(user).then(() => {
                                    resolve(true);
                                });
                            } else {
                                this.getPhoneChallengeQuestion(user.username).subscribe((result: any) => {
                                    let supportQuestion = result.question;
                                    let supportAnswer = result.answer;

                                    const modalRef: NgbModalRef = this.modalService.open(
                                            PhoneSupportQuestionDialogComponent);
                                    const modalInstance = modalRef.componentInstance;
                                    modalInstance.supportQuestion = supportQuestion;
                                    modalInstance.supportAnswer = supportAnswer;

                                    modalRef.result.then((doUnlock: boolean) => {
                                        if (doUnlock) {
                                            this.doTheUnlock(user).then(() => {
                                                resolve(true);
                                            });
                                        } else {
                                            resolve(false);
                                        }
                                    }, () => {
                                        resolve(false);
                                    });
                                });
                            }
                        });
                    } else {
                        this.doTheUnlock(user).then(() => {
                            resolve(true);
                        });
                    }
                });
            });
        }
    }

    private doTheUnlock(user: Partial<User>): Promise<boolean> {
        return new Promise((resolve: any, reject: any) => {
            this.clearUserLockedOut(user.username).subscribe(() => {
                user.isLockedOut = false;
                user.attempts = 0;
                //emit event that others can respond to
                this.userUnlocked$.emit(user.username);
                this.growlService.success("User has been unlocked.");
                resolve(true);
            });
        });
    }

    getPhoneChallengeQuestion(username: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "getPhoneChallengeQuestion",
            username
        );
    }

    isPhoneSupportAnswerRequired(username: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "isPhoneSupportAnswerRequired",
            username
        );
    }

    private hasPhoneSupportAnswer(username: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "hasPhoneSupportAnswer",
            username
        );
    }

    private clearUserLockedOut(username: string) {
        return this.rpcService.makeRequest("userorg", "clearUserLockedOut", {
            username: username
        });
    }
}
