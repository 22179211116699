<div class="header" (contextmenu)="showContextMenu($event)">
    <sf-context-menu [x]="contextMenuX" [y]="contextMenuY" [shown]="contextMenuVisible"
        [options]="contextMenuItems" (select)="handleContextMenuItem($event)"></sf-context-menu>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <div class="d-flex flex-column flex-md-row">
                <div class="header-selector">
                    <div class="title" *ngIf="pageData.pageTitle">
                        <h1 style="display: inline-block">{{pageData.pageTitle}}</h1>&nbsp;
                        <span class="fixed-width" *ngIf="pageData.videoLink">
                             <a [href]="pageData.videoLink"
                                target="_blank"
                                container="body"
                                placement="bottom"
                                class="help-icon"
                                aria-label="video icon"
                             ><fa-icon icon="video" [fixedWidth]="true"></fa-icon></a>
                        </span>
                    </div>
                    <div class="d-flex flex-column flex-md-row select-holder">
                        <sf-organization-filter-selector *ngIf="hasMultipleOrganizations"
                            [hideBorder]="true"
                            [selectedOrgID]="selectedOrganization.id"
                            [selectableOrgServices]="selectableOrgServices"
                            [selectableOrgPermissions]="selectableOrgPermissions"
                            [autoInitialSelect]="false"
                            [canClearSelectedOrg]="false"
                            (select)="setSelectedOrg($event)">
                        </sf-organization-filter-selector>
                        <div class="single-item" *ngIf="!hasMultipleOrganizations">{{selectedOrganization.name}} ({{selectedOrganization.id}})</div>
                        <div *ngIf="selectedOrganization && selectedOrganization.status"
                            class="status-info sf-status-badge-container"
                            id="statusInfo">
                            <span class="badge {{selectedOrganization.statusClass}}"
                                    [ngbTooltip]="selectedOrganization.statusTooltip" placement="top">
                                {{selectedOrganization.status}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 save-changes align-self-center" *ngIf="showSaveChanges && !!selectedOrganization && selectedOrganization.id">
                    <button class="btn btn-primary save-changes" (click)="saveCurrentOrg()">Save Changes</button>
                </div>
            </div>
        </div>
    </div>
</div>
