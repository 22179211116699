<sf-modal-header title="Verify Digital Certificate Password"></sf-modal-header>
<div class="modal-body">
    <form [formGroup]="certificateForm">
        <div *ngIf="passphraseError">
            <sf-notification type="alert" [noClose]="true">{{passphraseError}}</sf-notification>
            <br/>
        </div>
        <div *ngIf="certificateForm.controls.certificate">
            <div>
                <p>A digital signature is
                    required{{ digitalSignatureRequirement?.requiredByState ? "by the state of " + digitalSignatureRequirement.state : "" }}.
                </p>
                <p>Choose a .p12 or .pfx keyfile to use for document signing. This keyfile will be saved by your browser.
                    If you clear cookies, you will be required to choose the keyfile again.</p>
                <br/>
            </div>
            <div>
                <label>P12 or PFX Key File
                    <input type="file" (change)="updateCertificate($event)" formControlName="certificate" accept=".pfx,.p12"/>
                </label>
            </div>
        </div>
        <div>
            <label>Digital Certificate Password</label>
            <button class="btn btn-link sf-btn-link">
                <fa-icon [icon]="['far', 'question-circle']" ngbTooltip="Not your Simplifile password"></fa-icon>
            </button>
            <input #password type="password" class="form-control" formControlName="password" (keyup.enter)="verifyPassphrase()"/>
        </div>
        <div *ngIf="passphraseError && !certificateForm.controls.certificate" class="mt-1">
            <button class="btn btn-link sf-btn-link" (click)="selectNewCertificate()">
                Select a different certificate?
            </button>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
