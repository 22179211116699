import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-remove-profile-picture-dialog",
    templateUrl: "./remove-profile-picture-dialog.component.html",
    styleUrls: ["./remove-profile-picture-dialog.component.scss"]
})
export class RemoveProfilePictureDialogComponent implements OnInit {
    @Input() dialogTitle: string;
    @Input() dialogText: string;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal Buttons
        this.primary = {
            text: "Remove",
            disabled: false,
            callback: this.confirmRemove.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };
    }

    confirmRemove() {
        this._activeModal.close(true);
    }

    abort() {
        this._activeModal.dismiss(false);
    }
}
