import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { SigningEventService } from "../services/signing-event.service";

@Injectable({
    providedIn: "root"
})
export class SigningEventGuard implements CanActivate {
    constructor(private _signingEventService: SigningEventService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this._signingEventService.checkForActiveSigningEvents();
        return true;
    }
}
