import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { forkJoin, merge, Observable, Subject, Subscription } from "rxjs";
import { first, map, reduce, take, takeUntil } from "rxjs/operators";
import {
    capitalize,
    ChooseColumnsDialogComponent,
    ConfigurationService,
    ConfirmationModalComponent,
    CustomColumnsService,
    CustomizableColumn,
    dayjs,
    getParamsFromRoute,
    GrowlService,
    SelectComponent,
    SessionOrganization,
    SessionService,
    setSelectedItems,
    SpinnerService,
    TemplateRendererComponent
} from "@sf/common";
import {
    CellKeyDownEvent,
    ColDef,
    ColumnApi,
    FullWidthCellKeyDownEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridSizeChangedEvent,
    RowDataChangedEvent,
    SortChangedEvent,
    ValueGetterParams
} from "@ag-grid-community/core";
import { NgbDropdown, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
    BankAccount,
    BannerNotificationCategory,
    BannerNotificationService,
    ContractService,
    CreditCardAccount,
    CreditCardStatus,
    CreditCardType,
    CurrentOrganizationService,
    Organization,
    OrganizationEntitlement,
    OrganizationService,
    PaymentAccount,
    PaymentAccountsService,
    PaymentAccountType,
    StripeService,
    UserOrgService,
    UserorgSubscriptionService
} from "@sf/userorg/common";
import { AddEditPaymentAccountDialogComponent } from "../../dialogs/add-edit-payment-account-dialog/add-edit-payment-account-dialog.component";
import { ImportPaymentAccountDialogComponent } from "../../dialogs/import-payment-account-dialog/import-payment-account-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import {
    PaymentNavTickersService,
    SubmitterOrganizationService,
    SubmitterPaymentService
} from "@sf/submitter/common";
import { UpdatePackagesDefaultAccountDialogComponent } from "../../dialogs/update-packages-default-account-dialog/update-packages-default-account-dialog.component";
import { UnaffectedPackageUpdateDialogComponent } from "../../dialogs/unaffected-package-update-dialog/unaffected-package-update-dialog.component";
import { KeyCode } from "@ag-grid-community/core";
import { PackagesUsingAccountArchivedDialogComponent } from "../../dialogs/packages-using-account-archived-dialog/packages-using-account-archived-dialog.component";

// prettier-ignore
@Component({
    selector: "sf-organization-payment-accounts",
    templateUrl: "./organization-payment-accounts.component.html",
    styleUrls: ["./organization-payment-accounts.component.scss"]
})
export class OrganizationPaymentAccountsComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    /** PUBLIC **/

    @ViewChild("accountNameTemplate")
    accountNameTemplate: TemplateRef<any>;
    @ViewChild("accountDetailsTemplate")
    accountDetailsTemplate: TemplateRef<any>;
    @ViewChild("nameOnAccountTemplate")
    nameAccountTemplate: TemplateRef<any>;
    @ViewChild("accountActionsHeaderTemplate")
    accountActionsHeaderTemplate: TemplateRef<any>;
    @ViewChild("accountActionsTemplate")
    accountActionsTemplate: TemplateRef<any>;
    @ViewChild("accountDefaultTemplate")
    accountDefaultTemplate: TemplateRef<any>;
    @ViewChild("accountStatusTemplate")
    accountStatusTemplate: TemplateRef<any>;
    @ViewChildren("actionsDropdown")
    actionDropdowns: QueryList<NgbDropdown>;

    @ViewChildren("submitterSelects")
    submitterSelects: QueryList<SelectComponent>;
    @ViewChildren("docBuilderSelects")
    docBuilderSelects: QueryList<SelectComponent>;

    //for reference in HTML
    get paymentAccountType() {
        return PaymentAccountType;
    }
    get creditCardStatus() {
        return CreditCardStatus;
    }

    isSuperUser: boolean = false;
    showArchived: boolean = false;
    organizationId: string = null;
    paymentAccountsLoaded: boolean = false;
    paymentAccounts: PaymentAccount[] = [];
    defaultAccounts: any[] = [];
    filteredAccounts: PaymentAccount[] = [];
    numActiveAccounts: number = 0;
    gridApi: GridApi;
    columnApi: ColumnApi;
    gridOptions: GridOptions;
    hasAccounts: boolean = false;
    hasSubmitter: boolean = false;
    hasRecordingPlus = false;
    hasRecipient: boolean = false;
    hasDocBuilder: boolean = false;
    hasSignEvents: boolean = false;
    hasNotary: boolean = false;
    products: string[] = [
        "SUBMITTER",
        "SUBMITTER_PAPER",
        "SUBMITTER_SIGNING",
        "ESIGN_EVENT",
        "NOTARY"
    ];
    defaultPaymentOptions: any = [];
    feeCategories: { [product: string]: any } = {};
    hasAccountingPermission: boolean = false;
    hasAcctMgmtPermission: boolean = false;
    gridDivStyle: any = {
        height: "200px" //minimum for two rows
    };
    hasSalesTax: boolean = false;
    showSubmitterLicenseFee: boolean = true;
    showDocBuilderLicenseFee: boolean = true;
    allowSubmitterFeeInvoicing: boolean = false;
    allowSubmitterLicenseInvoicing: boolean = false;
    // usesAutomaticPayments: boolean = false;
    allowDocBuilderFeeInvoicing: boolean = false;
    allowDocBuilderLicenseInvoicing: boolean = false;
    allowEEFeeInvoicing: boolean = false;
    allowEELicenseInvoicing: boolean = false;
    isSearchingPackages: boolean = false;
    feeSearched: string = null;
    initialAccountType: PaymentAccountType = PaymentAccountType.BANK;
    orgEnabledForCards: boolean = false;
    numActiveBankAccounts: number = 0;
    numActiveCardAccounts: number = 0;
    showCard: boolean = true;
    showBasicCardText: boolean = true;
    missingAccountInfo: any;

    /** PRIVATE **/

    private _onDestroy$: Subject<boolean> = new Subject<boolean>();
    private _pageColumnsKey: string;
    private _columnSetName: string = "default";
    private _allColumns: CustomizableColumn[];
    private _gridColumnDefs: Partial<ColDef>[];
    private _prevOrganizationId: string = null;
    private _initialized: boolean = false;
    private _simplifileInvoiceAccountID: string = null;
    private _accountsSortKey: string = "paymentAccountsSort";
    private _savedSort: any = null;
    private _username: string = null;
    private _showGrowl: boolean = false;
    private _lastSubmitterLicenseSelection: string;
    private _lastDocBuilderLicenseSelection: string;
    private _updater: Subscription = null;

    /** LIFECYCLE **/

    constructor(
        private _currentOrgService: CurrentOrganizationService,
        private _paymentAccountsService: PaymentAccountsService,
        private _sessionService: SessionService,
        private _growlService: GrowlService,
        private _columnsService: CustomColumnsService,
        private _modalService: NgbModal,
        private _userOrgService: UserOrgService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _bannerService: BannerNotificationService,
        private _submitterOrgService: SubmitterOrganizationService,
        private _submitterPaymentService: SubmitterPaymentService,
        private _paymentNavTickersService: PaymentNavTickersService,
        private _configurationService: ConfigurationService,
        private _orgService: OrganizationService,
        private _stripeService: StripeService,
        private _contractService: ContractService,
        private _userorgSubscriptionService: UserorgSubscriptionService,
        private spinnerService: SpinnerService
    ) {
        this._pageColumnsKey = this._columnsService.getPageKey(
            "organizationPaymentAccountsColumns"
        );
        this.isSuperUser = this._sessionService.isSuperUser();
        this._username = this._sessionService.getUsername();
        this._configurationService
            .getUserSettingCustom(this._username, this._accountsSortKey)
            .subscribe((savedSort: string) => {
                this._savedSort = JSON.parse(savedSort);
            });
    }

    ngOnInit(): void {
        this.hasAccountingPermission =
            this._sessionService.hasPermission(
                "admin_accounting_payment_account_management",
                "SIMPFL"
            ) && this.isSuperUser;

        this._currentOrgService.currentOrganization$
            .pipe(
                takeUntil(this._onDestroy$)
                // delay(0)
            )
            .subscribe((organization) => {
                let orgID = organization ? organization.id : null;
                if (orgID != this.organizationId) {
                    this._initializeWithOrgID(orgID);
                }
            });

        let currentOrg: Organization =
            this._currentOrgService.getCurrentOrganization();

        if (!this.isSuperUser && !currentOrg) {
            //see if we have an org id in the URL first
            let params = getParamsFromRoute(this._route, ["orgID"]);
            if (params.orgID && params.orgID !== "_") {
                //this._initializeWithOrgID(params.orgID);
                this._userOrgService
                    .getOrganization(params.orgID)
                    .pipe(first())
                    .subscribe((org: Organization) => {
                        this._currentOrgService.updateCurrentOrganization(org);
                    });
            } else {
                let orgs = this._sessionService.getAllOrganizations();
                let filteredOrgs = orgs.filter((org) => {
                    return (
                        this._sessionService.hasAnyPermission(
                            ["organization_accounting", "organization_users"],
                            org.id
                        ) && org.isEnabled
                    );
                });

                let orgToUse: SessionOrganization = null;

                let defaultOrgID: string =
                    this._sessionService.getDefaultOrganizationID();
                let filteredOrgIDs: string[] = filteredOrgs.map((org) => {
                    return org.id;
                });

                if (filteredOrgIDs.includes(defaultOrgID)) {
                    orgToUse = filteredOrgs.find((org) => {
                        return org.id == defaultOrgID;
                    });
                }

                if (!orgToUse) {
                    orgToUse = filteredOrgs[0];
                }
                if (orgToUse) {
                    this._currentOrgService.updateCurrentOrganization(
                        <Organization>(<any>orgToUse)
                    );
                }
            }
        } else if (!currentOrg) {
            this.paymentAccountsLoaded = true; //prevent eternal spinner (should only see on accounting's payment accounts page)
        }

        // sometimes we get to this page and no org is selected
        // we don't really want to have NO organization selected
        setTimeout(() => {
            let nowOrg = this._currentOrgService.getCurrentOrganizationID();
            if (!nowOrg) {
                this.pickInitialOrg();
            }
        }, 400);

        this._initialized = true;
    }

    ngAfterViewInit(): void {
        this._initializeGridColumns();
        this._setGridOptions();
        this._getSavedColumns();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next(true);
        this._onDestroy$.complete();
    }

    /** PRIVATE FUNCTIONS **/

    private _initializeWithOrgID(orgID: string) {
        // sometimes org coming in is one where user doesn't have permission
        if (
            !this.isSuperUser &&
            (!orgID ||
                !this._sessionService.hasPermission(
                    "organization_accounting",
                    orgID
                ))
        ) {
            let permissionOrgs =
                this._sessionService.getOrganizationIDsWithPermission(
                    "organization_accounting"
                );
            if (permissionOrgs && permissionOrgs.length) {
                orgID = permissionOrgs[0];
            }
        }

        this.organizationId = orgID;
        if (orgID == "SIMPFL") {
            //don't do anything with Simplifile Org
            this.paymentAccountsLoaded = true;
            this.organizationId = null;
        }

        if (this.organizationId) {
            //check to see if org is enabled for credit cards
            this._stripeService
                .isOrganizationEnabledForCreditCard(this.organizationId)
                .pipe(first())
                .subscribe((result: boolean) => {
                    this.orgEnabledForCards = result;
                    this.showBasicCardText = !this.orgEnabledForCards;
                });

            this._paymentAccountsService
                .getSimplifileInvoiceAccountID(this.organizationId)
                .pipe(first())
                .subscribe((accountID: string) => {
                    // make sure selected org is still selected
                    if (this.organizationId) {
                        this._simplifileInvoiceAccountID = accountID;
                        this.hasAcctMgmtPermission =
                            this.isSuperUser ||
                            this._sessionService.hasPermission(
                                "organization_accounting",
                                this.organizationId
                            );
                        if (this.hasAcctMgmtPermission) {
                            this._paymentAccountsService
                                .getTransactionCategoriesForProducts(
                                    this.organizationId,
                                    this.products
                                )
                                .pipe(first())
                                .subscribe((categories) => {
                                    this.feeCategories = categories;
                                    this._loadOrganizationInfo();
                                });
                        } else {
                            this.paymentAccountsLoaded = true; // no more to do
                        }
                    } else {
                        this.paymentAccountsLoaded = true; // no more to do
                    }
                });
        }

        this._updater = this._userorgSubscriptionService
            .subscribeToCountsChanged(this.organizationId)
            .subscribe((msg: any) => {
                let data: any = JSON.parse(msg.data);
                switch (data.type) {
                    case "paymentAccountAdded":
                        if (
                            data.user_id !== this._sessionService.getUsername()
                        ) {
                            this._bannerService.recheckCategory(
                                BannerNotificationCategory.PAYMENT_ACCOUNTS
                            );
                            this._initializeWithOrgID(this.organizationId);
                        }
                        break;
                }
            });
    }

    private _initializeGridColumns() {
        this._gridColumnDefs = [
            {
                colId: "label",
                field: "label",
                headerName: "Account Nickname",
                width: 350,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.accountNameTemplate
                },
                comparator: this.caseInsensitiveComparator
            },
            {
                colId: "type",
                field: "viewType",
                headerName: "Account Type",
                width: 150
            },
            {
                colId: "name",
                headerName: "Name on Account",
                width: 350,
                valueGetter: (params: ValueGetterParams) => {
                    if (
                        params.data.paymentAccountType ==
                        PaymentAccountType.BANK
                    ) {
                        return params.data.nameOnBankAccount;
                    }
                    if (
                        params.data.paymentAccountType ==
                        PaymentAccountType.CREDIT_CARD
                    ) {
                        return params.data.nameOnCard;
                    }
                    return null;
                },
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.nameAccountTemplate
                },
                comparator: this.caseInsensitiveComparator
            },
            {
                colId: "details",
                headerName: "Account Details",
                width: 350,
                sortable: false,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.accountDetailsTemplate
                }
            },
            {
                colId: "status",
                headerName: "Status",
                width: 100,
                valueGetter: (params: ValueGetterParams) => {
                    if (
                        params.data.paymentAccountType ==
                        PaymentAccountType.CREDIT_CARD
                    ) {
                        if (!params.data.active) return "removed";

                        let status: CreditCardStatus = this.getCreditCardStatus(
                            params.data as CreditCardAccount
                        );
                        if (status == CreditCardStatus.EXPIRING)
                            return "expiring";
                        if (status == CreditCardStatus.EXPIRED)
                            return "expired";
                    } else if (
                        params.data.paymentAccountType ==
                        PaymentAccountType.BANK
                    )
                        return params.data.isActive ? "active" : "removed";

                    return params.data.active ? "active" : "removed";
                },
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.accountStatusTemplate
                }
            },
            // {
            //     colId: "addendumId",
            //     headerName: "Addendum ID",
            //     hide: true
            // },
            // {
            //     colId: "debits",
            //     headerName: "Debits",
            //     hide: true
            // },
            {
                colId: "default",
                headerName: "Default",
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                valueGetter: (params: ValueGetterParams) => {
                    if (this.isDefaultAccount(params.data)) return true;
                    else return false;
                },
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.accountDefaultTemplate
                },
                sortingOrder: ["desc", "asc"]
            },
            {
                colId: "actions",
                headerName: "",
                headerComponent: TemplateRendererComponent,
                headerComponentParams: {
                    ngTemplate: this.accountActionsHeaderTemplate
                },
                minWidth: 35,
                width: 35,
                maxWidth: 35,
                sortable: false,
                cellClass: "account-actions-cell",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.accountActionsTemplate
                }
            }
        ];

        this._allColumns = [
            {
                uniqueId: "label",
                displayName: "Account Name",
                width: 350,
                visible: true,
                alwaysVisible: true
            },
            {
                uniqueId: "type",
                displayName: "Account Type",
                width: 150,
                visible: true,
                alwaysVisible: true
            },
            {
                uniqueId: "name",
                displayName: "Name on Account",
                width: 350,
                visible: true,
                alwaysVisible: true
            },
            {
                uniqueId: "details",
                displayName: "Account Details",
                width: 350,
                visible: true,
                alwaysVisible: true
            },
            {
                uniqueId: "status",
                displayName: "Status",
                width: 100,
                visible: true,
                alwaysVisible: true
            },
            // {
            //     uniqueId: "addendumId",
            //     displayName: "Addendum ID",
            //     width: 150,
            //     visible: false
            // },
            // {
            //     uniqueId: "debits",
            //     displayName: "Allow Debits",
            //     width: 100,
            //     visible: false
            // },
            {
                uniqueId: "actions",
                displayName: "Account Actions",
                width: 350,
                visible: true,
                alwaysVisible: true
            }
        ];
    }

    private _getVisibleColumns() {
        let visibleColumns: CustomizableColumn[] = [];
        this._allColumns.forEach((col) => {
            if (col.visible) {
                visibleColumns.push(col);
            }
        });
        return visibleColumns;
    }

    private _markVisibleColumns(visibleColumns: CustomizableColumn[]) {
        this._allColumns.forEach((col) => {
            let foundCol = this._findColumn(col.uniqueId, visibleColumns);
            col.visible = !!foundCol;
        });
    }

    private _findColumn(uniqueId: string, list: CustomizableColumn[]) {
        let filter = { uniqueId: uniqueId };
        return list.find((element) => {
            return element.uniqueId == uniqueId;
        });
    }

    private _getSavedColumns() {
        this._columnsService
            .getColumnLayout(
                this._pageColumnsKey,
                this._allColumns,
                this._columnSetName
            )
            .then((columns: CustomizableColumn[]) => {
                const cols = columns ? columns : this._allColumns;
                //column service doesn't save visible status - but since column chooser dialog only returns visible columns, we know these are visible
                cols.forEach((col) => {
                    col.visible = true;
                });
                this._markVisibleColumns(cols);
            });
    }

    private _setCurrentColumns(columns: CustomizableColumn[]) {
        columns.map((column) => {
            let foundCol = this._gridColumnDefs.find(
                (colDef) => colDef.colId === column.uniqueId
            );
            if (foundCol) {
                if (isFinite(column.width as number))
                    foundCol.width = column.width as number;
                if (this.columnApi) {
                    this.columnApi.setColumnVisible(
                        foundCol.colId,
                        column.visible
                    );
                }
            }

            return foundCol;
        });
        if (this.gridApi) this.gridApi.sizeColumnsToFit();
    }

    private _setGridOptions() {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                suppressMovable: true
            },
            columnDefs: this._gridColumnDefs,
            // rowHeight: 80,
            getRowHeight: this._getRowHeight,
            //domLayout: "autoHeight",
            suppressCellFocus: false,
            suppressRowClickSelection: true,
            context: this,
            onGridSizeChanged: (event: GridSizeChangedEvent) => {
                if (this.gridApi) {
                    this.gridApi.sizeColumnsToFit();
                }
            },
            onGridReady: (event: GridReadyEvent) => {
                this.gridApi = event.api;
                this.columnApi = event.columnApi;
                this._setCurrentColumns(this._allColumns);
            },
            onCellKeyDown(
                event: CellKeyDownEvent | FullWidthCellKeyDownEvent
            ) {
                if (event.hasOwnProperty("colDef")) {
                    let cellEvent: CellKeyDownEvent =
                        event as CellKeyDownEvent;
                    let keyEvent: KeyboardEvent =
                        cellEvent.event as KeyboardEvent;
                    if (
                        keyEvent.key == KeyCode.SPACE ||
                        keyEvent.key == KeyCode.ENTER
                    ) {
                        if (
                            cellEvent.colDef.colId == "label" ||
                            cellEvent.colDef.colId == "name"
                        ) {
                            this.context.editAccount(cellEvent.data);
                        }
                        if (cellEvent.colDef.colId == "actions") {
                            keyEvent.preventDefault();
                            let dropdown: NgbDropdown =
                                this.context.actionDropdowns.find(
                                    (item: any) => {
                                        return (
                                            item._elementRef.nativeElement.id ==
                                            "dropdown-" +
                                                cellEvent.data.paymentAccountID
                                        );
                                    }
                                );
                            if (dropdown.isOpen()) {
                                dropdown.close();
                            } else {
                                dropdown.open();
                                setTimeout(() => {
                                    //alert("firstMenuItem-" + cellEvent.data.paymentAccountID);
                                    document
                                        .getElementById(
                                            "firstMenuItem-" +
                                                cellEvent.data.paymentAccountID
                                        )
                                        .focus();
                                });
                            }
                        }
                    }
                }
            },
            onSortChanged(event: SortChangedEvent): void {
                let sort: any = event.columnApi.getColumnState()
                    .filter((s) => s.sort !== null)
                    .map((s) => ({colId: s.colId, sort: s.sort, sortIndex: s.sortIndex}))

                this.context._configurationService
                    .setUserSettingCustom(
                        this.context._username,
                        this.context._accountsSortKey,
                        JSON.stringify(sort)
                    )
                    .subscribe();
                this.context._savedSort = sort;
            },
            onRowDataChanged(event: RowDataChangedEvent): void {
                let sort: any = this.context._savedSort
                    ? this.context._savedSort
                    : [{ colId: "label", sort: "asc" }];
                //put in timeout to avoid race condition with data - and it wouldn't be sorted
                setTimeout(() => {
                    event.columnApi.applyColumnState({ state: sort });
                }, 100);
            }
        };
    }

    private _getRowHeight(params: any): number {
        if (params.data.paymentAccountType == PaymentAccountType.BANK) {
            switch (params.data.accountType) {
                case PaymentAccountType.CHECKING:
                case PaymentAccountType.GENERAL_LEDGER:
                case PaymentAccountType.SAVINGS:
                    return 81;

                case PaymentAccountType.E_CHECK:
                    if (params.data.accountName) return 108;
                    else return 81;

                case PaymentAccountType.FROST_BANK:
                    if (
                        params.data.expiration &&
                        params.data.expiration.month &&
                        params.data.expiration.year
                    )
                        return 81;
                    else return 54;
            }
        }
        if (params.data.paymentAccountType == PaymentAccountType.CREDIT_CARD) {
            return 54;
        }

        //default
        return 81;
    }

    private _loadOrganizationInfo() {
        this.gridApi = null;
        this.hasSubmitter = false;
        this.hasRecordingPlus = false;
        this.hasRecipient = false;
        this.hasDocBuilder = false;
        this.hasSignEvents = false;
        this.hasNotary = false;

        //missing account info, if needed
        this._userOrgService
            .currentOrgMissingPaymentAccount(this.organizationId)
            .subscribe(
                (result: any) => {
                    this.missingAccountInfo = result;
                },
                () => {}
            );

        this._userOrgService
            .getOrganizationEntitlements(this.organizationId)
            .pipe(first())
            .subscribe((entitlements: OrganizationEntitlement[]) => {
                entitlements.forEach((entitlement: any) => {
                    switch (entitlement.product) {
                        case "SUBMITTER":
                            this.hasSubmitter = true;
                            this._getDefaultOptions(entitlement.product);
                            this.allowSubmitterFeeInvoicing =
                                entitlement.transactionFeeInvoicingAllowed;
                            this.allowSubmitterLicenseInvoicing =
                                entitlement.licenseFeeInvoicingAllowed;
                            break;
                        case "SUBMITTER_SIGNING":
                            this.hasDocBuilder = true;
                            this._getDefaultOptions(entitlement.product);
                            this.allowDocBuilderFeeInvoicing =
                                entitlement.transactionFeeInvoicingAllowed;
                            this.allowDocBuilderLicenseInvoicing =
                                entitlement.licenseFeeInvoicingAllowed;
                            break;
                        case "SUBMITTER_PAPER":
                            this.hasRecordingPlus = true;
                            this._getDefaultOptions(entitlement.product);
                            break;
                        case "RECIPIENT":
                            this.hasRecipient = true;
                            this._getDefaultOptions(entitlement.product);
                            break;
                        case "ESIGN_EVENT":
                            this.hasSignEvents = true;
                            this._getDefaultOptions(entitlement.product);
                            this.allowEEFeeInvoicing =
                                entitlement.transactionFeeInvoicingAllowed;
                            this.allowEELicenseInvoicing =
                                entitlement.licenseFeeInvoicingAllowed;
                            break;
                        case "NOTARY":
                            this.hasNotary = true;
                            this._getDefaultOptions(entitlement.product);
                            break;
                    }
                });
                this._checkContracts();
            });
        this._loadOrganizationAccounts();
        this._orgService
            .getOrganization(this.organizationId)
            .subscribe((org: Organization) => {
                // update status in header, if status has changed
                this._currentOrgService.updateCurrentOrganization(org);
            });
    }

    private _checkContracts() {
        let licenseFee: number;
        let renewalFee: number;
        this.showSubmitterLicenseFee = true;
        this.showDocBuilderLicenseFee = true;
    }

    private _getDefaultOptions(product: string) {
        this._paymentAccountsService
            .getDefaultPaymentOptions(this.organizationId, product)
            .pipe(first())
            .subscribe((options) => {
                this.defaultPaymentOptions[product] = options;
            });
    }

    private _loadOrganizationAccounts() {
        this._clearAccounts();
        this._getPaymentAccounts();
    }

    private _clearAccounts() {
        this.paymentAccountsLoaded = false;
        this.paymentAccounts = [];
        this.defaultAccounts = [];
    }

    private _getPaymentAccounts() {
        this.hasSalesTax = false;
        this._submitterOrgService
            .getSubmitterOrganizationConfigPayments(
                this.organizationId,
                true,
                true
            )
            .subscribe((config: any) => {
                this.hasSalesTax =
                    config["Sales Tax"] &&
                    config["Sales Tax"].is_sales_tax_enabled;

                this.spinnerService.startSpinner();
                this._paymentAccountsService
                    .getPaymentAccounts([this.organizationId], false)
                    .pipe(first())
                    .subscribe((accounts: any) => {
                        this.spinnerService.stopSpinner();
                        this.paymentAccounts = []; //reset PS-8060
                        this.numActiveBankAccounts = 0;
                        this.numActiveCardAccounts = 0;
                        let orgAccounts: any =
                            accounts.paymentAccountsMap[this.organizationId];
                        orgAccounts.bankAccounts.forEach(
                            (account: PaymentAccount) => {
                                let bankAccount: BankAccount =
                                    account as BankAccount;
                                //fields not common to PaymentAccount
                                account.paymentAccountID =
                                    bankAccount.bankAccountID;
                                account.label = bankAccount.accountLabel;
                                this.paymentAccounts.push(
                                    account as BankAccount
                                );
                                if (bankAccount.isActive)
                                    this.numActiveBankAccounts++;
                            }
                        );
                        orgAccounts.creditCardAccounts.forEach(
                            (account: PaymentAccount) => {
                                let ccAccount: CreditCardAccount =
                                    account as CreditCardAccount;
                                //fields not common to PaymentAccount
                                this.paymentAccounts.push(
                                    account as CreditCardAccount
                                );
                                if (ccAccount.active)
                                    this.numActiveCardAccounts++;
                            }
                        );
                        orgAccounts.invoiceAccounts.forEach(
                            (account: PaymentAccount) => {
                                this.paymentAccounts.push(account);
                            }
                        );
                        this.paymentAccountsLoaded = true;
                        //this.paymentAccounts = accounts;
                        this._setAccountViewProperties();
                        this.defaultAccounts = this._getAccountsByStatus(false);
                        //sort defaults by name before adding "special" options
                        this.defaultAccounts = this.defaultAccounts.sort(
                            (acct1: any, acct2: any): number => {
                                return acct1.viewLabel.localeCompare(
                                    acct2.viewLabel
                                );
                            }
                        );
                        this._setFilteredAccounts();
                    });
            });
    }

    private _setAccountViewProperties() {
        this.paymentAccounts.forEach((account) => {
            switch (account.paymentAccountType) {
                case PaymentAccountType.BANK:
                    let bankAccount: BankAccount = account as BankAccount;
                    bankAccount.viewType = this._getAccountViewLabelByType(
                        bankAccount.accountType
                    );
                    bankAccount.viewLabel =
                        bankAccount.accountLabel +
                        " (" +
                        bankAccount.viewType +
                        " " +
                        bankAccount.accountNumber +
                        ")";
                    break;
                case PaymentAccountType.CREDIT_CARD:
                    let ccAccount: CreditCardAccount =
                        account as CreditCardAccount;
                    ccAccount.viewType = this._getAccountViewLabelByType(
                        ccAccount.paymentAccountType,
                        ccAccount.cardType
                    );
                    let addDebit: boolean =
                        ccAccount.cardType == CreditCardType.DEBIT;
                    ccAccount.viewLabel =
                        ccAccount.label +
                        (addDebit ? " (Debit Exp. " : " (Exp. ") +
                        this.getCreditCardExpirationDayjs(ccAccount).format(
                            "MM/YY)"
                        );
                    break;
                default:
                    account.viewType = this._getAccountViewLabelByType(
                        account.paymentAccountType
                    );
                    account.viewLabel = account.label;
                    break;
            }
        });
    }

    private _getAccountViewLabelByType(
        type: PaymentAccountType,
        cardType?: CreditCardType
    ): string {
        switch (type) {
            case PaymentAccountType.CHECKING:
                return "ACH";
            case PaymentAccountType.E_CHECK:
                return "eCheck";
            case PaymentAccountType.GENERAL_LEDGER:
                return "General Ledger";
            case PaymentAccountType.SAVINGS:
                return "Savings Account";
            case PaymentAccountType.FROST_BANK:
                return "LegalEase";
            case PaymentAccountType.INVOICE:
                return "Invoice";
            case PaymentAccountType.CREDIT_CARD:
                // fixme
                switch (cardType) {
                    case CreditCardType.CREDIT:
                        return "Credit Card";
                    case CreditCardType.DEBIT:
                        return "Debit Card";
                    case CreditCardType.PREPAID:
                        return "Prepaid Credit Card";
                    case CreditCardType.UNKNOWN:
                        return "Unknown Card Type";
                }
                break;
        }
    }

    private _setFilteredAccounts() {
        this.filteredAccounts = this._getAccountsByStatus(this.showArchived);
        this.hasAccounts =
            this.filteredAccounts && this.filteredAccounts.length > 0;
        if (!this.hasAccounts && this._showGrowl) {
            this._growlService.info(
                "There aren't any removed payment accounts to show."
            );
        }
        if (this.filteredAccounts.length < 4) {
            this.gridDivStyle.height =
                this.filteredAccounts.length * 120 + "px";
        } else {
            this.gridDivStyle.height = "400px";
        }
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
                this.gridApi.ensureIndexVisible(0, "top");
            }, 100);
        }
    }

    private _getAccountsByStatus(showArchived: boolean): PaymentAccount[] {
        let activeAccounts: PaymentAccount[] = [];
        if (this.paymentAccounts && this.paymentAccounts.length > 0) {
            activeAccounts = this.paymentAccounts.filter((account) => {
                if (account.paymentAccountType == PaymentAccountType.BANK)
                    return (account as BankAccount).isActive;
                else return account.active;
            });
        }
        this.numActiveAccounts = activeAccounts.length;
        if (showArchived) return this.paymentAccounts;
        else return activeAccounts;
    }

    private _checkPackages(
        product: string,
        fee: string,
        newAccount: PaymentAccount,
        oldAccount: PaymentAccount
    ) {
        //don't check packages for LICENSE fees
        if (fee === "LICENSE") return;

        //don't check packages for unrelated products
        if (product === "ESIGN_EVENT") return;

        // if old or new account is NONE, exit (do nothing)
        // if (
        //     oldAccount.paymentAccountType === PaymentAccountType.NONE ||
        //     newAccount.paymentAccountType === PaymentAccountType.NONE
        // ) {
        //     return;
        // }

        // if (oldAccount.paymentAccountType === PaymentAccountType.INVOICE) {
        //     oldAccount.paymentAccountID = null;
        // }
        // if (newAccount.paymentAccountType === PaymentAccountType.INVOICE) {
        //     newAccount.paymentAccountID = null;
        // }

        if (
            !!oldAccount &&
            oldAccount.paymentAccountType === PaymentAccountType.NONE
        ) {
            oldAccount.paymentAccountID = null;
        }

        //search packages using the old account
        this.feeSearched = fee;
        this.isSearchingPackages = true;
        let accountID: string =
            !!oldAccount &&
            oldAccount.paymentAccountType == PaymentAccountType.BANK
                ? (oldAccount as BankAccount).bankAccountID
                : oldAccount?.paymentAccountID
                ? oldAccount.paymentAccountID
                : null;
        this.spinnerService.startSpinner();
        this._paymentAccountsService
            .getEditablePackagesUsingPaymentAccount(
                oldAccount?.organizationID
                    ? oldAccount.organizationID
                    : this.organizationId,
                this._getTranslatedFee(fee),
                accountID
            )
            .pipe(first())
            .subscribe(
                (packages: any[]) => {
                    this.spinnerService.stopSpinner();
                    this.isSearchingPackages = false;
                    this.feeSearched = null;
                    if (packages && packages.length > 0) {
                        this._showPackageUpdateDialog(
                            product,
                            fee,
                            newAccount,
                            oldAccount,
                            packages
                        );
                    }
                },
                () => {
                    this.isSearchingPackages = false;
                    this.feeSearched = null;
                }
            );
    }

    private _getTranslatedFee(fee: string): string {
        switch (fee) {
            case "SALES_TAX":
                return "SalesTax";
            default:
                return fee;
        }
    }

    private _showPackageUpdateDialog(
        product: string,
        fee: string,
        newAccount: PaymentAccount,
        oldAccount: PaymentAccount,
        packages: any[]
    ) {
        const modalRef: NgbModalRef = this._modalService.open(
            UpdatePackagesDefaultAccountDialogComponent,
            {
                backdrop: "static",
                size: "lg"
            }
        );
        const modalInstance = modalRef.componentInstance;

        if (!newAccount.hasOwnProperty("organizationID")) {
            newAccount.organizationID = this.organizationId;
        }
        modalInstance.newAccount = newAccount;
        modalInstance.oldAccount = oldAccount;
        modalInstance.fee = fee;
        modalInstance.feeLabel = this.getLabelForFee(fee);
        modalInstance.product = product;
        modalInstance.packages = packages;

        modalRef.result.then(
            (unaffectedPackageMessages: any) => {
                if (
                    unaffectedPackageMessages &&
                    unaffectedPackageMessages != "secondary" &&
                    unaffectedPackageMessages.length > 0
                ) {
                    this._showUnaffectedPackagesDialog(
                        unaffectedPackageMessages
                    );
                }
            },
            () => {
                //canceled, do nothing
            }
        );
    }

    private _showUnaffectedPackagesDialog(unaffectedPackageMessages: any[]) {
        const modalRef: NgbModalRef = this._modalService.open(
            UnaffectedPackageUpdateDialogComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.unaffectedPackageMessages = unaffectedPackageMessages;

        modalRef.result.then(() => {
            //do nothing
        });
    }

    private _accountIsDefaultForFees(
        product: string,
        account: PaymentAccount
    ): boolean {
        let result: boolean = false;
        this.feeCategories[product].forEach((fee: any) => {
            if (
                this.getDefaultAccountID(fee, product) ===
                account.paymentAccountID
            ) {
                result = true;
            }
        });

        return result;
    }

    private _getFeesForDefaultAccount(
        product: string,
        account: PaymentAccount
    ): string[] {
        let result: string[] = [];
        this.feeCategories[product].forEach((fee: any) => {
            if (
                this.getDefaultAccountID(fee, product) ===
                account.paymentAccountID
            ) {
                //don't want to include the account as a default for license fee if we aren't showing that category
                if (product === "SUBMITTER" && fee === "LICENSE") {
                    if (this.showSubmitterLicenseFee) {
                        result.push(fee);
                    }
                } else if (
                    product === "SUBMITTER_SIGNING" &&
                    fee === "LICENSE"
                ) {
                    if (this.showDocBuilderLicenseFee) {
                        result.push(fee);
                    }
                } else {
                    result.push(fee);
                }
            }
        });
        return result;
    }

    private _showCannotArchiveAccount(account: PaymentAccount, packages: any[]) {
        const modalRef: NgbModalRef = this._modalService.open(
            PackagesUsingAccountArchivedDialogComponent,
            { backdrop: "static", size: "lg"}
        );
        const modalInstance: any = modalRef.componentInstance;

        modalInstance.account = account;
        modalInstance.packages = packages;

        modalRef.result.then(() => {}, () => {});
    }

    private _finalizeArchive(account: PaymentAccount) {
        let sort: any = this._savedSort
            ? this._savedSort
            : [{ colId: "label", sort: "asc" }];
        //put in timeout to avoid race condition with data - and it wouldn't be sorted
        setTimeout(() => {
            this.columnApi?.applyColumnState({state: sort});
        }, 100);

        let accountID: string = account.paymentAccountID;
        let accountLabel: string = account.label;
        if (account.paymentAccountType == PaymentAccountType.BANK) {
            accountID = (account as BankAccount).bankAccountID;
            accountLabel = (account as BankAccount).accountLabel;
        }
        this._paymentAccountsService
            .archivePaymentAccount(this.organizationId, accountID)
            .pipe(first())
            .subscribe(
                () => {
                    this._growlService.success(
                        "Payment account: " + accountLabel + " was removed."
                    );
                    this._loadOrganizationInfo(); //updates defaults too
                },
                (error) => {
                    this._growlService.error(error.error.errorMessage, null, {
                        disableTimeOut: true
                    });
                }
            );
    }

    private _showNextStep() {
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = "Payment Account Added";
        modalInstance.primary = {
            text: "Let's Go"
        };
        modalInstance.secondary = {
            hidden: true
        };
        modalInstance.message =
            "Now that a payment account has been added, you are ready to start eRecording!";

        modalRef.result.then(
            (result: any) => {
                if (result === "primary") {
                    this._userOrgService.clearCachedOrgsAndUsers();
                    this._paymentNavTickersService.refreshMissingPaymentCount();
                    this._router.navigate(["/submitter/packages"]);
                }
            },
            () => {
                //if a user exits from this dialog rather than hit the primary button, this is what happens
                this._router.navigate(["/submitter/payments/manage"]);
            }
        );
    }

    caseInsensitiveComparator(valA: string, valB: string) {
        return valA.toLowerCase().localeCompare(valB.toLowerCase());
    }

    getCreditCardExpirationDayjs(account: CreditCardAccount): dayjs.Dayjs {
        return dayjs(account.expMonth + "/" + account.expYear, "M/YYYY");
    }

    getCreditCardStatus(account: CreditCardAccount): CreditCardStatus {
        let expDate: dayjs.Dayjs = this.getCreditCardExpirationDayjs(account);
        let now: dayjs.Dayjs = dayjs();

        //check this first because "isBefore()" will give false positive because there is no time
        if (
            expDate.year() == now.year() &&
            (expDate.month() == now.month() ||
                expDate.month() - now.month() == 1)
        )
            return CreditCardStatus.EXPIRING;

        if (expDate.isBefore(now)) return CreditCardStatus.EXPIRED;

        return CreditCardStatus.GOOD;
    }

    /** PUBLIC FUNCTIONS **/

    /**
     * Handler called when org selector fires
     * @param org - Organization object selected
     */
    /*
    setSelectedOrg(org: Organization) {
        if (org && org.id != this._prevOrganizationId) {
            this._prevOrganizationId = org.id;
            //if component initialized, this is a selector change and need to reload
            if (this._initialized) {
                this._initializeWithOrgID(org.id);
            }
        }
    }
    */

    editAccount(account: PaymentAccount) {
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "A Simplifile employee cannot add or edit payment accounts while impersonating a user."
            );
            return;
        }

        const modalRef: NgbModalRef = this._modalService.open(
            AddEditPaymentAccountDialogComponent,
            {
                backdrop: "static",
                windowClass: "payment-account-dialog"
            }
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.paymentAccount = account;
        modalRef.result.then(
            () => {
                if (
                    account.paymentAccountType ===
                    PaymentAccountType.CREDIT_CARD
                ) {
                    let status: CreditCardStatus = this.getCreditCardStatus(
                        account as CreditCardAccount
                    );
                    if (
                        status === CreditCardStatus.EXPIRED ||
                        status === CreditCardStatus.EXPIRING
                    ) {
                        this._paymentNavTickersService.refreshCardCounts();
                        this._userOrgService.clearCachedOrgsAndUsers();
                    }
                }
                this._growlService.success("Payment account modified", null);
                this._loadOrganizationAccounts();
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.CREDIT_CARD_EXPIRED
                );
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.CREDIT_CARD_EXPIRING
                );
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.PAYMENT_ACCOUNTS
                );
            },
            () => {
                //dialog canceled
            }
        );
    }

    copyID(accountId: string) {
        let selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = accountId;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this._growlService.success("ID copied", null, { timeOut: 2000 });
    }

    toggleShowArchived() {
        this._showGrowl = !this.showArchived;
        this.showArchived = !this.showArchived;
        this._setFilteredAccounts();
    }

    confirmArchive(account: PaymentAccount, archive: boolean) {
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        const action: string = archive ? "Remove" : "Activate";

        modalInstance.title = "Confirm " + action;
        modalInstance.primary = {
            text: action + " Account"
            //callback: this.removeAccount.bind(this, account)
        };
        modalInstance.secondary = {
            text: "Cancel"
        };
        modalInstance.message =
            "Are you sure you want to " +
            action.toLowerCase() +
            " payment account: <br/><br/><b>" +
            account.viewLabel +
            "</b>?";

        modalRef.result.then(
            (result: any) => {
                if (result === "primary") {
                    if (archive) {
                        this.archiveAccount(account);
                    } else {
                        this.unarchiveAccount(account);
                    }
                }
            },
            () => {}
        );
    }

    showAboutDialog(type: string) {
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.hideSecondary = true;

        switch (type) {
            case "payment":
                modalInstance.title = "About Payment Accounts";
                modalInstance.message =
                    "Simplifile uses the Automated Clearing House (ACH) network, credit card transactions, and eChecks to collect fees and associated taxes. <br/><br/>" +
                    "You may add as many accounts as you wish. You can choose to use one account for the payment of all fees or you may set up " +
                    "multiple accounts for specific fees. Regardless of the default selections, any account may be assigned for payment of a " +
                    "specific fee for a given transaction. <br/><br/>" +
                    "By adding an account, you grant Simplifile permission to withdraw funds to pay the fees as they accrue.";
                break;
            case "erecord":
                modalInstance.title = "About Recording Fees";
                let text: string =
                    "Recording fees are charged by and collected on behalf of the county recorder and include fees for the recording of " +
                    "documents, non-conforming, eRecording, and rejection in the amount prescribed by law. <br/><br/>" +
                    "<b>Recording Fee: </b>Fee charged by the county to record a document. Some jurisdictions will also include taxes collected " +
                    "at recording in this category. <br/><br/>" +
                    "<b>Tax: </b>Tax collected by the county when a document is recorded. <br/><br/>";
                if (!this.hasRecordingPlus) {
                    text =
                        text +
                        "<b>Submission Fee: </b>Simplifile per document/package eRecording fee. <br/><br/>";
                } else {
                    text =
                        text +
                        "<b>Submission Fee: </b>Simplifile per document/package recording for In-State eRecording, Out-of-State eRecording, In-State Paper Recording, and Out-of-State Paper Recording.<br/><br/>";
                }
                text =
                    text +
                    "<b>Sales Tax: </b>Sales tax due on the Simplifile submission fee, if applicable. The sales tax rate is based on where " +
                    "your company resides. <br/><br/>" +
                    "<b>License Fee: </b>Your yearly eRecording license fee, if applicable.";
                if (this.hasRecordingPlus) {
                    text =
                        text +
                        "<br/><br/><b>Mail Fee: </b>Simplifile per document fee to mail recorded documents to the customer.";
                }
                modalInstance.message = text;
                break;
            case "docbuilder":
                modalInstance.title = "About Document Builder Fees";
                modalInstance.message =
                    "Document Builder fees are collected by Simplifile for each document created in the Document Builder service and " +
                    "successfully sent to Simplfile eRecording. These documents are created from a template or submitted via the print " +
                    "driver, and are typically eSigned and eNotarized. <br/><br/>" +
                    "<b>Document Fee: </b>Simplifile document creation from template fee. <br/><br/>" +
                    //"<b>Sales Tax: </b>Sales tax due, if applicable. The sales tax rate is based on where your company resides. <br/><br/>" +
                    "<b>License Fee: </b>Your yearly Document Builder license fee, if applicable.";
                break;
            case "recordingPlus":
                modalInstance.title = "About Recording PLUS Fees";
                modalInstance.message =
                    "<b>Submission Fees:</b> Simplifile per document/package recording fee for In-State eRecording, Out-of-State eRecording, " +
                    "In-State Paper Recording and Out-of-State Paper Recording. " +
                    "<br/><br/>" +
                    "<b>Mail Fee:</b> Simplifile per document fee to mail the recorded documents to the consumer." +
                    "<br/><br/>" +
                    "<b>Embedded Employee Fee:</b> Simplifile fee for providing a fully trained recording specialist to the consumer's location. " +
                    "Embedded employee requires a minimum package amount per month and may be removed at Simplifile's discretion if the required volume is not met.";
                break;
            case "sign-events":
                modalInstance.title = "About eSign Events Fees";
                modalInstance.message =
                    "eSign Events fees are collected by Simplifile for each eNotary event in the eSign Events service. <br/><br/>" +
                    "<b>No eNotarization Fee: </b>Included in the license fee. <br/><br/>" +
                    "<b>IPEN Notary Fee: </b>Per event charge for In-Person Electronic Notarization. <br/><br/>" +
                    "<b>RON Notary Fee: </b>Per event charge for Remote Online Notarization. <br/><br/>" +
                    "<b>Renewal License Fee: </b>Your monthly or yearly eSign Events license fee, if applicable.";
                break;
            case "notary":
                modalInstance.title = "About Notary Fees";
                modalInstance.message =
                    "Notary fees will be charged when ordering through any partnered Notary Service to supply any " +
                    "number of services offered around tagging and signing documents that may require notarization.";
        }

        modalRef.result.then(
            () => {},
            () => {}
        );
    }

    showImportDialog() {
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "A Simplifile employee cannot add or edit payment accounts while impersonating a user."
            );
            return;
        }

        const modalRef: NgbModalRef = this._modalService.open(
            ImportPaymentAccountDialogComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.organizationId = this.organizationId;

        modalRef.result.then(
            (button: string) => {
                if (button == "secondary") return;
                this._bannerService.removeBannerOfCategory(
                    BannerNotificationCategory.PAYMENT_ACCOUNTS
                );
                this._loadOrganizationInfo(); //updates defaults too
            },
            () => {}
        );
    }

    archiveAccount(account: PaymentAccount) {
        //check if any packages are using payment account being archived
        let accountID: string =
            account.paymentAccountType == PaymentAccountType.BANK
                ? (account as BankAccount).bankAccountID
                : account.paymentAccountID;

        let obs: Observable<unknown>[] = [];
        this.feeCategories["SUBMITTER"].map((fee: any) => {
            obs.push(
                this._paymentAccountsService
                    .getEditablePackagesUsingPaymentAccount(
                        account.organizationID,
                        this._getTranslatedFee(fee),
                        accountID
                    )
                    .pipe(
                        map((packagesForFee: any[]) => {
                            return { fee, packagesForFee };
                        })
                    )
            );
        });

        //check doc_builder fees
        this.feeCategories["SUBMITTER_SIGNING"].map((fee: any) => {
            obs.push(
                this._paymentAccountsService
                    .getEditablePackagesUsingPaymentAccount(
                        account.organizationID,
                        this._getTranslatedFee(fee),
                        accountID
                    )
                    .pipe(
                        map((packagesForFee: any[]) => {
                            return { fee, packagesForFee };
                        })
                    )
            );
        });

        forkJoin(obs).subscribe((results: any[]) => {
            if (results && results.length > 0) {
                //show packages using account and stop archival process
                let uniquePackages: any[] = [];
                results.forEach((result: any) => {
                    if (result.packagesForFee && result.packagesForFee.length > 0) {
                        result.packagesForFee.forEach((pkg: any) => {
                            let found: boolean = false;
                            uniquePackages.forEach((uPkg: any) => {
                                if (uPkg.id === pkg.id) {
                                    found = true;
                                }
                            });
                            if (!found) {
                                uniquePackages.push(pkg);
                            }
                        });
                    }
                });
                if (uniquePackages.length > 0) {
                    this._showCannotArchiveAccount(account, uniquePackages);
                } else {
                    this._removeDefaultsForAccount(account);
                }
            } else {
                this._removeDefaultsForAccount(account);
            }
        });
    }

    private _removeDefaultsForAccount(account: PaymentAccount) {
        let feesUsingAccount: string[] = this._getFeesForDefaultAccount(
            "SUBMITTER",
            account
        );
        const obs: Observable<unknown>[] = [];
        feesUsingAccount.forEach((fee: string) => {
            obs.push(
                this._paymentAccountsService.saveDefaultPaymentOption(
                    this.organizationId,
                    "SUBMITTER",
                    fee,
                    null,
                    null
                )
            );
        });
        feesUsingAccount = this._getFeesForDefaultAccount(
            "SUBMITTER_SIGNING",
            account
        );
        feesUsingAccount.forEach((fee: string) => {
            obs.push(
                this._paymentAccountsService.saveDefaultPaymentOption(
                    this.organizationId,
                    "SUBMITTER_SIGNING",
                    fee,
                    null,
                    null
                )
            );
        });
        feesUsingAccount = this._getFeesForDefaultAccount(
            "ESIGN_EVENT",
            account
        );
        feesUsingAccount.forEach((fee: string) => {
            obs.push(
                this._paymentAccountsService.saveDefaultPaymentOption(
                    this.organizationId,
                    "ESIGN_EVENT",
                    fee,
                    null,
                    null
                )
            );
        });
        feesUsingAccount = this._getFeesForDefaultAccount(
            "SUBMITTER_PAPER",
            account
        );
        feesUsingAccount.forEach((fee: string) => {
            obs.push(
                this._paymentAccountsService.saveDefaultPaymentOption(
                    this.organizationId,
                    "SUBMITTER_PAPER",
                    fee,
                    null,
                    null
                )
            );
        });
        feesUsingAccount = this._getFeesForDefaultAccount("NOTARY", account);
        feesUsingAccount.forEach((fee: string) => {
            obs.push(
                this._paymentAccountsService.saveDefaultPaymentOption(
                    this.organizationId,
                    "NOTARY",
                    fee,
                    null,
                    null
                )
            );
        });
        if (obs.length > 0) {
            merge(...obs)
                .pipe(
                    //want the obs to run (remove default account for each fee), but reduce to one call of the logic in the subscribe
                    reduce((x, y) => null)
                )
                .subscribe(() => {
                    this._growlService.warning(
                        "Fee default payment account(s) have been updated where account: " +
                            account.viewLabel +
                            " was set. " +
                            "Please review current defaults and update, if necessary.",
                        null
                    );

                    this._getDefaultOptions("SUBMITTER");
                    this._getDefaultOptions("SUBMITTER_SIGNING");
                    this._getDefaultOptions("ESIGN_EVENT");
                    this._getDefaultOptions("SUBMITTER_PAPER");
                    this._getDefaultOptions("NOTARY");

                    this._finalizeArchive(account);
                });
        } else {
            this._finalizeArchive(account);
        }
    }

    unarchiveAccount(account: PaymentAccount) {
        let accountID: string = account.paymentAccountID;
        let accountLabel: string = account.label;
        if (account.paymentAccountType == PaymentAccountType.BANK) {
            accountID = (account as BankAccount).bankAccountID;
            accountLabel = (account as BankAccount).accountLabel;
        }

        this._paymentAccountsService
            .unarchivePaymentAccount(accountID)
            .pipe(first())
            .subscribe(
                () => {
                    this._growlService.success(
                        "Payment account: " + accountLabel + " was activated."
                    );
                    this._loadOrganizationInfo(); //updates defaults too
                },
                (error) => {
                    this._growlService.error(error.error.errorMessage, null, {
                        disableTimeOut: true
                    });
                }
            );
    }

    customizeColumns() {
        const visibleColumns = this._getVisibleColumns();
        const modalRef: NgbModalRef = this._modalService.open(
            ChooseColumnsDialogComponent,
            {
                backdrop: "static",
                size: "sm"
            }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.currentColumns = visibleColumns;
        modalInstance.allColumns = this._allColumns;
        modalInstance.allowReorder = false;
        modalInstance.allowColumnSets = false;

        modalRef.result.then((columns: CustomizableColumn[]) => {
            //this returns the visible columns only
            if (columns) {
                //order important here - mark visible columns first, then use all columns to set grid
                this._markVisibleColumns(columns);
                this._setCurrentColumns(this._allColumns);
                this._columnsService.saveCustomColumns(
                    this._pageColumnsKey,
                    this._columnSetName,
                    columns
                );
            }
        });
    }

    getLabelForFee(fee: string): string {
        switch (fee) {
            case "DOCUMENT_BUILDER":
                return "Document Fee";
            case "SALES_TAX":
                return "Sales Tax";
            case "TAX":
                return "Tax";
            case "FILE_TRANSFER":
                return "File Transfer Fee";
            case "LICENSE":
                return "License Fee";
            case "EE_RON":
                return "RON Notary Fee";
            case "EE_IPEN":
                return "IPEN Notary Fee";
            case "EE_NO_ENOTARY":
                return "No eNotarization Fee";
            case "PAPER_MAIL":
                return "Mail Fee";
            case "NOTARY_DOCUMENT":
                return "Notary Fees";

            default:
                return capitalize(fee.toLowerCase()) + " Fee";
        }
    }

    getDefaultAccountID(fee: string, product: string): string {
        if (
            !this.defaultPaymentOptions ||
            !this.defaultPaymentOptions[product] ||
            this.defaultPaymentOptions[product].length == 0
        )
            return "none";

        let defaultOption: any[] = this.defaultPaymentOptions[product].filter(
            (option: any) => {
                return option.category === fee;
            }
        );
        if (defaultOption[0]) {
            if (fee == "LICENSE") {
                if (product == "SUBMITTER")
                    this._lastSubmitterLicenseSelection =
                        defaultOption[0].accountID;
                if (product == "SUBMITTER_SIGNING")
                    this._lastDocBuilderLicenseSelection =
                        defaultOption[0].accountID;
            }

            return defaultOption[0].accountID;
        }

        return "none";
    }

    getPlaceholderText(fee: string, product: string): string {
        let txt: string = "No Default";
        //covers case where license default = INVOICE, but non super-users can't select; otherwise, should find
        //a matching account in the list
        if (
            product == "SUBMITTER" &&
            this.allowSubmitterLicenseInvoicing &&
            !this.isSuperUser
        ) {
            if (
                this.getDefaultAccountID(fee, product) ==
                this._simplifileInvoiceAccountID
            )
                txt = "Invoice";
        }

        return txt;
    }

    handleDefaultAccountSelected(event: any, fee: string, product: string) {
        if (!event.$isSelectionChanged) return;
        if (!event.$selection) return;

        let account: any = event.$selection;
        let accountID: string =
            account.paymentAccountType == PaymentAccountType.BANK
                ? (account as BankAccount).bankAccountID
                : account.paymentAccountID;
        //sf-select may fire on initial render with same account even though nothing was changed, so this is a double-check
        if (accountID === this.getDefaultAccountID(fee, product)) return;

        if (account.paymentAccountType == PaymentAccountType.NONE) {
            account.paymentAccountID = null;
            account.paymentAccountType = null;
        }
        // if (account.paymentAccountType == PaymentAccountType.INVOICE) {
        //     account.paymentAccountID = null;
        // }

        this._paymentAccountsService
            .saveDefaultPaymentOption(
                this.organizationId,
                product,
                fee,
                account.paymentAccountType,
                account.paymentAccountID
            )
            .pipe(take(1))
            .subscribe(
                (message) => {
                    if (message) {
                        this._growlService.error(message);
                        //if message is defined, the save didn't happen - so reset select list back to original value
                        setTimeout(() => {
                            if (fee == "LICENSE") {
                                if (product == "SUBMITTER") {
                                    this.submitterSelects.last.clearSelection();
                                    this.submitterSelects.last.selectStore.dispatch(
                                        setSelectedItems(
                                            this._lastSubmitterLicenseSelection
                                        )
                                    );
                                }
                                if (product == "SUBMITTER_SIGNING") {
                                    this.docBuilderSelects.last.clearSelection();
                                    this.docBuilderSelects.last.selectStore.dispatch(
                                        setSelectedItems(
                                            this._lastDocBuilderLicenseSelection
                                        )
                                    );
                                }
                            }
                        }, 100);
                    } else {
                        this._growlService.success(
                            "Default payment account saved for: " +
                                this.getLabelForFee(fee),
                            null
                        );
                        this._bannerService.recheckCategory(
                            BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT
                        );

                        //recalculate home page url by getting it (should be different after adding the account)
                        this._userOrgService
                            .getHomePageURL()
                            .pipe(first())
                            .subscribe((url: string) => {
                                if (url)
                                    this._sessionService.setHomePageUrl(url);
                            });

                        //get old payment account for fee
                        let oldAccountID: string = this.getDefaultAccountID(
                            fee,
                            product
                        );
                        let oldAccount: PaymentAccount =
                            this.getDefaultAccounts(fee, product).find(
                                (account: any) => {
                                    return (
                                        account.paymentAccountID ===
                                        oldAccountID
                                    );
                                }
                            );

                        //update defaults for product - AFTER getting old account
                        this._getDefaultOptions(product);

                        this._checkPackages(product, fee, account, oldAccount);

                        let sort: any = this._savedSort
                            ? this._savedSort
                            : [{ colId: "label", sort: "asc" }];
                        //put in timeout to avoid race condition with data - and it wouldn't be sorted
                        setTimeout(() => {
                            this.columnApi?.applyColumnState({ state: sort });
                        }, 100);
                    }
                },
                () => {
                    this._growlService.error("Failed to save default account.");
                }
            );
    }

    addPaymentAccount() {
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "A Simplifile employee cannot add or edit payment accounts while impersonating a user."
            );
            return;
        }

        const modalRef: NgbModalRef = this._modalService.open(
            AddEditPaymentAccountDialogComponent,
            {
                backdrop: "static",
                windowClass: "payment-account-dialog"
            }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.orgId = this.organizationId;
        modalInstance.hasSubmitter = this.hasSubmitter;
        modalInstance.hasRecordingPlus = this.hasRecordingPlus;
        modalInstance.hasEsignEvents = this.hasSignEvents;
        modalInstance.hasDocBuilder = this.hasDocBuilder;
        modalInstance.hasNotary = this.hasNotary;
        let wasZeroDayState: boolean = false;
        if (this.isZeroDayState()) {
            modalInstance.initialAccountType = this.initialAccountType;
            wasZeroDayState = true;
        }
        modalRef.result.then(
            (addedAccountType: PaymentAccountType) => {
                this._growlService.success("Payment account added", null);
                //flag for if added account is "bank" type
                let isBankTypeAccount: boolean =
                    addedAccountType == PaymentAccountType.BANK ||
                    addedAccountType == PaymentAccountType.CHECKING ||
                    addedAccountType == PaymentAccountType.SAVINGS ||
                    addedAccountType == PaymentAccountType.GENERAL_LEDGER ||
                    addedAccountType == PaymentAccountType.E_CHECK ||
                    addedAccountType == PaymentAccountType.FROST_BANK;
                //this check is for non-zero day state (i.e. paid license fee with CC when org setup)
                if (
                    this.numActiveBankAccounts == 0 &&
                    this.numActiveCardAccounts >= 1 &&
                    isBankTypeAccount
                )
                    wasZeroDayState = true;
                if (!this.hasAccounts || wasZeroDayState) {
                    //no active accounts
                    this._paymentNavTickersService.refreshMissingPaymentCount();
                    //so package list page shows something now
                    this._sessionService.setOrganizationSetUp(
                        this.organizationId
                    );
                    //recalculate home page url by getting it (should be different after adding the account)
                    this._userOrgService
                        .getHomePageURL()
                        .pipe(first())
                        .subscribe((url: string) => {
                            if (url) this._sessionService.setHomePageUrl(url);
                        });
                    //if not super-user, reset initial account type and show dialog for next steps
                    if (!this.isSuperUser) {
                        //only want to show the next dialog if the account added was of bank type
                        if (
                            isBankTypeAccount &&
                            this.hasSubmitter &&
                            this.missingAccountInfo?.product == "SUBMITTER"
                        )
                            if (
                                this._sessionService.hasPermission(
                                    "submitter_package_viewer",
                                    this.organizationId
                                )
                            ) {
                                this._showNextStep();
                            }
                        this.initialAccountType = PaymentAccountType.BANK;
                    }
                }
                this._loadOrganizationInfo();
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.CREDIT_CARD_EXPIRED
                );
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.CREDIT_CARD_EXPIRING
                );
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.PAYMENT_ACCOUNTS
                );
                this._bannerService.recheckCategory(
                    BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT
                );
            },
            () => {
                //dialog canceled
            }
        );
    }

    goToPayments() {
        this._router.navigate(["/submitter/payments/pending-payments"]);
    }

    goToSalesTax() {
        if (this.isSuperUser) {
            // Fixme: Old AngularJS route?
            this._router.navigate([
                "/admin/organization/" +
                    this.organizationId +
                    "/submitter/salesTax"
            ]);
        } else {
            // Fixme: Old AngularJS route?
            this._router.navigate([
                "/submitter/organization/" +
                    this.organizationId +
                    "/e-record/salesTax"
            ]);
        }
    }

    goToPaymentSettings() {
        this._router.navigate([
            "/admin/organization/" +
                this.organizationId +
                "/submitter/payment-options"
        ]);
    }

    goToDocBuilderSettings() {
        this._router.navigate([
            "/admin/organization/" +
                this.organizationId +
                "/document-builder/configuration"
        ]);
    }

    goToEESettings() {
        this._router.navigate([
            "/admin/organization/" +
                this.organizationId +
                "/sign-event/settings"
        ]);
    }

    isDefaultAccount(data: PaymentAccount): boolean {
        let result: boolean = false;
        //checks all product defaults
        Object.keys(this.defaultPaymentOptions).forEach((key: string) => {
            this.defaultPaymentOptions[key].forEach((option: any) => {
                let accounts: any[] = this.getDefaultAccounts(
                    option.category,
                    key
                );
                accounts.forEach((account: any) => {
                    if (
                        data.paymentAccountID === account.paymentAccountID &&
                        data.paymentAccountID === option.accountID
                    )
                        result = true;
                });
            });
        });
        return result;
    }

    getTooltipForFee(product: string, fee: string): string {
        switch (product) {
            case "SUBMITTER":
                // fixme
                switch (fee) {
                    case "LICENSE":
                        return "Your yearly eRecording license fee, if applicable";
                    case "SALES_TAX":
                        return "Sales tax due on the Simplifile submission fee, if applicable. The sales tax rate is based on where your company resides.";
                    case "RECORDING":
                        return "Fee charged by the county to record a document. Some jurisdictions will also include taxes collected at recording in this category.";
                    case "SUBMISSION":
                        return this.hasRecordingPlus
                            ? "Simplifile per document/package recording for In-State eRecording, Out-of-State eRecording, In-State Paper Recording, and Out-of-State Paper Recording"
                            : "Simplifile per document/package eRecording fee.";
                    case "TAX":
                        return "Tax collected by the county when a document is recorded.";
                }
                break;
            case "SUBMITTER_SIGNING":
                // fixme
                switch (fee) {
                    case "LICENSE":
                        return "Your yearly Document Builder license fee, if applicable";
                    case "DOCUMENT_BUILDER":
                        return "Simplifile document creation from template fee";
                }
                break;
            case "ESIGN_EVENT":
                // fixme
                switch (fee) {
                    case "LICENSE":
                        return "Your monthly or yearly eSign Events license fee, if applicable";
                }
                break;
            case "SUBMITTER_PAPER":
                switch (fee) {
                    case "PAPER_MAIL":
                        return "Simplifile per document fee to mail recorded documents to the customer";
                }
        }
    }

    getDefaultAccounts(fee: string, product: string): any[] {
        let defaults: any[] = this.defaultAccounts;

        //filter cards out based on live config and fee type
        defaults = defaults.filter((acct: any) => {
            if (acct.paymentAccountType == PaymentAccountType.CREDIT_CARD) {
                if (
                    fee == "LICENSE" ||
                    ((product == "SUBMITTER" || product == "SUBMITTER_PAPER") &&
                        this.orgEnabledForCards)
                ) {
                    return true;
                }

                //don't allow credit cards anywhere else (doc builder)
                return false;
            }

            return true;
        });

        //filter out non-ACH accounts for doc builder fees
        defaults = defaults.filter((acct: any) => {
            if (product == "SUBMITTER_SIGNING" || product == "ESIGN_EVENT") {
                if (
                    fee == "DOCUMENT_BUILDER" ||
                    fee == "EE_RON" ||
                    fee == "EE_IPEN" ||
                    fee == "EE_NO_ENOTARY"
                ) {
                    return (
                        (acct.paymentAccountType == PaymentAccountType.BANK &&
                            acct.accountType != PaymentAccountType.E_CHECK) ||
                        acct.paymentAccountType == PaymentAccountType.INVOICE
                    );
                }
                if (fee == "LICENSE") {
                    return (
                        (acct.paymentAccountType == PaymentAccountType.BANK &&
                            acct.accountType != PaymentAccountType.E_CHECK) ||
                        acct.paymentAccountType == PaymentAccountType.INVOICE ||
                        acct.paymentAccountType ==
                            PaymentAccountType.CREDIT_CARD
                    );
                }
            }

            //only allow ACH
            if (product == "NOTARY") {
                return (
                    acct.paymentAccountType == PaymentAccountType.BANK &&
                    acct.accountType != PaymentAccountType.E_CHECK
                );
            }

            return true;
        });

        //map accounts to group and mark invalid if necessary
        defaults.forEach((acct: any) => {
            acct.invalid = false;
            acct.group = "Accounts";
            if (acct.paymentAccountType == PaymentAccountType.CREDIT_CARD) {
                if (
                    this.getCreditCardStatus(acct as CreditCardAccount) ==
                    CreditCardStatus.EXPIRED
                ) {
                    acct.invalid = true;
                    acct.group = "Expired Cards";
                }
            }
        });

        //sort accounts by validity, so expired cards are on bottom
        defaults = defaults.sort((a: any, b: any) =>
            a.invalid > b.invalid ? 1 : -1
        );

        //add in INVOICE option if setting allows for product
        if (
            (product === "SUBMITTER" &&
                fee !== "LICENSE" &&
                this.allowSubmitterFeeInvoicing) ||
            (product === "SUBMITTER" &&
                fee === "LICENSE" &&
                this.allowSubmitterLicenseInvoicing) ||
            (product === "SUBMITTER_SIGNING" &&
                fee !== "LICENSE" &&
                this.allowDocBuilderFeeInvoicing) ||
            (product === "SUBMITTER_SIGNING" &&
                fee === "LICENSE" &&
                this.allowDocBuilderLicenseInvoicing) ||
            (product === "ESIGN_EVENT" &&
                fee !== "LICENSE" &&
                this.allowEEFeeInvoicing) ||
            (product === "ESIGN_EVENT" &&
                fee === "LICENSE" &&
                this.allowEELicenseInvoicing) ||
            (product === "SUBMITTER_PAPER" &&
                fee === "PAPER_MAIL" &&
                this.allowSubmitterFeeInvoicing)
        ) {
            defaults = defaults.concat([
                {
                    id: "invoice",
                    viewLabel: "Invoice",
                    paymentAccountID: this._simplifileInvoiceAccountID,
                    //accountType: "invoice",
                    paymentAccountType: PaymentAccountType.INVOICE,
                    organizationID: this.organizationId,
                    group: "Accounts",
                    invalid: false
                }
            ]);
        }

        //add in NONE option if not license fee
        if (
            fee !== "LICENSE" &&
            product !== "SUBMITTER_SIGNING" &&
            product !== "ESIGN_EVENT" &&
            product !== "NOTARY"
        ) {
            defaults = defaults.concat([
                {
                    id: "none",
                    viewLabel: "No Default",
                    paymentAccountID: "none",
                    //accountType: "none",
                    paymentAccountType: PaymentAccountType.NONE,
                    group: "Accounts",
                    invalid: false
                }
            ]);
        }

        return defaults;
    }

    canRemoveAccount(account: PaymentAccount): boolean {
        let submitterFeeDefaults: string[] = this._getFeesForDefaultAccount(
            "SUBMITTER",
            account
        );
        let docBuilderFeeDefaults: string[] = this._getFeesForDefaultAccount(
            "SUBMITTER_SIGNING",
            account
        );
        let esignEventFeeDefaults: string[] = this._getFeesForDefaultAccount(
            "ESIGN_EVENT",
            account
        );
        let paperFeeDefaults: string[] = this._getFeesForDefaultAccount(
            "SUBMITTER_PAPER",
            account
        );
        let notaryDefaults: string[] = this._getFeesForDefaultAccount(
            "NOTARY",
            account
        );

        //not a default anywhere - if a default, user should be setting it to Invoice (if allowed) or choosing another account first
        if (
            submitterFeeDefaults.length == 0 &&
            docBuilderFeeDefaults.length == 0 &&
            esignEventFeeDefaults.length == 0 &&
            paperFeeDefaults.length == 0 &&
            notaryDefaults.length == 0
        ) {
            //mostly for regular users, as long as it isn't the last account they can remove it.
            if (this.numActiveAccounts > 1) return true;

            //this allows accounting to remove the last payment account
            if (this.hasAccountingPermission && this.numActiveAccounts == 1)
                return true;

            return false;
        }

        return false;
    }

    isZeroDayState(): boolean {
        if (!this.isSuperUser) {
            if (this.paymentAccountsLoaded) {
                if (
                    this.missingAccountInfo?.product == "SUBMITTER" ||
                    this.missingAccountInfo?.product == "SUBMITTER_PAPER"
                ) {
                    this.showCard = true;
                } else {
                    this.showCard = false;
                }
                if (
                    this.numActiveCardAccounts == 1 &&
                    this.numActiveBankAccounts == 0
                ) {
                    this.showCard = false;
                    return !!this.missingAccountInfo;
                }
                return !!this.missingAccountInfo;
            }
        }

        return false;
    }

    showFee(fee: string, product: string): boolean {
        if (fee != "SALES_TAX" && fee != "LICENSE") return true;

        if (fee == "SALES_TAX" && product == "SUBMITTER" && this.hasSalesTax)
            return true;

        if (fee == "LICENSE") {
            //     if (product == "SUBMITTER" && this.showSubmitterLicenseFee)
            //         return true;
            //     if (product == "SUBMITTER_SIGNING" && this.showDocBuilderLicenseFee)
            //         return true;
            return true;
        }

        return false;
    }

    missingDefault(product: string, fee: string): boolean {
        if (fee != "LICENSE") return false;
        let licDefault: string = this.getDefaultAccountID(fee, product);
        return licDefault == "none";
    }

    pickInitialOrg() {
        let orgs = this._sessionService.getAllOrganizations();
        let filteredOrgs = orgs.filter((org) => {
            return (
                this._sessionService.hasAnyPermission(
                    ["organization_accounting", "organization_users"],
                    org.id
                ) &&
                org.isEnabled &&
                (this._sessionService.hasProduct(org.id, "submitter") ||
                    this._sessionService.hasProduct(org.id, "capc") ||
                    this._sessionService.hasProduct(org.id, "esign_event") ||
                    this._sessionService.hasProduct(org.id, "notary") ||
                    this._sessionService.hasProduct(org.id, "trustee"))
            );
        });
        if (filteredOrgs && filteredOrgs.length) {
            this._currentOrgService.updateCurrentOrganization(
                filteredOrgs[0] as unknown as Organization
            );
        }
    }
}
