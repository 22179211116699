import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "oxfordComma"
})
export class OxfordCommaPipe implements PipeTransform {
    // Type Guard
    private static _isArray(input: any): input is Array<string> {
        return Array.isArray(input);
    }

    transform(input: string | string[], ...args: unknown[]): string {
        if (!input) {
            return "";
        }

        if (OxfordCommaPipe._isArray(input)) {
            const inputArr: string[] = input as string[];
            if (inputArr.length === 0) {
                return "";
            }

            if (inputArr.length === 1) {
                return inputArr[0];
            }

            if (inputArr.length === 2) {
                return inputArr[0] + " and " + inputArr[1];
            }

            return (
                inputArr.slice(0, inputArr.length - 1).join(", ") +
                ", and " +
                inputArr[inputArr.length - 1]
            );
        } else {
            return input;
        }
    }
}
