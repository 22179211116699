import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { RpcClientService, SessionService } from "@sf/common";
import { CurrentOrganizationService } from "@sf/userorg/common";
import { Organization } from "@sf/userorg/common";
import { PersistentDataStack, PersistentDataStackService } from "@sf/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class RecentOrganizationsService {
    private _orgStack: PersistentDataStack;

    constructor(
        private _rpc: RpcClientService,
        private _currentOrgService: CurrentOrganizationService,
        private sessionService: SessionService,
        private persistentDataStackService: PersistentDataStackService
    ) {
        this._orgStack = this.persistentDataStackService.createNewStack("sfAdminOrganizationStack", 9);

        // when the 'current' org changes, push it on our 'recent' list
        this._currentOrgService.currentOrganization$.subscribe((org) => {
            if (org && !this.sessionService.isLoggedInAs()) {
                this.pushRecentOrganizationID(org.id);
            }
        });
    }

    getPastRecentOrganizations(): Observable<Organization[]> {
        return this.getPastRecentOrganizationIDs(10)
                   .pipe(mergeMap(
                       (orgIDs) => this._rpc.makeRequest<Organization[]>("userorg", "getOrganizations", { orgIDs })));
    }

    getPastRecentOrganizationsForList(): Observable<Organization[]> {
        return this.getPastRecentOrganizationIDs()
                   .pipe(mergeMap(
                       (orgIDs) => this._rpc.makeRequest<Organization[]>("userorg", "getOrganizationsForList",
                           { orgIDs })));
    }

    getPastRecentOrganizationIDs(count?: number): Observable<string[]> {
        return from(this._orgStack.last(count) as Promise<string[]>);
    }

    pushRecentOrganizationID(orgID: string): void {
        if (orgID && orgID.length >= 6) {
            this._orgStack.push(orgID);
        }
    }

    getLastRecentOrganizationID(): Observable<string> {
        return from(this._orgStack.peek() as Promise<string>);
    }

    pullRecentOrganizationIDs(orgIDs: string[]): void {
        this._orgStack.pullMultiple(orgIDs);
    }
}
