import {
    Component,
    ComponentFactoryResolver,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FieldState } from "../../interfaces";
import { COMPONENT_FROM_FIELD } from "../../helpers/fields.helpers";
import { InputComponent } from "../../components/input/input.component";

import { Subject } from "rxjs";
import { takeUntil, scan } from "rxjs/operators";
import { GroupFieldComponent } from "../group-field/group-field.component";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-root-field",
    templateUrl: "./root-field.component.html",
    styleUrls: ["./root-field.component.scss"]
})
export class RootFieldComponent
    extends InputComponent
    implements OnInit, OnChanges, OnDestroy
{
    private _destroy$ = new Subject<void>();
    @Input() useColumnLayout: boolean;

    @ViewChild("groupHost", { read: ViewContainerRef, static: true })
    groupHost: ViewContainerRef;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        private _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit(): void {
        this._facade
            .getRootVisibleFields()
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.id),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) =>
                this.loadComponents(current, previous)
            );
    }

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        // this.groupHost.clear();
        let nextIndex = 0;
        let currentFields = controls.map((field) => field.id);

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this.groupHost.remove(index);
                previous.splice(index, 1);
            }
        }

        for (let field of controls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                this._createNewComponent(field, nextIndex);
            }
            nextIndex++;
        }
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentType = this._getComponentFromField(field);
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                componentType
            );

        const component = this.groupHost.createComponent<InputComponent>(
            componentFactory,
            index
        );
        const instance = component.instance;
        instance.useColumnLayout = this.useColumnLayout;
        instance.field = field;
        instance.controlPath = field.path;

        if (instance instanceof GroupFieldComponent) {
            instance.isInnerGroup = true;
        }
    }
}
