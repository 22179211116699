export const STANDARD_ERRORS: {
    [type: string]: (error: any, label: string) => string;
} = {
    required: (error: any, label: string) => `${label} is required`,
    requiredTrue: (error: any, label: string) => `${label} is required`,
    email: (error: any, label: string) => `Invalid email`,
    minLength: (error: any, label: string) =>
        `${label} must be longer than ${error.requiredLength}`,
    maxLength: (error: any, label: string) =>
        `${label} must be shorter than ${error.requiredLength}`,
    pattern: (error: any, label: string) => `${label} is invalid`,
    url: (error: any, label: string) => `Incorrectly formatted URL`,
    numeric: (error: any, label: string) => `You can only use numbers`,
    min: (error: any, label: string) =>
        `${label} cannot be less than ${error.min}`,
    max: (error: any, label: string) =>
        `${label} cannot be more than ${error.max}`,
    custom: (error: any, label: string) =>
        error.message && error.message.length > 0
            ? `${error.message}`
            : `${label} is invalid`
};
