<div class="select-holder">
    <sf-select
        id="sf-state-selector"
        name="sf-state-selector"
        [class]="windowClass"
        [ngClass]=""
        selectorName="sf-state-selector"
        [placeholder]="placeholder"
        searchPlaceholder="Filter"
        [hasFilter]="true"
        [filterIcon]="['far', 'filter']"
        [filterDebounce]="300"
        [handleFilterExternally]="true"
        [isSingleSelect]="!isMultiSelect"
        [selectedOption]="selectedStateID"
        [selectedOptions]="selectedStateIDs"
        [options]="selectableItems"
        [hasClearButton]="isMultiSelect || canClearSelectedState"
        trackBy="abbrev"
        labelBy="name"
        (filter)="getStates($event)"
        (select)="handleStateSelection($event)"
        [required]="required"
        [isDisabled]="isDisabled"
    ></sf-select>

    <!-- Hidden input that allows browser autofill to populate the state selector -->
    <input type="text" id="state" name="state" class="hide" tabindex="-1"
        (change)="hiddenStateChange($event)"/>
</div>
