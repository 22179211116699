<div class="sf-select-testing">
    <h1>Sf-Select Testing Page</h1>

    <div class="sf-select-instance">
        <h2>Single Select</h2>
        <p>Simple single select, no extra options.</p>
        <sf-select
            id="single-select"
            [options]="sfSelectInstances.single.config.options"
            (select)="sfSelectInstances.single.config.onSelect($event)"
            [isSingleSelect]="true"
        ></sf-select>
        <h5>Output</h5>
        <pre id="single-select-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Multiple Select</h2>
        <p>Simple multiple select, no extra options.</p>
        <sf-select
            id="multiple-select"
            [options]="sfSelectInstances.multiple.config.options"
            (select)="sfSelectInstances.multiple.config.onSelect($event)"
            [isSingleSelect]="false"
        ></sf-select>
        <h5>Output</h5>
        <pre id="multiple-select-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Single Select with Filter</h2>
        <p>Single select with the option to filter using internal sfSelect filtering.</p>
        <sf-select
            id="single-filter-select"
            [options]="sfSelectInstances.singleFilter.config.options"
            (select)="sfSelectInstances.singleFilter.config.onSelect($event)"
            [isSingleSelect]="true"
            [hasFilter]="true"
        ></sf-select>
        <h5>Output</h5>
        <pre id="single-filter-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Single Select with Filter (and disabled items)</h2>
        <p>Single select with the option to filter using internal sfSelect filtering.</p>
        <sf-select
            id="single-filter-select-with-disabled"
            [options]="sfSelectInstances.singleFilterWithDisabled.config.options"
            disableBy="disabled"
            (select)="sfSelectInstances.singleFilterWithDisabled.config.onSelect($event)"
            [isSingleSelect]="true"
            [hasFilter]="true"
        ></sf-select>
        <h5>Output</h5>
        <pre id="single-filter-with-disabled-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Multiple Select with Filter</h2>
        <p>Multiple select with the option to filter using internal sfSelect filtering.</p>
        <sf-select
            id="multiple-filter-select"
            [options]="sfSelectInstances.multipleFilter.config.options"
            (select)="sfSelectInstances.multipleFilter.config.onSelect($event)"
            [isSingleSelect]="false"
            [hasFilter]="true"
        ></sf-select>
        <h5>Output</h5>
        <pre id="multiple-filter-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Single Select with Grouping</h2>
        <p>Single select with Grouping.</p>
        <sf-select
            id="single-grouping-select"
            [options]="sfSelectInstances.singleGrouping.config.options"
            (select)="sfSelectInstances.singleGrouping.config.onSelect($event)"
            [isSingleSelect]="true"
            groupBy="category"
        ></sf-select>
        <h5>Output</h5>
        <pre id="single-grouping-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Multiple Select with Grouping</h2>
        <p>Multiple select with Grouping.</p>
        <sf-select
            id="multiple-grouping-select"
            [options]="sfSelectInstances.multipleGrouping.config.options"
            (select)="sfSelectInstances.multipleGrouping.config.onSelect($event)"
            [isSingleSelect]="false"
            groupBy="category"
        ></sf-select>
        <h5>Output</h5>
        <pre id="multiple-grouping-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Long Single Select</h2>
        <p>A single select with many options (1000 items).</p>
        <sf-select
            id="long-single-select"
            [options]="sfSelectInstances.longSingle.config.options"
            (select)="sfSelectInstances.longSingle.config.onSelect($event)"
            [isSingleSelect]="true"
        ></sf-select>
        <h5>Output</h5>
        <pre id="long-single-onSelect-output"></pre>
    </div>

    <div class="sf-select-instance">
        <h2>Long Multiple Select</h2>
        <p>A multiple select with many options (1000 items).</p>
        <sf-select
            id="long-multiple-select"
            [options]="sfSelectInstances.longMultiple.config.options"
            (select)="sfSelectInstances.longMultiple.config.onSelect($event)"
            [isSingleSelect]="false"
        ></sf-select>
        <h5>Output</h5>
        <pre id="long-multiple-onSelect-output"></pre>
    </div>

</div>
