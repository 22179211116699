import { Component, OnDestroy, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { FormBuilder, FormGroup } from "@angular/forms";
import { dayjs } from "@sf/common";
import { deepEqual, scheduleMicroTask } from "@sf/common";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { DateRangePreset } from "@sf/common";
import { DateRangeFieldViewState } from "../../interfaces/dynamic-form-state";

@Component({
    selector: "sf-date-range-field",
    templateUrl: "./date-range-field.component.html",
    styleUrls: ["./date-range-field.component.scss"]
})
export class DateRangeFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy
{
    private _destroy = new Subject<void>();
    dummyForm: FormGroup = this._fb.group({
        daterange: [
            {
                start: null,
                end: null
            }
        ]
    });

    isSimple: boolean;
    presets$: Observable<DateRangePreset[]>;

    constructor(
        private _fb: FormBuilder,
        protected _formFacade: DynamicFormStore
    ) {
        super(_formFacade);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loadControl();
        this.presets$ = this._formFacade
            .getViewStateForField(this.field.fullPath)
            .pipe(
                map((viewState: DateRangeFieldViewState) => {
                    return viewState.dateRangePresets;
                })
            );
    }

    ngOnDestroy() {
        this._destroy.next();
    }

    loadControl() {
        this.formControl$
            .pipe(takeUntil(this._destroy))
            .subscribe((control) => {
                let daterange = this._getValue(control.value);
                this.dummyForm = this._fb.group({
                    daterange
                });

                if (control.disabled) {
                    setTimeout(() => {
                        this.dummyForm.disable();
                    });
                }

                this.dummyForm
                    .get("daterange")
                    .valueChanges.pipe(takeUntil(this._destroy))
                    .subscribe((daterange: any) => {
                        let newValue = this._getValueFromForm(daterange);
                        if (!deepEqual(control.value, newValue)) {
                            scheduleMicroTask(() => {
                                control.setValue(newValue);
                            });
                        }
                    });

                control.valueChanges
                    .pipe(takeUntil(this._destroy))
                    .subscribe((values) => {
                        let currentValue = this._getValueFromForm(
                            this.dummyForm.get("daterange").value
                        );
                        if (!deepEqual(currentValue, values)) {
                            this.dummyForm.setValue({
                                daterange: this._getValue(values)
                            });
                        }
                    });

                control.statusChanges
                    .pipe(takeUntil(this._destroy))
                    .subscribe((status) => {
                        if (status === "DISABLED") {
                            this.dummyForm.disable();
                        } else {
                            this.dummyForm.enable();
                        }
                    });
            });
    }

    checkRequired(): boolean {
        if (this.field.defaults.formState.validators.length > 0) {
            return this.field.defaults.formState.validators.some(
                (validator) => validator.identifier === "required"
            );
        }
        return false;
    }

    private _getValue(value: any) {
        if (value && typeof value === "object") {
            value = {
                preset: value.preset,
                start: value.start,
                end: value.end
            };
            if (!dayjs.isDayjs(value.start)) {
                value.start = dayjs(value.start, [
                    "YYYY-MM-DD",
                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                ]);
            }
            if (!dayjs.isDayjs(value.end)) {
                value.end = dayjs(value.end, ["YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss.SSSZ"]);
            }
        } else if (typeof value === "string") {
            value = {
                preset: value
            };
        }

        return value;
    }

    private _getValueFromForm(daterange: any) {
        if (daterange != null) {
            if (daterange.preset && daterange.preset !== "custom") {
                return {
                    preset: daterange.preset
                };
            } else if (daterange.start && daterange.end) {
                return {
                    start: daterange.start.clone().utc().toISOString(),
                    end: daterange.end.clone().utc().toISOString()
                };
            }
        }

        return null;
    }
}
