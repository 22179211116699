<sf-custom-form-viewer-new
    *ngIf="customForm && customForm.customFormID && !transforming"
    [docLeftData]="documentData"
    [formId]="customForm.customFormID"
    [orgID]="orgID"
    [userType]="userType"
    [form]="dynamicForm"
    [definition]="definition"
    (onSave)="onFormSaved($event)"
    (currentFormGroup)="setCurrentGroup($event)"
    (visibleValuesOnly)="visibleValues($event)"
></sf-custom-form-viewer-new>
