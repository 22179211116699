import { Injectable } from "@angular/core";
import {
    DetailedFees,
    FeeData,
    FeeSchedule,
    OtherFees,
    RecordingFeeDetails
} from "../interfaces/detailed-fees.interface";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { SubmitterAccountType } from "../enums/submitter-account-type.enum";
import { FeeStatus } from "../enums/fee-status.enum";
import { TotaledFees } from "../interfaces/totaled-fees.interface";
import { SubmitterDocument } from "../interfaces/submitter-document.interface";
import { SubmitterPackage } from "../interfaces/submitter-package.interface";
import { SessionService } from "@sf/common";
import { DOC_STATUS } from "../enums/document-status.enum";
import { RecipientConfig } from "../interfaces/recipient-config.interface";
import { SubmitterFeeType } from "../enums/submitter-fee-type.enum";

const EDITABLE_DOCUMENT_STATUSES = [
    DOC_STATUS.DRAFT,
    DOC_STATUS.READY,
    DOC_STATUS.REJECTED,
    DOC_STATUS.CONVERTED
];

const FINAL_FEE_STATUSES = [
    DOC_STATUS.RECORDED,
    DOC_STATUS.VOIDED,
    DOC_STATUS.BILLED_REJECTED
];

const isQuantityInAmountColumn: any = {
    [SubmitterFeeType.OPTIONAL_FEE]: false,
    [SubmitterFeeType.ADDITIONAL_FEE]: true,
    [SubmitterFeeType.KIND_OF_INSTRUMENT_FEE]: true,
    [SubmitterFeeType.QUANTITY_EXPRESSION_FEE]: false,
    [SubmitterFeeType.AMOUNT_EXPRESSION_FEE]: true
};

@Injectable({
    providedIn: "root"
})
export class SubmitterFeeService {
    private ERECORD = "erecord";
    private PAYMENT = "payment";

    // Type Guard
    private static _isDocumentIDObject(
        obj: any
    ): obj is { [documentID: string]: FeeStatus } {
        return typeof obj === "object" && Object.values(obj).length > 0;
    }

    private static _calcFee(feeItem: FeeSchedule) {
        return feeItem.amount * feeItem.quantity;
    }

    constructor(
        private _rpcClient: RpcClientService,
        private _sessionService: SessionService
    ) {}

    getDetailedFees(packageID: string): Observable<DetailedFees> {
        return this._rpcClient.makeRequest("erecord", "getDetailedFees", {
            id: packageID
        });
    }

    updateDocumentFeeDetails(documentID: string, feeData: any) {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "updateDetailedFeesFromDocument",
            {
                documentID: documentID,
                feeData: feeData
            }
        );
    }

    updateFeeDetailsSubmitter(
        packageID: string,
        documentID: string,
        recipientFees: { feeDetails: FeeData; recipientID: string }[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "updateFeeDetailsSubmitter",
            {
                packageID: packageID,
                documentID: documentID,
                feeData: {
                    recipientFees
                }
            }
        );
    }

    areFeesEditable(doc: SubmitterDocument, pkg: SubmitterPackage): boolean {
        if (pkg.currentOwnerId !== pkg.submitterID) {
            return false;
        }

        if (
            !this._sessionService.hasPermission(
                "erecord_modify_recording_fees",
                pkg.submitterID
            )
        ) {
            return false;
        }

        if (!EDITABLE_DOCUMENT_STATUSES.includes(doc.status as DOC_STATUS)) {
            return false;
        }

        return !pkg.isArchived;
    }

    areFeesEditableByRecipient(
        doc: SubmitterDocument,
        pkg: SubmitterPackage,
        recipientConfig: RecipientConfig
    ): boolean {
        return (
            this.areFeesEditable(doc, pkg) &&
            recipientConfig.submitter_can_edit_fees
        );
    }

    areAnyFeeItemsEditable(
        doc: SubmitterDocument,
        pkg: SubmitterPackage,
        recipientFeeData: FeeData,
        recipientConfig: RecipientConfig
    ): boolean {
        if (!this.areFeesEditable(doc, pkg)) {
            return false;
        }

        if (
            this.isOtherFeeVisible(recipientFeeData.otherFees, recipientConfig)
        ) {
            return true;
        }

        if (
            recipientFeeData.feeSchedule.some((feeItem) =>
                this.isLeftEditable(feeItem, doc, pkg, recipientConfig)
            )
        ) {
            return true;
        }

        return recipientFeeData.feeSchedule.some((feeItem) =>
            this.isRightEditable(feeItem, doc, pkg, recipientConfig)
        );
    }

    isOtherFeeVisible(
        otherFees: OtherFees,
        recipientConfig: RecipientConfig
    ): boolean {
        if (!recipientConfig.hide_other_fee) {
            return true;
        }
        if (!otherFees) {
            return false;
        }

        return parseFloat(otherFees.amount) !== 0;
    }

    isLeftEditable(
        feeItem: FeeSchedule,
        doc: SubmitterDocument,
        pkg: SubmitterPackage,
        recipientConfig: RecipientConfig
    ): boolean {
        return (
            this.areFeesEditable(doc, pkg) &&
            recipientConfig.submitter_can_edit_fees &&
            !this.isQuantityOnRight(feeItem) &&
            (feeItem.type !== SubmitterFeeType.QUANTITY_EXPRESSION_FEE ||
                feeItem.canOverride)
        );
    }

    isRightEditable(
        feeItem: FeeSchedule,
        doc: SubmitterDocument,
        pkg: SubmitterPackage,
        recipientConfig: RecipientConfig
    ): boolean {
        return (
            this.areFeesEditable(doc, pkg) &&
            recipientConfig.submitter_can_edit_fees &&
            this.isQuantityOnRight(feeItem) &&
            (feeItem.type !== SubmitterFeeType.AMOUNT_EXPRESSION_FEE ||
                feeItem.canOverride)
        );
    }

    isQuantityOnRight(feeItem: FeeSchedule): boolean {
        return isQuantityInAmountColumn[feeItem.type];
    }

    isFeeItemVisible(
        feeItem: FeeSchedule,
        doc: SubmitterDocument,
        pkg: SubmitterPackage
    ): boolean {
        const feeEditable = this.areFeesEditable(doc, pkg);
        if (feeItem.quantity !== 0) {
            return true;
        }

        if (feeItem.type === SubmitterFeeType.OPTIONAL_FEE && feeEditable) {
            return true;
        }

        if (feeItem.type === SubmitterFeeType.ADDITIONAL_FEE) {
            return true;
        }

        if (feeItem.type === SubmitterFeeType.KIND_OF_INSTRUMENT_FEE) {
            return true;
        }

        if (
            feeItem.type === SubmitterFeeType.QUANTITY_EXPRESSION_FEE &&
            feeEditable &&
            feeItem.canOverride
        ) {
            return true;
        }

        if (
            feeItem.type === SubmitterFeeType.AMOUNT_EXPRESSION_FEE &&
            feeEditable &&
            feeItem.canOverride
        ) {
            return true;
        }

        return false;
    }

    useEstimatedFees(doc: SubmitterDocument): boolean {
        return !FINAL_FEE_STATUSES.includes(doc.status as DOC_STATUS);
    }

    calculateTotalFeesForPackage(
        feeDetails: DetailedFees,
        STATUS: FeeStatus | { [documentID: string]: FeeStatus },
        groupSalesTaxWithSubmissionFee?: boolean
    ): TotaledFees {
        let result: any = {};

        result[SubmitterAccountType.SALES_TAX] = Object.values(feeDetails)
            .map((d) => d.salesTax)
            .reduce((a, b) => a + b, 0);

        result[SubmitterAccountType.SUBMISSION] = Object.values(feeDetails)
            .map((d) => d.submission)
            .reduce((a, b) => a + b, 0);

        result[SubmitterAccountType.MAIL] = Object.values(feeDetails)
            .map((d) => d.mail)
            .reduce((a, b) => a + b, 0);

        result[SubmitterAccountType.DOCUMENT_BUILDER] = Object.values(
            feeDetails
        )
            .map((d) => d.documentBuilder)
            .reduce((a, b) => a + b, 0);

        if (groupSalesTaxWithSubmissionFee) {
            result[SubmitterAccountType.SUBMISSION] +=
                result[SubmitterAccountType.SALES_TAX];
            result[SubmitterAccountType.SALES_TAX] = 0;
        }

        if (SubmitterFeeService._isDocumentIDObject(STATUS)) {
            //calculate based on status per document
            result[SubmitterAccountType.TAX] = 0;
            result[SubmitterAccountType.RECORDING] = 0;

            Object.entries(STATUS).forEach(([docID, status]) => {
                result[SubmitterAccountType.TAX] += this._calculateTaxFees(
                    feeDetails,
                    status,
                    docID
                );
                result[SubmitterAccountType.RECORDING] +=
                    this._calculateNonTaxFees(feeDetails, status, docID);
            });
        } else {
            //calculate as if all documents have same status
            result[SubmitterAccountType.TAX] = this._calculateTaxFees(
                feeDetails,
                STATUS
            );
            result[SubmitterAccountType.RECORDING] = this._calculateNonTaxFees(
                feeDetails,
                STATUS
            );
        }
        let totalSurcharge: number = 0;
        Object.values(feeDetails).forEach((item: any) => {
            Object.values(item.surchargeMap).forEach((val: number) => {
                totalSurcharge += val;
            });
        });
        result["Surcharge"] = totalSurcharge;
        result.total = Object.values(result).reduce(
            (a: number, b: number) => a + b,
            0
        );

        return result;
    }

    public calculateTotalFeesForDocument(
        feeDetails: DetailedFees,
        documentID: string,
        STATUS: FeeStatus
    ): TotaledFees {
        let result: any = {};
        result[SubmitterAccountType.RECORDING] = this._calculateNonTaxFees(
            feeDetails,
            STATUS,
            documentID
        );
        result[SubmitterAccountType.TAX] = this._calculateTaxFees(
            feeDetails,
            STATUS,
            documentID
        );
        result.total = Object.values(result).reduce(
            (a: number, b: number) => a + b,
            0
        );

        return result;
    }

    public isOrganizationEnabledForCreditCard(
        orgId: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.PAYMENT,
            "isOrganizationEnabledForCreditCard",
            {
                orgID: orgId
            }
        );
    }

    public getCreditCardFeeSchedule(state: string): Observable<any> {
        return this._rpcClient.makeRequest(
            this.PAYMENT,
            "getCreditCardFeeSchedule",
            {
                stateCode: state
            }
        );
    }

    private _calculateTaxFees(
        feeDetails: DetailedFees,
        STATUS: FeeStatus,
        documentID?: string
    ): number {
        return Number.parseFloat(
            String(
                this._getAllRecordingFeeSchedules(
                    feeDetails,
                    STATUS,
                    documentID
                )
                    .filter((schedule) => schedule != null)
                    .filter((schedule) => schedule.isTax)
                    .map((schedule) => SubmitterFeeService._calcFee(schedule))
                    .reduce((a: number, b: number) => a + b, 0)
            )
        );
    }

    private _calculateNonTaxFees(
        feeDetails: DetailedFees,
        STATUS: FeeStatus,
        documentID?: string
    ): number {
        const normalFees = Number.parseFloat(
            String(
                this._getAllRecordingFeeSchedules(
                    feeDetails,
                    STATUS,
                    documentID
                )
                    .filter((schedule) => schedule != null)
                    .filter((schedule: FeeSchedule) => !schedule.isTax)
                    .map((schedule: FeeSchedule) =>
                        SubmitterFeeService._calcFee(schedule)
                    )
                    .reduce((a: number, b: number) => a + b, 0)
            )
        );

        const otherFees = Number.parseFloat(
            String(
                this._getAllOtherRecordingFees(feeDetails, STATUS, documentID)
                    .filter((schedule) => schedule != null)
                    .map((oFees: OtherFees) => Number.parseFloat(oFees.amount))
                    .reduce((a: number, b: number) => a + b, 0)
            )
        );

        return normalFees + otherFees;
    }

    private _getAllOtherRecordingFees(
        feeDetails: DetailedFees,
        STATUS: FeeStatus,
        documentID?: string
    ): OtherFees[] {
        return this._getAllRecordingFeeDetails(
            feeDetails,
            STATUS,
            documentID
        ).map((fees: FeeData) => fees?.otherFees);
    }

    private _getAllRecordingFeeSchedules(
        feeDetails: DetailedFees,
        STATUS: FeeStatus,
        documentID?: string
    ): FeeSchedule[] {
        return this._getAllRecordingFeeDetails(feeDetails, STATUS, documentID)
            .map((fees: FeeData) => {
                return fees?.feeSchedule;
            })
            .flat();
    }

    private _getAllRecordingFeeDetails(
        feeDetails: DetailedFees,
        STATUS: FeeStatus,
        documentID?: string
    ) {
        let feeDetailsSubset = feeDetails;
        if (documentID) {
            feeDetailsSubset = {};
            feeDetailsSubset[documentID] = feeDetails[documentID];
        }

        return Object.values(feeDetailsSubset)
            .map((doc) => doc.recordingDetails)
            .map((fees) => Object.values(fees))
            .flat()
            .map((orgFees: RecordingFeeDetails) => orgFees[STATUS]);
    }
}
