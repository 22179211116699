<div class="d-flex flex-row" [ngClass]="{'expandable':field.expandable}">
    <div class="flex-grow-1">
        <ng-template [cdkPortalOutlet]="componentPortal" (attached)="attached($event)"></ng-template>
    </div>
    <div>
        <button
            class="remove-item"
            aria-label="Delete Group"
            (click)="remove()"
            *ngIf="!(disabled$ | async) && field.expandable">
            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </button>
    </div>
</div>
