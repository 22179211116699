import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {
    ContractFee,
    ContractFeeThresholdResetPeriod,
    ContractFeeTier,
    CurrentOrganizationService,
    UserOrgService
} from "@sf/userorg/common";
import { first } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EditContractPricingTiersDialogComponent } from "../../dialogs/edit-contract-pricing-tiers-dialog/edit-contract-pricing-tiers-dialog.component";
import { ConfirmationModalComponent } from "@sf/common";

@Component({
    selector: "sf-contract-pricing-tiers",
    templateUrl: "./contract-pricing-tiers.component.html",
    styleUrls: ["./contract-pricing-tiers.component.scss"]
})
export class ContractPricingTiersComponent implements OnInit, OnChanges {
    @Input()
    orgId: string;
    @Input()
    productId: string;
    @Input()
    contractFee: ContractFee;
    @Input()
    readOnly: boolean;
    @Input()
    thisRef: any;
    @Input()
    decimalPlaces: number;

    @Output()
    update: EventEmitter<any> = new EventEmitter(); // called on changes
    @Output()
    removeTiers: EventEmitter<any> = new EventEmitter(); // called on reset

    get indefiniteThreshold(): ContractFeeThresholdResetPeriod {
        return ContractFeeThresholdResetPeriod.INDEFINITE;
    }

    feeCopy: ContractFee = null;
    hasTiers: boolean = false;
    resetThresholdLabels: any = {
        INDEFINITE: "Indefinite",
        MONTH: "Monthly",
        QUARTER: "Quarterly",
        YEAR: "Yearly"
    };
    currDocCount: number = 0;
    prevDocCount: number = 0;
    activeTierIndex: number = -1;
    docTypeLabel: string = "documents";
    firstTime: boolean = true;

    constructor(
        private _userOrgService: UserOrgService,
        private _modalService: NgbModal,
        private _currentOrgService: CurrentOrganizationService
    ) {}

    ngOnInit(): void {
        if (!this.decimalPlaces) {
            this.decimalPlaces = 2;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes &&
            changes.contractFee &&
            changes.contractFee.currentValue
        ) {
            this.feeCopy = JSON.parse(
                JSON.stringify(changes.contractFee.currentValue)
            );

            //this.hasTiers = this.feeCopy.contractFeeTiers.length >= 1;
            this.hasTiers = this._hasTiers();

            //use org from service if not available
            if (!this.orgId) {
                this.orgId = this._currentOrgService.getCurrentOrganizationID();
            }

            if (this.orgId && this.productId) {
                let period = "ALL_TIME"; //default (indefinite)
                if (
                    this.feeCopy.thresholdResetPeriod ===
                    ContractFeeThresholdResetPeriod.MONTH
                ) {
                    period = "CURRENT_MONTH";
                } else if (
                    this.feeCopy.thresholdResetPeriod ===
                    ContractFeeThresholdResetPeriod.QUARTER
                ) {
                    period = "CURRENT_QUARTER";
                } else if (
                    this.feeCopy.thresholdResetPeriod ===
                    ContractFeeThresholdResetPeriod.YEAR
                ) {
                    period = "CURRENT_YEAR";
                }

                this._userOrgService
                    .getDocumentCountByPeriod(
                        this.orgId,
                        this.productId,
                        period
                    )
                    .pipe(first())
                    .subscribe((docStats: any) => {
                        this.getActiveTier(docStats);
                    });
            }
        }
    }

    getActiveTier(docStats: any) {
        if (this.hasTiers) {
            let currentTierIndex: number = 0;
            //TODO: check this logic
            this.feeCopy.contractFeeTiers.forEach(
                (tier: ContractFeeTier, index: number) => {
                    if (
                        docStats.docCount >= tier.tierThreshold ||
                        docStats.previousPeriodDocCount >= tier.tierThreshold
                    ) {
                        currentTierIndex = index;
                    }
                }
            );
            this.activeTierIndex = currentTierIndex;
            this.currDocCount = docStats.docCount;
            this.prevDocCount = docStats.previousPeriodDocCount;
        }
    }

    editTiers() {
        const modalRef: NgbModalRef = this._modalService.open(
            EditContractPricingTiersDialogComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.contractFee = this.feeCopy;
        modalInstance.decimalPlaces = this.decimalPlaces;

        modalRef.result.then(
            (updatedContractFee: ContractFee) => {
                this.firstTime = false;
                if (updatedContractFee) {
                    this.feeCopy = updatedContractFee;
                    // if (this.feeCopy.contractFeeTiers.length > 1)
                    //     this.hasTiers = true;
                    this.hasTiers = this._hasTiers();

                    this.update.emit(this.feeCopy);
                }
            },
            () => {
                console.log("dismiss");
            }
        );
    }

    deleteTiers() {
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.title = "Delete Pricing Tiers";
        modalInstance.message =
            "This will remove the current pricing tiers. Are you sure?";
        modalInstance.primary = {
            text: "Delete",
            callback: () => {
                this._resetTiers();
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Cancel"
        };
    }

    getCurrentTooltip() {
        return "Documents recorded this " + this.getPeriod();
    }

    getPreviousTooltip(): string {
        return "Documents recorded last " + this.getPeriod();
    }

    getPeriod(): string {
        switch (this.feeCopy.thresholdResetPeriod) {
            case ContractFeeThresholdResetPeriod.MONTH:
                return "month";
            case ContractFeeThresholdResetPeriod.QUARTER:
                return "quarter";
            case ContractFeeThresholdResetPeriod.YEAR:
                return "year";
        }
    }

    /** PRIVATE FUNCTIONS **/

    private _resetTiers() {
        let baseFeeAmount = this.feeCopy.contractFeeTiers[0].feeAmount;

        this.feeCopy.thresholdResetPeriod =
            ContractFeeThresholdResetPeriod.INDEFINITE;
        this.feeCopy.contractFeeTiers = [
            {
                tierThreshold: null,
                feeAmount: baseFeeAmount
            }
        ];
        this.hasTiers = false;
        this.update.emit(this.feeCopy);
        this.removeTiers.emit(null);
    }

    private _hasTiers(): boolean {
        let result: boolean = true;
        this.feeCopy.contractFeeTiers.forEach((tier) => {
            if (tier.tierThreshold == null || tier.feeAmount == null) {
                result = false;
            }
        });

        return result;
    }
}
