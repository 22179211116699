import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonCoreModule } from "../common-core.module";
import { AlertModalComponent } from "./components";
import { BaseModalComponent } from "./components";
import { ChangeNotesModalComponent } from "./components";
import { ChooseColumnsDialogComponent } from "./components";
import { ConfirmationModalComponent } from "./components";
import { HotKeysModalComponent } from "./components";
import { ModalFooterComponent } from "./components";
import { ModalHeaderComponent } from "./components";
import { TabbedModalFooterComponent } from "./components";
import { TabbedModalHeaderComponent } from "./components";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { IeNotSupportedDialogComponent } from "./components/ie-not-supported-dialog/ie-not-supported-dialog.component";
import { SfCommonModule } from "../common.module";
import { DialogModalComponent } from "./components/dialog-modal/dialog-modal.component";
import { DialogModalHeaderComponent } from "./components/dialog-modal-header/dialog-modal-header.component";
import { DialogModalFooterComponent } from "./components/dialog-modal-footer/dialog-modal-footer.component";
import { MinimizableModalHeaderComponent } from "./components/minimizable-modal-header/minimizable-modal-header.component";

@NgModule({
    declarations: [
        AlertModalComponent,
        BaseModalComponent,
        ChangeNotesModalComponent,
        ChooseColumnsDialogComponent,
        ConfirmationModalComponent,
        HotKeysModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        TabbedModalFooterComponent,
        TabbedModalHeaderComponent,
        IeNotSupportedDialogComponent,
        DialogModalComponent,
        DialogModalHeaderComponent,
        DialogModalFooterComponent,
        MinimizableModalHeaderComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        DragDropModule,
        FormsModule,
        NgbTooltipModule,
        CommonCoreModule,
        SfCommonModule
    ],
    exports: [
        AlertModalComponent,
        BaseModalComponent,
        ChangeNotesModalComponent,
        ChooseColumnsDialogComponent,
        ConfirmationModalComponent,
        HotKeysModalComponent,
        ModalHeaderComponent,
        ModalFooterComponent,
        TabbedModalFooterComponent,
        TabbedModalHeaderComponent,
        IeNotSupportedDialogComponent,
        DialogModalComponent,
        MinimizableModalHeaderComponent
    ]
})
export class ModalsModule {}
