import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import {
    DynamicFormState,
    FieldState,
    FieldStateWrapper,
    FieldViewState,
    FormState,
    ROOT_FIELD_KEY,
    RootFieldState,
    ViewState
} from "../interfaces/dynamic-form-state";
import { filter, finalize, first, map, shareReplay, tap } from "rxjs/operators";
import { deepMergeOverrideArrays, isPlainObject } from "@sf/common";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
    getDefaultFieldViewState,
    getDefaultFormState,
    parseConditionals,
    parseFields,
    parseFieldState
} from "../helpers/definition-parser.helpers";
import { FormArray, FormGroup } from "@angular/forms";
import { buildFormFromRoot, findControl } from "../helpers/build-form.helpers";
import { ConditionUpdateType, RootField, StateChange } from "../interfaces";
import {
    buildNewControlForArrayField,
    getParentPath,
    getStateUpdateForNewArrayField,
    removeFieldAndChildrenFromState,
    removeGivenFieldsAndChildrenFromState,
    setExternalValueForFormGroup
} from "../helpers/repeatable-fields.helpers";
import * as clone from "clone";

const initialState: DynamicFormState = {
    // set initial required properties
    value: null,
    rootField: null,
    viewState: {},
    formState: {},
    fieldState: {},
    conditionals: {}
};

@Injectable()
export class DynamicFormStore extends ComponentStore<DynamicFormState> {
    private _formGroup$: BehaviorSubject<FormGroup> =
        new BehaviorSubject<FormGroup>(null);
    private _controlObs: { [path: string]: Observable<any> } = {};

    constructor() {
        super(initialState);
    }

    /**
     * Store Selectors
     */

    readonly fieldState$ = this.select((state) => state.fieldState);
    readonly formState$ = this.select((state) => state.formState);
    readonly viewState$ = this.select((state) => state.viewState);
    readonly rootField$ = this.select((state) => state.rootField, {
        debounce: true
    });
    readonly conditions$ = this.select((state) => state.conditionals, {
        debounce: true
    });

    readonly formGroup$ = this.select(this.rootField$, (fieldState) => {
        const formGroup = buildFormFromRoot(
            ROOT_FIELD_KEY,
            this.get((state) => state.fieldState),
            this.get((state) => state.viewState),
            this.get((state) => state.formState)
        );
        this._formGroup$.next(formGroup);
        return formGroup;
    });

    getStateForField(fieldId: string) {
        return this.select(
            this.fieldState$,
            (fieldState) => fieldState[fieldId]
        );
    }

    getStateForFields(fieldIds: string[]) {
        return this.select(this.fieldState$, (fieldState) =>
            fieldIds.reduce((fieldStates: FieldStateWrapper, id: string) => {
                if (fieldState[id]) {
                    fieldStates[id] = fieldState[id];
                }

                return fieldStates;
            }, {})
        );
    }

    getViewStateForField(path: string) {
        return this.select(this.viewState$, (viewState: ViewState) => {
            return viewState[path];
        });
    }

    getVisibleFieldPathsForField(fieldPath: string) {
        return this.select(
            this.getViewStateForField(fieldPath),
            (viewState: FieldViewState) => {
                if (!viewState) {
                    return [] as string[];
                }
                const fields = viewState.visibleFieldsMap
                    ? Object.keys(viewState.visibleFieldsMap)
                    : [];
                const paths = [];
                for (const field of fields) {
                    if (viewState.visibleFieldsMap[field]) {
                        paths.push(field);
                    }
                }

                return paths;
            }
        );
    }

    getVisibleFieldsForField(path: string) {
        return this.select(
            this.fieldState$,
            this.getVisibleFieldPathsForField(path),
            (fieldState, ids) =>
                ids
                    .map((id) => fieldState[id])
                    .filter((state: FieldState) => !!state)
        ) as Observable<FieldState[]>;
    }

    getArrayFieldIds(fieldId: string) {
        return this.select(
            this.getViewStateForField(fieldId),
            (fieldState) => fieldState?.arrayFields || []
        );
    }

    getFieldsForArray(fieldId: string) {
        return this.select(
            this.fieldState$,
            this.getArrayFieldIds(fieldId),
            (fieldState, ids) =>
                ids
                    .map((id) => fieldState[id])
                    .filter((state: FieldState) => !!state)
        ) as Observable<FieldState[]>;
    }

    getFormState(fieldId: string) {
        return this.select(this.formState$, (formState) => formState[fieldId]);
    }

    getFormControl(fullPath: string) {
        if (!this._controlObs[fullPath]) {
            this._controlObs[fullPath] = this.formGroup$.pipe(
                filter((formGroup) => !!formGroup),
                map((formGroup) =>
                    findControl(
                        formGroup,
                        fullPath,
                        this.get((state) => state.viewState),
                        "."
                    )
                ),
                filter((control) => !!control),
                finalize(() => delete this._controlObs[fullPath]),
                shareReplay({ refCount: true, bufferSize: 1 })
            );
        }
        return this._controlObs[fullPath];
    }

    getRootVisibleFields(): Observable<FieldState[]> {
        return this.getVisibleFieldsForField(ROOT_FIELD_KEY);
    }

    getRootState(): Observable<RootFieldState> {
        return this.getStateForField(
            ROOT_FIELD_KEY
        ) as Observable<RootFieldState>;
    }

    getRootViewState(): Observable<FieldViewState> {
        return this.getViewStateForField(ROOT_FIELD_KEY);
    }

    getFormStateForField(fieldId: string) {
        return this.select(this.formState$, (formState) => formState[fieldId]);
    }

    /**
     * Store Updaters
     */
    readonly initializeForm = this.updater(
        (state, startingValue: DynamicFormState) => ({
            ...state,
            ...startingValue
        })
    );
    performOperation(stateChange: StateChange) {
        this.setState((state) => {
            const newState = {
                ...state
            };
            if (stateChange[ConditionUpdateType.Form]) {
                newState.formState = this._mergeStates(
                    state.formState,
                    stateChange[ConditionUpdateType.Form] as Partial<FormState>
                );
            }
            if (stateChange[ConditionUpdateType.View]) {
                newState.viewState = this._mergeStates(
                    state.viewState,
                    stateChange[ConditionUpdateType.View] as Partial<ViewState>
                );
            }
            if (stateChange[ConditionUpdateType.Field]) {
                newState.fieldState = this._mergeStates(
                    state.fieldState,
                    stateChange[
                        ConditionUpdateType.Field
                    ] as Partial<FieldState>
                );
            }
            if (stateChange[ConditionUpdateType.Conditionals]) {
                newState.conditionals = this._mergeStates(
                    state.conditionals,
                    stateChange[ConditionUpdateType.Conditionals]
                );
            }

            return newState;
        });
    }

    private _mergeStates(prev: any, curr: any) {
        const changes: any = {};
        for (let objectKey of Object.keys(curr)) {
            if (prev.hasOwnProperty(objectKey)) {
                changes[objectKey] = this._mergeFieldStates(
                    prev[objectKey],
                    curr[objectKey]
                );
            } else {
                changes[objectKey] = curr[objectKey];
            }
        }
        return {
            ...prev,
            ...changes
        };
    }

    private _mergeFieldStates(prev: any, curr: any) {
        return deepMergeOverrideArrays(prev, curr, {
            isMergeableObject: isPlainObject
        });
    }

    addFieldToArray(
        fieldPath: string,
        controlPath: string,
        index?: number,
        setFieldsAsOptional?: boolean
    ) {
        const state = this.get();
        const { update, newPath } = getStateUpdateForNewArrayField(
            fieldPath,
            state
        );

        if (setFieldsAsOptional) {
            this._removeRequiredValidatorsAndPlaceholders(update);
        }

        update.viewState[fieldPath] = {
            arrayFields: [...state.viewState[fieldPath].arrayFields, newPath],
            visibleFieldsMap: {
                ...state.viewState[fieldPath].visibleFieldsMap,
                [newPath]: true
            }
        };

        const newControl = buildNewControlForArrayField(newPath, update);
        const currentFormGroup = this._formGroup$.getValue();
        const parentControl = currentFormGroup.get(controlPath) as FormArray;

        if (index) {
            parentControl.insert(index, newControl);
        } else {
            parentControl.push(newControl);
        }

        this.performOperation(update);
        return update;
    }

    private _removeRequiredValidatorsAndPlaceholders(update: StateChange) {
        // remove required validators
        Object.values(update.formState).forEach((formState) => {
            formState.validators = formState.validators?.filter(
                (validator) => validator.identifier !== "required"
            );
        });
        // remove required placeholders
        Object.values(update.fieldState).forEach((fieldState: FieldState) => {
            if (fieldState.placeholder?.includes("Required")) {
                fieldState.placeholder = "";
            }
        });
    }

    removeFieldFromArray(fieldId: string, controlPath: string) {
        const parentPath = getParentPath(fieldId);
        const controlParentPath = getParentPath(controlPath);
        const state = this.get();
        const parentView = state.viewState[parentPath];
        const currentIndex = parentView.arrayFields.indexOf(fieldId); //parseInt(pathArray[pathArray.length - 1]);
        const arrayFields = [...parentView.arrayFields];
        let removedField: string = arrayFields.splice(currentIndex, 1)[0];
        delete parentView.visibleFieldsMap[removedField];
        const update: StateChange = {
            [ConditionUpdateType.View]: {
                [parentPath]: {
                    arrayFields
                }
            }
        };

        const currentFormGroup = this._formGroup$.getValue();
        const parentControl = currentFormGroup.get(
            controlParentPath
        ) as FormArray;

        parentControl.removeAt(currentIndex);

        this.performOperation(update);

        this.setState((state) => {
            removeFieldAndChildrenFromState(fieldId, state);
            return {
                ...state
            };
        });
    }

    removeFieldFromArrayByIndex(
        arrayFieldPath: string,
        arrayControlPath: string,
        index: number
    ) {
        const state = this.get();
        const parentView = state.viewState[arrayFieldPath];
        const arrayFields = [...parentView.arrayFields];
        let removedField: string = arrayFields.splice(index, 1)[0];
        delete parentView.visibleFieldsMap[removedField];
        const update: StateChange = {
            [ConditionUpdateType.View]: {
                [arrayFieldPath]: {
                    arrayFields
                }
            }
        };

        const currentFormGroup = this._formGroup$.getValue();
        const parentControl = currentFormGroup.get(
            arrayControlPath
        ) as FormArray;

        parentControl.removeAt(index);

        this.performOperation(update);

        this.setState((state) => {
            removeFieldAndChildrenFromState(removedField, state);
            return {
                ...state
            };
        });
    }

    updateFieldPositionInArray(
        fieldId: string,
        controlPath: string,
        newIndex: number
    ) {
        const parentPath = getParentPath(fieldId);
        const controlParentPath = getParentPath(controlPath);
        const state = this.get();
        const parentView = state.viewState[parentPath];
        const currentIndex = parentView.arrayFields.indexOf(fieldId);
        const arrayFields = [...parentView.arrayFields];

        let movedField = arrayFields[currentIndex];
        arrayFields.splice(currentIndex, 1);
        arrayFields.splice(newIndex, 0, movedField);

        const update: StateChange = {
            [ConditionUpdateType.View]: {
                [parentPath]: {
                    arrayFields
                }
            }
        };

        const currentFormGroup = this._formGroup$.getValue();
        const parentControl = currentFormGroup.get(
            controlParentPath
        ) as FormArray;

        const moved = parentControl.at(currentIndex);

        parentControl.removeAt(currentIndex);
        parentControl.insert(newIndex, moved);

        this.performOperation(update);
    }

    clearArray(controlPath: string) {
        const currentFormGroup = this._formGroup$.getValue();
        const parentControl = currentFormGroup.get(controlPath) as FormArray;

        let fieldIDs: string[] = [];
        const state = this.get();
        const parentView = state.viewState[controlPath];
        const arrayFields = [...parentView.arrayFields];
        for (let i = arrayFields.length - 2; i >= 0; --i) {
            fieldIDs.push(arrayFields[i]);
            arrayFields.splice(i, 1);
            parentControl.removeAt(i);
        }

        const update: StateChange = {
            [ConditionUpdateType.View]: {
                [controlPath]: {
                    arrayFields
                }
            }
        };
        this.performOperation(update);

        this.setState((state) => {
            removeGivenFieldsAndChildrenFromState(fieldIDs, state);
            return {
                ...state
            };
        });
    }

    setExpanded(path: string, expanded: boolean) {
        const state = this.get();
        this.getViewStateForField(path)
            .pipe(first())
            .subscribe((viewState) => {
                const visibleFieldsMap = Object.keys(
                    viewState.visibleFieldsMap
                ).reduce((map, key) => {
                    return {
                        ...map,
                        // [key]: expanded
                        [key]:
                            expanded &&
                            state[ConditionUpdateType.View][key].visible
                    };
                }, {});
                const update: StateChange = {
                    [ConditionUpdateType.View]: {
                        [path]: {
                            expanded,
                            visibleFieldsMap
                        }
                    }
                };

                this.performOperation(update);
            });
    }

    setMultipleExpanded(expansions: { path: string; expanded: boolean }[]) {
        const state = this.get();
        let update: StateChange = {
            [ConditionUpdateType.View]: {}
        };
        for (let expansion of expansions) {
            if (!state[ConditionUpdateType.View][expansion.path]) return;
            const visibleFieldsMap = Object.keys(
                state[ConditionUpdateType.View][expansion.path].visibleFieldsMap
            ).reduce((map, key) => {
                return {
                    ...map,
                    // [key]: expansion.expanded
                    [key]:
                        expansion.expanded &&
                        state[ConditionUpdateType.View][key].visible
                };
            }, {});
            update[ConditionUpdateType.View][expansion.path] = {
                expanded: expansion.expanded,
                visibleFieldsMap
            };
        }

        this.performOperation(update);
    }

    setVisible(path: string, visible: boolean) {
        const update: StateChange = {
            [ConditionUpdateType.View]: {
                [path]: {
                    visible
                }
            }
        };

        this.performOperation(update);
    }

    /**
     * Store Effects
     */

    readonly loadExternalValue = this.effect(
        (externalValue$: Observable<any>) =>
            combineLatest([
                externalValue$,
                this._formGroup$.pipe(filter((formGroup) => !!formGroup))
            ]).pipe(
                map(([value, formGroup]) => {
                    const state = this.get();
                    const stateChange = setExternalValueForFormGroup(
                        formGroup,
                        value,
                        clone(state)
                    );

                    if (stateChange !== null) {
                        this.performOperation(stateChange.change);

                        if (stateChange.fieldsToRemove.length > 0) {
                            this.setState((state) => {
                                for (const fieldId of stateChange.fieldsToRemove) {
                                    removeFieldAndChildrenFromState(
                                        fieldId,
                                        state
                                    );
                                }
                                return {
                                    ...state
                                };
                            });
                        }
                    }
                })
            )
    );

    readonly loadFormDefinition = this.effect(
        (
            definition$: Observable<{
                definition: RootField;
                value?: any;
            }>
        ) => {
            return definition$.pipe(
                map(({ definition }) => {
                    const fieldEntities = parseFields(definition.fields);
                    const fieldState = parseFieldState(fieldEntities);
                    const conditionals = parseConditionals(fieldEntities);

                    return {
                        rootField: definition,
                        fieldState,
                        viewState: getDefaultFieldViewState(fieldState),
                        formState: getDefaultFormState(fieldState),
                        conditionals
                    } as DynamicFormState;
                }),
                map((dynamicFormState: DynamicFormState) => {
                    return dynamicFormState;
                }),
                tap({
                    next: (definition: DynamicFormState) => {
                        this.initializeForm(definition);
                    }
                })
            );
        }
    );
}
