import { Injectable } from "@angular/core";
import { PostViaFormService } from "./post-via-form.service";
import { Observable, of } from "rxjs";
import { RpcClientService } from "./rpc-client.service";

@Injectable({
    providedIn: "root"
})
export class DownloadService {
    constructor(
        private _postViaFormService: PostViaFormService,
        private _rpc: RpcClientService
    ) {}

    downloadPages(
        pageIDs: string[],
        fileName: string,
        outputType: string,
        documentID: string,
        allowAnyRecordedDoc: boolean
    ): Observable<{}> {
        let url = "/sf/services/printDownload/downloadPages";
        let parameters: any = {
            pageIDs: pageIDs,
            fileName: fileName,
            outputType: outputType,
            documentIDs: [documentID],
            allowAnyRecordedDoc: allowAnyRecordedDoc,
            useXhrPost: true
        };
        let extension: string = outputType === "PDF" ? ".pdf" : ".tiff";

        const ids = documentID.split(".");
        if (ids.length > 1) {
            parameters = {}; // parameters are passed in the URL so form parameters are not needed for these requests
            if (ids[0] === "esign") {
                // this is an ESignDocument
                let original = false;
                if (ids[2] === "original") {
                    original = true;
                }
                url = `/sf/submitter/esign/images/${ids[1]}${extension}?download=true&fileName=${fileName}&original=${original}`;
            } else if (ids[1] === "esign") {
                // this is an ESignDocument package
                url = `/sf/submitter/esign/images/package/${ids[0]}${extension}?download=true&fileName=${fileName}`;
            } else {
                // this is an SF2 document
                extension = outputType === "PDF" ? ".pdf" : ".tif";
                url = `/sf/submitter/images/${ids[0]}/${ids[1]}${extension}?download=true&fileName=${fileName}`;
            }
        }

        this._postViaFormService.post(url, parameters, null, null, true);
        return of({});
    }

    download(downloadOptions: any): Observable<any> {
        this._postViaFormService.post(
            "/sf/services/printDownload/downloadPackages",
            downloadOptions,
            null,
            null,
            true
        );
        return of({});
    }

    downloadSignatureAndSeal(): Observable<any> {
        this._postViaFormService.post(
            "/sf/submitter/esign/getSignatureAndSealImage",
            {},
            null,
            true,
            true
        );
        return of({});
    }

    downloadSeal(userID: string): Observable<any> {
        this._postViaFormService.post(
            "/sf/submitter/esign/getNotarySealImage.pdf",
            { userID, download: true },
            null,
            true,
            true
        );
        return of({});
    }

    downloadNotaryCompletionCertificate(): Observable<any> {
        this._postViaFormService.post(
            "/sf/submitter/esign/getNotaryCompletionCertificate",
            {},
            null,
            true,
            true
        );
        return of({});
    }

    downloadString(fileContent: string, fileType: string, fileName: string) {
        let blob = new Blob([fileContent], { type: fileType });
        this.downloadBlob(blob, fileName);
    }

    download64(fileContent: string, fileType: string, fileName: string) {
        let blob = this.b64toBlob(fileContent, fileType);
        this.downloadBlob(blob, fileName);
    }

    downloadBlob(blob: Blob, fileName: string) {
        let url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    b64toBlob(b64String: any, type: string): Blob {
        let byteString = atob(b64String);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type });
    }

    downloadDocumentByID(documentID: string) {
        return this._rpc.makeRequest("admin", "getDocumentImage", {
            documentID
        });
    }
}
