<div class="header" (contextmenu)="showContextMenu($event)">
    <sf-context-menu [x]="contextMenuX" [y]="contextMenuY" [shown]="contextMenuVisible"
        [options]="contextMenuItems" (select)="handleContextMenuItem($event)"></sf-context-menu>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <div class="d-flex flex-column flex-md-row">
                <div class="header-selector">
                    <div class="title" *ngIf="pageData.pageTitle">
                        <h1>{{pageData.pageTitle}}</h1>
                    </div>
                    <div class="d-flex flex-column flex-md-row select-holder">
                        <sf-user-filter-selector
                            [hideBorder]="true"
                            [selectedUserID]="selectedUserID"
                            [canEditUsersOnly]="canEditUsersOnly"
                            [autoInitialSelect]="true"
                            (select)="setSelectedUser($event)"
                        ></sf-user-filter-selector>
                        <div *ngIf="selectedUser && selectedUser.status && selectedUser.canModify"
                            class="status-info sf-status-badge-container"
                            id="userStatusInfo">
                            <span *ngIf="!selectedUser.isLockedOut" class="badge {{selectedUser.statusClass}}"
                                    [ngbTooltip]="selectedUser.statusTooltip" placement="top">
                                {{selectedUser.status}}
                            </span>
                            <button *ngIf="selectedUser.isLockedOut" class="btn btn-link badge badge-link {{selectedUser.statusClass}}"
                                    (click)="onStatusClick()"
                                    [ngbTooltip]="selectedUser.statusTooltip" placement="top">
                                {{selectedUser.status}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
