import {
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    ElementRef,
    OnDestroy
} from "@angular/core";
import { DocumentRefService, WindowRefService } from "../../services";
import { parents } from "../../helpers/browser";

@Directive({
    selector: "[sfSelectMaintainPosition]"
})
export class SfSelectMaintainPositionDirective implements OnChanges, OnDestroy {
    /* Private Variables */
    private _scrollableElements: HTMLElement[];

    /* Inputs */
    @Input()
    isOpen: boolean;
    @Input()
    minPopupWidth: number;
    @Input()
    selectButtonRef: HTMLElement;

    constructor(
        private windowRef: WindowRefService,
        private documentRef: DocumentRefService,
        private popup: ElementRef<HTMLElement>
    ) {
        this._movePopupContainerOnScroll =
            this._movePopupContainerOnScroll.bind(this);
        this._adjustAfterWindowResize =
            this._adjustAfterWindowResize.bind(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isOpen && changes.isOpen.currentValue === true) {
            this._setPopupWidth();
            this._setPopupContainerOffset();
            this._registerScrollListener();
            this._attachWindowResizeListener();
        }
        if (changes.isOpen && changes.isOpen.currentValue === false) {
            this._removeScrollListener();
            this._removeWindowResizeListener();
        }
    }

    ngOnDestroy() {
        this._removeScrollListener();
        this._removeWindowResizeListener();
    }

    refresh() {
        setTimeout(() => {
            this._setPopupContainerOffset();
        });
    }

    private _setPopupContainerOffset() {
        let buttonOffset,
            newOffset: { top: number; left: number },
            hasSpaceBelow,
            hasSpaceAbove,
            hasSpaceOnLeft,
            hasSpaceOnRight,
            popupHeight,
            popupWidth,
            windowWidth,
            buttonWidth,
            windowHeight,
            popupRect: ClientRect,
            buttonRect: ClientRect,
            moreSpaceOnRight;

        popupRect = this.popup.nativeElement.getBoundingClientRect();
        buttonRect = this.selectButtonRef.getBoundingClientRect();

        popupHeight = popupRect.height + 1;
        popupWidth = popupRect.width;
        const window = this.windowRef.nativeWindow;
        const document = this.documentRef.nativeDocument;
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
        const scrollTop = Math.max(
            window.pageYOffset,
            document.scrollingElement?.scrollTop ?? 0
        );
        const scrollLeft = Math.max(
            window.pageXOffset,
            document.scrollingElement?.scrollLeft ?? 0
        );
        buttonOffset = {
            top: buttonRect.top + scrollTop,
            left: buttonRect.left + scrollLeft
        };
        buttonWidth = buttonRect.width;

        hasSpaceBelow =
            windowHeight - buttonRect.height - buttonOffset.top - popupHeight >=
            0;
        hasSpaceAbove = buttonOffset.top - popupHeight >= 0;
        hasSpaceOnLeft =
            buttonOffset.left + buttonWidth >= popupWidth - buttonWidth + 2;
        hasSpaceOnRight = buttonOffset.left + popupWidth <= windowWidth;
        moreSpaceOnRight =
            windowWidth - (buttonOffset.left + popupWidth) >
            buttonOffset.left + buttonWidth;

        newOffset = {
            top: buttonOffset.top + buttonRect.height - 1,
            left: buttonOffset.left
        };

        if (
            (!hasSpaceOnRight && hasSpaceOnLeft) ||
            (!hasSpaceOnRight && !hasSpaceOnLeft && !moreSpaceOnRight)
        ) {
            newOffset.left = Math.max(
                buttonOffset.left + (buttonWidth - popupWidth) - 2,
                3
            );
        } else {
            this.popup.nativeElement.style.maxWidth =
                windowWidth - buttonOffset.left + "px";
        }
        if (!hasSpaceBelow && hasSpaceAbove) {
            this.popup.nativeElement.classList.remove(
                "sf-select-two-popup-down"
            );
            this.popup.nativeElement.classList.add("sf-select-two-popup-up");
            newOffset.top = buttonOffset.top - popupHeight + 2;
            this.popup.nativeElement.style.top = newOffset.top + "px";
            this.popup.nativeElement.style.left = newOffset.left + "px";
            return;
        }
        this.popup.nativeElement.classList.remove("sf-select-two-popup-up");
        this.popup.nativeElement.classList.add("sf-select-two-popup-down");
        this.popup.nativeElement.style.top = newOffset.top + "px";
        this.popup.nativeElement.style.left = newOffset.left + "px";
    }

    private _getScrollableElements() {
        return parents(this.selectButtonRef).filter((element) => {
            let vertically_scrollable, horizontally_scrollable;
            const styles = getComputedStyle(element);
            if (
                styles.overflow == "scroll" ||
                styles.overflowX == "scroll" ||
                styles.overflowY == "scroll"
            )
                return true;

            vertically_scrollable =
                element.clientHeight < element.scrollHeight &&
                (["scroll", "auto"].includes(styles.overflowY) ||
                    ["scroll", "auto"].includes(styles.overflow));

            if (vertically_scrollable) {
                return true;
            }

            horizontally_scrollable =
                element.clientWidth < element.scrollWidth &&
                (["scroll", "auto"].includes(styles.overflowX) ||
                    ["scroll", "auto"].includes(styles.overflow));
            return horizontally_scrollable;
        });
    }

    private _removeScrollListener() {
        setTimeout(() => {
            if (!this._scrollableElements) {
                return;
            }
            this._scrollableElements.forEach((el) => {
                el.removeEventListener(
                    "scroll",
                    this._movePopupContainerOnScroll
                );
            });
        });
    }

    private _registerScrollListener() {
        setTimeout(() => {
            if (!this._scrollableElements) {
                this._scrollableElements = this._getScrollableElements();
            }
            this._scrollableElements.forEach((el) => {
                el.addEventListener("scroll", this._movePopupContainerOnScroll);
            });
        });
    }

    private _movePopupContainerOnScroll() {
        if (this.isOpen) {
            this._setPopupContainerOffset();
        }
    }

    private _attachWindowResizeListener() {
        this.windowRef.nativeWindow.onresize = this._adjustAfterWindowResize;
    }

    private _removeWindowResizeListener() {
        this.windowRef.nativeWindow.onresize = undefined;
    }

    private _adjustAfterWindowResize() {
        this._setPopupWidth();
        this._setPopupContainerOffset();
    }

    private _setPopupWidth() {
        let buttonWidth = this.selectButtonRef.getBoundingClientRect().width;
        let minWidth = this.minPopupWidth
            ? Math.max(this.minPopupWidth, buttonWidth - 3)
            : buttonWidth - 3;
        this.popup.nativeElement.style.minWidth = minWidth + "px"; //adjust width so it doesn't extend over the button if contents are shorter than the button
    }
}
