import { Component, TemplateRef, ViewChild } from "@angular/core";
import { BaseModalComponent } from "@sf/common";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SpinnerService } from "@sf/common";
import { finalize } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "@sf/common";
import { Folder } from "../../interfaces/folder";
import { EsignOrganizationService } from "../../services/esign-organization.service";

@Component({
    selector: "sf-manage-folders-modal",
    templateUrl: "./manage-folders-modal.component.html",
    styleUrls: ["./manage-folders-modal.component.scss"]
})
export class ManageFoldersModalComponent extends BaseModalComponent {
    private _subject = new Subject<Folder[]>();
    result = this._subject.asObservable();
    folders: Folder[] = [];
    foldersForm: FormGroup;
    organizations: any[] = [];
    disableCreate = true;
    rename = "";

    @ViewChild("renameModal", { static: false })
    renameModal: TemplateRef<any>;

    constructor(
        private _fb: FormBuilder,
        private _organizationService: EsignOrganizationService,
        private _spinnerService: SpinnerService,
        private _modal: NgbModal
    ) {
        super();
    }

    ngOnInit(): void {
        let organizationId =
            this.organizations.length === 1 ? this.organizations[0].id : "";

        this.foldersForm = this._fb.group({
            name: "",
            organizationId: organizationId
        });
        this.foldersForm.valueChanges.subscribe((value) => {
            this.disableCreate = !value.name || !value.organizationId;
        });

        this.primary = {
            text: "Done"
        };
    }

    ngOnDestroy() {
        this._subject.next(this.folders);
        this._subject.complete();
    }

    createNewFolder() {
        let formValues = this.foldersForm.value;
        this._spinnerService.startSpinner();
        this._organizationService
            .createReviewFolder(formValues.organizationId, formValues.name)
            .pipe(finalize(() => this._spinnerService.stopSpinner()))
            .subscribe((folder) => {
                this.folders = this.folders.concat([folder]);
                this.foldersForm.get("name").setValue("");
            });
    }

    deleteFolder(folder: Folder) {
        if (folder && folder.documentCount === 0) {
            let message =
                "Are you sure you want to remove folder '" + folder.name + "'?";
            if (folder.ruleCount > 0) {
                message +=
                    "<br/><br/>This folder is currently being used in " +
                    (folder.ruleCount === 1
                        ? "a document routing rule."
                        : folder.ruleCount + " document routing rules.");
            }

            let modal = this._modal.open(ConfirmationModalComponent, {
                windowClass: "sf-unmask"
            }).componentInstance;
            modal.title = "Remove Review Folder";
            modal.message = message;
            modal.primary = {
                text: "Remove",
                callback: () => {
                    this._organizationService
                        .deleteReviewFolder(folder.id)
                        .subscribe(() => {
                            this.folders = this.folders.filter(
                                (f) => f.id !== folder.id
                            );
                        });
                    return true;
                }
            };
        }
    }

    renameFolder(folder: Folder) {
        if (folder) {
            let modal = this._modal.open(ConfirmationModalComponent, {
                windowClass: "sf-unmask"
            }).componentInstance;
            modal.title = "Rename Review Folder";
            modal.template = this.renameModal;
            modal.primary = {
                text: "Save",
                callback: () => {
                    this._organizationService
                        .renameReviewFolder(folder.id, this.rename)
                        .subscribe(() => (folder.name = this.rename));
                    return true;
                }
            };
            this.rename = folder.name;
        }
    }
}
