import { AfterContentInit, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[sfAutoFocus]"
})
export class AutoFocusDirective implements AfterContentInit {
    constructor(private _el: ElementRef) {}

    ngAfterContentInit(): void {
        setTimeout(() => {
            this._el.nativeElement.focus();
        }, 500);
    }
}
