<sf-modal-header title="Limited Trial"></sf-modal-header>
<div class="modal-body">
    <div class="vertical-space">
        <span>
            You currently have a limited trial for the <b>{{productLabel}}</b> service
            for the <b>{{organizationName}}</b> organization.
            The {{productLabel}} service allows you to {{productBenefits}} within Simplifile.
        </span>
    </div>
    <div *ngIf="!!productSpecificParagraph" class="vertical-space">
        <span>{{productSpecificParagraph}}</span>
    </div>
    <div class="vertical-space">
        <span>Learn more about {{productLabel}} in the
            <button class="btn btn-link sf-btn-link" (click)="goToResourceCenter()">Resource Center</button>.
        </span>
    </div>
    <div *ngIf="isAdmin" class="vertical-space">
        <span>Click <b>Activate Service</b> to begin full activation of the {{productLabel}} service.</span>
    </div>
    <div *ngIf="!isAdmin" class="vertical-space">
        <div>Select one or more organization administrators to notify about your interest in activating the {{productLabel}} service:</div>
        <div class="scroll-container">
            <div *ngFor="let administrator of administratorUsers">
                <label>
                    <input type="checkbox" [(ngModel)]="administrator.selected"/>
                    <span> {{administrator.name}} ({{administrator.email}})</span>
                </label>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer *ngIf="!requestSent" [primary]="primary" [secondary]="secondary"></sf-modal-footer>
<div *ngIf="requestSent" class="bottom-spinner">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>
