import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable, of } from "rxjs";
import {
    UIHistoryEventModel,
    UIOrganizationEEligibility
} from "@sf/userorg/common";
import { map, tap } from "rxjs/operators";
import { UiRecipient } from "@sf/submitter/common";
import { StatesService } from "@sf/common";

@Injectable()
export class EEligibilityDataService {
    private _stateEEligibilityDataCache: UIOrganizationEEligibility[] = [];
    private _allAvailableCountiesCache: UiRecipient[] = [];

    constructor(
        private _rpcClientService: RpcClientService,
        private _statesService: StatesService
    ) {}

    public getEEligibilityForAllStates(): Observable<
        UIOrganizationEEligibility[]
    > {
        return this._rpcClientService
            .makeRequest("admin", "getEEligibilityForAllStates")
            .pipe(
                tap((data: any[]) => {
                    data.forEach((state: any) => {
                        state.stateName = state.orgName = state.name;
                        state.orgHierarchy = [state.stateName];
                        state.orgID = state.stateAbbrev + "S000";
                    });
                    this._stateEEligibilityDataCache = data;
                })
            );
    }

    public getCachedStateData(): UIOrganizationEEligibility[] {
        return this._stateEEligibilityDataCache;
    }

    public getEEligibilityForAllRecipientsInState(
        stateAbbrev: string
    ): Observable<UIOrganizationEEligibility[]> {
        return this._rpcClientService
            .makeRequest("admin", "getEEligibilityForAllRecipientsInState", {
                state: stateAbbrev
            })
            .pipe(
                map((counties: any[]) => {
                    counties.forEach((county: any) => {
                        county.stateName = this._getStateName(stateAbbrev);
                        county.stateAbbrev = stateAbbrev;
                        county.orgHierarchy = [county.stateName, county.name];
                        county.orgName = county.name;
                    });
                    return this._copyStateApprovedData(counties);
                })
            );
    }

    private _getStateName(abbreviation: string) {
        let state = this._statesService
            .getAllStatesPlusDC()
            .find((state) => state.abbrev === abbreviation);
        return state ? state.name : "";
    }

    public getEEligibilityBySearchOrgName(
        filterText: string
    ): Observable<UIOrganizationEEligibility[]> {
        return this._rpcClientService
            .makeRequest("admin", "getEEligibilityBySearchOrgName", {
                searchOrgName: filterText
            })
            .pipe(
                map((counties: any[]) => {
                    counties = counties.filter(
                        (county) => county.stateAbbrev !== undefined
                    );
                    counties.forEach((county: any) => {
                        county.stateName = this._getStateName(
                            county.stateAbbrev
                        );
                        county.orgHierarchy = [county.stateName, county.name];
                        county.orgName = county.name;
                    });
                    return counties;
                }),
                map(this._copyStateApprovedData.bind(this)),
                map(this._includeStateEEligibilityData.bind(this))
            );
    }

    public getEEligibilityByOrgID(
        organizationID: string,
        fromCache: boolean = false
    ): Observable<UIOrganizationEEligibility> {
        if (fromCache) {
            const data = this._stateEEligibilityDataCache.find(
                (state) => state.orgID === organizationID
            );
            if (data) {
                return of<UIOrganizationEEligibility>(data);
            }
        }
        return this._rpcClientService.makeRequest(
            "admin",
            "getEEligibilityByOrgID",
            {
                organizationID
            }
        );
    }

    public getEEligibilityByState(
        stateStr: string,
        fromCache: boolean = false
    ): Observable<UIOrganizationEEligibility> {
        if (fromCache) {
            const data = this._stateEEligibilityDataCache.find(
                (state) => state.stateAbbrev === stateStr
            );
            if (data) {
                return of<UIOrganizationEEligibility>(data);
            }
        }
        return this._rpcClientService.makeRequest(
            "admin",
            "getEEligibilityByState",
            {
                stateStr
            }
        );
    }

    public saveEEligibility(
        organizationID: string,
        uiOrganizationEEligibility: Partial<UIOrganizationEEligibility>,
        overwriteAllRecipients: boolean,
        historyStr: string
    ): Observable<void> {
        return this._rpcClientService.makeRequest("admin", "saveEEligibility", {
            organizationID,
            uiOrganizationEEligibility,
            overwriteAllRecipients,
            historyStr
        });
    }

    public saveEEligibilityState(
        uiOrganizationEEligibility: Partial<UIOrganizationEEligibility>,
        stateAbbrev: string,
        overwriteAllRecipients: boolean,
        historyStr: string
    ): Observable<void> {
        return this._rpcClientService.makeRequest("admin", "saveEEligibility", {
            uiOrganizationEEligibility,
            stateAbbrev,
            overwriteAllRecipients,
            historyStr
        });
    }

    public getEEligibilityHistory(
        id: string
    ): Observable<UIHistoryEventModel[]> {
        return this._rpcClientService.makeRequest(
            "admin",
            "getEEligibilityHistory",
            {
                id
            }
        );
    }

    public getAllAvailableCounties(): Observable<UiRecipient[]> {
        if (this._allAvailableCountiesCache.length > 0) {
            return of(this._allAvailableCountiesCache);
        }
        return this._rpcClientService
            .makeRequest<UiRecipient[]>(
                "userorg",
                "getAllAvailableCounties",
                {}
            )
            .pipe(
                tap((counties) => (this._allAvailableCountiesCache = counties))
            );
    }

    private _copyStateApprovedData(counties: UIOrganizationEEligibility[]) {
        if (!!this._stateEEligibilityDataCache.length) {
            let prevStateName = "";
            let state = this._stateEEligibilityDataCache[0];
            counties.forEach((c: UIOrganizationEEligibility) => {
                if (c.stateName !== prevStateName) {
                    state = this._stateEEligibilityDataCache.find(
                        (s: UIOrganizationEEligibility) =>
                            s.orgName === c.stateName
                    );
                    prevStateName = c.stateName;
                }
                c.simpfl_approved_ipen_vendor =
                    state.simpfl_approved_ipen_vendor;
                c.simpfl_approved_ron_vendor = state.simpfl_approved_ron_vendor;
            });
        }
        return counties;
    }

    private _includeStateEEligibilityData(
        counties: UIOrganizationEEligibility[]
    ) {
        const stateAbbrevs: string[] = [
            ...new Set(
                counties.map((mc: UIOrganizationEEligibility) => mc.stateAbbrev)
            )
        ];
        const statesData: UIOrganizationEEligibility[] =
            this._stateEEligibilityDataCache.filter(
                (stateData: UIOrganizationEEligibility) =>
                    stateAbbrevs.includes(stateData.stateAbbrev)
            );
        return counties.concat(statesData);
    }
}
