import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Navigation, NavItem } from "../../interfaces/navigation.interface";
import { combineLatest, isObservable, of } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { getCurrentRouteData } from "../../helpers/router";

@Component({
    selector: "sf-navigation-items",
    templateUrl: "./navigation-items.component.html",
    styleUrls: ["./navigation-items.component.scss"]
})
export class NavigationItemsComponent implements OnInit, OnDestroy {
    /** Private Variables */
    private _navigationEndEvents = this._router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
    );

    /** Public Variables */
    routeData$ = this._navigationEndEvents.pipe(
        startWith(null),
        map(() => this._route),
        map(getCurrentRouteData),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    navData$ = this.routeData$.pipe(
        switchMap(({ leafRoute, data }) => {
            let navData = data.navData;
            if (!isObservable(navData)) {
                navData = of(navData);
            }
            return combineLatest({
                currentRoute: of(leafRoute),
                navigation: navData
            });
        }),
        filter(({ currentRoute, navigation }) => !!navigation),
        map(
            ({
                currentRoute,
                navigation
            }: {
                currentRoute: ActivatedRoute;
                navigation: Navigation;
            }) => {
                return {
                    parent: navigation.parent
                        ? this._parseNavItemUrl(
                              { ...navigation.parent },
                              currentRoute
                          )
                        : null,
                    items: this._parseActiveNavItems(
                        navigation.items,
                        currentRoute
                    )
                };
            }
        )
    );

    constructor(private _route: ActivatedRoute, private _router: Router) {}

    /** Lifecycle Hooks */
    ngOnInit() {}

    ngOnDestroy(): void {}

    /** Public Methods */

    /** Private Methods */
    private _parseActiveNavItems(
        navItems: NavItem[],
        leafRoute: ActivatedRoute
    ): NavItem[] {
        const parsedNavItems = [];
        for (let i = 0; i < navItems.length; i++) {
            const navItem = this._parseNavItemUrl(
                { ...navItems[i] },
                leafRoute
            );
            navItem.active = this._router.isActive(
                navItem.url,
                !navItem.inexactMatching
            );
            navItem.url = navItem.url.split("?")[0];
            if (
                Array.isArray(navItem.children) &&
                navItem.children.length > 0
            ) {
                navItem.children = this._parseActiveNavItems(
                    navItem.children,
                    leafRoute
                );
                const hasActiveChildren = navItem.children.reduce(
                    (isActive, navItem) => isActive || navItem.active,
                    false
                );
                navItem.hasActiveChildren = hasActiveChildren;
                if (!navItem.expanded) {
                    navItem.expanded = navItem.active || hasActiveChildren;
                }
            }
            parsedNavItems.push(navItem);
        }
        return parsedNavItems;
    }

    private _parseNavItemUrl(
        navItem: NavItem,
        leafRoute: ActivatedRoute
    ): NavItem {
        if (Array.isArray(navItem.link)) {
            const link = navItem.link.join("/").replace(/^\/+/, "");
            // if (sf.overrideLinks.hasOwnProperty(link)) {
            //     navItem.link = sf.overrideLinks[link];
            // }
            navItem.url = this._router
                .createUrlTree(navItem.link, {
                    relativeTo: leafRoute,
                    queryParamsHandling: "merge",
                    queryParams: navItem.queryParams
                })
                .toString();
        } else {
            navItem.url = navItem.link;
        }

        return navItem;
    }
}
