import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
    NgbProgressbarModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { ModalsModule, SfCommonModule, SfFormsModule } from "@sf/common";
import { PhoneHeaderComponent } from "./components/phone-header/phone-header.component";
import { NotaryProblemsDialogComponent } from "./dialogs/notary-problems-dialog/notary-problems-dialog.component";
import { PrivateEventDialogComponent } from "./dialogs/private-event-dialog/private-event-dialog.component";
import { ESignEventNamePipe } from "./pipes/esign-event-name.pipe";
import { TaggingSharedPermissionService } from "./services/tagging-shared-permission.service";

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        ModalsModule,
        NgbProgressbarModule,
        NgbTooltipModule,
        SfCommonModule,
        SfFormsModule
    ],
    declarations: [
        ESignEventNamePipe,
        NotaryProblemsDialogComponent,
        PhoneHeaderComponent,
        PrivateEventDialogComponent
    ],
    exports: [
        ESignEventNamePipe,
        NotaryProblemsDialogComponent,
        PhoneHeaderComponent,
        PrivateEventDialogComponent
    ],
    providers: [TaggingSharedPermissionService, ESignEventNamePipe]
})
export class TaggingSharedModule {}
