import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PersistedMemoryService, WindowRefService } from "@sf/common";
import { SignEventStorage } from "@sf/tagging-shared";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class SignEventTimeoutInterceptor implements HttpInterceptor {
    constructor(
        private _persistedMemoryService: PersistedMemoryService,
        private _windowRef: WindowRefService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const pathname = this._windowRef.nativeWindow.location.pathname;
                if (error.status === 401 && pathname.includes("sign-event")) {
                    if (pathname.includes("closing")) {
                        this._windowRef.nativeWindow.opener?.postMessage(
                            { message: "CLOSE_PAGE" },
                            this._windowRef.nativeWindow.origin
                        );
                        const redirectUrl = this._getRedirectUrl();
                        this._windowRef.nativeWindow.location.assign(
                            redirectUrl
                        );
                    } else if (
                        pathname.includes("signing") ||
                        pathname.includes("tagging")
                    ) {
                        this._windowRef.nativeWindow.close();
                    }
                }
                return throwError(error);
            })
        );
    }

    private _getRedirectUrl(): any {
        const storage: SignEventStorage =
            this._persistedMemoryService.get("eSignEvent");
        return storage?.onSessionTimeoutUrl;
    }
}
