import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DevelopmentService {
    constructor(protected _rpcClientService: RpcClientService) {}

    listFileSystems(): Observable<any> {
        return this._rpcClientService.makeRequest(
            "admin",
            "listFileSystems",
            {}
        );
    }

    findFiles(
        fileSystemId: string,
        searchPattern: string,
        offset: number
    ): Observable<any> {
        return this._rpcClientService.makeRequest("admin", "findFiles", {
            fileSystemId: fileSystemId,
            pattern: !searchPattern ? "" : searchPattern,
            offset: offset,
            count: 20
        });
    }

    getJobSchedule(): Observable<any> {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobSchedule",
            {}
        );
    }

    setJobSchedule(jobSchedule: any) {
        return this._rpcClientService.makeRequest("admin", "setJobSchedule", {
            jobSchedule
        });
    }

    deleteJobSchedule(jobScheduleID: number) {
        return this._rpcClientService.makeRequest(
            "admin",
            "deleteJobSchedule",
            {
                jobScheduleID
            }
        );
    }

    runJobScheduleNow(jobScheduleID: number) {
        return this._rpcClientService.makeRequest(
            "admin",
            "runJobScheduleNow",
            {
                jobScheduleID
            }
        );
    }

    getAllVendors() {
        return this._rpcClientService.makeRequest("admin", "getAllVendors", {});
    }

    getJobQueueTypesMap() {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobQueueTypesMap",
            {}
        );
    }

    getRecipients(recipient: string) {
        return this._rpcClientService.makeRequest("admin", "getRecipients", {
            recipient
        });
    }

    setJobQueue(jobQueueRecord: any) {
        return this._rpcClientService.makeRequest("admin", "setJobQueue", {
            jobQueueRecord
        });
    }

    getQueueTypeJobMethods(queueType: string) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getQueueTypeJobMethods",
            {
                queueType
            }
        );
    }

    setJobNotes(jobIDs: number[], notes: string) {
        return this._rpcClientService.makeRequest("admin", "setJobNotes", {
            jobIDs,
            notes
        });
    }

    getJobQueueControls(
        queueTypeStr: string,
        queueIDStr: string,
        jobMethodStr: string,
        hold: boolean,
        activeOverride: boolean,
        active: boolean,
        state: any,
        vendor: string
    ) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobQueueControls",
            {
                queueTypeStr,
                queueIDStr,
                jobMethodStr,
                hold,
                activeOverride,
                active,
                state,
                vendor
            }
        );
    }

    toggleQueueControls(
        jobQueueIDs: string[],
        column: string,
        newValue: boolean
    ) {
        return this._rpcClientService.makeRequest(
            "admin",
            "toggleQueueControls",
            { jobQueueIDs, column, newValue }
        );
    }

    deleteJobs(jobIDs: number[]) {
        return this._rpcClientService.makeRequest("admin", "deleteJobs", {
            jobIDs
        });
    }

    getJobList(queueTypeStr: string, jobMethodStr: string) {
        return this._rpcClientService.makeRequest("admin", "getJobList", {
            queueTypeStr,
            jobMethodStr
        });
    }

    toggleJobs(jobIDs: number[], column: string, newValue: boolean) {
        return this._rpcClientService.makeRequest("admin", "toggleJobs", {
            jobIDs,
            column,
            newValue
        });
    }

    setJobRetryCount(jobIDs: number[], newValue: number) {
        return this._rpcClientService.makeRequest("admin", "setJobRetryCount", {
            jobIDs,
            newValue
        });
    }

    getJobMessages(jobID: number) {
        // return adminResource.send("getJobMessages", {"jobID": jobID});
        return this._rpcClientService.makeRequest("admin", "getJobMessages", {
            jobID
        });
    }

    getJobQueueSummaryNew() {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobQueueSummaryNew",
            {}
        );
    }

    getThreadSummary() {
        return this._rpcClientService.makeRequest(
            "admin",
            "getThreadSummary",
            {}
        );
    }

    getBuildInfo(): Observable<any> {
        return this._rpcClientService.makeRequest("admin", "getBuildInfo", {});
    }

    removeDocumentLock(documentID: string) {
        return this._rpcClientService.makeRequest(
            "admin",
            "removeDocumentLock",
            {
                documentID
            }
        );
    }

    removeUserDocumentLocks(userID: string) {
        return this._rpcClientService.makeRequest(
            "admin",
            "removeUserDocumentLocks",
            {
                userID
            }
        );
    }

    getFeeDifferenceReport(
        recipientID: string,
        docTypes: string[],
        feeDifference: number,
        startDate: Date,
        endDate: Date
    ) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getFeeDifferenceReport",
            {
                recipientID,
                docTypes,
                feeDifference,
                startDate,
                endDate
            }
        );
    }

    getRecipientKOIList(recipientID: string) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getRecipientKOIList",
            {
                recipientID
            }
        );
    }

    getRecipientVendors() {
        return this._rpcClientService.makeRequest(
            "userorg",
            "getRecipientVendors"
        );
    }

    getJobQueueErrorSummary(
        startDate: Date,
        endDate: Date,
        errorMessage: string,
        showAutoHandled: boolean
    ) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobQueueErrorSummary",
            { startDate, endDate, errorMessage, showAutoHandled }
        );
    }

    getJobQueueErrorSummaryDrillDown(
        recipientID: string,
        jobMethod: string,
        startDate: Date,
        endDate: Date,
        errorMessage: string,
        showAutoHandled: boolean
    ) {
        return this._rpcClientService.makeRequest(
            "admin",
            "getJobQueueErrorSummaryDrillDown",
            {
                recipientID,
                jobMethod,
                startDate,
                endDate,
                errorMessage,
                showAutoHandled
            }
        );
    }

    getAllRecipientsWithVendors() {
        return this._rpcClientService.makeRequest(
            "admin",
            "getAllRecipientsWithVendors"
        );
    }

    getDefaultRecipientConfiguration() {
        return this._rpcClientService.makeRequest(
            "admin",
            "getDefaultRecipientConfiguration"
        );
    }

    findRecordingJurisdictions(countyID: string): Observable<string> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "findRecordingJurisdictions",
            {
                countyID: countyID
            }
        );
    }

    getRecordingJurisdictions(state: string): Observable<any> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getRecordingJurisdictions",
            {
                selectedState: state
            }
        );
    }

    saveRecordingJurisdiction(jurisdiction: any): Observable<any> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "saveRecordingJurisdiction",
            jurisdiction
        );
    }

    deleteRecordingJurisdiction(id: string) {
        return this._rpcClientService.makeRequest(
            "erecord",
            "deleteRecordingJurisdiction",
            {
                id
            }
        );
    }

    getCustomFormsMostUsed() {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFormsMostUsed",
            {}
        );
    }

    getUnusedCustomForms() {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getUnusedCustomForms",
            {}
        );
    }

    getCustomFormsByState(state: string) {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFormsByState",
            {
                state: state
            }
        );
    }

    getCustomFormsByRecipient(recipientID: string) {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFormsByRecipient",
            {
                recipientID: recipientID
            }
        );
    }

    getCustomFieldsMostUsed() {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFieldsMostUsed",
            {}
        );
    }

    getUnusedCustomFields() {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getUnusedCustomFields",
            {}
        );
    }

    getCustomFieldsByState(state: string) {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFieldsByState",
            {
                state: state
            }
        );
    }

    getCustomFieldsByRecipient(recipientID: string) {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getCustomFieldsByRecipient",
            {
                recipientID: recipientID
            }
        );
    }
}
