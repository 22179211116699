<sf-modal-header [title]="modalTitle" [showDismiss]="false"></sf-modal-header>
<div class="p-3">
    The following organization(s) have been selected to receive a physical refund check. Please ensure that the address(es)
    listed below are correct. If needed, the address(es) can be updated by sending an email to:
    <a href="mailto: ar-simplifile@ice.com">ar-simplifile@ice.com</a>.
</div>
<div class="p-3 scrollable">
    <ng-container *ngFor="let orgID of orgIDs">
        <span [innerHTML]="getOrganizationInfo(orgID)"></span>
        <br/><br/>
    </ng-container>
</div>
<sf-modal-footer [primary]="primaryButton" [secondary]="secondaryButton"></sf-modal-footer>
