export enum PackageStatus {
    ASSEMBLE = "ASSEMBLE",
    PREPARE = "PREPARE",
    SIGN = "SIGN",
    SIGNREVIEW = "SIGNREVIEW",
    INITIALIZE = "INITIALIZE",
    DRAFT = "DRAFT",
    READY = "READY",
    SENT = "SENT",
    FINALIZING = "FINALIZING",
    RECORDED = "RECORDED",
    REJECTED = "REJECTED",
    VOIDED = "VOIDED",
    CANCELLED = "CANCELLED",
    ERROR = "ERROR",
    MAINTENANCE = "MAINTENANCE",
    REMOVED = "REMOVED",
    NEEDS_POA = "NEEDS_POA",
    DATA_ENTRY = "DATA_ENTRY",
    DATA_REVIEW = "DATA_REVIEW",
    READY_TO_PRINT = "READY_TO_PRINT"
}
