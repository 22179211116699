<div class="form-group row" [formGroup]="controlParent$ | async" *ngIf="formControl$ | async as control" [ngStyle]="columnLayout">
    <label [for]="field.id" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <sf-date-picker [inputId]="field.id"
                        [required]="checkRequired()"
                        [formControl]="control"></sf-date-picker>
    </div>
<!--    <div *ngIf="control.invalid && control.dirty" class="invalid-feedback">-->
<!--        <span *ngIf="control.errors.required">{{ control.dynamicField.label }} is required.</span>-->
<!--    </div>-->
</div>
