<div class="sf-fixed-nav">
    <section class="sidebar"
        [@.disabled]="disableAnimations"
        [@navAnimation]="collapsed ? 'collapsed' : 'expanded'"
        (@navAnimation.start)='captureStartEvent($event)'
        (@navAnimation.done)='captureDoneEvent($event)'
    >
        <nav aria-label="Primary" class="d-flex flex-column flex-grow-1 overflow-y" style="overflow-x: hidden; width: 180px;">
            <sf-logo
                [linkEnabled]="(logoLinkEnabled | async)"
                [class.collapsed]="menuCollapsed"
            ></sf-logo>
            <ng-container *ngIf="!menuCollapsed">
                <sf-navigation-items></sf-navigation-items>
            </ng-container>

            <ng-container>
                <button class="btn btn-link"
                        [hidden]="!menuCollapsed"
                        (click)="isNavPanelOpen = true"
                        cdkOverlayOrigin
                        #trigger="cdkOverlayOrigin"
                        [attr.aria-label]="menuTooltip"
                        [ngbTooltip]="menuTooltip"
                >
                    <fa-icon [icon]="'bars'" ></fa-icon>
                </button>
                <button class="btn btn-link"
                    [class]="menuCollapsed ? 'chevron-right' : 'chevron-left'"
                    (click)="toggleLeftNavState()"
                    [attr.aria-label]="menuCollapsed ? collapsedTooltip : expandedTooltip"
                    [ngbTooltip]="menuCollapsed ? collapsedTooltip : expandedTooltip"
                >
                    <fa-icon
                        [icon]="menuCollapsed ? 'chevron-double-right' : 'chevron-double-left'"
                    ></fa-icon>
                </button>
            </ng-container>
        </nav>
    </section>
</div>

<ng-template
    #overlay
    cdkConnectedOverlay
    #cdkConnectedOverlay="cdkConnectedOverlay"
    [cdkConnectedOverlayOrigin]="overlayTriggerOrigin"
    [cdkConnectedOverlayOpen]="isNavPanelOpen"
    [cdkConnectedOverlayOffsetX]="26"
    [cdkConnectedOverlayOffsetY]="-42"
    cdkConnectedOverlayPanelClass="nav-panel"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="nav-panel-backdrop"
    (overlayOutsideClick)="isNavPanelOpen = false"
>
    <sf-navigation-items
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        (click)="isNavPanelOpen = false"
    ></sf-navigation-items>
</ng-template>
