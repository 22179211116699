import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UserType } from "../../interfaces/user-type.enum";
import { SessionService } from "@sf/common";
import { CustomFormService } from "../../custom-form.service";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GrowlService } from "@sf/common";

@Component({
    selector: "sf-common-party-dialog",
    templateUrl: "./common-party-dialog.component.html",
    styleUrls: ["./common-party-dialog.component.scss"]
})
export class CommonPartyDialogComponent implements OnInit, OnDestroy {
    // @Input()
    commonParties: { value: any; editing: boolean }[];
    @Input()
    possibleParties: { value: any; editing: boolean }[];
    @Input()
    orgID: string;
    @Input()
    userType: UserType;
    @Input()
    formId: string;
    @Input()
    enableAdvancedCommonParties: boolean;

    @Output()
    commonPartySave: any;

    private _destroy$ = new Subject<void>();
    hasAdminPermission: boolean = false;
    isSubmitter: boolean = false;
    title: string;
    errorMessage: string = null;

    done = {
        text: "Done",
        responseValue: -1
    };
    primary = {
        text: "Save",
        callback: () => {
            if (typeof this.commonPartySave === "function") {
                this.commonPartySave(
                    this.commonParties.map((party) => {
                        return party.value;
                    })
                );
            }
        }
    };
    secondary = {
        text: "Cancel",
        responseValue: -1
    };

    constructor(
        private _sessionService: SessionService,
        private _customFormService: CustomFormService,
        private _growlService: GrowlService
    ) {}

    ngOnInit(): void {
        this.isSubmitter = this.userType === UserType.SUBMITTER;
        if (this.isSubmitter) {
            this.hasAdminPermission =
                this._sessionService.hasPermission(
                    "submitter_admin",
                    this.orgID
                ) ||
                this._sessionService.hasPermission(
                    "organization_admin",
                    this.orgID
                );
        }

        if (!this.possibleParties) {
            this.possibleParties = [];
        }

        // set the common parties
        if (this.enableAdvancedCommonParties) {
            combineLatest([
                this._customFormService.getESignCommonPartiesByLabel(
                    this.orgID,
                    "indexingCommonParty_Individual"
                ),
                this._customFormService.getESignCommonPartiesByLabel(
                    this.orgID,
                    "indexingCommonParty_Organization"
                )
            ])
                .pipe(takeUntil(this._destroy$))
                .subscribe(([individualParties, orgParties]) => {
                    this.commonParties = (individualParties as any[])
                        .concat(orgParties)
                        .map((value: any) => {
                            return { editing: false, value: value };
                        });

                    for (let i = 0; i < this.commonParties.length; ++i) {
                        let usedIndex = this.possibleParties.findIndex(
                            (party) => {
                                return (
                                    party.value ===
                                    this.commonParties[i].value.party
                                        .nameUnparsed
                                );
                            }
                        );
                        if (usedIndex > -1) {
                            this.possibleParties.splice(usedIndex, 1);
                        }
                    }
                });
        } else {
            this._customFormService
                .getCommonParties(this.formId)
                .pipe(takeUntil(this._destroy$))
                .subscribe((result: any) => {
                    if (result.errorMessage) {
                        this._growlService.error(result.errorMessage);
                    } else {
                        this.commonParties = result.map((value: any) => {
                            return { editing: false, value: value };
                        });

                        for (let i = 0; i < this.commonParties.length; ++i) {
                            let usedIndex = this.possibleParties.findIndex(
                                (party) => {
                                    return (
                                        party.value ===
                                        this.commonParties[i].value
                                    );
                                }
                            );
                            if (usedIndex > -1) {
                                this.possibleParties.splice(usedIndex, 1);
                            }
                        }
                    }
                });
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
    }

    removeParty($index: number) {
        let removed: any = this.commonParties.splice($index, 1)[0];
        if (this.enableAdvancedCommonParties) {
            this._customFormService
                .removeESignCommonParty(this.orgID, removed.value.id)
                .subscribe(() => {
                    return true;
                });
        } else {
            this.possibleParties.unshift(removed);
        }
    }

    setEditingParty($index: number) {
        this.commonParties[$index].editing =
            !this.commonParties[$index].editing;

        if (this.commonParties[$index].editing) {
            setTimeout(() => {
                document.getElementById("CPInput_" + $index).focus();
            });
        } else {
            if (
                this.commonParties[$index].value === "" ||
                this.commonParties[$index].value.party.nameUnparsed === ""
            ) {
                this.commonParties.splice($index, 1);
            }
            if (this.enableAdvancedCommonParties && this.hasAdminPermission) {
                this.updateCommonParty($index);
            }
        }
    }

    newParty() {
        if (this.enableAdvancedCommonParties) {
            let party = {
                nameUnparsed: "",
                addressStreet: "",
                addressCity: "",
                addressState: "",
                addressZip: "",
                phone: "",
                capacity: ""
            };
            let commonParty = {
                party: party,
                organizationId: this.orgID
            };
            this.commonParties.push({ value: commonParty, editing: true });
        } else {
            this.commonParties.push({ value: "", editing: true });
        }
        setTimeout(() => {
            document
                .getElementById("CPInput_" + (this.commonParties.length - 1))
                .focus();
        });
    }

    addParty(index: number) {
        let toAdd = this.possibleParties.splice(index, 1);
        if (this.enableAdvancedCommonParties) {
            let party = {
                nameUnparsed: toAdd[0].value ? toAdd[0].value : toAdd[0],
                addressStreet: "",
                addressCity: "",
                addressState: "",
                addressZip: "",
                phone: "",
                capacity: ""
            };
            let commonParty = {
                party: party,
                organizationId: this.orgID
            };
            this.commonParties.unshift({ value: commonParty, editing: true });
            this.updateCommonParty(0);
        } else {
            this.commonParties.unshift(toAdd[0]);
        }
    }

    updateCommonParty(index: number) {
        // for advanced common party stuff
        let commonParty = this.commonParties[index];
        this._customFormService
            .getSinglePartyNameParsed(commonParty.value.party.nameUnparsed)
            .subscribe((data: any) => {
                commonParty.value.party.capacity = data[0].bOrganization
                    ? "indexingCommonParty_Organization"
                    : "indexingCommonParty_Individual";

                this._customFormService
                    .saveESignCommonParty(this.orgID, commonParty.value)
                    .subscribe((data: any) => {
                        this.commonParties[index].editing = false;
                        // this.commonParties[index] = {value:data, editing:false};
                    });
            });
    }
}
