import { Component, OnInit } from "@angular/core";
import { PackageResult } from "../../universal-search.interfaces";
import { UniversalSearchResultComponent } from "../universal-search-result/universal-search-result.component";

@Component({
    selector: "sf-package-result",
    templateUrl: "./package-result.component.html",
    styleUrls: ["./package-result.component.scss"]
})
export class PackageResultComponent
    extends UniversalSearchResultComponent
    implements OnInit
{
    item: PackageResult;

    ngOnInit(): void {
        this.item = this.result as PackageResult;
    }
}
