import { dayjs } from "@sf/common";
import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class UserorgMessagesService {
    cachedMessage: string = null;
    cachedMessageTime: dayjs.Dayjs = null;
    numMinutesToExpireCache = 15;

    // flag so we don't display a cached message that was already closed and/or make a backend call to get an empty message before cache expires
    closedMostRecentMessage = false;
    // flag so we don't make another backend call if no messages had been return on a previous call if cache is active
    noMessageReturned = false;

    constructor(private _rpcClient: RpcClientService) {}

    getCriticalMessagesForCurrentUser(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            // check cache expiration
            if (this.cachedMessageTime) {
                let now = dayjs();
                if (
                    now.diff(this.cachedMessageTime, "minutes") >=
                    this.numMinutesToExpireCache
                ) {
                    this.cachedMessage = null;
                    this.noMessageReturned = false;
                    this.closedMostRecentMessage = false;
                }
            }

            //get critical messages, either from cached data or back-end call
            if (
                this.cachedMessage &&
                !this.noMessageReturned &&
                !this.closedMostRecentMessage
            ) {
                resolve(this.cachedMessage);
            } else if (this.noMessageReturned || this.closedMostRecentMessage) {
                resolve(null);
            } else {
                this._rpcClient
                    .makeRequest("erecord", "getCriticalMessages", {})
                    .subscribe((message: string) => {
                        if (message === null) {
                            // Set flag if no message is returned so we don't display a cached message that was already closed
                            this.noMessageReturned = true;
                        } else {
                            this.cachedMessage = message;
                            this.noMessageReturned = false;
                        }
                        this.closedMostRecentMessage = false;
                        this.cachedMessageTime = dayjs();
                        this.cachedMessage = message;
                        resolve(message);
                    });
            }
        });
    }

    closeCriticalMessagesForCurrentUser() {
        this._rpcClient
            .makeRequest("erecord", "closeCriticalMessages", {})
            .subscribe(() => {
                this.closedMostRecentMessage = true;
            });
    }
}
