import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { SubscriptionBaseService } from "@sf/common";
import { SocketService } from "@sf/common";
import { Observable } from "rxjs";
import {
    CustomerSurvey,
    CustomerSurveyAnswer
} from "../interfaces/customer-survey";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CustomerSurveyService extends SubscriptionBaseService {
    /** Private Variables **/
    protected namespace = "customerSurveys";
    protected product = "userorg";

    /** Public Variables **/

    constructor(
        protected _socket: SocketService,
        private _rpcClient: RpcClientService
    ) {
        super(_socket);
    }

    /** Public Methods **/
    subscribeToCustomerSurveys(
        scrambledUsername: string
    ): Observable<CustomerSurvey> {
        return this.on("update", scrambledUsername).pipe(
            map((response: { data: CustomerSurvey }) => {
                return response.data;
            })
        );
    }

    answerSurveyQuestion(surveyAnswer: CustomerSurveyAnswer) {
        return this._rpcClient.makeRequest(
            "userorg",
            "answerSurveyQuestion",
            surveyAnswer
        );
    }

    dismissSurvey(surveyID: string) {
        return this._rpcClient.makeRequest("userorg", "dismissSurvey", {
            surveyID
        });
    }

    sendSurveyTrigger(trigger: string, organizationID: string) {
        return this._rpcClient.makeRequest("userorg", "answerSurveyQuestion", {
            trigger,
            organizationID
        });
    }

    syncUserSurveys() {
        return this._rpcClient.makeRequest("userorg", "syncUserSurveys");
    }

    /**  Private Methods  **/
}
