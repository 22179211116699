import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DigitalSignature } from "../interfaces/digital-signature";
import { Pkcs12Reader } from "../interfaces/pkcs12-reader";
import { GrowlService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class Pkcs12ReaderService {
    constructor(private _growlService: GrowlService) {}

    getPKCS12Reader(forge: any) {
        let bagMap = forge.pki.oids;
        let map: Record<string, any> = {};

        function init(p12Raw: string, password: string) {
            map = {};
            let p12Der = forge.util.decode64(p12Raw);
            let p12Asn1 = forge.asn1.fromDer(p12Der);
            let p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, false, password);

            for (let sci = 0, safe; (safe = p12.safeContents[sci]); sci++)
                for (let sbi = 0, bag; (bag = safe.safeBags[sbi]); sbi++)
                    indexSafeBag(bag);
        }

        function getFirst(lookup: string) {
            for (let key in map) {
                if (map[key][lookup]) return map[key][lookup];
            }
            return null;
        }

        function extractLocalKey(bag: any) {
            let localKey = bag.attributes.localKeyId;
            if (localKey && localKey.length > 0)
                return forge.util.bytesToHex(localKey[0]);
            else return null;
        }

        function indexSafeBag(bag: any) {
            let key = extractLocalKey(bag);
            if (!map[key]) map[key] = { privateKey: null, certChain: [] };

            let data = map[key];
            switch (bag.type) {
                case bagMap.pkcs8ShroudedKeyBag: // private key
                    data.privateKey = bag.key;
                    break;
                case bagMap.certBag: // certificate
                    data.certChain.push(bag.cert);
                    break;
            }
        }

        function forge2ab(key: string) {
            let rsaPK = forge.pki.privateKeyToAsn1(key);
            let pkinfo = forge.pki.wrapRsaPrivateKey(rsaPK);
            let pkcs8 = forge.asn1.toDer(pkinfo).getBytes();
            let ab = new ArrayBuffer(pkcs8.length);
            let view = new Uint8Array(ab);
            for (let i = 0; i < pkcs8.length; i++)
                view[i] = pkcs8.charCodeAt(i);
            return ab;
        }

        return function (p12Raw: string, password: string) {
            init(p12Raw, password);
            return {
                getPrivateKey: function (options: any) {
                    let key = getFirst("privateKey");
                    if (!options || !options.format) {
                        return key;
                    } else {
                        switch (options.format) {
                            case "pem": // for pretty printing
                                return forge.pki.privateKeyToPem(key);
                            case "arraybuffer": // consumable by web crypto
                                return forge2ab(key);
                            default:
                                return key;
                        }
                    }
                },
                getCertChain: function (options: any) {
                    let chain = getFirst("certChain");
                    if (!options || !options.format) {
                        return chain;
                    } else {
                        switch (options.format) {
                            case "pem":
                                let pemChain = [];
                                for (
                                    let chi = 0, cert;
                                    (cert = chain[chi]);
                                    chi++
                                )
                                    pemChain.push(
                                        forge.pki.certificateToPem(cert)
                                    );
                                return pemChain;
                            default:
                                return chain;
                        }
                    }
                }
            } as Pkcs12Reader;
        };
    }

    getSignature(
        p12Reader: any,
        tamperSeal: string
    ): Observable<DigitalSignature> {
        let signature$ = new Subject<DigitalSignature>();
        let algorithm = "RSASSA-PKCS1-v1_5";
        let hash = "SHA-256";

        let buf = new ArrayBuffer(tamperSeal.length * 2);
        let bufView = new Uint16Array(buf);
        for (let i = 0, strLen = tamperSeal.length; i < strLen; i++) {
            bufView[i] = tamperSeal.charCodeAt(i);
        }
        let certPem = p12Reader.getCertChain({ format: "pem" });

        // IE 11 has a non-standard implementation of the crytpo stuff - handle it as a special case
        if ((window as any).msCrypto) {
            let crypto = (window as any).msCrypto.subtle;
            try {
                let importOp = crypto.importKey(
                    "pkcs8",
                    p12Reader.getPrivateKey({ format: "arraybuffer" }),
                    {
                        name: algorithm,
                        hash: { name: hash }
                    },
                    false,
                    ["sign"]
                );
                importOp.onerror = (e: any) =>
                    this.handleError(signature$, e.target);
                importOp.oncomplete = (e: any) => {
                    let key = e.target.result;

                    let signOp = crypto.sign(
                        {
                            name: algorithm,
                            hash: { name: hash }
                        },
                        key,
                        buf
                    );
                    signOp.onerror = (e: any) =>
                        this.handleError(signature$, e.target);
                    signOp.oncomplete = (e: any) => {
                        let signature = e.target.result;
                        let b64Sig = this.ab2b64(signature);
                        signature$.next({
                            signature: b64Sig,
                            certificate: certPem[0],
                            algorithm: algorithm
                        });
                        signature$.complete();
                    };
                };
            } catch (e) {
                this.handleError(
                    signature$,
                    "Unable to add digital signature."
                );
            }
        } else {
            try {
                let crypto = window.crypto.subtle;
                // this._spinnerService.startSpinner();
                crypto
                    .importKey(
                        "pkcs8",
                        p12Reader.getPrivateKey({ format: "arraybuffer" }),
                        {
                            name: algorithm,
                            hash: { name: hash }
                        },
                        false,
                        ["sign"]
                    )
                    .then(
                        (cryptoKey: any) => {
                            crypto
                                .sign(
                                    {
                                        name: algorithm,
                                        hash: { name: hash }
                                    },
                                    cryptoKey,
                                    buf
                                )
                                .then(
                                    (signature: any) => {
                                        let b64Sig = this.ab2b64(signature);
                                        signature$.next({
                                            signature: b64Sig,
                                            certificate: certPem[0],
                                            algorithm: algorithm
                                        });
                                        signature$.complete();
                                    },
                                    (error: any) =>
                                        this.handleError(signature$, error)
                                );
                        },
                        (error: any) => this.handleError(signature$, error)
                    );
            } catch (e) {
                this.handleError(
                    signature$,
                    "Unable to add digital signature."
                );
            }
        }
        return signature$.asObservable();
    }

    handleError(signature$: Subject<DigitalSignature>, error: any) {
        let errorMessage;
        if (error.data && error.data.errorMessage) {
            errorMessage = error.data.errorMessage;
        } else if (error.statusText) {
            errorMessage = error.statusText;
        } else {
            errorMessage =
                "There was an error adding digital signature to document";
        }

        this._growlService.error(errorMessage);
        signature$.error(errorMessage);
        log.debug(error);
    }

    ab2b64(ab: any) {
        let binary = "";
        let bytes = new Uint8Array(ab);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) binary += String.fromCharCode(bytes[i]);
        return window.btoa(binary);
    }
}
