import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Navigation, NavItem } from "@sf/common";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SessionService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { SubmitterOrganizationService } from "@sf/submitter/common";
import { distinctUntilChanged, first } from "rxjs/operators";
import { transformLinkWithParams } from "@sf/common";

interface CachedOrgAttrs {
    usesSalesTax: boolean;
    hasSubmitter: boolean;
    hasDocBuilder: boolean;
    hasESignEvent: boolean;
    hasTrustee: boolean;
    hasSubscription: boolean;
    hasPaperRecording: boolean;
    hasCAPCSubmitterAPI: boolean;
    hasCAPCLenderAPI: boolean;
    hasLCSettlement: boolean;
    hasLCLender: boolean;
    hasLenderVendor: boolean;
    hasNotaryVendor: boolean;
    hasEsignEventsVendor: boolean;
    hasRecipientVendor: boolean;
    hasSubmitterVendor: boolean;
    hasThirdParty: boolean;
    hasDocBuilderRON: boolean;
    hasLienRelease: boolean;
    canSeeHierarchyPage: boolean;
    isOrgAdministrator: boolean;
    hasAccountingPermission: boolean;
    hasSecurityPermission: boolean;
    hasCAPCPermission: boolean;
    hasRolesPermission: boolean;
    hasTemplatePermission: boolean;
    hasLenderAdminPermission: boolean;
    hasFeeSetPermission: boolean;
    hasOrgLoginPermission: boolean;
}

export type OrgViewType =
    | "submitter"
    | "lender"
    | "recipient"
    | "vendor"
    | "sign-event";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationPagesNavResolverService {
    private _orgIDKey: string = "orgID"; //in case param ever changes
    private _lastOrgID: string;
    private _cachedOrgAttrs: CachedOrgAttrs;
    private _cachedOrgAttrs$: BehaviorSubject<CachedOrgAttrs> =
        new BehaviorSubject<CachedOrgAttrs>(null);
    private _submitterOrgService: SubmitterOrganizationService;

    navigation$: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(
        null
    );

    constructor(
            private _sessionService: SessionService,
            private _userOrgService: UserOrgService,
            private _injector: Injector
    ) {}

    getPages(viewType: OrgViewType, route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Observable<Navigation>> {
        let orgID: string = route.params[this._orgIDKey];
        if (orgID && orgID.length == 6) {
            this._getOrgAttributes(orgID);
        }
        this._cachedOrgAttrs$
            .pipe(distinctUntilChanged())
            .subscribe((orgAttrs: CachedOrgAttrs) => {
                if (orgAttrs) {
                    this._getNavigation(viewType, orgAttrs, route);
                }
            });

        return of(this.navigation$);
    }

    _getOrgAttributes(orgID: string) {
        if (orgID === this._lastOrgID && this._cachedOrgAttrs) {
            return;
        }

        this._lastOrgID = orgID;
        this._cachedOrgAttrs = {
            usesSalesTax: false,
            hasSubmitter: false,
            hasDocBuilder: false,
            hasESignEvent: false,
            hasTrustee: false,
            hasSubscription: false,
            hasPaperRecording: false,
            hasCAPCSubmitterAPI: false,
            hasCAPCLenderAPI: false,
            hasLCSettlement: false,
            hasLCLender: false,
            hasLenderVendor: false,
            hasNotaryVendor: false,
            hasEsignEventsVendor: false,
            hasRecipientVendor: false,
            hasSubmitterVendor: false,
            hasThirdParty: false,
            hasDocBuilderRON: false,
            hasLienRelease: false,
            canSeeHierarchyPage: false,
            isOrgAdministrator: false,
            hasAccountingPermission: false,
            hasCAPCPermission: false,
            hasRolesPermission: false,
            hasSecurityPermission: false,
            hasTemplatePermission: false,
            hasLenderAdminPermission: false,
            hasFeeSetPermission: false,
            hasOrgLoginPermission: false
        };

        this._cachedOrgAttrs.hasSubmitter = this._sessionService.hasProduct(
                orgID, "submitter");
        this._cachedOrgAttrs.hasDocBuilder = this._sessionService.hasProduct(
                orgID, "submitter_signing");
        this._cachedOrgAttrs.hasESignEvent = this._sessionService.hasProduct(
                orgID, "esign_event");
        this._cachedOrgAttrs.hasTrustee = this._sessionService.hasProduct(orgID, "trustee");
        this._cachedOrgAttrs.hasSubscription = this._sessionService.hasProduct(orgID, "subscription");
        this._cachedOrgAttrs.hasPaperRecording = this._sessionService.hasProduct(
                orgID, "submitter_paper");
        this._cachedOrgAttrs.hasCAPCSubmitterAPI = this._sessionService.hasProduct(
                orgID, "capc_submitter_api");
        this._cachedOrgAttrs.hasCAPCLenderAPI = this._sessionService.hasProduct(
                orgID, "capc_lender_api");
        this._cachedOrgAttrs.hasLCSettlement = this._sessionService.hasProduct(
                orgID, "licensed_collaborator_settlement");
        this._cachedOrgAttrs.hasLCLender = this._sessionService.hasProduct(
                orgID, "licensed_collaborator_lender");
        this._cachedOrgAttrs.hasSubmitterVendor = this._sessionService.hasProduct(
                orgID, "submitter_vendor");
        this._cachedOrgAttrs.hasRecipientVendor = this._sessionService.hasProduct(
                orgID, "recipient_vendor");
        this._cachedOrgAttrs.hasLenderVendor = this._sessionService.hasProduct(
                orgID, "lender_vendor");
        this._cachedOrgAttrs.hasNotaryVendor = this._sessionService.hasProduct(
                orgID, "notary_vendor");
        this._cachedOrgAttrs.hasEsignEventsVendor = this._sessionService.hasProduct(
                orgID, "esign_events_vendor");
        this._cachedOrgAttrs.hasThirdParty = this._sessionService.hasProduct(
                orgID, "third_party");
        this._cachedOrgAttrs.hasDocBuilderRON = this._sessionService.hasProduct(
                orgID, "doc_builder_ron");
        this._cachedOrgAttrs.hasLienRelease = this._sessionService.hasProduct(
                orgID, "mers_api_integration");
        this._cachedOrgAttrs.isOrgAdministrator = this._sessionService.hasPermission(
                "organization_admin", orgID);
        this._cachedOrgAttrs.hasAccountingPermission = this._sessionService.hasPermission(
                "organization_accounting", orgID);
        this._cachedOrgAttrs.hasCAPCPermission = this._sessionService.hasAnyPermission(
                ["capc_view_loan_info", "capc_view_document"], orgID);
        this._cachedOrgAttrs.hasRolesPermission = this._sessionService.hasPermission(
                "organization_roles", orgID);
        this._cachedOrgAttrs.hasSecurityPermission = this._sessionService.hasAnyPermission(
                ["organization_security", "organization_admin"], orgID);
        this._cachedOrgAttrs.hasTemplatePermission = this._sessionService.hasPermission(
                "capc_manage_template", orgID);
        // this should exclude settlement agent admins
        this._cachedOrgAttrs.hasLenderAdminPermission =
                this._sessionService.hasPermission("organization_admin", orgID) &&
                this._sessionService.hasPermission("capc_manage_fee_set", orgID);
        this._cachedOrgAttrs.hasFeeSetPermission = this._sessionService.hasPermission(
                "capc_manage_fee_set", orgID);
        this._cachedOrgAttrs.hasOrgLoginPermission = this._sessionService.hasPermission(
                "organization_login", orgID);

        this._userOrgService
            .canSeeHierarchyPage()
            .pipe(first())
            .subscribe((can: boolean) => {
                if (this._cachedOrgAttrs)
                    this._cachedOrgAttrs.canSeeHierarchyPage = can;
                this._cachedOrgAttrs$.next(this._cachedOrgAttrs);
            });

        if (this._sessionService.hasProductInAnyOrg("submitter")) {
            if (!this._submitterOrgService) {
                this._injector.get(SubmitterOrganizationService);
            }
            if (this._submitterOrgService) {
                this._submitterOrgService
                    .getSubmitterOrganizationConfigPayments(orgID, false, false)
                    .pipe(first())
                    .subscribe((config: any) => {
                        if (config["Sales Tax"] && config["Sales Tax"].is_sales_tax_enabled) {
                            this._cachedOrgAttrs.usesSalesTax = true;
                        }
                        this._cachedOrgAttrs$.next(this._cachedOrgAttrs);
                    });
            }
        }

        this._cachedOrgAttrs$.next(this._cachedOrgAttrs);
    }

    _getNavigation(
        viewType: OrgViewType,
        orgAttrs: CachedOrgAttrs,
        route: ActivatedRouteSnapshot
    ) {
        let items: NavItem[];
        let parent: NavItem = {
            label: "Organizations",
            link: [""] //update for each view
        };
        switch (viewType) {
            case "submitter":
                parent.link = ["/submitter/organizations"];
                items = this._getSubmitterNavItems(route, orgAttrs);
                break;
            case "lender":
                parent.link = ["/lender/organizations"];
                items = this._getLenderNavItems(route, orgAttrs);
                break;
            case "recipient":
                parent.link = ["/recipient/organization-list"];
                items = this._getRecipientNavItems(route, orgAttrs);
                break;
            case "vendor":
                parent.link = ["/vendor/organizations"];
                items = this._getVendorNavItems(route, orgAttrs);
                break;
            case "sign-event":
                parent.link = ["/sign-event/organizations"];
                items = this._getSignEventNavItems(route, orgAttrs);
                break;
            default:
                break;
        }

        let navigation: Navigation = {
            parent,
            items
        };
        this.navigation$.next(navigation);
    }

    private _getSubmitterNavItems(
        route: ActivatedRouteSnapshot,
        orgAttrs: CachedOrgAttrs
    ): NavItem[] {
        const items: NavItem[] = [
            {
                label: "Services",
                link: transformLinkWithParams(["/submitter/organization/:orgID/dashboard"], route),
                children: [],
                expanded: true
            }
        ];
        items.push({
            label: "Contacts",
            link: transformLinkWithParams(["/submitter/organization/:orgID/contact"], route)
        }, {
            label: "Users & Roles",
            link: transformLinkWithParams(["/submitter/organization/:orgID/users"], route)
        });
        if (orgAttrs.hasRolesPermission) {
            items.push({
                label: "Manage Roles",
                link: transformLinkWithParams(["/submitter/organization/:orgID/roles/:roleID"], route)
            });
        }
        if (orgAttrs.hasSecurityPermission) {
            items.push({
                label: "Security",
                link: transformLinkWithParams(["/submitter/organization/:orgID/security"], route)
            });
        }
        items.push({
            label: "History",
            link: transformLinkWithParams(["/submitter/organization/:orgID/history"], route)
        });
        if (orgAttrs.hasTemplatePermission) {
            items.push({
                label: "Loan Templates",
                link: transformLinkWithParams([
                    "/submitter/organization/:orgID/settlement/submitter-loan-template-manager"
                ], route)
            });
        }
        let children = items[0].children;
        if (orgAttrs.hasSubscription) {
            children.push({
                label: "Subscription",
                link: transformLinkWithParams(["/submitter/organization/:orgID/subscription/license"], route)
            });
        }
        if (orgAttrs.hasOrgLoginPermission) {
            if (orgAttrs.hasSubmitter) {
                children.push({
                    label: "eRecording",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/e-record/license"], route)
                });
            }
            if (orgAttrs.hasDocBuilder) {
                children.push({
                    label: "Document Builder",
                    link: transformLinkWithParams([
                        "/submitter/organization/:orgID/document-builder/license"
                    ], route)
                });
            }
            if (orgAttrs.hasDocBuilderRON) {
                children.push({
                    label: "Document Builder RON",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/document-builder-ron/license"], route)
                })
            }
            if (orgAttrs.hasLienRelease) {
                children.push({
                    label: "Automated Document Request",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/lien-release/license"], route)
                })
            }
            if (orgAttrs.hasPaperRecording) {
                children.push({
                    label: "Recording PLUS",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/paper/license"], route)
                });
            }
            if (orgAttrs.hasLCSettlement) {
                children.push({
                    label: "Settlement",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/settlement/license"], route)
                });
            }
            if (orgAttrs.hasLCLender) {
                children.push({
                    label: "Lender",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/lender/license"], route)
                });
            }
            if (orgAttrs.hasTrustee) {
                children.push({
                    label: "Trustee",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/trustee/license"], route)
                });
            }
            if (orgAttrs.hasESignEvent) {
                children.push({
                    label: "eSign Events",
                    link: transformLinkWithParams(["/submitter/organization/:orgID/sign-event/license"], route)
                });
            }
            children.push({
                label: "Master Service Agreement",
                link: transformLinkWithParams(["/submitter/organization/:orgID/msa"], route)
            });
            /*  // Todo: maybe allow viewing vendor license - can do that now in Vendor view
            if (orgAttrs.isOrgAdministrator) {
                if (orgAttrs.hasLenderVendor) {
                    items.push({
                        label: "CAPC Vendor License",
                        link: transformLinkWithParams(["/submitter/organization/:orgID/vendor/license-lender-vendor"], route)
                    });
                }
                if (orgAttrs.hasNotaryVendor) {
                    items.push({
                        label: "Notary Vendor License",
                        link: transformLinkWithParams(["/submitter/organization/:orgID/license-notary-vendor"], route)
                    });
                }
                if (orgAttrs.hasRecipientVendor) {
                    items.push({
                        label: "Recipient Vendor License",
                        link: transformLinkWithParams(["/submitter/organization/:orgID/license-recipient-vendor"], route)
                    });
                }
                if (orgAttrs.hasSubmitterVendor) {
                    items.push({
                        label: "Submitter Vendor License",
                        link: transformLinkWithParams(["/submitter/organization/:orgID/license-submitter-vendor"], route)
                    });
                }
            }
            */
        }
        return items;
    }

    private _getLenderNavItems(route: ActivatedRouteSnapshot, orgAttrs: CachedOrgAttrs): NavItem[] {
        const items: NavItem[] = [
            {
                label: "Contacts",
                link: transformLinkWithParams(["/lender/organization/:orgID/contact"], route)
            }, {
                label: "Users & Roles",
                link: transformLinkWithParams(["/lender/organization/:orgID/users"], route)
            }
        ];
        items.push({
            label: "Services",
            link: transformLinkWithParams(["/lender/organization/:orgID/dashboard"], route)
        });
        if (orgAttrs.hasSecurityPermission) {
            items.push({
                label: "Security",
                link: transformLinkWithParams(["/lender/organization/:orgID/security"], route)
            });
        }
        if (orgAttrs.hasRolesPermission) {
            items.push({
                label: "Manage Roles",
                link: transformLinkWithParams(["/lender/organization/:orgID/roles"], route),
                inexactMatching: true // Needed for active route highlighting since the URL is altered after routing
            });
        }
        if (orgAttrs.hasOrgLoginPermission) {
            if (orgAttrs.hasSubscription) {
                items.push({
                    label: "Subscription License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/subscription-license"], route)
                });
            }
            if (orgAttrs.hasLCLender) {
                items.push({
                    label: "Lender License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/license"], route)
                });
            }
            if (orgAttrs.hasLCSettlement) {
                items.push({
                    label: "Settlement License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/settlement-license"], route)
                });
            }
            if (orgAttrs.hasSubmitter) {
                items.push({
                    label: "eRecording License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/submitter-license"], route)
                });
            }
            if (orgAttrs.hasDocBuilder) {
                items.push({
                    label: "Document Builder License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/doc-builder-license"], route)
                });
            }
            if (orgAttrs.hasDocBuilderRON) {
                items.push({
                    label: "Document Builder RON License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/document-builder-ron-license"], route)
                });
            }
            if (orgAttrs.hasLienRelease) {
                items.push({
                    label: "Automated Document Request License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/lien-release-license"], route)
                });
            }
            if (orgAttrs.hasPaperRecording) {
                items.push({
                    label: "Recording PLUS License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/paper-license"], route)
                });
            }
            if (orgAttrs.hasESignEvent) {
                items.push({
                    label: "eSign Events License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/esign-event-license"], route)
                });
            }
            if (orgAttrs.hasTrustee) {
                items.push({
                    label: "Trustee License",
                    link: transformLinkWithParams(["/lender/organization/:orgID/trustee-license"], route)
                });
            }
            items.push({
                label: "Master Service Agreement",
                link: transformLinkWithParams(["/lender/organization/:orgID/msa"], route)
            });
        }
        if (orgAttrs.hasTemplatePermission) {
            items.push({
                label: "Loan Templates",
                link: transformLinkWithParams([
                    "/lender/organization/:orgID/lender-loan-template-manager"
                ], route)
            });
        }
        if (orgAttrs.hasLenderAdminPermission || orgAttrs.isOrgAdministrator) {
            if (orgAttrs.hasCAPCSubmitterAPI || orgAttrs.hasCAPCLenderAPI) {
                items.push({
                    label: "API Tokens",
                    link: transformLinkWithParams(["/lender/organization/:orgID/tokens"], route)
                });
            }
        }
        if (orgAttrs.hasCAPCPermission && (orgAttrs.isOrgAdministrator || orgAttrs.hasAccountingPermission)) {
            items.push({
                label: "Default Notifications",
                link: transformLinkWithParams(["/lender/organization/:orgID/lendernotifications"], route)
            });
        }
        if (orgAttrs.hasLCLender && orgAttrs.isOrgAdministrator) {
            items.push({
                label: "Document Options",
                link: transformLinkWithParams([
                    "/lender/organization/:orgID/document-sort-order"
                ], route)
            });
        }
        if (orgAttrs.isOrgAdministrator && ( orgAttrs.hasLCSettlement || orgAttrs.hasLCLender)) {
            items.push({
                label: "Third Party Access",
                link: transformLinkWithParams([
                    "/lender/organization/:orgID/lender-third-party-access"
                ], route)
            });
        }
        // This was commented out in the old page service. I'm including it in case we have plans to re-enable it in the future.
        // these are only ever available in the "lender" context because only available to orgs with lender product
        // if (orgAttrs.hasLenderAdminPermission) {
        //     items.push({
        //         label: "Settlement Organizations",
        //         state: "lender.organization.closingorgs"
        //         link: ??
        //     });
        // }
        if (orgAttrs.hasFeeSetPermission) {
            items.push({
                label: "Custom Fee Set",
                link: transformLinkWithParams( ["/lender/organization/:orgID/fee-set"], route)
            });
        }
        return items;
    }

    private _getRecipientNavItems(
        route: ActivatedRouteSnapshot,
        orgAttrs: CachedOrgAttrs
    ): NavItem[] {
        const items = [
            {
                label: "Contacts",
                link: transformLinkWithParams(
                    ["/recipient/organization/:orgID/contact"],
                    route
                )
            },
            {
                label: "Users & Roles",
                link: transformLinkWithParams(
                    ["/recipient/organization/:orgID/users"],
                    route
                )
            }
        ];
        if (orgAttrs.hasRolesPermission) {
            items.push({
                label: "Manage Roles",
                link: transformLinkWithParams(
                    ["/recipient/organization/:orgID/roles/:roleID"],
                    route
                )
            });
        }
        if (orgAttrs.hasSecurityPermission) {
            items.push({
                label: "Security",
                link: transformLinkWithParams(
                    ["/recipient/organization/:orgID/security"],
                    route
                )
            });
        }
        return items;
    }

    private _getVendorNavItems(
        route: ActivatedRouteSnapshot,
        orgAttrs: CachedOrgAttrs
    ): NavItem[] {
        const items = [
            {
                label: "Contact",
                link: transformLinkWithParams(
                    ["/vendor/organization/:orgID/contact"],
                    route
                )
            },
            {
                label: "Users & Roles",
                link: transformLinkWithParams(
                    ["/vendor/organization/:orgID/users"],
                    route
                )
            }
        ]; //may need to update links with "/app" in the future...
        if (orgAttrs.hasOrgLoginPermission) {
            items.push({
                label: "Services",
                link: transformLinkWithParams(
                    ["/vendor/organization/:orgID/dashboard"],
                    route
                )
            });
        }
        if (orgAttrs.hasSecurityPermission) {
            items.push({
                label: "Security",
                link: transformLinkWithParams(
                    ["/vendor/organization/:orgID/security"],
                    route
                )
            });
        }
        if (orgAttrs.hasRolesPermission) {
            items.push({
                label: "Manage Roles",
                link: transformLinkWithParams(
                    ["/vendor/organization/:orgID/roles/:roleID"],
                    route
                )
            });
        }
        if (orgAttrs.hasOrgLoginPermission) {
            if (orgAttrs.hasLenderVendor) {
                items.push({
                    label: "CAPC License",
                    link: transformLinkWithParams(
                        ["/vendor/organization/:orgID/license-lender"],
                        route
                    )
                });
            }
            if (orgAttrs.hasNotaryVendor) {
                items.push({
                    label: "Notary License",
                    link: transformLinkWithParams(
                            ["/vendor/organization/:orgID/license-notary"],
                            route
                    )
                });
            }
            if (orgAttrs.hasEsignEventsVendor) {
                items.push({
                    label: "eSign Events License",
                    link: transformLinkWithParams(
                            ["/vendor/organization/:orgID/license-esign-events"],
                            route
                    )
                });
            }
            if (orgAttrs.hasRecipientVendor) {
                items.push({
                    label: "Recipient License",
                    link: transformLinkWithParams(
                        ["/vendor/organization/:orgID/license-recipient"],
                        route
                    )
                });
            }
            if (orgAttrs.hasSubmitterVendor) {
                items.push({
                    label: "Submitter License",
                    link: transformLinkWithParams(
                        ["/vendor/organization/:orgID/license-submitter"],
                        route
                    )
                });
            }
            if (
                orgAttrs.isOrgAdministrator &&
                this._sessionService.hasAnyProductInAnyOrg([
                    "submitter_vendor_api_integration",
                    "vendor_api_integration",
                    "notary_api_integration",
                    "esign_events_api_integration",
                    "recipient_vendor_api_integration",
                    "internal_api_integration",
                    "internal_erecord_plus_facilitator"
                ])
            ) {
                items.push({
                    label: "API Tokens",
                    link: transformLinkWithParams(
                        ["/vendor/organization/:orgID/tokens"],
                        route
                    )
                });
            }
        }
        return items;
    }

    private _getSignEventNavItems(route: ActivatedRouteSnapshot, orgAttrs: CachedOrgAttrs): NavItem[] {
        const items = [
            {
                label: "Contact",
                link: transformLinkWithParams(["/sign-event/organization/:orgID/contact"], route)
            },
            {
                label: "Users & Roles",
                link: transformLinkWithParams(["/sign-event/organization/:orgID/users"], route)
            }
        ];
        items.push({
            label: "Services",
            link: transformLinkWithParams(["/sign-event/organization/:orgID/dashboard"], route)
        });
        if (orgAttrs.hasSecurityPermission) {
            items.push({
                label: "Security",
                link: transformLinkWithParams(["/sign-event/organization/:orgID/security"], route)
            });
        }
        if (orgAttrs.hasRolesPermission) {
            items.push({
                label: "Manage Roles",
                link: transformLinkWithParams(["/sign-event/organization/:orgID/roles/:roleID"], route)
            });
        }
        if (orgAttrs.hasOrgLoginPermission) {
            if (orgAttrs.hasSubscription) {
                items.push({
                    label: "Subscription License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/subscription-license"], route)
                });
            }
            if (orgAttrs.hasESignEvent) {
                items.push({
                    label: "eSign Events License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/license"], route)
                });
                if (orgAttrs.isOrgAdministrator) {
                    items.push({
                        label:"eSign Events Settings",
                        link: transformLinkWithParams(["/sign-event/organization/:orgID/settings"], route)
                    });
                }
            }
            if (orgAttrs.hasSubmitter) {
                items.push({
                    label: "eRecording License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/submitter-license"], route)
                });
            }
            if (orgAttrs.hasDocBuilder) {
                items.push({
                    label: "Document Builder License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/doc-builder-license"], route)
                });
            }
            if (orgAttrs.hasDocBuilderRON) {
                items.push({
                    label: "Document Builder RON License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/document-builder-ron-license"], route)
                });
            }
            if (orgAttrs.hasLienRelease) {
                items.push({
                    label: "Automated Document Request License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/lien-release-license"], route)
                });
            }
            if (orgAttrs.hasPaperRecording) {
                items.push({
                    label: "Recording PLUS License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/paper-license"], route)
                });
            }
            if (orgAttrs.hasLCLender) {
                items.push({
                    label: "Lender License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/lender-license"], route)
                });
            }
            if (orgAttrs.hasLCSettlement) {
                items.push({
                    label: "Settlement License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/settlement-license"], route)
                });
            }
            if (orgAttrs.hasTrustee) {
                items.push({
                    label: "Trustee License",
                    link: transformLinkWithParams(["/sign-event/organization/:orgID/trustee-license"], route)
                });
            }
            items.push({
                label: "Master Service Agreement",
                link: transformLinkWithParams(["/sign-event/organization/:orgID/msa"], route)
            });
        }
        return items;
    }
}
