import { Component, Input, OnInit } from "@angular/core";
import { DocType } from "../../interfaces/docType.interface";
import { ModalButton } from "@sf/common";

@Component({
    selector: "sf-doc-types-dialog",
    template: `
        <sf-modal-header title="Doc Types"></sf-modal-header>
        <div tabindex="0" class="modal-body">
            <ul>
                <li *ngFor="let type of docTypes">
                    {{ type.name }}
                </li>
            </ul>
        </div>
        <sf-modal-footer [primary]="primary"></sf-modal-footer>
    `,
    styleUrls: ["./doc-types-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class DocTypesDialogComponent implements OnInit {
    @Input()
    docTypes: Array<DocType>;

    title: string;
    primary: ModalButton;

    constructor() {}

    ngOnInit() {
        this.title = "Doc Types";
        this.primary = {
            text: "OK",
            responseValue: true
        };
    }
}
