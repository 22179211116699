<sf-modal-header title="{{title}}"></sf-modal-header>
<div id="phoneSupportQuestion" class="modal-body">
    <div class="d-flex flex-column">
        <div class="title">Phone Support Question:</div>
        <div class="mb-3">{{supportQuestion}}</div>
        <div class="title">Answer:</div>
        <div class="mb-3">{{supportAnswer}}</div>
        <div>If they answer the question correctly, go ahead and press OK.</div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
