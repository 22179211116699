import { Injectable } from "@angular/core";
import { createStore, Reducer, StoreEnhancer, Store } from "redux";

@Injectable({
    providedIn: "root"
})
export class ReduxService {
    constructor() {}

    createStore<S>(reducer: Reducer<S>, enhancer?: StoreEnhancer<S>): Store<S> {
        return createStore(reducer, enhancer);
    }
}
