import { Injectable } from "@angular/core";

@Injectable()
export class SfSelectValidateComponentService {
    constructor() {}

    validateComponent(ctrl: any) {
        let name = ctrl.name || ctrl.selectorName;
        let hasErrors = false;
        let originalLevel = log.getLevel();
        if (originalLevel > 4) {
            log.setLevel(4);
        }

        if (ctrl.selectedOption && ctrl.selectedOptions) {
            log.error(
                name +
                    ': sf-select elements can only have a "selected-option" or a "selected-options" attribute, not both.'
            );
            hasErrors = true;
        }

        if (ctrl.isSingleSelect && ctrl.selectedOptions) {
            log.error(
                name +
                    ': sf-select elements cannot use the "selected-options" attribute in conjunction with "is-single-select". Use "selected-option" instead.'
            );
            hasErrors = true;
        }

        if (!ctrl.isSingleSelect && ctrl.selectedOption) {
            log.error(
                name +
                    ': sf-select elements cannot use the "selected-option" attribute when "is-single-select="true"" is not present. Use "selected-options" instead.'
            );
            hasErrors = true;
        }

        if (ctrl.isSingleSelect && ctrl.hasSelectAll) {
            log.error(
                name +
                    ': sf-select elements cannot use the "has-select-all" attribute with "is-single-select".'
            );
            hasErrors = true;
        }

        if (ctrl.showIconInDisplay && !ctrl.isSingleSelect) {
            log.error(
                name +
                    ": sf-select elements cannot use the show-icon-in-display attribute when it is a multiselect control. You must set is-single-select to true."
            );
            hasErrors = true;
        }

        if (ctrl.showIconInDisplay && !ctrl.rightMetadataIsIcon) {
            log.error(
                name +
                    ": sf-select elements cannot use the show-icon-in-display attribute when the right metadata is not an icon. You must set right-metadata-is-icon to true."
            );
            hasErrors = true;
        }

        if (ctrl.showIconInDisplay && !ctrl.rightMetadataKey) {
            log.error(
                name +
                    ": sf-select elements cannot use the show-icon-in-display attribute when no right metadata is present. Set right-metadata-key to the string name of the key on your items that represents the right metadata icon."
            );
            hasErrors = true;
        }

        if (!name && hasErrors) {
            log.warning(
                name +
                    ': sf-select elements can define a "name" or a "selector-name" (in Angular 2+) attribute that aids in debugging error codes.'
            );
        }

        log.setLevel(originalLevel);
    }
}
