import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { EEligibilityDataService } from "../e-eligibility-data.service";
import { ModalButton } from "@sf/common";
import { UIHistoryEventModel } from "@sf/userorg/common";
import { GridOptions, GridApi, GetRowIdParams } from "@ag-grid-community/core";
import { TemplateRendererComponent } from "@sf/common";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

@Component({
    selector: "sf-e-eligibility-change-history-dialog",
    templateUrl: "./e-eligibility-change-history-dialog.component.html",
    styleUrls: ["./e-eligibility-change-history-dialog.component.scss"]
})
export class EEligibilityChangeHistoryDialogComponent implements OnInit {
    @ViewChild("dateTemplate", { static: true })
    public dateTemplate: TemplateRef<any>;
    @ViewChild("detailsTemplate", { static: true })
    public detailsTemplate: TemplateRef<any>;
    @Input() orgID: string;
    @Input() name: string;
    @Input() stateAbbrev?: string;

    public okButton: ModalButton = {
        text: "OK",
        callback: () => {
            return true;
        }
    };

    public gridOptions: GridOptions;
    public loadingData = true;
    public title: string = "";

    private _gridApi: GridApi;

    constructor(private _eEligibilityService: EEligibilityDataService) {}

    public ngOnInit(): void {
        this.gridOptions = this._getGridOptions();
        this.title = `${this.name} eEligibility Change History`;
    }

    private _getGridOptions(): GridOptions {
        return {
            columnDefs: [
                {
                    autoHeight: true,
                    headerName: "Changes",
                    field: "details",
                    flex: 1,
                    resizable: true,
                    cellRenderer: TemplateRendererComponent,
                    cellRendererParams: {
                        ngTemplate: this.detailsTemplate
                    }
                },
                {
                    cellRenderer: TemplateRendererComponent,
                    cellRendererParams: {
                        ngTemplate: this.dateTemplate
                    },
                    field: "date",
                    headerName: "Updated Date",
                    width: 130
                },
                {
                    headerName: "Updated By",
                    field: "username",
                    width: 120
                },
                {
                    field: "displayDate",
                    hide: true,
                    sort: "desc"
                }
            ],
            getRowId: (params: GetRowIdParams): string => params.data.id,
            onGridReady: ($event) => {
                this._gridApi = $event.api;
                let id = this.stateAbbrev ? this.stateAbbrev : this.orgID;
                this._eEligibilityService
                    .getEEligibilityHistory(id)
                    .subscribe((history) => this._gridApi.setRowData(history));
            },
            overlayLoadingTemplate: `<span class="loading-overlay">${icon(
                faSpinner,
                {
                    classes: ["fa-spin"],
                    attributes: { "aria-hidden": "true" }
                }
            ).html.join("")} Loading</span>`,
            rowHeight: 30
        };
    }
}
