import { Injectable } from "@angular/core";
import {
    ArrayField,
    Conditional,
    ConditionalExpression,
    DateRangeFieldViewState,
    DynamicField,
    GroupField,
    OverrideDynamicField,
    ROOT_FIELD_KEY,
    SelectField,
    UIField,
    UIForm
} from "../interfaces";
import { DynamicFormBuilder } from "./dynamic-form-builder";
import { UIFieldType } from "../enums/ui-field-type.enum";
import { FieldType } from "../enums/field-type.enum";
import { ConditionalType } from "../enums/conditional.enum";
import { RequirementType } from "../enums/ui-field-requirement.enum";
import { DynamicFormGroup } from "../controls";
import { generateRandomString } from "@sf/common";
import {
    getDefaultComponentFromType,
    reformatListableObject
} from "../helpers";
import { Validators } from "@angular/forms";
import { DateRangePreset } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class UiFieldTransformerService {
    dateRangePresets: DateRangePreset[];
    constructor(private _fb: DynamicFormBuilder) {}

    updateDateRangePresets(
        dateRangePresets: DateRangePreset[]
    ): UiFieldTransformerService {
        this.dateRangePresets = dateRangePresets;
        return this;
    }

    transform(form: UIForm, value: any = {}): DynamicField[] {
        if (
            (form as any).listField &&
            (form as any).type === UIFieldType.LISTTABLE
        ) {
            const reformatted = reformatListableObject(form);
            return this._parseFields(reformatted.mapFields, value);
        } else {
            return this._parseFields(form.mapFields, value);
        }
    }

    convertToDynamicFormGroup(form: UIForm, value: any = {}): DynamicFormGroup {
        const dynamicFields = this.transform(form, value);

        return this._fb.build(dynamicFields);
    }

    private _getDynamicField(
        field: UIField,
        value: any = null,
        parentPath: any[]
    ): DynamicField | GroupField | ArrayField {
        switch (field.type) {
            case UIFieldType.CONTAINER:
            case UIFieldType.PARTY_NAME:
                if (field.listField) {
                    return this._getArrayField(field, value || [], parentPath);
                }

                return this._getGroupField(field, value || {}, parentPath);
            case UIFieldType.SELECT:
            case UIFieldType.MULTISELECT:
                return this._getSelectField(field, value, parentPath);
            case UIFieldType.DATERANGE:
            case UIFieldType.DATERANGE_SIMPLE:
                return this._getDateRangeField(field, value, parentPath);
            case UIFieldType.LISTTABLE:
                return this._getSortableField(field, value || {}, parentPath);
            case UIFieldType.CHECKBOX:
            case UIFieldType.CURRENCY:
            case UIFieldType.DATE:
            case UIFieldType.NUMERIC:
            case UIFieldType.RADIO:
            case UIFieldType.TEXTFIELD:
            case UIFieldType.HIDDEN:
            default:
                return this._getBasicField(field, value, parentPath);
        }
    }

    private _getArrayField(
        field: UIField,
        valueArray: any[] = [],
        parentPath: any[]
    ): ArrayField {
        const arrayField: ArrayField = {
            ...this._parseBasicFieldValues(field, valueArray, parentPath),
            fields: [],
            repeatableFields: null
        };
        parentPath = parentPath.concat(field.path);
        field.listField.path = "*";

        arrayField.repeatableFields = this._getDynamicField(
            field.listField as UIField,
            null,
            []
        );

        if (field.listField) {
            const initialValues =
                valueArray.length > 0
                    ? valueArray
                    : arrayField.defaultValue
                    ? arrayField.defaultValue
                    : ([] as []);
            initialValues.forEach((value: any, index: number) => {
                let itemField = JSON.parse(JSON.stringify(field.listField));
                itemField.path = generateRandomString(5);

                arrayField.fields.push(
                    this._getDynamicField(itemField, value, parentPath) as any
                );
            });
        }
        return arrayField;
    }

    private _getGroupField(
        field: UIField,
        value: any = {},
        parentPath: any[]
    ): GroupField {
        const groupField: GroupField = {
            ...this._parseBasicFieldValues(field, value, parentPath),
            fields: []
        };

        if (field.mapFields) {
            groupField.fields = this._parseFields(field.mapFields, value, [
                ...parentPath,
                field.path
            ]);
        }

        return groupField;
    }

    private _getSortableField(
        field: UIField,
        valueArray: any[] = [],
        parentPath: any[]
    ): ArrayField {
        let arrayField = this._getArrayField(field, valueArray, parentPath);
        arrayField.sortableTable = true;
        return arrayField;
    }

    private _getSelectField(
        field: UIField,
        value: any,
        parentPath: any[]
    ): SelectField {
        const baseField = this._parseBasicFieldValues(field, value, parentPath);
        return {
            ...baseField,
            viewState: {
                ...baseField.viewState,
                options: field.options,
                externalFilter:
                    field.optionsProduct && field.optionsMethod
                        ? {
                              product: field.optionsProduct,
                              method: field.optionsMethod,
                              defaultValue: field.defaultValue
                          }
                        : undefined
            }
        };
    }

    private _getBasicField(
        field: UIField,
        value: any,
        parentPath: any[]
    ): DynamicField {
        return this._parseBasicFieldValues(field, value, parentPath);
    }

    private _getDateRangeField(
        field: UIField,
        value: any,
        parentPath: any[]
    ): DynamicField {
        const baseField = this._parseBasicFieldValues(field, value, parentPath);
        return {
            ...baseField,
            viewState: {
                ...baseField.viewState,
                dateRangePresets: this.dateRangePresets
            } as DateRangeFieldViewState
        };
    }

    private _parseBasicFieldValues(
        field: UIField,
        value: any,
        parentPath: any[] = []
    ): DynamicField {
        const fieldType = this._mapUIFieldToFieldType(field);
        const fullPath = [...parentPath, field.path];
        const visibility = this._mapVisibility(field);
        const basicField: DynamicField = {
            id: fullPath + "-" + generateRandomString(10),
            type: fieldType,
            component: getDefaultComponentFromType(fieldType),
            path: field.path,
            fullPath: fullPath,
            label: field.label,
            value: value,
            formState: {
                validators: [],
                disabled: visibility.disabled
            },
            viewState: {
                visible: visibility.visible
            },
            showLabel: field.showLabel ?? true
        };

        if (typeof field.defaultValue !== "undefined") {
            basicField.defaultValue = field.defaultValue;
        }

        if (typeof field.placeholder !== "undefined") {
            basicField.placeholder = field.placeholder;
        }

        if (typeof field.requirement === "undefined") {
            field.requirement = RequirementType.OPTIONAL;
        }

        if (field.requirement === RequirementType.REQUIRED) {
            basicField.formState.validators.push({
                identifier: "required",
                type: "sync",
                validators: [Validators.required]
            });
        }

        if (field.conditionals) {
            basicField.conditionals = this._parseConditionals(
                field,
                parentPath
            );
        }

        if (field.options) {
            basicField.viewState.options = field.options;
        }

        return basicField;
    }

    private _mapUIFieldToFieldType(field: UIField): FieldType {
        if (field.listField && field.type !== UIFieldType.MULTISELECT) {
            return FieldType.ARRAY;
        } else if (field.type === UIFieldType.CONTAINER) {
            return FieldType.GROUP;
        }

        const uiFieldType = UIFieldType[field.type];

        return FieldType[uiFieldType as keyof typeof FieldType] as FieldType;
    }

    private _parseFields(
        uiFields: UIField[],
        value: any = {},
        parentPath: any[] = []
    ): DynamicField[] {
        const fields = [];

        if (typeof uiFields !== "undefined") {
            for (let field of uiFields) {
                let newValue = null;
                if (value[field.path]) {
                    newValue = value[field.path];
                }
                let f: DynamicField = this._getDynamicField(
                    field,
                    newValue,
                    parentPath
                );
                if (field.description && field.showDescriptionAsTooltip)
                    f.toolTip = field.description;
                fields.push(f);
            }
        }

        return fields;
    }

    private _parseConditionals(
        field: UIField,
        parentPath: any[]
    ): Conditional[] {
        const conditionalArray: Conditional[] = [];

        for (const conditional of field.conditionals) {
            const fullPath = [...parentPath, conditional.conditionField].filter(
                (part) => !!part
            );
            const condition: Conditional = {
                type: ConditionalType.EQUAL,
                value: conditional.conditionValue,
                field: fullPath,
                fullPath: fullPath.join("."),
                override: this._parsePartialBaseField(conditional.override)
            };

            if (!condition.value) {
                condition.type = ConditionalType.NOTNULL;
            }

            if (conditional.conditionExpression) {
                condition.type = ConditionalType.EXPRESSION;
                (condition as ConditionalExpression).expression =
                    conditional.conditionExpression;
                condition.fullPath = ROOT_FIELD_KEY;
            }

            conditionalArray.push(condition);
        }

        return conditionalArray;
    }

    private _parsePartialBaseField(
        override: Partial<UIField>
    ): OverrideDynamicField {
        const field: OverrideDynamicField = {
            value: null
        };

        if (override.type) {
            field.type = this._mapUIFieldToFieldType(override as UIField);
        }
        if (override.label) {
            field.label = override.label;
        }
        if (override.requirement) {
            if (override.requirement === RequirementType.PROHIBITED) {
                if (!field.formState) {
                    field.formState = {};
                }
                field.formState.disabled = true;
            } else if (override.requirement !== RequirementType.NOT_REQUIRED) {
                if (!field.viewState) {
                    field.viewState = {};
                }
                field.viewState.visible = true;
            }
            if (override.requirement === RequirementType.REQUIRED) {
                if (!field.formState) {
                    field.formState = {};
                }
                field.formState.validators = [
                    {
                        identifier: "required",
                        type: "sync",
                        validators: [Validators.required]
                    }
                ];
            }
        }
        // if (override.conditionals) {
        //     field.conditionals = this._parseConditionals(override.conditionals);
        // }

        return field;
    }

    private _mapVisibility(field: Partial<UIField>) {
        let visible = field.requirement !== RequirementType.NOT_REQUIRED;
        let disabled = field.requirement === RequirementType.PROHIBITED;
        if (!visible) {
            disabled = visible;
        }
        return {
            visible,
            disabled
        };
    }
}
