import { Injectable } from "@angular/core";
import { CustomFormSaveData } from "./interfaces";
import {
    RpcClientService,
    SocketService,
    SubscriptionBaseService
} from "@sf/common";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { DocViewerData } from "./interfaces/document-viewer-data.interface";
import { SaveField } from "./interfaces/save-field.interface";
import { map } from "rxjs/operators";
interface StateSearchParams {
    state?: string;
    vendor?: string;
}

@Injectable()
export class CustomFormService extends SubscriptionBaseService {
    static autoCompleteSubject: BehaviorSubject<any>;
    private static _lastAutoCompleteData: any;

    protected product = "document";
    protected namespace = "documentOcrSuggestions";
    protected options: any = {};

    constructor(
        protected _socket: SocketService,
        private _rpcClient: RpcClientService
    ) {
        super(_socket);
    }

    getOcrSuggestionUpdates$(
        documentID: string
    ): Observable<{ pageAnalysisID: string }> {
        return this.onAll(documentID).pipe(
            map((response: any) => response?.data)
        );
    }

    getVendors() {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRecipientVendors",
            {}
        );
    }

    searchCustomForms(state: string, vendor: string) {
        let search: StateSearchParams = {};
        if (state) {
            search.state = state;
        }
        if (vendor) {
            search.vendor = vendor;
        }

        return this._rpcClient.makeRequest(
            "erecord",
            "searchCustomForms",
            search
        );
    }

    runRecipientCustomizationsOnDocument(documentID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "runRecipientCustomizationsOnDocument",
            {
                documentID: documentID
            }
        );
    }

    getCustomFormsByIDs(formIds: any[]) {
        return this._rpcClient.makeRequest("erecord", "getCustomFormsByIDs", {
            customFormIDs: formIds
        });
    }

    setCustomForm(saveFormData: CustomFormSaveData) {
        return this._rpcClient.makeRequest("erecord", "setCustomForm", {
            form: saveFormData
        });
    }

    getDocumentsAndForms(documentID: string): Observable<DocViewerData> {
        return this._rpcClient.makeRequest("erecord", "getDocumentsAndForms", {
            documentID: documentID
        });
    }

    getDocumentGroupInfo(documentID: string, customFieldIDs: string[]) {
        return this._rpcClient.makeRequest("erecord", "getDocumentGroupInfo", {
            documentID,
            customFieldIDs
        });
    }

    getOtherDocumentIDs(
        documentID: string,
        overrideDefaultErrorHandling?: boolean
    ): Observable<{ [key: string]: string }> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getOtherDocumentIDs",
            {
                documentID
            },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    getAutoCompleteSuggestions(
        documentID: string,
        formID: string,
        pageAnalysisID?: string
    ) {
        let params: any = { documentID: documentID, customFormID: formID };
        if (pageAnalysisID) {
            params.pageAnalysisID = pageAnalysisID;
        }
        return this._rpcClient.makeRequest(
            "erecord",
            "getAutoCompleteSuggestions",
            params
        );
    }

    pushAutoCompleteUpdate(autoCompleteData: any) {
        CustomFormService._lastAutoCompleteData = autoCompleteData;
        if (!CustomFormService.autoCompleteSubject) {
            CustomFormService.autoCompleteSubject = new BehaviorSubject<any>(
                CustomFormService._lastAutoCompleteData
            );
        }

        CustomFormService.autoCompleteSubject.next(autoCompleteData);
    }

    getAutoCompleteUpdate$(): Observable<any[]> {
        if (!CustomFormService.autoCompleteSubject) {
            CustomFormService.autoCompleteSubject = new BehaviorSubject<any>(
                CustomFormService._lastAutoCompleteData
            );
        }

        return CustomFormService.autoCompleteSubject.asObservable();
    }

    getPartyNameParsed(
        value: string,
        documentID: string,
        customFormID: string,
        customFieldID: string,
        sequenceID: number,
        forcedPartyType?: string // "Organization"; // "Individual";
    ) {
        return this._rpcClient.makeRequest("erecord", "getPartyNameParsed", {
            value: value,
            documentID: documentID,
            customFormID: customFormID,
            customFieldID: customFieldID,
            sequenceID: sequenceID,
            forcedPartyType: forcedPartyType
        });
    }

    getCommonParties(customFormID: string) {
        return this._rpcClient.makeRequest("erecord", "getCommonParties", {
            customFormID: customFormID
        });
    }

    getSinglePartyNameParsed(partyName: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getSinglePartyNameParsed",
            {
                partyName: partyName
            }
        );
    }

    setCommonParties(documentID: string, formID: string, partyList: any[]) {
        return this._rpcClient.makeRequest("erecord", "setCommonParties", {
            documentID: documentID,
            customFormID: formID,
            commonParties: partyList
        });
    }

    saveESignCommonParty(orgID: string, commonParty: any) {
        return this._rpcClient.makeRequest("esign", "saveESignCommonParty", {
            submitterId: orgID,
            commonParty: commonParty
        });
    }

    getESignCommonPartiesByLabel(orgID: string, groupLabel: string) {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignCommonPartiesByLabel",
            {
                submitterId: orgID,
                groupLabel: groupLabel,
                projectId: null
            }
        );
    }

    removeESignCommonParty(orgID: string, id: string) {
        return this._rpcClient.makeRequest("esign", "removeESignCommonParty", {
            submitterId: orgID,
            id: id,
            projectId: "",
            deleteEntirely: true
        });
    }

    getNYAddress(
        documentID: string,
        borough: string,
        block: number,
        lot: number
    ) {
        return this._rpcClient.makeRequest("erecord", "getNYAddress", {
            documentID: documentID,
            borough: borough,
            block: block,
            lot: lot
        });
    }

    getBoroughBlockLot(
        documentID: string,
        borough: string,
        street: string,
        number: string,
        unit: string
    ) {
        return this._rpcClient.makeRequest("erecord", "getBoroughBlockLot", {
            documentID: documentID,
            borough: borough,
            street: street,
            number: number,
            unit: unit
        });
    }

    setDocumentCustomValues(
        documentID: string,
        formID: string,
        customValues: SaveField[]
    ) {
        return this._rpcClient.makeRequest(
            "erecord",
            "setDocumentCustomValues",
            {
                documentID: documentID,
                customFormID: formID,
                customValues: customValues
            }
        );
    }

    getDocumentCustomValuesByForm(
        documentID: string,
        formID: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getDocumentCustomValuesByForm",
            { documentID: documentID, customFormID: formID },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    getRecipientDocTypeList(recipientID: string, includeHelpers: boolean) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientDocTypeList",
            {
                recipientID,
                includeHelpers
            }
        );
    }

    getRecipientBookTypeList(
        recipientID: string,
        documentID: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientBookTypeList",
            {
                recipientID: recipientID,
                documentID: documentID
            },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    getSingleCustomForm(customFormID: string) {
        return this._rpcClient.makeRequest("erecord", "getCustomForm", {
            customFormID: customFormID
        });
    }

    doButtonAction(recipientID: string, documentID: string, userID: string) {
        return this._rpcClient.makeRequest("erecord", "doButtonAction", {
            recipientID,
            documentID,
            userID
        });
    }

    getRecipientsWhoUseCustomFormID(
        customFormID: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientsWhoUseCustomFormID",
            {
                customFormID
            }
        );
    }

    getCustomFormIDsWhoUseCustomFieldID(
        customFieldID: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getCustomFormIDsWhoUseCustomFieldID",
            {
                customFieldID
            }
        );
    }
}
