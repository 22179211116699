<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div class="row ">
        <div class="col-12">
            <p *ngIf="!notaryStatus.setupVerificationPassed">
                Your notary setup is not complete and may be missing some information.
                Click <button class="btn btn-link sf-btn-link" (click)="goNotarySetup()">here</button> to go to the Notary setup page.
            </p>
            <p *ngIf="!notaryStatus.stateVerificationPassed">
                The {{notaryState}} state notary validation failed. Invalid commission number.
            </p>
        </div>
    </div>
</div>

<sf-modal-footer [primary]="primary"></sf-modal-footer>
<!--<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>-->
