import { Component, OnInit } from "@angular/core";
import { BaseModalComponent, ModalButton } from "@sf/common";

@Component({
    selector: "sf-tray-download-modal",
    templateUrl: "./tray-download-modal.component.html",
    styleUrls: ["./tray-download-modal.component.scss"]
})
export class TrayDownloadModalComponent {
    title = "Software Updates";

    primary: ModalButton = {
        text: "OK",
        responseValue: "primary"
    };

    secondary: ModalButton = {
        text: "I’ll do it later",
        responseValue: "later"
    };

    trayInstallerUrl: string;

    constructor() {}
}
