<table [sfSortableTable]="repeatableFields | async" (sortEvent)="sortOrderChange($event)" class="table">
    <thead>
    <tr>
        <th scope="col" *ngFor="let col of columnHeaders">{{col.label}}</th>
        <th></th>
    </tr>
    </thead>
    <tbody #rowHost>
    </tbody>
</table>
<div class="actions" [ngClass]="">
    <button class="btn btn-primary" (click)="addRow()" *ngIf="!(disabled$ | async)" aria-label="Add Item">Add Item</button>
</div>
