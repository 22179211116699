import { Component, Input, OnInit } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";

@Component({
    selector: "sf-confirmation-modal",
    templateUrl: "../base-modal/base-modal.component.html",
    styleUrls: ["../base-modal/base-modal.component.scss"]
})
export class ConfirmationModalComponent
    extends BaseModalComponent
    implements OnInit
{
    @Input()
    hideSecondary?: boolean = false;

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.primary) {
            this.primary = {
                text: "OK",
                responseValue: "primary"
            };
        }
        if (!this.secondary && !this.hideSecondary) {
            this.secondary = {
                text: "Cancel",
                responseValue: "secondary"
            };
        }
        if (this.hideSecondary) {
            this.secondary = null;
        }

        super.ngOnInit();
    }
}
