import {
    Directive,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    OnDestroy,
    EmbeddedViewRef
} from "@angular/core";
import { DocumentRefService } from "../services";

@Directive({
    selector: "[sfAttachToBody]"
})
export class AttachToBodyDirective implements OnInit, OnDestroy {
    /* Private Variables */
    _embeddedView: EmbeddedViewRef<any>;

    constructor(
        private tmpl: TemplateRef<any>,
        private view: ViewContainerRef,
        private documentRef: DocumentRefService
    ) {}

    ngOnInit() {
        this._embeddedView = this.view.createEmbeddedView(this.tmpl);
        this._embeddedView.detectChanges();
        this._embeddedView.rootNodes.forEach((n) =>
            this.documentRef.nativeDocument.body.appendChild(n)
        );
    }

    ngOnDestroy() {
        if (this._embeddedView) {
            this._embeddedView.rootNodes.forEach((n) =>
                this.documentRef.nativeDocument.body.removeChild(n)
            );
        }
    }
}
