import { Component, Input, OnInit } from "@angular/core";
import { UIOrganizationEEligibility } from "@sf/userorg/common";
import { EEligibilityDataService } from "../e-eligibility-data.service";
import { ModalButton } from "@sf/common";
import { GrowlService } from "@sf/common";

@Component({
    selector: "sf-e-eligibility-edit-note-dialog",
    templateUrl: "./e-eligibility-edit-note-dialog.component.html",
    styleUrls: ["./e-eligibility-edit-note-dialog.component.scss"]
})
export class EEligibilityEditNoteDialogComponent implements OnInit {
    @Input() orgID: string;
    @Input() name: string;
    @Input() stateAbbrev?: string;

    saveButton: ModalButton = {
        text: "Save",
        callback: () => {
            this._save();
            return true;
        }
    };

    cancelButton: ModalButton = {
        text: "Cancel",
        callback: () => {
            return true;
        }
    };

    title: string = "";
    loading: boolean = true;
    eEligibilitySettings: UIOrganizationEEligibility;

    constructor(
        private _eEligibilityDataService: EEligibilityDataService,
        private _growlService: GrowlService
    ) {}

    ngOnInit(): void {
        this.title = `Edit Notes for ${this.name}`;
        if (this.stateAbbrev) {
            this._eEligibilityDataService
                .getEEligibilityByState(this.stateAbbrev)
                .subscribe((stateData: UIOrganizationEEligibility) => {
                    this.eEligibilitySettings = stateData;
                    this.loading = false;
                });
        } else {
            this._eEligibilityDataService
                .getEEligibilityByOrgID(this.orgID)
                .subscribe((countyData: UIOrganizationEEligibility) => {
                    this.eEligibilitySettings = countyData;
                    this.loading = false;
                });
        }
    }

    private _save() {
        const saveData: any = {
            accepts_ipen_in_state:
                this.eEligibilitySettings.accepts_ipen_in_state,
            accepts_ipen_out_of_state:
                this.eEligibilitySettings.accepts_ipen_out_of_state,
            accepts_ron_in_state:
                this.eEligibilitySettings.accepts_ron_in_state,
            accepts_ron_out_of_state:
                this.eEligibilitySettings.accepts_ron_out_of_state,
            accepts_e_notarized_security_instrument:
                this.eEligibilitySettings
                    .accepts_e_notarized_security_instrument,
            e_eligibility_notes: this.eEligibilitySettings.e_eligibility_notes
        };
        if (this.stateAbbrev) {
            saveData.simpfl_approved_ipen_vendor =
                this.eEligibilitySettings.simpfl_approved_ipen_vendor;
            saveData.simpfl_approved_ron_vendor =
                this.eEligibilitySettings.simpfl_approved_ron_vendor;
            this._eEligibilityDataService
                .saveEEligibilityState(
                    saveData,
                    this.stateAbbrev,
                    false,
                    "State notes changed"
                )
                .subscribe(
                    () => {
                        this._growlService.success("Notes Saved");
                    },
                    (error) => {
                        this._growlService.error(error);
                    }
                );
        } else {
            this._eEligibilityDataService
                .saveEEligibility(
                    this.orgID,
                    saveData,
                    false,
                    "County notes changed"
                )
                .subscribe(
                    () => {
                        this._growlService.success("Notes Saved");
                    },
                    (error) => {
                        this._growlService.error(error);
                    }
                );
        }
    }
}
