<sf-modal-header title="Send Agreement"></sf-modal-header>
<div class="modal-body">
    <div class="extra-bottom">
        {{description}}
    </div>

    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <form [formGroup]="sendForm">
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="firstName">First Name</label>
                <input type="text" id="firstName" placeholder="Required" class="form-control"
                    formControlName="firstName" required>
            </div>
            <div class="form-group col-sm-6">
                <label for="lastName">Last Name</label>
                <input type="text" id="lastName" placeholder="Required" class="form-control"
                    formControlName="lastName" required>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-7">
                <label for="title">Job Title</label>
                <input type="text" id="title" placeholder="Required" class="form-control"
                    formControlName="title" required>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label for="email">Email</label>
                <input type="email" id="email" placeholder="Required" class="form-control"
                    formControlName="email" required/>
            </div>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>