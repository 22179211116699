import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { StringMap } from "../interfaces/submitter.interface";

@Injectable({
    providedIn: "root"
})
export class OrganizationSubmittersService {
    constructor(private _rpcClient: RpcClientService) {}

    getAdminRecipientSubmitterSettings(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getAdminRecipientSubmitterSettings",
            { recipientID }
        );
    }

    getSubmitterSettings(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getSubmitterSettings", {
            recipientID
        });
    }

    enableDisableSubmitters(
        recipientIDs: string[],
        submitterIDs: string[],
        enabled: boolean,
        emailType?: string,
        customEmail?: string,
        disableReason?: string,
        authorizedContact?: string
    ) {
        const data: any = { recipientIDs, submitterIDs, enabled };
        if (emailType) {
            data.emailType = emailType;
        }
        if (customEmail) {
            data.customEmail = customEmail;
        }
        if (disableReason) {
            data.disableReason = disableReason;
        }
        if (authorizedContact) {
            data.nameOfAuthorizationPerson = authorizedContact;
        }
        return this._rpcClient.makeRequest(
            "admin",
            "enableDisableSubmitters",
            data
        );
    }

    removeSubmitters(
        recipientID: string,
        submitterIDs: string[],
        emailType?: string,
        customEmail?: string
    ) {
        const data: any = { recipientID, submitterIDs };
        if (emailType) {
            data.emailType = emailType;
        }
        if (customEmail) {
            data.customEmail = customEmail;
        }
        return this._rpcClient.makeRequest("admin", "removeSubmitters", data);
    }

    updateSubmitterValues(
        recipientID: string,
        submitterID: string,
        values: StringMap
    ) {
        return this._rpcClient.makeRequest("admin", "updateSubmitterValues", {
            recipientID,
            submitterID,
            values
        });
    }
}
