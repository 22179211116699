import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { ModalButton } from "@sf/common";
import { RejectionSuggestion } from "../../interfaces/rejection-suggestion";
import { SharedDocStatusConstants } from "../../enums/shared-doc-status-constants.enum";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { GrowlService } from "@sf/common";
import { Router } from "@angular/router";
import { SharedPhoneNumberConstants } from "@sf/userorg/common";
import { SubmitterDocument } from "../../interfaces/submitter-document.interface";
import { DocumentRejectionSuggestionService } from "../../services/document-rejection-suggestion.service";
import { SubmitterPackagesService } from "../../services/submitter-packages.service";
import { OpenDocumentService } from "@sf/common";

interface PackageDetails {
    id: string;
    recipient: string;
    recipientPhone?: string;
    recipientPackageID?: string;
}

@Component({
    selector: "sf-document-status-dialog",
    templateUrl: "./document-status-dialog.component.html",
    styleUrls: ["./document-status-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class DocumentStatusDialogComponent implements OnInit {
    @Input()
    selfRef: NgbModalRef;
    @Input()
    pkg: PackageDetails;
    @Input()
    doc: SubmitterDocument;
    @Input()
    inDocViewer: boolean;
    @Input()
    originalPdf?: Blob;

    title: string = "Document Status";
    primary: ModalButton = { text: "Ok" };
    rejectionSuggestions: RejectionSuggestion[];
    validationErrors: string[];
    voidedEvents: string[];
    statusMessage: string;
    supportPhoneNumber: string;

    constructor(
        private _rejectionSuggestionService: DocumentRejectionSuggestionService,
        private _submitterPackagesService: SubmitterPackagesService,
        private _sanitizer: DomSanitizer,
        private _growlService: GrowlService,
        private _router: Router,
        private _openDocumentService: OpenDocumentService
    ) {}

    ngOnInit(): void {
        this.title = this.doc.status;
        this.supportPhoneNumber =
            SharedPhoneNumberConstants.SUPPORT_PHONE_NUMBER;
        this._rejectionSuggestionService
            .getSuggestionsForDocumentRejections(
                this.doc.id,
                this.doc.rejectionReasons
            )
            .subscribe((suggestions: RejectionSuggestion[]) => {
                this.rejectionSuggestions = suggestions.filter((suggestion) => {
                    return suggestion !== null;
                });
                for (const item of this.rejectionSuggestions) {
                    this._rejectionSuggestionService
                        .saveSuggestionRejectionFeedbackSeen(
                            item.rejectionReasonID,
                            item.suggestionID,
                            true
                        )
                        .subscribe((r: any) => {});
                }
            });
        this._submitterPackagesService
            .getDocumentValidationErrors(this.pkg.id, this.doc.id)
            .subscribe((errors: string[]) => {
                this.validationErrors = errors;
            });
        if (this.doc.status === SharedDocStatusConstants.VOIDED) {
            this._submitterPackagesService
                .getDocumentVoidedEvents(this.pkg.id, this.doc.id)
                .subscribe((events: string[]) => {
                    this.voidedEvents = events;
                });
        }
        this.statusMessage = this._getStatusMessage(this.doc.status);
    }

    openPrintWindow(status: string, id: string) {
        const sanStatus = this._sanitizer.sanitize(SecurityContext.URL, status);
        const sanDocumentID = this._sanitizer.sanitize(SecurityContext.URL, id);
        window.open(
            `/sf/validateDocumentItems.jsp?status=${sanStatus}&documentID=${sanDocumentID}`,
            "_blank",
            "width=500,height=600,resizable,dependent,scrollbars"
        );
    }

    openOriginalPdf() {
        window.open(window.URL.createObjectURL(this.originalPdf));
        this.selfRef.close();
    }

    goToDocViewerURL() {
        let url: string;
        if (this.doc.eSignDocumentID) {
            if (
                this.doc.status === SharedDocStatusConstants.NEEDS_POA ||
                this.doc.status === SharedDocStatusConstants.DATA_ENTRY
            ) {
                url =
                    "/signing/data-entry/esign/" +
                    this.doc.eSignDocumentID +
                    "/data-entry";
            } else if (this.doc.status === SharedDocStatusConstants.SIGN) {
                url =
                    "/signing/data-entry/esign/" +
                    this.doc.eSignDocumentID +
                    "/document";
            }
        }
        if (!url) {
            this._openDocumentService.openDocument(this.doc.id);
            this.selfRef.close();
        } else {
            this._router.navigateByUrl(url);
            this.selfRef.close();
        }
    }

    shouldShowRecipientPhoneNumber(): boolean {
        return (
            !!this.doc.rejectionReasons &&
            !!this.doc.rejectionReasons.length &&
            !!this.doc.rejectingRecipient &&
            !!this.doc.rejectingRecipient.name &&
            !!this.doc.rejectingRecipient.phone
        );
    }

    sendFeedback(suggestion: RejectionSuggestion, feedback: string) {
        this._rejectionSuggestionService
            .saveSuggestionRejectionFeedback(
                suggestion.rejectionReasonID,
                suggestion.suggestionID,
                feedback
            )
            .subscribe(
                () => {
                    this._growlService.success(
                        "Your feedback has been recorded."
                    );
                    suggestion.feedbackRecorded = true;
                },
                () => {
                    this._growlService.error(
                        "Unable to record your feedback at this time. Please try again later"
                    );
                }
            );
    }

    private _getStatusMessage(status: string): string {
        const messages = [
            {
                status: SharedDocStatusConstants.DATA_ENTRY,
                message:
                    "This template-based document is awaiting completion by one or more parties."
            },
            {
                status: SharedDocStatusConstants.DATA_REVIEW,
                message:
                    "This template-based document is awaiting completion by one or more parties."
            },
            {
                status: SharedDocStatusConstants.SIGN,
                message:
                    "This template-based document is awaiting completion by one or more parties."
            },
            {
                status: SharedDocStatusConstants.NEEDS_POA,
                message:
                    "This template-based document is awaiting completion by one or more parties."
            },
            {
                status: SharedDocStatusConstants.DRAFT,
                message: "This document is not ready for submission."
            },
            {
                status: SharedDocStatusConstants.CONVERTED,
                message:
                    "This document requires one or more helper documents that have not yet been added."
            },
            {
                status: SharedDocStatusConstants.READY,
                message: "This document is ready to be submitted."
            },
            {
                status: SharedDocStatusConstants.SENT,
                message: "This package has been sent for recording."
            },
            {
                status: SharedDocStatusConstants.SENT_REMOTE,
                message: "This package has been sent for recording."
            },
            {
                status: SharedDocStatusConstants.RECEIVED,
                message:
                    "This package has been received and should be reviewed shortly."
            },
            {
                status: SharedDocStatusConstants.REVIEWING,
                message: "This package is being reviewed by the recipient."
            },
            {
                status: SharedDocStatusConstants.ACCEPTED,
                message: "This package is being reviewed by the recipient."
            },
            {
                status: SharedDocStatusConstants.REJECTED,
                message:
                    "This package has been rejected. Please make the indicated corrections and re-submit."
            },
            {
                status: SharedDocStatusConstants.BILLED_REJECTED,
                message:
                    "This package has been rejected and the recipient has charged a rejection fee."
            },
            {
                status: SharedDocStatusConstants.RECORDED,
                message:
                    "This package has been recorded and is now available in Simplifile."
            },
            {
                status: SharedDocStatusConstants.FINALIZING,
                message:
                    "This package has been recorded but has not been returned to Simplifile." +
                    " This may occur when the recipient has not yet applied stamps or not yet calculated fees." +
                    " These packages will typically be available for retrieval in 24 to 48 hours."
            },
            {
                status: SharedDocStatusConstants.MAINTENANCE,
                message:
                    "An error occurred while processing this package. Simplifile Support is reviewing the error."
            },
            {
                status: SharedDocStatusConstants.VOIDED,
                message: "This package has been voided."
            },
            {
                status: SharedDocStatusConstants.UNKNOWN,
                message:
                    "An error occurred while processing this package. Simplifile Support is reviewing the error."
            }
        ];
        let statusMessage = messages.find(
            (message) => message.status === status
        );
        if (statusMessage) {
            return statusMessage.message;
        }
        return "";
    }
}
