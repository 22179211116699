import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType,
    NotaryMethod,
    NotaryStatus,
    UserService
} from "@sf/userorg/common";
import { SessionService, StatesService } from "@sf/common";
import { map } from "rxjs/operators";
import { SignerNotarySetupStatus } from "../interfaces/signer-notary-setup-status";
import { NotaryStatusSubscriptionService } from "./notary-status-subscription.service";
import { DocumentBuilderGuideService } from "./document-builder-guide.service";
import { EsignUserService } from "./esign-user.service";
import { EsignOrganizationService } from "./esign-organization.service";
import {
    BroadcastType,
    EsignBroadcastService
} from "./esign-broadcast.service";

@Injectable({
    providedIn: "root"
})
export class SigningSetupService {
    _setupStatus: SignerNotarySetupStatus = {
        signerSetupNeeded: false,
        initialsSetupNeeded: false,
        notarySetupNeeded: false,
        hasSignature: false,
        hasInitials: false,
        hasAddress: false,
        notaryStatuses: null,
        digitalCertificateRequiredForIPEN: false,
        digitalCertificateExpired: false,
        allowingExpiredDigitalCertificate: false,
        missingDigitalCertificate: false,
        hasP12: false,
        notaryCertificateRejected: false
    };
    _isSigner = false;
    _isNotary = false;
    p12OnServer = sf.liveConfig.DocBuilderConfig.p12OnServer;

    constructor(
        private bannerNotificationService: BannerNotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private statesService: StatesService,
        private userService: UserService,
        private esignUserService: EsignUserService,
        private notaryStatusSubscriptionService: NotaryStatusSubscriptionService,
        private documentBuilderGuideService: DocumentBuilderGuideService,
        private esignOrganizationService: EsignOrganizationService,
        private esignBroadcastService: EsignBroadcastService
    ) {
        this._isSigner = this.sessionService.hasAnyPermissionInAnyOrg([
            "submitter_signing_package_signer",
            "trustee_signer",
            "submitter_signing_package_witness"
        ]);
        this._isNotary = this.sessionService.hasAnyPermissionInAnyOrg([
            "submitter_signing_package_notary",
            "trustee_notary",
            "capc_signing_package_notary",
            "esign_event_notary"
        ]);
        let userId = this.sessionService.getUserID();
        if (this._isNotary) {
            this.notaryStatusSubscriptionService
                .getNotaryStatusUpdate$(userId)
                .subscribe(() => {
                    this.checkSigningSetupWithBanners();
                });
        }
    }

    checkSigningSetup(): Observable<SignerNotarySetupStatus> {
        if (this._isSigner || this._isNotary) {
            return this.esignUserService.getSignerNotarySetupStatus().pipe(
                map((setupStatus) => {
                    setupStatus.signerSetupNeeded =
                        this._isSigner &&
                        (!setupStatus.hasSignature || !setupStatus.hasAddress);
                    setupStatus.initialsSetupNeeded =
                        this._isSigner && !setupStatus.hasInitials;
                    let isRON = !!setupStatus.notaryStatuses.RON;
                    let isIPEN = !!setupStatus.notaryStatuses.IPEN;
                    let digitalCertRequired =
                        isRON ||
                        (isIPEN &&
                            setupStatus.digitalCertificateRequiredForIPEN);
                    this._saveDigitalCertificateFromLocalStorage(setupStatus);
                    setupStatus.missingDigitalCertificate =
                        digitalCertRequired &&
                        ((!this.p12OnServer &&
                            !localStorage?.getItem(
                                this.sessionService.getUserID() + ".pkcs12"
                            )) ||
                            (this.p12OnServer && !setupStatus.hasP12) ||
                            setupStatus.digitalCertificateExpired);

                    let allActive =
                        !Object.values(setupStatus.notaryStatuses).find(
                            (status) => status && status !== NotaryStatus.ACTIVE
                        ) && !!setupStatus.notaryStatuses.INK; // if ink is not selected then notary has not started setup
                    setupStatus.notarySetupNeeded =
                        this._isNotary &&
                        (!allActive || setupStatus.missingDigitalCertificate);
                    this._setupStatus = setupStatus;
                    return setupStatus;
                })
            );
        } else {
            return of(this._setupStatus);
        }
    }

    checkSigningSetupWithBanners(): Observable<SignerNotarySetupStatus> {
        let subject = new Subject<SignerNotarySetupStatus>();
        this.checkSigningSetup().subscribe((setupStatus) => {
            if (
                !this.documentBuilderGuideService.shouldShowDocumentBuilderSignerGuide()
            ) {
                // remove it regardless - or you will get duplicates
                this.bannerNotificationService.removeBannerOfCategory(
                    BannerNotificationCategory.ESIGN_SETUP
                );
                if (setupStatus.signerSetupNeeded) {
                    this.addSigningSetupBanner();
                }
            }

            if (
                !this.documentBuilderGuideService.shouldShowDocumentBuilderNotaryGuide()
            ) {
                // remove it regardless - or you will get duplicates
                this.bannerNotificationService.removeBannerOfCategory(
                    BannerNotificationCategory.NOTARY_SETUP
                );
                if (
                    setupStatus.notarySetupNeeded ||
                    setupStatus.allowingExpiredDigitalCertificate
                ) {
                    this.addNotarySetupBanner();
                }
            }

            subject.next(setupStatus);
            subject.complete();
        });
        return subject.asObservable();
    }

    addSigningSetupBanner() {
        let url = "/settings/signature";
        this.bannerNotificationService.push({
            type: BannerNotificationType.INFO,
            category: BannerNotificationCategory.ESIGN_SETUP,
            message:
                "Additional setup is required before signing documents. Click here to continue.",
            destinationUrl: url,
            clickHandler: () => {
                this.router.navigate([url], {
                    relativeTo: this.route
                });
            },
            closeHandler: () => {},
            disableTooltip: true
        });
    }

    addNotarySetupBanner() {
        let notaryStatuses = this._setupStatus.notaryStatuses;
        let hasRON = !!notaryStatuses.RON;
        let hasIPEN = !!notaryStatuses.IPEN;
        let message: string;
        let bannerType = BannerNotificationType.INFO;
        let url = "/settings/notary-credentials";
        let destinationUrl: string;

        if (this.hasStatusForAnyMethod(NotaryStatus.DISABLED)) {
            message =
                "Your notary commission has been suspended or revoked by your state's governing body.";
            bannerType = BannerNotificationType.DANGER;
        } else if (this.hasStatusForAnyMethod(NotaryStatus.REJECTED)) {
            let type =
                notaryStatuses.IPEN === NotaryStatus.REJECTED
                    ? "electronic"
                    : "remote";
            message = `You do not have a valid ${type} notary commission. Please apply with your state's notary-governing body.`;
            bannerType = BannerNotificationType.DANGER;
        } else if (this.hasStatusForAnyMethod(NotaryStatus.EXPIRED)) {
            message =
                "Your notary commission has expired. Click here to update your commission.";
            destinationUrl = url; // set the destinationUrl so that the banner won't show on the notary credentials page
        } else if (this._setupStatus.notaryCertificateRejected) {
            message =
                "Your notary certificate was not approved. Click here for more information.";
            bannerType = BannerNotificationType.DANGER;
            destinationUrl = url; // set the destinationUrl so that the banner won't show on the notary credentials page
        } else if (
            this.hasStatusForAnyMethod(NotaryStatus.INCOMPLETE) ||
            !notaryStatuses.INK ||
            this._setupStatus.missingDigitalCertificate
        ) {
            let type = "";
            if (
                notaryStatuses.INK &&
                notaryStatuses.INK !== NotaryStatus.INCOMPLETE
            ) {
                if (
                    notaryStatuses.IPEN === NotaryStatus.INCOMPLETE ||
                    (this._setupStatus.missingDigitalCertificate &&
                        this._setupStatus.digitalCertificateRequiredForIPEN)
                ) {
                    type = " electronically";
                } else if (
                    notaryStatuses.RON === NotaryStatus.INCOMPLETE ||
                    this._setupStatus.missingDigitalCertificate
                ) {
                    type = " remotely";
                }
            }
            message = `Additional setup is required before notarizing documents${type}. Click here to continue.`;
            destinationUrl = url; // set the destinationUrl so that the banner won't show on the notary credentials page
        } else if (this.hasStatusForAnyMethod(NotaryStatus.PENDING_STATE)) {
            if (
                (!hasRON ||
                    notaryStatuses.RON === NotaryStatus.PENDING_STATE) &&
                (!hasIPEN || notaryStatuses.IPEN === NotaryStatus.PENDING_STATE)
            ) {
                message = "Notary functionality is pending State approval.";
            } else {
                let type =
                    notaryStatuses.RON === NotaryStatus.PENDING_STATE
                        ? "RON"
                        : "IPEN";
                message = `Notary functionality for ${type} is pending State approval.`;
            }
        } else if (
            this.hasStatusForAnyMethod(NotaryStatus.PENDING_SIMPLIFILE)
        ) {
            if (
                (!hasRON ||
                    notaryStatuses.RON === NotaryStatus.PENDING_SIMPLIFILE) &&
                (!hasIPEN ||
                    notaryStatuses.IPEN === NotaryStatus.PENDING_SIMPLIFILE)
            ) {
                message =
                    "Notary functionality is pending Simplifile approval.";
            } else {
                let type =
                    notaryStatuses.RON === NotaryStatus.PENDING_SIMPLIFILE
                        ? "RON"
                        : "IPEN";
                message = `Notary functionality for ${type} is pending Simplifile approval.`;
            }
        } else if (this._setupStatus.allowingExpiredDigitalCertificate) {
            message =
                "Your digital certificate is expired. You are allowed to use it temporarily. Please obtain a new certificate.";
        }

        this.bannerNotificationService.push({
            type: bannerType,
            category: BannerNotificationCategory.NOTARY_SETUP,
            message: message,
            clickHandler: () => {
                this.router.navigate([url], {
                    relativeTo: this.route
                });
            },
            closeHandler: () => {},
            disableTooltip: true,
            destinationUrl: destinationUrl
        });
    }

    checkNotarySetup(userID: string) {
        return this.esignUserService.signerSetupNeeded(userID, true);
    }

    isNotarySetupNeeded(method: NotaryMethod): boolean {
        return this._setupStatus.notaryStatuses[method] !== NotaryStatus.ACTIVE;
    }

    hasStatusForAnyMethod(notaryStatus: NotaryStatus) {
        return !!Object.values(this._setupStatus.notaryStatuses).find(
            (status) => status === notaryStatus
        );
    }

    isSignerSetupNeeded(): boolean {
        return this._setupStatus.signerSetupNeeded;
    }

    hasSignature(): boolean {
        return this._setupStatus.hasSignature;
    }

    isInitialsSetupNeeded(): boolean {
        return this._setupStatus.initialsSetupNeeded;
    }

    hasInitials(): boolean {
        return this._setupStatus.hasInitials;
    }

    hasAddress(): boolean {
        return this._setupStatus.hasAddress;
    }

    checkLegalEntitySetup() {
        this.bannerNotificationService.removeBannerOfCategory(
            BannerNotificationCategory.MISSING_LEGAL_ENTITY_INFO
        );
        if (
            sf.liveConfig.DocBuilderConfig.requireLegalEntityTypeAndState &&
            this.sessionService.hasPermissionInAnyOrg(
                "submitter_signing_admin"
            ) &&
            !this.sessionService.isSuperUser()
        ) {
            this.esignOrganizationService
                .getOrganizationsMissingLegalEntityInfo()
                .subscribe((submitterIds) => {
                    if (submitterIds.length) {
                        const url = `/submitter/organization/${submitterIds[0]}/document-builder/configuration#LegalEntityType`;
                        let banner = {
                            type: BannerNotificationType.INFO,
                            category:
                                BannerNotificationCategory.MISSING_LEGAL_ENTITY_INFO,
                            message:
                                "Before anyone can generate documents for your organization you must specify your organization's entity type. Click here to do so.",
                            destinationUrl: url,
                            clickHandler: () => {
                                this.router.navigateByUrl(url);
                            },
                            closeHandler: () => {},
                            disableTooltip: true
                        };
                        this.bannerNotificationService.push(banner);
                    }
                });
        }
    }

    checkPendingServicers() {
        if (this.sessionService.hasPermissionInAnyOrg("trustee_admin")) {
            this.esignOrganizationService
                .hasPendingTrusteeServicer()
                .subscribe((hasPendingServicer) => {
                    this.bannerNotificationService.removeBannerOfCategory(
                        BannerNotificationCategory.PENDING_TRUSTEE_SERVICER
                    );
                    if (hasPendingServicer) {
                        this.bannerNotificationService.push({
                            type: BannerNotificationType.INFO,
                            category:
                                BannerNotificationCategory.PENDING_TRUSTEE_SERVICER,
                            message:
                                "A new servicer has added your organization as a trustee. Click here to review and approve them.",
                            clickHandler: () => {
                                if (
                                    this.router.url === "/signing/manage-list"
                                ) {
                                    this.esignBroadcastService.broadcast(
                                        BroadcastType.SHOW_SERVICER_MODAL
                                    );
                                } else {
                                    this.router.navigateByUrl(
                                        "/signing/manage-servicers"
                                    );
                                }
                            },
                            closeHandler: () => {},
                            disableTooltip: true
                        });
                    }
                });
        }
    }

    _saveDigitalCertificateFromLocalStorage(
        setupStatus: SignerNotarySetupStatus
    ) {
        if (
            !setupStatus.hasP12 &&
            this.p12OnServer &&
            localStorage?.getItem(this.sessionService.getUserID() + ".pkcs12")
        ) {
            setupStatus.hasP12 = true;
            this.esignUserService
                .saveDigitalCertificateFromLocalStorage(
                    localStorage?.getItem(
                        this.sessionService.getUserID() + ".pkcs12"
                    )
                )
                .subscribe(() => {});
        }
    }
}
