import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { SubmitterPackageSearchResult } from "../interfaces/submitter-package-search-result.interface";

const ERECORD = "erecord";

@Injectable({
    providedIn: "root"
})
export class SubmitterGlobalSearchService {
    constructor(private _rpcClient: RpcClientService) {}

    searchPackages(
        searchStr: string,
        paperOnly = false
    ): Observable<SubmitterPackageSearchResult> {
        return this._rpcClient.makeRequest(
            ERECORD,
            "submitterPackageGlobalSearch",
            { searchTerm: searchStr, paperOnly }
        );
    }
}
