<sf-modal-header title="Warning: Duplicate Check Number(s)"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="disallowDups">
        One or more check numbers you have entered already exist and cannot be re-used. <br>
        Refer to the table below for more information:
    </div>
    <div *ngIf="!disallowDups">
        One or more check numbers you have entered already exist and should not be re-used. <br>
        Using duplicate check numbers may result in a bank return and incur bank return fees. <br>
        Refer to the table below for more information:
    </div>
    <br>
    <div>
        <table>
            <thead>
                <th>Payment Account</th>
                <th>Duplicate Number(s)</th>
                <th>Most Recent Check#</th>
            </thead>
            <tbody>
                <tr *ngFor="let dupAccount of arrayInfo">
                    <td>{{dupAccount.label}}</td>
                    <td>{{dupAccount.duplicates}}</td>
                    <td>{{dupAccount.mostRecent}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
</div>
<sf-modal-footer *ngIf="disallowDups" [primary]="primary"></sf-modal-footer>
<sf-modal-footer *ngIf="!disallowDups" [primary]="primary" [secondary]="secondary"></sf-modal-footer>
