import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DocLeftEventsService {
    private _eventMap: any = {};
    private _subscriberCounts: any = {};
    private _callBackCounts: any = {};
    private _callBackMap: any = {};

    constructor() {}

    getEvent$(eventName: string) {
        if (!this._eventMap[eventName]) {
            this._eventMap[eventName] = new Subject<any>();
            this._subscriberCounts[eventName] = 0;
        }

        ++this._subscriberCounts[eventName];
        return this._eventMap[eventName];
    }

    onUnsubscribe(eventName: string) {
        if (this._subscriberCounts[eventName]) {
            --this._subscriberCounts[eventName];
        }
        if (this._subscriberCounts[eventName] === 0) {
            this._eventMap[eventName] = null;
        }
    }

    triggerEvent(eventName: string, callBack?: () => void) {
        if (this._eventMap[eventName]) {
            this._callBackCounts[eventName] = 0;
            this._callBackMap[eventName] = callBack;
            this._eventMap[eventName].next(
                (() => this.callBackEvent(eventName)).bind(this)
            );
        } else if (callBack) {
            callBack();
        }
    }

    callBackEvent(eventName: string) {
        if (!this._callBackCounts[eventName]) {
            this._callBackCounts[eventName] = 0;
        }
        if (!this._subscriberCounts[eventName]) {
            this._subscriberCounts[eventName] = 0;
        }

        ++this._callBackCounts[eventName];

        if (
            this._callBackCounts[eventName] ===
            this._subscriberCounts[eventName]
        ) {
            this._callBackMap[eventName]();
        }
    }
}
