import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import {
    PersistentDataStack,
    PersistentDataStackService,
    RpcClientService
} from "@sf/common";
import { CurrentPermissionService, UserPermission } from "@sf/userorg/common";
import { mergeMap } from "rxjs/operators";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class RecentPermissionsService {
    private _permissionStack: PersistentDataStack;

    constructor(
            private _rpc: RpcClientService,
            private currentPermissionService: CurrentPermissionService,
            private persistentDataStackService: PersistentDataStackService
    ) {
        this._permissionStack = this.persistentDataStackService.createNewStack("sfAdminPermissionStack", 9);

        // when the 'current' permission changes, push it on our 'recent' list
        this.currentPermissionService.currentPermission$.subscribe((permission) => {
            if (permission) {
                this.pushRecentPermissionID(permission.permissionID);
            }
        });
    }

    getPastRecentPermissions(): Observable<UserPermission[]> {
        return this.getPastRecentPermissionIDs(10)
                   .pipe(mergeMap((permissionNames) => this._rpc.makeRequest<UserPermission[]>("userorg", "getPermissions", {
                       permissionIDs: permissionNames
                   })));
    }

    getPastRecentPermissionIDs(count?: number): Observable<string[]> {
        return from(this._permissionStack.last(count) as Promise<string[]>);
    }

    pushRecentPermissionID(permissionID: string): void {
        // an '@' sign is illegal and messes us up - shouldn't ever happen though
        if (!permissionID.includes("@")) {
            this._permissionStack.push(permissionID);
        }
    }

    getLastRecentPermissionID(): Observable<string> {
        return from(this._permissionStack.peek() as Promise<string>);
    }

    pullRecentPermissionIDs(permissionIDs: string[]): void {
        this._permissionStack.pullMultiple(permissionIDs);
    }
}
