<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async" [ngStyle]="columnLayout">
    <label [for]="field.id" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left" tabindex="0">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <ngb-timepicker
            [formControl]="control"
            [minuteStep]="15"
            [meridian]="true"
            [attr.aria-required]="isRequired$ | async"
        ></ngb-timepicker>
        <sf-form-error *ngIf="control.invalid && !control.pristine" [control]="control" [label]="field.label"></sf-form-error>
    </div>
</div>
