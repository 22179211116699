<div class="sf-unmask">
    <sf-modal-header title="Register Authenticator App"></sf-modal-header>
    <div class="modal-body">
        <div *ngIf="!passwordOK">
            <div class="explain">
                This is the process to register to use an authenticator app like Google Authenticator or Microsoft Authenticator
                for two-factor authentication on your mobile phone.
            </div>
            <div class="explain">
                Make sure you have already downloaded and installed an authenticator app.
                You can find them on the Play Store for Android or the App store for iPhone.
            </div>
            <form (submit)="continue()">
                <div>
                    <label for="password">For security, enter your password.</label>
                </div>
                <div>
                    <input type="password" id="password" class="form-control sf-exclude" #password/>
                </div>
            </form>
        </div>
        <div *ngIf="passwordOK">
            <div class="explain">
                Scan this QR code in your authenticator app.
            </div>
            <div class="framer">
                <img [src]="safeUrl" alt="QR code" class="sf-exclude"/>
            </div>
        </div>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
