import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SubmitterOrganizationService } from "./submitter-organization.service";
import { map } from "rxjs/operators";
import { dayjs } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class SubmitterDefaultFilenameService {
    private _separatorNames: { [key: string]: string } = {
        "-": "hyphen",
        _: "underscore",
        ",": "comma",
        " ": "space"
    };
    private _elementNames: string[] = [
        "PackageName",
        "DocumentTypeName",
        "DocumentName",
        "RecordingDate",
        "Counter"
    ];

    constructor(
        private _submitterOrganizationService: SubmitterOrganizationService
    ) {}

    getStructure(orgId: string): Observable<any> {
        return this._submitterOrganizationService
            .getSubmitterOrganizationConfigPreparation(orgId, true, true)
            .pipe(
                map(
                    SubmitterDefaultFilenameService.getStructureFromSubmitterConfig
                )
            );
    }

    saveStructure(structure: string, orgId: string) {
        return this._submitterOrganizationService.setSubmitterOrganizationConfig(
            orgId,
            {
                Retrieval: {
                    download_file_name_format: {
                        value: structure
                    }
                }
            }
        );
    }

    getSeparatorsAsList(): { [key: string]: string }[] {
        return Object.entries(this._separatorNames).map(([key, value]) => ({
            name: value,
            value: key
        }));
    }

    getSeparatorValue(separatorName: string): string {
        return Object.keys(this._separatorNames).find(
            (key: string) => this._separatorNames[key] === separatorName
        );
    }

    parseStructure(structure: any): { [key: string]: any } {
        const separator =
            SubmitterDefaultFilenameService._getSeparator(structure) || "-";
        const parsed: { [key: string]: any } = this._elementNames.reduce(
            (result: { [key: string]: any }, elementName: string) => {
                result[elementName] =
                    SubmitterDefaultFilenameService._getElementStructure(
                        structure,
                        elementName,
                        separator
                    );
                return result;
            },
            {}
        );
        parsed.separator = this._separatorNames[separator];
        parsed.hasAllElements = this.hasAllElements(structure);
        return parsed;
    }

    addElement(structure: string, elementName: string, position?: number) {
        const separator =
            SubmitterDefaultFilenameService._getSeparator(structure) || "-"; // Default separator
        const elements = SubmitterDefaultFilenameService._getElements(
            structure,
            separator
        );
        const newElements = SubmitterDefaultFilenameService._insertElement(
            elements,
            elementName,
            position
        );
        return SubmitterDefaultFilenameService._createStructure(
            newElements,
            separator
        );
    }

    addAllElements(structure: string) {
        let newStructure = structure;
        this._elementNames.forEach((elName) => {
            if (
                !SubmitterDefaultFilenameService._hasElement(
                    newStructure,
                    elName
                )
            ) {
                newStructure = this.addElement(newStructure, elName);
            }
        });
        return newStructure;
    }

    removeElement(structure: string, elementName: string) {
        const separator =
            SubmitterDefaultFilenameService._getSeparator(structure);
        const elements = SubmitterDefaultFilenameService._getElements(
            structure,
            separator
        );
        const newElements = SubmitterDefaultFilenameService._dropElement(
            elements,
            elementName
        );
        return SubmitterDefaultFilenameService._createStructure(
            newElements,
            separator
        );
    }

    removeAllElements(structure: string) {
        return SubmitterDefaultFilenameService._getSeparator(structure);
    }

    hasAllElements(structure: string) {
        return this._elementNames.every((elName) =>
            SubmitterDefaultFilenameService._hasElement(structure, elName)
        );
    }

    setPosition(structure: string, elementName: string, position: number) {
        if (
            !SubmitterDefaultFilenameService._hasElement(structure, elementName)
        ) {
            return this.addElement(structure, elementName, position);
        }
        const newStructure = this.removeElement(structure, elementName);
        return this.addElement(newStructure, elementName, position);
    }

    setSeparator(structure: string, separatorName: string) {
        const newStructure = structure || "";
        const currentSeparator =
            SubmitterDefaultFilenameService._getSeparator(newStructure);
        const newSeparator = this.getSeparatorValue(separatorName);
        if (newStructure.includes(currentSeparator)) {
            return newStructure.replace(
                new RegExp(currentSeparator, "g"),
                newSeparator
            );
        }
        return newSeparator + newStructure;
    }

    static getStructureFromSubmitterConfig(config: any) {
        let structure = config?.Retrieval?.download_file_name_format;
        return !structure ? "" : structure;
    }

    private static _getSeparator(structure: string): string {
        if (structure) {
            if (structure.includes("_")) {
                return "_";
            }
            if (structure.includes("-")) {
                return "-";
            }
            if (structure.includes(" ")) {
                return " ";
            }
            if (structure.includes(",")) {
                return ",";
            }
        }
    }

    private static _getElements(structure: string, separator: string) {
        if (!structure) {
            return [];
        }
        return structure.split(separator).filter(Boolean);
    }

    private static _insertElement(
        elements: string[],
        elementName: string,
        position: number
    ): string[] {
        if (elements.includes(elementName)) {
            return elements;
        }
        if (position === undefined || isNaN(position)) {
            return elements.concat([elementName]);
        }
        return elements
            .slice(0, position)
            .concat([
                elementName,
                ...elements.slice(position, elements.length)
            ]);
    }

    private static _dropElement(
        elements: string[],
        elementName: string
    ): string[] {
        return elements.filter((el) => el !== elementName);
    }

    private static _hasElement(structure: string, elementName: string) {
        return !!structure && structure.includes(elementName);
    }

    private static _createStructure(
        elements: string[],
        separator: string
    ): string {
        if (!elements || elements.length === 0) {
            return separator;
        }
        if (elements.length === 1) {
            return "" + separator + elements[0];
        }
        return elements.join(separator);
    }

    private static _getElementStructure(
        structure: string,
        elementName: string,
        separator: string
    ) {
        const elements = !structure ? [] : structure.split(separator);
        return {
            include: elements.includes(elementName),
            position: elements.indexOf(elementName)
        };
    }

    createFormatter(structure: string = "DocumentName-") {
        const parsedStructure = this.parseStructure(structure);
        let separator =
            SubmitterDefaultFilenameService._getSeparator(structure) || "-";
        const includedElements = Object.entries(parsedStructure)
            .map(([elName, el]) => ({
                elementName: elName,
                include: el.include,
                position: el.position
            }))
            .filter((el) => el.include)
            .sort((a, b) => a.position - b.position)
            .map((el) => el.elementName);
        if (includedElements.length === 0) {
            includedElements.push("DocumentName"); // set defaults
        }
        let counters: { [key: string]: any } = {};

        return (
            packageName: string,
            documentName: string,
            documentTypeName: string,
            recordingDate: any
        ) => {
            const fileNameNoCounter: string = includedElements
                .filter((el) => el !== "Counter")
                .reduce((acc, el) => {
                    const sep = acc === "" ? "" : separator;
                    switch (el) {
                        case "PackageName":
                            return (
                                acc + sep + (!packageName ? "" : packageName)
                            );
                        case "DocumentName":
                            return (
                                acc + sep + (!documentName ? "" : documentName)
                            );
                        case "DocumentTypeName":
                            return (
                                acc +
                                sep +
                                (!documentTypeName ? "" : documentTypeName)
                            );
                        case "RecordingDate":
                            if (recordingDate) {
                                return (
                                    acc +
                                    sep +
                                    dayjs(recordingDate).format("YYYYMMDD")
                                );
                            }
                            return acc;
                        default:
                            return acc;
                    }
                }, "");

            counters = this._updateCounter(counters, fileNameNoCounter);

            if (includedElements.includes("Counter")) {
                return includedElements.reduce((acc, el) => {
                    const sep = acc === "" ? "" : separator;
                    switch (el) {
                        case "PackageName":
                            return (
                                acc + sep + (!packageName ? "" : packageName)
                            );
                        case "DocumentName":
                            return (
                                acc + sep + (!documentName ? "" : documentName)
                            );
                        case "DocumentTypeName":
                            return (
                                acc +
                                sep +
                                (!documentTypeName ? "" : documentTypeName)
                            );
                        case "RecordingDate":
                            if (recordingDate) {
                                return (
                                    acc +
                                    sep +
                                    dayjs(recordingDate).format("YYYYMMDD")
                                );
                            }
                            return acc;
                        case "Counter":
                            if (!counters[fileNameNoCounter]) {
                                return acc + sep + this._formatCounter(1);
                            }
                            return (
                                acc +
                                sep +
                                this._formatCounter(counters[fileNameNoCounter])
                            );
                        default:
                            return acc;
                    }
                }, "");
            }

            if (counters[fileNameNoCounter] > 1) {
                return (
                    fileNameNoCounter +
                    separator +
                    this._formatCounter(counters[fileNameNoCounter])
                );
            }

            return fileNameNoCounter;
        };
    }

    private _formatCounter(counterValue: number): string {
        return (
            Array(Math.max(3 - String(counterValue).length + 1, 0)).join("0") +
            counterValue
        );
    }

    private _updateCounter(
        counters: { [key: string]: any },
        fileNameNoCounter: string
    ): { [key: string]: any } {
        const countersClone = { ...counters };
        if (countersClone[fileNameNoCounter]) {
            countersClone[fileNameNoCounter] = counters[fileNameNoCounter] + 1;
        } else {
            countersClone[fileNameNoCounter] = 1;
        }
        return countersClone;
    }
}
