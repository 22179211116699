import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { SubmitterDocument } from "../interfaces/submitter-document.interface";
import {
    AdvancedSearchParams,
    PackageIdLabelResponse
} from "../interfaces/advanced-search.interface";

@Injectable({
    providedIn: "root"
})
export class SubmitterAdvancedSearchService {
    constructor(private _rpcClient: RpcClientService) {}

    private _makeSearchRequest<T>(method: string, data?: any) {
        return this._rpcClient.makeRequest<T>("search", method, data);
    }

    search(params: AdvancedSearchParams): Observable<SubmitterDocument[]> {
        return this._makeSearchRequest<SubmitterDocument[]>("advancedSearch", {
            searchModel: params
        });
    }

    searchRecordedDocuments(params: any, refDocSearch: boolean) {
        return this._makeSearchRequest("searchRecordedDocuments", {
            searchModel: params,
            refDocSearch: refDocSearch
        });
    }

    getPackageIDLabel(orgIds: string[]): Observable<PackageIdLabelResponse> {
        return this._makeSearchRequest("getPackageIDLabel", {
            organizationIDs: orgIds
        });
    }
}
