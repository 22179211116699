import { Injectable } from "@angular/core";
import { SubmitterDocument } from "../interfaces/submitter-document.interface";

export interface PaperDocumentRecordingInfo {
    entryNumber: string;
    bookNumber: string;
    bookModifier: string;
    pageNumber: string;
    date: Date;
    time: any;
}

export interface PaperPackageRecordingInfo {
    [key: string]: PaperDocumentRecordingInfo;
}

@Injectable({
    providedIn: "root"
})
export class PaperPackageRecordingService {
    private _packageRecordingInfo: {
        [key: string]: PaperDocumentRecordingInfo;
    } = {};
    private _documents: SubmitterDocument[] = [];

    constructor() {}

    setPackageRecordingInfo(recordingInfo: PaperPackageRecordingInfo) {
        this._packageRecordingInfo = recordingInfo;
    }

    getPackageRecordingInfo(): PaperPackageRecordingInfo {
        return this._packageRecordingInfo;
    }

    clearPackageRecordingInfo() {
        this._packageRecordingInfo = {};
    }

    updateRecordingInfo(
        docID: string,
        recordingInfo: PaperDocumentRecordingInfo
    ) {
        this._packageRecordingInfo[docID] = recordingInfo;
    }

    setDocuments(documents: SubmitterDocument[]) {
        this._documents = documents;
    }

    getDocuments(): SubmitterDocument[] {
        return this._documents;
    }

    clearDocuments() {
        this._documents = [];
    }

    clearAll(): void {
        this.clearDocuments();
        this.clearPackageRecordingInfo();
    }
}
