export enum FieldType {
    ARRAY = "array",
    GROUP = "group",
    CURRENCY = "currency",
    DATE = "date",
    DATERANGE = "daterange",
    DATERANGE_SIMPLE = "daterangeSimple",
    NUMERIC = "numeric",
    TEXTFIELD = "textfield",
    TEXTAREA = "textarea",
    ZIP = "zip",
    HIDDEN = "hidden",
    TIME = "time",
    TIMESTAMP = "timestamp",
    STATE = "state",
    PARTYPARSER = "parser",
    NAMEUNPARSED = "unparsed",
    PARTYTYPE = "partyType",
    SORTABLE = "sortable",
    CHECKBOX = "checkbox",
    SELECT = "select",
    RADIO = "radio",
    MULTISELECT = "multiselect",
    MULTITITLE = "multititle",
    ROOT = "root",
    PARAGRAPH = "paragraph",
    UPLOAD = "upload",
    PHONE_NUMBER = "phoneNumber"
}
