import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { OpenHours } from "@sf/common";

import { Observable } from "rxjs";
import { RecipientMessageInterface } from "../interfaces/recipient-message.interface";
import { dayjs } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class RecipientRpcService {
    /** Private Variables **/

    /** Public Variables **/

    /** Lifecycle Hooks **/
    constructor(private _rpc: RpcClientService) {}

    /** Public Methods **/
    getDocumentRecipientMessages(
        documentID: string,
        recipientID: string
    ): Observable<RecipientMessageInterface[]> {
        return this._rpc.makeRequest(
            "document",
            "getDocumentRecipientMessages",
            {
                documentID,
                recipientID
            }
        );
    }
    getRecipientConfigurationForCurrentUser() {
        return this._rpc.makeRequest(
            "erecord",
            "getRecipientConfigurationForCurrentUser"
        );
    }

    getRecipientAccountData(recipientID: string) {
        return this._rpc.makeRequest("erecord", "getRecipientAccountData", {
            recipientID
        });
    }

    setRecipientAccountData(recipientID: string, recipientAccount: any) {
        return this._rpc.makeRequest("erecord", "setRecipientAccountData", {
            recipientID,
            recipientAccount
        });
    }

    getSettings(recipientID: string, mode: string) {
        return this._rpc.makeRequest("erecord", "getSettings", {
            recipientID,
            mode
        });
    }

    setSettings(recipientID: string, mode: string, settings: any) {
        return this._rpc.makeRequest("erecord", "setSettings", {
            recipientID,
            mode,
            settings
        });
    }

    getRecipientSubmitterSettings(recipientID: string) {
        return this._rpc.makeRequest(
            "erecord",
            "getRecipientSubmitterSettings",
            {
                recipientID
            }
        );
    }

    getAuthorizedDocumentConverters(submitterID: string) {
        return this._rpc.makeRequest(
            "erecord",
            "getAuthorizedDocumentConverters",
            {
                submitterID
            }
        );
    }

    setRecipientSubmitterSettings(
        recipientID: string,
        submitterID: string,
        enabled: boolean,
        emailType?: string,
        customEmail?: string,
        disableReason?: string
    ) {
        const data: any = { recipientID, submitterID, enabled };
        if (emailType) {
            data.emailType = emailType;
        }
        if (customEmail) {
            data.customEmail = customEmail;
        }
        if (disableReason) {
            data.disableReason = disableReason;
        }
        return this._rpc.makeRequest(
            "erecord",
            "enableDisableSubmitters",
            data
        );
    }

    getTimeZone() {
        return this._rpc.makeRequest("erecord", "getTimeZone");
    }

    getOpenHours() {
        return this._rpc.makeRequest("erecord", "getOpenHours");
    }

    setOpenHours(openHours: OpenHours) {
        return this._rpc.makeRequest("erecord", "setOpenHours", { openHours });
    }

    isOpenForQueues() {
        return this._rpc.makeRequest("erecord", "isOpenForQueues");
    }

    getClosures() {
        return this._rpc.makeRequest("erecord", "getClosures");
    }

    setClosures(closedDays: any[]) {
        return this._rpc.makeRequest("erecord", "setClosures", { closedDays });
    }

    addForcedOpen(
        hoursToOpen: number,
        isSubmission: boolean,
        isQueue: boolean = true
    ) {
        return this._rpc.makeRequest("erecord", "addForcedOpen", {
            hoursToOpen,
            isSubmission,
            isQueue
        });
    }

    getRecipientMenuData(recipientID: string) {
        return this._rpc.makeRequest("erecord", "getRecipientMenuData", {
            recipientID
        });
    }

    getPackageLock(packageID: string) {
        return this._rpc.makeRequest("erecord", "getPackageLock", {
            packageID
        });
    }

    setRecordQueueError(packageID: string, error: string) {
        return this._rpc.makeRequest("erecord", "setRecordQueueError", {
            packageID,
            error
        });
    }

    removePackageLock(packageID: string) {
        return this._rpc.makeRequest("erecord", "removePackageLock", {
            packageID
        });
    }

    recordPackage(packageID: string, recordingInfo?: any) {
        return this._rpc.makeRequest("erecord", "recordPackage", {
            packageID,
            recordingInfo
        });
    }

    removePackageLocks() {
        return this._rpc.makeRequest("erecord", "removePackageLocks");
    }

    getAlreadyUsedEntryNumbers(
        recipientID: string,
        packageID: string,
        entryNumberList: string[]
    ) {
        return this._rpc.makeRequest("erecord", "getAlreadyUsedEntryNumbers", {
            recipientID,
            packageID,
            entryNumberList
        });
    }

    reassignPackageLock(
        packageID: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpc.makeRequest(
            "erecord",
            "reassignPackageLock",
            {
                packageID
            },
            overrideDefaultErrorHandling
        );
    }

    rejectPackage(
        packageID: string,
        rejectionReason: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpc.makeRequest(
            "erecord",
            "rejectPackage",
            {
                packageID,
                rejectionReason
            },
            overrideDefaultErrorHandling
        );
    }

    unRejectDocument(documentID: string) {
        return this._rpc.makeRequest("erecord", "unrejectDocument", {
            documentID: documentID
        });
    }

    acceptDocument(
        queueType: string,
        documentID: string,
        acceptWithoutRequiredStamps?: boolean
    ) {
        return this._rpc.makeRequest("erecord", "acceptDocument", {
            queueType,
            documentID,
            acceptWithoutRequiredStamps
        });
    }

    unacceptDocuments(
        packageID: string,
        documentIDs: string[],
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpc.makeRequest(
            "erecord",
            "unacceptDocuments",
            {
                packageID,
                documentIDs
            },
            overrideDefaultErrorHandling
        );
    }

    rejectDocument(
        queueType: string,
        documentID: string,
        rejectionReasons: string[],
        sendTo: string
    ) {
        return this._rpc.makeRequest("erecord", "rejectDocument", {
            queueType,
            documentID,
            rejectReason: rejectionReasons,
            sendTo
        });
    }

    removeLineWaiter(lineWaiterID: string) {
        return this._rpc.makeRequest("erecord", "removeLineWaiter", {
            lineWaiterID
        });
    }

    getRecordingInfo(documentID: string) {
        return this._rpc.makeRequest("erecord", "getRecordingInfo", {
            documentID
        });
    }

    setDocumentRecordingInfo(params: any) {
        return this._rpc.makeRequest(
            "erecord",
            "setDocumentRecordingInfo",
            params
        );
    }

    getDocumentQueueJSON(queueType: string, start?: number, limit?: number) {
        const data: any = { queueType: queueType };
        if (start) {
            data.start = start;
        }
        if (limit) {
            data.limit = limit;
        }
        return this._rpc.makeRequest("erecord", "getDocumentQueueJSON", data);
    }

    filterDocumentQueue(
        queueType: string,
        recipientID: string,
        filterTerm: string,
        start?: number,
        limit?: number
    ) {
        const data: any = {
            queueType: queueType,
            recipientID: recipientID,
            filter: filterTerm
        };
        if (start) {
            data.start = start;
        }
        if (limit) {
            data.limit = limit;
        }
        return this._rpc.makeRequest("erecord", "filterDocumentQueue", data);
    }

    unlockPackage(packageID: string) {
        return this._rpc.makeRequest("erecord", "unlockPackageAll", {
            packageID
        });
    }

    unlockPackageMine(packageID: string) {
        return this._rpc.makeRequest("erecord", "unlockPackageMine", {
            packageID
        });
    }

    getNextAvailableDocumentId(queueType: string) {
        return this._rpc.makeRequest("erecord", "getNextReviewDocument", {
            queueType
        });
    }

    reviewPackage(packageID: string, documentID: string) {
        return this._rpc.makeRequest("erecord", "reviewPackage", {
            packageID,
            documentID
        });
    }

    toggleOnHoldQueue(
        queueType: string,
        packageID: string,
        moveToOnHoldQueue: boolean
    ) {
        return this._rpc.makeRequest("erecord", "toggleOnHoldQueue", {
            queueType,
            packageID,
            moveToOnHoldQueue
        });
    }

    removeQueueItem(queueItemID: string, queueType: string) {
        return this._rpc.makeRequest("erecord", "removeQueueItem", {
            queueItemID,
            queueType
        });
    }

    getQueueItemJSON(itemType: string) {
        return this._rpc.makeRequest("erecord", "getQueueItemJSON", {
            itemType
        });
    }

    turnOffUploadInProgress() {
        return this._rpc.makeRequest("erecord", "turnOffUploadInProgress");
    }

    uploadQueueToggle(toggleOn: boolean) {
        return this._rpc.makeRequest("erecord", "uploadQueueToggle", {
            toggleOn
        });
    }

    turnOffPrintInProgress() {
        return this._rpc.makeRequest("erecord", "turnOffPrintInProgress");
    }

    printQueueToggle(toggleOn: boolean) {
        return this._rpc.makeRequest("erecord", "printQueueToggle", {
            toggleOn
        });
    }

    getRecentActivity() {
        return this._rpc.makeRequest("erecord", "getRecentActivity");
    }

    getPackageHistory(packageID: string, includeDocumentHistory: boolean) {
        return this._rpc.makeRequest("erecord", "getPackageHistory", {
            packageID,
            includeDocumentHistory
        });
    }

    getDocumentHistory(documentID: string) {
        return this._rpc.makeRequest("erecord", "getDocumentHistory", {
            documentID
        });
    }

    getDocumentSize(documentID: string) {
        return this._rpc.makeRequest("document", "getDocumentSize", {
            documentID
        });
    }

    addAllToQueue(
        type: string,
        queue: string,
        ids: string[],
        showUIErrorOnDuplicates: boolean = false
    ) {
        return this._rpc.makeRequest("erecord", "addAllToQueue", {
            type,
            queue,
            ids,
            showUIErrorOnDuplicates
        });
    }

    searchDocuments(search: any) {
        const searchModel = {
            counties: [search.recipientID],
            startDateString: search.start_date,
            endDateString: search.end_date,
            entryNumber: search.entry_number || null,
            book: search.book || null,
            bookPage: search.book_page || null,
            documentStates: search.document_states?.length
                ? search.document_states
                : null,
            organizations: search.submitter_id ? [search.submitter_id] : null,
            partyName: search.party_name || null,
            kindOfInstrument: search.document_type || null,
            packageName: search.package_name || null,
            helperDocTypes: search.helper_types?.length
                ? search.helper_types
                : null
        };

        return this._rpc.makeRequest("erecord", "searchDocuments", {
            searchModel
        });
    }

    oneClickPrint(documentID: string) {
        let params: any = {
            documentID: documentID,
            printHelpers: true,
            customPageRange: null,
            firstPageOnly: false
        };
        return this._rpc.makeRequest("erecord", "oneClickPrint", params);
    }

    getDocumentStates(recipientID: string) {
        return this._rpc.makeRequest("erecord", "getRecipientDocumentStates", {
            recipientID
        });
    }

    getKindOfInstruments(recipientID: string) {
        return this._rpc.makeRequest("erecord", "getRecipientKOIs", {
            recipientID
        });
    }

    getRecipientSubmitters(recipientID: string) {
        return this._rpc.makeRequest("erecord", "getRecipientSubmitters", {
            recipientID
        });
    }

    getRecipientEntryNumberString() {
        return this._rpc.makeRequest(
            "erecord",
            "getRecipientEntryNumberString"
        );
    }

    getRecipientPackageList(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
        return this._rpc.makeRequest("erecord", "getRecipientPackageList", {
            startDate,
            endDate
        });
    }

    getPackageListDocuments(packageID: string) {
        return this._rpc.makeRequest("erecord", "getPackageListDocuments", {
            packageID
        });
    }

    addLineWaiter(
        sType: string,
        sFullName: string,
        sCompany: string,
        iDocCount: number
    ) {
        return this._rpc.makeRequest("erecord", "addLineWaiter", {
            sType,
            sFullName,
            sCompany,
            iDocCount
        });
    }

    getUploadLock(queueItemID: string, documentID: string) {
        return this._rpc.makeRequest("erecord", "getUploadLock", {
            queueItemID,
            documentID
        });
    }

    getPrintLock(queueItemID: string, documentID: string) {
        return this._rpc.makeRequest("erecord", "getPrintLock", {
            queueItemID,
            documentID
        });
    }

    getNotificationSettings(recipientID: string) {
        return this._rpc.makeRequest(
            "userorg",
            "getRecipientOrganizationConfig",
            {
                recipientId: recipientID,
                group: "Notification"
            }
        );
    }

    setNotificationSettings(recipientID: string, notificationSettings: any) {
        return this._rpc.makeRequest(
            "userorg",
            "setRecipientOrganizationConfig",
            {
                recipientId: recipientID,
                settings: notificationSettings
            }
        );
    }

    addRecipientMessage(
        documentId: string,
        newMessage: any,
        sendFromOrg: string,
        sendToOrg: string
    ): Observable<any> {
        return this._rpc.makeRequest("document", "addRecipientMessage", {
            documentID: documentId,
            message: newMessage,
            currentUserOrg: sendFromOrg,
            sendToOrg: sendToOrg
        });
    }

    getReviewQueueMessages(recipientID: string): Observable<any> {
        return this._rpc.makeRequest("erecord", "getReviewQueueMessages", {
            recipientID
        });
    }

    getRecipientCustomFieldLabel(
        recipientID: string,
        mdd: string
    ): Observable<any> {
        return this._rpc.makeRequest(
            "erecord",
            "getRecipientCustomFieldLabel",
            {
                recipientID,
                mdd
            }
        );
    }

    getCustomProperties(recipientID: string): Observable<any> {
        return this._rpc.makeRequest("erecord", "getCustomProperties", {
            recipientID
        });
    }

    validateRecipientConfiguration(recipientID: string): Observable<any> {
        return this._rpc.makeRequest(
            "erecord",
            "validateRecipientConfiguration",
            {
                recipientID
            }
        );
    }

    mouNeedsSoftAuthReview(mouPackageID: string): Observable<any> {
        return this._rpc.makeRequest("erecord", "mouNeedsSoftAuthReview", {
            mouPackageID
        });
    }

    /** Private Methods **/
}
