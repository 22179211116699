import { Injectable } from "@angular/core";
import {
    OrganizationResult,
    PackageResult,
    ResultObjectType,
    UniversalResult,
    UserResult
} from "../universal-search.interfaces";
import { Router } from "@angular/router";
import {
    ConfirmationModalComponent,
    dayjs,
    EncryptionService,
    GrowlService,
    JspNavigationService
} from "@sf/common";
import { GlobalSearchService } from "../../global-search.service";
import { first } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    LockoutService,
    User,
    UserArchiveService,
    UserOrgService
} from "@sf/userorg/common";
import {
    AdminLoginAsService,
    AdminOrganizationService,
    AdminUserorgService,
    RecentOrganizationsService
} from "@sf/userorg/admin-common";
import { RecentUsersService } from "@sf/userorg/common";
import { UnarchiveUserDialogComponent } from "@sf/userorg/main";

@Injectable({
    providedIn: "root"
})
export class UniversalSearchComponentService {
    constructor(
        private growlService: GrowlService,
        private lockoutService: LockoutService,
        private userOrgService: UserOrgService,
        private globalSearchService: GlobalSearchService,
        private router: Router,
        private recentUsersService: RecentUsersService,
        private recentOrganizationsService: RecentOrganizationsService,
        private encryptionService: EncryptionService,
        private adminUserorgService: AdminUserorgService,
        private modalService: NgbModal,
        private jspNavigationService: JspNavigationService,
        private archiveService: UserArchiveService,
        private adminLoginAsService: AdminLoginAsService
    ) {}

    goToOrganizationPage(orgID: string, page: string): void {
        this.recentOrganizationsService.pushRecentOrganizationID(orgID);
        let url = "/admin/organization/" + orgID + "/" + page;
        this.router.navigate([url]);
    }

    goToUserPage(username: string, page: string): void {
        this.recentUsersService.pushRecentUserID(username);
        let enc = this.encryptionService.scrambleEncode(username);
        let url = "/admin/user/" + enc + "/" + page;
        this.router.navigate([url]);
    }

    goToJsp(url: string): void {
        setTimeout(() => {
            this.jspNavigationService.navigateToJsp(url);
        }, 100);
    }

    goToContact(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.goToOrganizationPage(item.id, "config/contact");
        } else if (item.objectType === ResultObjectType.USER) {
            this.goToUserPage(item.id, "contact");
        } else if (item.objectType === ResultObjectType.PACKAGE) {
            this.globalSearchService.pushRecentGlobalSearchItem(item);
            this.router.navigate(["/admin/package-admin/" + item.id]);
        }
    }

    becomeUser(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.USER) {
            this.recentUsersService.pushRecentUserID(item.id);
            this.adminUserorgService.loginAs(item.id);
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.adminLoginAsService.loginToOrganization(
                item.id,
                (item as OrganizationResult).label
            );
        } else if (item.objectType === ResultObjectType.PACKAGE) {
            let temp: PackageResult = item as PackageResult;
            this.adminLoginAsService.loginToOrganization(
                temp.submitterID,
                temp.submitterName,
                temp.id
            );
        }
    }

    goToRoles(item: UniversalResult): void {
        this.goToUserPage(item.id, "organizations");
    }

    goToActivityLogs(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.USER) {
            this.goToUserPage(item.id, "activity-logs");
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.goToOrganizationPage(item.id, "config/activity-logs");
        }
    }

    goToSecurity(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.USER) {
            this.goToUserPage(item.id, "security");
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.goToOrganizationPage(item.id, "config/security");
        }
    }

    unlockUser(user: any): void {
        let temp: User = user as User;
        this.lockoutService.doUserUnlock(user, false).then((result) => {
            if (result) {
                user.isLockedOut = false;
                this.userOrgService.buildUserStatus(user);
            }
        });
    }

    goToAssignments(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.USER) {
            this.goToUserPage(item.id, "organizations");
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.goToOrganizationPage(item.id, "config/users");
        }
    }

    goToSettings(item: UniversalResult): void {
        if (item.objectType === ResultObjectType.USER) {
            this.goToUserPage(item.id, "settings");
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.goToOrganizationPage(item.id, "config/settings");
        }
    }

    doUnarchive(item: any): void {
        if (item.objectType === ResultObjectType.USER) {
            this.unarchiveUser(item);
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.userOrgService
                .unarchiveOrganization(item.id)
                .pipe(first())
                .subscribe(() => {
                    this.growlService.success(
                        "Success:",
                        "Organization: " +
                            item.name +
                            " (" +
                            item.id +
                            ") unarchived"
                    );
                });
        }
    }

    unarchiveUser(user: UserResult): void {
        this.userOrgService
            .isEmailAddressAvailable(user.email, user.username)
            .pipe(first())
            .subscribe((available: boolean) => {
                if (!available) {
                    const modalRef = this.modalService.open(
                        ConfirmationModalComponent,
                        {
                            backdrop: "static"
                        }
                    );
                    const modalInstance = modalRef.componentInstance;
                    modalInstance.title = "Duplicate Email Address";
                    modalInstance.message =
                        "This user cannot be unarchived, because there is at least one other user with the same email address of '" +
                        user.email +
                        "'.";
                    modalInstance.primary = {
                        text: "OK",
                        callback: () => {
                            modalRef.close();
                        }
                    };
                } else {
                    this.innerUnarchiveUser(user);
                }
            });
    }

    innerUnarchiveUser(user: UserResult): void {
        const modalRef = this.modalService.open(UnarchiveUserDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.user = user;
        modalInstance.emailShared = false;

        modalRef.result.then((result: any) => {
            if (result) {
                this.archiveService
                    .unarchiveUser(user.id)
                    .pipe(first())
                    .subscribe(() => {
                        this.growlService.success(
                            "User: " +
                                user.name.first +
                                " " +
                                user.name.last +
                                " (" +
                                user.id +
                                ") has been unarchived"
                        );
                        this.recentUsersService.pushRecentUserID(user.id);
                    });
            }
        });
    }

    // positionMenu(event: any): void {
    //     $(event).focus();
    //
    //     // let $relParent = $(".sf-admin-universal-search .pick-menu").last();
    //     // let $parent = $(event.target);
    //     // let $menu = $("+ ul", $parent);
    //     // $menu.css({
    //     //     top:
    //     //             $parent.offset().top -
    //     //             $relParent.offset().top +
    //     //             $parent.outerHeight(),
    //     //     left:
    //     //             $parent.offset().left -
    //     //             $relParent.offset().left -
    //     //             $menu.outerWidth() +
    //     //             $parent.outerWidth(),
    //     //     visibility: "visible"
    //     // });
    // }

    goToPackageDetails(item: UniversalResult): void {
        this.globalSearchService.pushRecentGlobalSearchItem(item);
        sf.router.navigateByUrl(`/submitter/package/${item.id}/details`);
        this.reset();
    }
    reset(): void {}

    getDocumentTooltip(item: PackageResult): string {
        let tooltip = "";
        if (item.documentInfo.length >= 1) {
            let count = 0;
            while (count < 5) {
                if (item.documentInfo[count]) {
                    if (tooltip) {
                        tooltip += "   ";
                    }
                    let docInfo = item.documentInfo[count];
                    let recInfo = docInfo.recordingInfo;
                    if (recInfo) {
                        tooltip += recInfo + "   ";
                    }
                    let recordDate = item.documentInfo[count].recordDate;
                    if (recordDate) {
                        tooltip += dayjs(recordDate).format("MM/DD/YYYY");
                    }
                    count++;
                } else {
                    count = 5;
                }
            }
            if (tooltip && item.documentInfo.length > 5) {
                let leftover = item.documentInfo.length - 5;
                tooltip += " + " + leftover + " more documents";
            }
        }
        return tooltip ? tooltip : null;
    }

    goToPackages(item: any, type: string): void {
        let recipient = null;
        let url = null;

        if (item.objectType === ResultObjectType.ORGANIZATION) {
            this.recentOrganizationsService.pushRecentOrganizationID(item.id);
            recipient = item.id;
        } else if (item.objectType === ResultObjectType.PACKAGE) {
            this.globalSearchService.pushRecentGlobalSearchItem(item);
            recipient = item.recipientID;
        }
        if (type == "admin") {
            url = "/admin/package-admin/" + item.id;
            sf.router.navigateByUrl(url);
        } else if (type == "search") {
            this.goToOrganizationPage(recipient, "support/packages");
        }
        this.reset();
    }

    goToTransactions(item: UniversalResult, query?: string): void {
        if (item.objectType === ResultObjectType.PACKAGE) {
            this.globalSearchService.pushRecentGlobalSearchItem(item);
            this.router.navigate(["/admin/accounting/manage-transactions"], {
                queryParams: { pref: "package", packageID: item.id }
            });
        } else if (item.objectType === ResultObjectType.ORGANIZATION) {
            item = item as OrganizationResult;
            this.recentOrganizationsService.pushRecentOrganizationID(item.id);
            this.router.navigate(["/admin/accounting/manage-transactions"], {
                queryParams: {
                    transactionID: item.foundByTransactionID ? query : "",
                    pref: item.foundByTransactionID ? "trans" : "org",
                    orgID: item.foundByTransactionID ? "" : item.id
                }
            });
        }
        this.reset();
    }

    goToServices(item: UniversalResult): void {
        this.goToOrganizationPage(item.id, "config/services");
        this.reset();
    }

    goToPackagesForOrg(item: UniversalResult) {
        if (item.objectType == "organization") {
            this.recentOrganizationsService.pushRecentOrganizationID(item.id);
        }
        //this is a workaround for the JSP logic that ultimately ends up at the same page, but totally unresponsive
        if (!(item as OrganizationResult).isEnabled) {
            let url =
                "/admin/organization/" +
                item.id +
                "/recipient/config/settings/main";
            this.router.navigate([url]);
            this.reset();
        } else {
            let url = "/sf/recipient.jsp?organizationID=" + item.id;
            this.goToJsp(url);
        }
    }

    goToJobQueue(item: UniversalResult) {
        this.recentOrganizationsService.pushRecentOrganizationID(item.id);
        //this.router.navigate(['/admin/support/queues/job-queue-triage?getErrorsOnly=true&recipientIDs=' + item.id]);
        this.router.navigate(["/admin/support/queues/job-queue-triage"], {
            queryParams: { getErrorsOnly: "true", recipientIDs: item.id }
        });
        this.reset();
    }

    goToPaymentAccounts(item: UniversalResult) {
        if (item.objectType == "organization") {
            this.goToOrganizationPage(item.id, "config/accounts");
        }
        this.reset();
    }

    goToCounties(item: UniversalResult) {
        this.goToOrganizationPage(item.id, "submitter/counties");
        this.reset();
    }

    goToReports(item: UniversalResult) {
        this.recentOrganizationsService.pushRecentOrganizationID(item.id);
        let url = "/admin/organization/" + item.id + "/reports";
        this.router.navigate([url]);
        this.reset();
    }

    goToHierarchy(item: any) {
        this.goToOrganizationPage(item.id, "config/hierarchy");
        this.reset();
    }

    goToESignEventDetails(item: any) {
        this.globalSearchService.pushRecentGlobalSearchItem(item);
        sf.router.navigateByUrl(`/admin/sign-events/${item.id}/admin`);
        this.reset();
    }

    goToCapcLoanDetails(item: any) {
        this.globalSearchService.pushRecentGlobalSearchItem(item);
        sf.router.navigateByUrl(`/admin/lender/${item.id}/admin`);
        this.reset();
    }
}
