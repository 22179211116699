import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { RejectionReason } from "./rejection-reason.interface";
import { ContactInfoInterface } from "../contact-info.interface";

@Injectable({
    providedIn: "root"
})
export class RejectionReasonsService {
    /** Lifecycle Hooks **/
    constructor(private _rpc: RpcClientService) {}

    /** Public Methods **/
    getRejectionReasons(): Observable<RejectionReason[]> {
        return this._rpc.makeRequest("erecord", "getRejectionReasons");
    }

    getAdminRejectionReasons(
        recipientID: string
    ): Observable<RejectionReason[]> {
        return this._rpc.makeRequest("admin", "getRejectionReasons", {
            recipientID
        });
    }

    setRejectionReasons(rejectionReasons: any[]) {
        return this._rpc.makeRequest("erecord", "setRejectionReasons", {
            rejectionReasons
        });
    }

    setAdminRejectionReasons(
        recipientID: string,
        rejectionReasons: any[],
        importNotes: string
    ) {
        return this._rpc.makeRequest("admin", "setRejectionReasons", {
            recipientID,
            rejectionReasons,
            importNotes
        });
    }

    getSubmitterContactInfo(
        submitterId: string
    ): Observable<ContactInfoInterface> {
        return this._rpc.makeRequest("erecord", "getSubmitterContactInfo", {
            submitterId
        });
    }
}
