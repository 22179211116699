import { Injectable } from "@angular/core";
import { SocketService, SubscriptionBaseService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SigningEventSubscriptionService extends SubscriptionBaseService {
    product = "esign";

    constructor(protected _socket: SocketService) {
        super(_socket);
    }

    getSigningEventCreatedUpdate$(userID: string): Observable<{
        data: {
            eventID: string;
            creatingUserName: string;
            notarizationType: "RON" | "IPEN";
        };
    }> {
        this.namespace = "signing_event_created";
        return this.on("update", userID);
    }

    getSigningEventCanceledUpdate$(
        userID: string
    ): Observable<{ data: { eventID: string } }> {
        this.namespace = "signing_event_canceled";
        return this.on("update", userID);
    }
}
