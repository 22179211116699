import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface HighlightArea {
    pageID: string;
    height: number;
    width: number;
    x: number;
    y: number;
}

@Injectable()
export class ErecordHighlightService {
    private _currentHighlight = new BehaviorSubject<HighlightArea>(null);

    constructor() {}

    getHighlight$(): Observable<HighlightArea> {
        return this._currentHighlight.asObservable();
    }

    setHighlight(
        pageID: string,
        x: number,
        y: number,
        width: number,
        height: number
    ) {
        this._currentHighlight.next({
            pageID,
            x,
            y,
            width,
            height
        });
    }

    removeHighlight() {
        this._currentHighlight.next(null);
    }
}
