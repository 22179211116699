import { Component, OnInit } from "@angular/core";
import { GrowlService } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";

@Component({
    selector: "sf-tray-relink-modal",
    templateUrl: "./tray-relink-modal.component.html",
    styleUrls: ["./tray-relink-modal.component.scss"]
})
export class TrayRelinkModalComponent implements OnInit {
    /** Private Variables **/

    /** Public Variables **/
    primary: ModalButton;
    performingLinking = false;
    trayInstallerUrl: string;
    linkTray: Function;

    /** View Children **/

    constructor(
        private _growlService: GrowlService,
        private _modalService: NgbModal,
        public activeModal: NgbActiveModal
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        this.primary = {
            text: "OK",
            responseValue: "primary"
        };
    }

    /** Public Methods **/

    /**
     * Performs a link
     */
    performLink() {
        this.performingLinking = true;
        this.linkTray(true).subscribe({
            next: () => {
                this.activeModal.close("Linked");
                this._growlService.success(
                    "Simplifile Utilities successfully linked"
                );
            },
            complete: () => {
                this.performingLinking = false;
            },
            error: (err: any) => {
                this._modalService.dismissAll();
                this._growlService.error(err);
            }
        });
    }

    /**  Private Methods  **/
}
