<sf-modal-header [title]="modalTitle"></sf-modal-header>
<div class="modal-body sf-contract-pricing-tiers-dialog">
    <form #tierForm="ngForm">
        <span>Tier Reset Interval:&nbsp;</span>
        <div style="display: inline-block; width: 150px; vertical-align: middle">
            <sf-select name="tier-duration"
                       [options]="tierResetOptions"
                       (select)="handleResetSelect($event)"
                       [selectedOption]="selectedResetOption"
                       [isSingleSelect]="true"
                       trackBy="id"
                       labelBy="label"></sf-select>
        </div><br/><br/>
        <div class="row">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <span>{{quantityLabel}}</span>
            </div>
            <div style="width: 120px; display: inline-block">
                <span style="padding-left: 24px">{{amountLabel}}</span>
            </div>
        </div>
<!--
    Each row is explicitly defined, but hidden as needed, because Angular couldn't keep the FormControl data in sync...particularly when
    creating them dynamically, and after hours and hours of trying different things - THIS finally worked!
-->
        <div class="row mb-3">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <input type="text" [(ngModel)]="pricingTiers[0].tierThreshold" name="tierThreshold0"
                        class="form-control" id="tierThreshold0" maxlength="9" [required]="true"
                        aria-label="Tier 1 threshold"
                        [disabled]="true" (blur)="convertPricingTiers()">
            </div>
            <div style="width: 250px; display: inline-block; padding-left: 14px">
                <span>$</span>
                <input type="text" [(ngModel)]="pricingTiers[0].feeAmountString" name="tierAmount0" class="form-control"
                        id="tierAmount0" [required]="true"
                        aria-label="Tier 1 amount"
                        style="display: inline-block; width: 120px; margin-right: 10px" (blur)="convertPricingTiers()" maxlength="10">
                <span *ngIf="showDelete(0)" (click)="deleteTier(0)">
                    <fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true" ngbTooltip="Delete pricing tier"></fa-icon>
                </span>
                <span *ngIf="showAdd(0)" (click)="addTier(0)" style="padding-left: 10px">
                    <fa-icon [icon]="['far', 'plus']" [fixedWidth]="true" ngbTooltip="Add pricing tier" placement="right"></fa-icon>
                </span>
            </div>
        </div>
        <div class="row mb-3" [hidden]="tiersToShow < 2">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <input type="text" [(ngModel)]="pricingTiers[1].tierThreshold" name="tierThreshold1" class="form-control"
                        aria-label="Tier 2 threshold"
                       id="tierThreshold1" maxlength="9" [required]="true" (blur)="convertPricingTiers()">
            </div>
            <div style="width: 250px; display: inline-block; padding-left: 14px">
                <span>$</span>
                <input type="text" [(ngModel)]="pricingTiers[1].feeAmountString" name="tierAmount1" class="form-control"
                        id="tierAmount1" [required]="true"
                        aria-label="Tier 2 amount"
                        style="display: inline-block; width: 120px; margin-right: 10px" (blur)="convertPricingTiers()">
                <span *ngIf="showDelete(1)" (click)="deleteTier(1)">
                    <fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true" ngbTooltip="Delete pricing tier"></fa-icon>
                </span>
                <span *ngIf="showAdd(1)" (click)="addTier(1)" style="padding-left: 10px">
                    <fa-icon [icon]="['far', 'plus']" [fixedWidth]="true" ngbTooltip="Add pricing tier" placement="right"></fa-icon>
                </span>
            </div>
        </div>
        <div class="row mb-3" [hidden]="tiersToShow < 3">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <input type="text" [(ngModel)]="pricingTiers[2].tierThreshold" name="tierThreshold2" class="form-control"
                        aria-label="Tier 3 threshold"
                       id="tierThreshold2" maxlength="9" [required]="true" (blur)="convertPricingTiers()">
            </div>
            <div style="width: 250px; display: inline-block; padding-left: 14px">
                <span>$</span>
                <input type="text" [(ngModel)]="pricingTiers[2].feeAmountString" name="tierAmount2" class="form-control"
                        id="tierAmount2" [required]="true"
                        aria-label="Tier 3 amount"
                        style="display: inline-block; width: 120px; margin-right: 10px" (blur)="convertPricingTiers()">
                <span *ngIf="showDelete(2)" (click)="deleteTier(2)">
                    <fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true" ngbTooltip="Delete pricing tier"></fa-icon>
                </span>
                <span *ngIf="showAdd(2)" (click)="addTier(2)" style="padding-left: 10px">
                    <fa-icon [icon]="['far', 'plus']" [fixedWidth]="true" ngbTooltip="Add pricing tier" placement="right"></fa-icon>
                </span>
            </div>
        </div>
        <div class="row mb-3" [hidden]="tiersToShow < 4">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <input type="text" [(ngModel)]="pricingTiers[3].tierThreshold" name="tierThreshold3" class="form-control"
                        aria-label="Tier 4 threshold"
                       id="tierThreshold3" maxlength="9" [required]="true" (blur)="convertPricingTiers()">
            </div>
            <div style="width: 250px; display: inline-block; padding-left: 14px">
                <span>$</span>
                <input type="text" [(ngModel)]="pricingTiers[3].feeAmountString" name="tierAmount3" class="form-control"
                        id="tierAmount3" [required]="true"
                        aria-label="Tier 4 amount"
                        style="display: inline-block; width: 120px; margin-right: 10px" (blur)="convertPricingTiers()">
                <span *ngIf="showDelete(3)" (click)="deleteTier(3)">
                    <fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true" ngbTooltip="Delete pricing tier"></fa-icon>
                </span>
                <span *ngIf="showAdd(3)" (click)="addTier(3)" style="padding-left: 10px">
                    <fa-icon [icon]="['far', 'plus']" [fixedWidth]="true" ngbTooltip="Add pricing tier" placement="right"></fa-icon>
                </span>
            </div>
        </div>
        <div class="row mb-3" [hidden]="tiersToShow < 5">
            <div style="width: 120px; display: inline-block; padding-left: 14px">
                <input type="text" [(ngModel)]="pricingTiers[4].tierThreshold" name="tierThreshold4" class="form-control"
                        aria-label="Tier 5 threshold"
                       id="tierThreshold4" maxlength="9" [required]="true" (blur)="convertPricingTiers()">
            </div>
            <div style="width: 250px; display: inline-block; padding-left: 14px">
                <span>$</span>
                <input type="text" [(ngModel)]="pricingTiers[4].feeAmountString" name="tierAmount4" class="form-control"
                        id="tierAmount4" [required]="true"
                        aria-label="Tier 5 amount"
                        style="display: inline-block; width: 120px; margin-right: 10px" (blur)="convertPricingTiers()">
                <span *ngIf="showDelete(4)" (click)="deleteTier(4)">
                    <fa-icon [icon]="['far', 'trash-alt']" [fixedWidth]="true" ngbTooltip="Delete pricing tier"></fa-icon>
                </span>
                <span *ngIf="showAdd(4)" (click)="addTier(4)" style="padding-left: 10px">
                    <fa-icon [icon]="['far', 'plus']" [fixedWidth]="true" ngbTooltip="Add pricing tier" placement="right"></fa-icon>
                </span>
            </div>
        </div>
        <div class="errorMsg mt-3" *ngIf="error">
            <span>{{error}}</span>
        </div>
        <br/>
    </form>
</div>
<sf-modal-footer [primary]="primaryButton" [secondary]="secondaryButton"></sf-modal-footer>
