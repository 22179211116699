import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { SessionService } from "@sf/common";
import { GrowlService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class LoadRecipientPagesGuardService implements CanLoad {
    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _growlService: GrowlService
    ) {}

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        const isRecipient =
            this._sessionService.hasProductInAnyOrg("recipient");
        if (!isRecipient) {
            this._router.navigate(["/"]);
            if (this._sessionService.getEnv() !== "PROD") {
                this._growlService.error(
                    "You are not a recipient, so you're being redirected to your home page."
                );
            }
        }

        return isRecipient;
    }
}
