import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { Subject } from "rxjs";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "sf-tabbed-modal-footer",
    templateUrl: "./tabbed-modal-footer.component.html",
    styleUrls: ["./tabbed-modal-footer.component.scss"]
})
export class TabbedModalFooterComponent implements OnInit, OnDestroy {
    @Input()
    primary: ModalButton;
    @Input()
    secondary: ModalButton;
    @Input()
    nextButton: ModalButton;
    @Input()
    backButton: ModalButton;

    private _$ngOnDestroy: Subject<void> = new Subject();

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {
        if (this.primary && this.primary.formGroup) {
            this.primary.formGroup.statusChanges
                .pipe(takeUntil(this._$ngOnDestroy))
                .subscribe((validity: string) => {
                    this.primary.disabled = validity === "INVALID";
                });
        }
    }

    ngOnDestroy(): void {
        this._$ngOnDestroy.next();
    }

    buttonClick(btn: ModalButton, fallback: string) {
        if (btn.callback) {
            if (btn.callback()) {
                this.modal.close(btn.responseValue);
            }
        } else {
            this.modal.close(btn.responseValue ? btn.responseValue : fallback);
        }
    }
}
