import {
    ConditionRule,
    ExpressionToken,
    OperatorToken
} from "../interfaces/conditional.interface";
import { FieldViewState } from "../interfaces/dynamic-form-state";

const regex = /(\S+)\s(\!\=|\=\=|<\=|>\=|>|<)\s(\S+)|(\&\&|\|\|)/g;

export class ExpressionRule implements ConditionRule {
    private readonly _tokens: Array<OperatorToken | ExpressionToken>;

    constructor(private _expression: string, private _parentPath: string[]) {
        this._tokens = this._tokenizeExpression(_expression);
    }

    match(values: any, viewState?: FieldViewState): boolean {
        if (!values) {
            return false;
        }

        let expressionValues: any[] = [];

        for (let i = 0; i < this._tokens.length; i++) {
            let token = this._tokens[i];
            if (token.type === "expression") {
                expressionValues.push(
                    this._getValueFromExpression(token, values)
                );
            } else if (token.type === "operator") {
                expressionValues.push(token);
            }
        }

        let ruleValid = false;
        for (let i = 0; i < expressionValues.length; i++) {
            let value = expressionValues[i];
            if (typeof value === "object") {
                if (value.value === "AND") {
                    ruleValid = ruleValid && expressionValues[i + 1];
                    i++;
                } else if (value.value === "OR") {
                    ruleValid = ruleValid || expressionValues[i + 1];
                    i++;
                }
            } else {
                ruleValid = value;
            }
        }

        return !!ruleValid;
    }

    private _tokenizeExpression(
        expression: string
    ): Array<OperatorToken | ExpressionToken> {
        let tokens: Array<ExpressionToken | OperatorToken> = [];
        let part;

        while ((part = regex.exec(expression)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (part.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            let token: ExpressionToken | OperatorToken;
            // The result can be accessed through the `m`-variable.
            if (typeof part[4] !== "undefined") {
                token = {
                    type: "operator",
                    value: "AND"
                };

                if (part[4] === "||") {
                    token.value = "OR";
                }
            } else if (typeof part[1] !== "undefined") {
                token = {
                    type: "expression",
                    path: part[1].split("."),
                    operand: "equal",
                    value: part[3]
                };
                if (part[2] === "!=") {
                    token.operand = "not_equal";
                } else if (part[2] === "<") {
                    token.operand = "lt";
                } else if (part[2] === "<=") {
                    token.operand = "lte";
                } else if (part[2] === ">") {
                    token.operand = "gt";
                } else if (part[2] === ">=") {
                    token.operand = "gte";
                }
            }

            if (token) {
                tokens.push(token);
            }
        }

        return tokens;
    }

    private _getValueFromExpression(
        token: ExpressionToken,
        values: any
    ): boolean {
        let result = false;
        let val;
        let tokenValue = this._getTokenValue(token.path, values);
        if (typeof tokenValue === "number") val = Number(token.value);
        else val = token.value;

        if (tokenValue !== undefined && tokenValue !== null) {
            if (token.operand === "equal") {
                result = tokenValue === val;
            } else if (token.operand === "not_equal") {
                result = tokenValue !== val;
            } else if (token.operand === "lt") {
                result = tokenValue < val;
            } else if (token.operand === "lte") {
                result = tokenValue <= val;
            } else if (token.operand === "gt") {
                result = tokenValue > val;
            } else if (token.operand === "gte") {
                result = tokenValue >= val;
            }
        }
        return result;
    }

    private _getTokenValue(tokenPath: string[], values: any) {
        let path = [...tokenPath];
        if (path[0] !== "root") {
            path = [...this._parentPath, ...path];
        } else {
            path.shift();
        }

        let currentValue: any = values;
        for (let pathItem of path) {
            if (typeof currentValue[pathItem] === "object") {
                currentValue = currentValue[pathItem];
            } else if (typeof currentValue[pathItem] !== "undefined") {
                return currentValue[pathItem];
            }
        }
    }
}
