import { ConditionRule, FieldViewState } from "../interfaces";

export class ContainsAndVisibleRule implements ConditionRule {
    constructor(private _value: any) {}
    match(value: any, viewState?: FieldViewState): boolean {
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; ++i) {
                if (this._value === value[i])
                    return true && (viewState ? viewState.visible : true);
            }
            return false;
        } else if (typeof value === "string") {
            return (
                value.indexOf(this._value) != -1 &&
                (viewState ? viewState.visible : true)
            );
        } else {
            return (
                this._value === value && (viewState ? viewState.visible : true)
            );
        }
    }
}
