import { Component, Input, OnInit } from "@angular/core";
import { ModalButton, SessionService, UserSettingsService } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-verify-phone-number-dialog",
    templateUrl: "./verify-phone-number-dialog.component.html",
    styleUrls: ["./verify-phone-number-dialog.component.scss"]
})
export class VerifyPhoneNumberDialogComponent implements OnInit {
    @Input() phoneNumber: string;
    @Input() destination: string;

    primary: ModalButton;
    secondary: ModalButton;
    warning: string;
    verificationCode: string;
    codeSent = false;
    waiting = true;

    constructor(
            private modalService: NgbModal,
            private activeModal: NgbActiveModal,
            private sessionService: SessionService,
            private userSettingsService: UserSettingsService,
            private userService: UserService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Verify",
            disabled: false,
            callback: this.verify.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        this.newCode();
    }

    newCode() {
        this.warning = null;
        this.waiting = true;
        this.userService.generateAndSendValidationCode(this.phoneNumber, this.destination).subscribe((result: any) => {
            this.restartWait();
            this.codeSent = true;
        }, (errorResult: any) => {
            this.warning = "Failed to send code. Please request a new code in a little while.";
            this.restartWait();
        });
    }

    restartWait() {
        setTimeout(() => {
            this.waiting = false;
        }, 10000);
    }

    verify() {
        this.warning = null;
        if (!this.verificationCode) {
            this.warning = "Please enter the verification code sent to your mobile phone at " + this.phoneNumber;
            return;
        }
        this.waiting = true;
        this.userService.validateValidationCode(this.verificationCode).subscribe((result: any) => {
            if (result) {
                if (result.status == "success") {
                    this.activeModal.close(this.phoneNumber);
                    // store the verified date
                    this.userSettingsService.setUserSetting("MOBILE_PHONE_VERIFIED_DATE", new Date());
                } else if (result.status == "locked") {
                    // too many failures
                    this.activeModal.close(null);
                } else {
                    this.warning = "The verification code you entered is incorrect. " +
                            "Please try again or request a new verification code";
                    this.waiting = false;
                }
            }
        },
        (errorResult: any) => {
            this.warning = "Something is wrong with the code you entered.";
            this.waiting = false;
        });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
