import { Component, OnInit } from "@angular/core";
import {
    LogoutService,
    NavItem,
    Session,
    SessionService,
    WindowRefService
} from "@sf/common";
import { TrayModulesService } from "@sf/tray";
import { sampleTime, takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import {
    UserorgActivationService,
    UserSubscriptionService
} from "@sf/userorg/common";

@Component({
    selector: "sf-user-settings-control",
    templateUrl: "./user-settings-control.component.html",
    styleUrls: ["./user-settings-control.component.scss"]
})
export class UserSettingsControlComponent implements OnInit {
    userInitials: string;
    first: string;
    last: string;
    profilePicture: string;
    links: NavItem[];
    private _onDestroy$: Subject<void> = new Subject<void>();
    private updater: Subscription = null;

    constructor(
        private _sessionService: SessionService,
        private _logoutService: LogoutService,
        private _window: WindowRefService,
        private _trayModulesService: TrayModulesService,
        private userSubscriptionService: UserSubscriptionService,
        private userorgActivationService: UserorgActivationService
    ) {}

    ngOnInit(): void {
        this.links = this._getLinks();
        this.reload();
        this.subscribeToChanges();
    }

    // this is to get updated if initials or image changes
    subscribeToChanges() {
        this.userSubscriptionService.profileChanged
            .pipe(takeUntil(this._onDestroy$), sampleTime(2000))
            .subscribe(() => {
                this.refreshSession();
            });
    }

    reload() {
        [this.first, this.last] = this._sessionService
            .getFirstLast()
            .split(" ");
        this.userInitials = this.first.charAt(0) + this.last.charAt(0);
        this.profilePicture = this._sessionService.getImageUrl();
    }

    // need to reset to get updated user settings
    refreshSession() {
        this.userorgActivationService
            .updateUserSession()
            .subscribe((session: { session: Session }) => {
                if (session && session.session) {
                    this._sessionService.setSession(session.session);
                    this.reload();
                }
            });
    }

    private _getLinks(): NavItem[] {
        const isSuperUser = this._sessionService.isSuperUser();
        let items: NavItem[] = [];

        if (isSuperUser) {
            items.push({
                label: "Profile",
                link: ["/admin/settings/personal"]
            });
        } else {
            items.push({
                label: "Profile",
                link: ["/settings/personal"]
            });
        }

        items.push(
            {
                label: "Roles",
                link: ["/settings/roles"]
            },
            {
                label: "Notifications",
                link: ["/settings/notifications"]
            }
        );

        if (!isSuperUser) {
            items = items.concat(this._getNotaryLinks());

            if (
                this._sessionService.hasProductInAnyOrg("submitter") ||
                this._sessionService.hasProductInAnyOrg("recipient")
            ) {
                items = items.concat(this._getErecordLinks());
            }

            if (this._sessionService.hasProductInAnyOrg("submitter")) {
                items = items.concat(this._getSubmitterLinks());
            }
            items = items.concat(this._getGeneralLinks());
        }

        return items;
    }

    logout(): void {
        this._logoutService.runLogoutActions();
        this._window.nativeWindow.location.assign("/sf/ui/login");
    }

    private _getNotaryLinks(): NavItem[] {
        const items: NavItem[] = [];
        if (
            this._sessionService.hasAnyPermissionInAnyOrg([
                "organization_notary_credentials_add"
            ])
        ) {
            items.push({
                label: "Notary",
                link: ["/settings/notary-credentials"]
            });
        }
        return items;
    }

    private _getErecordLinks(): NavItem[] {
        const items: NavItem[] = [];
        if (
            this._sessionService.hasAnyPermissionInAnyOrg([
                "organization_signature_click_sign_add",
                "submitter_signature_digital_add",
                "recipient_add_modify_signature"
            ])
        ) {
            const link = "/settings/signature";
            items.push({
                label: "Signature",
                link: [link]
            });
        }

        return items;
    }

    private _getSubmitterLinks(): NavItem[] {
        // none at this time
        return [];
    }

    private _getGeneralLinks() {
        const items: NavItem[] = [];
        if (
            this._trayModulesService.isAnyModuleEnabled() ||
            this._trayModulesService.allowDownload()
        ) {
            items.push({
                label: "Utilities",
                link: ["/settings/tray-setup"]
            });
        }

        return items;
    }
}
