import { UIFieldType } from "../enums";
import { UIForm } from "../interfaces";

export function reformatListableObject(listableObj: any): UIForm {
    return {
        type: UIFieldType.CONTAINER,
        mapFields: [
            {
                type: UIFieldType.LISTTABLE,
                path: listableObj.path,
                label: listableObj.label,
                listField: {
                    type: UIFieldType.CONTAINER,
                    mapFields: listableObj.listField.mapFields
                }
            }
        ]
    };
}
