import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import { kebabCase, startCase } from "../../helpers/string";

@Component({
    selector: "sf-pill",
    templateUrl: "./pill.component.html",
    styleUrls: ["./pill.component.scss"]
})
export class PillComponent implements OnInit, OnChanges {
    @Input()
    value: string;
    @Input()
    infoOnly? = false; // Don't change cursor on hover for info-only pills
    @Input()
    simple? = false; // used to style as colored text instead of a pill

    titleCaseStatus = "";
    badgeClasses = "";

    ngOnInit() {
        this._initPill();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentValue !== null) {
            this._initPill();
        }
    }

    private _initPill() {
        let statusClass = "";
        if (this.value) {
            this.titleCaseStatus = startCase(this.value.toLowerCase());
            statusClass = kebabCase(this.value);
        }
        if (this.simple) {
            this.badgeClasses = `simple ${statusClass}`;
        } else {
            this.badgeClasses = `badge badge-pill ${statusClass}`;
        }
    }
}
