import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "sf-base-layout",
    templateUrl: "./base-layout.component.html",
    styleUrls: ["./base-layout.component.scss"]
})
export class BaseLayoutComponent implements OnInit {
    containerClass: string;
    rightContentClass: string;

    constructor(private _activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        let route = this._activatedRoute.snapshot;

        if (!route || !route.data) {
            return;
        }

        if (route.data.containerClass) {
            this.containerClass = route.data.containerClass;
        }
    }
}
