import * as dayjsBare from 'dayjs';
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import * as duration from "dayjs/plugin/duration";
import * as relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/en';

dayjsBare.extend(utc);
dayjsBare.extend(timezone);
dayjsBare.extend(duration);
dayjsBare.extend(relativeTime);

dayjsBare.locale('en');

export { dayjsBare as dayjs };
