import { Pipe, PipeTransform, TrackByFunction } from "@angular/core";
// See https://www.bennadel.com/blog/3580-using-pure-pipes-to-generate-ngfor-trackby-identity-functions-for-mixed-collections-in-angular-7-2-7.htm
/*
    Examples:
        Single Property: *ngFor="let item of optionalList; trackBy: ('feeId' | trackByProperty)"
        Multi Property: *ngFor="let item of optionalList; trackBy: (['lastName', 'firstName', 'middle'] | trackByProperty)"
 */
// Slight efficiency tweak - cache instances of the trackBy function that take identical inputs
const cache: any = {};

@Pipe({
    name: "trackByProperty",
    pure: true
})
export class TrackByPropertyPipe implements PipeTransform {
    // Return a TrackBy function that returns the given properties from the item.
    public transform(propertyNames: string | string[]): TrackByFunction<any> {
        console.warn(`Getting track-by for [${propertyNames.toString()}].`);

        let cacheKey: string;

        // If the given property names are defined as an Array, then we have to generate
        // the item identity based on the composition of several item values (in which
        // each key in the input maps to a property on the item).
        if (Array.isArray(propertyNames)) {
            cacheKey = propertyNames.join("->");

            // Create if not already cached
            if (!cache[cacheKey]) {
                cache[cacheKey] = function trackByProperty(
                    index: number,
                    item: any
                ): any {
                    let values: string[] = [];

                    // Collect the item values that will be aggregated in the resultant
                    // item identity
                    propertyNames.forEach((property: string) => {
                        values.push(item[property]);
                    });
                    // console.log("smartTrackBy:", cacheKey);

                    return values.join("->"); // Return combined property key
                };
            }
            // Special case the $index option
        } else if (propertyNames === "$index") {
            cacheKey = propertyNames;
            // Create if not already cached
            if (!cache[cacheKey]) {
                cache[cacheKey] = function trackByProperty(
                    index: number,
                    item: any
                ): any {
                    return index; // Return INDEX.
                };
            }
        } else {
            cacheKey = propertyNames;
            // Ensure cached identity function.
            if (!cache[cacheKey]) {
                cache[cacheKey] = function trackByProperty(
                    index: number,
                    item: any
                ): any {
                    // console.log("smartTrackBy:", propertyNames);
                    return item[propertyNames]; // Return single property VALUE.
                };
            }
        }

        return cache[cacheKey];
    }
}
