import { Component, OnInit } from "@angular/core";
import { SfSelectEvent } from "../../common/sf-select.interface";

@Component({
    selector: "sf-sf-select-test",
    templateUrl: "./sf-select-test.component.html",
    styleUrls: ["./sf-select-test.component.scss"]
})
export class SfSelectTestComponent implements OnInit {
    sfSelectInstances: {
        [name: string]: {
            config: {
                options: {
                    label: string;
                    id: string;
                    category?: string;
                    disabled?: boolean;
                }[];
                onSelect: (event: SfSelectEvent) => void;
            };
        };
    };

    constructor() {
        this.sfSelectInstances = {
            single: {
                config: {
                    options: [
                        { label: "a", id: "a" },
                        { label: "b", id: "b" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#single-select-output",
                            event
                        );
                    }
                }
            },
            multiple: {
                config: {
                    options: [
                        { label: "a", id: "a" },
                        { label: "b", id: "b" },
                        { label: "c", id: "c" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#multiple-select-output",
                            event
                        );
                    }
                }
            },
            singleFilter: {
                config: {
                    options: [
                        { label: "a", id: "a" },
                        { label: "b", id: "b" },
                        { label: "c", id: "c" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#single-filter-onSelect-output",
                            event
                        );
                    }
                }
            },
            singleFilterWithDisabled: {
                config: {
                    options: [
                        { label: "apple", id: "apple" },
                        { label: "banana", id: "banana" },
                        { label: "carrot", id: "carrot", disabled: true }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#single-filter-with-disabled-onSelect-output",
                            event
                        );
                    }
                }
            },
            multipleFilter: {
                config: {
                    options: [
                        { label: "aa", id: "a" },
                        { label: "ab", id: "b" },
                        { label: "c", id: "c" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#multiple-filter-onSelect-output",
                            event
                        );
                    }
                }
            },
            singleGrouping: {
                config: {
                    options: [
                        { label: "a1", id: "a1", category: "a" },
                        { label: "a2", id: "a2", category: "a" },
                        { label: "b1", id: "b1", category: "b" },
                        { label: "b2", id: "b2", category: "b" },
                        { label: "c2", id: "c2", category: "c" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#single-grouping-onSelect-output",
                            event
                        );
                    }
                }
            },
            multipleGrouping: {
                config: {
                    options: [
                        { label: "a1", id: "a1", category: "a" },
                        { label: "a2", id: "a2", category: "a" },
                        { label: "b1", id: "b1", category: "b" },
                        { label: "b2", id: "b2", category: "b" },
                        { label: "c2", id: "c2", category: "c" }
                    ],
                    onSelect: (event: SfSelectEvent) => {
                        SfSelectTestComponent._displaySelection(
                            "#multiple-grouping-onSelect-output",
                            event
                        );
                    }
                }
            }
        };

        this.sfSelectInstances.longSingle = {
            config: {
                options: [],
                onSelect: (event: SfSelectEvent) => {
                    SfSelectTestComponent._displaySelection(
                        "#long-single-onSelect-output",
                        event
                    );
                }
            }
        };
        for (let i = 1; i <= 1000; i++) {
            this.sfSelectInstances.longSingle.config.options.push({
                label: "Option " + i,
                id: "option" + i
            });
        }

        this.sfSelectInstances.longMultiple = {
            config: {
                options: [],
                onSelect: (event: SfSelectEvent) => {
                    SfSelectTestComponent._displaySelection(
                        "#long-multiple-onSelect-output",
                        event
                    );
                }
            }
        };
        for (let i = 1; i <= 1000; i++) {
            this.sfSelectInstances.longMultiple.config.options.push({
                label: "Option " + i,
                id: "option" + i
            });
        }
    }

    ngOnInit() {}

    static _displaySelection(selector: string, { $selection }: SfSelectEvent) {
        (document.querySelector(selector) as HTMLElement).innerText =
            JSON.stringify($selection, undefined, 2);
    }
}
