import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {
    DynamicFormComponent,
    DynamicFormStore,
    FieldType,
    FormViewerConditions
} from "@sf/dynamic-form-viewer";
import { UserType } from "../../interfaces/user-type.enum";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";
import { debounceTime, map, switchMap, take, takeUntil } from "rxjs/operators";
import { SaveField } from "../../interfaces/save-field.interface";
import { CustomFormService } from "../../custom-form.service";
import { GrowlService } from "@sf/common";
import { sortBy } from "@sf/common";
import { Observable, Subject } from "rxjs";
import { dayjs } from "@sf/common";

@Component({
    selector: "sf-custom-form-viewer-new",
    templateUrl: "./custom-form-viewer-new.component.html",
    styleUrls: ["./custom-form-viewer-new.component.scss"],
    providers: [FormViewerConditions, DynamicFormStore]
})
export class CustomFormViewerNewComponent
    extends DynamicFormComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
    @Input() docLeftData: ErecordDocumentDetails;
    @Input() formId?: string;
    @Input() orgID?: string;
    @Input() userType?: UserType;
    @Input() onResize?: Observable<any>;
    @Input() ocrUpdates?: Observable<{ pageAnalysisID: string }>;
    @Output() onSave: EventEmitter<string> = new EventEmitter<string>();
    @Output() onError: EventEmitter<string> = new EventEmitter<string>();

    private _fieldState: any;
    private _otherPostFix: string = "_other";
    private _otherSelectedValue: string = "freeForm";

    @ViewChild("rootFieldContainer")
    rootFieldContainer: ElementRef;

    rootFieldWidth: number = 0;

    constructor(
        _formFacade: DynamicFormStore,
        _conditions: FormViewerConditions,
        private _customFormService: CustomFormService,
        private _growl: GrowlService
    ) {
        super(_formFacade, _conditions);
    }

    ngOnInit() {
        super.ngOnInit();
        this._formFacade.formGroup$
            .pipe(
                takeUntil(this._destroy),
                switchMap((formGroup: any) => {
                    return formGroup.valueChanges;
                }),
                debounceTime(500)
            )
            .subscribe((values: any) => {
                this._saveForm(values);
            });

        this._formFacade.fieldState$
            .pipe(takeUntil(this._destroy))
            .subscribe((state) => {
                this._fieldState = state;
            });

        this._customFormService
            .getOcrSuggestionUpdates$(this.docLeftData.id)
            .pipe(takeUntil(this._destroy))
            .subscribe((ocrSuggestions: any) => {
                this._getAutoCompleteData(ocrSuggestions.pageAnalysisID);
            });

        this._getAutoCompleteData();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.rootFieldWidth =
                this.rootFieldContainer.nativeElement.offsetWidth;
        });
        if (this.onResize) {
            this.onResize.subscribe(() => {
                this.rootFieldWidth =
                    this.rootFieldContainer.nativeElement.offsetWidth;
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    private _getAutoCompleteData(pageAnalysisID?: string) {
        // this.autoComplete$ = this.autoCompleteSub.asObservable();
        if (this.docLeftData.id) {
            this._customFormService
                .getAutoCompleteSuggestions(
                    this.docLeftData.id,
                    this.formId,
                    pageAnalysisID
                )
                .subscribe((autoCompleteData: any) => {
                    // this.autoCompleteSub.next(this._sortAutoCompleteData(autoCompleteData));
                    this._customFormService.pushAutoCompleteUpdate(
                        this._sortAutoCompleteData(autoCompleteData)
                    );
                });
        }
    }

    private _sortAutoCompleteData(autoCompleteData: any): any {
        let sortedData: any = {};
        if (autoCompleteData) {
            for (let key in autoCompleteData) {
                let value: any[] = autoCompleteData[key];
                if (value && Array.isArray(value) && value.length) {
                    sortedData[key] = value.sort(sortBy("auto_complete_name"));
                } else {
                    sortedData[key] = value;
                }
            }
        }
        return sortedData;
    }

    private _saveForm(values: any): void {
        if (
            !this.userType ||
            !this.docLeftData?.id ||
            !this.docLeftData?.packageData?.docLeftEditable
        ) {
            return;
        }

        let allSaveValues: SaveField[] = this._getSaveValues(values);
        this._customFormService
            .setDocumentCustomValues(
                this.docLeftData.id,
                this.formId,
                allSaveValues
            )
            .subscribe({
                next: (newFee: string) => {
                    this._growl.success("Save Successful!");
                    this.onSave.emit(newFee);
                },
                error: (error: any) => {
                    if (error && typeof error === "string") {
                        if (error.indexOf("Document is locked by") > -1) {
                            this._growl.error(
                                error +
                                    ". Your data was not saved. Please take control of the document to continue."
                            );
                            this.onError.emit(error);
                        } else {
                            this._growl.error(error);
                            this.onError.emit(error);
                        }
                    } else if (
                        error &&
                        error.error &&
                        error.error.errorMessage &&
                        typeof error.error.errorMessage === "string"
                    ) {
                        this._growl.error(error.error.errorMessage);
                        this.onError.emit(error.error.errorMessage);
                    } else if (
                        error &&
                        error.message &&
                        typeof error.message === "string"
                    ) {
                        this._growl.error(error.message);
                        this.onError.emit(error.message);
                    }
                }
            });
    }

    private _getSaveValues(
        values: any,
        indexLevel: number = 0,
        parentPath?: string
    ): SaveField[] {
        let allSaveValues: SaveField[] = [];
        for (let path in values) {
            let isArrayValue: boolean = Array.isArray(values[path]);
            let type = this._getAttributeFromPath(path, "type", parentPath);

            if (isArrayValue) {
                // groupable fields
                if (typeof values[path][0] === "object") {
                    let maxLevel = this._getAttributeFromPath(
                        path,
                        "maxLength",
                        parentPath
                    );
                    let valueLength = values[path].length;
                    let indexAdjustment = valueLength === maxLevel ? 0 : 1;

                    for (
                        let i = 0;
                        i < values[path].length - indexAdjustment;
                        ++i
                    ) {
                        // iterate over the grouped fields (skip the last level, unless the max level has been reached)
                        if (!this._allValuesEmpty(values[path][i])) {
                            allSaveValues = allSaveValues.concat(
                                this._getSaveValues(values[path][i], i, path)
                            );
                        }
                    }
                }
                // fixed fields (checkboxes, multi-select, radio, etc)
                else {
                    let fieldID = this._getAttributeFromPath(
                        path,
                        "id",
                        parentPath
                    );
                    if (type === FieldType.MULTITITLE) {
                        allSaveValues.push({
                            customFieldID: fieldID,
                            documentValue: values[path],
                            sequence_id: indexLevel
                        });
                    } else {
                        for (let v = 0; v < values[path].length; ++v) {
                            allSaveValues.push({
                                customFieldID: fieldID,
                                customValueID: values[path][v],
                                sequence_id: indexLevel
                            });
                        }
                    }
                }
            } else {
                // non-fixed fields (textbox, numeric, etc)
                let id = this._getAttributeFromPath(path, "id", parentPath);
                if (!id.endsWith(this._otherPostFix)) {
                    let docVal = values[path];
                    let fullPath: string = path;
                    if (parentPath) {
                        this._formFacade
                            .getVisibleFieldsForField(parentPath)
                            .pipe(take(1))
                            .subscribe((states) => {
                                fullPath = states[indexLevel].path + "." + path;
                            });
                    }
                    if (this._fieldState[fullPath]) {
                        if (
                            this._fieldState[fullPath].type ===
                                FieldType.DATE ||
                            this._fieldState[fullPath].type ===
                                FieldType.DATERANGE ||
                            this._fieldState[fullPath].type ===
                                FieldType.DATERANGE_SIMPLE
                        ) {
                            if (dayjs(docVal).isValid()) {
                                docVal = dayjs(docVal).format("YYYY-MM-DD");
                            }
                        }
                        if (
                            this._fieldState[fullPath].type ===
                                FieldType.TIME ||
                            this._fieldState[fullPath].type ===
                                FieldType.TIMESTAMP
                        ) {
                            if (dayjs(docVal).isValid()) {
                                docVal = dayjs(docVal).format(""); // this will format a time value to the correct format
                            }
                        }
                    }
                    if (
                        type === FieldType.NUMERIC ||
                        type === FieldType.CURRENCY
                    ) {
                        if (values[path] && typeof values[path] === "string") {
                            docVal = values[path].split(",").join("");
                            try {
                                if (parseFloat(docVal) < 0) {
                                    docVal = "";
                                }
                            } catch (e) {
                                docVal = "";
                            }
                        } else {
                            docVal = "";
                        }
                    }
                    if (values[path] === this._otherSelectedValue) {
                        docVal = values[path + this._otherPostFix];
                    }
                    // for some reason, sometimes the formgroup can save empty values of a checkbox group as an ""
                    if (docVal != "") {
                        allSaveValues.push({
                            customFieldID: id,
                            documentValue: docVal,
                            customValueID: docVal, // radio, and select fields, don't have an array of values. This ensure the data is saved correctly
                            sequence_id: indexLevel
                        });
                    }
                }
            }
        }

        return allSaveValues;
    }

    private _getAttributeFromPath(
        fieldPath: string,
        attribute: string,
        parentPath?: string
    ) {
        let attr: string;
        if (parentPath) {
            let rFields = this._fieldState[parentPath].repeatableFields.fields;
            for (let i = 0; i < rFields.length; ++i) {
                if (rFields[i].path === fieldPath) {
                    attr = rFields[i][attribute];
                }
            }
        } else {
            attr = this._fieldState[fieldPath][attribute];
        }

        return attr;
    }

    private _allValuesEmpty(values: any): boolean {
        for (let key in values) {
            if (values[key] && values[key] !== "") {
                return false;
            }
        }
        return true;
    }
}
