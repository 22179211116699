/**
 * Corresponds to com.simplifile.api.ApiCustomizationRuleConditional.BooleanOperatorType
 */
import { ApiHistoryItem } from "./api-definition";

export type BooleanOperatorType = "AND" | "OR" | "XOR";

/**
 * Corresponds to com.simplifile.api.ApiCustomizationOption
 */
export type ApiCustomizationOption =
    | "AUTO_VALIDATE_NC_STAMP"
    | "BREAK_OUT_PORTAL_FEES"
    | "CONVERT_TROUBLE_PARTY_CHARACTERS"
    | "DEFAULT_TAX_ACCOUNT_TO_RECORDING_ACCOUNT"
    | "DELAY_SUBMISSIONS"
    | "DETECT_ORG_PARTIES"
    | "DO_NOT_REMOVE_ON_ERRORS"
    | "DO_NOT_RETURN_EMPTY_REJECTION_ARRAY"
    | "ENABLE_CUSTOM_FORMS_REQUIREMENTS"
    | "FULLY_CAPITALIZE_GRANTEES"
    | "FULLY_CAPITALIZE_GRANTORS"
    | "GUESS_TOWNSHIP"
    | "INCLUDE_HELPER_PAGES"
    | "INCLUDE_STAMPED_FINALIZING_PAGES"
    | "INJECT_PARTY_COUNT"
    | "LOG_FULL_REQUEST"
    | "LOG_FULL_RESPONSE"
    | "MATCH_API_STATUS_TO_UI_STATUS"
    | "RESPECT_FALSE_ORG_TAG"
    | "STORE_RAW_PARTY"
    | "SUPPRESS_SALES_TAX_FEE"
    | "SWAP_PARTIES"
    | "SYNC_RECIPIENT"
    | "USE_ALL_GRANTEE_TAGS"
    | "USE_ALL_GRANTOR_TAGS"
    | "USE_DOCUMENT_HANDLER_IMAGE_PROCESSING"
    | "USE_SF2_PARTIES_PARSER"
    | "USE_LEGACY_SUBMIT_ERRORS"
    | "COPY_SUBMITTER_PACKAGE_ID_TO_TERTIARY"
    | "ALLOW_FALL_BACK_TO_COMMON_API_UTIL"
    //String Customizations
    | "APPEND_TO_KOI"
    | "CHANGE_GRANTEE_ORDER"
    | "CHANGE_GRANTOR_ORDER"
    | "DRAFT_REJECTED_PACKAGE_LIST_DISPLAY_STATUS"
    | "DRAFT_REJECTED_PACKAGE_DISPLAY_STATUS"
    | "FILTER_REJECTIONS"
    | "FILTER_SUBMISSIONS"
    | "FINALIZING_PACKAGE_LIST_DISPLAY_STATUS"
    | "FINALIZING_PACKAGE_DISPLAY_STATUS"
    | "GRANTEE_XML_TAGS"
    | "GRANTOR_XML_TAGS"
    | "INJECT_REFERENCE_DOCTYPE"
    | "LOG_DATA_RECEIPT"
    | "MAP_COUNTY_NAME"
    | "MOVE_GRANTEE"
    | "MOVE_GRANTOR"
    | "OVERRIDE_INDEXING_DATA"
    | "PARTY_CHARACTERS_TO_SPACE"
    | "PARTY_CHARACTERS_TO_STRIP"
    | "REFERENCE_ENTRY_CHARACTERS_TO_STRIP"
    | "REFERENCE_BOOK_CHARACTERS_TO_STRIP"
    | "REFERENCE_PAGE_CHARACTERS_TO_STRIP"
    //JSON customizations (special case of String)
    | "INJECT_DOCPROP"
    //String[] customizations
    | "ALTER_CREATED_RECORDABLE_DOCUMENT"
    | "ALTER_MASTER_DOCUMENT_DATA"
    | "INJECT_PACKAGE_PROPERTIES"
    | "MASTER_DOCUMENT_DATA_MASSAGER"
    | "PACKAGE_PROPERTIES_TO_EXPOSE"
    | "TRACK_DOCUMENT_PROPERTY_HISTORY"
    | "TRACK_IN_PACKAGE_PROPERTIES";

/**
 * Corresponds to com.simplifile.api.model.ApiCustomizationRuleConditional.ConditionalType
 */
export type ConditionalType = "FIELD" | "OPERATION";

/**
 * Corresponds to com.simplifile.api.model.ApiCustomizationRuleConditional.OperationType
 */
export type OperationType =
    | "IS"
    | "IN"
    | "MATCHES"
    | "GREATER_THAN"
    | "LESS_THAN"
    | "IN_RANGE";

/**
 * Corresponds to com.simplifile.api.model.ApiCustomizationRuleParameters.Field
 */
export type Field =
    | "API_KEY"
    | "RECIPIENT_STATE"
    | "RECIPIENT_ID"
    | "SUBMITTER_ID"
    | "RECIPIENT_KOI"
    | "SUBMITTER_KOI"
    | "SUBMITTER_PACKAGE_ID"
    | "PARTY"
    | "PAGE_COUNT"
    | "CUSTOM_STRINGS"
    | "CUSTOM_VALUE"
    | "CUSTOM_OBJECT";

/**
 * Corresponds to com.simplifile.api.model.ApiCustomizationRuleConditional
 */
export type ApiCustomizationRuleConditional = {
    conditionalType?: ConditionalType;
    not?: boolean;
    field?: Field;
    operationType?: OperationType;
    regex?: string;
    value?: string;
    values?: string[];
    customValueKey?: string;
    customObjectPath?: string;
    booleanOperatorType?: BooleanOperatorType;
    conditionals?: ApiCustomizationRuleConditional[];
};

/**
 * Corresponds to com.simplifile.api.model.ApiCustomizationRule
 */
export type ApiCustomizationRule = {
    // see com.simplifile.api.model.ApiCustomizationOption for valid values
    apiCustomizationOption: string;
    conditionals: ApiCustomizationRuleConditional[];
    settingValue?: any;
    overridePriority?: number;
    note?: string;
};

/**
 * Corresponds to com.simplifile.api.model.ApiCustomization
 */
export type ApiCustomization = {
    // see com.simplifile.api.model.ApiCustomizationOption for valid values
    customizationOption?: string;
    rules: ApiCustomizationRule[];
    behaviorDescription?: string;
    prereqDescription?: string;
    id: string;
    version?: number;
    _timestamp?: string;
    _userID?: string;
};

export interface ApiCustomizationHistoryItem extends ApiHistoryItem {
    selected: boolean;
}

export const makeApiCustomizationStub: (id?: string) => ApiCustomization = (
    id: string = ""
) => ({
    id,
    customizationOption: id,
    rules: [
        {
            apiCustomizationOption: id,
            conditionals: [
                {
                    field: "SUBMITTER_ID",
                    operationType: "IS",
                    regex: "",
                    value: "",
                    values: [""]
                },
                {
                    field: "RECIPIENT_ID",
                    operationType: "IS",
                    regex: "",
                    value: "",
                    values: [""]
                }
            ],
            settingValue: ""
        }
    ]
});
