<ng-container *ngIf="recipients$ | async as recipients">
    <form [formGroup]="selectorForm">
        <sf-select
            #selector
            aria-label="recipient organization"
            formControlName="selector"
            [isSingleSelect]="true"
            placeholder="-- Select a County --"
            [options]="recipients"
            (select)="selectRecipient($event.$selection, allowPaper)"
            groupBy="group"
            trackBy="id"
            labelBy="name"
            [hasFilter]="true"
            [maxPopupHeight]="540"
            [minPopupWidth]="285"
            [ngbTooltip]="tooltip"
            tooltipClass="tooltip--dark-bottom"
            container="body"
            [hasClearButton]="hasClearButton"
            (filter)="filterRecipients($event.$searchValue)"
            [filterDebounce]="250"
            [handleFilterExternally]="true"
            disableBy="notAllowed"
            filterPlaceholder="-- Begin typing to find a county --"
            [isDisabled]="isDisabled"
        ></sf-select>
    </form>
</ng-container>
