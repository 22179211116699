<input id="filter"
       type="text"
       class="filter form-control"
       [ngModel]="filterOptions.filterText"
       (ngModelChange)="onFilterChanged($event)"
       [placeholder]="placeHolder">
<label for="filter" class="filter-icon" [attr.aria-label]="placeHolder">
    <fa-icon *ngIf="!filterOptions.filterText"
             [icon]="['far', 'filter']"
             [fixedWidth]="true"
    ></fa-icon>
    <button class="btn btn-link p-0"
            *ngIf="filterOptions.filterText"
            (click)="onFilterChanged('')"
            aria-label="Clear Filter">
        <fa-icon [icon]="['far', 'times']"
                 [fixedWidth]="true"
        ></fa-icon>
    </button>
</label>
