import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    Injector,
    OnDestroy,
    OnInit,
    Renderer2,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FieldState } from "../../interfaces";
import { COMPONENT_FROM_FIELD } from "../../helpers/fields.helpers";
import { BehaviorSubject, interval, Observable, Subject } from "rxjs";
import {
    filter,
    pluck,
    scan,
    startWith,
    take,
    takeUntil,
    takeWhile
} from "rxjs/operators";
import { InputComponent } from "../../components/input/input.component";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { FormControl } from "@angular/forms";
import { RemoveRowButtonComponent } from "./remove-row-button/remove-row-button.component";

@Component({
    selector: "sf-sortable-field",
    templateUrl: "./sortable-field.component.html",
    styleUrls: ["./sortable-field.component.scss"]
})
export class SortableFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    private _destroy$ = new Subject<void>();

    collapsedMessageDisplay: string = "";
    expanded = true;

    @ViewChild("rowHost", { read: ViewContainerRef })
    rowHost: ViewContainerRef;
    fields$: Observable<FieldState[]>;

    disabled$: Observable<boolean>;
    columnHeaders: any[];

    repeatableFields = new BehaviorSubject<any>(undefined);

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore,
        private _injector: Injector,
        private _renderer: Renderer2,
        private _app: ApplicationRef
    ) {
        super(_facade);
    }

    ngOnInit() {
        super.ngOnInit();

        this.disabled$ = this.formControl$.pipe(
            takeUntil(this._destroy$),
            filter((control) => !!control),
            pluck("disabled"),
            startWith(true)
        );

        this.columnHeaders = (this.field as any).repeatableFields.fields.map(
            (field: FieldState) => {
                return { label: field.label, path: field.path };
            }
        );
    }

    ngAfterViewInit() {
        this._formFacade
            .getFieldsForArray(this.field.fullPath)
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.id),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) => {
                this.repeatableFields.next(current);
                this.updateCurrentRows(current, previous);
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
    }

    updateCurrentRows(currentRowControls: FieldState[], previous: string[]) {
        let nextIndex = 0;
        let currentFields = [];
        let rows = this.rowHost.element.nativeElement.rows;

        if (
            currentRowControls.length !== 0 &&
            rows.length < currentRowControls.length
        ) {
            this._createRows(currentRowControls.length - rows.length);
        }

        for (let field of currentRowControls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                this.resolveFieldsForRow(field, nextIndex);
            }
            nextIndex++;
        }

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this._deleteRow(index);
                previous.splice(index, 1);
            }
        }
    }

    addRow() {
        this._formFacade.addFieldToArray(this.field.fullPath, this.controlPath);
    }

    removeRow() {
        this._facade.removeFieldFromArray(
            this.field.fullPath,
            this.controlPath
        );
    }

    sortOrderChange(event: any) {
        const movedField = this.repeatableFields.getValue()[event.old];
        this._facade.updateFieldPositionInArray(
            movedField.fullPath,
            movedField.fullPath,
            event.new
        );
    }

    // Sortable Row Stuff

    resolveFieldsForRow(field: FieldState, rowIndex: number) {
        if (field && field.fullPath) {
            this._facade
                .getVisibleFieldsForField(field.fullPath)
                .pipe(
                    scan(
                        (previous, curr) => [
                            previous[1].map((field) => field.id),
                            curr
                        ],
                        [[], []]
                    ),
                    takeUntil(this._destroy$)
                )
                .subscribe(([previous, current]) =>
                    this.loadComponentsForRow(
                        current,
                        previous,
                        rowIndex,
                        field
                    )
                );

            this._facade
                .getViewStateForField(field.fullPath)
                .pipe(
                    takeUntil(this._destroy$),
                    takeWhile((val) => val !== undefined)
                )
                .subscribe((viewState) => {
                    this.expanded = viewState.expanded;
                    this._setDisplayMessage();
                });

            this._setDisplayMessage();
        }
    }

    private _setDisplayMessage() {
        if (!this.expanded) {
            let subs = this.formControl$
                .pipe(take(1))
                .subscribe((control: FormControl) => {
                    let collapsedMessage: string | string[];
                    if (typeof this.field.collapsedMessage === "function") {
                        collapsedMessage = this.field.collapsedMessage(control);
                        this.collapsedMessageDisplay = !Array.isArray(
                            collapsedMessage
                        )
                            ? collapsedMessage
                            : collapsedMessage.join("");
                    } else {
                        collapsedMessage = this.field.collapsedMessage;
                        this.collapsedMessageDisplay = !Array.isArray(
                            collapsedMessage
                        )
                            ? collapsedMessage
                            : collapsedMessage.join("");
                    }
                });

            subs.unsubscribe();
        }
    }

    loadComponentsForRow(
        controls: FieldState[],
        previous: string[],
        rowIndex: number,
        parentField: FieldState
    ) {
        let nextIndex = 0;
        let currentFieldIDs = [];
        let currentFields = [];
        let fieldsToAdd: { field: FieldState; index: number }[] = [];

        let row = this._getRow(rowIndex);

        // Find non-rendered controls
        for (let field of controls) {
            currentFieldIDs.push(field.id);
            currentFields.push(field);
            if (!previous.includes(field.id)) {
                fieldsToAdd.push({ field: field, index: nextIndex });
            }
            nextIndex++;
        }

        // Already rendered controls do we need to remove them?
        for (let visibleControl of [...previous]) {
            if (!currentFieldIDs.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                // this.rowHost.remove(index);
                this._removeComponentCellFromRow(row, visibleControl);
                previous.splice(index, 1);
            }
        }

        if (fieldsToAdd.length !== 0) {
            this._removeBlankCells(row);
        }

        // Make sure each cell gets a component or a blank cell for the row we are rendering
        if (fieldsToAdd.length > 0) {
            for (let i = 0; i < this.columnHeaders.length; ++i) {
                const currentComponent = currentFields.find(
                    (f) =>
                        f.path.toLowerCase() ===
                        this.columnHeaders[i].path.toLowerCase()
                );
                const columnCurrentComponentIndex =
                    currentFields.indexOf(currentComponent);

                // Fields to Add
                const columnComponent = fieldsToAdd.find(
                    (f) =>
                        f.field.path.toLowerCase() ===
                        this.columnHeaders[i].path.toLowerCase()
                );
                const columnComponentIndex =
                    fieldsToAdd.indexOf(columnComponent);

                if (columnComponent) {
                    this._createNewComponent(
                        fieldsToAdd[columnComponentIndex].field,
                        fieldsToAdd[columnComponentIndex].index,
                        row
                    );
                } else {
                    if (!currentComponent) {
                        this._createBlankCell(
                            row,
                            columnComponentIndex > -1
                                ? fieldsToAdd[columnComponentIndex].field
                                : null
                        );
                    }
                }
            }
            this._addRemoveButton(row, parentField);
        }
    }

    private _createNewComponent(
        field: FieldState,
        index: number,
        row: HTMLElement
    ) {
        if (field && !row.querySelector(`#${field.id}`)) {
            const componentType = this._getComponentFromField(field);
            const componentFactory =
                this._componentFactoryResolver.resolveComponentFactory(
                    componentType
                );

            let col = document.createElement("td");
            col.id = field.id.replace(",", "-").replace(".", "-");

            const component = componentFactory.create(this._injector, [], col);
            const instance = component.instance;

            instance.field = field;
            instance.controlPath = field.fullPath;
            instance.field.label = null;

            this._app.attachView(component.hostView);
            this._renderer.appendChild(row, col);
        }
    }

    private _createRows(count: Number) {
        for (let i = 0; i < count; i++) {
            let row = document.createElement("tr");
            this._renderer.appendChild(this.rowHost.element.nativeElement, row);
        }
    }

    private _deleteRow(index: number) {
        this.rowHost.element.nativeElement.deleteRow(index);
    }

    private _getRow(index: number): HTMLElement {
        return this.rowHost.element.nativeElement.rows[index];
    }

    private _createBlankCell(row: HTMLElement, field: FieldState) {
        if (!field || !row.querySelector(this._getFieldHtmlID(field.id))) {
            let blankCell = document.createElement("td");
            blankCell.classList.add("blank");
            row.append(blankCell);
        }
    }

    private _removeComponentCellFromRow(row: HTMLElement, id: string) {
        if (!row) {
            return;
        }
        this._removeBlankCells(row);
        const componentCell = row.querySelectorAll(this._getFieldHtmlID(id));
        for (let i = 0; i < componentCell.length; ++i) {
            row.removeChild(componentCell[i]);
        }
    }

    private _removeBlankCells(row: HTMLElement) {
        if (row) {
            const blanks = row.querySelectorAll(".blank");
            for (let i = 0; i < blanks.length; ++i) {
                row.removeChild(blanks[i]);
            }
        }
    }

    private _getFieldHtmlID(id: string) {
        return `#${id.replace(",", "-").replace(".", "-")}`;
    }

    private _addRemoveButton(row: HTMLElement, parentField: FieldState) {
        const alreadyAddedRemoveButton =
            !!row.querySelectorAll(".remove-row").length;

        if (!alreadyAddedRemoveButton) {
            let removeCol = document.createElement("td");

            const faComponentFactory =
                this._componentFactoryResolver.resolveComponentFactory(
                    RemoveRowButtonComponent
                );

            const ref = faComponentFactory.create(
                this._injector,
                [],
                removeCol
            );

            ref.instance.fullPath = parentField.fullPath;
            ref.instance.remove
                .pipe(takeUntil(this._destroy$))
                .subscribe((fullPath: string) => {
                    this._facade.removeFieldFromArray(fullPath, fullPath);
                });

            this._app.attachView(ref.hostView);

            removeCol.classList.add("remove-row");
            removeCol.classList.add("blank");

            row.append(removeCol);
        }
    }
}
