import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { OrganizationService } from "@sf/userorg/common";
import { Observable } from "rxjs";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class AdminOrganizationService {
    constructor(
        protected _rpc: RpcClientService,
        private organizationService: OrganizationService
    ) {
    }

    reloadOrganizations(orgIDs: string[]): Observable<any> {
        return this._rpc.makeRequest("admin", "reloadOrganizations", {
            orgIDs: orgIDs
        });
    }

    getOrganizationsByUser(userId: string): Observable<any[]> {
        return this._rpc.makeRequest("admin", "getOrganizationsByUser", {
            userID: userId
        });
    }

    checkForBillableEvent(org: any) {
        let hasSubmitter = this.organizationService.orgHasDisplayableService(org, "submitter");
        if (hasSubmitter && !org.lastSubmissionDate) {
            org.missingBillableEvent = true;
        }
    }
}
