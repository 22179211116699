import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    SecurityContext,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
    BaseModalComponent,
    dayjs,
    Icons,
    IconsService,
    MetricLoggerService,
    PersistedMemoryService,
    SessionService,
    TemplateRendererComponent
} from "@sf/common";
import { PackageHistoryService } from "./services/package-history.service";
import {
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap
} from "rxjs/operators";
import { combineLatest, Observable, Subject } from "rxjs";
import { ColDef, ColumnApi, GridApi } from "@ag-grid-community/core";
import { PackageHistoryEvent } from "./interfaces/package-history-event";
import { PackageHistoryPackage } from "./interfaces/package-history-package";
import { PackageHistoryDocument } from "./interfaces/package-history-document";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as FileSaver from "file-saver";
import { DomSanitizer } from "@angular/platform-browser";

const views = {
    TABLE: "table",
    CARD: "card"
};

interface PackageHistoryItem {
    group?: string;
    name: string;
    Before: string;
    After: string;
}

interface PackageHistoryEntry {
    user: string;
    date: string;
    history: PackageHistoryItem[];
}

@Component({
    selector: "sf-package-history",
    templateUrl: "./package-history.component.html",
    styleUrls: ["./package-history.component.scss"]
})
export class PackageHistoryComponent implements OnInit, OnDestroy {
    constructor(
        private _fb: FormBuilder,
        private _sessionService: SessionService,
        private _persistedMemoryService: PersistedMemoryService,
        private _iconsService: IconsService,
        private _packageService: PackageHistoryService,
        private _ngbModal: NgbModal,
        private _metricLoggerService: MetricLoggerService,
        private _sanitizer: DomSanitizer
    ) {}

    @Input() packageID: string;
    @Input() initialDocumentID?: string;

    @ViewChild("dateTemplate")
    dateTemplate: TemplateRef<any>;
    @ViewChild("detailsTemplate", { static: false })
    detailsTemplate: TemplateRef<any>;
    @ViewChild("dataEntryChangesTemplate", { static: false })
    dataEntryChangesTemplate: TemplateRef<any>;

    icons: Icons = {};

    userID = this._sessionService.getUserID();
    private key = `sf-shared-package-history-view-type-${this.userID}`;

    private _initialView =
        this._persistedMemoryService.get(this.key) || views.TABLE;
    displayForm = this._fb.group({
        document: [],
        view: [this._initialView]
    });

    package$: Observable<PackageHistoryPackage>;
    documentSelectionList$: Observable<
        { id: string; text: string; eSignDocumentID?: string }[]
    >;
    private _documentSelectionList: {
        id: string;
        text: string;
        eSignDocumentID?: string;
    }[];
    dataEntryChange: PackageHistoryEntry;
    historyList$: Observable<PackageHistoryEvent[]>;
    document$: Observable<PackageHistoryDocument>;
    private _downloadPdfFn: () => Observable<any>;
    private _ngOnDestroy$ = new Subject<void>();
    private _viewChanges$ = this.displayForm
        .get("view")
        .valueChanges.pipe(
            takeUntil(this._ngOnDestroy$),
            startWith(this._initialView)
        );
    tableView$ = this._viewChanges$.pipe(
        map((view) => {
            return view === views.TABLE;
        })
    );
    cardView$ = this._viewChanges$.pipe(
        map((view) => {
            return view === views.CARD;
        })
    );

    private _gridApi: GridApi;

    ngOnInit() {
        this.icons.windowMaximize = this._iconsService.get("windowMaximize");
        this.icons.thList = this._iconsService.get("thList");
        this.icons.download = this._iconsService.get("download");

        this.displayForm
            .get("view")
            .valueChanges.pipe(takeUntil(this._ngOnDestroy$))
            .subscribe((value) => {
                this._persistedMemoryService.set(this.key, value);
            });

        this.package$ = this._packageService
            .getPackageUpdates$(this.packageID)
            .pipe(
                takeUntil(this._ngOnDestroy$),
                map((pkg) => {
                    const allHelperDocuments = pkg.documents.flatMap((doc) => {
                        return doc.helpers.map((helper) => {
                            return {
                                ...helper,
                                parent: doc,
                                isHelper: true
                            };
                        });
                    });
                    pkg.flatDocuments = [
                        ...pkg.documents,
                        ...allHelperDocuments
                    ];
                    return pkg;
                }),
                shareReplay(1)
            );

        this.documentSelectionList$ = this.package$.pipe(
            map((pkg) => {
                return this._generateDocumentSelectionList(pkg.documents);
            }),
            tap((documentSelectionList) => {
                this._documentSelectionList = documentSelectionList;
                this.displayForm.patchValue({
                    document:
                        this.initialDocumentID || documentSelectionList[0].id
                });
            })
        );

        const _selection$ = this.displayForm
            .get("document")
            .valueChanges.pipe(takeUntil(this._ngOnDestroy$));
        const _updates$ = combineLatest([_selection$, this.package$]);

        this.document$ = _updates$.pipe(
            map(([selectionID, pkg]) => {
                return pkg.flatDocuments.find(
                    (document: PackageHistoryDocument) => {
                        return document.id === selectionID;
                    }
                ) as PackageHistoryDocument;
            })
        );

        this.historyList$ = _updates$.pipe(
            switchMap(([selectionID, pkg]) => {
                if (selectionID === "all") {
                    this._downloadPdfFn = () =>
                        this._packageService.downloadPackageHistory(
                            pkg.id,
                            true
                        );
                    return this._packageService.getPackageHistory(pkg.id, true);
                } else if (selectionID === "package") {
                    this._downloadPdfFn = () =>
                        this._packageService.downloadPackageHistory(
                            pkg.id,
                            false
                        );
                    return this._packageService.getPackageHistory(
                        pkg.id,
                        false
                    );
                } else {
                    this._downloadPdfFn = () =>
                        this._packageService.downloadDocumentHistory(
                            selectionID
                        );
                    return this._packageService.getDocumentHistory(selectionID);
                }
            }),
            map(this._formatHistoryList.bind(this))
        );
    }

    ngOnDestroy() {
        this._ngOnDestroy$.next();
    }

    csvExport() {
        this._metricLoggerService.recordMetric(
            "package_history",
            "csv_export_clicked",
            1
        );
        this._gridApi.exportDataAsCsv({
            fileName: "package-history.csv"
        });
    }

    private _toBlob(b64String: any): Blob {
        const byteCharacters = atob(b64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: "application/pdf" });
    }

    pdfExport() {
        this._metricLoggerService.recordMetric(
            "package_history",
            "pdf_export_clicked",
            1
        );
        this._downloadPdfFn().subscribe((response) => {
            FileSaver.saveAs(this._toBlob(response), "package-history.pdf");
        });
    }

    private _formatHistoryList(historyList: PackageHistoryEvent[]) {
        return historyList.map((item) => {
            item.formattedDate = this._formatDate(item.date);
            if (item.event === "DataEntry") {
                let match = item.details.match(/@@(\d+)@@/);
                item.dataEntryVersion = match[1];
                // remove version string from details
                item.details = item.details.replace(match[0], "");
            }
            return item;
        });
    }

    private _formatDate(date: string) {
        return dayjs(date)
            .tz(dayjs.tz.guess())
            .format("ddd MM/DD/YY hh:mm:ss A z");
    }

    private _generateDocumentSelectionList(
        documents: PackageHistoryDocument[]
    ): { id: string; text: string }[] {
        let list: { id: string; text: string }[];
        list = documents.flatMap((doc) => {
            let helperDocItems = doc.helpers.map((helperDoc) => {
                return {
                    id: helperDoc.id,
                    text: `${doc.name} - Helper Document - ${helperDoc.kindOfInstrument}`
                };
            });
            const docItem = {
                id: doc.id,
                text: doc.name,
                eSignDocumentID: doc.eSignDocumentID
            };
            return [docItem, ...helperDocItems];
        });
        list.unshift({ id: "all", text: "All Events" });
        list.unshift({ id: "package", text: "Package Only" });
        return list;
    }

    private _defineColumns(): ColDef[] {
        return [
            {
                headerName: "Event",
                field: "event",
                lockVisible: true,
                sortable: true,
                resizable: true,
                flex: 1
            },
            {
                headerName: "Date",
                field: "date",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.dateTemplate
                },
                sort: "asc",
                lockVisible: true,
                sortable: true,
                resizable: true,
                width: 240,
                maxWidth: 300
            },
            {
                headerName: "User",
                field: "user",
                cellRenderer: ({ value }: { value: string }): string => {
                    const sanitizedValue: string = this._sanitizer.sanitize(
                        SecurityContext.HTML,
                        value
                    );
                    return `<span class="sf-mask" title="${sanitizedValue}">${sanitizedValue}</span>`;
                },
                cellStyle: { "white-space": "normal" },
                lockVisible: true,
                sortable: true,
                resizable: true,
                flex: 1
            },
            {
                headerName: "Organization",
                field: "organization",
                cellStyle: { "white-space": "normal" },
                lockVisible: true,
                sortable: true,
                resizable: true,
                flex: 1
            },
            {
                headerName: "Details",
                field: "details",
                cellStyle: { "white-space": "normal" },
                lockVisible: true,
                sortable: true,
                resizable: true,
                flex: 3,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.detailsTemplate
                }
            }
        ];
    }

    onGridSizeChanged() {
        this._gridApi.sizeColumnsToFit();
    }

    onGridReady($event: { api: GridApi; columnApi: ColumnApi }) {
        this._gridApi = $event.api;
        this._gridApi.setColumnDefs(this._defineColumns());
    }

    showDataEntryChanges(item: any) {
        let document = this._documentSelectionList.find(
            (selection) => selection.id === item.id
        );
        this._packageService
            .getTemplateDataHistory(
                document.eSignDocumentID,
                item.dataEntryVersion
            )
            .pipe(map((history) => this._getHistoryItems(history)))
            .subscribe((history) => {
                this.dataEntryChange = {
                    user: item.user,
                    date: item.formattedDate,
                    history: history
                };

                let modal = this._ngbModal.open(BaseModalComponent, {
                    size: "lg"
                }).componentInstance;
                modal.unmaskModal = true;
                modal.title = "Data Entry Modification";
                modal.template = this.dataEntryChangesTemplate;
                modal.primary = {
                    text: "Close",
                    callback: () => true
                };
            });
    }

    _getHistoryItems(templateDataHistory: any) {
        let history: PackageHistoryItem[] = [];
        Object.keys(templateDataHistory)
            .sort((a, b) => (a === "Main" ? -1 : a.localeCompare(b)))
            .forEach((key) => {
                let group = key;
                let first = true;
                templateDataHistory[key].forEach((subKey: any) => {
                    Object.keys(subKey).forEach((key) => {
                        if (first)
                            history.push({
                                group: group,
                                name: key,
                                Before: subKey[key].Before,
                                After: subKey[key].After
                            });
                        else
                            history.push({
                                name: key,
                                Before: subKey[key].Before,
                                After: subKey[key].After
                            });
                        first = false;
                    });
                });
            });
        return history;
    }
}
