import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class QueryStringService {
    constructor() {}

    getQueryParam(param: string): string {
        const params: Map<string, string> = new Map();
        window.location.search
            .substr(1)
            .split("&")
            .forEach((pair: any) => {
                let key, value;
                [key, value] = pair.split("=");
                params.set(key, value);
            });
        let found = null;
        if (params.has(param)) {
            found = params.get(param);
        }
        return found;
    }
}
