import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { RoleService, UserOrgService } from "@sf/userorg/common";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { SortUtilitiesService } from "@sf/common";
import { SelectableRole, UserRole } from "@sf/userorg/common";
import { RecentRolesService } from "../../services/recent-roles.service";

// prettier-ignore
@Component({
    selector: "sf-role-search-selector",
    templateUrl: "./role-search-selector.component.html",
    styleUrls: ["./role-search-selector.component.scss"]
})
export class RoleSearchSelectorComponent implements OnInit, OnChanges, OnDestroy {
    /* Inputs */
    @Input() selectedRoleID: string; // (optional) (single select) roleID initially selected
    @Input() autoInitialSelect: boolean; // initially select an item - determined by selectedRoleID, else first item in list
    @Input() searchLimit: number; // (optional - default is 25) max number of roles to show in list
    @Input() canClearSelectedRole: boolean; // whether to show an 'X' to clear selection
    @Input() hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input() required: boolean = null; // if required in form
    @Input() placeholder: string; // (optional) placeholder - will be defaulted
    @Input() windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'

    /* Outputs */
    @Output() select: EventEmitter<any> = new EventEmitter<any>(); // passes a Role

    /** PRIVATE **/
    private _onDestroy: Subject<void> = new Subject<void>();

    /** PUBLIC **/
    roles: UserRole[] = [];
    selectedRole: UserRole = null;
    selectableItems: SelectableRole[] = [];

    constructor(
            private _userOrgService: UserOrgService,
            private roleService: RoleService,
            private recentRolesService: RecentRolesService,
            private _router: Router,
            private _activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = "Select Role...";
        }
        if (!this.windowClass) {
            this.windowClass = "not-full-width";
        }
        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        this.populateRoles({}).subscribe(() => {
            this.selectSelectedItem();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedRoleID && changes.selectedRoleID.currentValue
                && changes.selectedRoleID.currentValue !== changes.selectedRoleID.previousValue) {
            //do nothing
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getRoles(event?: any): Subscription {
        return this.populateRoles(event).subscribe(() => {
            // nothing
        });
    }

    selectSelectedItem() {
        // figure out which role should be initially selected
        let currentItem: SelectableRole = null;
        if (this.selectedRoleID) {
            currentItem = this.selectableItems.find((role) => {
                return role.id === this.selectedRoleID;
            });
        }

        if (!currentItem && this.selectedRoleID) {
            // we get here if the role with the selected ID is not in the list of selectable roles,
            // so get the role and add it to the list
            this.roleService.getRole(this.selectedRoleID)
                .subscribe((results: UserRole) => {
                    if (!this.roles) {
                        this.roles = [results];
                    } else {
                        this.roles.splice(0, 0, results);
                    }
                    // need new array here
                    let selectableRole = this._makeSelectableRole(results);
                    let newSelectionList = [selectableRole]; // need to create new array for watchers
                    this.selectableItems = newSelectionList.concat(this.selectableItems);
                    this.handleRoleSelection({
                        $selection: selectableRole
                    });
                });
        } else if (this.autoInitialSelect && this.roles.length > 0) {
            if (this.selectedRoleID) {
                // select the role with the selected ID
                this.handleRoleSelection({
                    $selection: {
                        id: this.selectedRoleID,
                        label: null
                    }
                });
            } else {
                // select the first role in the list
                this.handleRoleSelection({
                    $selection: {
                        id: this.selectableItems[0].id,
                        label: this.selectableItems[0].label
                    }
                });
            }
        } else if (this.selectedRoleID) {
            // normal case - if we have a selected ID, select that one
            this.handleRoleSelection({
                $selection: {
                    id: this.selectedRoleID,
                    label: null
                }
            });
        } else {
            // this.selectedRoleID is not set, but never happens
        }
    }

    populateRoles(event?: any): Observable<UserRole[]> {
        let searchValue = event.$searchValue;

        // if role entered search criteria
        if (searchValue !== null && searchValue !== "" && searchValue !== undefined) {
            // do a search
            return this.roleService.search(searchValue, this.searchLimit)
                       .pipe(tap((results: UserRole[]) => {
                //this.roles = this._sortRoles(results);
                this.roles = results;
                this.selectableItems = this.roles.map(this._makeSelectableRole, this);

                if (this.selectedRole) {
                    let foundSelection = this.selectableItems.find((role) => {
                        return role.id === this.selectedRole.id;
                    });
                    if (!foundSelection) {
                        this.selectableItems.push(this._makeSelectableRole(this.selectedRole));
                    }
                }
            }));
        }

        //use most recently accessed roles
        return this.recentRolesService.getPastRecentRoles().pipe(tap((results: UserRole[]) => {
            //this.roles = this._sortRoles(results);
            this.roles = results.filter((result) => { return !!result; });
            this.selectableItems = this.roles.map(this._makeSelectableRole, this);
        }));
    }

    private _sortRoles(unsorted: UserRole[]): UserRole[] {
        let sorted: UserRole[] = unsorted.sort((role1, role2) => {
            return SortUtilitiesService.stringSortCompareInsensitive(role1.name, role2.name);
        });
        return sorted;
    }

    handleRoleSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            let selection: SelectableRole = selectionObject;
            let role: UserRole = null;
            if (selection && selection.id) {
                role = this.findRoleInList(selection.id);
            }
            this.selectedRole = role; // may be null, which is fine
            if (this.select) {
                this.select.emit(role);
            }
        }
    }

    private findRoleInList(roleID: string): UserRole {
        let role: UserRole = this.roles.find((localRole) => {
            return localRole.id === roleID;
        });
        return role;
    }

    private _makeSelectableRole(u: UserRole): SelectableRole {
        return {
            id: u.id,
            label: u.name + " (" + u.id + ")",
            selected: false
        };
    }
}
