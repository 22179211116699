<div class="sf-admin-universal-search-new">
    <sf-global-search
            [results]="results"
            ariaLabel="Search"
            placeholder="Search..."
            [debounceTime]="500"
            [busy]="busy"
            (onQueryChange)="onQueryChange($event)"
            (onExpandResults)="onExpandResults($event)"
            (onGroupSelect)="onGroupSelect($event)"
            [closeOnSelect]="true"
            [showExpandMore]="true"
            [searchClickHandler]="onSearchClick.bind(this)"
    >
        <sf-universal-search-result *sfGlobalSearchResult="let result" [result]="result" [showingRecentItems]="showingRecentItems" [query]="query"></sf-universal-search-result>
    </sf-global-search>
</div>
