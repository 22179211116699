<a
    [href]="videoLink"
    target="_blank"
    [ngbTooltip]="videoTooltip"
    aria-label="Introduction to this page"
    container="body"
    placement="bottom"
>
    <fa-icon icon="video" [fixedWidth]="true" class="p-1" ></fa-icon>
</a>
