import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {
    ActiveService,
    ApiTokenService,
    ConfirmationModalComponent,
    CustomColumnsService,
    EncryptionService,
    GrowlService,
    SelectableItem,
    SessionService,
    SortUtilitiesService,
    SpinnerService
} from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Organization } from "../../interfaces/organization.interface";
import { User } from "../../interfaces/user.interface";
import { OrganizationService } from "../../services/organization.service";
import { OrganizationSubscriptionService } from "../../services/organization-subscription.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { sampleTime, takeUntil } from "rxjs/operators";

interface TokenUser extends User {
    apiToken: boolean;
    selected?: boolean;
    pending?: boolean;
    pendingAccept?: boolean;
    emailShared?: boolean;
    apiTokenURLEncoded?: boolean;
    tokenSort?: number;
}

interface QueryThing {
    text: string;
}

// prettier-ignore
@Component({
    selector: "sf-organization-api-tokens",
    templateUrl: "./organization-api-tokens.component.html",
    styleUrls: ["./organization-api-tokens.component.scss"]
})
export class OrganizationApiTokensComponent implements OnInit, OnChanges, OnDestroy {
    @Input() hideVendors: any;
    @Input() vendorsOnly: any;
    @Input() orgID: string;
    @Input() userURL: string;
    @Input() apiKey: string;

    @ViewChild("grid") theGrid: ElementRef;
    @ViewChildren("nameCol") nameColumns: QueryList<ElementRef>;
    @ViewChildren("otherCol") otherColumns: QueryList<ElementRef>;

    @HostListener("window:resize", ["$event"])
    onResize(event: Event) {
        this.scrollbarWidth = this.customColumnsService.getScrollbarWidth();
        this.adjustColumnWidths();
    }

    private _ngOnDestroy$: Subject<boolean> = new Subject<boolean>();
    private updater: Subscription = null;

    /** Public Variables **/
    showResults = false;
    allSelected = false;
    selectedOrganization: Organization = null;
    allowEdit = false;
    //editWarningShown = false;
    sortKey = "name";
    sortAscending = true;
    query: QueryThing = null;
    filterDelay: number = null;
    loaded = false;

    canEdit = false;
    isSuperUser = false;
    isImpersonating = false;
    canLinkToUsers = false;
    canExport = false;
    scrollbarWidth = 20;

    usersWithTokens: TokenUser[] = [];
    sortedList: TokenUser[] = [];
    apiServiceEnabled = false;
    allDefinitionKeys: SelectableItem[] = null;
    capcApiServices = [
        "capc_submitter_api",
        "capc_lender_api",
        "vendor_lender_integration",
        "vendor_settlement_integration"
    ];
    submitterApiServices = [
        "submitter_api",
        "recipient_api",
        "capc_submitter_api",
        "vendor_settlement_integration",
        "recipient_vendor_api"
    ];
    eeApiServices = [
        "vendor_esign_events_integration",
    ];
    sharedApiServices = [
        "notary",
    ];
    vendorApiServices = [
        //"internal_erecord_plus_facilitator",
        "esign_events_api_integration", //
        "internal_api_integration", //
        "notary_api_integration",   //
        "vendor_api_integration",   //
        "recipient_vendor_api_integration", //
        "submitter_vendor_api_integration"  //
    ];

    constructor(
            private customColumnsService: CustomColumnsService,
            private sessionService: SessionService,
            private growlService: GrowlService,
            private spinnerService: SpinnerService,
            private modalService: NgbModal,
            private apiTokenService: ApiTokenService,
            private organizationService: OrganizationService,
            private router: Router,
            private route: ActivatedRoute,
            private encryptionService: EncryptionService,
            private organizationSubscriptionService: OrganizationSubscriptionService
    ) {}

    ngOnInit(): void {
        this.loaded = false;
        this.query = {
            text: null
        };
        this.allSelected = false;

        this.apiServiceEnabled = false;
        this.allDefinitionKeys = null;
        this.isSuperUser = this.sessionService.isSuperUser();
        this.isImpersonating = this.sessionService.isLoggedInAs();

        if (this.hideVendors === undefined) {
            this.hideVendors = false;
        }
        if (this.vendorsOnly == undefined) {
            this.vendorsOnly = false;
        }

        if (!this.apiKey) {
            this.apiKey = this.getQueryParam("key");
        }
        if (!this.userURL) {
            if (this.isSuperUser) {
                this.userURL = "/admin/user/";
            }
        }

        this.selectOrg();
        this.subscribeToChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.orgID && !changes.orgID.firstChange) {
            this.orgID = changes.orgID.currentValue;
            this.apiKey = null;
            this.selectOrg();
            this.subscribeToChanges();
        }
    }

    ngOnDestroy() {
        this._ngOnDestroy$.next(true);
    }

    subscribeToChanges() {
        if (this.updater) {
            // new organization
            this.updater.unsubscribe();
        }
        this.updater = this.organizationSubscriptionService
                           .subscribeToUpdates(this.orgID)
                           .pipe(takeUntil(this._ngOnDestroy$), sampleTime(5000))
                           .subscribe((msg: any) => {
                               this.selectOrg();
                           });
    }

    getQueryParam(param: string): string {
        let params = new Map();
        window.location.search
            .substr(1)
            .split("&")
            .forEach((pair) => {
                let splitted = pair.split("=");
                let key = splitted[0];
                let value = splitted[1];
                params.set(key, value);
            });
        let found = null;
        if (params.has(param)) {
            found = params.get(param);
        }
        return found;
    }

    findObjectByKey(
        array: ActiveService[],
        key: string,
        value: string
    ): boolean {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === value) {
                return true;
            }
        }
        return false;
    }

    hasApplicableApiService(org: Organization): boolean {
        let url = this.router.url;
        let found: any = null;

        if (!found && url.indexOf("vendor") < 0) {
            // shared (but not for vendors)
            found = this.sharedApiServices.find((service) => {
            if (this.findObjectByKey(org.activeServices, "id", service))
                return true;
            });
        }
        if (!found && url.indexOf("submitter") > 0 || url.indexOf("recipient") > 0) {
            found = this.submitterApiServices.find((service) => {
                if (this.findObjectByKey(org.activeServices, "id", service))
                    return true;
            });
        }
        if (!found && url.indexOf("lender") > 0 || url.indexOf("settlement") > 0) {
            found = this.capcApiServices.find((service) => {
                if (this.findObjectByKey(org.activeServices, "id", service))
                    return true;
            });
        }
        if (!found && url.indexOf("sign-event") > 0) {
            found = this.eeApiServices.find((service) => {
                if (this.findObjectByKey(org.activeServices, "id", service))
                    return true;
            });
        }
        if (!found && url.indexOf("vendor") > 0) {
            found = this.vendorApiServices.find((service) => {
                if (this.findObjectByKey(org.activeServices, "id", service))
                    return true;
            });
        }
        return !!found;
    }

    selectOrg() {
        let isAdmin = this.sessionService.hasPermission("organization_admin", this.orgID);
        this.allowEdit = this.isSuperUser || isAdmin;
        this.canEdit = this.allowEdit;
        this.canLinkToUsers = this.allowEdit;
        this.canExport = !this.isSuperUser && isAdmin;

        // get org details
        this.organizationService.getOrganization(this.orgID)
            .subscribe((org: Organization) => {
                this.selectedOrganization = org;
                this.apiServiceEnabled = this.hasApplicableApiService(org);
                this.usersWithTokens = [];
                this.deselectAll();

                if (!this.sessionService.hasPermission("organization_api_tokens", this.orgID) &&
                    !this.sessionService.hasPermission("admin_api", "SIMPFL")) {
                    this.growlService.error("You don't have permission to manage API tokens for this organization.");
                    this.canEdit = false;
                    this.loaded = true;
                    return;
                }

                this.apiTokenService.findApiSubscriptionsByOrganizationID(this.orgID)
                    .subscribe((integrations: any) => {
                        if (integrations) {
                            if (this.hideVendors) {
                                this.allDefinitionKeys = integrations.filter((integration: any) => {
                                    return integration.integrationType !== "VENDOR_API";
                                });
                            } else if (this.vendorsOnly) {
                                this.allDefinitionKeys = integrations.filter((integration: any) => {
                                    return integration.integrationType == "VENDOR_API";
                                });
                            } else {
                                this.allDefinitionKeys = integrations;
                            }
                            this.allDefinitionKeys = this.allDefinitionKeys.map((integration: any) => {
                                return {
                                    option: integration.apiKeyID,
                                    label: integration.apiKeyID
                                };
                            });

                            this.selectDefinitionKeyID(this.apiKey);
                        } else {
                            this.loaded = true;
                        }
                    });
            });
    }

    showConfirmDialog(message: string, callback: Function) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.title = "Confirmation";
        modalInstance.message = message;
        modalInstance.primary = {
            text: "Confirm",
            callback: () => {
                modalRef.close();
                callback();
            }
        };
        modalInstance.secondary = {
            text: "Cancel"
        };
    }

    changeSort(field: string) {
        if (field == this.sortKey) {
            // reverse sort order
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortKey = field;
            this.sortAscending = true;
        }
        this.doSort();
    }

    doSort() {
        let keyo = this.sortKey as keyof TokenUser;
        this.sortedList = this.usersWithTokens.sort((a, b) => {
            let aData: any = a[keyo];
            let bData: any = b[keyo];
            let result = 0;
            if (typeof aData == "string" || typeof bData == "string") {
                result = SortUtilitiesService.stringSortCompareInsensitive(aData, bData);
            } else if (typeof aData == "number" || typeof bData == "number") {
                // negatize because we want checked ones at top by default
                result = -SortUtilitiesService.numberSortCompare(aData, bData);
            } else if (typeof aData == "boolean" || typeof bData == "boolean") {
                // negatize because we want checked ones at top by default
                result = -SortUtilitiesService.booleanSortCompare(aData, bData);
            }
            return (this.sortAscending ? result : -result);
        });
        this.sortedList = this.userTokenFilter(this.sortedList, this.query);
        this.adjustColumnWidths();
    }

    // toggles 'all' selected
    selectAll() {
        //this.allSelected = !this.allSelected; // already changed
        this.usersWithTokens.forEach((user) => {
            user.selected = this.allSelected;
        });
    }

    deselectAll() {
        this.usersWithTokens.forEach((user) => {
            user.selected = false;
        });
        this.allSelected = false;
    }

    sendAPITokenRequest(users: TokenUser[], isReset: boolean) {
        let pendingUsers: TokenUser[] = [];
        users.forEach((user) => {
            if (user.pendingAccept) {
                pendingUsers.push(user);
            }
        });
        if (pendingUsers.length) {
            // cancel pending request
            let emails = this.emailsFromUsers(pendingUsers);
            this.apiTokenService
                .cancelPendingTokenRequests(this.apiKey, emails, this.orgID)
                .subscribe((result: any) => {
                    this.reallySendApiTokenRequest(users, isReset);
                });
        } else {
            this.reallySendApiTokenRequest(users, isReset);
        }
    }

    /**
     * Only called from inside sendApiTokenRequest
     */
    reallySendApiTokenRequest(users: TokenUser[], isReset: boolean) {
        let usernames = this.usernamesFromUsers(users);
        this.apiTokenService.sendAPITokenRequestEmail(this.apiKey, usernames, this.orgID, isReset)
            .subscribe((results: any) => {
                this.growlService.success("Request sent successfully.");
                this.loadList();
                this.deselectAll();
                // note that 'results' is screwy here - string or array?
                if (results && results != "Email sent" && results.length > 0) {
                    results.forEach((url: string) => {
                        this.growlService.success(url, "Invitation URL:", { disableTimeOut: true });
                    });
                }
            });
    }

    resetAPIToken(user: TokenUser) {
        if (this.isImpersonating) {
            this.growlService.error("This action is not allowed while impersonating a user.");
            return;
        }
        if (user.emailShared) {
            this.growlService.warning(
                    "This user has an email address that is also assigned to a different user, which prevents sending API tokens.");
            return;
        }
        let message = "Are you sure you want to request a replacement API Token for <b>" + user.name + " (" +
                user.username + ")</b>?";
        message += "<br/><br/>An email will be sent to the user (<b>" + user.email +
                "</b>) with a secure one-time-use link to generate the new API token.";
        this.showConfirmDialog(message, () => {
            this.sendAPITokenRequest([user], true);
        });
    }

    addAPIToken(user: TokenUser) {
        if (this.isImpersonating) {
            this.growlService.error("This action is not allowed while impersonating a user.");
            return;
        }

        let users: TokenUser[] = [];
        if (user) {
            users = [user];
        } else {
            users = this.getSelectedUsers();
        }
        if (!users.length) {
            this.growlService.warning("No users are selected.");
            return;
        }

        let sharedEmailUsers = [];
        users.forEach((thisUser) => {
            if (thisUser.emailShared) {
                sharedEmailUsers.push(user);
            }
        });

        if (sharedEmailUsers.length) {
            if (sharedEmailUsers.length == 1) {
                this.growlService.warning(
                        "Can't send a new token request because one of the selected users shares an email address with another user.");
            } else {
                this.growlService.warning(
                        "Can't send a new token request because some of the selected users share an email address with another user.");
            }
            return;
        }

        let message;
        if (users.length == 1) {
            user = users[0];

            let userFirstLast = user.firstLastName;
            userFirstLast = this.escapeHTML(userFirstLast);
            message = "This will create an API Token Request for <b>" + userFirstLast + " (" + user.username + ")</b>.";
            message += "<br/><br/>An email will be sent to the user (<b>" + user.email +
                    "</b>) with a secure one-time-use link to generate the new API token.";
        } else {
            message = "This will create an API Token Request for <b>" + users.length + "</b> users.";
            message +=
                    "<br/><br/>An email will be sent to the users with a secure one-time-use link to generate the new API token.";
        }
        this.showConfirmDialog(message, () => {
            this.checkIfTokensReplacedBySend(users, () => {
                this.sendAPITokenRequest(users, false);
            });
        });
    }

    usernamesFromUsers(users: TokenUser[]): string[] {
        let usernames: string[] = [];
        users.forEach((user) => {
            usernames.push(user.username);
        });
        return usernames;
    }

    emailsFromUsers(users: TokenUser[]): string[] {
        let emails: string[] = [];
        users.forEach((user) => {
            emails.push(user.email);
        });
        return emails;
    }

    cancelTokenRequest(user?: TokenUser) {
        if (this.isImpersonating) {
            this.growlService.error("This action is not allowed while impersonating a user.");
            return;
        }

        let users;
        if (user) {
            users = [user];
        } else {
            users = this.getSelectedUsers();
        }

        if (!users.length) {
            this.growlService.warning("No users are selected.");
            return;
        }
        if (users.length == 1) {
            if (!users[0].pendingAccept) {
                this.growlService.warning("" + users[0].name + " doesn't have a pending request.");
                return;
            }
        }

        let tokenUsers: TokenUser[] = [];
        users.forEach((thisUser) => {
            if (thisUser.pendingAccept) {
                tokenUsers.push(thisUser);
            }
        });

        if (!tokenUsers.length) {
            this.growlService.warning("No selected users have pending requests.");
            return;
        }

        let message;
        if (tokenUsers.length == 1) {
            message = "This will cancel the pending request for " + tokenUsers[0].name + ".";
        } else {
            message = "This will cancel the pending requests for " + tokenUsers.length + " users.";
        }
        this.showConfirmDialog(message, () => {
            let emails = this.emailsFromUsers(tokenUsers);
            this.apiTokenService.cancelPendingTokenRequests(this.apiKey, emails, this.orgID)
                .subscribe((result: any) => {
                    this.growlService.success("Canceled successfully.");
                    this.loadList();
                    this.deselectAll();
                });
        });
    }

    removeAPIToken(user?: TokenUser) {
        if (this.isImpersonating) {
            this.growlService.error("This action is not allowed while impersonating a user.");
            return;
        }

        let users;
        if (user) {
            users = [user];
        } else {
            users = this.getSelectedUsers();
        }

        if (!users.length) {
            this.growlService.warning("No users are selected.");
            return;
        }
        if (users.length == 1) {
            if (!users[0].apiToken) {
                this.growlService.warning("" + users[0].name + " doesn't have a token.");
                return;
            }
        }

        let tokenUsers: TokenUser[] = [];
        users.forEach((thisUser) => {
            if (thisUser.apiToken) {
                tokenUsers.push(thisUser);
            }
        });

        if (!tokenUsers.length) {
            this.growlService.warning("No selected users have a token.");
            return;
        }

        let message;
        if (tokenUsers.length == 1) {
            message = "This will remove the token from " + tokenUsers[0].name + ".";
        } else {
            message = "This will remove the tokens from " + tokenUsers.length + " users.";
        }
        this.showConfirmDialog(message, () => {
            let usernames = this.usernamesFromUsers(tokenUsers);
            this.apiTokenService.deleteAPITokens(this.apiKey, usernames, this.orgID)
                .subscribe((result: any) => {
                    this.growlService.success("Removed successfully.");
                    this.loadList();
                    this.deselectAll();
                });
        });
    }

    doSearchFilter() {
        if (this.filterDelay) {
            clearTimeout(this.filterDelay);
            // kill active scheduled filter
        }
        this.filterDelay = window.setTimeout(() => {
            this.filterDelay = null;
            this.doSort();
        }, 300);
    }

    clearFilter() {
        this.query.text = "";
        this.doSearchFilter();
    }

    clickUser(user: TokenUser) {
        if (user.pending) {
            return false;
        }
        if (this.userURL) {
            let userID = this.encryptionService.scrambleEncode(user.id);
            let end = "/contact";
            let newUrl = this.userURL + userID + end;
            //$log.log("Navigating to " + newUrl;
            this.router.navigate([newUrl], { relativeTo: this.route });
        }
        return false;
    }

    getSelectedUsers() {
        let selectedUsers: TokenUser[] = [];

        this.sortedList.forEach((user) => {
            if (user.selected) {
                selectedUsers.push(user);
            }
        });

        return selectedUsers;
    }

    downloadTokens() {
        let users = this.getSelectedUsers();
        if (!users.length) {
            this.growlService.warning("No users are selected.");
            return;
        }

        let message;
        if (users.length == 1) {
            message = "This will download a CSV file containing a new token for " + users[0].name + ".";
        } else {
            message = "This will download a CSV file containing new tokens for " + users.length + " users.";
        }
        message += "<br/><br/>You will need to inform users of their new token.";
        message +=
                "<br/><br/>Warning: Tokens have the same kind of risk as passwords. Please keep them protected in the same way you would a list of passwords!";

        this.showConfirmDialog(message, () => {
            this.checkIfTokensReplacedByExport(users, () => {
                let usernames = this.usernamesFromUsers(users);
                this.apiTokenService.getNewAPITokenForUsers(this.apiKey, usernames, this.orgID)
                    .subscribe((tokenUsers: TokenUser[]) => {
                        let date = new Date();
                        let exportText = this.generateTokenCSV(tokenUsers);
                        let targetFilename = "apiTokens_" + this.orgID + "_" + this.apiKey + "_" + date.toISOString() +
                                ".csv";

                        let downloadElement = document.createElement("a");
                        downloadElement.setAttribute("href",
                                "data:text/csv;charset=utf-8," + encodeURIComponent(exportText));
                        downloadElement.setAttribute("download", targetFilename);
                        downloadElement.style.display = "none";
                        document.body.appendChild(downloadElement);
                        downloadElement.click();
                        document.body.removeChild(downloadElement);

                        this.growlService.success("Downloaded successfully.");
                        this.loadList();
                        this.deselectAll();
                    }, (error: any) => {
                        let errorMessage = "Download failed";
                        if (error) {
                            let obj = JSON.parse(error);
                            errorMessage = obj.errorMessage;
                        }
                        this.growlService.error(errorMessage);
                    });
            });
        });
    }

    checkIfTokensReplacedByExport(users: TokenUser[], callback: Function) {
        let existingUsers: TokenUser[] = [];
        users.forEach((user) => {
            if (user.apiToken) {
                existingUsers.push(user);
            }
        });

        if (!existingUsers.length) {
            callback();
            return;
        }

        let message;
        if (existingUsers.length == 1) {
            message = "<span class='text-danger'><b>" + existingUsers[0].name +
                    " already has a token. If you continue, that existing token will become invalid.</b></span>";
            message += "<br/><br/>A new token will be generated for this user.";
        } else {
            message = "<span class='text-danger'><b>" + existingUsers.length +
                    " selected users already have a token. If you continue, those existing tokens will become invalid.</b></span>";
            message += "<br/><br/>New tokens will be generated for these users.";
        }

        this.showConfirmDialog(message, callback);
    }

    checkIfTokensReplacedBySend(users: TokenUser[], callback: Function) {
        let existingUsers: TokenUser[] = [];
        let pendingUsers: TokenUser[] = [];
        users.forEach((user) => {
            if (user.apiToken) {
                existingUsers.push(user);
            }
            if (user.pendingAccept) {
                pendingUsers.push(user);
            }
        });

        if (!existingUsers.length && !pendingUsers.length) {
            callback();
            return;
        }

        let message;
        if (existingUsers.length == 1) {
            message = "<span class='text-danger'><b>" + existingUsers[0].name +
                    " already has a token. If you continue, that existing token will be replaced.</b></span>";
            message +=
                    "<br/><br/>A new token will be generated for this user when they accept the request for a new token.";
        } else if (existingUsers.length) {
            message = "<span class='text-danger'><b>" + existingUsers.length +
                    " selected users already have a token. If you continue, those existing tokens will be replaced.</b></span>";
            message +=
                    "<br/><br/>New tokens will be generated for these users when they accept the request for a new token.";
        } else if (pendingUsers.length == 1) {
            message = "<span><b>" + pendingUsers[0].name +
                    " already has a pending token request. If you continue, that existing request will be canceled.</b></span>";
            message += "<br/><br/>A new token request will be sent to this user.";
        } else {
            message = "<span><b>" + pendingUsers.length +
                    " users already have a pending token request. If you continue, those existing requests will be canceled.</b></span>";
            message += "<br/><br/>New token requests will be sent to these users.";
        }

        this.showConfirmDialog(message, callback);
    }

    generateTokenCSV(users: TokenUser[]) {
        let content = "organization,apiKey,name,username,token";
        let hasURLEncodedToken = false;
        if (users.length > 0 && users[0].hasOwnProperty("apiTokenURLEncoded")) {
            content += ",tokenURLEncoded";
            hasURLEncodedToken = true;
        }
        content += "\n";
        for (let i = 0; i < users.length; i++) {
            content += this.orgID + "," + this.apiKey + "," + users[i].name + "," + users[i].username + "," +
                    (users[i].apiToken ? users[i].apiToken : "") + "," +
                    (hasURLEncodedToken ? users[i].apiTokenURLEncoded ? users[i].apiTokenURLEncoded : "" : "") + "\n";
        }
        return content;
    }

    viewSharedEmail(email: string) {
        let newUrl = "/admin/user-org/users";
        if (email) {
            this.router.navigate([newUrl], {
                relativeTo: this.route,
                queryParams: { queryText: email }
            });
        } else {
            this.router.navigate([newUrl], { relativeTo: this.route });
        }
    }

    selectUser() {
        // selecting a single user can change the selected.all state
        let anyUnselected = false;
        this.usersWithTokens.forEach((user) => {
            if (!user.selected) {
                anyUnselected = true;
            }
        });
        this.allSelected = !anyUnselected;
    }

    selectDefinitionKey($event: any) {
        if ($event && $event.$selection) {
            this.selectDefinitionKeyID($event.$selection.option);
        }
    }

    selectDefinitionKeyID(proposedKey: string) {
        let foundKey: any = null;
        if (proposedKey) {
            // make sure the proposed key is one that the org has
            foundKey = this.allDefinitionKeys.find((defKey) => {
                return (defKey.option == proposedKey);
            });
            if (!foundKey) {
                this.growlService.warning("'" + proposedKey + "' is not a valid API key for this organization.");
            }
        }
        if (!!foundKey) {
            this.apiKey = proposedKey;
        } else if (this.allDefinitionKeys.length) {
            this.apiKey = this.allDefinitionKeys[0].label;
        } else {
            this.apiKey = null;
        }
        this.loadList();
    }

    loadList() {
        if (!this.orgID || !this.apiKey) {
            this.loaded = true;
            return;
        }

        this.spinnerService.startSpinner();
        this.apiTokenService.getOrgUsersWithAPIDefinitionTokens(this.orgID, this.apiKey)
            .subscribe((response: TokenUser[]) => {
                if (response) {
                    this.usersWithTokens = response;
                    this.usersWithTokens.forEach((user) => {
                       if (user.apiToken && user.pending && user.pendingAccept) {
                           user.tokenSort = 4;
                       } else if (user.apiToken && (user.pending || user.pendingAccept)) {
                           user.tokenSort = 3;
                       } else if (user.apiToken) {
                           user.tokenSort = 2;
                       } else if (user.pending ||user.pendingAccept) {
                           user.tokenSort = 1;
                       } else {
                           user.tokenSort = 0;
                       }
                    });
                    this.doSort();
                }
                this.loaded = true;
                this.spinnerService.stopSpinner();
            }, () => {
                this.loaded = true;
                this.spinnerService.stopSpinner();
            });
    }

    /*
    // removed because 2 reasons:
    // 1. don't encourage logging as user here
    // 2. creates a dependency on 'admin' module
    becomeUser(username: string) {
        this.adminUserorgService.loginAs(username);
    }
    */

    userTokenFilter(input: TokenUser[], search: QueryThing) {
        if (!input) {
            return input; // no collection of items.  Skip it.
        }
        if (!search) {
            return input; // no search text available yet
        }
        let out: TokenUser[] = []; // array of new items to return
        let escaped = "";
        if (search && search.text) {
            escaped = search.text.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
        }
        let ri = new RegExp(escaped, "i"); // get the regex ready - ignore case.
        input.forEach((item) => {
            if (ri.test(item.firstLastName) || ri.test(item.id)) {
                // search on name, orgID or id
                out.push(item); // matched add back to the list
            }
        });
        return out; // return the filtered array
    }

    adjustColumnWidths() {
        if (!this.theGrid || !this.theGrid.nativeElement) {
            setTimeout(() => {
                this.adjustColumnWidths();
            }, 200);
            return;
        }

        let normalColCount = 2;
        let fullWidth = this.theGrid.nativeElement.clientWidth;
        let fixedColsWidth = 157; // 21 + 30 + 106
        fixedColsWidth += this.scrollbarWidth;
        let aveWidth = (fullWidth - fixedColsWidth) / normalColCount;
        let nameWidth = aveWidth;
        let otherWidth = aveWidth - 1; // for rounding

        setTimeout(() => {
            this.nameColumns.forEach((col) => {
                col.nativeElement.style.width = "" + nameWidth + "px";
                //this.renderer.setStyle(col.nativeElement, "width", "30%");
            });
            this.otherColumns.forEach((col) => {
                col.nativeElement.style.width = "" + otherWidth + "px";
            });
        }, 0);
    }

    escapeHTML(value: string): string {
        if (!value) {
            return value;
        }
        let safe = value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        return safe;
    }
}
