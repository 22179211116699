<sf-tabbed-modal-header title="{{title}}" [currentStep]="currentIndex+1" [stepCount]="stepCount"></sf-tabbed-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        <span [innerHTML]="warning"></span>
    </div>

    <!-- step 0 -->
    <form *ngIf="currentIndex == 0" name="datesForm">
        <div class="explain">
            <i>You can upload an image of a customized license
                after it has been signed by the customer and approved by Simplifile management.</i>
        </div>
        <div class="explain">
            Enter the details below that were agreed upon in the signed license.
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">Signature Date:</div>
            <div class="col-sm-7">
                <sf-date-picker id="exec-date" name="exec-date" inputId="exec-date-input" class="upload-date standard-spacing"
                        [(ngModel)]="dialogData.executedDate" [required]="true" [maxDate]="maxSignDate"
                        (dateChange)="blurSignatureDate()" [minDate]="minSignDate"
                        (blur)="blurSignatureDate()"></sf-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="eff-date">Effective Date:</label>
            </div>
            <div class="col-sm-7">
                <sf-date-picker id="eff-date" name="eff-date" inputId="eff-date-input" class="upload-date standard-spacing"
                        [(ngModel)]="effectiveDayjs" (dateChange)="effectiveDateChanged($event)"
                        [minDate]="today" [maxDate]="maxEffectiveDate"></sf-date-picker>
            </div>
        </div>
<!--        <div class="row">-->
<!--            <div class="col-sm-5 input-label">Expiration Date:</div>-->
<!--            <div class="col-sm-7">-->
<!--                <sf-date-picker id="exp-date" name="exp-date" inputId="exp-date-input" class="upload-date standard-spacing"-->
<!--                        [(ngModel)]="expirationDayjs" (dateChange)="expirationDateChanged($event)" [required]="true"-->
<!--                        [minDate]="minExpireDate" [maxDate]="maxDate"></sf-date-picker>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="signer">Signer Name:</label>
            </div>
            <div class="col-sm-7">
                <input type="text" id="signer" name="signer" class="form-control standard-spacing" maxlength="68"
                        [(ngModel)]="dialogData.signatureName" [required]="true"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">
                <label for="title">Signer Title:</label>
            </div>
            <div class="col-sm-7">
                <input type="text" id="title" name="title" class="form-control standard-spacing" maxlength="68"
                        [(ngModel)]="dialogData.signatureTitle"/>
            </div>
        </div>
<!--        <div class="row">-->
<!--            <div class="col-sm-11 mt-2">-->
<!--                <span><i>* License expiration date will be one {{dialogData.renewalFrequency == "MONTHLY" ? "month" : "year"}} from the signature date.</i></span>-->
<!--            </div>-->
<!--        </div>-->
    </form>

    <!-- step 1 -->
    <form *ngIf="currentIndex == 1" name="feesForm">
        <div class="row">
            <div class="col-sm-5 input-label">Revenue Share Option:</div>
            <div class="col-sm-7">
                <sf-select selectedOption="selectedShareOption.id" class="standard-spacing"
                    [options]="selectableShareOptions" (select)="selectShareOption($event)"
                    trackBy="id" labelBy="label"
                    [isSingleSelect]="true" placeholder="Select an option..."
                    [required]="true">
                </sf-select>
            </div>
        </div>
        <div class="row" *ngIf="selectedShareOption.id == 'PERCENTAGE'">
            <div class="col-sm-5 input-label">Percentage of Fee:</div>
            <div class="col-sm-7">
                <input type="text" id="subPercent" name="subPercent"
                        class="form-control upload-money-input standard-spacing" maxlength="8"
                        [(ngModel)]="dialogData.nSubmissionFee" [required]="true"/>
                <span>%</span>
            </div>
        </div>
        <div class="row" *ngIf="selectedShareOption.id == 'PERCENTAGE' && showExclusiveFee">
            <div class="col-sm-5 input-label">Exclusive Fee Percentage:</div>
            <div class="col-sm-7">
                <input type="text" id="exsubpercent" name="exsubpercent"
                        class="form-control upload-money-input standard-spacing" maxlength="8"
                        [(ngModel)]="dialogData.exclusiveSubmissionFee" [required]="true"/>
                <span>%</span>
            </div>
        </div>
        <div class="row" *ngIf="selectedShareOption.id == 'FLAT_FEE'">
            <div class="col-sm-5 input-label">Per Document Flat Amount:</div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="docflat" name="docflat" class="form-control upload-money-input standard-spacing"
                        maxlength="10" [(ngModel)]="dialogData.nSubmissionFee" [required]="true"/>
            </div>
        </div>
        <div class="row" *ngIf="selectedShareOption.id == 'FLAT_FEE' && showExclusiveFee">
            <div class="col-sm-5 input-label">Exclusive Per Document Flat Amount:</div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="exdocflat" name="exdocflat"
                        class="form-control upload-money-input standard-spacing" maxlength="10"
                        [(ngModel)]="dialogData.exclusiveSubmissionFee" [required]="true"/>
            </div>
        </div>
        <div class="row" *ngIf="selectedShareOption.id == 'KEEP'">
            <div class="col-sm-5 input-label">Amount We Keep per Document:</div>
            <div class="col-sm-7">
                <span>$</span>
                <input type="text" id="dockeep" name="dockeep" class="form-control upload-money-input standard-spacing"
                        maxlength="10" [(ngModel)]="dialogData.nSubmissionFee" [required]="true"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">Billing Cycle:</div>
            <div class="col-sm-7">
                <sf-select class="standard-spacing"
                    [options]="selectableBillingCycles" (select)="selectBillingCycle($event)"
                    trackBy="id" labelBy="label"
                    [isSingleSelect]="true" placeholder="Select a billing cycle..."
                    [selectedOption]="dialogData.billingCycle">
                </sf-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 input-label">Payment Terms:</div>
            <div class="col-sm-7">
                <sf-select class="standard-spacing"
                    [options]="selectablePaymentTerms" (select)="selectPaymentTerm($event)"
                    trackBy="id" labelBy="label"
                    [isSingleSelect]="true" placeholder="Select a payment term..."
                    [selectedOption]="dialogData.paymentTerms">
                </sf-select>
            </div>
        </div>
        <div class="row top-space">
            <div class="col-sm-5">
                <button class="btn btn-secondary" (click)="openFileExplorer()">Choose File</button>
            </div>
            <div class="col-sm-7">{{dialogData.file ? dialogData.file.name : ""}}</div>
        </div>
        <input type="file" accept="application/pdf, image/tiff"
            #file style="display: none;" (change)="onFileAdded()" single />
    </form>
</div>
<sf-tabbed-modal-footer [primary]="primary" [secondary]="secondary" [nextButton]="nextButton"
        [backButton]="backButton"></sf-tabbed-modal-footer>
