import { Component, OnInit } from "@angular/core";
import { UniversalSearchResultComponent } from "../universal-search-result/universal-search-result.component";
import { CapcLoanResult } from "../../universal-search.interfaces";

@Component({
    selector: "sf-capc-result",
    templateUrl: "./capc-result.component.html",
    styleUrls: ["./capc-result.component.scss"]
})
export class CapcResultComponent
    extends UniversalSearchResultComponent
    implements OnInit
{
    item: CapcLoanResult;

    ngOnInit(): void {
        this.item = this.result as CapcLoanResult;
    }
}
