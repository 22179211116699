import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { filter, startWith, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { getCurrentRouteData } from "@sf/common";

@Component({
    selector: "sf-right-content-left-nav",
    templateUrl: "./right-content-left-nav.component.html",
    styleUrls: ["./right-content-left-nav.component.scss"]
})
export class RightContentLeftNavComponent implements OnInit {
    private _$ngOnDestroy = new Subject<void>();

    containerClass: string;
    rightContentClass: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._$ngOnDestroy),
                startWith(true)
            )
            .subscribe(() => {
                const { data } = getCurrentRouteData(this._activatedRoute);

                this.containerClass = data.containerClass;
                this.rightContentClass = data.rightContentClass;
            });
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
    }
}
