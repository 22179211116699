import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { OrganizationMap } from "@sf/userorg/common";

@Injectable({
    providedIn: "root"
})
export class AdminPackagesService {
    constructor(private _rpcClient: RpcClientService) {}

    getUSStates() {
        return this._rpcClient.makeRequest("admin", "getUSStates");
    }

    getPackages(searchParams: any) {
        return this._rpcClient.makeRequest("admin", "getAdminPackages", {
            search: searchParams
        });
    }

    getRecipients(searchParams: any) {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecipients",
            searchParams
        );
    }

    getRecipientsFromList(orgIDs: any) {
        return this._rpcClient.makeRequest("admin", "getRecipientsFromList", {
            organizationIDs: orgIDs
        });
    }

    getRecipientsByState(states: any) {
        return this._rpcClient.makeRequest("admin", "getRecipientsByState", {
            states: states
        });
    }

    getSubmitters(searchParams: any) {
        searchParams.submitters = searchParams.submitters + "TD";
        return this._rpcClient.makeRequest(
            "admin",
            "getSubmitters",
            searchParams
        );
    }

    getOrganizations(searchParams: any): Observable<OrganizationMap[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getOrganizations",
            searchParams
        );
    }

    discardPackages(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "erecord",
            "removePackages",
            selectedRows
        );
    }

    removeFromCountyQueue(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "erecord",
            "dequeuePackages",
            selectedRows
        );
    }

    undoAcceptance(selectedRows: any) {
        return this._rpcClient.makeRequest("erecord", "undoAcceptance", {
            packageIDs: selectedRows
        });
    }

    refreshStamps(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "erecord",
            "refreshStamps",
            selectedRows
        );
    }

    redoGhostStamps(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "admin",
            "redoGhostStamps",
            selectedRows
        );
    }

    schedulePrinting(selectedRows: any) {
        return this._rpcClient.makeRequest("erecord", "schedulePrinting", {
            uiPackageLiteList: selectedRows
        });
    }

    scheduleUploading(selectedRows: any) {
        return this._rpcClient.makeRequest("erecord", "scheduleUploading", {
            uiPackageLiteList: selectedRows
        });
    }

    schedulePayments(selectedRows: any) {
        return this._rpcClient.makeRequest("erecord", "schedulePayments", {
            uiPackageLiteList: selectedRows
        });
    }

    rejectPackages(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "erecord",
            "rejectPackages",
            selectedRows
        );
    }

    removeLocks(selectedRows: any) {
        return this._rpcClient.makeRequest("erecord", "removeLocks", {
            uiPackageLiteList: selectedRows
        });
    }

    reapplyDoctypeMappings(selectedRows: any) {
        return this._rpcClient.makeRequest(
            "admin",
            "reapplyDoctypeMappings",
            selectedRows
        );
    }
}
