import { IndividualConfig } from "ngx-toastr";

export enum GrowlTypes {
    SUCCESS = "SUCCESS",
    INFO = "INFO",
    WARNING = "WARNING",
    DANGER = "DANGER"
}

export interface GrowlMessage {
    message: string;
    title?: string;
    type?: GrowlTypes; // Defaults to info
    options?: Partial<IndividualConfig>;
}
