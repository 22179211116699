import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface SelectableState {
    abbrev: string;
    name: string;
}

export interface SelectableCounty {
    state: string;
    name: string;
    fips_code: string;
}

@Injectable({
    providedIn: "root"
})
export class StatesService {
    constructor(private _http: HttpClient) {}

    getAllStatesPlusDC(): Array<any> {
        let states = this.getAllStates();

        states.push({ abbrev: "DC", name: "District of Columbia" });

        return states.sort(function (s1: any, s2: any): any {
            return s1.name.localeCompare(s2.name);
        });
    }

    getAllStatesAndTerritories(): Array<SelectableState> {
        let states = this.getAllStatesPlusDC();
        let territories = this.getAllTerritories();

        let list = states.concat(territories);
        return list.sort(function (s1, s2): any {
            return s1.name.localeCompare(s2.name);
        });
    }

    getAllTerritories(): Array<SelectableState> {
        let list = [
            { abbrev: "AS", name: "American Samoa" },
            { abbrev: "GU", name: "Guam" },
            { abbrev: "MH", name: "Marshall Islands" },
            { abbrev: "FM", name: "Micronesia" },
            { abbrev: "MP", name: "Northern Mariana Islands" },
            { abbrev: "PW", name: "Palau" },
            { abbrev: "PR", name: "Puerto Rico" },
            { abbrev: "VI", name: "US Virgin Islands" }
        ];

        return list.sort(function (s1, s2): any {
            return s1.name.localeCompare(s2.name);
        });
    }

    getAllTimeZones() {
        return [
            { label: "Hawaii", option: "US/Hawaii" },
            { label: "Alaska", option: "US/Alaska" },
            { label: "Pacific", option: "US/Pacific" },
            { label: "Arizona", option: "US/Arizona" },
            { label: "Mountain", option: "US/Mountain" },
            { label: "Central", option: "US/Central" },
            { label: "Indiana East", option: "US/East-Indiana" },
            { label: "Eastern", option: "US/Eastern" },
            { label: "Puerto Rico", option: "America/Puerto_Rico" },
            { label: "Virgin Islands", option: "America/Virgin" },
            { label: "Samoa", option: "Pacific/Samoa" },
            { label: "Guam", option: "Pacific/Guam" }
        ];
    }

    getAllStates() {
        return [
            { abbrev: "AL", name: "Alabama" },
            { abbrev: "AK", name: "Alaska" },
            { abbrev: "AZ", name: "Arizona" },
            { abbrev: "AR", name: "Arkansas" },
            { abbrev: "CA", name: "California" },
            { abbrev: "CO", name: "Colorado" },
            { abbrev: "CT", name: "Connecticut" },
            { abbrev: "DE", name: "Delaware" },
            { abbrev: "FL", name: "Florida" },
            { abbrev: "GA", name: "Georgia" },
            { abbrev: "HI", name: "Hawaii" },
            { abbrev: "ID", name: "Idaho" },
            { abbrev: "IL", name: "Illinois" },
            { abbrev: "IN", name: "Indiana" },
            { abbrev: "IA", name: "Iowa" },
            { abbrev: "KS", name: "Kansas" },
            { abbrev: "KY", name: "Kentucky" },
            { abbrev: "LA", name: "Louisiana" },
            { abbrev: "ME", name: "Maine" },
            { abbrev: "MD", name: "Maryland" },
            { abbrev: "MA", name: "Massachusetts" },
            { abbrev: "MI", name: "Michigan" },
            { abbrev: "MN", name: "Minnesota" },
            { abbrev: "MS", name: "Mississippi" },
            { abbrev: "MO", name: "Missouri" },
            { abbrev: "MT", name: "Montana" },
            { abbrev: "NE", name: "Nebraska" },
            { abbrev: "NV", name: "Nevada" },
            { abbrev: "NH", name: "New Hampshire" },
            { abbrev: "NJ", name: "New Jersey" },
            { abbrev: "NM", name: "New Mexico" },
            { abbrev: "NY", name: "New York" },
            { abbrev: "NC", name: "North Carolina" },
            { abbrev: "ND", name: "North Dakota" },
            { abbrev: "OH", name: "Ohio" },
            { abbrev: "OK", name: "Oklahoma" },
            { abbrev: "OR", name: "Oregon" },
            { abbrev: "PA", name: "Pennsylvania" },
            { abbrev: "RI", name: "Rhode Island" },
            { abbrev: "SC", name: "South Carolina" },
            { abbrev: "SD", name: "South Dakota" },
            { abbrev: "TN", name: "Tennessee" },
            { abbrev: "TX", name: "Texas" },
            { abbrev: "UT", name: "Utah" },
            { abbrev: "VT", name: "Vermont" },
            { abbrev: "VA", name: "Virginia" },
            { abbrev: "WA", name: "Washington" },
            { abbrev: "WV", name: "West Virginia" },
            { abbrev: "WI", name: "Wisconsin" },
            { abbrev: "WY", name: "Wyoming" }
        ];
    }

    getAllStatesAndCanadianProvinces() {
        return [
            { abbrev: "AL", name: "Alabama" },
            { abbrev: "AK", name: "Alaska" },
            { abbrev: "AZ", name: "Arizona" },
            { abbrev: "AR", name: "Arkansas" },
            { abbrev: "CA", name: "California" },
            { abbrev: "CO", name: "Colorado" },
            { abbrev: "CT", name: "Connecticut" },
            { abbrev: "DE", name: "Delaware" },
            { abbrev: "DC", name: "District of Columbia" },
            { abbrev: "FL", name: "Florida" },
            { abbrev: "GU", name: "Guam" },
            { abbrev: "GA", name: "Georgia" },
            { abbrev: "HI", name: "Hawaii" },
            { abbrev: "ID", name: "Idaho" },
            { abbrev: "IL", name: "Illinois" },
            { abbrev: "IN", name: "Indiana" },
            { abbrev: "IA", name: "Iowa" },
            { abbrev: "KS", name: "Kansas" },
            { abbrev: "KY", name: "Kentucky" },
            { abbrev: "LA", name: "Louisiana" },
            { abbrev: "ME", name: "Maine" },
            { abbrev: "MD", name: "Maryland" },
            { abbrev: "MA", name: "Massachusetts" },
            { abbrev: "MI", name: "Michigan" },
            { abbrev: "MN", name: "Minnesota" },
            { abbrev: "MS", name: "Mississippi" },
            { abbrev: "MO", name: "Missouri" },
            { abbrev: "MT", name: "Montana" },
            { abbrev: "NE", name: "Nebraska" },
            { abbrev: "NV", name: "Nevada" },
            { abbrev: "NH", name: "New Hampshire" },
            { abbrev: "NJ", name: "New Jersey" },
            { abbrev: "NM", name: "New Mexico" },
            { abbrev: "NY", name: "New York" },
            { abbrev: "NC", name: "North Carolina" },
            { abbrev: "ND", name: "North Dakota" },
            { abbrev: "OH", name: "Ohio" },
            { abbrev: "OK", name: "Oklahoma" },
            { abbrev: "OR", name: "Oregon" },
            { abbrev: "PA", name: "Pennsylvania" },
            { abbrev: "PR", name: "Puerto Rico" },
            { abbrev: "RI", name: "Rhode Island" },
            { abbrev: "SC", name: "South Carolina" },
            { abbrev: "SD", name: "South Dakota" },
            { abbrev: "TN", name: "Tennessee" },
            { abbrev: "TX", name: "Texas" },
            { abbrev: "UT", name: "Utah" },
            { abbrev: "VT", name: "Vermont" },
            { abbrev: "VA", name: "Virginia" },
            { abbrev: "VI", name: "US Virgin Islands" },
            { abbrev: "WA", name: "Washington" },
            { abbrev: "WV", name: "West Virginia" },
            { abbrev: "WI", name: "Wisconsin" },
            { abbrev: "WY", name: "Wyoming" },
            { abbrev: "AS", name: "American Samoa" },
            { abbrev: "FM", name: "Federated States of Micronesia" },
            { abbrev: "MH", name: "Marshall Islands" },
            { abbrev: "MP", name: "Northern Mariana Islands" },
            { abbrev: "PW", name: "Palau" },
            { abbrev: "AB", name: "Alberta" },
            { abbrev: "BC", name: "British Columbia" },
            { abbrev: "MB", name: "Manitoba" },
            { abbrev: "NB", name: "New Brunswick" },
            { abbrev: "NL", name: "Newfoundland and Labrador" },
            { abbrev: "NT", name: "Northwest Territories" },
            { abbrev: "NS", name: "Nova Scotia" },
            { abbrev: "NU", name: "Nunavut" },
            { abbrev: "ON", name: "Ontario" },
            { abbrev: "PE", name: "Prince Edward Island" },
            { abbrev: "QC", name: "Quebec" },
            { abbrev: "SK", name: "Saskatchewan" },
            { abbrev: "YT", name: "Yukon" },
            { abbrev: "AA", name: "Armed Forces Americas" },
            { abbrev: "AE", name: "Armed Forces Europe" },
            { abbrev: "AP", name: "Armed Forces Pacific" }
        ];
    }

    getTrusteeStates() {
        return [
            { abbrev: "CA", name: "California" },
            { abbrev: "ID", name: "Idaho" },
            { abbrev: "MT", name: "Montana" },
            { abbrev: "NE", name: "Nebraska" },
            { abbrev: "NV", name: "Nevada" },
            { abbrev: "OR", name: "Oregon" },
            { abbrev: "UT", name: "Utah" },
            { abbrev: "WA", name: "Washington" }
        ];
    }

    getCountiesByState(stateAbbrev: string): Observable<SelectableCounty[]> {
        return this._http.get("/sf/ui/assets/countiesByState.json").pipe(
            map((counties: SelectableCounty[]) => {
                return counties.filter(
                    (county) => county.state === stateAbbrev
                );
            })
        );
    }
}
