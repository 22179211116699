<div class="contract-details-dialog">
    <sf-modal-header title="License Details"></sf-modal-header>
    <div class="modal-body">
        <div class="bold mb-1">
            {{contract.documentType}}
        </div>
        <table class="table-width">
            <tr>
                <td class="some">Contract ID:</td>
                <td class="smaller">{{contract.contractID}}</td>
            </tr>
            <tr>
                <td class="some">Signature Date:</td>
                <td>{{contract.executedDateDayjs}}</td>
            </tr>
            <tr>
                <td class="some">Effective Date:</td>
                <td>{{contract.effectiveDateDayjs}}</td>
            </tr>
            <tr *ngIf="contract.expirationDateDayjs">
                <td class="some">Expiration Date:</td>
                <td>{{contract.expirationDateDayjs}}</td>
            </tr>
            <tr *ngIf="contract.signatureName">
                <td class="some">Signed by:</td>
                <td>{{contract.signatureName}} {{contract.signatureTitle}}</td>
            </tr>
        </table>
        <div *ngIf="contract.wetSigned" class="">
            <br/><b>Document was uploaded</b>
        </div>
        <div *ngIf="contract.pendingApproval" class="">
            <br/><b>Pending approval</b>
            <div *ngIf="!!orgID">
                <div *ngIf="!names || !names.length">
                    <div>Waiting for acceptance by any organization administrator.</div>
                </div>
                <div *ngIf="names && names.length == 1">
                    <div>Waiting for acceptance by: </div>
                    <div>{{names[0].firstName + ' ' + names[0].lastName + ' (' + names[0].userId + ') ' + names[0].email}}</div>
                </div>
                <div class="name-list" *ngIf="names && names.length > 1">
                    <div>Waiting for acceptance by any of the following: </div>
                    <ul class="selector-bottom">
                        <li *ngFor="let name of names">
                            <span>{{name.firstName + ' ' + name.lastName + ' (' + name.userId + ') ' + name.email}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <br/>
        <table *ngIf="fees" class="table-width">
            <tr *ngIf="contract.enrollmentCode">
                <td class="some">Enrollment Code:</td>
                <td>{{contract.enrollmentCode}}</td>
            </tr>
            <tr *ngFor="let fee of fees">
                <td class="some">{{fee.name}}:</td>
                <td>{{fee.amount}}</td>
            </tr>
        </table>
    </div>
    <sf-modal-footer [primary]="primary"></sf-modal-footer>
</div>
