import { Injectable } from "@angular/core";
import { guid } from "../../helpers/guid";

@Injectable()
export class SfSelectUtils {
    hashValue = hashValue;
    guid = guid;

    constructor() {}
}

export function hashValue(stringOrInteger: any) {
    // http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
    let hash = 0,
        i,
        chr,
        len;
    if (Number.isInteger(stringOrInteger)) {
        return stringOrInteger;
    }
    if (stringOrInteger == null || stringOrInteger.length === 0) {
        return hash;
    }
    for (i = 0, len = stringOrInteger.length; i < len; i++) {
        chr = stringOrInteger.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
