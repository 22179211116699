<div class="page-contents">
    <input
        type="text"
        id="filter-field"
        placeholder="Filter by county name or state abbreviation"
        [formControl]="filterText"
        ngbTooltip="Type 3 or more letters in a county name or a 2-letter state abbreviation followed by a space"
        placement="right"
    />
    <div class="grid-container">
        <ag-grid-angular
            class="ag-grid ag-theme-sf"
            enableCellTextSelection
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
        ></ag-grid-angular>
    </div>
</div>

<ng-template #orgNameTemplate let-row>
    <ng-container *ngIf="row">
        <span *ngIf="isState(row)">
            <button
                *ngIf="countyDataNotYetRetrieved(row.orgID)"
                class="btn btn-plus-minus"
                (click)="getEEligibilityForAllRecipientsInState(row, false)"
            >
                +
            </button>
        </span>
        {{ row.orgName }}
        <fa-icon
            [icon]="['far', 'comment-alt-edit']"
            class="dialog-button enabled-checkmark ml-3 mr-2"
            [hidden]="!hasEditPermission"
            (click)="openEditNoteDialog(row)"
            ngbTooltip="Edit Notes"
            container="body"
        ></fa-icon>
        <fa-icon
            [icon]="['far', 'history']"
            class="dialog-button enabled-checkmark"
            [hidden]="!hasEditPermission"
            (click)="openHistoryDialog(row)"
            ngbTooltip="Change History"
            container="body"
        ></fa-icon>
    </ng-container>
</ng-template>

<ng-template #readOnlyCheckmarkTemplate let-row let-params="params">
    <ng-container *ngIf="row && params">
        <fa-icon
            *ngIf="row[params.fieldName]"
            [icon]="['far', 'check']"
            class="disabled-checkmark"
        ></fa-icon>
    </ng-container>
</ng-template>

<ng-template #checkmarkTemplate let-row let-params="params">
    <ng-container *ngIf="row && params">
        <fa-icon
            *ngIf="row[params.fieldName] && hasEditPermission"
            (click)="changeCheckmark(row, params.fieldName)"
            [icon]="['far', 'check']"
            class="enabled-checkmark"
        ></fa-icon>
        <fa-icon
            *ngIf="!row[params.fieldName] && hasEditPermission"
            (click)="changeCheckmark(row, params.fieldName)"
            [icon]="['far', 'square']"
            class="enabled-checkmark"
        ></fa-icon>
        <fa-icon
            *ngIf="row[params.fieldName] && !hasEditPermission"
            [icon]="['far', 'check']"
            class="disabled"
        ></fa-icon>
        <fa-icon
            *ngIf="!row[params.fieldName] && !hasEditPermission"
            [icon]="['far', 'square']"
            class="disabled"
        ></fa-icon>
    </ng-container>
</ng-template>

<ng-template #tripleOptionCheckmarkTemplate let-row let-params="params">
    <ng-container *ngIf="row && params">
        <fa-icon
            *ngIf="row[params.fieldName] && hasEditPermission"
            (click)="changeTripleCheckmark(row, params.fieldName)"
            [icon]="['far', 'check']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Approved"
            container="body"
        ></fa-icon>
        <fa-icon
            *ngIf="row[params.fieldName] && !hasEditPermission"
            [icon]="['far', 'check']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Approved"
            container="body"
        ></fa-icon>
        <fa-icon
            *ngIf="row[params.fieldName] === false && hasEditPermission"
            (click)="changeTripleCheckmark(row, params.fieldName)"
            [icon]="['far', 'square']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Not approved"
            container="body"
        ></fa-icon>
        <fa-icon
            *ngIf="row[params.fieldName] === false && !hasEditPermission"
            [icon]="['far', 'square']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Not approved"
            container="body"
        ></fa-icon>
        <fa-icon
            *ngIf="
                (row[params.fieldName] === null ||
                    row[params.fieldName] === undefined) &&
                hasEditPermission
            "
            (click)="changeTripleCheckmark(row, params.fieldName)"
            [icon]="['far', 'thumbs-up']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Approval not required"
            container="body"
        ></fa-icon>
        <fa-icon
            *ngIf="
                (row[params.fieldName] === null ||
                    row[params.fieldName] === undefined) &&
                !hasEditPermission
            "
            [icon]="['far', 'thumbs-up']"
            [class]="
                !hasEditPermission ||
                (!this.isState(row) &&
                    [
                        'isRegistered',
                        'simpfl_approved_ipen_vendor',
                        'simpfl_approved_ron_vendor'
                    ].includes(params.fieldName))
                    ? 'disabled-checkmark'
                    : 'enabled-checkmark'
            "
            ngbTooltip="Approval not required"
            container="body"
        ></fa-icon>
    </ng-container>
</ng-template>

<ng-template #dateTemplate let-row>
    <ng-container *ngIf="row">
        <span>{{ row.last_updated | date: "MM/dd/yyyy" }}</span>
    </ng-container>
</ng-template>
