<div class="modal-footer">
    <span *ngIf="primary" [ngbTooltip]="primary.disabled ? primary.disabledTooltip : primary.toolTip" [openDelay]="500">
        <button type="button" class="btn btn-primary" #primaryButtonEl [id]="primary.id ? primary.id : 'btn-primary'"
            [disabled]="primary.disabled || modal.working" [hidden]="primary.hidden"
            (click)="buttonClick(primary, 'primary')">{{primary.text}}</button>
    </span>
    <span *ngIf="secondary" [ngbTooltip]="secondary.disabled ? secondary.disabledTooltip : secondary.toolTip" [openDelay]="500">
        <button type="button" class="btn btn-secondary" [id]="secondary.id ? secondary.id : 'btn-secondary'"
            [disabled]="secondary.disabled || modal.working" [hidden]="secondary.hidden"
            (click)="buttonClick(secondary, 'secondary')">{{secondary.text ? secondary.text : 'Cancel'}}</button>
    </span>
    <span *ngIf="quinary" [ngbTooltip]="quinary.disabled ? quinary.disabledTooltip : quinary.toolTip" [openDelay]="500">
        <button type="button" class="btn btn-secondary" [id]="quinary.id ? quinary.id : 'btn-quinary'"
            [disabled]="quinary.disabled || modal.working" [hidden]="quinary.hidden"
            (click)="buttonClick(quinary, 'quinary')">{{quinary.text ? quinary.text : 'Other'}}</button>
    </span>

    <span *ngIf="tertiary" class="tertiary-action" [ngbTooltip]="tertiary.disabled ? tertiary.disabledTooltip : tertiary.toolTip" [openDelay]="500">
        <button type="button" class="btn btn-secondary" [id]="tertiary.id ? tertiary.id : 'btn-tertiary'"
            [disabled]="tertiary.disabled || modal.working" [hidden]="tertiary.hidden"
            (click)="buttonClick(tertiary, 'tertiary')">{{tertiary.text ? tertiary.text : 'Other'}}</button>
    </span>
    <span *ngIf="quaternary" [ngbTooltip]="quaternary.disabled ? quaternary.disabledTooltip : quaternary.toolTip" [openDelay]="500">
        <button type="button" class="btn btn-secondary" [id]="quaternary.id ? quaternary.id : 'btn-quaternary'"
            [disabled]="quaternary.disabled || modal.working" [hidden]="quaternary.hidden"
            (click)="buttonClick(quaternary, 'quaternary')">{{quaternary.text ? quaternary.text : 'Other'}}</button>
    </span>
    <div class="tertiary-action" *ngIf="!tertiary">
        <ng-content></ng-content>
    </div>
</div>
