import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    Params,
    Router,
    RoutesRecognized,
    UrlTree
} from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter, map, pairwise } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class PreviousRouteService {
    public previousUrl$: BehaviorSubject<UrlTree>;

    constructor(private _router: Router, private _route: ActivatedRoute) {
        let currentUrl = this._router.parseUrl(
            window.location.pathname.replace("/sf/ui", "") +
                window.location.search
        );
        this.previousUrl$ = new BehaviorSubject<UrlTree>(currentUrl);

        this._router.events
            .pipe(
                filter((e) => e instanceof RoutesRecognized),
                pairwise(),
                map(
                    ([prev, curr]: [RoutesRecognized, RoutesRecognized]) =>
                        prev.url
                )
            )
            .subscribe((url) => {
                this.previousUrl$.next(this._router.parseUrl(url));
            });
    }

    getPreviousUrl(): string {
        return this._router.serializeUrl(this.previousUrl$.getValue());
    }

    getPreviousUrlTree(): UrlTree {
        return this.previousUrl$.getValue();
    }

    getRouterLink(): { url: string; queryParams: Params } {
        const url = this.getPreviousUrl().split("?")[0];
        const queryParams = this.getPreviousUrlTree().queryParams;

        return { url, queryParams };
    }
}
