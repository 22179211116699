import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SupportBannerComponent } from "./support-banner.component";
import { UniversalSearchComponent } from "./universal-search/universal-search.component";
import { CommonCoreModule, SfCommonModule } from "@sf/common";
import {
    NgbDropdownModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { SfFormsModule } from "@sf/common";
import { FormsModule } from "@angular/forms";
import { SupportBannerService } from "./support-banner.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UniversalSearchNewComponent } from "./universal-search-new/universal-search-new.component";
import { UniversalSearchResultComponent } from "./universal-search-new/components/universal-search-result/universal-search-result.component";
import { UserResultComponent } from "./universal-search-new/components/user-result/user-result.component";
import { PackageResultComponent } from "./universal-search-new/components/package-result/package-result.component";
import { OrganizationResultComponent } from "./universal-search-new/components/organization-result/organization-result.component";
import { EeResultComponent } from "./universal-search-new/components/ee-result/ee-result.component";
import { CapcResultComponent } from "./universal-search-new/components/capc-result/capc-result.component";

@NgModule({
    declarations: [
        SupportBannerComponent,
        UniversalSearchComponent,
        UniversalSearchNewComponent,
        UniversalSearchResultComponent,
        UserResultComponent,
        PackageResultComponent,
        OrganizationResultComponent,
        EeResultComponent,
        CapcResultComponent
    ],
    imports: [
        CommonModule,
        SfCommonModule,
        SfFormsModule,
        FormsModule,
        NgbTooltipModule,
        FontAwesomeModule,
        NgbDropdownModule,
        CommonCoreModule
    ],
    providers: [SupportBannerService]
})
export class SupportBannerModule {}
