export enum Product {
    ADMIN = "ADMIN",
    ORGANIZATION = "ORGANIZATION",
    ERECORD = "ERECORD",
    SUBMITTER = "SUBMITTER",
    SUBMITTER_PAPER = "SUBMITTER_PAPER",
    SUBMITTER_SIGNING = "SUBMITTER_SIGNING",
    THIRD_PARTY_DOCUMENTS = "THIRD_PARTY_DOCUMENTS",
    TEMPLATE = "TEMPLATE",
    SUBMITTER_API = "SUBMITTER_API",
    RECIPIENT = "RECIPIENT",
    RECIPIENT_PRIMARY = "RECIPIENT_PRIMARY",
    RECIPIENT_SECONDARY = "RECIPIENT_SECONDARY",
    RECIPIENT_API = "RECIPIENT_API",
    CAPC = "CAPC",
    SETTLEMENT = "SETTLEMENT",
    COLLABORATION_SETTLEMENT = "COLLABORATION_SETTLEMENT",
    POST_CLOSING_SETTLEMENT = "POST_CLOSING_SETTLEMENT",
    LICENSED_COLLABORATOR_SETTLEMENT = "LICENSED_COLLABORATOR_SETTLEMENT",
    CAPC_SUBMITTER_API = "CAPC_SUBMITTER_API",
    SECURED_DISBURSEMENT = "SECURED_DISBURSEMENT",
    SETTLEMENT_INITIATED_LOAN = "SETTLEMENT_INITIATED_LOAN",
    VENDOR_SETTLEMENT_INTEGRATION = "VENDOR_SETTLEMENT_INTEGRATION",
    LENDER = "LENDER",
    COLLABORATION_LENDER = "COLLABORATION_LENDER",
    POST_CLOSING_LENDER = "POST_CLOSING_LENDER",
    LICENSED_COLLABORATOR_LENDER = "LICENSED_COLLABORATOR_LENDER",
    CAPC_LENDER_API = "CAPC_LENDER_API",
    VENDOR_LENDER_INTEGRATION = "VENDOR_LENDER_INTEGRATION",
    CAPC_ECLOSE_DEMO = "CAPC_ECLOSE_DEMO",
    THIRD_PARTY = "THIRD_PARTY",
    INTEGRATION_ACCESS = "INTEGRATION_ACCESS",
    VENDOR = "VENDOR",
    SUBMITTER_VENDOR = "SUBMITTER_VENDOR",
    RECIPIENT_VENDOR = "RECIPIENT_VENDOR",
    LENDER_VENDOR = "LENDER_VENDOR",
    NOTARY_VENDOR = "NOTARY_VENDOR",
    ESIGN_EVENTS_VENDOR = "ESIGN_EVENTS_VENDOR",
    VENDOR_API_INTEGRATION = "VENDOR_API_INTEGRATION",
    NOTARY_API_INTEGRATION = "NOTARY_API_INTEGRATION",
    ESIGN_EVENTS_API_INTEGRATION = "ESIGN_EVENTS_API_INTEGRATION",
    RECIPIENT_VENDOR_API_INTEGRATION = "RECIPIENT_VENDOR_API_INTEGRATION",
    SUBMITTER_VENDOR_API_INTEGRATION = "SUBMITTER_VENDOR_API_INTEGRATION",
    INTERNAL_VENDOR = "INTERNAL_VENDOR",
    INTERNAL_API_INTEGRATION = "INTERNAL_API_INTEGRATION",
    INTERNAL_ERECORD_PLUS_FACILITATOR = "INTERNAL_ERECORD_PLUS_FACILITATOR",
    TRUSTEE = "TRUSTEE",
    ESIGN_EVENT = "ESIGN_EVENT",
    VENDOR_ESIGN_EVENTS_INTEGRATION = "VENDOR_ESIGN_EVENTS_INTEGRATION",
    DOC_BUILDER_RON = "DOC_BUILDER_RON",
    NOTARY = "NOTARY",
    MERS_API_INTEGRATION = "MERS_API_INTEGRATION",
    SUBSCRIPTION = "SUBSCRIPTION"
}
