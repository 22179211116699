import {
    UIEERoom,
    UIESignEventWrapper,
    UIRoomStatus
} from "../interfaces/sign-event-notification.interface";
import { RoomStatusType } from "../interfaces/sign-event-room-status.interface";
import {
    ESignEventNotarizationType,
    EventType,
    StatusType,
    UIAppointment,
    UIEClosingPlatform,
    UIEESystemOriginator,
    UIESignEvent,
    UIESignEventUser
} from "../interfaces/sign-event.interface";

export class ESignEventEventSnapshot {
    eSignEventID: string;
    status: StatusType;
    eventType: EventType;
    organizationID: string;
    recipientID: string;
    name: string;
    isNotary: boolean;
    notary: UIESignEventUser;
    notarizationType: ESignEventNotarizationType;
    isRON: boolean;
    isDemo: boolean;
    sampleESignEvent: boolean;
    systemOriginator: UIEESystemOriginator;
    originatorReferenceID: string;
    eClosingPlatform: UIEClosingPlatform;
    cancellationReason: string;
    notaryServiceOrderID: string;
    appointment: UIAppointment;

    constructor() {}

    update(event: UIESignEvent) {
        this.eSignEventID = event?.eSignEventID;
        this.status = event?.status;
        this.eventType = event?.eventType;
        this.organizationID = event?.organizationID;
        this.recipientID = event?.recipientID;
        this.name = event?.name;
        this.isNotary = event?.permissions?.includes("esign_event_notary");
        this.notary = event?.notary;
        this.notarizationType = event?.eSignEventNotarizationType;
        this.isRON = this.notarizationType === ESignEventNotarizationType.RON;
        this.isDemo = event?.isDemo;
        this.sampleESignEvent = event?.sampleESignEvent;
        this.systemOriginator = event?.systemOriginator;
        this.originatorReferenceID = event?.originatorReferenceID;
        this.eClosingPlatform = event?.eClosingPlatform;
        this.cancellationReason = event?.cancellationReason;
        this.notaryServiceOrderID = event?.notaryServiceOrderID;
        this.appointment = event?.appointment;
    }
}

export class ESignEventRoomSnapshot {
    participantID: string;
    notaryState: string;

    constructor() {}

    update(room: UIEERoom) {
        this.participantID = room?.uiRoomParticipants?.find(
            (roomParty) => roomParty.currentUser
        )?.eSignEventParticipantID;
        this.notaryState = room?.notaryState;
    }
}

export class ESignEventRoomStatusSnapshot {
    type: RoomStatusType;
    isNotaryPresent: boolean;
    recordingVideoHasStarted: boolean;

    constructor() {}

    update(roomStatus: UIRoomStatus) {
        this.type = roomStatus?.type;
        this.isNotaryPresent = roomStatus?.isNotaryPresent;
        this.recordingVideoHasStarted = roomStatus?.recordingVideoHasStarted;
    }
}

export class ESignEventSnapshot {
    event: ESignEventEventSnapshot;
    room: ESignEventRoomSnapshot;
    roomStatus: ESignEventRoomStatusSnapshot;

    constructor() {
        this.event = new ESignEventEventSnapshot();
        this.room = new ESignEventRoomSnapshot();
        this.roomStatus = new ESignEventRoomStatusSnapshot();
    }

    updateAll(eventWrapper: UIESignEventWrapper) {
        this.handleEventUpdate(eventWrapper?.event);
        this.handleRoomUpdate(eventWrapper?.room);
        this.handleRoomStatusUpdate(eventWrapper?.roomStatus);
    }

    handleEventUpdate(event: UIESignEvent) {
        this.event.update(event);
    }

    handleRoomUpdate(room: UIEERoom) {
        this.room.update(room);
    }

    handleRoomStatusUpdate(roomStatus: UIRoomStatus) {
        this.roomStatus.update(roomStatus);
    }
}
