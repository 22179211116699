import { Component, Input, OnInit } from "@angular/core";
import {
    CurrentOrganizationService,
    Organization,
    RoleService,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import {
    GrowlService,
    ModalButton,
    SessionOrganization,
    SessionService,
    SfValidators,
    SortUtilitiesService,
    UserSettingsService
} from "@sf/common";
import { first } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";

interface AdministratorUser {
    name: string;
    id: string;
    email: string;
    selected: boolean;
}

// prettier-ignore
@Component({
    selector: "sf-limited-trial-dialog",
    templateUrl: "./limited-trial-dialog.component.html",
    styleUrls: ["./limited-trial-dialog.component.scss"]
})
export class LimitedTrialDialogComponent implements OnInit {
    @Input() organizationID: string;
    @Input() organizationName: string;
    @Input() productID: string;
    @Input() productLabel: string;

    isAdmin = false;
    productBenefits = "do more";
    productSpecificParagraph: string = null;
    administratorUsers: AdministratorUser[] = [];
    loadingAdmins = true;
    requestSent = false;
    primary: ModalButton;
    secondary: ModalButton;

    constructor(
            private activeModal: NgbActiveModal,
            private roleService: RoleService,
            private userorgService: UserOrgService,
            private activationService: UserorgActivationService,
            private router: Router,
            private route: ActivatedRoute,
            private currentOrgService: CurrentOrganizationService,
            private growlService: GrowlService,
            private userSettingsService: UserSettingsService,
            private sessionService: SessionService
    ) {
    }

    ngOnInit(): void {
        this.isAdmin = this.sessionService.hasPermission("organization_admin", this.organizationID);

        // Modal buttons
        this.primary = {
            text: this.isAdmin ? "Activate Service" : "Send",
            disabled: false,
            callback: this.initiateRequest.bind(this)
        };
        this.secondary = {
            text: "Not Now",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.buildProductBenefits();

        if (!this.isAdmin) {
            this.roleService
                .getUsersWithPermissions(this.organizationID)
                .pipe(first())
                .subscribe((response: any) => {
                    this.loadingAdmins = false;
                    let users: any[] = [];
                    if (response.users) {
                        users = response.users.sort((a: any, b: any) => {
                            return SortUtilitiesService.stringSortCompareInsensitive(a.name, b.name);
                        });
                    }

                    let administrators: any = {};
                    users.forEach((user) => {
                        // is this user an admin for this org?
                        user.permissions.forEach((permission: any) => {
                            if (permission.name == "organization_admin" || permission.name == "submitter_admin") {
                                administrators[user.name] = user;
                            }
                        });
                    });

                    // note that administrators is an object - not array
                    Object.values(administrators).forEach((user: any) => {
                        this.administratorUsers.push({
                            name: user.name,
                            id: user.id,
                            email: user.email,
                            selected: true
                        });
                    });
                });
        }
    }

    buildProductBenefits() {
        switch (this.productID) {
            case "submitter_signing":
                this.buildDocBuilderBenefits();
                break;
            default:
                break;
        }
    }

    buildDocBuilderBenefits() {
        this.productBenefits = "create, sign, and notarize documents electronically";

        // UserConfiguration.getInteger(UserConfiguration.Option.DOCUMENT_BUILDER_TRIAL_DOCUMENTS_COUNT)
        let docCount = this.userSettingsService.getUserSetting("DOCUMENT_BUILDER_TRIAL_DOCUMENTS_COUNT");
        if (SfValidators.isIntegerString(docCount)) {
            this.productSpecificParagraph = "You have " + docCount + " Document Builder trial documents remaining.";
        }
    }

    goToResourceCenter() {
        this.userorgService.goToResourceCenter();
    }

    initiateRequest() {
        if (this.isAdmin) {
            this.initiateActivation();
        } else {
            this.initiateNotification();
        }
    }

    initiateNotification() {
        this.requestSent = true;

        let notifyAdmins: string[] = [];
        this.administratorUsers.forEach((user) => {
            if (user.selected) {
                notifyAdmins.push(user.id);
            }
        });

        this.activationService.requestAdminActivation(this.organizationID, this.productID, notifyAdmins)
            .subscribe((results: any) => {
                this.activeModal.close(true);
                this.growlService.success("An email message has been sent to your administrator.");
            }, (err: any) => {
                log.error("error", err);
                this.requestSent = false;
            });
    }

    initiateActivation() {
        this.requestSent = true;

        this.activationService.activateProductFromTrial(this.organizationID, this.productID)
            .subscribe((url: string) => {
                this.activeModal.close(url);
                this.router.navigateByUrl(url);
            }, (err: any) => {
                log.error("error", err);
                this.requestSent = false;
            });
    }

    cancel() {
        this.activeModal.close(false);
    }
}
