import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "./rpc-client.service";

@Injectable({
    providedIn: "root"
})
export class ApiGuidesService {
    constructor(protected _rpcClientService: RpcClientService) {}

    getApiGuidesSigninURL() {
        return this._rpcClientService.makeRequest(
            "api",
            "getApiGuidesSigninURL"
        );
    }
}
