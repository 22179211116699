import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    Input
} from "@angular/core";
import { GlobalViewContainerService, QueryStringService } from "@sf/common";

@Component({
    selector: "sf-banners",
    templateUrl: "./banners.component.html",
    styleUrls: ["./banners.component.scss"]
})
export class BannersComponent implements OnInit, AfterViewInit {
    @Input()
    isSupportBanner?: boolean;

    isRestricted = false;
    @ViewChild("supportBanner", { static: true, read: ViewContainerRef })
    supportBanner: ViewContainerRef;
    @ViewChild("notificationBanner", { read: ViewContainerRef })
    notificationBanner: ViewContainerRef;

    constructor(
        private _viewContainer: GlobalViewContainerService,
        private queryStringService: QueryStringService
    ) {}

    ngOnInit() {
        if (this.isSupportBanner) {
            this._viewContainer.register("supportBanner", this.supportBanner);
        }

        this.isRestricted =
            !!this.queryStringService.getQueryParam("restricted");
    }

    ngAfterViewInit() {
        if (!this.isSupportBanner) {
            this._viewContainer.register(
                "notificationBanner",
                this.notificationBanner
            );
        }
    }
}
