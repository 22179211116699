import { ActiveService, GlobalSearchResult } from "@sf/common";
import * as dayjs from "dayjs";
import { Address, Name, Phone } from "@sf/userorg/common";

export type UniversalResult =
    | OrganizationResult
    | PackageResult
    | UserResult
    | EsignEventResult
    | CapcLoanResult;

export interface PackageResult extends GlobalSearchResult {
    createDate: string;
    documentInfo: DocumentInfo[];
    id: string;
    label: string;
    name: string;
    objectType: ResultObjectType;
    recipientID: string;
    recipientName: string;
    status: string;
    statusDate: string;
    statusString: string;
    submitterID: string;
    submitterName: string;
}

export interface DocumentInfo {
    bookNumber: string;
    creationUserID: string;
    docStatus: string;
    documentName: string;
    entryNumber: string;
    fee: string;
    id: string;
    instrumentNumber: string;
    isRetrieved: boolean;
    kindOfInstrument: string;
    koiExternalID: string;
    pageCount: number;
    pageNumber: string;
    recordDate: string;
    recordingInfo: string;
    rejectionReasons: string[];
    status: string;
    statusDate: string;
    version: number;
}
export interface UserResult extends GlobalSearchResult {
    address: Address;
    attempts: number;
    canModify: boolean;
    createdDate: string;
    email: string;
    fax: string;
    faxText: string;
    id: string;
    isArchived: boolean;
    isLockedOut: boolean;
    isSuspended: boolean;
    jobTitle: string;
    lastLoginAttempt: string;
    lastLoginDayjs: dayjs.Dayjs;
    lastLoginFromNow: string;
    lastSuccessfulLogin: string;
    mobilePhone: string;
    mobilePhoneText: string;
    modifiedDate: string;
    name: Name;
    objectType: ResultObjectType;
    organizations: SimpleOrganization[];
    phone: Phone;
    phoneText: string;
    products: SimpleProduct[];
    status: string;
    statusClass: string;
    statusNumber: number;
    statusTooltip: string;
    username: string;
}

export interface SimpleOrganization {
    address: Address;
    id: string;
    name: string;
}

export interface SimpleProduct {
    id: string;
    label: string;
    shortLabel: string;
}

export interface OrganizationResult extends GlobalSearchResult {
    activeServices: ActiveService[];
    address: SimpleOrganizationAddress;
    archived: boolean;
    childIDs: string[];
    disabledReason?: string;
    foundByTransactionID: boolean;
    hasNoCounties: boolean;
    hasPaymentAccounts: boolean;
    hasUsers: boolean;
    id: string;
    inHierarchy: boolean;
    isEnabled: boolean;
    isRecipient: boolean;
    isSample: boolean;
    isSubmitter: boolean;
    label: string;
    name: string;
    objectType: ResultObjectType;
    parentId: string;
    parentOrgName: string;
    status: string;
    statusClass: string;
    statusNumber: number;
    statusTooltip: string;
}

export interface SimpleOrganizationAddress {
    city: string;
    state: string;
    street: string;
    zipCode: string;
}

export interface EsignEventResult extends GlobalSearchResult {
    label: string;
    name: string;
    objectType: ResultObjectType;
    organizationName: string;
    status: string;
}

export interface CapcLoanResult extends GlobalSearchResult {
    borrowerName: string;
    id: string;
    lenderLoanNumber: string;
    lenderOrganizationName: string;
    objectType: ResultObjectType;
    settlementFileNumber?: string;
    status: string;
}

export enum ResultObjectType {
    ORGANIZATION = "organization",
    PACKAGE = "package",
    USER = "user",
    ESIGN_EVENT = "esignevent",
    CAPC_LOAN = "lenderLoan"
}
