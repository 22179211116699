import { Injectable } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn
} from "@angular/forms";
import { dayjs } from "../date-and-time/plugins/dayjs/index";

@Injectable()
export class SfValidators {
    // public regular expressions
    public static readonly ZIPCODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
    public static readonly URL_REGEXP =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|properties|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    public static readonly ECHECK_MEMO_URL_REGEXP =
        /((http|ftp|https):\/\/)?([a-zA-Z\d]+:\/)?(\w+:\w+@)?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(\/.*)?/;
    public static readonly NUMERIC_REGEXP = /^\d+$/;
    public static readonly PHONE_REGEXP = /^([0-9]{10})?$/;
    public static readonly USERNAME_REGEXP = /^[A-Za-z0-9_\-\.]+$/;
    public static readonly EMAIL_REGEXP =
        /^(((\s*[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))((\s*[,;]\s*)|(\s*(?!\s*\S))))+$/;
    public static readonly ORGNAME_REGEXP = /^((https?):\/\/)|^(www.)/;
    public static readonly CC_EXP_REGEXP = /^\d{2}\/\d{4}$/;
    public static readonly FEE_REGEXP = "^\\d{0,7}(\\.\\d{1,3})?$";
    public static readonly FEE_VENDOR_REGEXP = "^(?!.?$)\\d{0,7}(.\\d{0,3})?$";
    public static readonly GUID_REGEXP =
        /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/;

    //validates bank routing number
    public static routingNumberValidator(routingNumber: FormControl): any {
        if (routingNumber.pristine) {
            return null;
        }
        routingNumber.markAsTouched();
        let result: any = SfValidators.numericValidator(routingNumber);
        if (result != null) {
            return result;
        }

        let sum: number = 0,
            i: number = 0;
        if (
            routingNumber.value &&
            routingNumber.value.length &&
            routingNumber.value.length > 0
        ) {
            for (i = 0; i < routingNumber.value.length; i += 3) {
                sum +=
                    parseInt(routingNumber.value.charAt(i), 10) * 3 +
                    parseInt(routingNumber.value.charAt(i + 1), 10) * 7 +
                    parseInt(routingNumber.value.charAt(i + 2), 10);
            }
        }
        if (sum != 0 && sum % 10 == 0) {
            return null;
        } else {
            return {
                invalidChecksum: true
            };
        }
    }

    // Validates URL
    public static urlValidator(url: any): any {
        if (url.pristine) {
            return null;
        }
        url.markAsTouched();
        if (!url.value) {
            // null, empty, undefined, are OK
            return null;
        }
        if (SfValidators.URL_REGEXP.test(url.value)) {
            return null;
        }
        return {
            invalidUrl: true
        };
    }

    // Checks for leading/trailing spaces
    public static ltSpace(ctrl: AbstractControl): ValidationErrors {
        if (ctrl.pristine) {
            return null;
        }
        ctrl.markAsTouched();
        let strVal: string = String(ctrl.value).trim();
        if (strVal !== ctrl.value) {
            return {
                ltSpace: true
            };
        }
        return null;
    }

    // Validates email address
    // Note that there is already a standard Angular validator for email addresses, but it is a little different
    public static emailValidator(email: any): any {
        if (email.pristine) {
            return null;
        }
        email.markAsTouched();
        if (!email.value) {
            // null, empty, undefined, are OK
            return null;
        }
        if (SfValidators.EMAIL_REGEXP.test(email.value)) {
            return null;
        }
        return {
            invalidEmail: true
        };
    }

    // Validates creating a valid username
    public static usernameValidator(username: any): any {
        if (username.pristine) {
            return null;
        }
        username.markAsTouched();
        if (SfValidators.USERNAME_REGEXP.test(username.value)) {
            return null;
        }
        return {
            invalidPassword: true
        };
    }

    // Validates numeric strings
    public static numericValidator(ctrl: any): any {
        if (ctrl.pristine) {
            return null;
        }
        ctrl.markAsTouched();
        if (!ctrl.value) {
            // null, empty, 0,  undefined, are all OK
            return null;
        }
        if (SfValidators.NUMERIC_REGEXP.test(ctrl.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates numeric strings
    public static nonZeroNumberValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        number.markAsTouched();
        if (SfValidators.isNonZeroNumberString(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates numeric strings
    public static positiveNumberValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        number.markAsTouched();
        if (SfValidators.isPositiveFloatString(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates numeric strings
    public static positiveIntegerValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        number.markAsTouched();
        if (SfValidators.isPositiveIntegerString(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates US phone numbers
    // Note that this will ignore any non-numeric digits in the input string
    public static phoneValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        number.markAsTouched();
        if (!number.value) {
            // null, empty, undefined, are OK
            return null;
        }
        let justNumbers = SfValidators.getNumericDigits(number.value);
        if (
            justNumbers &&
            justNumbers.length == 10 &&
            SfValidators.PHONE_REGEXP.test(justNumbers)
        ) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates zip codes
    public static zipCodeValidator(zip: any): any {
        if (zip.pristine) {
            return null;
        }
        zip.markAsTouched();
        if (!zip.value) {
            return null;
        }
        if (SfValidators.ZIPCODE_REGEX.test(zip.value)) {
            return null;
        }
        return {
            invalidZip: true
        };
    }

    public static nameFieldValidator(fld: any): any {
        if (fld.pristine) {
            return null;
        }
        fld.markAsTouched();
        if (!fld.value) {
            return null;
        }
        if (!SfValidators.hasInvalidNameCharacters(fld.value)) {
            return null;
        }
        return {
            invalidName: true
        };
    }

    //validates credit card expiration in form of MM/YY
    public static creditCardExp(ctrl: AbstractControl): any {
        if (ctrl.pristine) {
            return null;
        }
        ctrl.markAsTouched();
        if (!ctrl.value) {
            return null;
        }
        if (!SfValidators.CC_EXP_REGEXP.test(ctrl.value)) {
            return {
                invalidExpFormat: true
            };
        }

        let tokens: string[] = ctrl.value.split("/");
        let month: number = parseInt(tokens[0], 10);
        if (month < 1 || month > 12) {
            return {
                invalidMonth: true
            };
        }

        let now = dayjs();
        let test = dayjs(ctrl.value, "MM/YYYY");
        if (test.isBefore(now)) {
            return {
                invalidExp: true
            };
        }

        return null; //all is well
    }

    // remove non-numeric characters from a string
    // original purpose was for phone numbers
    public static getNumericDigits(original: string): string {
        const JUST_NUMERIC_REGEXP = /\D/g;
        if (!original) {
            return original;
        }
        let updated = original.replace(JUST_NUMERIC_REGEXP, "");
        return updated;
    }

    public static formatPhone(original: string): string {
        if (!original) {
            return original;
        }
        let numbers = SfValidators.getNumericDigits(original);
        if (numbers.length != 10) {
            return original;
        }
        let areaCode = numbers.substring(0, 3);
        let prefix = numbers.substring(3, 6);
        let suffix = numbers.substring(6, 10);
        return areaCode + "." + prefix + "." + suffix;
    }

    public static formatUrl(original: string): string {
        if (!original) {
            return original;
        }
        const prefix = "http://";
        const securePrefix = "https://";
        let originalPrefix = original.substring(0, 7);
        let originalSecurePrefix = original.substring(0, 8);
        if (
            originalPrefix === prefix ||
            originalSecurePrefix === securePrefix
        ) {
            return original;
        }
        return prefix + original;
    }

    static minLengthAfterTrim(minLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let trimmedVal = (control.value || "").trim();
            return trimmedVal.length >= minLength
                ? null
                : { minlength: `Length must be at least ${minLength}` };
        };
    }

    public static notAllWhitespace(ctrl: AbstractControl): any {
        if (ctrl.pristine) {
            return null;
        }
        ctrl.markAsTouched();
        if (!ctrl.value) {
            return null;
        }
        if (!SfValidators.isAllWhitespace(ctrl.value)) {
            return null;
        }
        return {
            allWhitespace: true
        };
    }

    static isAllWhitespace(str: string): boolean {
        if (!str) {
            // if the string is empty, then by definition it is not all whitespace
            return false;
        }
        return str.trim().length == 0;
    }

    static getNumberFromString(n: string | number): number {
        if (typeof n === "undefined" || n == null) {
            return null;
        }

        let num: number;

        if (typeof n !== "string") {
            if (typeof n === "number") {
                num = n;
            } else {
                return null;
            }
        } else {
            if (!n || !n.trim()) {
                return null;
            }
            num = Number(n.replace(/,/g, "").trim());
        }

        return num;
    }

    static isNonZeroNumberString(n: string): boolean {
        let num: number = this.getNumberFromString(n);
        if (num === null || num === 0 || Number.isNaN(num)) {
            return false;
        }

        return true;
    }

    static isPositiveFloatString(n: string | number): boolean {
        let num = this.getNumberFromString(n);
        if (num == null) {
            return false;
        }

        return num >= 0;
    }

    static isPositiveIntegerString(n: string | number): boolean {
        let num = this.getNumberFromString(n);
        if (num == null) {
            return false;
        }

        if (!Number.isInteger(num)) {
            return false;
        }

        return num >= 0;
    }

    static isIntegerString(n: string | number): boolean {
        let num: number = this.getNumberFromString(n);
        if (num == null) {
            return false;
        }

        return Number.isInteger(num);
    }

    /**
     * Check for invalid chars in a field like an organization name or other field
     * Currently checks for non-breaking space characters
     * returns the name of any invalid character found
     */
    static hasInvalidNameCharacters(text: string): string {
        if (!text) {
            return null;
        }

        // nbsp = alt-255
        if (text.indexOf("\u00A0") != -1) {
            return "non-breaking space";
        }

        // en-dash = alt-0150
        if (text.indexOf("\u2013") != -1) {
            return "en-dash";
        }

        // em-dash = alt-0151
        if (text.indexOf("\u2014") != -1) {
            return "em-dash";
        }

        // non-breaking hyphen
        if (text.indexOf("\u2011") != -1) {
            return "non-breaking hyphen";
        }

        return null;
    }

    static isPastDate(dateControl: AbstractControl): ValidationErrors {
        const today = dayjs().startOf("day");
        if (
            !dayjs.isDayjs(dateControl.value) ||
            !dateControl.value?.isValid()
        ) {
            return {
                invalidDate: "Invalid Date"
            };
        }

        if (dateControl.value.isAfter(today, "day")) {
            return {
                invalidDate: "Date must be in the past"
            };
        }

        return null;
    }

    static isFutureDate(dateControl: AbstractControl): ValidationErrors {
        const today = dayjs();
        if (
            !dayjs.isDayjs(dateControl.value) ||
            !dateControl.value?.isValid()
        ) {
            return {
                invalidDate: "Invalid Date"
            };
        }

        if (dateControl.value.isBefore(today, "day")) {
            return {
                invalidDate: "Date must be in the future"
            };
        }

        return null;
    }

    public static echeckMemoNoUrl(url: any): any {
        if (url.pristine) {
            return null;
        }
        url.markAsTouched();
        if (!url.value) {
            // null, empty, undefined, are OK
            return null;
        }
        if (!SfValidators.ECHECK_MEMO_URL_REGEXP.test(url.value)) {
            return null;
        }
        return {
            memoHasUrl: true
        };
    }

    public static testFee(amount: number): boolean {
        let regex: RegExp = new RegExp(this.FEE_REGEXP);
        return regex.test(amount + "");
    }

    public static testVendorFee(amount: number): boolean {
        let regex: RegExp = new RegExp(this.FEE_VENDOR_REGEXP);
        return regex.test(amount + "");
    }

    public static isGUID(ctrl: AbstractControl): ValidationErrors {
        if (ctrl.pristine) {
            return null;
        }
        ctrl.markAsTouched();
        if (!ctrl.value) {
            // null, empty, undefined, are OK
            return null;
        }
        if (!SfValidators.GUID_REGEXP.test(ctrl.value)) {
            return {
                invalidGUID: true
            };
        }
        return null;
    }
}
