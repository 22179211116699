import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PreChatDialogComponent } from "@sf/chat";
import { SessionService } from "@sf/common";

@Component({
    selector: "sf-contact-control",
    templateUrl: "./contact-control.component.html",
    styleUrls: ["./contact-control.component.scss"]
})
export class ContactControlComponent {
    constructor(
        private _sessionService: SessionService,
        private _modalService: NgbModal
    ) {}

    openChatModal() {
        const modalRef = this._modalService.open(PreChatDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        // pass the organization Ids for the user
        modalInstance.userOrganizationIDs = this._sessionService.getOrgIds();

        // pass the username and orgID
        modalInstance.username = this._sessionService.getUsername();
        modalInstance.organizationID =
            this._sessionService.getDefaultOrganizationID();
        modalInstance.isRecipient =
            this._sessionService.hasProductInAnyOrg("recipient");
        modalInstance.initUrl = window.location.href;
    }
}
