<sf-modal-header title="Internet Explorer Compatibility Warning"></sf-modal-header>
<div class="modal-body">
    <div class="yelling">
        <span>Internet Explorer is no longer supported as of June 14, 2022</span>
    </div>
    <div class="lesson">
        <span>To avoid issues using Simplifile, please immediately start using a supported browser such as Chrome or Edge.</span>
    </div>
    <div class="browsers-box">
        <div class="browser mr-2">
            <div class="browser-image">
                <img src="/sf/ui/images/chrome-logo-72.png" alt=""/>
            </div>
            <div class="right-side">
                <div class="browser-name">Google Chrome</div>
                <button class="btn btn-secondary browser-button" autofocus (click)="goChrome()">Learn More</button>
            </div>
        </div>
        <div class="browser ml-2">
            <div class="browser-image">
                <img src="/sf/ui/images/edge-logo-72.png" alt=""/>
            </div>
            <div class="right-side">
                <div class="browser-name">Microsoft Edge</div>
                <button class="btn btn-secondary browser-button" (click)="goEdge()">Learn More</button>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <span>
        <button class="btn btn-primary" (click)="doPrimary()">I Understand</button>
    </span>
</div>
