import { Component, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-user-locked-dialog",
    templateUrl: "./user-locked-dialog.component.html",
    styleUrls: ["./user-locked-dialog.component.scss"]
})
export class UserLockedDialogComponent implements OnInit {
    primary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
    }

    doPrimary() {
        this.activeModal.close(true);
    }
}
