import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class AdminUserService {
    constructor(
        protected _rpc: RpcClientService
    ) {
    }

    reloadUsers(userIDs: string[]): Observable<any> {
        return this._rpc.makeRequest("admin", "reloadUsers", {
            userIDs: userIDs
        });
    }
}
