import { Component, Input, OnInit } from "@angular/core";
import { User } from "@sf/userorg/common";
import { ModalButton } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-salesperson-contact-dialog",
    templateUrl: "./salesperson-contact-dialog.component.html",
    styleUrls: ["./salesperson-contact-dialog.component.scss"]
})
export class SalespersonContactDialogComponent implements OnInit {
    @Input() salesperson: User;

    primary: ModalButton;
    secondary: ModalButton = null;

    constructor(
            private activeModal: NgbActiveModal,
            private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.close.bind(this)
        };
    }

    close() {
        this.activeModal.close(false);
    }
}
