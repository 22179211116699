import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { RPC_PATH, INTERNATIONAL_RPC_PATH } from "../helpers/http.helpers";
import { catchError } from "rxjs/operators";
import { isObjectEmpty } from "../helpers/object";
import { ActivatedRoute } from "@angular/router";
import { GrowlService } from "../growl/growl.service";
import { Environment, ENVIRONMENT } from "../environment";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable({
    providedIn: "root"
})
export class RpcClientService {
    constructor(
        private _http: HttpClient,
        private _growlService: GrowlService,
        private _route: ActivatedRoute,
        @Inject(ENVIRONMENT) private _environment: Environment
    ) {}

    makeRequest<T>(
        path: string,
        method: string,
        data?: any,
        overrideDefaultErrorHandling?: boolean,
        disableGrowlErrorTimeout?: boolean
    ): Observable<T>;
    makeRequest(
        path: string,
        method: string,
        data?: any,
        overrideDefaultErrorHandling?: boolean,
        disableGrowlErrorTimeout?: boolean
    ): Observable<any> {
        return this._makeRPCCall(
            path,
            method,
            data,
            overrideDefaultErrorHandling,
            false,
            disableGrowlErrorTimeout
        );
    }

    makeInternationalRequest<T>(
        path: string,
        method: string,
        data?: any,
        overrideDefaultErrorHandling?: boolean
    ): Observable<T>;
    makeInternationalRequest(
        path: string,
        method: string,
        data?: any,
        overrideDefaultErrorHandling?: boolean
    ): Observable<any> {
        return this._makeRPCCall(
            path,
            method,
            data,
            overrideDefaultErrorHandling,
            true
        );
    }

    private _makeRPCCall(
        path: string,
        method: string,
        data?: any,
        overrideDefaultErrorHandling?: boolean,
        international?: boolean,
        disableGrowlErrorTimeout?: boolean
    ) {
        if (!data) {
            data = {};
        }
        return this._http
            .request(
                "post",
                `${
                    international ? INTERNATIONAL_RPC_PATH : RPC_PATH
                }${path}/${method}`,
                {
                    body: data
                }
            )
            .pipe(
                catchError((errorObj: any) => {
                    if (errorObj === "connection error") {
                        this._handleErrorRedirect(errorObj);
                        return;
                    }
                    // Angular error handling
                    if (overrideDefaultErrorHandling) {
                        return throwError(errorObj);
                    }
                    this._handleMalformedErrorsAndSendErrorGrowl(
                        errorObj,
                        method,
                        disableGrowlErrorTimeout
                    );
                    return throwError(errorObj);
                })
            );
    }

    private _sendDeveloperWarningGrowl(wrongWay: string, method: string) {
        if (!this._environment.production) {
            this._growlService.warning(
                `Try using "throw new UIException("User friendly Message") instead of something like "${wrongWay}". Check your endpoint: "${method}"`,
                "Hey Developer!",
                {
                    disableTimeOut: true
                }
            );
        }
    }

    private _handleErrorRedirect(error: any) {
        // if (!!$stateParams.restricted)
        if (this._route.snapshot.queryParamMap.get("restricted")) {
            (window as any).location =
                "/sf/ui/login/?err=restrictedExpired&app=Encompass";
        } else {
            (window as any).location = "/sf/ui/login/?err=expired";
        }
    }

    private _handleMalformedErrorsAndSendErrorGrowl(
        errorObj: any,
        method: string,
        disableGrowlErrorTimeout?: boolean
    ) {
        try {
            // Handle various ways the backend can return errors and let developers know to fix
            const resp = errorObj.error;

            let errorToReport = resp.errorMessage; // Default error structure

            // Lender errors
            if (resp.errors && resp.errors.error) {
                errorToReport = resp.errors.error;
            }

            if (resp.data && !isObjectEmpty(resp.data)) {
                let msg = resp.data;
                if (typeof resp.data != "string") {
                    if (
                        resp.errorMessage &&
                        typeof resp.errorMessage == "string"
                    ) {
                        msg = resp.errorMessage;
                    } else if (
                        resp.data.error &&
                        typeof resp.data.error == "string"
                    ) {
                        msg = resp.data.error;
                    } else {
                        msg = "Unknown error";
                    }
                }
                log.getLogger(LoggerType.FALCON).error(msg);
            }

            if (
                errorToReport === "session is not valid" ||
                errorToReport === "invalid credentials"
            ) {
                this._handleErrorRedirect(errorToReport);
                return;
            }

            // UIResponse.failure();
            if (
                !resp.success &&
                !resp.errorMessage &&
                isObjectEmpty(resp.data)
            ) {
                this._sendDeveloperWarningGrowl(
                    `UIResponse.failure();`,
                    method
                );
            }
            // UIResponse.failure("specific error message");
            else if (resp.errorMessage && isObjectEmpty(resp.data)) {
                this._growlService.error(errorToReport);
                this._sendDeveloperWarningGrowl(
                    `UIResponse.failure("specific error message");`,
                    method
                );
            }
            // UIResponse.failure("specific error message", exception);
            else if (resp.errorMessage && typeof resp.data === "string") {
                this._growlService.error(errorToReport);
                this._sendDeveloperWarningGrowl(
                    `UIResponse.failure("specific error message", exception);`,
                    method
                );
            }
            // UIResponse.failure("errorMessage", "specific error message");
            else if (!resp.errorMessage && resp.data.errorMessage) {
                this._growlService.error(resp.data.errorMessage);
                this._sendDeveloperWarningGrowl(
                    `UIResponse.failure("errorMessage", "specific error message");`,
                    method
                );
            } else {
                if (errorToReport) {
                    this._growlService.error(errorToReport, null, {
                        disableTimeOut: disableGrowlErrorTimeout ? true : false
                    });
                }
            }
        } catch (e) {}
    }
}
