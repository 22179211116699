import { Name } from "./name.interface";

export interface UIEEImage {
    base64Image: string;
    width: number;
    height: number;
}

export enum ESignEventParticipantRole {
    Attorney = "Attorney",
    Borrower = "Borrower",
    Buyer = "Buyer",
    AuthorizedRepresentative = "AuthorizedRepresentative",
    Builder = "Builder",
    CorporateOfficerCorporation = "CorporateOfficerCorporation",
    DocumentObserver = "DocumentObserver",
    Grantee = "Grantee",
    Grantor = "Grantor",
    Interpreter = "Interpreter",
    Lender = "Lender",
    LoanOfficer = "LoanOfficer",
    Originator = "Originator",
    Other = "Other",
    NonPurchasingEntity = "NonPurchasingEntity",
    PowerOfAttorney = "PowerOfAttorney",
    PropertySeller = "PropertySeller",
    RealEstateAgent = "RealEstateAgent",
    Seller = "Seller",
    SettlementAgent = "SettlementAgent",
    Signer = "Signer",
    Trustee = "Trustee",
    Witness = "Witness"
}

export interface UIESignEventParticipantMetadata {
    isSigner: boolean;
    hasSigned: boolean;
    signedAllAncillaryDocs: Date; // TODO: figure out if dates are passed number or string.
    signedAllWitnessDocs: Date; // TODO: figure out if dates are passed number or string.
    ancillaryDocs: string[];
    ancillaryDocsSigned: string[];
    witnessDocsNeedingSigning: number;
    ancillaryDocsNeedingSigning: number;
    simulatedScreenShareDimensions?: { width: number; height: number };
}

export interface UIESignEventParticipant {
    id: string;
    role: ESignEventParticipantRole;
    roleOtherDescription?: string;
    roleLabel: string;
    roleIndex: number;
    name: Name;
    emailAddress?: string;
    mobilePhone?: string;
    companyName?: string;
    metadata?: UIESignEventParticipantMetadata;
    oneOfMany?: boolean; // do we need participant ordering
    fullName?: string;
    reviewedAllDocs?: Date;
    returnUrl?: string;
    canViewDocsStartDate?: string;
    canSignNonWitnessDocsStartDate?: string;
    canSignNonWitnessDocsEndDate?: string;
    certificationID?: string;
    /** FE only variables **/
    canViewDocs?: boolean;
    tooltip?: string;
    signatureType?: "N/A" | "Digital" | "Drawn";
}

export interface UIESignEventInterpreterDetails {
    address: {
        street1: string;
        street2: string;
        city: string;
        state: string;
        zipCode: string;
        county: string;
    };
    certificationID: string;
}

export enum IDType {
    KNOWN = "Personally Known to Me",
    LICENSE = "Driver License",
    PASSPORT = "Passport",
    OTHER = "Other Government Issued ID"
}
