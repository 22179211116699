<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="doc.status !== 'Sent' && doc.status !== 'Ready'">
        <fa-icon
            class="print-icon"
            (click)="openPrintWindow(doc.status, doc.id)"
            icon="print"
            ngbTooltip="Print status report"
        >
        </fa-icon>
    </div>
    <h4>
        <button
            *ngIf="!inDocViewer"
            class="btn btn-link sf-btn-link sf-mask"
            [ngClass]="doc.status"
            (click)="goToDocViewerURL()"
        >
            {{ doc.name }} - {{ doc.status }}
        </button>
        <span *ngIf="inDocViewer && !originalPdf" [ngClass]="doc.status" class="sf-mask">
            {{ doc.name }} - {{ doc.status }}
        </span>
        <a
            *ngIf="inDocViewer && originalPdf"
            (click)="openOriginalPdf()"
            [ngClass]="doc.status"
            class="sf-mask"
        >
            {{ doc.name }} - {{ doc.status }} (View Original Document)
        </a>
    </h4>
    <hr />
    <p>{{ statusMessage }}</p>
    <div
        *ngIf="
            doc.rejectionReasons &&
            doc.rejectionReasons.length &&
            (!validationErrors || validationErrors.length === 0)
        "
    >
        <h5 class="sf-mask">
            Rejected by {{ doc.rejectingRecipient.displayName }} - Reason{{
                doc.rejectionReasons.length > 1 ? "s" : ""
            }}
        </h5>
        <ul>
            <li *ngFor="let reason of doc.rejectionReasons" class="sf-mask">
                {{ reason }}
            </li>
        </ul>
    </div>
    <div *ngIf="!!validationErrors && validationErrors.length">
        <h5>
            {{
                validationErrors.length > 1
                    ? "Items that require attention"
                    : "Item that requires attention"
            }}
        </h5>
        <ul>
            <li *ngFor="let error of validationErrors">
                {{ error }}
            </li>
        </ul>
    </div>
    <div
        class="rejection-suggestions"
        *ngIf="rejectionSuggestions && rejectionSuggestions.length > 0"
    >
        <h5>
            Simplifile Suggestion{{
                rejectionSuggestions.length > 1 ? "s" : ""
            }}
        </h5>
        <ul>
            <li *ngFor="let suggestion of rejectionSuggestions">
                <div [innerHtml]="suggestion.suggestion"></div>
                <div *ngIf="!suggestion.feedbackRecorded">
                    <div>Is this suggestion helpful?</div>
                    <div class="suggestion-feedback-button-wrapper">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="sendFeedback(suggestion, 'HELPFUL')"
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="sendFeedback(suggestion, 'NOT_HELPFUL')"
                        >
                            No
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <!-- Voided Reasons-->
    <div *ngIf="!!voidedEvents && voidedEvents.length">
        <div class="mb-3">
            Document ID: {{doc.id}}
            <div *ngIf="pkg.recipientPackageID" class="sf-mask">Remote ID: {{pkg.recipientPackageID}}</div>
        </div>
        <h5>Reason{{ voidedEvents.length > 1 ? "s" : "" }} for voiding</h5>
        <ul>
            <li *ngFor="let event of voidedEvents" class="sf-mask">{{ event }}</li>
        </ul>
    </div>
    <div
        *ngIf="
            (doc.rejectionReasons && doc.rejectionReasons.length) ||
            (validationErrors && validationErrors.length)
        "
    >
        <h5>Questions?</h5>
        <ul>
            <li *ngIf="shouldShowRecipientPhoneNumber()">
                {{ doc.rejectingRecipient.name }}:
                {{ doc.rejectingRecipient.phone | formatPhone }}
            </li>
            <li
                *ngIf="
                    !shouldShowRecipientPhoneNumber() &&
                    pkg.recipient &&
                    pkg.recipientPhone
                "
            >
                {{ pkg.recipient }}: {{ pkg.recipientPhone | formatPhone }}
            </li>
            <li>Simplifile Support: {{ supportPhoneNumber | formatPhone }}</li>
        </ul>
    </div>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
