import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatPhone"
})
export class FormatPhonePipe implements PipeTransform {
    private _area: string;
    private _number: string;

    transform(
        tel: any,
        format?: "DotOnly" | "DashOnly" | "Traditional"
    ): string {
        if (!tel) {
            return "";
        }

        let digits = tel
            .toString()
            .trim()
            .replace(/[^0-9]+/g, "");

        switch (digits.length) {
            case 7: // #######
                this._area = "";
                this._number = digits;
                break;

            case 10: // PPP#######
                this._area = digits.slice(0, 3);
                this._number = digits.slice(3);
                break;

            case 11: // CPPP#######
                this._area = digits.slice(1, 4);
                this._number = digits.slice(4);
                break;

            case 12: // CCPPP#######
                this._area = digits.slice(2, 5);
                this._number = digits.slice(5);
                break;

            default:
                return tel;
        }

        if (format === "DashOnly") {
            return this._formatWithSeparator("-");
        } else if (format === "Traditional") {
            return this._formatTraditional();
        } else {
            return this._formatWithSeparator(".");
        }
    }

    private _separateNumber(separator: string): void {
        this._number =
            this._number.slice(0, 3) + separator + this._number.slice(3);
    }

    private _formatWithSeparator(separator: string): string {
        let formattedNumber = "";
        this._separateNumber(separator);
        if (this._area?.length === 3) {
            formattedNumber = this._area + separator + this._number;
        } else {
            formattedNumber = this._number;
        }
        return formattedNumber;
    }

    private _formatTraditional(): string {
        let formattedNumber = "";
        this._separateNumber("-");
        if (this._area?.length === 3) {
            formattedNumber = `(${this._area}) ${this._number}`;
        } else {
            formattedNumber = this._number;
        }
        return formattedNumber;
    }
}
