import { Injectable, SecurityContext } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PackageStatusModalComponent } from "./package-status-modal.component";
import { DomSanitizer } from "@angular/platform-browser";
import { SessionService } from "@sf/common";
import { combineLatest, Observable, of, Subject } from "rxjs";
import {
    PackageStatusDetails,
    PackageStatusValidationError as ValidationError,
    PackageStatusVoidedEvent as ValidationEvent
} from "./package-status-details.interface";
import { catchError, take } from "rxjs/operators";
import { DOC_STATUS } from "../constants/document-status.enum";
import { STATUS, SUB_STATUS } from "../constants/package-status.enum";

type withDocViewerLink = { docViewerLink?: string };
type PackageStatusVoidedEvent = ValidationEvent & withDocViewerLink;
type PackageStatusValidationError = ValidationError & withDocViewerLink;
type StatusEvent = PackageStatusVoidedEvent | PackageStatusValidationError;

@Injectable({
    providedIn: "root"
})
export class PackageStatusDialogService {
    private _packageID: string;
    private _documents: StatusEvent[];
    private _hasErrors: boolean;
    private _packageState: string;
    private _packageStatus: string;
    private _packageStatusString: string;
    private _statusMessage: string;
    private _title: string;
    private _statusMessages: {
        status: DOC_STATUS | STATUS | SUB_STATUS;
        message: string;
    }[];
    private _templateStates: DOC_STATUS[];
    private _printableStatuses: STATUS[];
    private _packageIsReleaseToRecord: boolean;
    private _recipientID: string;
    private _isMissingPaymentMethod: boolean = false;

    constructor(
        private _rpcClient: RpcClientService,
        private _modalService: NgbModal,
        private _sanitizer: DomSanitizer,
        private _sessionService: SessionService
    ) {
        this._openPrintWindow = this._openPrintWindow.bind(this);
        this._shouldAllowPrint = this._shouldAllowPrint.bind(this);
        this._initStatusMessages();
        this._initTemplateStates();
        this._initPrintableStatuses();
    }

    open(packageID: string, closed$?: Subject<void>) {
        this._packageID = packageID;
        combineLatest([
            this._getPackageStatusDetails(packageID),
            this._isMissingPaymentAccountMethod(packageID).pipe(
                catchError(() => {
                    return of(false);
                })
            )
        ])
            .pipe(take(1))
            .subscribe(([packageData, isMissingPaymentMethod]) => {
                this._isMissingPaymentMethod = isMissingPaymentMethod;
                this._initData(packageData);
                this._openModal(closed$);
            });
    }

    private _initData(data: PackageStatusDetails) {
        const usesCustomForms = data.usesCustomForms;
        let documents: Record<string, StatusEvent> = {};
        let hasErrors = false;
        // Combine the two arrays, removing any falsy values.
        const combined: StatusEvent[] = [
            ...data.validationErrors,
            ...data.voidedEvents
        ].filter((value) => Boolean(value));
        combined.forEach((item) => {
            documents[item.id] = { ...item };
            const document = documents[item.id];
            document.docViewerLink = `/sf/ui/document/viewer/${document.id}`;
            if (
                (this._isValidationError(document) &&
                    document.validationErrors?.length) ||
                (this._isVoidedEvent(document) && document.voidedEvents?.length)
            ) {
                const isTemplate = this._templateStates.includes(
                    document.status as DOC_STATUS
                );
                hasErrors = true;
                if (isTemplate) {
                    if (
                        this._isValidationError(document) &&
                        document.isCustomFormsTemplate
                    ) {
                        // ESignDocument created from a template - go to custom forms data entry page
                        document.docViewerLink = `/sf/ui/signing/data-entry/esign/${document.eSignDocumentID}/data-entry`;
                    } else if (
                        this._isValidationError(document) &&
                        document.isElectronicallyOriginated &&
                        document.eSignDocumentID
                    ) {
                        // ESignDocument created for UPF Services - go to documnt view page
                        document.docViewerLink = `/sf/ui/signing/data-entry/esign/${document.eSignDocumentID}/document`;
                    } else {
                        // Either a print driver doc or a quail template doc - go to package details
                        document.docViewerLink = `/sf/ui/submitter/package/${this._packageID}/details`;
                    }
                }
            }
        });

        this._documents = Object.values(documents);
        this._packageState = data.packageState;
        this._packageStatus = data.packageStatus;
        this._packageStatusString = data.packageStatusString;
        this._recipientID = data.recipientID;
        this._packageIsReleaseToRecord = data.packageIsReleaseToRecord;
        this._statusMessage = this._getStatusMessage();
        this._title = this._getTitle();
        // if the package is in READY but not released to record, then usually, the only errors on documents
        // are "The package has not been marked with the "Release to Record" option", which we should suppress.
        // We don't remove it from PackageHandler.java because other places still rely on that method of validation
        const hideErrors =
            !this._packageIsReleaseToRecord &&
            this._packageStatus === STATUS.READY;
        this._hasErrors = hasErrors && !hideErrors;
    }

    private _openModal(closed$?: Subject<void>): void {
        const modalRef = this._modalService.open(PackageStatusModalComponent);
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = this._title;
        modalInstance.statusMessage = this._statusMessage;
        modalInstance.hasErrors = this._hasErrors;
        modalInstance.documents = this._documents;
        modalInstance.openPrintWindow = this._openPrintWindow;
        modalInstance.shouldAllowPrint = this._shouldAllowPrint;
        modalInstance.packageID = this._packageID;
        modalInstance.packageStatus = this._packageStatus;
        modalInstance.recipientID = this._recipientID;

        if (closed$) {
            // Notify parent component when the dialog closes
            modalRef.result.finally(() => {
                closed$.next();
                closed$.complete();
            });
        }
    }

    private _getPackageStatusDetails(
        packageID: string
    ): Observable<PackageStatusDetails> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getPackageStatusDetails",
            { packageID }
        );
    }

    private _isMissingPaymentAccountMethod(
        packageID: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "isMissingPaymentAccountMethod",
            { packageID },
            true
        );
    }

    private _getStatusMessage() {
        let statusMessage = this._statusMessages.find(
            (message: { status: string }) =>
                message.status === this._packageStatus
        ).message;
        if (
            this._packageStatus === STATUS.DRAFT &&
            this._documents.length === 1
        ) {
            statusMessage =
                "This document is not ready to be submitted. <strong>Here is what must still be addressed:</strong>";
        } else if (
            !this._packageIsReleaseToRecord &&
            this._packageStatus === STATUS.READY
        ) {
            statusMessage =
                "This package can be submitted to the county once it is released for recording by an authorized user.";
        }

        if (
            this._packageStatus === STATUS.DRAFT &&
            this._isMissingPaymentMethod
        ) {
            statusMessage += `<p class="mt-4">Please select a payment account for each estimated fee type.</p>`;
        }
        return statusMessage;
    }

    private _getTitle() {
        switch (this._packageState) {
            case STATUS.DRAFT:
                return "This Package Is Not Ready To Be Submitted";
            case STATUS.REJECTED:
                return "Package is not ready to submit";
            case STATUS.BILLED_REJECTED:
                return "Rejected Fee";
            case STATUS.RECORDED:
                return this._packageStatusString;
            case STATUS.READY:
                if (!this._packageIsReleaseToRecord) {
                    return "Ready when released for recording";
                }
            default:
                return this._packageState;
        }
    }

    private _isValidationError(
        error:
            | PackageStatusValidationError
            | PackageStatusVoidedEvent
            | StatusEvent
    ): error is PackageStatusValidationError {
        return (
            (error as PackageStatusValidationError).eSignDocumentID !==
                undefined ||
            (error as PackageStatusValidationError)
                .isTemplateRecordableDocument !== undefined ||
            (error as PackageStatusValidationError).isCustomFormsTemplate !==
                undefined ||
            (error as PackageStatusValidationError)
                .isElectronicallyOriginated !== undefined ||
            (error as PackageStatusValidationError).validationErrors !==
                undefined ||
            (error as PackageStatusValidationError).docType !== undefined
        );
    }

    private _isVoidedEvent(
        error:
            | PackageStatusValidationError
            | PackageStatusVoidedEvent
            | StatusEvent
    ): error is PackageStatusVoidedEvent {
        return (
            (error as PackageStatusVoidedEvent).rejectingRecipient !==
                undefined ||
            (error as PackageStatusVoidedEvent).voidedEvents !== undefined
        );
    }

    private _openPrintWindow() {
        const status = this._sanitizer.sanitize(
            SecurityContext.URL,
            this._packageStatus
        );
        const packageID = this._sanitizer.sanitize(
            SecurityContext.URL,
            this._packageID
        );
        window.open(
            `/sf/validatePackageItems.jsp?status=${status}&packageID=${packageID}`,
            "_blank",
            "width=500,height=600,resizable,dependent,scrollbars"
        );
    }

    private _shouldAllowPrint() {
        return this._printableStatuses.includes(this._packageStatus as STATUS);
    }

    private _initStatusMessages() {
        this._statusMessages = [
            {
                status: DOC_STATUS.DATA_ENTRY,
                message: "Data entry is incomplete."
            },
            {
                status: DOC_STATUS.SIGN,
                message: "Document has not been signed by all required parties."
            },
            {
                status: DOC_STATUS.DATA_REVIEW,
                message:
                    "The document must be removed from the review folder before it is eligible to be submitted."
            },
            {
                status: DOC_STATUS.DRAFT,
                message:
                    "One or more documents in this package is not ready to be submitted. <strong>Here is what must still be addressed:</strong>"
            },
            {
                status: DOC_STATUS.CONVERTED,
                message:
                    "The package contains helper documents that have not been completed."
            },
            {
                status: DOC_STATUS.READY,
                message: "The package is ready to be submitted to the county."
            },
            {
                status: DOC_STATUS.SENT,
                message: "The package has been sent to the county for review."
            },
            {
                status: STATUS.SENT_REMOTE,
                message: "The package has been sent to the county for review."
            },
            {
                status: STATUS.RECEIVED,
                message:
                    "The package has been received by the county and will be reviewed shortly."
            },
            {
                status: DOC_STATUS.REVIEWING,
                message: "The package is being reviewed by the county."
            },
            {
                status: STATUS.ACCEPTED,
                message:
                    "The package has been accepted by the county and is awaiting review and/or recording."
            },
            {
                status: STATUS.REJECTED,
                message: "Please make corrections and re-submit."
            },
            {
                status: STATUS.BILLED_REJECTED,
                message:
                    "This package has been rejected and has a rejection fee."
            },
            {
                status: STATUS.RECORDED,
                message:
                    "The package has been recorded by the county and returned to Simplifile."
            },
            {
                status: SUB_STATUS.MAINTENANCE,
                message:
                    "The package has encountered an error and is being worked on by Simplifile staff."
            },
            { status: STATUS.VOIDED, message: "The package has been voided." },
            {
                status: DOC_STATUS.UNKNOWN,
                message: "The status of this package is unknown."
            },
            {
                status: SUB_STATUS.FINALIZING,
                message:
                    "The package has been recorded by the county but not returned to Simplifile yet. This may happen because the stamps have not been applied to the documents by the county yet or because the fees have not been calculated by the county. Typically packages will only stay in this state for 24 to 48 hours."
            },
            {
                status: STATUS.REMOVED,
                message: "The package has been removed."
            },
            {
                status: STATUS.MOU_COMPELTE,
                message: "This MOU package is complete."
            }
        ];
    }

    private _initTemplateStates() {
        this._templateStates = [
            DOC_STATUS.DATA_ENTRY,
            DOC_STATUS.DATA_REVIEW,
            DOC_STATUS.SIGN
        ];
    }

    private _initPrintableStatuses() {
        this._printableStatuses = [
            STATUS.DRAFT,
            STATUS.REJECTED,
            STATUS.BILLED_REJECTED
        ];
    }
}
