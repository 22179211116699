import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Navigation, NavItem } from "@sf/common";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserOrgService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { EncryptionService } from "@sf/common";
import { transformLinkWithParams } from "@sf/common";
import { first } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class UserPagesNavResolverService {
    private _userIDKey: string = "userID"; //in case param ever changes
    private _cachedPermissions: any = null;
    private _lastUsername: string = null;

    navigation$: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(
        null
    );

    constructor(
        private _userOrgService: UserOrgService,
        private _sessionService: SessionService,
        private _encryptionService: EncryptionService
    ) {}

    getPages(
        viewType: string,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Observable<Navigation>> {
        let username: string = this._encryptionService.decodeUnscramble(
            route.params[this._userIDKey]
        );
        if (username === this._lastUsername && this._cachedPermissions) {
            this._getNavigation(viewType, this._cachedPermissions, route);
        } else {
            this._userOrgService
                .userCanEditUser(username)
                .pipe(first())
                .subscribe((can: boolean) => {
                    this._cachedPermissions = {
                        isAdmin: this._sessionService.hasAnyPermissionInAnyOrg([
                            "organization_admin"
                        ]),
                        //isSelf: this._sessionService.getUsername() === username,
                        canEdit: can
                    };
                    this._getNavigation(
                        viewType,
                        this._cachedPermissions,
                        route
                    );
                });
        }

        return of(this.navigation$);
    }

    private _getNavigation(
        viewType: string,
        permissions: any,
        route: ActivatedRouteSnapshot
    ) {
        let items: NavItem[];
        let parent: NavItem = {
            label: "Users",
            link: [""] //update this for each type
        };
        switch (viewType) {
            case "submitter":
                parent.link = ["/submitter/users"];
                items = [
                    {
                        label: "Contact",
                        link: transformLinkWithParams(
                            ["/submitter/user/:userID/contact"],
                            route
                        )
                    },
                    {
                        label: "User Roles",
                        link: transformLinkWithParams(
                            ["/submitter/user/:userID/organizations"],
                            route
                        )
                    }
                ];
                if (permissions.canEdit && permissions.isAdmin) {
                    items.push({
                        label: "Security",
                        link: transformLinkWithParams(
                            ["/submitter/user/:userID/security"],
                            route
                        )
                    });
                }
                if (permissions.canEdit && permissions.isAdmin) {
                    if (
                        this._sessionService.hasAnyProductInAnyOrg([
                            "submitter_api",
                            "capc_submitter_api",
                            "capc_lender_api"
                        ])
                    ) {
                        items.push({
                            label: "API Tokens",
                            link: transformLinkWithParams(
                                ["/submitter/user/:userID/tokens"],
                                route
                            )
                        });
                    }
                }
                break;
            case "recipient":
                parent.link = ["/recipient/account/users"];
                items = [
                    {
                        label: "Contact",
                        link: transformLinkWithParams(
                            ["/recipient/user/:userID/contact"],
                            route
                        )
                    },
                    {
                        label: "User Roles",
                        link: transformLinkWithParams(
                            ["/recipient/user/:userID/organizations"],
                            route
                        )
                    }
                ]; //may need to update links with "/app" in the future...
                if (permissions.canEdit && permissions.isAdmin) {
                    items.push({
                        label: "Security",
                        link: transformLinkWithParams(
                            ["/recipient/user/:userID/security"],
                            route
                        )
                    });
                }
                break;
            case "lender":
                parent.link = ["/lender/users"];
                items = [
                    {
                        label: "Contact",
                        link: transformLinkWithParams(
                            ["/lender/user/:userID/contact"],
                            route
                        )
                    },
                    {
                        label: "User Roles",
                        link: transformLinkWithParams(
                            ["/lender/user/:userID/organizations"],
                            route
                        )
                    }
                ]; //may need to update links with "/app" in the future...
                if (permissions.canEdit && permissions.isAdmin) {
                    items.push({
                        label: "Security",
                        link: transformLinkWithParams(
                            ["/lender/user/:userID/security"],
                            route
                        )
                    });
                }
                if (permissions.canEdit && permissions.isAdmin) {
                    if (
                        this._sessionService.hasAnyProductInAnyOrg([
                            "submitter_api",
                            "capc_submitter_api",
                            "capc_lender_api"
                        ])
                    ) {
                        items.push({
                            label: "API Tokens",
                            link: transformLinkWithParams(
                                ["/lender/user/:userID/tokens"],
                                route
                            )
                        });
                    }
                }
                break;
            case "vendor":
                parent.link = ["/vendor/users"];
                items = [
                    {
                        label: "Contact",
                        link: transformLinkWithParams(
                            ["/vendor/user/:userID/contact"],
                            route
                        )
                    },
                    {
                        label: "User Roles",
                        link: transformLinkWithParams(
                            ["/vendor/user/:userID/organizations"],
                            route
                        )
                    }
                ]; //may need to update links with "/app" in the future...
                if (permissions.canEdit && permissions.isAdmin) {
                    items.push({
                        label: "Security",
                        link: transformLinkWithParams(
                            ["/vendor/user/:userID/security"],
                            route
                        )
                    });
                }
                break;
            case "sign-event":
                parent.link = ["/sign-event/users"];
                items = [
                    {
                        label: "Contact",
                        link: transformLinkWithParams(
                            ["/sign-event/user/:userID/contact"],
                            route
                        )
                    },
                    {
                        label: "User Roles",
                        link: transformLinkWithParams(
                            ["/sign-event/user/:userID/organizations"],
                            route
                        )
                    }
                ];
                if (permissions.canEdit && permissions.isAdmin) {
                    items.push({
                        label: "Security",
                        link: transformLinkWithParams(
                            ["/sign-event/user/:userID/security"],
                            route
                        )
                    });
                }
                break;
        }

        let navigation: Navigation = {
            parent,
            items
        };
        this.navigation$.next(navigation);
    }
}
