<div *ngIf="field.label" class="group-header">
    <h4>
        {{field.label}}
    </h4>
</div>
<span *ngIf="field.expandable" (click)="toggleExpanded()">
    <fa-icon
        [icon]="!expanded ? ['far', 'plus-square'] : ['far', 'minus-square']">
    </fa-icon>
    <span *ngIf="!expanded"> {{collapsedMessageDisplay}}</span>
</span>
<hr *ngIf="field.expandable && expanded" />
<div
    [ngClass]="{'inner-group': isInnerGroup}">
    <ng-container #groupHost></ng-container>
</div>
<hr *ngIf="field.label"/>

