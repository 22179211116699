<div>
    <sf-select
            id="sf-permission-search-selector"
            name="sf-permission-search-selector"
            [class]="windowClass"
            [ngClass]="{'no-style-select-box': hideBorder}"
            selectorName="sf-permission-search-selector"
            [placeholder]="placeholder"
            searchPlaceholder="Search"
            [hasFilter]="true"
            [filterIcon]="['far', 'search']"
            [filterDebounce]="300"
            [handleFilterExternally]="true"
            [isSingleSelect]="true"
            [selectedOption]="selectedPermissionID"
            [options]="selectableItems"
            [hasClearButton]="canClearSelectedPermission"
            trackBy="id"
            labelBy="label"
            (filter)="getPermissions($event)"
            (select)="handlePermissionSelection($event)"
            [required]="required"
    ></sf-select>
</div>
