<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="content like-table">
    <div class="head-text">Cancel Invitation</div>
    <!--
    <div>
        <span>Would you like to only cancel this invitation, or all future invitations from Simplifile?</span>
    </div>
    <form [formGroup]="cancelForm">
        <div class="radio-block">
            <label>
                <input type="radio" name="whatInvites" formControlName="whatInvites" value="justOne"/>
                &nbsp;Just this invitation
            </label>
            <label>
                <input type="radio" name="whatInvites" formControlName="whatInvites" value="allInvites"/>
                &nbsp;Unsubscribe me from future invitations
            </label>
        </div>
    </form>
    -->
    <button class="btn btn-primary" (click)="doCancel()">Cancel Invitation</button>
    <button class="btn btn-secondary pad-left" (click)="goToLogin()">Log In</button>
</div>
