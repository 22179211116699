export enum RegistrationSettingType {
    Textfield = "Textfield",
    Checkbox = "Checkbox",
    Optional_Textfield = "Optional Textfield",
    Optional_Checkbox = "Optional Checkbox",
    Invisible = "Invisible",
    Invisible_Checkbox = "Invisible Checkbox",
    Required_MOU = "Required MOU",
    Required_MOU_Immediate = "Required MOU Immediate",
    Dropdown = "DROWDOWN",
    Text_Only = "TEXT_ONLY"
}

export enum RegistrationSettingTypeAlt {
    "Textfield" = "TEXTFIELD",
    "Checkbox" = "REQUIRED_CHECKBOX",
    "Optional Textfield" = "OPTIONAL_TEXTFIELD",
    "Optional Checkbox" = "OPTIONAL_CHECKBOX",
    "Required MOU" = "REQUIRED_MOU",
    "Required MOU Immediate" = "REQUIRED_MOU_IMMEDIATE",
    "Dropdown" = "DROPDOWN",
    "Text Only" = "TEXT_ONLY"
}
