<sf-modal-header title="Notarization Type Information"></sf-modal-header>
<div class="modal-body">
    <h4>In-Person Electronic Notarization (IPEN)</h4>
    <div>Your signers will either:</div>
    <ol>
        <li>Sign documents in the physical presence of the notary</li>
        <li>Sign documents and then physically appear before the notary and attest they signed the documents</li>
    </ol>
    <h4>Remote Online Notarization (RON)</h4>
    <div>Your signers will:</div>
    <ol>
        <li>Not physically appear before the notary</li>
        <li>Will virtually appear before the notary by using video and screen sharing</li>
    </ol>
</div>
<sf-modal-footer [primary]="{text: 'OK'}"></sf-modal-footer>
