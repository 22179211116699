<div class="sf-unmask">
    <sf-modal-header title="Unregister Authenticator App"></sf-modal-header>
    <div class="modal-body">
        <form (submit)="continue()">
            <div class="explain">
                <label for="password">Enter your password to unregister with authenticator apps.</label>
            </div>
            <div>
                <input type="password" id="password" class="form-control sf-exclude" #password/>
            </div>
        </form>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
