import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SessionService } from "@sf/common";
import { SfValidators } from "@sf/common";

@Component({
    selector: "sf-send-license-dialog",
    templateUrl: "./send-license-dialog.component.html",
    styleUrls: ["./send-license-dialog.component.scss"]
})
export class SendLicenseDialogComponent implements OnInit {
    @Input() cancelText: string;

    sendForm: FormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    description: string;
    warning: string = null;
    params: any = {
        prefix: "",
        firstName: "",
        lastName: "",
        title: "",
        email: ""
    };

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private sessionService: SessionService
    ) {
        this.sendForm = formBuilder.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            title: ["", Validators.required],
            email: ["", [Validators.required, SfValidators.emailValidator]]
        });
    }

    ngOnInit() {
        if (!this.cancelText) this.cancelText = "Cancel";

        // Modal buttons
        this.primary = {
            text: "Send",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        this.secondary = {
            text: this.cancelText,
            disabled: false,
            callback: this.doCancel.bind(this)
        };

        this.description =
            "You can send your organization's License Agreement to someone else to review and accept. " +
            "Please enter their details below. We will notify you once the agreement is accepted.";
        if (this.sessionService.isSuperUser())
            this.description =
                "This organization's license agreement will be sent to the following person to review and accept.";
    }

    doPrimary() {
        this.warning = this.validate();
        if (!this.warning) {
            this.activeModal.close(this.params);
        }
    }

    validate(): string {
        let message = null;

        this.params.firstName = this.sendForm.controls.firstName.value;
        this.params.lastName = this.sendForm.controls.lastName.value;
        this.params.title = this.sendForm.controls.title.value;
        this.params.email = this.sendForm.controls.email.value;

        if (!this.params.email) message = "Please enter an email address";
        if (!this.params.title) message = "Please the recipient's job title";
        if (!this.params.lastName)
            message = "Please enter the recipient's last name";
        if (!this.params.firstName)
            message = "Please enter the recipient's first name";

        if (!message) {
            // see if sending to internal address that belong to us
            this.params.email = this.params.email.trim();

            if (this.params.email.indexOf("@simplifile.com", 0) != -1)
                message = "A 'simplifile.com' email address is not allowed.";

            if (this.params.email.indexOf("@elliemae.com", 0) != -1)
                message = "An 'elliemae.com' email address is not allowed.";

            if (this.params.email.indexOf("@theice.com", 0) != -1)
                message = "A 'theice.com' email address is not allowed.";

            if (this.params.email.indexOf("@ice.com", 0) != -1)
                message = "An 'ice.com' email address is not allowed.";
        }

        if (!message && this.sendForm.controls.email.errors) {
            message = "Enter a valid Email Address";
        }

        if (!message && this.sendForm.invalid) {
            message = "Please correct input errors";
        }

        return message;
    }

    doCancel() {
        this.activeModal.close(false);
    }
}
