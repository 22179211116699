<sf-modal-header [title]="modalTitle"></sf-modal-header>
<div class="modal-body update-packages-default-account-dialog sf-unmask">

    <div *ngIf="mode == Mode.DefaultAccountChanged">
        <div>
            <p>
                The following packages are using the previous default <b>{{feeLabel}}</b> payment account. You may choose to
                update the payment account on the packages shown below to the new <b>{{feeLabel}}</b> default. If you have a
                large number of packages to update, it may take a while to process.
            </p>
        </div>
        <div class="d-flex">
            <div style="width: 120px">Old Account:</div>
            <div class="flex-fill sf-mask">{{oldAccount ? oldAccount.viewLabel : "NONE"}}</div>
        </div>
        <div class="d-flex">
            <div style="width: 120px">New Account:</div>
            <div class="flex-fill sf-mask">{{newAccount.viewLabel}}</div>
        </div>
        <div *ngIf="noDefaultMode" class="mt-3">
            <p>
                <strong>Important:</strong> No Default has been selected as the new payment account, and only packages
                that can be updated are selectable at the top of the list. Packages that cannot be modified are displayed
                at the bottom of the list and will continue to use the old payment account.
            </p>
        </div>
    </div>

    <div *ngIf="mode == Mode.AccountArchived">
        <div class="d-flex sf-mask">
            <b>Account: {{oldAccount.viewLabel}}</b>
        </div>
        <br/>
        <div>
            <p>
                The following packages use the account being removed for one or more fee types. Packages selected will be
                changed to use the default account for the associated fee type.
            </p>
        </div>
    </div>

    <br/>
    <div id="pkgGrid" [ngStyle]="gridDivStyle">
        <ag-grid-angular
            class="ag-grid ag-theme-sf package-grid-overrides"
            [rowData]="packages"
            [gridOptions]="gridOptions"
            (selectionChanged)="onSelectionChanged($event)"
        ></ag-grid-angular>
    </div>
    <br/>
    <div>
        <i>{{selectedPkgLength}} selected package(s)</i>
    </div>
    <div *ngIf="showProgress" class="progress" style="height: 20px">
        <br/>
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="progressStyle">{{progressInfo}}</div>
    </div>
</div>
<sf-modal-footer [primary]="primaryButton" [secondary]="secondaryButton"></sf-modal-footer>

<ng-template #packageNameTemplate let-data>
    <a [routerLink]="['/submitter/package', data.id, 'details']" class="package-name-link" target="_blank">{{data.name}}</a>
</ng-template>
