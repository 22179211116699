import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NavItem } from "../../interfaces/navigation.interface";
import { filter, map, shareReplay, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
    selector: "sf-nav-item",
    templateUrl: "./nav-item.component.html",
    styleUrls: ["./nav-item.component.scss"]
})
export class NavItemComponent implements OnInit {
    @Input()
    item: NavItem;

    link: string;
    isActive$: Observable<boolean>;
    isExpanded$: Observable<boolean>;

    constructor(private router: Router) {}

    ngOnInit() {}
}
