<sf-modal-header title="Retry Credit Card Payments"></sf-modal-header>
<div class="modal-body sf-unmask">
    <span>The selected payments failed when processing and are past due.</span>
    <br>
    <br>
    <span>Before retrying these payments, please verify your card information is correct. You may also update or add a new card on the previous screen by clicking on the payment account.</span>
    <br>
    <br>
    <span>Clicking Retry Payments below will retry processing the selected payments.</span>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
