import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-duplicate-check-number",
    templateUrl: "./duplicate-check-number.component.html",
    styleUrls: ["./duplicate-check-number.component.scss"]
})
export class DuplicateCheckNumberComponent implements OnInit {
    @Input() duplicateInfo: any;

    /** Public Variables **/
    primary: ModalButton;
    secondary: ModalButton;
    arrayInfo: any[];
    disallowDups: boolean = false;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.disallowDups =
            sf.liveConfig.EcheckSettings.disallowDuplicateCheckNumbers;
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.fixCheckNumbers.bind(this)
        };
        this.secondary = {
            text: "Ignore & Approve",
            disabled: false,
            callback: this.submitPayment.bind(this)
        };

        this.arrayInfo = [];
        Object.keys(this.duplicateInfo).forEach((key) => {
            this.arrayInfo.push({
                label: this.duplicateInfo[key].label,
                duplicates: this.duplicateInfo[key].duplicates,
                mostRecent: this.duplicateInfo[key].mostRecent
            });
        });
    }

    fixCheckNumbers() {
        this._activeModal.close(true);
    }

    submitPayment() {
        this._activeModal.close(false);
    }
}
