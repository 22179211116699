import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "sf-simple-filter",
    templateUrl: "./simple-filter.component.html",
    styleUrls: ["./simple-filter.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimpleFilterComponent),
            multi: true
        }
    ]
})
export class SimpleFilterComponent implements OnInit, ControlValueAccessor {
    constructor() {}

    /* Public Variables */

    /* Inputs */
    @Input()
    filterOptions: { filterText: string };
    @Input()
    placeHolder?: string;
    @Output()
    changed = new EventEmitter<string>();
    hasChangedObserver = false;

    /* Private Variables */
    private _onChange = (filterText: string) => {};
    private _onTouched = () => {};

    ngOnInit() {
        this.filterOptions = {
            filterText: ""
        };
        this.placeHolder = this.placeHolder ? this.placeHolder : "Filter";

        // Allow for optional output by detecting if a callback was passed in
        this.hasChangedObserver = this.changed.observers.length > 0;
    }

    onFilterChanged(filterText: string) {
        this.filterOptions.filterText = filterText;

        if (this.hasChangedObserver) {
            this.changed.emit(this.filterOptions.filterText);
        }
        this._onChange(this.filterOptions.filterText);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    writeValue(obj: string | { filterText: string }): void {
        if (!obj) {
            this.filterOptions = {
                filterText: ""
            };
        } else if (typeof obj === "string") {
            this.filterOptions = {
                filterText: obj
            };
        } else {
            this.filterOptions = obj;
        }
    }
}
