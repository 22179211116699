import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import { RecipientSubmitterSetting, StringMap } from "@sf/userorg/common";

@Component({
    selector: "sf-recipient-submitter-setting",
    templateUrl: "./recipient-submitter-setting.component.html",
    styleUrls: ["./recipient-submitter-setting.component.scss"]
})
export class RecipientSubmitterSettingComponent
    implements OnInit, AfterViewInit
{
    @Input()
    setting: RecipientSubmitterSetting;
    @Input()
    submitterValues: StringMap;

    @ViewChild("textField")
    textField: any;
    @ViewChild("checkboxField")
    checkboxField: any;
    @ViewChild("mouField")
    mouField: any;
    @ViewChild("dropdownField")
    dropdownField: any;
    @ViewChild("textOnlyField")
    textOnlyField: any;

    supportedType: boolean;
    contents: any;
    labelText: string;

    /** Private Variables **/
    private readonly SUPPORTED_TYPES: string[] = [
        "TEXTFIELD",
        "REQUIRED_CHECKBOX",
        "OPTIONAL_CHECKBOX",
        "INVISIBLE",
        "OPTIONAL_TEXTFIELD",
        "INVISIBLE_CHECKBOX",
        "REQUIRED_MOU_IMMEDIATE",
        "REQUIRED_MOU",
        "DROPDOWN",
        "TEXT_ONLY"
    ];
    private readonly SUPER_USER: string = "(only visible to super-users)";

    constructor(private _cd: ChangeDetectorRef) {
        this.supportedType = false;
        this.labelText = "";
    }

    ngOnInit(): void {
        this.supportedType =
            this.SUPPORTED_TYPES.indexOf(this.setting.type) > -1;
    }

    ngAfterViewInit(): void {
        if (this.supportedType) {
            // noinspection FallThroughInSwitchStatementJS
            switch (this.setting.type) {
                case "INVISIBLE":
                    this.labelText = this.SUPER_USER;
                case "OPTIONAL_TEXTFIELD":
                case "TEXTFIELD":
                    this.contents = this.textField;
                    break;
                case "REQUIRED_CHECKBOX":
                    this.contents = this.checkboxField;
                    this.labelText = "I Understand";
                    break;
                case "OPTIONAL_CHECKBOX":
                    this.contents = this.checkboxField;
                    this.labelText = this.setting.name;
                    break;
                case "INVISIBLE_CHECKBOX":
                    this.contents = this.checkboxField;
                    this.labelText = `${this.setting.name} ${this.SUPER_USER}`;
                    break;
                case "REQUIRED_MOU_IMMEDIATE":
                case "REQUIRED_MOU":
                    this.contents = this.mouField;
                    this.labelText = this.SUPER_USER;
                    break;
                case "DROPDOWN":
                    this.contents = this.dropdownField;
                    this.setting.options = this.setting.details.split(",");
                    break;
                case "TEXT_ONLY":
                    this.contents = this.textOnlyField;
                    break;
            }
            this._cd.detectChanges();
        }
    }

    checkboxChanged($event: any, settingName: string) {
        this.submitterValues[settingName] = $event.target.checked
            ? "true"
            : "false";
    }
}
