import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver
} from "@angular/core";
import { DocumentBuilderGuideCard } from "../../interfaces/document-builder-guide-card";
import { combineLatest } from "rxjs";
import { SessionService, UserSettingsService } from "@sf/common";
import { EsignOrganizationService } from "../../services/esign-organization.service";
import { DocumentBuilderGuideEntityTypeComponent } from "../document-builder-guide-entity-type/document-builder-guide-entity-type.component";

@Component({
    selector: "sf-document-builder-guide-card",
    templateUrl: "./document-builder-guide-card.component.html",
    styleUrls: ["./document-builder-guide-card.component.scss"]
})
export class DocumentBuilderGuideCardComponent implements OnInit {
    @Input() card: DocumentBuilderGuideCard;
    @Input() adminOrganizationId: string;
    @Output() action = new EventEmitter<void>();
    @Output() skipAction = new EventEmitter<void>();
    standardTemplateGroupList: { id: string }[];
    standardTemplateGroups: string[];

    @ViewChild("documentBuilderCardComponent", {
        read: ViewContainerRef,
        static: true
    })
    documentBuilderCardComponent: ViewContainerRef;

    constructor(
        private _esignOrganizationService: EsignOrganizationService,
        private _sessionService: SessionService,
        private _userSettingsService: UserSettingsService,
        private _componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        // if (this.card.id === DocumentBuilderGuideTask.TEMPLATE_GROUPS) {
        //     this.getStandardTemplateGroups();
        // }
        if (this.card.component) {
            const componentFactory =
                this._componentFactoryResolver.resolveComponentFactory(
                    this.card.component
                );
            let cardComponent =
                this.documentBuilderCardComponent.createComponent(
                    componentFactory
                );
            if (
                cardComponent.instance instanceof
                DocumentBuilderGuideEntityTypeComponent
            ) {
                let componentInstance: DocumentBuilderGuideEntityTypeComponent =
                    cardComponent.instance;
                componentInstance.adminOrganizationId =
                    this.adminOrganizationId;
                componentInstance.complete.subscribe(() => {
                    this.card.status = "complete";
                    this._userSettingsService.setUserSetting(
                        "DOCUMENT_BUILDER_GUIDE_ENTITY_TYPE_STATUS",
                        "complete"
                    );
                });
            }
        }
    }

    get complete() {
        return this.card.status === "complete";
    }

    getStandardTemplateGroups() {
        combineLatest([
            this._esignOrganizationService.getStandardTemplateGroups(),
            this._esignOrganizationService.getEnabledStandardTemplateGroups(
                this.adminOrganizationId
            )
        ]).subscribe(([groups, enabledGroups]) => {
            this.standardTemplateGroupList = groups.map((group) => {
                return { id: group };
            });
            this.standardTemplateGroups = enabledGroups;
        });
    }

    updateStandardTemplateGroups(groups: { id: string }[]) {
        let enabledGroups = groups.map((group) => group.id);
        if (!!enabledGroups.length) {
            combineLatest([
                this._esignOrganizationService.setEnabledStandardTemplateGroups(
                    this.adminOrganizationId,
                    enabledGroups
                ),
                this._userSettingsService.setUserSetting(
                    "DOCUMENT_BUILDER_GUIDE_TEMPLATE_GROUPS_STATUS",
                    "complete"
                )
            ]).subscribe(() => {
                this.card.status = "complete";
            });
        }
    }
}
