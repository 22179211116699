<div class="submitter-history-page">
    <form name="dateSelectionForm">
        <div>
            <sf-date-range-picker
                name="dateRange"
                inputId="statusDate"
                [presets]="presets"
                [(ngModel)]="dateSelections"
                rangeLabel="Date Range"
                startLabel="Start Date"
                endLabel="End Date"
                singleRow="true"
                (ngModelChange)="refreshHistory()"
            ></sf-date-range-picker>
        </div>
    </form>
    <div class="text-count">
        <br />
        <i *ngIf="history.length < historyCount && history.length > 2"
            >Displaying {{ history.length | number }} most recent events (of
            {{ historyCount | number }}) from the selected date range and filter
            settings.</i
        >
        <i *ngIf="history.length === historyCount && history.length > 2"
            >Displaying all {{ history.length | number }} events from the
            selected date range and filter settings.</i
        >
        <i *ngIf="history.length === 2"
            >Displaying the only 2 events from the selected date range and
            filter settings.</i
        >
        <i *ngIf="history.length === 1"
            >Displaying the only event from the selected date range and filter
            settings.</i
        >
        <i *ngIf="history.length === 0"
            >There are no events from the selected date range and filter
            settings.</i
        >
        <a (click)="showFilter()" class="filter-toggle-btn">
            <fa-icon
                [icon]="['far', 'filter']"
                pull="right"
                size="2x"
            ></fa-icon>
        </a>
    </div>

    <ag-grid-angular
        class="history-grid ag-grid ag-theme-sf"
        [rowData]="history"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
</div>

<ng-template #eventTemplate let-params="params">
    <span class="row-cell">{{ displayEvent(params.value) }}</span>
</ng-template>
<ng-template #cellTemplate let-params="params">
    <span class="row-cell">{{ params.value }}</span>
</ng-template>
<ng-template #dateTemplate let-row>
    <span class="date-cell" (click)="clickDate(row.date)">{{
        row.date | date: "MMM dd, yyyy"
    }}</span>
</ng-template>
<ng-template #dateModal>
    <span class="modal-sm"
        >{{ textDate | date: "MMM dd, yyyy" }} at
        {{ textDate | date: "hh:mm a" }}</span
    >
</ng-template>
<ng-template #filterModal>
    <div class="history-filter">
        <div class="filter-dropdowns">
            <h4>Events</h4>
            <sf-select
                name="events"
                [(ngModel)]="filter.events"
                [options]="filter.eventOptions"
                [trackBy]="'id'"
                [labelBy]="'label'"
                [placeholder]="'None Selected'"
                [hasClearButton]="true"
                [hasFilter]="true"
            >
            </sf-select>
            <h4>Users</h4>
            <sf-select
                [name]="'users'"
                [(ngModel)]="filter.users"
                [options]="filter.userOptions"
                [trackBy]="'id'"
                [labelBy]="'label'"
                [placeholder]="'None Selected'"
                [hasClearButton]="true"
                [hasFilter]="true"
            >
            </sf-select>
        </div>
        <div class="flex-row">
            <h4>Details</h4>
            <input type="text" name="'Details'" [(ngModel)]="filter.details" />
        </div>
    </div>
</ng-template>
