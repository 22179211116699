export function generateRandomString(len: number) {
    let randomString = "";
    let randomAscii: number;
    let asciiLow = 65;
    let asciiHigh = 90;
    for (let i = 0; i < len; i++) {
        randomAscii = Math.floor(
            Math.random() * (asciiHigh - asciiLow) + asciiLow
        );
        randomString += String.fromCharCode(randomAscii);
    }
    return randomString;
}

export function humanizeString(string: string) {
    string = string || "";
    string = string.toString(); // might be a number
    string = string.trim();
    string = string.replace(extname(string), "");
    string = underscore(string);
    string = string.replace(/[\W_]+/g, " ");

    return capitalize(string);
}

function underscore(string: string) {
    string = string || "";
    string = string.toString(); // might be a number
    string = string.trim();
    string = string.replace(/([a-z\d])([A-Z]+)/g, "$1_$2");
    string = string.replace(/[-\s]+/g, "_").toLowerCase();

    return string;
}

function extname(string: string) {
    var index = string.lastIndexOf(".");
    var ext = string.substring(index, string.length);

    return index === -1 ? "" : ext;
}

export function capitalize(string: string) {
    return `${string.charAt(0).toUpperCase()}${string.substr(1)}`;
}

// https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149?permalink_comment_id=3253486#gistcomment-3253486
export function kebabCase(string: string) {
    if (!string.match(/\s/g)) {
        const result = string.replace(
            /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g,
            (match) => "-" + match.toLowerCase()
        );
        return string[0] === string[0].toUpperCase()
            ? result.substring(1)
            : result;
    }

    return string.toLowerCase();
}

export function startCase(input: string): string {
    return input
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
        .replace(/[_-]+/g, " ") // Replace underscores and hyphens with spaces
        .replace(/\s+/g, " ") // Replace multiple spaces with a single space
        .trim() // Trim leading and trailing spaces
        .toLowerCase() // Convert to lowercase
        .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
}

// https://stackoverflow.com/a/2970667/6111857
export function camelCase(string: string) {
    return string.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

export function isString(str: any) {
    if (str != null && typeof str.valueOf() === "string") {
        return true;
    }
    return false;
}

export function isEqualStrings(a: any, b: any): boolean {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    if (typeof a == "string" && typeof b == "string") {
        return a == b;
    }
    return undefined;
}
