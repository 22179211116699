import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActiveService, SessionService } from "@sf/common";
import { ModalButton } from "@sf/common";
import { Router } from "@angular/router";
import { UserorgActivationService } from "@sf/userorg/common";
import { OrganizationSuspensionService } from "@sf/userorg/common";

interface ServiceEntry {
    productLabel: string;
    organizationLabel: string;
    reasonText: string;
    orgID: string;
}

interface AdminUser {
    name: string;
    phone: string;
    email: string;
}

// prettier-ignore
@Component({
    selector: "sf-suspended-service-dialog",
    templateUrl: "./suspended-service-dialog.component.html",
    styleUrls: ["./suspended-service-dialog.component.scss"]
})
export class SuspendedServiceDialogComponent implements OnInit {
    loading = true;
    suspendedServices: ServiceEntry[] = [];
    isAdmin = false;
    hasPendingPayments = false;
    hasInvoicingIssue = false;
    allowHelp = false;
    adminUsers: AdminUser[] = [];

    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private sessionService: SessionService,
        private activationService: UserorgActivationService,
        private suspensionService: OrganizationSuspensionService,
        private router: Router
    ) {}

    ngOnInit() {
        this.loading = true;

        let orgs: any[] = this.sessionService.getAllOrganizations();

        this.hasInvoicingIssue = false;
        this.hasPendingPayments = false;
        orgs.forEach((organization) => {
            organization.activeServices.forEach((service: ActiveService) => {
                if (service.status === "SUSPENDED") {
                    let reasonText = service.statusDetails;
                    this.suspendedServices.push({
                        productLabel: service.label,
                        organizationLabel: organization.name + " (" + organization.id + ")",
                        reasonText: reasonText,
                        orgID: organization.id
                    });
                    if (this.suspensionService.isPaymentIssues(service.statusDetails)) {
                        let invoiceIssue = service.statusDetails == "Past Due Invoice Payments";
                        let achIssue = service.statusDetails == "Past Due ACH Payments";
                        if (invoiceIssue) {
                            this.hasInvoicingIssue = true;
                        }
                        if (!invoiceIssue && !achIssue) {
                            this.hasPendingPayments = true;
                        }
                        if (this.sessionService.hasAnyPermission([
                            "organization_payment_approval", "organization_accounting"
                        ], organization.id)) {
                            this.isAdmin = true;
                        }
                    } else {
                        if (this.sessionService.hasAnyPermission(["organization_accounting"], organization.id)) {
                            this.isAdmin = true;
                        }
                    }
                }
            });
        });

        if (!this.isAdmin && this.suspendedServices.length == 1) {
            this.activationService
                .getOrganizationAdminUsers(this.suspendedServices[0].orgID)
                .subscribe((adminUsers: any[]) => {
                    if (adminUsers) {
                        adminUsers.forEach((user) => {
                            this.adminUsers.push({
                                name: user.name,
                                phone: user.phone,
                                email: user.email
                            });
                        });
                    }
                });
        }

        // weird checks when user only belongs to CAPC org(s).
        // see if one of their orgs allows viewing help.
        // since some people are not worthy of our help. :)
        this.allowHelp = true;
        if (this.sessionService.hasAnyProductInAnyOrg(["lender", "settlement"])) {
            if (!this.sessionService.hasProductInAnyOrg("submitter")) {
                this.allowHelp = false;
                orgs.forEach((org) => {
                    if (this.sessionService.hasPermission("capc_view_help_support", org.id)) {
                        this.allowHelp = true;
                    }
                });
            }
        }

        this.loading = false;

        // Modal buttons
        // default primary
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.close.bind(this)
        };
        if (this.hasPendingPayments && this.isAdmin) {
            this.primary = {
                text: "Review Pending Payments",
                disabled: false,
                callback: this.goToPayments.bind(this)
            };
            this.secondary = {
                text: "Close",
                disabled: false,
                callback: this.close.bind(this)
            };
        }
        if (
            this.suspendedServices &&
            this.suspendedServices.length &&
            this.allowHelp
        ) {
            this.tertiary = {
                text: "Get Help",
                disabled: false,
                callback: this.goToHelp.bind(this)
            };
        }
    }

    close() {
        this.activeModal.close(false);
    }

    goToHelp() {
        this.close();

        let url: string = null;
        if (this.sessionService.hasProductInAnyOrg("submitter")) {
            url = "/submitter/payments/help";
        } else if (
            this.sessionService.hasAnyProductInAnyOrg(["settlement", "lender"])
        ) {
            url = "/lender/help";
        }
        // if they don't have submitter or settlement or lender, then ...?
        if (url) {
            this.router.navigateByUrl(url);
        }
    }

    goToPayments() {
        this.close();

        let url = "/submitter/payments/pending-payments/";
        this.router.navigate([url]);
    }
}
