<div *ngIf="field.label" class="group-header">
    <h4>
        {{field.label}}
    </h4>
</div>
<span *ngIf="field.expandable" (click)="toggleExpanded()" class="expand-toggle" >
    <span [ngClass]="{'pending-collapsed-message':expanded}">
        <span>
            <fa-icon
                *ngIf="partyTypeSelected === 'Organization'"
                [icon]="['far', 'university']"></fa-icon>
            <fa-icon
                *ngIf="partyTypeSelected === 'Individual'"
                [icon]="['far', 'male']"></fa-icon>
        </span>
        <!-- IMPORTANT - needs to be a single line -->
        <span *ngFor="let message of collapsedMessageDisplay" class="preview-message"><br *ngIf="message.hasNewLine"/>{{message.display}}</span>
        <!-- IMPORTANT - end single line -->
    </span>
    &nbsp;
    <fa-icon
        [icon]="!expanded ? ['far', 'plus-square'] : ['far', 'minus-square']">
    </fa-icon>
</span>
<div
    [ngClass]="{'inner-group': isInnerGroup, 'last-group-in-array':!field.expandable}">
    <ng-container #groupHost></ng-container>
</div>
<hr *ngIf="field.label"/>

