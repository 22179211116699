import { Pipe, PipeTransform } from "@angular/core";

/*
    Clean way to map an ID to a displayable name / label property
    Works with same input array as sfSelect takes for "options"
 */

@Pipe({
    name: "mapFromId"
})
export class MapFromIdPipe implements PipeTransform {
    transform(
        id: string,
        map: any[],
        displayProp: string = "label",
        idProp: string = "id"
    ): string {
        let found = map.find((obj) => {
            return obj[idProp] === id;
        });

        return found && found[displayProp] ? found[displayProp] : "";
    }
}
