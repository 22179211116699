import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
    CopyTextService,
    EncryptionService,
    getLinkWithChangedParams,
    getParamsFromRoute,
    LoggerService,
    SelectableItem
} from "@sf/common";
import { PageHeaderComponent } from "@sf/headers";
import { Subject, Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { sampleTime, takeUntil } from "rxjs/operators";
import {
    CurrentUserService,
    LockoutService,
    User,
    UserOrgService,
    UserSubscriptionService
} from "@sf/userorg/common";
import { AdminUserorgService } from "../../services/admin-userorg.service";

// prettier-ignore
@Component({
    selector: "sf-admin-user-header-with-selector",
    templateUrl: "./admin-user-header-with-selector.component.html",
    styleUrls: ["./admin-user-header-with-selector.component.scss"]
})
export class AdminUserHeaderWithSelectorComponent implements OnInit, OnDestroy, PageHeaderComponent {
    public pageData: any;
    public selectedUser: User = null;
    public selectedUserID: string = null;

    private _onDestroy: Subject<void> = new Subject<void>();
    private updater: Subscription = null;

    needToNavigate = false;
    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy ID"
        },
        {
            option: "copyName",
            label: "Copy Name"
        }
    ];

    constructor(
            private _currentUserService: CurrentUserService,
            private _route: ActivatedRoute,
            private _router: Router,
            private logger: LoggerService,
            private _encryptionService: EncryptionService,
            private _userorgService: UserOrgService,
            private adminUserorgService: AdminUserorgService,
            private _userorgLockoutService: LockoutService,
            private copyService: CopyTextService,
            private userSubscriptionService: UserSubscriptionService
    ) {}

    ngOnInit(): void {
        let params = getParamsFromRoute(this._route, ["userID"]);
        if (params.userID && params.userID && params.userID !== "_") {
            this.selectedUserID = this._encryptionService.decodeUnscramble(params.userID);
        }

        this._currentUserService.currentUser$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((user: User) => {
                if (!user) {
                    return;
                }

                /*
                    this.logger.debug("user-header: Current user changed from " +
                            (this.selectedUser ? this.selectedUser.id : "null") +
                            " to " + user.id);
                */

                this.selectedUser = user;

                this._userorgService.getUser(user.username).subscribe((readUser: User) => {
                    // note that this is async, so we may get details about a different user than is NOW current
                    if (readUser.id == this.selectedUser.id) {
                        this._userorgService.buildUserStatus(readUser);
                        this.selectedUser = readUser;
                        this.setSelectedUser(this.selectedUser);
                        this.subscribeToChanges();
                    }
                });

                let encodedID: string = this._encryptionService.scrambleEncode(user.id);

                if (this.needToNavigate) {
                    // this returns current activated route, but not sure why nothing changes...
                    let link = getLinkWithChangedParams(this._route, { encodedID }, true);
                    if (encodedID && !link.includes(encodedID)) {
                        //set param for user id explicitly
                        link[2] = encodedID;
                    }
                    let extras: NavigationExtras = {
                        relativeTo: this._route,
                        queryParamsHandling: "preserve"
                    };
                    this._router.navigate(link, extras);
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new user
            this.updater.unsubscribe();
        }
        this.updater = this.userSubscriptionService.subscribeToUpdates(this.selectedUser.username)
            .pipe(
                    sampleTime(1000),
                    takeUntil(this._onDestroy))
            .subscribe((msg: any) => {
                if (this.selectedUser.username == this._currentUserService.getCurrentUserID()) {
                    //this.logger.debug("user-header: Realtime update for " + this.selectedUser.username);
                    this.setSelectedUser(this.selectedUser);
                }
            });
    }

    public setSelectedUser(selectedUser: User) {
        //this.logger.debug("user-header: Changing current user to " + (selectedUser ? selectedUser.id : "null"));
        this._currentUserService.updateCurrentUser(selectedUser);

        if (selectedUser) {
            this._userorgService.buildUserStatus(selectedUser);
            this.selectedUser = selectedUser;
            this.needToNavigate = true;
        } else {
            this.selectedUser = null;
        }
    }

    onStatusClick(): void {
        //if locked out, unlock; otherwise, go to roles for user
        if (this.selectedUser.isLockedOut) {
            this._userorgLockoutService.doUserUnlock(this.selectedUser, false).then((result) => {
                // nothing
            });
        } else if (this.selectedUser.isSuspended) {
            let newURL: string =
                "/admin/user/" +
                this._encryptionService.scrambleEncode(this.selectedUserID) +
                "/security";
            this._router.navigateByUrl(newURL);
        } else {
            let newURL: string =
                "/admin/user/" +
                this._encryptionService.scrambleEncode(this.selectedUserID) +
                "/organizations";
            if (this.selectedUser.isArchived) {
                newURL =
                    "/admin/user-org/users?queryText=" +
                    this.selectedUser.username +
                    "&archived=true";
            }
            //let currentUrl = this._router.routerState.snapshot.url;
            this._router.navigateByUrl(newURL);
        }
    }

    becomeUser(): void {
        this.adminUserorgService.loginAs(this.selectedUser.id);
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                event.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft;
            this.contextMenuY =
                event.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedUser) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(
                        this.selectedUser.name.first +
                            " " +
                            this.selectedUser.name.last
                    );
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedUser.id);
                    break;
            }
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }
}
