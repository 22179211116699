import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { HotKeysModalComponent } from "../modals/components";
import { getOS } from "../helpers/browser";
import { finalize, share, takeUntil } from "rxjs/operators";
import { LoggerService } from "./logger.service";
import { EventManager } from "@angular/platform-browser";

interface Options {
    combo: string;
    displayAs?: string;
    description?: string;
    target?: HTMLElement;
}

export interface HotKey {
    combo: string;
    observable: Observable<KeyboardEvent>;
    dispose: VoidFunction;
    displayAs?: string;
    description?: string;
}

@Injectable({
    providedIn: "root"
})
export class HotKeysService {
    private _hotkeys: { [key: string]: HotKey } = {};
    private _existingModal: NgbModalRef;

    private readonly _modifierKey = getOS() === "MacOS" ? "CMD" : "CTRL";
    private readonly _hotkeyTriggered$: Subject<KeyboardEvent> =
        new Subject<KeyboardEvent>();

    public readonly hotkeyTriggered$: Observable<KeyboardEvent> =
        this._hotkeyTriggered$.asObservable();

    constructor(
        private _modal: NgbModal,
        private _logger: LoggerService,
        private _eventManager: EventManager
    ) {
        this._addHelpHotkey();
    }

    get currentHotkeys(): HotKey[] {
        return Object.values(this._hotkeys);
    }

    getModifierKey(): string {
        return this._modifierKey;
    }

    getModifierSymbol(): string {
        return this._modifierKey === "CMD" ? "&#8984;" : "ctrl";
    }

    addShortcut(options: Options): Observable<KeyboardEvent> {
        options.combo = replaceMod(options.combo);
        if (options.displayAs) {
            options.displayAs = replaceMod(options.displayAs);
        }

        if (this._hotkeys.hasOwnProperty(options.combo)) {
            this._logger.debug(
                `${options.combo} has already been added as a hotkey.`
            );
            return this._hotkeys[options.combo].observable;
        }

        this._logger.debug(`Adding ${options.combo} as a hotkey.`);

        const dispose = new Subject<void>();
        let removeListener: Function;

        this._hotkeys[options.combo] = {
            ...options,
            dispose: () => {
                dispose.next();
                dispose.complete();
            },
            observable: new Observable((observer) => {
                const handler = (e: KeyboardEvent) => {
                    const target = e.target as HTMLElement;
                    const nodeName = target.nodeName.toUpperCase();
                    // Don't capture event if user is entering text in an input.
                    if (!["INPUT", "TEXTAREA", "SELECT"].includes(nodeName)) {
                        e.preventDefault();
                        observer.next(e);
                        this._hotkeyTriggered$.next(e);
                    }
                };

                removeListener = this._eventManager.addGlobalEventListener(
                    "document",
                    `keydown.${options.combo}`,
                    handler
                );

                return () => {
                    dispose.next();
                    dispose.complete();
                };
            }).pipe(
                finalize(() => {
                    this._logger.debug(`Removed ${options.combo}`);
                    removeListener();
                    delete this._hotkeys[options.combo];
                }),
                takeUntil(dispose),
                share()
            ) as Observable<KeyboardEvent>
        };

        return this._hotkeys[options.combo].observable;
    }

    removeShortcut(combo: string) {
        combo = replaceMod(combo);
        this._logger.debug(`Removing ${combo}`);
        this._hotkeys[combo]?.dispose();
    }

    openHelpModal() {
        this._existingModal = this._modal.open(HotKeysModalComponent, {
            size: "lg"
        });
        this._existingModal.componentInstance.hotKeys = this._hotkeys;
        this._existingModal.result
            .then(() => delete this._existingModal)
            .catch(() => delete this._existingModal);
    }

    private _addHelpHotkey() {
        this.addShortcut({ combo: "shift.?" }).subscribe(() => {
            if (
                Object.keys(this._hotkeys).filter((key) => key !== "shift.?")
                    .length <= 0
            ) {
                return;
            }
            if (!this._existingModal) {
                this.openHelpModal();
            } else {
                this._existingModal.dismiss();
                delete this._existingModal;
            }
        });
    }
}

function replaceMod(combo: string) {
    if (combo.includes("mod.")) {
        combo =
            getOS() === "MacOS"
                ? combo.replace("mod.", "meta.")
                : combo.replace("mod.", "control.");
    }
    return combo;
}
