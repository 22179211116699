import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RoleService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { first } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-remove-all-user-roles-dialog",
    templateUrl: "./remove-all-user-roles-dialog.component.html",
    styleUrls: ["./remove-all-user-roles-dialog.component.scss"]
})
export class RemoveAllUserRolesDialogComponent implements OnInit {
    @Input() userID: string;
    @Input() userFirstLast: string;

    loading = true;
    organizations: any[] = null;
    removalGroups: any;
    isSuperUser: boolean;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private rolesService: RoleService,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Remove All Roles",
            disabled: true,
            callback: this.submit.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        this.isSuperUser = this.sessionService.isSuperUser();

        this.rolesService.getAssignmentsForUser(this.userID)
            .pipe(first())
            .subscribe((groups: any) => {
                this.removalGroups = [];
                if (groups.organizations) {
                    this.organizations = [];
                    Object.values(groups.organizations).forEach((organization: any) => {
                        if (organization.canModify) {
                            this.organizations.push(organization);
                        }
                    });
                    this.primary.disabled = this.organizations.length == 0;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            });
    }

    submit() {
        let assignmentsToRemove: any[] = [];
        this.organizations.forEach((organization) => {
            organization.assignments.forEach((role: any) => {
                assignmentsToRemove.push({
                    orgID: organization.id,
                    roleID: role.id,
                    pending: role.pending,
                    email: role.email,
                    tokenID: role.tokenID
                });
            });
        });
        this.primary.disabled = true;
        this.rolesService.removeAssignmentsFromUser(assignmentsToRemove, this.userID)
            .pipe(first())
            .subscribe(() => {
                // done - back end does check for report subscriptions
                this.activeModal.close(true);
            }, () => {
                this.primary.disabled = false;
            });
    }

    dismiss() {
        this.activeModal.close(false);
    }
}
