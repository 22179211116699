import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentBuilderGuideModalComponent } from "./components/document-builder-guide-modal/document-builder-guide-modal.component";
import { DocumentBuilderGuideCardComponent } from "./components/document-builder-guide-card/document-builder-guide-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterModule } from "@angular/router";
import { ModalsModule, SfCommonModule } from "@sf/common";
import { SelectModule } from "@sf/common";
import { ProjectSelectorComponent } from "./components/project-selector/project-selector.component";
import { MoveDocumentsModalComponent } from "./components/move-documents-modal/move-documents-modal.component";
import { ManageFoldersModalComponent } from "./components/manage-folders-modal/manage-folders-modal.component";
import { DocumentBuilderGuideNotificationSettingsComponent } from "./components/document-builder-guide-notification-settings/document-builder-guide-notification-settings.component";
import { DocumentBuilderGuideEntityTypeComponent } from "./components/document-builder-guide-entity-type/document-builder-guide-entity-type.component";
import { NotarizationTypeInfoModalComponent } from "./components/notarization-type-info-modal/notarization-type-info-modal.component";
import { EsignUserCenterComponent } from "./components/esign-user-center/esign-user-center.component";
import { HeadersModule } from "@sf/headers";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        DocumentBuilderGuideModalComponent,
        DocumentBuilderGuideCardComponent,
        ProjectSelectorComponent,
        MoveDocumentsModalComponent,
        ManageFoldersModalComponent,
        DocumentBuilderGuideNotificationSettingsComponent,
        DocumentBuilderGuideEntityTypeComponent,
        NotarizationTypeInfoModalComponent,
        EsignUserCenterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        ModalsModule,
        SelectModule,
        HeadersModule,
        NgbTooltipModule,
        SfCommonModule
    ],
    exports: [
        ProjectSelectorComponent,
        MoveDocumentsModalComponent,
        ManageFoldersModalComponent
    ]
})
export class EsignCoreModule {}
