import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { error } from "loglevel";
import "zone.js";

if (environment.production) {
    enableProdMode();
}

// Bootstrap Angular (which will in turn bootstrap Angular JS in the app module)
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => error(err));
