<div class="form-group row" [formGroup]="dummyForm" [ngStyle]="columnLayout">
    <label [for]="field.id" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <sf-date-range-picker
            formControlName="daterange"
            [presets]="presets$ | async"
            [showDates]="!isSimple"
            [required]="checkRequired()"
            [singleRow]="false">
        </sf-date-range-picker>
    </div>
</div>
