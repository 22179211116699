import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-confirm-surcharge-dialog",
    templateUrl: "./confirm-surcharge-dialog.component.html",
    styleUrls: ["./confirm-surcharge-dialog.component.scss"]
})
export class ConfirmSurchargeDialogComponent implements OnInit {
    primary: ModalButton;
    secondary: ModalButton;
    modalTitle: string;
    minimumCharge: boolean;

    @Input() ccAccountLabel: string;
    @Input() chargedAmount: string;
    @Input() surchargePercent: number;
    @Input() surchargeAmount: number;
    @Input() totalAmount: number;
    @Input() addNewCC: boolean;
    @Input() numSteps: number;
    @Input() stepIndex: number;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Confirm",
            disabled: true,
            callback: this.confirm.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.abort.bind(this)
        };

        this.modalTitle = this.addNewCC
            ? "Add New Card and Change Payment"
            : "Confirm Payment Change";

        //only allow if charged amount >= $.50
        if (parseFloat(this.chargedAmount) >= 0.5) {
            this.minimumCharge = true;
            this.primary.disabled = false;
        }
    }

    confirm() {
        this._activeModal.close(true);
    }

    abort() {
        this._activeModal.close(false);
    }
}
