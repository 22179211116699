<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="loadingLoans">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </div>

    <div class="vertical-space" *ngIf="!supportedProduct">
        <span>The option to enable the {{product.label}} service is not available yet.</span>
    </div>

    <div *ngIf="supportedProduct">
        <!-- if service shouldn't be removed yet -->
        <div class="vertical-space" *ngIf="!activating && loanCount > 0">
            <b>This organization has loans assigned! </b>
        </div>

        <!-- main confirmation message -->
        <div class="vertical-space">
            <span>Are you sure you want to</span>
            <span *ngIf="action == 'activate'"> activate </span>
            <span *ngIf="action == 'deactivate'"> deactivate </span>
            <span *ngIf="action == 'trial'"> start a trial of </span>
            <span> &quot;{{product.label}}&quot; for {{organization.name}}?</span>
        </div>

        <!-- if activating -->
        <div class="vertical-space" *ngIf="activating">
            <span *ngIf="activating">This will allow {{organization.name}}
                to see the available roles for &quot;{{product.label}}&quot; and assign them to users.</span>
        </div>

        <!-- if users will be removed from organization -->
        <div class="vertical-space" *ngIf="!activating && deletedUserNames.length">
            <div class="">
                <span class="">Caution: </span>
                <span [ngPlural]="deletedUserNames.length">
                    <ng-template ngPluralCase="1"> One user</ng-template>
                    <ng-template ngPluralCase="other"> {{deletedUserNames.length}} users</ng-template>
                </span>
                <span> will be removed from the organization by this action.</span>
            </div>
            <div>
                <button class="btn btn-link sf-btn-link" (click)="showDeletedUsers = !showDeletedUsers"
                    *ngIf="!showDeletedUsers">Show users to be removed
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showDeletedUsers = !showDeletedUsers"
                    *ngIf="showDeletedUsers">Hide users to be removed
                </button>
            </div>
            <div class="scroll-container" *ngIf="showDeletedUsers">
                <div *ngFor="let user of deletedUserNames">{{user}}</div>
            </div>
        </div>

        <!-- users who will lose roles -->
        <div class="vertical-space" *ngIf="!activating && affectedUserNames.length">
            <div class="">
                <span>Be aware that this will disassociate</span>
                <span [ngPlural]="affectedUserNames.length">
                    <ng-template ngPluralCase="1"> one user</ng-template>
                    <ng-template ngPluralCase="other"> {{affectedUserNames.length}} users</ng-template>
                </span>
                <span> from their &quot;{{product.label}}&quot; roles.</span>
            </div>
            <div>
                <button class="btn btn-link sf-btn-link" (click)="showAffectedUsers = !showAffectedUsers"
                    *ngIf="!showAffectedUsers">Show affected users
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showAffectedUsers = !showAffectedUsers"
                    *ngIf="showAffectedUsers">Hide affected users
                </button>
            </div>
            <div class="scroll-container" *ngIf="showAffectedUsers">
                <div *ngFor="let user of affectedUserNames">{{user}}</div>
            </div>
        </div>

        <!-- if deactivating -->
        <div *ngIf="!activating" class="vertical-space">
            <div>
                <label for="reason">Deactivate Status Reason</label>
            </div>
            <div class="vertical-space">
                <sf-select id="reason" name="disable-reason"
                    [hasFilter]="false"
                    trackBy="id"
                    titleBy="label"
                    [options]="disabledReasons"
                    [isSingleSelect]="true"
                    placeholder="Select reason..."
                    (select)="handleSelectReason($event)"
                    [required]="true">
                </sf-select>
            </div>
            <div>
                <label for="reasonDetails">Additional Details</label>
            </div>
            <div>
            <textarea name="reasonDetails" id="reasonDetails" #reasonDetailsArea
                    [(ngModel)]="reasonDetails" class="form-control"
                    [required]="reasonDetailsRequired" rows="3"></textarea>
            </div>
        </div>

        <!-- administrators to receive email notification -->
        <div *ngIf="loadingAdmins">
            <fa-icon icon="spinner" [spin]="true"></fa-icon>
        </div>
        <div class="" *ngIf="administratorUsers.length">
            <hr>
            <label>
                <input type="checkbox" [(ngModel)]="sendNotification">
                <span> {{getSelectedAdministratorCount()}} administrators at
                    {{organization.name}} will receive an email notification.</span>
            </label>
            <div *ngIf="sendNotification">
                <button class="btn btn-link sf-btn-link" (click)="showAdministrators = !showAdministrators"
                    *ngIf="!showAdministrators">
                    Show email recipients
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showAdministrators = !showAdministrators"
                    *ngIf="showAdministrators">
                    Hide email recipients
                </button>
            </div>
            <div *ngIf="!sendNotification">
                <span class="small-text">Notification disabled</span>
            </div>
        </div>
        <div class="scroll-container" *ngIf="showAdministrators && sendNotification">
            <div *ngFor="let administrator of administratorUsers">
                <label>
                    <input type="checkbox" [(ngModel)]="administrator.selected"/>
                    <span> {{administrator.name}} ({{administrator.email}})</span>
                </label>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer *ngIf="!requestSent" [primary]="primary" [secondary]="secondary"></sf-modal-footer>
<div *ngIf="requestSent" class="bottom-spinner">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>
