import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { RpcClientService } from "@sf/common";
import { User } from "@sf/userorg/common";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { ChatInitInfoRCAPI, UserDetailsRCAPI } from "../interfaces/chat.interface";

@Injectable({
    providedIn: "root"
})
export class ChatService {
    chatInfo: any;
    userDetails: any;
    chatInitInfo: any;
    postCaseResponse: any;

    constructor(
        private _rpcClient: RpcClientService,
        private _http: HttpClient
    ) {}

    getOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganization", {
            orgID: orgID
        });
    }

    getUser(userID: string): Observable<User> {
        return this._rpcClient.makeRequest("userorg", "getBasicUser", {
            username: userID
        });
    }

    getUserDetailsRCAPI(
        userEmail: string,
        source: string
    ): Observable<UserDetailsRCAPI> {
        return this._rpcClient
            .makeRequest(
                "userorg",
                "RCAPIGetUserDetails",
                {
                    EmailId: userEmail,
                    Source: source
                },
                true
            )
            .pipe(
                map((response: any) => {
                    this.userDetails = JSON.parse(response);
                    return this.userDetails;
                }),
                catchError((err) => {
                    return of(null);
                })
            );
    }

    getChatInitInfoRCAPI(
        userEmail: string,
        product: string,
        source: string
    ): Observable<ChatInitInfoRCAPI> {
        return this._rpcClient
            .makeRequest("userorg", "RCAPIGetChatInitInfo", {
                EmailId: userEmail,
                Product: product,
                Source: source
            })
            .pipe(
                map((response: any) => {
                    this.chatInitInfo = JSON.parse(response);
                    return this.chatInitInfo;
                }),
                catchError((err) => {
                    return of(null);
                })
            );
    }

    postCaseToSalesForceRCAPI(postCase: any): Observable<any> {
        return this._rpcClient
            .makeRequest("userorg", "RCAPIPostCaseToSalesForce", postCase)
            .pipe(
                map((response: any) => {
                    this.postCaseResponse = JSON.parse(response);
                    return this.postCaseResponse;
                })
            );
    }

    getChatInfoRCAPI(
        userEmail: string,
        caseId: string,
        userChatName: string,
        source: string
    ): Observable<ChatInitInfoRCAPI> {
        return this._rpcClient.makeRequest("userorg", "RCAPIGetChatInfo", {
            EmailId: userEmail,
            CaseId: caseId,
            UserChatName: userChatName,
            Source: source
        });
    }

    // Leave in place to test after hours settings when it isn't after hours
    getChatDetails(): Observable<any> {
        return of({
            isChatSupportOpen: false
        });
    }
}
