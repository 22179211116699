import { Injectable } from "@angular/core";
import { SessionService, UserSettingsService } from "@sf/common";
import { dayjs } from "@sf/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType,
    UserOrgService
} from "@sf/userorg/common";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import {
    DocumentBuilderDataEntryGuideTask,
    DocumentBuilderGuideTask,
    DocumentBuilderNotaryGuideTask,
    DocumentBuilderSignerGuideTask
} from "../interfaces/document-builder-guide-card";
import { DocumentBuilderGuideModalComponent } from "../components/document-builder-guide-modal/document-builder-guide-modal.component";

@Injectable({
    providedIn: "root"
})
export class DocumentBuilderGuideService {
    private _signerPermissions = [
        "submitter_signing_package_signer",
        "submitter_signing_vp_signer",
        "submitter_signing_mers_signer",
        "submitter_signing_package_witness"
    ];
    private _modal: NgbModalRef;

    constructor(
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService,
        private _ngbModal: NgbModal,
        private _bannerNotificationService: BannerNotificationService,
        private _userOrgService: UserOrgService
    ) {}

    showDocumentBuilderGuide() {
        this.shouldShowDocumentBuilderAdminGuide().subscribe(
            (organizationId) => {
                let showAdminGuide = !!organizationId;
                let showSignerGuide =
                    this.shouldShowDocumentBuilderSignerGuide();
                let showNotaryGuide =
                    this.shouldShowDocumentBuilderNotaryGuide();
                let showDataEntryGuide =
                    this.shouldShowDocumentBuilderDataEntryGuide();

                if (
                    showAdminGuide ||
                    showSignerGuide ||
                    showNotaryGuide ||
                    showDataEntryGuide
                ) {
                    this._addBanner(
                        showAdminGuide,
                        showSignerGuide,
                        showNotaryGuide,
                        showDataEntryGuide,
                        organizationId
                    );

                    // only show guide once per session
                    let lastSessionID = this.userSettingsService.getUserSetting(
                        "DOCUMENT_BUILDER_GUIDE_SESSION_ID"
                    );
                    if (lastSessionID !== this._sessionService.getSessionID()) {
                        this.userSettingsService.setUserSetting(
                            "DOCUMENT_BUILDER_GUIDE_SESSION_ID",
                            this._sessionService.getSessionID()
                        );
                        this._displayGuide(
                            showAdminGuide,
                            showSignerGuide,
                            showNotaryGuide,
                            showDataEntryGuide,
                            organizationId
                        );

                        if (showAdminGuide) {
                            // set date that admin guide was first displayed
                            let displayDate =
                                this.userSettingsService.getUserSetting(
                                    "DOCUMENT_BUILDER_GUIDE_DISPLAY_DATE"
                                );
                            if (displayDate === "Never") {
                                this.userSettingsService.setUserSetting(
                                    "DOCUMENT_BUILDER_GUIDE_DISPLAY_DATE",
                                    new Date()
                                );
                            }
                        }
                        if (showSignerGuide) {
                            // set date that signer guide was first displayed
                            let displayDate =
                                this.userSettingsService.getUserSetting(
                                    "DOCUMENT_BUILDER_SIGNER_GUIDE_DISPLAY_DATE"
                                );
                            if (displayDate === "Never") {
                                this.userSettingsService.setUserSetting(
                                    "DOCUMENT_BUILDER_SIGNER_GUIDE_DISPLAY_DATE",
                                    new Date()
                                );
                            }
                        }
                        if (showNotaryGuide) {
                            // set date that notary guide was first displayed
                            let displayDate =
                                this.userSettingsService.getUserSetting(
                                    "DOCUMENT_BUILDER_NOTARY_GUIDE_DISPLAY_DATE"
                                );
                            if (displayDate === "Never") {
                                this.userSettingsService.setUserSetting(
                                    "DOCUMENT_BUILDER_NOTARY_GUIDE_DISPLAY_DATE",
                                    new Date()
                                );
                            }
                        }
                    }
                }
            }
        );
    }

    shouldShowDocumentBuilderAdminGuide(
        organizationIds?: string[]
    ): Observable<string> {
        if (!this._shouldShowAdminGuide()) {
            return of(null);
        } else {
            return this._userOrgService
                .getDocumentBuilderSetupGuideOrganizations()
                .pipe(
                    map((matchedOrganizations) => {
                        return matchedOrganizations.find(
                            (orgId) =>
                                this._sessionService.hasPermission(
                                    "submitter_signing_admin",
                                    orgId
                                ) &&
                                (!organizationIds ||
                                    organizationIds.includes(orgId))
                        );
                    })
                );
        }
    }

    shouldShowDocumentBuilderSignerGuide(): boolean {
        const displayDate = this.userSettingsService.getUserSetting(
            "DOCUMENT_BUILDER_SIGNER_GUIDE_DISPLAY_DATE"
        );
        // only show guide for seven business days after first time
        const lessThan7DaysAgo =
            displayDate === "Never" ||
            this._addBusinessDays(dayjs(displayDate), 7).isAfter(dayjs());

        return (
            sf.liveConfig.DocBuilderConfig.displayDocumentBuilderSignerGuide &&
            this._sessionService.hasAnyPermissionInAnyOrg(
                this._signerPermissions
            ) &&
            this.userSettingsService.getUserSetting(
                "SHOW_DOCUMENT_BUILDER_SIGNER_GUIDE"
            ) &&
            lessThan7DaysAgo &&
            this.hasIncompleteSignerStep()
        );
    }

    shouldShowDocumentBuilderNotaryGuide(): boolean {
        const displayDate = this.userSettingsService.getUserSetting(
            "DOCUMENT_BUILDER_NOTARY_GUIDE_DISPLAY_DATE"
        );
        // only show guide for seven business days after first time
        const lessThan7DaysAgo =
            displayDate === "Never" ||
            this._addBusinessDays(dayjs(displayDate), 7).isAfter(dayjs());

        return (
            sf.liveConfig.DocBuilderConfig.displayDocumentBuilderNotaryGuide &&
            this._sessionService.hasPermissionInAnyOrg(
                "submitter_signing_package_notary"
            ) &&
            this.userSettingsService.getUserSetting(
                "SHOW_DOCUMENT_BUILDER_NOTARY_GUIDE"
            ) &&
            lessThan7DaysAgo &&
            this.hasIncompleteNotaryStep()
        );
    }

    shouldShowDocumentBuilderDataEntryGuide(): boolean {
        return (
            sf.liveConfig.DocBuilderConfig
                .displayDocumentBuilderDataEntryGuide &&
            this._sessionService.hasPermissionInAnyOrg(
                "submitter_signing_data_entry"
            ) &&
            this.userSettingsService.getUserSetting(
                "SHOW_DOCUMENT_BUILDER_DATA_ENTRY_GUIDE"
            ) &&
            this.hasIncompleteDataEntryStep()
        );
    }

    hasIncompleteAdminStep() {
        return !!Object.keys(DocumentBuilderGuideTask).find(
            (taskId) =>
                !this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_${taskId}_STATUS`
                )
        );
    }

    hasIncompleteSignerStep() {
        return !!Object.keys(DocumentBuilderSignerGuideTask).find(
            (taskId) =>
                !this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_SIGNER_GUIDE_${taskId}_STATUS`
                )
        );
    }

    hasIncompleteNotaryStep() {
        return !!Object.keys(DocumentBuilderNotaryGuideTask).find(
            (taskId) =>
                !this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_NOTARY_GUIDE_${taskId}_STATUS`
                )
        );
    }

    hasIncompleteDataEntryStep() {
        return !!Object.keys(DocumentBuilderDataEntryGuideTask).find(
            (taskId) =>
                !this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_DATA_ENTRY_GUIDE_${taskId}_STATUS`
                )
        );
    }

    /*
    getDataEntryOrganizationId() {
        return this._sessionService.getOrganizationIDsWithPermission(
            "submitter_signing_data_entry"
        )[0];
    }
    */

    allStepsComplete(
        showAdminGuide: boolean,
        showSignerGuide: boolean,
        showNotaryGuide: boolean
    ) {
        return (
            (!showAdminGuide || !this.hasIncompleteAdminStep()) &&
            (!showSignerGuide || !this.hasIncompleteSignerStep()) &&
            (!showNotaryGuide || !this.hasIncompleteNotaryStep())
        );
    }

    completeDocumentBuilderAdminGuideTask(
        organizationIds: string[],
        taskId: DocumentBuilderGuideTask
    ) {
        let settingName = `DOCUMENT_BUILDER_GUIDE_${taskId}_STATUS`;
        let currentStatus =
            this.userSettingsService.getUserSetting(settingName);
        if (!currentStatus) {
            let matchedOrgId: string;
            this.shouldShowDocumentBuilderAdminGuide(organizationIds)
                .pipe(
                    filter((orgId) => !!orgId),
                    switchMap((orgId) => {
                        matchedOrgId = orgId;
                        return this.userSettingsService.setUserSetting(
                            settingName,
                            "complete"
                        );
                    })
                )
                .subscribe(() => {
                    let showSignerGuide =
                        this.shouldShowDocumentBuilderSignerGuide();
                    let showNotaryGuide =
                        this.shouldShowDocumentBuilderNotaryGuide();
                    let showDataEntryGuide =
                        this.shouldShowDocumentBuilderDataEntryGuide();
                    this._displayGuide(
                        true,
                        showSignerGuide,
                        showNotaryGuide,
                        showDataEntryGuide,
                        matchedOrgId,
                        [taskId]
                    );

                    if (
                        this.allStepsComplete(
                            true,
                            showSignerGuide,
                            showNotaryGuide
                        )
                    ) {
                        this._bannerNotificationService.removeBannerOfCategory(
                            BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE
                        );
                    }
                });
        }
    }

    completeDocumentBuilderSignerGuideTask(
        taskId: DocumentBuilderSignerGuideTask
    ) {
        this.completeDocumentBuilderSignerGuideTasks([taskId]);
    }

    completeDocumentBuilderSignerGuideTasks(
        taskIds: DocumentBuilderSignerGuideTask[]
    ) {
        let newlyCompletedTaskIds = taskIds.filter(
            (taskId) =>
                !this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_SIGNER_GUIDE_${taskId}_STATUS`
                )
        );
        if (
            !!newlyCompletedTaskIds.length &&
            this.shouldShowDocumentBuilderSignerGuide()
        ) {
            combineLatest(
                [this.shouldShowDocumentBuilderAdminGuide()].concat(
                    newlyCompletedTaskIds.map((taskId) => {
                        let settingName = `DOCUMENT_BUILDER_SIGNER_GUIDE_${taskId}_STATUS`;
                        this.userSettingsService.setUserSetting(
                            settingName,
                            "complete"
                        );
                        return of("");
                    })
                )
            ).subscribe(([adminOrganizationId]) => {
                let showAdminGuide = !!adminOrganizationId;
                let showNotaryGuide =
                    this.shouldShowDocumentBuilderNotaryGuide();
                let showDataEntryGuide =
                    this.shouldShowDocumentBuilderDataEntryGuide();
                this._displayGuide(
                    showAdminGuide,
                    true,
                    showNotaryGuide,
                    showDataEntryGuide,
                    adminOrganizationId,
                    newlyCompletedTaskIds
                );

                if (
                    this.allStepsComplete(showAdminGuide, true, showNotaryGuide)
                ) {
                    this._bannerNotificationService.removeBannerOfCategory(
                        BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE
                    );
                }
            });
        }
    }

    completeDocumentBuilderNotaryGuideTask(
        taskId: DocumentBuilderNotaryGuideTask
    ) {
        let settingName = `DOCUMENT_BUILDER_NOTARY_GUIDE_${taskId}_STATUS`;
        let currentStatus =
            this.userSettingsService.getUserSetting(settingName);
        if (!currentStatus && this.shouldShowDocumentBuilderNotaryGuide()) {
            this.userSettingsService.setUserSetting(settingName, "complete");
            combineLatest([
                this.shouldShowDocumentBuilderAdminGuide()
            ]).subscribe(([adminOrganizationId]) => {
                let showAdminGuide = !!adminOrganizationId;
                let showSignerGuide =
                    this.shouldShowDocumentBuilderSignerGuide();
                let showDataEntryGuide =
                    this.shouldShowDocumentBuilderDataEntryGuide();
                this._displayGuide(
                    showAdminGuide,
                    showSignerGuide,
                    true,
                    showDataEntryGuide,
                    adminOrganizationId,
                    [taskId]
                );
                if (
                    this.allStepsComplete(showAdminGuide, showSignerGuide, true)
                ) {
                    this._bannerNotificationService.removeBannerOfCategory(
                        BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE
                    );
                }
            });
        }
    }

    private _shouldShowAdminGuide(): boolean {
        const hasPermission =
            this._sessionService.hasPermissionInAnyOrg(
                "submitter_signing_admin"
            ) && !this._sessionService.isSuperUser();
        const displayDate = this.userSettingsService.getUserSetting(
            "DOCUMENT_BUILDER_GUIDE_DISPLAY_DATE"
        );
        // only show guide for seven business days after first time
        const lessThan7DaysAgo =
            displayDate === "Never" ||
            this._addBusinessDays(dayjs(displayDate), 7).isAfter(dayjs());

        return (
            sf.liveConfig.DocBuilderConfig.displayDocumentBuilderGuide &&
            hasPermission &&
            lessThan7DaysAgo &&
            this.hasIncompleteAdminStep()
        );
    }

    _displayGuide(
        showAdminGuide: boolean,
        showSignerGuide: boolean,
        showNotaryGuide: boolean,
        showDataEntryGuide: boolean,
        adminOrganizationId: string,
        tasksOnTop?: (
            | DocumentBuilderGuideTask
            | DocumentBuilderSignerGuideTask
            | DocumentBuilderNotaryGuideTask
            | DocumentBuilderDataEntryGuideTask
        )[]
    ) {
        this._modal?.close(); // close previous modal if it's still open
        this._modal = this._ngbModal.open(DocumentBuilderGuideModalComponent, {
            windowClass: "document-builder-guide"
        });
        Object.assign(this._modal.componentInstance, {
            showAdminGuide,
            showSignerGuide,
            showNotaryGuide,
            showDataEntryGuide,
            adminOrganizationId,
            tasksOnTop: tasksOnTop ?? []
        });
    }

    _addBanner(
        showAdminGuide: boolean,
        showSignerGuide: boolean,
        showNotaryGuide: boolean,
        showDataEntryGuide: boolean,
        organizationId: string
    ) {
        this._bannerNotificationService.push({
            type: BannerNotificationType.INFO,
            category: BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE,
            message:
                "You have remaining Document Builder setup tasks. Click here for help completing these tasks.",
            clickHandler: () => {
                this._displayGuide(
                    showAdminGuide,
                    showSignerGuide,
                    showNotaryGuide,
                    showDataEntryGuide,
                    organizationId
                );
            },
            closeHandler: () => {},
            disableTooltip: true
        });
    }

    _addBusinessDays(date: any, daysToAdd: any) {
        const SUNDAY = 0,
            SATURDAY = 6;

        while (daysToAdd > 0) {
            date = date.add(1, "days");
            if (date.day() !== SUNDAY && date.day() !== SATURDAY) {
                --daysToAdd;
            }
        }
        return date;
    }
}
