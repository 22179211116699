import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionService } from "@sf/common";
import { CustomerSurveyDataService } from "./services/customer-survey-data.service";
import { Observable } from "rxjs";
import {
    CustomerSurveyQuestion,
    CustomerSurveyResponse
} from "./interfaces/customer-survey";

@Component({
    selector: "sf-customer-survey", //same Angular JS kebab case selector
    styleUrls: ["./customer-survey.component.scss"],
    templateUrl: "./customer-survey.component.html"
})
export class CustomerSurveyComponent implements OnInit, OnDestroy {
    /** I/O **/

    /** Private Variables **/

    /** Public Variables **/
    superUser = this._session.isSuperUser();
    superUserLoggedInAs = this._session.isLoggedInAs();
    currentQuestion$: Observable<CustomerSurveyQuestion>;

    /** View Children **/
    constructor(
        private _session: SessionService,
        private _customerSurveyData: CustomerSurveyDataService
    ) {}

    /** Lifecycle Hooks **/
    ngOnInit() {
        if (!this.superUser && !this.superUserLoggedInAs) {
            this.currentQuestion$ =
                this._customerSurveyData.subscribeToCustomerSurveys();
        }
    }

    ngOnDestroy() {
        this._customerSurveyData.ngOnDestroy();
    }

    /** Public Methods **/
    questionAnswered({ questionID, response }: CustomerSurveyResponse) {
        this._customerSurveyData.answerQuestion(questionID, response);
    }

    dismissSurvey() {
        this._customerSurveyData.dismissSurvey();
    }

    /**  Private Methods  **/
}
