import { Component } from "@angular/core";
import { ILoadingOverlayAngularComp } from "@ag-grid-community/angular";
import { Icons } from "@sf/common";
import { IconsService } from "@sf/common";

@Component({
    selector: "sf-pending-payments-loading-overlay",
    templateUrl: "./pending-payments-loading-overlay.component.html",
    styleUrls: ["./pending-payments-loading-overlay.component.scss"]
})
export class PendingPaymentsLoadingOverlayComponent
    implements ILoadingOverlayAngularComp
{
    params: any;
    icons: Icons = {};

    constructor(private _iconsService: IconsService) {}

    agInit(params: any): void {
        this.icons.spinner = this._iconsService.get("spinner");
        this.params = params;
    }
}
