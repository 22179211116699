import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { RpcClientService, SessionService } from "@sf/common";
import { PersistentDataStack, PersistentDataStackService } from "@sf/common";
import { CurrentUserService } from "./current-user.service";
import { User } from "../interfaces/user.interface";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class RecentUsersService {
    private _userStack: PersistentDataStack;

    constructor(
            private _rpc: RpcClientService,
            private _currentUserService: CurrentUserService,
            private sessionService: SessionService,
            private persistentDataStackService: PersistentDataStackService
    ) {
        this._userStack = this.persistentDataStackService.createNewStack("sfAdminUserStack", 9);

        // when the 'current' user changes, push it on our 'recent' list
        this._currentUserService.currentUser$.subscribe((user) => {
            if (user && !this.sessionService.isLoggedInAs()) {
                this.pushRecentUserID(user.id);
            }
        });
    }

    getPastRecentUsers(): Observable<User[]> {
        return this.getPastRecentUserIDs(10)
                   .pipe(mergeMap((usernames) => this._rpc.makeRequest<User[]>("userorg", "getBasicUsers", {
                       usernames
                   })));
    }

    getPastRecentUserIDs(count?: number): Observable<string[]> {
        return from(this._userStack.last(count) as Promise<string[]>);
    }

    pushRecentUserID(userID: string): void {
        // an '@' sign is illegal and messes us up - shouldn't ever happen though
        if (!userID.includes("@")) {
            this._userStack.push(userID);
        }
    }

    getLastRecentUserID(): Observable<string> {
        return from(this._userStack.peek() as Promise<string>);
    }

    pullRecentUserIDs(userIDs: string[]): void {
        this._userStack.pullMultiple(userIDs);
    }
}
