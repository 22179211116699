import { Injectable } from "@angular/core";
import {
    animationFrameScheduler,
    asapScheduler,
    BehaviorSubject,
    fromEvent,
    merge,
    Observable
} from "rxjs";
import {
    auditTime,
    debounceTime,
    distinctUntilChanged,
    mapTo,
    shareReplay
} from "rxjs/operators";

/**
 * Scheduler to be used for document events. Needs to fall back to
 * something that doesn't rely on requestAnimationFrame on environments
 * that don't support it (e.g. server-side rendering).
 */
const EVENT_SCHEDULER =
    typeof requestAnimationFrame !== "undefined"
        ? animationFrameScheduler
        : asapScheduler;

@Injectable({
    providedIn: "root"
})
export class UserInactiveService {
    private _isInactive: Observable<any>;
    private _hasActivity: Observable<any>;
    private _activity: Observable<any>;
    private _isActiveBS: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor() {
        this.run();
    }

    isActive: Observable<boolean> = this._isActiveBS.pipe(
        distinctUntilChanged(),
        shareReplay(1)
    );

    get currentActivity(): boolean {
        return this._isActiveBS.getValue();
    }

    run() {
        this._setUpObservables();

        merge(this._hasActivity, this._isInactive).subscribe((isActive) =>
            this._isActiveBS.next(!isActive)
        );
    }

    private _setUpObservables() {
        let mousemove = fromEvent(document, "mousemove");
        let mousedown = fromEvent(document, "mousedown");
        let keypress = fromEvent(document, "keypress");
        let touchmove = fromEvent(document, "touchmove");

        this._activity = merge(mousedown, mousemove, keypress, touchmove).pipe(
            auditTime(2500, EVENT_SCHEDULER)
        );

        this._hasActivity = this._activity.pipe(mapTo(false));

        this._isInactive = this._activity.pipe(
            debounceTime(30000, EVENT_SCHEDULER),
            mapTo(true)
        );
    }
}
