<sf-modal-header title="eRecording in {{ recipient.name }}"></sf-modal-header>
<div class="modal-body" [ngStyle]="bodyStyles">
    <div *ngIf="hasCountyPermission">
        <p>
            This county requires registration before you can e-record. Click
            "Register" below to begin the process.
        </p>
        <div>
            <label>
                <input type="checkbox" [(ngModel)]="hidePaperPackageWarning" />
                Do not show this warning again
            </label>
        </div>
    </div>
    <div *ngIf="!hasCountyPermission">
        <p>
            This county requires registration before you can e-record. Please
            select an administrator below who will receive a notification to
            register.
        </p>
        <div *ngFor="let user of adminUsers" class="indent">
            <label class="sf-mask">
                <input
                    type="checkbox"
                    [(ngModel)]="selectedAdminUsers[user.id]"
                />
                {{ user.name }}
            </label>
        </div>
        <p>
            You can wait for registration or create a paper package in the
            interim.
        </p>
        <div>
            <label>
                <input type="checkbox" [(ngModel)]="hidePaperPackageWarning" />
                Do not show this warning again
            </label>
        </div>
    </div>
</div>
<sf-modal-footer
    [primary]="primary"
    [secondary]="secondary"
    [tertiary]="tertiary"
></sf-modal-footer>
