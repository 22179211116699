import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild
} from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    CdkDragDrop,
    CdkDropList,
    moveItemInArray
} from "@angular/cdk/drag-drop";
import {
    CustomizableColumn,
    DisplayedCustomizableColumn
} from "../../../interfaces";
import { BaseModalComponent } from "../base-modal/base-modal.component";

@Component({
    selector: "sf-choose-columns-dialog",
    templateUrl: "./choose-columns-dialog.component.html",
    styleUrls: ["./choose-columns-dialog.component.scss"]
})
export class ChooseColumnsDialogComponent
    extends BaseModalComponent
    implements OnInit
{
    @Input()
    currentColumns: CustomizableColumn[];
    @Input()
    allColumns: CustomizableColumn[];
    @Input()
    allowReorder: boolean;
    @Input()
    allowColumnSets: boolean;
    @Input()
    putLockedColumnsLast: boolean;

    @ViewChild(CdkDropList)
    dropList: CdkDropList;

    @ViewChild("ariaAnnounce")
    announcementContainer: ElementRef;

    primary: ModalButton;
    secondary: ModalButton;
    possibleColumns: DisplayedCustomizableColumn[] = null;
    reorderIndex = -1;

    constructor(private _activeModal: NgbActiveModal) {
        super();
    }

    /** Lifecycle Hooks **/
    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this._resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this._dismiss.bind(this)
        };

        // Add the currently visible columns first keeping them in the same order.
        this.possibleColumns = this.currentColumns.map((col) => ({
            uniqueId: col.uniqueId,
            displayName: col.displayName,
            alwaysVisible: col.alwaysVisible || col.groupBy,
            showMe: true,
            locked: col.locked,
            groupBy: col.groupBy
        }));
        // Add the columns that are not visible to the end.
        this.allColumns.forEach((col) => {
            if (
                !this.currentColumns.find(
                    ({ uniqueId }) => uniqueId === col.uniqueId
                )
            ) {
                this.possibleColumns.push({
                    uniqueId: col.uniqueId,
                    displayName: col.displayName,
                    alwaysVisible: col.alwaysVisible,
                    showMe: false,
                    locked: col.locked
                });
            }
        });
        // Make sure the hidden columns are sorted.
        this.moveUncheckedColumnsToEnd();
    }

    /** Public Methods **/
    moveUncheckedColumnsToEnd() {
        // sorts the array in place
        this.possibleColumns.sort((a, b) => {
            if (a.showMe && b.showMe) {
                // If they are both true we leave them in the same order.
                return 0;
            } else if (a.showMe && !b.showMe) {
                return -1;
            } else if (!a.showMe && b.showMe) {
                return 1;
            } else {
                // We want the hidden columns sorted by name.
                return a.displayName.localeCompare(b.displayName);
            }
        });
        if (this.putLockedColumnsLast) {
            this._moveLockedColumnsToEnd();
        }
    }

    drop(event: CdkDragDrop<CustomizableColumn[]>) {
        moveItemInArray(
            this.possibleColumns,
            event.previousIndex,
            event.currentIndex
        );
        this.moveUncheckedColumnsToEnd();
    }

    identify(index: number, col: CustomizableColumn): string {
        return col.uniqueId;
    }

    toggleReorder(col: CustomizableColumn, currentIndex: number) {
        if (this.reorderIndex > -1) {
            this.reorderIndex = -1;
            this._announce(`${col.displayName} column dropped`);
        } else {
            this.reorderIndex = currentIndex;
            this._announce(`Reorder ${col.displayName} column grabbed`);
        }
    }

    moveUp(col: CustomizableColumn, currentIndex: number, $event: Event) {
        $event.preventDefault();
        if (this.reorderIndex > 0) {
            this.reorderIndex = currentIndex - 1;
            this._moveColumn(col.displayName, currentIndex, this.reorderIndex);
        }
    }

    moveDown(col: CustomizableColumn, currentIndex: number, $event: Event) {
        $event.preventDefault();
        if (
            this.reorderIndex > -1 &&
            this.reorderIndex <
                this.possibleColumns.filter((c) => !c.locked).length - 1
        ) {
            this.reorderIndex = currentIndex + 1;
            this._moveColumn(col.displayName, currentIndex, this.reorderIndex);
        }
    }

    stopReorder(col: CustomizableColumn) {
        this.reorderIndex = -1;
        this._announce(`${col.displayName} dropped`);
    }

    /** Private Methods **/
    private _moveLockedColumnsToEnd() {
        // sorts the array in place
        this.possibleColumns.sort((a, b) => {
            if (a.locked && !b.locked) {
                return 1;
            }
            if (!a.locked && b.locked) {
                return -1;
            }
            if (a.locked && b.locked) {
                return a.locked - b.locked; // sort by locked i.e., 1 will come before 2
            }
            return 0;
        });
    }

    private _resolve() {
        let columns: CustomizableColumn[] = [];
        const allColumnsMap: {
            [key: string]: CustomizableColumn;
        } = this.allColumns.reduce(
            (obj, col) => ({ ...obj, [col.uniqueId]: col }),
            {}
        );
        for (let col of this.possibleColumns) {
            if (col.showMe) {
                let visibleCol: CustomizableColumn | undefined;
                if (col.groupBy) {
                    visibleCol = this.currentColumns.find(
                        (c) => c.uniqueId === col.uniqueId
                    );
                } else {
                    visibleCol = allColumnsMap[col.uniqueId];
                }
                if (visibleCol) {
                    columns.push(visibleCol);
                }
            }
        }
        this._activeModal.close(columns);
    }

    private _dismiss() {
        this._activeModal.close(null);
    }

    private _moveColumn(name: string, currentIndex: number, newIndex: number) {
        moveItemInArray(this.possibleColumns, currentIndex, newIndex);
        this.moveUncheckedColumnsToEnd();
        setTimeout(() => {
            (
                this.dropList.element.nativeElement
                    .querySelectorAll("fa-icon")
                    .item(newIndex) as HTMLElement
            ).focus();
            this.reorderIndex = newIndex;
            this._announce(
                `The column order has been updated, ${name} is now #${
                    newIndex + 1
                } of ${this.possibleColumns.length}`
            );
        }, 0);
    }

    private _announce(announcement: string) {
        this.announcementContainer.nativeElement.innerText = announcement;
    }
}
