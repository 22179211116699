export enum SubmitterNotificationEventType {
    USER_SIGNATURE_WAITING = "Signature Waiting",
    DOCUMENT_REJECTED = "Document Rejected",
    DOCUMENT_RECORDED = "Document Recorded",
    PACKAGE_SUBMITTED = "Package Submitted",
    PACKAGE_REJECTED = "Package Rejected",
    PACKAGE_RECORDED = "Package Recorded",
    DAILY_REPORT = "Daily Report",
    MONTHLY_REPORT = "Monthly Report",
    WEEKLY_REPORT = "Weekly Report",
    SEMI_MONTHLY_REPORT = "Semi-Monthly Report",
    SIMPLIFILE_BALANCED_SCORECARD = "Simplifile Balanced Scorecard",
    MESSAGE_CENTER_UPDATES = "Message Center Updates"
}
