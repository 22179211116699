import { Component, Input, OnInit } from "@angular/core";
import {
    ResultObjectType,
    UniversalResult
} from "../../universal-search.interfaces";
import { UniversalSearchComponentService } from "../universal-search-component.service";

@Component({
    selector: "sf-universal-search-result",
    templateUrl: "./universal-search-result.component.html",
    styleUrls: ["./universal-search-result.component.scss"]
})
export class UniversalSearchResultComponent implements OnInit {
    @Input() result: UniversalResult;
    @Input() showingRecentItems: boolean;
    @Input() query: string;

    get ResultObjectType() {
        return ResultObjectType;
    }

    get uscService(): UniversalSearchComponentService {
        return this._uscService;
    }

    constructor(private _uscService: UniversalSearchComponentService) {}

    ngOnInit(): void {}
}
