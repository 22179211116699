import {
    Directive,
    Input,
    OnDestroy,
    SimpleChanges,
    Output,
    EventEmitter,
    OnChanges
} from "@angular/core";
import { parents } from "../../helpers/browser";

@Directive({
    selector: "[sfSelectCloseOnOffClick]"
})
export class SfSelectCloseOnOffClickDirective implements OnChanges, OnDestroy {
    /* Inputs */
    @Input()
    isOpen: boolean;
    @Input()
    selectorId: string;

    /* Outputs */
    @Output()
    toggleOpen: EventEmitter<null> = new EventEmitter();

    constructor() {
        this._closeOnOffClick = this._closeOnOffClick.bind(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isOpen && changes.isOpen.currentValue === true) {
            this._attachEventCatcher();
        }
        if (changes.isOpen && changes.isOpen.currentValue === false) {
            this._removeEventCatcher();
        }
    }

    ngOnDestroy() {
        this._removeEventCatcher();
    }

    _attachEventCatcher() {
        document.body.addEventListener("click", this._closeOnOffClick, true);
    }

    _removeEventCatcher() {
        document.body.removeEventListener("click", this._closeOnOffClick, true);
    }

    _closeOnOffClick($event: any) {
        let insideDropdown =
            $event.target.id === "ms-two-button-" + this.selectorId ||
            parents($event.target, "#ms-two-button-" + this.selectorId)
                .length ||
            parents($event.target, "#ms-two-popup-container-" + this.selectorId)
                .length;
        if (this.isOpen && !insideDropdown) {
            this.toggleOpen.emit($event);
        }
    }
}
