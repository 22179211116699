import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MetricLoggerService, StatesService } from "@sf/common";
import type { Organization, User } from "@sf/userorg/common";
import { ChatService } from "../../services/chat.service";
import { map, shareReplay, switchMap, take, takeUntil } from "rxjs/operators";
import { combineLatest, Observable, Subject } from "rxjs";
import { ChatUploadService } from "../../services/chat-upload.service";
import { ChatUploadFileTypeConstants } from "../../constants/chat-upload-file-type.constants";
import {
    CasePosted,
    ChatInitInfoRCAPI,
    UserDetailsRCAPI
} from "../../interfaces/chat.interface";
import { ChatOverlayService } from "../../services/chat-overlay.service";

enum view {
    loadingView,
    generalError,
    chooseSupportType,
    chatDetails,
    caseDetails,
    sendingCaseComplete,
    chatInitialized
}

enum topicArea {
    erecording = "erecording",
    capc = "capc",
    esign = "esign",
    erecordingCounty = "erecordingCounty"
}

enum supportType {
    chat,
    case
}

enum GeneralError {
    missingIdError,
    chatInitError,
    caseSendingError, // Error while sending request to create case
    caseCreateFailedError, // Received a response when creating case but no new case Id
    caseCreatedButAttachmentsFailed // The case created successfully but the request to add attachments failed
}

interface UserContactInfo {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

interface UserSalesforceInfo {
    contactId: string;
    accountId: string;
    environmentId: string;
}

enum Severity {
    General = "General Help and Configuration",
    Minor = "Minor Performance Issue",
    Some = "System or Service Impact for Some Users",
    Moderate = "Moderate Performance Issue, Multiple Users",
    Major = "Major Performance Issue, All Users Affected",
    Critical = "Critical System or Service Down, All Users Affected"
}

const SeverityOptions: { id: number; name: Severity }[] = [
    { id: 1, name: Severity.General },
    { id: 2, name: Severity.Minor },
    { id: 3, name: Severity.Some },
    { id: 4, name: Severity.Moderate },
    { id: 5, name: Severity.Major },
    { id: 6, name: Severity.Critical }
];

const LiveEventOptions: { name: string }[] = [{ name: "Yes" }, { name: "No" }];

// The SalesForce liveagent object is added to window when the SalesForce DeploymentJSUrl script is loaded on the page
declare let liveagent: any;

@Component({
    selector: "sf-pre-chat-dialog",
    templateUrl: "./pre-chat-dialog.component.html",
    styleUrls: ["./pre-chat-dialog.component.scss"]
})
export class PreChatDialogComponent implements OnInit {
    @Input()
    username: string;
    @Input()
    organizationID: string;
    @Input()
    userOrganizationIDs: any;
    @Input()
    isRecipient: boolean;
    @Input()
    initUrl: string;

    private _onDestroy: Subject<void> = new Subject<void>();

    acceptFileTypes = ChatUploadFileTypeConstants.ALL_POSSIBLE_TYPES_AS_STRING;

    chatForm: FormGroup;
    caseForm: FormGroup;
    caseAttachments: File[] = [];
    expandCaseAttachmentsList: boolean;

    readonly view = view;
    readonly topicArea = topicArea;
    readonly supportType = supportType;
    currentView: view = view.loadingView;
    chatDetailsSelected: boolean;
    caseDetailsSelected: boolean;
    topicErecordSet = true;
    topicCapcSet = false;
    topicEsignSet = false;
    loadingViewText = "Loading...";
    loadingViewSubText: string = null;
    warningList: string[];
    chatTooltip: string;
    title = "Contact Customer Support";
    errorMessage: string;
    readonly liveAgentButtonId: string = "liveAgentHiddenButtonId";
    readonly chatWindowName: string = "sfChatWindow";
    severityOptions = SeverityOptions;
    liveEventOptions = LiveEventOptions;
    showSeverityMessage = false;
    highSeverityAfterHours: boolean;
    states: any;
    defaultOrgState: string;
    selectedState: string;
    topicAreaSelected = "erecording";
    selectedLiveEvent: string = "N/A";
    loanNumber: string;
    loanNumberCheckbox: any = false;
    reason: string;
    source = "Simplifile";
    product = "Simplifile";

    // User Info
    userContactInfo$: Observable<UserContactInfo> = null;
    userSalesforceInfo$: Observable<UserSalesforceInfo> = null;

    //Chat Init Var
    chatInitInfo: ChatInitInfoRCAPI;
    //Post Case Var
    caseId: string;

    constructor(
        public activeModal: NgbActiveModal,
        private statesService: StatesService,
        private _chatService: ChatService,
        private _chatUploadService: ChatUploadService,
        private _metricLoggerService: MetricLoggerService,
        private _chatOverlayService: ChatOverlayService
    ) {}

    ngOnInit() {
        this.currentView = view.loadingView;

        // get information for their default organization
        if (this.organizationID !== "") {
            this._chatService
                .getOrganization(this.organizationID)
                .subscribe((org: Organization) => {
                    this.defaultOrgState = org?.address?.state;
                    if (!this.defaultOrgState) {
                        this.logMetric(
                            "init_no_state_for_default_organziation"
                        );
                        this.defaultOrgState = "None";
                    }
                });
        } else {
            this.logMetric("init_no_default_organization");
            this.defaultOrgState = "None";
        }

        // Get chat init info from salesforce
        this._chatService
            .getChatInitInfoRCAPI(
                "random_string_does_not_matter", // The email is not verified or used, but it is required
                this.product,
                this.source
            )
            .pipe(take(1))
            .subscribe((chatInitRCAPI: ChatInitInfoRCAPI) => {
                if (chatInitRCAPI === null) {
                    this.setErrorMessage(GeneralError.chatInitError);
                    this.logMetric("error_chatInitRCAPI_response_is_null");
                } else {
                    this.chatInitInfo = chatInitRCAPI;
                    this.currentView = view.chooseSupportType;
                    // Sets the default value to chat or case depending on if chat is available
                    if (this.chatInitInfo.IsChatSupportOpen) {
                        this.chatDetailsSelected = true;
                        this.caseDetailsSelected = false;
                    } else {
                        this.chatDetailsSelected = false;
                        this.caseDetailsSelected = true;
                        this.chatTooltip =
                            "Our support team is unavailable to chat at this time.";
                    }

                    //loading the salesforce script
                    this._loadSalesforceDeploymentUrl();
                }
            });

        this.userContactInfo$ = this._chatService.getUser(this.username).pipe(
            map((userInfo: User) => {
                return {
                    firstName: userInfo?.name?.first,
                    lastName: userInfo?.name?.last,
                    email: userInfo?.email,
                    phone: userInfo?.phoneText
                };
            }),
            shareReplay(1),
            takeUntil(this._onDestroy)
        );
        // Pre-load the user details
        this.userContactInfo$.pipe(take(1)).subscribe();

        // Get SalesForce Contact for the User if it exists. If not, use backup Contact.
        this.userSalesforceInfo$ = this.userContactInfo$.pipe(
            switchMap((user: UserContactInfo) => {
                return this._chatService.getUserDetailsRCAPI(
                    user.email,
                    this.source
                );
            }),
            map((response: UserDetailsRCAPI) => {
                if (response === null) {
                    this.logMetric("error_userDetailsRCAPI_response_is_null");
                }
                const contactId = this.getContactId(response);
                const accountId = this.getAccountId(response);
                const environmentId = this.getEnvironmentId(response);
                if (!contactId || !accountId || !environmentId) {
                    log.error(
                        "Missing a required Id! Case will not be created.\n",
                        "contactId:",
                        contactId,
                        "accountId:",
                        accountId,
                        "environmentId:",
                        environmentId,
                        "\nYou need all three. Setting a default Id will get rid of this error."
                    );
                    this.setErrorMessage(GeneralError.missingIdError);
                    if (!contactId) {
                        this.logMetric("error_missing_default_contactId");
                    }
                    if (!accountId) {
                        this.logMetric("error_missing_default_accountId");
                    }
                    if (!environmentId) {
                        this.logMetric("error_missing_default_environmentId");
                    }
                } else {
                    // Successfully loaded user. Ready to go
                    return {
                        contactId: contactId,
                        accountId: accountId,
                        environmentId: environmentId
                    };
                }
            }),
            shareReplay(1),
            takeUntil(this._onDestroy)
        );
        // Pre-load the user details
        this.userSalesforceInfo$.pipe(take(1)).subscribe();

        //bring the state info in for the dropdown
        this.states = this.statesService.getAllStates();
        this.states.push({
            abbrev: "None",
            name: "No State"
        });
    }

    ngOnDestroy(): void {
        if (liveagent) {
            liveagent.disconnect();
            // Remove the liveagent and liveAgentDeployment objects from the window
            // This completes the cleanup of the SalesForce liveagent library
            setTimeout(() => {
                //@ts-ignore
                delete window.liveagent;
                //@ts-ignore
                delete window.liveAgentDeployment;
                //@ts-ignore
                delete window._laq;
            });
        }
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private _loadSalesforceDeploymentUrl() {
        const node = document.createElement("script");
        node.src = this.chatInitInfo.DeploymentJSUrl;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
    }

    setErrorMessage(Error: GeneralError) {
        switch (Error) {
            case GeneralError.chatInitError:
            case GeneralError.missingIdError:
                this.errorMessage =
                    "We are currently experiencing an issue connecting to our chat service. Please contact our support team at the number below.";
                break;
            case GeneralError.caseCreatedButAttachmentsFailed:
                this.errorMessage =
                    "Your ticket has been successfully created and will be reviewed in the order it was received. However, the attachments failed to send. If the attachments are crucial, please contact our support team at the number below for assistance.";
                break;
            case GeneralError.caseSendingError:
            case GeneralError.caseCreateFailedError:
            default:
                this.errorMessage =
                    "We are currently experiencing an issue, your ticket was not submitted to our support team. Please contact our support team at the number below for assistance.";
                break;
        }

        this.errorMessage += " (Error Code: F." + Error + ")";

        this.currentView = view.generalError;
    }

    createRequiredTooltip() {
        if (this.highSeverityAfterHours) {
            return "Because it is after normal support hours and you have selected a high severity level, you will need to call our support team for assistance. ";
        }
        if (this.warningList) {
            if (this.warningList.length) {
                const warningText = this.warningList.join(", ");
                return "You are missing these required fields: " + warningText;
            } else {
                return "Click here to start...";
            }
        }
    }

    chooseSupportType(SupportType: supportType) {
        if (SupportType === supportType.chat) {
            this.chatDetailsSelected = true;
            this.caseDetailsSelected = false;
        } else if (SupportType === supportType.case) {
            this.chatDetailsSelected = false;
            this.caseDetailsSelected = true;
        }
    }

    selectSupportMethod(View: view) {
        if (View === view.chatDetails) {
            this.currentView = view.chatDetails;
            this.title = "Select Your Simplifile Service";
            this.buildChatForm();
            this._subscribeToChatFormChanges();
            this.findInvalidControlsChat();
        } else if (View === view.caseDetails) {
            this.currentView = view.loadingView;
            this.userContactInfo$
                .pipe(take(1))
                .subscribe((userContactInfo: UserContactInfo) => {
                    this.buildCaseForm(userContactInfo);
                    this._subscribeToCaseFormChanges();
                    this.findInvalidControlsCase();
                    this.currentView = view.caseDetails;
                    this.title = "Open a Support Ticket";
                });
        }
    }

    buildChatForm() {
        this.chatForm = new FormGroup({
            customerDescription: new FormControl("", Validators.required),
            topicArea: new FormControl("", Validators.required),
            stateSelection: new FormControl("", Validators.required),
            liveEventSelection: new FormControl("", Validators.required),
            loanNumber: new FormControl("", Validators.required),
            loanNumberCheckbox: new FormControl("")
        });
        this.chatForm.controls["liveEventSelection"].setValue("N/A");
        this.chatForm.controls["loanNumber"].setValue("N/A");
        if (this.isRecipient) {
            this.chatForm.get("topicArea").setValue("erecordingCounty");
            this.chatForm.get("stateSelection").setValue(this.defaultOrgState);
        } else {
            this.chatForm.get("topicArea").setValue("erecording");
        }
        this.topicErecordSet = true;
        this._subscribeToChatFormChanges();
    }

    buildCaseForm(userContactInfo: UserContactInfo) {
        this.caseForm = new FormGroup({
            firstName: new FormControl("", Validators.required),
            lastName: new FormControl("", Validators.required),
            email: new FormControl("", Validators.required),
            phone: new FormControl("", Validators.required),
            customerDescription: new FormControl("", Validators.required),
            topicArea: new FormControl("", Validators.required),
            stateSelection: new FormControl("", Validators.required),
            liveEventSelection: new FormControl("", Validators.required),
            loanNumber: new FormControl("", Validators.required),
            loanNumberCheckbox: new FormControl(""),
            severity: new FormControl("", Validators.required),
            attachment: new FormControl("")
        });
        this.caseForm.get("firstName").setValue(userContactInfo.firstName);
        this.caseForm.get("lastName").setValue(userContactInfo.lastName);
        this.caseForm.get("email").setValue(userContactInfo.email);
        this.caseForm.get("phone").setValue(userContactInfo.phone);
        this.caseForm.get("severity").setValue(SeverityOptions[0].name);

        this.caseForm.controls["liveEventSelection"].setValue("N/A");
        this.caseForm.controls["loanNumber"].setValue("N/A");
        if (this.isRecipient) {
            this.caseForm.get("topicArea").setValue("erecordingCounty");
            this.caseForm.get("stateSelection").setValue(this.defaultOrgState);
        } else {
            this.caseForm.get("topicArea").setValue("erecording");
        }
        this.topicErecordSet = true;
        this._subscribeToCaseFormChanges();
    }

    setTopicArea(TopicArea: topicArea, View: view) {
        if (View === view.chatDetails) {
            if (this.topicArea.erecording === TopicArea) {
                this.chatForm.get("topicArea").setValue("erecording");
                this.topicErecordSet = true;
                this.topicCapcSet = false;
                this.topicEsignSet = false;
            } else if (this.topicArea.capc === TopicArea) {
                this.chatForm.get("topicArea").setValue("capc");
                this.topicErecordSet = false;
                this.topicCapcSet = true;
                this.topicEsignSet = false;
            } else if (this.topicArea.esign === TopicArea) {
                this.chatForm.get("topicArea").setValue("esign");
                this.topicErecordSet = false;
                this.topicCapcSet = false;
                this.topicEsignSet = true;
            }
        } else if (View === view.caseDetails) {
            if (this.topicArea.erecording === TopicArea) {
                this.caseForm.get("topicArea").setValue("erecording");
                this.topicCapcSet = false;
                this.topicErecordSet = true;
                this.topicEsignSet = false;
            } else if (this.topicArea.capc === TopicArea) {
                this.caseForm.get("topicArea").setValue("capc");
                this.topicErecordSet = false;
                this.topicCapcSet = true;
                this.topicEsignSet = false;
            } else if (this.topicArea.esign === TopicArea) {
                this.caseForm.get("topicArea").setValue("esign");
                this.topicErecordSet = false;
                this.topicCapcSet = false;
                this.topicEsignSet = true;
            }
        }
    }

    private _setLoadingViewText(text: string, subText: string = null) {
        this.loadingViewText = text;
        this.loadingViewSubText = subText;
    }

    private _subscribeToChatFormChanges() {
        this.chatForm.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.findInvalidControlsChat();
            });
        this.chatForm
            .get("topicArea")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((topicArea: string) => {
                this.topicAreaSelected = topicArea;
                if (this.topicAreaSelected === "capc") {
                    this.chatForm.controls["stateSelection"].setValue(
                        this.defaultOrgState,
                        { emitEvent: false }
                    );
                    this.chatForm.controls["liveEventSelection"].setValue(
                        "N/A"
                    );
                    this.chatForm.controls["loanNumber"].reset();
                } else if (this.topicAreaSelected === "esign") {
                    this.chatForm.controls["loanNumber"].setValue("N/A");
                    this.chatForm.controls["liveEventSelection"].reset();
                    this.chatForm.controls["stateSelection"].setValue(
                        this.defaultOrgState,
                        { emitEvent: false }
                    );
                } else if (this.topicAreaSelected === "erecording") {
                    this.chatForm.controls["loanNumber"].setValue("N/A");
                    this.chatForm.controls["stateSelection"].setValue("");
                    this.chatForm.controls["liveEventSelection"].setValue(
                        "N/A"
                    );
                }
            });
        this.chatForm
            .get("loanNumberCheckbox")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((loanNumberCheckbox: boolean) => {
                this.loanNumberCheckbox = loanNumberCheckbox;
                if (this.loanNumberCheckbox) {
                    this.chatForm.controls["loanNumber"].disable(
                        this.loanNumberCheckbox
                    );
                    this.chatForm.controls["loanNumber"].setValue("");
                } else if (!this.loanNumberCheckbox) {
                    this.chatForm.controls["loanNumber"].enable(
                        this.loanNumberCheckbox
                    );
                }
            });
    }

    private _subscribeToCaseFormChanges() {
        this.caseForm.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.findInvalidControlsCase();
            });
        this.caseForm
            .get("topicArea")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((topicArea: string) => {
                this.topicAreaSelected = topicArea;
                if (this.topicAreaSelected === "capc") {
                    this.caseForm.controls["loanNumber"].reset();
                    this.caseForm.controls["stateSelection"].setValue(
                        this.defaultOrgState,
                        { emitEvent: false }
                    );
                    this.caseForm.controls["liveEventSelection"].setValue(
                        "N/A"
                    );
                } else if (this.topicAreaSelected === "esign") {
                    this.caseForm.controls["liveEventSelection"].reset();
                    this.caseForm.controls["stateSelection"].setValue(
                        this.defaultOrgState,
                        { emitEvent: false }
                    );
                    this.caseForm.controls["loanNumber"].setValue("N/A");
                } else if (this.topicAreaSelected === "erecording") {
                    this.caseForm.controls["stateSelection"].setValue("");
                    this.caseForm.controls["liveEventSelection"].setValue(
                        "N/A"
                    );
                    this.caseForm.controls["loanNumber"].setValue("N/A");
                }
            });
        this.caseForm
            .get("severity")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((severity: Severity) => {
                if (
                    severity === Severity.Major ||
                    severity === Severity.Critical
                ) {
                    this.showSeverityMessage = true;

                    if (!this.chatInitInfo.IsChatSupportOpen) {
                        this.highSeverityAfterHours = true;
                    }
                } else {
                    this.showSeverityMessage = false;
                    this.highSeverityAfterHours = false;
                }
            });
        this.caseForm
            .get("loanNumberCheckbox")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((loanNumberCheckbox: boolean) => {
                this.loanNumberCheckbox = loanNumberCheckbox;
                if (this.loanNumberCheckbox) {
                    this.caseForm.controls["loanNumber"].disable(
                        this.loanNumberCheckbox
                    );
                    this.caseForm.controls["loanNumber"].setValue("");
                } else if (!this.loanNumberCheckbox) {
                    this.caseForm.controls["loanNumber"].enable(
                        this.loanNumberCheckbox
                    );
                }
            });
    }

    findInvalidControlsChat() {
        this.warningList = [];
        const controls = this.chatForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                switch (name) {
                    case "customerDescription":
                        this.warningList.push("Customer Description");
                        break;
                    case "topicArea":
                        this.warningList.push("Topic Area");
                        break;
                    case "stateSelection":
                        this.warningList.push("State");
                        break;
                    case "liveEventSelection":
                        this.warningList.push("Live Event");
                        break;
                    case "loanNumber":
                        this.warningList.push("Loan Number");
                        break;
                }
            }
        }
        this.createRequiredTooltip();
    }

    findInvalidControlsCase() {
        this.warningList = [];
        const controls = this.caseForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                switch (name) {
                    case "firstName":
                        this.warningList.push("First Name");
                        break;
                    case "lastName":
                        this.warningList.push("Last Name");
                        break;
                    case "email":
                        this.warningList.push("Email");
                        break;
                    case "phone":
                        this.warningList.push("Phone");
                        break;
                    case "customerDescription":
                        this.warningList.push("Customer Description");
                        break;
                    case "topicArea":
                        this.warningList.push("Topic Area");
                        break;
                    case "stateSelection":
                        this.warningList.push("State");
                        break;
                    case "severity":
                        this.warningList.push("Severity");
                        break;
                    case "liveEventSelection":
                        this.warningList.push("Live Event");
                        break;
                    case "loanNumber":
                        this.warningList.push("Loan Number");
                        break;
                }
            }
        }
        this.createRequiredTooltip();
    }

    openFilePicker($event: Event, input: HTMLInputElement) {
        // detail is 0 when it's a keypress like enter or space.
        // We can ignore actual mouse clicks because those are passed down through to the file input.
        if (($event as MouseEvent).detail === 0) {
            input.click();
        }
    }

    handleFileInput($event: Event) {
        const target = $event.target as HTMLInputElement;
        if (target.files?.length > 0) {
            for (let i = 0; i < target.files.length; i++) {
                if (
                    !this.caseAttachments.some((f) =>
                        this._filesEqual(f, target.files[i])
                    )
                ) {
                    this.caseAttachments.push(target.files[i]);
                }
            }
        }
        target.value = null;
    }

    clearCaseAttachments() {
        this.caseAttachments = [];
    }

    removeCaseAttachment(attachment: File) {
        this.caseAttachments = this.caseAttachments.filter(
            (f) => !this._filesEqual(f, attachment)
        );
    }

    /**
     * Use a shortcut to check if two files are equal by checking attributes about
     * the files. (eg. name, size, lastModified) This is much faster than checking
     * the contents of the files.
     *
     * @private
     */
    private _filesEqual(a: File, b: File): boolean {
        return (
            a.name === b.name &&
            a.size === b.size &&
            a.lastModified === b.lastModified &&
            a.type === b.type
        );
    }

    setRoutingReason() {
        if (this.chatForm) {
            this.topicAreaSelected = this.chatForm.get("topicArea").value;
        } else if (this.caseForm) {
            this.topicAreaSelected = this.caseForm.get("topicArea").value;
        }

        if (
            sf.liveConfig.PlatformServicesSettings
                .setSalesforceReasonToSimplifile
        ) {
            this.reason = "Simplifile";
        } else {
            if (this.topicAreaSelected === "erecordingCounty") {
                this.reason = "Simplifile - eRecording (County)";
            } else if (this.topicAreaSelected === "erecording") {
                this.reason = "Simplifile - eRecording";
            } else if (this.topicAreaSelected === "capc") {
                this.reason = "Simplifile - Pre/Post Close";
            } else if (this.topicAreaSelected === "esign") {
                this.reason = "Simplifile - eSign Events";
            }
        }
    }

    getCaseContactInformation() {
        return (
            "Name: " +
            this.caseForm.get("firstName").value +
            " " +
            this.caseForm.get("lastName").value +
            "\r\nEmail: " +
            this.caseForm.get("email").value +
            "\r\n Phone: " +
            this.caseForm.get("phone").value
        );
    }

    getChatContactInformation(userContactInfo: UserContactInfo) {
        return (
            "Name: " +
            userContactInfo.firstName +
            " " +
            userContactInfo.lastName +
            "\r\nEmail: " +
            userContactInfo.email +
            "\r\n Phone: " +
            userContactInfo.phone
        );
    }

    getDescriptionDetails(contactInformation: string) {
        let stateAndOrg;
        let liveEventDescription: string =
            "Are they currently facilitating a live IPEN or RON event: " +
            this.selectedLiveEvent;
        if (this.organizationID !== "") {
            const organization = this.organizationID;
            if (this.isRecipient) {
                stateAndOrg = "Default Organization: " + organization;
            }
            stateAndOrg =
                "State: " +
                this.selectedState +
                "\r\nDefault Organization: " +
                organization;
        } else if (this.organizationID === "") {
            const firstFourOrganizationIDs = this.userOrganizationIDs.splice(
                0,
                4
            );
            if (this.isRecipient) {
                stateAndOrg = "OrgIDs: " + firstFourOrganizationIDs;
            } else {
                stateAndOrg =
                    "State: " +
                    this.selectedState +
                    "\r\nOrgIDs: " +
                    firstFourOrganizationIDs;
            }
        }
        return (
            "Reason: " +
            this.reason +
            "\r\nOpened from: " +
            this.initUrl +
            "\r\nUsername: " +
            this.username +
            "\r\n" +
            contactInformation +
            "\r\n" +
            stateAndOrg +
            "\r\n" +
            liveEventDescription
        );
    }

    postCaseForNewChat() {
        this._chatOverlayService.open();

        //set the loading view for chat
        this.currentView = view.loadingView;
        this._setLoadingViewText(
            "Initiating Chat",
            "A separate window will open for chatting."
        );
        this.title = "One moment while we connect you...";
        this.selectedState = this.chatForm.get("stateSelection").value;
        this.selectedLiveEvent = this.chatForm.get("liveEventSelection").value;
        //in case there is no default state and capc was selected
        if (this.selectedState === "None") {
            this.selectedState = "";
        }

        combineLatest([
            this.userContactInfo$.pipe(take(1)),
            this.userSalesforceInfo$.pipe(take(1))
        ]).subscribe(([userContactInfo, userSalesforceInfo]) => {
            const contactInformation =
                this.getChatContactInformation(userContactInfo);
            this.setRoutingReason();

            // Add anything to the description here
            const description =
                "Customer Question: " +
                this.chatForm.get("customerDescription").value +
                "\r\n" +
                this.getDescriptionDetails(contactInformation);

            const postCase = {
                EmailId: userContactInfo.email,
                ContactId: userSalesforceInfo.contactId,
                AccountId: userSalesforceInfo.accountId,
                IMT_Environment__c: userSalesforceInfo.environmentId,
                IMT_On_Shore_Only_Flag__c: false,
                Subject: this.getSubject(),
                Description: description,
                IMT_Routing_State__c: this.selectedState,
                IMT_Contact_Method__c: "Email",
                IMT_Entitlement_Type__c: "Standard",
                Reason: this.reason,
                Priority: "Medium",
                Status: "Pending - Chat Routing",
                Type: "Help/Assistance",
                Origin: "Chat",
                SourceName: "Simplifile"
            };

            this._chatService.postCaseToSalesForceRCAPI(postCase).subscribe(
                (casesPosted: CasePosted[]) => {
                    if (casesPosted?.length >= 1) {
                        if (casesPosted?.length > 1) {
                            console.warn(
                                "BAD STATE: Multiple SalesForce cases were created. This should never happen.\n",
                                "Using the first case returned and ignoring the rest."
                            );
                        }
                        this.caseId = casesPosted[0].Id;
                        this.connectCaseToChat(userContactInfo);
                    } else {
                        this.setErrorMessage(
                            GeneralError.caseCreateFailedError
                        );
                        this.logMetric(
                            "error_chat_case_creation_failed-no_case_created"
                        );
                    }
                },
                (errorFound) => {
                    this.setErrorMessage(GeneralError.caseSendingError);
                    this.logMetric(
                        "error_chat_case_creation_failed-request_error"
                    );
                }
            );
        });
    }

    connectCaseToChat(userContact: UserContactInfo) {
        //Sets up the local live agent button
        //@ts-ignore
        if (!window._laq) {
            //@ts-ignore
            window._laq = [];
        }
        // Set local variables so external callbacks don't hold a reference to "this" and cause memory leaks
        const localLiveAgentButton = this.liveAgentButtonId;
        const localChatButtonIdToSend = this.chatInitInfo.ChatButtonId;
        //@ts-ignore
        window._laq.push(function () {
            liveagent.showWhenOnline(
                localChatButtonIdToSend,
                document.getElementById(localLiveAgentButton)
            );
        });
        // Links to the case and adds in the routing details
        liveagent.addCustomDetail("CaseId", this.caseId);
        liveagent
            .findOrCreate("Case")
            .map("Id", "CaseId", true, true, false)
            .saveToTranscript("CaseId")
            .showOnCreate();
        liveagent
            .addCustomDetail("IMT_Case_Reason__c", this.reason)
            .saveToTranscript("IMT_Case_Reason__c");
        liveagent
            .addCustomDetail("IMT_On_Shore_Only_Flag__c", false)
            .saveToTranscript("IMT_On_Shore_Only_Flag__c");
        liveagent
            .addCustomDetail("IMT_Entitlement_Type__c", "Standard")
            .saveToTranscript("IMT_Entitlement_Type__c");
        liveagent
            .addCustomDetail("IMT_Routing_State__c", this.selectedState)
            .saveToTranscript("IMT_Routing_State__c");
        liveagent
            .addCustomDetail("EmailVar", userContact.email)
            .map("Contact", "EmailVar", true, true, false)
            .saveToTranscript("IMT_Email__c");
        liveagent
            .findOrCreate("Contact", userContact.email)
            .map("Email", "EmailVar", true, true, false)
            .saveToTranscript("ContactId");
        liveagent.setName(userContact.firstName + " " + userContact.lastName);
        liveagent.init(
            this.chatInitInfo.LiveAgentInitUrl,
            this.chatInitInfo.ChatOrgId,
            this.chatInitInfo.ChatDeploymentId
        );
        //starts the chat and opens the window
        let chatWindowOpened: boolean = false;
        liveagent.addButtonEventHandler(
            this.chatInitInfo.ChatButtonId,
            (event: string) => {
                // !chatWindowOpened value is essential for the if statement below, do not remove.
                // It prevents the chat window from incrementing by 1 each time a chat is opened prior to a page refresh.
                if (
                    event === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE &&
                    !chatWindowOpened
                ) {
                    chatWindowOpened = true;
                    this.liveagentStartChat();
                    this.currentView = view.chatInitialized;
                    setTimeout(() => {
                        this.closeModal();
                    }, 5000);
                } else if (
                    event === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE
                ) {
                    this._setLoadingViewText(
                        "Thank you for your patience, we will connect you once an agent becomes available."
                    );
                }
            }
        );
    }

    /**
     * Several events must happen before calling startChat
     *  1. Chat is linked to case
     *  2. init is called on liveagent object
     *  3. initialization finishes (indicated by button_available in addButtonEventHandler)
     *
     * @private
     */
    liveagentStartChat() {
        liveagent.startChatWithWindow(
            this.chatInitInfo.ChatButtonId,
            this.chatWindowName
        );
    }

    postCaseForNewCase() {
        this.currentView = view.loadingView;
        this._setLoadingViewText(
            "Your case is being sent to our support team."
        );
        this.selectedState = this.caseForm.get("stateSelection").value;
        this.selectedLiveEvent = this.caseForm.get("liveEventSelection").value;
        //in case there is no default state and capc was selected
        if (this.selectedState === "None") {
            this.selectedState = "";
        }

        combineLatest([
            this.userContactInfo$.pipe(take(1)),
            this.userSalesforceInfo$.pipe(take(1))
        ]).subscribe(([userContactInfo, userSalesforceInfo]) => {
            const contactInformation = this.getCaseContactInformation();
            this.setRoutingReason();

            // Add anything to the description here
            const description =
                "Customer Question: " +
                this.caseForm.get("customerDescription").value +
                "\r\n" +
                this.getDescriptionDetails(contactInformation);

            let postCase = {
                EmailId: userContactInfo.email,
                AccountId: userSalesforceInfo.accountId,
                ContactId: userSalesforceInfo.contactId,
                IMT_Environment__c: userSalesforceInfo.environmentId,
                IMT_On_Shore_Only_Flag__c: false,
                Subject: this.getSubject(),
                Description: description,
                IMT_Routing_State__c: this.selectedState,
                IMT_Contact_Method__c: "Email",
                IMT_Entitlement_Type__c: "Standard",
                Reason: this.reason,
                Priority: "Medium",
                Status: "Open",
                Type: "Help/Assistance",
                Origin: "Resource Center",
                SourceName: "Simplifile",
                IMT_Customer_Priority__c: this.caseForm.get("severity").value
            };

            this._chatService.postCaseToSalesForceRCAPI(postCase).subscribe(
                (newCases: CasePosted[]) => {
                    const caseCreated = newCases?.length > 0 && newCases[0].Id;
                    if (!caseCreated) {
                        this.setErrorMessage(
                            GeneralError.caseCreateFailedError
                        );
                        this.logMetric(
                            "error_case_creation_failed-no_case_created"
                        );
                    } else {
                        if (newCases?.length > 1) {
                            console.warn(
                                "BAD STATE: Multiple SalesForce cases were created. This should never happen.\n",
                                "Using the first case returned and ignoring the rest."
                            );
                        }
                        const newCase = newCases[0];
                        if (this.caseAttachments?.length > 0) {
                            this._setLoadingViewText("Adding attachments...");
                            this._chatUploadService
                                .addAttachmentsRCAPI(
                                    userContactInfo.email,
                                    newCase.Id,
                                    this.caseAttachments
                                )
                                .subscribe(
                                    () => {
                                        this._setViewSendingCaseComplete();
                                    },
                                    () => {
                                        this.setErrorMessage(
                                            GeneralError.caseCreatedButAttachmentsFailed
                                        );
                                        this.logMetric(
                                            "error_case_created_attachment_failed"
                                        );
                                    }
                                );
                        } else {
                            this._setViewSendingCaseComplete();
                        }
                    }
                },
                (errorFound) => {
                    this.setErrorMessage(GeneralError.caseSendingError);
                    this.logMetric("error_case_creation_failed-request_error");
                }
            );
        });
    }

    private _setViewSendingCaseComplete() {
        this.currentView = view.sendingCaseComplete;
        setTimeout(() => {
            this.closeModal();
        }, 5000);
    }

    closeModal(): void {
        this.activeModal.dismiss();
    }

    backButton() {
        this.currentView = view.chooseSupportType;
        this.title = "Contact Customer Support";
        this.topicAreaSelected = "erecording";
        this.topicErecordSet = true;
        this.topicCapcSet = false;
        this.topicEsignSet = false;
    }

    logMetric(metricName: string) {
        this._metricLoggerService.recordMetric(
            "salesforce_chat_integration",
            metricName,
            1
        );
    }

    getContactId(userDetailsRCAPI: any) {
        const contactId = userDetailsRCAPI?.Contact?.Id;
        if (contactId) {
            this.logMetric("source_contactId=userDetails");
            return contactId;
        } else {
            this.logMetric("source_contactId=default");
            return sf?.liveConfig?.PlatformServicesSettings
                ?.defaultSupportCaseSalesforceContactId;
        }
    }

    getAccountId(userDetailsRCAPI: any) {
        const accountId = userDetailsRCAPI?.Account?.Id;
        if (accountId) {
            this.logMetric("source_accountId=userDetails");
            return accountId;
        } else {
            this.logMetric("source_accountId=default");
            return sf?.liveConfig?.PlatformServicesSettings
                ?.defaultSupportCaseSalesforceAccountId;
        }
    }

    /**
     * There are a few places the environmentId could be.
     *
     * In order of preference:
     *  1. userDetails.Contact.Product_User[].IMT_Environment_ID__c where ProductUser must have Simplifile product line and be Enabled
     *  2. userDetails.Account.Environments[].Id where Environment must have Simplifile product line and be Enabled
     *  3. userDetails.Account.Environments[].Id where Environment must have Simplifile product line but does not need to be Enabled
     *  4. default
     *
     * @param userDetailsRCAPI
     */
    getEnvironmentId(userDetailsRCAPI: any) {
        // First check for ProductUser
        const productUsers = userDetailsRCAPI?.Contact?.Product_User;
        if (productUsers && productUsers.length > 0) {
            for (let productUser of productUsers) {
                if (
                    this.hasKeyValueCaseInsensitive(
                        productUser,
                        "IMT_Status__c",
                        "Enable"
                    ) &&
                    this.hasKeyValueCaseInsensitive(
                        productUser,
                        "IMT_Product_Line__c",
                        "Simplifile"
                    )
                ) {
                    let productUserEnvId = this.getKeyCaseInsensitive(
                        productUser,
                        "IMT_Environment_ID__c"
                    );
                    if (
                        typeof productUserEnvId === "string" &&
                        productUserEnvId.length > 0
                    ) {
                        this.logMetric("source_environmentId=ProductUser");
                        return productUserEnvId;
                    }
                }
            }
        }

        // Second check for Account Environments that are Enabled
        const accountEnvs = userDetailsRCAPI?.Account?.Environments;
        if (accountEnvs && accountEnvs.length > 0) {
            for (let accountEnv of accountEnvs) {
                if (
                    this.hasKeyValueCaseInsensitive(
                        accountEnv,
                        "IMT_Status__c",
                        "Enabled"
                    ) &&
                    this.hasKeyValueCaseInsensitive(
                        accountEnv,
                        "IMT_Product_Line__c",
                        "Simplifile"
                    )
                ) {
                    let accountEnvId = this.getKeyCaseInsensitive(
                        accountEnv,
                        "Id"
                    );
                    if (
                        typeof accountEnvId === "string" &&
                        accountEnvId.length > 0
                    ) {
                        this.logMetric(
                            "source_environmentId=AccountEnvironment(Enabled)"
                        );
                        return accountEnvId;
                    }
                }
            }

            // Third check for Account Environments of any Status
            for (let accountEnv of accountEnvs) {
                if (
                    this.hasKeyValueCaseInsensitive(
                        accountEnv,
                        "IMT_Product_Line__c",
                        "Simplifile"
                    )
                ) {
                    let accountEnvId = this.getKeyCaseInsensitive(
                        accountEnv,
                        "Id"
                    );
                    if (
                        typeof accountEnvId === "string" &&
                        accountEnvId.length > 0
                    ) {
                        this.logMetric(
                            "source_environmentId=AccountEnvironment(AnyStatus)"
                        );
                        return accountEnvId;
                    }
                }
            }
        }

        // Last resort - use the default EnvironmentId
        this.logMetric("source_environmentId=default");
        return sf?.liveConfig?.PlatformServicesSettings
            ?.defaultSupportCaseSalesforceEnvironmentId;
    }

    /**
     * Given the object, determine if it has the key-value pair while ignore case
     * This is intentionally designed to be generous on matching
     * @param obj
     * @param key
     * @param value
     */
    hasKeyValueCaseInsensitive(obj: any, key: string, value: string) {
        const keyLowercase = key.toLowerCase();
        const valueLowercase = value.toLowerCase();
        for (const k in obj) {
            if (
                k.toLowerCase() === keyLowercase &&
                typeof obj[k] === "string" &&
                obj[k].toLowerCase() === valueLowercase
            ) {
                return true;
            }
        }
        return false;
    }

    getKeyCaseInsensitive(obj: any, key: string) {
        const keyLowercase = key.toLowerCase();
        for (const k in obj) {
            if (k.toLowerCase() === keyLowercase) {
                return obj[k];
            }
        }
        return false;
    }

    getSubject(): string {
        let form: FormGroup = this.chatDetailsSelected
            ? this.chatForm
            : this.caseForm;
        let subject: string = "";
        switch (form.get("topicArea").value) {
            case topicArea.erecording:
            case topicArea.erecordingCounty:
                subject = "eRecording - " + form.get("stateSelection").value;
                break;
            case topicArea.esign:
                subject =
                    "eSign Events - " +
                    (form.get("liveEventSelection").value === "Yes"
                        ? "Live eSign event"
                        : "Not a live eSign event");
                break;
            case topicArea.capc:
                const loanNumber: string = form.get("loanNumber").value;
                subject =
                    "Pre-Close/Post-Close - Loan Number: " +
                    (loanNumber ? loanNumber : "N/A");
                break;
        }
        return subject;
    }

    closeChat() {
        this._chatOverlayService.close();
    }
}
