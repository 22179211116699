import { Injectable } from "@angular/core";
import { PersistedMemoryService } from "../persisted-memory.service";

@Injectable({
    providedIn: "root"
})
export class LogoutService {
    public logoutActions: Function[] = [];

    private _sessionValues: string[] = [];
    private _addedRemoveSessionAction = false;
    private _clearSessionValue: Function = () => {
        new Set(this._sessionValues).forEach((value) =>
            this._persistedMemoryService.remove(value)
        );
    };

    constructor(private _persistedMemoryService: PersistedMemoryService) {}

    clearSessionValue(value: string) {
        if (value) {
            this._sessionValues.push(value);

            if (!this._addedRemoveSessionAction) {
                this.addLogoutAction(this._clearSessionValue);
                this._addedRemoveSessionAction = true;
            }
        }
    }

    addLogoutAction(callback: Function) {
        this.logoutActions.push(callback);
    }

    runLogoutActions() {
        this.logoutActions.forEach((action) => action());
        this.logoutActions = [];
    }
}
