import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { DateRangePickerComponent } from "./components/date-range-picker/date-range-picker.component";
import { FormatTimePipe, FormatDatePipe } from "./pipes";
import {
    NgbDatepickerModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { SelectModule } from "../select/select.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    declarations: [
        DatePickerComponent,
        DateRangePickerComponent,
        FormatTimePipe,
        FormatDatePipe
    ],
    imports: [
        CommonModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        SelectModule,
        FontAwesomeModule,
        NgbTooltipModule
    ],
    exports: [
        DatePickerComponent,
        DateRangePickerComponent,
        FormatTimePipe,
        FormatDatePipe
    ]
})
export class DateAndTimeModule {}
