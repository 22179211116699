<sf-modal-header title="Suspended Service"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="!loading && (!suspendedServices || !suspendedServices.length)">
        <span><b>You are in good shape. You don't have any suspended services.</b></span>
    </div>

    <div *ngIf="suspendedServices && suspendedServices.length">
        <div>
            <span *ngIf="suspendedServices.length > 1"><b>The following services are suspended:</b></span>
            <span *ngIf="suspendedServices.length == 1"><b>The following service is suspended:</b></span>
        </div>
        <div class="boxed reason">
            <div *ngFor="let service of suspendedServices" class="listed-service">
                <span>
                    The <b>{{service.productLabel}}</b> service for <b>{{service.organizationLabel}}</b>
                    has been suspended due to: {{service.reasonText}}.
                </span>
            </div>
        </div>

        <div *ngIf="isAdmin">
            <span *ngIf="hasPendingPayments">
                To enable your service, click on "Review Pending Payments" below to review and approve your payments.
            </span>
            <span *ngIf="hasInvoicingIssue">
                Please contact us at <b>ar-simplifile@ice.com</b> to enable your service and pay any outstanding invoices.
            </span>
            <span *ngIf="!hasInvoicingIssue && allowHelp && !hasPendingPayments">
                For assistance, click on "Get Help" below, or call 800.460.5657 to resolve.
            </span>
            <span *ngIf="!hasInvoicingIssue && !allowHelp && !hasPendingPayments">
                Please call Simplifile Support at <b>800.460.5657</b> to resolve.
            </span>
        </div>

        <div *ngIf="!isAdmin">
            <span>For assistance, contact your organization administrator, click on "Get Help" below, or call Simplifile Support
                at 800.460.5657 to resolve.</span>
            <div *ngIf="adminUsers && adminUsers.length" class="top-gap">
                <div>
                    <span *ngIf="adminUsers.length > 1"><b>Organization Administrators:</b></span>
                    <span *ngIf="adminUsers.length == 1"><b>Organization Administrator:</b></span>
                </div>
                <div class="boxed admins">
                    <table>
                        <tbody>
                        <tr *ngFor="let adminUser of adminUsers" class="">
                            <td class=""><b>{{adminUser.name}}</b></td>
                            <td class="">{{adminUser.phone}}</td>
                            <td class=""><a href="mailto:{{adminUser.email}}" target="_blank">{{adminUser.email}}</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary" [tertiary]="tertiary"></sf-modal-footer>
