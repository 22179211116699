import { Injectable } from "@angular/core";
import {
    NgbDateParserFormatter,
    NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { dayjs } from "../plugins/dayjs/index";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import { DateAdapterService } from "./date-adapter.service";

@Injectable()
export class DateParserFormatterService extends NgbDateParserFormatter {
    /* Private Variables */
    private _stringFormat = "MM/DD/YYYY";
    private _altStringFormats: string[] = [
        "MM/DD/YYYY",
        "MM-DD-YYYY",
        "MM.DD.YYYY",
        "MMDDYYYY",
        "M/D/YYYY",
        "MM/D/YYYY",
        "M/DD/YYYY",
        "M-D-YYYY",
        "M.D.YYYY",
        "MM/DD/YY",
        "MM-DD-YY",
        "MM.DD.YY",
        "MMDDYY",
        "M/D/YY",
        "M-D-YY",
        "M.D.YY",
        "YYYY/MM/DD",
        "YYYY-MM-DD",
        "YYYY.MM.DD",
        "YYYYMMDD",
        "YYYY/M/D",
        "YYYY-M-D",
        "YYYY.M.D",
        "YY/MM/DD",
        "YY-MM-DD",
        "YY.MM.DD",
        "YYMMDD",
        "YY/M/D",
        "YY-M-D",
        "YY.M.D",
        "MMM DD YYYY",
        "MMM DD, YYYY",
        "MMM D YYYY",
        "MMM D, YYYY",
        "MMM D YY",
        "MMM D, YY",
        "MMMM DD YYYY",
        "MMMM DD, YYYY",
        "MMMM D YYYY",
        "MMMM D, YYYY",
        "MMMM D YY",
        "MMMM D, YY",
        "MMMM DD, YY",
        "MMM Do YYYY",
        "MMM Do, YYYY",
        "MMM Do YY",
        "MMM Do, YY",
        "MMMM Do YYYY",
        "MMMM Do, YYYY",
        "MMMM Do YY",
        "MMMM Do, YY"
    ];
    private _strictParsing = true;

    constructor(private dateAdapter: DateAdapterService) {
        super();
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        let date = dayjs(
            value,
            [this._stringFormat, ...this._altStringFormats],
            this._strictParsing
        );
        if (date.year() <= 1900) {
            return null;
        }
        return this.dateAdapter.fromModel(date);
    }

    format(date: NgbDateStruct): string {
        if (!date) {
            return "";
        }
        return this.dateAdapter.toModel(date).format(this._stringFormat);
    }

    setStrictParsing(strictParsing: boolean | undefined) {
        if (strictParsing !== undefined) {
            this._strictParsing = !!strictParsing;
        }
    }

    setStringFormat(format: string) {
        if (!format) {
            return;
        }
        this._stringFormat = format;
    }

    setAltStringFormats(formats: string[]) {
        if (!formats || formats.length === 0) {
            return;
        }
        this._altStringFormats = formats;
    }

    getStringFormat(): string {
        return this._stringFormat;
    }
}
