<div class="logo"></div>
<ng-container *ngIf="errorMessage">
    <div class="text-center mt-4">
        <div class="error-row">{{errorMessage}}</div>
    </div>
</ng-container>
<ng-container *ngIf="showApprovalDialog">
    <div class="head-text mou-auth-container">
        <ng-container *ngIf="needsApproval">
            <div *ngIf="approvalAction === 'approve'">
                <b>{{submitterName}}</b> is approved.
            </div>
            <ng-container *ngIf="approvalAction === 'deny'">
                <div *ngIf="showDenial else denialReasonsDialog">
                    <b>{{submitterName}}</b> has been denied.
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!needsApproval">
            User <b>{{approvalUser}}</b> {{approvalStatus}} this submitter on <b>{{approvalDate}}</b>.
        </ng-container>
    </div>
</ng-container>

<ng-template #denialReasonsDialog>
    <div class="mb-3"><b>Denial of MOU Request</b></div>
    <div class="h4">Reason for Denial</div>
    <div class="h5">
        <form [formGroup]="denialForm" (ngSubmit)="confirmDenial()">
            <label>
                <input type="radio" [value]="denialReasonEnum.SIGNATURE" formControlName="denialReason">
                {{denialReasonEnum.SIGNATURE}}
            </label>
            <label>
                <input type="radio" [value]="denialReasonEnum.ORGANIZATION_NAME" formControlName="denialReason">
                {{denialReasonEnum.ORGANIZATION_NAME}}
            </label>
            <label>
                <input type="radio" [value]="denialReasonEnum.MOU_VERSION" formControlName="denialReason">
                {{denialReasonEnum.MOU_VERSION}}
            </label>
            <label>
                <input type="radio" [value]="denialReasonEnum.OTHER" formControlName="denialReason">
                {{denialReasonEnum.OTHER}}
            </label>
            <input type="text" class="form-control other-reason" formControlName="otherDenialReason" />
        </form>
    </div>
    <button class="btn btn-primary mt-3" (click)="confirmDenial()" [disabled]="!denialForm.valid">Confirm Denial</button>
</ng-template>
