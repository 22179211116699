import { Component, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-bulk-credit-card-retry-dialog",
    templateUrl: "./bulk-credit-card-retry-dialog.component.html",
    styleUrls: ["./bulk-credit-card-retry-dialog.component.scss"]
})
export class BulkCreditCardRetryDialogComponent implements OnInit {
    /**Public Variables**/
    primary: ModalButton;
    secondary: ModalButton;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        /** Modal Buttons **/
        this.primary = {
            text: "Retry Payments",
            disabled: false,
            callback: this._retryPayments.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this._abort.bind(this)
        };
    }

    /** Private Methods **/
    private _abort() {
        this._activeModal.close(false);
    }

    private _retryPayments() {
        this._activeModal.close(true);
    }
}
