import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import {
    distinctUntilChanged,
    map,
    startWith,
    takeUntil
} from "rxjs/operators";
import { STANDARD_ERRORS } from "../../helpers/standard-errors";

@Component({
    selector: "sf-form-error",
    templateUrl: "./form-error.component.html",
    styleUrls: ["./form-error.component.scss"]
})
export class FormErrorComponent implements OnInit, OnDestroy {
    private _destroy = new Subject<void>();

    @Input() control: AbstractControl;
    @Input() label: string;

    errors$: Observable<any>;

    constructor() {}

    ngOnInit(): void {
        if (this.control && this.control.statusChanges) {
            this.errors$ = this.control.statusChanges.pipe(
                startWith(this.control.status),
                map(() => {
                    const controlErrors = this.control.errors;

                    if (controlErrors) {
                        const messages = [];
                        for (let errorKey of Object.keys(controlErrors)) {
                            const errorFn = STANDARD_ERRORS[errorKey];
                            messages.push(
                                errorFn(controlErrors[errorKey], this.label)
                            );
                        }

                        return messages;
                    }

                    return [];
                }),
                takeUntil(this._destroy)
            );
        }
    }

    ngOnDestroy() {
        this._destroy.next();
    }
}
