import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

const capcActiveDefault = false;
const logoLinkEnabledDefault = true;
const userOptionsEnabledDefault = true;

@Injectable({
    providedIn: "root"
})
export class LeftNavConfigService {
    private capcActiveBS = new BehaviorSubject(false);
    private logoLinkEnabledBS = new BehaviorSubject(true);
    private userOptionsEnabledBS = new BehaviorSubject(true);

    capcActive = this.capcActiveBS.asObservable();
    logoLinkEnabled = this.logoLinkEnabledBS.asObservable();
    userOptionsEnabled = this.userOptionsEnabledBS.asObservable();

    constructor() {}

    // Component that changes a value is responsible for resetting it on Destroy
    resetCapcActive() {
        this.capcActiveBS.next(capcActiveDefault);
    }

    resetLogoLinkEnabled() {
        this.logoLinkEnabledBS.next(logoLinkEnabledDefault);
    }

    resetUserOptionsEnabled() {
        this.userOptionsEnabledBS.next(userOptionsEnabledDefault);
    }

    setCapcActive(active: boolean) {
        this.capcActiveBS.next(active);
    }

    setLogoLinkEnabled(enabled: boolean) {
        this.logoLinkEnabledBS.next(enabled);
    }

    setUserOptionsEnabled(enabled: boolean) {
        this.userOptionsEnabledBS.next(enabled);
    }
}
