<ng-template #bannerTooltipContents>
    <span [innerHTML]="message"></span>
</ng-template>
<ngb-alert *ngIf="showBanner" [type]="type" (click)="click($event)" (close)="close($event)" [dismissible]="dismissible"
    [ngbTooltip]="bannerTooltipContents" placement="bottom" container="body" [openDelay]="1000" [closeDelay]="0" [disableTooltip]="disableTooltip"
    [ngClass]="{'alert-no-click': !clickHandler, 'progress-bar': category === 'progress_bar' && progressBar}" aria-live="polite">
    <button *ngIf="!!clickHandler" class="btn btn-link sf-btn-link" [innerHTML]="message" (click)="click($event)"></button>
    <span *ngIf="!clickHandler" class="no-link" [innerHTML]="message"></span>
    <ngb-progressbar *ngIf="category === 'progress_bar' && progressBar" type="primary" [striped]="true"
                     [animated]="progressBar.value < progressBar.max" [value]="progressBar.value" [max]="progressBar.max">
        {{progressBar.value}} of {{progressBar.max}}
    </ngb-progressbar>
</ngb-alert>
