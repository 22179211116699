import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "@sf/userorg/common";
import { GrowlService, ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

// prettier-ignore
@Component({
    selector: "sf-qr-code-dialog",
    templateUrl: "./qr-code-dialog.component.html",
    styleUrls: ["./qr-code-dialog.component.scss"]
})
export class QrCodeDialogComponent implements OnInit {
    @ViewChild('password') passwordElement: ElementRef;

    url: string;
    safeUrl: SafeUrl;
    primary: ModalButton;
    secondary: ModalButton;
    passwordOK = false;

    constructor(
            private userService: UserService,
            private activeModal: NgbActiveModal,
            private sanitizer: DomSanitizer,
            private growlService: GrowlService
    ) {
    }

    ngOnInit(): void {
        this.primary = {
            text: "Continue",
            disabled: false,
            callback: this.continue.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        setTimeout(() => {
            this.passwordElement.nativeElement.focus();
        }, 300);
    }

    continue() {
        if (this.passwordOK) {
            this.activeModal.close("success");
            return;
        }

        let password = this.passwordElement.nativeElement.value;
        this.userService.getQRCodeImage(password).subscribe((result) => {
            if (result && result.image) {
                this.passwordOK = true;
                this.primary.text = "Close";
                this.secondary.hidden = true;
                let url = "data:image/png," + result.image as string;
                this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64," + result.image);
            } else {
                this.growlService.error("An error occurred. Please try again later.");
            }
        });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
