import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { Project } from "../interfaces/project";
import { Customer } from "../interfaces/customer";

@Injectable({
    providedIn: "root"
})
export class ProjectService {
    constructor(private _rpcClient: RpcClientService) {}

    getProjects(
        organizationId: string,
        includeArchived: boolean,
        includeDraft: boolean
    ): Observable<Project[]> {
        return this._rpcClient.makeRequest("esign", "getProjects", {
            organizationId,
            includeArchived,
            includeDraft
        });
    }

    getProjectsForSubmitters(
        organizationIds: string[],
        includeArchived: boolean,
        includeDraft: boolean
    ): Observable<Project[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getProjectsForSubmitters",
            {
                organizationIds,
                includeArchived,
                includeDraft
            }
        );
    }

    getProjectCustomers(organizationId: string): Observable<Customer[]> {
        return this._rpcClient.makeRequest("esign", "getProjectCustomers", {
            organizationId
        });
    }

    createProject(
        project: Project,
        copyFromProjectId: string,
        copyConfiguration: boolean,
        copyParties: boolean,
        copyBeneficiaries: boolean,
        copyPOAs: boolean,
        copyCSVMapping: boolean
    ): Observable<Project> {
        return this._rpcClient.makeRequest("esign", "createProject", {
            project,
            copyFromProjectId,
            copyConfiguration,
            copyParties,
            copyBeneficiaries,
            copyPOAs,
            copyCSVMapping
        });
    }

    deleteProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deleteProject", {
            projectId
        });
    }

    archiveProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "archiveProject", {
            projectId
        });
    }

    activateProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "activateProject", {
            projectId
        });
    }

    deactivateProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deactivateProject", {
            projectId
        });
    }

    readyProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "readyProject", {
            projectId
        });
    }

    updateProject(
        projectId: string,
        name: string,
        code: string,
        customerId: string
    ): Observable<Project> {
        code = code ? code : "";
        return this._rpcClient.makeRequest("esign", "updateProject", {
            projectId,
            name,
            code,
            customerId
        });
    }

    deleteProjectCustomer(customerId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deleteProjectCustomer", {
            customerId
        });
    }

    createProjectCustomer(customer: Customer): Observable<Customer> {
        return this._rpcClient.makeRequest("esign", "createProjectCustomer", {
            customer
        });
    }

    updateProjectCustomer(customer: Customer): Observable<Customer> {
        return this._rpcClient.makeRequest("esign", "updateProjectCustomer", {
            customer
        });
    }

    getProjectIncompletePackageCount(projectId: string): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getProjectIncompletePackageCount",
            {
                projectId
            }
        );
    }

    getProject(projectId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "getProjectById", {
            projectId
        });
    }

    saveProject(project: any): Observable<void> {
        return this._rpcClient.makeRequest("esign", "saveProject", {
            project
        });
    }
}
