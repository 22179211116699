<div class="layout-grid">
    <sf-banners [isSupportBanner]="true"></sf-banners>
    <div class="container-fluid sf-main-layout overflow-auto {{containerClass}}">
        <div class="sf-row {{rightContentClass}}">
            <main id="main">
                <sf-banners></sf-banners>
                <div class="sf-main">
                    <sf-growls></sf-growls>
                    <router-outlet></router-outlet>
                </div>
            </main>
        </div>
    </div>
</div>
