import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    forkJoin,
    Observable,
    of,
    ReplaySubject,
    Subject
} from "rxjs";
import { InitializationStatus } from "../interfaces/initialization-status.interface";
import {
    concatAll,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom
} from "rxjs/operators";
import { chunk, sortBy } from "@sf/common";
import { LoggerService } from "@sf/common";
import { AbstractCountiesDataService } from "./abstract-counties-data.service";
import { CountyRegistrationSettings } from "../interfaces/countyRegistrationSettings.interface";
import {
    CountyData,
    StateWithCounties
} from "../interfaces/countyData.interface";
import { County } from "../interfaces/county.interface";
import { SubmitterRecipientService } from "./submitter-recipient.service";
import { AddCountyResponse } from "../interfaces/add-county-response.interface";
import { RegistrationSettingTypeAlt } from "../enums/registration-setting-type.enum";

@Injectable({
    providedIn: "root"
})
export class SubmitterCountiesDataService extends AbstractCountiesDataService {
    /** Private Variables **/
    private _currentOrgId: string;
    private _currentRecipientId: string;
    private _registrationSettings: CountyRegistrationSettings;
    private _registrationSettings$ =
        new ReplaySubject<CountyRegistrationSettings>();
    private _initialized$: BehaviorSubject<InitializationStatus> =
        new BehaviorSubject({
            stateCounties: false,
            unassociatedCounties: false,
            defaultPrintScale: false,
            states: false
        });
    private _progressMessage$: BehaviorSubject<string> =
        new BehaviorSubject<string>("");
    private _countyData$: BehaviorSubject<CountyData> =
        new BehaviorSubject<CountyData>(null);
    private _countyAdded$: Subject<County[]> = new Subject();
    public updatingUnassociatedCounties$ = new BehaviorSubject(false);

    constructor(
        private _loggerService: LoggerService,
        private _submitterRecipientService: SubmitterRecipientService
    ) {
        super();
    }

    /** Public Methods **/
    getCountyData$(): Observable<CountyData> {
        return this._countyData$.asObservable();
    }

    setOrgID(orgID: string) {
        if (orgID !== this._currentOrgId || this._currentRecipientId != null) {
            this._currentRecipientId = null;
            this._init(orgID).pipe(take(1)).subscribe();
        }
    }

    initializeForSingleUnassociatedCounty(orgID: string, recipientID: string) {
        this._currentOrgId = orgID;
        this._currentRecipientId = recipientID;
        this._initialized$.next({
            stateCounties: true,
            unassociatedCounties: false,
            defaultPrintScale: true,
            states: true
        });
        this._countyData$.next({
            orgID: "",
            states: [],
            allCounties: [],
            registeredCounties: [],
            unassociatedCounties: [],
            unassociatedStateCounties: {},
            unassociatedOneClickCounties: {},
            vendors: [],
            defaultPrintScale: null,
            filterUnregistered: false,
            stateCounties: [],
            numRegisteredCounties: 0
        });
        this._submitterRecipientService
            .getUnassociatedCounty(orgID, recipientID)
            .subscribe((county) => {
                let initialized = this._initialized$.getValue();
                initialized.unassociatedCounties = true;
                this._initialized$.next(initialized);

                let countyData = this._countyData$.getValue();
                countyData.states = [county.state];
                countyData.unassociatedCounties = [county];
                this._countyData$.next(countyData);
            });
    }

    getRegisteredCounties(orgID: string, state: string): Observable<County[]> {
        return this._submitterRecipientService
            .getSubmitterRecipients(orgID, state, false)
            .pipe(
                withLatestFrom(this._countyData$),
                map(([recipients, countyData]: [County[], CountyData]) => {
                    const stateObj = countyData.stateCounties.find(
                        (swc: StateWithCounties) => swc.state === state
                    );

                    if (stateObj) {
                        // Separate out the counties that are marked "Disabled by County" (DBC for short)
                        stateObj.counties = recipients.filter(
                            (r: County) => !r.disabledByCounty
                        );
                        stateObj.dbcCounties = recipients.filter(
                            (r: County) => r.disabledByCounty && r.orgEnabled
                        );

                        stateObj.count =
                            stateObj.counties.length +
                            stateObj.dbcCounties.length;
                    }

                    this._countyData$.next(countyData);
                    return recipients;
                })
            );
    }

    addCounty(
        county: County,
        orgID: string,
        params?: { [key: string]: any },
        overrideDefaultErrorHandling?: boolean
    ): Observable<County[]> {
        return this._checkInitialization(
            ["stateCounties", "unassociatedCounties", "defaultPrintScale"],
            orgID
        ).pipe(
            switchMap(() => {
                if (!params) params = {};

                return this._submitterRecipientService
                    .addCounty(
                        orgID,
                        county.id,
                        params,
                        overrideDefaultErrorHandling
                            ? overrideDefaultErrorHandling
                            : false
                    )
                    .pipe(
                        withLatestFrom(this._countyData$),
                        switchMap(
                            ([newCounty, countyData]: [
                                AddCountyResponse,
                                CountyData
                            ]) => {
                                if (newCounty && newCounty.id) {
                                    county.printScale =
                                        countyData.defaultPrintScale;
                                    county.enabled = newCounty.enabled;
                                    county.isRegistrationPending =
                                        newCounty.isRegistrationPending ??
                                        "false";
                                    county.registrationsPending =
                                        newCounty.registrationsPending ?? [];
                                    return this._processAddedCountiesForState(
                                        [county],
                                        county.state
                                    );
                                }

                                return of([county]);
                            }
                        )
                    );
            })
        );
    }

    addAllOneClickCounties(orgID: string): Observable<County[]> {
        this._progressMessage$.next(
            "Adding all one click counties for all states..."
        );
        return this._submitterRecipientService.addAllCounties(orgID, "").pipe(
            switchMap((addedCounties: AddCountyResponse[]) => {
                const initStateCountyMap: {
                    [key: string]: AddCountyResponse[];
                } = {};
                const stateCountyMap: {
                    [key: string]: AddCountyResponse[];
                } = addedCounties.reduce(
                    (
                        r: { [key: string]: AddCountyResponse[] },
                        v: AddCountyResponse
                    ) => {
                        (r[v.state] || (r[v.state] = [])).push(v);
                        return r;
                    },
                    initStateCountyMap
                );

                const processObservables: Observable<County[]>[] = [];
                Object.entries(stateCountyMap).forEach(
                    ([state, counties]: [string, AddCountyResponse[]]) => {
                        processObservables.push(
                            this._processAddedCountiesForState(counties, state)
                        );
                    }
                );

                return forkJoin(processObservables).pipe(
                    map((counties: County[][]) => {
                        return counties.flat();
                    })
                );
            })
        );
    }

    addAllOneClickCountiesForState(
        state: string,
        orgID: string
    ): Observable<County[]> {
        return this._checkInitialization(
            ["stateCounties", "unassociatedCounties", "defaultPrintScale"],
            orgID
        ).pipe(
            switchMap(() => {
                return this._submitterRecipientService
                    .addAllCounties(orgID, state)
                    .pipe(
                        switchMap((addedCounties: AddCountyResponse[]) => {
                            return this._processAddedCountiesForState(
                                addedCounties,
                                state
                            );
                        })
                    );
            })
        );
    }

    removeCounties(
        countiesToRemove: County[],
        orgID: string
    ): Observable<County[]> {
        return this._checkInitialization(
            ["stateCounties", "unassociatedCounties"],
            orgID
        ).pipe(
            switchMap(() => {
                const countyChunks: County[][] = chunk(countiesToRemove, 20);
                const length = countyChunks.length;

                return countyChunks.map((chunk: County[], index: number) => {
                    return this._submitterRecipientService
                        .removeCounties(
                            orgID,
                            chunk.map((c: County) => c.id)
                        )
                        .pipe(
                            tap((_: string[]) => {
                                const percentComplete = Math.max(
                                    0,
                                    Math.floor(((index + 1) / length) * 100)
                                );
                                this._progressMessage$.next(
                                    `Removing counties, ${percentComplete}% complete...`
                                );
                            }),
                            switchMap((_: string[]) => {
                                return this._processRemovedCounties(chunk);
                            })
                        );
                });
            }),
            concatAll()
        );
    }

    setCountyPrintScale(
        orgID: string,
        countyIDs: string[],
        printScale: number
    ): Observable<boolean | void> {
        return this._checkInitialization(
            ["stateCounties", "unassociatedCounties", "defaultPrintScale"],
            orgID
        ).pipe(
            switchMap(() => {
                return this._submitterRecipientService
                    .setPrintScale(orgID, countyIDs, printScale)
                    .pipe(
                        withLatestFrom(this._countyData$),
                        map(([_, countyData]: [void, CountyData]) => {
                            countyIDs.forEach((countyID: string) => {
                                let county: County;
                                for (const swc of countyData.stateCounties) {
                                    if (swc.counties?.length) {
                                        const tmp = swc.counties.find(
                                            (c: County) => c.id === countyID
                                        );
                                        if (tmp) {
                                            county = tmp;
                                            break;
                                        }
                                    }
                                }
                                if (county) {
                                    county.printScale = printScale;
                                    this._countyData$.next(countyData);
                                }
                            });
                        })
                    );
            })
        );
    }

    setAllCountiesPrintScale(
        orgID: string,
        printScale: number
    ): Observable<void> {
        return this._checkInitialization(
            ["stateCounties", "unassociatedCounties", "defaultPrintScale"],
            orgID
        ).pipe(
            switchMap(() => {
                return this._submitterRecipientService
                    .setAllPrintScale(orgID, printScale)
                    .pipe(
                        withLatestFrom(this._countyData$),
                        map(([_, countyData]: [void, CountyData]) => {
                            countyData.stateCounties.forEach(
                                (state: StateWithCounties) => {
                                    state.counties.forEach((county: County) => {
                                        county.printScale = printScale;
                                    });
                                }
                            );
                            this._countyData$.next(countyData);
                        })
                    );
            })
        );
    }

    showRegistrationDetailsView(
        recipientID: string,
        recipientSettings: { [key: string]: string },
        submitterID: string
    ): Observable<boolean> {
        return this._countyData$.pipe(
            take(1),
            switchMap((countyData: CountyData) => {
                const recipient = countyData.stateCounties
                    .map((swc: StateWithCounties) => {
                        if (swc.hasOwnProperty("dbcCounties")) {
                            return swc.counties.concat(swc.dbcCounties);
                        }
                        return swc.counties;
                    })
                    .reduce((a: County[], b: County[]) => a.concat(b), [])
                    .concat(countyData.unassociatedCounties)
                    .find((c: County) => c.id === recipientID);

                this._registrationSettings = {
                    recipientConfiguration: undefined,
                    recipientSettings: undefined,
                    submitterSettings: undefined,
                    recipientID: recipientID,
                    recipient: recipient,
                    submitterID: submitterID
                };
                this._registrationSettings$.next(this._registrationSettings);

                return this._submitterRecipientService
                    .getCountyRegistrationSettings(submitterID, recipientID)
                    .pipe(
                        map(
                            ({
                                submitterSettings,
                                recipientSettings,
                                recipientConfig
                            }: {
                                submitterSettings: any[];
                                recipientSettings: {
                                    [_: string]: string;
                                };
                                recipientConfig: { [_: string]: any };
                            }) => {
                                this._updateSubmitterSettings(
                                    submitterSettings
                                );
                                this._updateRecipientSettings(
                                    recipientSettings
                                );
                                this._updateRecipientConfig(
                                    recipientID,
                                    recipientConfig
                                )
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this._registrationSettings$.next(
                                            this._registrationSettings
                                        );
                                    });

                                return true;
                            }
                        )
                    );
            })
        );
    }

    isAnyCountyRegistered$(): Observable<boolean> {
        return this._countyData$.pipe(
            map((countyData: CountyData) => {
                if (countyData?.stateCounties?.length) {
                    // sometimes a state is in the list, but there are really no
                    // counties registered in the state
                    const stateWithCounty = countyData.stateCounties.find(
                        (swc: StateWithCounties) => {
                            if (swc?.counties?.length) {
                                return swc;
                            }
                            return null;
                        }
                    );
                    return !!stateWithCounty;
                } else {
                    return false;
                }
            })
        );
    }

    getRegistrationSettings$(): Observable<CountyRegistrationSettings> {
        return this._registrationSettings$.asObservable();
    }

    getProgressMessage$(): Observable<string> {
        return this._progressMessage$.asObservable();
    }

    getCountyAdded$(): Observable<County[]> {
        return this._countyAdded$.asObservable();
    }

    getInitializationStatus$(): Observable<InitializationStatus> {
        return this._initialized$.asObservable();
    }

    updateUnassociatedCounties(orgID: string): void {
        this.updatingUnassociatedCounties$.next(true);
        this._initializeUnassociatedCounties(orgID).subscribe(() => {
            this.updatingUnassociatedCounties$.next(false);
        });
    }

    /**  Private Methods  **/
    private _init(orgID: string): Observable<void> {
        this._currentOrgId = orgID;
        this._initialized$.next({
            stateCounties: false,
            unassociatedCounties: false,
            defaultPrintScale: false,
            states: false
        });
        this._countyData$.next({
            orgID: "",
            states: [],
            allCounties: [],
            registeredCounties: [],
            unassociatedCounties: [],
            unassociatedStateCounties: {},
            unassociatedOneClickCounties: {},
            vendors: [],
            defaultPrintScale: null,
            filterUnregistered: false,
            stateCounties: [],
            numRegisteredCounties: 0
        });

        return forkJoin([
            this._initializeRegisteredCountyCounts(orgID),
            this._initializeUnassociatedCounties(orgID),
            this._initializeDefaultPrintScale(orgID)
        ]).pipe(
            withLatestFrom(this._countyData$),
            map(
                ([_, countyData]: [
                    [StateWithCounties[], County[], number],
                    CountyData
                ]) => {
                    this._initializeStates(countyData);
                    this._countyData$.next(countyData);
                }
            )
        );
    }

    private _initializeRegisteredCountyCounts(
        orgID: string
    ): Observable<StateWithCounties[]> {
        return this._submitterRecipientService
            .getRegisteredRecipientsCountByState(orgID)
            .pipe(
                withLatestFrom(this._countyData$),
                map(([data, countyData]: [StateWithCounties[], CountyData]) => {
                    countyData.stateCounties = data.sort(sortBy("state"));

                    countyData.stateCounties.forEach(
                        (swc: StateWithCounties) => {
                            swc.counties = [];
                            countyData.numRegisteredCounties += swc.count;
                        }
                    );
                    this._setInitialized("stateCounties");
                    this._countyData$.next(countyData);
                    return data;
                })
            );
    }

    private _initializeUnassociatedCounties(
        orgID: string
    ): Observable<County[]> {
        return this._submitterRecipientService
            .getUnassociatedCounties(orgID)
            .pipe(
                withLatestFrom(this._countyData$),
                map(
                    ([unassociatedCounties, countyData]: [
                        County[],
                        CountyData
                    ]) => {
                        const unassociatedCountyStates = [
                            ...new Set(
                                unassociatedCounties.map((c: County) => c.state)
                            )
                        ];

                        countyData.unassociatedCounties = unassociatedCounties;
                        unassociatedCountyStates.forEach((s: string) =>
                            this._stateInit(s, countyData)
                        );
                        this._setInitialized("unassociatedCounties");
                        this._countyData$.next(countyData);
                        return unassociatedCounties;
                    }
                )
            );
    }

    private _initializeDefaultPrintScale(orgID: string): Observable<number> {
        return this._submitterRecipientService
            .getSubmitterPrintScale(orgID)
            .pipe(
                withLatestFrom(this._countyData$),
                map(([defaultPrintScale, countyData]: [number, CountyData]) => {
                    countyData.defaultPrintScale = defaultPrintScale;
                    this._setInitialized("defaultPrintScale");
                    this._countyData$.next(countyData);
                    return defaultPrintScale;
                })
            );
    }

    private _initializeStates(countyData: CountyData) {
        countyData.states = [
            ...new Set(
                countyData.states
                    .concat(
                        countyData.unassociatedCounties.map(
                            (c: County) => c.state
                        )
                    )
                    .concat(
                        countyData.stateCounties.map(
                            (swc: StateWithCounties) => swc.state
                        )
                    )
            )
        ].sort();
        this._setInitialized("states");
        this._countyData$.next(countyData);
    }

    private _checkInitialization(
        propertiesToCheck: (keyof InitializationStatus)[],
        orgID: string
    ): Observable<void> {
        return this._initialized$.pipe(
            take(1),
            switchMap((initialized: InitializationStatus) => {
                if (
                    orgID === this._currentOrgId &&
                    propertiesToCheck.every(
                        (prop: keyof InitializationStatus) => {
                            return initialized[prop];
                        }
                    )
                ) {
                    return of(undefined);
                }
                return this._init(orgID);
            })
        );
    }

    private _processRemovedCounties(
        countiesToRemove: County[]
    ): Observable<County[]> {
        countiesToRemove.forEach((c: County) => {
            c.enabled = false;
        });

        return this._countyData$.pipe(
            take(1),
            map((countyData: CountyData) => {
                countyData.unassociatedCounties =
                    countyData.unassociatedCounties
                        .concat(countiesToRemove)
                        .sort(sortBy("state"));

                const states = [
                    ...new Set(countiesToRemove.map((c: County) => c.state))
                ];
                states.forEach((s: string) => this._stateInit(s, countyData));

                countyData.numRegisteredCounties -= countiesToRemove.length;

                countiesToRemove.forEach((c: County) => {
                    const stateObj = countyData.stateCounties.find(
                        (swc: StateWithCounties) => swc.state === c.state
                    );

                    stateObj.counties = stateObj.counties.filter(
                        (county: County) => county !== c
                    );
                    stateObj.count =
                        stateObj.counties.length + stateObj.dbcCounties.length;

                    if (stateObj.count === 0) {
                        countyData.stateCounties =
                            countyData.stateCounties.filter(
                                (swc: StateWithCounties) => {
                                    return swc.state !== c.state;
                                }
                            );
                    }
                });
                this._countyData$.next(countyData);
                return countiesToRemove;
            })
        );
    }

    private _processAddedCountiesForState(
        addedCounties: Partial<County>[],
        state: string
    ): Observable<County[]> {
        return combineLatest([
            this._countyData$,
            this._submitterRecipientService.getRegisteredRecipientsCountByState(
                this._currentOrgId
            )
        ]).pipe(
            take(1),
            map(
                ([countyData, countyCounts]: [
                    CountyData,
                    StateWithCounties[]
                ]) => {
                    let countyState = countyData.stateCounties.find(
                        (swc: StateWithCounties) => swc.state === state
                    );
                    let unassociatedCounties = countyData.unassociatedCounties;
                    const countiesChanged: County[] = [];

                    if (!countyState) {
                        countyState =
                            SubmitterCountiesDataService._addNewState(state);
                        countyData.stateCounties.push(countyState);
                        countyData.stateCounties =
                            countyData.stateCounties.sort(sortBy("state"));
                    }

                    addedCounties.forEach((newCounty: Partial<County>) => {
                        const county = unassociatedCounties.find(
                            (uc: County) => uc.id === newCounty.id
                        );
                        if (!!county) {
                            county.printScale = countyData.defaultPrintScale;
                            county.enabled = newCounty.enabled;
                            countyState.counties.push(county);
                            unassociatedCounties = unassociatedCounties.filter(
                                (uc: County) => uc !== county
                            );
                            countiesChanged.push(county);
                        }
                    });

                    countyState.counties = countyState.counties.sort(
                        sortBy("fullName")
                    );

                    countyState.count = countyCounts.find(
                        (countyCount) => countyCount.state === countyState.state
                    ).count;

                    if (countyState.dbcCounties) {
                        countyState.count += countyState.dbcCounties.length;
                    }
                    countyData.numRegisteredCounties += addedCounties.length;
                    countyData.unassociatedCounties = unassociatedCounties;
                    this._stateInit(state, countyData);

                    this._countyData$.next(countyData);
                    this._countyAdded$.next(countiesChanged);

                    return countiesChanged;
                }
            )
        );
    }

    private _stateInit(state: string, countyData: CountyData) {
        countyData.unassociatedStateCounties[state] =
            countyData.unassociatedCounties.filter(
                (uc: County) => uc.state === state
            );
        countyData.unassociatedOneClickCounties[state] =
            countyData.unassociatedStateCounties[state].filter(
                (usc: County) => usc.isOneClick
            );
    }

    private _setInitialized(property: keyof InitializationStatus) {
        this._initialized$
            .pipe(take(1))
            .subscribe((initialized: InitializationStatus) => {
                initialized[property] = true;
                this._initialized$.next(initialized);
            });
    }

    private _updateSubmitterSettings(submitterSettings: any[]) {
        submitterSettings.forEach((settings) => {
            const dfd = settings.dataFieldDefinition;
            const type = settings.settingType;

            if (type === RegistrationSettingTypeAlt["Dropdown"]) {
                const newDescription = [];
                const descriptionArr = dfd.description.split(",");
                for (let i = 0; i < descriptionArr.length; i++) {
                    newDescription.push({ option: descriptionArr[i] });
                }
                dfd.description = newDescription;
            }

            if (
                type === RegistrationSettingTypeAlt["Required MOU"] ||
                type === RegistrationSettingTypeAlt["Required MOU Immediate"]
            ) {
                try {
                    const jsonObj = JSON.parse(dfd.description);
                    dfd.href = jsonObj.url;
                    dfd.description = jsonObj.description;
                    if (jsonObj.dropdownValueSettingName) {
                        dfd.dropdownValueSettingName =
                            jsonObj.dropdownValueSettingName;
                    }
                } catch (e) {
                    this._loggerService.debug(e);
                    const re = /href=["'](.*?)["']/;
                    const result = re.exec(dfd.description);
                    dfd.href = result[1];
                }
            }
        });

        this._registrationSettings.submitterSettings = submitterSettings;
    }

    private _updateRecipientSettings(recipientSettings: {
        [key: string]: string;
    }) {
        this._registrationSettings.recipientSettings = recipientSettings;
    }

    private _updateRecipientConfig(
        recipientID: string,
        recipientConfig: {
            [key: string]: any;
        }
    ): Observable<any> {
        if (recipientConfig) {
            this._registrationSettings.recipientConfiguration = recipientConfig;
            return of({});
        } else {
            return this._submitterRecipientService
                .getRecipientConfiguration(recipientID)
                .pipe(
                    map((config: { [key: string]: any }) => {
                        this._registrationSettings.recipientConfiguration =
                            config;
                    })
                );
        }
    }

    private static _addNewState(state: string): StateWithCounties {
        return {
            state: state,
            count: 0,
            dbcCounties: [],
            counties: []
        };
    }
}
