/**
 * Note that these methods all work only on settings for the currently logged-in user
 */
import { Injectable } from "@angular/core";
import { RpcClientService } from "./rpc-client.service";
import { SessionService } from "./session.service";
import { Session } from "../interfaces/session.interface";
import { getPath } from "../helpers/object";
import { Observable, Subject } from "rxjs";

declare const sf: any;

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class UserSettingsService {
    private cachedSettings = {};

    constructor(
            private _rpcClient: RpcClientService,
            private sessionService: SessionService
    ) {
        let session: Session;
        if (typeof sf != "undefined" && sf) {
            session = sf.session;
            this.cachedSettings = getPath(session, "userSettings");
        }
        this.sessionService.sessionResetEvent.subscribe(() => {
            session = sf.session;
            this.cachedSettings = getPath(session, "userSettings");
        });
    }

    /**
     * Get ALL user settings from the most recent cached snapshot
     * You probably don't want to use this normally. Use getUserSetting for individual settings.
     */
    getAllUserSettings(): any {
        return this.cachedSettings;
    }

    /**
     * a specific user setting - from the most recent snapshot
     * @param settingName
     */
    getUserSetting(settingName: string): any {
        if (!this.cachedSettings || !settingName) {
            return null;
        }
        settingName = settingName.toUpperCase();

        const value = getPath(this.cachedSettings, settingName);
        // if (value === null) {
        //     log.getLogger(LoggerType.FALCON).warn("Unrecognized user setting name: " + settingName);
        // }

        return value;
    }

    /**
     * get a 'boolean' value for a user setting
     * @param settingName
     */
    getUserSettingBoolean(settingName: string): boolean {
        const value = this.getUserSetting(settingName);

        return !(
                typeof value === "undefined" ||
                value == null ||
                value == "null" ||
                value == "0" ||
                value == "false" ||
                value == 0 ||
                value == "FALSE"
        );
    }

    /**
     * Save one user setting to the back end
     * @param {string} settingName
     * @param {*} value
     */
    setUserSetting(settingName: string, value: any): Observable<void> {
        let response$ = new Subject<void>();
        let userID = this.sessionService.getUserID();
        settingName = settingName.toUpperCase();
        this._rpcClient.makeRequest("userorg", "setUserSetting", {
            username: userID,
            settingName: settingName,
            value: value
        }).subscribe((settings: any) => {
            this.cachedSettings = settings;
            response$.next();
            response$.complete();
        });
        return response$.asObservable();
    }

    /**
     * Save multiple user settings (in an Object) - only the ones passed in will be updated
     * @param settings
     */
    setUserSettings(settings: any): boolean {
        let userID = this.sessionService.getUserID();
        this._rpcClient.makeRequest("userorg", "saveUserSettings", {
            username: userID,
            settings: settings
        }).subscribe((settings: any) => {
            this.cachedSettings = settings;
        });
        return true;
    }
}
