import {
    Component,
    ComponentFactoryResolver,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { NavBadgeComponent } from "../nav-badge/nav-badge.component";
import { Router } from "@angular/router";
import { NavItem } from "../../interfaces/navigation.interface";
import { NavItemBadge } from "../../interfaces/nav-badge.interface";

@Component({
    selector: "sf-nav-item-link",
    templateUrl: "./nav-item-link.component.html",
    styleUrls: ["./nav-item-link.component.scss"]
})
export class NavItemLinkComponent implements OnInit {
    @Input()
    item: NavItem;
    @Input()
    isSubNavigation = false;

    @ViewChild("badge", { read: ViewContainerRef, static: true })
    badgeRef: ViewContainerRef;

    @HostBinding("class.active")
    get active() {
        return this.item.active && !this.item.hasActiveChildren;
    }

    label: string;
    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected router: Router
    ) {}

    ngOnInit() {
        if (this.item.badge) {
            this._createBadge(this.item.badge);
        }
        if (this.item.link) {
            this.label =
                this.item.label.toLowerCase().split(" ").join("-") + "_nav";
        }
    }

    private _createBadge(badge: NavItemBadge) {
        let component = NavBadgeComponent;
        if (badge.component) {
            component = badge.component;
        }

        let componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = this.badgeRef.createComponent(componentFactory);
        const badgeInstance = componentRef.instance as NavBadgeComponent;

        badgeInstance.value = badge.value;
        if (badge.type) {
            badgeInstance.type = badge.type;
        }
        if (badge.extras) {
            badgeInstance.extras = badge.extras;
        }
        if (badge.label) {
            badgeInstance.label = badge.label;
        }
    }
}
