<div class="d-flex flex-grow-1" *ngIf="changes != undefined">
    <ag-grid-angular
        #changesGrid
        suppressDragLeaveHidesColumns
        enableCellTextSelection
        rowSelection="single"
        class="ag-grid ag-theme-sf live-config-changes-grid"
        sortable="true"
        [context]="gridContext()"
        (gridSizeChanged)="onGridSizeChanged($event)"
        [columnDefs]="changesGridColumns"
        [rowData]="changes"
        [gridOptions]="changesGridOptions"
    >
    </ag-grid-angular>
</div>
<div *ngIf="extra != undefined">
    <pre>{{ extra }}</pre>
</div>
