import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { GridApi, SelectionChangedEvent } from "@ag-grid-community/core";
import { TemplateRendererComponent } from "@sf/common";
import {
    EmailPackageDownloadLinkService,
    UIEmailDownloadDocumentModel,
    UIEmailDownloadPackageModel
} from "../../services/email-package-download-link.service";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { icon } from "@fortawesome/fontawesome-svg-core";
import {
    faCheckSquare,
    faSquare,
    faMinusSquare
} from "@fortawesome/pro-regular-svg-icons";

@Component({
    selector: "sf-email-package-download-link",
    templateUrl: "./email-package-download-link.component.html",
    styleUrls: ["./email-package-download-link.component.scss"]
})
export class EmailPackageDownloadLinkComponent implements OnInit {
    /** Public variables **/
    status: string;
    pkgData: UIEmailDownloadPackageModel;
    token: string = "";
    selectedDocumentIDs: string = "";
    icons = {
        checkboxChecked: `${icon(faCheckSquare).html.join("")}`,
        checkboxUnchecked: `${icon(faSquare).html.join("")}`,
        checkboxIndeterminate: `${icon(faMinusSquare).html.join("")}`
    };
    allowDownloading: boolean = false;
    tooltipMessage: string =
        "Please select documents from the table to download.";

    /** Private variables **/
    private _gridApi: GridApi;
    private _selectedRowsCount: number = 0;
    private _downloadLimit: number = 25;

    /** View children **/
    @ViewChild("downloadButtonCellTemplate")
    downloadButtonCellTemplate: TemplateRef<any>;

    constructor(
        private _emailPackageDownloadLinkService: EmailPackageDownloadLinkService,
        private _activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this._activatedRoute.queryParams.subscribe((params) => {
            this.token = params["token"];
            this.authenticateAndGetData(false);
        });
    }

    authenticateAndGetData(overwrite: boolean) {
        this._emailPackageDownloadLinkService
            .authenticateAndGetUIEmailDownloadPackageModel(
                this.token,
                overwrite
            )
            .pipe(take(1))
            .subscribe((response) => {
                this.status = response.status;
                this.pkgData = response.uiemailDownloadPackageModel;
            });
    }

    onGridReady($event: { api: GridApi }) {
        this._gridApi = $event.api;
        this._gridApi.setColumnDefs([
            {
                colId: "checkbox",
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerName: "",
                suppressMovable: true,
                suppressMenu: true,
                resizable: false,
                sortable: false,
                suppressSizeToFit: true,
                lockPosition: true,
                width: 40
            },
            {
                colId: "documentName",
                field: "documentName",
                headerName: "Document Name",
                suppressMenu: true,
                width: 360
            },
            {
                colId: "documentType",
                field: "type",
                headerName: "Document Type",
                suppressMenu: true,
                width: 360
            },
            {
                colId: "entry",
                field: "entryNumber",
                headerName: "Entry",
                suppressMenu: true,
                width: 360
            },
            {
                colId: "download",
                headerName: "",
                width: 60,
                suppressMenu: true,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.downloadButtonCellTemplate
                }
            }
        ]);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.selectAll();
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        let selectedRows = event.api.getSelectedRows();
        this._selectedRowsCount = selectedRows.length;
        this.allowDownloading = this._downloadingAllowed();
        this.tooltipMessage = this._getTooltipMessage();
        this.selectedDocumentIDs = selectedRows
            .map((row: UIEmailDownloadDocumentModel) => {
                return row.documentID;
            })
            .join(",");
    }

    _downloadingAllowed(): boolean {
        return (
            this._selectedRowsCount > 0 &&
            this._selectedRowsCount <= this._downloadLimit
        );
    }

    _getTooltipMessage(): string {
        if (this._selectedRowsCount === 0) {
            return "Please select documents from the table to download.";
        } else if (this._selectedRowsCount > this._downloadLimit) {
            return (
                "For technical reasons, downloading is limited to " +
                this._downloadLimit +
                " documents at a time."
            );
        } else {
            return "";
        }
    }
}
