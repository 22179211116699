/**
 * This is a 'drop-down' selector of users
 * It allows filtering for any user that the currently logged-in user is related to.
 * Single-select only. For multi-select, use user-multi-select.component.
 */
import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Subject } from "rxjs";
import { SortUtilitiesService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { SelectableUser, User } from "@sf/userorg/common";
import { noop } from "@sf/common";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { first } from "rxjs/operators";

@Component({
    selector: "sf-user-filter-selector",
    templateUrl: "./user-filter-selector.component.html",
    styleUrls: ["./user-filter-selector.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserFilterSelectorComponent),
            multi: true
        }
    ]
})
export class UserFilterSelectorComponent
    implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
    /* Inputs */
    @Input()
    selectedUserID: string; // (optional) (single select) userID initially selected
    @Input()
    autoInitialSelect: boolean; // initially select an item - determined by selectedUserID, else first item in list
    @Input()
    searchLimit: number; // (optional - default is 25) max number of users to show in list
    @Input()
    canClearSelectedUser: boolean; // whether to show an 'X' to clear selection
    @Input()
    hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input()
    selectableUserAdminOnly: boolean; // only display the users this user has admin rights for
    @Input()
    includeNoneOption: SelectableUser; // (optional) item to include at the top of the list
    @Input()
    required: boolean = null; // if required in form
    @Input()
    placeholder: string; // (optional) placeholder - will be defaulted
    @Input()
    windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'
    @Input()
    canEditUsersOnly: boolean; // only display users the current user can edit
    @Input()
    isDisabled: boolean;
    @Input()
    disableSearch: boolean;

    /* Outputs */
    @Output()
    select: EventEmitter<any> = new EventEmitter(); // when a user is selected - passes a 'user' parameter when called

    /** Private Variables **/
    private _$ngOnDestroy: Subject<void> = new Subject();
    private _ngModelOnChange: Function = noop;
    private _ngModelOnTouched: Function = noop;

    /** Public Variables **/
    users: User[] = [];
    selectedUser: User = null;
    selectableItems: SelectableUser[] = [];
    searchMyUsers = false; // only set if more than 100 users

    constructor(private userorgService: UserOrgService) {}

    ngOnInit() {
        if (!this.placeholder) {
            this.placeholder = "Select User...";
        }
        if (!this.windowClass) this.windowClass = "not-full-width";
        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        this.userorgService
            .getUserCountForCurrentUser()
            .pipe(first())
            .subscribe((count: number) => {
                // 100 is the max number of users before we require searching
                this.searchMyUsers = !this.disableSearch && count > 100;
                this.getInitialList();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.selectedUserID &&
            changes.selectedUserID.currentValue &&
            changes.selectedUserID.currentValue !==
                changes.selectedUserID.previousValue
        ) {
            // this.selectedUser = this.findUserInList(this.selectedUserID);
        }
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
    }

    /* Begin ControlValueAccessor Implementation */
    writeValue(user: User) {
        // to where?
    }

    registerOnChange(fn: Function) {
        this._ngModelOnChange = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    registerOnTouched(fn: Function) {
        this._ngModelOnTouched = fn;
    }
    /* End ControlValueAccessor Implementation */

    getInitialList() {
        if (this.searchMyUsers) {
            this.populateUsers({
                $searchValue: this.selectedUserID
            });
        } else {
            this.userorgService
                .getUsersForCurrentUser(this.canEditUsersOnly)
                .subscribe((userData: any) => {
                    this.newUsersReceived(userData);
                });
        }
    }

    getUsers(event: any) {
        if (this.searchMyUsers) {
            this.populateUsers(event);
        }
    }

    populateUsers(event?: any) {
        let searchValue = event.$searchValue;

        // if user entered search criteria
        if (
            searchValue !== null &&
            searchValue !== "" &&
            searchValue !== undefined
        ) {
            // do a search
            this.userorgService
                .getUsersForCurrentUserByQuery(
                    searchValue,
                    this.canEditUsersOnly
                )
                .pipe(first())
                .subscribe((results: User[]) => {
                    this.users = this._sortUsers(results);
                    this.selectableItems = this.users.map(
                        this._makeSelectableUser,
                        this
                    );

                    if (this.selectedUser) {
                        let foundSelection = this.selectableItems.find(
                            (user) => {
                                return user.id === this.selectedUser.id;
                            }
                        );
                        if (!foundSelection) {
                            this.selectableItems.push(
                                this._makeSelectableUser(this.selectedUser)
                            );
                        }
                    }
                });
        }
    }

    private _sortUsers(unsorted: User[]): User[] {
        let sorted: User[] = unsorted.sort((user1, user2) => {
            return SortUtilitiesService.stringSortCompareInsensitive(
                user1.name.first + " " + user1.name.last,
                user2.name.first + " " + user2.name.last
            );
        });
        return sorted;
    }

    handleUserSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            let selection: SelectableUser = selectionObject;
            let user = null;
            if (selection && selection.id) {
                user = this.findUserInList(selection.id);
            }
            this.selectedUser = user; // may be null, which is fine
            if (this.select) {
                this.select.emit(user);
                this._ngModelOnChange(user);
            }
        }
    }

    private findUserInList(userID: string): User {
        return this.findUserInSpecifiedList(userID, this.users);
    }

    private findUserInSpecifiedList(userID: string, list: User[]): User {
        if (!list) {
            return null;
        }
        let user: User = list.find((user) => {
            return user.id === userID;
        });
        return user;
    }

    /*
    onSearchChange() {
        this.filterTextChanged();
    }

    cancelSearch() {
        setTimeout(() => {
            this.filteredUserList = this.initialUserList;
        }, 1);
    }

    onSearchKey(event: any) {
        // this is called on every keystroke in the 'search' input
        if (event.which === 13) {
            // 'Enter' key pressed
            let first = this.filteredUserList[0];
            if (first) {
                this.selectUser(first);
            }
        } else if (event.which === 27) {
            // Esc key pressed
            // The search box will clear the search text when Esc pressed
        }
    }

    // filter the list by what user typed
    filterTextChanged() {
        this.postResults(
            this.userSelectorPipe.transform(this.initialUserList, this.searchText)
        );
    }
    */

    postResults(results: SelectableUser[]) {
        setTimeout(() => {
            this.selectableItems = results;
        }, 1);
    }

    // build list of users - only called once
    newUsersReceived(allUserData: User[]) {
        // filter by permission
        if (this.selectableUserAdminOnly) {
            allUserData = allUserData.filter((user: User) => {
                return user.canModify;
            });
        }

        this.users = this._sortUsers(allUserData);

        // build object to display
        let results: SelectableUser[] = [];
        this.users.forEach((user) => {
            // no pending users in list
            if (!user.pending) {
                results.push(this._makeSelectableUser(user));
            }
        });

        if (this.includeNoneOption) {
            results.unshift(this.includeNoneOption);
        }

        this.postResults(results);
    }

    private _makeSelectableUser(u: User): SelectableUser {
        return {
            id: u.id,
            label: u.name.first + " " + u.name.last + " (" + u.id + ")",
            selected: false
        };
    }
}
