import { Component, OnInit } from "@angular/core";
import { DynamicFormStore, InputComponent } from "@sf/dynamic-form-viewer";
import { StatesService } from "@sf/common";

@Component({
    selector: "sf-custom-state-field",
    templateUrl: "./custom-state-field.component.html",
    styleUrls: ["./custom-state-field.component.scss"]
})
export class CustomStateFieldComponent
    extends InputComponent
    implements OnInit
{
    states: any[];

    constructor(
        protected _formFacade: DynamicFormStore,
        private _stateService: StatesService
    ) {
        super(_formFacade);
    }

    ngOnInit() {
        this.states = this._stateService.getAllStatesAndCanadianProvinces();
        super.ngOnInit();
    }
}
