import { Component, OnInit } from "@angular/core";
import { GrowlService, UserSettingsService } from "@sf/common";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "sf-document-builder-guide-notification-settings",
    templateUrl:
        "./document-builder-guide-notification-settings.component.html",
    styleUrls: ["./document-builder-guide-notification-settings.component.scss"]
})
export class DocumentBuilderGuideNotificationSettingsComponent
    implements OnInit
{
    private oldSettings: any = null;

    settings: any = {};
    signingSettingsForm: FormGroup;

    constructor(
        private _userSettingsService: UserSettingsService,
        private _fb: FormBuilder,
        private _growlService: GrowlService
    ) {}

    ngOnInit(): void {
        this.settings = {
            NOTIFICATION_SIGNING_DOCUMENTS:
                this._userSettingsService.getUserSetting(
                    "NOTIFICATION_SIGNING_DOCUMENTS"
                ),
            NOTIFICATION_SIGNING_ELAPSED:
                this._userSettingsService.getUserSetting(
                    "NOTIFICATION_SIGNING_ELAPSED"
                ),
            NOTIFICATION_SIGNING_DOCUMENTS_COUNT:
                this._userSettingsService.getUserSetting(
                    "NOTIFICATION_SIGNING_DOCUMENTS_COUNT"
                ),
            NOTIFICATION_SIGNING_ELAPSED_TIME:
                this._userSettingsService.getUserSetting(
                    "NOTIFICATION_SIGNING_ELAPSED_TIME"
                )
        };
        this.oldSettings = JSON.parse(JSON.stringify(this.settings));
        this.setupForm();
    }

    setupForm(): void {
        this.signingSettingsForm = this._fb.group({});
        this.signingSettingsForm.addControl(
            "NOTIFICATION_SIGNING_DOCUMENTS",
            new FormControl(this.settings.NOTIFICATION_SIGNING_DOCUMENTS)
        );
        this.signingSettingsForm.addControl(
            "NOTIFICATION_SIGNING_ELAPSED",
            new FormControl(this.settings.NOTIFICATION_SIGNING_ELAPSED)
        );
        this.signingSettingsForm.addControl(
            "NOTIFICATION_SIGNING_DOCUMENTS_COUNT",
            new FormControl(
                this.settings.NOTIFICATION_SIGNING_DOCUMENTS_COUNT,
                [Validators.required, Validators.min(1)]
            )
        );
        this.signingSettingsForm.addControl(
            "NOTIFICATION_SIGNING_ELAPSED_TIME",
            new FormControl(this.settings.NOTIFICATION_SIGNING_ELAPSED_TIME, [
                Validators.required,
                Validators.min(5)
            ])
        );
        this.signingSettingsForm
            .get("NOTIFICATION_SIGNING_DOCUMENTS")
            .valueChanges.subscribe((checked: boolean) => {
                if (checked) {
                    this.signingSettingsForm.addControl(
                        "NOTIFICATION_SIGNING_DOCUMENTS_COUNT",
                        new FormControl(
                            this.settings.NOTIFICATION_SIGNING_DOCUMENTS_COUNT,
                            [Validators.required, Validators.min(1)]
                        )
                    );
                } else {
                    this.signingSettingsForm.removeControl(
                        "NOTIFICATION_SIGNING_DOCUMENTS_COUNT"
                    );
                }
            });
        this.signingSettingsForm
            .get("NOTIFICATION_SIGNING_ELAPSED")
            .valueChanges.subscribe((checked: boolean) => {
                if (checked) {
                    this.signingSettingsForm.addControl(
                        "NOTIFICATION_SIGNING_ELAPSED_TIME",
                        new FormControl(
                            this.settings.NOTIFICATION_SIGNING_ELAPSED_TIME,
                            [Validators.required, Validators.min(5)]
                        )
                    );
                } else {
                    this.signingSettingsForm.removeControl(
                        "NOTIFICATION_SIGNING_ELAPSED_TIME"
                    );
                }
            });

        this.signingSettingsForm.valueChanges
            .pipe(debounceTime(500))
            .subscribe(() => {
                if (
                    !this.signingSettingsForm.pristine &&
                    this.signingSettingsForm.valid
                ) {
                    // changed and valid, then save
                    this.save();
                }
            });
    }

    save(): void {
        this.oldSettings = JSON.parse(
            JSON.stringify(this.signingSettingsForm.value)
        );

        this.settings.NOTIFICATION_SIGNING_DOCUMENTS =
            this.oldSettings.NOTIFICATION_SIGNING_DOCUMENTS;
        this.settings.NOTIFICATION_SIGNING_ELAPSED =
            this.oldSettings.NOTIFICATION_SIGNING_ELAPSED;
        this.settings.NOTIFICATION_SIGNING_DOCUMENTS_COUNT =
            this.oldSettings.NOTIFICATION_SIGNING_DOCUMENTS_COUNT;
        this.settings.NOTIFICATION_SIGNING_ELAPSED_TIME =
            this.oldSettings.NOTIFICATION_SIGNING_ELAPSED_TIME;

        this._userSettingsService.setUserSettings(this.settings);
        this._growlService.success("Settings saved.");
    }
}
