import {
    Component,
    Input,
    OnInit,
    SecurityContext,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
    CurrentOrganizationService,
    PaymentAccount,
    PaymentAccountsService
} from "@sf/userorg/common";
import { dayjs, ModalButton, TemplateRendererComponent } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridSizeChangedEvent,
    ICellRendererParams,
    RowNode,
    SelectionChangedEvent
} from "@ag-grid-community/core";

import { take } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

enum Mode {
    DefaultAccountChanged,
    AccountArchived
}

@Component({
    selector: "sf-update-packages-default-account-dialog",
    templateUrl: "./update-packages-default-account-dialog.component.html",
    styleUrls: ["./update-packages-default-account-dialog.component.scss"]
})
export class UpdatePackagesDefaultAccountDialogComponent implements OnInit {
    @Input()
    newAccount: PaymentAccount;
    @Input()
    oldAccount: PaymentAccount;
    @Input()
    fee: string;
    @Input()
    feeLabel: string;
    @Input()
    product: string;
    @Input()
    packages: any[];
    @Input()
    packagesPerFee: any[];
    @Input()
    defaultOptions: any[];

    @ViewChild("packageNameTemplate", { static: true })
    packageNameTemplate: TemplateRef<any>;

    get Mode() {
        return Mode;
    }

    mode: Mode;
    modalTitle: string;
    primaryButton: ModalButton;
    secondaryButton: ModalButton;
    gridOptions: GridOptions;
    gridDivStyle: any = {
        height: "90px" //minimum for two rows
    };
    showProgress: boolean = false;
    pkgUpdateInProgress: boolean = false;
    progressPercent: number = 0;
    progressStyle: any = {
        width: this.progressPercent + "%",
        "aria-valuenow": this.progressPercent,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
    };
    progressInfo: string = "Updating Packages " + this.progressPercent + "%";
    selectedPkgLength: number;
    noDefaultMode: boolean = false;

    private _selectedPackages: any[];
    private _gridApi: GridApi;
    private _gridColumnDefs: Partial<ColDef>[];
    private _rowHeight: number = 30;
    private _headerHeight: number = 35;
    private _intervalID: number;
    private _unaffectedPackages: any[];
    private _orgID: string = null;

    constructor(
        private _activeModal: NgbActiveModal,
        private _paymentAccountsService: PaymentAccountsService,
        private _currentOrgService: CurrentOrganizationService,
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this._orgID = this._currentOrgService.getCurrentOrganizationID();

        //determine mode
        if (this.packagesPerFee && this.defaultOptions) {
            this.mode = Mode.AccountArchived;
        } else {
            this.mode = Mode.DefaultAccountChanged;
            this.noDefaultMode = this.newAccount.viewLabel == "No Default";
        }

        //set title
        if (this.mode == Mode.DefaultAccountChanged)
            this.modalTitle =
                "Update Payment Account Default for Pending Packages";
        else
            this.modalTitle =
                "Update Payment Account Defaults for Pending Packages";

        //setup buttons
        this.primaryButton = {
            text: "Confirm Update",
            hidden: false,
            disabled: false
        };
        this.secondaryButton = {
            text: "Cancel Update"
        };
        if (this.mode == Mode.DefaultAccountChanged)
            this.primaryButton.callback = this._processPackages.bind(this);
        else
            this.primaryButton.callback = this._processPackagesByFee.bind(this);

        //setup columns
        this._gridColumnDefs = [
            {
                colId: "packageName",
                field: "name",
                headerName: "Package",
                checkboxSelection: true,
                headerCheckboxSelection: true,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.packageNameTemplate
                },
                comparator: this._packageNameComparator
            },
            {
                colId: "packageStatus",
                field: "status",
                headerName: "Status",
                width: 100,
                cellRenderer: (params: ICellRendererParams) => {
                    let sanitized = this._sanitizer.sanitize(
                        SecurityContext.HTML,
                        params.value.toLowerCase()
                    );
                    return (
                        '<div class="pkgStatus"><button class="btn badge ' +
                        sanitized +
                        '">' +
                        sanitized +
                        "</button></div>"
                    );
                }
            },
            {
                colId: "packageRecipient",
                field: "recipient",
                headerName: "Recipient"
            },
            {
                colId: "packageDate",
                field: "createdDate",
                headerName: "Date Created",
                cellRenderer: (params: ICellRendererParams) => {
                    let sanitized = this._sanitizer.sanitize(
                        SecurityContext.HTML,
                        params.value
                    );
                    let date: dayjs.Dayjs = dayjs(sanitized);
                    return date.format("ddd M/D/YYYY h:mm A");
                },
                comparator: this._packageDateComparator
            }
        ];

        //setup grid
        this.gridOptions = {
            columnDefs: this._gridColumnDefs,
            context: this,
            defaultColDef: {
                sortable: true
            },
            rowHeight: this._rowHeight,
            rowSelection: "multiple" as const,
            suppressCellFocus: true,
            suppressRowClickSelection: true,
            onGridSizeChanged: (event: GridSizeChangedEvent) => {
                if (this._gridApi) {
                    this._gridApi.sizeColumnsToFit();
                }
            },
            onGridReady: (event: GridReadyEvent) => {
                this._gridApi = event.api;
                //set initial sort of grid
                event.columnApi.applyColumnState({
                    state: [{ colId: "packageName", sort: "asc" }]
                });
                this._gridApi.selectAll();
            },
            isRowSelectable: (rowNode: RowNode) => {
                if (this.noDefaultMode) {
                    return rowNode.data.isUnsentState;
                }
                return true;
            }
        };

        if (this.packages.length <= 8) {
            this.gridDivStyle.height =
                this.packages.length * this._rowHeight +
                this._headerHeight +
                10 +
                "px";
        } else {
            this.gridDivStyle.height = "300px";
        }
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this._selectedPackages = event.api.getSelectedRows();
        this.selectedPkgLength = this._selectedPackages!!
            ? this._selectedPackages.length
            : 0;
        this.primaryButton.disabled = this.selectedPkgLength == 0;
    }

    /**
     * Handles dismissing the active dialog - can be executed as needed, or when secondary button is clicked
     * @param dismiss - boolean; if true, the dialog is dismissed rather than closed
     * @private
     */
    private _closeDialog(dismiss: boolean) {
        if (dismiss) {
            this._activeModal.dismiss();
        } else {
            this._activeModal.close(this._unaffectedPackages);
        }
    }

    private _processPackages() {
        if (this._selectedPackages!! && this._selectedPackages.length < 1) {
            //nothing to process, just close dialog
            this._closeDialog(true);
            return;
        }

        //show progress bar
        this.showProgress = true;
        this.pkgUpdateInProgress = true;

        //kick off interval to update progress bar
        this._intervalID = window.setInterval(this._updateProgress, 1000, this);

        //disable buttons - as flow should be programmatic now
        this.primaryButton.disabled = true;

        //selected packages should already be set from selection change handler
        let packageIDsToUpdate: string[] = this._selectedPackages.map((pkg) => {
            return pkg.id;
        });
        this._paymentAccountsService
            .setDefaultAccountOnPackages(
                this.newAccount.organizationID,
                this._getTranslatedFee(this.fee),
                //this.fee,
                this.newAccount.paymentAccountID,
                packageIDsToUpdate
            )
            .pipe(take(1))
            .subscribe(
                (unaffectedPackages: any[]) => {
                    this._unaffectedPackages = unaffectedPackages;
                    this.pkgUpdateInProgress = false;
                },
                () => {
                    this._unaffectedPackages = [];
                    this.pkgUpdateInProgress = false;
                }
            );
    }

    private _processPackagesByFee() {
        if (this._selectedPackages.length < 1) {
            //nothing to process, just close dialog
            this._closeDialog(true);
            return;
        }

        //show progress bar
        this.showProgress = true;
        this.pkgUpdateInProgress = true;

        //kick off interval to update progress bar
        this._intervalID = window.setInterval(this._updateProgress, 1000, this);

        //disable buttons - as flow should be programmatic now
        this.primaryButton.disabled = true;

        //selected packages should already be set from selection change handler
        let packageIDsToUpdate: string[] = this._selectedPackages.map((pkg) => {
            return pkg.id;
        });

        let obs: any[] = this.packagesPerFee.map((feePkgObj: any) => {
            //only process given fee if it has packages
            if (feePkgObj?.packagesForFee?.length > 0) {
                //only process given fee if it has a default account set
                let defaultOption: any[] = this.defaultOptions.filter(
                    (option: any) => {
                        return option.category === feePkgObj.fee;
                    }
                );
                //union the selected packages with packages associated with fee - and process those
                let packagesToProcess = feePkgObj.packagesForFee.filter(
                    (x: any) => packageIDsToUpdate.includes(x.id)
                );
                let packageIDs: string[] = packagesToProcess.map((pkg: any) => {
                    return pkg.id;
                });
                if (packageIDs.length > 0) {
                    if (defaultOption.length == 1) {
                        return this._paymentAccountsService.setDefaultAccountOnPackages(
                            defaultOption[0].organizationID,
                            this._getTranslatedFee(defaultOption[0].category),
                            defaultOption[0].accountID,
                            packageIDs
                        );
                    } else {
                        //this will update the package to use the invoice (draw down) account
                        return this._paymentAccountsService.setDefaultAccountOnPackages(
                            this._orgID,
                            this._getTranslatedFee(feePkgObj.fee),
                            null,
                            packageIDs
                        );
                    }
                }
            }
        });

        //clears out any undefined subscriptions in the obs array - else you get an error in the forkJoin
        obs = obs.filter((sub: any) => {
            return sub;
        });

        this._unaffectedPackages = [];
        if (obs.length > 0) {
            forkJoin(...obs).subscribe((results: any[]) => {
                results.forEach((unaffectedPackages: any[]) => {
                    unaffectedPackages.forEach((pkg: any) => {
                        if (!this._unaffectedPackages.includes(pkg))
                            this._unaffectedPackages.push(pkg);
                    });
                });
                this.pkgUpdateInProgress = false;
            });
        } else {
            this.pkgUpdateInProgress = false;
        }
    }

    private _getTranslatedFee(fee: string): string {
        switch (fee) {
            case "SALES_TAX":
                return "SalesTax";
            default:
                return fee;
        }
    }

    private _updateProgress(thisRef: any) {
        //done processing all packages?
        if (!thisRef.pkgUpdateInProgress) {
            thisRef.progressPercent = 100;
        } else {
            //hold progress at 90% until done...how ever long that takes
            if (thisRef.progressPercent < 90) {
                thisRef.progressPercent += 10;
            }
        }
        thisRef._updateProgressStyle();
        if (thisRef.progressPercent == 100) {
            //stop interval from running
            clearInterval(thisRef._intervalID);

            //set time out to allow progress bar to update before closing dialog
            setTimeout(() => {
                thisRef._closeDialog(false);
            }, 1500);
        }
    }

    private _updateProgressStyle() {
        this.progressInfo = "Updating Packages " + this.progressPercent + "%";
        this.progressStyle = {
            width: this.progressPercent + "%",
            "aria-valuenow": this.progressPercent,
            "aria-valuemin": "0",
            "aria-valuemax": "100"
        };
    }

    private _packageDateComparator(date1: string, date2: string) {
        let m1: dayjs.Dayjs = dayjs(date1),
            m2: dayjs.Dayjs = dayjs(date2);

        if (m1.isSame(m2)) return 0;
        if (m1.isBefore(m2)) return -1;
        if (m1.isAfter(m2)) return 1;
    }

    private _packageNameComparator(
        name1: string,
        name2: string,
        node1: RowNode,
        node2: RowNode
    ) {
        // seems what was done here before was problematic after the agGrid update, plus not needed
        if (name1 == name2) return 0;
        return name1 > name2 ? 1 : -1;
    }
}
