import { Component, OnDestroy, OnInit } from "@angular/core";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { CustomFormService } from "../../custom-form.service";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { generateRandomString } from "@sf/common";

@Component({
    selector: "sf-custom-paragraph-field",
    templateUrl: "./custom-paragraph-field.component.html",
    styleUrls: ["./custom-paragraph-field.component.scss"]
})
export class CustomParagraphFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    randomName: string = generateRandomString(5);

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }
}
