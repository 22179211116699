import { Injectable, OnDestroy } from "@angular/core";
import { SessionService } from "@sf/common";
import { PersistedMemoryService } from "@sf/common";
import { CustomerSurveyService } from "./customer-survey.service";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import {
    CustomerSurvey,
    CustomerSurveyQuestion
} from "../interfaces/customer-survey";
import { filter, finalize, takeUntil, withLatestFrom } from "rxjs/operators";
import { valueMatchesCondition } from "../helpers/customer-surveys.helper";
import { GrowlService } from "@sf/common";
import { NavigationStart, Router } from "@angular/router";

interface CurrentSurveyState {
    currentSurvey?: CustomerSurvey;
    currentQuestion?: CustomerSurveyQuestion;
    currentPrimaryQuestionIndex?: number;
    safeToDismiss?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class CustomerSurveyDataService implements OnDestroy {
    /** Private Variables **/
    private _storageKey = `customerSurvey-${this._session.getScrambledUsername()}`;
    private _currentSurvey$ = new BehaviorSubject<CustomerSurvey>(undefined);
    private _currentQuestion$ = new BehaviorSubject<CustomerSurveyQuestion>(
        undefined
    );
    private _currentPrimaryQuestionIndex$ = new BehaviorSubject(0);
    private _safeToDismiss$ = new BehaviorSubject(false);
    private _stopListeningToRouteChanges$ = new Subject<void>();
    private _customerSurveysSubscription: Subscription;

    /** Public Variables **/

    constructor(
        private _session: SessionService,
        private _persistedMemory: PersistedMemoryService,
        private _customerSurvey: CustomerSurveyService,
        private _growlService: GrowlService,
        private _router: Router
    ) {}

    ngOnDestroy() {
        this._customerSurveysSubscription?.unsubscribe();
    }

    /** Public Methods **/
    subscribeToCustomerSurveys(): Observable<CustomerSurveyQuestion> {
        const _currentSurveyState = (this._persistedMemory.get(
            this._storageKey
        ) || {}) as CurrentSurveyState;

        const _currentPrimaryQuestionIndex =
            _currentSurveyState.currentPrimaryQuestionIndex;
        const _safeToDismiss = _currentSurveyState.safeToDismiss;

        this._currentSurvey$.next(_currentSurveyState.currentSurvey);
        this._currentPrimaryQuestionIndex$.next(_currentPrimaryQuestionIndex);
        this._safeToDismiss$.next(_safeToDismiss);

        // Surveys can be in a state where they can be dismissed safely,
        // like after a user answers the first question and navigates away
        if (_safeToDismiss) {
            this.dismissSurvey();
        }

        // If the user already has a survey, sent it to them
        if (_currentSurveyState.currentSurvey) {
            this._presentSurveyWithQuestion(
                _currentSurveyState.currentQuestion
            );
        } else {
            // If the backend has a survey waiting for the user,
            // this will trigger it so the user will see it
            this._customerSurvey.syncUserSurveys().subscribe();
        }

        this._customerSurveysSubscription = this._customerSurvey
            .subscribeToCustomerSurveys(this._session.getScrambledUsername())
            .pipe(withLatestFrom(this._currentSurvey$))
            .subscribe(([newSurvey, currentSurvey]) => {
                if (currentSurvey) {
                    // dismiss the new survey if survey is already in progress
                    this._customerSurvey.dismissSurvey(newSurvey.id);
                } else {
                    const currentPrimaryQuestionIndex = 0;
                    const currentQuestion =
                        newSurvey.questions[currentPrimaryQuestionIndex];
                    const safeToDismiss = false;

                    this._saveCurrentSurveyState({
                        currentSurvey: newSurvey,
                        currentQuestion,
                        currentPrimaryQuestionIndex,
                        safeToDismiss
                    });

                    this._presentSurveyWithQuestion(currentQuestion);
                    this._currentSurvey$.next(newSurvey);
                    this._currentPrimaryQuestionIndex$.next(
                        currentPrimaryQuestionIndex
                    );
                    this._safeToDismiss$.next(safeToDismiss);
                }
            });

        return this._currentQuestion$;
    }

    answerQuestion(questionID: string, response: string | number) {
        if (this._currentQuestion$.getValue().id === questionID) {
            this._customerSurvey
                .answerSurveyQuestion({
                    surveyID: this._currentSurvey$.getValue().id,
                    questionID: questionID,
                    value: response.toString()
                })
                .pipe(
                    withLatestFrom(
                        this._currentSurvey$,
                        this._currentQuestion$,
                        this._currentPrimaryQuestionIndex$
                    )
                )
                .subscribe(
                    ([
                        _,
                        currentSurvey,
                        currentQuestion,
                        currentPrimaryQuestionIndex
                    ]) => {
                        this._safeToDismiss$.next(true);

                        let newQuestion: CustomerSurveyQuestion;
                        let newCurrentPrimaryQuestionIndex =
                            currentPrimaryQuestionIndex;
                        if (currentQuestion.dependentQuestions) {
                            newQuestion = this._getNextQuestion(
                                currentQuestion.dependentQuestions,
                                response as number
                            );
                        } else if (
                            currentPrimaryQuestionIndex <
                            currentSurvey.questions.length - 1
                        ) {
                            newCurrentPrimaryQuestionIndex =
                                currentPrimaryQuestionIndex + 1;
                            this._currentPrimaryQuestionIndex$.next(
                                newCurrentPrimaryQuestionIndex
                            );
                            newQuestion =
                                currentSurvey.questions[
                                    newCurrentPrimaryQuestionIndex
                                ];
                        } else {
                            newQuestion = null;
                        }

                        if (newQuestion) {
                            this._currentQuestion$.next(newQuestion);
                            this._saveCurrentSurveyState({
                                currentSurvey,
                                currentQuestion: newQuestion,
                                currentPrimaryQuestionIndex:
                                    newCurrentPrimaryQuestionIndex,
                                safeToDismiss: true
                            });
                        } else {
                            this._completeSurvey();
                        }
                    },
                    ([_, currentSurvey, currentQuestion]) => {
                        this._growlService.error(
                            "Feedback could not be saved.",
                            undefined,
                            {
                                timeOut: 0
                            }
                        );
                        this._currentQuestion$.next(currentQuestion);
                    }
                );
        } else {
            // Something weird happened, so resend the current question
            this._currentQuestion$.next(this._currentQuestion$.getValue());
        }
    }

    dismissSurvey() {
        this._customerSurvey
            .dismissSurvey(this._currentSurvey$.getValue().id)
            .subscribe(() => {
                this._clearCurrentSurvey();
            });
    }

    /**  Private Methods  **/
    private _presentSurveyWithQuestion(question: CustomerSurveyQuestion) {
        this._currentQuestion$.next(question);
        this._router.events
            .pipe(
                takeUntil(this._stopListeningToRouteChanges$),
                filter((event) => {
                    return event instanceof NavigationStart;
                }),
                withLatestFrom(this._safeToDismiss$)
            )
            .subscribe(([_, safeToDismiss]) => {
                if (safeToDismiss) {
                    this.dismissSurvey();
                    this._growlService.success("Thank you for your feedback!");
                }
            });
    }

    private _saveCurrentSurveyState(currentSurveyState: CurrentSurveyState) {
        this._persistedMemory.set(this._storageKey, currentSurveyState);
    }

    private _getNextQuestion(
        potentialQuestions: CustomerSurveyQuestion[],
        previousResponse: number
    ): CustomerSurveyQuestion {
        let nextQuestion = null;
        potentialQuestions.forEach((question) => {
            if (valueMatchesCondition(previousResponse, question.condition)) {
                nextQuestion = question;
            }
        });

        return nextQuestion;
    }

    private _completeSurvey() {
        this._growlService.success("Thank you for your feedback!");
        this._clearCurrentSurvey();
    }

    private _clearCurrentSurvey() {
        this._currentSurvey$.next(null);
        this._currentQuestion$.next(null);
        this._currentPrimaryQuestionIndex$.next(undefined);
        this._safeToDismiss$.next(false);
        this._persistedMemory.remove(this._storageKey);
        this._stopListeningToRouteChanges$.next();
    }
}
