import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { Organization } from "../interfaces/organization.interface";
import { SessionOrganization, SessionService } from "@sf/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class CurrentOrganizationService {
    private _currentOrganization: BehaviorSubject<Organization> = new BehaviorSubject(null);

    public hasChildOrganizations$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public currentOrganization$ = this._currentOrganization.pipe(
        distinctUntilChanged((newOrg: Organization, oldOrg: Organization) => {
            // don't send notice unless it's a new org, or name changed, or status changed
            return !this.organizationChanged(newOrg, oldOrg);
        }),
        shareReplay(1) // magical mystery incantation
    );

    constructor(private sessionService: SessionService) {
        // if not a superuser, initialize the current organization
        if (!sessionService.isSuperUser()) {
            let organization: SessionOrganization = null;
            let orgID = sessionService.getDefaultOrganizationID();
            if (orgID) {
                organization = sessionService.getPartialOrganization(orgID);
            } else if (this.sessionService.getSessionID()) {
                let orgIDs = sessionService.getOrganizationIDsWithPermission("organization_login");
                if (orgIDs) {
                    organization = sessionService.getPartialOrganization(orgIDs[0]);
                }
            }
            // force it to obey
            let org: Partial<Organization> = organization;
            this._currentOrganization.next(org as Organization);
        }
    }

    getCurrentOrganization(): Organization {
        return this._currentOrganization.getValue();
    }

    // Convenience method for ID
    getCurrentOrganizationID(): string {
        let org = this._currentOrganization.getValue();
        if (!org) {
            return null;
        }
        return org.id;
    }

    updateCurrentOrganization(org: Organization): void {
        let currentOrgNow = this.getCurrentOrganization();
        if (this.organizationChanged(currentOrgNow, org)) {
            if (org && !org.name) {
                log.error("Invalid call to updateCurrentOrganization with partial organization");
            }
            this._currentOrganization.next(org);
        }
    }

    organizationChanged(org1: Organization, org2: Organization): boolean {
        if (!org1 && !org2) {
            // if both null, then they are the same
            return false;
        }
        if (!org1 || !org2) {
            // one is null
            return true;
        }
        if (org1.id != org2.id) {
            return true;
        }
        if (org1.name != org2.name) {
            return true;
        }
        if (org1.status != org2.status) {
            return true;
        }
        if (org1.statusCode && org2.statusCode) {
            if (org1.statusCode != org2.statusCode) {
                return true;
            }
        }
        return false;
    }

    // ------------------ start of hokey 'save' stuff -----------------

    // This is a way of notifying of requests
    // to save the contents of the current organization
    private _saveCurrentOrganizationNotifier: Subject<void> =
        new Subject();

    saveOrganization$ = this._saveCurrentOrganizationNotifier.asObservable();

    // notify listeners that somebody wants to save the contents of the current organization
    saveCurrentOrganization(): void {
        this._saveCurrentOrganizationNotifier.next();
    }

    // ------------------ end of 'save' stuff -----------------
}
