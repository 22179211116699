import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    CustomerSurveyQuestion,
    CustomerSurveyResponse
} from "../interfaces/customer-survey";

@Component({
    selector: "sf-customer-survey-question",
    templateUrl: "./customer-survey-question.component.html",
    styleUrls: ["./customer-survey-question.component.scss"]
})
export class CustomerSurveyQuestionComponent implements OnInit {
    /** I/O **/
    @Input() question: CustomerSurveyQuestion;
    @Input() isFinal: boolean;
    @Output() questionAnswered = new EventEmitter<CustomerSurveyResponse>();

    /** Private Variables **/

    /** Public Variables **/

    /** View Children **/

    constructor() {}

    /** Lifecycle Hooks **/

    ngOnInit() {}

    /** Public Methods **/
    submitAnswer(response: string | number) {
        this.questionAnswered.emit({ questionID: this.question.id, response });
    }

    /**  Private Methods  **/
}
