<div [ngClass]="{'input-group': true, 'negative': isNegative}">
    <div class="input-group-prepend" aria-hidden="true">
        <span class="input-group-text">$</span>
    </div>
    <input type="text" #currencyInput class="form-control"
           #inputModel="ngModel"
           [attr.id]="inputId"
           [ngModel]="model"
           (ngModelChange)="setCurrencyValue($event)"
           (keypress)="onKeyPress($event, currencyInput.value)"
           [attr.required]="required ? '' : null"
           [attr.disabled]="disabled ? '' : null"
           [attr.readOnly]="readonly ? '' : null"
           [attr.tabindex]="tabindex ? tabindex : null"
           [attr.placeholder]="placeholder ? placeholder : null"
           [ngClass]="{'text-right': rightAlign}"
           (blur)="onBlur($event)"
           (focus)="onFocus($event)"
           [sfAriaDescription]="ariaDescription"
    />
</div>
