import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AddCountyResponse,
    AddVendorResponse,
    County,
    GetCountyRegistrationSettingsResponse,
    ToggleCountyResponse
} from "@sf/submitter/common";
import { RpcClientService } from "@sf/common";
import { UIRecipientNameModel } from "../interfaces/ui-recipient-name-model.interface";

const RECIPIENT_PATH = "erecord";
const USERORG_PATH = "userorg";

@Injectable({
    providedIn: "root"
})
export class AdminRecipientService {
    constructor(private _rpcClient: RpcClientService) {}

    getAllRecipients(): Observable<UIRecipientNameModel[]> {
        return this._rpcClient.makeRequest(USERORG_PATH, "getAllRecipients");
    }

    getSubmitterRecipients(
        submitterID: string,
        state: string,
        bEnabledOnly: boolean
    ): Observable<County[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getSubmitterRecipients",
            {
                submitterID,
                state,
                bEnabledOnly
            }
        );
    }

    getSubmitterCountyCountByState(
        submitterID: string
    ): Observable<SubmitterCountyCountByState[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getSubmitterCountyCountByState",
            { submitterID }
        );
    }

    getRecipientDocumentStates(recipientID: string) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "getRecipientDocumentStates",
            {
                recipientID
            }
        );
    }

    getRecipientKOIs(recipientID: string) {
        return this._rpcClient.makeRequest(RECIPIENT_PATH, "getRecipientKOIs", {
            recipientID
        });
    }

    getRecipientKOIsFiltered(recipientID: string, submitterID: string) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "getRecipientKOIsFiltered",
            {
                recipientID,
                submitterID
            }
        );
    }

    getRecipientTownships(recipientID: string) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "getRecipientTownships",
            {
                recipientID
            }
        );
    }

    getRecipientConfiguration(recipientID: string) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "getRecipientConfiguration",
            {
                recipientID
            }
        );
    }

    getRecipientCustomProperties(recipientID: string) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "getRecipientCustomProperties",
            {
                recipientID
            }
        );
    }

    isOpen(recipients: any[]) {
        return this._rpcClient.makeRequest(
            RECIPIENT_PATH,
            "isOpenForSubmission",
            {
                recipients
            }
        );
    }

    getUnassociatedCounties(orgID: string, bAdminView: boolean) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getUnassociatedCounties",
            {
                orgID,
                adminView: bAdminView ? bAdminView : "true"
            }
        );
    }

    getUnassociatedCountiesByState(
        orgID: string,
        state: string
    ): Observable<County[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getUnassociatedCounties",
            {
                orgID,
                adminView: true,
                state
            }
        );
    }

    removeCounties(
        submitterID: string,
        recipientIDs: string[],
        emailType: "standard" | "none" | "custom",
        customEmailText?: string
    ): Observable<string[]> {
        const data: any = { submitterID, recipientIDs, emailType };
        if (customEmailText) {
            data.customEmailString = customEmailText;
        }
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "removeCounties",
            data
        );
    }

    getCountyRegistrationSettings(
        submitterID: string,
        recipientID: string,
        params?: Object
    ): Observable<GetCountyRegistrationSettingsResponse> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getCountyRegistrationSettings",
            {
                submitterID,
                recipientID,
                params: { ...params }
            }
        );
    }

    addCounty(
        submitterID: string,
        recipientID: string,
        params: any
    ): Observable<AddCountyResponse> {
        return this._rpcClient.makeRequest(USERORG_PATH, "addCounty", {
            submitterID,
            recipientID,
            params
        });
    }

    addAllCounties(
        submitterID: string,
        state: string
    ): Observable<AddCountyResponse[]> {
        return this._rpcClient.makeRequest(USERORG_PATH, "addAllCounties", {
            submitterID,
            state
        });
    }

    setPrintScale(
        orgID: string,
        countyIDs: string[],
        value: number
    ): Observable<void> {
        return this._rpcClient.makeRequest(USERORG_PATH, "setPrintScale", {
            orgID,
            countyIDs,
            value
        });
    }

    setAllPrintScale(orgID: string, value: number): Observable<void> {
        return this._rpcClient.makeRequest(USERORG_PATH, "setAllPrintScale", {
            orgID,
            value
        });
    }

    getSubmitterPrintScale(orgID: string): Observable<number> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getSubmitterPrintScale",
            {
                orgID
            }
        );
    }

    getEstimatedRecordingTimes(id: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getEstimatedRecordingTimes",
            id
        );
    }

    getRegisteredRecipientsForOrgs(orgIDs: string[]) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRegisteredRecipientsForOrgs",
            {
                orgIDs
            }
        );
    }

    getRegisteredRecipients(orgID: string): Observable<County[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRegisteredRecipients",
            {
                orgID
            }
        );
    }

    getRegisteredRecipientsByState(
        orgID: string,
        state: string
    ): Observable<County[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRegisteredRecipients",
            {
                orgID,
                state
            }
        );
    }

    getRegistrationSettingDefinitions(
        recipientID: string,
        submitterID: string
    ) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRegistrationSettingDefinitions",
            {
                recipientID,
                submitterID
            }
        );
    }

    setSubmissionFee(
        countyIDs: string[],
        submitterID: string,
        submissionFee: number
    ): Observable<void> {
        return this._rpcClient.makeRequest(USERORG_PATH, "setSubmissionFee", {
            countyIDs,
            submitterID,
            submissionFee
        });
    }

    setCustomerID(
        countyIDs: string[],
        submitterID: string,
        customerID: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(USERORG_PATH, "setCustomerID", {
            countyIDs,
            submitterID,
            customerID
        });
    }

    toggleCountyStatus(
        countyIDs: string[],
        submitterID: string,
        emailType: "standard" | "none" | "custom",
        customEmailText?: string
    ): Observable<ToggleCountyResponse> {
        const data: any = { countyIDs, submitterID, emailType };
        if (customEmailText) {
            data.customEmailString = customEmailText;
        }
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "toggleCountyStatus",
            data
        );
    }

    enableDisableSubmitters(
        recipientIDs: string[],
        submitterIDs: string[],
        enabled: boolean,
        emailType: "standard" | "none" | "custom",
        customEmail?: string,
        disableReason?: string,
        authorizedContact?: string
    ): Observable<any> {
        const data: any = { recipientIDs, submitterIDs, enabled, emailType };
        if (customEmail) {
            data.customEmail = customEmail;
        }
        if (disableReason) {
            data.disableReason = disableReason;
        }
        if (authorizedContact) {
            data.nameOfAuthorizationPerson = authorizedContact;
        }
        return this._rpcClient.makeRequest(
            "admin",
            "enableDisableSubmitters",
            data
        );
    }

    addOneSubmitterRecipientMessage(
        submitterID: string,
        recipientIDs: string[],
        message: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "addOneSubmitterRecipientMessage",
            {
                submitterID,
                recipientIDs,
                message
            }
        );
    }

    getAllSubmitterRecipientMessages(
        submitterID: string,
        recipientID: string
    ): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getAllSubmitterRecipientMessages",
            {
                submitterID,
                recipientID
            }
        );
    }

    removeAllSubmitterRecipientMessages(
        submitterID: string,
        recipientID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "removeAllSubmitterRecipientMessages",
            {
                submitterID,
                recipientID
            }
        );
    }

    getRecipientVendors(submitterID: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRecipientVendors",
            { submitterID }
        );
    }

    addVendorCounties(
        vendor: string,
        submitterID: string,
        customerID: string,
        erdsType: string
    ): Observable<AddVendorResponse[]> {
        return this._rpcClient.makeRequest(USERORG_PATH, "addVendorCounties", {
            vendor,
            customerID,
            submitterID,
            erdsType
        });
    }

    getRecipientSettings(submitterID: string, recipientID: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRecipientSettings",
            {
                submitterID,
                recipientID
            }
        );
    }

    getRecipientOrganizationSettings(recipientID: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRecipientOrganizationLite",
            {
                orgID: recipientID
            }
        );
    }

    setRecipientOrganizationSettings(recipientID: string, settings: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "setRecipientOrganizationLite",
            {
                orgID: recipientID,
                settings
            }
        );
    }

    saveRecipientClass(recipientID: string, recipientClass: string) {
        return this._rpcClient.makeRequest(USERORG_PATH, "saveRecipientClass", {
            orgID: recipientID,
            recipientClass
        });
    }

    saveVendor(recipientID: string, vendor: string) {
        return this._rpcClient.makeRequest(USERORG_PATH, "saveVendor", {
            orgID: recipientID,
            vendor
        });
    }

    getRecipientOrganizationConfig(recipientID: string, group: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRecipientOrganizationConfig",
            {
                recipientId: recipientID,
                group
            }
        );
    }

    setRecipientOrganizationConfig(recipientId: string, settings: any) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "setRecipientOrganizationConfig",
            {
                recipientId,
                settings
            }
        );
    }

    getRecipientPrintQueueSettings(recipientID: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "getRecipientPrintQueueSettings",
            {
                orgID: recipientID
            }
        );
    }

    setRecipientPrintPaperSize(recipientID: string, paperSize: string) {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "setRecipientPrintPaperSize",
            {
                orgID: recipientID,
                paperSize
            }
        );
    }

    getRecipientConfigOptions() {
        this._rpcClient.makeRequest(USERORG_PATH, "getRecipientConfigOptions");
    }

    resetSubmissionFee(
        submitterID: string,
        recipientID: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            USERORG_PATH,
            "resetSubmitterSubmissionFee",
            {
                submitterID,
                recipientID
            }
        );
    }

    downloadMOU(
        submitterID: string,
        recipientID: string,
        mouFileType: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "downloadMOU", {
            submitterID,
            recipientID,
            mouFileType
        });
    }

    removeMOU(
        submitterID: string,
        recipientID: string,
        mouFileType: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "removeMOU", {
            submitterID,
            recipientID,
            mouFileType
        });
    }

    downloadCountyAgreement(recipientID: string): Observable<any> {
        return this._rpcClient.makeRequest("admin", "downloadCountyAgreement", {
            recipientID
        });
    }

    saveCOIExpirationDate(
        submitterID: string,
        recipientID: string,
        mouFileType: string,
        expirationDate: Date
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "saveCOIExpirationDate", {
            submitterID,
            recipientID,
            mouFileType,
            expirationDate
        });
    }

    getUserCustomEmailContents(userID?: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getUserCustomEmailContents",
            {
                user: userID ?? null
            }
        );
    }

    getBankAccounts(recipientID: string): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getBankAccounts", {
            recipientID
        });
    }

    saveBankAccount(bankAccount: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "saveBankAccount", {
            bankAccountModel: bankAccount
        });
    }

    getBankAccount(bankAccountID: string): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getBankAccount", {
            bankAccountID
        });
    }

    getVendorSimulationProperties(): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getVendorSimulationProperties",
            {}
        );
    }

    createMOUPackage(
        submitterID: string,
        recipientID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "createMOUPackage", {
            submitterID,
            recipientID
        });
    }

    setDisabledByCounty(
        recipientIDs: string[],
        submitterIDs: string[]
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "setDisabledByCounty", {
            recipientIDs,
            submitterIDs
        });
    }
}

export type SubmitterCountyCountByState = {
    st: string; // State Abbreviation
    state: string; // State Name
    unregistered: number;
    registered: number;
    disabled: number;
    enabled: number;
};
