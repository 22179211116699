import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, take } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ConsumerPortalGuard implements CanActivate {
    constructor(private _http: HttpClient) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._http
            .get("/sf/submitter/consumerPortal/getConsumerPortalEnabled")
            .pipe(
                take(1),
                map((data: boolean) => data)
            );
    }
}
