import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "sf-tour-step",
    template: ""
})
export class TourStepComponent {
    @Input()
    title: string;
    @Input()
    text: string;
    @Input()
    attachTo: string;
    @Input()
    backAction: any; //Function
    @Input()
    backText?: string;
    @Input()
    nextAction: any; // Function
    @Input()
    nextText?: string;

    constructor() {}
}
