import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { RejectionSuggestion } from "../interfaces/rejection-suggestion";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class DocumentRejectionSuggestionService {
    constructor(private _rpcClientService: RpcClientService) {}

    getSuggestionForDocumentRejection(
        documentID: string,
        reason: string
    ): Observable<RejectionSuggestion> {
        return this._rpcClientService.makeRequest(
            "admin",
            "getSuggestionForDocumentRejection",
            {
                documentID: documentID,
                reason: reason
            }
        );
    }

    getSuggestionsForDocumentRejections(
        documentID: string,
        reasons: string[]
    ): Observable<RejectionSuggestion[]> {
        let suggestions: Observable<RejectionSuggestion>[] = [];
        reasons.forEach((reason) => {
            suggestions.push(
                this.getSuggestionForDocumentRejection(documentID, reason)
            );
        });

        return forkJoin(suggestions).pipe(
            map((suggestions) => {
                return suggestions.filter((suggestion) => {
                    return Boolean(suggestion);
                });
            })
        );
    }

    saveSuggestionRejectionFeedback(
        rejectionReasonID: string,
        suggestionID: number,
        feedbackType: string
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "admin",
            "saveSuggestionRejectionFeedback",
            {
                rejectionReasonID: rejectionReasonID,
                suggestionID: suggestionID,
                feedbackType: feedbackType
            }
        );
    }

    saveSuggestionRejectionFeedbackSeen(
        rejectionReasonID: string,
        suggestionID: number,
        seen: boolean
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "admin",
            "saveSuggestionRejectionFeedbackSeen",
            {
                rejectionReasonID: rejectionReasonID,
                suggestionID: suggestionID,
                seen: seen
            }
        );
    }
}
