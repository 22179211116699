import { Directive } from "@angular/core";
import { DynamicFormStore, InputComponent } from "@sf/dynamic-form-viewer";
import { CustomFormService } from "../../custom-form.service";
import { takeUntil } from "rxjs/operators";
import { merge, Observable, Subject } from "rxjs";
import { dayjs } from "@sf/common";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";

@Directive()
export abstract class InputAutoCompleteComponent extends InputComponent {
    typeAhead: (text$: Observable<string>) => Observable<any[]>;
    dateMatches: string[] = [];
    private _ngOnDestroy$ = new Subject<void>();
    protected _highlightLocation: {
        height: number;
        width: number;
        pageID: string;
        x: number;
        y: number;
    };

    focus$ = new Subject<string>();

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade);
    }

    ngOnInit() {
        super.ngOnInit();
        this._customFormService
            .getAutoCompleteUpdate$()
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe((autoCompleteSuggestions: any) => {
                let ocrMatches = autoCompleteSuggestions
                    ? autoCompleteSuggestions.OCR.ocrMatches
                    : null;
                if (ocrMatches) {
                    let extractedPath = this.field.fullPath;
                    if (extractedPath.indexOf("#") > -1) {
                        extractedPath = extractedPath.split("#")[0];
                    }
                    if (this.field.fullPath.indexOf("[]") > -1) {
                        let splitPath: string[] = extractedPath.split(".");
                        extractedPath = splitPath[0] + "." + splitPath[2];
                    }

                    if (ocrMatches[extractedPath]) {
                        this._highlightLocation =
                            ocrMatches[extractedPath].dataLocation;
                        this.typeAhead = (text$: Observable<string>) => {
                            let debouncedText$ = text$.pipe(
                                debounceTime(200),
                                distinctUntilChanged()
                            );
                            const inputFocus$ = this.focus$;
                            return merge(debouncedText$, inputFocus$).pipe(
                                map(
                                    this._suggestionMatch.bind(
                                        null,
                                        ocrMatches[extractedPath].valueList
                                    )
                                )
                            );
                        };
                        this.dateMatches = [];
                        for (let val of ocrMatches[extractedPath].valueList) {
                            if (dayjs(val).isValid()) {
                                let dateObj = dayjs(val);
                                this.dateMatches.push(
                                    dateObj.format("MM/DD/YYYY")
                                );
                            } else if (dayjs(val, "MM/DD/YYYY").isValid()) {
                                this.dateMatches.push(val);
                            }
                        }
                    }
                }

                if (!this.typeAhead) {
                    this.typeAhead = (text$: Observable<string>) => {
                        return text$.pipe(
                            debounceTime(200),
                            distinctUntilChanged(),
                            map(this._suggestionMatch.bind(null, []))
                        );
                    };
                }
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy$.next();
    }

    showHighlight() {
        if (this._highlightLocation) {
            const dpi = 300;
            const padding = 10;
            const x = this._highlightLocation.x * dpi - padding;
            const y = this._highlightLocation.y * dpi - padding;
            const width = this._highlightLocation.width * dpi + padding * 2;
            const height = this._highlightLocation.height * dpi + padding * 2;
            this._highlightService.setHighlight(
                this._highlightLocation.pageID,
                x,
                y,
                width,
                height
            );
        }
    }

    hideHighlight() {
        this._highlightService.removeHighlight();
    }

    private _suggestionMatch(parties: string[], text: string) {
        let _filter = (val: any) => {
            return val.toLowerCase().indexOf(text.toLowerCase()) > -1;
        };
        return !text ? parties : parties.filter(_filter);
    }
}
