<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async" [ngStyle]="columnLayout">
    <label class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left" tabindex="0">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <div *ngFor="let option of (options$ | async)" class="row">
            <div class="col-md-1">
                <input [id]="field.fullPath + '_' + option.option"
                       type="radio"
                       [name]="field.fullPath + '_' + control"
                       [formControl]="control"
                       [value]="option.option"
                       [attr.aria-required]="isRequired$ | async"
                />
            </div>
            <label
                class="radio-label col-md"
                [for]="field.fullPath + '_' + option.option"
                [ngClass]="{'invalid':errors !== null}">
                {{option.label}}
            </label>
        </div>
    </div>
</div>
