<div class="select-holder">
    <sf-select
        id="sf-organization-filter-selector"
        name="sf-organization-filter-selector"
        [class]="windowClass"
        [ngClass]="{'no-style-select-box': hideBorder}"
        selectorName="sf-organization-filter-selector"
        [placeholder]="placeholder"
        searchPlaceholder="Filter"
        [hasFilter]="true"
        [filterIcon]="['far', 'filter']"
        [filterDebounce]="300"
        [handleFilterExternally]="false"
        [isSingleSelect]="true"
        [selectedOption]="selectedOrgID"
        [options]="selectableItems"
        [hasClearButton]="canClearSelectedOrg"
        trackBy="id"
        labelBy="label"
        (filter)="getOrgs($event)"
        (select)="handleOrgSelection($event)"
        [required]="required"
    ></sf-select>
</div>
