import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ProfilePictureCompressorService {
    constructor() {}

    compress(file: any): Observable<any> {
        const scaleFactor: number = 2000000 / file.size;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Observable<any>((observer) => {
            reader.onload = (ev) => {
                const img = new Image();
                img.src = (ev.target as any).result;
                (img.onload = () => {
                    const elem = document.createElement("canvas");
                    elem.width = img.width * scaleFactor;
                    elem.height = img.height * scaleFactor;
                    const ctx = <CanvasRenderingContext2D>elem.getContext("2d");
                    ctx.drawImage(
                        img,
                        0,
                        0,
                        img.width * scaleFactor,
                        img.height * scaleFactor
                    );
                    ctx.canvas.toBlob(
                        (blob) => {
                            observer.next(
                                new File([blob], file.name, {
                                    type: "image/png",
                                    lastModified: Date.now()
                                })
                            );
                        },
                        "image/png",
                        1
                    );
                }),
                    (reader.onerror = (error) => observer.error(error));
            };
        });
    }
}
