<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div class="sf-shared-doc-status-dialog">
        <span [innerHTML]="statusMessage"></span>
        <ng-container *ngIf="hasErrors">
            <h4 class="border-top border-bottom py-2 mt-4 text-uppercase">
                Items That Require Attention
            </h4>
            <ng-container *ngFor="let document of documents; last as isLast" >
                <div
                    class="d-flex flex-row align-items-center"
                    [class]="{ 'border-bottom': !isLast, 'mb-2': !isLast }"
                >
                    <div class="flex-grow-1 text-break">
                        <h5 (click)="close()" class="m-0 p-0">
                            <a [href]="document.docViewerLink" class="sf-mask">{{document.name}}</a>
                        </h5>
                        <div class="mt-2" *ngIf="document.docType">
                            {{document.docType}}
                        </div>
                        <div class="mt-2">
                            <div *ngIf="!!document.validationErrors && document.validationErrors.length">
                                <ul class="text-alert">
                                    <li *ngFor="let error of document.validationErrors">{{error}}</li>
                                </ul>
                                <ul *ngIf="document.status === rejected && customRejectionInstructions?.length">
                                    <li *ngFor="let instruction of customRejectionInstructions" class="sf-mask">{{instruction}}</li>
                                </ul>
                            </div>
                            <div *ngIf="!!document.voidedEvents && document.voidedEvents.length">
                                <h5 class="m-0 p-0">Reasons for voiding</h5>
                                <ul>
                                    <li class="sf-mask" *ngFor="let event of document.voidedEvents">{{event}}</li>
                                </ul>
                            </div>
                            <div *ngIf="document.rejectingRecipient">
                                Rejected by {{document.rejectingRecipient.name}}
                                <span *ngIf="document.rejectingRecipient.phone"> - {{document.rejectingRecipient.phone}}</span>
                                <span *ngIf="document.rejectingRecipient.initials" class="sf-mask"> ({{document.rejectingRecipient.initials}})</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <sf-pill *ngIf="!useStatusIcons" [value]="document.status"></sf-pill>
                        <sf-status-icon
                                *ngIf="useStatusIcons"
                                [status]="document.status"
                                [isPackage]="false"
                        ></sf-status-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
