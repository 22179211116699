<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div class="mb-2">
        <span>This organization has been invited to the {{productName}} service.</span>
    </div>
    <div *ngIf="!names || !names.length">
        <div>Waiting for acceptance by any organization administrator.</div>
    </div>
    <div *ngIf="names && names.length == 1">
        <div>Waiting for acceptance by: </div>
        <div>{{names[0].firstName + ' ' + names[0].lastName + ' (' + names[0].userId + ') ' + names[0].email}}</div>
    </div>
    <div class="name-list" *ngIf="names && names.length > 1">
        <div>Waiting for acceptance by any of the following: </div>
        <ul class="selector-bottom">
            <li *ngFor="let name of names">
                <span>{{name.firstName + ' ' + name.lastName + ' (' + name.userId + ') ' + name.email}}</span>
            </li>
        </ul>
    </div>
    <div *ngIf="!!details" class="mt-3">
        <div><b><u>Invitation Details:</u></b></div>
        <table>
            <tr *ngFor="let detail of details">
                <td>{{detail.label}}</td>
                <td>{{detail.option}}</td>
            </tr>
        </table>
    </div>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
