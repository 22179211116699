import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { PageHeaderComponent } from "@sf/headers";
import { Subject, Subscription } from "rxjs";
import {
    ActivatedRoute,
    Data,
    NavigationExtras,
    Router
} from "@angular/router";
import {
    CopyTextService,
    EncryptionService,
    getAllRouteData,
    getLinkWithChangedParams,
    getParamsFromRoute,
    SelectableItem
} from "@sf/common";
import {
    CurrentUserService,
    LockoutService,
    User,
    UserOrgService,
    UserSubscriptionService
} from "@sf/userorg/common";
import { sampleTime, takeUntil } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-user-header-with-selector",
    templateUrl: "./user-header-with-selector.component.html",
    styleUrls: ["./user-header-with-selector.component.scss"]
})
export class UserHeaderWithSelectorComponent
    implements OnInit, OnDestroy, PageHeaderComponent
{
    /** PUBLIC **/
    public pageData: any;
    public selectedUser: User = null;
    public selectedUserID: string = null;
    public canEditUsersOnly: boolean = false;

    /** PRIVATE **/
    private _onDestroy: Subject<void> = new Subject<void>();
    private updater: Subscription = null;

    needToNavigate = false;
    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy ID"
        },
        {
            option: "copyName",
            label: "Copy Name"
        }
    ];

    constructor(
            private _currentUserService: CurrentUserService,
            private _route: ActivatedRoute,
            private _router: Router,
            private _encryptionService: EncryptionService,
            private _userorgService: UserOrgService,
            private _userorgLockoutService: LockoutService,
            private copyService: CopyTextService,
            private userSubscriptionService: UserSubscriptionService
    ) {}

    ngOnInit(): void {
        //subscribe to user unlock event
        this._userorgLockoutService.userUnlocked$.subscribe(
            (userUnlocked: boolean) => {
                if (userUnlocked) {
                    this.refreshUserStatus();
                }
            }
        );
        let params = getParamsFromRoute(this._route, ["userID"]);

        // get route data that could influence selection
        let routeData: Data = getAllRouteData(this._route);

        if (routeData.canEditUsersOnly) {
            this.canEditUsersOnly = routeData.canEditUsersOnly;
        }

        //let data = getAllRouteData(this._route);
        if (params.userID && params.userID !== "_") {
            // scheduleMicrotask.then(() => {
            //     // this.selected = {
            //     //     id: this._encryptionService.decodeUnscramble(params.userID)
            //     // };
            //     this.selectedUserID = this._encryptionService.decodeUnscramble(params.userID);
            // });

            this.selectedUserID = this._encryptionService.decodeUnscramble(
                params.userID
            );
            if (!this.selectedUser) {
                this._userorgService.getUser(this.selectedUserID)
                    .subscribe((user) => {
                        this._userorgService.buildUserStatus(user);
                        this.selectedUser = user;
                        this.setSelectedUser(this.selectedUser);
                    });
            }
        }

        this._currentUserService.currentUser$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((user) => {
                if (!user) {
                    return;
                }

                this.selectedUser = user;
                this.subscribeToChanges();

                this._userorgService.buildUserStatus(user);

                let encodedID: string = this._encryptionService.scrambleEncode(user.id);

                if (this.needToNavigate) {
                    let link = getLinkWithChangedParams(this._route, { encodedID }, true);
                    if (encodedID && !link.includes(encodedID)) {
                        //set param for user id explicitly
                        link[2] = encodedID;
                    }
                    let extras: NavigationExtras = {
                        relativeTo: this._route,
                        queryParamsHandling: "preserve"

                    };
                    this._router.navigate(link, extras);
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new organization
            this.updater.unsubscribe();
        }
        this.updater = this.userSubscriptionService.subscribeToUpdates(this.selectedUser.username)
               .pipe(
                       takeUntil(this._onDestroy),
                       sampleTime(1000)
               )
               .subscribe((msg: any) => {
                   this.setSelectedUser(this.selectedUser);
               });
    }

    public setSelectedUser(selectedUser: User) {
        this._currentUserService.updateCurrentUser(selectedUser);
        if (selectedUser) {
            this.selectedUser = selectedUser;
            this._userorgService.buildUserStatus(this.selectedUser);
            this.needToNavigate = true;
        } else {
            this.selectedUser = null;
        }
    }

    refreshUserStatus() {
        this.selectedUser.isLockedOut = false;
        this.selectedUser.attempts = 0;
        this._userorgService.buildUserStatus(this.selectedUser);
    }

    onStatusClick(): void {
        //if locked out, unlock; otherwise, go to roles for user
        if (this.selectedUser.isLockedOut) {
            this._userorgLockoutService.doUserUnlock(this.selectedUser, false).then((result) => {
                // nothing
            });
        } else {
            // we would need to know if submitter or lender or vendor here - but not needed
            /*
            let newURL: string =
                "/submitter/user/" +
                this._encryptionService.scrambleEncode(this.selectedUserID) +
                "/organizations";
            this._router.navigateByUrl(newURL);
            */
        }
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                event.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft;
            this.contextMenuY =
                event.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedUser) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(
                        this.selectedUser.name.first +
                            " " +
                            this.selectedUser.name.last
                    );
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedUser.id);
                    break;
            }
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }
}
