import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { SessionService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class IsSuperUserGuard implements CanLoad {
    constructor(private _session: SessionService, private _router: Router) {}
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
        const isSuperUser = this._session.isSuperUser();
        return isSuperUser || this._router.createUrlTree(["/"]);
    }
}
