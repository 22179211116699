export class FixedQueue<T> extends Array<T> {
    constructor(private _fixedSize: number, initialValues: T[] = []) {
        super();
        Object.setPrototypeOf(this, FixedQueue.prototype);
        super.push(...initialValues);
    }

    set fixedSize(fixedSize: number) {
        this._fixedSize = fixedSize;
    }

    get fixedSize(): number {
        return this._fixedSize;
    }

    get isFull(): boolean {
        return this._fixedSize === this.length;
    }

    push(...items: T[]): number {
        let number = super.push(...items);

        this._trimHead();

        return number;
    }

    splice(start: number, deleteCount?: number, ...items: T[]): T[] {
        let newItems: T[] = [];
        if (items.length > 0) {
            newItems = super.splice(start, deleteCount, ...items);
        } else if (items.length === 0) {
            newItems = super.splice(start, deleteCount);
        }

        this._trimHead();

        return newItems;
    }

    unshift(...items: T[]): number {
        let number = super.unshift(...items);

        this._trimTail();

        return number;
    }

    /**
     * Trim the queue down to the fixed size by removing items from the beginning of the array
     *
     * @private
     */
    private _trimHead() {
        // Check to see if any trimming needs to be performed.
        if (this.length <= this._fixedSize) {
            // No trimming, return out.
            return;
        }

        // Trim whatever is beyond the fixed size
        super.splice(0, this.length - this._fixedSize);
    }

    /**
     * Trim the queue down to the fixed size by removing items from the end of the array
     *
     * @private
     */
    private _trimTail() {
        // Check to see if any trimming needs to be performed.
        if (this.length <= this._fixedSize) {
            // No trimming, return out.
            return;
        }

        super.splice(this._fixedSize, this.length - this._fixedSize);
    }
}
