<sf-select
    id="sf-user-filter-selector"
    name="sf-user-filter-selector"
    [class]="windowClass"
    [ngClass]="{'no-style-select-box': hideBorder}"
    selectorName="sf-user-filter-selector"
    [placeholder]="placeholder"
    searchPlaceholder="Filter"
    [hasFilter]="true"
    [filterIcon]="['far', 'filter']"
    [filterDebounce]="300"
    [handleFilterExternally]="false"
    [isSingleSelect]="true"
    [selectedOption]="selectedUserID"
    [options]="selectableItems"
    [hasClearButton]="canClearSelectedUser"
    trackBy="id"
    labelBy="label"
    (filter)="getUsers($event)"
    (select)="handleUserSelection($event)"
    [required]="required"
    [isDisabled]="isDisabled"
></sf-select>
