import { Injectable } from "@angular/core";
import {
    PersistentDataStack,
    PersistentDataStackService,
    RpcClientService
} from "@sf/common";
import { RecentUsersService } from "@sf/userorg/common";
import { RecentOrganizationsService } from "@sf/userorg/admin-common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class GlobalSearchService {
    private _searchStack: PersistentDataStack;

    constructor(
        private persistentDataStackService: PersistentDataStackService,
        private recentUsersService: RecentUsersService,
        private recentOrganizationsService: RecentOrganizationsService,
        private rpcService: RpcClientService
    ) {
        this._searchStack = this.persistentDataStackService.createNewStack(
            "sfGlobalSearchItemsStack",
            9
        );
    }

    getRecentGlobalSearchItems(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            let items: string[] = [];
            this._searchStack.last().then((entries) => {
                //only getting packages from recent global search items
                entries.forEach((entry) => {
                    let item = JSON.parse(entry);
                    if (item.objectType == "package") items.push(item);
                });

                //get users
                this.recentUsersService
                    .getPastRecentUserIDs()
                    .subscribe((ids) => {
                        this.getUniversalUsersData(ids).subscribe((users) => {
                            users.forEach((user) => {
                                items.push(user);
                            });

                            //get orgs
                            this.recentOrganizationsService
                                .getPastRecentOrganizationIDs()
                                .subscribe((ids) => {
                                    this.getUniversalOrganizationsData(
                                        ids
                                    ).subscribe((orgs) => {
                                        orgs.forEach((org) => {
                                            items.push(org);
                                        });
                                        resolve(items);
                                    });
                                });
                        });
                    });
            });
        });
    }

    pushRecentGlobalSearchItem(item: any) {
        //the object pushed contains a $$hashKey that is unique, so when it saves it won't find a match
        //and keep adding the "same" object into the recent item list - so removing it will allow that
        //to function properly
        if (item.hasOwnProperty("$$hashKey")) delete item.$$hashKey;

        this._searchStack.push(JSON.stringify(item));
    }

    searchEverything(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest("search", "searchEverything", {
            query: searchString,
            limit: searchLimit
        });
    }

    searchPackagesExpanded(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest("search", "searchPackagesExpanded", {
            query: searchString,
            limit: searchLimit
        });
    }

    searchUsersExpanded(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest("search", "searchUsersExpanded", {
            query: searchString,
            limit: searchLimit
        });
    }

    searchOrganizationsExpanded(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest(
            "search",
            "searchOrganizationsExpanded",
            {
                query: searchString,
                limit: searchLimit
            }
        );
    }

    searchESignEventsExpanded(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest(
            "search",
            "searchESignEventsExpanded",
            {
                query: searchString,
                limit: searchLimit
            }
        );
    }

    searchCAPCLoansExpanded(searchString: string, searchLimit: number) {
        return this.rpcService.makeRequest(
            "search",
            "searchCAPCLoansExpanded",
            {
                query: searchString,
                limit: searchLimit
            }
        );
    }

    private getUniversalUsersData(userIDs: string[]): Observable<string[]> {
        return this.rpcService.makeRequest("search", "getUniversalUsersData", {
            userIDs: userIDs
        });
    }

    private getUniversalOrganizationsData(
        orgIDs: string[]
    ): Observable<string[]> {
        return this.rpcService.makeRequest(
            "search",
            "getUniversalOrganizationsData",
            { orgIDs: orgIDs }
        );
    }
}
