<div class="header" (contextmenu)="showContextMenu($event)">
    <sf-context-menu [x]="contextMenuX" [y]="contextMenuY" [shown]="contextMenuVisible"
            [options]="contextMenuItems" (select)="handleContextMenuItem($event)"></sf-context-menu>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <div class="d-flex flex-column flex-md-row">
                <div class="header-selector">
                    <div class="title" *ngIf="pageData.pageTitle">
                        <h1>{{pageData.pageTitle}}</h1>
                    </div>
                    <div class="d-flex flex-column flex-md-row select-holder">
                        <sf-permission-search-selector
                                [hideBorder]="true"
                                [selectedPermissionID]="selectedPermissionID"
                                [autoInitialSelect]="true"
                                (select)="setSelectedPermission($event)"
                        ></sf-permission-search-selector>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
