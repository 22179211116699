export enum SocketRequestOperation {
    Subscribe = "subscribe",
    Resubscribe = "resubscribe",
    Unsubscribe = "unsubscribe",
    Heartbeat = "heartbeat",
    Close = "close"
}

export enum SocketOperation {
    Authorized = "authorized",
    Unauthorized = "unauthorized",
    Response = "response",
    Heartbeat = "heartbeat",
    Event = "event",
    Update = "update",
    Close = "close",
    Disconnect = "disconnect"
}

export interface SocketMessage {
    operation: SocketOperation;
    success: boolean;
    requestId: number;
    subscriptionId?: string;
    sequence?: number;
    timestamp?: Date;
    data?: any;
    errorMessage?: string;
}

export interface AuthMessage extends SocketMessage {
    operation: SocketOperation.Authorized | SocketOperation.Unauthorized;
    timestamp: Date;
}

export interface DisconnectMessage extends SocketMessage {
    operation: SocketOperation.Disconnect;
}

export interface HeartbeatMessage extends SocketMessage {
    operation: SocketOperation.Heartbeat;
    timestamp: Date;
}

export interface SubscriptionMessage extends SocketMessage {
    operation: SocketOperation.Update | SocketOperation.Event;
    subscriptionId: string;
    sequence: number;
    timestamp: Date;
    data: any;
}

export interface ErrorMessage extends SocketMessage {
    operation: SocketOperation.Response;
    success: false;
    errorMessage: string;
}

export interface ResponseMessage extends SocketMessage {
    operation: SocketOperation.Response;
    success: true;
    requestId: number;
    subscriptionId?: string;
}

export interface SocketRequest {
    operation: SocketRequestOperation;
    product: string;
    source: string;
    subscriptionId?: string;
    requestId?: number;
    data?: any;
    includeFields?: string[];
}
