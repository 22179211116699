import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-tabbed-modal-header",
    templateUrl: "./tabbed-modal-header.component.html",
    styleUrls: ["./tabbed-modal-header.component.scss"]
})
export class TabbedModalHeaderComponent {
    @Input()
    title: string;
    @Input()
    currentStep: number;
    @Input()
    stepCount: number;
    @Input()
    hideSteps: boolean;

    constructor(public modal: NgbActiveModal) {}
}
