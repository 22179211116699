
<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <sf-select
            placeholder="Select a State..."
            [options]="states"
            labelBy="name"
            trackBy="abbrev"
            [additionalPropertiesToFilter]="['abbrev']"
            [hasClearButton]="true"
            [formControl]="control"
            [showSelectedToggle]="false"
            [filterDebounce]="50"
            [hasFilter]="true"
            [isSingleSelect]="true"
        ></sf-select>
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>
