import { Directive, TemplateRef } from "@angular/core";

@Directive({
    selector: "[sfGlobalSearchResult]"
})
export class GlobalSearchResultDirective {
    readonly templateRef = this._templateRef;

    constructor(private _templateRef: TemplateRef<any>) {}
}
