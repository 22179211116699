import { ComponentRef, Injectable } from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { SpinnerComponent } from "./spinner.component";

@Injectable({
    providedIn: "root"
})
export class SpinnerService {
    private _timesCalled = 0;
    private _overlayRef: OverlayRef = null;
    private _componentRef: ComponentRef<SpinnerComponent> = null;

    constructor(private _overlay: Overlay) {}

    startSpinner() {
        if (this._timesCalled === 0) {
            this._overlayRef = this._overlay.create();

            const portal = new ComponentPortal(SpinnerComponent);
            this._componentRef = this._overlayRef.attach(portal);
        }

        this._timesCalled += 1;
    }

    stopSpinner(force?: boolean) {
        if (this._timesCalled > 0) {
            this._timesCalled -= 1;
        }

        if (this._timesCalled === 0 || force) {
            if (this._overlayRef) {
                this._overlayRef.detach();
            }
            this._timesCalled = 0;
        }
    }

    isActive(): boolean {
        return this._timesCalled > 0;
    }

    setMessage(message: string) {
        if (!this.isActive()) {
            return;
        }
        this._componentRef.instance.message = message;
    }

    clearMessage() {
        this._componentRef.instance.message = null;
    }

    setSpinnerTimesCalled(newTimesCalled: number) {
        this._timesCalled = newTimesCalled;
    }
}
