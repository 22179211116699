import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { UiRecipient } from "../../../../interfaces/ui-recipient";

@Component({
    selector: "sf-recipient-registration-dialog",
    templateUrl: "./recipient-registration-dialog.component.html",
    styleUrls: ["./recipient-registration-dialog.component.scss"]
})
export class RecipientRegistrationDialogComponent implements OnInit {
    @Input() recipient: UiRecipient;
    @Input() userHasRegistrationPermission: boolean;

    title: string;
    primary: ModalButton;
    secondary: ModalButton;

    constructor() {
        this.title = "Recipient requires registration";
        this.primary = {
            text: "Go to registration page",
            disabled: false
        };
        this.secondary = { text: "Cancel", responseValue: -1 };
    }

    ngOnInit() {}
}
