import {
    ConditionUpdateType,
    DynamicFormState,
    FieldState,
    FieldStateWrapper,
    FieldViewState,
    FormViewerValidator,
    ROOT_FIELD_KEY,
    ViewState
} from "../interfaces";
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormControl,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import { FieldFormState, FormState } from "../interfaces/dynamic-form-state";
import { FieldType } from "../enums";

export function findControl(
    control: AbstractControl,
    path: Array<string | number> | string,
    viewState: ViewState,
    delimiter: string
) {
    if (path == null) return null;

    if (path === ROOT_FIELD_KEY) return control;

    if (!Array.isArray(path)) {
        path = path.split(delimiter);
    }
    if (Array.isArray(path) && path.length === 0) return null;

    // Not using Array.reduce here due to a Chrome 80 bug
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
    let controlToFind: AbstractControl | null = control;
    let currentName: string = null;
    path.forEach((name: string | number) => {
        if (controlToFind instanceof FormGroup) {
            controlToFind = controlToFind.controls.hasOwnProperty(
                name as string
            )
                ? controlToFind.controls[name]
                : null;
        } else if (controlToFind instanceof FormArray) {
            let index = parseInt(name as string);
            if (isNaN(index)) {
                index = viewState[currentName].arrayFields.indexOf(
                    currentName + "." + name
                );
            }
            controlToFind = controlToFind.at(<number>index) || null;
        } else {
            controlToFind = null;
        }

        if (!currentName) {
            currentName = "" + name;
        } else {
            currentName += "." + name;
        }
    });
    return controlToFind;
}

export function buildFormFromRoot(
    rootField: string,
    fieldState: FieldStateWrapper,
    viewState: ViewState,
    formState: FormState
): FormGroup {
    const baseFormGroup = new FormGroup({});
    const rootView = viewState[rootField] as FieldViewState;

    for (let childFullPath of Object.keys(rootView.visibleFieldsMap)) {
        const childField = fieldState[childFullPath] as FieldState;
        baseFormGroup.addControl(
            childField.path,
            buildFormControlFromField(
                childField,
                fieldState,
                viewState,
                formState
            )
        );
    }

    return baseFormGroup;
}

export function buildFormControlFromField(
    field: FieldState,
    fieldState: FieldStateWrapper,
    viewState: ViewState,
    formState: FormState
): AbstractControl {
    let control: AbstractControl = null;

    if (field.type === FieldType.GROUP) {
        control = buildGroupControlFromField(
            field,
            fieldState,
            viewState,
            formState
        );
    } else if (field.type === FieldType.ARRAY) {
        control = buildArrayControlFromField(
            field,
            fieldState,
            viewState,
            formState
        );
    } else {
        // const controlType = getDefaultControlFromType(field.type);
        const [validators, asyncValidators] = getValidatorsForField(
            formState[field.fullPath]
        );
        control = new FormControl(
            {
                value: field.value || field.defaults.value,
                disabled: formState[field.fullPath].disabled
            },
            validators,
            asyncValidators
        );
    }

    return control;
}

export function buildGroupControlFromField(
    field: FieldState,
    fieldState: FieldStateWrapper,
    viewState: ViewState,
    formState: FormState
): FormGroup {
    const [validators, asyncValidators] = getValidatorsForField(
        formState[field.fullPath]
    );
    const formGroup = new FormGroup({}, validators, asyncValidators);
    const groupView = viewState[field.fullPath];

    for (let childFullPath of Object.keys(groupView.visibleFieldsMap)) {
        const childField = fieldState[childFullPath] as FieldState;

        let control = buildFormControlFromField(
            childField,
            fieldState,
            viewState,
            formState
        );
        formGroup.addControl(childField.path, control);
    }

    return formGroup;
}

export function getValidatorsForField(
    fieldState: FieldFormState
): [ValidatorFn[], AsyncValidatorFn[]] {
    if (!fieldState) {
        return [[], []];
    }
    return parseValidators(fieldState.validators);
}

export function parseValidators(
    validators: FormViewerValidator[]
): [ValidatorFn[], AsyncValidatorFn[]] {
    let validatorFns: ValidatorFn[] = [];
    let asyncValidatorFns: AsyncValidatorFn[] = [];
    const existing: { [identifier: string]: boolean } = {};
    for (const validator of validators) {
        if (existing[validator.identifier]) {
            continue;
        }
        if (validator.type === "sync") {
            validatorFns = validatorFns.concat(validator.validators);
        } else if (validator.type === "async") {
            asyncValidatorFns = asyncValidatorFns.concat(
                validator.asyncValidators
            );
        }
        existing[validator.identifier] = true;
    }

    return [validatorFns, asyncValidatorFns];
}

export function applyValidatorsToControl(
    control: AbstractControl,
    validatorFns: ValidatorFn[],
    asyncValidatorFns: AsyncValidatorFn[]
) {
    if (validatorFns.length > 0) {
        control.setValidators(validatorFns);
    } else {
        control.clearValidators();
    }
    if (asyncValidatorFns.length > 0) {
        control.setAsyncValidators(asyncValidatorFns);
    } else {
        control.clearAsyncValidators();
    }
    control.updateValueAndValidity();
}

export function buildArrayControlFromField(
    field: FieldState,
    fieldState: FieldStateWrapper,
    viewState: ViewState,
    formState: FormState
): FormArray {
    const [validators, asyncValidators] = getValidatorsForField(
        formState[field.fullPath]
    );
    const formArray = new FormArray([], validators, asyncValidators);
    const fieldView = viewState[field.fullPath];

    for (let childFullPath of Object.keys(fieldView.visibleFieldsMap)) {
        // for (let childFullPath of fieldView.arrayFields) {
        const childField = fieldState[childFullPath] as FieldState;
        let control = buildFormControlFromField(
            childField,
            fieldState,
            viewState,
            formState
        );
        formArray.push(control);
    }

    return formArray;
}
