import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "@sf/userorg/common";

@Component({
    selector: "sf-unarchive-user-dialog",
    templateUrl: "./unarchive-user-dialog.component.html",
    styleUrls: ["./unarchive-user-dialog.component.scss"]
})
export class UnarchiveUserDialogComponent implements OnInit {
    @Input() user: User;
    @Input() emailShared: boolean;

    displayName: string;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Confirm",
            disabled: false,
            callback: this.submit.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        let name = "";
        if (this.user.name) {
            name += this.user.name.first;
            name += " ";
            name += this.user.name.middle;
            name += " ";
            name += this.user.name.last;
            name += " (";
            name += this.user.id;
            name += ")";
        }
        this.displayName = name;
    }

    submit() {
        this.activeModal.close(true);
    }

    dismiss() {
        this.activeModal.close(false);
    }
}
