import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "../../interfaces";

@Component({
    selector: "sf-ie-not-supported-dialog",
    templateUrl: "./ie-not-supported-dialog.component.html",
    styleUrls: ["./ie-not-supported-dialog.component.scss"]
})
export class IeNotSupportedDialogComponent implements OnInit {
    primary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "I understand",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
    }

    doPrimary() {
        this.activeModal.close(true);
    }

    goChrome() {
        window.location.href = "https://www.google.com/chrome";
    }

    goEdge() {
        window.location.href = "https://www.microsoft.com/en-us/edge";
    }
}
