import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { User } from "../interfaces/user.interface";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class CurrentUserService {
    private _currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

    public currentUser$ = this._currentUser.pipe(
        distinctUntilChanged((newUser: User, oldUser: User) => {
            // don't send notice unless it's a new user, or name changed, or status changed
            return !this.userChanged(newUser, oldUser);
        }),
        shareReplay(1)
    );

    constructor() {}

    getCurrentUser(): User | null {
        return this._currentUser.getValue();
    }

    // Convenience method for ID
    getCurrentUserID(): string {
        let user = this._currentUser.getValue();
        if (!user) {
            return null;
        }
        return user.id;
    }

    updateCurrentUser(user: User): void {
        let currentUserNow = this.getCurrentUser();
        if (this.userChanged(currentUserNow, user)) {
            if (user && !user.name) {
                log.error("Invalid call to updateCurrentUser with partial user");
            }
            this._currentUser.next(user);
        }
    }

    userChanged(currentUser: User, newUser: User): boolean {
        if (!currentUser && !newUser) {
            // both null
            return false;
        }
        if (!currentUser || !newUser) {
            // one is null
            return true;
        }
        if (currentUser.id !== newUser.id) {
            // different id means different user
            return true;
        }
        if (currentUser.name && newUser.name) {
            if (currentUser.name.first != newUser.name.first) {
                return true;
            }
            if (currentUser.name.last != newUser.name.last) {
                return true;
            }
        } else if (newUser.name) {
            return true;    // adding name
        }
        if (currentUser.status && newUser.status) {
            if (currentUser.status != newUser.status) {
                return true;
            }
        } else if (newUser.status) {
            return true;    // adding status
        }
        return false;
    }

    // ------------------ start of hokey 'save' stuff -----------------

    private _saveCurrentUser: Subject<void> = new Subject();

    saveCurrentUser(): void {
        this._saveCurrentUser.next();
    }

    getSaveUser(): Observable<void> {
        return this._saveCurrentUser.asObservable();
    }

    // ------------------ end of 'save' stuff -----------------
}
