import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class LoginService {
    LOGIN_PATH = "/sf/userorg/login/";

    constructor(private _http: HttpClient) {}

    logout() {
        return this.makeLoginRequest("logout");
    }

    getTermsOfUseRevisionDate() {
        return this.makeLoginRequest("getTermsOfUseRevisionDate");
    }

    getRememberedUsername() {
        return this.makeLoginRequest("getRememberedUsername");
    }

    login(
        username: string,
        password: string,
        rememberUsername: boolean,
        tokenID: string,
        redirectParam: string
    ) {
        return this.makeLoginRequest("login", {
            username: username,
            password: password,
            rememberUsername: rememberUsername,
            tokenID: tokenID,
            redirectParam: redirectParam
        });
    }

    sendUsername(email: string) {
        return this.makeLoginRequest("sendUsername", {
            email: email
        });
    }

    sendPassword(username: string, email: string) {
        return this.makeLoginRequest("sendPassword", {
            username: username,
            email: email
        });
    }

    getChangePasswordValues(fpid: string) {
        return this.makeLoginRequest("getChangePasswordValues", {
            fpid: fpid
        });
    }

    sendSecondFactorEmail() {
        return this.makeLoginRequest("sendSecondFactorEmail", {});
    }

    sendSecondFactorSMS() {
        return this.makeLoginRequest("sendSecondFactorSMS", {});
    }

    sendSecondFactorVoice() {
        return this.makeLoginRequest("sendSecondFactorVoice", {});
    }

    secondFactorCanMakeVoiceCalls() {
        return this.makeLoginRequest("voiceCallSupported", {});
    }

    secondFactorLogin(code: string, channel: string) {
        return this.makeLoginRequest("secondFactorLogin", {
            code: code,
            channel: channel
        });
    }

    isValidSecondFactorCodeOnFile() {
        return this.makeLoginRequest("isValidSecondFactorCodeOnFile", {});
    }

    sendDCPreflight(url: string) {
        return this._http.get(url);
    }

    saveDCPreflight(success: boolean, type: string, notes: string) {
        return this.makeLoginRequest("saveDCPreflight", {success: success, type: type, notes: notes});
    }

    validateSecurityAnswers(
        fpid: string,
        q0ID: string,
        answer0: string,
        q1ID: string,
        answer1: string,
        q2ID: string,
        answer2: string
    ) {
        return this.makeLoginRequest("validateSecurityAnswers", {
            fpid: fpid,
            question0ID: q0ID,
            answer0: answer0,
            question1ID: q1ID,
            answer1: answer1,
            question2ID: q2ID,
            answer2: answer2
        });
    }

    changePasswordWithSecurityQuestions(
        fpid: string,
        q0ID: string,
        answer0: string,
        q1ID: string,
        answer1: string,
        q2ID: string,
        answer2: string,
        username: string,
        password: string
    ) {
        return this.makeLoginRequest("changePasswordWithSecurityQuestions", {
            fpid: fpid,
            question0ID: q0ID,
            answer0: answer0,
            question1ID: q1ID,
            answer1: answer1,
            question2ID: q2ID,
            answer2: answer2,
            username: username,
            password: password
        });
    }

    changePassword(fpid: string, username: string, password: string) {
        return this.makeLoginRequest("changePassword", {
            fpid: fpid,
            username: username,
            password: password
        });
    }

    hasMobilePhone() {
        return this.makeLoginRequest("hasMobilePhone", { });
    }

    passwordIncludesExtendedChar(pwd: string): boolean {
        // these are the illegal chars
        if (pwd.includes("$") ||
                pwd.includes("&") ||
                pwd.includes("+") ||
                pwd.includes(",") ||
                pwd.includes("/") ||
                pwd.includes(":") ||
                pwd.includes(";") ||
                pwd.includes("=") ||
                pwd.includes("?") ||
                pwd.includes("@")) {
            return true;
        }
        return false;
    }

    private makeLoginRequest(method: string, data?: any): Observable<any> {
        if (!data) {
            data = {};
        }
        return this._http.post(this.LOGIN_PATH + method, data);
    }
}
