import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {
    FilterPanelPositionStrategy,
    FilterPanelProperties,
    FilterPanelSortOption
} from "./filter-panel-properties";
import { FilterPanelOverlayService } from "./filter-panel-overlay.service";
import { FilterPanelOverlayRef } from "./filter-panel-overlay-ref";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CdkPortal } from "@angular/cdk/portal";
import { takeUntil } from "rxjs/operators";
import { DocumentRefService } from "../../services";
import { ViewInfo } from "../view-manager";

export interface ActiveFilterCount {
    count: number;
}

@Component({
    selector: "sf-filter-panel",
    templateUrl: "./filter-panel.component.html",
    styleUrls: ["./filter-panel.component.scss"]
})
export class FilterPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    // Pass an object, because ngIf will resolve a 0 as falsey in an async pipe
    @Input() activeFilterCount: Observable<ActiveFilterCount>;
    @Input() activeFilterDescription: Observable<string>;
    @Input() filterChanged$: Observable<ViewInfo>;
    @Input() panelProperties: FilterPanelProperties;
    @Input() viewsKey: string;
    @Input() viewSelected$: Subject<ViewInfo>;

    // API Calls
    @Input() flashFilterIcon: Observable<void>;
    @Input() togglePanel: Observable<void>;

    @Output() apply: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() clear?: EventEmitter<any> = new EventEmitter<any>();
    @Output() sort?: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("filterPanelOpener")
    filterPanelOpener: HTMLSpanElement;

    @ViewChild(CdkPortal) portal: CdkPortal;

    private _dialogRef: FilterPanelOverlayRef;
    private _allowClickAway: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    private _opened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private _destroy$: Subject<void> = new Subject<void>();

    public open = false;
    public opened$: Observable<boolean> = this._opened.asObservable();
    public clearHover = false;
    public pulse = true;
    public sortDescription: string;
    public sortIcon: any = {
        "-1": "fa-rotate-90",
        "1": "custom-fa-flip-rotate"
    };

    constructor(
        private _filterPanelOverlayService: FilterPanelOverlayService,
        private _documentRef: DocumentRefService
    ) {}

    ngOnInit() {
        if (this.panelProperties.currentSort) {
            this.sortDescription =
                "Sort by " + this.panelProperties.currentSort.title;
        }

        this._documentRef.nativeDocument
            .querySelector("body")
            .classList.add("sf-filter-panel-body");
    }

    ngAfterViewInit(): void {
        this.flashFilterIcon
            .pipe(takeUntil(this._destroy$))
            .subscribe((_) => this._flashFilterIcon());

        this.togglePanel.pipe(takeUntil(this._destroy$)).subscribe((_) => {
            this.toggleFilterPanel();
        });

        this._flashFilterIcon();

        this._filterPanelOverlayService.backdropClick$
            .pipe(takeUntil(this._destroy$))
            .subscribe((_) => {
                this.clickAway();
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();

        this._documentRef.nativeDocument
            .querySelector("body")
            .classList.remove("sf-filter-panel-body");
    }

    clickAway() {
        if (
            this._allowClickAway.getValue() &&
            !this.panelProperties.blockClickAway
        ) {
            this.doCancel();
        }
    }

    doApply() {
        this._dialogRef.close();
        this._allowClickAway.next(false);
        this.open = false;
        this._opened.next(this.open);
        this.apply.emit();
        this._flashFilterIcon();
        this._dialogRef = null;
    }

    doCancel() {
        this._allowClickAway.next(false);

        this._dialogRef.close();
        this.open = false;
        this._opened.next(this.open);
        this.cancel.emit();
        this._dialogRef = null;
    }

    doClear(apply?: boolean) {
        this.clear.emit({ apply: apply });
    }

    doSort(option: FilterPanelSortOption) {
        let currentSort = option;
        this.sort.emit({ option: option });
        this.sortDescription = "Sort by " + currentSort.title;
    }

    toggleFilterPanel() {
        if (this._dialogRef) {
            this.doCancel();
        } else {
            let positionStrategy: FilterPanelPositionStrategy =
                this.panelProperties.positionStrategy ||
                FilterPanelPositionStrategy.GLOBAL;
            this._dialogRef = this._filterPanelOverlayService.open(
                this.portal,
                {
                    origin: this.filterPanelOpener,
                    positionStrategy: positionStrategy
                }
            );

            if (this._dialogRef) {
                this.open = true;
                this._opened.next(this.open);

                // Allow click off to close panel
                setTimeout(() => {
                    this._allowClickAway.next(true);
                }, 250);
            }
        }
    }

    private _flashFilterIcon() {
        setTimeout(() => {
            this.activeFilterCount
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => {
                    this.pulse = true;
                    setTimeout(() => {
                        this.pulse = false;
                    }, 4000);
                });
        }, 250);
    }
}
