<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label class="col-md-2" [for]="field.id + '_custom_party'" >{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}"  placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <input *ngIf="!loadingTypeahead"
            #partyNameInput
            class="form-control"
            type="text"
            [id]="field.id + '_custom_party'"
            [autocomplete]="field.id + '_custom_party'"
            (click)="focus$.next($any($event).target.value)"
            (focus)="focus$.next($any($event).target.value)"
            [focusFirst]="false"
            [formControl]="control"
            [ngbTypeahead]="typeAhead"
            (selectItem)="onSelection($event)"
        />
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>
