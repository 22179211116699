<sf-modal-header title="Credit Card Payment Failed"></sf-modal-header>
<div *ngIf="!isFailedLicense" class="modal-body sf-unmask">
    <span>The ${{feeTotal}} payment failed while attempting to use the card <strong class="sf-mask">{{creditCardLabel}}</strong>.</span>
    <br>
    <br>
    Please verify your card information is correct or visit Package Details to use a different payment account.
    <span *ngIf="hasManageAccountPermission">You can also add a new payment account on the Manage Payment Accounts page.</span>
    <span *ngIf="!hasManageAccountPermission">An account administrator can also add a new payment account on the Manage Payment Accounts page.</span>
    <br>
    <br>
<!--    TODO: Uncomment as part of 8939-->
    <strong class="red-text">Failure to approve this payment will result in service suspension.</strong>
<!--    <strong class="red-text">Failure to update payment account information will result in service suspension.</strong>-->
</div>
<div *ngIf="isFailedLicense" class="modal-body sf-unmask">
    <span>The ${{feeTotal}} payment failed while attempting to use the card <strong class="sf-mask">{{creditCardLabel}}</strong>.</span>
    <br>
    <br>
    Please verify your card information is correct.
    <span *ngIf="hasManageAccountPermission">You can also add a new payment account on the Manage Payment Accounts page.</span>
    <span *ngIf="!hasManageAccountPermission">An account administrator can also add a new payment account on the Manage Payment Accounts page.</span>
    <br>
    <br>
    <!--    TODO: Uncomment as part of 8939-->
    <strong class="red-text">Failure to resolve this payment issue will result in service suspension.</strong>
    <!--    <strong class="red-text">Failure to update payment account information will result in service suspension.</strong>-->
</div>
<!--Uncomment as part of PS-8939-->
<sf-modal-footer [primary]="primary" [secondary]="secondary" [tertiary]="tertiary"></sf-modal-footer>
<!--<sf-modal-footer [primary]="primary" [tertiary]="tertiary"></sf-modal-footer>-->

<!--    Uncomment if we want to add links to the package details and manage payment accounts page and replace lines 6-8-->
<!--    <span>Please verify your card information is correct and retry payment or visit-->
<!--        <button class="btn btn-link sf-btn-link dialog-btn-link"-->
<!--                style="text-decoration: none"-->
<!--                (click)="viewPackageDetails()"-->
<!--                (mousedown)="handleMiddleClick($event, true)"-->
<!--        >Package Details</button>-->
<!--        to use a different payment account. You can also add a new payment account on the-->
<!--        <button class="btn btn-link sf-btn-link dialog-btn-link"-->
<!--                style="text-decoration: none"-->
<!--                (click)="goToManageAccounts()"-->
<!--                (mousedown)="handleMiddleClick($event, false)"-->
<!--        >Manage Payment Accounts</button>-->
<!--        page.</span>-->
