<sf-modal-header title="Archive Organization"></sf-modal-header>
<div class="modal-body">
    <form>
        <div class="extra-bottom">
            <span>Are you sure you want to archive this organization?</span>
        </div>
        <div class="extra-bottom">
            <b>{{displayName}}</b>
        </div>
        <div class="extra-bottom">
            <span>Archived organizations are not deleted, but they will not appear in search results.</span>
        </div>
        <div class="extra-bottom">
            <span>Any users in the organization will have their roles removed from this organization.</span>
        </div>

        <div class="extra-bottom">
            <span>Archive Reason</span>
            <sf-select name="archive-reason"
                [hasFilter]="false"
                trackBy="id"
                titleBy="label"
                [options]="archivedReasons"
                [isSingleSelect]="true"
                placeholder="Select reason..."
                (select)="handleSelect($event)"
                [required]="true">
            </sf-select>
        </div>
        <div>
            <span>Additional Details</span>
            <textarea name="details" id="details" [(ngModel)]="details" [required]="detailsRequired"
                rows="3" class="text-area" aria-label="Additional Details">
                </textarea>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
