<div class="background">
    <div class="header">
        <a href="https://www.simplifile.com/">
            <img class="logo-img" src="images/sf-logo-white.png" alt="Simplifile Logo" alt=""/>
        </a>
    </div>
    <div [ngSwitch]="status">
        <div class="body" *ngSwitchCase="'SUCCESS'">
            <div>
                <p>
                    Simplifile User
                    <b>{{ pkgData.submitterUserName }}</b>
                    of
                    <b>{{ pkgData.submitterName }}</b>
                    has invited you to download documents associated with a
                    package recorded through Simplifile labeled
                    <b>{{ pkgData.packageName }}</b
                    >. Please do not close this page before downloading all
                    desired files as the one-use link included in the email is
                    now disabled.
                </p>
            </div>
            <ul class="fields">
                <li>
                    <span class="fieldName">Package Name</span
                    ><span class="fieldValue">{{ pkgData.packageName }}</span>
                </li>
                <li>
                    <span class="fieldName">Submitter Name</span
                    ><span class="fieldValue">{{ pkgData.submitterName }}</span>
                </li>
                <li>
                    <span class="fieldName">Recipient</span
                    ><span class="fieldValue">{{ pkgData.recipientName }}</span>
                </li>
                <li>
                    <span class="fieldName">Recorded Date</span
                    ><span class="fieldValue">{{
                        pkgData.stampedDate
                            | formatTime
                                : pkgData.recipientTimeZone
                                : "MM/DD/YYYY h:mm A zz"
                                : true
                    }}</span>
                </li>
            </ul>
            <div class="action-row">
                <b>Documents</b>
                <div class="download-actions">
                    <span class="download-instructions"
                        >Download all selected</span
                    >
                    <div
                        class="button-block"
                        [ngbTooltip]="tooltipMessage"
                        [disableTooltip]="allowDownloading"
                    >
                        <!--using *ngIf below instead of [ngClass] bc of flickering in IE11 - see SUB-16441-->
                        <a
                            *ngIf="allowDownloading"
                            class="btn btn-primary"
                            href="/sf/rpc/erecord/downloadDocuments?tokenID={{
                                token
                            }}&sDocumentIDSet={{
                                selectedDocumentIDs
                            }}&zipped=true"
                            tabindex="1">as ZIP file</a
                        >
                        <a
                            *ngIf="!allowDownloading"
                            class="btn btn-primary disabled"
                            tabindex="1">as ZIP file</a
                        >
                        <a
                            *ngIf="allowDownloading"
                            class="btn btn-secondary"
                            href="/sf/rpc/erecord/downloadDocuments?tokenID={{
                                token
                            }}&sDocumentIDSet={{
                                selectedDocumentIDs
                            }}&zipped=false"
                            tabindex="2">as one PDF</a
                        >
                        <a
                            *ngIf="!allowDownloading"
                            class="btn btn-secondary disabled"
                            tabindex="2">as one PDF</a
                        >
                    </div>
                </div>
            </div>
            <div class="grid-container">
                <ag-grid-angular
                    class="ag-grid ag-theme-sf"
                    suppressColumnVirtualisation
                    suppressCellFocus
                    suppressRowHoverHighlight
                    suppressRowClickSelection
                    suppressContextMenu
                    rowSelection="multiple"
                    [headerHeight]="31"
                    [rowHeight]="30"
                    domLayout="autoHeight"
                    [icons]="icons"
                    (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged($event)"
                    [rowData]="pkgData.documents"
                ></ag-grid-angular>
            </div>
        </div>
        <div class="body" *ngSwitchCase="'INVALID_TOKEN'">
            <h2>Invalid or Expired Link</h2>
            <p>
                The link you have provided is invalid or expired. Please request
                a new link to be generated by the Simplifile user who originated
                this link for you.
            </p>
        </div>
        <div class="body" *ngSwitchCase="'EXISTING_SESSION'">
            <h2>Already Logged In</h2>
            <p>
                This page requires creating a new unique browser session, but
                you are already logged in to Simplifile on this browser. You may
                open the link in a separate browser or in a private (incognito)
                window. Alternatively, you may log out manually and refresh this
                page, or click the button below to automatically log out of
                Simplifile. Please check to ensure you won't lose any unsaved
                data.
            </p>
            <div class="text-center">
                <button
                    (click)="authenticateAndGetData(true)"
                    class="btn btn-primary"
                >
                    Log Out
                </button>
            </div>
        </div>
        <div class="body text-center" *ngSwitchDefault>
            <p>Loading...</p>
        </div>
    </div>
</div>

<ng-template #downloadButtonCellTemplate let-row>
    <a
        href="/sf/rpc/erecord/downloadDocuments?tokenID={{
            token
        }}&sDocumentIDSet={{ row.documentID }}&zipped=false"
        class="download-btn"
    >
        <fa-icon [icon]="['far', 'download']"></fa-icon>
    </a>
</ng-template>
