import { Component, Input, OnInit } from "@angular/core";
import { ModalButton, SelectableItem } from "@sf/common";
import { ContractService } from "@sf/userorg/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

interface LocalInvitationRecipient {
    date: string;
    id: string;
    name: string;
}

interface LocalInvitationDetails {
    enrollmentCode: string; // enrollment code. Not required.
    freeSubmissions: number; // free document count. Not required.
    renewalFrequency: string; // defaults to 'yearly'
    billingCycle: string; // not required
    recordingFeeBillingCycle: string; // not required
    paymentTerm: string; // not required
    salesforceID: string; // not required
    firstName: string; // only if sending to non-user
    lastName: string; // only if sending to non-user
    title: string; // only if sending to non-user

    // fees for submitter, document builder or eSign event
    licenseFee: string;

    // fees for submitter e-record or document builder or submitter capc or local lender capc
    renewalFee: string;

    // fees for submitter e-record only
    submissionFee: string;

    // fees for vendor
    exclusiveSubmissionFee: string;

    // fees for document builder and trustee only
    documentFee: string;

    // fees for document builder RON only
    ronEventFee: string;

    // fees for lien release
    lienReleaseFee: string;

    // subscription
    subscriptionMinimum: string;

    // fees for 'recordingPLUS' (paper)
    inStateErecordSubmissionFee: string;
    outOfStateErecordSubmissionFee: string;
    inStatePaperSubmissionFee: string;
    outOfStatePaperSubmissionFee: string;
    paperMailFee: string;
    embeddedEmployeeFee: string;

    // fees for submitter capc only
    disbursementFee: string;
    verifiedWithoutSecureDisbursementFee: string;
    revShareFee: string;

    // fees for lender capc or submitter capc
    loanCreateFee: string;
    loanAcceptFee: string;

    // fees for eSign event
    eseNoEnotorizationFee: string;
    eseIpenFee: string;
    eseRonFee: string;
}

interface LocalInvitation {
    invitation: any;
    service: string;
    recipients: LocalInvitationRecipient[];
}

interface LocalName {
    firstName: string;
    lastName: string;
    userId: string;
    email: string;
}

// prettier-ignore
@Component({
    selector: "sf-invitation-details-dialog",
    templateUrl: "./invitation-details-dialog.component.html",
    styleUrls: ["./invitation-details-dialog.component.scss"]
})
export class InvitationDetailsDialogComponent implements OnInit {
    @Input() orgID: string;
    @Input() productName: string;
    @Input() invitation: LocalInvitation;

    title = "Service Invitation";
    names: LocalName[];
    details: SelectableItem[];
    isProcessing = false;
    primary: ModalButton;
    tertiary: ModalButton;

    constructor(
            private contractService: ContractService,
            private activeModal: NgbActiveModal
    ) {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
    }

    ngOnInit() {
        if (this.invitation) {
            this.names = [];
            if (this.invitation.recipients) {
                this.invitation.recipients.forEach((recipient) => {
                    let name: LocalName = {
                        firstName: recipient.name,
                        lastName: "",
                        userId: recipient.id,
                        email: ""
                    };
                    this.names.push(name);
                });
            }
            this.buildInvitationDetails(this.invitation.invitation);
        }
    }

    dismiss() {
        this.activeModal.close(false);
    }

    buildInvitationDetails(invite: LocalInvitationDetails) {
        this.details = [];
        let detail: SelectableItem;
        if (invite.firstName) {
            this.details.push({
                label: "Person Invited:",
                option:
                        invite.firstName +
                        " " +
                        invite.lastName +
                        " (" +
                        invite.title +
                        ")"
            });
        }
        if (invite.renewalFrequency) {
            this.details.push({
                label: "Renewal Frequency:",
                option: invite.renewalFrequency
            });
        }
        if (invite.billingCycle) {
            this.details.push({
                label: "Billing Cycle:",
                option: invite.billingCycle
            });
        }
        if (invite.recordingFeeBillingCycle) {
            this.details.push({
                label: "Recording Fee Billing Cycle:",
                option: invite.recordingFeeBillingCycle
            });
        }
        if (invite.paymentTerm) {
            this.details.push({
                label: "Payment Terms:",
                option: invite.paymentTerm
            });
        }
        if (invite.salesforceID) {
            this.details.push({
                label: "Salesforce ID:",
                option: invite.salesforceID
            });
        }
        if (invite.enrollmentCode) {
            this.details.push({
                label: "Enrollment Code:",
                option: invite.enrollmentCode
            });
        }
        if (invite.freeSubmissions) {
            this.details.push({
                label: "Free Documents/Submissions:",
                option: "" + invite.freeSubmissions
            });
        }
        if (invite.licenseFee) {
            this.details.push({
                label: "License Fee:",
                option: "$" + invite.licenseFee
            });
        }
        if (invite.renewalFee) {
            this.details.push({
                label: "Renewal Fee:",
                option: "$" + invite.renewalFee
            });
        }
        if (invite.loanAcceptFee) {
            this.details.push({
                label: "Loan Fee:",
                option: "$" + invite.loanAcceptFee
            });
        }
        if (invite.submissionFee) {
            this.details.push({
                label: "Submission Fee:",
                option: "$" + invite.submissionFee
            });
        }
        if (invite.exclusiveSubmissionFee) {
            this.details.push({
                label: "Exclusive Submission Fee:",
                option: "$" + invite.exclusiveSubmissionFee
            });
        }
        if (invite.documentFee) {
            this.details.push({
                label: "Document Fee:",
                option: "$" + invite.documentFee
            });
        }
        if (invite.ronEventFee) {
            this.details.push({
                label: "RON Fee:",
                option: "$" + invite.ronEventFee
            });
        }
        if (invite.lienReleaseFee) {
            this.details.push({
                label: "Automated Document Request Fee:",
                option: "$" + invite.lienReleaseFee
            });
        }
        if (invite.subscriptionMinimum) {
            this.details.push({
                label: "Subscription Minimum:",
                option: "$" + invite.subscriptionMinimum
            });
        }
        if (invite.inStateErecordSubmissionFee) {
            this.details.push({
                label: "In-State eRecord Submission Fee:",
                option: "$" + invite.inStateErecordSubmissionFee
            });
        }
        if (invite.outOfStateErecordSubmissionFee) {
            this.details.push({
                label: "Out-of-State eRecord Submission Fee:",
                option: "$" + invite.outOfStateErecordSubmissionFee
            });
        }
        if (invite.inStatePaperSubmissionFee) {
            this.details.push({
                label: "In-State Paper Submission Fee:",
                option: "$" + invite.inStatePaperSubmissionFee
            });
        }
        if (invite.outOfStatePaperSubmissionFee) {
            this.details.push({
                label: "Out-of-State Paper Submission Fee:",
                option: "$" + invite.outOfStatePaperSubmissionFee
            });
        }
        if (invite.outOfStatePaperSubmissionFee) {
            this.details.push({
                label: "Paper Mail Fee:",
                option: "$" + invite.outOfStatePaperSubmissionFee
            });
        }
        if (invite.embeddedEmployeeFee) {
            this.details.push({
                label: "Embedded Employee Fee:",
                option: "$" + invite.embeddedEmployeeFee
            });
        }
        if (invite.disbursementFee) {
            this.details.push({
                label: "Disbursement Fee:",
                option: "$" + invite.disbursementFee
            });
        }
        if (invite.verifiedWithoutSecureDisbursementFee) {
            this.details.push({
                label: "Verified w/o Secure Disp Fee:",
                option: "$" + invite.verifiedWithoutSecureDisbursementFee
            });
        }
        if (invite.revShareFee) {
            this.details.push({
                label: "Revenue Share Fee:",
                option: "$" + invite.revShareFee
            });
        }
        if (invite.eseNoEnotorizationFee) {
            this.details.push({
                label: "No eNotarization Fee:",
                option: "$" + invite.eseNoEnotorizationFee
            });
        }
        if (invite.eseIpenFee) {
            this.details.push({
                label: "IPEN Fee:",
                option: "$" + invite.eseIpenFee
            });
        }
        if (invite.eseRonFee) {
            this.details.push({
                label: "RON Fee:",
                option: "$" + invite.eseRonFee
            });
        }
    }
}
