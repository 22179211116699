import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, timer } from "rxjs";
import {
    auditTime,
    delay,
    map,
    shareReplay,
    switchMap,
    takeWhile
} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class LoaderService {
    private _loader: BehaviorSubject<number> = new BehaviorSubject<number>(
        null
    );
    private _shouldAnimateWidth: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    progress$ = this._loader.pipe(
        auditTime(10),
        switchMap((curr) => {
            if (curr === null) {
                return of(100);
            }

            return timer(0, 500).pipe(
                map((value) => curr + value * 0.1),
                takeWhile((value) => value < 100.1)
            );
        }),
        shareReplay(1)
    );

    isLoading$ = this._loader.pipe(
        auditTime(10),
        map((value) => typeof value === "number"),
        switchMap((value) => {
            if (value) {
                return of(value);
            }

            return of(value).pipe(delay(1000));
        }),
        shareReplay(1)
    );
    shouldAnimateWidth$: Observable<boolean> =
        this._shouldAnimateWidth.asObservable();

    constructor() {}

    start() {
        this._loader.next(0);
        this._shouldAnimateWidth.next(true);
    }

    complete() {
        this._loader.next(null);
    }

    increment(value: number) {
        this._loader.next(value);
    }
}
