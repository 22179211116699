import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerSurveyComponent } from "./customer-survey.component";
import { CustomerSurveyQuestionComponent } from "./customer-survey-question/customer-survey-question.component";
import { CustomerSurveyEssayComponent } from "./customer-survey-essay/customer-survey-essay.component";
import { CustomerSurveyScaleComponent } from "./customer-survey-scale/customer-survey-scale.component";
import { FormsModule } from "@angular/forms";
import { SfCommonModule } from "@sf/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    imports: [CommonModule, SfCommonModule, FormsModule, FontAwesomeModule],
    declarations: [
        CustomerSurveyComponent,
        CustomerSurveyQuestionComponent,
        CustomerSurveyEssayComponent,
        CustomerSurveyScaleComponent
    ],
    exports: [
        CustomerSurveyComponent,
        CustomerSurveyEssayComponent,
        CustomerSurveyScaleComponent
    ]
})
export class CustomerSurveysModule {}
