import { NgModule } from "@angular/core";
import { AdminOrgHeaderWithSelectorComponent } from "./components/admin-org-header-with-selector/admin-org-header-with-selector.component";
import { ModalsModule } from "@sf/common";
import { UserSearchSelectorComponent } from "./components/user-search-selector/user-search-selector.component";
import { OrganizationSearchSelectorComponent } from "./components/organization-search-selector/organization-search-selector.component";
import { RoleSearchSelectorComponent } from "./components/role-search-selector/role-search-selector.component";
import { PermissionSearchSelectorComponent } from "./components/permission-search-selector/permission-search-selector.component";
import { LayoutModule } from "@angular/cdk/layout";
import { RouterModule } from "@angular/router";
import { UserorgCoreModule } from "@sf/userorg/common";
import { CommonModule } from "@angular/common";
import { SfCommonModule } from "@sf/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomFormModule } from "@sf/custom-form";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AdminUserHeaderWithSelectorComponent } from "./components/admin-user-header-with-selector/admin-user-header-with-selector.component";
import { AdminLoginModalComponent } from "./dialogs/admin-login-modal/admin-login-modal.component";
import { AdminApiTokensComponent } from "./components/admin-api-tokens/admin-api-tokens.component";
import { AdminRoleHeaderWithSelectorComponent } from "./components/admin-role-header-with-selector/admin-role-header-with-selector.component";
import { AdminPermissionHeaderWithSelectorComponent } from "./components/admin-permission-header-with-selector/admin-permission-header-with-selector.component";

@NgModule({
    imports: [
        CommonModule,
        CustomFormModule,
        FontAwesomeModule,
        FormsModule,
        SfFormsModule,
        LayoutModule,
        NgbModule,
        NgbTooltipModule,
        RouterModule,
        ReactiveFormsModule,
        SfCommonModule,
        SelectModule,
        UserorgCoreModule,
        ModalsModule
    ],
    declarations: [
        AdminLoginModalComponent,
        AdminOrgHeaderWithSelectorComponent,
        AdminUserHeaderWithSelectorComponent,
        UserSearchSelectorComponent,
        OrganizationSearchSelectorComponent,
        RoleSearchSelectorComponent,
        PermissionSearchSelectorComponent,
        AdminApiTokensComponent,
        AdminRoleHeaderWithSelectorComponent,
        AdminPermissionHeaderWithSelectorComponent
    ],
    providers: [],
    exports: [
        UserSearchSelectorComponent,
        OrganizationSearchSelectorComponent,
        RoleSearchSelectorComponent,
        PermissionSearchSelectorComponent,
        AdminApiTokensComponent
    ]
})
export class UserorgAdminCoreModule {
    constructor() {}
}
