import { Injectable } from "@angular/core";
import {
    LiveConfigGroup,
    LiveConfigChangeSummary,
    MigrationPage
} from "../interfaces";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class LiveConfigManagementService {
    constructor(protected _rpcClient: RpcClientService) {}

    // Public Methods
    getConfigForms(tag: string = ""): Observable<LiveConfigGroup[]> {
        return this._rpcClient.makeRequest<LiveConfigGroup[]>(
            "admin",
            "getConfigForms",
            { tag }
        );
    }

    getLiveConfigFilters(): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getLiveConfigFilters");
    }

    updateConfigValue(update: {
        sectionID: string;
        optionID: string;
        rawJsonValue: any;
        overrideSpecs: any;
        previousVersion: any;
    }): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "updateConfigValue",
            update
        );
    }

    getPinnedPartitions(): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getPinnedPartitions");
    }

    getConfigOptOuts(): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getOptOutPartitions");
    }

    saveConfigOptOut(optOutValue: any): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "updateOptOutPartition",
            optOutValue
        );
    }

    savePinnedPartition(partition: any): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "updateLiveConfigPartition",
            {
                partition,
                overrideSpecs: []
            }
        );
    }

    getRecentLiveConfigChanges(
        tag: string,
        after: DateString,
        before: DateString,
        limit: number
    ): Observable<LiveConfigChangeSummary[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecentLiveConfigChanges",
            {
                tag,
                after,
                before,
                limit
            }
        );
    }

    setLiveConfigValueForSelf(
        sectionID: string,
        optionID: string,
        value: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "admin",
            "setLiveConfigValueForSelf",
            {
                sectionID,
                optionID,
                value
            }
        );
    }

    removePinnedPartition(partitionID: any): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "removeLiveConfigPartition",
            {
                partitionID
            }
        );
    }

    guid() {
        return (
            this._s4() +
            this._s4() +
            this._s4() +
            this._s4() +
            this._s4() +
            this._s4() +
            this._s4() +
            this._s4()
        );
    }

    // Private Methods
    private _s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
}
