export interface RonJournalRequirements {
    notaryRequirements: RonJournalRequirement[];
    participantRequirements: RonJournalRequirement[];
}

export interface RonJournalRequirement {
    type: RonJournalRequirementType;
    enabled: boolean;
}

export enum RonJournalRequirementType {
    ADDRESS = "ADDRESS",
    RECORDING_CONSENT = "RECORDING_CONSENT",
    CONFIRM_ID_ISSUE_DATE = "CONFIRM_ID_ISSUE_DATE",
    CONFIRM_ID_EXP_DATE = "CONFIRM_ID_EXP_DATE",
    PRESENT_STATE_SCRIPT = "PRESENT_STATE_SCRIPT",
    NOTORIAL_ACT = "NOTORIAL_ACT",
    NOTARY_FEE_AMT = "NOTARY_FEE_AMT",
    NOTARY_NOTES = "NOTARY_NOTES",
    VERIFY_TAMPER_SEAL = "VERIFY_TAMPER_SEAL",
    RESTART_EVENT_ON_SIGNER_EXIT = "RESTART_EVENT_ON_SIGNER_EXIT"
}
