import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import log from "loglevel";
import {
    ExternalPageService,
    MetricLoggerService,
    SessionService
} from "@sf/common";

// prettier-ignore
@Injectable()
export class DefaultRouteGuard implements CanActivate {
    private _validNonProductRoutes = [
        "legacy",
        "extract",
        "reporting",
        "sign-mou",
        "sf-select"
    ];

    constructor(
            private _router: Router,
            private _sessionService: SessionService,
            private externalPageService: ExternalPageService,
            private _metricLoggerService: MetricLoggerService
    ) {}

    canActivate(
            next: ActivatedRouteSnapshot,
            state: RouterStateSnapshot
    ):
            | Observable<boolean | UrlTree>
            | Promise<boolean | UrlTree>
            | boolean
            | UrlTree {
        // Remove deprecated 'app/' fragment from url
        if (state.url.indexOf("app/") > -1) {
            return this._router.createUrlTree([state.url.replace("app/", "")]);
        }

        // TODO: Remove in a future release. Ask the Submitter team, as this is only a temporary fix
        // Redirect old docviewer urls to the current url
        if (state.url.match(/^\/document\/(?!viewer)/)) {
            this._metricLoggerService.recordMetric("erecord", "docViewerRedirect", 1);
            return this._router.createUrlTree([
                state.url.replace("document/", "document/viewer/")
            ]);
        }

        let routePaths = state.url.split("/");
        if (!next.firstChild || routePaths.length < 3) {
            let newState;
            if (!next.firstChild) {
                if (this._sessionService.isValid()) {
                    // Get default app from session
                    newState = this._sessionService.getDefaultApp();

                    // if no organization is setup or enabled for 'erecord', and user has access to a CAPC org, then go to lender module
                    if (!this._sessionService.isAnyOrganizationSetUp() ||
                            !this._sessionService.isAnyOrganizationEnabled()) {
                        if (this._sessionService.hasAnyProductInAnyOrg(["settlement", "lender"])) {
                            newState = "lender";
                        } else if (this._sessionService.hasProductInAnyOrg("vendor")) {
                            newState = "vendor";
                        }
                    }
                }
            } else {
                // i.e. ['', 'admin']
                newState = routePaths[1];
            }

            let urlTree = null;
            if (newState == "admin") {
                urlTree = this._router.createUrlTree(["/admin/mainmenu"]);
            } else {
                if (this._sessionService.isValid() && this.hasRelatedProduct(newState)) {
                    // this is the normal path
                    let redirect = "/" + newState;
                    let home = this._sessionService.getHomePageUrl();
                    if (typeof home === "string") {
                        // should always get here
                        redirect = home.replace("/sf/ui", "");
                    }
                    urlTree = this._router.createUrlTree([redirect]);
                } else if (this.isValidNonProductRoute(newState)) {
                    // this is a valid route so let it slide through
                    return true;
                } else if (this.externalPageService.isCurrentPageExternal()) {
                    // this is a valid external page so they can stay
                    return true;
                } else {
                    // there is probably a session problem,
                    // like they were using a 'token' authentication - go to login page
                    let onLoginPageAlready = false;
                    if (location && location.pathname) {
                        if (location.pathname.indexOf("/login") > -1) {
                            onLoginPageAlready = true;
                        }
                    }
                    let eeHack = (location.pathname.includes("sign-event") &&
                            (location.pathname.includes("closing") || location.pathname.includes("signing")));
                    if (!onLoginPageAlready && !eeHack) {
                        log.warn("default-route sending to login page from: " + location.pathname);
                        urlTree = this._router.createUrlTree(["/login"]);
                    }
                }
            }

            return !!urlTree ? urlTree : false;
        }

        // FIXME:
        // If root of default app has been refactored in Angular then redirect them to that otherwise go to Angular JS defaultModuleRouter
        // For now since no root default route has been done in Angular navigate and rely on AngularJS logic
        // this._router.navigate([""]);

        return true;
    }

    isValidNonProductRoute(newState: string): boolean {
        if (!newState) {
            return false;
        }
        let found = this._validNonProductRoutes.find((npRoute) => {
            return newState.indexOf(npRoute) >= 0;
        });
        return !!found;
    }

    hasRelatedProduct(state: string): boolean {
        let products = [state];
        if (state == "lender") {
            products.push("settlement");
        }
        return this._sessionService.hasAnyProductInAnyOrg(products);
    }
}
