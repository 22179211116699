/**
 * This is a 'drop-down' selector of states
 * You need to pass in the list of states
 * Can select one or multiple, based on 'isMultiSelect' attribute
 */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Subject } from "rxjs";
import { SelectableState } from "../../../services/states.service";
import { StatesService } from "../../../services/states.service";

@Component({
    selector: "sf-state-selector",
    templateUrl: "./state-selector.component.html",
    styleUrls: ["./state-selector.component.scss"]
})
export class StateSelectorComponent implements OnInit, OnChanges, OnDestroy {
    /* Inputs */
    @Input()
    selectedStateID: string; // (optional) (if single select) state abbreviation initially selected
    @Input()
    selectedStateIDs: string[]; // (optional) (if multi select) list of state abbreviations initially selected
    @Input()
    states: SelectableState[]; // (optional) list of states to select from - if not provided, then list built by selector
    @Input()
    autoInitialSelect: boolean; // initially select an item - determined by selectedStateID, else first item in list
    @Input()
    isMultiSelect: boolean; // allow multiple selection
    @Input()
    canClearSelectedState: boolean; // whether to show an 'X' to clear selection
    @Input()
    hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input()
    includeNoneOption: SelectableState; // (optional) item to include at the top of the list
    @Input()
    required: boolean = null; // if required in form
    @Input()
    placeholder: string; // (optional) placeholder - will be defaulted
    @Input()
    windowClass: string; // CSS class that is passed to the sf-select control - defaults to nothing
    @Input()
    isDisabled: boolean; // (optional) flag to indicate if selector is disabled

    /* Outputs */
    @Output()
    select: EventEmitter<any> = new EventEmitter(); // when a state is selected - passes a state object when called

    /** Private Variables **/
    private _$ngOnDestroy: Subject<void> = new Subject();

    /** Public Variables **/
    selectedState: SelectableState = null;
    selectableItems: SelectableState[] = [];

    constructor(private statesService: StatesService) {}

    ngOnInit() {
        if (!this.states) {
            this.states = this.statesService.getAllStatesAndTerritories();
        }
        if (!this.placeholder) {
            this.placeholder = this.isMultiSelect
                ? "Select State(s)..."
                : "Select State...";
        }

        if (this.includeNoneOption) {
            this.states.unshift(this.includeNoneOption);
        }

        this.postResults(this.states);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.selectedStateID &&
            changes.selectedStateID.currentValue &&
            changes.selectedStateID.currentValue !==
                changes.selectedStateID.previousValue
        ) {
            // nothing
        }
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
    }

    getStates(event?: any) {
        let results = this.states;
        if (event.hasOwnProperty("$searchValue")) {
            let search: string = event.$searchValue;
            if (search) {
                search = search.toLowerCase();
                results = [];
                this.states.forEach((state: SelectableState) => {
                    let lowerID = state.abbrev.toLowerCase();
                    let lowerLabel = state.name.toLowerCase();
                    if (
                        lowerID.startsWith(search) ||
                        lowerLabel.startsWith(search)
                    ) {
                        results.push(state);
                    }
                });
            }
        }
        this.postResults(results);
    }

    handleStateSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            if (Array.isArray(selectionObject)) {
                this.selectedState = null; // initially
                let selection: SelectableState[] = selectionObject;
                let emitData: SelectableState[] = [];
                selection.forEach((item) => {
                    let state = this.findStateInList(item.abbrev);
                    if (state) {
                        emitData.push(state);
                        this.selectedState = state; // weird but OK
                    }
                });
                if (this.select) {
                    this.select.emit(emitData);
                }
            } else {
                let selection: SelectableState = selectionObject;
                let state = null;
                if (selection && selection.abbrev) {
                    state = this.findStateInList(selection.abbrev);
                }
                this.selectedState = state; // may be null, which is fine
                if (this.select) {
                    this.select.emit(state);
                }
            }
        }
    }

    private findStateInList(stateID: string): SelectableState {
        let state: SelectableState = this.states.find((state) => {
            return state.abbrev === stateID;
        });
        return state;
    }

    postResults(results: SelectableState[]) {
        setTimeout(() => {
            this.selectableItems = results;
        }, 1);
    }

    hiddenStateChange($event: any) {
        let stateName = $event.currentTarget.value;

        // Depending on how the browser stores the state in the Address,
        // it may autofill the state's full name or the state's abbreviation.
        let selectedState: SelectableState = this.states.find(
            (state: SelectableState) => {
                return state.abbrev == stateName || state.name == stateName;
            }
        );

        if (selectedState) {
            this.selectedStateID = selectedState.abbrev;
            this.selectedState = selectedState;

            if (this.select) {
                this.select.emit(selectedState);
            }
        }
    }
}
