import { Injectable } from "@angular/core";
import { SessionService } from "@sf/common";
import { LiveConfigService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class TrayModulesService {
    /** Public Variables **/
    private _MODULES = {
        ENHANCED_PRINT_AND_DOWNLOAD: {
            VERSION: "3.8.8",
            NAME: "submitter"
        },
        PRINT_TO_SIMPLIFILE: {
            VERSION: "1.0.10",
            NAME: "esign"
        },
        JAVA_TEST: {
            VERSION: "1.0.5",
            NAME: "java_test"
        },
        QUICKSTART: {
            VERSION: "5.1.2",
            NAME: "quickstart"
        },
        SCAN_TO_SIMPLIFILE: {
            VERSION: "1.0.10",
            NAME: "scanning"
        }
    };

    constructor(
        private _sessionService: SessionService,
        private _liveConfigService: LiveConfigService
    ) {}

    /** Public Methods **/
    public get MODULES() {
        return this._MODULES;
    }

    isEnabled(module: any) {
        if (module === this._MODULES.ENHANCED_PRINT_AND_DOWNLOAD) {
            return (
                this._liveConfigService.get("TrayAppSettings.allowSubmitter") &&
                this._sessionService.hasProductInAnyOrg("submitter")
            );
        }
        if (module === this._MODULES.PRINT_TO_SIMPLIFILE) {
            return this._sessionService.hasPermissionInAnyOrg(
                "third_party_documents_print_driver"
            );
        }
        if (module === this._MODULES.SCAN_TO_SIMPLIFILE) {
            return this._sessionService.hasProductInAnyOrg("submitter");
        }
        if (module === this._MODULES.QUICKSTART) {
            return this._liveConfigService.get(
                "TrayAppSettings.allowRecipient"
            );
        }
        return false;
    }

    isAnyModuleEnabled() {
        let anyModuleEnabled = false;
        for (const [key, mod] of Object.entries(this._MODULES)) {
            if (!anyModuleEnabled) {
                anyModuleEnabled = this.isEnabled(mod);
            }
        }
        return anyModuleEnabled;
    }

    allowDownload() {
        return this._liveConfigService.get("TrayAppSettings.allowDownload");
    }
}
