import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class ActivityLogService {
    constructor(
        private rpcService: RpcClientService
    ) {
    }

    getLogs(options: any): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getLogs", {
            idQuery: options.idQuery,
            performedByQuery: options.performedByQuery,
            types: options.types,
            after: options.after,
            before: options.before
        });
    }

    getActivityTypes(): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getActivityTypes");
    }

    logManualContact(details: string, activityType: string, orgID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "logManualContact", {
            contactDetails: details,
            activityType: activityType,
            orgId: orgID
        });
    }
}
