import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { UIESignEventWrapper } from "../interfaces/sign-event-notification.interface";
import {
    UICurrentUserCanViewEventResponse,
    UIESignEvent
} from "../interfaces/sign-event.interface";

@Injectable({
    providedIn: "root"
})
export class TaggingSharedService {
    constructor(private _rpcClient: RpcClientService) {}

    getESignEvent(
        eSignEventID: string,
        getESignEvent: boolean,
        getParticipants: boolean,
        getDocuments: boolean,
        getRoom: boolean,
        getRoomStatus: boolean,
        overrideDefaultErrorHandling: boolean = false
    ): Observable<UIESignEventWrapper> {
        const data: any = {
            eSignEventID,
            getESignEvent,
            getParticipants,
            getDocuments,
            getRoom,
            getRoomStatus
        };
        return this._rpcClient.makeInternationalRequest(
            "esign_event",
            "getESignEvent",
            data,
            overrideDefaultErrorHandling
        );
    }

    processSessionCompleteSurveyTrigger(eSignEventID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "processSessionCompleteSurveyTrigger",
            { eSignEventID }
        );
    }

    /**
     * Simple/quick call to the backend to see if our session has timed out already.
     * @param eSignEventID The ESign Event ID, of course.
     */
    heartbeat(eSignEventID: string): Observable<UIESignEvent> {
        return this._rpcClient.makeInternationalRequest(
            "esign_event",
            "heartbeat",
            {
                eSignEventID
            }
        );
    }

    currentUserCanViewEvent(
        eSignEventID: string
    ): Observable<UICurrentUserCanViewEventResponse> {
        return this._rpcClient.makeInternationalRequest(
            "esign_event",
            "currentUserCanViewEvent",
            {
                eSignEventID
            }
        );
    }
}
