import { Injectable } from "@angular/core";
import { from, Observable, of, throwError } from "rxjs";
import { NewPackageCreationResponse } from "../interfaces/new-package-creation-response";
import { NewPackageCreationConfig } from "../interfaces/new-package-creation-config";
import { PackageReceiptData } from "../interfaces/receipt-data.interface";
import { SubmittedPackageNotification } from "../interfaces/submitted-package-notification.interface";
import { RpcClientService } from "@sf/common";
import { PackageNotificationSubscription } from "../interfaces/package-notification-subscription";
import { PackageStatusDetails } from "../interfaces/package-status-details.interface";
import { PackageStatusUpdate } from "../interfaces/package-status-update";
import { SubmitterPackage } from "../interfaces/submitter-package.interface";
import { GrowlService } from "@sf/common";
import { catchError, switchMap, tap } from "rxjs/operators";
import { HistoryEventModel } from "../interfaces/history-event-model.interface";
import { SubmitterDocument } from "../interfaces/submitter-document.interface";
import { PackageStatusChangesService } from "./package-status-changes.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SecureCorrectionsWarningDialogComponent } from "../dialogs/secure-corrections-warning-dialog/secure-corrections-warning-dialog.component";

export interface AddDocumentResponse {
    newDocumentID: string;
}

export interface AddTemplateResponse {
    documentID: string;
    eSignDocumentID: string;
    inkSign: boolean;
    noTrusteeState: string;
    isMissingReferenceDocument: boolean;
    trialDocumentsCount: number;
    showTrialTour: boolean;
}

export interface PackageRecipientsPausedStatuses {
    [packageID: string]: boolean;
}

@Injectable({
    providedIn: "root"
})
export class SubmitterPackagesService {
    private ERECORD = "erecord";
    private ESIGN = "esign";

    constructor(
        private _rpcClient: RpcClientService,
        private _growl: GrowlService,
        private _packageStatusChanges: PackageStatusChangesService,
        private _modalService: NgbModal
    ) {}

    getPackage(pkgID: string): Observable<SubmitterPackage> {
        return this._rpcClient.makeRequest<SubmitterPackage>(
            this.ERECORD,
            "getPackage",
            { id: pkgID }
        );
    }

    subscribeToAllPackageStatusChanges(): Observable<PackageStatusUpdate> {
        return this._packageStatusChanges.createUpdateSubscription();
    }

    deletePackages(
        packageIDs: string[],
        growlOnCompletion: boolean
    ): Observable<{ numOfPackagesDeleted: number; errors?: string[] }> {
        return this._rpcClient
            .makeRequest<{ numOfPackagesDeleted: number; errors?: string[] }>(
                this.ERECORD,
                "removePackages",
                { packageIDs }
            )
            .pipe(
                catchError((errorResponse) => {
                    return of({
                        numOfPackagesDeleted: 0,
                        errors: errorResponse.error.data.errors ?? [
                            errorResponse.error.errorMessage
                        ]
                    });
                }),
                tap((result) => {
                    if (!growlOnCompletion) {
                        return;
                    }
                    const msg = `${result.numOfPackagesDeleted} package(s) deleted.`;
                    this._growl.success(msg);
                    if (!result.errors) {
                        return;
                    }
                    for (const error of result.errors) {
                        this._growl.error(error);
                    }
                })
            );
    }

    deleteDocuments(
        documentIDs: string[],
        packageID: string
    ): Observable<void> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "removeDocuments",
                {
                    documentIDs,
                    packageID
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "discard any document from this package",
                        this.deleteDocuments.bind(this),
                        documentIDs,
                        packageID
                    );
                })
            );
    }

    changeRecipient(
        packageID: string,
        newRecipientID: string,
        newTownship: string,
        newDocumentTypes: string[]
    ): Observable<string> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "changePackageRecipient",
                {
                    packageID,
                    newRecipientID,
                    newTownship,
                    newDocumentTypes
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "alter this package's recipient",
                        this.changeRecipient.bind(this),
                        packageID,
                        newRecipientID,
                        newTownship,
                        newDocumentTypes
                    );
                })
            );
    }

    changeSubmitter(
        packageID: string,
        newSubmitterID: string,
        newTemplates: any[]
    ): Observable<string> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "changePackageSubmitter",
                {
                    packageID,
                    newSubmitterID,
                    newTemplates
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "change this package's submitter",
                        this.changeSubmitter.bind(this),
                        packageID,
                        newSubmitterID,
                        newTemplates
                    );
                })
            );
    }

    updateTownship(
        pkgId: string,
        newTownship: string,
        submitterId: string
    ): Observable<boolean | string> {
        return this._rpcClient.makeRequest(this.ERECORD, "updateTownship", {
            id: pkgId,
            newTownship: newTownship,
            organizationID: submitterId
        });
    }

    submitPackages(
        packageIDs: string[]
    ): Observable<SubmittedPackageNotification> {
        return this._rpcClient.makeRequest(this.ERECORD, "submitPackages", {
            packageIDs
        });
    }

    submitPackagesEnableNotifications(
        packageIDs: string[],
        enableNotifications: boolean
    ): Observable<SubmittedPackageNotification> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "submitPackagesEnableNotifications",
            { packageIDs, enableNotifications }
        );
    }

    submitPaperPackages(packageIDs: string[]): Observable<void | string> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "submitPaperPackages",
            { packageIDs }
        );
    }

    rejectPaperPackage(
        packageID: string,
        rejectionReasons: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.ERECORD, "rejectPaperPackage", {
            packageID,
            rejectionReasons
        });
    }

    recordPaperPackage(
        packageID: string,
        documentRecordingInfo: {
            [key: string]: {
                entryNumber: string;
                bookNumber: string;
                pageNumber: string;
                date: string;
                formattedDate: string;
                receiptID?: string;
                docType?: string;
                fees?: {
                    recipientID: string;
                    description: string;
                    amount: number | string;
                    category: string;
                }[];
            };
        }
    ): Observable<void | string> {
        return this._rpcClient.makeRequest(this.ERECORD, "recordPaperPackage", {
            packageID,
            documentRecordingInfo
        });
    }

    createPackage(
        pkg: NewPackageCreationConfig
    ): Observable<NewPackageCreationResponse> {
        return this._rpcClient.makeRequest(this.ERECORD, "createPackage", {
            submitterID: pkg.submitterID,
            recipientID: pkg.recipientID,
            state: pkg.state,
            name: pkg.name,
            submitterPackageId: pkg.submitterPackageID,
            tertiaryPackageId: pkg.tertiaryPackageId,
            township: pkg.township,
            projectId: pkg.projectId
        });
    }

    validateCSVForUpload(
        submitterID: string,
        projectID: string,
        csvFile: string,
        filename: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "validateCSVForUpload",
            {
                submitterID,
                projectID,
                csvFile,
                filename
            }
        );
    }

    uploadCSVFile(
        submitterID: string,
        projectID: string,
        csvFile: string,
        filename: string,
        skipRecords: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.ERECORD, "uploadCSVFile", {
            submitterID,
            projectID,
            csvFile,
            filename,
            skipRecords
        });
    }

    uploadInksignDocument(
        submitterID: string,
        contents: string,
        filename: string,
        uploadId: string,
        fileCount: number
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "uploadInksignDocument",
            {
                submitterID,
                contents,
                filename,
                uploadId,
                fileCount
            }
        );
    }

    getAutoCreateAnalysisStatus(
        documentAnalysisID: string
    ): Observable<{ packages: string[]; error?: string }> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getAutoCreateAnalysisStatus",
            { documentAnalysisID }
        );
    }

    setPackageNotes(pkgID: string, notes: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(this.ERECORD, "setPackageNotes", {
            id: pkgID,
            notes
        });
    }

    setCountyComments(
        pkgID: string,
        countyComments: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(this.ERECORD, "setCountyComments", {
            id: pkgID,
            countyComments
        });
    }

    setPackageName(
        pkg: SubmitterPackage,
        name: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(this.ERECORD, "setPackageName", {
            id: pkg.id,
            name
        });
    }

    setSubmitterPackageId(
        pkg: SubmitterPackage,
        submitterPackageId: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "setSubmitterPackageId",
            {
                id: pkg.id,
                submitterPackageId
            }
        );
    }

    setTertiaryPackageId(
        pkg: SubmitterPackage,
        tertiaryPackageId: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "setTertiaryPackageId",
            {
                id: pkg.id,
                tertiaryPackageId
            }
        );
    }

    setScheduledSubmitDate(
        pkg: SubmitterPackage,
        scheduledSubmitDate: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "setScheduledSubmitDate",
            {
                id: pkg.id,
                scheduledSubmitDate
            }
        );
    }

    setExpectedSubmissionDate(
        pkg: SubmitterPackage,
        expectedSubmissionDate: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "setExpectedSubmissionDate",
            {
                packageID: pkg.id,
                expectedSubmissionDate
            }
        );
    }

    setIsPaperPackage(
        pkg: SubmitterPackage,
        isPaper: boolean,
        historyDescription?: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(this.ERECORD, "setIsPaperPackage", {
            packageID: pkg.id,
            isPaper,
            historyDescription
        });
    }

    getArchivedPackages(packageIDs: string[]): Observable<SubmitterPackage[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getArchivedPackages",
            {
                packageIDs
            }
        );
    }

    setPaymentAccount(
        pkg: SubmitterPackage,
        newAccountID: string,
        ACCOUNT_TYPE: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(this.ERECORD, "setAccount", {
            id: pkg.id,
            newAccountID,
            ACCOUNT_TYPE
        });
    }

    moveDocumentToPosition(
        pkg: SubmitterPackage,
        documentId: string,
        toPosition: number
    ): Observable<string | void> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "moveDocumentToPosition",
                {
                    id: pkg.id,
                    documentId,
                    toPosition
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        pkg.id,
                        "change the order of documents within this package",
                        this.moveDocumentToPosition.bind(this),
                        pkg,
                        documentId,
                        toPosition
                    );
                })
            );
    }

    hasTransactions(pkg: SubmitterPackage): Observable<{
        hasTransactions: { pkg: boolean; [key: string]: boolean };
    }> {
        return this._rpcClient.makeRequest(this.ERECORD, "hasTransactions", {
            id: pkg.id
        });
    }

    getPackageHistory(
        packageID: string,
        includeDocumentHistory: boolean = false
    ): Observable<HistoryEventModel[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getPackageHistory", {
            packageID,
            includeDocumentHistory
        });
    }

    getDocumentHistory(documentID: string): Observable<HistoryEventModel[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getDocumentHistory", {
            documentID
        });
    }

    addDocument(
        packageID: string,
        kindOfInstrument: string,
        orgID: string
    ): Observable<AddDocumentResponse> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "addDocument",
                {
                    packageID,
                    orgID,
                    kindOfInstrument
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "add documents to this package",
                        this.addDocument.bind(this),
                        packageID,
                        kindOfInstrument,
                        orgID
                    );
                })
            );
    }

    addTemplateDocument(
        orgID: string,
        packageId: string,
        templateId: string
    ): Observable<AddTemplateResponse> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "addTemplateDocument",
                {
                    orgID,
                    packageId,
                    templateId
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageId,
                        "add template documents to this package",
                        this.addTemplateDocument.bind(this),
                        orgID,
                        packageId,
                        templateId
                    );
                })
            );
    }

    getDocument(documentID: string): Observable<SubmitterDocument> {
        return this._rpcClient.makeRequest(this.ERECORD, "getDocument", {
            documentID
        });
    }

    getDocumentFromESignDocumentID(
        eSignDocumentID: string
    ): Observable<SubmitterDocument> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getDocumentFromESignDocumentID",
            {
                eSignDocumentID
            }
        );
    }

    getDocuments(documentIDs: string[]): Observable<SubmitterDocument[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getDocuments", {
            documentIDs
        });
    }

    addDocumentKOI(
        packageID: string,
        documentID: string,
        newDocType: string,
        automaticallyMap: boolean
    ): Observable<void | string> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "addDocumentKOI",
                {
                    documentID,
                    newDocType,
                    automaticallyMap
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "change any document type within this package",
                        this.addDocumentKOI.bind(this),
                        packageID,
                        documentID,
                        newDocType,
                        automaticallyMap
                    );
                })
            );
    }

    changeDocumentKOI(
        packageID: string,
        documentID: string,
        kindOfInstrument: string
    ): Observable<string> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "changeDocumentKOI",
                {
                    documentID,
                    kindOfInstrument
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageID,
                        "change any document type within this package",
                        this.changeDocumentKOI.bind(this),
                        packageID,
                        documentID,
                        kindOfInstrument
                    );
                })
            );
    }

    associateHelperDocs(
        documentID: string,
        helperDocs: { [key: string]: string }[]
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "associateHelperDocs",
            { documentID, helperDocs }
        );
    }

    getPackageNotificationSubscription(
        packageID: string,
        subscriptionType: string
    ): Observable<PackageNotificationSubscription> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageNotificationSubscription",
            {
                packageID,
                subscriptionType
            }
        );
    }

    savePackageNotificationSubscription(
        uiPackageNotificationSubscription: PackageNotificationSubscription
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "savePackageNotificationSubscription",
            { uiPackageNotificationSubscription }
        );
    }

    removePackageNotificationSubscription(
        subscriptionID: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "removePackageNotificationSubscription",
            { subscriptionID }
        );
    }

    hasPackageNotificationSubscriptions(
        packageID: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "hasPackageNotificationSubscriptions",
            { packageID }
        );
    }

    getPackageStatusDetails(
        packageID: string,
        documentID?: string
    ): Observable<PackageStatusDetails> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageStatusDetails",
            { packageID, documentID }
        );
    }

    getDocumentValidationErrors(
        packageID: string,
        documentID: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getDocumentValidationErrors",
            { packageID, documentID }
        );
    }

    getDocumentVoidedEvents(
        packageID: string,
        documentID: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getDocumentVoidedEvents",
            { packageID, documentID }
        );
    }

    getDocumentPageIDs(
        packageID: string,
        documentID: string,
        includeHelperDocIDs: boolean
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getDocumentPageIDs", {
            packageID,
            documentID,
            includeHelperDocIDs
        });
    }

    rejectPackageToSubmitterSystem(
        packageID: string,
        reason?: string
    ): Observable<void | string> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "rejectPackageToSubmitterSystem",
            {
                packageID,
                reason
            }
        );
    }

    hasUncompletedRejectionEntry(packageID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "hasUncompletedRejectionEntry",
            { packageID }
        );
    }

    updateRejectionFilterEntry(
        packageID: string,
        action: string
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "updateRejectionFilterEntry",
            { packageID, action }
        );
    }

    recallPackage(packageID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(this.ERECORD, "recallPackage", {
            packageID
        });
    }

    editDocumentDataEntry(documentID: string): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "editDocumentDataEntry",
            { documentID }
        );
    }

    archivePackages(packageIDs: string[], archived: boolean): Observable<void> {
        return this._rpcClient.makeRequest(this.ERECORD, "archivePackages", {
            packageIDs,
            archived
        });
    }

    setReleaseToRecord(
        packageID: string,
        isReadyToRecord: boolean
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(this.ERECORD, "setReleaseToRecord", {
            packageID,
            isReadyToRecord
        });
    }

    getAssociatedDocumentID(documentID: string): Observable<string | null> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getAssociatedDocumentID",
            {
                documentID
            }
        );
    }

    packageCanBeRecalledImmediately(packageID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "packageCanBeRecalledImmediately",
            {
                packageID
            }
        );
    }

    getPackageTagsForSubmitters(submitterIDs: string[]): Observable<string[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageTagsForSubmitters",
            {
                submitterIDs
            }
        );
    }

    assignRecordedPackageHelperDocs(documentID: string): Observable<void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "assignRecordedPackageHelperDocs",
            {
                documentID
            }
        );
    }

    getPackageReceiptData(packageId: string): Observable<PackageReceiptData> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageReceiptData",
            { packageId }
        );
    }

    setInkSign(
        pkg: SubmitterPackage,
        isInkSign: boolean
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(this.ERECORD, "setInkSign", {
            packageID: pkg.id,
            isInkSign
        });
    }

    getPackageHoldDaysRemaining(packageID: string): Observable<number> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageHoldDaysRemaining",
            {
                packageID
            }
        );
    }

    setIsRushPackage(
        packageID: string,
        isRushPackage: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.ERECORD, "setIsRushPackage", {
            packageID,
            isRushPackage
        });
    }

    setReviewedErecordPlus(
        packageID: string,
        userIsRecordingPlusServicerForPackage: boolean
    ): Observable<string | void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "negateRecordingPlusReviewerEntries",
            {
                packageID,
                userIsRecordingPlusServicerForPackage
            }
        );
    }

    changeProject(
        packageID: string,
        newProjectID: string,
        newSubmitterID: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "changePackageProject",
            {
                packageID,
                newProjectID,
                newSubmitterID
            }
        );
    }

    correctPackageCheckAmount(
        packageId: string,
        feeAmount: number,
        voidExisting: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.ESIGN,
            "correctPackageCheckAmount",
            { packageId, feeAmount, voidExisting }
        );
    }

    voidChecksForPackage(packageId: string): Observable<void> {
        return this._rpcClient.makeRequest(this.ESIGN, "voidChecksForPackage", {
            packageId
        });
    }

    getEsignDocument(documentID: string): Observable<any> {
        return this._rpcClient.makeRequest(this.ERECORD, "getEsignDocument", {
            documentID
        });
    }

    getPackageCreditCardReceipt(packageID: string): Observable<string> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getPackageCreditCardReceipt",
            {
                packageID: packageID
            }
        );
    }

    changeTemplates(
        packageId: string,
        documentIds: string[],
        templateIds: string[]
    ): Observable<any> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "changeTemplates",
                {
                    packageId,
                    documentIds,
                    templateIds
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageId,
                        "change any templates in this package",
                        this.changeTemplates.bind(this),
                        packageId,
                        documentIds,
                        templateIds
                    );
                })
            );
    }

    duplicateDocument(
        packageId: string,
        documentId: string,
        createNewPackage: boolean,
        packageName: string,
        submitterPackageId: string,
        tertiaryPackageId: string
    ): Observable<any> {
        return this._rpcClient
            .makeRequest(
                this.ERECORD,
                "duplicateDocument",
                {
                    packageId,
                    documentId,
                    createNewPackage,
                    packageName,
                    submitterPackageId,
                    tertiaryPackageId
                },
                true
            )
            .pipe(
                catchError((err): Observable<any> => {
                    return this.catchSecureResubmitCorrectionError(
                        err,
                        packageId,
                        "duplicate any documents in this package",
                        this.duplicateDocument.bind(this),
                        packageId,
                        documentId,
                        createNewPackage,
                        packageName,
                        submitterPackageId,
                        tertiaryPackageId
                    );
                })
            );
    }

    convertFromSample(
        packageId: string,
        documentIds: string[]
    ): Observable<any> {
        return this._rpcClient.makeRequest(this.ERECORD, "convertFromSample", {
            packageId,
            documentIds
        });
    }

    getPackageStates(): Observable<String[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getPackageStates");
    }

    setLenderIdentifiers(
        packageID: string,
        lenderID: string,
        lenderLoanNumber: string
    ) {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "setLenderIdentifiers",
            {
                packageID,
                lenderID,
                lenderLoanNumber
            }
        );
    }

    removeSecureCorrectionsOption(packageID: string): Observable<void> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "removeSecureCorrectionsOption",
            { packageID }
        );
    }

    catchSecureResubmitCorrectionError<
        T extends any[],
        R extends Observable<any>
    >(
        err: { error: { errorMessage: string } },
        packageID: string,
        attemptedActionDescription: string,
        attemptedFunction: (...args: T) => Observable<R>,
        ...args: T
    ): Observable<R> {
        if (
            err?.error?.errorMessage ===
            "Change will void Secure Resubmit Corrections option."
        ) {
            return this.getPackage(packageID).pipe(
                switchMap((pkg: SubmitterPackage): Observable<R> => {
                    const modalRef: NgbModalRef = this._modalService.open(
                        SecureCorrectionsWarningDialogComponent
                    );
                    const modalInstance: SecureCorrectionsWarningDialogComponent =
                        modalRef.componentInstance as SecureCorrectionsWarningDialogComponent;

                    modalInstance.recipientName = pkg.recipient;
                    modalInstance.attemptedActionDescription =
                        attemptedActionDescription;
                    return from(modalRef.result).pipe(
                        switchMap((result: string): Observable<R> => {
                            if (result === "proceed") {
                                return this.removeSecureCorrectionsOption(
                                    packageID
                                ).pipe(
                                    switchMap((): Observable<R> => {
                                        return attemptedFunction(...args);
                                    })
                                );
                            } else {
                                this._growl.error("Change was not saved.");
                                return throwError({
                                    error: {
                                        errorMessage: "Change was not saved."
                                    }
                                });
                            }
                        })
                    );
                })
            );
        } else {
            this._growl.error(err.error.errorMessage);
            return throwError(err);
        }
    }

    createAlrDemoPackages(mers: boolean): Observable<void> {
        return this._rpcClient.makeRequest(
            this.ESIGN,
            "createAlrDemoPackages",
            { mers }
        );
    }

    getPackageRecipientsPausedStatuses(
        packageIDList: string[]
    ): Observable<PackageRecipientsPausedStatuses> {
        return this._rpcClient.makeRequest<PackageRecipientsPausedStatuses>(
            this.ERECORD,
            "getPackageRecipientsPausedStatuses",
            { packageIDList }
        );
    }
}
