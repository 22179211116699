<ng-container *ngIf="activeFilterCount | async as activeFilters">
    <sf-view-manager class="mr-4" *ngIf="viewsKey"
                     [filterChanged$]="filterChanged$"
                     [viewsKey]="viewsKey"
                     [viewSelected$]="viewSelected$">
    </sf-view-manager>
    <span class="sort-apply" [class.associate]="clearHover" #filterPanelOpener cdkOverlayOrigin>
        <button (click)="toggleFilterPanel()"
                id="editFiltersLink"
                class="btn btn-link sf-btn-link"
                [ngbTooltip]="tooltip"
                container="body">
            <fa-icon
                [icon]="['far', 'filter']"
                class="fas-filter"
                [class.pulse]="true"
                [class.opened]="open"
                aria-hidden="true"
                size="lg"
            ></fa-icon>
            {{activeFilters.count}}
            <span [ngPlural]="activeFilters.count">
                <ng-template ngPluralCase="=1">Filter</ng-template>
                <ng-template ngPluralCase="other">Filters</ng-template>
            </span>
            Applied
            <fa-icon [icon]="['fas', 'caret-down']" class="fas-caret-down"></fa-icon></button>
        <button (click)="doClear(true)"
                [hidden]="open || !clear || activeFilters.count === 0"
                class="btn btn-link sf-btn-link"
                (mouseover)="clearHover = true"
                (mouseleave)="clearHover = false"
                aria-label="Click to clear all filters"
                ngbTooltip="Click to clear all filters">
            <fa-icon [icon]="['fas', 'window-close']" class="clear"></fa-icon></button>
    </span>

    <span *ngIf="panelProperties.sortOptions" id="loanSortOrderSelect" ngbDropdown [ngbTooltip]="sortDescription">
        <button class="btn btn-link sf-btn-link dropdown-toggle sort-link ml-4" ngbDropdownToggle>
            <fa-icon [icon]="['far', 'signal']" [class]="sortIcon[panelProperties.currentSort?.direction]"></fa-icon>
            {{panelProperties.currentSort?.label}}
        </button>
        <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
            <li *ngFor="let option of panelProperties.sortOptions" ngbDropdownItem>
                <button class="btn btn-link sf-btn-link sort-option" (click)="doSort(option)">
                    <fa-icon [icon]="['far', 'check']"
                             [hidden]="panelProperties.currentSort?.id !== option.id"></fa-icon>
                    {{option.title}}
                </button>
            </li>
        </ul>
    </span>

    <ng-template cdk-portal>
        <div class="filter-panel" [class]="panelProperties.panelClass" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
            <div class="filter-header vertical-center">
                <div>
                    <button
                        id="filtersAppliedCount"
                        [ngbTooltip]="tooltip"
                        container="body"
                        cdkFocusInitial
                        (click)="doCancel()"
                        class="btn btn-link sf-btn-link filter-header__filter-count opened">
                        <fa-icon [icon]="['far', 'filter']" class="fas-filter" size="lg" aria-hidden="true"></fa-icon>
                        {{activeFilters.count}}
                        <span [ngPlural]="activeFilters.count">
                            <ng-template ngPluralCase="=1">Filter</ng-template>
                            <ng-template ngPluralCase="other">Filters</ng-template>
                        </span>
                        Applied
                    </button>
                    <span class="divider">|</span>
                    <button class="btn btn-secondary filter-header__clear"
                       (click)="doClear(false)"
                       [class]="{'clear-all-disabled': activeFilters.count === 0 }"
                       [disabled]="activeFilters.count === 0"
                       [ngbTooltip]="activeFilters.count === 0 ? 'No active filters are applied' : 'Click to clear all filters'">
                        Clear All
                    </button>
                </div>

                <div>
                    <button class="btn filter-header__apply btn-primary"
                            [class.disabled]="open && !(panelProperties.isValidated | async)"
                            [attr.disabled]="open && !(panelProperties.isValidated | async) ? '' : null"
                            (click)="doApply()">
                        Apply Filters
                    </button>
                    <button class="btn btn-secondary filter-header__cancel" (click)="doCancel()">Cancel</button>
                </div>
            </div>

            <div class="filter-panel-body">
                <ng-content></ng-content>
            </div>

        </div>
    </ng-template>

    <ng-template #tooltip>
        <div class="text-center" *ngIf="activeFilterDescription | async as description else noFilter">
            Current Filters: <br/>
            <span [innerHTML]="description"></span>
        </div>
    </ng-template>
    <ng-template #noFilter>{{panelProperties.noFilterDescription}}</ng-template>

</ng-container>
