import { Component, Input, OnInit } from "@angular/core";
import {
    OrganizationResult,
    UniversalResult
} from "../../universal-search.interfaces";
import { UniversalSearchComponentService } from "../universal-search-component.service";
import { UniversalSearchResultComponent } from "../universal-search-result/universal-search-result.component";

@Component({
    selector: "sf-organization-result",
    templateUrl: "./organization-result.component.html",
    styleUrls: ["./organization-result.component.scss"]
})
export class OrganizationResultComponent
    extends UniversalSearchResultComponent
    implements OnInit
{
    item: OrganizationResult;

    ngOnInit(): void {
        this.item = this.result as OrganizationResult;
    }
}
