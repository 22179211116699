import { Component, Input, OnInit } from "@angular/core";
import { Organization, ProductBasic } from "@sf/userorg/common";
import { ProductExtended } from "@sf/userorg/common";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-parent-product-inactive-dialog",
    templateUrl: "./parent-product-inactive-dialog.component.html",
    styleUrls: ["./parent-product-inactive-dialog.component.scss"]
})
export class ParentProductInactiveDialogComponent implements OnInit {
    @Input() organization: Organization;
    @Input() product: ProductBasic;
    @Input() parentProduct: ProductExtended;
    @Input() invitePending: boolean;

    primary: ModalButton;
    secondary: ModalButton;
    title = "Parent License Agreement Missing";
    sameProduct = false;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        // Modal buttons
        if (this.invitePending) {
            this.title = "Waiting to Accept Invitation";
            this.primary = {
                text: "Close",
                disabled: false,
                callback: this.dismiss.bind(this)
            };
        } else {
            this.primary = {
                text: "Send Invitation",
                disabled: false,
                callback: this.resolve.bind(this)
            };
            this.secondary = {
                text: "Close",
                disabled: false,
                callback: this.dismiss.bind(this)
            };
        }

        this.sameProduct =
            this.product.productID == this.parentProduct.productID;
    }

    dismiss() {
        this.activeModal.close(null);
    }

    resolve() {
        this.activeModal.close("invite");
    }
}
