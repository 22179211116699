import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { SessionService } from "@sf/common";
import { SharedFeesService } from "../shared-fees.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentRecordingFeeInfo, RecipientFeeData } from "../interfaces";
import {
    FormBuilder,
    FormArray,
    FormGroup,
    AbstractControl
} from "@angular/forms";
import * as clone from "clone";
import {
    distinctUntilChanged,
    map,
    pluck,
    skip,
    startWith,
    switchMap,
    withLatestFrom
} from "rxjs/operators";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { OrganizationService } from "@sf/userorg/common";

@Component({
    selector: "sf-fee-details",
    templateUrl: "./fee-details-modal.component.html",
    styleUrls: ["./fee-details-modal.component.scss"]
})
export class FeeDetailsModalComponent implements OnInit {
    /** I/O **/
    @Input() navigableDocumentIDList: string[];
    @Input() selectedDocToOpen?: number;

    @Input() surcharge?: number; //percentage to charge for surcharge
    @Input() ccSubmissionAccount?: any;
    @Input() ccRecordingAccount?: any;
    @Input() ccSalesTaxAccount?: any;
    @Input() ccTaxAccount?: any;
    @Input() ccMailAccount?: any;
    @Input() forceShowEstimatedFees?: boolean;
    @Input() locked?: boolean;
    @Input() docViewer?: boolean;

    /** Private Variables **/
    private _ccGroups: any[];
    private _fetchDocumentRecordingFeeInfo$ = new Subject<string>();
    private _documentRecordingFeeInfo$: BehaviorSubject<DocumentRecordingFeeInfo> =
        new BehaviorSubject(null);
    private _feeForm$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
    private _formChanges$ = this._feeForm$.pipe(
        skip(1),
        switchMap((form) => {
            return form.valueChanges.pipe(startWith(form.value));
        })
    );

    private _recipientFeeTotals$: Observable<{
        [id: string]: {
            actualTotal: number;
            estimateTotal: number;
        };
    }> = this._formChanges$.pipe(
        map(({ recipientFeeData }) => {
            return this._aggregateFees(recipientFeeData);
        })
    );

    /** Public Variables **/
    title = "Fee Details";
    primary: ModalButton = {
        text: "Save",
        callback: this.saveFeeData.bind(this)
    };
    secondary: ModalButton = {
        text: "Cancel"
    };
    isSuperUser: boolean;
    actualEditableBySuperUser: boolean;
    estimateEditableBySuperUser: boolean;

    maxAllowedIncrease = 0;
    totalFee: any;
    currentDocIndex = 0;
    paymentsScheduled: boolean;
    submissionFeeLabel: string;
    submissionFeeCategory: string;
    showMailFee: boolean = false;

    viewModel$: Observable<{
        recordingFeeInfo: DocumentRecordingFeeInfo;
        form: FormGroup;
        feeTotals: {
            [id: string]: {
                actualTotal: number;
                estimateTotal: number;
            };
        };
    }> = combineLatest([
        this._documentRecordingFeeInfo$.pipe(skip(1)),
        this._feeForm$.pipe(skip(1)),
        this._recipientFeeTotals$
    ]).pipe(
        map(([recordingFeeInfo, form, feeTotals]) => ({
            recordingFeeInfo,
            form,
            feeTotals
        }))
    );
    totalSurcharge: number = 0.0;

    /** View Children **/

    constructor(
        private _sessionService: SessionService,
        private _feesService: SharedFeesService,
        private _activeModal: NgbActiveModal,
        private _fb: FormBuilder
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        /* if we want superusers to have more functionality in the doc viewer we can remove
         the this.docViewer check (previously 'openedFromNewPackageAdmin' option from the
         package admin component) and use this._sessionService.isSuperUser() below */
        this.isSuperUser = this.docViewer
            ? false
            : this._sessionService.isSuperUser();
        this._fetchDocumentRecordingFeeInfo$
            .pipe(
                switchMap((documentID) => {
                    return this._feesService.getRecordingFeeInfo(documentID);
                })
            )
            .subscribe((recordingInfo) => {
                const recordingPlus = recordingInfo.recordingPlus;
                if (recordingPlus) {
                    this.submissionFeeLabel =
                        recordingPlus.submissionFeeLabel || "Submission Fee";
                    this.submissionFeeCategory =
                        recordingPlus.submissionFeeCategory;
                    this.showMailFee =
                        recordingPlus.submitterHasRecordingPlusContract &&
                        recordingPlus.showMailFees;
                }
                this.actualEditableBySuperUser =
                    this.isSuperUser && recordingInfo.showActualFees;
                this.estimateEditableBySuperUser =
                    this.isSuperUser &&
                    !recordingInfo.showActualFees &&
                    !this.locked;
                this.paymentsScheduled = this.actualEditableBySuperUser;
                this.primary.disabled =
                    !this._hasEditableFees(recordingInfo.recipientFeeData) &&
                    !this.isSuperUser;
                recordingInfo.showEstimatedFees =
                    recordingInfo.showEstimatedFees ||
                    this.forceShowEstimatedFees;
                this._documentRecordingFeeInfo$.next(recordingInfo);
                this._feeForm$.next(this._setupFeesForm(recordingInfo));
            });

        if (this.isSuperUser) {
            this._formChanges$
                .pipe(
                    pluck("submissionFees"),
                    distinctUntilChanged(),
                    withLatestFrom(
                        this._feeForm$,
                        this._documentRecordingFeeInfo$
                    )
                )
                .subscribe(([submissionFees, form, { salesTaxRate }]) => {
                    form.patchValue(
                        {
                            salesTax: parseFloat(
                                (submissionFees * salesTaxRate).toFixed(2)
                            )
                        },
                        { emitEvent: false }
                    );
                });
        }

        if (this.selectedDocToOpen) {
            this.currentDocIndex = this.selectedDocToOpen;
            this._fetchDocumentRecordingFeeInfo$.next(
                this.navigableDocumentIDList[this.selectedDocToOpen]
            );
        } else {
            this._fetchDocumentRecordingFeeInfo$.next(
                this.navigableDocumentIDList[0]
            );
        }
    }

    getRecipientFeeDataArray(formGroup: FormGroup): FormArray {
        return formGroup.get("recipientFeeData") as FormArray;
    }

    getFeeArrayControls(formGroup: AbstractControl): FormGroup[] {
        return (formGroup.get("fees") as FormArray).controls as FormGroup[];
    }

    /** Public Methods **/
    goToDocInPackage(direction: "next" | "previous") {
        if (
            direction === "next" &&
            this.currentDocIndex < this.navigableDocumentIDList.length - 1
        ) {
            this.currentDocIndex++;
        } else if (direction === "previous" && this.currentDocIndex > 0) {
            this.currentDocIndex--;
        }

        const currentDocumentID =
            this.navigableDocumentIDList[this.currentDocIndex];
        this._fetchDocumentRecordingFeeInfo$.next(currentDocumentID);
    }

    saveFeeData() {
        this._feesService
            .saveFeeData(
                this.navigableDocumentIDList[this.currentDocIndex],
                this._feeForm$.getValue().value,
                this.submissionFeeCategory,
                this.paymentsScheduled
            )
            .subscribe((feeTotal) => {
                this._activeModal.close({
                    feeTotal,
                    recipientFeeData:
                        this._feeForm$.getValue().value.recipientFeeData
                });
            });
    }

    zeroAllFees() {
        this._feeForm$.getValue().patchValue({
            submissionFees: 0,
            salesTax: 0,
            mailFees: 0
        });
        const feeControls = (
            this._feeForm$.getValue().controls.recipientFeeData as FormArray
        ).controls.flatMap((recipientData) => {
            return ((recipientData as FormGroup).controls.fees as FormArray)
                .controls;
        });
        feeControls.forEach((control) => {
            control.patchValue({
                actualQuantity: 0,
                actualOtherAmount: 0,
                estimateQuantity: 0,
                estimateOtherAmount: 0
            });
        });
    }

    manageTransactions() {
        window.location.href = `/sf/ui/admin/accounting/manage-transactions?pref=doc&documentID=${
            this.navigableDocumentIDList[this.currentDocIndex]
        }`;
    }

    /**  Private Methods  **/
    private _setupFeesForm(recordingFeeInfo: DocumentRecordingFeeInfo) {
        const recipientFeeData = clone(recordingFeeInfo.recipientFeeData);
        const feeInfoForm = this._fb.group({
            recipientFeeData: this._fb.array([])
        });
        feeInfoForm.registerControl(
            "salesTax",
            this._fb.control(recordingFeeInfo.salesTax)
        );
        feeInfoForm.registerControl(
            "showActualFees",
            this._fb.control(recordingFeeInfo.showActualFees)
        );
        feeInfoForm.registerControl(
            "showEstimatedFees",
            this._fb.control(recordingFeeInfo.showEstimatedFees)
        );
        feeInfoForm.registerControl(
            "submissionFees",
            this._fb.control(recordingFeeInfo.submissionFees)
        );
        feeInfoForm.registerControl(
            "mailFees",
            this._fb.control(recordingFeeInfo.mailFees)
        );

        for (let recipientData of recipientFeeData) {
            const fees = recipientData.fees;
            delete recipientData.fees;
            const recipientArray = this._fb.group(recipientData);
            recipientArray.registerControl("fees", this._fb.array([]));
            for (let fee of fees) {
                const group = this._fb.group(clone(fee));
                (recipientArray.get("fees") as FormArray).push(group);
            }
            (feeInfoForm.controls.recipientFeeData as FormArray).push(
                recipientArray
            );
        }

        return feeInfoForm;
    }

    private _calculateSurchargeFee(recipientFeeData: RecipientFeeData[]) {
        let fg: FormGroup = this._feeForm$.getValue();
        if (this.surcharge) {
            this.totalSurcharge = 0.0;
            this._ccGroups = [];
            if (!!this.ccSubmissionAccount) {
                this._determineGroup(
                    this.ccSubmissionAccount.id,
                    parseFloat(fg.controls.submissionFees.value)
                );
            }
            if (!!this.ccSalesTaxAccount) {
                this._determineGroup(
                    this.ccSalesTaxAccount.id,
                    parseFloat(fg.controls.salesTax.value)
                );
            }
            if (!!this.ccMailAccount) {
                this._determineGroup(
                    this.ccMailAccount.id,
                    parseFloat(fg.controls.mailFees.value)
                );
            }
            //iterate through all the fees, separating out taxes
            let totalRecording: number = 0;
            let totalTaxes: number = 0;
            for (let recipientData of recipientFeeData) {
                const fees = recipientData.fees;
                for (let fee of fees) {
                    // Other is part of recording fees
                    let amount: number = 0;
                    if (this.actualEditableBySuperUser) {
                        amount = parseFloat(
                            fee.type == "Other"
                                ? fee.actualOtherAmount
                                : fee.actualQuantity
                        );
                    } else {
                        amount = parseFloat(
                            fee.type == "Other"
                                ? fee.estimateOtherAmount
                                : fee.estimateQuantity
                        );
                    }
                    if (fee.tax) {
                        totalTaxes += amount;
                    } else {
                        totalRecording += amount;
                    }
                }
            }
            if (!!this.ccRecordingAccount) {
                this._determineGroup(
                    this.ccRecordingAccount.id,
                    totalRecording
                );
            }
            if (!!this.ccTaxAccount) {
                this._determineGroup(this.ccTaxAccount.id, totalTaxes);
            }
            this.totalSurcharge = this._getTotalSurcharge();
        }
    }

    private _getTotalSurcharge(): number {
        let total: number = 0;
        if (this.surcharge) {
            this._ccGroups.forEach((group: any) => {
                total += this._round(group.amount * (this.surcharge / 100), 2);
            });
        }
        return total;
    }

    private _round(value: number, decimals: number): number {
        return Number(
            Math.round(Number(value + "e" + decimals)) + "e-" + decimals
        );
    }

    private _determineGroup(accountID: string, feeAmount: number) {
        let foundGroup: any = this._ccGroups.find((group: any) => {
            return group.id == accountID;
        });
        if (!!foundGroup) {
            foundGroup.amount += feeAmount;
        } else {
            this._ccGroups.push({
                id: accountID,
                amount: feeAmount
            });
        }
    }

    private _aggregateFees(recipientFeeData: RecipientFeeData[]) {
        this.totalFee = 0;
        const feeTotals: any = {};
        for (let recipientData of recipientFeeData) {
            if (!recipientData.fees) {
                recipientData.fees = [];
            }
            const actualTotal = recipientData.fees.reduce((acc, curr) => {
                if (curr.type === "Other") {
                    return acc + curr.actualOtherAmount;
                } else {
                    const amount = Math.abs(curr.amount);
                    const quantity = parseFloat(curr.actualQuantity);
                    return acc + amount * quantity;
                }
            }, 0);
            const estimateTotal = recipientData.fees.reduce((acc, curr) => {
                if (curr.type === "Other") {
                    return acc + curr.estimateOtherAmount;
                } else {
                    const amount = Math.abs(curr.amount);
                    const quantity = parseFloat(curr.estimateQuantity);
                    return acc + amount * quantity;
                }
            }, 0);
            this.totalFee += actualTotal;

            feeTotals[recipientData.recipientID] = {
                actualTotal,
                estimateTotal
            };
        }

        //calculate surcharge information
        this._calculateSurchargeFee(recipientFeeData);

        return feeTotals;
    }

    private _hasEditableFees(recipientFeeData: RecipientFeeData[]) {
        let hasEditable = false;
        recipientFeeData.forEach((feeData) => {
            feeData.fees.forEach((feeItem) => {
                if (
                    feeItem.actualOverridable ||
                    feeItem.estimateOverridable ||
                    feeItem.actualOtherOverridable ||
                    feeItem.estimateOtherOverridable
                ) {
                    hasEditable = true;
                }
            });
        });
        return hasEditable;
    }
}
