export enum RoomStatusType {
    // PUSH: there is no push associated with this status
    // initial state when the room is created
    // give participant initial room screen with just the "Welcome, [name]!" message
    NOTARY_NOT_PRESENT = "NOTARY_NOT_PRESENT",

    // PUSH: when the notary starts editing tags
    // at this point the participants are paused in any signing they are doing
    NOTARY_IS_TAGGING = "NOTARY_IS_TAGGING",

    // PUSH: when the notary signs in
    // at this point the participants are given the option to verify identity
    NOTARY_HAS_ARRIVED = "NOTARY_HAS_ARRIVED",

    // PUSH: when the last participant finishes their KBA
    NOTARY_APPROVING_IDS = "NOTARY_APPROVING_IDS",

    // PUSH: when the last participant is finished with all setup and ID has been verified by the notary
    ALL_PARTICIPANTS_READY = "ALL_PARTICIPANTS_READY",

    // PUSH: once all participants have joined and the notary starts the recording
    // this status text remains until the notary has recited any state specific requirements
    // if there are no state specific requirements then we do a push of the next status after waiting 4 seconds
    NOTARY_SESSION_STARTED = "NOTARY_SESSION_STARTED",

    // PUSH: when a notary indicates finished reciting state specific requirements
    // PUSH: if there are no state specific requirements push 4 seconds after pushing the NOTARY_STARTED_RECORDING status
    NOTARY_SELECTING_FIRST_SIGNER = "NOTARY_SELECTING_FIRST_SIGNER",

    // PUSH:  when a participant selected by the notary begins signing their first document
    // if the participant that is selected receives this message then the FE presents the "It's Your Turn To Sign!" text with the continue button
    // all other participants get the text "[name] is signing.."
    PARTICIPANT_IS_SIGNING = "PARTICIPANT_IS_SIGNING",

    // PUSH: whenever a non-ultimate participant finishes signing
    NOTARY_SELECTING_NEXT_SIGNER = "NOTARY_SELECTING_NEXT_SIGNER",

    // PUSH: whenever an ultimate participant finishes signing
    NOTARY_IS_NOTARIZING = "NOTARY_IS_NOTARIZING",

    // PUSH: when the notary finishes notarizing
    // FE will show "Thank You!" text and participants given button to "Leave Video Session"
    // IF a participant is a signer they are also given an option to "Download Documents"
    SIGNING_COMPLETE = "SIGNING_COMPLETE",
    SESSION_COMPLETE = "SESSION_COMPLETE",

    // PUSH: if the notary cancels the event
    CANCELED = "CANCELED",
    VOIDED = "VOIDED",
    EXPIRED = "EXPIRED",
    // IF a participant gets disconnected for signingSession or notary looses connection
    SESSION_PAUSED = "SESSION_PAUSED"
}

export enum RoomParticipantStatusType {
    /***** HAPPY PATH FOR SIGNING PARTICIPANT ****/

    // PUSH: there is no push associated with this status
    // when the notary arrives in the room they should get the current status of each participant
    NOT_SIGNED_IN = "NOT_SIGNED_IN",

    COMPLETING_TERMS_AND_PRIVACY = "COMPLETING_TERMS_AND_PRIVACY",
    COMPLETING_E_CONSENT = "COMPLETING_E_CONSENT",

    // PUSH: If have Non-Witnessed Documents we push this once they are logged in
    // and have finished all the privacy, terms, eConsent etc.
    // Otherwise don't push and go directly to ID_VERIFICATION
    SIGNING_NON_WITNESSED_DOCUMENTS = "SIGNING_NON_WITNESSED_DOCUMENTS",

    // PUSH: When done with SIGNING_NON_WITNESSED_DOCUMENTS
    // Or if there aren't any then right after login
    COMPLETING_AUDIO_VIDEO = "COMPLETING_AUDIO_VIDEO",

    // PUSH: When done with COMPLETING_AUDIO_VIDEO and the participant has the
    // interpreter role and the notary is commissioned in the state of Colorado
    CONFIRMING_INTERPRETER_DETAILS = "CONFIRMING_INTERPRETER_DETAILS",

    // PUSH: When done with COMPLETING_AUDIO_VIDEO
    // Or if there aren't any then right after login
    COMPLETING_ID_VERIFICATION = "COMPLETING_ID_VERIFICATION",

    // PUSH: we push this status when we get the questions from LexisNexis
    COMPLETING_KBA = "COMPLETING_KBA",

    // PUSH: if there is state specific we push this once they are done with the KBA
    COMPLETING_STATE_SPECIFIC_REQUIREMENTS = "COMPLETING_STATE_SPECIFIC_REQUIREMENTS",

    SIGNED_IN = "SIGNED_IN",

    // PUSH: After State specific requirements or if none then after completing KBA
    AWAITING_NOTARY_ID_APPROVAL = "AWAITING_NOTARY_ID_APPROVAL",

    // PUSH: when all the ID and State specific stuff is done and they are not the last participant joining
    // if they are the last participant joining then this isn't pushed for them and they just get READY_TO_SIGN
    ID_VERIFIED = "ID_VERIFIED",

    // PUSH: when the last participant finishes ID_VERIFICATION this gets pushed out for all signing participants
    READY_TO_SIGN = "READY_TO_SIGN",

    // PUSH: when the notary selects them to be the next signers
    SIGNING_WITNESSED_DOCUMENTS = "SIGNING_WITNESSED_DOCUMENTS",

    // ???
    PARTIALLY_SIGNED = "PARTIALLY_SIGNED",

    // PUSH: when they are done signing
    SIGNING_COMPLETE = "SIGNING_COMPLETE",

    /***** FOR NON-SIGNING PARTICIPANT ONLY ****/
    NON_SIGNING_CONNECTED = "NON_SIGNING_CONNECTED",

    /***** PROBLEMS ****/
    FAILED_AV_SETUP = "FAILED_AV_SETUP",
    FAILED_ID_VERIFICATION = "FAILED_ID_VERIFICATION",
    LOCKED_ID_VERIFICATION = "LOCKED_ID_VERIFICATION",
    FAILED_KBA = "FAILED_KBA",
    KBA_SERVICE_ERROR = "KBA_SERVICE_ERROR",
    TOO_MANY_FAILED_ID_INFO_ATTEMPTS = "TOO_MANY_FAILED_ID_INFO_ATTEMPTS",
    NOTARY_REJECTED_ID = "NOTARY_REJECTED_ID",
    TOO_MANY_FAILED_LOGIN_ATTEMPTS = "TOO_MANY_FAILED_LOGIN_ATTEMPTS",
    TOO_MANY_VERIFY_CODES_SENT = "TOO_MANY_VERIFY_CODES_SENT",

    // If participant gets disconnected
    DISCONNECTED = "DISCONNECTED",

    // Participant has disabled their invite
    INVITE_DISABLED = "INVITE_DISABLED"
}

export interface UIRoomParticipantStatus {
    participantID: string;
    type: RoomParticipantStatusType;
    date: string;
}
