import { Injectable } from "@angular/core";
import { webSocket } from "../rxjs/webSocket";
import { WebSocketSubject, WebSocketSubjectConfig } from "../rxjs/WebSocketSubject";
import { LongPollingService } from "./long-polling.service";

@Injectable({
    providedIn: "root"
})
export class SocketFactoryService {
    constructor(private _longPolling: LongPollingService) {}

    getSocket<T>(
        urlOrConfig: string | WebSocketSubjectConfig<T>
    ): WebSocketSubject<T> {
        if (this.socketSupported()) {
            return webSocket<T>(urlOrConfig);
        }

        return new WebSocketSubject(
            this._longPolling.poll$,
            this._longPolling.send
        );
    }

    socketSupported(): boolean {
        return (
            typeof WebSocket !== "undefined" && !("__initialize" in WebSocket)
        );
    }

    // getLongPollingSource<T>(): Observable<T> {
    //     const whenToRefresh$ = of(null).pipe(
    //         delay(5000),
    //         tap((_) => this._refreshPoll$.next(null)),
    //         skip(1)
    //     );
    //
    //     const longPoll$ = this._http.post("/sf/longpoll/poll", {});
    //
    //     const poll$ = concat(longPoll$, whenToRefresh$);
    //
    //     return this._refreshPoll$.pipe(concatMapTo(poll$));
    // }

    // getLongPollingDestination<T>(): Observer<T> {}
}
