<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div class="vertical-space" *ngIf="!supportedProduct">
        <span>The option to enable the {{product.label}} service is not available yet.</span>
    </div>

    <div class="vertical-space" *ngIf="activating">
            <span>Activating a limited trial for the &quot;{{product.label}}&quot; service will allow
                {{organization.name}} to try out its functionality. Billable events are not allowed
                until the service is activated and a license is accepted.</span>
    </div>

    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <div *ngIf="supportedProduct">
        <!-- if activating -->
        <div class="vertical-space" *ngIf="activating">
            <div *ngIf="product.productID!='submitter_vendor' && product.productID!='recipient_vendor' && product.productID!='lender_vendor'"
                    class="row bottom-space">
                <div class="col-sm-6">
                    Enrollment Code:
                </div>
                <div class="col-sm-6">
                    <span *ngIf="!isEnrollmentCodeVisible">
                        <button class="btn btn-link sf-btn-link" (click)="showEnrollmentCode()">
                            Add Enrollment Code
                        </button>
                    </span>
                    <span *ngIf="isEnrollmentCodeVisible">
                        <b> {{enrollmentCode}} </b>
                        <button type="button" class="btn btn-secondary btn-small" (click)="changeEnrollmentCode()">
                            Change
                        </button>
                    </span>
                </div>
            </div>

            <!-- submitter e-record -->
            <div *ngIf="product.productID=='submitter'" class="">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="licenseFeeSubmitter">Initial License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="licenseFeeSubmitter" [(ngModel)]="licenseFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="renewalFeeSubmitter">Renewal License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="renewalFeeSubmitter" [(ngModel)]="renewalFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="submissionFee">Submission Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="submissionFee" [(ngModel)]="submissionFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="freeSubmissions">Free Submissions:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="freeSubmissions" class="form-control input-short"
                                [disabled]="true"
                                [(ngModel)]="freeSubmissions" maxlength="8"/>
                    </div>
                </div>
            </div>

            <!-- document builder -->
            <div *ngIf="product.productID=='submitter_signing'" class="">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="licenseFeeSigning">Initial License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="licenseFeeSigning" [(ngModel)]="licenseFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="renewalFeeSigning">Renewal License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="renewalFeeSigning" [(ngModel)]="renewalFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="documentFee">Document Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="documentFee" [(ngModel)]="documentFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="freeDocuments">Free Documents:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="freeDocuments" [disabled]="true" class="form-control input-short"
                                [(ngModel)]="freeSubmissions" maxlength="8"/>
                    </div>
                </div>
            </div>

            <!-- esign_event -->
            <div *ngIf="product.productID=='esign_event'" class="">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="licenseFeeEsignevent">Initial License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="licenseFeeEsignevent" [(ngModel)]="licenseFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="renewalFeeEsignevent">Renewal License Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="renewalFeeEsignevent" [(ngModel)]="renewalFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="notarizationFeeEsignevent">No e-notarization Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="notarizationFeeEsignevent" [(ngModel)]="notarizationFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="ipenFeeEsignevent">IPEN Notary Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="ipenFeeEsignevent" [(ngModel)]="ipenFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="ronFeeEsignevent">RON Notary Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="ronFeeEsignevent" [(ngModel)]="ronFee"
                                [disabled]="true" required
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="freeEvents">Free Transactions:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="freeEvents" class="form-control input-short"
                                [disabled]="true" required
                                [(ngModel)]="freeSubmissions" maxlength="8"/>
                    </div>
                </div>
            </div>

            <!-- trustee -->
            <div *ngIf="product.productID=='trustee'" class="">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="documentFeeTrustee">Document Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="documentFeeTrustee" [(ngModel)]="documentFee"
                                [disabled]="true"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
            </div>

            <!-- paper recording -->
            <div *ngIf="product.productID=='submitter_paper'">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="instateerecordfee">In-State eRecord Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="instateerecordfee" [(ngModel)]="inStateErecordFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="outstateerecordfee">Out-of-State eRecord Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="outstateerecordfee" [(ngModel)]="outOfStateErecordFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="instatepaperfee">In-State Paper Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="instatepaperfee" [(ngModel)]="inStatePaperFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="outstatepaperfee">Out-of-State Paper Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="outstatepaperfee" [(ngModel)]="outOfStatePaperFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="papermailfee">Paper Mail Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="papermailfee" [(ngModel)]="paperMailFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="embeddedemployeefee">Embedded Employee Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="embeddedemployeefee" [(ngModel)]="embeddedEmployeeFee"
                                class="form-control money-input" maxlength="10"/>
                    </div>
                </div>
            </div>

            <!-- vendor -->
            <div *ngIf="product.productID=='submitter_vendor' || product.productID=='recipient_vendor' || product.productID=='lender_vendor'">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Revenue Share Option:</label>
                    </div>
                    <div class="col-sm-6">
                        <sf-select class=""
                                [selectedOption]="selectedShareOption.id"
                                [options]="selectableShareOptions"
                                (select)="selectShareOption($event)"
                                trackBy="id" labelBy="label"
                                [isSingleSelect]="true" placeholder="Select an option..."
                                [required]="true">
                        </sf-select>
                    </div>
                </div>
                <div *ngIf="selectedShareOption.id == 'PERCENT'" class="row">
                    <div class="col-sm-6">
                        <label for="feeflat">Submission Fee Percentage:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="feeflat" class="form-control money-input" maxlength="8"
                                [(ngModel)]="submissionFee" [required]="true"/>
                        <span>%</span>
                    </div>
                </div>
                <div *ngIf="selectedShareOption.id == 'PERCENT'" class="row">
                    <div class="col-sm-6">
                        <label for="exfeeflat">Exclusive Submission Fee Percentage:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="exfeeflat" class="form-control money-input" maxlength="8"
                                [(ngModel)]="exclusiveSubmissionFee" [required]="true"/>
                        <span>%</span>
                    </div>
                </div>
                <div *ngIf="selectedShareOption.id == 'FLAT'" class="row">
                    <div class="col-sm-6">
                        <label for="feepercent">Per Document Flat Amount:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="feepercent" class="form-control money-input" maxlength="10"
                                [(ngModel)]="submissionFee" [required]="true"/>
                    </div>
                </div>
                <div *ngIf="selectedShareOption.id == 'FLAT'" class="row">
                    <div class="col-sm-6">
                        <label for="exfeepercent">Exclusive Per Document Flat Amount:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="exfeepercent" class="form-control money-input" maxlength="10"
                                [(ngModel)]="exclusiveSubmissionFee" [required]="true"/>
                    </div>
                </div>
            </div>

            <!-- licensed collaborator -->
            <div *ngIf="product.productID=='licensed_collaborator_lender' || product.productID=='licensed_collaborator_settlement'">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="loanCreateFee">Loan Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="loanCreateFee" [(ngModel)]="loanCreateFee" maxlength="10"
                                [disabled]="true" class="form-control money-input"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="renewalFee2">Renewal Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="renewalFee2" [(ngModel)]="renewalFee" maxlength="10"
                                [disabled]="true" class="form-control money-input"/>
                    </div>
                </div>
                <div *ngIf="product.productID=='licensed_collaborator_settlement'">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="disbursementFee">Disbursement Fee:</label>
                        </div>
                        <div class="col-sm-6">
                            <span>$</span>
                            <input type="text" id="disbursementFee" [(ngModel)]="disbursementFee" maxlength="10"
                                    [disabled]="true" class="form-control money-input"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="verifiedInsecureFee">Verified w/o Secure Disbursement Fee:</label>
                        </div>
                        <div class="col-sm-6">
                            <span>$</span>
                            <input type="text" id="verifiedInsecureFee" [(ngModel)]="verifiedInsecureFee" maxlength="10"
                                    [disabled]="true" class="form-control money-input"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="freeLoans">Free Loans:</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" id="freeLoans" class="form-control input-short" [disabled]="true"
                                [(ngModel)]="freeSubmissions" maxlength="8"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label *ngIf="product.productID != 'submitter'">Billing Cycle:</label>
                    <label *ngIf="product.productID == 'submitter'">Submission Fees Billing Cycle:</label>
                </div>
                <div class="col-sm-6">
                    <sf-select
                            [selectedOption]="billingCycle"
                            [options]="selectableBillingCycles"
                            (select)="selectBillingCycle($event)"
                            trackBy="id" labelBy="label"
                            [isSingleSelect]="true" placeholder="Not selected"
                            [isDisabled]="!!billingCycle">
                    </sf-select>
                </div>
            </div>
            <div *ngIf="product.productID == 'submitter'" class="row">
                <div class="col-sm-6">
                    <label>Recording Fees Billing Cycle:</label>
                </div>
                <div class="col-sm-6">
                    <sf-select
                            [selectedOption]="recordingFeeBillingCycle"
                            [options]="selectableBillingCycles"
                            (select)="selectRecordingFeeBillingCycle($event)"
                            trackBy="id" labelBy="label"
                            [isSingleSelect]="true" placeholder="Not selected">
                    </sf-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label>Payment Terms:</label>
                </div>
                <div class="col-sm-6">
                    <sf-select
                            [selectedOption]="paymentTerm"
                            [options]="selectablePaymentTerms"
                            (select)="selectPaymentTerm($event)"
                            trackBy="id" labelBy="label"
                            [isSingleSelect]="true" placeholder="Not selected"
                            [isDisabled]="!!paymentTerm">
                    </sf-select>
                </div>
            </div>
            <div class="row" *ngIf="product.productID=='esign_event'">
                <div class="col-sm-6 input-label">License Renewal Frequency:</div>
                <div class="col-sm-6">
                    <sf-select class="standard-spacing"
                            [selectedOption]="renewalFrequency"
                            [options]="selectableRenewalFrequencies"
                            (select)="selectRenewalFrequency($event)"
                            trackBy="option" labelBy="label"
                            [isSingleSelect]="true"
                            placeholder="Not selected">
                    </sf-select>
                </div>
            </div>
        </div>

        <!-- if users will be removed from organization -->
        <div class="vertical-space" *ngIf="!activating && deletedUserNames.length">
            <div class="">
                <span class="">Caution: </span>
                <span [ngPlural]="deletedUserNames.length">
                    <ng-template ngPluralCase="1"> One user</ng-template>
                    <ng-template ngPluralCase="other"> {{deletedUserNames.length}} users</ng-template>
                </span>
                <span> will be removed from the organization by this action.</span>
            </div>
            <div>
                <button class="btn btn-link sf-btn-link" (click)="showDeletedUsers = !showDeletedUsers"
                        *ngIf="!showDeletedUsers">Show users to be removed
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showDeletedUsers = !showDeletedUsers"
                        *ngIf="showDeletedUsers">Hide users to be removed
                </button>
            </div>
            <div class="scroll-container" *ngIf="showDeletedUsers">
                <div *ngFor="let user of deletedUserNames">{{user}}</div>
            </div>
        </div>

        <!-- users who will lose roles -->
        <div class="vertical-space" *ngIf="!activating && affectedUserNames.length">
            <div class="">
                <span>Be aware that this will disassociate</span>
                <span [ngPlural]="affectedUserNames.length">
                    <ng-template ngPluralCase="1"> one user</ng-template>
                    <ng-template ngPluralCase="other"> {{affectedUserNames.length}} users</ng-template>
                </span>
                <span> from their &quot;{{product.label}}&quot; roles.</span>
            </div>
            <div>
                <button class="btn btn-link sf-btn-link" (click)="showAffectedUsers = !showAffectedUsers"
                        *ngIf="!showAffectedUsers">Show affected users
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showAffectedUsers = !showAffectedUsers"
                        *ngIf="showAffectedUsers">Hide affected users
                </button>
            </div>
            <div class="scroll-container" *ngIf="showAffectedUsers">
                <div *ngFor="let user of affectedUserNames">{{user}}</div>
            </div>
        </div>

        <!-- if deactivating -->
        <!-- administrators to receive email notification -->
        <div *ngIf="loadingAdmins">
            <fa-icon icon="spinner" [spin]="true"></fa-icon>
        </div>
        <div class="" *ngIf="administratorUsers.length">
            <hr>
            <label>
                <input type="checkbox" [(ngModel)]="sendNotification">
                <span> {{getSelectedAdministratorCount()}} administrators at
                    {{organization.name}} will receive an email notification.</span>
            </label>
            <div *ngIf="sendNotification">
                <button class="btn btn-link sf-btn-link" (click)="showAdministrators = !showAdministrators"
                        *ngIf="!showAdministrators">
                    Show email recipients
                </button>
                <button class="btn btn-link sf-btn-link" (click)="showAdministrators = !showAdministrators"
                        *ngIf="showAdministrators">
                    Hide email recipients
                </button>
            </div>
            <div *ngIf="!sendNotification">
                <span class="small-text">Notification disabled</span>
            </div>
        </div>
        <div class="scroll-container" *ngIf="showAdministrators && sendNotification">
            <div *ngFor="let administrator of administratorUsers">
                <label>
                    <input type="checkbox" [(ngModel)]="administrator.selected"/>
                    <span> {{administrator.name}} ({{administrator.email}})</span>
                </label>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer *ngIf="!requestSent" [primary]="primary" [secondary]="secondary"></sf-modal-footer>
<div *ngIf="requestSent" class="bottom-spinner">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>
