import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EsignOrganizationService } from "../../services/esign-organization.service";
import { StatesService } from "@sf/common";

@Component({
    selector: "sf-document-builder-guide-entity-type",
    templateUrl: "./document-builder-guide-entity-type.component.html",
    styleUrls: ["./document-builder-guide-entity-type.component.scss"]
})
export class DocumentBuilderGuideEntityTypeComponent implements OnInit {
    @Input()
    adminOrganizationId: string;
    @Output()
    complete = new EventEmitter<boolean>();

    states = this._statesService.getAllStatesPlusDC();
    entityTypes = this._esignOrganizationService.getEntityTypes();
    entityType: string;
    entityState: string;

    constructor(
        private _esignOrganizationService: EsignOrganizationService,
        private _statesService: StatesService
    ) {}

    ngOnInit(): void {
        this._esignOrganizationService
            .getLegalEntityTypeAndState(this.adminOrganizationId)
            .subscribe((entityInfo) => {
                this.entityType = entityInfo.legalEntityType;
                this.entityState = entityInfo.legalEntityState;
            });
    }

    updateEntityType(entityType: string) {
        this.entityType = entityType;
        if (this.entityType && this.entityState) {
            this._setLegalEntityTypeAndState();
        }
    }

    updateEntityState(entityState: string) {
        this.entityState = entityState;
        if (this.entityType && this.entityState) {
            this._setLegalEntityTypeAndState();
        }
    }

    _setLegalEntityTypeAndState() {
        this._esignOrganizationService
            .setLegalEntityTypeAndState(
                this.adminOrganizationId,
                this.entityType,
                this.entityState
            )
            .subscribe(() => {
                this.complete.emit(true);
            });
    }
}
