import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
    CopyTextService,
    getAllRouteData,
    getLinkWithChangedParams,
    getParamsFromRoute,
    scheduleMicroTask,
    SelectableItem,
    SessionService
} from "@sf/common";
import { PageHeaderComponent } from "@sf/headers";
import { Subject, Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { sampleTime, takeUntil } from "rxjs/operators";
import {
    CurrentOrganizationService,
    Organization,
    OrganizationSubscriptionService
} from "@sf/userorg/common";

interface ExtendedOrganization extends Organization {
    statusTooltip?: string;
}

// prettier-ignore
@Component({
    selector: "sf-org-header-with-selector",
    templateUrl: "./org-header-with-selector.component.html",
    styleUrls: ["./org-header-with-selector.component.scss"]
})
export class OrgHeaderWithSelectorComponent
    implements OnInit, OnDestroy, PageHeaderComponent
{
    public pageData: any;
    private _onDestroy$: Subject<void> = new Subject();
    private updater: Subscription = null;

    /* Public Variables */
    selectedOrganization: Partial<ExtendedOrganization> = {
        id: null
    };
    hasMultipleOrganizations: boolean = false;
    selectableOrgServices: string[] = [];
    selectableOrgPermissions: string[] = [];
    urlIsAbsolute: boolean;

    /** Private Variables */
    showSaveChanges = false;

    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy Organization ID"
        },
        {
            option: "copyName",
            label: "Copy Organization Name"
        }
    ];

    constructor(
            private currentOrganizationService: CurrentOrganizationService,
            private _route: ActivatedRoute,
            private router: Router,
            private sessionService: SessionService,
            private copyService: CopyTextService,
            private organizationSubscriptionService: OrganizationSubscriptionService
    ) {}

    ngOnInit() {
        this.hasMultipleOrganizations =
            this.sessionService.getAllOrganizationCount() > 1;

        // look for route data that could influence selection -----
        let routeData = getAllRouteData(this._route);

        if (routeData.selectableOrgServices) {
            this.selectableOrgServices = routeData.selectableOrgServices;
        }
        if (routeData.selectableOrgPermissions) {
            this.selectableOrgPermissions = routeData.selectableOrgPermissions;
        }

        // should seldom be used
        if (typeof routeData.showSave === "boolean") {
            this.showSaveChanges = routeData.showSave;
        }

        // get the selected organization
        this.pickInitialOrg();

        // update the display if the organization status or name changes (by somebody editing it)
        this.currentOrganizationService.currentOrganization$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((org) => {
                if (org) {
                    this.selectedOrganization = org;
                    this.subscribeToChanges();
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new organization
            this.updater.unsubscribe();
        }
        this.updater = this.organizationSubscriptionService.subscribeToUpdates(this.selectedOrganization.id)
               .pipe(
                       takeUntil(this._onDestroy$),
                       sampleTime(1000)
               )
               .subscribe((msg: any) => {
                   this.setSelectedOrg(this.selectedOrganization as Organization);
               });
    }

    pickInitialOrg() {
        let initialOrg: Organization = null;
        let currentOrg: Organization = this.currentOrganizationService.getCurrentOrganization();

        // look for orgID in URL
        let params = getParamsFromRoute(this._route, ["orgID"]);
        if (params.orgID && params.orgID !== "_") {
            // an orgID was specified in the URL, so it takes priority
            if (currentOrg && params.orgID == currentOrg.id) {
                initialOrg = currentOrg;
            } else {
                // this gets a partial organization, which we hope is only temporary
                initialOrg = <Organization>(
                    (<any>(
                        this.sessionService.getPartialOrganization(params.orgID)
                    ))
                );
                this.currentOrganizationService.updateCurrentOrganization(
                    initialOrg
                );
            }
            if (initialOrg) {
                this.selectedOrganization = initialOrg;
                return; // done
            }
        }

        // try the 'current' org
        initialOrg = currentOrg;
        if (initialOrg) {
            this.selectedOrganization = initialOrg;
            return; //done
        }
    }

    navigateToOrgID(orgID: string) {
        let extras: NavigationExtras = {
            relativeTo: this._route,
            queryParamsHandling: "preserve"
        };
        let link = getLinkWithChangedParams(
            this._route,
            {
                orgID
            },
            true
        );
        if (orgID && !link.includes(orgID)) {
            link = link.map((part) =>
                part.includes("./organization") ? part + "/" + orgID : part
            );
        }
        scheduleMicroTask(() => {
            this.router.navigate(link, extras);
        });
    }

    setSelectedOrg(selectedOrg: Organization) {
        if (selectedOrg) {
            if (!this.selectedOrganization || selectedOrg.id != this.selectedOrganization.id) {
                this.navigateToOrgID(selectedOrg.id);
            }
            this.selectedOrganization = selectedOrg;
        } else {
            this.selectedOrganization = {
                id: null
            };
        }
        this.currentOrganizationService.updateCurrentOrganization(selectedOrg);
    }

    // sends a notification that the 'save' button was clicked
    saveCurrentOrg() {
        this.currentOrganizationService.saveCurrentOrganization();
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                event.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft;
            this.contextMenuY =
                event.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedOrganization) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(this.selectedOrganization.name);
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedOrganization.id);
                    break;
            }
        }
    }

    showHelp() {
        this._detectIfUrlIsAbsolute(this.pageData.helpLink);

        if (this.urlIsAbsolute) {
            window.open(this.pageData.helpLink, "_blank");
        } else {
            let url: string = location.origin + this.pageData.helpLink;
            window.open(url, "_blank");
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    _detectIfUrlIsAbsolute(url: string) {
        let firstFour = url.substring(0, 4);
        if (firstFour === "http") {
            this.urlIsAbsolute = true;
        } else {
            this.urlIsAbsolute = false;
        }
    }
}
