<a *ngIf="skipLinkPath?.length"
   [href]="skipLinkPath"
   (click)="$event.stopPropagation()"
   (keypress)="$event.stopPropagation()"
   (keyup)="$event.stopPropagation()"
   (keydown)="$event.stopPropagation()"
   class="visible-hidden">Skip to Content</a>
<sf-loading-bar></sf-loading-bar>
<router-outlet></router-outlet>
<template #tourstepcontainer></template>
<!--<ng1-common-styles></ng1-common-styles>-->
<!--<ng1-shared-styles></ng1-shared-styles>-->
<!--<ng1-userorg-styles></ng1-userorg-styles>-->
<!--<ng1-settings-styles></ng1-settings-styles>-->

<!-- I indicate that a router module is being loaded asynchronously. -->
<!--<div-->
<!--    *ngIf="isShowingRouteLoadIndicator"-->
<!--    class="router-load-indicator">-->
<!--    Loading...-->
<!--</div>-->
