<div class="content {{ type || 'info' }}">
    <div class="content-wrapper">
        <span *ngIf="title" class="title">{{ title }}</span>
        <div class="message">
            <ng-content></ng-content>
        </div>
    </div>
    <button class="btn btn-icon close-btn" *ngIf="!noClose" (click)="close($event)" aria-label="Clear notification banner">
        <fa-icon [icon]="['far', 'times']"></fa-icon>
    </button>
</div>
