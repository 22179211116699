import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import {
    removeByValue,
    SessionService,
    SortUtilitiesService
} from "@sf/common";
import { RoleService } from "@sf/userorg/common";
import { RoleSelectorPipe } from "../../pipes/role-selector-pipe";
import { first } from "rxjs/operators";

interface FullProduct {
    productID: string;
    label: string; // mixed case
}

interface Role {
    product: FullProduct;
    isStandard: boolean;
    canModifyRole: boolean;
    id: string;
    name: string;
    label: string;
    description: string;
    permissions: string[];
    allowsLogin: boolean;
}

interface LocalRole extends Role {
    disabled: boolean;
    checked: boolean;
    locked: boolean;
    custom: string;
    name: string;
    type: string; // could be "label" or empty string
    lockReason: string;
    tooltip: string;
}

// prettier-ignore
@Component({
    selector: "sf-userorg-role-multi-selector",
    templateUrl: "./role-multi-selector.component.html",
    styleUrls: ["./role-multi-selector.component.scss"]
})
export class RoleMultiSelectorComponent implements OnInit, OnChanges {
    @Input() initialSelection: string[]; // array of role IDs
    @Input() orgIDs: string[];
    @Input() showFilter: boolean;
    @Input() dimmedRoles: string[];
    @Input() lock = false;
    @Input() apiRolesOnly = false;

    @Output()
    changed: EventEmitter<{ $selection: string[] }> = new EventEmitter();

    searchText = { text: "" };
    isSuperUser = false;
    rolesList: LocalRole[] = [];
    filteredRolesList: LocalRole[] = [];
    loading = true;
    selectedRoles: LocalRole[] = [];

    private previousOrgIDs: string[] = null;
    private prevDimmedList: string[] = null;

    @ViewChildren("checkbox")
    checkboxes: QueryList<ElementRef>;

    constructor(
        private sessionService: SessionService,
        private userorgRolesService: RoleService,
        private roleSelectorPipe: RoleSelectorPipe
    ) {}

    ngOnInit() {
        this.isSuperUser = this.sessionService.isSuperUser();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.searchText.text = "";

        // handle orgid changes
        if (!this.previousOrgIDs || !this.isSameOrgID(this.previousOrgIDs, this.orgIDs)) {
            this.previousOrgIDs = this.orgIDs;
            if (this.rolesList.length) {
                /* fixme
                // unbind event handlers to old checkboxes
                let checks = $element.find('#sf-userorg-role-selector input');
                checks.unbind("click propertyChange keyup", checkChange);
                */
            }
            // read roles for new organization
            if (this.orgIDs && this.orgIDs.length) {
                this.loading = true;
                this.userorgRolesService.getUserRolesAssignableToAll(this.orgIDs, this.apiRolesOnly)
                    .pipe(first())
                    .subscribe((roles: LocalRole[]) => {
                        this.newRolesReceived(roles);
                    });
            } else {
                this.clearSelection();
                this.loading = false;
            }
        }

        // handle dimmedRoles change
        this.dimmedRolesChange();
    }

    setInitialSelection() {
        if (this.initialSelection) {
            let selectionChanged = false;
            this.initialSelection.forEach((roleID: string) => {
                let role: LocalRole = this.findDisplayedRole(roleID);
                if (role) {
                    let added = this.addSelectedItem(role);
                    if (added) {
                        selectionChanged = true;
                    }
                } else {
                    log.error("Role selector can't select role " + roleID);
                }
            });
            if (selectionChanged) {
                this.emitSelection();
            }
        }
    }

    emitSelection() {
        let selectedIDs: string[] = [];
        this.selectedRoles.forEach((role) => {
            if (!role.disabled) {
                selectedIDs.push(role.id);
            }
        });
        this.changed.emit({ $selection: selectedIDs });
    }

    isSameOrgID(one: string[], two: string[]) {
        let diff1 = this.difference(one, two);
        let diff2 = this.difference(two, one);
        let same = !diff1.length && !diff2.length;
        return same;
    }

    difference(a: string[], b: string[]): Array<string> {
        if (!b) {
            return a;
        }
        if (!a) {
            return null;
        }
        let diff: string[] = [];
        a.forEach((ae) => {
            let foundb = b.find((be) => {
                return (be == ae);
            });
            if (!foundb) {
                diff.push(ae);
            }
        });
        return diff;
    }

    clearSelection() {
        if (this.lock) {
            return; // can't change
        }
        if (!this.selectedRoles.length) {
            return; // already clear
        }
        let selectionChanged = false;
        if (this.rolesList) {
            this.rolesList.forEach((role: LocalRole) => {
                if (!role.disabled) {
                    role.checked = false;
                    removeByValue(this.selectedRoles, role);
                    selectionChanged = true;
                }
            });
        }
        if (selectionChanged) {
            this.emitSelection();
        }
    }

    dimmedRolesChange() {
        if (!this.dimmedRoles && !this.prevDimmedList) {
            return;
        }

        // enable everything that is not locked
        if (this.rolesList) {
            this.rolesList.forEach((role: LocalRole) => {
                role.disabled = role.locked;
            });
        }

        // uncheck everything we had previously dimmed
        if (this.prevDimmedList) {
            this.prevDimmedList.forEach((roleID: string) => {
                let role = this.findDisplayedRole(roleID);
                if (role) {
                    role.checked = false;
                }
            });
        }

        // dim and check new list
        if (this.dimmedRoles) {
            this.dimmedRoles.forEach((roleID: string) => {
                let role = this.findDisplayedRole(roleID);
                if (role) {
                    role.disabled = true;
                    role.checked = true;
                }
            });
        }
        this.prevDimmedList = this.dimmedRoles
            ? this.dimmedRoles.slice(0)
            : null;

        // synch with selection
        // this block can probably be removed - not needed
        /*
        let selectionChanged = false;
        if (this.rolesList) {
            this.rolesList.forEach((role: Role) => {
                let isInSelected = this.findSelectedRole(role.id);
                if (role.checked) {
                    if (!isInSelected && !role.disabled) {
                        this.selectedRoles.push(role);
                        selectionChanged = true;
                    }
                } else {
                    if (isInSelected) {
                        _.pull(this.selectedRoles, role);
                        selectionChanged = true;
                    }
                }
            });
        }
        if (selectionChanged) {
            this.emitSelection();
        }
        */
    }

    onSearchChange() {
        this.filterTextChanged();
    }

    selectionText() {
        let selText;
        let selectedCount = 0;
        let firstSelectedName = null;
        this.selectedRoles.forEach((role: LocalRole) => {
            if (!role.disabled) {
                selectedCount++;
                firstSelectedName = role.name;
            }
        });
        if (selectedCount === 0) {
            selText = "No role selected";
        } else if (selectedCount === 1) {
            selText = firstSelectedName;
        } else {
            selText = "" + selectedCount + " roles selected";
        }
        return selText;
    }

    multipleOrgsSelected() {
        return this.orgIDs && Array.isArray(this.orgIDs) && this.orgIDs.length > 1;
    }

    addSelectedItem(selectedItem: LocalRole): boolean {
        let added = false;
        let isInSelected = this.findSelectedRole(selectedItem.id);
        if (!isInSelected) {
            selectedItem.checked = true;
            if (!selectedItem.disabled) {
                this.selectedRoles.push(selectedItem);
                added = true;
            }
            this.catchCheckboxEvents();
        }
        return added;
    }

    removeSelectedItem(selectedItem: LocalRole): boolean {
        if (!selectedItem) {
            return false;
        }
        let removed = false;
        let toRemove = this.findSelectedRole(selectedItem.id);
        if (toRemove){
            removeByValue(this.selectedRoles, toRemove);
            removed = true;
        }
        return removed;
    }

    checkChange(event: any) {
        let checkbox = event.target;
        let match = this.selectedRoles.find((v: LocalRole) => {
            return v.id === checkbox.value;
        });
        let role = this.findDisplayedRole(checkbox.value);
        if (checkbox.checked) {
            if (!match) {
                this.selectedRoles.push(role);
            }
        } else {
            if (match) {
                removeByValue(this.selectedRoles, role);
            }
        }

        this.emitSelection();
    }

    findDisplayedRole(roleID: string): LocalRole {
        if (this.rolesList) {
            for (let i = 0; i < this.rolesList.length; i++) {
                let role = this.rolesList[i];
                if (roleID === role.id) {
                    return role;
                }
            }
        }
        return null;
    }

    findSelectedRole(roleID: string) {
        if (this.selectedRoles) {
            for (let i = 0; i < this.selectedRoles.length; i++) {
                let role = this.selectedRoles[i];
                if (roleID == role.id) {
                    return role;
                }
            }
        }
        return null;
    }

    synchModel() {
        if (!this.selectedRoles) {
            return;
        }

        let selectionChanged = false;
        // look at existing selection
        this.selectedRoles.forEach((selectedRole) => {
            let role = this.findDisplayedRole(selectedRole.id);
            if (role) {
                // the role still applies to the new organization list
                role.checked = true;
            } else {
                // the role was selected, but doesn't apply to the new organization list
                this.removeSelectedItem(selectedRole);
                selectionChanged = true;
            }
        });
        if (selectionChanged) {
            this.emitSelection();
        }
    }

    cancelSearch() {
        this.catchCheckboxEvents();
    }

    filterTextChanged() {
        this.filteredRolesList = this.roleSelectorPipe.transform(this.rolesList, this.searchText);
        this.catchCheckboxEvents();
    }

    // bind with checkbox events
    catchCheckboxEvents() {
        /* fixme?
        let checks = $element.find('#sf-userorg-role-selector input');
        checks.bind("click propertyChange keyup", checkChange);
        */
    }

    roleAllowsLogin(role: LocalRole): boolean {
        return ("organization_login" in role.permissions);
    }

    addRoles(roleData: LocalRole[]) {
        if (roleData) {
            roleData.forEach((role) => {
                role.custom = role.isStandard ? "" : "(Custom)";
                role.tooltip =
                    role.name +
                    " " +
                    (this.isSuperUser ? "(" + role.id + ")" : "");
                if (role.locked) {
                    role.disabled = true;
                }
                role.allowsLogin = this.roleAllowsLogin(role);
            });
        }
        roleData = roleData.sort((a, b) => {
            let plabela = (a.product.label ? a.product.label : "");
            let plabelb = (b.product.label ? b.product.label : "");
            let aData = plabela + a.custom + a.name;
            let bData = plabelb + b.custom + b.name;
            return SortUtilitiesService.stringSortCompareInsensitive(aData, bData);
        });
        let lastStandard = false;
        let lastLabel = "";
        let group: LocalRole = null;
        for (let i = 0; i < roleData.length; i++) {
            let role = roleData[i];
            if (role.isStandard !== lastStandard || role.product.label !== lastLabel) {
                let text = role.product.label + " " + role.custom;
                group = {
                    label: text,
                    type: "label"
                } as LocalRole;
                this.rolesList.push(group);
            }

            if (role.locked) {
                group.locked = true;
                group.lockReason = role.lockReason;
            }
            lastStandard = role.isStandard;
            lastLabel = role.product.label;
            this.rolesList.push(role);
        }

        window.setTimeout(() => {
            this.synchModel();
        });
    }

    newRolesReceived(roleData: LocalRole[]) {
        this.loading = false;
        window.setTimeout(() => {
            // remove all existing options
            this.rolesList.length = 0;
            this.addRoles(roleData);
            this.dimmedRolesChange();
            this.setInitialSelection();
        });
        this.filterTextChanged();
    }
}
