import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { isObservable, Subject } from "rxjs";
import { first, startWith, takeUntil } from "rxjs/operators";
import { ModalButton } from "../../interfaces";

@Component({
    selector: "sf-modal-footer",
    templateUrl: "./modal-footer.component.html",
    styleUrls: ["./modal-footer.component.scss"]
})
export class ModalFooterComponent implements OnInit, OnDestroy, AfterViewInit {
    /** Public Variables **/
    @Input()
    primary: ModalButton;
    @Input()
    secondary: ModalButton;
    @Input()
    tertiary: ModalButton;
    @Input()
    quaternary: ModalButton;
    @Input()
    quinary: ModalButton;

    @ViewChild("primaryButtonEl")
    primaryButtonEl: ElementRef;

    /** Private Variables **/
    private _ngOnDestroy$: Subject<void> = new Subject();

    /** Lifecycle Hooks **/

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {
        this._initButton(this.primary);
        this._initButton(this.secondary);
        this._initButton(this.tertiary);
        this._initButton(this.quaternary);
        this._initButton(this.quinary);
    }

    //In the HTML quinary has been placed after the secondary button.
    // Falcon has approved this due to the padding on the tertiary button not allowing three buttons to be on the left side if placed in order

    ngOnDestroy(): void {
        this._ngOnDestroy$.next();
    }

    ngAfterViewInit() {
        if (this.primary?.autoFocus) {
            this.primaryButtonEl?.nativeElement?.focus();
        }
    }

    /** Public Methods **/

    buttonClick(btn: ModalButton, fallback: string) {
        if (btn.callback) {
            const result = btn.callback();
            if (isObservable(result)) {
                this.modal.working = true;
                result.pipe(first()).subscribe((shouldClose) => {
                    (this.modal as any).working = false;
                    if (shouldClose) {
                        this.modal.close(btn.responseValue);
                    }
                });
            } else if (result) {
                this.modal.close(btn.responseValue);
            }
        } else {
            this.modal.close(btn.responseValue ?? fallback);
        }
    }

    /** Private Methods **/

    private _initButton(button: ModalButton) {
        if (button) {
            if (button.formGroup) {
                button.formGroup.statusChanges
                    .pipe(
                        startWith(button.formGroup.status),
                        takeUntil(this._ngOnDestroy$)
                    )
                    .subscribe((validity: string) => {
                        button.disabled = validity === "INVALID";
                    });
            }
            // If only one tooltip given, use it when disabled. This is for backwards compatibility.
            if (button.toolTip && !button.disabledTooltip) {
                button.disabledTooltip = button.toolTip;
            }
        }
    }
}
