import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { Organization } from "../interfaces/organization.interface";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationService {

    constructor(
            private rpcService: RpcClientService
    ) {
    }

    search(query: string, limit: number, services?: string[], adminRightsOnly?: boolean, enhancedResults?: boolean,
            byStatus?: boolean, includeArchived?: boolean): Observable<Organization[]> {
        return this.rpcService.makeRequest<Organization[]>("search", "searchBasicOrganizations", {
            query,
            limit,
            services,
            adminRightsOnly,
            enhancedResults,
            byStatus,
            includeArchived
        });
    }

    searchOrganizations(query: string, limit: number, services?: string[], adminRightsOnly?: boolean,
            enhancedResults?: boolean, byStatus?: boolean, includeArchived?: boolean): Observable<any[]> {
        return this.rpcService.makeRequest<Organization[]>("search", "searchBasicOrganizations", {
            query: query,
            limit: limit,
            services: services,
            adminRightsOnly: adminRightsOnly,
            enhancedResults: enhancedResults,
            byStatus: byStatus,
            includeArchived: includeArchived
        });
    }

    getOrganization(orgID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getOrganization", { orgID: orgID });
    }

    getOrganizations(orgIDs: string[]): Observable<Organization[]> {
        return this.rpcService.makeRequest<Organization[]>("userorg", "getOrganizations", { orgIDs });
    }

    getOrganizationTree(orgID: string): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getOrganizationTree", {
            orgID: orgID
        });
    }

    getUsersWithSpecificPermission(orgID: string, permissionID: string): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getUsersWithSpecificPermission", {
            orgID,
            permissionID
        });
    }

    getUsersByOrganization(orgID: string): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getBasicUsersByOrganization", {
            orgID: orgID
        });
    }

    getBasicUsers(usernames: string[]): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getBasicUsers", { usernames });
    }

    getAllUsersByOrganization(orgID: string): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getAllUsersByOrganization", {
            orgID: orgID
        });
    }

    canUserLogin(userID: string): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "canUserLogin", { username: userID });
    }

    getOrganizationSalesRep(orgID: string, productID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getOrganizationSalesRep", {
            organizationID: orgID,
            productID: productID
        });
    }

    getOrganizationAccountManager(orgID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getOrganizationAccountManager", {
            organizationID: orgID
        });
    }

    setOrganizationSalesRep(orgID: string, salesUserID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "setOrganizationSalesRep", {
            organizationID: orgID,
            username: salesUserID
        });
    }

    setOrganizationAccountManager(orgID: string, managerUserID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "setOrganizationAccountManager", {
            organizationID: orgID,
            username: managerUserID
        });
    }

    // this takes an unusual data object
    saveOrganization(requestData: any): Observable<any> {
        return this.rpcService.makeRequest("userorg", "saveOrganization", requestData);
    }

    getOrganizationConfigurationSecurity(organizationID: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getOrganizationConfigurationSecurity", {
            organizationID: organizationID
        });
    }

    /**
     * if an array of services is passed, will check if org has ANY of those services
     * @param org - organization to check
     * @param services - service id or array of service ids (lower-case)
     */
    orgHasService(org: Organization, services: string|string[]): boolean {
        let has = false;
        if (!services) {
            return true;
        } // org has the 'null' service
        if (typeof services == "object") {
            // array
            if (!services.length) {
                has = true;
            } else if (!org.activeServices) {
                return false; // org has no services - not normal
            } else {
                services.forEach((service) => {
                    let foundService = org.activeServices.find((activeService) => {
                        return activeService.id == service;
                    });
                    has = !!foundService;
                });
            }
        } else if (typeof services == "string") {
            let foundService = org.activeServices.find((activeService) => {
                return activeService.id == services;
            });
            has = !!foundService;
        }
        return has;
    }

    /**
     * returns true if the org has NO services
     * @param org
     */
    orgHasNoServices(org: any): boolean {
        if (org.id.startsWith("UST")) {
            if (org.childIDs && org.childIDs.length) {
                return false;
            }
            if (org.children && org.children.length) {
                return false;
            }
        }
        return !org.displayableServices || !org.displayableServices.length;
    }

    orgHasDisplayableService(org: any, serviceID: string): boolean {
        let has = false;
        for (let i = 0; i < org.displayableServices.length; ++i) {
            let service = org.displayableServices[i];
            if (service.id == serviceID) {
                has = true;
            }
        }
        return has;
    }

    getSubmittersWithRecipient(recipientID: string): Observable<any[]> {
        return this.rpcService.makeRequest("userorg", "getSubmittersWithRecipient", {
            orgID: null,
            recipientID: recipientID
        });
    }

    removeOrganizationProfilePicture(orgID: string): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "removeOrganizationProfilePicture", { orgID: orgID });
    }

    getCustomProperty(orgID: string, key: string): Observable<string> {
        return this.rpcService.makeRequest("userorg", "getCustomProperty", {
            orgID,
            key
        });
    }

    sendCountyRegistrationRequest(submitterID: string, recipientID: string, userIDs: string[]): Observable<void> {
        return this.rpcService.makeRequest("userorg", "sendCountyRegistrationRequest", {
            submitterID,
            recipientID,
            userIDs
        });
    }

    getTransactionFeeInvoicingAllowed(orgID: string, product: string): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "getTransactionFeeInvoicingAllowed", {
            organizationID: orgID,
            product: product
        });
    }

    setTransactionFeeInvoicingAllowed(orgID: string, product: string, isAllowed: boolean): Observable<any> {
        return this.rpcService.makeRequest("userorg", "setTransactionFeeInvoicingAllowed", {
            organizationID: orgID,
            product: product,
            isAllowed: isAllowed
        });
    }

    getLicenseFeeInvoicingAllowed(orgID: string, product: string): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "getLicenseFeeInvoicingAllowed", {
            organizationID: orgID,
            product: product
        });
    }

    setLicenseFeeInvoicingAllowed(orgID: string, product: string, isAllowed: boolean): Observable<any> {
        return this.rpcService.makeRequest("userorg", "setLicenseFeeInvoicingAllowed", {
            organizationID: orgID,
            product: product,
            isAllowed: isAllowed
        });
    }
}
