import { Injectable } from "@angular/core";
import { TrayModulesService, TrayService } from "@sf/tray";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CustomFormTrayService {
    constructor(
        private _trayModulesService: TrayModulesService,
        private _trayService: TrayService
    ) {}

    /**
     * Returns true or false depending on whether the user is allowed to use the tray app
     * @returns {boolean}
     */
    allowUse() {
        return this._trayModulesService.isEnabled(
            this._trayModulesService.MODULES.QUICKSTART
        );
    }

    /**
     * Returns true or false depending on whether the tray is enabled and linked
     * @returns {boolean}
     */
    trayEnabled() {
        return this._trayService.trayEnabled();
    }

    makeQuickstartRequest(quickstartRequest: any) {
        const returnSubject = new Subject();
        const requestID = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        let dependencies: any = {};
        dependencies[this._trayModulesService.MODULES.QUICKSTART.NAME] =
            this._trayModulesService.MODULES.QUICKSTART.VERSION;
        const logMessage = (prefix: string, requestID: string, data: any) => {
            this._trayService
                .logMessage(
                    prefix + "(" + requestID + "): " + JSON.stringify(data)
                )
                .subscribe(() => {});
        };
        logMessage("Quickstart request", requestID, quickstartRequest);

        this._trayService
            .sendMessage({
                module: this._trayModulesService.MODULES.QUICKSTART.NAME,
                method: "makeQuickstartRequest",
                dependencies: dependencies,
                data: quickstartRequest
            })
            .subscribe(
                (result: any) => {
                    logMessage("Quickstart success", requestID, result);
                    returnSubject.next(result);
                    returnSubject.complete();
                },
                (rejection: any) => {
                    logMessage("Quickstart error", requestID, rejection);
                    returnSubject.error(rejection.data);
                }
            );
        return returnSubject.asObservable();
    }
}
