<sf-modal-header title="Hot Keys Mapping"></sf-modal-header>
<div class="modal-body">
    <table class="table">
        <caption>A list of hotkeys and their actions.</caption>
        <tr>
            <th>Hot Key</th>
            <th>Description</th>
        </tr>
        <tr *ngFor="let key of keyMapping">
            <td [innerHTML]="key.key"></td>
            <td>{{key.description}}</td>
        </tr>
    </table>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
