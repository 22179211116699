import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotUsernameComponent } from "./pages/forgot-username/forgot-username.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { SecondFactorComponent } from "./pages/second-factor/second-factor.component";
import { LogoutIfValidSessionGuard } from "./guards/logout-if-valid-session.guard";
import { LoanAuthenticationComponent } from "./pages/loan-authentication/loan-authentication.component";
import { EmailPackageDownloadLinkComponent } from "./pages/email-package-download-link/email-package-download-link.component";
import { MustChangePasswordComponent } from "./pages/must-change-password/must-change-password.component";
import { SecurityQuestionsComponent } from "./pages/security-questions/security-questions.component";
import { ScNotaryOnboardComponent } from "./pages/sc-notary-onboard/sc-notary-onboard.component";
import { MouAuthenticationComponent } from "./pages/mou-authentication/mou-authentication.component";

const routes: Routes = [
    {
        path: "",
        component: LoginComponent,
        canActivate: [LogoutIfValidSessionGuard]
    },
    {
        path: "must-change-password",
        component: MustChangePasswordComponent
    },
    {
        path: "second-factor",
        component: SecondFactorComponent
    },
    {
        path: "forgot-username",
        component: ForgotUsernameComponent
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent
    },
    {
        path: "change-password",
        component: ChangePasswordComponent
    },
    {
        path: "contact",
        component: ContactComponent
    },
    {
        path: "loan-authentication",
        component: LoanAuthenticationComponent
    },
    {
        path: "package-download",
        component: EmailPackageDownloadLinkComponent
    },
    {
        path: "security-questions",
        component: SecurityQuestionsComponent
    },
    {
        path: "sc-notary-onboard",
        component: ScNotaryOnboardComponent
    },
    {
        path: "mou-authentication",
        component: MouAuthenticationComponent
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule {}
