<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label [for]="field.id+'_custom_text'" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}"  placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <textarea class="form-control" [id]="field.id+'_custom_text'" [autocomplete]="field.id+'_custom_text'" [formControl]="control"></textarea>
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>

</div>
