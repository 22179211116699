import { ConditionRule, FieldViewState } from "../interfaces";

export class NotEmptyRule implements ConditionRule {
    match(value: any, viewState?: FieldViewState): boolean {
        return (
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value.length !== 0
        );
    }

    static staticMatch(value: any): boolean {
        return (
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value.length !== 0
        );
    }
}
