<sf-modal-header title="Getting Started With Simplifile"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="showAdminGuide" class="mb-4">
        <h2>Complete the following tasks to configure Document Builder for your organization.</h2>
        <h4>Watch videos to learn more.</h4>
        <sf-document-builder-guide-card *ngFor="let card of filteredAdminCards" [card]="card" (action)="card.action(card)"
                                        (skipAction)="skipAdminCard(card)" [adminOrganizationId]="adminOrganizationId">
        </sf-document-builder-guide-card>
    </div>
    <div *ngIf="showSignerGuide" class="mb-4">
        <h2>Complete the following tasks to begin signing in Simplifile.</h2>
        <h4>Watch videos to learn more.</h4>
        <sf-document-builder-guide-card *ngFor="let card of filteredSignerCards" [card]="card" (action)="card.action(card)"
                                        (skipAction)="skipSignerCard(card)">
        </sf-document-builder-guide-card>
    </div>
    <div *ngIf="showNotaryGuide" class="mb-4">
        <h2>Complete the following tasks to begin notarizing in Simplifile.</h2>
        <h4>Watch videos to learn more.</h4>
        <sf-document-builder-guide-card *ngFor="let card of filteredNotaryCards" [card]="card" (action)="card.action(card)"
                                        (skipAction)="skipNotaryCard(card)">
        </sf-document-builder-guide-card>
    </div>
    <div *ngIf="showDataEntryGuide">
        <h2>Complete the following task to begin creating documents in Simplifile.</h2>
        <h4>Watch videos to learn more.</h4>
        <sf-document-builder-guide-card *ngFor="let card of filteredDataEntryCards" [card]="card" (action)="card.action(card)"
                                        (skipAction)="skipDataEntryCard(card)">
        </sf-document-builder-guide-card>
    </div>
</div>
<sf-modal-footer [secondary]="{ text: 'Close' }"></sf-modal-footer>
