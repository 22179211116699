import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { TokenService } from "@sf/userorg/common";
import { CopyTextService } from "@sf/common";
import { GrowlService } from "@sf/common";

// prettier-ignore
@Component({
    selector: "sf-api-token-accept",
    templateUrl: "./api-token-accept.component.html",
    styleUrls: ["./api-token-accept.component.scss"]
})
export class ApiTokenAcceptComponent implements OnInit, OnDestroy {
    loaded = false;
    loadFailed: string = null;
    orgName: string = null;
    integrationName: string = null;
    tokenID: string = null;
    tokenText: string = null;
    tokenEncoded: string = null;
    showUrlEncode = true;
    buttonClicked = false;

    private _ngOnDestroy: Subject<void>;

    constructor(
        private route: ActivatedRoute,
        private tokenService: TokenService,
        private copyService: CopyTextService,
        private growlService: GrowlService
    ) {}

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.loadPageInitial(this.tokenID);
            });

    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    loadPageInitial(invitationTokenID: string) {
        this.loaded = false;

        this.tokenService.loginWithToken(invitationTokenID, ["API_TOKEN_VIEW"])
            .subscribe((response: any) => {
                this.tokenService.getAPITokenInvitation(invitationTokenID, false)
                    .subscribe((response: any) => {
                        this.loaded = true;
                        this.orgName = response.orgName;
                        this.integrationName = response.integrationName;
                    }, (response: any) => {
                        this.loaded = true;
                        this.loadFailed = response.error.errorMessage;
                    });
            }, (response: any) => {
                this.loadFailed = response.error.errorMessage;
                this.loaded = true;
            });
    }

    loadPageToken(invitationTokenID: string) {
        this.loaded = false;
        this.tokenService.getAPITokenInvitation(invitationTokenID, true)
            .subscribe((response: any) => {
                this.loaded = true;
                this.orgName = response.orgName;
                this.integrationName = response.integrationName;
                this.tokenText = response.tokenText;
                this.tokenEncoded = response.tokenEncoded;
            }, (response: any) => {
                this.loaded = true;
                this.loadFailed = response.error.errorMessage;
            });
    }

    viewToken() {
        this.buttonClicked = true;
        this.loadPageToken(this.tokenID);
    }

    copyToken() {
        this.copyService.copy(this.tokenText);
        this.growlService.success("Copied to clipboard");
    }

    copyUrlToken() {
        this.copyService.copy(this.tokenEncoded);
        this.growlService.success("Copied to clipboard");
    }

    urlEncoded() {
        this.showUrlEncode = true;
    }
}
