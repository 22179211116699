import { FormControl } from "@angular/forms";
import { DynamicField, FieldVisibility } from "../interfaces";
import { applyMixins } from "@sf/common";
import { DynamicFormGroup } from "./dynamic-form-group";
import { DynamicFormArray } from "./dynamic-form-array";
import { Observable, Subject } from "rxjs";
import { AbstractDynamicFormControl } from "./abstract-dynamic-form-control";

abstract class AbstractFormControl extends FormControl {
    isVisible = true;
    dynamicField: DynamicField;

    setIsVisible: (isVisible: boolean) => void;
    setDynamicField: (field: DynamicField) => void;
    checkVisibility: (viewState: FieldVisibility) => void;
    checkForChanges: () => void;
    checkForChanges$ = new Subject();

    setViewState(viewState: Observable<FieldVisibility>) {}

    onVisibilityChange(viewState: any) {
        if (viewState.visibility.disabled !== this.disabled) {
            if (viewState.visibility.disabled || this.parent.disabled) {
                this.disable({ onlySelf: true });
            } else {
                this.enable({ onlySelf: true });
            }
        }

        this.setIsVisible(viewState.visibility.visible);
    }
}

export class DynamicFormControl extends FormControl {
    isVisible = true;
    dynamicField: DynamicField;
    // @ts-ignore
    readonly parent: DynamicFormGroup | DynamicFormArray;

    setIsVisible: (isVisible: boolean) => void;
    setDynamicField: (field: DynamicField) => void;
    checkVisibility: (viewState: FieldVisibility) => void;

    setViewState(viewState: Observable<FieldVisibility>) {}

    onVisibilityChange(viewState: any) {
        if (viewState && viewState.visibility !== null) {
            if (viewState.visibility.disabled !== this.disabled) {
                if (viewState.visibility.disabled || this.parent.disabled) {
                    this.disable({ onlySelf: true });
                } else {
                    this.enable({ onlySelf: true });
                }
            }

            this.setIsVisible(viewState.visibility.visible);
        }
    }
}
applyMixins(DynamicFormControl, [AbstractDynamicFormControl]);
//
// export class TextFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.TEXTFIELD;
//     component = TextFieldComponent;
// }
// applyMixins(TextFormControl, [DynamicFormControl]);
//
// export class NumericFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.NUMERIC;
//     component = NumericFieldComponent;
// }
// applyMixins(NumericFormControl, [DynamicFormControl]);
//
// export class SelectFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.SELECT;
//     component = SelectFieldComponent;
//     dynamicField: SelectField;
// }
// applyMixins(SelectFormControl, [DynamicFormControl]);
//
// export class CheckboxFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.CHECKBOX;
//     component = CheckboxFieldComponent;
// }
// applyMixins(CheckboxFormControl, [DynamicFormControl]);
//
// export class CurrencyFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.CURRENCY;
//     component = CurrencyFieldComponent;
// }
// applyMixins(CurrencyFormControl, [DynamicFormControl]);
//
// export class DateFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.NUMERIC;
//     component = DateFieldComponent;
// }
// applyMixins(DateFormControl, [DynamicFormControl]);
//
// export class DateRangeFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.DATERANGE;
//     component = DateRangeFieldComponent;
// }
// applyMixins(DateRangeFormControl, [DynamicFormControl]);
//
// export class RadioFormControl extends AbstractFormControl
//     implements DynamicFormControl {
//     type = FieldType.RADIO;
//     component = RadioFieldComponent;
// }
// applyMixins(RadioFormControl, [DynamicFormControl]);
