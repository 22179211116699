import { Injectable } from "@angular/core";

/*
    This service was taken from this article
    https://medium.com/@zainzafar/angular-load-external-javascript-file-dynamically-3d14dde815cb
 */
interface Scripts {
    name: string;
    src: string;
}

export const ScriptStore: Scripts[] = [
    { name: "c3", src: "assets/js/c3/c3.min.js" },
    { name: "d3", src: "assets/js/d3/d3.min.js" },
    { name: "ckeditor", src: "assets/js/ckeditor/ckeditor.js" },
    { name: "forge", src: "assets/js/forge/forge.min.js" },
    { name: "pdfmake", src: "assets/js/pdfmake/pdfmake.min.js" },
    { name: "vfs_fonts", src: "assets/js/pdfmake/vfs_fonts.js" },
    {
        name: "acuant-sdk-previous",
        src: "assets/js/acuant/previous/AcuantJavascriptWebSdk.min.js"
    },
    {
        name: "acuant-cam-previous",
        src: "assets/js/acuant/previous/AcuantCamera.min.js"
    },
    {
        name: "acuant-sdk-current",
        src: "assets/js/acuant/current/AcuantJavascriptWebSdk.min.js"
    },
    {
        name: "acuant-cam-current",
        src: "assets/js/acuant/current/AcuantCamera.js"
    },
    {
        name: "elli",
        src: "assets/js/ellie/elli.ssf.guest-with-polyfill.js"
    },
    { name: "highcharts", src: "assets/js/highcharts/highcharts.min.js" },
    { name: "highmaps", src: "assets/js/highcharts/highmaps.min.js" },
    {
        name: "signature-pad",
        src: "assets/js/signature-pad/signature_pad.min.js"
    },
    { name: "jsdifflib", src: "assets/js/jsdifflib/jsdifflib.js" },
    /* Make sure to import the stylesheet for jsdifflib. For example:

    :host {
        // Include the styles for the jsdifflib library
        ::ng-deep {
            @import "~libs/styles/src/lib/libs/_jsdifflib.scss";
        }
    }
     */
    { name: "hljs", src: "assets/js/highlightjs/highlight.pack.min.js" }
];

declare var document: any;

@Injectable({
    providedIn: "root"
})
export class DynamicScriptLoaderService {
    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise((resolve) => {
            if (!this.scripts[name].loaded) {
                //load script
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = this.scripts[name].src;
                if (script.readyState) {
                    //IE
                    script.onreadystatechange = () => {
                        if (
                            script.readyState === "loaded" ||
                            script.readyState === "complete"
                        ) {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({
                                script: name,
                                loaded: true,
                                status: "Loaded"
                            });
                        }
                    };
                } else {
                    //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({
                            script: name,
                            loaded: true,
                            status: "Loaded"
                        });
                    };
                }
                script.onerror = (error: any) =>
                    resolve({
                        script: name,
                        loaded: false,
                        status: "Load Error",
                        error: error
                    });
                document.getElementsByTagName("head")[0].appendChild(script);
            } else {
                resolve({
                    script: name,
                    loaded: true,
                    status: "Already Loaded"
                });
            }
        });
    }
}
