export interface LiveConfigRequest {
    optionID: string;
    overrideSpecs: Array<
        | UserIDOverride
        | OrgIDOverride
        | ServerRegexOverride
        | UserPercentageOverride
        | ApiKeyIDOverride
    >;
    rawJSONValue: string;
    sectionID: string;
}

export enum LiveConfigOverrideType {
    USER_ID = "USER_ID",
    ORG_ID = "ORG_ID",
    API_KEY_ID = "API_KEY_ID",
    USER_PERCENTAGE = "USER_PERCENTAGE",
    SERVER_REGEX = "SERVER_REGEX",
    COMPOSITE = "COMPOSITE"
}

export const OverrideTypesOptions = [
    {
        label: "By User ID",
        option: LiveConfigOverrideType.USER_ID
    },
    {
        label: "By Organization ID",
        option: LiveConfigOverrideType.ORG_ID
    },
    {
        label: "By User Percentage",
        option: LiveConfigOverrideType.USER_PERCENTAGE
    },
    {
        label: "By Server Regex",
        option: LiveConfigOverrideType.SERVER_REGEX
    },
    {
        label: "By API KEY ID",
        option: LiveConfigOverrideType.API_KEY_ID
    },
    {
        label: "Multiple Criteria",
        option: LiveConfigOverrideType.COMPOSITE
    }
];

export enum LiveConfigCompositeMatchType {
    ANY = "ANY",
    ALL = "ALL"
}

export interface BaseLiveConfigOverride {
    id: string;
    name: string;
    pinned: boolean;
    type: LiveConfigOverrideType;
    negate: boolean;
    criteria: {
        patterns?: string[];
        percentage?: number;
        users?: string[];
        orgs?: string[];
        apiKeyIDs?: string[];
        partitions?: BaseLiveConfigOverride[];
        matchType?: LiveConfigCompositeMatchType;
    };
}

export interface LiveConfigOverride extends BaseLiveConfigOverride {
    value: any;
}

export interface UserIDOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.USER_ID;
    criteria: {
        users: string[];
    };
}

export interface OrgIDOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.ORG_ID;
    criteria: {
        orgs: string[];
    };
}

export interface ServerRegexOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.SERVER_REGEX;
    criteria: {
        patterns: string[];
    };
}

export interface UserPercentageOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.USER_PERCENTAGE;
    criteria: {
        percentage: number;
    };
}

export interface ApiKeyIDOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.API_KEY_ID;
    criteria: {
        apiKeyIDs: string[];
    };
}

export interface CompositeOverride extends LiveConfigOverride {
    type: LiveConfigOverrideType.COMPOSITE;
    criteria: {
        partitions: BaseLiveConfigOverride[];
        matchType: LiveConfigCompositeMatchType;
    };
}
