import { Component, Input, OnInit } from "@angular/core";
import {
    Product,
    TokenService,
    User,
    UserorgActivationService
} from "@sf/userorg/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton, SfValidators, SortUtilitiesService } from "@sf/common";
import { SendLicenseDialogComponent } from "@sf/invitations";

// prettier-ignore
@Component({
    selector: "sf-license-send-dialog",
    templateUrl: "./license-send-dialog.component.html",
    styleUrls: ["./license-send-dialog.component.scss"]
})
export class LicenseSendDialogComponent implements OnInit {
    @Input() orgID: string;
    @Input() requiredSend: boolean;
    //@Input() activatingPerPackage: boolean;
    //@Input() activatingPerDocument: boolean;

    adminUsers: any[] = [];
    selectedUser: User = null;
    loading = true;
    singleInvite = false;
    warning: string = null;
    primary: ModalButton;
    secondary: ModalButton;
    explainText: string;
    selectPrompt: string;
    changeDetails: string;
    newFee: string;
    newPaperFee: string;
    newInStateErecordFee: string;
    newOutOfStateErecordFee: string;
    newInStatePaperFee: string;
    newOutOfStatePaperFee: string;
    newEmbeddedEmployeeFee: string;
    hasRecordingPlus = false;

    constructor(
        private activationService: UserorgActivationService,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Send",
            disabled: false,
            callback: this.save.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        // get list of all admin users in the organization
        this.activationService
            .getOrganizationAdminUsers(this.orgID)
            .subscribe((admins: any[]) => {
                this.loading = false;
                admins.forEach((admin) => {
                    admin.value = admin.username;
                    admin.label = admin.name + " (" + admin.username + ")";
                    this.adminUsers.push(admin);
                });
                this.adminUsers = this.adminUsers.sort((a, b) => {
                    return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                });
            });

        if (this.requiredSend) {
            this.explainText =
                "The license changes must be approved by the customer.";
        } else {
            this.explainText =
                "This will send the standard contract to the customer for approval.";
        }

        this.selectPrompt = "";
        this.selectPrompt += "Select one " +
                (this.singleInvite ? "organization administrator" :
                        "or more organization administrators") +
                " who should receive an email invitation to accept the new license agreement.";

        this.activationService.isProductEnabledForOrganization(this.orgID, Product.SUBMITTER_PAPER)
            .subscribe((enabled: boolean) => {
                if (enabled) {
                    this.hasRecordingPlus = true;
                }
            });
    }

    chooseNonUser() {
        const modalRef = this.modalService.open(SendLicenseDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalInstance.cancelText = "Back";

        modalRef.result.then(
            (newData: any) => {
                if (newData) {
                    this.activeModal.close({
                        email: newData.email,
                        firstName: newData.firstName,
                        lastName: newData.lastName,
                        title: newData.title
                    });
                }
            },
            () => {
                // nothing
            }
        );
    }

    save() {
        this.warning = null;
        let selectedUsers: string[] = [];
        if (!this.changeDetails) {
            this.warning = "Additional details are missing";
            return;
        }
        /*
        if (this.activatingPerPackage && !this.newFee) {
            this.warning = "Enter the per package submission fee";
            return;
        }
        if (this.activatingPerDocument && !this.newFee) {
            this.warning = "Enter the per document submission fee";
            return;
        }
        if (this.activatingPerDocument && !SfValidators.isPositiveFloatString(this.newFee)) {
            this.warning = "The per document fee must be a positive number";
            return;
        }
        if (this.activatingPerPackage && !SfValidators.isPositiveFloatString(this.newFee)) {
            this.warning = "The per package fee must be a positive number";
            return;
        }
        if (this.hasPaper && (this.activatingPerPackage || this.activatingPerDocument)) {
            if (!this.newPaperFee) {
                this.warning = "Enter the Paper Submission Fee";
                return;
            }
            if (!SfValidators.isPositiveFloatString(this.newPaperFee)) {
                this.warning = "The Paper Submission Fee must be a positive number"
                return;
            }
        }
        if (this.hasRecordingPlus && (this.activatingPerPackage || this.activatingPerDocument)) {
            if (!this.newInStateErecordFee) {
                this.warning = "Enter the In-State eRecording Submission Fee";
            }
            if (!this.newOutOfStateErecordFee) {
                this.warning = "Enter the Out-of-State eRecording Submission Fee";
            }
            if (!this.newInStatePaperFee) {
                this.warning = "Enter the In-State Paper Submission Fee";
            }
            if (!this.newOutOfStatePaperFee) {
                this.warning = "Enter the Out-of-State Paper Submission Fee";
            }
            if (!this.newEmbeddedEmployeeFee) {
                this.warning = "Enter the Embedded Employee Fee";
            }
            if (this.warning)
                return;
            if (!SfValidators.isPositiveFloatString(this.newInStateErecordFee)) {
                this.warning = "The In-State eRecording Submission Fee must be a positive number"
            }
            if (!SfValidators.isPositiveFloatString(this.newOutOfStateErecordFee)) {
                this.warning = "The Out-of-State eRecording Submission Fee must be a positive number"
            }
            if (!SfValidators.isPositiveFloatString(this.newInStatePaperFee)) {
                this.warning = "The In-State Paper Submission Fee must be a positive number"
            }
            if (!SfValidators.isPositiveFloatString(this.newOutOfStatePaperFee)) {
                this.warning = "The Out-of-State Paper Submission Fee must be a positive number"
            }
            if (!SfValidators.isPositiveFloatString(this.newEmbeddedEmployeeFee)) {
                this.warning = "The Embedded Employee Fee must be a positive number"
            }
            if (this.warning)
                return;
        }
        */
        if (this.singleInvite) {
            if (this.selectedUser.username) {
                selectedUsers.push(this.selectedUser.username);
            } else {
                this.warning = "Select a user";
                return;
            }
        } else {
            this.adminUsers.forEach((user) => {
                if (user.checked) {
                    selectedUsers.push(user.username);
                }
            });
            if (this.adminUsers && this.adminUsers.length && selectedUsers.length == 0) {
                this.warning = "Select at least one user";
                return;
            }
        }
        if (this.warning)
            return;
        this.activeModal.close({
            selectedUsernames: selectedUsers,
            changeDetails: this.changeDetails,
            newFee: this.newFee,
            newPaperFee: this.newPaperFee,
            newInStateErecordFee: this.newInStateErecordFee,
            newOutOfStateErecordFee: this.newOutOfStateErecordFee,
            newInStatePaperFee: this.newInStatePaperFee,
            newOutOfStatePaperFee: this.newOutOfStatePaperFee,
            newEmbeddedEmployeeFee: this.newEmbeddedEmployeeFee
        });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
