import { OverlayRef } from "@angular/cdk/overlay";
import { Subject } from "rxjs";

export class FilterPanelOverlayRef {
    closed$: Subject<void> = new Subject<void>();

    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.closed$.next();
        this.closed$.complete();

        this.overlayRef.dispose();
        this.overlayRef = null;
    }
}
