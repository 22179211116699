import { RemoteParticipant } from "twilio-video";

export const SIGN_EVENT_STORAGE_KEY: string = "eSignEvent";

export interface UIRoomConnection {
    token: string;
    roomName: string;
}

export interface SignEventStorage {
    participantToken?: string;
    videoRoom?: UIRoomConnection;
    onLeaveEventUrl?: string;
    onSessionTimeoutUrl?: string;
    participantRoomAction?: string;
    redirectSoon?: boolean;
}

export interface VideoRoom {
    onBottom: boolean;

    sendMuteParticipant(participant: RemoteParticipant, mute: boolean): void;

    receiveDataMessage(message: string): void;
}

export interface DataMessage {
    operation: "MUTE_SELF" | "UNMUTE_SELF"; // The operation to be performed by the recipient of the message.
    participantID: string; // Participant that this message is sent to. "ALL" for everyone.
    from: string; // Participant that sent this message.
}

export interface UIStartStepRequest {
    step: UIEEStep;
}

export enum UIEEStep {
    TERMS_AND_PRIVACY_POLICY = "TERMS_AND_PRIVACY_POLICY",
    ECONSENT = "ECONSENT",
    AUDIO_VIDEO = "AUDIO_VIDEO",
    INTERPRETER_DETAILS = "INTERPRETER_DETAILS",
    STATE_SPECIFIC_REQUIREMENTS = "STATE_SPECIFIC_REQUIREMENTS",
    ID_VERIFICATION = "ID_VERIFICATION",
    LOBBY = "LOBBY",
    NOTARIZING = "NOTARIZING",
    IPEN_LOBBY = "IPEN_LOBBY"
}
