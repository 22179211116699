<div class="card" [ngClass]="{'complete': complete}">
    <div class="icon">
        <fa-icon *ngIf="!complete" [icon]="['far', 'info-circle']"></fa-icon>
        <span *ngIf="complete" class="fa-stack">
            <fa-icon class="fa-stack-2x" [icon]="['far', 'circle']"></fa-icon>
            <fa-icon class="fa-stack-1x" [icon]="['far', 'check']" [style.font-size]="'24px'"></fa-icon>
        </span>
    </div>
    <div class="content">
        <h3>{{card.title}}</h3>
        <p *ngIf="!!card.description && (!complete || !card.completeMessage)">{{card.description}}</p>
        <div [ngStyle]="{'display': complete && card.hideOnComplete ? 'none': 'inline'}">
            <ng-container #documentBuilderCardComponent></ng-container>
        </div>
        <p *ngIf="complete && !!card.completeMessage">{{card.completeMessage}}</p>
        <div class="actions">
            <div *ngIf="card.action && card.actionLabel">
                <button class="btn action" [ngClass]="{'btn-outline-secondary': complete, 'btn-primary': !complete}" (click)="action.emit()">
                    {{card.actionLabel}}
                </button>
            </div>
<!--            <div *ngIf="card.id === 'TEMPLATE_GROUPS'" class="template-groups">-->
<!--                <sf-select [options]="standardTemplateGroupList" trackBy="id" labelBy="id"-->
<!--                           [hasSelectAll]="true" (select)="updateStandardTemplateGroups($event.$selection);" placeholder="None"-->
<!--                           [hasClearButton]="true" [isSingleSelect]="false" [selectedOptions]="standardTemplateGroups">-->
<!--                </sf-select>-->
<!--            </div>-->
            <div *ngIf="!complete">
                <button class="btn btn-outline-primary" (click)="skipAction.emit()">Skip</button>
            </div>
        </div>
    </div>
    <div *ngIf="card.videoId" class="video">
        <div data-experience="5eda8e9b0b3c8700213de5c9" [attr.data-video-ids]="card.videoId"></div>
    </div>
</div>
