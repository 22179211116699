import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { UserRole } from "../interfaces/user-role.interface";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class CurrentRoleService {
    private _currentRole: BehaviorSubject<UserRole> = new BehaviorSubject(null);

    public currentRole$ = this._currentRole.pipe(
            distinctUntilChanged((newRole: UserRole, oldRole: UserRole) => {
                // don't send notice unless it's a new role, or name changed, or status changed
                return !this.roleChanged(newRole, oldRole);
            }),
            shareReplay(1)
    );

    constructor() {}

    getCurrentRole(): UserRole | null {
        return this._currentRole.getValue();
    }

    // Convenience method for ID
    getCurrentRoleID(): string {
        let role = this._currentRole.getValue();
        if (!role) {
            return null;
        }
        return role.id;
    }

    updateCurrentRole(role: UserRole): void {
        let currentRoleNow = this.getCurrentRole();
        if (this.roleChanged(currentRoleNow, role)) {
            if (role && !role.name) {
                log.error("Invalid call to updateCurrentRole with partial role");
            }
            this._currentRole.next(role);
        }
    }

    roleChanged(currentRole: UserRole, newRole: UserRole): boolean {
        if (!currentRole && !newRole) {
            // both null
            return false;
        }
        if (!currentRole || !newRole) {
            // one is null
            return true;
        }
        if (currentRole.id !== newRole.id) {
            // different id means different role
            return true;
        }
        if (currentRole.name && newRole.name) {
            if (currentRole.name != newRole.name) {
                return true;
            }
        } else if (newRole.name) {
            return true;    // adding name
        }
        return false;
    }
}
