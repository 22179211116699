import { Injectable } from "@angular/core";
import { dayjs } from "../date-and-time/plugins/dayjs/index";

@Injectable({
    providedIn: "root"
})
export class HolidayService {
    constructor() {}

    /**
     * Returns the month/day, without leading zeros, of Good Friday for the year specified.
     * @param targetYear
     * @return {string}
     */
    private static _goodFriday(targetYear: number) {
        // calculates Easter Sunday
        const C = Math.floor(targetYear / 100);
        const N = targetYear - 19 * Math.floor(targetYear / 19);
        const K = Math.floor((C - 17) / 25);
        let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
        I -= 30 * Math.floor(I / 30);
        I -=
            Math.floor(I / 28) *
            (1 -
                Math.floor(I / 28) *
                    Math.floor(29 / (I + 1)) *
                    Math.floor((21 - N) / 11));
        let J =
            targetYear +
            Math.floor(targetYear / 4) +
            I +
            2 -
            C +
            Math.floor(C / 4);
        J -= 7 * Math.floor(J / 7);
        const L = I - J;
        let M = 3 + Math.floor((L + 40) / 44);
        let D = L + 28 - 31 * Math.floor(M / 4);
        // subtracts 2 days from Easter Sunday
        D -= 2; // subtract 2 days for Good Friday
        if (D <= 0) {
            D += 31; // correct day if we went back to March
            M = 3; // correct month
        }
        return parseInt(String(M), 10) + "/" + parseInt(String(D), 10); // return without any leading zeros
    }

    /**
     * Returns true if date provided is observed by the vast majority of counties.
     * Good example of county holidays http://www.titledata.com/tdiforms/holidaycc2017-Dallas-FortWorth-area.pdf
     * This includes Friday before or Monday after dates.  e.g. Friday December 31 will return true because Saturday is January 1.
     * New Year's Day (Jan 1), Third Monday in January), Memorial Day(Last Monday in May), Independence Day (Jul 4),
     * Labor Day (First Monday in September), Thanksgiving day Thursday and Friday (Fourth Thursday in Nov), Christmas (Dec 25)
     * @param dateToCheck valid date or dayjs
     */
    public isMajorHoliday(dateToCheck: Date | dayjs.Dayjs) {
        const majorHolidays = [
            "New Year's",
            "Memorial Day",
            "Independence Day",
            "Labor Day",
            "Thanksgiving",
            "Christmas"
        ];
        let sourceDate = dateToCheck;
        if (dayjs.isDayjs(dateToCheck)) {
            sourceDate = dateToCheck.toDate();
        }
        if (!(sourceDate instanceof Date)) {
            throw new Error(
                "holidayService.dateToCheck must be called with a dayjs or a Date object"
            );
        }
        const sourceMonth = sourceDate.getUTCMonth(); // months, zero based, that have majorHolidays 0, 4, 6, 8, 9, 10, 11
        // we won't check Feb, Mar, Apr, Jun, or Aug.  None of the majorHolidays, or observed days (Monday after or Friday before), fall in these months.
        const nonMajorHolidayMonths0Based = [1, 2, 3, 5, 7];
        if (nonMajorHolidayMonths0Based.indexOf(sourceMonth) >= 0) {
            return false;
        }
        const response = this.checkHoliday(dateToCheck);
        return (
            typeof response === "string" && majorHolidays.indexOf(response) >= 0
        );
    }

    /**
     * Checks the date provided to see if it is a day that will be recognized as a holiday.
     * This includes Friday before or Monday after dates.  e.g. Friday December 31 will return "New Year's" because Saturday is January 1.
     * @param dateToCheck valid date or dayjs to check
     * @return {*} returns the name of the holiday or false if the date is not a holiday
     */
    public checkHoliday(dateToCheck: Date | dayjs.Dayjs): string | boolean {
        let sourceDate = dateToCheck;
        if (dayjs.isDayjs(dateToCheck)) {
            sourceDate = dateToCheck.toDate();
        }
        if (!(sourceDate instanceof Date)) {
            throw new Error(
                "sfHolidayService.checkHoliday must be called with a dayjs or a Date object"
            );
        }
        // sourceDate = new Date("2017-04-14T12:01:00Z"); // for testing purposes
        // check simple dates (month/date - no leading zeroes)
        const numericDay = sourceDate.getUTCDate();
        const numericMonth1Based = sourceDate.getUTCMonth() + 1;
        const monthSlashDay = numericMonth1Based + "/" + numericDay;
        const fullYear = sourceDate.getUTCFullYear();
        const numericDayOfWeek0Based = sourceDate.getUTCDay(); // day of the week 0-6
        switch (monthSlashDay) {
            case "1/1":
                return "New Year's";
            case "7/4":
                return "Independence Day";
            case "11/11":
                return "Veteran's Day";
            case "12/25":
                return "Christmas";
            case HolidayService._goodFriday(fullYear):
                return "Good Friday";
        }
        // special cases - friday before or monday after weekend holiday
        if (numericDayOfWeek0Based === 5) {
            // Friday before
            switch (monthSlashDay) {
                case "12/31":
                    return "New Year's Observed";
                case "7/3":
                    return "Independence Day Observed";
                case "11/10":
                    return "Veteran's Day Observed";
                case "12/24":
                    return "Christmas Observed";
            }
        }
        if (numericDayOfWeek0Based === 1) {
            // Monday after
            switch (monthSlashDay) {
                case "1/2":
                    return "New Year's Observed";
                case "7/5":
                    return "Independence Day Observed";
                case "11/12":
                    return "Veteran's Day Observed";
                case "12/26":
                    return "Christmas Observed";
            }
        }
        // weekday from beginning of the month (month/num/day)
        let weekNumber1Based = Math.floor((numericDay - 1) / 7) + 1;
        let month1BasedWeekNumber1BasedDayOfWeek0Based =
            numericMonth1Based +
            "/" +
            weekNumber1Based +
            "/" +
            numericDayOfWeek0Based;
        switch (month1BasedWeekNumber1BasedDayOfWeek0Based) {
            case "1/3/1":
                return "ML King Birthday";
            case "2/3/1":
                return "President's Day";
            case "9/1/1":
                return "Labor Day";
            case "11/4/4":
                return "Thanksgiving";
        }
        // Because Thanksgiving is the 4th Thursday
        // but Thanksgiving can be the 4th or 5th Friday
        // check to see if the day before this date is Thanksgiving.
        weekNumber1Based = Math.floor((numericDay - 2) / 7) + 1;
        month1BasedWeekNumber1BasedDayOfWeek0Based =
            numericMonth1Based +
            "/" +
            weekNumber1Based +
            "/" +
            (numericDayOfWeek0Based - 1);
        if (month1BasedWeekNumber1BasedDayOfWeek0Based === "11/4/4") {
            return "Thanksgiving Friday";
        }
        // weekday number from end of the month (month/num/day)
        const tempDate = new Date(sourceDate);
        tempDate.setUTCDate(1);
        tempDate.setUTCMonth(tempDate.getUTCMonth() + 1);
        tempDate.setUTCDate(tempDate.getUTCDate() - 1);
        weekNumber1Based =
            Math.floor((tempDate.getUTCDate() - numericDay) / 7) + 1;
        const s_date3 =
            numericMonth1Based +
            "/" +
            weekNumber1Based +
            "/" +
            numericDayOfWeek0Based;
        if (s_date3 === "5/1/1") {
            return "Memorial Day";
        } // Memorial Day, last Monday in May

        // misc complex dates
        //	if (monthSlashDay == "1/20" && (((dateToCheck.getFullYear() - 1937) % 4) == 0)
        // Inauguration Day, January 20th every four years, starting in 1937.
        //	) return "Inauguration Day";
        //	if (numericMonth1Based == 11 && numericDay >= 2 && numericDay < 9 && n_wday == 2
        // Election Day, Tuesday on or after November 2.
        //	) return "Election Day";
        return false;
    }
}
