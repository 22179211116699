import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { from, Observable, Subject } from "rxjs";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NgControl,
    ValidatorFn
} from "@angular/forms";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { RecipientRegistrationDialogComponent } from "./dialogs/recipient-registration-dialog/recipient-registration-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "@sf/common";
import { SelectComponent } from "@sf/common";
import { SubmitterRecipientSelectorService } from "./services/submitter-recipient-selector.service";
import { RecipientSelectorData } from "./common/recipient-selector-data";
import { UiRecipient } from "../../interfaces/ui-recipient";
import { RecipientSelectorGroup } from "./common/recipient-selector-group";

@Component({
    selector: "sf-submitter-recipient-selector",
    templateUrl: "./submitter-recipient-selector.component.html",
    styleUrls: ["./submitter-recipient-selector.component.scss"],
    providers: [SubmitterRecipientSelectorService]
})
export class SubmitterRecipientSelectorComponent
    implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
    @Input() recipientsData: RecipientSelectorData;
    @Input() isDisabled: boolean;
    @Input() tooltip: string;
    @Input() allowPaper: boolean;
    @Input() hasClearButton: boolean;

    @ViewChild("selector", { static: false }) selector: SelectComponent;

    /* Public Variables */
    public value: string;
    public selectorForm: FormGroup;
    public recipients$: Observable<UiRecipient[]> = this._service.recipients$;

    /* Private Variables */
    private _destroy$: Subject<void> = new Subject();
    private _onChange: Function;
    private _onTouch: Function;

    constructor(
        private _service: SubmitterRecipientSelectorService,
        private _modalService: NgbModal,
        private _sessionService: SessionService,
        private _router: Router,
        @Self() @Optional() private ngControl: NgControl
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        this._service.allowPaper = this.allowPaper;

        this.selectorForm = new FormGroup({
            selector: new FormControl(null, [])
        });

        this._updateValidators();
        this.selectorForm.get("selector").setValue(this.ngControl.value);

        // Propagate validators added dynamically
        this.ngControl.control.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
            .subscribe((value) => {
                this.selectorForm.get("selector").setValue(value);
                if (this.ngControl.dirty) {
                    this._updateValidators();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.recipientsData?.currentValue &&
            changes.recipientsData?.currentValue !==
                changes.recipientsData?.previousValue
        ) {
            this._service.recipientsData = changes.recipientsData.currentValue;

            if (!changes.recipientsData.isFirstChange()) {
                // Reset filter value
                this.selector.api.setFilterValue("");
            }
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    selectRecipient($selection: any, allowPaper: boolean) {
        if ($selection) {
            let recipient: UiRecipient = $selection as UiRecipient;
            if (
                recipient.group ===
                    RecipientSelectorGroup.REQUIRES_REGISTRATION &&
                !allowPaper
            ) {
                this._showCountyRegistrationDialog(recipient);
            } else {
                this._service.setSelected(recipient.id);
                this._onChange(recipient.id);
            }
        } else {
            this._onChange(null);
        }
    }

    writeValue(value: any): void {
        if (value) {
            this._service.setSelected(value);
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this._onTouch = fn;
    }

    filterRecipients(value: string) {
        this._service.filterValue = value;
    }

    private _showCountyRegistrationDialog(recipient: UiRecipient) {
        const modalRef = this._modalService.open(
            RecipientRegistrationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.recipient = recipient;
        modalInstance.userHasRegistrationPermission =
            this._sessionService.hasPermissionInAnyOrg("submitter_counties");
        from(modalRef.result).subscribe((result: any) => {
            if (result === -1) {
                this.selectorForm
                    .get("selector")
                    .setValue(this._service.getSelected());
            } else {
                this._modalService.dismissAll();
                let config: {
                    orgId: string;
                    countyId: string;
                    state: string;
                } = this._service.getRecipientRegistrationConfiguration(
                    recipient.id
                );
                let url =
                    "submitter/counties?" +
                    "orgID=" +
                    config.orgId +
                    "&countyID=" +
                    config.countyId +
                    "&state=" +
                    config.state;
                this._router.navigateByUrl(url);
            }
        });
    }

    // Propagate validators on form control to sfSelect component
    private _updateValidators() {
        const control = this.ngControl.control;
        let validator: ValidatorFn = control.validator;
        let validators = validator ? [validator] : [];
        let selectorControl = this.selectorForm.get("selector");
        selectorControl.setValidators(validators);
        selectorControl.updateValueAndValidity();
    }
}
