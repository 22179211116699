import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentAccountsService, UserOrgService } from "@sf/userorg/common";
import { first, map } from "rxjs/operators";
import { Organization } from "@sf/userorg/common";
import { ActiveService, SessionService } from "@sf/common";
import { UserorgActivationService } from "@sf/userorg/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { SuspensionNotificationDialogComponent } from "../dialogs/suspension-notification-dialog/suspension-notification-dialog.component";
import { OrganizationSuspensionService } from "@sf/userorg/common";

@Injectable({
    providedIn: "root"
})
export class OrganizationSuspensionDialogService {
    private _shownNotification: boolean = false;

    constructor(
        private _userOrgService: UserOrgService,
        private _sessionService: SessionService,
        private _activationService: UserorgActivationService,
        private orgSuspensionService: OrganizationSuspensionService,
        private _paymentService: PaymentAccountsService,
        private _modalService: NgbModal
    ) {}

    /**
     * Shows dialog containing service suspension information. Intended to be called at a billable event, or when
     * otherwise needed. User context is checked and an appropriate dialog is displayed based on whether they are an
     * admin or not. If not an admin, admin users for the org is obtained and passed along.
     * @param orgID Organization id (ex: UTTDLY)
     */
    showSuspensionDialogForOrg(orgID: string) {
        let isAdmin = this._userIsAdminInAnyOrg(orgID.split(","));

        this._userOrgService
            .getOrganization(orgID)
            .pipe(first())
            .subscribe((organization: Organization) => {
                let orgData: Organization[] = [organization];
                if (!isAdmin) {
                    this._activationService
                        .getOrganizationAdminUsers(orgID)
                        .subscribe((adminUsers: any) => {
                            this._processOrganizationData(
                                orgData,
                                isAdmin,
                                adminUsers
                            );
                        });
                } else {
                    this._processOrganizationData(orgData, isAdmin);
                }
            });
    }

    /**
     * Shows the actual dialog for any suspended service.
     * @param organizationData Array of organization data objects containing service information
     * @param hasPendingPayments Boolean flag indicating if there are pending payments or not
     * @param isAdmin Boolean flag indicating if the user is an admin or not
     * @param adminUsers Array of admin user objects, if the user is not an admin; otherwise undefined
     */
    showSuspensionNotification(
        organizationData: Organization[],
        hasPendingPayments: boolean,
        isAdmin: boolean,
        adminUsers: any[]
    ) {
        const modalRef: NgbModalRef = this._modalService.open(
            SuspensionNotificationDialogComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;

        modalInstance.unmaskModal = true;
        modalInstance.organizationData = organizationData;
        modalInstance.hasPendingPayments = hasPendingPayments;
        modalInstance.isAdmin = isAdmin;
        modalInstance.adminUsers = adminUsers;

        modalRef.result.then(
            () => {
                // norfing
            },
            () => {
                // nada
            }
        );

        this._shownNotification = true;
    }

    /**
     * Checks to see if any organization has pending e-check payments.
     * NOTE: this is currently only valid for submitters, but in the future, an organization type could have
     * payments - so this code might have to change.
     * @param orgIDs Array of organization ids (ex: ['UTTDLY', 'UTTYP3']
     * @returns {*} (Observable) true if payments are pending, false otherwise
     * @private
     */
    private _anyOrgHasPendingPayments(orgIDs: string[]): Observable<boolean> {
        return this._paymentService.getPendingPaymentCounts(orgIDs).pipe(
            map((countInfo: any) => {
                return !!countInfo.pendingCount;
            })
        );
    }

    /**
     * Checks to see if the organization data passed in contains any suspended service(s)
     * @param organizationData Array of organization data objects contained service information
     * @returns {boolean} true if at least one service is suspended, false otherwise
     * @private
     */
    private _hasSuspendedService(organizationData: Organization[]) {
        let hasSuspendedService: boolean = false;
        organizationData.forEach((organization: Organization) => {
            organization.activeServices.forEach((service: ActiveService) => {
                if (service.status === "SUSPENDED") {
                    hasSuspendedService = true;
                    organization.hasSuspendedService = true;
                }
            });
        });

        return hasSuspendedService;
    }

    /**
     * Checks service information from organization data to see if any service is suspended for a particular reason.
     * @param orgData Array of organization data objects containing service information
     * @returns {boolean} true if any service is suspended for the reason, false otherwise
     * @private
     */
    private hasSuspendedForPaymentIssues(orgData: Organization[]): boolean {
        let result: boolean = false;
        orgData.forEach((org: Organization) => {
            org.activeServices.forEach((service: ActiveService) => {
                if (
                    service.status === "SUSPENDED" &&
                    this.orgSuspensionService.isPaymentIssues(
                        service.statusDetails
                    )
                ) {
                    result = true;
                }
            });
        });

        return result;
    }

    /**
     * Checks to see if current user is an admin in organizations passed in
     * @param orgIDs Array of organization ids (ex: ['UTTDLY', 'UTTYP3'])
     * @returns {boolean} true if an admin, false otherwise
     * @private
     */
    private _userIsAdminInAnyOrg(orgIDs: string[]): boolean {
        let isAdmin: boolean = false;

        //based on organization_accounting permission - will get E-Record admins, Org Admins, Financial Admins
        orgIDs.forEach((orgID: string) => {
            if (
                this._sessionService.hasPermission(
                    "organization_accounting",
                    orgID
                )
            ) {
                isAdmin = true;
            }
        });

        return isAdmin;
    }

    /**
     * Checks all organization and only proceeds if there are suspended services. If a service is suspended for payment
     * issues, it is determined if there are payments pending.
     * @param organizationList Array of organization data objects containing service information
     * @param isAdmin Boolean flag to indicate if the user is an admin or not
     * @param adminUsers Array of objects that are admin users for the org - only defined if the current user is not an admin.
     * @private
     */
    private _processOrganizationData(
        organizationList: Organization[],
        isAdmin: boolean,
        adminUsers?: any[]
    ) {
        if (this._hasSuspendedService(organizationList)) {
            if (this.hasSuspendedForPaymentIssues(organizationList)) {
                let orgIDs = organizationList.map((org: Organization) => {
                    return org.id;
                });
                this._anyOrgHasPendingPayments(orgIDs)
                    .pipe(first())
                    .subscribe((hasPendingPayments: boolean) => {
                        this.showSuspensionNotification(
                            organizationList,
                            hasPendingPayments,
                            isAdmin,
                            adminUsers
                        );
                    });
            } else {
                this.showSuspensionNotification(
                    organizationList,
                    false,
                    isAdmin,
                    adminUsers
                );
            }
        } else {
            //give warning
            console.log(
                "No suspended services were found...not showing dialog."
            );
        }
    }
}
