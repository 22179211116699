import { Component, OnInit } from "@angular/core";
import { DynamicFormStore, InputComponent } from "@sf/dynamic-form-viewer";

@Component({
    selector: "sf-custom-textarea-field",
    templateUrl: "./custom-textarea-field.component.html",
    styleUrls: ["./custom-textarea-field.component.scss"]
})
export class CustomTextareaFieldComponent
    extends InputComponent
    implements OnInit {
    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
    }
}
