import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GrowlMessage, GrowlTypes } from "./interfaces/growl.interface";
import { ActiveToast, IndividualConfig } from "ngx-toastr";
import { map, scan, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class GrowlService {
    private growlSubject$ = new Subject<GrowlMessage>();
    private _toasts: BehaviorSubject<ActiveToast<any>[]> = new BehaviorSubject(
        []
    );

    activeToasts$: Observable<ActiveToast<any>[]> = this._toasts.pipe(
        shareReplay(1)
    );
    activeToastsCount$ = this.activeToasts$.pipe(
        map((toasts) => toasts.length),
        shareReplay(1)
    );
    hasActiveToasts$ = this.activeToastsCount$.pipe(map((count) => count > 0));

    growlState = this.growlSubject$.asObservable();

    constructor() {}

    /** show successful growl */
    success(
        message: string,
        title?: string,
        options?: Partial<IndividualConfig>
    ) {
        let growl: GrowlMessage = {
            message: message,
            title: title,
            options: options,
            type: GrowlTypes.SUCCESS
        };

        this._pushMessage(growl);
    }
    /** show info growl */
    info(message: string, title?: string, options?: Partial<IndividualConfig>) {
        let growl: GrowlMessage = {
            message: message,
            title: title,
            options: options,
            type: GrowlTypes.INFO
        };

        this._pushMessage(growl);
    }
    /** show warning growl */
    warning(
        message: string,
        title?: string,
        options?: Partial<IndividualConfig>
    ) {
        let growl: GrowlMessage = {
            message: message,
            title: title,
            options: options,
            type: GrowlTypes.WARNING
        };

        this._pushMessage(growl);
    }
    /** show error growl */
    error(
        message: string,
        title?: string,
        options?: Partial<IndividualConfig>
    ) {
        let growl: GrowlMessage = {
            message: message,
            title: title,
            options: options,
            type: GrowlTypes.DANGER
        };

        this._pushMessage(growl);
    }

    pushActiveToast(newToast: ActiveToast<any>) {
        let toastArray = this._toasts.getValue();
        const currIndex = toastArray.findIndex(
            (toast) => toast.toastId === newToast.toastId
        );
        if (currIndex !== -1) {
            toastArray.splice(currIndex, 1);
        } else {
            toastArray.push(newToast);
        }

        this._toasts.next([...toastArray]);
    }

    private _pushMessage(message: GrowlMessage) {
        this.growlSubject$.next(message);
    }
}
