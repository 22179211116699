<ng-container *ngIf="singleRow">
    <div class="row" [formGroup]="dateForm" #dateFormRef="ngForm">
        <ng-container *ngTemplateOutlet="dateRangeSelector"></ng-container>
        <ng-container *ngTemplateOutlet="dateRangeInputs"></ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!singleRow">
    <div class="row mb-3">
        <ng-container *ngTemplateOutlet="dateRangeSelector"></ng-container>
    </div>
    <div class="row">
        <ng-container *ngTemplateOutlet="dateRangeInputs"></ng-container>
    </div>
</ng-container>

<ng-template #dateRangeSelector>
    <div class="date-presets col" [formGroup]="presetFormGroup">
        <label for="inputDateRange" *ngIf="!rangeOnly">{{(rangeLabel || "Date Range")}}</label>
        <sf-select
            id="inputDateRange"
            formControlName="value"
            [attr.id]="inputId + 'DateRange'"
            [isSingleSelect]="true"
            [placeholder]="rangePlaceholder || 'None'"
            [options]="datePresets"
            [alwaysCallOnSelect]="true"
            [tabindex]="startingTabIndex"
            [hasClearButton]="allowClear"
            trackBy="id"
            labelBy="text"
            [hasFilter]="true"
            (registerApi)="selectRegisterApi($event)">
        </sf-select>
    </div>
</ng-template>

<ng-template #dateRangeInputs>
    <ng-container [formGroup]="dateFormGroup" *ngIf="showDates || dateForm.controls.preset.value === 'custom'">
        <div class="col" *ngIf="!rangeOnly">
            <label for="inputStartDate">{{(startLabel || "Start Date")}}</label>
            <div class="input-group mb-3">
                <sf-date-picker id="inputStartDate"
                                class="tooltip-alert"
                                name="inputStartDate"
                                formControlName="start"
                                [minDate]="minDate"
                                [maxDate]="startMaxDate$ | async"
                                [required]="dateFieldsRequired"
                                title="Start Date"
                                [container]="container"
                                [tabindex]="startingTabIndex2"
                                [placeholder]="startPlaceholder"
                                [inputId]="inputId + 'StartDate'"
                                ngbTooltip="Please enter a date"
                                tooltipClass="tooltip-alert"
                                container="body"
                                triggers="manual"
                                [autoClose]="false"
                                [updateOn]="updateOn || 'blur'"
                                #inputStartDateTooltip=ngbTooltip
                >
                </sf-date-picker>
            </div>

        </div>
        <div class="col" *ngIf="!rangeOnly" [ngClass]="{'col-md-4': singleRow, 'col-md-6': !singleRow}">
            <label for="inputEndDate">{{(endLabel || "End Date")}}</label>
            <div class="input-group mb-3">
                <sf-date-picker id="inputEndDate"
                                class="tooltip-alert"
                                name="inputEndDate"
                                formControlName="end"
                                [minDate]="endMinDate$ | async"
                                [maxDate]="maxDate"
                                [required]="dateFieldsRequired"
                                title="End Date"
                                [container]="container"
                                [tabindex]="startingTabIndex3"
                                [placeholder]="endPlaceholder"
                                [inputId]="inputId + 'EndDate'"
                                ngbTooltip="Please enter a date"
                                tooltipClass="tooltip-alert"
                                container="body"
                                triggers="manual"
                                [autoClose]="false"
                                [updateOn]="updateOn || 'blur'"
                                #inputEndDateTooltip=ngbTooltip
                >
                </sf-date-picker>
            </div>
        </div>
    </ng-container>
</ng-template>
