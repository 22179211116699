import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TokenService } from "@sf/userorg/common";
import { takeUntil } from "rxjs/operators";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-verify-email",
    templateUrl: "./verify-email.component.html",
    styleUrls: ["./verify-email.component.scss"]
})
export class VerifyEmailComponent implements OnInit {
    private _ngOnDestroy: Subject<void>;

    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    resultText: string = null;

    constructor(
            private tokenService: TokenService,
            private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.loadUp(this.tokenID);
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }


    private loadUp(tokenID: string) {
        this.loaded = false;

        this.tokenService.verifyEmail(tokenID)
            .subscribe((response: any) => {
                this.loaded = true;
                this.resultText = response as string;
            }, (response: any) => {
                this.loadFailed = response.error.errorMessage;
                this.loaded = true;
            });
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

}
