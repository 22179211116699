export interface ApiHistoryItem {
    id: string;
    revision: number;
    date: string;
    user: string;
    json: string;
    hasRealChange?: boolean;
}

export interface ApiDefinitionHistoryItem extends ApiHistoryItem {
    isCurrentRevision: boolean;
}

export interface ValidationErrorCode {
    code: string;
    name: string;
}

export interface ValidationError {
    reference: string;
    errorCode: ValidationErrorCode;
    message: string;
}

export enum SaveStatus {
    Saved = "SAVED",
    FailedDueToIndependentUpdate = "FAILED_DUE_TO_INDEPENDENT_UPDATE",
    FailedDueToValidationErrors = "FAILED_DUE_TO_VALIDATION_ERRORS"
}

export interface Difference {
    path: string;
    value1: any;
    value2: any;
}

export interface MergeResult {
    errorMessage?: string;
    hasConflictingChanges?: boolean;
    savedDifferences?: Difference[];
    updateDifferences?: Difference[];
    mergedResult?: any;
}

export interface SaveApiDefinitionResult {
    validationErrorList?: ValidationError[];
    saveStatus?: SaveStatus;
    latestSavedInstance?: any;
    mergeResult?: MergeResult;
}

export interface Metadata {
    id: string;
    userID?: string;
    _deleted?: boolean;
    _timestamp?: string;
    version?: number;
    lock?: number;
}
