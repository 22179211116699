import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { HotKey } from "../../../services/hot-keys.service";

@Component({
    selector: "sf-hot-keys-modal",
    templateUrl: "./hot-keys-modal.component.html",
    styleUrls: ["./hot-keys-modal.component.scss"]
})
export class HotKeysModalComponent implements OnInit {
    @Input()
    hotKeys: { [key: string]: HotKey };

    private _platform: "win" | "mac" | "linux";

    primary: ModalButton = {
        text: "Close"
    };

    keyMapping: {
        key: string;
        description: string;
    }[] = [];

    constructor() {
        if (navigator.appVersion.indexOf("Win") != -1) this._platform = "win";
        else if (navigator.appVersion.indexOf("Mac") != -1)
            this._platform = "mac";
        else if (navigator.appVersion.indexOf("Linux") != -1)
            this._platform = "linux";
    }

    ngOnInit(): void {
        const keys = Object.keys(this.hotKeys).sort();

        for (let key of keys) {
            const hotKey = this.hotKeys[key];

            if (!hotKey.description) {
                continue;
            }

            this.keyMapping.push({
                key: this._convertKeysToSymbols(
                    hotKey.displayAs || hotKey.combo
                ),
                description: hotKey.description
            });
        }
    }

    private _convertKeysToSymbols(key: string) {
        const keyParts = key.split(".");
        const convertedKeys = [];
        const mapping: any = {
            // shift: "&#8679;", // ⇧ (removed bc too similar to arrowup)
            arrowleft: "&#8592;", // ←
            arrowright: "&#8594;", // →
            arrowup: "&#8593;", // ↑
            arrowdown: "&#8595;" // ↓
        };

        for (let keyPart of keyParts) {
            if (keyPart === "meta") {
                convertedKeys.push(
                    this._platform !== "mac" ? "ctrl" : "&#8984;"
                );
            } else if (mapping.hasOwnProperty(keyPart)) {
                convertedKeys.push(mapping[keyPart]);
            } else {
                convertedKeys.push(keyPart);
            }
        }

        return convertedKeys.join(" + ");
    }
}
