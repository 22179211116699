import { Injectable } from "@angular/core";
import { SubmitterPackage } from "../interfaces/submitter-package.interface";
import { SubmitterStatusPipe } from "../pipes/submitter-status.pipe";

@Injectable({
    providedIn: "root"
})
export class SubmitterReceiptDataDialogService {
    constructor(private _statusPipe: SubmitterStatusPipe) {}

    /**
     * A convenience method for determining if this dialog should be opened
     * instead of the generic package status dialog.
     */
    shouldOpenReceiptDataDialog(pkg: SubmitterPackage) {
        const status = this._statusPipe.transform(pkg);
        const lowerCaseStatus = status?.toLowerCase();
        return (
            lowerCaseStatus === "recorded" || lowerCaseStatus === "finalizing"
        );
    }
}
