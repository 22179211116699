export enum ContractFeeType {
    LICENSE_FEE = "LICENSE_FEE",
    RENEWAL_FEE = "RENEWAL_FEE",
    SUBMISSION_FEE = "SUBMISSION_FEE",
    NFS_RETRY_FEE = "NFS_RETRY_FEE",
    RECORDING_FEE = "RECORDING_FEE",
    SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE = "SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE",
    SETTLEMENT_LOAN_CREATE_FEE = "SETTLEMENT_LOAN_CREATE_FEE",
    SETTLEMENT_LOAN_ACCEPT_FEE = "SETTLEMENT_LOAN_ACCEPT_FEE",
    DISBURSEMENT_FEE = "DISBURSEMENT_FEE",
    VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE = "VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE",
    REVSHARE_FEE = "REVSHARE_FEE",
    SETTLEMENT_RENEWAL_FEE = "SETTLEMENT_RENEWAL_FEE",
    LENDER_LOAN_CREATE_FEE = "LENDER_LOAN_CREATE_FEE",
    LENDER_LOAN_ACCEPT_FEE = "LENDER_LOAN_ACCEPT_FEE",
    LENDER_ANNUAL_FEE = "LENDER_ANNUAL_FEE",
    DOCUMENT_BUILDER_LICENSE_FEE = "DOCUMENT_BUILDER_LICENSE_FEE",
    DOCUMENT_BUILDER_RENEWAL_FEE = "DOCUMENT_BUILDER_RENEWAL_FEE",
    DOCUMENT_BUILDER_DOCUMENT_FEE = "DOCUMENT_BUILDER_DOCUMENT_FEE",
    DOCUMENT_BUILDER_FILE_TRANSFER_FEE = "DOCUMENT_BUILDER_FILE_TRANSFER_FEE",
    PAPER_SUBMISSION_FEE = "PAPER_SUBMISSION_FEE",
    INSTATE_ERECORD_SUBMISSION_FEE = "INSTATE_ERECORD_SUBMISSION_FEE",
    OUTOFSTATE_ERECORD_SUBMISSION_FEE = "OUTOFSTATE_ERECORD_SUBMISSION_FEE",
    INSTATE_PAPER_SUBMISSION_FEE = "INSTATE_PAPER_SUBMISSION_FEE",
    OUTOFSTATE_PAPER_SUBMISSION_FEE = "OUTOFSTATE_PAPER_SUBMISSION_FEE",
    PAPER_MAIL_FEE = "PAPER_MAIL_FEE",
    EMBEDDED_EMPLOYEE_FEE = "EMBEDDED_EMPLOYEE_FEE",
    PAPER_RECORDING_FEE = "PAPER_RECORDING_FEE",
    LENDER_VENDOR_REVSHARE = "LENDER_VENDOR_REVSHARE",
    NOTARY_VENDOR_REVSHARE = "NOTARY_VENDOR_REVSHARE",
    ESIGN_EVENTS_VENDOR_REVSHARE = "ESIGN_EVENTS_VENDOR_REVSHARE",
    SUBMITTER_VENDOR_REVSHARE = "SUBMITTER_VENDOR_REVSHARE",
    RECIPIENT_VENDOR_REVSHARE = "RECIPIENT_VENDOR_REVSHARE",
    RECIPIENT_VENDOR_EXCLUSIVE_REVSHARE = "RECIPIENT_VENDOR_EXCLUSIVE_REVSHARE",
    TRUSTEE_DOCUMENT_FEE = "TRUSTEE_DOCUMENT_FEE",
    ESE_LICENSE_FEE = "ESE_LICENSE_FEE",
    ESE_RENEWAL_FEE = "ESE_RENEWAL_FEE",
    ESE_NO_ENOTARIZATION = "ESE_NO_ENOTARIZATION",
    ESE_IPEN = "ESE_IPEN",
    ESE_RON = "ESE_RON",
    DOCUMENT_BUILDER_RON_FEE = "DOCUMENT_BUILDER_RON_FEE",
    LIEN_RELEASE_FEE = "LIEN_RELEASE_FEE",
    SUBSCRIPTION_MINIMUM = "SUBSCRIPTION_MINIMUM"
}
