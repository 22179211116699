import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class OrganizationHistoryService {
    private USERORG = "userorg";

    constructor(private _rpcClient: RpcClientService) {}

    getOrganizationHistory(
        orgID: string,
        startDate: Date,
        endDate: Date,
        events: string[],
        users: string[],
        details: string,
        resultOffset: number
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getOrganizationHistory",
            {
                organizationID: orgID,
                fromDate: startDate,
                toDate: endDate,
                events: events,
                users: users,
                details: details,
                resultOffset: resultOffset
            }
        );
    }

    getHistoryDistinctEvents(
        orgID: string,
        startDate: Date,
        endDate: Date
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getHistoryDistinctEvents",
            {
                organizationID: orgID,
                fromDate: startDate,
                toDate: endDate
            }
        );
    }

    getHistoryDistinctUsers(
        orgID: string,
        startDate: Date,
        endDate: Date
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getHistoryDistinctUsers",
            {
                organizationID: orgID,
                fromDate: startDate,
                toDate: endDate
            }
        );
    }
}
