import { Component, Input, OnInit } from "@angular/core";
import { BaseModalComponent, ModalButton } from "@sf/common";
import { Organization } from "@sf/userorg/common";
import { ActiveService, SessionService } from "@sf/common";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-suspension-notification-dialog",
    templateUrl: "./suspension-notification-dialog.component.html",
    styleUrls: ["./suspension-notification-dialog.component.scss"]
})
export class SuspensionNotificationDialogComponent extends BaseModalComponent implements OnInit {
    @Input()
    organizationData: Organization[];
    @Input()
    hasPendingPayments: boolean;
    @Input()
    isAdmin: boolean;
    @Input()
    adminUsers: any[];

    allowHelp: boolean = true;
    title: string = "Suspended Services";
    primaryButton: ModalButton = {
        text: "Review Pending Payments",
        callback: this._goToPayments.bind(this),
        responseValue: "review"
    };
    secondaryButton: ModalButton = {
        text: "Get Help",
        callback: this._goToHelp.bind(this),
        responseValue: "help"
    };
    tertiaryButton: ModalButton = {
        text: "Close",
        responseValue: "close"
    };
    hasInvoicingIssue: boolean = false;

    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _activeModal: NgbActiveModal
    ) {
        super();
    }

    ngOnInit(): void {
        ///set invoicing issues flag
        this.hasPendingPayments = false;
        this.hasInvoicingIssue = false;
        this.organizationData.forEach((org: Organization) => {
            org.activeServices.forEach((service: ActiveService) => {
                if (service.status === "SUSPENDED" && this._isPaymentIssues(service.statusDetails)) {
                    let invoiceIssue = service.statusDetails == "Past Due Invoice Payments";
                    let achIssue = service.statusDetails == "Past Due ACH Payments";
                    if (invoiceIssue) {
                        this.hasInvoicingIssue = true;
                    }
                    if (!invoiceIssue && !achIssue) {
                        this.hasPendingPayments = true;
                    }
                }
            });
        });

        this.primaryButton.hidden = !this.hasPendingPayments || !this.isAdmin;
        this.secondaryButton.hidden = !this.allowHelp;

        // weird checks when user only belongs to CAPC org(s).
        // see if one of their orgs allows viewing help.
        // since some people are not worthy of our help. :)
        if (this._sessionService.hasAnyProductInAnyOrg(["lender", "settlement"])) {
            if (!this._sessionService.hasProductInAnyOrg("submitter")) {
                this.allowHelp = false;
                this.organizationData.forEach((org: Organization) => {
                    if (
                        this._sessionService.hasPermission(
                            "capc_view_help_support",
                            org.id
                        )
                    ) {
                        this.allowHelp = true;
                    }
                });
            }
        }
    }

    private _isPaymentIssues(reason: string): boolean {
        let paymentReasons = [
            "Payment Issues",
            "Past Due ACH Payments",
            "Past Due Credit Card Payments",
            "Past Due eCheck Payments",
            "Past Due Invoice Payments"
        ];
        return paymentReasons.includes(reason);
    }

    private _goToPayments() {
        this._activeModal.close();
        let url = "/submitter/payments/pending-payments";
        this._router.navigate([url]);
    }

    private _goToHelp() {
        this._activeModal.close();
        let url: string;
        if (this._sessionService.hasProductInAnyOrg("submitter")) {
            url = "/submitter/help";
        } else if (this._sessionService.hasAnyProductInAnyOrg(["lender", "settlement"])) {
            url = "/lender/help";
        } else {
            // huh
        }
        if (url) {
            this._router.navigateByUrl(url);
        }
    }
}
