import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "sf-admin-api-tokens",
    templateUrl: "./admin-api-tokens.component.html",
    styleUrls: ["./admin-api-tokens.component.scss"]
})
export class AdminApiTokensComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy$: Subject<void>;

    /* Public Variables */
    selectedOrgID: string = null;
    hideVendorsFlag: boolean;
    vendorsOnlyFlag: boolean;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this._ngOnDestroy$ = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe((params) => {
                this.selectedOrgID = params.get("orgID");
            });
        this.route.data.subscribe((data: any) => {
            this.hideVendorsFlag = !!data.hideVendors;
        });
        this.route.data.subscribe((data: any) => {
            this.vendorsOnlyFlag = !!data.vendorsOnly;
        });
    }

    ngOnDestroy() {
        this._ngOnDestroy$.next();
    }
}
