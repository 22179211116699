import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { TrayService } from "../tray-service/tray.service";
import { TrayModulesService } from "../tray-modules-service/tray-modules.service";

@Injectable({
    providedIn: "root"
})
export class TrayAppScannerExecutorService {
    /** Private Variables **/
    private _dependencies: any = {};

    /** Public Variables **/

    constructor(
        private _trayService: TrayService,
        private _trayModulesService: TrayModulesService
    ) {
        this._dependencies[
            this._trayModulesService.MODULES.SCAN_TO_SIMPLIFILE.NAME
        ] = this._trayModulesService.MODULES.SCAN_TO_SIMPLIFILE.VERSION;
    }

    /** Public Methods **/
    /**
     * Starts the scan and upload process in the tray app
     */
    scanAndUpload(scannerSettings: any, documentHandle: any, sessionID: any) {
        return this._trayService
            .sendMessage({
                module: this._trayModulesService.MODULES.SCAN_TO_SIMPLIFILE
                    .NAME,
                method: "scanAndUpload",
                dependencies: this._dependencies,
                data: {
                    url: `${location.protocol}//${location.host}/sf/uploadFile`,
                    documentHandle: documentHandle,
                    session: sessionID,
                    options: scannerSettings.local
                }
            })
            .pipe(
                map((response: any) => {
                    return response.data;
                })
            );
    }

    /**
     * Gets the list of scanners from the tray app
     * @returns {Observable<string[]>} - scanner list
     */
    getScanners(): Observable<string[]> {
        return this._trayService
            .sendMessage({
                module: this._trayModulesService.MODULES.SCAN_TO_SIMPLIFILE
                    .NAME,
                method: "getScanners",
                dependencies: this._dependencies
            })
            .pipe(
                catchError((err: any) => of(err)),
                map((response: any) => {
                    return response.data;
                })
            );
    }

    /**
     * Returns a promise that resolves if the tray app is linked and set up to use scanning
     * Automatically links the tray app if it is not
     * @returns {Promise<undefined>}
     */
    isSetup() {
        if (!this._trayService.trayEnabled()) {
            return this.openSetupDialog();
        }
        return this._trayService.sendMessage({
            module: this._trayModulesService.MODULES.SCAN_TO_SIMPLIFILE.NAME,
            method: "verify",
            dependencies: this._dependencies
        });
    }

    openSetupDialog() {
        // this will be used once we get a definition from product on how to introduce users
        // to scanning
        return of(false); // mock implementation to avoid console errors
    }

    /**  Private Methods  **/
}
