<div class="question-text-container">
    <div class="text">
        {{questionText}}
    </div>
</div>
<div class="scale-container">
    <div class="scale">
        <div class="range-container">
            <ul class="range">
                <li class="option" *ngFor="let i of scaleRange; let first = first; let last = last">
                    <button class="btn sf-btn-link number" [ngClass]="{'first': first, 'last': last}" (click)="ratingSelected.emit(i)" tabindex="0"><span>{{i}}</span></button>
                </li>
            </ul>
        </div>
        <div class="labels">
            <div class="low">
                <span class="low-label">{{minLabel}}</span>
            </div>
            <div class="high">
                <span class="high-label">{{maxLabel}}</span>
            </div>
        </div>
    </div>
</div>
<div class="spacer"></div>
