<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="orgUsers && orgUsers.length">
        <div class="prompter">
            <span *ngIf="orgUsers.length > 1">Choose a user to log in as:</span>
            <span *ngIf="orgUsers.length == 1">One user in this organization:</span>
        </div>
        <div *ngIf="orgUsers.length > 1">
            <sf-select
                    name="'loginAs'"
                    placeholder="User..."
                    [options]="orgUsers"
                    trackBy="id"
                    labelBy="label"
                    [isSingleSelect]="true"
                    (select)="setSelectedUser($event.$selection)">
            </sf-select>
        </div>
        <div *ngIf="orgUsers.length == 1">
            <input type="text" class="form-control user-edit" [readOnly]="true" [value]="orgUsers[0].label"/>
            <button class="btn btn-secondary" (click)="selectFirstUser()">
                Select
            </button>
        </div>
    </div>
    <div *ngIf="!orgUsers || !orgUsers.length">
        This organization has no users.
    </div>
</div>
<sf-modal-footer [secondary]="secondary"></sf-modal-footer>
