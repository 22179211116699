import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SecurityContext,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
    ModalButton,
    BaseModalComponent,
    TourService,
    UserSettingsService
} from "@sf/common";
import { GrowlService } from "@sf/common";
import { SpinnerService } from "@sf/common";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalRef,
    NgbPopover
} from "@ng-bootstrap/ng-bootstrap";
import { UserType } from "@sf/custom-form";
import {
    catchError,
    filter,
    finalize,
    first,
    map,
    take,
    takeUntil,
    withLatestFrom
} from "rxjs/operators";
import { SharedPhoneNumberConstants } from "@sf/userorg/common";
import { WindowRefService } from "@sf/common";
import { LoggerService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import {
    DetailGridInfo,
    GetRowIdParams,
    GridApi,
    GridOptions,
    Module,
    RowHeightParams
} from "@ag-grid-community/core";
import { TemplateRendererComponent } from "@sf/common";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { ICONS } from "@sf/common";
import { OrganizationSignatureAuthorizationComponent } from "../organization-signature-authorization/organization-signature-authorization.component";
import { County } from "../../interfaces/county.interface";
import { CountyRegistrationSettings } from "../../interfaces/countyRegistrationSettings.interface";
import { SubmitterCountiesDataService } from "../../services/submitter-counties-data.service";
import { SubmitterRecipientService } from "../../services/submitter-recipient.service";
import { RegistrationSettingTypeAlt } from "../../enums/registration-setting-type.enum";
import { RecipientDefinition } from "../../enums/recipient-definition.enum";
import { RegistrationSetting } from "../../interfaces/registration-setting";
import { CountyData } from "../../interfaces/countyData.interface";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { DomSanitizer } from "@angular/platform-browser";

interface RegisterCountiesGridRow {
    state: string;
    addCountyText: string;
    oneClickCountiesAvailable: boolean;
    anyCountiesAvailable: boolean;
    hasNewCounties: boolean;
    anyCountiesAvailableCount: number;
}

@Component({
    selector: "sf-submitter-quick-register-dialog",
    templateUrl: "./submitter-quick-register-dialog.component.html",
    styleUrls: ["./submitter-quick-register-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class SubmitterQuickRegisterDialogComponent
    implements OnInit, OnDestroy
{
    /** I/O **/
    @Input()
    orgID: string;
    @Input()
    showSettings: boolean;
    @Input()
    recipientID?: string;

    @Output()
    viewDocTypes = new EventEmitter<string>();

    @ViewChild("requiredPopover")
    requiredPopover: NgbPopover;

    /** Private Variables **/
    private _masterGridApi: GridApi;
    private _noCountiesAlreadyRegistered: boolean = false;
    private _$onDestroy$: Subject<void> = new Subject<undefined>();
    private _countiesAdded$ = new Subject<County[]>();

    /** Public Variables **/
    title: string;
    primary: ModalButton;
    secondary: ModalButton;
    templateContext: any = {};
    masterGridOptions: GridOptions;
    registrationSettings: CountyRegistrationSettings;
    showingSettings$ = new BehaviorSubject(false);
    registerCountyForm: FormGroup = this._formBuilder.group({});
    gridData$: Observable<RegisterCountiesGridRow[]> = new BehaviorSubject([]);
    countiesAdded$: Observable<County[]> = this._countiesAdded$.asObservable();
    restricted = false;
    restrictedTooltip = "";
    unassociatedCountiesLoading: boolean = false;
    customFormModal: NgbModalRef;

    documentID: string;
    customFormID: string;
    userType: UserType = UserType.SUBMITTER;
    showNewMOUTour: boolean = false;
    modules: Module[] = [MasterDetailModule];

    readonly supportPhone: string =
        SharedPhoneNumberConstants.SUPPORT_PHONE_NUMBER;
    readonly rowHeight: number = 34;

    /** View Children **/
    @ViewChild("addCountyTemplate")
    addCountyTemplate: TemplateRef<any>;
    @ViewChild("stateTemplate")
    stateTemplate: TemplateRef<any>;
    @ViewChild("countyNameTemplate")
    countyNameTemplate: TemplateRef<any>;
    @ViewChild("numDocTypes")
    numDocTypes: TemplateRef<any>;
    @ViewChild("registerTemplate")
    registerTemplate: TemplateRef<any>;
    @ViewChild("customFormTemplate")
    customFormTemplate: TemplateRef<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private _growlService: GrowlService,
        private _windowRef: WindowRefService,
        private _activeModal: NgbActiveModal,
        private _loggerService: LoggerService,
        private _userOrgService: UserOrgService,
        private _spinnerService: SpinnerService,
        private _submitterCountiesDataService: SubmitterCountiesDataService,
        private _submitterRecipientService: SubmitterRecipientService,
        private _modalService: NgbModal,
        private _tourService: TourService,
        private _userSettingsService: UserSettingsService,
        private _sanitizer: DomSanitizer
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit(): void {
        this.title = "Register Counties";
        this.primary = {
            text: "OK",
            callback: this.registerCounty.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            callback: this.closeRegisterCountiesDialog.bind(this)
        };

        this._submitterCountiesDataService
            .getRegistrationSettings$()
            .pipe(takeUntil(this._$onDestroy$))
            .subscribe((registrationSettings: CountyRegistrationSettings) => {
                this.registrationSettings = { ...registrationSettings };
            });

        this.showingSettings$
            .pipe(takeUntil(this._$onDestroy$))
            .subscribe((showSettings: boolean) => {
                if (
                    showSettings &&
                    this.registrationSettings?.submitterSettings
                ) {
                    this._generateRegistrationForm(this.registrationSettings);
                }
            });

        this.showingSettings$.next(this.showSettings);

        this._submitterCountiesDataService
            .isAnyCountyRegistered$()
            .pipe(takeUntil(this._$onDestroy$))
            .subscribe((anyCountyRegistered: boolean) => {
                this._noCountiesAlreadyRegistered = !anyCountyRegistered;
            });

        this.gridData$ = this._submitterCountiesDataService
            .getCountyData$()
            .pipe(
                takeUntil(this._$onDestroy$),
                withLatestFrom(this.gridData$),
                map(this._processGridData)
            );

        this._submitterCountiesDataService.updatingUnassociatedCounties$
            .pipe(takeUntil(this._$onDestroy$))
            .subscribe((loading: boolean) => {
                this.unassociatedCountiesLoading = loading;
            });

        if (!!this.recipientID) {
            this._submitterCountiesDataService
                .getCountyData$()
                .pipe(
                    takeUntil(this._$onDestroy$),
                    filter(
                        (countyData) =>
                            !!countyData.unassociatedCounties?.length
                    ),
                    first()
                )
                .subscribe((countyData) => {
                    let county = countyData.unassociatedCounties.find(
                        (c) => c.id === this.recipientID
                    );
                    if (county) {
                        this.addCounty(county);
                    }
                });
        }

        this.masterGridOptions = this._setupMasterGridOptions();
    }

    ngOnDestroy() {
        this._$onDestroy$.next();
        this._$onDestroy$.complete();
        this._countiesAdded$.complete();
    }

    /** Public Methods **/
    onViewDocTypes(countyID: string) {
        this.viewDocTypes.emit(countyID);
    }

    addCounty(county: County) {
        const firstCounty = this._noCountiesAlreadyRegistered;

        if (!county.isOneClick) {
            if (county.useCustomFormForMOU) {
                this._activeModal.close();
                const modalRef = this._modalService.open(
                    OrganizationSignatureAuthorizationComponent,
                    { size: "lg" }
                );
                const modalInstance = modalRef.componentInstance;
                modalInstance.orgID = this.orgID;
                modalInstance.recipientID = county.id;

                modalRef.result.then((responseValue) => {
                    if (responseValue === "startRegistration") {
                        this._submitterRecipientService
                            .createMOUPackage(this.orgID, county.id)
                            .subscribe(
                                (documentData: {
                                    customFormID: string;
                                    documentID: string;
                                }) => {
                                    this.customFormID =
                                        documentData.customFormID;
                                    this.documentID = documentData.documentID;
                                    this.showCustomFormModal(county);
                                }
                            );
                    }
                });
                this.showNewMOUTour = this._tourService.shouldShowTour(
                    "SHOW_TOUR_NEW_MOU_PROCESS"
                );
            } else {
                // OLD registration process:
                this.registerCountyForm = this._formBuilder.group({});
                this._submitterCountiesDataService
                    .showRegistrationDetailsView(county.id, {}, this.orgID)
                    .subscribe((showSettings: boolean) => {
                        this.showingSettings$.next(showSettings);
                    });
            }
        } else {
            this._submitterCountiesDataService
                .addCounty(county, this.orgID)
                .subscribe((changedCounties: County[]) => {
                    if (changedCounties.length > 0) {
                        this._updateStateDisplay(changedCounties[0].state);
                        this._updateCountiesDisplay(changedCounties);
                        this._addCountySuccess(
                            firstCounty,
                            "County enabled.",
                            changedCounties
                        );
                    }
                });
        }
        return;
    }

    showCustomFormModal(county: County) {
        this.customFormModal = this._modalService.open(BaseModalComponent, {
            windowClass: "custom-form-modal"
        });
        const modalInstance = this.customFormModal.componentInstance;
        let primary = {
            text: "Read Form",
            disabled: true,
            callback: () => {
                window.open(
                    `sign-mou/${this.orgID}/${county.id}/mou`,
                    "_blank"
                );
            }
        };
        modalInstance.title = "Register Counties";
        modalInstance.primary = primary;
        modalInstance.template = this.customFormTemplate;

        // @ts-ignore
        this._windowRef.nativeWindow.mouAccepted = () => {
            this._growlService.success("Successful submission");
            this._modalService.dismissAll();
            this._submitterCountiesDataService
                .addCounty(county, this.orgID)
                .subscribe();
            this._submitterCountiesDataService.updateUnassociatedCounties(
                this.orgID
            );
        };
    }

    startNewMOUTour(tour: any) {
        if (this._tourService.shouldShowTour("SHOW_TOUR_NEW_MOU_PROCESS")) {
            setTimeout(() => {
                tour.startTour();
            }, 1000);
            this._userSettingsService.setUserSetting(
                "SHOW_TOUR_NEW_MOU_PROCESS",
                false
            );
        }
    }

    addAllOneClickCounties() {
        this._spinnerService.startSpinner();
        const firstCounty = this._noCountiesAlreadyRegistered;
        return this._submitterCountiesDataService
            .addAllOneClickCounties(this.orgID)
            .pipe(
                finalize(() => {
                    this._spinnerService.stopSpinner();
                })
            )
            .subscribe((changedCounties: County[]) => {
                if (changedCounties.length > 0) {
                    [
                        ...new Set(changedCounties.map((c: County) => c.state))
                    ].forEach((s: string) => {
                        this._updateStateDisplay(s);
                    });
                    this._updateCountiesDisplay(changedCounties);
                    this._addCountySuccess(
                        firstCounty,
                        "Counties added.",
                        changedCounties
                    );
                }
                this._checkForOneClickCountiesThatWereNotAdded();
            });
    }

    addAllOneClickCountiesForState(state: string) {
        this._spinnerService.startSpinner();
        const firstCounty = this._noCountiesAlreadyRegistered;
        return this._submitterCountiesDataService
            .addAllOneClickCountiesForState(state, this.orgID)
            .pipe(
                finalize(() => {
                    this._spinnerService.stopSpinner();
                })
            )
            .subscribe((changedCounties: County[]) => {
                if (changedCounties.length > 0) {
                    this._updateStateDisplay(state);
                    this._updateCountiesDisplay(changedCounties);
                    this._addCountySuccess(
                        firstCounty,
                        "Counties added.",
                        changedCounties
                    );
                }
                this._checkForOneClickCountiesThatWereNotAdded(state);
            });
    }

    private _checkForOneClickCountiesThatWereNotAdded(state?: string) {
        this.gridData$
            .pipe(take(1))
            .subscribe((gridData: RegisterCountiesGridRow[]) => {
                const rows = state
                    ? gridData.filter(
                          (gridRow: RegisterCountiesGridRow) =>
                              gridRow.state === state
                      )
                    : gridData;
                if (
                    rows.some(
                        (row: RegisterCountiesGridRow) =>
                            row.oneClickCountiesAvailable
                    )
                ) {
                    const msg =
                        "One or more counties could not be added. " +
                        "This can happen if the county has an invalid bank " +
                        "account or is a sample county.";
                    this._growlService.warning(msg, "", {
                        timeOut: 10000
                    });
                }
            });
    }

    openMOUWindow(dfd: any) {
        if (
            dfd.dropdownValueSettingName &&
            this.registrationSettings.recipientSettings[
                dfd.dropdownValueSettingName
            ] === undefined
        ) {
            this.requiredPopover.open();
            return;
        } else {
            // @ts-ignore
            this._windowRef.nativeWindow.mouAccepted = () => {
                let mouImmediate = false;
                let mou = false;

                let sSetting = "";
                this.registrationSettings.submitterSettings.forEach(
                    (setting: any) => {
                        if (
                            setting.settingType ===
                            RegistrationSettingTypeAlt["Required MOU Immediate"]
                        ) {
                            mouImmediate = true;
                            sSetting = setting.dataFieldDefinition.label;
                        }
                        if (
                            setting.settingType ===
                            RegistrationSettingTypeAlt["Required MOU"]
                        ) {
                            mou = true;
                            sSetting = setting.dataFieldDefinition.label;
                        }
                    }
                );

                if (mouImmediate) {
                    this.registerCountyForm.patchValue({ [sSetting]: "true" });
                }

                if (mou) {
                    this.registerCountyForm.patchValue({ [sSetting]: "false" });
                }
            };
            let dropDownSelection: string = "";
            if (dfd.dropdownValueSettingName) {
                dropDownSelection =
                    "&" +
                    dfd.dropdownValueSettingName.replace(/\s+/g, "") +
                    "=" +
                    this.registrationSettings.recipientSettings[
                        dfd.dropdownValueSettingName
                    ];
            }
            this._windowRef.nativeWindow.open(
                "/sf/" + dfd.href + dropDownSelection
            );
        }
    }

    registerCounty() {
        this._spinnerService.startSpinner();
        this.registrationSettings.errors = [];

        this.registrationSettings.submitterSettings.forEach((ss: any) => {
            const type = ss.settingType;
            const label: string = this._sanitizer.sanitize(
                SecurityContext.HTML,
                ss.dataFieldDefinition.label
            );
            let value = this.registerCountyForm.get([
                ss.dataFieldDefinition.label
            ]).value;

            if (
                !value &&
                type === RegistrationSettingTypeAlt["Optional Checkbox"]
            ) {
                value = "false";
            } else if (
                label === RecipientDefinition.Scale_Print &&
                value !== null &&
                value.match(/^([1-9]|[1-9][0-9]|[1][0][0])$/) === null
            ) {
                this.registrationSettings.errors.push(
                    "The value is not a valid percentage from 1 to 100."
                );
            }
            this.registrationSettings.recipientSettings[label] = value;

            if (!value) {
                switch (type) {
                    case "TEXTFIELD":
                        this.registrationSettings.errors.push(
                            `The <strong>${label}</strong> cannot be left blank.`
                        );
                        break;
                    case "REQUIRED_CHECKBOX":
                        this.registrationSettings.errors.push(
                            `The <strong>${label}</strong> cannot be unchecked.`
                        );
                        break;
                    case "REQUIRED_MOU":
                    case "REQUIRED_MOU_IMMEDIATE":
                        this.registrationSettings.errors.push(
                            `The <strong>${
                                label.trim().length ? label : "form"
                            }</strong> must be read and accepted.`
                        );
                        break;
                }
            } else {
                if (
                    label === RecipientDefinition.Customer_ID &&
                    !this._isValidCustomerID(value)
                ) {
                    this.registrationSettings.errors.push(
                        "Please enter a valid customer ID."
                    );
                }
            }
        });

        if (!this.registrationSettings.errors.length) {
            this.registrationSettings.recipientSettings["saveSettings"] = true;

            let countyAddedMessage = "County enabled.";
            if (
                this.registrationSettings.submitterSettings.find(
                    (ss: any) =>
                        ss.settingType ===
                        RegistrationSettingTypeAlt["Required MOU"]
                )
            ) {
                // REQUIRED_MOU should be added as a disabled county to the submitter list
                this.registrationSettings.recipientSettings["enabled"] = false;
                countyAddedMessage = "County added and pending registration.";
            }

            const firstCounty = this._noCountiesAlreadyRegistered;
            let message = !this.registrationSettings.recipient.enabled
                ? countyAddedMessage
                : "County updated.";
            let registerError = false;
            this._submitterCountiesDataService
                .addCounty(
                    this.registrationSettings.recipient,
                    this.registrationSettings.submitterID,
                    this.registrationSettings.recipientSettings,
                    true
                )
                .pipe(
                    map((changedCounties: County[]) => {
                        if (changedCounties[0]?.registrationsPending?.length) {
                            message =
                                changedCounties[0].registrationsPending.join(
                                    ", "
                                );
                        }
                        if (
                            this.registrationSettings.recipient
                                .isMultiCountyVendor
                        ) {
                            this._submitterCountiesDataService.updateUnassociatedCounties(
                                this.orgID
                            );
                        }
                        this._addCountySuccess(
                            firstCounty,
                            message,
                            changedCounties
                        );
                    }),
                    catchError((err) => {
                        registerError = true;
                        const notTitle7AMError =
                            "Only Title Insurers or Underwritten Title Companies are permitted access to 7am Queues";
                        const displayError =
                            err.error.errorMessage ===
                            "This recipient queue is only setup for Company ERDS Eligibility documents: [Title Insurer, Underwritten Title Company]"
                                ? notTitle7AMError
                                : err.error.errorMessage;
                        this.registrationSettings.errors.push(displayError);
                        return of([]);
                    }),
                    finalize(() => {
                        if (!registerError) {
                            this.closeRegisterCountiesDialog();
                        }
                        this._spinnerService.stopSpinner();
                    })
                )
                .subscribe(() => {});
        } else {
            this._spinnerService.stopSpinner();
        }
    }

    toggleState(state: string) {
        const rowNode = this._masterGridApi.getRowNode(state);
        this._masterGridApi.setRowNodeExpanded(rowNode, !rowNode.expanded);
    }

    closeRegisterCountiesDialog() {
        this.showingSettings$.next(false);
        this._activeModal.close();
    }

    selectOption(label: string, option: string) {
        this._handleRestrictions(label, option);
        this.registrationSettings.recipientSettings[label] = option;
        this.registerCountyForm.get(label).setValue(option);
    }

    validateMinimalCustomForm(valid: boolean) {
        this.customFormModal.componentInstance.primary.disabled = !valid;
    }

    /** Private Methods **/
    private _updateCountiesDisplay(counties: County[]) {
        this._masterGridApi.forEachDetailGridInfo(
            (gridInfo: DetailGridInfo) => {
                const parentNode = this._masterGridApi.getRowNode(
                    gridInfo.id.split("detail_")[1]
                );
                const countiesFromThisStateToRemove = counties.filter(
                    (c: County) => {
                        return c.state === parentNode.id;
                    }
                );
                gridInfo.api.applyTransaction({
                    remove: countiesFromThisStateToRemove
                });

                const remainingRows = gridInfo.api.getDisplayedRowCount();
                if (remainingRows > 0) {
                    this._masterGridApi.resetRowHeights();
                } else {
                    this._masterGridApi.setRowNodeExpanded(parentNode, false);
                }
            }
        );
    }

    private _updateStateDisplay(state: string) {
        const parentNode = this._masterGridApi.getRowNode(state);
        this.gridData$
            .pipe(take(1))
            .subscribe((gridData: RegisterCountiesGridRow[]) => {
                const currentStateGridData = gridData.find(
                    (gridRow: RegisterCountiesGridRow) => {
                        return gridRow.state === state;
                    }
                );
                parentNode.setData(currentStateGridData);
            });
    }

    private _addCountySuccess(
        firstCounty: boolean,
        message: string,
        counties: County[]
    ) {
        this._growlService.success(message);
        this.showingSettings$.next(false);
        if (firstCounty) {
            // first county added, so clear cache so org status updates
            this._userOrgService.clearCachedOrgsAndUsers();
        }
        this._countiesAdded$.next(counties);
    }

    private _isValidCustomerID(customerID: string) {
        const pattern =
            this.registrationSettings.recipientConfiguration[
                "customer_id_pattern"
            ];
        if (pattern?.length) {
            const regex = new RegExp(pattern);
            return !!customerID.match(regex);
        } else {
            return true;
        }
    }

    private _generateRegistrationForm(settings: CountyRegistrationSettings) {
        settings.submitterSettings.forEach((s: RegistrationSetting) => {
            let controlValue: any = settings.recipientSettings;

            if (
                controlValue[s.dataFieldDefinition.label] &&
                (s.settingType === RegistrationSettingTypeAlt["Checkbox"] ||
                    s.settingType ===
                        RegistrationSettingTypeAlt["Optional Checkbox"])
            ) {
                controlValue[s.dataFieldDefinition.label] =
                    controlValue[s.dataFieldDefinition.label] === "true";
            }

            this.registerCountyForm.addControl(
                s.dataFieldDefinition.label,
                new FormControl(controlValue[s.dataFieldDefinition.label])
            );
            this.templateContext[s.dataFieldDefinition.label] = {
                dfd: s.dataFieldDefinition,
                rs: controlValue
            };
            if (
                s.settingType === RegistrationSettingTypeAlt["Dropdown"] &&
                Array.isArray(s.dataFieldDefinition.description)
            ) {
                this.templateContext[
                    s.dataFieldDefinition.label
                ].dfd.description = s.dataFieldDefinition.description.map(
                    (optionObj) => optionObj.option
                );
            }
        });
    }

    private _processGridData([countyData, gridData]: [
        CountyData,
        RegisterCountiesGridRow[]
    ]) {
        countyData.states.forEach((state: string) => {
            let gridRow: RegisterCountiesGridRow = {
                oneClickCountiesAvailable: false,
                anyCountiesAvailable: true,
                state: state,
                addCountyText: "",
                hasNewCounties:
                    countyData.unassociatedCounties.filter((uc: County) => {
                        return uc.state === state && uc.isNew;
                    }).length > 0,
                anyCountiesAvailableCount:
                    countyData.unassociatedCounties.filter(
                        (uc: County): boolean => {
                            return uc.state === state;
                        }
                    ).length
            };
            if (
                !countyData.unassociatedStateCounties.hasOwnProperty(state) ||
                countyData.unassociatedStateCounties[state].length === 0
            ) {
                gridRow.addCountyText = "All counties added";
                gridRow.anyCountiesAvailable = false;
                gridRow.anyCountiesAvailableCount = 0;
            } else if (
                countyData.unassociatedOneClickCounties[state].length === 0
            ) {
                gridRow.addCountyText = "No one-click counties";
            } else if (
                countyData.unassociatedOneClickCounties[state].length > 0
            ) {
                gridRow.addCountyText = "Add all one-click counties";
                gridRow.oneClickCountiesAvailable = true;
            }
            const index = gridData.findIndex(
                (row: RegisterCountiesGridRow) => row.state === gridRow.state
            );
            if (index > -1) {
                gridData.splice(index, 1, gridRow);
            } else {
                gridData.push(gridRow);
            }
        });
        return gridData;
    }

    private _setupMasterGridOptions() {
        return {
            suppressCellFocus: true,
            suppressRowHoverHighlight: true,
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            masterDetail: true,
            detailRowHeight: this.rowHeight,
            icons: {
                groupContracted: icon(ICONS.caretSquareRight).html[0],
                groupExpanded: icon(ICONS.caretSquareDown).html[0]
            },
            isRowMaster: (gridRow: RegisterCountiesGridRow): boolean => {
                return gridRow.anyCountiesAvailable;
            },
            getRowId(params: GetRowIdParams) {
                return params.data.state;
            },
            getRowHeight: (params: RowHeightParams): number => {
                const isDetailRow: boolean = params.node.detail;
                if (isDetailRow) {
                    return (
                        params.data.anyCountiesAvailableCount * this.rowHeight +
                        53
                    );
                }
                return this.rowHeight;
            },
            onGridReady: (event: { api: GridApi }) => {
                this._masterGridApi = event.api;
                event.api.setColumnDefs(this._getMasterGridColumnDefs());
            },
            detailCellRendererParams: {
                detailGridOptions: this._setupDetailGridOptions(),
                getDetailRowData: (params: any) => {
                    this._submitterCountiesDataService
                        .getCountyData$()
                        .pipe(take(1))
                        .subscribe((countyData: CountyData) => {
                            params.successCallback(
                                countyData.unassociatedStateCounties[
                                    params.data.state
                                ]
                            );
                        });
                }
            }
        };
    }

    private _getMasterGridColumnDefs() {
        return [
            {
                field: "state",
                headerName: "State",
                cellRenderer: "agGroupCellRenderer",
                cellClassRules: {
                    "all-counties-added": (params: any) => {
                        return !params.data.anyCountiesAvailable;
                    }
                },
                cellRendererParams: {
                    innerRenderer: TemplateRendererComponent,
                    innerRendererParams: {
                        ngTemplate: this.stateTemplate
                    }
                },
                flex: 1
            },
            {
                field: "addCountyText",
                headerName: "Add County",
                flex: 1,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.addCountyTemplate
                }
            }
        ];
    }

    private _setupDetailGridOptions() {
        return {
            suppressCellFocus: true,
            suppressRowHoverHighlight: true,
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            headerHeight: 0,
            rowHeight: this.rowHeight,
            getRowId(params: GetRowIdParams) {
                return params.data.id;
            },
            onGridReady: ($event: { api: GridApi }) => {
                $event.api.setColumnDefs(this._getDetailGridColumnDefs());
            }
        };
    }

    private _getDetailGridColumnDefs() {
        return [
            {
                headerName: "County",
                field: "name",
                flex: 1,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.countyNameTemplate
                }
            },
            {
                headerName: "Document Types",
                field: "recordableDocTypes",
                flex: 1,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.numDocTypes
                }
            },
            {
                headerName: "Register",
                field: "isOneClick",
                flex: 1,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.registerTemplate
                }
            }
        ];
    }

    private _handleRestrictions(label: string, option: string) {
        this.restricted = false;
        this.primary.disabled = false;
        if (label === "Company ERDS Eligibility") {
            this._submitterRecipientService
                .titleCompanyCheck7AM(
                    this.registrationSettings.recipientID,
                    option
                )
                .subscribe((allowed) => {
                    if (!allowed) {
                        this.restricted = true;
                        this.restrictedTooltip =
                            "Only Title Insurers or Underwritten Title Companies are permitted access to 7am Queues";
                        this.primary.disabled = true;
                    }
                });
        }
    }
}
