<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>
    <form [formGroup]="editForm">
        <div class="row">
            <div class="form-group col-sm-2">
                <label for="prefixSelector">Prefix<span class="optional">&nbsp;(Optional)</span></label>
                <sf-select aria-label="Prefix"
                    id="prefixSelector" name="prefix" placeholder=""
                    class="sf-mask"
                    [isSingleSelect]="true"
                    [options]="prefixOptions"
                    [selectedOption]="editForm.controls.prefix.value"
                    (select)="prefixChanged($event.$selection)"
                    [hasClearButton]="false"
                    [hasFilter]="false"
                    [withFooter]="false"
                    trackBy="option"
                    labelBy="label"></sf-select>
            </div>
            <div class="form-group col-sm-4">
                <label for="firstName">First Name</label>
                <input id="firstName" type="text" class="form-control sf-mask" size="25"
                    maxlength="82" formControlName="firstName" required/>
            </div>
            <div class="form-group col-sm-2">
                <label for="middleName">Middle</label>
                <input id="middleName" type="text" class="form-control sf-mask"
                    maxlength="82" formControlName="middleName"/>
            </div>
            <div class="form-group col-sm-4">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="text" class="form-control sf-mask" size="25"
                    maxlength="82" formControlName="lastName" required/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-6">
                <label for="title">Job Title</label>
                <input id="title" type="text" class="form-control" required
                    maxlength="82" formControlName="title"/>
            </div>
<!--            <div class="form-group col-sm-6" *ngIf="isRecipient">-->
            <div class="form-group col-sm-6">
                <label for="initials">Employee ID<span class="optional">&nbsp;(Optional)</span></label>
                <input id="initials" type="text" class="form-control sf-mask"
                    maxlength="12" formControlName="initials"/>
            </div>
        </div>
        <div *ngIf="allowSecondaryTitle" class="row">
            <div class="form-group col-sm-8">
                <label for="secondaryTitle">Secondary Title</label>
                <input id="secondaryTitle" type="text" class="form-control" maxlength="82" formControlName="secondaryTitle"/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-4">
                <label for="phone">Phone Number</label>
                <input id="phone" type="tel" formControlName="phone" name="phone" class="form-control sf-mask"
                    maxlength="20" placement="top" ngbTooltip="10-digit phone number" sfPhoneInput/>
            </div>
            <div class="form-group col-sm-3">
                <label for="extension">Ext.</label>
                <input id="extension" type="text" formControlName="extension" maxlength="9" class="form-control sf-mask"
                    placement="top" ngbTooltip="Phone extension (optional)"/>
            </div>
            <div class="form-group col-sm-4">
                <label for="mobile-phone">
                    Mobile Phone Number
                    &nbsp;
                    <button *ngIf="!mobilePhoneString" class="btn btn-link sf-btn-link"
                            ngbTooltip="Mobile numbers can be used for two-factor authentication." placement="left-top"
                            aria-label="Details"
                            tooltipClass="tooltip-interaction">
                        <fa-icon icon="info-circle"></fa-icon>
                    </button>
                    <button *ngIf="!!contactData.mobilePhoneVerifiedDate" class="btn btn-link sf-btn-link"
                            ngbTooltip="Mobile phone verified on {{contactData.mobilePhoneVerifiedDate | date:'shortDate'}}."
                            aria-label="Details"
                            placement="left-top" tooltipClass="tooltip-interaction">
                        <fa-icon icon="check-circle" class="green-button"></fa-icon>
                    </button>
                    <button *ngIf="!!mobilePhoneString && !contactData.mobilePhoneVerifiedDate" class="btn btn-link sf-btn-link"
                            ngbTooltip="Phone number is unverified and must be verified by the user."
                            aria-label="Details"
                            placement="left-top" tooltipClass="tooltip-interaction">
                        <fa-icon icon="exclamation-circle" class="orange-button"></fa-icon>
                    </button>
                </label>
                <input id="mobile-phone" type="tel" formControlName="mobilePhone" name="mobile-phone" class="form-control sf-mask"
                        sfPhoneInput maxlength="14"
                        ngbTooltip="10-digit phone number" placement="top" tooltipClass="tooltip-interaction" [openDelay]="200"/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-8">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control email-input sf-mask" maxlength="255" formControlName="email" required/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-8">
                <label for="street">Street Address<span class="optional">&nbsp;(Optional)</span></label>
                <input type="text" id="street" class="form-control sf-mask" formControlName="street1" ngbTooltip="Address line 1"
                    maxlength="255" placement="top" style="margin-bottom: 6px"/>
                <input type="text" class="form-control sf-mask" formControlName="street2" ngbTooltip="Address line 2"
                    maxlength="255" placement="top" aria-label="Street address line 2"/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-6">
                <label for="city">City<span class="optional">&nbsp;(Optional)</span></label>
                <input id="city" type="text" class="form-control sf-mask" maxlength="30" formControlName="city" ngbTooltip="City" placement="top"/>
            </div>
            <div class="form-group col-sm-4">
                <label for="state">State<span class="optional">&nbsp;(Optional)</span></label>
                <sf-state-selector id="state-sel" class="sf-mask"
                    [selectedStateID]="editForm.controls.state.value"
                    [canClearSelectedState]="true"
                    (select)="stateChanged($event)"></sf-state-selector>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-3">
                <label for="zipCode">Zip<span class="optional">&nbsp;(Optional)</span></label>
                <input id="zipCode" type="text" formControlName="zipCode" class="form-control sf-mask" maxlength="10"/>
            </div>
        </div>

        <div *ngIf="invalidAddressMessage" class="row outer-validation">
            <div class="inner-validation">
                <span [innerHTML]="invalidAddressMessage"></span><br/>
                <label *ngIf="showCheckbox"><input type="checkbox" name="amCheckbox" [(ngModel)]="addressCorrect" [ngModelOptions]="{standalone: true}"/> The address I entered is correct</label>
                <label *ngIf="showRadios"><input type="radio" name="amRadio" [(ngModel)]="radioSelection" [ngModelOptions]="{standalone: true}" value="recommended"/> Use the recommended address</label><br/>
                <label *ngIf="showRadios"><input type="radio" name="amRadio" [(ngModel)]="radioSelection" [ngModelOptions]="{standalone: true}" value="entered"/> Use the address entered above</label>
            </div>
        </div>

    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
