<div class="wrapper">
    <input type="text" #searchInput [id]="inputID" class='inline' [(ngModel)]="model.text" [placeholder]="placeholder"
           (keyup)="handleKey($event)" [disabled]="!enable" (click)="onClick()" spellcheck="false"
           (blur)="onBlur()" (focus)="onFocus()" (keydown)="onKeystroke.emit($event)"
           (keypress)="onKeystroke.emit($event)" [attr.aria-label]="placeholder">
    <button *ngIf="model.text?.length > 0"
            class="btn btn-link inline-search-icon"
            (click)="cancelSearch()"
            aria-label="Cancel Search">
        <fa-icon [icon]="['far', 'times']"
                 [fixedWidth]="true">
        </fa-icon>
    </button>
    <fa-icon [icon]="icon"
             [fixedWidth]="true"
             *ngIf="!model.text?.length"
             class="inline-search-icon">
    </fa-icon>
    <button class="btn btn-secondary mobile-search-icon" (click)="displayInlineDialog = true" aria-label="Open Search">
        <fa-icon [icon]="['far', 'search']"></fa-icon>
    </button>
</div>
<div class="inline-search" *ngIf="displayInlineDialog">
    <input type="text" class="mobile" [(ngModel)]="model.text"
           placeholder="Filter by package name, county, doc type, etc"
           (keyup)="emitChange()" [attr.aria-label]="placeholder"/>
    <button (click)="displayInlineDialog = false"
            aria-label="Close Search"
            class="btn btn-link close-inline-search">
        <fa-icon [icon]="['far', 'times-circle']">
        </fa-icon>
    </button>
</div>
