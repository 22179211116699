import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminCountyMessagesComponent } from "./components/admin-county-messages/admin-county-messages.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AdminCountyOpenStatusComponent } from "./components/admin-county-open-status/admin-county-open-status.component";
import { AdminOrganizationQuicklinksComponent } from "./components/admin-organization-quicklinks/admin-organization-quicklinks.component";
import { ModalsModule, TemplateRendererComponent } from "@sf/common";
import { LiveConfigChangesTableComponent } from "./live-config/components/live-config-changes-table/live-config-changes-table.component";
import { AgGridModule } from "@ag-grid-community/angular";

@NgModule({
    declarations: [
        AdminCountyMessagesComponent,
        AdminCountyOpenStatusComponent,
        AdminOrganizationQuicklinksComponent,
        LiveConfigChangesTableComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbModule,
        ModalsModule,
        AgGridModule
    ],
    exports: [
        AdminCountyMessagesComponent,
        AdminCountyOpenStatusComponent,
        AdminOrganizationQuicklinksComponent,
        LiveConfigChangesTableComponent
    ]
})
export class AdminCommonModule {}
