import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectModule } from "@sf/common";
import {
    NgbDropdownMenu,
    NgbModalModule,
    NgbModule,
    NgbTooltipModule,
    NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ImageCropperModule } from "ngx-image-cropper";
import { SettingsESignEventComponent } from "./components/settings-esign-event/settings-esign-event.component";
import { UserContactReadonlyComponent } from "./components/user-contact-readonly/user-contact-readonly.component";
import { OrganizationContactComponent } from "./components/organization-contact/organization-contact.component";
import { OrganizationSecurityComponent } from "./components/organization-security/organization-security.component";
import { OrganizationAddressDialogComponent } from "./dialogs/organization-address-dialog/organization-address-dialog.component";
import { UserEditDialogComponent } from "./dialogs/user-edit-dialog/user-edit-dialog.component";
import { SelectUserDialogComponent } from "./dialogs/select-user-dialog/select-user-dialog.component";
import { ContactEditDialogComponent } from "./dialogs/contact-edit-dialog/contact-edit-dialog.component";
import { OrgSelectorPipe } from "./pipes/org-selector.pipe";
import { UserSelectorPipe } from "./pipes/user-selector.pipe";
import { RoleSelectorPipe } from "./pipes/role-selector-pipe";
import { OrganizationMultiSelectorComponent } from "./components/organization-multi-selector/organization-multi-selector.component";
import { OrganizationFilterSelectorComponent } from "./components/organization-filter-selector/organization-filter-selector.component";
import { UserMultiSelectorComponent } from "./components/user-multi-selector/user-multi-selector.component";
import { UserFilterSelectorComponent } from "./components/user-filter-selector/user-filter-selector.component";
import { RoleMultiSelectorComponent } from "./components/role-multi-selector/role-multi-selector.component";
import { RemoveRolesDialogComponent } from "./dialogs/remove-roles-dialog/remove-roles-dialog.component";
import { OrganizationUsersComponent } from "./components/organization-users/organization-users.component";
import { AddRoleDialogComponent } from "./dialogs/add-role-dialog/add-role-dialog.component";
import { PhoneSupportQuestionDialogComponent } from "@sf/userorg/common";
import { AddUserDialogComponent } from "./dialogs/add-user-dialog/add-user-dialog.component";
import { OrganizationPaymentAccountsComponent } from "./components/organization-payment-accounts/organization-payment-accounts.component";
import { EnrollmentCodeSelectDialogComponent } from "./dialogs/enrollment-code-select-dialog/enrollment-code-select-dialog.component";
import { CreateOrganizationDialogComponent } from "./dialogs/create-organization-dialog/create-organization-dialog.component";
import { ArchiveOrganizationDialogComponent } from "./dialogs/archive-organization-dialog/archive-organization-dialog.component";
import { AddEditPaymentAccountDialogComponent } from "./dialogs/add-edit-payment-account-dialog/add-edit-payment-account-dialog.component";
import { ImportPaymentAccountDialogComponent } from "./dialogs/import-payment-account-dialog/import-payment-account-dialog.component";
import { ActivityLogComponent } from "./components/activity-log/activity-log.component";
import { ActivityLogEntryComponent } from "./components/activity-log-entry/activity-log-entry.component";
import { TokenDetailsDialogComponent } from "./dialogs/token-details-dialog/token-details-dialog.component";
import { SuspendedServiceDialogComponent } from "./dialogs/suspended-service-dialog/suspended-service-dialog.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { TemplateRendererComponent } from "@sf/common";
import { RecipientSubmitterSettingsDialogComponent } from "./dialogs/recipient-submitter-settings-dialog/recipient-submitter-settings-dialog.component";
import { RecipientSubmitterSettingComponent } from "./components/recipient-submitter-setting/recipient-submitter-setting.component";
import { UpdatePackagesDefaultAccountDialogComponent } from "./dialogs/update-packages-default-account-dialog/update-packages-default-account-dialog.component";
import { UnaffectedPackageUpdateDialogComponent } from "./dialogs/unaffected-package-update-dialog/unaffected-package-update-dialog.component";
import { ArchivedUserDialogComponent } from "./dialogs/archived-user-dialog/archived-user-dialog.component";
import { UserContactComponent } from "./components/user-contact/user-contact.component";
import { OrganizationServicesComponent } from "./components/organization-services/organization-services.component";
import { OrganizationServiceActivationDialogComponent } from "./dialogs/organization-service-activation-dialog/organization-service-activation-dialog.component";
import { OrganizationServiceInviteDialogComponent } from "./dialogs/organization-service-invite-dialog/organization-service-invite-dialog.component";
import { OrganizationServiceSuspendDialogComponent } from "./dialogs/organization-service-suspend-dialog/organization-service-suspend-dialog.component";
import { MsaUploadDialogComponent } from "./dialogs/msa-upload-dialog/msa-upload-dialog.component";
import { SubmitterLicenseUploadDialogComponent } from "./dialogs/submitter-license-upload-dialog/submitter-license-upload-dialog.component";
import { ContactInfoVerifyComponent } from "./components/contact-info-verify/contact-info-verify.component";
import { SubmitterSigningUploadDialogComponent } from "./dialogs/submitter-signing-upload-dialog/submitter-signing-upload-dialog.component";
import { LenderLicenseUploadDialogComponent } from "./dialogs/lender-license-upload-dialog/lender-license-upload-dialog.component";
import { TrusteeUploadDialogComponent } from "./dialogs/trustee-upload-dialog/trustee-upload-dialog.component";
import { EsignEventUploadDialogComponent } from "./dialogs/esign-event-upload-dialog/esign-event-upload-dialog.component";
import { SettlementLicenseUploadDialogComponent } from "./dialogs/settlement-license-upload-dialog/settlement-license-upload-dialog.component";
import { VendorLicenseUploadDialogComponent } from "./dialogs/vendor-license-upload-dialog/vendor-license-upload-dialog.component";
import { PaperRecordingUploadDialogComponent } from "./dialogs/paper-recording-upload-dialog/paper-recording-upload-dialog.component";
import { ParentProductInactiveDialogComponent } from "./dialogs/parent-product-inactive-dialog/parent-product-inactive-dialog.component";
import { OrganizationSalesTaxComponent } from "./components/organization-sales-tax/organization-sales-tax.component";
import { OrganizationListComponent } from "./components/organization-list/organization-list.component";
import { ContractPricingTiersComponent } from "./components/contract-pricing-tiers/contract-pricing-tiers.component";
import { EditContractPricingTiersDialogComponent } from "./dialogs/edit-contract-pricing-tiers-dialog/edit-contract-pricing-tiers-dialog.component";
import { ArchiveUserDialogComponent } from "./dialogs/archive-user-dialog/archive-user-dialog.component";
import { RemoveUserDialogComponent } from "./dialogs/remove-user-dialog/remove-user-dialog.component";
import { UnarchiveUserDialogComponent } from "./dialogs/unarchive-user-dialog/unarchive-user-dialog.component";
import { RemoveAllUserRolesDialogComponent } from "./dialogs/remove-all-user-roles-dialog/remove-all-user-roles-dialog.component";
import { UserListComponent } from "./components/user-list/user-list.component";
import { UserApiTokensDialogComponent } from "./dialogs/user-api-tokens-dialog/user-api-tokens-dialog.component";
import { OrganizationMultiSelectDialogComponent } from "./dialogs/organization-multi-select-dialog/organization-multi-select-dialog.component";
import { UserMultiSelectDialogComponent } from "./dialogs/user-multi-select-dialog/user-multi-select-dialog.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { UserOrganizationsComponent } from "./components/user-organizations/user-organizations.component";
import { TempAdminPermissionDateDialogComponent } from "./dialogs/temp-admin-permission-date-dialog/temp-admin-permission-date-dialog.component";
import { OrgHeaderWithSelectorComponent } from "./components/org-header-with-selector/org-header-with-selector.component";
import { UserHeaderWithSelectorComponent } from "./components/user-header-with-selector/user-header-with-selector.component";
import { SettingsPersonalComponent } from "./components/settings-personal/settings-personal.component";
import { ChangePasswordSettingsPersonalDialogComponent } from "./dialogs/change-password-settings-personal-dialog/change-password-settings-personal-dialog.component";
import { EditPersonalContactAddressSettingsPersonalDialogComponent } from "./dialogs/edit-personal-contact-address-settings-personal-dialog/edit-personal-contact-address-settings-personal-dialog.component";
import { EditSecurityQuestionsSettingsPersonalDialogComponent } from "./dialogs/edit-security-questions-settings-personal-dialog/edit-security-questions-settings-personal-dialog.component";
import { EditSupportQuestionSettingsPersonalDialogComponent } from "./dialogs/edit-support-question-settings-personal-dialog/edit-support-question-settings-personal-dialog.component";
import { ModalsModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { SfCommonModule } from "@sf/common";
import { DateAndTimeModule } from "@sf/common";
import { RoleDesignerComponent } from "./components/role-designer/role-designer.component";
import { RoleDetailsDialogComponent } from "./dialogs/role-details-dialog/role-details-dialog.component";
import { OrganizationConfigurationComponent } from "./components/organization-configuration/organization-configuration.component";
import { LicenseSubmitterComponent } from "./components/license-submitter/license-submitter.component";
import { CommonPipesModule } from "@sf/common";
import { LicenseSendDialogComponent } from "./dialogs/license-send-dialog/license-send-dialog.component";
import { LicenseLenderComponent } from "./components/license-lender/license-lender.component";
import { CapcThirdPartyDialogComponent } from "./dialogs/capc-third-party-dialog/capc-third-party-dialog.component";
import { LicenseDocumentBuilderComponent } from "./components/license-document-builder/license-document-builder.component";
import { LicenseSettlementComponent } from "./components/license-settlement/license-settlement.component";
import { LicensePaperComponent } from "./components/license-paper/license-paper.component";
import { LicenseVendorComponent } from "./components/license-vendor/license-vendor.component";
import { MsaUpdateDialogComponent } from "./dialogs/msa-update-dialog/msa-update-dialog.component";
import { EditEcheckMemoDialogComponent } from "./dialogs/edit-echeck-memo-dialog/edit-echeck-memo-dialog.component";
import { PendingPaymentsLoadingOverlayComponent } from "./components/pending-payments-loading-overlay/pending-payments-loading-overlay.component";
import { PendingPaymentsNoRowsOverlayComponent } from "./components/pending-payments-no-rows-overlay/pending-payments-no-rows-overlay.component";
import { DuplicateCheckNumberComponent } from "./dialogs/duplicate-check-number/duplicate-check-number.component";
import { PendingPaymentsFeeDetailDialogComponent } from "./dialogs/pending-payments-fee-detail-dialog/pending-payments-fee-detail-dialog.component";
import { UploadProfilePictureDialogComponent } from "./dialogs/upload-profile-picture-dialog/upload-profile-picture-dialog.component";
import { LicenseTrusteeComponent } from "./components/license-trustee/license-trustee.component";
import { LicenseEsignEventComponent } from "./components/license-esign-event/license-esign-event.component";
import { UserApiTokensComponent } from "./components/user-api-tokens/user-api-tokens.component";
import { RemoveProfilePictureDialogComponent } from "./dialogs/remove-profile-picture-dialog/remove-profile-picture-dialog.component";
import { LicenseAllDocumentsComponent } from "./components/license-all-documents/license-all-documents.component";
import { SuspensionNotificationDialogComponent } from "./dialogs/suspension-notification-dialog/suspension-notification-dialog.component";
import { SubmitterAmendmentUploadDialogComponent } from "./dialogs/submitter-amendment-upload-dialog/submitter-amendment-upload-dialog.component";
import { PastDueCreditCardPaymentDialogComponent } from "./dialogs/past-due-credit-card-payment-dialog/past-due-credit-card-payment-dialog.component";
import { CustomFormModule } from "@sf/custom-form";
import { NotificationBannerComponent } from "./components/notification-banner/notification-banner.component";
import { NotificationBannerItemComponent } from "./components/notification-banner-item/notification-banner-item.component";
import { PendingPaymentsComponent } from "./components/pending-payments/pending-payments.component";
import { BulkCreditCardRetryDialogComponent } from "./dialogs/bulk-credit-card-retry-dialog/bulk-credit-card-retry-dialog.component";
import { EsignCoreModule } from "@sf/esign/common";
import { ImportApiUsersDialogComponent } from "./dialogs/import-api-users-dialog/import-api-users-dialog.component";
import { SecurityComponent } from "./components/security/security.component";
import { SubmitterCommonModule } from "@sf/submitter/common";
import { AddMobilePhoneDialogComponent } from "./dialogs/add-mobile-phone-dialog/add-mobile-phone-dialog.component";
import { VerifyPhoneNumberDialogComponent } from "./dialogs/verify-phone-number-dialog/verify-phone-number-dialog.component";
import { QrCodeDialogComponent } from "./dialogs/qr-code-dialog/qr-code-dialog.component";
import { ConfirmSurchargeDialogComponent } from "./dialogs/confirm-surcharge-dialog/confirm-surcharge-dialog.component";
import { UnregisterAuthenticatorDialogComponent } from "./dialogs/unregister-authenticator-dialog/unregister-authenticator-dialog.component";
import { OrganizationServiceTrialDialogComponent } from "./dialogs/organization-service-trial-dialog/organization-service-trial-dialog.component";
import { LimitedTrialDialogComponent } from "./dialogs/limited-trial-dialog/limited-trial-dialog.component";
import { SalespersonContactDialogComponent } from "./dialogs/salesperson-contact-dialog/salesperson-contact-dialog.component";
import { NoOrgCreationDialogComponent } from "./dialogs/no-org-creation-dialog/no-org-creation-dialog.component";
import { DocbuilderRonUploadDialogComponent } from "./dialogs/docbuilder-ron-upload-dialog/docbuilder-ron-upload-dialog.component";
import { LicenseDocbuilderRonComponent } from "./components/license-docbuilder-ron/license-docbuilder-ron.component";
import { ContractDetailsDialogComponent } from "./dialogs/contract-details-dialog/contract-details-dialog.component";
import { InvitationDetailsDialogComponent } from "./dialogs/invitation-details-dialog/invitation-details-dialog.component";
import { UserorgCoreModule } from "@sf/userorg/common";
import { LicenseMsaComponent } from "./components/license-msa/license-msa.component";
import { NameChangeUploadDialogComponent } from "./dialogs/name-change-upload-dialog/name-change-upload-dialog.component";
import { LicenseLienReleaseComponent } from "./components/license-lien-release/license-lien-release.component";
import { LienReleaseUploadDialogComponent } from "./dialogs/lien-release-upload-dialog/lien-release-upload-dialog.component";
import { SubscriptionUploadDialogComponent } from "./dialogs/subscription-upload-dialog/subscription-upload-dialog.component";
import { LicenseSubscriptionComponent } from "./components/license-subscription/license-subscription.component";
import { SubscriptionInfoDialogComponent } from "./dialogs/subscription-info-dialog/subscription-info-dialog.component";
import { OrganizationExternalIdDialogComponent } from "./dialogs/organization-external-id-dialog/organization-external-id-dialog.component";
import { EcheckRefundAddressConfirmationDialogComponent } from "./dialogs/echeck-refund-address-confirmation-dialog/echeck-refund-address-confirmation-dialog.component";
import { PackagesUsingAccountArchivedDialogComponent } from "./dialogs/packages-using-account-archived-dialog/packages-using-account-archived-dialog.component";
import { EditEmailDialogComponent } from "./dialogs/edit-email-dialog/edit-email-dialog.component";

@NgModule({
    imports: [
        AgGridModule,
        CommonModule,
        CustomFormModule,
        DateAndTimeModule,
        FontAwesomeModule,
        FormsModule,
        ModalsModule,
        NgbModalModule,
        NgbModule,
        NgbTooltipModule,
        NgbTypeaheadModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        SelectModule,
        SfCommonModule,
        SfFormsModule,
        ImageCropperModule,
        CommonPipesModule,
        UserorgCoreModule,
        EsignCoreModule,
        SubmitterCommonModule
    ],
    declarations: [
        OrgSelectorPipe,
        UserSelectorPipe,
        RoleSelectorPipe,
        OrgHeaderWithSelectorComponent,
        UserHeaderWithSelectorComponent,
        OrganizationContactComponent,
        UserContactReadonlyComponent,
        OrganizationSecurityComponent,
        OrganizationUsersComponent,
        OrganizationAddressDialogComponent,
        AddRoleDialogComponent,
        AddUserDialogComponent,
        RemoveRolesDialogComponent,
        ContactEditDialogComponent,
        SelectUserDialogComponent,
        UserEditDialogComponent,
        PhoneSupportQuestionDialogComponent,
        OrganizationMultiSelectorComponent,
        OrganizationFilterSelectorComponent,
        UserMultiSelectorComponent,
        UserFilterSelectorComponent,
        RoleMultiSelectorComponent,
        RoleSelectorPipe,
        OrganizationPaymentAccountsComponent,
        EnrollmentCodeSelectDialogComponent,
        CreateOrganizationDialogComponent,
        ArchiveOrganizationDialogComponent,
        AddEditPaymentAccountDialogComponent,
        ImportPaymentAccountDialogComponent,
        ActivityLogComponent,
        ActivityLogEntryComponent,
        TokenDetailsDialogComponent,
        SuspendedServiceDialogComponent,
        RecipientSubmitterSettingsDialogComponent,
        RecipientSubmitterSettingComponent,
        SuspendedServiceDialogComponent,
        UpdatePackagesDefaultAccountDialogComponent,
        UnaffectedPackageUpdateDialogComponent,
        UserContactComponent,
        ArchivedUserDialogComponent,
        OrganizationServicesComponent,
        OrganizationServiceActivationDialogComponent,
        OrganizationServiceInviteDialogComponent,
        OrganizationServiceSuspendDialogComponent,
        ContactInfoVerifyComponent,
        ParentProductInactiveDialogComponent,
        OrganizationSalesTaxComponent,
        OrganizationListComponent,
        ContractPricingTiersComponent,
        EditContractPricingTiersDialogComponent,
        ArchiveUserDialogComponent,
        RemoveUserDialogComponent,
        UnarchiveUserDialogComponent,
        RemoveAllUserRolesDialogComponent,
        UserListComponent,
        UserApiTokensDialogComponent,
        OrganizationMultiSelectDialogComponent,
        UserMultiSelectDialogComponent,
        UserOrganizationsComponent,
        TempAdminPermissionDateDialogComponent,
        SettingsPersonalComponent,
        ChangePasswordSettingsPersonalDialogComponent,
        EditPersonalContactAddressSettingsPersonalDialogComponent,
        EditSecurityQuestionsSettingsPersonalDialogComponent,
        EditSupportQuestionSettingsPersonalDialogComponent,
        RoleDesignerComponent,
        RoleDetailsDialogComponent,
        OrganizationConfigurationComponent,
        CapcThirdPartyDialogComponent,
        LicenseDocumentBuilderComponent,
        LicenseDocbuilderRonComponent,
        LicenseEsignEventComponent,
        LicenseLenderComponent,
        LicensePaperComponent,
        LicenseSettlementComponent,
        LicenseSubmitterComponent,
        LicenseTrusteeComponent,
        LicenseVendorComponent,
        LicenseMsaComponent,
        LicenseLienReleaseComponent,
        LicenseSubscriptionComponent,
        LicenseAllDocumentsComponent,
        LimitedTrialDialogComponent,
        DocbuilderRonUploadDialogComponent,
        EsignEventUploadDialogComponent,
        LenderLicenseUploadDialogComponent,
        MsaUploadDialogComponent,
        PaperRecordingUploadDialogComponent,
        SettlementLicenseUploadDialogComponent,
        SubmitterLicenseUploadDialogComponent,
        SubmitterSigningUploadDialogComponent,
        TrusteeUploadDialogComponent,
        VendorLicenseUploadDialogComponent,
        SubmitterAmendmentUploadDialogComponent,
        LicenseSendDialogComponent,
        MsaUpdateDialogComponent,
        PendingPaymentsComponent,
        EditEcheckMemoDialogComponent,
        PendingPaymentsLoadingOverlayComponent,
        PendingPaymentsNoRowsOverlayComponent,
        DuplicateCheckNumberComponent,
        PendingPaymentsFeeDetailDialogComponent,
        UploadProfilePictureDialogComponent,
        UserApiTokensComponent,
        RemoveProfilePictureDialogComponent,
        SuspensionNotificationDialogComponent,
        PastDueCreditCardPaymentDialogComponent,
        NotificationBannerItemComponent,
        NotificationBannerComponent,
        BulkCreditCardRetryDialogComponent,
        ImportApiUsersDialogComponent,
        SecurityComponent,
        AddMobilePhoneDialogComponent,
        VerifyPhoneNumberDialogComponent,
        QrCodeDialogComponent,
        ConfirmSurchargeDialogComponent,
        UnregisterAuthenticatorDialogComponent,
        OrganizationServiceTrialDialogComponent,
        SalespersonContactDialogComponent,
        NoOrgCreationDialogComponent,
        ContractDetailsDialogComponent,
        InvitationDetailsDialogComponent,
        NameChangeUploadDialogComponent,
        LienReleaseUploadDialogComponent,
        SubscriptionUploadDialogComponent,
        SubscriptionInfoDialogComponent,
        SettingsESignEventComponent,
        OrganizationExternalIdDialogComponent,
        EcheckRefundAddressConfirmationDialogComponent,
        PackagesUsingAccountArchivedDialogComponent,
        EditEmailDialogComponent
    ],
    providers: [
        OrgSelectorPipe,
        UserSelectorPipe,
        RoleSelectorPipe,
        NgbDropdownMenu
    ],
    exports: [
        OrgHeaderWithSelectorComponent,
        UserHeaderWithSelectorComponent,
        OrganizationMultiSelectorComponent,
        OrganizationFilterSelectorComponent,
        UserMultiSelectorComponent,
        UserFilterSelectorComponent,
        RoleMultiSelectorComponent,
        OrganizationContactComponent,
        OrganizationSecurityComponent,
        OrganizationUsersComponent,
        LicenseDocumentBuilderComponent,
        LicenseDocbuilderRonComponent,
        LicenseEsignEventComponent,
        LicenseLenderComponent,
        LicensePaperComponent,
        LicenseSettlementComponent,
        LicenseSubmitterComponent,
        LicenseTrusteeComponent,
        LicenseVendorComponent,
        LicenseLienReleaseComponent,
        LicenseSubscriptionComponent,
        LicenseAllDocumentsComponent,
        LicenseMsaComponent,
        LienReleaseUploadDialogComponent,
        SubscriptionUploadDialogComponent,
        DocbuilderRonUploadDialogComponent,
        EsignEventUploadDialogComponent,
        LenderLicenseUploadDialogComponent,
        MsaUploadDialogComponent,
        PaperRecordingUploadDialogComponent,
        SettlementLicenseUploadDialogComponent,
        SubmitterLicenseUploadDialogComponent,
        SubmitterSigningUploadDialogComponent,
        TrusteeUploadDialogComponent,
        VendorLicenseUploadDialogComponent,
        ActivityLogComponent,
        OrganizationServicesComponent,
        UserContactComponent,
        ContactInfoVerifyComponent,
        OrganizationListComponent,
        UserListComponent,
        UserOrganizationsComponent,
        SettingsPersonalComponent,
        RoleDesignerComponent,
        ContractPricingTiersComponent,
        OrganizationConfigurationComponent,
        PendingPaymentsComponent,
        ActivityLogEntryComponent,
        UserApiTokensComponent,
        NotificationBannerItemComponent,
        NotificationBannerComponent,
        SettingsESignEventComponent
    ]
})
export class UserorgModule {}
