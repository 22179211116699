<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label [for]="field.id+'_custom_currency'" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">$</span>
            </div>
            <input
                #amount
                class="form-control"
                [id]="field.id+'_custom_currency'"
                [name]="field.id+'_custom_currency'"
                [formControl]="control"
                [autocomplete]="field.id+'_custom_currency'"
                [ngbTypeahead]="typeAhead"
                [focusFirst]="false"
                (click)="focus$.next($any($event).target.value)"
                (focus)="showHighlight();focus$.next($any($event).target.value)"
                (blur)="hideHighlight();!control.invalid && confirmAmount(control);!control.invalid && formatAsValidCurrency(control)"
                (keyup)="addCommasControl(control)"
                pattern="^[\s]*[\d,]*?([\.]\d{0,2})?\s*$" />
        </div>
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>

<ng-template #confirmAmountDialog>
    <label [for]="field.id+'_custom_currency_confirmation'">
        Currency amount is over $1 million.<br/>
        Please re-enter the same value to confirm.
    </label>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text">$</span>
        </div>
        <input
            #confirmAmount
            attr.aria-label="{{'Currency amount is over $1 million. Please re-enter the same value to confirm'}}"
            class="form-control"
            (paste)="$event.preventDefault()"
            [id]="field.id+'_custom_currency_confirmation'"
            (keyup)="handleConfirmInputChange(confirmAmount)" />
    </div>
</ng-template>
