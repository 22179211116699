<div class="select-holder">
    <sf-select
        id="{{controlID}}-select"
        name="sf-organization-search-selector"
        [class]="windowClass"
        [ngClass]="conditionalClasses"
        selectorName="sf-organization-search-selector"
        [placeholder]="placeholder"
        searchPlaceholder="Search"
        [hasFilter]="true"
        [filterIcon]="['far', 'search']"
        [filterDebounce]="300"
        [handleFilterExternally]="true"
        [isSingleSelect]="true"
        [selectedOption]="selectedOrgID"
        [options]="selectableItems"
        [hasClearButton]="canClearSelectedOrg"
        trackBy="id"
        labelBy="label"
        titleBy="title"
        (filter)="getOrgs($event)"
        (select)="handleOrgSelection($event)"
        [required]="required"
        [isDisabled]="isDisabled"
    ></sf-select>
</div>
