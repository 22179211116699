import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Organization } from "@sf/userorg/common";
import { OrganizationHierarchyHelperService } from "./organization-hierarchy-helper.service";
import { Observable } from "rxjs";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationHierarchyService {
    cachedTreeOrgID: string = null;
    cachedOrgTree: any = null;
    cachedUserInHierarchy: boolean = null;
    cachedOrgChildren: Organization[] = null;
    cachedOrgChildrenID: string = null;

    constructor(
        private _rpcClient: RpcClientService,
        private helperService: OrganizationHierarchyHelperService
    ) {}

    addOrganizationsToTree(orgIDs: string[], parentID: string): Observable<any> {
        return this.addOrganizations(orgIDs, parentID);
    }

    addOrganization(orgID: string, parentID: string) {
        this.cachedOrgTree = null;
        this.cachedTreeOrgID = null;
        return this._rpcClient.makeRequest("userorg", "addOrganization", {
            orgID: orgID,
            parentID: parentID
        });
    }

    addOrganizations(childOrgIDs: string[], parentOrgID: string): Observable<any> {
        this.cachedOrgTree = null;
        this.cachedTreeOrgID = null;
        return this._rpcClient.makeRequest("userorg", "addOrganizations", {
            orgIDs: childOrgIDs,
            parentID: parentOrgID
        });
    }

    // strategy can be "REMOVE_SUBTREE" or "PROMOTE_SUBTREE"
    removeOrganization(orgID: string, strategy: string) {
        this.cachedOrgTree = null;
        this.cachedTreeOrgID = null;
        return this._rpcClient.makeRequest("userorg", "removeOrganization", {
            orgID: orgID,
            strategy: strategy
        });
    }

    // list is a list of organization IDs
    removeOrganizations(list: string[]) {
        this.cachedOrgTree = null;
        this.cachedTreeOrgID = null;
        return this._rpcClient.makeRequest("userorg", "removeOrganizations", {
            list: list
        });
    }

    buildTrees(
        allOrganizationData: any,
        sortKey: string,
        sortAscending: boolean
    ): Organization[] {
        return this.helperService.buildTrees(
            allOrganizationData,
            sortKey,
            sortAscending
        );
    }

    clearCache() {
        this.cachedOrgTree = null;
        this.cachedTreeOrgID = null;
    }

    getChildren(orgID: string) {
        return this._rpcClient.makeRequest("userorg", "getChildren", {
            orgID: orgID
        });
    }

    getChildrenOfList(orgIDs: string[]) {
        return this._rpcClient.makeRequest("userorg", "getChildrenOfList", {
            orgIDs: orgIDs
        });
    }

    getChildrenOrganizations(orgID: string): Promise<Organization[]> {
        return new Promise<Organization[]>((resolve, reject) => {
            if (this.cachedOrgChildren && this.cachedOrgChildrenID == orgID) {
                // we already have data cached
                resolve(this.cachedOrgChildren); // return cached data
            } else {
                // make the back-end call
                let obs = this._rpcClient.makeRequest(
                    "userorg",
                    "getChildrenOrganizations",
                    { orgID: orgID }
                );
                obs.subscribe((results: Organization[]) => {
                    this.cachedOrgChildren = results;
                    this.cachedOrgChildrenID = orgID;
                    resolve(results);
                });
            }
        });
    }

    getOrganizationTree(orgID: string): Promise<any> {
        return new Promise<Organization[]>((resolve, reject) => {
            // caching disabled because you could disable the org in another page
            // and it would not clear the cache
            if (false && this.cachedOrgTree && this.cachedTreeOrgID == orgID) {
                // we already have data cached
                resolve(this.cachedOrgTree); // return cached data
            } else {
                // make the back-end call
                let obs = this._rpcClient.makeRequest(
                    "userorg",
                    "getOrganizationTree",
                    { orgID: orgID }
                );
                obs.subscribe((results: Organization[]) => {
                    this.cachedOrgTree = results;
                    this.cachedTreeOrgID = orgID;
                    resolve(results);
                });
            }
        });
    }

    getRootOrganizations() {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRootOrganizations",
            {}
        );
    }

    isInHierarchy(orgID: string) {
        return this._rpcClient.makeRequest("userorg", "isInHierarchy", {
            organizationID: orgID
        });
    }

    isCurrentUserInHierarchy(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (this.cachedUserInHierarchy != null) {
                // we already have data cached
                resolve(this.cachedUserInHierarchy); // return cached data
            } else {
                // make the back-end call
                this._rpcClient
                    .makeRequest("userorg", "isCurrentUserInHierarchy", {})
                    .subscribe((results: boolean) => {
                        this.cachedUserInHierarchy = results;
                        resolve(results);
                    });
            }
        });
    }

    searchRootOrganizations(
        queryString: string,
        limit: number,
        typeFilter: string[],
        serviceFilter: string[]
    ) {
        return this._rpcClient.makeRequest(
            "userorg",
            "searchRootOrganizations",
            {
                query: queryString,
                limit: limit,
                types: typeFilter,
                services: serviceFilter
            }
        );
    }
}
