<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div class="whole" *ngIf="loaded && licenseContents">
    <div class="doc-container" [innerHTML]="licenseContents">
    </div>
    <div class="license-footer" *ngIf="signContractInfo">
        <span *ngIf="!signContractInfo.contractSigned">
            <button class="btn btn-primary gap-right" (click)="checkAcceptance();">
                Accept Agreement
            </button>
            <button class="btn btn-link" (click)="sendToSigner();">
                Send to Someone Else
            </button>
            <button *ngIf="canSkip" class="btn btn-link" (click)="skipForNow()">
                Skip for Now
            </button>
        </span>
        <span *ngIf="signContractInfo.contractSigned">
            <span class="gap-right">
                This license has already been accepted
            </span>
            <button *ngIf="canSkip" class="btn btn-link" (click)="skipForNow()">
                Continue
            </button>
        </span>
        <button class="btn btn-link" (click)="checkPrintDocument()">
            Print Agreement
        </button>
    </div>
    <div class="license-footer" *ngIf="!signContractInfo">
        <button class="btn btn-primary gap-right"
            (click)="skipForNow();">Close
        </button>
    </div>
</div>
