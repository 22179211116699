import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    ContractService,
    TokenService,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import { InvitationSharedService } from "../../services/invitation-shared.service";
import {
    ConfirmationModalComponent,
    SessionService,
    SpinnerService
} from "@sf/common";
import { takeUntil } from "rxjs/operators";
import { CancelInvitationDialogComponent } from "../../dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";

interface WizardStep {
    title: string;
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-new-service",
    templateUrl: "./new-service.component.html",
    styleUrls: ["./new-service.component.scss"]
})
export class NewServiceComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy: Subject<void>;
    private _userDetails: any = {
        first: "",
        last: "",
        email: ""
    };

    /* Public Variables */
    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    currentStep = 0;
    canSkip = false;
    mustSignContract = false;
    userLoggedIn = false;
    contractID: string = null;
    formErrorMessages: string[] = [];
    serviceName = "";
    organizationID: string;
    organizationName = "";
    organizationFullName = "";
    organizationPhone = "";
    organizationStreet = "";
    organizationStreet2 = "";
    organizationCityStateZip = "";
    licenseFeeAmount = 0;

    wizardSteps: WizardStep[] = [
        {
            title: "New Service"
        },
        {
            title: "Simplifile License Agreement"
        },
        {
            title: "License Fee"
        },
        {
            title: "Enrollment Complete"
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private contractService: ContractService,
        private userorgService: UserOrgService,
        private invitationSharedService: InvitationSharedService,
        private spinnerService: SpinnerService,
        private sessionService: SessionService,
        private userorgActivationService: UserorgActivationService
    ) {}

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.preloadForms(this.tokenID);
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    private preloadForms(tokenID: string) {
        this.tokenService.getServiceInvitation(tokenID)
            .subscribe((invitation: any) => {
                if (invitation) {
                    this.loaded = true;

                    this._userDetails.first = invitation.firstName;
                    this._userDetails.last = invitation.lastName;
                    this._userDetails.email = invitation.email;

                    this.serviceName = invitation.serviceID;
                    this.organizationID = invitation.organizationID;
                    this.organizationName = invitation.orgName;
                    this.userLoggedIn = invitation.userLoggedIn;

                    if (this.sessionService.isSystemUser()) {
                        // use could be logged in just with the token
                        this.userLoggedIn = false;
                    }

                    if (this.serviceName == "Paper Recording") {
                        this.serviceName = "Recording PLUS";
                    }

                    if (invitation.licenseFee) {
                        this.licenseFeeAmount = parseFloat(invitation.licenseFee);
                    } else {
                        this.licenseFeeAmount = 0;
                    }

                    if (invitation.userLoggedIn) {
                        this.canSkip = true;
                    }

                    if (invitation.requiresContract) {
                        this.mustSignContract = true;
                    }

                    this.contractID = invitation.contractID;
                }
            },
            () => {
                // error
                this.loadFailed =
                    "Your invitation was not found. Please try clicking again on the link in the email message you received.";
                this.loaded = true;
            }
        );
    }

    private getOrganizationDetails(contractID: string) {
        this.contractService.getOrganizationForContract(contractID)
            .subscribe((organization: any) => {
                this.organizationName = organization.name;
                this.organizationFullName =
                    organization.name + " (" + organization.id + ")";
                this.organizationPhone = organization.phone;
                this.organizationStreet = organization.street;
                this.organizationStreet2 = organization.street2;
                this.organizationCityStateZip = organization.cityStateZip;
                if (organization.isSample) {
                    this.licenseFeeAmount = 0;
                }
                this.loaded = true;
            },
            () => {
                this.loaded = true;
            }
        );
    }

    private goToStep(step: number) {
        // the timeout seems to be required for Edge browser
        window.setTimeout(() => {
            this.currentStep = step;
        }, 10);
    }

    stepClick(step: number) {
        if (this.currentStep > 1) {
            // can't go back
            return;
        }

        if (step == this.currentStep) {
            // staying on same step
            return;
        }

        if (step > this.currentStep) {
            // going forward - need to submit form
            this.submitCurrentForm();
            return;
        }

        // going backward
        this.formErrorMessages = [];
        this.goToStep(step);
    }

    doCancel() {
        const modalRef = this.modalService.open(
            CancelInvitationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.tokenID = this.tokenID;
        modalRef.result.then(
            (result: any) => {
                if (result) {
                    this.invitationCanceled(result);
                }
            },
            () => {
                // nothing
            }
        );
    }

    invitationCanceled(action: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Invitation Canceled";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1,
            callback: this.goToHomeBase.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Thank you. Your invitation has been successfully canceled";
        if (action == "allInvites") {
            modalInstance.message +=
                ", and you will not receive future invitations from Simplifile. You may receive other types of emails from Simplifile.";
        } else {
            modalInstance.message += ".";
        }
    }

    skipForNow() {
        this.goToHomeBase();
    }

    goToHomeBase() {
        if (!this.userLoggedIn) {
            this.goToLogin();
        } else {
            this.goToHomePage();
        }
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    goToHomePage() {
        let homePage = this.sessionService.getHomePageUrl();
        if (homePage && !this.sessionService.isSystemUser()) {
            window.location = homePage;
        } else {
            this.goToLogin();
        }
    }

    // call this after accepting new service
    refreshSession() {
        // kind-of hack: need to update the session to get updated permissions
        this.spinnerService.startSpinner();
        this.userorgActivationService.updateUserSession()
            .subscribe((session: any) => {
                if (session && session.session) {
                    this.sessionService.setSession(session.session);
                }
                this.spinnerService.stopSpinner();
            },
            () => {
                this.spinnerService.stopSpinner();
            }
        );
    }

    submitCurrentForm() {
        switch (this.currentStep) {
            case 0:
                this.acceptInvitation();
                break;
            case 1:
                // stay here - must accept the contract
                //this.prepareForPayment();
                break;
            default:
                break;
        }
    }

    acceptInvitation() {
        if (this.mustSignContract) {
            this.loaded = false;
            this.tokenService.getServiceInvitationContract(this.tokenID)
                .subscribe((contractID: string) => {
                    if (contractID) {
                        this.loaded = true;
                        this.contractID = contractID;
                        this.goToStep(1); // sign license
                    } else {
                        this.loadFailed =
                                "A license agreement for your invitation was not found. Please try clicking again on the link in the email message you received.";
                        this.loaded = true;
                    }
                    this.getOrganizationDetails(this.contractID);
                }, () => {
                    // error
                    this.loadFailed =
                            "A license agreement for your invitation was not found. Please try clicking again on the link in the email message you received.";
                    this.loaded = true;
                });
        } else {
            this.loaded = false;
            this.tokenService.acceptServiceInvitationNoContract(this.tokenID)
                .subscribe(() => {
                    this.loaded = true;
                    if (this.userLoggedIn) {
                        this.refreshSession();
                    }
                    this.goToStep(3); // done
                    this.doneNoLicense();
                }, () => {
                    // error
                    this.loaded = true;
                });
        }
    }

    prepareForPayment() {
        if (!this.licenseFeeAmount) {
            this.paymentAction("nofee");
        } else {
            this.goToStep(2); // make payment
        }
    }

    licenseAction(event: string) {
        if (event == "accept") {
            this.prepareForPayment();
        } else if (event == "skip") {
            this.goToHomeBase();
        }
    }

    paymentAction(event: string) {
        if (this.userLoggedIn) {
            this.refreshSession();
            this.goToHomePage();
        } else {
            this.goToLogin();
        }
    }

    doneNoLicense() {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Enrollment Successful";

        if (this.userLoggedIn) {
            modalInstance.primary = {
                text: "OK",
                responseValue: 1,
                callback: this.goToHomePage.bind(this)
            };
        } else {
            modalInstance.primary = {
                text: "Log In",
                responseValue: 1,
                callback: this.goToHomeBase.bind(this)
            };
        }
        modalInstance.hideSecondary = true;
        modalInstance.message = "Your new service is now ready to use.";
    }
}
