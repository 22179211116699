import { Injectable, SecurityContext } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { SubscriptionBaseService } from "@sf/common";
import { SocketService } from "@sf/common";
import { distinct, finalize, startWith, switchMap } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { PackageHistoryEvent } from "../interfaces/package-history-event";
import { PackageHistoryPackage } from "../interfaces/package-history-package";

interface PackageUpdate {
    data: {
        id: string;
    };
}

@Injectable({
    providedIn: "root"
})
export class PackageHistoryService extends SubscriptionBaseService {
    protected namespace = "packages";
    protected product = "erecord";

    private _subscriptions: {
        [packageID: string]: Observable<PackageHistoryPackage>;
    } = {};

    constructor(
        private _rpcClient: RpcClientService,
        private _socketService: SocketService,
        private _sanitizer: DomSanitizer
    ) {
        super(_socketService);
    }

    getPackageUpdates$(packageID: string): Observable<PackageHistoryPackage> {
        const sanitizedID = this._sanitizer.sanitize(
            SecurityContext.URL,
            packageID
        );
        if (this._subscriptions.hasOwnProperty(sanitizedID)) {
            return this._subscriptions[sanitizedID];
        }
        this._subscriptions[sanitizedID] = this.on<PackageUpdate>(
            "update",
            sanitizedID
        ).pipe(
            finalize(() => {
                delete this._subscriptions[sanitizedID];
            }),
            distinct(),
            startWith({ data: { id: sanitizedID } }),
            switchMap((pkgUpdate) => {
                return this._getPackage(pkgUpdate.data.id);
            })
        );
        return this._subscriptions[sanitizedID];
    }

    getPackageHistory(
        packageID: string,
        includeDocumentHistory: boolean = false
    ): Observable<PackageHistoryEvent[]> {
        return this._rpcClient.makeRequest(this.product, "getPackageHistory", {
            packageID,
            includeDocumentHistory
        });
    }

    getDocumentHistory(documentID: string): Observable<PackageHistoryEvent[]> {
        return this._rpcClient.makeRequest(this.product, "getDocumentHistory", {
            documentID
        });
    }

    downloadPackageHistory(
        packageID: string,
        includeDocumentHistory: boolean = false
    ) {
        return this._rpcClient.makeRequest(
            this.product,
            "downloadPackageHistory",
            {
                packageID,
                includeDocumentHistory
            }
        );
    }

    downloadDocumentHistory(documentID: string) {
        return this._rpcClient.makeRequest(
            this.product,
            "downloadDocumentHistory",
            {
                documentID
            }
        );
    }

    private _getPackage(packageID: string): Observable<PackageHistoryPackage> {
        return this._rpcClient.makeRequest(this.product, "getPackage", {
            id: packageID
        });
    }

    getTemplateDataHistory(
        documentId: string,
        version: number
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getTemplateDataHistory", {
            documentId,
            version
        });
    }
}
