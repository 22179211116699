import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    BaseModalComponent,
    GrowlService,
    SessionService,
    UserSettingsService
} from "@sf/common";
import { Subject } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first, takeUntil } from "rxjs/operators";
import { OrganizationService } from "@sf/userorg/common";
import { UiRecipient } from "../../../../interfaces/ui-recipient";
import { SubmitterCountiesDataService } from "../../../../services/submitter-counties-data.service";
import { SubmitterQuickRegisterDialogComponent } from "../../../../dialogs/submitter-quick-register-dialog/submitter-quick-register-dialog.component";

@Component({
    selector: "sf-registration-or-paper-dialog",
    templateUrl: "./registration-or-paper-dialog.component.html",
    styleUrls: ["./registration-or-paper-dialog.component.scss"]
})
export class RegistrationOrPaperDialogComponent
    extends BaseModalComponent
    implements OnInit, OnDestroy
{
    private _result$ = new Subject<"create-package" | "registration-pending">();
    private _destroy$ = new Subject<void>();

    result$ = this._result$.asObservable();
    hasCountyPermission: boolean;
    hidePaperPackageWarning: boolean;
    adminUsers: any[];
    selectedAdminUsers: Record<string, boolean>;

    @Input()
    recipient: UiRecipient;
    @Input()
    submitterID: string;

    constructor(
        private _userSettingsService: UserSettingsService,
        private _activeModal: NgbActiveModal,
        private _submitterCountiesDataService: SubmitterCountiesDataService,
        private _ngbModal: NgbModal,
        private _growlService: GrowlService,
        private _sessionService: SessionService,
        private _organizationService: OrganizationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.hasCountyPermission = this._sessionService.hasPermission(
            "submitter_counties",
            this.submitterID
        );
        let recipientIDs = this._userSettingsService
            .getUserSetting("HIDE_PAPER_PACKAGE_WARNING_FOR_RECIPIENTS")
            .split(",");
        this.hidePaperPackageWarning = recipientIDs.includes(this.recipient.id);

        if (this.hidePaperPackageWarning) {
            this._result$.next("create-package");
            this._activeModal.close();
            return;
        }

        if (this.hasCountyPermission) {
            this.primary = {
                text: "Register",
                callback: () => {
                    this.showCountyRegistrationModal(
                        this.submitterID,
                        this.recipient.id
                    );
                }
            };
            this.secondary = {
                text: "Create Paper Package",
                callback: () => {
                    if (this.hidePaperPackageWarning) {
                        recipientIDs.push(this.recipient.id);
                        this._userSettingsService.setUserSetting(
                            "HIDE_PAPER_PACKAGE_WARNING_FOR_RECIPIENTS",
                            recipientIDs.join(",")
                        );
                    }
                    this._result$.next("create-package");
                    return true;
                }
            };
        } else {
            this._organizationService
                .getUsersByOrganization(this.submitterID)
                .subscribe((users) => {
                    this.adminUsers = users
                        .filter((user) => user.isAdmin)
                        .map((user) => {
                            return {
                                id: user.id,
                                name: `${user.name.first} ${user.name.last}`
                            };
                        });
                    this.selectedAdminUsers = {};
                    this.primary = {
                        text: "Create Paper Package",
                        callback: () => {
                            if (this.hidePaperPackageWarning) {
                                recipientIDs.push(this.recipient.id);
                                this._userSettingsService.setUserSetting(
                                    "HIDE_PAPER_PACKAGE_WARNING_FOR_RECIPIENTS",
                                    recipientIDs.join(",")
                                );
                            }
                            this.sendCountyRegistrationRequest(
                                this.submitterID,
                                this.recipient.id
                            );
                            this._result$.next("create-package");
                            return true;
                        }
                    };
                    this.secondary = {
                        text: "Wait",
                        callback: () => {
                            this.sendCountyRegistrationRequest(
                                this.submitterID,
                                this.recipient.id
                            );
                            return true;
                        }
                    };
                });
        }
    }

    ngOnDestroy() {
        this._result$.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }

    showCountyRegistrationModal(submitterID: string, recipientID: string) {
        this._submitterCountiesDataService.initializeForSingleUnassociatedCounty(
            submitterID,
            recipientID
        );
        let modalComponent: SubmitterQuickRegisterDialogComponent =
            this._ngbModal.open(SubmitterQuickRegisterDialogComponent, {
                size: "lg"
            }).componentInstance;
        modalComponent.orgID = submitterID;
        modalComponent.recipientID = recipientID;
        modalComponent.countiesAdded$
            .pipe(takeUntil(this._destroy$), first())
            .subscribe((counties) => {
                let county = counties.find((c) => c.id === recipientID);
                if (county?.isRegistrationPending === "true") {
                    this._growlService.success(
                        `Registration with ${county.name} is pending approval`
                    );

                    this._result$.next("registration-pending");
                    this._activeModal.close();
                } else {
                    this._result$.next("create-package");
                    this._activeModal.close();
                }
            });
    }

    sendCountyRegistrationRequest(submitterID: string, recipientID: string) {
        let selectedUserIDs = Object.keys(this.selectedAdminUsers).filter(
            (userID) => this.selectedAdminUsers[userID]
        );
        if (!!selectedUserIDs.length) {
            this._organizationService
                .sendCountyRegistrationRequest(
                    submitterID,
                    recipientID,
                    selectedUserIDs
                )
                .subscribe(() => {
                    this._growlService.success(
                        "Registration notification sent successfully"
                    );
                });
        }
    }
}
