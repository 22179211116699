<div>
    <sf-select
        id="sf-user-search-selector"
        name="sf-user-search-selector"
        [class]="windowClass"
        [ngClass]="{'no-style-select-box': hideBorder}"
        selectorName="sf-user-search-selector"
        [placeholder]="placeholder"
        searchPlaceholder="Search"
        [hasFilter]="true"
        [filterIcon]="['far', 'search']"
        [filterDebounce]="300"
        [handleFilterExternally]="true"
        [isSingleSelect]="true"
        [selectedOption]="selectedUserID"
        [options]="selectableItems"
        [hasClearButton]="canClearSelectedUser"
        trackBy="id"
        labelBy="label"
        (filter)="getUsers($event)"
        (select)="handleUserSelection($event)"
        [required]="required"
    ></sf-select>
</div>
