import { Component, OnInit, OnDestroy } from "@angular/core";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { CustomFormService } from "../../custom-form.service";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";

@Component({
    selector: "sf-custom-numeric-field",
    templateUrl: "./custom-numeric-field.component.html",
    styleUrls: ["./custom-numeric-field.component.scss"]
})
export class CustomNumericFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }
}
