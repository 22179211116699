<sf-tabbed-modal-header [title]="modalTitle" [currentStep]="stepIndex" [stepCount]="numSteps" [hideSteps]="!addNewCC"></sf-tabbed-modal-header>
<div class="modal-body sf-unmask">
    <span *ngIf="minimumCharge">You are changing this payment to a card payment. Once confirmed, your card payment will process at the end of day.
        Please confirm the details below, including the added surcharge, if applicable.
    </span>
    <span *ngIf="!minimumCharge"><b>Minimum charge amount not met!</b> In order to change payment to a credit card, the charged amount must be greater than $0.50.</span>
    <br>
    <br>
    <table class="surcharge-table">
        <thead class="header">
        <tr>
            <th>Credit Card Account</th>
            <th>Charged Amount</th>
            <th>Surcharge ({{surchargePercent}}%)</th>
            <th>Total Card Amount</th>
        </tr>
        </thead>
        <tr class="surcharge-row">
            <td class="sf-mask">
                {{ccAccountLabel}}
            </td>
            <td>
                <div class="adjustable-amount" >{{chargedAmount | currency}}</div>
            </td>
            <td>
                <div class="adjustable-amount">{{surchargeAmount | currency}}</div>
            </td>
            <td>
                <div class="adjustable-amount">{{totalAmount | currency}}</div>
            </td>
        </tr>
    </table>
    <br>
    <span>
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
        The surcharge amount only applies to credit cards, not debit cards, and is calculated per document.
        The surcharge amount does not exceed Simplifile's cost of acceptance.
    </span>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
