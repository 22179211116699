import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
    CopyTextService,
    getAllRouteData,
    getLinkWithChangedParams,
    getParamsFromRoute,
    scheduleMicroTask,
    SelectableItem
} from "@sf/common";
import { PageHeaderComponent } from "@sf/headers";
import { Subject } from "rxjs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import {
    CurrentPermissionService,
    RoleService,
    UserPermission
} from "@sf/userorg/common";
import { takeUntil } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-admin-permission-header-with-selector",
    templateUrl: "./admin-permission-header-with-selector.component.html",
    styleUrls: ["./admin-permission-header-with-selector.component.scss"]
})
export class AdminPermissionHeaderWithSelectorComponent implements OnInit, OnDestroy, PageHeaderComponent {
    pageData: any;
    _onDestroy$: Subject<void> = new Subject();

    /* Public Variables */
    selectedPermission: Partial<UserPermission> = {
        permissionID: null
    };
    public selectedPermissionID: string = null;

    /** Private Variables */
    showSaveChanges = false;
    updatingCurrentPermission = false;

    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy Permission ID"
        },
        {
            option: "copyName",
            label: "Copy Permission Label"
        }
    ];

    constructor(
            private currentPermissionService: CurrentPermissionService,
            private _route: ActivatedRoute,
            private router: Router,
            private roleService: RoleService,
            private copyService: CopyTextService
    ) {
    }

    ngOnInit() {
        // look for route data that could influence selection -----
        let routeData = getAllRouteData(this._route);

        // get the selected permission
        this.pickInitialPermission();

        // update the display if the permission name changes (by somebody editing it)
        this.currentPermissionService.currentPermission$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((permission: UserPermission) => {
                if (permission) {
                    this.selectedPermission = permission;
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    pickInitialPermission() {
        let initialPermission: UserPermission = null;
        let currentPermission: UserPermission =
                this.currentPermissionService.getCurrentPermission();

        // look for permissionID in URL
        let params = getParamsFromRoute(this._route, ["permissionID"]);
        if (params.permissionID && params.permissionID !== "_") {
            // a permissionID was specified in the URL, so it takes priority
            if (currentPermission && params.permissionID == currentPermission.permissionID) {
                initialPermission = currentPermission;
            } else {
                // temporarily use a partial Permission object
                initialPermission = <UserPermission>{
                    permissionID: params.permissionID
                };
            }
            this.selectedPermission = initialPermission;
            this.selectedPermissionID = params.permissionID;
            return; // done
        }

        // try the 'current' rold
        initialPermission = currentPermission;
        if (initialPermission) {
            this.selectedPermission = initialPermission;
            return; //done
        }
    }

    navigateToPermissionID(permissionID: string) {
        let extras: NavigationExtras = {
            relativeTo: this._route,
            queryParamsHandling: "preserve"
        };
        let link = getLinkWithChangedParams(
                this._route,
                {
                    permissionID: permissionID
                },
                true
        );
        if (permissionID && !link.includes(permissionID)) {
            link = link.map((part) =>
                    part.includes("./permission") ? part + "/" + permissionID : part
            );
        }
        scheduleMicroTask(() => {
            this.router.navigate(link, extras);
        });
    }

    // called from HTML
    setSelectedPermission(selectedPermission: UserPermission) {
        if (this.updatingCurrentPermission) {
            // ignore this call if we are looking up a permission to make it current
            return;
        }
        this.currentPermissionService.updateCurrentPermission(selectedPermission);
        if (selectedPermission) {
            this.selectedPermission = selectedPermission;
            this.navigateToPermissionID(selectedPermission.permissionID);
        } else {
            this.selectedPermission = {
                permissionID: null
            };
        }
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                    event.clientX +
                    document.body.scrollLeft +
                    document.documentElement.scrollLeft;
            this.contextMenuY =
                    event.clientY +
                    document.body.scrollTop +
                    document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedPermission) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(this.selectedPermission.label);
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedPermission.permissionID);
                    break;
            }
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }
}
