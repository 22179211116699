import { Injectable } from "@angular/core";
import { EsignUserService } from "./esign-user.service";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType
} from "@sf/userorg/common";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseModalComponent, SessionService } from "@sf/common";
import { SigningEventSubscriptionService } from "./signing-event-subscription.service";

@Injectable({
    providedIn: "root"
})
export class SigningEventService {
    private _isDocBuilderSigner: boolean;
    private _isDocBuilderNotary: boolean;

    constructor(
        private _esignUserService: EsignUserService,
        private _bannerNotificationService: BannerNotificationService,
        private _router: Router,
        private _ngbModal: NgbModal,
        private _signingEventSubscriptionService: SigningEventSubscriptionService,
        private sessionService: SessionService
    ) {
        this._isDocBuilderSigner = this.sessionService.hasAnyPermissionInAnyOrg(
            [
                "submitter_signing_package_signer",
                "submitter_signing_package_witness"
            ]
        );
        this._isDocBuilderNotary = this.sessionService.hasAnyPermissionInAnyOrg(
            ["submitter_signing_package_notary"]
        );
        if (this._isDocBuilderSigner || this._isDocBuilderNotary) {
            this._signingEventSubscriptionService
                .getSigningEventCreatedUpdate$(this.sessionService.getUserID())
                .subscribe((update) => {
                    this.showSigningEventModal(
                        update.data.eventID,
                        update.data.creatingUserName,
                        update.data.notarizationType
                    );
                });
            this._signingEventSubscriptionService
                .getSigningEventCanceledUpdate$(this.sessionService.getUserID())
                .subscribe((update) => {
                    this.checkForActiveSigningEvents();
                });
        }
    }

    checkForActiveSigningEvents() {
        if (this._isDocBuilderSigner || this._isDocBuilderNotary) {
            this._esignUserService.getActiveSigningEvents().subscribe(
                (
                    events: Array<{
                        eSignEventID: string;
                        notarizationType: string;
                    }>
                ) => {
                    if (events.length) {
                        this.addSigningEventBanner(
                            events[0].eSignEventID,
                            events[0].notarizationType
                        );
                    } else {
                        this._bannerNotificationService.removeBannerOfCategory(
                            BannerNotificationCategory.SIGNING_EVENT_CREATED
                        );
                    }
                }
            );
        }
    }

    addSigningEventBanner(eventId: string, notarizationType: string) {
        this._bannerNotificationService.removeBannerOfCategory(
            BannerNotificationCategory.SIGNING_EVENT_CREATED
        );
        this._bannerNotificationService.push({
            type: BannerNotificationType.INFO,
            category: BannerNotificationCategory.SIGNING_EVENT_CREATED,
            message: `Click here to join your ${notarizationType} signing event.`,
            destinationUrl: `sign-event/event/${eventId}/closing/clear-video`,
            clickHandler: () => {
                this._navigateToEvent(eventId);
            },
            closeHandler: () => {},
            disableTooltip: true
        });
    }

    showSigningEventModal(
        eventId: string,
        creatingUserName: string,
        notarizationType: string
    ) {
        Object.assign(
            this._ngbModal.open(BaseModalComponent).componentInstance,
            {
                title: "New Signing Event",
                message: `${creatingUserName} has started ${
                    notarizationType === "RON" ? "a RON" : "an IPEN"
                } signing event. You are included in the event.`,
                showDismiss: false,
                primary: {
                    text: "Join Event",
                    callback: () => {
                        this._navigateToEvent(eventId);
                        return true;
                    }
                },
                secondary: {
                    text: "Give Me a Minute",
                    callback: () => {
                        this.addSigningEventBanner(eventId, notarizationType);
                        return true;
                    }
                }
            }
        );
    }

    private _navigateToEvent(eventId: string) {
        this._bannerNotificationService.removeBannerOfCategory(
            BannerNotificationCategory.SIGNING_EVENT_CREATED
        );
        this._router.navigateByUrl(
            `sign-event/event/${eventId}/closing/clear-video`
        );
    }
}
