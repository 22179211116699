import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { map } from "rxjs/operators";
import { SubmitterConfigPreparation } from "@sf/userorg/common";
import { ReportsColumns } from "../interfaces/banking-export-column";
import { SubmitterOrganizationConfigPayments } from "../interfaces/submitter-organization-config-payments.interface";
import {
    SubmitterOrganizationConfigNotifications,
    SubmitterOrganizationConfigNotificationsValuesOnly
} from "../interfaces/submitter-organization-config-notifications.interface";
import { DownloadOptions } from "../interfaces/download-options.interface";

@Injectable({
    providedIn: "root"
})
export class SubmitterOrganizationService {
    private USERORG = "userorg";
    submitterOrganizationService: any;

    constructor(private _rpcClient: RpcClientService) {}

    getSubmitterOrganizationSettings(submitterId: string): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganization",
            { submitterId }
        );
    }

    getLiveConfigSettingByOrg(
        sectionId: string,
        optionId: string,
        submitterId: string
    ) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getLiveConfigSettingByOrg",
            {
                sectionID: sectionId,
                optionID: optionId,
                organizationID: submitterId
            }
        );
    }

    getSubmitterOrganizationsSettings(submitterIds: Array<string>) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizations",
            { submitterIds }
        );
    }

    getPrintDownloadLimit() {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getPrintDownloadLimit",
            {}
        );
    }

    getSubmitterOrganizationConfigPreparation(
        submitterId: string,
        retrieveAllSettings?: boolean,
        valuesOnly?: boolean
    ): Observable<SubmitterConfigPreparation> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationConfigPreparation",
            {
                submitterId: submitterId,
                retrieveAllSettings: retrieveAllSettings,
                valuesOnly: valuesOnly
            }
        );
    }

    getSubmitterOrganizationConfigPreparationWithAllValues(
        submitterId: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationConfigPreparationWithAllValues",
            {
                submitterId: submitterId
            }
        );
    }

    getSubmitterOrganizationsConfigPreparation(
        submitterIds: Array<string>,
        retrieveAllSettings?: boolean,
        justValues?: boolean
    ) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationsConfigPreparation",
            {
                submitterIds: submitterIds,
                retrieveAllSettings: retrieveAllSettings,
                justValues: justValues
            }
        );
    }

    getSubmitterOrganizationConfigReports(
        submitterId: string,
        retrieveAllSettings: boolean,
        valuesOnly: boolean
    ): Observable<ReportsColumns> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationConfigReports",
            {
                submitterId: submitterId,
                retrieveAllSettings: retrieveAllSettings,
                valuesOnly: valuesOnly
            }
        );
    }

    getSubmitterOrganizationConfigPayments(
        submitterId: string,
        valuesOnly?: boolean,
        retrieveAllSettings?: boolean
    ): Observable<SubmitterOrganizationConfigPayments> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationConfigPayments",
            {
                submitterId: submitterId,
                valuesOnly: valuesOnly,
                retrieveAllSettings: retrieveAllSettings
            }
        );
    }

    /**
     *
     * @param submitterIds array of orgIDs
     * @param retrieveAllSettings boolean
     * @param justValues boolean
     * @returns {*}
     */
    getSubmitterOrganizationsConfigPayments(
        submitterIds: string,
        retrieveAllSettings: boolean,
        justValues: boolean
    ) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterOrganizationsConfigPayments",
            {
                submitterIds: submitterIds,
                retrieveAllSettings: retrieveAllSettings,
                justValues: justValues
            }
        );
    }

    getSubmitterOrganizationConfigNotifications(
        submitterId: string,
        retrieveAllSettings: boolean,
        valuesOnly: boolean
    ): Observable<
        | SubmitterOrganizationConfigNotifications
        | SubmitterOrganizationConfigNotificationsValuesOnly
    > {
        return this._rpcClient
            .makeRequest(
                this.USERORG,
                "getSubmitterOrganizationConfigNotifications",
                {
                    submitterId: submitterId,
                    retrieveAllSettings: retrieveAllSettings,
                    valuesOnly: valuesOnly
                }
            )
            .pipe(map((response: any) => response.Notification));
    }

    setSubmitterOrganizationPaymentOptionsSettings(
        submitterId: string,
        nonConfig: boolean
    ) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "setSubmitterOrganizationPaymentOptionsSettings",
            {
                submitterId: submitterId,
                nonConfig: nonConfig
            }
        );
    }

    setSubmitterOrganizationConfig(
        submitterId: string,
        settings: any,
        updateChildren?: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "setSubmitterOrganizationConfig",
            {
                submitterId: submitterId,
                settings: settings,
                updateChildren: updateChildren
            }
        );
    }

    setSubmitterOrganizationConfigAndCustomLog(
        submitterId: string,
        settings: { [key: string]: any },
        appendedComment: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "setSubmitterOrganizationConfig",
            {
                submitterId,
                settings,
                appendedComment
            }
        );
    }

    createSubmitterOrganizationBasedOn(
        baseOrganizationID: string,
        basicInfo: any
    ) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "createNewSubmitterOrganizationBasedOn",
            {
                baseOrganizationID: baseOrganizationID,
                basicInfo: basicInfo
            }
        );
    }

    getSubmitterApiSystemsUsed(orgID: string) {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterApiSystemsUsed",
            { orgID }
        );
    }

    getLockBeneficiaryAssignor(submitterId: string): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getLockBeneficiaryAssignor",
            { submitterId }
        );
    }

    setCommonPartySetting(
        submitterId: string,
        option: string,
        value: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "setCommonPartySetting",
            { submitterId, option, value }
        );
    }

    submitterLiveRecipientExport(
        downloadOptions?: DownloadOptions
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "submitterLiveRecipientExport",
            downloadOptions
        );
    }

    adminLiveRecipientExport(downloadOptions?: DownloadOptions) {
        let data = {
            format: downloadOptions.format,
            includeSecondaryRecipients:
                downloadOptions.includeSecondaryRecipients
        } as any;
        if (downloadOptions.orgID) {
            data.orgID = downloadOptions.orgID;
        }
        if (downloadOptions.recipientFilter) {
            data.recipientFilter = downloadOptions.recipientFilter;
        }
        return this._rpcClient.makeRequest(
            "admin",
            "liveRecipientExport",
            data
        );
    }

    getTrusteeConfiguration(organizationID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getTrusteeConfiguration",
            { organizationID }
        );
    }

    getTrusteesConfiguration(organizationIDs: string[]): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getTrusteesConfiguration",
            { organizationIDs }
        );
    }

    saveTrusteeConfiguration(
        organizationID: string,
        settings: any
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "saveTrusteeConfiguration",
            { organizationID, settings }
        );
    }

    resetDocumentBuilderGuideForUsers(userIDs: string[]): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "resetDocumentBuilderGuideForUsers",
            {
                userIDs
            }
        );
    }

    resetDocumentBuilderSignerGuideForUsers(
        userIDs: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "resetDocumentBuilderSignerGuideForUsers",
            {
                userIDs
            }
        );
    }

    resetDocumentBuilderNotaryGuideForUsers(
        userIDs: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "resetDocumentBuilderNotaryGuideForUsers",
            {
                userIDs
            }
        );
    }

    resetDocumentBuilderDataEntryGuideForUsers(
        userIDs: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "resetDocumentBuilderDataEntryGuideForUsers",
            {
                userIDs
            }
        );
    }

    organizationHasProduct(
        organizationID: string,
        product: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "organizationHasProduct",
            {
                organizationID,
                product
            }
        );
    }
}
