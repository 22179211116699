import { FieldType } from "../enums/field-type.enum";
import { Type } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { DynamicFormControl } from "../controls";
import { DynamicFormGroup } from "../controls";
import { DynamicFormArray } from "../controls";
import { ArrayFieldComponent } from "../fields/array-field/array-field.component";
import { GroupFieldComponent } from "../fields/group-field/group-field.component";
import { TextFieldComponent } from "../fields/text-field/text-field.component";
import { InputComponent } from "../components";
import { CheckboxFieldComponent } from "../fields/checkbox-field/checkbox-field.component";
import { CurrencyFieldComponent } from "../fields/currency-field/currency-field.component";
import { DateFieldComponent } from "../fields/date-field/date-field.component";
import { DateRangeFieldComponent } from "../fields/date-range-field/date-range-field.component";
import { NumericFieldComponent } from "../fields/numeric-field/numeric-field.component";
import { SelectFieldComponent } from "../fields/select-field/select-field.component";
import { RadioFieldComponent } from "../fields/radio-field/radio-field.component";
import { ArrayField, DynamicField, GroupField } from "../interfaces";
import { HiddenFieldComponent } from "../fields/hidden-field/hidden-field.component";
import { AbstractDynamicFormControl } from "../controls";
import { FieldState } from "../interfaces/dynamic-form-state";
import { RootFieldComponent } from "../fields/root-field/root-field.component";
import { TimeFieldComponent } from "../fields/time-field/time-field.component";
import { SortableFieldComponent } from "../fields/sortable-field/sortable-field.component";
import { ParagraphFieldComponent } from "../fields/paragraph-field/paragraph-field.component";
import { UploadFieldComponent } from "../fields/upload-field/upload-field.component";

const fieldTypeToControl: { [type in FieldType]: Type<AbstractControl> } = {
    [FieldType.CHECKBOX]: DynamicFormControl,
    [FieldType.CURRENCY]: DynamicFormControl,
    [FieldType.DATE]: DynamicFormControl,
    [FieldType.DATERANGE]: DynamicFormControl,
    [FieldType.DATERANGE_SIMPLE]: DynamicFormControl,
    [FieldType.NUMERIC]: DynamicFormControl,
    [FieldType.SELECT]: DynamicFormControl,
    [FieldType.TEXTFIELD]: DynamicFormControl,
    [FieldType.TEXTAREA]: DynamicFormControl,
    [FieldType.ZIP]: DynamicFormControl,
    [FieldType.TIME]: DynamicFormControl,
    [FieldType.TIMESTAMP]: DynamicFormControl,
    [FieldType.HIDDEN]: DynamicFormControl,
    [FieldType.STATE]: DynamicFormControl,
    [FieldType.PARTYPARSER]: DynamicFormControl,
    [FieldType.NAMEUNPARSED]: DynamicFormControl,
    [FieldType.PARTYTYPE]: DynamicFormControl,
    [FieldType.MULTISELECT]: DynamicFormControl,
    [FieldType.MULTITITLE]: DynamicFormControl,
    [FieldType.RADIO]: DynamicFormControl,
    [FieldType.GROUP]: DynamicFormGroup,
    [FieldType.ARRAY]: DynamicFormArray,
    [FieldType.ROOT]: DynamicFormGroup,
    [FieldType.SORTABLE]: DynamicFormControl,
    [FieldType.PARAGRAPH]: DynamicFormControl,
    [FieldType.UPLOAD]: DynamicFormControl,
    [FieldType.PHONE_NUMBER]: DynamicFormControl
};

const fieldTypeToComponent: { [type in FieldType]: Type<InputComponent> } = {
    [FieldType.CHECKBOX]: CheckboxFieldComponent,
    [FieldType.CURRENCY]: CurrencyFieldComponent,
    [FieldType.DATE]: DateFieldComponent,
    [FieldType.DATERANGE]: DateRangeFieldComponent,
    [FieldType.DATERANGE_SIMPLE]: DateRangeFieldComponent,
    [FieldType.NUMERIC]: NumericFieldComponent,
    [FieldType.RADIO]: RadioFieldComponent,
    [FieldType.TEXTFIELD]: TextFieldComponent,
    [FieldType.TEXTAREA]: TextFieldComponent,
    [FieldType.ZIP]: TextFieldComponent,
    [FieldType.TIME]: TimeFieldComponent,
    [FieldType.TIMESTAMP]: TextFieldComponent,
    [FieldType.HIDDEN]: HiddenFieldComponent,
    [FieldType.STATE]: TextFieldComponent,
    [FieldType.PARTYPARSER]: TextFieldComponent,
    [FieldType.NAMEUNPARSED]: TextFieldComponent,
    [FieldType.PARTYTYPE]: RadioFieldComponent,
    [FieldType.SELECT]: SelectFieldComponent,
    [FieldType.MULTISELECT]: SelectFieldComponent,
    [FieldType.MULTITITLE]: SelectFieldComponent,
    [FieldType.GROUP]: GroupFieldComponent,
    [FieldType.ARRAY]: ArrayFieldComponent,
    [FieldType.ROOT]: RootFieldComponent,
    [FieldType.SORTABLE]: SortableFieldComponent,
    [FieldType.PARAGRAPH]: ParagraphFieldComponent,
    [FieldType.UPLOAD]: UploadFieldComponent,
    [FieldType.PHONE_NUMBER]: TextFieldComponent
};

export function getComponentFromField(field: FieldState): Type<InputComponent> {
    if (field.component) {
        return field.component;
    }

    return fieldTypeToComponent[field.type];
}

export function getDefaultControlFromType(
    type: FieldType
): Type<AbstractControl> {
    return fieldTypeToControl[type];
}

export function getDefaultComponentFromType(
    type: FieldType
): Type<InputComponent> {
    return fieldTypeToComponent[type];
}

export function buildForm(fields: DynamicField[]): DynamicFormGroup {
    const baseFormGroup = new DynamicFormGroup({});
    baseFormGroup.setDynamicField({ fields } as GroupField);

    for (let childField of fields) {
        baseFormGroup.addControl(childField.path, buildFormControl(childField));
    }

    return baseFormGroup;
}

export function buildFormControl(field: DynamicField): AbstractControl {
    let control: AbstractControl = null;

    if (field.type === FieldType.GROUP) {
        control = buildGroupControl(field as GroupField);
    } else if (field.type === FieldType.ARRAY) {
        control = buildArrayControl(field as ArrayField);
    } else {
        const controlType = getDefaultControlFromType(field.type);
        control = new controlType(field.value || field.defaultValue);
    }

    (control as unknown as AbstractDynamicFormControl).setIsVisible(
        field.visibility.visible
    );

    (control as unknown as AbstractDynamicFormControl).setDynamicField(field);

    return control;
}

export function buildGroupControl(field: GroupField): DynamicFormGroup {
    const formGroup = new DynamicFormGroup({});
    formGroup.setExpandability(field);

    for (let childField of field.fields) {
        formGroup.addControl(childField.path, buildFormControl(childField));
    }

    return formGroup;
}

export function buildArrayControl(field: ArrayField): DynamicFormArray {
    const formArray = new DynamicFormArray([]);
    formArray.setExpandability(field);
    formArray.setRepeatableFields(field.repeatableFields);
    formArray.setMaxLength(field.maxLength);

    for (let childField of field.fields) {
        formArray.push(buildFormControl(childField));
    }

    return formArray;
}

export function setValueForDynamicFormGroup(
    formGroup: DynamicFormGroup,
    value: any,
    options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }
): void {
    try {
        formGroup.patchValue(value, options);
    } catch (error) {
        if (
            error.message.includes(
                "There are no form controls registered with this array yet"
            ) ||
            error.message.includes("Cannot find form control at index")
        ) {
            parseFormGroupForMissingArrayControls(formGroup, value);
            setValueForDynamicFormGroup(formGroup, value, options);
            return;
        } else if (
            error.message.includes(
                "Must supply a value for form control at index"
            ) ||
            error.message.includes("value.forEach is not a function")
        ) {
            parseFormGroupForMissingArrayControls(formGroup, value, false);
            setValueForDynamicFormGroup(formGroup, value, options);
            return;
        }

        throw error;
    }
}

export function parseFormGroupForMissingArrayControls(
    formGroup: DynamicFormGroup,
    value: any,
    add = true
) {
    let keys = Object.keys(formGroup.controls);

    for (let key of keys) {
        if (formGroup.controls.hasOwnProperty(key)) {
            let control = formGroup.controls[key];
            let controlValue = value[key];
            if (control instanceof DynamicFormGroup) {
                parseFormGroupForMissingArrayControls(control, controlValue);
            } else if (control instanceof DynamicFormArray) {
                controlValue = controlValue || [];
                if (!Array.isArray(value[key])) {
                    value[key] = controlValue;
                }
                if (control.controls.length < controlValue.length) {
                    for (
                        let i = control.controls.length;
                        i < controlValue.length;
                        i++
                    ) {
                        let newDynamicField = control.getNewDynamicField(i);
                        let newControl = buildFormControl(newDynamicField);
                        (newControl as any).setDynamicField(newDynamicField);

                        control.push(newControl);
                    }
                    control.checkForChanges();
                } else if (
                    add === false &&
                    control.controls.length > controlValue.length
                ) {
                    for (
                        let i = controlValue.length;
                        i < control.controls.length;
                        i++
                    ) {
                        control.removeAt(i);
                    }
                    control.checkForChanges();
                }

                for (let i = 0; i < control.controls.length; i++) {
                    let childControl = control.controls[i];
                    let childControlValue = controlValue[i];
                    if (
                        childControlValue &&
                        childControl instanceof DynamicFormGroup
                    ) {
                        parseFormGroupForMissingArrayControls(
                            childControl,
                            childControlValue
                        );
                    }
                }
            }
        }
    }
}
