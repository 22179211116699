import { Component, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { dayjs, FileUploadService, GrowlService } from "@sf/common";
import { CurrentOrganizationService } from "@sf/userorg/common";
import { FormControl } from "@angular/forms";

@Component({
    selector: "sf-upload-field",
    templateUrl: "./upload-field.component.html",
    styleUrls: ["./upload-field.component.scss"]
})
export class UploadFieldComponent extends InputComponent implements OnInit {
    uploadComplete: boolean = false;
    fileName: string;
    uploadDate: any;

    private _groupPath: string;

    constructor(
        protected _formFacade: DynamicFormStore,
        private _growlService: GrowlService,
        private _fileUploadService: FileUploadService,
        private _currentOrgService: CurrentOrganizationService
    ) {
        super(_formFacade);
    }

    ngOnInit(): void {
        super.ngOnInit();
        //mark upload complete if form values are there
        this._groupPath = this.field.fullPath.split(this.field.path)[0];
        this._formFacade
            .getFormControl(this._groupPath + "file_name")
            .subscribe((filenameField: FormControl) => {
                if (filenameField?.value) {
                    this.fileName = filenameField.value;
                    this.uploadComplete = true;
                }
            });
        this._formFacade
            .getFormControl(this._groupPath + "upload_date")
            .subscribe((uploadDateField: FormControl) => {
                if (uploadDateField?.value) {
                    this.uploadDate = uploadDateField.value;
                }
            });
    }

    onFileSelected(event: any) {
        if (
            event &&
            event.target &&
            event.target.files &&
            event.target.files[0]
        ) {
            let file = event.target.files[0];
            if (file.type !== "application/pdf") {
                this._growlService.error(
                    "PDF is the only acceptable file format."
                );
                return;
            }
            this._fileUploadService
                .uploadWithForm(
                    file,
                    {
                        orgID: this._currentOrgService.getCurrentOrganizationID()
                    },
                    this.field.toolTip // use tooltip field as path
                )
                .subscribe(
                    () => {
                        this._growlService.success("Uploaded successfully.");
                        this.uploadComplete = true;
                        this.fileName = file.name;
                        this.uploadDate = dayjs();
                        this._formFacade
                            .getFormControl(this._groupPath + "file_name")
                            .subscribe((filenameField: FormControl) => {
                                // may need to fix this
                                filenameField?.setValue(this.fileName);
                            });
                        this._formFacade
                            .getFormControl(this._groupPath + "upload_date")
                            .subscribe((uploadDateField: FormControl) => {
                                uploadDateField?.setValue(this.uploadDate);
                            });
                    },
                    () => {
                        this.uploadComplete = false;
                    }
                );
        }
    }
}
