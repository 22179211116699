<sf-customer-survey-scale
    *ngIf="question.type === 'NPS' || question.type === 'SATISFACTION'"
    [questionText]="question.label"
    [minValue]="question.minValue"
    [minLabel]="question.minLabel"
    [maxValue]="question.maxValue"
    [maxLabel]="question.maxLabel"
    (ratingSelected)="submitAnswer($event)">
</sf-customer-survey-scale>
<sf-customer-survey-essay
    *ngIf="question.type === 'ESSAY'"
    (commentSubmitted)="submitAnswer($event)"
    [placeholder]="question.label">
</sf-customer-survey-essay>
