import { Injectable } from "@angular/core";

@Injectable()
export class SfScrollIntoViewService {
    constructor() {
        this.scrollItemIntoView = this.scrollItemIntoView.bind(this);
    }

    /*
    selector: OPTIONAL parameter to prevent finding an invalid scroll parent in some cases
                If not passed, this will search all the way up the element hierarchy
    */
    scrollItemIntoView(anchorId: string, selector: string) {
        if (!anchorId) {
            return;
        }

        let element = document.getElementById(anchorId);
        let scrollElement = element ? element.closest(selector) : null;

        if (
            !element ||
            !scrollElement ||
            scrollElement.scrollHeight === scrollElement.clientHeight
        ) {
            return;
        }

        let elementPosition = this._elementPositionInScrollContainer(
            element,
            scrollElement
        );
        if (elementPosition.isVisible) {
            return;
        }
        if (elementPosition.isBelow) {
            element.scrollIntoView(false);
            return;
        }
        if (elementPosition.isAbove) {
            element.scrollIntoView(true);
        }
    }

    findFirstVisibleItem(
        selectorId: string,
        selections: any[],
        scrollElementSelector: string
    ) {
        for (const sel of selections) {
            let anchorId = `msAnchor${selectorId}${sel.$$msHashedId}`;
            let element = document.getElementById(anchorId);
            let scrollElement = element
                ? element.closest(scrollElementSelector)
                : null;

            if (
                element &&
                scrollElement &&
                scrollElement.scrollHeight !== scrollElement.clientHeight &&
                this._isVisible(
                    element.getBoundingClientRect(),
                    scrollElement.getBoundingClientRect()
                )
            ) {
                return anchorId;
            }
        }
    }

    _elementPositionInScrollContainer(el: HTMLElement, scrollEl: Element) {
        let parentRect = scrollEl.getBoundingClientRect();
        let elementRect = el.getBoundingClientRect();

        return {
            isVisible: this._isVisible(elementRect, parentRect),
            isAbove: parentRect.top > elementRect.top,
            isBelow: parentRect.bottom < elementRect.bottom
        };
    }

    _isVisible(elementRect: DOMRect, parentRect: DOMRect) {
        return (
            parentRect.bottom > elementRect.bottom &&
            parentRect.top < elementRect.top
        );
    }
}
