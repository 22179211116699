import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    CurrentOrganizationService,
    Organization,
    UserOrgService
} from "@sf/userorg/common";
import { GrowlService, SessionService } from "@sf/common";
import { SubmitterOrganizationService } from "@sf/submitter/common";
import { first, takeUntil } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
    selector: "sf-organization-sales-tax",
    templateUrl: "./organization-sales-tax.component.html",
    styleUrls: ["./organization-sales-tax.component.scss"]
})
export class OrganizationSalesTaxComponent implements OnInit, OnDestroy {
    orgID: string;
    isAdmin: boolean = false;
    isSuperUser: boolean = false;
    payments: any = null;
    noSalesTax: boolean = false;
    salesTaxGroup: FormGroup;
    orgLoaded: boolean = false;
    processing: boolean = false;

    private _onDestroy$: Subject<void> = new Subject<undefined>();
    private _timeDelay: number = 5000; //5 seconds

    constructor(
        private _currentOrgService: CurrentOrganizationService,
        private _sessionService: SessionService,
        private _submitterOrgService: SubmitterOrganizationService,
        private _growlService: GrowlService,
        private _formBuilder: FormBuilder,
        private _userOrgService: UserOrgService
    ) {}

    ngOnInit() {
        this._currentOrgService.currentOrganization$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((org: Organization) => {
                if (org) {
                    this.orgLoaded = true;
                    this.orgID = org.id;
                    this.isAdmin = this._sessionService.hasAnyPermission(
                        ["organization_admin", "submitter_admin"],
                        this.orgID
                    );
                    this.isSuperUser = this._sessionService.isSuperUser();

                    this._getSalesTax();
                } else {
                    this.orgLoaded = false;
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    private _getSalesTax() {
        this._submitterOrgService
            .getSubmitterOrganizationConfigPayments(this.orgID, false, true)
            .subscribe((paymentConfig: any) => {
                const usesSalesTax: boolean =
                    paymentConfig["Sales Tax"] &&
                    paymentConfig["Sales Tax"].is_sales_tax_enabled;
                if (usesSalesTax) {
                    this.payments = paymentConfig;
                } else {
                    this.noSalesTax = true;
                }

                this.salesTaxGroup = this._formBuilder.group({
                    groupOnPackage: new FormControl(
                        {
                            value: this.payments
                                ? this.payments["Sales Tax"].sales_tax_grouped
                                      .value
                                : null,
                            disabled: !(this.isAdmin || this.isSuperUser)
                        },
                        {}
                    ),
                    groupOnReports: new FormControl(
                        {
                            value: this.payments
                                ? this.payments["Sales Tax"]
                                      .reports_group_sales_tax.value
                                : null,
                            disabled: !(this.isAdmin || this.isSuperUser)
                        },
                        {}
                    )
                });
            });
    }

    save() {
        this.payments["Sales Tax"].sales_tax_grouped.value =
            this.salesTaxGroup.controls.groupOnPackage.value;
        this.payments["Sales Tax"].reports_group_sales_tax.value =
            this.salesTaxGroup.controls.groupOnReports.value;

        let taxSection: any = {
            "Sales Tax": this.payments["Sales Tax"]
        };
        delete taxSection["Sales Tax"].is_sales_tax_enabled;
        this._submitterOrgService
            .setSubmitterOrganizationConfig(this.orgID, taxSection)
            .pipe(first())
            .subscribe(
                () => {
                    this._growlService.success("Organization settings saved.");
                },
                (error: string) => {
                    this._growlService.error("Error: " + error);
                }
            );
    }

    updateSalesTax() {
        this.processing = true;
        this._userOrgService
            .updateOrganizationSalesTax(this.orgID)
            .pipe(first())
            .subscribe(
                () => {
                    //since the call to update sales tax is async, this is an artificial delay before getting new values
                    setTimeout(() => {
                        this.processing = false;
                        this._growlService.success("Tax rates updated.");
                        this.payments = null;
                        this._getSalesTax();
                    }, this._timeDelay);
                },
                () => {
                    this.processing = false;
                    this._growlService.error("Tax rates failed to update.");
                }
            );
    }
}
