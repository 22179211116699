import { Injectable } from "@angular/core";
import { LoggerType } from "../../enums/logger-type.enum";

const AUDIO_API = Audio;
const SOUND_SOURCES: any = {
    1: {
        src: "images/sounds/xylophone-roll-up-slow",
        name: "Xylophone"
    },

    2: {
        src: "images/sounds/micro-bell",
        name: "Microwave Bell"
    },

    3: {
        src: "images/sounds/glass-ting",
        name: "Glass Ting"
    },

    4: {
        src: "images/sounds/job-done",
        name: "Job Done"
    },

    5: {
        src: "images/sounds/new-packages",
        name: "New Packages"
    },

    6: {
        src: "images/sounds/Chime1",
        name: "Two Bells"
    },

    7: {
        src: "images/sounds/Chime2",
        name: "Robot"
    },

    8: {
        src: "images/sounds/Chime3",
        name: "Eureka"
    },

    9: {
        src: "images/sounds/Chime4",
        name: "Bamboo 1"
    },

    10: {
        src: "images/sounds/Chime4b",
        name: "Bamboo 2"
    },

    11: {
        src: "images/sounds/Chime5",
        name: "Outer Space 1"
    },

    12: {
        src: "images/sounds/Chime5b",
        name: "Outer Space 2"
    },

    13: {
        src: "images/sounds/Chime6",
        name: "Xylophone 1"
    },

    14: {
        src: "images/sounds/Chime6b",
        name: "Xylophone 2"
    }
};

@Injectable({
    providedIn: "root"
})
export class SoundService {
    public SOUNDS: any = {
        // https://www.freesound.org/people/CGEffex/sounds/109370/
        XYLOPHONE: 1,

        // https://www.freesound.org/people/Snapper4298/sounds/179198/
        MICROWAVE: 2,

        // https://www.freesound.org/people/LuttoAudio/sounds/170237/
        GLASS_TING: 3,

        //https://notificationsounds.com/notification-sounds/job-done-501
        JOB_DONE: 4,

        // NewPackages.wav and NewPackages.mp3 have been in our repo for a long time.
        NEW_PACKAGES: 5,

        //Chimes 6-14 were provided my Marketing in EREC-3826
        TWO_BELLS: 6,

        ROBOT: 7,

        EUREKA: 8,

        BAMBOO_1: 9,

        BAMBOO_2: 10,

        OUTER_SPACE_1: 11,

        OUTER_SPACE_2: 12,

        XYLOPHONE_1: 13,

        XYLOPHONE_2: 14
    };

    constructor() {}

    playSound(sound: number) {
        if (AUDIO_API) {
            let a = new AUDIO_API();

            if (a.canPlayType("audio/ogg")) {
                a = new AUDIO_API(SOUND_SOURCES[sound].src + ".ogg");
            } else if (a.canPlayType("audio/aac")) {
                a = new AUDIO_API(SOUND_SOURCES[sound].src + ".aac");
            } else if (a.canPlayType("audio/mp3")) {
                a = new AUDIO_API(SOUND_SOURCES[sound].src + ".mp3");
            } else {
                log.getLogger(LoggerType.FALCON).warn("Cannot play audio");
                return;
            }
            a.play();
        }
    }
}
