import { Component, OnInit } from "@angular/core";

@Component({
    selector: "sf-tray-update-modal",
    templateUrl: "./tray-update-modal.component.html",
    styleUrls: ["./tray-update-modal.component.scss"]
})
export class TrayUpdateModalComponent implements OnInit {
    /** Public Variables **/
    message: string;
    percentProgress: number;

    constructor() {}

    ngOnInit(): void {}
}
