<ng-container *ngIf="{ showSettings: showingSettings$ | async } as view">
    <sf-modal-header [title]="title"></sf-modal-header>
    <div class="modal-body">
        <!-- Counties Grid -->
        <div *ngIf="!view.showSettings">
            <div class="counties-grid">
                <h3>Add More Counties</h3>
                <div class="filter-block float-right">
                    <div class="action-block">
                        <button
                            class="btn btn-link"
                            (click)="addAllOneClickCounties()"
                        >
                            <fa-icon [icon]="['far', 'plus-circle']"></fa-icon>
                            <span> Add all one-click counties</span>
                        </button>
                    </div>
                </div>
                <span>
                    Some counties can be registered with one click.<br />Others
                    require additional registration steps.
                </span>
                <br /><br />

                <div
                    class="register-counties-modal"
                    *ngIf="gridData$ | async as gridData"
                >
                    <div
                        *ngIf="!gridData.length || unassociatedCountiesLoading"
                    >
                        <fa-icon icon="spinner" [spin]="true"></fa-icon>
                        Loading unregistered counties...
                    </div>
                    <div
                        class="grid-container"
                        *ngIf="gridData.length && !unassociatedCountiesLoading"
                    >
                        <ag-grid-angular
                            class="ag-grid ag-theme-sf"
                            [gridOptions]="masterGridOptions"
                            [rowData]="gridData"
                            [modules]="modules"
                        ></ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>

        <!-- County Registration Settings -->
        <ng-container *ngIf="view.showSettings">
            <div
                *ngIf="
                    registrationSettings.errors &&
                    registrationSettings.errors.length
                "
                class="sf-mask"
            >
                <div *ngFor="let error of registrationSettings.errors">
                    <div
                        class="alert alert-danger"
                        role="alert"
                        [innerHTML]="error"
                    ></div>
                </div>
            </div>
            <p  class="sf-mask">
                You must complete all of the requirements below before you can
                submit documents electronically to
                {{ registrationSettings.recipient.name }},
                {{ registrationSettings.recipient.state }}.
            </p>

            <blockquote  class="sf-mask">
                <ng-container
                    *ngFor="
                        let setting of registrationSettings.submitterSettings
                    "
                >
                    <div [ngSwitch]="setting.settingType">
                        <ng-container *ngSwitchCase="'TEXTFIELD'">
                            <ng-container
                                *ngTemplateOutlet="
                                    textfield;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'REQUIRED_CHECKBOX'">
                            <ng-container
                                *ngTemplateOutlet="
                                    required_checkbox;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'OPTIONAL_CHECKBOX'">
                            <ng-container
                                *ngTemplateOutlet="
                                    optional_checkbox;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'OPTIONAL_TEXTFIELD'">
                            <ng-container
                                *ngTemplateOutlet="
                                    optional_textfield;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'REQUIRED_MOU'">
                            <ng-container
                                *ngTemplateOutlet="
                                    required_mou;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'REQUIRED_MOU_IMMEDIATE'">
                            <ng-container
                                *ngTemplateOutlet="
                                    required_mou_immediate;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DROPDOWN'">
                            <ng-container
                                *ngTemplateOutlet="
                                    dropdown;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'TEXT_ONLY'">
                            <ng-container
                                *ngTemplateOutlet="
                                    text_only;
                                    context: templateContext[
                                        setting.dataFieldDefinition.label
                                    ]
                                "
                            ></ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </blockquote>
            <p class="sf-mask">
                Please call Simplifile Support at {{ supportPhone }} if you need
                assistance completing the listed requirements.
            </p>
            <sf-modal-footer
                [primary]="primary"
                [secondary]="secondary"
            ></sf-modal-footer>
        </ng-container>
    </div>
</ng-container>

<ng-template #textfield let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <p *ngIf="dfd.description != 'null'" [innerHTML]="dfd.description"></p>
        <div class="form-group">
            <input
                type="text"
                [id]="dfd.label"
                [name]="dfd.label"
                [value]="rs[dfd.label] ? rs[dfd.label] : ''"
                [formControlName]="dfd.label"
                [attr.aria-label]="dfd.label"
            />
        </div>
    </form>
</ng-template>

<ng-template #required_checkbox let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <div
            *ngIf="dfd.description != 'null'"
            [innerHTML]="dfd.description"
        ></div>
        <div class="form-group form-check">
            <input
                type="checkbox"
                [name]="dfd.label"
                [id]="dfd.label"
                title="Complete"
                [formControlName]="dfd.label"
                class="form-check-input"
            />
            <label class="form-check-label" [for]="dfd.label"
                >I Understand</label
            >
        </div>
    </form>
</ng-template>

<ng-template #optional_checkbox let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <div class="form-group form-check">
            <input
                type="checkbox"
                [name]="dfd.label"
                [id]="dfd.label"
                title="Complete"
                [formControlName]="dfd.label"
                class="form-check-input"
            />
            <label class="form-check-label" [for]="dfd.label">{{
                dfd.label
            }}</label>
            <p
                *ngIf="dfd.description != 'null'"
                [innerHTML]="dfd.description"
            ></p>
        </div>
    </form>
</ng-template>

<ng-template #optional_textfield let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <p *ngIf="dfd.description != 'null'" [innerHTML]="dfd.description"></p>
        <div class="form-group">
            <input
                type="text"
                [id]="dfd.label"
                [name]="dfd.label"
                [value]="rs[dfd.label] ? rs[dfd.label] : ''"
                [formControlName]="dfd.label"
                [attr.aria-label]="dfd.label"
            />
        </div>
    </form>
</ng-template>

<ng-template #required_mou let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <div
            *ngIf="dfd.description != 'null'"
            [innerHTML]="dfd.description"
        ></div>
        <div class="form-group">
            <button class="btn btn-secondary" (click)="openMOUWindow(dfd)">
                Read Form
            </button>
        </div>
    </form>
</ng-template>

<ng-template #required_mou_immediate let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <div
            *ngIf="dfd.description != 'null'"
            [innerHTML]="dfd.description"
        ></div>
        <div class="form-group">
            <span
                class="tooltip-wrapper"
                [ngbTooltip]="restricted ? restrictedTooltip : ''"
                placement="right"
            >
                <button
                    class="btn btn-secondary"
                    (click)="openMOUWindow(dfd)"
                    [disabled]="restricted"
                >
                    Read Form
                </button>
            </span>
        </div>
    </form>
</ng-template>

<ng-template #dropdown let-dfd="dfd" let-rs="rs">
    <form [formGroup]="registerCountyForm">
        <p>
            <strong>{{ dfd.label }}</strong>
        </p>
        <div ngbDropdown>
            <button
                placement="right"
                [ngbPopover]="requiredPopoverTemplate"
                #requiredPopover="ngbPopover"
                triggers="manual"
                [autoClose]="false"
                (click)="requiredPopover.close()"
                class="btn btn-outline-primary mr-2"
                ngbDropdownToggle
            >
                {{ rs[dfd.label] ? rs[dfd.label] : "Select an Option" }}
            </button>
            <div
                ngbDropdownMenu
                aria-labelledby="dropdownManual"
                class="settings-dropdown"
            >
                <button
                    *ngFor="let option of dfd.description"
                    ngbDropdownItem
                    (click)="selectOption(dfd.label, option)"
                >
                    {{ option }}
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #requiredPopoverTemplate>
    <p>
        <strong>You must select a Company ERDS Type before proceeding.</strong>
    </p>
    <button class="btn btn-primary" (click)="requiredPopover.close()">
        OK
    </button>
</ng-template>

<ng-template #text_only let-dfd="dfd">
    <form [formGroup]="registerCountyForm" class="sf-mask">
        <p class="mt-3">
            <strong>{{ dfd.label }}</strong>
        </p>
        <div
            *ngIf="dfd.description != 'null'"
            [innerHTML]="dfd.description"
        ></div>
    </form>
</ng-template>

<ng-template #stateTemplate let-row>
    <ng-container *ngIf="row">
        <button
            *ngIf="row.anyCountiesAvailable"
            class="state-name btn btn-link p-0 border-0"
            (click)="toggleState(row.state)"
        >
            {{ row.state }}
        </button>
        <span
            *ngIf="!row.anyCountiesAvailable"
            class="state-name all-counties-added"
            title="All counties have already been added"
            >{{ row.state }}</span
        >
        <span *ngIf="row.hasNewCounties" class="new-county">New Counties</span>
    </ng-container>
</ng-template>

<ng-template #addCountyTemplate let-row>
    <ng-container *ngIf="row">
        <button
            *ngIf="row.oneClickCountiesAvailable"
            class="btn btn-link p-0 border-0"
            (click)="addAllOneClickCountiesForState(row.state)"
        >
            <fa-icon [icon]="['far', 'plus-circle']"></fa-icon>
            {{ row.addCountyText }}
        </button>
        <i *ngIf="!row.oneClickCountiesAvailable">{{ row.addCountyText }}</i>
    </ng-container>
</ng-template>

<ng-template #countyNameTemplate let-row>
    <ng-container *ngIf="row">
        <span
            [ngbTooltip]="
                row.name.includes('7AM')
                    ? 'Available for Title Organizations Only'
                    : ''
            "
            container="body"
            >{{ row.name }}&nbsp;&nbsp;</span
        ><span *ngIf="row.isNew" class="new-county">New</span>
    </ng-container>
</ng-template>

<ng-template #numDocTypes let-row>
    <ng-container *ngIf="row">
        <button
            class="btn btn-link p-0 border-0"
            (click)="onViewDocTypes(row.id)"
        >
            View Document Types
        </button>
    </ng-container>
</ng-template>

<ng-template #registerTemplate let-row>
    <ng-container *ngIf="row">
        <button
            *ngIf="row.isOneClick"
            class="btn btn-link p-0 border-0"
            [ngbTooltip]="
                row.recipientSystem
                    ? 'MOU is on file for vendor ' + row.recipientSystem
                    : ''
            "
            container="body"
            (click)="addCounty(row)"
        >
            <fa-icon [icon]="['far', 'plus-circle']"></fa-icon> Add
        </button>
        <div
            [ngbTooltip]="
                row.isRegistrationPending === 'true' ? 'Pending Approval' : ''
            "
            container="body"
        >
            <button
                *ngIf="!row.isOneClick"
                class="btn btn-link p-0 border-0"
                [disabled]="row.isRegistrationPending === 'true'"
                (click)="addCounty(row)"
            >
                <fa-icon [icon]="['far', 'clipboard-list-check']"></fa-icon>
                Start registration process
            </button>
        </div>
    </ng-container>
</ng-template>

<ng-template #customFormTemplate>
    <sf-minimal-custom-form-viewer
            class="sf-mask"
        [customFormID]="customFormID"
        [documentID]="documentID"
        [orgID]="orgID"
        [userType]="userType"
        (formValidity)="validateMinimalCustomForm($event)"
    ></sf-minimal-custom-form-viewer>
</ng-template>

<sf-tour
    *ngIf="showNewMOUTour"
    (afterInit)="startNewMOUTour($event.tour)"
    tour-name="activity-dashboard"
    [modal]="true"
>
    <sf-tour-step
        title="Alert: New MOU Process"
        text="Welcome to the new MOU signing module! We have made some changes on how we collect and manage MOU related documents. We have automated the process to allow submitters to e-sign an MOU. That means you may not need to download/wet sign/scan/email Simplifile your MOU documents. By default, the system will walk you through the automated process. There will be an option to download a blank form if needed."
        attachTo=".counties-grid right"
        nextText="Got It!"
    ></sf-tour-step>
</sf-tour>
