import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { GrowlService } from "./growl.service";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { GrowlMessage, GrowlTypes } from "./interfaces/growl.interface";
import { first, switchMap, tap } from "rxjs/operators";
import log from "loglevel";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable({
    providedIn: "root"
})
export class GrowlDisplayService {
    private growlSubscription: Subscription;

    constructor(
        private growlService: GrowlService,
        private toastr: ToastrService
    ) {
        this.growlSubscription = this.growlService.growlState.subscribe(
            (growlMessage) => {
                if (!growlMessage.message) {
                    log.getLogger(LoggerType.FALCON).warn("Undefined message");
                    return;
                }
                if (growlMessage.type === GrowlTypes.SUCCESS) {
                    this.success(growlMessage);
                }
                if (growlMessage.type === GrowlTypes.INFO) {
                    this.info(growlMessage);
                }
                if (growlMessage.type === GrowlTypes.WARNING) {
                    this.warning(growlMessage);
                }
                if (growlMessage.type === GrowlTypes.DANGER) {
                    this.error(growlMessage);
                }
            }
        );
    }

    /** show successful toast */
    private success(growl: GrowlMessage) {
        let successDefaultOptions: Partial<IndividualConfig> = {
            timeOut: this._getShowDuration(3000, growl.message),
            enableHtml: true
        };

        let mergedOptions = { ...successDefaultOptions, ...growl.options };

        this._pushActiveToast(
            this.toastr.success(growl.message, growl.title, mergedOptions)
        );
    }

    /** show info toast */
    private info(growl: GrowlMessage) {
        let infoDefaultOptions: Partial<IndividualConfig> = {
            timeOut: this._getShowDuration(4000, growl.message),
            enableHtml: true
        };

        let mergedOptions = { ...infoDefaultOptions, ...growl.options };

        this._pushActiveToast(
            this.toastr.info(growl.message, growl.title, mergedOptions)
        );
    }
    /** show warning toast */
    private warning(growl: GrowlMessage) {
        let warningDefaultOptions: Partial<IndividualConfig> = {
            timeOut: this._getShowDuration(5000, growl.message),
            enableHtml: true
        };

        let mergedOptions = { ...warningDefaultOptions, ...growl.options };

        this._pushActiveToast(
            this.toastr.warning(growl.message, growl.title, mergedOptions)
        );
    }

    /** show error toast */
    private error(growl: GrowlMessage) {
        let errorDefaultOptions: Partial<IndividualConfig> = {
            timeOut: this._getShowDuration(6000, growl.message),
            enableHtml: true
        };

        let mergedOptions = { ...errorDefaultOptions, ...growl.options };

        this._pushActiveToast(
            this.toastr.error(growl.message, growl.title, mergedOptions)
        );
    }

    private _pushActiveToast(toast: ActiveToast<any>) {
        toast.onShown
            .pipe(
                first(),
                tap(() => this.growlService.pushActiveToast(toast)),
                switchMap(() => toast.onHidden),
                first()
            )
            .subscribe(
                () => this.growlService.pushActiveToast(toast),
                () => {
                    //do nothing - could show an rxjs "No elements in sequence" type error
                }
            );
    }

    private _getShowDuration(defaultTime: number, message: string): number {
        if (message?.length * 50 > defaultTime) {
            return defaultTime + 2000;
        } else {
            return defaultTime;
        }
    }
}
