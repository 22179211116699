import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class SubmitterPaymentService {
    constructor(private _rpcClient: RpcClientService) {}

    upsertECheckInfoNew(echecks: any[]): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "upsertECheckInfo", {
            echecks: echecks
        });
    }

    findDuplicateCheckNumbers(payments: any[]): Observable<any> {
        return this._rpcClient.makeRequest(
            "payment",
            "findDuplicateCheckNumbers",
            {
                payments: payments
            }
        );
    }

    checkEcheckPrintedStatus(
        orgIDs: string[],
        echeckID: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "checkPrintedEcheckStatus",
            {
                orgIDs: orgIDs,
                echeckID: echeckID
            }
        );
    }

    // Change to submit pending payment but for now...
    submitCreditCardTransactions(
        orgID: string,
        payments: any[],
        growlMessage?: any
    ) {
        const paymentList: any[] = [];
        payments.forEach((payment: any) => {
            delete payment.isChild;
            delete payment.label;
            delete payment.packageID;
            delete payment.status;
            delete payment.orgCCAccountsSelectorOptions;
            delete payment.paymentMethod;
            delete payment.badPaymentAccountID;
            delete payment.achReferenceID;
            delete payment.editMode;
            payment.echeck = null;
            payment.targetAccountID = null;
            paymentList.push(payment);
        });

        return this._rpcClient
            .makeRequest(
                "erecord",
                "submitPendingPayments",
                {
                    orgID: orgID,
                    payments: paymentList,
                    isCCBatch: false //seems odd, but back-end was previously calling submitPendingPayments with the flag false
                },
                true
            )
            .pipe(
                switchMap((res: any) => {
                    if (growlMessage) {
                        return of(growlMessage);
                    } else {
                        return of(res);
                    }
                })
            );
    }

    isECheckApprover(): Observable<boolean> {
        return this._rpcClient.makeRequest("payment", "isECheckApprover", {});
    }

    submitPendingPayments(orgIDs: string[], payments: any, isCCBatch: boolean) {
        let paymentMap: any = {};
        orgIDs.forEach((orgID: any) => {
            let paymentList: any[] = [];
            payments[orgID].forEach((payment: any) => {
                delete payment.isChild;
                delete payment.label;
                delete payment.packageID;
                delete payment.paymentStatus;
                delete payment.status;
                delete payment.editMode;
                delete payment.paymentMethod;
                delete payment.orgCCAccountsSelectorOptions;
                delete payment.badPaymentAccountID;
                paymentList.push(payment);
            });
            if (!paymentMap.hasOwnProperty(orgID)) {
                paymentMap[orgID] = paymentList;
            } else {
                paymentMap[orgID] = paymentMap[orgID].concat(paymentList);
            }
        });

        return this._rpcClient.makeRequest(
            "erecord",
            "submitAllPendingPayments",
            {
                orgIDs: orgIDs,
                payments: paymentMap,
                isCCBatch: isCCBatch
            },
            true
        );
    }

    setBouncePaymentAccount(
        echeckID: string,
        paymentAccountID: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "setBouncePaymentAccount",
            {
                echeckID: echeckID,
                newAccountID: paymentAccountID
            }
        );
    }
}
