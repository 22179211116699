import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "roleSelector"
})
export class RoleSelectorPipe implements PipeTransform {
    transform(input: any[], search?: { text: string }): any {
        if (!input) {
            return input;
        } // no collection of items, so don't filter an empty list

        if (!search || !search.text || !search.text.length) {
            // no filter text, so show all roles
            return input;
        }

        let out: any[] = []; // array of new items to return
        let escaped = search.text.replace(/[[\]{}()*+?\\^$|#]/g, "\\$&");
        let ri = new RegExp(escaped, "i"); // get the regex ready - ignore case.
        input.forEach(function (item: any) {
            // iterate the items
            if (item.type != "label" && ri.test(item.name)) {
                // search on name
                out.push(item); // matched add back to the list
            }
        });

        return out; // return the filtered array
    }
}
