<sf-modal-header title="Select One or More Organizations"></sf-modal-header>
<div class="modal-body selector-height">
    <sf-userorg-organization-multi-selector
            id="orgSelector" name="orgSelector"
            [lock]="false"
            [initialSelection]="initialSelection"
            [options]="organizationOptions"
            [allOptions]="allOrganizationOptions"
            [selectableOrgServices]="null"
            [ignoreDisabled]="ignoreDisabled"
            (changedObjects)="orgsChanged($event)">
    </sf-userorg-organization-multi-selector>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
