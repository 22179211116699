export enum PaymentAccountType {
    BANK = "BANK_ACCOUNT",
    CHECKING = "checking",
    E_CHECK = "myecheck",
    GENERAL_LEDGER = "general ledger",
    FROST_BANK = "frost bank",
    SAVINGS = "savings",
    INVOICE = "INVOICE",
    CREDIT_CARD = "CREDIT_CARD",
    NONE = "none"
}

export enum CreditCardType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
    PREPAID = "PREPAID",
    UNKNOWN = "UNKNOWN"
}
