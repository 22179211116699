import { Component, Input } from "@angular/core";
import { BaseModalComponent, ModalButton, WindowRefService } from "@sf/common";

@Component({
    selector: "sf-secure-corrections-warning-dialog",
    templateUrl: "./secure-corrections-warning-dialog.component.html",
    styleUrls: ["./secure-corrections-warning-dialog.component.scss"]
})
export class SecureCorrectionsWarningDialogComponent extends BaseModalComponent {
    private _moreInfoUrl: string =
        sf.liveConfig.DocumentationLinks
            .documentationUrl_SECURE_CORRECTIONS_QUEUE;

    @Input()
    recipientName: string;
    @Input()
    attemptedActionDescription: string;

    public primary: ModalButton;

    constructor(private _windowRefService: WindowRefService) {
        super();
    }

    ngOnInit(): void {
        this.title = "Are you sure?";
        this.message = `If you ${this.attemptedActionDescription}, ${this.recipientName} will not process it until the next business day. Are you sure you want to proceed?`;

        this.primary = {
            text: "Yes",
            responseValue: "proceed"
        };

        this.secondary = {
            text: "More Info",
            callback: () => {
                this._windowRefService.nativeWindow.open(
                    this._moreInfoUrl,
                    "_blank"
                );
            }
        };

        this.tertiary = {
            text: "Cancel",
            responseValue: "cancel"
        };

        super.ngOnInit();
    }
}
