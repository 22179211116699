<sf-modal-header [title]="title"></sf-modal-header>

<div class="modal-body">
    <ag-grid-angular
        class="ag-grid ag-theme-sf"
        style="height: 200px"
        enableCellTextSelection
        [gridOptions]="gridOptions"
    ></ag-grid-angular>
</div>

<sf-modal-footer [primary]="okButton"></sf-modal-footer>

<ng-template #dateTemplate let-row>
    <ng-container *ngIf="row">
        <span>{{ row.date | date: "MM/dd/yyyy" }}</span>
    </ng-container>
</ng-template>

<ng-template #detailsTemplate let-row>
    <ng-container *ngIf="row">
        <span class="text-wrap">{{ row.details }}</span>
    </ng-container>
</ng-template>
