import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { InvitationsRoutingModule } from "./invitations-routing.module";
import { SelectModule } from "@sf/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormModule } from "@sf/custom-form";
import { SignLicenseComponent } from "./components/sign-license-html/sign-license.component";
import { CancelInvitationDialogComponent } from "./dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";
import { AcceptLicenseDialogComponent } from "./dialogs/accept-license-dialog/accept-license-dialog.component";
import { AcceptLicenseService } from "./services/accept-license.service";
import { SelfSignupComponent } from "./pages/self-signup/self-signup.component";
import { NewOrganizationComponent } from "./pages/new-organization/new-organization.component";
import { NewUserComponent } from "./pages/new-user/new-user.component";
import { NewServiceComponent } from "./pages/new-service/new-service.component";
import { AcceptLicenseComponent } from "./pages/accept-license/accept-license.component";
import { LicenseAcceptedDialogComponent } from "./dialogs/license-accepted-dialog/license-accepted-dialog.component";
import { PrintLicenseDialogComponent } from "./dialogs/print-license-dialog/print-license-dialog.component";
import { SendLicenseDialogComponent } from "./dialogs/send-license-dialog/send-license-dialog.component";
import { DeferAcceptComponent } from "./pages/defer-accept/defer-accept.component";
import { ApiTokenAcceptComponent } from "./pages/api-token-accept/api-token-accept.component";
import { LicenseFeePaidDialogComponent } from "./dialogs/license-fee-paid-dialog/license-fee-paid-dialog.component";
import { ModalsModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { SfCommonModule } from "@sf/common";
import { PayLicenseFeeComponent } from "./components/pay-license-fee/pay-license-fee.component";
import { ServiceNonuserComponent } from "./pages/service-nonuser/service-nonuser.component";
import { DontCloseDialogComponent } from "./dialogs/dont-close-dialog/dont-close-dialog.component";
import { CancelInvitationComponent } from "./pages/cancel-invitation/cancel-invitation.component";
import { AcceptInviteComponent } from "./pages/accept-invite/accept-invite.component";
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component";

@NgModule({
    imports: [
        CommonModule,
        SfCommonModule,
        ReactiveFormsModule,
        FormsModule,
        SfFormsModule,
        CustomFormModule,
        InvitationsRoutingModule,
        SelectModule,
        FontAwesomeModule,
        NgbModule,
        ModalsModule
    ],
    providers: [AcceptLicenseService],
    declarations: [
        NewOrganizationComponent,
        SignLicenseComponent,
        CancelInvitationDialogComponent,
        SelfSignupComponent,
        NewUserComponent,
        AcceptLicenseDialogComponent,
        NewServiceComponent,
        AcceptLicenseComponent,
        LicenseAcceptedDialogComponent,
        PrintLicenseDialogComponent,
        SendLicenseDialogComponent,
        DeferAcceptComponent,
        ApiTokenAcceptComponent,
        LicenseFeePaidDialogComponent,
        PayLicenseFeeComponent,
        ServiceNonuserComponent,
        DontCloseDialogComponent,
        CancelInvitationComponent,
        AcceptInviteComponent,
        VerifyEmailComponent
    ]
})
export class InvitationsModule {}
