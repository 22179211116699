import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Data,
    Params
} from "@angular/router";
import { NavItem } from "../interfaces/navigation.interface";

export function getRouteToFurthestLeaf(route: ActivatedRoute): {
    route: ActivatedRoute;
    leafRoute: ActivatedRoute;
    data: Data;
} {
    let { route: leafRoute, data } = _traverseRouteForData(route);
    return { route, leafRoute, data };
}

export function getRouteFromRoot(route: ActivatedRoute): {
    route: ActivatedRoute;
    leafRoute: ActivatedRoute;
    data: Data;
} {
    let { route: leafRoute, data } = _traverseRouteForData(route, true);
    return { route, leafRoute, data };
}

export function getCurrentRouteData(route: ActivatedRoute): {
    route: ActivatedRoute;
    leafRoute: ActivatedRoute;
    data: Data;
} {
    let rootData = getRouteFromRoot(route);
    let leafData = getRouteToFurthestLeaf(route);
    return {
        route: route,
        leafRoute: leafData.leafRoute,
        data: {
            ...rootData.data,
            ...leafData.data
        }
    };
}

export function getAllRouteDataFromSnapshot(
    snapshot: ActivatedRouteSnapshot
): Data {
    return _traverseRouteForDataWithSnapshot(snapshot);
}

export function getAllRouteData(route: ActivatedRoute): Data {
    return {
        ...getRouteDataFromRoot(route),
        ...getRouteDataToFurthestLeaf(route)
    };
}

export function getParamsFromRoute(
    route: ActivatedRoute | ActivatedRouteSnapshot,
    keys?: string[]
): any {
    let value: Params = {};

    if (!keys) {
        keys = ["*"];
    }

    if (route instanceof ActivatedRoute || route.hasOwnProperty("snapshot")) {
        route = (route as ActivatedRoute).snapshot;
    }

    _traverseRoute(
        route.root,
        (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
            if (keys.length === 0) {
                return true;
            }

            if (keys.includes("*")) {
                value = { ...value, ...activatedRouteSnapshot.params };
            } else {
                for (let i = 0; i < keys.length; ++i) {
                    if (activatedRouteSnapshot.paramMap.has(keys[i])) {
                        value[keys[i]] = activatedRouteSnapshot.paramMap.get(
                            keys[i]
                        );

                        keys.splice(i, 1);
                        i--;
                    }
                }
            }

            return false;
        }
    );

    return value;
}

export function getRouteDataToFurthestLeaf(route: ActivatedRoute): Data {
    return getRouteToFurthestLeaf(route).data;
}

export function getRouteDataFromRoot(route: ActivatedRoute): Data {
    return getRouteFromRoot(route).data;
}

export function transformNavItemsWithParams(
    items: NavItem[],
    route: ActivatedRouteSnapshot
): NavItem[] {
    return items.map((item) => ({
        ...item,
        link: transformLinkWithParams(item.link, route)
    }));
}

export function transformLinkWithParams(
    commands: any[],
    route: ActivatedRouteSnapshot
): any[] {
    let params = getParamsFromRoute(route);
    let finalCommands: any[] = [];

    for (let command of commands) {
        if (command.includes(":")) {
            let commandParams = command.matchAll(/\:(.[a-zA-Z\d]+)/g);
            for (let commandParam of commandParams) {
                let paramKey = commandParam[1];
                if (params.hasOwnProperty(paramKey)) {
                    command = command.replace(":" + paramKey, params[paramKey]);
                } else {
                    command = command
                        .replace(":" + paramKey, "")
                        .replace("//", "/");

                    if (command === "") {
                        command = null;
                    }
                }
            }
        }

        finalCommands.push(command);
    }

    return finalCommands.filter((command) => command !== null);
}

export function getLinkWithChangedParams(
    route: ActivatedRoute,
    params: Params = {},
    relativeRoute = false
) {
    let root = route;
    if (!relativeRoute) {
        root = route.root;
    }
    let link: string[] = [];
    _traverseRoute(
        root.snapshot,
        (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
            if (!activatedRouteSnapshot.routeConfig) {
                if (link.length === 0 && !relativeRoute) {
                    link.push("");
                }

                return false;
            } else if (activatedRouteSnapshot.routeConfig.path === "") {
                return false;
            } else if (
                relativeRoute &&
                activatedRouteSnapshot.routeConfig.path === "app"
            ) {
                return false;
            } else if (activatedRouteSnapshot.paramMap.keys.length === 0) {
                link.push(activatedRouteSnapshot.routeConfig.path);

                return false;
            }

            let command = activatedRouteSnapshot.routeConfig.path;

            for (let key of activatedRouteSnapshot.paramMap.keys) {
                let value = activatedRouteSnapshot.paramMap.get(key);
                if (typeof params[key] !== "undefined") {
                    value = params[key];
                }

                if (value) {
                    command = command.replace(":" + key, value);
                } else {
                    command = command.replace(":" + key, "");
                }
            }

            if (command !== "") {
                if (command.includes("/")) {
                    link = link.concat(command.split("/"));
                } else {
                    link.push(command);
                }
            }

            return false;
        },
        "firstChild"
    );

    if (relativeRoute) {
        link[0] = "./" + link[0];
    }

    return link;
}

function _traverseRoute(
    route: ActivatedRoute | ActivatedRouteSnapshot,
    perItemCall: (route: ActivatedRoute | ActivatedRouteSnapshot) => boolean,
    key?: "firstChild" | "parent"
): void {
    if (!key) {
        key = "firstChild";
    }
    let traversableRoute = route;
    while (traversableRoute) {
        if (perItemCall(traversableRoute)) {
            break;
        }
        traversableRoute = traversableRoute[key];
        if (!traversableRoute) {
            break;
        }
    }
}

function _traverseRouteForData(
    route: ActivatedRoute,
    fromRoot = false
): { route: ActivatedRoute; data: Data } {
    let traversableRoute = route;
    let data: Data = {};
    let currentRoute: ActivatedRoute = route;
    if (fromRoot) {
        traversableRoute = route.root;
    }
    _traverseRoute(
        traversableRoute,
        (activatedRoute: ActivatedRoute) => {
            if (activatedRoute.snapshot && activatedRoute.snapshot.data) {
                data = { ...data, ...activatedRoute.snapshot.data };
                currentRoute = activatedRoute;
            }
            if (fromRoot && activatedRoute === route) {
                return true;
            }

            return false;
        },
        "firstChild"
    );

    return { route: currentRoute, data };
}

function _traverseRouteForDataWithSnapshot(route: ActivatedRouteSnapshot): Data {
    let traversableRoute = route;
    let data: Data = {};
    _traverseRoute(
        traversableRoute,
        (activatedRoute: ActivatedRouteSnapshot) => {
            if (activatedRoute?.data) {
                data = { ...data, ...activatedRoute.data };
            }

            return false;
        },
        "firstChild"
    );

    return data;
}
