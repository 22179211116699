import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { EncryptionService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { RecentUsersService } from "@sf/userorg/common";
import { GrowlService } from "@sf/common";
import { Observable } from "rxjs";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class AdminUserorgService {
    constructor(
        private userorgService: UserOrgService,
        private _encryptionService: EncryptionService,
        private _recentUsersService: RecentUsersService,
        private _growlService: GrowlService
    ) {}

    /**
     * Log in as a user, if you are a superuser
     * You normally want to just pass the first parameter.
     * @param userID - user to log in as
     * @param dontSaveCurrent - if true, then we don't save the current page
     * @param viewPackageAsUser - if true, we go directly to the package details page as user
     * @param packageID - id of package to view as user
     */
    loginAs(userID: string, dontSaveCurrent?: boolean, viewPackageAsUser?: boolean,
        packageID?: string) {
        // First check to see if the requested user has rights to log in
        this.userorgService.canUserLogin(userID)
            .pipe(first())
            .subscribe((result: any) => {
                if (result.canLogIn) {
                    // add to 'recent users' list
                    this._recentUsersService.pushRecentUserID(userID);

                    if (!dontSaveCurrent) {
                        let ss: Storage = window.sessionStorage;
                        if (ss) {
                            // save where we are now, so we can come back later
                            let thisPage = window.location.href;
                            thisPage = thisPage.substr(thisPage.indexOf("/sf/") + 4); // remove "/sf/" from front of url

                            // we save to sessionStorage so we can get to it from JSP pages
                            ss.setItem("lastAdminPage", thisPage);
                        }
                    }

                    // scramble the username to use as parameter
                    let scrambled: string = this._encryptionService.scrambleEncode(userID);

                    // build URL
                    let url = "/sf/login.jsp?ua=" + scrambled;
                    if (viewPackageAsUser && packageID) {
                        url += ("&r=ui/submitter/package/" + packageID + "/details");
                    }

                    // go
                    window.location.href = url;
                } else {
                    this._growlService.error("Cannot log in as this user: " + result.reason);
                }
            });
    }

    getSalesforceAccessURLByType(url: string, type: string): Observable<string> {
        return this.userorgService.getSalesforceAccessURLByType(url, type);
    }

    getSalesforceAccessURLByData(type: string, data: string): Observable<string> {
        return this.userorgService.getSalesforceAccessURLByData(type, data);
    }
}
