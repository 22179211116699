<sf-modal-header title="Cancel Invitation"></sf-modal-header>
<div class="modal-body">
    <div>Would you like to only cancel this invitation, or all future invitations from Simplifile?</div>
    <form [formGroup]="cancelForm">
        <div class="radio-block">
            <label>
                <input type="radio" name="whatInvites" formControlName="whatInvites" value="justOne"/>
                &nbsp;Just this invitation
            </label>
            <label>
                <input type="radio" name="whatInvites" formControlName="whatInvites" value="allInvites"/>
                &nbsp;Unsubscribe me from future invitations
            </label>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>