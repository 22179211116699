<div *ngIf="showCreditCardAlert" class="bottom-space">
    <span class="red-text">A credit card may only be used to pay the Simplifile license fee. </span>
    <span class="red-text">Other {{serviceName}} fees must be paid with a bank account which can be added upon login.</span>
</div>
<div class="bottom-space">
    <span>Your license fee amount is: </span>
    <b>{{licenseFeeAmount | currency}}</b><span>.</span>
    <span *ngIf="!paymentMethod"> Please select your method of payment for the Simplifile {{serviceName}} license fee.</span>
</div>
<div *ngIf="paymentMethod == 'ach'" class="bottom-space">
    <div>
        Please click 'Log In' below to add a bank account and begin using Simplifile.
    </div>
</div>
<div *ngIf="paymentMethod == 'card'">
    <div *ngIf="cardOption != 'new'" class="existing-holder">
        <label>Select an existing card on your account:</label>
        <div>
            <div class="card-selector">
                <sf-select (select)="cardSelected($event)"
                    [options]="existingCards" [selectedOption]="selectedCardID"
                    trackBy="id" labelBy="label" [isSingleSelect]="true"
                    placeholder="Select a Credit Card...">
                </sf-select>
            </div>
            <button class="btn btn-link add-card-button" (click)="changeCardOption('new')">Add a new card</button>
        </div>
    </div>
    <div *ngIf="cardOption == 'new'">
        <div *ngIf="existingCards && existingCards.length">
            <button class="btn btn-link sf-btn-link old-card-button"
                (click)="changeCardOption('old')">Click here to select an existing credit card</button>
        </div>
        <div>
            <label class="section-title">Credit Card Details</label>
        </div>
        <div *ngIf="creditCardWarning" class="error-row">
            <span>{{creditCardWarning}}</span>
        </div>
        <div class="form-group">
            <label for="cardName">Name on Card</label>
            <input type="text" id="cardName" name="name" placeholder=""
                class="form-control" [(ngModel)]="creditCardName" [required]="true"/>
        </div>
        <div class="clear-float">
            <div class="form-group float-left">
                <label for="cardNumberElement">Card Number</label>
                <div id="cardNumberElement" class="credit-card-row card-number"></div>
            </div>
            <div class="form-group float-left">
                <label> </label>
                <div class="card-image" [ngClass]="{'card-error': !!creditCardNumberError}">
                    <fa-icon *ngIf="!!creditCardImage" [icon]="['fab', creditCardImage]"></fa-icon>
                </div>
            </div>
            <div class="form-group float-left">
                <label for="cardExpiryElement">Expires</label>
                <div id="cardExpiryElement" class="credit-card-row card-expiry"></div>
            </div>
            <div class="form-group float-left">
                <label for="cardExpiryElement">CVC</label>
                <div id="cardCvcElement" class="credit-card-row card-cvc"></div>
            </div>
            <div class="form-group float-left">
                <label for="cardZipCode">Card Zip Code</label>
                <input type="text" id="cardZipCode" name="cardzip" placeholder=""
                    maxlength="10"
                    class="form-control" [(ngModel)]="creditCardZip" [required]="true"/>
            </div>
        </div>
    </div>
    <div class="clear-float">
        <label class="section-title">Terms & Conditions</label>
    </div>
    <div class="agreement-text">
        You agree, by your submission of this form, to authorize Simplifile LC ("Simplifile") to charge
        your credit card for purposes of fulfilling your payment obligations that are due and payable to
        Simplifile, as those payments become due. You further represent and warrant that (i) the credit card information
        provided to Simplifile (including, but not limited to, card number, expiration date and card holder's
        name) is valid in all respects and lawfully authorized for use, (ii) that such credit card belongs
        to you or has been authorized for use by you by the valid cardholder, and (iii) the credit card
        information you provided will be saved by Simplifile for future transactions on your account. Upon
        expiration of the credit card, you agree to notify Simplifile of the new credit card expiration
        date and all other relevant information pertaining to the new credit card.
    </div>
    <div class="agreement-check">
        <label>
            <input type="checkbox" class="mt-1 mr-1" id="cardAgreeCheckbox"
                (click)="handleCardAgreeCheckbox($event)" />
            I understand and agree to the above terms and to be charged {{licenseFeeAmount | currency}} for the Simplifile {{serviceName}} license fee.
        </label>
    </div>
</div>
<!--
<div *ngIf="!paymentMethod" class="button-row">
    <button class="btn btn-primary" (click)="selectPaymentMethod('card')">Pay with Credit Card</button>
    <button class="btn btn-link" (click)="done()">Log in and pay with a bank account</button>
</div>
-->
<div *ngIf="paymentMethod == 'card'" class="button-row">
    <button [disabled]="!cardAgreementAccepted" class="btn btn-primary"
        (click)="submitPayment()">Pay License Fee</button>
    <button class="btn btn-link" (click)="dontPayCreditCard()">Log in and pay with a bank account</button>
</div>
<!--
<div *ngIf="paymentMethod == 'ach'" class="button-row">
    <button class="btn btn-primary" (click)="selectPaymentMethod('card')">Pay with Credit Card</button>
    <button class="btn btn-link" (click)="done()">Log in and pay with a bank account</button>
</div>
-->
