import { CdkDrag } from "@angular/cdk/drag-drop";
import {
    AfterViewInit,
    Component,
    Injector,
    ViewChild,
    ElementRef
} from "@angular/core";
import { CHAT_CONTAINER_DATA } from "../../services/chat-overlay.service";

@Component({
    selector: "sf-chat-overlay",
    templateUrl: "./chat-overlay.component.html",
    styleUrls: ["./chat-overlay.component.scss"]
})
export class ChatOverlayComponent implements AfterViewInit {
    @ViewChild("chatWindow", { static: false }) chatWindow: ElementRef;
    @ViewChild("drag") dragElm: CdkDrag;

    initialContent = `<html><body><p>The chat will begin here momentarily.</p></body></html>`;
    minimized: boolean;
    dragPosition = { x: 0, y: 0 };
    bounds: DOMRect;

    constructor(private _injector: Injector) {}

    ngAfterViewInit() {
        const contentWindow = this.chatWindow.nativeElement.contentWindow;
        if (contentWindow) {
            contentWindow.document.write(this.initialContent);
        }
        this.bounds = this.chatWindow.nativeElement.getBoundingClientRect();
    }

    toggleChatWindow() {
        if (this.minimized) {
            this.minimized = false;

            // Ensure window doesn't maximize off the screen
            setTimeout(() => {
                const currentBounds =
                    this.chatWindow.nativeElement.getBoundingClientRect();
                const dragPosition = this.dragElm.getFreeDragPosition();
                if (currentBounds.y < 0) {
                    this.dragPosition = {
                        x: dragPosition.x,
                        y: dragPosition.y - this.bounds.top
                    };
                }
            }, 500);
        } else {
            this.minimized = true;
        }
    }

    closeChat() {
        this._injector.get(CHAT_CONTAINER_DATA).dispose();
    }
}
