import { Injectable } from "@angular/core";
import { SubscriptionBaseService } from "@sf/common";
import { SocketService } from "@sf/common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AnalysisUploadStatus } from "../interfaces/analysis-upload-status";
import { NotaryCertificateData } from "../interfaces/notary-certificate-data";

@Injectable({
    providedIn: "root"
})
export class AnalysisSubscriptionService extends SubscriptionBaseService {
    product = "esign";
    namespace = "ocr_file";

    constructor(protected _socket: SocketService) {
        super(_socket);
    }

    getTextUpdate$(): Observable<any> {
        return this.on("update", "get_pdf_text");
    }

    getAnalyzeUpdate$(): Observable<any> {
        return this.on("update", "analyze_pdf");
    }

    getAnalyzeStatusUpdate$(): Observable<any> {
        return this.on("update", "analyze_pdf_status");
    }

    getUploadStatusUpdate$(userId: string): Observable<AnalysisUploadStatus> {
        return this.on("update", "upload_pdfs_status/" + userId).pipe(
            map((response: any) => JSON.parse(response.data.uploadPDFStatus))
        );
    }

    getReferenceFileUpdate$(documentId: string): Observable<any> {
        return this.on("update", "reference_file/" + documentId);
    }

    getNotaryFileUpdate$(userId: string): Observable<NotaryCertificateData> {
        return this.on("update", "notary_certificate/" + userId).pipe(
            map((response: any) =>
                JSON.parse(response.data.verifyNotaryCertificate)
            )
        );
    }

    getPoaOcrUpdate$(): Observable<any> {
        return this.on("update", "ocr_poa").pipe(
            map((response: any) => JSON.parse(response.data.ocrReferenceFile))
        );
    }

    getNotaryCertificateUpdate$(): Observable<any> {
        return this.on("update", "analyze_notary_certificate").pipe(
            map((response: any) =>
                JSON.parse(response.data.analyzeNotaryCertificate)
            )
        );
    }
}
