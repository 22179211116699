import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { RpcClientService } from "@sf/common";
import { mapTo, switchMap, tap } from "rxjs/operators";
import { GrowlService } from "@sf/common";
import {
    DocumentRecordingFeeInfo,
    DocumentRecordingFeeInfoUpdate
} from "./interfaces";

@Injectable({
    providedIn: "root"
})
export class SharedFeesService {
    constructor(
        private _rpcClient: RpcClientService,
        private _growlService: GrowlService
    ) {}

    getRecordingFeeInfo(
        documentID: string
    ): Observable<DocumentRecordingFeeInfo> {
        return this._rpcClient.makeRequest("erecord", "getRecordingFeeInfo", {
            documentID
        });
    }

    saveFeeData(
        documentID: string,
        recordingFeeInfo: DocumentRecordingFeeInfoUpdate,
        submissionFeeCategory: string,
        paymentsScheduled?: boolean
    ): Observable<number> {
        return this._updateDetailedFeesFromDocument(documentID, {
            ...recordingFeeInfo,
            submissionFeeCategory
        }).pipe(
            switchMap((data: { fees: number }) => {
                if (paymentsScheduled) {
                    return this._schedulePaymentsForDocument(documentID).pipe(
                        mapTo(data.fees)
                    );
                }
                return of(data.fees);
            })
        );
    }

    private _updateDetailedFeesFromDocument(
        documentID: string,
        feeData: DocumentRecordingFeeInfoUpdate
    ) {
        return this._rpcClient
            .makeRequest("erecord", "updateDetailedFeesFromDocument", {
                documentID,
                feeData
            })
            .pipe(
                tap(() => {
                    this._growlService.success(
                        "Fees were updated successfully."
                    );
                })
            );
    }

    private _schedulePaymentsForDocument(documentID: String) {
        return this._rpcClient
            .makeRequest("erecord", "schedulePaymentsForDocument", {
                documentID
            })
            .pipe(
                tap(() => {
                    this._growlService.success(
                        "Payments were scheduled successfully."
                    );
                })
            );
    }
}
