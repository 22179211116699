import { Injectable } from "@angular/core";
import { SessionService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class UtilitiesRequestFormatterService {
    /** Private Variables **/
    _serviceNames = {
        submitter: "submitter",
        jre: "jre"
    };
    _actions = {
        DOWNLOAD: "download",
        PRINT: "print"
    };

    /** Public Variables **/

    constructor(private _sessionService: SessionService) {}

    /** Public Methods **/
    createDownloadRequest(options: any) {
        let api = this._convertDownloadOptions(options);

        return {
            sf_service: this._serviceNames.submitter,
            sf_user_session: this._sessionService.getSessionID(),
            api: api
        };
    }

    createPrintRequest(options: any) {
        let api = this._convertPrintOptions(options);

        return {
            sf_service: this._serviceNames.submitter,
            sf_user_session: this._sessionService.getSessionID(),
            api: api
        };
    }

    /**  Private Methods  **/

    private _convertDownloadOptions(options: any) {
        let convertedOpts = Object.assign({}, options);
        convertedOpts.base_url = `${window.location.origin}/sf/`;
        convertedOpts.action = this._actions.DOWNLOAD;

        if (Array.isArray(convertedOpts.packageIDs)) {
            convertedOpts.packageIDs = convertedOpts.packageIDs.join(",");
        }
        if (Array.isArray(convertedOpts.documentIDs)) {
            convertedOpts.documentIDs = convertedOpts.documentIDs.join(",");
        }

        convertedOpts.outputType = convertedOpts.outputType.toUpperCase();

        return convertedOpts;
    }

    private _convertPrintOptions(options: any) {
        let convertedOpts = Object.assign({}, options);
        convertedOpts.base_url = `${window.location.origin}/sf/`;
        convertedOpts.action = this._actions.PRINT;

        if (Array.isArray(convertedOpts.packageIDs)) {
            convertedOpts.packageIDs = convertedOpts.packageIDs.join(",");
        }
        if (Array.isArray(convertedOpts.documentIDs)) {
            convertedOpts.documentIDs = convertedOpts.documentIDs.join(",");
        }

        return convertedOpts;
    }
}
