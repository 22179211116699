import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintService } from "@sf/erecord";
import { SpinnerService } from "@sf/common";
import { TokenService } from "@sf/userorg/common";

@Component({
    selector: "sf-license-accepted-dialog",
    templateUrl: "./license-accepted-dialog.component.html",
    styleUrls: ["./license-accepted-dialog.component.scss"]
})
export class LicenseAcceptedDialogComponent implements OnInit {
    @Input()
    documentID: string;
    @Input()
    addendumDocumentID: string;
    @Input()
    primaryButtonText: string;
    @Input()
    addUserData: any;

    primary: ModalButton;
    secondary: ModalButton;
    messages: string[] = [];

    constructor(
        private activeModal: NgbActiveModal,
        private printService: PrintService,
        private spinnerService: SpinnerService,
        private tokenService: TokenService
    ) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: this.primaryButtonText ? this.primaryButtonText : "OK",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        if (this.addUserData) {
            this.secondary = {
                text: "Create a User Account",
                disabled: false,
                callback: this.createUser.bind(this)
            };
        }
    }

    doPrimary() {
        this.activeModal.close(true);
    }

    printAgreement() {
        let documentIDs = [this.documentID];
        if (this.addendumDocumentID) {
            documentIDs.push(this.addendumDocumentID);
        }
        this.printService.printESignDocuments(documentIDs, null);
    }

    createUser() {
        this.spinnerService.startSpinner();
        this._sendAddUser().subscribe(
            (result: any) => {
                this.spinnerService.stopSpinner();
                if (result) {
                    if (result.message) {
                        this.messages.push(result.message);
                        return;
                    }
                    this.messages.push("Invitation URL: " + result);
                }
                let message =
                    "An invitation has been sent to you by email to " +
                    this.addUserData.email +
                    " with instructions on how to create a new Simplifile user for yourself.";
                this.messages.push(message);
                this.secondary.disabled = true;
            },
            (/*error*/) => {
                this.spinnerService.stopSpinner();
            }
        );
    }

    _sendAddUser() {
        let name = {
            firstName: this.addUserData.firstName,
            //middleName: this.addUserData.middleName,
            lastName: this.addUserData.lastName
        };
        let params = {
            organizationID: this.addUserData.organizationID,
            roleIDs: this.addUserData.roleIDs,
            email: this.addUserData.email,
            name: name,
            title: this.addUserData.title,
            phone: this.addUserData.phone
        };

        let promise = this.tokenService.inviteUser(params);
        return promise;
    }
}
