import { Component, OnDestroy, OnInit } from "@angular/core";
import { GrowlService, ModalButton } from "@sf/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubmitterRecipientService } from "../../services/submitter-recipient.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "sf-organization-signature-authorization",
    templateUrl: "./organization-signature-authorization.component.html",
    styleUrls: ["./organization-signature-authorization.component.scss"]
})
export class OrganizationSignatureAuthorizationComponent
    implements OnInit, OnDestroy
{
    /** I/O **/

    /** Private Variables **/
    private _onDestroy$ = new Subject<void>();

    /** Public Variables **/
    orgID: string;
    recipientID: string;
    title: string;
    primary: ModalButton;
    secondary: ModalButton;
    authForm: FormGroup;
    authorizedSigners: {
        phone: string;
        name: string;
        email: string;
        username: string;
    }[];

    /** View Children **/

    constructor(
        private fb: FormBuilder,
        private _submitterRecipientService: SubmitterRecipientService,
        private _growlService: GrowlService
    ) {}

    /** Lifecycle Hooks **/
    ngOnInit(): void {
        this.title = "Organization Signature Authorization";
        this.primary = {
            text: "Continue",
            callback: this._handlePrimaryButton.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            responseValue: "cancel"
        };

        this.authForm = this.fb.group({
            authorized: "yes",
            hasAccount: "yes",
            authorizedSigner: "",
            authorizedSignerEmail: ""
        });

        this.authForm.valueChanges
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(() => {
                this.primary.text =
                    this.authForm.get("authorized").value === "yes"
                        ? "Continue"
                        : "Send";
                this._handleFormValidation();
            });

        this._submitterRecipientService
            .getOrganizationAdminUsers(this.orgID)
            .subscribe((users) => {
                this.authorizedSigners = users;
            });

        this._handleFormValidation();
    }

    ngOnDestroy() {
        this._onDestroy$.next();
    }

    /** Public Methods **/

    /** Private Methods **/
    private _handlePrimaryButton() {
        if (this.authForm.get("authorized").value === "yes") {
            this.primary.responseValue = "startRegistration";
        } else {
            this._sendSignatureNeededEmail();
        }
        return true;
    }

    private _handleFormValidation() {
        const authorized = this.authForm.get("authorized").value;
        const hasAccount = this.authForm.get("hasAccount").value;
        const authorizedSigner = this.authForm.get("authorizedSigner");
        authorizedSigner.addValidators(Validators.required);
        const authorizedSignerEmail = this.authForm.get(
            "authorizedSignerEmail"
        );
        authorizedSignerEmail.addValidators([
            Validators.required,
            Validators.email
        ]);

        if (authorized === "yes") {
            authorizedSigner.clearValidators();
            authorizedSignerEmail.clearValidators();
        } else if (hasAccount === "yes") {
            authorizedSignerEmail.clearValidators();
        } else {
            authorizedSigner.clearValidators();
        }

        authorizedSigner.updateValueAndValidity({ emitEvent: false });
        authorizedSignerEmail.updateValueAndValidity({ emitEvent: false });
        this.primary.disabled = this.authForm.invalid;
    }

    private _sendSignatureNeededEmail() {
        const hasAccount = this.authForm.get("hasAccount").value;
        const authorizedSigner =
            hasAccount === "yes"
                ? this.authForm.get("authorizedSigner").value
                : null;
        const authorizedSignerEmail =
            hasAccount === "no"
                ? this.authForm.get("authorizedSignerEmail").value
                : null;

        this._submitterRecipientService
            .sendElectronicSignatureNeededEmail(
                this.orgID,
                this.recipientID,
                authorizedSigner,
                authorizedSignerEmail
            )
            .subscribe(() => {
                const signerLabel =
                    this.authorizedSigners.find(
                        (signer) => signer.username === authorizedSigner
                    )?.name ?? authorizedSignerEmail;
                this._growlService.success(
                    `Email successfully submitted to ${signerLabel}`
                );
            });
    }
}
