import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbPopoverModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SelectModule, SfCommonModule } from "@sf/common";
import { HeaderWrapperComponent } from "./components/header-wrapper/header-wrapper.component";
import { SimpleHeaderComponent } from "./components/simple-header/simple-header.component";
import { SimpleUserCenterComponent } from "./user-center/simple-user-center/simple-user-center.component";
import { UserSettingsControlComponent } from "./user-center/user-settings-control/user-settings-control.component";
import { HelpControlComponent } from "./user-center/help-control/help-control.component";
import { RouterModule } from "@angular/router";
import { SearchControlComponent } from "./user-center/search-control/search-control.component";
import { NotificationsControlComponent } from "./user-center/notifications-control/notifications-control.component";
import { PageSettingsControlComponent } from "./user-center/page-settings-control/page-settings-control.component";
import { ContactControlComponent } from "./user-center/contact-control/contact-control.component";
import { VideoControlComponent } from "./user-center/video-control/video-control.component";

@NgModule({
    imports: [
        CommonModule,
        SelectModule,
        RouterModule,
        SfCommonModule,
        FontAwesomeModule,
        NgbPopoverModule,
        NgbTooltipModule
    ],
    declarations: [
        HeaderWrapperComponent,
        SimpleHeaderComponent,
        SimpleUserCenterComponent,
        UserSettingsControlComponent,
        HelpControlComponent,
        SearchControlComponent,
        NotificationsControlComponent,
        PageSettingsControlComponent,
        ContactControlComponent,
        VideoControlComponent
    ],
    exports: [
        SimpleHeaderComponent,
        HeaderWrapperComponent,
        SimpleUserCenterComponent,
        UserSettingsControlComponent,
        HelpControlComponent,
        SearchControlComponent,
        NotificationsControlComponent,
        PageSettingsControlComponent,
        ContactControlComponent,
        VideoControlComponent
    ]
})
export class HeadersModule {}
