<sf-modal-header title="User Account Locked"></sf-modal-header>
<div class="modal-body">
    <div class="sector">
        <span>You have made too many unsuccessful attempts to log in. </span>
        <span>For security reasons, your user account is now locked.</span>
    </div>
    <div class="sector">
        <span>For further assistance, please contact your organization administrator, </span>
        <span>or call Simplifile support at 800.460.5657.</span>
    </div>
</div>
<sf-modal-footer [primary]="primary">
</sf-modal-footer>