import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";

import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { MetricLoggerService } from "../services/metric-logger.service";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    private productRegex = new RegExp(/([^/]+)(?=\/[^/]+\/?$)/);
    constructor(private metricLogger: MetricLoggerService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const product = this.productRegex.exec(req.url);
        const method = req.url.substr(req.url.lastIndexOf("/") + 1);
        const startTime = new Date().getTime();
        return next.handle(req).pipe(
            tap(
                (response) => {
                    if (
                        response instanceof HttpResponse &&
                        response.url.includes("/sf/rpc/")
                    ) {
                        this.metricLogger
                            .getLogger(LoggerType.FALCON)
                            .record(
                                product[0],
                                method,
                                new Date().getTime() - startTime
                            );
                        // if (response.body.success === true) {
                        // } else {
                        // }
                    }

                    return response;
                },
                (error) => {
                    return error;
                }
            )
        );
    }
}
