import { Injectable, Type } from "@angular/core";
import { CustomField, CustomForm } from "../interfaces";
import { dayjs, generateRandomString, GrowlService } from "@sf/common";
import {
    ArrayField,
    Conditional,
    ConditionalType,
    DynamicField,
    DynamicFormBuilder,
    DynamicFormGroup,
    FieldState,
    FieldType,
    GroupField,
    InputComponent,
    SelectField
} from "@sf/dynamic-form-viewer";
import { CustomFieldType } from "../interfaces/custom-field-type.enum";
import { CustomCheckboxFieldComponent } from "../fields/custom-checkbox-field/custom-checkbox-field.component";
import { CustomTextareaFieldComponent } from "../fields/custom-textarea-field/custom-textarea-field.component";
import { FieldRequirementType } from "../interfaces/field-requirement-type.enum";
import {
    AbstractControl,
    FormGroup,
    ValidationErrors,
    Validators
} from "@angular/forms";
import { CustomZipFieldComponent } from "../fields/custom-zip-field/custom-zip-field.component";
import { CustomNumericFieldComponent } from "../fields/custom-numeric-field/custom-numeric-field.component";
import { CustomCurrencyFieldComponent } from "../fields/custom-currency-field/custom-currency-field.component";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CustomStateFieldComponent } from "../fields/custom-state-field/custom-state-field.component";
import { CustomArrayFieldComponent } from "../fields/custom-array-field/custom-array-field.component";
import { CustomGroupFieldComponent } from "../fields/custom-group-field/custom-group-field.component";
import { CustomPartyNameFieldComponent } from "../fields/custom-party-name-field/custom-party-name-field.component";
import { CustomPartyTypeFieldComponent } from "../fields/custom-party-type-field/custom-party-type-field.component";
import { CustomButtonFieldComponent } from "../fields/custom-button-field/custom-button-field.component";
import { ButtonDynamicField } from "../interfaces/button-dynamic-field.interface";
import { DisplaySpecialType } from "../interfaces/display-special-type.enum";
import { DocumentState } from "@sf/doc-viewer";
import { CustomFormService } from "../custom-form.service";
import { CustomTextFieldComponent } from "../fields/custom-text-field/custom-text-field.component";
import { CustomDateFieldComponent } from "../fields/custom-date-field/custom-date-field.component";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";
import { CustomParagraphFieldComponent } from "../fields/custom-paragraph-field/custom-paragraph-field.component";
import { CustomUploadFieldComponent } from "../fields/custom-upload-field/custom-upload-field.component";
import { CustomPhoneNumberFieldComponent } from "../fields/custom-phone-number-field/custom-phone-number-field.component";

export interface CustomFieldValue {
    customField: CustomField;
    customFieldID: string;
    customValueID: string;
    sequenceID: number;
    valueText: string;
}

@Injectable({
    providedIn: "root"
})
export class CustomFormFieldTransformerNewService {
    private _formFieldList: CustomField[];
    private _zipRegex: string = "^[0-9]{5}(?:-[0-9]{4})?$";
    private _customValues: CustomFieldValue[];
    private _docTypes: { name: string; id: string }[];
    private _bookTypes: { name: string; id: string }[];
    private _docLeftDetails: Partial<ErecordDocumentDetails>;
    private _docLeftEditable: boolean;
    private docsInPackage: { name: string; id: string }[];
    private _documentState: DocumentState<ErecordDocumentDetails>;
    private _customFieldIDMap: any = {};
    public documentID$: BehaviorSubject<string> = new BehaviorSubject(null);
    static pageSubject: BehaviorSubject<number>;
    static helperPageCounts: any;

    constructor(
        private _fb: DynamicFormBuilder,
        private _customFormService: CustomFormService,
        private _growlService: GrowlService
    ) {}

    transform$(
        form: CustomForm,
        recipientID?: string,
        docLeftDetails?: Partial<ErecordDocumentDetails>,
        documentState?: DocumentState<ErecordDocumentDetails>
    ): Observable<DynamicFormGroup> {
        this._docLeftDetails = docLeftDetails;
        this._documentState = documentState;

        if (
            this._docLeftDetails &&
            this._docLeftDetails.packageData &&
            this._docLeftDetails.packageData.docLeftEditable !== undefined
        ) {
            this._docLeftEditable =
                this._docLeftDetails.packageData.docLeftEditable;
        } else {
            this._docLeftEditable = true;
        }
        let documentID = docLeftDetails?.id;
        if (documentID) {
            this.documentID$.next(documentID);
        }
        let valuesErrorMessage: string =
            documentID && documentID.length > 0
                ? "An error occurred attempting to retrieve the document values: "
                : null;
        let bookTypeErrorMessage: string =
            documentID && documentID.length > 0
                ? "An error occurred attempting to retrieve the BookTypes: "
                : null;
        let docTypeErrorMessage: string =
            "An error occurred attempting to retrieve the document types: ";
        let otherDocsErrorMessage: string =
            documentID && documentID.length > 0
                ? "An error occurred attempting to retrieve the other documents in the package: "
                : null;

        return forkJoin({
            values: this._customFormService
                .getDocumentCustomValuesByForm(
                    documentID,
                    form.customFormID,
                    true
                )
                .pipe(this._catchErrorWithMessage(valuesErrorMessage)),
            docsInPackage: this._customFormService
                .getOtherDocumentIDs(documentID, true)
                .pipe(this._catchErrorWithMessage(otherDocsErrorMessage)),
            docTypeList: this._customFormService
                .getRecipientDocTypeList(recipientID, false)
                .pipe(this._catchErrorWithMessage(docTypeErrorMessage)),
            bookTypeList: this._customFormService
                .getRecipientBookTypeList(recipientID, documentID, true)
                .pipe(this._catchErrorWithMessage(bookTypeErrorMessage))
        }).pipe(
            map((result: any) => {
                return this.transform(
                    form,
                    result.values,
                    result.docsInPackage,
                    result.docTypeList,
                    result.bookTypeList?.bookTypes
                );
            })
        );
    }

    transform(
        form: CustomForm,
        values?: CustomFieldValue[],
        docsInPackage?: any[],
        docTypes?: any[],
        bookTypes?: any[]
    ): DynamicFormGroup {
        // setup static variables for parent helper docs
        if (this._documentState) {
            if (!CustomFormFieldTransformerNewService.helperPageCounts) {
                CustomFormFieldTransformerNewService.helperPageCounts = {};
            }
            if (!CustomFormFieldTransformerNewService.pageSubject) {
                CustomFormFieldTransformerNewService.pageSubject =
                    new BehaviorSubject<number>(0);
                this._documentState.pages$.subscribe((pages: any[]) => {
                    CustomFormFieldTransformerNewService.pageSubject.next(
                        pages.length
                    );
                });
            }
        }

        this._customValues = values ? values : [];
        this._docTypes = docTypes ? this._convertNameToNameID(docTypes) : [];
        this._bookTypes = bookTypes ? this._convertNameToNameID(bookTypes) : [];
        this.docsInPackage = docsInPackage
            ? this._convertDocsInPackage(docsInPackage)
            : [];

        return this._processGroupings(form);
    }

    private _processGroupings(form: CustomForm): DynamicFormGroup {
        let fields: DynamicField[] = [];
        let currentGroupName = null;
        let cfField: CustomField = null;
        let arrayField: ArrayField = null;

        // -*- addArrayField -*-
        // if the arrayField is NOT null at the specified point, then add it to the fields array.
        // Basically ALL of the fields of the previous group have been added to the array field,
        // and now need to be added to the full field list

        this._formFieldList = form.fields;
        for (let i = 0; i < this._formFieldList.length; ++i) {
            cfField = this._formFieldList[i];
            this._customFieldIDMap[cfField.customFieldID] = cfField;
            let dfValues: CustomFieldValue[] = this._customValues
                ? this._customValues.filter((value: CustomFieldValue) => {
                      return cfField.customFieldID === value.customFieldID;
                  })
                : null;

            if (this._isGroupable(cfField)) {
                // if the new group doesn't match the group we were working on in the last loop,
                // then a new group was found
                if (currentGroupName != cfField.groupName) {
                    // -*- addArrayField -*-
                    if (arrayField !== null) {
                        fields.push(this._checkMaxOrAddBlank(arrayField));
                    }
                    currentGroupName = cfField.groupName;
                    arrayField = this._createArrayFieldForGroup(cfField);
                }

                this._addGroupFieldsAndValues(arrayField, cfField, dfValues);
            } else {
                // -*- addArrayField -*-
                if (arrayField !== null) {
                    fields.push(this._checkMaxOrAddBlank(arrayField));
                    arrayField = null;
                }
                currentGroupName = null;
                let dfField = this._customToDynamicFormConverter(
                    cfField,
                    // dfValues ? dfValues[0] : null
                    dfValues
                );
                fields.push(dfField);
            }
        }
        // -*- addArrayField -*-
        if (arrayField !== null) {
            fields.push(this._checkMaxOrAddBlank(arrayField));
            arrayField = null;
        }
        let customFormGroup: DynamicFormGroup = this._fb.build(fields);
        return customFormGroup;
    }

    private _checkMaxOrAddBlank(arrayField: ArrayField): ArrayField {
        if (
            !arrayField.maxLength ||
            arrayField.maxLength === -1 ||
            (arrayField.maxLength &&
                arrayField.fields.length < arrayField.maxLength)
        ) {
            let blankFields = this._getCopyOfRepeatableFields(
                arrayField.repeatableFields as GroupField,
                arrayField.path,
                arrayField.fields.length > 0,
                true
            );
            blankFields.expandable = false;
            blankFields.viewState.expanded = true;
            blankFields.defaultValue = null;
            arrayField.fields.push(blankFields);
        }

        return arrayField;
    }

    private _catchErrorWithMessage(errorMessage?: string) {
        return catchError((errors: any) => {
            if (errorMessage) {
                this._growlService.error(errorMessage + errors);
            }
            return of([]);
        });
    }

    private _customToDynamicFormConverter(
        customField: CustomField,
        dynamicFieldValue: CustomFieldValue[]
    ): DynamicField {
        let newDynamicField: DynamicField;

        switch (customField.displayType) {
            case CustomFieldType.MULTISELECT:
            case CustomFieldType.SELECT:
            case CustomFieldType.RADIO:
                let parentField: CustomField = this._getCustomFieldFromID(
                    customField.parentFieldID
                );
                if (
                    customField.dataField.fieldPath &&
                    customField.dataField.fieldPath.indexOf("[].type") &&
                    parentField &&
                    parentField.displayType === CustomFieldType.PARTY_NAME
                ) {
                    newDynamicField = this._getFixedField(
                        customField,
                        dynamicFieldValue,
                        CustomPartyTypeFieldComponent
                    );
                } else {
                    newDynamicField = this._getFixedField(
                        customField,
                        dynamicFieldValue
                    );
                }
                break;
            case CustomFieldType.CHECKBOX:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    CustomCheckboxFieldComponent
                );
                break;
            case CustomFieldType.MULTITITLE:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    null,
                    null,
                    null,
                    this._docTypes.filter((type) => {
                        return type.name !== this._docLeftDetails?.type;
                    })
                );
                break;
            case CustomFieldType.DOC_TYPE:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    null,
                    null,
                    null,
                    this._docTypes
                );
                break;
            case CustomFieldType.DOC_IN_PACKAGE:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    null,
                    null,
                    null,
                    this.docsInPackage
                );
                break;
            case CustomFieldType.STATE:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    CustomStateFieldComponent
                );
                break;
            case CustomFieldType.BOOK_TYPE:
                newDynamicField = this._getFixedField(
                    customField,
                    dynamicFieldValue,
                    null,
                    null,
                    null,
                    this._bookTypes
                );
                break;
            case CustomFieldType.ZIP:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomZipFieldComponent,
                    this._zipRegex,
                    "Zip code must be in a 5 digit, or 9 digit hyphenated format"
                );
                break;
            case CustomFieldType.NUMERIC:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomNumericFieldComponent
                );
                break;
            case CustomFieldType.CURRENCY:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomCurrencyFieldComponent
                );
                break;
            case CustomFieldType.TEXTAREA:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomTextareaFieldComponent
                );
                break;
            case CustomFieldType.TEXTFIELD:
                if (
                    customField.dataField.fieldPath
                        .toLowerCase()
                        .includes("phone")
                ) {
                    customField.phoneValueFormat =
                        this._docLeftDetails?.recipientConfig
                            ?.value_format_phone ?? "Parenthesis";
                }
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomTextFieldComponent
                );
                break;
            case CustomFieldType.PARTY_NAME:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomPartyNameFieldComponent
                );
                break;
            case CustomFieldType.DATERANGE:
            case CustomFieldType.DATE:
                customField.defaultValue = null;
                if (dynamicFieldValue && dynamicFieldValue[0]) {
                    customField.defaultValue = dayjs.utc(
                        dynamicFieldValue[0].valueText
                    );
                }
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    null,
                    CustomDateFieldComponent
                );
                break;
            case CustomFieldType.BUTTON:
                customField.defaultValue = null;
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomButtonFieldComponent
                );
                break;
            case CustomFieldType.PARAGRAPH:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomParagraphFieldComponent
                );
                break;
            case CustomFieldType.UPLOAD:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomUploadFieldComponent
                );
                break;
            case CustomFieldType.PHONE_NUMBER:
                customField.phoneValueFormat =
                    this._docLeftDetails?.recipientConfig?.value_format_phone ??
                    "Parenthesis";
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null,
                    CustomPhoneNumberFieldComponent
                );
                break;
            default:
                newDynamicField = this._getBasicDynamicField(
                    customField,
                    dynamicFieldValue ? dynamicFieldValue[0] : null
                );
                break;
        }

        return newDynamicField;
    }

    private _createArrayFieldForGroup(cfField: CustomField): ArrayField {
        let groupName = cfField.groupName;
        let defaultGroupName;
        if (cfField.dataField.fieldPath && cfField.dataField.fieldPath != "") {
            defaultGroupName = cfField.dataField.fieldPath.split(".")[0];
        } else {
            defaultGroupName =
                cfField.dataField.defaultGroupName.toLowerCase() + "[]";
        }
        let newArrayField: ArrayField = {
            fields: [],
            repeatableFields: this._getRepeatableGroupField(),
            path: defaultGroupName,
            fullPath: [defaultGroupName],
            id: defaultGroupName,
            label: groupName,
            type: FieldType.ARRAY,
            expandable: true,
            maxLength:
                !cfField.maxListLength || cfField.maxListLength === -1
                    ? null
                    : cfField.maxListLength,
            component: CustomArrayFieldComponent,
            value: null,
            visibility: {
                visible: true,
                disabled: !this._docLeftEditable
            },
            viewState: {
                visible: true,
                expanded: true
            },
            formState: {
                validators: [],
                disabled: !this._docLeftEditable
            }
        };

        return newArrayField;
    }

    private _addGroupFieldsAndValues(
        arrayField: ArrayField,
        cfField: CustomField,
        dfValues: CustomFieldValue[]
    ) {
        let df: DynamicField = this._customToDynamicFormConverter(
            cfField,
            null
        );

        // add the new field to the repeatable field list,
        (arrayField.repeatableFields as GroupField).fields.push(df);

        // add the field to any groupFields with values (that have already been copied)
        for (let f = 0; f < arrayField.fields.length; ++f) {
            if (arrayField.fields[f]) {
                this._copyFieldFromRepeatable(df, arrayField, f);
            }
        }

        // add fields with values here
        if (dfValues) {
            // isolate the field values that share the same index (usually for only multi-select, checkboxes, etc)
            let filteredValues: CustomFieldValue[][] = [];
            for (let v = 0; v < dfValues.length; ++v) {
                let index = dfValues[v].sequenceID;
                if (!filteredValues[index]) {
                    filteredValues[index] = [];
                }
                filteredValues[index].push(dfValues[v]);
            }

            // go through all the values
            for (let i = 0; i < filteredValues.length; ++i) {
                if (!filteredValues[i]) continue;
                let index = i;
                let df_withValue = this._customToDynamicFormConverter(
                    cfField,
                    // dfValues[v]
                    filteredValues[i]
                );

                // if the group of fields doesn't exist at the given index, add a new group
                if (!arrayField.fields[index]) {
                    arrayField.fields[index] = this._getCopyOfRepeatableFields(
                        arrayField.repeatableFields as GroupField,
                        arrayField.path
                    );
                    arrayField.fields[index].expandable = true;
                    arrayField.fields[index].viewState.expanded = false;
                }

                // set the value
                this._setGroupField(
                    arrayField.fields[index] as GroupField,
                    df_withValue
                );
            }
        }
    }

    private _getCopyOfRepeatableFields(
        repeatableFields: GroupField,
        groupName: string,
        removeDefaultsForBlank?: boolean,
        clearDefaults?: boolean
    ): GroupField {
        let copy: GroupField = JSON.parse(JSON.stringify(repeatableFields));
        copy.id = groupName + "." + generateRandomString(5);
        copy.path = copy.id;
        copy.fullPath = [copy.id];
        for (let i = 0; i < copy.fields.length; ++i) {
            let field = copy.fields[i];
            field.fullPath = [copy.path, field.path];
            field.component = repeatableFields.fields[i].component;
            if (removeDefaultsForBlank) {
                field.defaultValue = field.value = null;
            }
            for (
                let c = 0;
                field.conditionals && c < field.conditionals.length;
                ++c
            ) {
                field.conditionals[c].fullPath = field.conditionals[c].fullPath
                    .split("*")
                    .join(copy.path);
            }

            //copy over the validators
            for (let v = 0; v < field.formState.validators.length; ++v) {
                field.formState.validators[v].validators =
                    repeatableFields.fields[i].formState.validators[
                        v
                    ].validators;
            }
        }
        if (clearDefaults) {
            for (let i = 0; i < repeatableFields.fields.length; ++i) {
                let field = repeatableFields.fields[i];
                field.value = field.defaultValue = null;
            }
        }

        // if the collapsed Message is a function, or a component
        // then JSON.stringify ignores it. Therefore we need to copy it here
        copy.component = repeatableFields.component;
        copy.collapsedMessage = repeatableFields.collapsedMessage;
        return copy;
    }

    private _copyFieldFromRepeatable(
        df: DynamicField,
        arrayField: ArrayField,
        index: number
    ) {
        let dfCopy = JSON.parse(JSON.stringify(df));

        // functions, nor components, don't get copied over using JSON.stringify
        dfCopy.component = df.component;
        for (let v = 0; v < dfCopy.formState.validators.length; ++v) {
            dfCopy.formState.validators[v].validators =
                df.formState.validators[v].validators;
        }
        this._updateFieldParentPath(
            dfCopy,
            arrayField.fields[index] as GroupField
        );
        (arrayField.fields[index] as GroupField).fields.push(dfCopy);
    }

    // final issue - when adding a new group, and then attempting to validate/invalidate the outside parent (check this)
    private _updateFieldParentPath(df: DynamicField, group: GroupField): void {
        df.fullPath = [...group.fullPath, df.path];
        if (df.conditionals) {
            for (let condition of df.conditionals) {
                if (condition.fullPath.indexOf("*") > -1) {
                    condition.fullPath = [
                        ...group.fullPath,
                        condition.field
                    ].join(".");
                }
            }
        }
    }

    private _setGroupField(groupField: GroupField, df: DynamicField) {
        for (let i = 0; i < groupField.fields.length; ++i) {
            if (groupField.fields[i].id === df.id) {
                // make sure to copy the full path of the parent group to the full path of the field
                this._updateFieldParentPath(df, groupField);
                groupField.fields[i] = df;
                return;
            }
        }
    }

    private _getRepeatableGroupField(): GroupField {
        let groupField: GroupField = {
            fields: [],
            path: "*",
            fullPath: ["*"],
            id: "*",
            component: CustomGroupFieldComponent,
            label: "",
            type: FieldType.GROUP,
            value: null,
            expandable: true,
            visibility: {
                visible: true,
                disabled: false
            },
            viewState: {
                visible: true,
                expanded: true
            },
            formState: {
                validators: [],
                disabled: false
            },
            collapsedMessage: this._getCollapsedMessage.bind(this)
        } as GroupField;

        return groupField;
    }

    private _getCollapsedMessage(
        control: FormGroup,
        visibleFieldStates: FieldState[]
    ) {
        let collapsedMessage: string[] = [];
        for (let field of visibleFieldStates) {
            if (
                field.type !== FieldType.PARTYPARSER &&
                field.type !== FieldType.PARTYTYPE
            ) {
                // let value = control.value[field.path];
                let value = control.controls[field.path].value;
                let isTime =
                    field.type === FieldType.DATE ||
                    field.type === FieldType.TIME ||
                    field.type === FieldType.TIMESTAMP;
                if (value && (isTime || value.length > 0)) {
                    let prefix = this._customFieldIDMap[field.id]
                        ? this._customFieldIDMap[field.id].summaryPrefix
                        : "";

                    if (
                        field.defaults.fieldViewState.options &&
                        field.defaults.fieldViewState.options.length > 0
                    ) {
                        let foundValue =
                            field.defaults.fieldViewState.options.find(
                                (fieldOption) => {
                                    return (
                                        fieldOption.option ===
                                        (Array.isArray(value)
                                            ? value[0]
                                            : value)
                                    );
                                }
                            );
                        if (foundValue) {
                            let additional: string = "";
                            if (Array.isArray(value) && value.length > 1) {
                                additional =
                                    " (+" + (value.length - 1) + " more)";
                            }
                            value = foundValue.label + additional;
                        }
                    }

                    if (field.type === FieldType.DATE) {
                        if (typeof value === "string") {
                            value = dayjs(value).format("MM/DD/YYYY");
                        } else {
                            value = value.format("MM/DD/YYYY");
                        }
                    }
                    if (
                        field.type === FieldType.TIME ||
                        field.type === FieldType.TIMESTAMP
                    ) {
                        value = dayjs()
                            .hour(value.hour)
                            .minute(value.minute)
                            .second(value.second)
                            .format("hh:mm A");
                    }
                    collapsedMessage.push(prefix + " " + value);
                }
            }
        }
        return collapsedMessage;
    }

    private _isGroupable(customField: CustomField) {
        let fieldPath = customField.dataField.fieldPath;
        if (
            (fieldPath &&
                fieldPath.indexOf("[]") > -1 &&
                customField.dataField.defaultGroupName) ||
            customField.displayType === CustomFieldType.PARTY_NAME
        ) {
            return true;
        }

        // Since field is not groupable - remove any group names that may have been added accidentally
        delete customField.dataField.defaultGroupName;
        delete customField.groupName;

        return false;
    }

    private _getFixedField(
        customField: CustomField,
        fieldValue: CustomFieldValue[],
        component?: Type<InputComponent>,
        customValidation?: string,
        customValidationMessage?: string,
        customOptions?: any[]
    ): SelectField {
        const baseField = this._getBasicDynamicField(
            customField,
            null,
            component,
            customValidation,
            customValidationMessage
        );
        baseField.showLabel = true;
        if (fieldValue && fieldValue.length > 0) {
            if (
                customField.displayType === CustomFieldType.RADIO ||
                customField.displayType === CustomFieldType.SELECT
            ) {
                baseField.value = fieldValue[0].customValueID;
            } else if (
                customField.displayType === CustomFieldType.STATE ||
                customField.displayType === CustomFieldType.DOC_TYPE ||
                customField.displayType === CustomFieldType.BOOK_TYPE ||
                customField.displayType === CustomFieldType.DOC_IN_PACKAGE
            ) {
                baseField.value = fieldValue[0].valueText;
            } else if (customField.displayType === CustomFieldType.MULTITITLE) {
                baseField.value = JSON.parse(fieldValue[0].valueText);
            } else {
                baseField.value = fieldValue.map((fv) => {
                    return fv.customValueID;
                });
            }
        }

        let options = [];
        if (customOptions) {
            for (let i = 0; i < customOptions.length; ++i) {
                options[i] = {
                    option: customOptions[i].id,
                    label: customOptions[i].name
                };
            }
        } else {
            for (let i = 0; i < customField.dataField.values.length; ++i) {
                options[i] = {
                    option: customField.dataField.values[i].customValueID,
                    label: customField.dataField.values[i].displayLabel
                };
            }
        }

        return {
            ...baseField,
            viewState: {
                ...baseField.viewState,
                options
            }
        };
    }

    private _getBasicDynamicField(
        customField: CustomField,
        fieldValue: CustomFieldValue,
        component?: Type<InputComponent>,
        customValidation?: string,
        customValidationMessage?: string
    ): DynamicField {
        let type: FieldType = this._convertCustomFieldToFieldType(customField);
        let path;
        if (
            customField.dataField.fieldPath &&
            customField.dataField.fieldPath != ""
        ) {
            // the customFieldID needs to be a part of the path (added as #customFieldID)
            // because multiple child fields
            // can have the same fieldPath (this distinguishes them)
            path =
                (customField.dataField.fieldPath.indexOf("[]") > -1
                    ? customField.dataField.fieldPath.split(".")[1]
                    : customField.dataField.fieldPath) +
                "#" +
                customField.customFieldID;
        } else {
            path = customField.customFieldID;
        }
        let fullPath = [path];
        if (customField.groupName && customField.groupName.length > 0) {
            // fullPath.unshift(customField.groupName);
            fullPath.unshift("*");
        }
        let value: any = fieldValue
            ? fieldValue.valueText
            : customField.defaultValue;
        if (customField.displayType == CustomFieldType.TIME) {
            try {
                // converts     {hour=1, minute=23, second=45}
                // to           {\"hour\":1, \"minute\":23, \"second\":45}
                value = value
                    .split("{")
                    .join('{"')
                    .split("=")
                    .join('": ')
                    .split(", ")
                    .join(', "');
                value = JSON.parse(value);
            } catch (e) {
                value = null;
            }
        }

        let newDynamicField: DynamicField = {
            id: customField.customFieldID, // unique based on customFieldID
            type: type,
            label: customField.displayLabel,

            path: path,
            fullPath: fullPath,
            value: value, // any | null
            defaultValue: customField.defaultValue, // TODO: <--- prevent default values if the index is greater than 0
            visibility: {
                visible:
                    customField.parentFieldID || customField.parentHelperDoc
                        ? false
                        : true,
                disabled:
                    customField.displaySpecial ===
                        DisplaySpecialType.READ_ONLY || !this._docLeftEditable
            },
            viewState: {
                visible:
                    customField.parentFieldID || customField.parentHelperDoc
                        ? false
                        : true
            },
            formState: {
                validators: [],
                disabled:
                    customField.displaySpecial ===
                        DisplaySpecialType.READ_ONLY || !this._docLeftEditable
            },
            phoneValueFormat: customField.phoneValueFormat,
            defaultLabel: customField.dataField.defaultDisplay
        };

        // we use cffNotes for custom function names for the customButton field AND for the tooltip for regular fields
        if (customField.displayType === CustomFieldType.BUTTON) {
            (newDynamicField as ButtonDynamicField).customFunction =
                customField.cffNotes;
            (newDynamicField as ButtonDynamicField).linkURL =
                customField.message;
        } else if (customField.cffNotes) {
            newDynamicField.toolTip = customField.cffNotes;
            newDynamicField.showToolTipIcon = true;
        }

        this._setRequirementsAndValidators(
            newDynamicField,
            customField,
            customValidation,
            customValidationMessage
        );

        // conditionals (parent/child relationships in Custom Forms)
        if (customField.parentFieldID) {
            let type: ConditionalType = this._getConditionalType(customField);

            let parentField: CustomField = this._getCustomFieldFromID(
                customField.parentFieldID
            );
            let parentPath;
            if (
                parentField.dataField.fieldPath &&
                parentField.dataField.fieldPath != ""
            ) {
                // the customFieldID needs to be a part of the path (added as #customFieldID)
                // because multiple child fields
                // can have the same fieldPath (this distinguishes them)
                parentPath =
                    (parentField.dataField.fieldPath.indexOf("[]") > -1
                        ? parentField.dataField.fieldPath.split(".")[1]
                        : parentField.dataField.fieldPath) +
                    "#" +
                    parentField.customFieldID;
            } else {
                parentPath = parentField.customFieldID;
            }

            let parentPathArray: string[] = [];
            if (parentField && parentField.groupName) {
                parentPathArray.push("*");
            }
            parentPathArray.push(parentPath);

            let parentConditional: Conditional = {
                type: type,
                value: customField.parentValueID
                    ? customField.parentValueID
                    : null,
                field: [parentPath],
                fullPath: parentPathArray.join("."),
                override: {
                    viewState: {
                        visible: true
                    },
                    value: null
                }
            };
            if (!newDynamicField.conditionals)
                newDynamicField.conditionals = [];
            newDynamicField.conditionals.push(parentConditional);
        }
        if (customField.parentHelperDoc && this._documentState) {
            let parentHelperDocs: string[] =
                customField.parentHelperDoc.split(",");

            // initial helper doc page count
            for (let individualHelper of parentHelperDocs) {
                let customHelperParent = individualHelper.trim();
                if (
                    CustomFormFieldTransformerNewService.helperPageCounts[
                        customHelperParent
                    ] === undefined
                ) {
                    CustomFormFieldTransformerNewService.helperPageCounts[
                        customHelperParent
                    ] = 0;
                    for (let helper of this._docLeftDetails.helperDocuments) {
                        if (
                            helper.type === customHelperParent &&
                            helper.pages &&
                            helper.pages.length > 0
                        ) {
                            CustomFormFieldTransformerNewService.helperPageCounts[
                                customHelperParent
                            ] = helper.pages.length;
                        }
                    }
                }
            }

            let parentHelperConditional: Conditional = {
                type: ConditionalType.FUNCTION,
                value: null,
                field: [], //[customField.customFieldID],
                fullPath: "", //newDynamicField.fullPath.join("."),
                override: {
                    viewState: {
                        visible: true
                    },
                    value: null
                },
                outsideCondition:
                    CustomFormFieldTransformerNewService.pageSubject.asObservable(),
                onConditionAction: (pages: number) => {
                    let hasParentWithPages: boolean = false;
                    for (let individualHelper of parentHelperDocs) {
                        let helper = individualHelper.trim();
                        if (
                            this._docLeftDetails.handle ==
                            this._docLeftDetails.id +
                                "/" +
                                helper.split(" ").join("_")
                        ) {
                            CustomFormFieldTransformerNewService.helperPageCounts[
                                helper
                            ] = pages;
                        }
                        if (
                            CustomFormFieldTransformerNewService
                                .helperPageCounts[helper] > 0
                        ) {
                            hasParentWithPages = true;
                        }
                    }

                    return hasParentWithPages;
                }
            };

            if (!newDynamicField.conditionals)
                newDynamicField.conditionals = [];
            newDynamicField.conditionals.push(parentHelperConditional);
        }
        if (component) {
            newDynamicField.component = component;
        }
        return newDynamicField;
    }

    private _setRequirementsAndValidators(
        newDynamicField: DynamicField,
        customField: CustomField,
        customValidation?: string,
        customValidationMessage?: string
    ): void {
        if (
            customField.requirement === FieldRequirementType.REQUIRED &&
            newDynamicField.type !== FieldType.PARTYTYPE
        ) {
            newDynamicField.formState.validators.push({
                identifier: "required",
                type: "sync",
                validators: [Validators.required]
            });
        }

        if (customField.regex && customField.regex.length > 0) {
            newDynamicField.formState.validators.push({
                identifier: "custom",
                type: "sync",
                validators: [
                    this._patternWithMessage(
                        customField.regex,
                        customField.message
                    )
                ]
            });
        }

        // custom validation is separate from custom regex, because a field could potentially have both
        if (customValidation) {
            newDynamicField.formState.validators.push({
                identifier: "custom",
                type: "sync",
                validators: [
                    this._patternWithMessage(
                        customValidation,
                        customValidationMessage
                            ? customValidationMessage
                            : "Invalid"
                    )
                ]
            });
        }
    }

    private _patternWithMessage(pattern: string | RegExp, message: string) {
        if (!pattern) return Validators.nullValidator;
        let regex: RegExp;
        let regexStr: string;
        if (typeof pattern === "string") {
            regexStr = pattern;
        } else {
            regexStr = pattern.toString();
        }

        regex = new RegExp(this._makeRegexExactMatch(regexStr));
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                control.value === null ||
                control.value === undefined ||
                control.value === ""
            ) {
                return null; // don't validate empty values to allow optional controls
            }
            const value: string = control.value;
            return regex.test(value)
                ? null
                : {
                      custom: { message: message }
                  };
        };
    }

    private _makeRegexExactMatch(regexStr: string) {
        if (regexStr[0] !== "^") {
            regexStr = "^" + regexStr;
        }
        if (regexStr[regexStr.length - 1] !== "$") {
            regexStr = regexStr + "$";
        }
        return regexStr;
    }

    private _getConditionalType(customField: CustomField): ConditionalType {
        let type: ConditionalType = null;
        if (customField.parentFieldID) {
            type = ConditionalType.NOTEMPTYANDVISIBLE;
            if (customField.parentValueID) {
                let parentField: CustomField = this._getCustomFieldFromID(
                    customField.parentFieldID
                );
                if (
                    parentField.displayType === CustomFieldType.MULTISELECT ||
                    parentField.displayType === CustomFieldType.CHECKBOX
                ) {
                    type = ConditionalType.CONTAINSANDVISIBLE;
                } else {
                    type = ConditionalType.EQUALANDVISIBLE;
                }
            }
        }
        return type;
    }

    private _convertCustomFieldToFieldType(
        customField: CustomField
    ): FieldType {
        if (
            customField.dataField.fieldPath &&
            customField.dataField.fieldPath.indexOf("[].type") > -1
        ) {
            return FieldType.PARTYTYPE;
        }
        if (
            customField.dataField.fieldPath &&
            customField.dataField.fieldPath.indexOf("[].nameUnparsed") > -1
        ) {
            return FieldType.NAMEUNPARSED;
        }

        switch (customField.displayType) {
            case CustomFieldType.BUTTON:
                return FieldType.TEXTFIELD;
            case CustomFieldType.BOOLEAN:
                return FieldType.TEXTFIELD;
            case CustomFieldType.PARTY_NAME:
                return FieldType.PARTYPARSER;
            case CustomFieldType.BOOK_TYPE:
            case CustomFieldType.DOC_TYPE:
            case CustomFieldType.DOC_IN_PACKAGE:
            case CustomFieldType.STATE:
                return FieldType.SELECT;
            default:
                return FieldType[
                    CustomFieldType[
                        customField.displayType
                    ] as keyof typeof FieldType
                ];
        }
    }

    private _convertNameToNameID(inputArray: { name: string }[]) {
        let index = 0;
        let nameIdArray: any[] = [];
        for (let input of inputArray) {
            nameIdArray[index] = {
                id: input.name,
                name: input.name
            };
            index++;
        }

        return nameIdArray;
    }

    private _convertDocsInPackage(docInPackageArray: any) {
        let index = 0;
        let newDocsInPackageArray: any[] = [];
        for (let key in docInPackageArray) {
            newDocsInPackageArray[index] = {
                id: "simultaneous_" + key,
                name: docInPackageArray[key]
            };
            index++;
        }

        return newDocsInPackageArray;
    }

    private _getCustomFieldFromID(customFieldID: string): CustomField {
        for (let i = 0; i < this._formFieldList.length; ++i) {
            if (customFieldID === this._formFieldList[i].customFieldID) {
                return this._formFieldList[i];
            }
        }
        return null;
    }
}
