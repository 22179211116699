import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormService } from "./custom-form.service";
import {
    NgbModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { DynamicFormViewerModule } from "@sf/dynamic-form-viewer";
import { CustomCheckboxFieldComponent } from "./fields/custom-checkbox-field/custom-checkbox-field.component";
import { CustomOtherFieldComponent } from "./fields/custom-other-field/custom-other-field.component";
import { CustomTextareaFieldComponent } from "./fields/custom-textarea-field/custom-textarea-field.component";
import { CustomZipFieldComponent } from "./fields/custom-zip-field/custom-zip-field.component";
import { CustomNumericFieldComponent } from "./fields/custom-numeric-field/custom-numeric-field.component";
import { CustomStateFieldComponent } from "./fields/custom-state-field/custom-state-field.component";
import { SelectModule } from "@sf/common";
import { SfFormsModule } from "@sf/common";
import { CustomArrayFieldComponent } from "./fields/custom-array-field/custom-array-field.component";
import { CustomArrayFieldItemWrapperComponent } from "./fields/custom-array-field-item-wrapper/custom-array-field-item-wrapper.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PortalModule } from "@angular/cdk/portal";
import { CustomGroupFieldComponent } from "./fields/custom-group-field/custom-group-field.component";
import { CustomPartyNameFieldComponent } from "./fields/custom-party-name-field/custom-party-name-field.component";
import { CustomFormViewerNewComponent } from "./components/custom-form-viewer-new/custom-form-viewer-new.component";
import { CustomRootFieldComponent } from "./fields/custom-root-field/custom-root-field.component";
import { CustomPartyTypeFieldComponent } from "./fields/custom-party-type-field/custom-party-type-field.component";
import { CommonPartyDialogComponent } from "./dialog/common-party-dialog/common-party-dialog.component";
import { ModalsModule } from "@sf/common";
import { CustomButtonFieldComponent } from "./fields/custom-button-field/custom-button-field.component";
import { CommonCoreModule } from "@sf/common";
import { CustomCurrencyFieldComponent } from "./fields/custom-currency-field/custom-currency-field.component";
import { CustomTextFieldComponent } from "./fields/custom-text-field/custom-text-field.component";
import { ErecordViewerCoreModule } from "@sf/document/erecord-viewer/common";
import { CustomDateFieldComponent } from "./fields/custom-date-field/custom-date-field.component";
import { DateAndTimeModule } from "@sf/common";
import { CustomParagraphFieldComponent } from "./fields/custom-paragraph-field/custom-paragraph-field.component";
import { CustomUploadFieldComponent } from "./fields/custom-upload-field/custom-upload-field.component";
import { MinimalCustomFormViewerComponent } from "./components/minimal-custom-form-viewer/minimal-custom-form-viewer.component";
import { CustomPhoneNumberFieldComponent } from "./fields/custom-phone-number-field/custom-phone-number-field.component";

@NgModule({
    imports: [
        SelectModule,
        CommonModule,
        NgbModule,
        DynamicFormViewerModule,
        FormsModule,
        ReactiveFormsModule,
        SfFormsModule,
        FontAwesomeModule,
        PortalModule,
        NgbTypeaheadModule,
        NgbDropdownModule,
        NgbTooltipModule,
        ModalsModule,
        CommonCoreModule,
        ErecordViewerCoreModule,
        DateAndTimeModule
    ],
    declarations: [
        CustomCheckboxFieldComponent,
        CustomOtherFieldComponent,
        CustomTextareaFieldComponent,
        CustomZipFieldComponent,
        CustomNumericFieldComponent,
        CustomStateFieldComponent,
        CustomArrayFieldComponent,
        CustomArrayFieldItemWrapperComponent,
        CustomGroupFieldComponent,
        CustomPartyNameFieldComponent,
        CustomFormViewerNewComponent,
        CustomRootFieldComponent,
        CustomPartyTypeFieldComponent,
        CommonPartyDialogComponent,
        CustomButtonFieldComponent,
        CustomCurrencyFieldComponent,
        CustomTextFieldComponent,
        CustomDateFieldComponent,
        CustomParagraphFieldComponent,
        CustomUploadFieldComponent,
        MinimalCustomFormViewerComponent,
        CustomPhoneNumberFieldComponent
    ],
    exports: [CustomFormViewerNewComponent, MinimalCustomFormViewerComponent],
    providers: [CustomFormService]
})
export class CustomFormModule {}
