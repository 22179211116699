import { Component, OnDestroy, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { ValidationErrors } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "sf-checkbox-field",
    templateUrl: "./checkbox-field.component.html",
    styleUrls: ["./checkbox-field.component.scss"]
})
export class CheckboxFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy {
    errors: ValidationErrors;
    private _destroy$ = new Subject<void>();

    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formErrors$.pipe(takeUntil(this._destroy$)).subscribe((errors) => {
            this.errors = errors;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
    }
}
