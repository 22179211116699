import { Injectable } from "@angular/core";
import { SubscriptionBaseService } from "@sf/common";
import { SocketService } from "@sf/common";
import { Observable } from "rxjs";
import { PackageStatusUpdate } from "../interfaces/package-status-update";

@Injectable({
    providedIn: "root"
})
export class PackageStatusChangesService extends SubscriptionBaseService {
    protected readonly namespace = "packageMenu";
    protected readonly product = "erecord";

    constructor(protected _socket: SocketService) {
        super(_socket);
    }

    public createUpdateSubscription(): Observable<PackageStatusUpdate> {
        return this.on("update");
    }
}
