import { Injectable } from "@angular/core";
import {
    RpcClientService,
    SessionOrganization,
    SessionService,
    sortBy
} from "@sf/common";
import { Observable, of } from "rxjs";
import { UiRecipient } from "@sf/submitter/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class PersonalSettingsService {
    constructor(
        private _rpcClient: RpcClientService,
        private _sessionService: SessionService
    ) {}

    getUserMapping(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getUserMapping", {
            userID
        });
    }

    saveUserMapping(userID: string, names: string[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "saveUserMapping", {
            userID: userID,
            names: names
        });
    }

    getAllAvailableCountiesBySubmitter(submitterID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAllAvailableCountiesBySubmitter", {
            submitterID: submitterID
        });
    }

    getRecentRecipients(submitterID: string): Observable<UiRecipient> {
        return this._rpcClient.makeRequest("erecord", "getRecentRecipients", {
            submitterID: submitterID
        });
    }

    getSubmitterOrganizationsSettings(submitterIds: string[]): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getSubmitterOrganizations", {
            submitterIds: submitterIds
        });
    }
}
