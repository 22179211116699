import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { LiveConfigManagementService } from "@sf/admin/common";

import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridOptions } from "@ag-grid-community/core";
import { dayjs } from "@sf/common";

@Component({
    selector: "sf-live-config-changes-table",
    templateUrl: "./live-config-changes-table.component.html",
    styleUrls: ["./live-config-changes-table.component.scss"]
})
export class LiveConfigChangesTableComponent implements OnInit {
    /** I/O **/
    @Input()
    tag: string;
    @Input()
    before?: dayjs.Dayjs;
    @Input()
    after?: dayjs.Dayjs;

    /** Private Variables **/

    /** Public Variables **/
    changes?: any[];
    extra?: string;

    @ViewChild("changesGrid")
    changesGrid: AgGridAngular;
    changesGridOptions: GridOptions;
    changesGridColumns: Partial<ColDef>[];

    constructor(private _rpc: LiveConfigManagementService) {}

    /** LifeCycle Hooks **/

    ngOnInit() {
        this._refresh();
        this.changesGridOptions = {
            defaultColDef: {
                resizable: true,
                filter: true,
                sortable: true
            },
            onRowSelected: (event) => this.onRowSelectedEmitter(event)
        };
        this.setupChangesGrid();
    }

    onGridSizeChanged(event: any) {
        event.api.sizeColumnsToFit();
    }

    _refresh() {
        if (this.after == null) {
            this.after = dayjs(new Date()).add(-2, "days");
        }
        if (this.before == null) {
            this.before = dayjs(new Date());
        }
        this.before = this.before.endOf("day");
        this._rpc
            .getRecentLiveConfigChanges(
                this.tag,
                this.after.toISOString(),
                this.before.toISOString(),
                500
            )
            .subscribe((changes) => {
                let nested_changes = changes.map(
                    ({ sectionID, optionID, username, date, changes }) => {
                        return changes.map(({ target, action, value }) => {
                            return {
                                sectionID,
                                optionID,
                                username,
                                date,
                                target,
                                action,
                                value
                            };
                        });
                    }
                );

                this.changes = [].concat.apply([], nested_changes);
                console.log(this.changes);
            });
    }

    onRowSelectedEmitter(event: any) {
        if (event.node.selected) {
            let temp: any = event.node.data.value;
            if (temp instanceof Object) {
                this.extra = JSON.stringify(event.node.data.value, null, 2);
                console.log("Yes extra", event.node.data.value);
            } else {
                this.extra = undefined;
                console.log("No extra", event.node.data.value);
            }
            console.log("Extra: ", this.extra);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._refresh();
    }

    setupChangesGrid() {
        this.changesGridColumns = [
            {
                field: "sectionID",
                headerName: "Section"
            },
            {
                field: "optionID",
                headerName: "Setting"
            },
            {
                field: "username",
                headerName: "User",
                width: 100
            },
            {
                field: "date",
                headerName: "Modified",
                width: 200
            },
            {
                field: "target",
                headerName: "Target",
                width: 120
            },

            {
                field: "action",
                headerName: "Action",
                width: 200
            },

            {
                field: "value",
                headerName: "Value",
                width: 400
            }
        ];
    }

    gridContext(): { componentParent: any } {
        return {
            componentParent: this
        };
    }
}
