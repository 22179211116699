<div class="sf-unmask">
    <sf-modal-header title="Verify Phone Number"></sf-modal-header>
    <div class="modal-body">
        <div class="input-error row" [ngClass]="{'on': warning}">
            {{warning}}
        </div>
        <div class="explain">
            To verify your phone number, please enter the verification code we just sent to your phone.
        </div>
        <div class="">
            <label for="number">Verification Code</label>
        </div>
        <div class="form-group">
            <input type="text" id="number" name="number" class="form-control code-input sf-mask"
                   [(ngModel)]="verificationCode" maxlength="10"/>
        </div>
        <div>
            <i *ngIf="waiting" class="">Please wait 30 seconds before requesting a new code.</i>
            <span *ngIf="!waiting">Didn't receive the code? </span>
            <button *ngIf="!waiting" class="btn btn-link sf-btn-link ml-1" (click)="newCode()">Request a new code</button>
        </div>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary" ></sf-modal-footer>
</div>
