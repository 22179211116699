import { Injectable } from "@angular/core";

declare const window: any;

@Injectable({
    providedIn: "root"
})
export class PersistedMemoryService {
    ss: any;
    ls: any;
    ssObjects: any = {};
    lsObjects: any = {};
    unsupported = false;

    constructor() {
        try {
            this.ss = window.sessionStorage;
            this.ls = window.localStorage;
        } catch (e) {
            //Ignore the exception. We handle the case a few lines lower....
        }

        if (!this.ss || !this.ls) {
            if (window.console) {
                console.warn("The browser does not support local storage");
            }
            this.unsupported = true;
        }
    }

    /**
     *
     * Get an object/value out of persisted memory. This method is optimized to only ever hit localStorage once and thereafter
     * store the key/value pair in an internal memory object.
     *
     * @param key - Key of object/value to get out of persisted memory.
     * @param options - {localStorage: true} - Get the object from localStorage instead of sessionStorage. Default false.
     *                - {noparse: true} - Don't parse the stored value as json. See options.noStringify in the set method. Default undefined (false).
     *                - {forceRead: true} - Force a read from localStorage even if the value is found in the internal memory object.
     * @return The object/value from persisted memory.
     */
    get(key: string, options?: any): any {
        options = options || {};

        var obj = this[options.localStorage ? "lsObjects" : "ssObjects"][key],
            val;

        if ((!obj || options.forceRead) && !this.unsupported) {
            val = options.localStorage
                ? this.ls.getItem(key)
                : this.ss.getItem(key);

            if (
                val === "undefined" ||
                typeof val === "undefined" ||
                val === null
            ) {
                return null;
            }

            if (!options.noparse) {
                val = JSON.parse(val);
            }

            this[options.localStorage ? "lsObjects" : "ssObjects"][key] = {
                value: val
            };
        } else {
            val = obj ? obj.value : undefined;
        }

        return val;
    }

    /**
     * Save an object/value. If the browser does not support local storage, the value/object will just
     * be stored in an internal hash, thus making it accessible by other modules but not persistent
     * between sessions.
     *
     * @param key - A simple key to save an object under. Should be unique.
     * @param obj - A string or an object. JSON.stringify will be called on this value unless noStringify is passed as an option
     * @param options    - {noStringify: true}    - If true, JSON.stringify will not be called on the value/object being set. Default false.
     *                    - {noLocal: true}        - If true, store the object/value in immediate memory, not in local/session storage.
     *                    - {localStorage: true}    - If true, save the object/value into localStorage instead of sessionStorage. Default false.
     * @return {*}
     */
    set(key: string, obj: any, options?: any): any {
        options = options || {};

        var val = options.noStringify ? obj : JSON.stringify(obj);

        options = options || {};

        this[options.localStorage ? "lsObjects" : "ssObjects"][key] = {
            value: obj
        };

        if (!this.unsupported && !options.noLocal) {
            options.localStorage
                ? this.ls.setItem(key, val)
                : this.ss.setItem(key, val);
        }

        return this;
    }

    /**
     *
     * Remove a key and object from the internal memory and/or from session/local storage. If the browser does not support
     * local or session storage, it will only remove from internal memory.
     *
     * @param key - Key of object to be removed
     * @param options - {localStorage: true} - Remove the object from localStorage instead of sessionStorage. Default false.
     * @return the object/value that was removed from persisted memory.
     */
    remove(key: string, options?: any): any {
        options = options || {};

        var val = this.get(key, options);

        delete this[options.localStorage ? "lsObjects" : "ssObjects"][key];

        if (!this.unsupported) {
            options.localStorage
                ? this.ls.removeItem(key)
                : this.ss.removeItem(key);
        }

        return val;
    }

    /**
     * Change the persistence methods from the global objects to another object. Useful for mocking in tests
     * @param ss
     * @param ls
     */
    changePersistence(ss: any, ls: any) {
        this.ls = ls;
        this.ss = ss;
    }

    keys(options: any): string[] {
        options = options || {};

        let keys: string[] = [];
        if (!this.unsupported && !options.noLocal) {
            const storage = options.localStorage ? this.ls : this.ss;
            keys = Object.keys(storage);
        } else {
            keys = Object.keys(
                this[options.localStorage ? "lsObjects" : "ssObjects"]
            );
        }
        return keys;
    }
}
