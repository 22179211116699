<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="content">
    <div class="head-text">Email Verification</div>
    <div class="top-gap">{{resultText}}</div>
    <div class="top-gap">
        <button class="btn btn-secondary" (click)="goToLogin()">Log In</button>
    </div>
</div>
