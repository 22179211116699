import {
    Directive,
    ElementRef,
    HostListener,
    EventEmitter,
    Output
} from "@angular/core";
import { parents } from "../helpers/browser";

@Directive({
    selector: "[sfClickOff]"
})
export class ClickOffDirective {
    @Output()
    onClickOff: EventEmitter<{ elem: ElementRef }> = new EventEmitter();

    @HostListener("document:click", ["$event"])
    documentClickHandler(event: MouseEvent) {
        let target = event.target;
        if (
            !this.el.nativeElement.contains(target) &&
            !this.isNestedSelect(target) &&
            !this.isNestedDatepicker(target) &&
            !this.isUniversalSearch(target) &&
            !this.el.nativeElement.isEqualNode(target)
        ) {
            this.onClickOff.emit({ elem: this.el });
        }
    }

    constructor(private el: ElementRef) {
        this.isNestedDatepicker = this.isNestedDatepicker.bind(this);
        this.isNestedSelect = this.isNestedSelect.bind(this);
    }

    // Very ugly but functional way to handle sfSelects because the selection list is always
    // generated outside the control at the <body> level- .
    isNestedSelect(target: any) {
        let id,
            parentSelect = parents(target, "div.sf-select"),
            isNested = false;
        if (parentSelect.length === 1) {
            id = parentSelect[0].id.slice("ms-two-popup-container-".length);
            isNested = !!this.el.nativeElement.querySelector(
                "#ms-two-button-" + id
            );
        }

        return isNested;
    }

    // Same as above but for date-picker popups
    isNestedDatepicker(target: any) {
        let parentSelect = parents(
            target,
            ".uib-datepicker-popup.dropdown-menu"
        );
        return parentSelect.length;
    }

    //For universal search
    isUniversalSearch(target: any) {
        let id: string = target.id;
        return (
            target.classList.contains("expanding-world") ||
            target.classList.contains("fa-ellipsis-h") ||
            id.startsWith("group-expand")
        );
    }
}
