import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-pending-payments-fee-detail-dialog",
    templateUrl: "./pending-payments-fee-detail-dialog.component.html",
    styleUrls: ["./pending-payments-fee-detail-dialog.component.scss"]
})
export class PendingPaymentsFeeDetailDialogComponent implements OnInit {
    @Input() submitterName: string;
    @Input() recipientName: string;
    @Input() packageName: string;
    @Input() date: string;
    @Input() packageID: string;
    @Input() recordingFees: number;
    @Input() salesTax: number;
    @Input() submissionFees: number;
    @Input() tax: number;
    @Input() surchargeAmount: number;
    @Input() surchargePercentage: number;
    @Input() total: number;
    @Input() paymentAccountName: string;
    @Input() hasSubmitterPackageViewerPermission: boolean;
    @Input() mailFee: number;
    @Input() otherFees: number;

    /**Public Variables**/
    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.abort.bind(this)
        };
        if (this.hasSubmitterPackageViewerPermission && this.packageID) {
            this.secondary = {
                text: "View Package Details",
                disabled: false,
                callback: this.viewPackageDetails.bind(this)
            };
        }
        this.tertiary = {
            text: "Print",
            disabled: false,
            callback: PendingPaymentsFeeDetailDialogComponent._print.bind(this)
        };
    }

    abort() {
        this._activeModal.close(true);
    }

    viewPackageDetails() {
        const url: string = this.packageID
            ? `/sf/ui/submitter/package/${this.packageID}/details`
            : "";
        if (url) {
            window.open(url, "_blank");
        }
    }

    private static _print() {
        const printEl = document.createElement("div");

        const css = `* {font-family: "Verdana", sans-serif; font-size: 15px;}
                .pending-details-header, .pending-details-feeDesc {
                    width: 200px;
                }

                .pending-details-amount {
                    width: 150px;
                }

                .pending-details-paymentAccount {
                    width: 300px;
                }

                .pending-details-pb {
                    padding-bottom: 15px;
                }`;
        const style = document.createElement("style");
        style.appendChild(document.createTextNode(css));
        printEl.appendChild(style);

        const dialogContents = document.getElementById("fee-details-dialog");
        printEl.appendChild(dialogContents.cloneNode(true));

        const newWindow = window.open("", "_blank");
        newWindow.document.write(printEl.innerHTML);
        newWindow.document.close();
        newWindow.print();
    }
}
