<sf-modal-header title="Select Enrollment Code"></sf-modal-header>
<div class="modal-body">
    <div class="status inline" *ngIf="isLoading">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
        <span> Loading...</span>
    </div>

    <form name="sfEnrollmentCodeSelectForm">
        <div *ngIf="!isLoading" class="table-wrapper">
            <div>
                <sf-search sf-focus="input" [model]="query" placeholder="Filter" [icon]="['far', 'filter']"
                    (onChange)="doFilter()" (cancelEvent)="initialList()"
                    [enable]="true" [showResults]="false"></sf-search>
            </div>
            <table class="sf-grid table">
                <thead>
                <tr class="header-row">
                    <td class="sortable name-col">
                        <button class="btn btn-link sf-btn-link column-header" (click)="changeSort('templateName')">
                            Name
                            <fa-icon *ngIf="sortKey=='templateName' && sortAscending" icon="sort-amount-down-alt"></fa-icon>
                            <fa-icon *ngIf="sortKey=='templateName' && !sortAscending" icon="sort-amount-up"></fa-icon>
                        </button>
                    </td>
                    <td class="sortable code-col">
                        <button class="btn btn-link sf-btn-link column-header" (click)="changeSort('registrationCode')">
                            Enrollment Code
                            <fa-icon *ngIf="sortKey=='registrationCode' && sortAscending" icon="sort-amount-down-alt"></fa-icon>
                            <fa-icon *ngIf="sortKey=='registrationCode' && !sortAscending" icon="sort-amount-up"></fa-icon>
                        </button>
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let template of filteredTemplates">
                    <td class="truncate limited-length" ngClassOdd="striped-odd">
                        <button class="btn btn-link sf-btn-link"
                            (click)="clickItem(template)">{{template.templateName}}</button>
                    </td>
                    <td>
                        <button class="btn btn-link sf-btn-link"
                            (click)="clickItem(template)">{{template.registrationCode}}</button>
                        <span *ngIf="hasTiers(template)" class="badge tooltip-interaction float-right"
                            tooltip-append-to-body="true"
                            [ngbTooltip]="getTierTooltip(template)" placement="right">Tiered</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="status inline" [hidden]="isLoading || hasData()">
            No Results
        </div>
    </form>
</div>
<sf-modal-footer [secondary]="secondary"></sf-modal-footer>
