import { Component, OnInit } from "@angular/core";
import { GroupedResults, uniqBy } from "@sf/common";
import { GlobalSearchService } from "../global-search.service";
import {
    CapcLoanResult,
    EsignEventResult,
    OrganizationResult,
    PackageResult,
    ResultObjectType,
    UniversalResult,
    UserResult
} from "./universal-search.interfaces";
import { UserOrgService } from "@sf/userorg/common";
import { SearchResults } from "../universal-search/universal-search.component";
import { forkJoin } from "rxjs";
import { Router } from "@angular/router";

@Component({
    selector: "sf-universal-search-new",
    templateUrl: "./universal-search-new.component.html",
    styleUrls: ["./universal-search-new.component.scss"]
})
export class UniversalSearchNewComponent implements OnInit {
    results: GroupedResults<UniversalResult>[];
    isNewPackageAdminLinkEnabled: boolean = false;
    busy: boolean = false;
    showingRecentItems: boolean = true;
    query: string;

    private _resultLimit: number = 15;
    private _maxLimit: number = 30;
    private _foundItems: SearchResults = null;
    private _expandedSearchUsrs: boolean = false;
    private _expandedSearchOrgs: boolean = false;
    private _expandedSearchPkgs: boolean = false;
    private _expandedSearchEevs: boolean = false;
    private _expandedSearchClns: boolean = false;

    constructor(
        private userOrgService: UserOrgService,
        private globalSearchService: GlobalSearchService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isNewPackageAdminLinkEnabled =
            sf.liveConfig.RecipientSettings.useNewPackageAdminLink;

        //initially get recents so something is displayed if user clicks into search box
        this._getRecentItems();
    }

    onQueryChange(query: string): void {
        this.busy = true;
        this.showingRecentItems = false;
        setTimeout(() => {
            this.query = query;
            this._updateResults(!!query);
        }, 200);
    }

    onSearchClick(): void {
        this._updateResults(false); //so recent items get updated
    }

    onExpandResults(group: string): void {
        switch (group) {
            case ResultObjectType.PACKAGE:
                this.globalSearchService
                    .searchPackagesExpanded(this.query, this._maxLimit)
                    .subscribe((result: any) => {
                        this._expandedSearchPkgs = true;
                        if (result.packages && result.packages.length > 0) {
                            if (!this._foundItems.packages)
                                this._foundItems.packages = [];
                            this._foundItems.packages =
                                this._foundItems.packages.concat(
                                    result.packages
                                );
                            if (this._foundItems.packages.length) {
                                this._foundItems.packages = uniqBy(
                                    this._foundItems.packages,
                                    "id"
                                );
                                if (
                                    this._foundItems.packages.length >
                                    this._maxLimit
                                )
                                    this._foundItems.packages.splice(
                                        this._maxLimit
                                    );
                            }

                            this.results = [];
                            setTimeout(() => {
                                this.results = this._convertSearchResults(
                                    this._foundItems
                                );
                            });
                        }
                    });
                break;
            case ResultObjectType.ORGANIZATION:
                this.globalSearchService
                    .searchOrganizationsExpanded(this.query, this._maxLimit)
                    .subscribe((result: any) => {
                        this._expandedSearchOrgs = true;
                        if (
                            result.organizations &&
                            result.organizations.length > 0
                        ) {
                            if (!this._foundItems.organizations)
                                this._foundItems.organizations = [];
                            this._foundItems.organizations =
                                this._foundItems.organizations.concat(
                                    result.organizations
                                );
                            if (this._foundItems.organizations.length) {
                                this._foundItems.organizations = uniqBy(
                                    this._foundItems.organizations,
                                    "id"
                                );
                                if (
                                    this._foundItems.organizations.length >
                                    this._maxLimit
                                )
                                    this._foundItems.organizations.splice(
                                        this._maxLimit
                                    );
                            }

                            this.results = [];
                            setTimeout(() => {
                                this.results = this._convertSearchResults(
                                    this._foundItems
                                );
                            });
                        }
                    });
                break;
            case ResultObjectType.USER:
                this.globalSearchService
                    .searchUsersExpanded(this.query, this._maxLimit)
                    .subscribe((result: any) => {
                        this._expandedSearchUsrs = true;
                        if (result.users && result.users.length > 0) {
                            result.users.forEach((user: any) => {
                                user.objectType = "user";
                                this.userOrgService.buildUserStatus(user);
                            });
                            if (!this._foundItems.users)
                                this._foundItems.users = [];
                            this._foundItems.users =
                                this._foundItems.users.concat(result.users);
                            if (this._foundItems.users.length) {
                                this._foundItems.users = uniqBy(
                                    this._foundItems.users,
                                    "id"
                                );
                                if (
                                    this._foundItems.users.length >
                                    this._maxLimit
                                )
                                    this._foundItems.users.splice(
                                        this._maxLimit
                                    );
                            }

                            this.results = [];
                            setTimeout(() => {
                                this.results = this._convertSearchResults(
                                    this._foundItems
                                );
                            });
                        }
                    });
                break;
            case ResultObjectType.ESIGN_EVENT:
                this.globalSearchService
                    .searchESignEventsExpanded(this.query, this._maxLimit)
                    .subscribe((result: any) => {
                        this._expandedSearchEevs = true;
                        if (
                            result.esignevents &&
                            result.esignevents.length > 0
                        ) {
                            if (!this._foundItems.esignevents)
                                this._foundItems.esignevents = [];
                            this._foundItems.esignevents =
                                this._foundItems.esignevents.concat(
                                    result.esignevents
                                );
                            if (this._foundItems.esignevents.length) {
                                this._foundItems.esignevents = uniqBy(
                                    this._foundItems.esignevents,
                                    "id"
                                );
                                if (
                                    this._foundItems.esignevents.length >
                                    this._maxLimit
                                )
                                    this._foundItems.esignevents.splice(
                                        this._maxLimit
                                    );
                            }

                            this.results = [];
                            setTimeout(() => {
                                this.results = this._convertSearchResults(
                                    this._foundItems
                                );
                            });
                        }
                    });
                break;
            case ResultObjectType.CAPC_LOAN:
                this.globalSearchService
                    .searchCAPCLoansExpanded(this.query, this._maxLimit)
                    .subscribe((result: any) => {
                        this._expandedSearchClns = true;
                        if (result.capcLoans && result.capcLoans.length > 0) {
                            if (!this._foundItems.capcLoans)
                                this._foundItems.capcLoans = [];
                            this._foundItems.capcLoans =
                                this._foundItems.capcLoans.concat(
                                    result.capcLoans
                                );
                            if (this._foundItems.capcLoans.length) {
                                this._foundItems.capcLoans = uniqBy(
                                    this._foundItems.capcLoans,
                                    "id"
                                );
                                if (
                                    this._foundItems.capcLoans.length >
                                    this._maxLimit
                                )
                                    this._foundItems.capcLoans.splice(
                                        this._maxLimit
                                    );
                            }

                            this.results = [];
                            setTimeout(() => {
                                this.results = this._convertSearchResults(
                                    this._foundItems
                                );
                            });
                        }
                    });
                break;
        }
    }

    onGroupSelect(group: string): void {
        switch (group) {
            case ResultObjectType.ORGANIZATION:
                this.router.navigate(["/admin/user-org/organizations"], {
                    queryParams: { queryText: this.query }
                });
                break;
            case ResultObjectType.USER:
                this.router.navigate(["/admin/user-org/users"], {
                    queryParams: { queryText: this.query }
                });
                break;
        }
    }

    private _getRecentItems(): void {
        this.globalSearchService
            .getRecentGlobalSearchItems()
            .then((items: any[]) => {
                items.forEach((item) => {
                    if (item.objectType === ResultObjectType.USER)
                        this.userOrgService.buildUserStatus(item);
                });
                this.results = this._convertRecentItems(items);
                this.showingRecentItems = true;
                this.busy = false;
            });
    }

    private _updateResults(haveQuery: boolean): void {
        if (!haveQuery) {
            this._getRecentItems();
        } else {
            this._searchItems();
        }
    }

    private _convertRecentItems(
        items: string[]
    ): GroupedResults<UniversalResult>[] {
        let recents: GroupedResults<UniversalResult>[] = [
            {
                id: "recents",
                title: "Recently Viewed Items",
                icon: ["far", "search"],
                totalResults: items.length,
                results: []
            }
        ];
        //add items to the list of results for recents
        items.forEach((item: any) => {
            switch (item.objectType) {
                case ResultObjectType.ORGANIZATION:
                    recents[0].results.push(item as OrganizationResult);
                    break;
                case ResultObjectType.PACKAGE:
                    recents[0].results.push(item as PackageResult);
                    break;
                case ResultObjectType.USER:
                    recents[0].results.push(item as UserResult);
                    break;
                case ResultObjectType.ESIGN_EVENT:
                    recents[0].results.push(item as EsignEventResult);
                    break;
                case ResultObjectType.CAPC_LOAN:
                    recents[0].results.push(item as CapcLoanResult);
                    break;
            }
        });
        return recents;
    }

    private _convertSearchResults(
        searchResults: SearchResults
    ): GroupedResults<UniversalResult>[] {
        let results: GroupedResults<UniversalResult>[] = [];

        //get current length for totalResults
        let pkgCount: number = searchResults.packages
            ? searchResults.packages.length
            : 0;
        let usrCount: number = searchResults.users
            ? searchResults.users.length
            : 0;
        let orgCount: number = searchResults.organizations
            ? searchResults.organizations.length
            : 0;
        let eevCount: number = searchResults.esignevents
            ? searchResults.esignevents.length
            : 0;
        let clnCount: number = searchResults.capcLoans
            ? searchResults.capcLoans.length
            : 0;

        //prepare for display
        results.push({
            id: ResultObjectType.PACKAGE,
            title: "Packages",
            icon: "file-alt",
            selectable: true,
            totalResults: pkgCount,
            expandOverride: !this._expandedSearchPkgs,
            results: this._convertItemsTo(
                searchResults.packages,
                ResultObjectType.PACKAGE
            )
        });
        results.push({
            id: ResultObjectType.USER,
            title: "Users",
            icon: "user",
            selectable: true,
            totalResults: usrCount,
            expandOverride: !this._expandedSearchUsrs,
            results: this._convertItemsTo(
                searchResults.users,
                ResultObjectType.USER
            )
        });
        results.push({
            id: ResultObjectType.ORGANIZATION,
            title: "Organizations",
            icon: "building",
            selectable: true,
            totalResults: orgCount,
            expandOverride: !this._expandedSearchOrgs,
            results: this._convertItemsTo(
                searchResults.organizations,
                ResultObjectType.ORGANIZATION
            )
        });
        results.push({
            id: ResultObjectType.ESIGN_EVENT,
            title: "eSign Events",
            icon: "calendar-alt",
            selectable: true,
            totalResults: eevCount,
            expandOverride: !this._expandedSearchEevs,
            results: this._convertItemsTo(
                searchResults.esignevents,
                ResultObjectType.ESIGN_EVENT
            )
        });
        results.push({
            id: ResultObjectType.CAPC_LOAN,
            title: "CAPC Loans",
            icon: "home",
            selectable: true,
            totalResults: clnCount,
            expandOverride: !this._expandedSearchClns,
            results: this._convertItemsTo(
                searchResults.capcLoans,
                ResultObjectType.CAPC_LOAN
            )
        });

        return results;
    }

    private _convertItemsTo(
        items: any[],
        type: ResultObjectType
    ): UniversalResult[] {
        let results: UniversalResult[] = [];
        if (!!items && items.length) {
            items.forEach((item: any) => {
                switch (type) {
                    case ResultObjectType.PACKAGE:
                        results.push(item as PackageResult);
                        break;
                    case ResultObjectType.ORGANIZATION:
                        results.push(item as OrganizationResult);
                        break;
                    case ResultObjectType.USER:
                        results.push(item as UserResult);
                        break;
                    case ResultObjectType.ESIGN_EVENT:
                        results.push(item as EsignEventResult);
                        break;
                    case ResultObjectType.CAPC_LOAN:
                        results.push(item as CapcLoanResult);
                        break;
                }
            });
        }
        return results;
    }

    private _searchItems(): void {
        //reset these flags with each new search
        this._expandedSearchUsrs = false;
        this._expandedSearchOrgs = false;
        this._expandedSearchPkgs = false;
        this._expandedSearchEevs = false;
        this._expandedSearchClns = false;

        this.globalSearchService
            .searchEverything(this.query, this._resultLimit)
            .subscribe((results: SearchResults) => {
                if (results.users) {
                    results.users.forEach((user) => {
                        user.objectType = "user";
                        this.userOrgService.buildUserStatus(user);
                    });
                }

                //set internal property holding the results
                this._foundItems = results;

                //setup for doing any expanded search, if necessary - the calls are joined so the final results can
                //be calculated and converted for display
                let obs: any = [];
                if (!results.packages) {
                    obs.push(
                        this.globalSearchService.searchPackagesExpanded(
                            this.query,
                            this._maxLimit
                        )
                    );
                    this._expandedSearchPkgs = true;
                }
                if (!results.users) {
                    obs.push(
                        this.globalSearchService.searchUsersExpanded(
                            this.query,
                            this._maxLimit
                        )
                    );
                    this._expandedSearchUsrs = true;
                }
                if (!results.organizations) {
                    obs.push(
                        this.globalSearchService.searchOrganizationsExpanded(
                            this.query,
                            this._maxLimit
                        )
                    );
                    this._expandedSearchOrgs = true;
                }
                if (!results.esignevents) {
                    obs.push(
                        this.globalSearchService.searchESignEventsExpanded(
                            this.query,
                            this._maxLimit
                        )
                    );
                    this._expandedSearchEevs = true;
                }
                if (!results.capcLoans) {
                    obs.push(
                        this.globalSearchService.searchCAPCLoansExpanded(
                            this.query,
                            this._maxLimit
                        )
                    );
                    this._expandedSearchClns = true;
                }

                if (obs.length > 0) {
                    const observable = forkJoin(obs);
                    observable.subscribe((results: any[]) => {
                        results.forEach((result) => {
                            if (result.packages && result.packages.length > 0) {
                                if (!this._foundItems.packages)
                                    this._foundItems.packages = [];
                                this._foundItems.packages =
                                    this._foundItems.packages.concat(
                                        result.packages
                                    );
                                if (this._foundItems.packages.length) {
                                    this._foundItems.packages = uniqBy(
                                        this._foundItems.packages,
                                        "id"
                                    );
                                }
                            }
                            if (result.users && result.users.length > 0) {
                                result.users.forEach((user: any) => {
                                    user.objectType = "user";
                                    this.userOrgService.buildUserStatus(user);
                                });
                                if (!this._foundItems.users)
                                    this._foundItems.users = [];
                                this._foundItems.users =
                                    this._foundItems.users.concat(result.users);
                                if (this._foundItems.users.length) {
                                    this._foundItems.users = uniqBy(
                                        this._foundItems.users,
                                        "id"
                                    );
                                }
                            }
                            if (
                                result.organizations &&
                                result.organizations.length > 0
                            ) {
                                if (!this._foundItems.organizations)
                                    this._foundItems.organizations = [];
                                this._foundItems.organizations =
                                    this._foundItems.organizations.concat(
                                        result.organizations
                                    );
                                if (this._foundItems.organizations.length) {
                                    this._foundItems.organizations = uniqBy(
                                        this._foundItems.organizations,
                                        "id"
                                    );
                                }
                            }
                            if (
                                result.esignevents &&
                                result.esignevents.length > 0
                            ) {
                                if (!this._foundItems.esignevents)
                                    this._foundItems.esignevents = [];
                                this._foundItems.esignevents =
                                    this._foundItems.esignevents.concat(
                                        result.esignevents
                                    );
                                if (this._foundItems.esignevents.length) {
                                    this._foundItems.esignevents = uniqBy(
                                        this._foundItems.esignevents,
                                        "id"
                                    );
                                }
                            }
                            if (
                                result.capcLoans &&
                                result.capcLoans.length > 0
                            ) {
                                if (!this._foundItems.capcLoans)
                                    this._foundItems.capcLoans = [];
                                this._foundItems.capcLoans =
                                    this._foundItems.capcLoans.concat(
                                        result.capcLoans
                                    );
                                if (this._foundItems.capcLoans.length) {
                                    this._foundItems.capcLoans = uniqBy(
                                        this._foundItems.capcLoans,
                                        "id"
                                    );
                                }
                            }
                        });

                        this.results = [];
                        //inside the resolution of the forkJoin so ALL the results are converted
                        setTimeout(() => {
                            this.results = this._convertSearchResults(
                                this._foundItems
                            );
                            this.busy = false;
                        });
                    });
                } else {
                    this.results = [];
                    setTimeout(() => {
                        this.results = this._convertSearchResults(
                            this._foundItems
                        );
                        this.busy = false;
                    });
                }
            });
    }
}
