import { Injectable } from "@angular/core";
import { SocketService, SubscriptionBaseService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NotaryStatusSubscriptionService extends SubscriptionBaseService {
    namespace = "notary_status_change";
    product = "esign";

    constructor(protected _socket: SocketService) {
        super(_socket);
    }

    getNotaryStatusUpdate$(userID: string): Observable<any> {
        return this.on("update", userID);
    }
}
