import { Injectable, OnDestroy } from "@angular/core";
import {
    filter,
    map,
    shareReplay,
    takeUntil,
    withLatestFrom
} from "rxjs/operators";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { SessionService } from "@sf/common";
import { StatesService } from "@sf/common";
import { UiRecipient } from "../../../interfaces/ui-recipient";
import { RecipientSelectorGroup } from "../common/recipient-selector-group";
import { RecipientSelectorData } from "../common/recipient-selector-data";
import { Submitter } from "@sf/userorg/common";
import { SubmitterRecipientService } from "../../../services/submitter-recipient.service";

@Injectable()
export class SubmitterRecipientSelectorService implements OnDestroy {
    constructor(
        private _sessionService: SessionService,
        private _statesService: StatesService,
        private _submitterRecipientService: SubmitterRecipientService
    ) {
        // Process recipients list
        this._recipientsState$
            .pipe(
                filter((recipients) => recipients?.length > 0),

                // Add grouping and state values and expanded name
                map((recipients) => {
                    // Determine if a customer id has already been entered for another of the "mobilis" vendor recipients
                    let customerId: string = null,
                        mobilis = recipients.filter(
                            (r) => r.mobilisOneClick && r.registeredBySubmitter
                        );
                    if (mobilis.length && mobilis[0].customerID) {
                        customerId = mobilis[0].customerID;
                    }

                    return recipients.map((r: UiRecipient, i) => {
                        let recentSortOrder = this._recentRecipients.find(
                            (rec) => rec.id === r.id
                        )?.recentSortOrder;
                        return SubmitterRecipientSelectorService._retrieveStateValues(
                            this._statesObj
                        )(
                            SubmitterRecipientSelectorService._assignToGroup(
                                customerId,
                                recentSortOrder,
                                this._allowPaper
                            )(r)
                        );
                    });
                }),
                map((recipients) => {
                    return recipients.map((recipient) => ({
                        ...recipient,
                        name: recipient.enabled
                            ? recipient.name
                            : `${recipient.name} (Disabled)`
                    }));
                }),
                takeUntil(this._destroy$)
            )
            .subscribe((recipients) => {
                this._processedRecipients.next(recipients);

                // Reset filter
                this._filterValue.next(null);
            });
    }

    private static _groupSortOrder: any = {
        Recent: 1,
        Matching: 2,
        "Requires Registration": 3,
        "Registration Pending": 4
    };
    private static _defaultReplaceRegex = new RegExp(/[()]/g);

    private _orgId: string;
    private _recentRecipients: UiRecipient[] = [];
    private _allowPaper = false;

    private _recipientsStore: Subject<UiRecipient[]> = new Subject();
    private _recipientsState$: Observable<UiRecipient[]> =
        this._recipientsStore.asObservable();

    private _filterValue: BehaviorSubject<string> = new BehaviorSubject(null);
    private _processedRecipients: BehaviorSubject<UiRecipient[]> =
        new BehaviorSubject([]);
    private _selected: BehaviorSubject<string> = new BehaviorSubject(null);
    private _destroy$: Subject<void> = new Subject();

    private _statesObj = this._statesService.getAllStatesAndTerritories();
    private _stateSearchRegex = new RegExp(/^[a-zA-Z]{2} $/);

    public recipients$: Observable<UiRecipient[]> = combineLatest(
        this._filterValue,
        this._selected
    ).pipe(
        withLatestFrom(this._processedRecipients),
        // Apply filter, if any
        map(
            ([[filterValue, selected], recipients]: [
                [string, string],
                UiRecipient[]
            ]): UiRecipient[] => {
                let result: UiRecipient[];
                if (filterValue && filterValue.length > 1) {
                    // Filter recipients
                    let filterMethod =
                        SubmitterRecipientSelectorService._defaultFilter;
                    if (
                        filterValue.length >= 3 &&
                        this._stateSearchRegex.test(filterValue)
                    ) {
                        // Return recipients filtered by state abbreviation
                        filterMethod =
                            SubmitterRecipientSelectorService._stateAbbreviationFilter;
                    }

                    result = recipients.filter(
                        filterMethod(filterValue.trim().toUpperCase())
                    );
                } else {
                    // Return only recent recipients
                    result = recipients.filter(
                        (r: UiRecipient) =>
                            r.group === RecipientSelectorGroup.RECENT
                    );
                }

                // Append selected recipient
                if (
                    selected &&
                    selected.length > 0 &&
                    result.findIndex((r: UiRecipient) => r.id === selected) ===
                        -1
                ) {
                    const selectedRecipient = recipients.find(
                        (r: UiRecipient) => r.id === selected
                    );
                    if (selectedRecipient) {
                        result.push(selectedRecipient);
                    }
                }
                result.sort(SubmitterRecipientSelectorService._sortRecipients);
                return result;
            }
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
    ) as Observable<UiRecipient[]>;

    private static _assignToGroup =
        (customerId: string, recentSortOrder: number, allowPaper: boolean) =>
        (r: UiRecipient) => {
            let groupedRecipient: UiRecipient = { ...r };
            let group: string;
            if (!groupedRecipient.group) {
                if (recentSortOrder > -1) {
                    group = RecipientSelectorGroup.RECENT as string;
                    groupedRecipient.groupSortOrder =
                        SubmitterRecipientSelectorService._groupSortOrder[
                            RecipientSelectorGroup.RECENT
                        ];
                    groupedRecipient.recentSortOrder = recentSortOrder;
                } else if (
                    groupedRecipient.registrationPending &&
                    (!groupedRecipient.mobilisOneClick || !customerId)
                ) {
                    group =
                        RecipientSelectorGroup.REGISTRATION_PENDING as string;
                } else if (
                    groupedRecipient.requiresRegistration &&
                    !groupedRecipient.registeredBySubmitter &&
                    (!groupedRecipient.mobilisOneClick || !customerId)
                ) {
                    group =
                        RecipientSelectorGroup.REQUIRES_REGISTRATION as string;
                } else {
                    group = RecipientSelectorGroup.MATCHING as string;
                }
                groupedRecipient.group = group;
                groupedRecipient.groupSortOrder =
                    SubmitterRecipientSelectorService._groupSortOrder[
                        groupedRecipient.group
                    ];
                groupedRecipient.notAllowed =
                    group === RecipientSelectorGroup.REGISTRATION_PENDING &&
                    !allowPaper;
            }
            return groupedRecipient;
        };

    private static _retrieveStateValues =
        (statesObj: any) => (r: UiRecipient) => {
            let abbrev = r.id.slice(0, 2),
                o = statesObj.find((x: any) => x.abbrev === abbrev);

            if (o && !r.stateAbbrev && !r.stateName) {
                r.stateAbbrev = abbrev;
                r.stateName = o.name;
                r.name = r.name + ", " + abbrev;
            }
            return r;
        };

    private static _sortRecipients(a: UiRecipient, b: UiRecipient): number {
        return (
            a.groupSortOrder - b.groupSortOrder ||
            a.recentSortOrder - b.recentSortOrder
        );
    }

    private static _stateAbbreviationFilter =
        (abbrev: string) => (r: UiRecipient) => {
            return r.stateAbbrev === abbrev;
        };

    private static _defaultFilter =
        (searchValue: string) => (r: UiRecipient) => {
            let words = r.name
                .split(" ")
                .concat(r.stateName?.split(" "))
                .filter((r) => !!r);

            // allows for searching using either query: '(Jonesboro)' or 'Jonesboro'
            words.forEach((word) => {
                word.replace(
                    SubmitterRecipientSelectorService._defaultReplaceRegex,
                    ""
                );
            });

            return searchValue.split(" ").every((queryWord) => {
                return words.some((word) => {
                    return word.toUpperCase().startsWith(queryWord);
                });
            });
        };

    set allowPaper(allowPaper: boolean) {
        this._allowPaper = allowPaper;
    }

    set recipientsData(recipientsData: RecipientSelectorData) {
        this._orgId = recipientsData.orgId;
        this._recentRecipients = this._addRecentRecipientSort(
            recipientsData.recentRecipients
        );
        this._recipientsStore.next(recipientsData.recipients);
    }

    set filterValue(value: string) {
        this._filterValue.next(value);
    }

    getSelected(): string {
        return this._selected.value;
    }

    setSelected(id: string) {
        if (id) {
            this._selected.next(id);
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public getRecipientRegistrationConfiguration(recipientId: string): {
        orgId: string;
        countyId: string;
        state: string;
    } {
        const recipient = this._processedRecipients
            .getValue()
            .find((r) => r.id === recipientId);

        return {
            orgId: this._orgId,
            countyId: recipient.id,
            state: this._statesService
                .getAllStatesPlusDC()
                .find((s) => s.abbrev === recipient.stateAbbrev).name
        };
    }

    private _addRecentRecipientSort(recents: UiRecipient[]): UiRecipient[] {
        return recents.map((r, i) => ({
            ...r,
            recentSortOrder: recents.length - i
        }));
    }
}
