import { Injectable } from "@angular/core";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable({
    providedIn: "root"
})
export class LoggerService {
    static loggers: any = {};

    // used to keep track of which instance of log this instance of LoggerService is using
    private _currentLogger: any;

    // IMPORTANT: don't place log in the constructor, angular doesn't like this particular global variable to be initialized too soon
    constructor() {}

    info(message: string, ...meta: any[]): this {
        this._getCurrentLogger()?.info(message, meta);

        return this;
    }

    debug(message: any, ...meta: any[]): this {
        this._getCurrentLogger().debug(message, meta);

        return this;
    }

    notice(message: any, ...meta: any[]): this {
        this._getCurrentLogger().notice(message, meta);

        return this;
    }

    warning(message: any, ...meta: any[]): this {
        this._getCurrentLogger().warning(message, meta);

        return this;
    }

    error(message: any, ...meta: any[]): this {
        this._getCurrentLogger().error(message, meta);

        return this;
    }

    crit(message: any, ...meta: any[]): this {
        this._getCurrentLogger().crit(message, meta);

        return this;
    }

    alert(message: any, ...meta: any[]): this {
        this._getCurrentLogger().alert(message, meta);

        return this;
    }

    emerg(message: any, ...meta: any[]): this {
        this._getCurrentLogger().emerg(message, meta);

        return this;
    }

    setLogger(name: LoggerType) {
        this._currentLogger = name ? log.getLogger(name) : log;
    }

    private _getCurrentLogger() {
        return this._currentLogger ? this._currentLogger : log;
    }

    getLogger(loggerName: LoggerType) {
        if (!LoggerService.loggers) {
            LoggerService.loggers = {};
        }
        if (!LoggerService.loggers[loggerName]) {
            LoggerService.loggers[loggerName] = new LoggerService();
            LoggerService.loggers[loggerName].setLogger(loggerName);
        }
        return LoggerService.loggers[loggerName];
    }
}
