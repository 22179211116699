import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    BaseModalComponent,
    isEmpty,
    isEqualStrings,
    SelectableItem,
    SelectableItemWithID,
    SfValidators,
    SortUtilitiesService,
    SpinnerService
} from "@sf/common";
import { ActiveService, SessionService } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    Address,
    AddressVerificationService,
    ContractFee,
    ContractService,
    Name,
    Organization,
    Phone,
    TokenService,
    User,
    UserOrgService
} from "@sf/userorg/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationModalComponent, ModalButton } from "@sf/common";
import { GrowlService } from "@sf/common";
import { SelectableState } from "@sf/common";
import { OrganizationFilterSelectorComponent } from "../../components/organization-filter-selector/organization-filter-selector.component";
import { first } from "rxjs/operators";

interface OrganizationCreateContact {
    email: string;
    name: Name;
    phone: Phone;
    type: string;
}

export interface OrganizationCreateData {
    basicInfo: {
        name: string; // preferred name
        legalName: string;
        fax?: Phone;
        phone: Phone;
        timezone?: string;
        sample: boolean;
        enabled: boolean;
        address: Address;
    };
    primaryContact: OrganizationCreateContact;
    parentID?: string;
    businessTypes: string[];
    initialService: string; // if creating a new org

    // The following only apply if cloning an existing org
    baseOrgID?: string;
    includeSubmitter: boolean;
    includeDocBuilder: boolean;
    includeDocBuilderRon: boolean;
    includeLienRelease: boolean;
    includeSubscription: boolean;
    includeTrustee: boolean;
    includePaper: boolean;
    includeSettlement: boolean;
    includeLender: boolean;
    includeEsignEvent: boolean;
}

interface SelectableFatUser extends SelectableItemWithID {
    name: Name;
    phone: Phone;
    email: string;
}

// prettier-ignore
@Component({
    selector: "sf-create-organization-dialog",
    templateUrl: "./create-organization-dialog.component.html",
    styleUrls: ["./create-organization-dialog.component.scss"]
})
export class CreateOrganizationDialogComponent extends BaseModalComponent implements OnInit, AfterViewInit
{
    @Input() modalTitle: string; // dialog title
    @Input() initialData?: Organization; // optional defaults for data in new organization
    @Input() actionLabel?: string; // optional text in 'primary' button in dialog, defaults to 'Create'
    @Input() creatingNotCloning: boolean; // if creating a new org as superuser
    @Input() defaultParentID: string; // optional default parent organization for the new organization
    @Input() organizationSelector: any; // used when superuser
    //@Input() baseOrgOptions: SelectableOrganization[];

    defaultSubmitterFees: any = null;
    defaultDocBuilderFees: any = null;
    defaultDocBuilderRonFees: any = null;
    defaultLienReleaseFees: any = null;
    defaultSubscriptionFees: any = null;
    defaultTrusteeFees: any = null;
    defaultPaperFees: any = null;
    defaultSettlementFees: any = null;
    defaultLenderFees: any = null;
    defaultEsignEventFees: any = null;

    tabCount = 4;
    emptyParent: any;
    showCheckbox = false;
    showRadios = false;

    presentSubmitterFees = false;
    presentDocBuilderFees = false;
    presentDocBuilderRonFees = false;
    presentLienReleaseFees = false;
    presentSubscriptionFees = false;
    presentTrusteeFees = false;
    presentPaperFees = false;
    presentSettlementFees = false;
    presentLenderFees = false;
    presentEsignEventFees = false;

    submitterLicenseFee: number = null;
    submitterRenewalFee: number = null;
    submitterSubmissionFee: number = null;
    docBuilderLicenseFee: number = null;
    docBuilderRenewalFee: number = null;
    docBuilderDocumentFee: number = null;
    trusteeDocumentFee: number = null;
    lienReleaseFee: number = null;
    subscriptionMinumum: number = null;
    //paperLicenseFee: number = null;
    //paperRenewalFee: number = null;
    paperSubmissionFee: number = null;
    inStateErecordFee: number = null;
    outOfStateErecordFee: number = null;
    inStatePaperFee: number = null;
    outOfStatePaperFee: number = null;
    paperMailFee: number = null;
    embeddedEmployeeFee: number = null;
    settlementRenewalFee: number = null;
    settlementLoanFee: number = null;
    settlementDisbursementFee: number = null;
    settlementInsecureFee: number = null;
    settlementRevShareFee: number = null;
    lenderRenewalFee: number = null;
    lenderLoanFee: number = null;
    esignEventLicenseFee: number = null;
    esignEventRenewalFee: number = null;
    esignEventNotarizationFee: number = null;
    esignEventIpenFee: number = null;
    esignEventRonFee: number = null;
    docBuilderRonFee: number = null;

    organization: OrganizationCreateData;
    baseOrgServices: ActiveService[] = null;
    userOptions: SelectableFatUser[];
    serviceOptions: SelectableItem[];

    currentIndex = 0;
    warning = "";
    primary: ModalButton;
    secondary: ModalButton;
    nextButton: ModalButton;
    backButton: ModalButton;

    invalidAddressMessage: string = null;
    verifiedAddress: any = null;

    businessTypes: SelectableItemWithID[];
    businessTypesPristine = true;
    parentFilterSelectorInstance: OrganizationFilterSelectorComponent = null;
    parentPristine = true;

    organizationForm: FormGroup;
    addressForm: FormGroup;
    contactForm: FormGroup;
    feesForm: FormGroup;

    @ViewChild("parentSelector", { read: ViewContainerRef })
    parentSelector: ViewContainerRef;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private userorgService: UserOrgService,
        private sessionService: SessionService,
        private spinnerService: SpinnerService,
        private contractService: ContractService,
        private growlService: GrowlService,
        private tokenService: TokenService,
        private addressVerificationService: AddressVerificationService,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        super();
        this.organizationForm = formBuilder.group({
            name: ["", [Validators.required, SfValidators.notAllWhitespace]],
            legalName: ["", [Validators.required, SfValidators.notAllWhitespace]],
            phone: ["", [Validators.required, SfValidators.phoneValidator]],
            extension: ["", [Validators.maxLength(9), SfValidators.numericValidator]]
        });
        this.addressForm = formBuilder.group({
            street: ["", [Validators.required, SfValidators.notAllWhitespace]],
            street2: [""],
            city: ["", Validators.required],
            zipCode: ["", [Validators.required, SfValidators.zipCodeValidator]],
            checkAddress: [false],
            whichAddress: [""]
        });
        this.contactForm = formBuilder.group({
            firstName: ["", [Validators.required, SfValidators.notAllWhitespace]],
            middleName: [""],
            lastName: ["", [Validators.required, SfValidators.notAllWhitespace]],
            title: ["", [Validators.required, SfValidators.notAllWhitespace]],
            phone: ["", [Validators.required, SfValidators.phoneValidator]],
            extension: ["", [Validators.maxLength(9), SfValidators.numericValidator]],
            email: ["", [Validators.required, SfValidators.emailValidator]]
        });
    }

    ngOnInit() {
        if (this.defaultParentID) {
            this.organization.parentID = this.defaultParentID;
        }

        this.serviceOptions = [
            {
                option: "NONE",
                label: "No Initial Service"
            }, {
                option: "ERECORD",
                label: "eRecording"
            }, {
                option: "SETTLEMENT",
                label: "Settlement"
            }, {
                option: "LENDER",
                label: "Lender"
            }, {
                option: "NOTARY",
                label: "Notary"
            }, {
                option: "VENDOR",
                label: "Vendor"
            }
        ];

        this.setInitialData();

        this.businessTypes = this.userorgService.getSelectableBusinessTypes();

        if (!this.actionLabel) {
            this.actionLabel = "Create";
        }
        this.emptyParent = {
            id: "none",
            option: "none",
            name: "No Parent",
            label: "No Parent (none)",
            indentLevel: 0
        };

        let isSuperUser = this.sessionService.isSuperUser();
        if (!isSuperUser && this.creatingNotCloning) {
            this.growlService.error("Invalid options.");
            return;
        }

        if (this.creatingNotCloning) {
            // default to 'sample' org if creating by superuser
            this.organization.basicInfo.sample = true;
        }

        this.catchFormChanges();

        this.primary = {
            text: this.actionLabel,
            disabled: false,
            callback: this.resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.close.bind(this)
        };
        this.nextButton = {
            text: "Next",
            hidden: false,
            callback: this.goNext.bind(this)
        };
        this.backButton = {
            text: "Back",
            hidden: false,
            callback: this.goBack.bind(this)
        };
        this.enableDisable();
    }

    ngAfterViewInit() {
        this.initParentSelector();
    }

    initParentSelector() {
        // the timeout should not be necessary here, but it is - to avoid 'expression changed after ...' error
        setTimeout(() => {
            if (this.creatingNotCloning) {
                // load the 'search' version of organization selector
                let searchSelector = this.organizationSelector;
                let parentSelectorRef = this.parentSelector.createComponent(searchSelector);
                let instance = parentSelectorRef.instance as OrganizationFilterSelectorComponent;   // FAKE
                instance.windowClass = "org-selector";
                instance.placeholder = "Select an organization (optional)...";
                instance.select = new EventEmitter<any>();
                instance.select.subscribe((event: any) => {
                    this.parentOrgSelected(event);
                });
                instance.selectedOrgID = this.organization.parentID;
                instance.includeNoneOption = this.emptyParent;
                instance.canClearSelectedOrg = true;
            } else {
                // load the 'filter' version of organization selector
                let filterSelector = this.componentFactoryResolver.resolveComponentFactory(
                        OrganizationFilterSelectorComponent);
                let parentSelectorRef = this.parentSelector.createComponent(filterSelector);
                let instance = parentSelectorRef.instance as OrganizationFilterSelectorComponent;
                instance.windowClass = "org-selector";
                instance.selectableOrgPermissions=['organization_admin'];
                instance.placeholder = "Select an organization (optional)...";
                instance.select = new EventEmitter<any>();
                instance.select.subscribe((event: any) => {
                    this.parentOrgSelected(event);
                });
                instance.selectedOrgID = this.organization.parentID;
                instance.includeNoneOption = this.emptyParent;
                instance.canClearSelectedOrg = true;
                this.parentFilterSelectorInstance = instance;
            }
        });
    }

    setInitialData() {
        if (this.initialData) {
            this.organization = {
                basicInfo: {
                    legalName: this.initialData.legalName || "",
                    name: this.initialData.name || "",
                    fax: this.initialData.fax,
                    phone: this.initialData.phone,
                    timezone: this.initialData.timezone || "",
                    sample: this.initialData.sample || false,
                    enabled: this.initialData.enabled || true,
                    address: {
                        streets: this.initialData.address.streets ? this.initialData.address.streets : ["", ""],
                        city: this.initialData.address.city ? this.initialData.address.city : "",
                        state: this.initialData.address.state ? this.initialData.address.state : "",
                        zipCode: this.initialData.address.zipCode ? this.initialData.address.zipCode : ""
                    }
                },
                primaryContact: {
                    email: this.initialData.contact.email,
                    name: this.initialData.contact.name,
                    phone: this.initialData.contact.phone,
                    type: "primary"
                },
                initialService: "NONE",
                baseOrgID: null,
                parentID: this.defaultParentID,
                includeSubmitter: false,
                includeDocBuilder: false,
                includeDocBuilderRon: false,
                includeLienRelease: false,
                includeSubscription: false,
                includeTrustee: false,
                includePaper: false,
                includeSettlement: false,
                includeLender: false,
                includeEsignEvent: false,
                businessTypes: []
            };
            // patch the data into the form
            this.organizationForm.patchValue({
                legalName: this.organization.basicInfo.legalName,
                name: this.organization.basicInfo.name,
                phone: this.organization.basicInfo.phone
            });
            this.addressForm.patchValue({
                street: this.organization.basicInfo.address.streets[0],
                street2: this.organization.basicInfo.address.streets[1],
                city: this.organization.basicInfo.address.city,
                zipCode: this.organization.basicInfo.address.zipCode
            });
            this.contactForm.patchValue({
                firstName: this.organization.primaryContact.name.first,
                middleName: this.organization.primaryContact.name.middle,
                lastName: this.organization.primaryContact.name.last,
                title: this.organization.primaryContact.name.title,
                phone: this.phoneToString(this.organization.primaryContact.phone),
                extension: this.organization.primaryContact.phone.extension,
                email: this.organization.primaryContact.email
            });
        } else {
            this.organization = {
                basicInfo: {
                    legalName: "",
                    name: "",
                    phone: {
                        areaCode: "",
                        prefix: "",
                        suffix: ""
                    },
                    timezone: "",
                    sample: false,
                    enabled: true,
                    address: {
                        streets: ["", ""],
                        city: "",
                        state: "",
                        zipCode: ""
                    }
                },
                primaryContact: {
                    email: "",
                    name: {
                        first: "",
                        last: "",
                        middle: "",
                        suffix: "",
                        title: ""
                    },
                    phone: {
                        areaCode: "",
                        prefix: "",
                        suffix: "",
                        extension: ""
                    },
                    type: "primary"
                },
                initialService: "NONE",
                baseOrgID: null,
                parentID: this.defaultParentID,
                includeSubmitter: false,
                includeDocBuilder: false,
                includeDocBuilderRon: false,
                includeLienRelease: false,
                includeSubscription: false,
                includeTrustee: false,
                includePaper: false,
                includeSettlement: false,
                includeLender: false,
                includeEsignEvent: false,
                businessTypes: []
            };
        }
    }

    enableDisable() {
        this.primary.disabled = this.currentIndex < this.tabCount - 1;
        this.nextButton.hidden = this.currentIndex == this.tabCount - 1;
        this.backButton.hidden = this.currentIndex == 0;
    }

    close(response: OrganizationCreateData) {
        if (!response && this.currentIndex > 2) {
            const modal = this.modalService.open(ConfirmationModalComponent);
            const modalInstance = modal.componentInstance;

            modalInstance.title = "Confirm Cancel";
            modalInstance.primary = {
                text: "Yes",
                responseValue: true,
                callback: () => {
                    modal.close();
                    this.activeModal.close(response);
                }
            };
            modalInstance.secondary = {
                text: "No",
                responseValue: false,
                callback: () => {
                    modal.close();
                }
            };
            modalInstance.message = "Do you really want to cancel?";
        } else {
            this.activeModal.close(response);
        }
    }

    goBack() {
        this.warning = null;
        this.goToStep(this.currentIndex - 1);
    }

    goNext() {
        let okToGo = false;
        this.warning = this.validateCurrentPage();
        if (!this.warning) {
            // if no warning, passed validation
            // if on address step, need to do address validation
            if (this.currentIndex == 1) {
                let whichAddress = this.addressForm.controls.whichAddress.value;
                let checkAddress = this.addressForm.controls.checkAddress.value;
                if (checkAddress || whichAddress) {
                    //user acknowledges as correct, or take as it was entered
                    this.invalidAddressMessage = null;
                    if (this.showCheckbox && checkAddress) {
                        okToGo = true;
                    } else if (this.showRadios) {
                        if (whichAddress == "recommended") {
                            this.organization.basicInfo.address.streets[0] = this.verifiedAddress.street1;
                            this.organization.basicInfo.address.streets[1] = this.verifiedAddress.street2;
                            this.organization.basicInfo.address.city = this.verifiedAddress.city;
                            this.organization.basicInfo.address.state = this.verifiedAddress.state;
                            this.organization.basicInfo.address.zipCode = this.verifiedAddress.zip;
                            /*
                            if (this.verifiedAddress.fips) {
                                this.organization.basicInfo.address.county = this.verifiedAddress.fips;
                            }
                            */
                            this.organization.basicInfo.timezone = this.verifiedAddress.timeZone;

                            this.addressForm.patchValue({
                                street: this.verifiedAddress.street1 +
                                        (this.verifiedAddress.secondary ? " " + this.verifiedAddress.secondary : ""),
                                street2: this.verifiedAddress.street2,
                                city: this.verifiedAddress.city, //state: this.verifiedAddress.state,
                                zipCode: this.verifiedAddress.zip
                            });

                            okToGo = true;
                        } else if (whichAddress == "entered") {
                            okToGo = true;
                        }
                    }
                } else {
                    this.spinnerService.startSpinner();
                    this.verifyAddress().then((valid: boolean) => {
                        this.spinnerService.stopSpinner();
                        if (valid) {
                            this.goToStep(2);
                        }
                    }, (/*error*/) => {
                        this.spinnerService.stopSpinner();
                    });
                }
            } else {
                okToGo = true;
            }
        }

        if (okToGo) {
            this.goToStep(this.currentIndex + 1);
        }
    }

    goToStep(index: number) {
        // excess checking here
        if (index >= this.tabCount) {
            index = this.tabCount - 1;
        }
        if (index < 0) {
            index = 0;
        }
        if (index == this.currentIndex) {
            return;
        }

        if (this.currentIndex == 3) {
            // handle 'back' from fees page
        }

        this.currentIndex = index;

        // set focus and page init
        switch (index) {
            case 0:
                this.initParentSelector();
                this.focusElement("prefName");
                break;
            case 1:
                this.focusElement("orgStreet1");
                break;
            case 2:
                this.focusElement("contactFirst");
                this.getOrgUsers();
                break;
            case 3:
                this.getDefaultSubmitterFees();
                this.getDefaultDocBuilderFees();
                this.getDefaultDocBuilderRonFees();
                this.getDefaultLienReleaseFees();
                this.getDefaultSubscriptionFees();
                this.getDefaultTrusteeFees();
                this.getDefaultPaperFees();
                this.getDefaultSettlementFees();
                this.getDefaultLenderFees();
                this.getDefaultEsignEventFees();
                break;
            default:
                break;
        }

        this.enableDisable();
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    validateCurrentPage(): string {
        let form;
        let inputValue: string;
        let charName: string;

        if (this.currentIndex == 0) {
            form = this.organizationForm;

            // initial service -----------
            if (this.creatingNotCloning && !this.organization.initialService) {
                return "Select the initial service";
            }

            // based-on org -----------
            if (!this.creatingNotCloning && !this.organization.baseOrgID) {
                return "Please select a 'Based on' organization";
            }

            // organization name -----------
            inputValue = form.controls.legalName.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.basicInfo.legalName = inputValue;
            if (!inputValue) {
                return "Please enter an Organization Legal Name";
            }
            charName = SfValidators.hasInvalidNameCharacters(this.organization.basicInfo.legalName);
            if (charName) {
                return ("Organization Legal Name contains an invalid " + charName + " character");
            }

            inputValue = form.controls.name.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.basicInfo.name = inputValue;
            if (!inputValue) {
                return "Please enter an Organization Preferred Name";
            }
            if (this.initialData && this.initialData.name) {
                // for 'create based on' case
                if (inputValue == this.initialData.name.trim()) {
                    return "Enter a unique organization preferred name";
                }
            }
            charName = SfValidators.hasInvalidNameCharacters(this.organization.basicInfo.name);
            if (charName) {
                return ("Organization Preferred Name contains an invalid " + charName + " character");
            }

            // phone number and extension -----------
            let prePhone: string = form.controls.phone.value;
            let extension: string = form.controls.extension.value;

            let phone = SfValidators.getNumericDigits(prePhone);
            if (!phone) {
                return "Please enter a phone number";
            }
            if (prePhone && phone.length != 10) {
                return "Phone Number must be 10 digits long";
            }
            if (extension && !SfValidators.NUMERIC_REGEXP.test(extension)) {
                return "Extension (optional) must be all numbers";
            }
            if (extension && extension.length > 9) {
                return "Extension cannot be longer than 9 digits";
            }

            if (phone) {
                this.organization.basicInfo.phone = this.stringToPhone(phone);
            }
            if (extension) {
                this.organization.basicInfo.phone.extension = extension;
            }

            // business type -----------
            if (!this.organization.businessTypes || this.organization.businessTypes.length === 0) {
                return "Please select a business type";
            }
        } else if (this.currentIndex == 1) {
            form = this.addressForm;

            // street address -----------
            inputValue = form.controls.street.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.basicInfo.address.streets[0] = inputValue;
            if (!inputValue) {
                return "Please enter a Street Address";
            }
            charName = SfValidators.hasInvalidNameCharacters(this.organization.basicInfo.address.streets[0]);
            if (charName) {
                return ("Street Address contains an invalid " + charName + " character");
            }

            this.organization.basicInfo.address.streets[1] = form.controls.street2.value;
            charName = SfValidators.hasInvalidNameCharacters(this.organization.basicInfo.address.streets[1]);
            if (charName) {
                return ("Street Address 2 contains an invalid " + charName + " character");
            }

            // city -----------
            inputValue = form.controls.city.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.basicInfo.address.city = inputValue;
            if (!inputValue) {
                return "Please enter a City";
            }
            charName = SfValidators.hasInvalidNameCharacters(this.organization.basicInfo.address.city);
            if (charName) {
                return ("City contains an invalid " + charName + " character");
            }

            // state -----------
            if (!this.organization.basicInfo.address.state) {
                return "Please select a State";
            }

            // zip code -----------
            this.organization.basicInfo.address.zipCode = form.controls.zipCode.value;
            if (!this.organization.basicInfo.address.zipCode) {
                return "Please enter a ZIP Code";
            }
            if (!SfValidators.ZIPCODE_REGEX.test(this.organization.basicInfo.address.zipCode)) {
                return "ZIP Code is invalid";
            }
        } else if (this.currentIndex == 2) {
            form = this.contactForm;

            // name -----------
            inputValue = form.controls.firstName.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.primaryContact.name.first = inputValue;
            if (!inputValue) {
                return "First Name is missing";
            }

            inputValue = form.controls.lastName.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.primaryContact.name.last = inputValue;
            if (!inputValue) {
                return "Last Name is missing";
            }

            // title -----------
            inputValue = form.controls.title.value;
            if (inputValue) {
                inputValue = inputValue.trim();
            }
            this.organization.primaryContact.name.title = inputValue;
            if (!inputValue) {
                return "Title is missing";
            }

            // email -----------
            let email = form.controls.email.value;
            this.organization.primaryContact.email = email;
            if (!email) {
                return "Email is missing or invalid";
            }
            if (!this.userorgService.validateEmail(email)) {
                return "Email is invalid";
            }

            // phone -----------
            let prePhone: string = form.controls.phone.value;
            let extension: string = form.controls.extension.value;

            let phone = SfValidators.getNumericDigits(prePhone);
            if (!phone) {
                return "Please enter a phone number";
            }
            if (prePhone && phone.length != 10) {
                return "Phone Number must be 10 digits long";
            }
            if (extension && !SfValidators.NUMERIC_REGEXP.test(extension)) {
                return "Extension (optional) must be all numbers";
            }
            if (extension && extension.length > 9) {
                return "Extension cannot be longer than 9 digits";
            }

            if (phone) {
                this.organization.primaryContact.phone = this.stringToPhone(phone);
            }
            if (extension) {
                this.organization.primaryContact.phone.extension = extension;
            }
        }
        return null;
    }

    stringToPhone(phoneString: string): Phone {
        let phone: Phone = {
            areaCode: phoneString.substring(0, 3),
            prefix: phoneString.substring(3, 6),
            suffix: phoneString.substring(6, 10)
        };
        return phone;
    }

    phoneToString(phone: Phone): string {
        if (!phone) {
            return "";
        }
        let one = phone.areaCode + "-" + phone.prefix + "-" + phone.suffix;
        return one;
    }

    verifyAddress() {
        this.showCheckbox = false;
        this.showRadios = false;
        this.invalidAddressMessage = null;
        this.verifiedAddress = null;
        let deferred = new Promise((resolve, reject) => {
            let address = this.organization.basicInfo.address;
            if (address && address.streets && address.streets.length >= 1 && address.city && address.state &&
                    address.zipCode) {
                let inputAddress = {
                    street: this.organization.basicInfo.address.streets[0],
                    street2: this.organization.basicInfo.address.streets[1],
                    city: this.organization.basicInfo.address.city,
                    state: this.organization.basicInfo.address.state, // should just be a state abbreviation
                    zipCode: this.organization.basicInfo.address.zipCode
                };
                if (this.addressVerificationService.isPOBox(inputAddress)) {
                    this.invalidAddressMessage = "Please enter your entity's physical address, not a post office box.";
                    this.showRadios = false;
                    resolve(false);
                    return;
                }
                this.addressVerificationService
                    .verifyAddress(inputAddress)
                    .pipe(first())
                    .subscribe((verifiedAddress: any) => {
                        if (verifiedAddress) {
                            //if the same, accept it
                            if (isEqualStrings(verifiedAddress.street1, this.organization.basicInfo.address.streets[0]) &&
                                    (isEmpty(verifiedAddress.street2) || isEqualStrings(verifiedAddress.street2,
                                            this.organization.basicInfo.address.streets[1])) &&
                                    isEqualStrings(verifiedAddress.city, this.organization.basicInfo.address.city) &&
                                    isEqualStrings(verifiedAddress.state, this.organization.basicInfo.address.state) &&
                                    isEqualStrings(verifiedAddress.zip, this.organization.basicInfo.address.zipCode)) {
                                this.organization.basicInfo.timezone = verifiedAddress.timeZone;
                                resolve(true);
                            } else {
                                if (this.addressVerificationService.isPOBox(verifiedAddress)) {
                                    this.invalidAddressMessage =
                                            "Please enter your entity's physical address, not a post office box.";
                                    this.showRadios = false;
                                    resolve(false);
                                } else {
                                    this.verifiedAddress = verifiedAddress;
                                    this.invalidAddressMessage = "Address validation recommends this address:<br/>" +
                                            this.parseVerifiedAddress(verifiedAddress);
                                    this.showRadios = true;
                                    resolve(false);
                                }
                            }
                        } else {
                            this.invalidAddressMessage =
                                    "The address information entered is not a recognized address.<br/>Are you sure it is correct?";
                            this.showCheckbox = true;
                            resolve(false);
                        }
                    });
            }
        });
        return deferred;
    }

    parseVerifiedAddress(verifiedAddress: any) {
        let result = verifiedAddress.street1;
        if (verifiedAddress.street2) {
            result = result + "<br/>" + verifiedAddress.street2;
        }
        result = result + "<br/>" + verifiedAddress.city + ", " + verifiedAddress.state + "&nbsp;&nbsp;" +
                verifiedAddress.zip;

        return result;
    }

    private resetInvalidAddress() {
        this.invalidAddressMessage = null;
        this.addressForm.patchValue({
            whichAddress: "",
            checkAddress: false
        });
    }

    copyLegalName() {
        if (!this.organizationForm.controls.name.value) {
            this.organizationForm.patchValue({
                name: this.organizationForm.controls.legalName.value
            });
        }
    }

    catchFormChanges() {
        this.addressForm.get("street").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.addressForm.get("street2").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.addressForm.get("city").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
        this.addressForm.get("zipCode").valueChanges.subscribe((val) => {
            this.resetInvalidAddress();
        });
    }

    getOrgUsers() {
        if (!this.organization.baseOrgID) {
            // no base org selected
            return;
        }
        this.userorgService
            .getUsersByOrganization(this.organization.baseOrgID)
            .pipe(first())
            .subscribe((users: User[]) => {
                this.userOptions = [];
                users.forEach((user) => {
                    let option: SelectableFatUser = {
                        id: user.id,
                        label: user.name.first + " " + user.name.last + " (" + user.id + ")",
                        name: user.name,
                        phone: user.phone,
                        email: user.email
                    };
                    this.userOptions.push(option);
                });
                this.userOptions = this.userOptions.sort((a, b) => {
                    return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                });
            });
    }

    private baseOrgHasService(serviceID: string) {
        let has = false;
        if (this.organization.baseOrgID && this.baseOrgServices) {
            this.baseOrgServices.forEach((service) => {
                if (service.id == serviceID) {
                    has = true;
                }
            });
        }
        return has;
    }

    getDefaultSubmitterFees() {
        if (this.defaultSubmitterFees) {
            this.showSubmitterFees(this.defaultSubmitterFees);
            return;
        }
        this.presentSubmitterFees = false;
        if (this.baseOrgHasService("submitter")) {
            this.presentSubmitterFees = true;
            this.organization.includeSubmitter = true;
            // get same fees as base org
            this.contractService.findContract(this.organization.baseOrgID, "ERECORDING")
                .subscribe((contract: any) => {
                    if (contract == null) {
                        this.requestStandardSubmitterFees();
                    } else {
                        this.defaultSubmitterFees = {
                            contractTemplateFees: {
                                LICENSE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                RENEWAL_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                SUBMISSION_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "LICENSE_FEE") {
                                this.defaultSubmitterFees.contractTemplateFees.LICENSE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "RENEWAL_FEE") {
                                this.defaultSubmitterFees.contractTemplateFees.RENEWAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "SUBMISSION_FEE") {
                                this.defaultSubmitterFees.contractTemplateFees.SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE") {
                                this.defaultSubmitterFees.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showSubmitterFees(this.defaultSubmitterFees);
                    }
                });
        }
    }

    getDefaultDocBuilderFees() {
        if (this.defaultDocBuilderFees) {
            this.showDocBuilderFees(this.defaultDocBuilderFees);
            return;
        }
        this.presentDocBuilderFees = false;
        if (this.baseOrgHasService("submitter_signing")) {
            // get same fees as base org
            this.contractService.findContract(this.organization.baseOrgID, "DOCUMENT_BUILDER")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentDocBuilderFees = true;
                        this.organization.includeDocBuilder = true;
                        this.defaultDocBuilderFees = {
                            contractTemplateFees: {
                                DOCUMENT_BUILDER_LICENSE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                DOCUMENT_BUILDER_RENEWAL_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                DOCUMENT_BUILDER_DOCUMENT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                DOCUMENT_BUILDER_FILE_TRANSFER_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "DOCUMENT_BUILDER_LICENSE_FEE") {
                                this.defaultDocBuilderFees.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "DOCUMENT_BUILDER_RENEWAL_FEE") {
                                this.defaultDocBuilderFees.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "DOCUMENT_BUILDER_DOCUMENT_FEE") {
                                this.defaultDocBuilderFees.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "DOCUMENT_BUILDER_FILE_TRANSFER_FEE") {
                                this.defaultDocBuilderFees.contractTemplateFees.DOCUMENT_BUILDER_FILE_TRANSFER_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showDocBuilderFees(this.defaultDocBuilderFees);
                    }
                });
        }
    }

    getDefaultDocBuilderRonFees() {
        if (this.defaultDocBuilderRonFees) {
            this.showDocBuilderRonFees(this.defaultDocBuilderRonFees);
            return;
        }
        this.presentDocBuilderRonFees = false;
        if (this.baseOrgHasService("submitter_signing")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "DOC_BUILDER_RON")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentDocBuilderRonFees = true;
                        this.organization.includeDocBuilderRon = true;
                        this.defaultDocBuilderRonFees = {
                            contractTemplateFees: {
                                DOCUMENT_BUILDER_RON_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "DOCUMENT_BUILDER_RON_FEE") {
                                this.defaultDocBuilderRonFees.contractTemplateFees.DOCUMENT_BUILDER_RON_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showDocBuilderRonFees(this.defaultDocBuilderRonFees);
                    }
                });
        }
    }

    getDefaultLienReleaseFees() {
        if (this.defaultLienReleaseFees) {
            this.showLienReleaseFees(this.defaultLienReleaseFees);
            return;
        }
        this.presentLienReleaseFees = false;
        if (this.baseOrgHasService("submitter_signing")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "LIEN_RELEASE")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentLienReleaseFees = true;
                        this.organization.includeLienRelease = true;
                        this.defaultLienReleaseFees = {
                            contractTemplateFees: {
                                LIEN_RELEASE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "LIEN_RELEASE_FEE") {
                                this.defaultLienReleaseFees.contractTemplateFees.LIEN_RELEASE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showLienReleaseFees(this.defaultLienReleaseFees);
                    }
                });
        }
    }

    getDefaultSubscriptionFees() {
        if (this.defaultSubscriptionFees) {
            this.showSubscriptionFees(this.defaultSubscriptionFees);
            return;
        }
        this.presentSubscriptionFees = false;
        /* we don't want to give the subscription service to new organizations created this way
        if (this.baseOrgHasService("subscription")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "SUBSCRIPTION")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentSubscriptionFees = true;
                        this.organization.includeSubscription = true;
                        this.defaultSubscriptionFees = {
                            contractTemplateFees: {
                                SUBSCRIPTION_MINIMUM: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        forEach(contract.contractFees, (fee) => {
                            if (fee.feeType == "SUBSCRIPTION_MINIMUM") {
                                this.defaultSubscriptionFees.contractTemplateFees.SUBSCRIPTION_MINIMUM.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showSubscriptionFees(this.defaultSubscriptionFees);
                    }
                });
        }
        */
    }

    getDefaultTrusteeFees() {
        if (this.defaultTrusteeFees) {
            this.showTrusteeFees(this.defaultTrusteeFees);
            return;
        }
        this.presentTrusteeFees = false;
        if (this.baseOrgHasService("trustee")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "TRUSTEE")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentTrusteeFees = true;
                        this.organization.includeTrustee = true;
                        this.defaultTrusteeFees = {
                            contractTemplateFees: {
                                TRUSTEE_DOCUMENT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "TRUSTEE_DOCUMENT_FEE") {
                                this.defaultTrusteeFees.contractTemplateFees.TRUSTEE_DOCUMENT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showTrusteeFees(this.defaultTrusteeFees);
                    }
                });
        }
    }

    getDefaultPaperFees() {
        if (this.defaultPaperFees) {
            this.showPaperFees(this.defaultPaperFees);
            return;
        }
        this.presentPaperFees = false;
        if (this.baseOrgHasService("submitter_paper")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "PAPER_RECORDING")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentPaperFees = true;
                        this.organization.includePaper = true;
                        this.defaultPaperFees = {
                            contractTemplateFees: {
                                PAPER_LICENSE_FEE: {
                                    contractFeeTiers: [{
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }]
                                },
                                PAPER_RENEWAL_FEE: {
                                    contractFeeTiers: [{
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }]
                                },
                                PAPER_SUBMISSION_FEE: {
                                    contractFeeTiers: [{
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }]
                                },
                                INSTATE_ERECORD_SUBMISSION_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                                OUTOFSTATE_ERECORD_SUBMISSION_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                                INSTATE_PAPER_SUBMISSION_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                                OUTOFSTATE_PAPER_SUBMISSION_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                                PAPER_MAIL_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                                EMBEDDED_EMPLOYEE_FEE: {
                                    contractFeeTiers: [{
                                        tierThreshold: 0,
                                        feeAmount: 0
                                    }]
                                },
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: any) => {
                            if (fee.feeType == "PAPER_LICENSE_FEE") {
                                this.defaultPaperFees.contractTemplateFees.PAPER_LICENSE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "PAPER_RENEWAL_FEE") {
                                this.defaultPaperFees.contractTemplateFees.PAPER_RENEWAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "PAPER_SUBMISSION_FEE") {
                                this.defaultPaperFees.contractTemplateFees.PAPER_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "INSTATE_ERECORD_SUBMISSION_FEE") {
                                this.defaultPaperFees.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "OUTOFSTATE_ERECORD_SUBMISSION_FEE") {
                                this.defaultPaperFees.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "INSTATE_PAPER_SUBMISSION_FEE") {
                                this.defaultPaperFees.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "OUTOFSTATE_PAPER_SUBMISSION_FEE") {
                                this.defaultPaperFees.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "PAPER_MAIL_FEE") {
                                this.defaultPaperFees.contractTemplateFees.PAPER_MAIL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "EMBEDDED_EMPLOYEE_FEE") {
                                this.defaultPaperFees.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showPaperFees(this.defaultPaperFees);
                    }
                });
        }
    }

    getDefaultSettlementFees() {
        if (this.defaultSettlementFees) {
            this.showSettlementFees(this.defaultSettlementFees);
            return;
        }
        this.presentSettlementFees = false;
        if (this.baseOrgHasService("licensed_collaborator_settlement")) {
            this.contractService
                .findContract(this.organization.baseOrgID, "SETTLEMENT")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentSettlementFees = true;
                        this.organization.includeSettlement = true;
                        this.defaultSettlementFees = {
                            contractTemplateFees: {
                                SETTLEMENT_LOAN_CREATE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                SETTLEMENT_LOAN_ACCEPT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                DISBURSEMENT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                REVSHARE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                SETTLEMENT_RENEWAL_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "SETTLEMENT_LOAN_CREATE_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "SETTLEMENT_LOAN_ACCEPT_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.SETTLEMENT_LOAN_ACCEPT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "DISBURSEMENT_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.DISBURSEMENT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "REVSHARE_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.REVSHARE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "SETTLEMENT_RENEWAL_FEE") {
                                this.defaultSettlementFees.contractTemplateFees.SETTLEMENT_RENEWAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showSettlementFees(this.defaultSettlementFees);
                    }
                });
        }
    }

    getDefaultLenderFees() {
        if (this.defaultLenderFees) {
            this.showLenderFees(this.defaultLenderFees);
            return;
        }
        this.presentLenderFees = false;
        if (this.baseOrgHasService("licensed_collaborator_lender")) {
            this.contractService.findContract(this.organization.baseOrgID, "LENDER")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentLenderFees = true;
                        this.organization.includeLender = true;
                        this.defaultLenderFees = {
                            contractTemplateFees: {
                                LENDER_LOAN_CREATE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                LENDER_LOAN_ACCEPT_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                LENDER_ANNUAL_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "LENDER_LOAN_CREATE_FEE") {
                                this.defaultLenderFees.contractTemplateFees.LENDER_LOAN_CREATE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "LENDER_LOAN_ACCEPT_FEE") {
                                this.defaultLenderFees.contractTemplateFees.LENDER_LOAN_ACCEPT_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "LENDER_ANNUAL_FEE") {
                                this.defaultLenderFees.contractTemplateFees.LENDER_ANNUAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showLenderFees(this.defaultLenderFees);
                    }
                });
        }
    }

    getDefaultEsignEventFees() {
        if (this.defaultEsignEventFees) {
            this.showEsignEventFees(this.defaultEsignEventFees);
            return;
        }
        this.presentEsignEventFees = false;
        if (this.baseOrgHasService("esign_event")) {
            // get same fees as base org
            this.contractService.findContract(this.organization.baseOrgID, "ESIGN_EVENT")
                .subscribe((contract: any) => {
                    if (contract != null) {
                        this.presentEsignEventFees = true;
                        this.organization.includeEsignEvent = true;
                        this.defaultEsignEventFees = {
                            contractTemplateFees: {
                                ESE_LICENSE_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                ESE_RENEWAL_FEE: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                ESE_NO_ENOTARIZATION: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                ESE_IPEN: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                },
                                ESE_RON: {
                                    contractFeeTiers: [
                                        {
                                            tierThreshold: 0,
                                            feeAmount: 0
                                        }
                                    ]
                                }
                            }
                        };
                        Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                            if (fee.feeType == "ESE_LICENSE_FEE") {
                                this.defaultEsignEventFees.contractTemplateFees.ESE_LICENSE_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "ESE_RENEWAL_FEE") {
                                this.defaultEsignEventFees.contractTemplateFees.ESE_RENEWAL_FEE.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "ESE_NO_ENOTARIZATION") {
                                this.defaultEsignEventFees.contractTemplateFees.ESE_NO_ENOTARIZATION.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "ESE_IPEN") {
                                this.defaultEsignEventFees.contractTemplateFees.ESE_IPEN.contractFeeTiers =
                                        fee.contractFeeTiers;
                            } else if (fee.feeType == "ESE_RON") {
                                this.defaultEsignEventFees.contractTemplateFees.ESE_RON.contractFeeTiers =
                                        fee.contractFeeTiers;
                            }
                        });
                        this.showEsignEventFees(this.defaultEsignEventFees);
                    }
                });
        }
    }

    // get 'standard' submitter fees
    requestStandardSubmitterFees() {
        this.contractService
            .getStandardContractTemplate("ERECORDING")
            .subscribe((template: any) => {
                this.defaultSubmitterFees = template;
                this.showSubmitterFees(this.defaultSubmitterFees);
            });
    }

    showSubmitterFees(template: any) {
        this.submitterLicenseFee = template.contractTemplateFees.LICENSE_FEE.contractFeeTiers[0].feeAmount;
        this.submitterRenewalFee = template.contractTemplateFees.RENEWAL_FEE.contractFeeTiers[0].feeAmount;
        this.submitterSubmissionFee = template.contractTemplateFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
    }

    showDocBuilderFees(template: any) {
        this.docBuilderLicenseFee = template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers[0].feeAmount;
        this.docBuilderRenewalFee = template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
        this.docBuilderDocumentFee = template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
    }

    showDocBuilderRonFees(template: any) {
        this.docBuilderRonFee = template.contractTemplateFees.DOCUMENT_BUILDER_RON_FEE.contractFeeTiers[0].feeAmount;
    }

    showLienReleaseFees(template: any) {
        this.lienReleaseFee = template.contractTemplateFees.LIEN_RELEASE_FEE.contractFeeTiers[0].feeAmount;
    }

    showSubscriptionFees(template: any) {
        this.subscriptionMinumum = template.contractTemplateFees.SUBSCRIPTION_MINIMUM.contractFeeTiers[0].feeAmount;
    }

    showTrusteeFees(template: any) {
        this.trusteeDocumentFee = template.contractTemplateFees.TRUSTEE_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
    }

    showPaperFees(template: any) {
        // this.paperLicenseFee = template.contractTemplateFees.PAPER_LICENSE_FEE.contractFeeTiers[0].feeAmount;
        // this.paperRenewalFee = template.contractTemplateFees.PAPER_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
        this.paperSubmissionFee = template.contractTemplateFees.PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        this.inStateErecordFee = template.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        this.outOfStateErecordFee = template.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        this.inStatePaperFee = template.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        this.outOfStatePaperFee = template.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        this.paperMailFee = template.contractTemplateFees.PAPER_MAIL_FEE.contractFeeTiers[0].feeAmount;
        this.embeddedEmployeeFee = template.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE.contractFeeTiers[0].feeAmount;
    }

    showSettlementFees(template: any) {
        this.settlementRenewalFee = template.contractTemplateFees.SETTLEMENT_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
        this.settlementLoanFee = template.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
        this.settlementDisbursementFee = template.contractTemplateFees.DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
        this.settlementInsecureFee =
                template.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
        this.settlementRevShareFee = template.contractTemplateFees.REVSHARE_FEE.contractFeeTiers[0].feeAmount;
    }

    showLenderFees(template: any) {
        this.lenderRenewalFee = template.contractTemplateFees.LENDER_ANNUAL_FEE.contractFeeTiers[0].feeAmount;
        this.lenderLoanFee = template.contractTemplateFees.LENDER_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
    }

    showEsignEventFees(template: any) {
        this.esignEventLicenseFee = template.contractTemplateFees.ESE_LICENSE_FEE.contractFeeTiers[0].feeAmount;
        this.esignEventRenewalFee = template.contractTemplateFees.ESE_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
        this.esignEventNotarizationFee =
                template.contractTemplateFees.ESE_NO_ENOTARIZATION.contractFeeTiers[0].feeAmount;
        this.esignEventIpenFee = template.contractTemplateFees.ESE_IPEN.contractFeeTiers[0].feeAmount;
        this.esignEventRonFee = template.contractTemplateFees.ESE_RON.contractFeeTiers[0].feeAmount;
    }

    submitterClicked() {
        this.organization.includeSubmitter = !this.organization.includeSubmitter;
        if (!this.organization.includeSubmitter) {
            this.organization.includePaper = false;
            this.organization.includeDocBuilder = false;
            this.organization.includeDocBuilderRon = false;
            this.organization.includeLienRelease = false;
        }
    }

    docBuilderClicked() {
        this.organization.includeDocBuilder = !this.organization.includeDocBuilder;
        if (!this.organization.includeDocBuilder) {
            this.organization.includeDocBuilderRon = false;
            this.organization.includeLienRelease = false;
        }
    }

    copyContactInfo(baseOrg: Organization) {
        if (!baseOrg) {
            return;
        }

        if (baseOrg.contact) {
            if (!this.organization.primaryContact.email) {
                this.organization.primaryContact.email = baseOrg.contact.email;
            }
            if (!this.organization.primaryContact.name) {
                this.organization.primaryContact.name = {
                    first: "",
                    middle: "",
                    last: ""
                };
            }
            if (baseOrg.contact.name) {
                if (!this.organization.primaryContact.name.prefix) {
                    this.organization.primaryContact.name.prefix = baseOrg.contact.name.prefix;
                }
                if (!this.organization.primaryContact.name.first) {
                    this.organization.primaryContact.name.first = baseOrg.contact.name.first;
                }
                if (!this.organization.primaryContact.name.last) {
                    this.organization.primaryContact.name.last = baseOrg.contact.name.last;
                }
                if (this.organization.primaryContact.name.middle) {
                    this.organization.primaryContact.name.middle = baseOrg.contact.name.middle;
                }
                if (!this.organization.primaryContact.name.suffix) {
                    this.organization.primaryContact.name.suffix = baseOrg.contact.name.suffix;
                }
                if (!this.organization.primaryContact.name.title) {
                    this.organization.primaryContact.name.title = baseOrg.contact.name.title;
                }
            }
            if (!this.organization.primaryContact.phone) {
                this.organization.primaryContact.phone = {
                    areaCode: "",
                    prefix: "",
                    suffix: ""
                };
            }
            if (!this.organization.primaryContact.phone.areaCode && baseOrg.contact.phone) {
                // note that you have to replace the existing phone object to get it to update
                this.organization.primaryContact.phone = {
                    areaCode: baseOrg.contact.phone.areaCode,
                    prefix: baseOrg.contact.phone.prefix,
                    suffix: baseOrg.contact.phone.suffix,
                    extension: baseOrg.contact.phone.extension
                };
            }
        }
    }

    selectInitialService(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selection: SelectableItem = event.$selection;
            this.organization.initialService = selection.option;
        }
    }

    selectUserContact(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selection: SelectableFatUser = event.$selection;
            if (!selection)
                return;

            this.organization.primaryContact.email = selection.email;
            this.organization.primaryContact.name = {
                prefix: selection.name.prefix,
                first: selection.name.first,
                last: selection.name.last,
                middle: selection.name.middle,
                suffix: selection.name.suffix,
                title: selection.name.title
            };
            this.organization.primaryContact.phone = {
                areaCode: selection.phone.areaCode,
                prefix: selection.phone.prefix,
                suffix: selection.phone.suffix,
                extension: selection.phone.extension
            };

            this.contactForm.patchValue({
                firstName: selection.name.first,
                middleName: selection.name.middle,
                lastName: selection.name.last,
                title: selection.name.title,
                phone: this.phoneToString(selection.phone),
                extension: selection.phone.extension,
                email: selection.email
            });
        }
    }

    // single select now
    selectBusinessType(event: any) {
        if (event.hasOwnProperty("$selection")) {
            this.businessTypesPristine = !event.$selection;
            let business: SelectableItemWithID = event.$selection;
            this.organization.businessTypes = business ? [business.id] : null;  // array of one
        }
    }

    baseOrgSelected(selection: Organization) {
        if (selection) {
            setTimeout(() => {
                if (this.organization.baseOrgID == selection.id) {
                    return;     // no change
                }
                this.organization.baseOrgID = selection.id;
                this.baseOrgServices = selection.activeServices;
                this.copyContactInfo(selection);
            });

        } else {
            this.organization.baseOrgID = null;
            this.baseOrgServices = null;
        }
        this.defaultSubmitterFees = null;
        this.defaultDocBuilderFees = null;
        this.defaultDocBuilderRonFees = null;
        this.defaultLienReleaseFees = null;
        this.defaultSubscriptionFees = null;
        this.defaultTrusteeFees = null;
        this.defaultPaperFees = null;
        this.defaultSettlementFees = null;
        this.defaultLenderFees = null;
        this.defaultEsignEventFees = null;

        // set the business type to match
        if (selection) {
            this.userorgService
                .getOrganization(selection.id)
                .pipe(first())
                .subscribe((fullOrg: Organization) => {
                    this.organization.basicInfo.legalName = fullOrg.legalName;
                    this.organizationForm.patchValue({
                        legalName: this.organization.basicInfo.legalName
                    });
                    if (fullOrg.phone && this.organizationForm.controls.phone.pristine) {
                        let phoneText = fullOrg.phone.areaCode + "." + fullOrg.phone.prefix + "." + fullOrg.phone.suffix;
                        this.organizationForm.patchValue({
                            phone: phoneText,
                            extension: fullOrg.phone.extension
                        });
                    }
                    if (this.businessTypesPristine) {
                        this.organization.businessTypes = fullOrg.businessTypes;
                    }
                    if (!this.organization.businessTypes) {
                        this.organization.businessTypes = [];
                    }
                    if (this.parentPristine && this.parentFilterSelectorInstance) {
                        this.organization.parentID = fullOrg.parentID;
                        this.parentFilterSelectorInstance.selectedOrgID = this.organization.parentID;
                    }
                });
        } else {
            this.organization.businessTypes = [];
        }

        /* maybe think about this
        // if base has a parent, then set the parent for the new org
        if (selection && selection.parentID) {
            this.organization.parentID = selection.parentID;
        }
        */
    }

    parentOrgSelected(selection: Organization) {
        this.parentPristine = !selection;
        if (selection) {
            this.organization.parentID = selection.id;
        } else {
            this.organization.parentID = null;
        }
    }

    stateSelected(selection: SelectableState) {
        this.organization.basicInfo.address.state = selection ? selection.abbrev : null;
    }

    resolve() {
        this.warning = this.validateCurrentPage();
        if (this.warning) {
            return;
        }

        this.close(this.organization);
    }
}
