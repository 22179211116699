import { Injectable } from "@angular/core";
import { PendingPaymentsNewService } from "@sf/userorg/common";
import { SessionOrganization, SessionService } from "@sf/common";
import { Observable, of, zip } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PendingPaymentsResolverService {
    constructor(
        private _pendingPaymentsNewService: PendingPaymentsNewService,
        private _sessionService: SessionService
    ) {}

    resolve(): Observable<any> {
        const allOrgs: SessionOrganization[] =
            this._sessionService.getAllOrganizations();
        const orgIDsToCheck: string[] = allOrgs
            .filter((org: SessionOrganization) =>
                this._sessionService.hasPermission(
                    "organization_payment_approval",
                    org.id
                )
            )
            .map((org) => org.id);

        /** Observables **/
        let uiPaymentAccountTypes$: Observable<any>;
        let allSessionOrganizations$: Observable<SessionOrganization[]>;
        //let allPendingPayments$: Observable<any>;

        /** Data Gathering **/
        uiPaymentAccountTypes$ =
            orgIDsToCheck.length > 0
                ? this._pendingPaymentsNewService.getPaymentAccountTypes(
                      orgIDsToCheck,
                      true
                  )
                : of({});
        allSessionOrganizations$ = of(allOrgs);
        // allPendingPayments$ =
        //     orgIDsToCheck.length > 0
        //         ? this._pendingPaymentsNewService.getAllPendingPayments(
        //               orgIDsToCheck,
        //               ["MYECHECK", "CREDIT_CARD", "ACH"]
        //           )
        //         : of([]);

        return zip(
            uiPaymentAccountTypes$,
            allSessionOrganizations$
            //allPendingPayments$
        );
    }
}
