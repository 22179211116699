import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldOption } from "../../interfaces";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { InputComponent } from "../../components";
import { map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { FormBuilder, FormControl, ValidationErrors } from "@angular/forms";

@Component({
    selector: "sf-radio-field",
    templateUrl: "./radio-field.component.html",
    styleUrls: ["./radio-field.component.scss"]
})
export class RadioFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy {
    options$: Observable<FieldOption[]>;
    errors: ValidationErrors;
    private _destroy$ = new Subject<void>();

    constructor(private _fb: FormBuilder, protected _facade: DynamicFormStore) {
        super(_facade);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.options$ = this._facade
            .getViewStateForField(this.field.fullPath)
            .pipe(map((viewState) => viewState?.options || []));

        this.formErrors$.pipe(takeUntil(this._destroy$)).subscribe((errors) => {
            this.errors = errors;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
    }
}
