import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "sf-not-found",
    templateUrl: "./page-not-found.component.html",
    styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent {
    badUrl = this._route.snapshot.paramMap.get("badUrl")
        ? decodeURIComponent(this._route.snapshot.paramMap.get("badUrl"))
        : undefined;

    constructor(private _route: ActivatedRoute) {}
}
