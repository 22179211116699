export class ChatUploadFileTypeConstants {
    // pdf: ["pdf", "spdf", "spd", "s1a"],
    // See http://www.iana.org/assignments/media-types/application/vnd.sealedmedia.softseal-pdf
    // tiff: ["tiff", "tif", "tfx", "stif"]
    // See http://www.iana.org/assignments/media-types/image/tiff
    //     http://www.iana.org/assignments/media-types/image/tiff-fx
    //     http://www.iana.org/assignments/media-types/application/vnd.sealed-tiff
    public static readonly ACCEPTED_FILE_TYPES: string[] = [
        ".pdf",
        ".spdf",
        ".spd",
        ".s1a",
        ".tiff",
        ".tif",
        ".tfx",
        ".stif"
    ];

    public static readonly ACCEPTED_MIME_TYPES: string[] = [
        "application/pdf",
        "application/vnd.cups-pdf",
        "application/vnd.sealedmedia.softseal-pdf",
        "image/tiff",
        "image/tiff-fx",
        "application/vnd.sealed-tiff"
    ];

    public static get ALL_POSSIBLE_TYPES(): string[] {
        return ChatUploadFileTypeConstants.ACCEPTED_FILE_TYPES.concat(
            ChatUploadFileTypeConstants.ACCEPTED_MIME_TYPES
        );
    }

    public static get ALL_POSSIBLE_TYPES_AS_STRING(): string {
        return ChatUploadFileTypeConstants.ALL_POSSIBLE_TYPES.join(",");
    }
}
