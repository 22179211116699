import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PendingPaymentsService {
    constructor(private _rpcClient: RpcClientService) {}

    public getUserHasApprovedEchecks(
        orgIDArray: string[],
        username: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getUserHasApprovedEchecks",
            { orgIDs: orgIDArray, username: username }
        );
    }

    setPackagePaymentAccounts(
        id: string,
        newAccountID: string,
        ACCOUNT_TYPES: string[]
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "setPackagePaymentAccounts",
            {
                id: id,
                newAccountID,
                ACCOUNT_TYPES
            }
        );
    }

    hasEcheckBankAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasEcheckBankAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }

    hasACHBankAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasACHBankAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }

    hasCreditCardAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasCreditCardAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }
}
