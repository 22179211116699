import { Component, Input } from "@angular/core";
import {
    BaseModalComponent,
    BrightcoveService,
    SessionService,
    SpinnerService,
    UserSettingsService
} from "@sf/common";
import { Router } from "@angular/router";
import {
    DocumentBuilderDataEntryGuideTask,
    DocumentBuilderGuideCard,
    DocumentBuilderGuideTask,
    DocumentBuilderGuideType,
    DocumentBuilderNotaryGuideTask,
    DocumentBuilderSignerGuideTask
} from "../../interfaces/document-builder-guide-card";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    BannerNotificationCategory,
    BannerNotificationService
} from "@sf/userorg/common";
import { finalize } from "rxjs/operators";
import {
    BroadcastType,
    EsignBroadcastService
} from "../../services/esign-broadcast.service";
import { EsignUserService } from "../../services/esign-user.service";
import { DocumentBuilderGuideNotificationSettingsComponent } from "../document-builder-guide-notification-settings/document-builder-guide-notification-settings.component";
import { DocumentBuilderGuideEntityTypeComponent } from "../document-builder-guide-entity-type/document-builder-guide-entity-type.component";

@Component({
    selector: "sf-document-builder-guide-modal",
    templateUrl: "./document-builder-guide-modal.component.html",
    styleUrls: ["./document-builder-guide-modal.component.scss"]
})
export class DocumentBuilderGuideModalComponent extends BaseModalComponent {
    @Input()
    showAdminGuide: boolean;
    @Input()
    showSignerGuide: boolean;
    @Input()
    showNotaryGuide: boolean;
    @Input()
    showDataEntryGuide: boolean;
    @Input()
    adminOrganizationId: string;
    @Input()
    tasksOnTop?: (
        | DocumentBuilderGuideTask
        | DocumentBuilderSignerGuideTask
        | DocumentBuilderNotaryGuideTask
        | DocumentBuilderDataEntryGuideTask
    )[] = [];
    adminCards: DocumentBuilderGuideCard[];
    signerCards: DocumentBuilderGuideCard[];
    notaryCards: DocumentBuilderGuideCard[];
    dataEntryCards: DocumentBuilderGuideCard[];
    filteredAdminCards: DocumentBuilderGuideCard[];
    filteredSignerCards: DocumentBuilderGuideCard[];
    filteredNotaryCards: DocumentBuilderGuideCard[];
    filteredDataEntryCards: DocumentBuilderGuideCard[];
    adminComplete = false;
    signerComplete = false;
    notaryComplete = false;
    dataEntryComplete = false;

    private _signerPermissions = [
        "submitter_signing_package_signer",
        "submitter_signing_vp_signer",
        "submitter_signing_mers_signer",
        "submitter_signing_package_witness"
    ];

    constructor(
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService,
        private _router: Router,
        private _activeModal: NgbActiveModal,
        private _bannerNotificationService: BannerNotificationService,
        private _brightcoveService: BrightcoveService,
        private _esignUserService: EsignUserService,
        private _spinnerService: SpinnerService,
        private _esignBroadcastService: EsignBroadcastService
    ) {
        super();
    }

    ngOnInit(): void {
        this._brightcoveService.injectBrightcoveScript();
        this.adminCards = [
            // {
            //     id: DocumentBuilderGuideTask.TEMPLATE_GROUPS,
            //     title: "Add Forms",
            //     description:
            //         "You will need forms from which to create your documents. Simplifile offers Lien Release forms and " +
            //         "Assignment forms for mortgages and deeds of trust. Select the forms that you wish to use:",
            //     completeMessage:
            //         "The forms you selected are now available for you to use. After creating a package, " +
            //         "you will be able to select your form, from which to create your document.",
            //     status: this._sessionService.getUserSetting(
            //         `DOCUMENT_BUILDER_GUIDE_TEMPLATE_GROUPS_STATUS`
            //     )
            // },
            {
                id: DocumentBuilderGuideTask.CONFIGURATION,
                title: "Configure Document Builder",
                description:
                    "Before you begin using Document Builder, take a few minutes to configure it to fit your needs.",
                actionLabel: "Configure",
                status: this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_CONFIGURATION_STATUS`
                ),
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl(
                        `/submitter/organization/${this.adminOrganizationId}/document-builder/configuration`
                    );
                }
            },
            {
                id: DocumentBuilderGuideTask.ADD_USERS,
                title: "Add Users and User Roles",
                description:
                    "In order to take full advantage of Document Builder, you will need to assign a few roles, such " +
                    "as Signer, Notary, and Data Entry User. If these users already have Simplifile accounts, it will be " +
                    "as simple as adding a new role. If any of your users do not already have Simplifile accounts, you can " +
                    "create an account for them and assign the correct role(s).",
                actionLabel: "Add Users & Roles",
                status: this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_ADD_USERS_STATUS`
                ),
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl(
                        `/submitter/organization/${this.adminOrganizationId}/users`
                    );
                }
            },
            {
                id: DocumentBuilderGuideTask.COMMON_PARTIES,
                title: "Add Common Parties",
                description:
                    "When creating documents, you may enter the names of Trustees, Original Beneficiaries, or Assignees. " +
                    "You can add these various party names in the Common Party setup, and quickly select them during data entry.",
                actionLabel: "Add Common Parties",
                status: this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_COMMON_PARTIES_STATUS`
                ),
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl(
                        `/submitter/organization/${this.adminOrganizationId}/document-builder/common-parties`
                    );
                }
            },
            {
                id: DocumentBuilderGuideTask.REPORTS,
                title: "Familiarize Yourself With Our Reports",
                description:
                    "Document Builder offers many reports to help you ensure your operations are running well. " +
                    "Please take a moment to familiarize yourself with these new reports.",
                actionLabel: "View Reports",
                status: this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_REPORTS_STATUS`
                ),
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl(`/submitter/reporting/reports`);
                }
            },
            {
                id: DocumentBuilderGuideTask.ENTITY_TYPE,
                title: "Tell Us Your Organization Entity Type",
                component: DocumentBuilderGuideEntityTypeComponent,
                hideOnComplete: false,
                status: this.userSettingsService.getUserSetting(
                    `DOCUMENT_BUILDER_GUIDE_ENTITY_TYPE_STATUS`
                )
            }
        ];

        let signerSampleDocumentsStatus =
            this.userSettingsService.getUserSetting(
                "DOCUMENT_BUILDER_SIGNER_GUIDE_SAMPLE_DOCUMENTS_STATUS"
            );
        this.signerCards = [
            {
                id: DocumentBuilderSignerGuideTask.SIGNATURE,
                title: "Add Signature",
                description:
                    "Upload your signature to be able to apply it to documents.",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_SIGNATURE_STATUS"
                ),
                actionLabel: "Add Signature",
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl("/settings/signature");
                }
            },
            {
                id: DocumentBuilderSignerGuideTask.TITLE,
                title: "Add or Verify Title",
                description:
                    "Your title will be added to the documents you sign. Take a minute to add your " +
                    "title to your profile or to verify that your existing title is correct.",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_TITLE_STATUS"
                ),
                actionLabel: "Add Title",
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl("/settings/personal");
                }
            },
            {
                id: DocumentBuilderSignerGuideTask.SECONDARY_TITLE,
                title: "Secondary (MERS) Title",
                description:
                    "Take a minute to add or review your secondary title. This title will be used when you sign documents " +
                    "on behalf of MERS. MERS only recognizes three titles—Vice President, Assistant Vice President, " +
                    "and Assistant Secretary. If your primary title is not one of those three, you must designate a " +
                    "secondary title. If you do not know your MERS title, please consult the MERS Corporate Resolution.",
                actionLabel: "Add Secondary Title",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_SECONDARY_TITLE_STATUS"
                ),
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl("/settings/personal");
                }
            },
            {
                id: DocumentBuilderSignerGuideTask.SAMPLE_DOCUMENTS,
                title: "Sign Sample Documents",
                description:
                    "Take a minute to familiarize yourself with our signing experience. You will be able to sign sample documents.",
                completeMessage:
                    "You have successfully completed this task. Great work!",
                actionLabel: signerSampleDocumentsStatus
                    ? ""
                    : "Sign Sample Documents",
                status: signerSampleDocumentsStatus,
                action: () =>
                    this.sampleDocumentsAction(DocumentBuilderGuideType.SIGNER)
            },
            {
                id: DocumentBuilderSignerGuideTask.NOTARY_METHOD,
                title: "Notarization Method",
                description:
                    "There are two methods of electronic notarization—in-person electronic notarization (IPEN) and remote " +
                    "online notarization (RON). Your organization is set up only for IPEN. Therefore you must appear personally " +
                    "before your notary. Please click the link below to learn more about the two notarization methods.",
                actionLabel: "Learn more about IPEN and RON",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_NOTARY_METHOD_STATUS"
                ),
                action: (card: DocumentBuilderGuideCard) => {
                    window.open(
                        "https://simplifile.com/landers/simplifile-e-notary-faq/",
                        "_blank"
                    );
                    this.userSettingsService.setUserSetting(
                        "DOCUMENT_BUILDER_SIGNER_GUIDE_NOTARY_METHOD_STATUS",
                        "complete"
                    );
                    card.status = "complete";
                    this.tasksOnTop = [
                        DocumentBuilderSignerGuideTask.NOTARY_METHOD
                    ];
                    this._filterCards();
                }
            },
            {
                id: DocumentBuilderSignerGuideTask.NOTIFICATION_SETTINGS,
                title: "Signing Notifications",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_NOTIFICATION_SETTINGS_STATUS"
                ),
                component: DocumentBuilderGuideNotificationSettingsComponent,
                hideOnComplete: true,
                actionLabel: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_SIGNER_GUIDE_NOTIFICATION_SETTINGS_STATUS"
                )
                    ? "Change Notification Settings"
                    : "Complete",
                action: (card: DocumentBuilderGuideCard) => {
                    if (card.status === "complete") {
                        this._activeModal.dismiss();
                        this._router.navigateByUrl("/settings/notifications");
                    }
                    this.userSettingsService.setUserSetting(
                        "DOCUMENT_BUILDER_SIGNER_GUIDE_NOTIFICATION_SETTINGS_STATUS",
                        "complete"
                    );
                    card.actionLabel = "Change Notification Settings";
                    card.status = "complete";
                    this.tasksOnTop = [
                        ...this.tasksOnTop,
                        DocumentBuilderSignerGuideTask.NOTIFICATION_SETTINGS
                    ];
                    this._filterCards();
                }
            }
        ];

        let notarySampleDocumentsStatus =
            this.userSettingsService.getUserSetting(
                "DOCUMENT_BUILDER_NOTARY_GUIDE_SAMPLE_DOCUMENTS_STATUS"
            );
        this.notaryCards = [
            {
                id: DocumentBuilderNotaryGuideTask.SETUP,
                title: "Complete Notary Setup",
                description:
                    "Complete your notary setup to begin notarizing documents.",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_NOTARY_GUIDE_SETUP_STATUS"
                ),
                actionLabel: "Set Up Notary Information",
                action: () => {
                    this._activeModal.dismiss();
                    this._router.navigateByUrl("/settings/notary-credentials");
                }
            },
            {
                id: DocumentBuilderNotaryGuideTask.SAMPLE_DOCUMENTS,
                title: "Notarize Sample Documents",
                description:
                    "Take a minute to familiarize yourself with our notary experience. You will be able to notarize sample documents.",
                completeMessage:
                    "You have successfully completed this task. Great work!",
                actionLabel: notarySampleDocumentsStatus
                    ? ""
                    : "Notarize Sample Documents",
                status: notarySampleDocumentsStatus,
                action: () =>
                    this.sampleDocumentsAction(DocumentBuilderGuideType.NOTARY)
            },
            {
                id: DocumentBuilderNotaryGuideTask.NOTARY_METHOD,
                title: "Notarization Method",
                description:
                    "There are two methods of electronic notarization—in-person electronic notarization (IPEN) and remote " +
                    "online notarization (RON). Your organization is set up only for IPEN. Therefore you must appear personally " +
                    "before your notary. Please click the link below to learn more about the two notarization methods.",
                actionLabel: "Learn more about IPEN and RON",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_NOTARY_GUIDE_NOTARY_METHOD_STATUS"
                ),
                action: (card: DocumentBuilderGuideCard) => {
                    window.open(
                        "https://simplifile.com/landers/simplifile-e-notary-faq/",
                        "_blank"
                    );
                    this.userSettingsService.setUserSetting(
                        "DOCUMENT_BUILDER_NOTARY_GUIDE_NOTARY_METHOD_STATUS",
                        "complete"
                    );
                    card.status = "complete";
                    this.tasksOnTop = [
                        DocumentBuilderNotaryGuideTask.NOTARY_METHOD
                    ];
                    this._filterCards();
                }
            }
        ];

        this.dataEntryCards = [
            {
                id: DocumentBuilderDataEntryGuideTask.DATA_ENTRY,
                title: "Create New Document",
                description:
                    "You can create documents in Simplifile by using Document Builder. This tutorial will show you how.",
                status: this.userSettingsService.getUserSetting(
                    "DOCUMENT_BUILDER_DATA_ENTRY_GUIDE_DATA_ENTRY_STATUS"
                ),
                actionLabel: "Create Document",
                action: (card: DocumentBuilderGuideCard) => {
                    this.createNewDocumentAction();
                }
            }
        ];

        this._filterCards();
    }

    skipAdminCard(card: DocumentBuilderGuideCard) {
        this.skipCard(card, DocumentBuilderGuideType.ADMIN);
    }

    skipSignerCard(card: DocumentBuilderGuideCard) {
        this.skipCard(card, DocumentBuilderGuideType.SIGNER);
    }

    skipNotaryCard(card: DocumentBuilderGuideCard) {
        this.skipCard(card, DocumentBuilderGuideType.NOTARY);
    }

    skipDataEntryCard(card: DocumentBuilderGuideCard) {
        this.skipCard(card, DocumentBuilderGuideType.DATA_ENTRY);
    }

    skipCard(
        card: DocumentBuilderGuideCard,
        guideType: DocumentBuilderGuideType
    ) {
        let settingName = `DOCUMENT_BUILDER_GUIDE_${card.id}_STATUS`;
        if (guideType === DocumentBuilderGuideType.SIGNER) {
            settingName = `DOCUMENT_BUILDER_SIGNER_GUIDE_${card.id}_STATUS`;
        } else if (guideType === DocumentBuilderGuideType.NOTARY) {
            settingName = `DOCUMENT_BUILDER_NOTARY_GUIDE_${card.id}_STATUS`;
        } else if (guideType === DocumentBuilderGuideType.DATA_ENTRY) {
            settingName = `DOCUMENT_BUILDER_DATA_ENTRY_GUIDE_${card.id}_STATUS`;
        }

        this.userSettingsService.setUserSetting(settingName, "skipped");
        card.status = "skipped";
        this._filterCards();
    }

    _filterCards() {
        if (this.showAdminGuide) {
            this.filteredAdminCards = this.adminCards
                .filter((card) => card.status !== "skipped")
                .sort(this._sortCards.bind(this));
            this.adminComplete = !this.adminCards.find((card) => !card.status);
            if (this.adminComplete) {
                this.filteredAdminCards = [
                    {
                        title: "Setup Complete",
                        description:
                            "Congratulations! You have completed the Document Builder setup.",
                        status: "complete"
                    },
                    ...this.filteredAdminCards
                ];
            }
        }

        if (this.showSignerGuide) {
            this.filteredSignerCards = this.signerCards
                .filter((card) => card.status !== "skipped")
                .sort(this._sortCards.bind(this));
            this.signerComplete = !this.signerCards.find(
                (card) => !card.status
            );
            if (this.signerComplete) {
                this.filteredSignerCards = [
                    {
                        title: "Setup Complete",
                        description:
                            "Congratulations! You have completed the Document Builder setup.",
                        status: "complete"
                    },
                    ...this.filteredSignerCards
                ];
            }
        }

        if (this.showNotaryGuide) {
            this.filteredNotaryCards = this.notaryCards
                .filter((card) => card.status !== "skipped")
                .sort(this._sortCards.bind(this));
            this.notaryComplete = !this.notaryCards.find(
                (card) => !card.status
            );
            if (this.notaryComplete) {
                this.filteredNotaryCards = [
                    {
                        title: "Setup Complete",
                        description:
                            "Congratulations! You have completed the Document Builder setup.",
                        status: "complete"
                    },
                    ...this.filteredNotaryCards
                ];
            }
        }

        if (this.showDataEntryGuide) {
            this.filteredDataEntryCards = this.dataEntryCards
                .filter(
                    (card: DocumentBuilderGuideCard) =>
                        card.status !== "skipped"
                )
                .sort(this._sortCards.bind(this));
            this.dataEntryComplete = !this.dataEntryCards.find(
                (card: DocumentBuilderGuideCard) => !card.status
            );
            if (this.dataEntryComplete) {
                this.filteredDataEntryCards = [
                    {
                        title: "Setup Complete",
                        description:
                            "Congratulations! You have completed the Document Builder setup.",
                        status: "complete"
                    },
                    ...this.filteredDataEntryCards
                ];
            }
        }

        if (
            (!this.showAdminGuide || this.adminComplete) &&
            (!this.showSignerGuide || this.signerComplete) &&
            (!this.showNotaryGuide || this.notaryComplete) &&
            (!this.showDataEntryGuide || this.dataEntryComplete)
        ) {
            this._bannerNotificationService.removeBannerOfCategory(
                BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE
            );
        }
    }

    _sortCards(a: DocumentBuilderGuideCard, b: DocumentBuilderGuideCard) {
        if (this.tasksOnTop.includes(a.id) && this.tasksOnTop.includes(b.id)) {
            return 0;
        } else if (this.tasksOnTop.includes(a.id)) {
            return -1;
        } else if (this.tasksOnTop.includes(b.id)) {
            return 1;
        }

        if (a.status !== b.status) {
            return !a.status ? -1 : 1;
        }
        return 0;
    }

    sampleDocumentsAction(guideType: DocumentBuilderGuideType) {
        this._activeModal.dismiss();
        this._spinnerService.startSpinner();
        let observable$ =
            guideType === DocumentBuilderGuideType.SIGNER
                ? this._esignUserService.createSampleSignerDocuments(
                      this.getSignerOrganizationId()
                  )
                : this._esignUserService.createSampleNotaryDocuments(
                      this.getNotaryOrganizationId()
                  );

        observable$
            .pipe(finalize(() => this._spinnerService.stopSpinner()))
            .subscribe((documentIds) => {
                if (
                    this._router.routerState.snapshot.url?.includes(
                        "signing-list"
                    ) &&
                    documentIds.length
                ) {
                    // already on signing list, reload data
                    this._esignBroadcastService.broadcast(
                        BroadcastType.RELOAD_SIGNING_LIST
                    );
                } else {
                    this._router.navigateByUrl("/signing/signing-list");
                }
            });
    }

    getSignerOrganizationId() {
        return this._signerPermissions.flatMap((permission: string) =>
            this._sessionService.getOrganizationIDsWithPermission(permission)
        )[0];
    }

    getNotaryOrganizationId() {
        return this._sessionService.getOrganizationIDsWithPermission(
            "submitter_signing_package_notary"
        )[0];
    }

    createNewDocumentAction() {
        this.userSettingsService.setUserSetting(
            "DOCUMENT_BUILDER_DATA_ENTRY_GUIDE_DATA_ENTRY_STATUS",
            "complete"
        );
        this._bannerNotificationService.removeBannerOfCategory(
            BannerNotificationCategory.DOCUMENT_BUILDER_GUIDE
        );
        this._activeModal.dismiss();
        let url = "/submitter/packages";
        if (this._router.url === url) {
            this._esignBroadcastService.broadcast(
                BroadcastType.SHOW_DATA_ENTRY_TOUR
            );
        } else {
            this.userSettingsService.setUserSetting(
                "SHOW_TOUR_PACKAGES_PAGE_GETTING_STARTED",
                true
            );
            this._router.navigateByUrl(url);
        }
    }
}
