export interface BorrowerLetterSettings {
    organizationId: string;
    type: BorrowerLetterSettingsType;
    eRecordOption?: BorrowerLetterSettingsOption;
    paperOption?: BorrowerLetterSettingsOption;
    stateOptions?: Record<string, BorrowerLetterSettingsOption>;
    templateStateMappings?: Record<string, any>;
    recordedDocumentCount: number;
    referenceDocumentCount: number;
}

export enum BorrowerLetterSettingsType {
    SUBMISSION_FORMAT = "SUBMISSION_FORMAT",
    STATE = "STATE"
}

export enum BorrowerLetterSettingsOption {
    AFTER_GENERATING = "AFTER_GENERATING",
    AFTER_RECORDING = "AFTER_RECORDING"
}
