import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";

// prettier-ignore
@Component({
    selector: "admin-login-modal",
    templateUrl: "./admin-login-modal.component.html",
    styleUrls: ["./admin-login-modal.component.scss"]
})
export class AdminLoginModalComponent implements OnInit {
    @Input() orgUsers: any[];
    @Input() orgID: string;
    @Input() orgName: string;

    title: string;
    secondary: ModalButton;

    constructor(
            private activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.title = "Log In as User";
        if (this.orgName) {
            this.title += " in " + this.orgName;
        }

        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.doCancel.bind(this)
        };
    }

    selectFirstUser() {
        this.activeModal.close(this.orgUsers[0].id);
    }

    setSelectedUser(user: any) {
        this.activeModal.close(user.id);
    }

    doCancel() {
        this.activeModal.close(null);
    }
}
