import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    SfCommonModule,
    ModalsModule,
    SelectModule,
    SfFormsModule,
    CommonPipesModule
} from "@sf/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AgGridModule } from "@ag-grid-community/angular";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { OrganizationApiTokensComponent } from "./components/organization-api-tokens/organization-api-tokens.component";
import { UserSecurityComponent } from "./components/user-security/user-security.component";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
    imports: [
        CommonModule,
        SfCommonModule,
        FormsModule,
        ModalsModule,
        SelectModule,
        FontAwesomeModule,
        AgGridModule,
        ReactiveFormsModule,
        NgbModule,
        NgbTooltipModule,
        SfFormsModule,
        ScrollingModule,
        CommonPipesModule
    ],
    exports: [
        OrganizationApiTokensComponent,
        UserSecurityComponent
    ],
    declarations: [
        UserSecurityComponent,
        OrganizationApiTokensComponent
    ],
    providers: []

})
export class UserorgCoreModule {
    constructor() {}
}
