export interface UiApiNotificationEditorTestResult {
    status: Status;
    description: string;
    capturedApiLogEntries: LogApiEntry[];
}

export interface LogApiEntry {
    id: string;
    requestHeaders: any;
    responseHeaders: any;
    paramMapValues: any;
    req: string;
    resp: string;
    uri: string;
}

export enum Status {
    FAILED = "FAILED",
    SUCCEEDED = "SUCCEEDED",
    INCONCLUSIVE = "INCONCLUSIVE"
}
