/**
 * This is a 'drop-down' selector of users
 * This is only for super users
 * It allows searching for any user in the system.
 * Single-select only. For multi-select, use user-multi-select.component.
 */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { LoggerService, SortUtilitiesService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { SelectableUser, User } from "@sf/userorg/common";
import { UserService } from "@sf/userorg/common";
import { RecentUsersService } from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-user-search-selector",
    templateUrl: "./user-search-selector.component.html",
    styleUrls: ["./user-search-selector.component.scss"]
})
export class UserSearchSelectorComponent
    implements OnInit, OnChanges, OnDestroy
{
    /* Inputs */
    @Input()
    selectedUserID: string; // (optional) (single select) userID initially selected
    @Input()
    autoInitialSelect: boolean; // initially select an item - determined by selectedUserID, else first item in list
    @Input()
    searchLimit: number; // (optional - default is 25) max number of users to show in list
    @Input()
    canClearSelectedUser: boolean; // whether to show an 'X' to clear selection
    @Input()
    hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input()
    required: boolean = null; // if required in form
    @Input()
    placeholder: string; // (optional) placeholder - will be defaulted
    @Input()
    windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'

    /* Outputs */
    @Output()
    select: EventEmitter<any> = new EventEmitter<any>(); // passes a User

    /** PRIVATE **/
    private _onDestroy: Subject<void> = new Subject<void>();

    /** PUBLIC **/
    users: User[] = [];
    selectedUser: User = null;
    selectableItems: SelectableUser[] = [];

    constructor(
            private _userOrgService: UserOrgService,
            private userService: UserService,
            private logger: LoggerService,
            private _router: Router,
            private _activatedRoute: ActivatedRoute,
            private _recentUsersService: RecentUsersService
    ) {}

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = "Select User...";
        }
        if (!this.windowClass) {
            this.windowClass = "not-full-width";
        }
        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        this.populateUsers({}).subscribe(() => {
            this.selectSelectedItem();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedUserID &&
                changes.selectedUserID.currentValue &&
                changes.selectedUserID.currentValue !== changes.selectedUserID.previousValue) {
            //do nothing
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getUsers(event?: any): Subscription {
        return this.populateUsers(event).subscribe(() => {
            // nothing
        });
    }

    selectSelectedItem() {
        // figure out which user should be initially selected
        let currentItem: SelectableUser = null;
        if (this.selectedUserID) {
            currentItem = this.selectableItems.find((user) => {
                return user.id === this.selectedUserID;
            });
        }

        if (!currentItem && this.selectedUserID) {
            // we get here if the user with the selected ID is not in the list of selectable users,
            // so get the user and add it to the list
            this._userOrgService
                .getUser(this.selectedUserID)
                .subscribe((results: User) => {
                    if (!this.users) {
                        this.users = [results];
                    } else {
                        this.users.splice(0, 0, results);
                    }
                    // need new array here
                    let selectableUser = this._makeSelectableUser(results);
                    let newSelectionList = [selectableUser]; // need to create new array for watchers
                    this.selectableItems = newSelectionList.concat(
                        this.selectableItems
                    );
                    this.handleUserSelection({
                        $selection: selectableUser
                    });
                });
        } else if (this.autoInitialSelect && this.users.length > 0) {
            if (this.selectedUserID) {
                // select the user with the selected ID
                this.handleUserSelection({
                    $selection: {
                        id: this.selectedUserID,
                        label: null
                    }
                });
            } else {
                // select the first user in the list
                this.handleUserSelection({
                    $selection: {
                        id: this.selectableItems[0].id,
                        label: this.selectableItems[0].label
                    }
                });
            }
        } else if (this.selectedUserID) {
            // normal case - if we have a selected ID, select that one
            this.handleUserSelection({
                $selection: {
                    id: this.selectedUserID,
                    label: null
                }
            });
        } else {
            // this.selectedUserID is not set, but never happens
        }
    }

    populateUsers(event?: any): Observable<User[]> {
        let searchValue = event.$searchValue;

        // if user entered search criteria
        if (
            searchValue !== null &&
            searchValue !== "" &&
            searchValue !== undefined
        ) {
            // do a search
            return this.userService.search(searchValue, this.searchLimit).pipe(
                tap((results: User[]) => {
                    //this.users = this._sortUsers(results);
                    this.users = results;
                    this.selectableItems = this.users.map(
                        this._makeSelectableUser,
                        this
                    );

                    if (this.selectedUser) {
                        let foundSelection = this.selectableItems.find(
                            (user) => {
                                return user.id === this.selectedUser.id;
                            }
                        );
                        if (!foundSelection) {
                            this.selectableItems.push(
                                this._makeSelectableUser(this.selectedUser)
                            );
                        }
                    }
                })
            );
        }

        //use most recently accessed users
        return this._recentUsersService.getPastRecentUsers().pipe(
            tap((results: User[]) => {
                //this.users = this._sortUsers(results);
                this.users = results;
                this.selectableItems = this.users.map(
                    this._makeSelectableUser,
                    this
                );
            })
        );
    }

    private _sortUsers(unsorted: User[]): User[] {
        let sorted: User[] = unsorted.sort((user1, user2) => {
            return SortUtilitiesService.stringSortCompareInsensitive(
                user1.name.first + " " + user1.name.last,
                user2.name.first + " " + user2.name.last
            );
        });
        return sorted;
    }

    handleUserSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            let selection: SelectableUser = selectionObject;
            let user: User = null;
            if (selection && selection.id) {
                user = this.findUserInList(selection.id);
            }
            this.selectedUser = user; // may be null, which is fine
            if (this.select) {
                this.select.emit(user);
            }
        }
    }

    private findUserInList(userID: string): User {
        let user: User = this.users.find((localUser) => {
            return localUser.id === userID;
        });
        return user;
    }

    private _makeSelectableUser(u: User): SelectableUser {
        return {
            id: u.id,
            label: u.name.first + " " + u.name.last + " (" + u.id + ")",
            selected: false
        };
    }
}
