import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

interface PackageMessage {
    packageName: string;
    packageRecipient: string;
    reason: string;
}

@Component({
    selector: "sf-unaffected-package-update-dialog",
    templateUrl: "./unaffected-package-update-dialog.component.html",
    styleUrls: ["./unaffected-package-update-dialog.component.scss"]
})
export class UnaffectedPackageUpdateDialogComponent implements OnInit {
    @Input()
    unaffectedPackageMessages: PackageMessage[];

    modalTitle: string;
    primaryButton: ModalButton;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.modalTitle = "Unaffected Packages - Default Account Update";
        this.primaryButton = {
            text: "OK",
            hidden: false,
            disabled: false
        };
    }
}
