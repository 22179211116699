import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { filter, map, share } from "rxjs/operators";

export enum BroadcastType {
    RELOAD_SIGNING_LIST,
    SHOW_DATA_ENTRY_TOUR,
    SHOW_SERVICER_MODAL
}

@Injectable({
    providedIn: "root"
})
export class EsignBroadcastService {
    private _eventBus = new Subject<{ key: BroadcastType; data: any }>();

    constructor() {}

    broadcast(key: BroadcastType, data?: any) {
        this._eventBus.next({ key, data });
    }

    on(key: BroadcastType) {
        return this._eventBus.pipe(
            filter((event) => event.key === key),
            map((event) => event.data),
            share()
        );
    }
}
