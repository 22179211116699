<div class="header sf-user-center">
    <div class="row header-padding">
        <div class="d-flex align-items-center">
            <!-- <sf-search-control></sf-search-control> -->

            <!-- Ensure the video link is the left-most icon, except for the search control -->
             <ng-container *ngIf="pageData?.videoLink && !pageData?.customVideo">
                 <sf-video-control [videoLink]="pageData.videoLink"></sf-video-control>
             </ng-container>

            <!-- <sf-notifications-control></sf-notifications-control> -->
            <!-- <sf-page-settings-control></sf-page-settings-control> -->
            <ng-content></ng-content>
            <sf-help-control
                [helpLink]="pageData?.helpLink"
                [documentationLink]="documentationLink"
                [additionalOptions]="pageData?.additionalHelpLinks"
            ></sf-help-control>
            <sf-contact-control></sf-contact-control>
            <sf-user-settings-control></sf-user-settings-control>
        </div>
    </div>
</div>
