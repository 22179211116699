import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { first, mapTo, switchMap, tap } from "rxjs/operators";
import { AuthService } from "@sf/common";
import { LoginService } from "../services/login.service";
import { SocketService } from "@sf/common";
import { SocketMessageType } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class LogoutIfValidSessionGuard
    implements CanActivate, CanActivateChild
{
    constructor(
        private _authService: AuthService,
        private _loginService: LoginService,
        private _socket: SocketService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._logout();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._logout();
    }

    private _logout(): Observable<boolean> {
        return this._authService.auth.pipe(
            first(),
            switchMap((auth) => {
                if (auth.data.authenticated === true) {
                    return this._loginService.logout().pipe(
                        tap(() => {
                            this._socket.close();
                            this._authService.pushAuth({
                                type: SocketMessageType.AUTH,
                                data: { authenticated: false }
                            });
                            sf.session = null;
                        }),
                        mapTo(true)
                    );
                }
                this._socket.close();
                return of(true);
            })
        );
    }
}
