import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";

@Component({
    selector: "sf-edit-submitter-settings-dialog",
    templateUrl: "./recipient-submitter-settings-dialog.component.html",
    styleUrls: ["./recipient-submitter-settings-dialog.component.scss"]
})
export class RecipientSubmitterSettingsDialogComponent implements OnInit {
    /** Public Variables **/
    submitterSettings: any[];
    submitterValues: any;
    recipientName: string;
    saveCallback?(): boolean;
    // Required Modal Properties
    primary: ModalButton;
    secondary: ModalButton;
    title: string;

    constructor() {}

    ngOnInit() {
        this.title = "Edit Settings";
        this.primary = {
            text: "Save",
            responseValue: "save"
        };
        if (this.saveCallback) {
            this.primary.callback = this.saveCallback;
        }

        this.secondary = {
            text: "Cancel"
        };
    }
}
