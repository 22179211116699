import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class JspNavigationService {
    constructor(private _router: Router) {}

    navigateToJsp(url: string) {
        if (url.includes(".jsp") || url.includes(".action")) {
            this._router.navigate(["legacy"], {
                queryParams: this._processLegacyUrl(url)
            });
        } else {
            window.location.assign(url);
        }
    }

    private _processLegacyUrl(quailUrl: string, name?: string) {
        let urlSplit = quailUrl.split("?");
        let url = urlSplit[0];

        let queryParams: { url: string; name?: string; query?: string } = {
            url: url
        };
        if (name) {
            queryParams.name = name;
        }
        if (urlSplit[1]) {
            const queryParts = urlSplit[1].split("&");
            let query: string[] = [];
            queryParts.forEach((part) => {
                if (!part.includes("nav=") && !part.includes("name=")) {
                    query.push(part);
                } else if (part.includes("name=")) {
                    const nameParts = part.split("=");
                    queryParams.name = nameParts[1];
                }
            });

            queryParams.query = query.join("&");
        }

        return queryParams;
    }
}
