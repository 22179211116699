import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    BannerNotification,
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType,
    BannerProgressBar
} from "@sf/userorg/common";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-notification-banner-item",
    templateUrl: "./notification-banner-item.component.html",
    styleUrls: ["./notification-banner-item.component.scss"]
})
export class NotificationBannerItemComponent implements OnInit, OnDestroy, BannerNotification {
    @Input() type: BannerNotificationType;
    @Input() category: BannerNotificationCategory;
    @Input() destinationUrl: string;
    @Input() message: string;
    @Input() clickHandler: () => void;
    @Input() closeHandler: () => void;
    @Input() triggerClose$?: Observable<void>;
    @Input() extras?: any;
    @Input() disableTooltip?: boolean;
    @Input() progressBar?: BannerProgressBar;

    private _onDestroy$ = new Subject<void>();

    public showBanner = true;
    public dismissible = true;

    constructor(private _bannerNotificationService: BannerNotificationService) {}

    ngOnInit() {
        if (this.triggerClose$) {
            log.info(this.triggerClose$);
            this.triggerClose$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
                this.close(null);
            });
        }

        if (this.category === BannerNotificationCategory.PROGRESS_BAR && this.progressBar) {
            this._bannerNotificationService.bannerProgressBar
                    .pipe(takeUntil(this._onDestroy$))
                    .subscribe((value) => (this.progressBar.value = value))
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    click($event: Event) {
        if ($event) {
            let foundClose: EventTarget = null;
            // error checking required for old browsers
            if ($event.composedPath) {
                let path: EventTarget[] = $event.composedPath();
                if (path) {
                    // screwy bug in new version of Angular libraries now sends us a 'click' event when 'close' is clicked
                    foundClose = path.find((target: Element) => {
                        return (target.localName == "button" &&
                            target.className &&
                            target.className.startsWith("close"));
                    });
                }
            }
            if (foundClose) {
                this.close($event);
            } else if (!!this.clickHandler) {
                $event.stopPropagation();
                this.clickHandler();
            }
        }
    }

    close($event: Event) {
        if ($event) {
            $event.stopPropagation();
        }
        this.closeHandler();
        this.showBanner = false;
    }
}
