import { Pipe, PipeTransform } from "@angular/core";
import { dayjs } from "../plugins/dayjs/index";

/*
 * Used to format a date string as a dayjs object, optionally using a format param
 */
@Pipe({
    name: "formatDate"
})
export class FormatDatePipe implements PipeTransform {
    transform(dateString: string, format: string = "MM/DD/YYYY"): dayjs.Dayjs {
        return dayjs(dateString, format);
    }
}
