export enum CustomFieldType {
    DATE = "DATE",
    DATERANGE = "DATERANGE",
    TIME = "TIME",
    TIMESTAMP = "TIMESTAMP",
    MULTITITLE = "MULTITITLE",
    NUMERIC = "NUMERIC",
    CURRENCY = "CURRENCY",
    ZIP = "ZIP",
    STATE = "STATE",
    BOOK_TYPE = "BOOK_TYPE",
    DOC_TYPE = "DOC_TYPE",
    PARTY_NAME = "PARTY_NAME",
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    BOOLEAN = "BOOLEAN",
    DOC_IN_PACKAGE = "DOC_IN_PACKAGE",
    PARAGRAPH = "PARAGRAPH",
    PHONE_NUMBER = "PHONE_NUMBER",

    HIDDEN = "HIDDEN",
    BUTTON = "BUTTON",
    UPLOAD = "UPLOAD",

    RADIO = "RADIO",
    CHECKBOX = "CHECKBOX",
    SELECT = "SELECT",
    MULTISELECT = "MULTISELECT"
}
