import { ConditionRule, FieldViewState } from "../interfaces";

export class IsNullRule implements ConditionRule {
    match(value: any, viewState?: FieldViewState): boolean {
        return value === null || value === undefined || value === "";
    }

    static staticMatch(value: any): boolean {
        return value === null || value === undefined || value === "";
    }
}
