import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { Auth } from "./interfaces/auth.interface";

export interface AppBootstrapService {
    bootstrapAfterLogin(auth: Auth): Observable<boolean>;
    initFullStory(): void;
}

export const APP_BOOTSTRAP_SERVICE = new InjectionToken<AppBootstrapService>(
    "APP_BOOTSTRAP_SERVICE"
);
