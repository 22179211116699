/**
 * This is an 'enhanced' user selector
 * It always allows selecting multiple users
 * It is not a 'drop-down', since it is always dropped down
 * It works for super-users or customer users, based on the 'allOptions' input
 */
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import { removeByValue } from "@sf/common";
import { SessionService, SortUtilitiesService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { SelectableUser, User } from "@sf/userorg/common";
import { UserService } from "@sf/userorg/common";
import { first } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-userorg-user-multi-selector",
    templateUrl: "./user-multi-selector.component.html",
    styleUrls: ["./user-multi-selector.component.scss"]
})
export class UserMultiSelectorComponent implements OnInit, OnChanges {
    @Input() initialSelection: string[]; // array of user IDs
    @Input() allOptions: SelectableUser[];
    @Input() options: SelectableUser[]; // optional array of initially selectable users
    @Input() lock: boolean; // if true, displays only a header, and no selections
    @Input() isSimplifile: boolean;

    @Output()
    changed: EventEmitter<{ $selection: string[]; }> = new EventEmitter(); // sends selected user IDs
    @Output()
    changedObjects: EventEmitter<{ $selection: SelectableUser[]; }> = new EventEmitter(); // sends selected user objects

    isSuperUser = false;
    searchDelay: any = null;

    showingResults = true;
    isTyping = false;
    searching = true;

    /** Public Variables **/
    usersList: SelectableUser[] = [];
    filteredUsers: SelectableUser[] = [];
    searchText = { text: "" };
    selectedViewOption = "all";
    selectedUsers: SelectableUser[] = [];

    @ViewChildren("checkbox") checkboxes: QueryList<ElementRef>;

    constructor(
        private sessionService: SessionService,
        private userorgService: UserOrgService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.isSuperUser = this.sessionService.isSuperUser();

        if (!this.allOptions || !this.allOptions.length) {
            this.allOptions = this.options;
        }

        this.isSimplifile = !!this.isSimplifile;

        this.searchText.text = "";
        this.newUsersReceived(this.options, this.allOptions);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            if (!this.allOptions || !this.allOptions.length) {
                this.allOptions = this.options;
            }
            this.searchText.text = "";
            this.newUsersReceived(this.options, this.allOptions);
        }
    }

    setInitialSelection() {
        if (this.initialSelection) {
            this.initialSelection.forEach((userID) => {
                let user = this.findDisplayedUser(userID);
                if (user) {
                    this.addSelectedItem(user);
                }
            });
        }
    }

    headerToggle() {
        // nothing
    }

    calculateTooltip(user: SelectableUser): string {
        let tooltip: string = user.label;
        return tooltip;
    }

    clearSelection() {
        if (this.lock) {
            return; // can't change
        }
        if (!this.selectedUsers.length) {
            return; // already clear
        }
        this.selectedUsers.length = 0;
        this.usersList.forEach((user: SelectableUser) => {
            user.selected = false;
        });
        window.setTimeout(() => {
            this.selectedViewOption = "all"; // back
        }, 1);
        this.changed.emit({ $selection: [] });
        this.changedObjects.emit({ $selection: [] });
    }

    onSearchChange(/*event*/) {
        this.filterTextChanged();
    }

    // get the 'selection' text to display
    selectionText(): string {
        let selText: string;
        if (!this.selectedUsers || this.selectedUsers.length == 0) {
            selText = "No user selected";
        } else if (this.selectedUsers.length == 1) {
            selText = this.selectedUsers[0].label;
        } else {
            selText = "" + this.selectedUsers.length + " users selected";
        }
        return selText;
    }

    addSelectedItem(selectedItem: SelectableUser) {
        let isInSelected = this.findSelectedUser(selectedItem.id);
        if (!isInSelected) {
            selectedItem.selected = true;
            this.selectedUsers.push(selectedItem);
        }
    }

    removeSelectedItem(selectedItem: SelectableUser) {
        if (!selectedItem) {
            return;
        }
        let foundItem = this.findSelectedUser(selectedItem.id);
        if (foundItem) {
            removeByValue(this.selectedUsers, foundItem);
        }
    }

    private getSelectedItem(checkbox: any): SelectableUser {
        if (!checkbox) {
            return null;
        }
        let itemValue = checkbox.value;
        let itemLabel: string = null;

        let siblings = checkbox.parentElement.children;
        let sibling = null;
        for (let i = 0; i < siblings.length; i++) {
            sibling = siblings[i];
            if (sibling.localName == "span") {
                itemLabel = sibling.innerText;
                if (itemLabel) {
                    itemLabel = itemLabel.trim();
                }
            }
        }
        let result: SelectableUser = {
            id: itemValue,
            label: itemLabel,
            selected: false
        };
        return result;
    }

    // called whenever a checkbox is checked
    checkChange(event: any) {
        let checkbox = event.target;
        let selectedItem: SelectableUser = this.getSelectedItem(checkbox);

        window.setTimeout(() => {
            let isSelected = this.alreadySelected(selectedItem.id);
            let user = this.findDisplayedUser(selectedItem.id);
            if (checkbox.checked) {
                if (user) {
                    user.selected = true;   // in case it wasn't already checked
                }
                if (selectedItem) {
                    selectedItem.selected = true;
                    this.addSelectedItem(selectedItem);
                }
            } else {
                // de-select from the displayed list, if it's still visible
                if (user) {
                    user.selected = false; // uncheck it
                }
                if (selectedItem) {
                    selectedItem.selected = true;
                    this.removeSelectedItem(selectedItem);
                }
            }

            let selectedIDs: string[] = [];
            this.selectedUsers.map((user: SelectableUser) => {
                selectedIDs.push(user.id);
            });
            this.changed.emit({ $selection: selectedIDs });
            this.changedObjects.emit({ $selection: this.selectedUsers });
        });
    }

    alreadySelected(userID: string): boolean {
        let match = this.selectedUsers.find((v) => {
            return v.id == userID;
        });
        return match != null;
    }

    findDisplayedUser(userID: string): SelectableUser {
        if (this.usersList) {
            for (let i = 0; i < this.usersList.length; i++) {
                let user = this.usersList[i];
                if (userID == user.id) {
                    return user;
                }
            }
        }
        return null;
    }

    findSelectedUser(userID: string): SelectableUser {
        if (this.selectedUsers) {
            for (let i = 0; i < this.selectedUsers.length; i++) {
                let user = this.selectedUsers[i];
                if (userID == user.id) {
                    return user;
                }
            }
        }
        return null;
    }

    syncModel(allUsersList: SelectableUser[]) {
        this.selectedUsers.forEach((selectedUser: SelectableUser) => {
            let user = this.findDisplayedUser(selectedUser.id);
            if (user) {
                user.selected = true;
                this.addSelectedItem(user);
            } else {
                this.removeSelectedItem(user);
            }
        });
    }

    cancelSearch() {
        this.searchText.text = "";
        this.newUsersReceived(this.options, this.allOptions);
    }

    filterUsers() {
        if (this.isSuperUser) {
            this.filteredUsers = this.usersList;
            return;
        }

        let escaped = this.getEscapedSearchFilter(); // trim the search string

        if (!escaped || escaped == "") {
            this.filteredUsers = this.usersList;
        } else {
            this.filteredUsers = [];
            let max = this.usersList.length;
            if (max > 100) {
                max = 100;
            }
            let ri = new RegExp(escaped, "i"); // get the regex ready - ignore case.
            for (let i = 0; i < max; i++) {
                let item: SelectableUser = this.usersList[i];
                if (ri.test(item.label) || ri.test(item.id)) {
                    // Matched. Add to the list
                    this.filteredUsers.push(item);
                }
            }
        }
    }

    filterTextChanged() {
        let escaped = this.getEscapedSearchFilter();

        this.isTyping = true;
        if (this.searchDelay) {
            clearTimeout(this.searchDelay);
        }
        this.searchDelay = window.setTimeout(
            () => {
                this.showingResults = true;
                this.isTyping = false;
                this.searching = true;

                if (escaped == "") {
                    this.cancelSearch();
                } else {
                    let selectableUsers: SelectableUser[] = [];
                    if (this.isSuperUser) {
                        if (this.isSimplifile) {
                            this.userService
                                .searchUsersWithEmail(
                                    escaped,
                                    50,
                                    "@simplifile.com"
                                )
                                .subscribe((foundUsers: any[]) => {
                                    foundUsers.map((user) => {
                                        selectableUsers.push(
                                            this.makeSelectableUser(user)
                                        );
                                    });
                                    this.newUsersReceived(selectableUsers, []);
                                });
                        } else {
                            this.userService
                                .search(escaped, 50)
                                .subscribe((foundUsers: User[]) => {
                                    foundUsers.map((user) => {
                                        selectableUsers.push(
                                            this.makeSelectableUser(user)
                                        );
                                    });
                                    this.newUsersReceived(selectableUsers, []);
                                });
                        }
                    } else {
                        this.userorgService
                            .getUsersForCurrentUserByQuery(escaped)
                            .pipe(first())
                            .subscribe((foundUsers: any[]) => {
                                foundUsers.map((user) => {
                                    selectableUsers.push(
                                        this.makeSelectableUser(user)
                                    );
                                });
                                this.newUsersReceived(selectableUsers, []);
                            });
                    }
                }
            },
            escaped == "" ? 0 : 350
        );

        /*  // if you just want to filter the users in the initial list
        } else {
            this.filterUsers();
        }
        */

        window.setTimeout(() => {
            this.selectedViewOption = "all"; // back
        }, 400);
    }

    getEscapedSearchFilter(): string {
        if (this.searchText == null || this.searchText.text == null) {
            return "";
        }
        //let escaped = this.searchText.text.replace(/[[\]{}()*+?\\^$|#]/g, "");
        let escaped = this.searchText.text.trim();
        return escaped;
    }

    //
    syncChecks(allUserData: SelectableUser[]) {
        allUserData.forEach((user: SelectableUser) => {
            user.selected = this.alreadySelected(user.id);
        });
    }

    makeSelectableUser(user: User): SelectableUser {
        let displayName = "";
        if (user.name) {
            displayName = user.name.first + " " + user.name.last;
        }
        let selectableOrg: SelectableUser = {
            id: user.id,
            label: displayName + " (" + user.id + ")",
            selected: false
        };
        return selectableOrg;
    }

    newUsersReceived(
        userData: SelectableUser[],
        allUserData: SelectableUser[]
    ): SelectableUser[] {
        let newUsers = userData;
        if (allUserData && userData) {
            allUserData.forEach((option) => {
                let found = newUsers.find((exo) => {
                    return exo.id == option.id;
                });
                if (!found) {
                    newUsers.push(option);
                }
            });
        } else if (!newUsers) {
            newUsers = allUserData;
        }

        if (!newUsers) {
            // still starting up, or nothing to display
            return[];
        }

        this.syncChecks(newUsers);

        // now sort by name (case insensitive)
        newUsers = newUsers.sort((a, b) => {
            return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
        });

        this.usersList = newUsers;
        this.filterUsers();

        this.syncModel(newUsers);
        this.setInitialSelection();

        return newUsers;
    }
}
