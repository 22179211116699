import { Injectable } from "@angular/core";
import { ProductBasic, ProductExtended } from "@sf/userorg/common";
import { SelectableItemWithID } from "@sf/common";
import { Contract } from "@sf/userorg/common";
import { PendingInvitation } from "@sf/userorg/common";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// prettier-ignore
// This is only used by the ORGANIZATION SERVICES page
export interface OrganizationServicesEnhanced {
    confirmProductActivation(product: OrgServicesPageProduct, action: string, onSuccess: Function): boolean;
    enableOrg(enable: boolean, onSuccess: Function): void;
    inviteAction(product: OrgServicesPageProduct, action: string, onSuccess: Function): void;
    confirmEndTrial(product: OrgServicesPageProduct, onSuccess: Function): void;
    addIntegration(product: OrgServicesPageProduct, onSuccess: Function): void;
    removeIntegration(integration: any, onSuccess: Function): void;
    uploadAgreement(orgID: string, product: ProductBasic, isActive: boolean, onSuccess: Function): void;
    uploadMSA(orgID: string, onSuccess: Function): void;
    doInviteCancel(product: OrgServicesPageProduct, invitation: any, onSuccess: Function): void;
}

export interface OrgServicesButton {
    text: string;
    icon: IconProp;
    parameter: string;
}

export interface OrgServicesPageProduct extends ProductExtended {
    userCountClickUrl?: string;
    clickUrl?: string;
    clickParams?: any;
    hidden?: boolean;
    hasContract?: boolean;
    isMissingSetup?: boolean;
    pendingContractID?: string;
    invitePending?: boolean;
    canInvite?: boolean;
    canModify?: boolean;
    lastColumn?: {
        icon?: IconProp;
        class?: string;
        tooltip?: string;
        clickUrl?: string;
        clickFunction?: any;
    };
    parent?: OrgServicesPageProduct;
    isCollapsed?: boolean;
    statusBadgeClass?: string;
    labelTooltip?: string;
    tooltip?: string;
    badgeText?: string;
    buttons?: OrgServicesButton[];
}

@Injectable({
    providedIn: "root"
})
export class OrganizationServicesCoreService {
    private msa: Contract = null;
    private products: OrgServicesPageProduct[] = [];
    private serviceInvitations: PendingInvitation[] = [];

    private disabledReasons: SelectableItemWithID[] = [
        { id: "COUNTY_REQUEST", label: "County Request" },
        { id: "CUSTOMER_REQUEST", label: "Customer Request" },
        { id: "DUPLICATE_ORGANIZATION", label: "Duplicate Organization" },
        { id: "FRAUD", label: "Fraud" },
        { id: "INACTIVITY", label: "Inactivity" },
        { id: "LEGAL_ACTION", label: "Legal Action by Court/Law Enforcement" },
        { id: "OUT_OF_BUSINESS", label: "No Longer in Business" },
        { id: "NO_USERS", label: "No Users in Organization" },
        { id: "ON_WATCHLIST", label: "On Watchlist" },
        { id: "PAYMENT_ISSUES", label: "Payment Issues" },
        { id: "PRICING_ISSUES", label: "Pricing Issues" },
        { id: "UNPAID_LICENSE_FEE", label: "Unpaid License Fee" },
        { id: "OTHER", label: "Other" }
    ];

    private suspendedReasons: SelectableItemWithID[] = [
        ...this.disabledReasons,
        {
            id: "PRICE_INCREASE",
            label: "Failure to Accept a Recent Price Increase"
        },
        { id: "EXPIRED_LICENSE", label: "Expired License" },
        { id: "UNPAID_LICENSE_FEE", label: "Unpaid License Fee" },
        { id: "PAST_DUE_ACH_PAYMENT", label: "Past Due ACH Payments" },
        { id: "PAST_DUE_CARD_PAYMENT", label: "Past Due Credit Card Payments" },
        { id: "PAST_DUE_ECHECK_PAYMENT", label: "Past Due eCheck Payments" },
        { id: "PAST_DUE_INVOICE_PAYMENT", label: "Past Due Invoice Payments" }
    ];

    constructor() {}

    setProducts(products: OrgServicesPageProduct[]) {
        this.products = products;
    }

    getProducts(): OrgServicesPageProduct[] {
        return this.products;
    }

    getServiceInvitations(): PendingInvitation[] {
        return this.serviceInvitations;
    }

    setServiceInvitations(serviceInvitations: PendingInvitation[]) {
        this.serviceInvitations = serviceInvitations;
    }

    getDisabledReasons(): SelectableItemWithID[] {
        return this.disabledReasons;
    }

    getFriendlyDisabledReason(statusReason: string): string {
        let disabledReason = this.suspendedReasons.find((reason) => {
            return reason.id == statusReason;
        });
        return disabledReason ? disabledReason.label : null;
    }

    getMsa(): Contract {
        return this.msa;
    }

    setMsa(msa: Contract) {
        this.msa = msa;
    }

    traverseForProductID(productID: string): OrgServicesPageProduct {
        return this.internalTraverse(this.products, productID);
    }

    traverseForProductIDInList(
        products: OrgServicesPageProduct[],
        productID: string
    ): OrgServicesPageProduct {
        return this.internalTraverse(products, productID);
    }

    private internalTraverse(
        products: OrgServicesPageProduct[],
        productID: string
    ): OrgServicesPageProduct {
        for (let i = 0; i < products.length; i++) {
            let product = products[i];
            if (product) {
                if (product.productID == productID) {
                    return product;
                }
                if (product.childProducts) {
                    product = this.internalTraverse(
                        product.childProducts,
                        productID
                    );
                    if (product) {
                        return product;
                    }
                }
            }
        }
        return null;
    }

    // see if the organization has ANY active product
    hasActiveProduct(): boolean {
        return this.internalHas(this.products);
    }

    private internalHas(products: OrgServicesPageProduct[]): boolean {
        for (let i = 0; i < products.length; i++) {
            let product = products[i];
            if (product) {
                if (
                    product.productID != "organization" &&
                    product.status === "ACTIVE"
                ) {
                    return true;
                }
                if (product.childProducts) {
                    let has = this.internalHas(product.childProducts);
                    if (has) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    findServiceInvitation(service: string): PendingInvitation {
        if (!this.serviceInvitations) {
            return null;
        }

        return this.serviceInvitations.find((invitation: PendingInvitation) => {
            return invitation.service == service;
        });
    }
}
