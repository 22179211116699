<sf-modal-header title="Fee Details"></sf-modal-header>
<div class="modal-body sf-unmask" id="fee-details-dialog">
    <table>
        <tr>
            <td class="pending-details-header">Submitter</td>
            <td>{{submitterName}}</td>
        </tr>
        <tr *ngIf="recipientName">
            <td class="pending-details-header">Recipient</td>
            <td>{{recipientName}}</td>
        </tr>
        <tr *ngIf="recipientName">
            <td class="pending-details-header">Package</td>
            <td>{{packageName}}</td>
        </tr>
        <tr>
            <td class="pending-details-header">Date</td>
            <td>{{date}}</td>
        </tr>
    </table>
    <hr>
    <table>
        <tr>
            <td class="pending-details-feeDesc pending-details-pb"><b>Fee Description</b></td>
            <td class="pending-details-amount pending-details-pb"><b>Amount</b></td>
            <td class="pending-details-paymentAccount pending-details-pb"><b>Payment Account</b></td>
        </tr>
        <tr *ngIf="recordingFees">
            <td class="pending-details-feeDesc pending-details-pb">Recording Fees</td>
            <td class="pending-details-amount pending-details-pb">{{recordingFees | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
        <tr *ngIf="tax">
            <td class="pending-details-feeDesc pending-details-pb">Taxes</td>
            <td class="pending-details-amount pending-details-pb">{{tax | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}e</td>
        </tr>
        <tr *ngIf="submissionFees">
            <td class="pending-details-feeDesc pending-details-pb">Submission Fees</td>
            <td class="pending-details-amount pending-details-pb">{{submissionFees | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
        <tr *ngIf="mailFee">
            <td class="pending-details-feeDesc pending-details-pb">Mail Fees</td>
            <td class="pending-details-amount pending-details-pb">{{mailFee | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
        <tr *ngIf="salesTax">
            <td class="pending-details-feeDesc pending-details-pb">Sales Tax</td>
            <td class="pending-details-amount pending-details-pb">{{salesTax | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
        <tr *ngIf="surchargeAmount">
            <td class="pending-details-feeDesc pending-details-pb">Surcharge ({{surchargePercentage}}%)</td>
            <td class="pending-details-amount pending-details-pb">{{surchargeAmount | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
        <tr *ngIf="otherFees">
            <td class="pending-details-feeDesc pending-details-pb">Other</td>
            <td class="pending-details-amount pending-details-pb">{{otherFees | currency}}</td>
            <td class="pending-details-paymentAccount pending-details-pb sf-mask">{{paymentAccountName}}</td>
        </tr>
    </table>
    <hr>
    <table>
        <tr>
            <td class="pending-details-feeDesc"><b>Total</b></td>
            <td class="pending-details-amount"><b>{{total | currency}}</b></td>
        </tr>
    </table>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary" [tertiary]="tertiary"></sf-modal-footer>
