<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <p>
        {{ recipient.name }} requires your organization to register before you
        can submit documents for eRecording.
        <span *ngIf="userHasRegistrationPermission"
            >Please click below to begin the registration process.</span
        >
        <span *ngIf="!userHasRegistrationPermission"
            >Please contact you administrator so they may complete the
            registration process</span
        >
    </p>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
