import { Observable } from "rxjs";

export abstract class AbstractCountiesDataService {
    protected constructor() {}

    abstract setAllCountiesPrintScale(
        orgID: string,
        printScale: number
    ): Observable<boolean | void>;
    abstract setCountyPrintScale(
        orgID: string,
        countyIDs: string[],
        printScale: number
    ): Observable<boolean | void>;
}
