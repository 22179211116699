import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class SharedCustomerSurveyService {
    constructor(private _rpcClient: RpcClientService) {}

    sendSurveyTrigger(trigger: string, organizationID: string) {
        this._rpcClient
            .makeRequest("userorg", "processSurveyTrigger", {
                trigger,
                organizationID
            })
            .subscribe();
    }
}
