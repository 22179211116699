import { Injectable } from "@angular/core";
import { GrowlService, SessionService, UserSettingsService } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EsignDocumentService } from "./esign-document.service";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType
} from "@sf/userorg/common";
import { AnalysisSubscriptionService } from "./analysis-subscription.service";
import { Router } from "@angular/router";
import { finalize, takeWhile } from "rxjs/operators";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AnalysisUploadService {
    constructor(
        private _userSettingsService: UserSettingsService,
        private _growlService: GrowlService,
        private _ngbModal: NgbModal,
        private _esignDocumentService: EsignDocumentService,
        private _sessionService: SessionService,
        private _bannerNotificationService: BannerNotificationService,
        private _analysisSubscriptionService: AnalysisSubscriptionService,
        private _router: Router
    ) {}

    private _uploadData: { name: string; content: string }[];

    uploadFiles(
        submitterId: string,
        projectId: string,
        fileInput: HTMLInputElement,
        userId?: string
    ) {
        let files = fileInput.files;
        if (files.length > 0) {
            // only allow files with pdf extension
            for (let i = 0; i < files.length; i++) {
                if (files[i].type != "application/pdf") {
                    this._growlService.error(
                        "Only PDF file upload is supported"
                    );
                    return;
                }
            }

            this._uploadData = [];
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                this._readFile(
                    file,
                    files.length,
                    submitterId,
                    projectId,
                    fileInput,
                    userId
                );
            }
        }
        this.showProgressBarBanner(0, files.length);
        let uploadStatusUpdate$ = new Subject<void>();
        let hasError = false;
        let errors: string[] = [];
        this._analysisSubscriptionService
            .getUploadStatusUpdate$(this._sessionService.getUserID())
            .pipe(
                takeWhile((data) => !data?.complete, true),
                finalize(() => {
                    uploadStatusUpdate$.complete();
                    if (hasError) {
                        this._displayUploadError(submitterId, errors);
                    }
                })
            )
            .subscribe((data) => {
                uploadStatusUpdate$.next();
                // notification comes when document is starting, so complete count will be one less
                let value = data.complete ? data.current : data.current - 1;
                this._bannerNotificationService.updateBannerProgressBar(value);
                if (data.complete) {
                    this.showProgressBarBanner(value, data.total);
                }
                if (!!data.error) {
                    errors.push(data.error);
                }
                hasError = hasError || !!data.error;
            });
        return uploadStatusUpdate$.asObservable();
    }

    _readFile(
        file: File,
        count: number,
        submitterId: string,
        projectId: string,
        fileInput: HTMLInputElement,
        userId?: string
    ) {
        let reader = new FileReader();
        reader.onloadend = () => {
            let dataUrl = reader.result as string;
            this._uploadData.push({
                name: file.name,
                content: dataUrl.split("base64,")[1]
            });
            if (this._uploadData.length === count) {
                let fileContents = this._uploadData.map((data) => data.content);
                let filenames = this._uploadData.map((data) => data.name);
                // all files have been read
                this._esignDocumentService
                    .analyzePDFsAndCreateDocsUI(
                        submitterId,
                        undefined,
                        projectId,
                        fileContents,
                        filenames,
                        userId
                    )
                    .subscribe();
                fileInput.value = "";
            }
        };
        reader.readAsDataURL(file); // IE doesn't support readAsBinaryString...
    }

    _displayUploadError(submitterId: string, errors: string[]) {
        let errorsDisplay: Set<string> = new Set<string>();
        let errorDisplay: string;
        errors.forEach((error) => {
            if (error === "No Analysis Mapping matched this document.") {
                errorsDisplay.add(
                    "This document is not recognized. Contact support to correct the issue."
                );
            } else if (
                error ===
                "The name of the document could not be found on the document."
            ) {
                errorsDisplay.add(
                    "The name of the document could not be found on the document. Contact support to correct the issue."
                );
            } else if (error.startsWith("Duplicate document")) {
                errorsDisplay.add(
                    "This document is a duplicate of a document you have already uploaded."
                );
            }
        });

        if (errorsDisplay.size === 0) {
            errorDisplay = "One or more files had an analysis mapping error.";
        } else if (errorsDisplay.size === 1) {
            errorsDisplay.forEach((error) => {
                errorDisplay = error;
            });
        } else {
            errorDisplay = "The following errors have occurred:";
            errorsDisplay.forEach((error) => {
                errorDisplay += "<br><br>• " + error;
            });
        }
        if (
            this._sessionService.hasAnyPermission(
                [
                    "third_party_documents_manage_unsignable",
                    "admin_signing_support"
                ],
                submitterId
            )
        ) {
            errorDisplay = `${errorDisplay}<br><br>Click <a href="/sf/ui/signing/unsignable-list" target="_blank">here</a> to view.`;
        }
        this._growlService.error(errorDisplay, "", {
            enableHtml: true
        });
    }

    showProgressBarBanner(value: number, max: number) {
        this._bannerNotificationService.removeBannerOfCategory(
            BannerNotificationCategory.PROGRESS_BAR
        );
        let complete = value === max;
        let url = "signing/manage-list";
        this._bannerNotificationService.push({
            type: complete
                ? BannerNotificationType.SUCCESS
                : BannerNotificationType.INFO,
            category: BannerNotificationCategory.PROGRESS_BAR,
            message:
                value === max
                    ? "Document upload complete"
                    : "Document upload in progress",
            destinationUrl: url,
            clickHandler: () => {
                this._router.navigateByUrl(url);
            },
            closeHandler: () => {},
            disableTooltip: true,
            progressBar: { value, max }
        });

        if (complete) {
            setTimeout(() => {
                this._bannerNotificationService.removeBannerOfCategory(
                    BannerNotificationCategory.PROGRESS_BAR
                );
            }, 3000);
        }
    }
}
