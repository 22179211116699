import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    BannerNotification,
    BannerNotificationCategory,
    BannerNotificationService,
    BannerNotificationType,
    ContractService,
    OrganizationSubscriptionService,
    OrganizationSuspensionService,
    PaymentAccountsService,
    Product,
    ProductsMissingDefaultLicenseAccount,
    RoleService,
    SuspendedService,
    TokenService,
    UserDetails,
    UserDetailsUser,
    UserorgMessagesService,
    UserOrgService
} from "@sf/userorg/common";
import { Observable, Subject } from "rxjs";
import { NotificationBannerItemComponent } from "../notification-banner-item/notification-banner-item.component";
import { first, takeUntil } from "rxjs/operators";
import {
    ConfirmationModalComponent,
    IeNotSupportedDialogComponent,
    QueryStringService,
    SessionService,
    SfValidators,
    UserSettingsService
} from "@sf/common";
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterEvent
} from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubmitterOrganizationService } from "@sf/submitter/common";
import { SuspendedServiceDialogComponent } from "../../dialogs/suspended-service-dialog/suspended-service-dialog.component";
import { AddMobilePhoneDialogComponent } from "../../dialogs/add-mobile-phone-dialog/add-mobile-phone-dialog.component";
import { VerifyPhoneNumberDialogComponent } from "../../dialogs/verify-phone-number-dialog/verify-phone-number-dialog.component";
import { LimitedTrialDialogComponent } from "../../dialogs/limited-trial-dialog/limited-trial-dialog.component";
import { AdminSettingsESignEventService } from "@sf/tagging-shared";

interface BannerOfCategory {
    category: BannerNotificationCategory;
    component: NotificationBannerItemComponent;
}

declare const window: any;

/**
 * This component is loaded when the app starts, and creates all the banners that should be displayed for the user.
 * Only the components that are common for ALL services are loaded here.
 * SubmitterNotificationBannerGuard creates Submitter-specific banners.
 * Banners are created here, but this is not a service to call from other components. See BannerNotificationService.
 */
// prettier-ignore
@Component({
    selector: "sf-notification-banner",
    templateUrl: "./notification-banner.component.html",
    styleUrls: ["./notification-banner.component.scss"]
})
export class NotificationBannerComponent implements OnInit, OnDestroy {
    bannersAddQueue$: Observable<BannerNotification>;
    bannersRemoveQueue$: Observable<BannerNotificationCategory>;
    bannerCategoryChecked$: Observable<BannerNotificationCategory>;
    bannerRecheckCategory$: Observable<BannerNotificationCategory>;

    @ViewChild("bannerContainer", { read: ViewContainerRef, static: true })
    container: ViewContainerRef;

    private _$ngOnDestroy: Subject<void> = new Subject();
    private bannerImpl: Type<BannerNotification>;
    private factory: ComponentFactory<BannerNotification>;
    private bannersOfCategory: BannerOfCategory[] = [];
    private routerEvents: any;
    private currentUrl: string = null;
    private hotCategoriesChecked = false;

    constructor(
            private resolver: ComponentFactoryResolver,
            private bannerNotificationService: BannerNotificationService,
            private userorgService: UserOrgService,
            private router: Router,
            private route: ActivatedRoute,
            private sessionService: SessionService,
            private userSettingsService: UserSettingsService,
            private userorgMessagesService: UserorgMessagesService,
            private userorgSuspensionService: OrganizationSuspensionService,
            private submitterOrganizationService: SubmitterOrganizationService,
            private userorgRolesService: RoleService,
            private contractService: ContractService,
            private paymentAccountsService: PaymentAccountsService,
            private organizationSubscriptionService: OrganizationSubscriptionService,
            private modalService: NgbModal,
            private queryStringService: QueryStringService,
            private tokenService: TokenService,
            private eeSettingsService: AdminSettingsESignEventService
    ) {}

    ngOnInit() {
        this.bannersAddQueue$ = this.bannerNotificationService.bannerAddQueue;
        this.bannersRemoveQueue$ = this.bannerNotificationService.bannerRemoveQueue;
        this.bannerCategoryChecked$ = this.bannerNotificationService.bannerCategoryChecked;
        this.bannerRecheckCategory$ = this.bannerNotificationService.bannerRecheckQueue;

        this.bannersAddQueue$
            .pipe(takeUntil(this._$ngOnDestroy))
            .subscribe((banner) => {
                this.addNewBanner(banner);
            });

        this.bannersRemoveQueue$
            .pipe(takeUntil(this._$ngOnDestroy))
            .subscribe((category) => {
                this.removeBannerOfCategory(category);
            });

        this.bannerCategoryChecked$
            .pipe(takeUntil(this._$ngOnDestroy))
            .subscribe((category) => {
                this.categoryWasChecked(category);
            });

        this.bannerRecheckCategory$
            .pipe(takeUntil(this._$ngOnDestroy))
            .subscribe((category) => {
                this.recheckCategory(category);
            });

        // add any existing banners that may have been created before this component got created
        // for example: in the submitter-notification-banner-guard
        let existingBanners = this.bannerNotificationService.getAllBanners();
        if (existingBanners) {
            existingBanners.forEach((banner) => {
                this.addNewBanner(banner);
            });
        }

        // This watches all navigation to see if we have just gone to a page where a banner should be hidden
        this.currentUrl = this.router.routerState.snapshot.url;
        // do once now to catch going directly to a page where a banner might show initially
        this.checkCurrentPageForAllCategories();
        this.router.events
            .pipe(takeUntil(this._$ngOnDestroy))
            .subscribe((event: RouterEvent): void => {
                if (event instanceof NavigationEnd) {
                    this.currentUrl = this.router.routerState.snapshot.url;
                    this.checkCurrentPageForAllCategories();
                }
            });

        // add banners as necessary
        // We check for 'red' banners first. When done we will check for 'orange' banners
        window.setTimeout(() => {
            this.checkForHotNotifications();
        }, 0);
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
        this._$ngOnDestroy.complete();
    }

    checkForHotNotifications() {
        // this.addTestBanners();      // temp for testing
        this.checkCriticalMessages();
        this.checkCreditCardsExpired();
        this.checkSuspended();
    }

    checkForNotifications() {
        this.checkContracts();
        this.checkTrial();
        this.checkPaymentAccount();
        this.checkCreditCardsExpiring();
        this.checkRoleInvitation();
        this.checkSecondFactorPhone();
        this.checkInternetExplorer();
    }

    recheckCategory(category: BannerNotificationCategory) {
        switch (category) {
            case BannerNotificationCategory.ACCEPT_CONTRACT:
                this.checkContracts();
                break;
            case BannerNotificationCategory.SERVICE_TRIAL:
                this.checkTrial();
                break;
            case BannerNotificationCategory.CRITICAL:
                this.checkCriticalMessages();
                break;
            case BannerNotificationCategory.PAYMENT_ACCOUNTS:
                this.checkPaymentAccount();
                break;
            case BannerNotificationCategory.CREDIT_CARD_EXPIRED:
                this.checkCreditCardsExpired();
                break;
            case BannerNotificationCategory.CREDIT_CARD_EXPIRING:
                this.checkCreditCardsExpiring();
                break;
            case BannerNotificationCategory.ROLE_INVITATION:
                this.checkRoleInvitation();
                break;
            case BannerNotificationCategory.SUSPENDED_SERVICE:
                this.checkSuspendedService();
                break;
            case BannerNotificationCategory.INTERNET_EXPLORER:
                this.checkInternetExplorer();
                break;
            case BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT:
                this.checkLicenseFeeDefaults();
                break;
            default:
                // other categories may be submitter-specific
                // see submitter-notification-banner-guard.ts
                break;
        }
    }

    checkCurrentPageForAllCategories() {
        // iterate through all the enumerations
        const categoryKeys = Object.keys(BannerNotificationCategory);
        const anyCategory: any = BannerNotificationCategory as any;
        categoryKeys.forEach((key, index) => {
            let category: BannerNotificationCategory = anyCategory[key];
            this.checkCurrentPage(category);
        });
    }

    checkCurrentPage(category: BannerNotificationCategory) {
        if (!this.currentUrl) {
            return;
        }
        let bannerOfCategory = this.findBannerOfCategory(category);
        if (!bannerOfCategory) {
            return;
        }

        if (this.currentUrl.includes("invitations/")) {
            // kind of a hack, but hide all banners on 'invitations' pages
            bannerOfCategory.component.showBanner = false;
            return;
        }

        if (!bannerOfCategory.component.destinationUrl) {
            return;
        }
        //this checks both ways on the urls - as sometimes the url can contain additional information (like org id) that causes it to fail one way
        bannerOfCategory.component.showBanner = !(this.currentUrl.startsWith(bannerOfCategory.component.destinationUrl) || bannerOfCategory.component.destinationUrl.startsWith(this.currentUrl));
    }

    addNewBanner(banner: BannerNotification) {
        if (banner.category != BannerNotificationCategory.CUSTOM) {
            this.removeBannerOfCategory(banner.category);
        }

        //this.bannerImpl = banner.component || NotificationBannerItemComponent;
        this.bannerImpl = NotificationBannerItemComponent;
        this.factory = this.resolver.resolveComponentFactory(this.bannerImpl);

        const componentRef = this.container.createComponent(this.factory);
        const instance = componentRef.instance as NotificationBannerItemComponent;

        instance.type = banner.type;
        instance.category = banner.category;
        instance.message = banner.message;
        instance.destinationUrl = banner.destinationUrl;
        instance.clickHandler = banner.clickHandler;
        instance.closeHandler = () => {
            banner.closeHandler();
            componentRef.destroy();
        };
        if (banner.triggerClose) {
            instance.triggerClose$ = banner.triggerClose;
        }
        if (banner.extras) {
            instance.extras = banner.extras;
        }
        instance.disableTooltip = banner.disableTooltip;
        if (banner.category === BannerNotificationCategory.PROGRESS_BAR) {
            instance.progressBar = banner.progressBar;
        }

        this.bannersOfCategory.push({
            category: banner.category,
            component: instance
        });

        // see if should be visible on the current page
        this.checkCurrentPage(banner.category);
    }

    findBannerOfCategory(category: BannerNotificationCategory): BannerOfCategory {
        let existing: BannerOfCategory = null;
        let existingIndex = this.findBannerOfCategoryIndex(category);
        if (existingIndex >= 0) {
            existing = this.bannersOfCategory[existingIndex];
        }
        return existing;
    }

    findBannerOfCategoryIndex(category: BannerNotificationCategory): number {
        let existingIndex = -1;
        for (let i = 0; i < this.bannersOfCategory.length; i++) {
            let banner = this.bannersOfCategory[i];
            if (banner.category == category) {
                existingIndex = i;
            }
        }
        return existingIndex;
    }

    removeBannerOfCategory(category: BannerNotificationCategory) {
        if (category == BannerNotificationCategory.CUSTOM) {
            return; // can have multiple 'CUSTOM' banners
        }
        let existingIndex = this.findBannerOfCategoryIndex(category);
        let existing: BannerOfCategory = null;
        if (existingIndex >= 0) {
            existing = this.bannersOfCategory[existingIndex];
        }
        if (existing) {
            existing.component.close(null);
            this.bannersOfCategory.splice(existingIndex, 1);
        }
    }

    categoryWasChecked(category: BannerNotificationCategory) {
        // see if we have finished checking for 'hot' categories
        if (!this.hotCategoriesChecked) {
            if (this.bannerNotificationService.wasCategoryChecked(BannerNotificationCategory.SUSPENDED_SERVICE) &&
                this.bannerNotificationService.wasCategoryChecked(BannerNotificationCategory.CRITICAL) &&
                this.bannerNotificationService.wasCategoryChecked(BannerNotificationCategory.CREDIT_CARD_EXPIRED)) {
                this.hotCategoriesChecked = true;

                // now we can check for 'warm' notifications
                this.checkForNotifications();
            }
        }
    }

    checkCriticalMessages() {
        this.removeBannerOfCategory(BannerNotificationCategory.CRITICAL);

        this.userorgMessagesService.getCriticalMessagesForCurrentUser().then((results: any) => {
            if (results && results.length) {
                let criticalMessageLabel = "" + results;

                this.bannerNotificationService.push({
                    type: BannerNotificationType.DANGER,
                    category: BannerNotificationCategory.CRITICAL,
                    message: criticalMessageLabel,
                    destinationUrl: null,
                    clickHandler: null,
                    closeHandler: () => {
                        this.userorgMessagesService.closeCriticalMessagesForCurrentUser();
                    }
                });
            }
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.CRITICAL);
        });
    }

    // see if user belongs to an organization that is missing a payment account
    checkPaymentAccount() {
        this.bannerNotificationService.removeBannerOfCategory(BannerNotificationCategory.PAYMENT_ACCOUNTS);

        this.userorgService.currentUserMissingPaymentAccount()
            .pipe(first())
            .subscribe((response: any) => {
                let bannerShown = false;
                if (response) {
                    let paymentOrgID = response.orgID;
                    let missing = "Something";
                    let permissions = null;
                    let paymentUrl: string = null;

                    if (response.missing == "account") {
                        permissions = ["organization_accounting"];
                        paymentUrl = "/submitter/payments/manage/" + paymentOrgID;
                        if (response.product == "eSign Events" &&
                                this.sessionService.hasPermission("esign_event_admin", paymentOrgID)) {
                            paymentUrl = "/sign-event/payments/accounts";
                        }
                        missing = "A payment account";
                        if (!!response.product) {
                            missing += (" for " + response.product);
                        }
                    } else if (response.missing == "contact") {
                        missing = "Contact information";
                        permissions = ["organization_info"];
                        paymentUrl = "/submitter/organization/" + paymentOrgID + "/contact";
                    } else if (response.missing == "email") {
                        missing = "An email address";
                        permissions = ["organization_info"];
                        paymentUrl = "/submitter/organization/" + paymentOrgID + "/contact";
                    } else if (response.missing == "address") {
                        missing = "A complete mailing address";
                        permissions = ["organization_info"];
                        paymentUrl = "/submitter/organization/" + paymentOrgID + "/contact";
                    } else if (response.missing == "phone") {
                        missing = "A phone number";
                        permissions = ["organization_info"];
                        paymentUrl = "/submitter/organization/" + paymentOrgID + "/contact";
                    }

                    if (permissions) {
                        let name = this.sessionService.getOrganizationName(paymentOrgID);
                        if (!name) {
                            name = paymentOrgID;
                        } else {
                            name = this.escapeHTML(name);
                        }
                        let paymentAccountLabel = missing + " is missing for " + name + ".";
                        let hasPaymentAccountPermission = false;

                        if (paymentUrl && this.sessionService.hasAnyPermission(permissions, paymentOrgID)) {
                            hasPaymentAccountPermission = true;
                            paymentAccountLabel += " Click here to add.";
                        }

                        let clickHandler = () => {
                            this.router.navigate([paymentUrl], {
                                relativeTo: this.route
                            });
                        };

                        this.bannerNotificationService.push({
                            type: BannerNotificationType.WARNING,
                            category: BannerNotificationCategory.PAYMENT_ACCOUNTS,
                            message: paymentAccountLabel,
                            destinationUrl: paymentUrl,
                            clickHandler: hasPaymentAccountPermission ? clickHandler : null,
                            closeHandler: () => {
                            }
                        });
                        bannerShown = true;
                    }
                }

                if (!bannerShown) {
                    // no banner of this type, so check 'default' account
                    this.checkLicenseFeeDefaults();
                }

                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.PAYMENT_ACCOUNTS);
            });
    }

    checkCreditCardsExpired() {
        this.removeBannerOfCategory(BannerNotificationCategory.CREDIT_CARD_EXPIRED);

        let orgIDs: string[] = this.sessionService.getOrganizationIDsWithPermission("organization_accounting");
        if (!orgIDs || !orgIDs.length) {
            // need to indicate that we checked for it
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.CREDIT_CARD_EXPIRED);
            return;
        }

        this.paymentAccountsService.getExpiredCreditCard(orgIDs)
            .subscribe((results: any) => {
                if (results) {
                    let orgName = this.sessionService.getOrganizationName(results.organizationID);
                    orgName = this.escapeHTML(orgName);
                    let cardMessageLabel = "The credit card " + results.label + " has expired for " + orgName +
                        ". Please click here to update.";
                    //let acceptUrl = "/submitter/organization/" + results.organizationID + "/e-record/payment-accounts";
                    let acceptUrl = "/submitter/payments/manage/" + results.organizationID;

                    this.bannerNotificationService.push({
                        type: results.active ? BannerNotificationType.DANGER : BannerNotificationType.WARNING,
                        category: BannerNotificationCategory.CREDIT_CARD_EXPIRED,
                        message: cardMessageLabel,
                        destinationUrl: acceptUrl,
                        clickHandler: () => {
                            this.router.navigate([acceptUrl], {
                                relativeTo: this.route
                            });
                        },
                        closeHandler: () => {
                        }
                    });
                }
                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.CREDIT_CARD_EXPIRED);
            });
    }

    checkCreditCardsExpiring() {
        this.removeBannerOfCategory(BannerNotificationCategory.CREDIT_CARD_EXPIRING);

        let orgIDs: string[] = this.sessionService.getOrganizationIDsWithPermission("organization_accounting");
        if (!orgIDs || !orgIDs.length) {
            // need to indicate that we checked for it
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.CREDIT_CARD_EXPIRING);
            return;
        }

        this.paymentAccountsService.getExpiringCreditCard(orgIDs)
            .subscribe((results: any) => {
                if (results) {
                    let orgName = this.sessionService.getOrganizationName(results.organizationID);
                    orgName = this.escapeHTML(orgName);
                    let cardMessageLabel = "The credit card " + results.label + " is expiring soon for " + orgName +
                        ". Please click here to update.";
                    //let acceptUrl = "/submitter/organization/" + results.organizationID + "/e-record/payment-accounts";
                    let acceptUrl = "/submitter/payments/manage/" + results.organizationID;

                    this.bannerNotificationService.push({
                        type: BannerNotificationType.WARNING,
                        category: BannerNotificationCategory.CREDIT_CARD_EXPIRING,
                        message: cardMessageLabel,
                        destinationUrl: acceptUrl,
                        clickHandler: () => {
                            this.router.navigate([acceptUrl], {
                                relativeTo: this.route
                            });
                        },
                        closeHandler: () => {
                        }
                    });
                }
                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.CREDIT_CARD_EXPIRING);
            });
    }

    checkSuspended() {
        this.checkSuspendedService();
    }

    checkInternetExplorer() {
        this.removeBannerOfCategory(BannerNotificationCategory.INTERNET_EXPLORER);

        let restrictedParam = this.queryStringService.getQueryParam("restricted");
        let isEncompass = !!restrictedParam;

        if (!isEncompass) {
            let isIE = window.bowser.name.search(/Internet Explorer/i) >= 0;
            if (isIE) {
                this.bannerNotificationService.push({
                    type: BannerNotificationType.DANGER,
                    category: BannerNotificationCategory.INTERNET_EXPLORER,
                    message: "Internet Explorer is no longer a supported web browser.",
                    destinationUrl: "https://www.microsoft.com/en-us/edge",
                    clickHandler: (() => {
                        const modalRef = this.modalService.open(IeNotSupportedDialogComponent, {
                            backdrop: "static",             // block close by clicking out
                            windowClass: "ie-bad-dialog"    // specify size
                        });
                    }),
                    closeHandler: () => {
                    }
                });
            }
        }

        this.bannerNotificationService.markCategoryChecked(
            BannerNotificationCategory.INTERNET_EXPLORER
        );
    }

    checkSuspendedService() {
        this.removeBannerOfCategory(BannerNotificationCategory.SUSPENDED_SERVICE);

        // only show a banner here if the service is suspended
        this.userorgSuspensionService.areAnyServicesSuspended()
            .pipe(first())
            .subscribe((suspendedServices: SuspendedService[]) => {
                if (suspendedServices && suspendedServices.length) {
                    let suspendedOrgID: string = suspendedServices[0].orgID;
                    let suspendedServiceID: string = suspendedServices[0].serviceID;
                    let suspendedOrgName = suspendedServices[0].orgName;
                    suspendedOrgName = this.escapeHTML(suspendedOrgName);
                    let suspendedServiceName = suspendedServices[0].serviceName;

                    let destinationUrl: string = null;
                    let suspendedServiceLabel = "" + suspendedServiceName + " service is suspended for " +
                            suspendedOrgName + ".";
                    suspendedServiceLabel += " Click to learn more.";
                    let handler = (() => {
                        const modalRef = this.modalService.open(SuspendedServiceDialogComponent);
                    });
                    if (suspendedServices.length > 1) {
                        suspendedServiceLabel = "You have " + suspendedServices.length + " suspended services."
                        suspendedServiceLabel += " Click to learn more.";
                    } else {
                        if (suspendedServices[0].suspendedReason == "Expired License") {
                            suspendedServiceLabel = "We have suspended your " + suspendedServiceName +
                                    " service due to an expired license.";
                            suspendedServiceLabel += " Click here to view your license.";
                            destinationUrl = this.buildLicenseUrl(suspendedServices[0]);
                            handler = (() => {
                                this.router.navigate([destinationUrl], {
                                    relativeTo: this.route
                                });
                            });
                        } else if (suspendedServices[0].suspendedReason == "Price Increase") {
                            suspendedServiceLabel = "We have suspended your " + suspendedServiceName +
                                    " service because you have not accepted a recent price increase.";
                            destinationUrl = this.buildLicenseUrl(suspendedServices[0]);
                            handler = (() => {
                                this.router.navigate([destinationUrl], {
                                    relativeTo: this.route
                                });
                            });
                        }
                    }

                    this.bannerNotificationService.push({
                        type: BannerNotificationType.DANGER,
                        category: BannerNotificationCategory.SUSPENDED_SERVICE,
                        message: suspendedServiceLabel,
                        destinationUrl: destinationUrl,
                        clickHandler: handler,
                        closeHandler: () => {
                        }
                    });
                }
            });
        this.bannerNotificationService.markCategoryChecked(
                BannerNotificationCategory.SUSPENDED_SERVICE
        );
    }

    buildLicenseUrl(suspendedService: SuspendedService): string {
        let licenseUrl: string;
        let hasSubmitter = this.sessionService.hasProductInAnyOrg("submitter");
        switch (suspendedService.serviceID) {
            case "licensed_collaborator_settlement":
                licenseUrl = "/lender/organization/" + suspendedService.orgID + "/settlement-license";
                break;
            case "licensed_collaborator_lender":
                licenseUrl = "/lender/organization/" + suspendedService.orgID + "/license";
                break;
            case "submitter_vendor":
                licenseUrl = "/vendor/organization/" + suspendedService.orgID + "/license-submitter";
                break;
            case "recipient_vendor":
                licenseUrl = "/vendor/organization/" + suspendedService.orgID + "/license-recipient";
                break;
            case "lender_vendor":
                licenseUrl = "/vendor/organization/" + suspendedService.orgID + "/license-lender";
                break;
            case "notary_vendor":
                licenseUrl = "/vendor/organization/" + suspendedService.orgID + "/license-notary";
                break;
            case "esign_events_vendor":
                licenseUrl = "/vendor/organization/" + suspendedService.orgID + "/license-esign-events";
                break;
            case "trustee":
                if (hasSubmitter) {
                    licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/trustee/license";
                } else {
                    licenseUrl = "/lender/organization/" + suspendedService.orgID + "/trustee-license";
                }
                break;
            case "notary":
                if (hasSubmitter) {
                    licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/notary-license";
                } else {
                    licenseUrl = "/lender/organization/" + suspendedService.orgID + "/notary-license";
                }
                break;
            case "esign_event":
                if (hasSubmitter) {
                    licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/sign-event/license";
                } else {
                    licenseUrl = "/lender/organization/" + suspendedService.orgID + "/esign-event-license";
                }
                break;
            case "submitter_paper":
                licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/paper/license";
                break;
            case "submitter_signing":
                licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/document-builder/license";
                break;
            case "submitter":
            default:
                licenseUrl = "/submitter/organization/" + suspendedService.orgID + "/e-record/license";
                break;
        }
        return licenseUrl;
    }

    // see if user has been invited to a new role
    checkRoleInvitation() {
        this.removeBannerOfCategory(BannerNotificationCategory.ROLE_INVITATION);

        this.userorgRolesService
            .currentUserHasPendingRoles()
            .pipe(first()).subscribe((has: boolean) => {
                if (has) {
                    let userInvitationTokenID = has;
                    let inviteLabel =
                        "You have been invited to a new role. Click here to accept or reject.";
                    let acceptUrl =
                        "/invitations/new-user/" + userInvitationTokenID;

                    this.bannerNotificationService.push({
                        type: BannerNotificationType.WARNING,
                        category: BannerNotificationCategory.ROLE_INVITATION,
                        message: inviteLabel,
                        destinationUrl: "/invitations",
                        clickHandler: () => {
                            this.router.navigate([acceptUrl], {
                                relativeTo: this.route
                            });
                        },
                        closeHandler: () => {}
                    });
                }
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.ROLE_INVITATION);
            });
    }

    // check if user has no mobile phone, and second factor login is required
    checkSecondFactorPhone() {
        this.removeBannerOfCategory(BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE);

        // real checks
        if (this.userSettingsService.getUserSettingBoolean("disable_mobile_phone_banner")) {
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE);
        } else {
            this.userorgService.getUserDetails().subscribe((details: UserDetails) => {
                if (details && details.user && (!details.user.mobilePhoneString || !details.user.mobilePhoneString.length)) {
                    this.userorgService.doesUserRequireSecondFactor().subscribe((result: any) => {
                        if (result) {
                            this.bannerNotificationService.push({
                                type: BannerNotificationType.INFO,
                                category: BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE,
                                message: "You can now use your mobile phone for login authentication. Click here to add a mobile phone number.",
                                destinationUrl: null,
                                clickHandler: () => {
                                    this.promptForMobilePhone(details.user);
                                },
                                closeHandler: () => {
                                    this.turnOffMobilePhoneBanner();
                                }
                            });
                        }
                    });
                }
                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE);
            }, () => {
                // error
                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE);
            });
        }
    }

    promptForMobilePhone(user: UserDetailsUser) {
        this.removeBannerOfCategory(BannerNotificationCategory.SECOND_FACTOR_MOBILE_PHONE);

        const modalRef = this.modalService.open(AddMobilePhoneDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.phoneNumber = null;
        modalInstance.addRemindLater = true;

        modalRef.result.then((results: any) => {
            if (results) {
                let newNumber = results.number;
                let numbers = SfValidators.getNumericDigits(newNumber);
                if (numbers && numbers.length == 10) {
                    user.mobilePhoneString = newNumber;
                    let areaCode = numbers.substring(0, 3);
                    let prefix = numbers.substring(3, 6);
                    let suffix = numbers.substring(6, 10);
                    user.mobilePhone = {
                        areaCode: areaCode,
                        extension: null,
                        prefix: prefix,
                        suffix: suffix
                    };
                    this.userorgService.setUserDetails(user).subscribe();
                    this.verifyNewMobilePhone(newNumber, results.destination);
                } else {
                    // don't do anything - will prompt again later
                }
            } else {
                this.turnOffMobilePhoneBanner();
            }
        }, () => {
            this.turnOffMobilePhoneBanner();
        });
    }

    turnOffMobilePhoneBanner() {
        this.userSettingsService.setUserSetting("disable_mobile_phone_banner", true);
    }

    verifyNewMobilePhone(numberToTest: string, destination: string) {
        const modalRef = this.modalService.open(VerifyPhoneNumberDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.phoneNumber = numberToTest;
        modalInstance.destination = destination;

        modalRef.result.then((newNumber: string) => {
            if (newNumber) {
                this.successMessage("Mobile Phone Verified", "Success! " +
                        "Your mobile phone number is now verified and can be used as an authentication option to log into Simplifile.")
            }
        }, () => {
            // cancel
        });
    }

    successMessage(title: string, message: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = title;
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
        modalInstance.message = message;
    }

    // see user belongs to an org with a limited trial active
    checkTrial() {
        this.removeBannerOfCategory(BannerNotificationCategory.SERVICE_TRIAL);

        if (this.sessionService.isSuperUser()) {
            // indicate that we checked for it
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.SERVICE_TRIAL);
            return; // exit here if superuser
        }

        // check for service in limited trial
        this.userorgService.findServicesInTrialMode().subscribe((result: any[]) => {
            if (result && result[0]) {
                let organizationID = result[0].organizationID;
                let organizationName = result[0].organizationName;
                let productLabel = result[0].productLabel;
                let productID = result[0].productID;
                let message = "Learn more about your " + productLabel + " limited trial. Click here.";

                this.bannerNotificationService.push({
                    type: BannerNotificationType.INFO,
                    category: BannerNotificationCategory.SERVICE_TRIAL,
                    message: message,
                    destinationUrl: null,
                    clickHandler: () => {
                        const modalRef = this.modalService.open(LimitedTrialDialogComponent, {
                            backdrop: "static"
                        });
                        const modalInstance = modalRef.componentInstance;
                        modalInstance.organizationID = organizationID;
                        modalInstance.organizationName = organizationName;
                        modalInstance.productID = productID;
                        modalInstance.productLabel = productLabel;
                    },
                    closeHandler: () => {}
                });

                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.SERVICE_TRIAL);
            }
        });
    }

    // see if the MSA or a contract addendum is pending
    checkContracts() {
        this.removeBannerOfCategory(BannerNotificationCategory.ACCEPT_CONTRACT);

        if (this.sessionService.isSuperUser()) {
            // indicate that we checked for it
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.ACCEPT_CONTRACT);
            return; // exit here if superuser
        }

        // check for service invitation
        this.tokenService.findServiceInvitationToAccept().subscribe((result: any) => {
            if (result) {
                let orgName = "You have";
                if (result.orgName) {
                    orgName = result.orgName + " has";
                }
                let contractLabel = orgName + " been invited to start using the " + result.product + " service. " +
                    "Click here to accept or reject.";
                let inviteUrl = "/invitations/new-service/" + result.tokenID;

                this.bannerNotificationService.push({
                    type: BannerNotificationType.WARNING,
                    category: BannerNotificationCategory.ACCEPT_CONTRACT,
                    message: contractLabel,
                    destinationUrl: "/invitations",
                    clickHandler: () => {
                        this.router.navigate([inviteUrl], {
                            relativeTo: this.route
                        });
                    },
                    closeHandler: () => {}
                });

                // watch in case that contract is accepted later
                this.organizationSubscriptionService.subscribeToAcceptContract(result.orgID)
                    .pipe(takeUntil(this._$ngOnDestroy))
                    .subscribe((msg: any) => {
                        this.recheckCategory(BannerNotificationCategory.ACCEPT_CONTRACT);
                    });

                this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.ACCEPT_CONTRACT);
            } else {
                this.checkUnsignedContract();
            }
        });
    }

    private checkUnsignedContract() {
        this.contractService.hasAnyContractToSign().subscribe((result: any) => {
            if (result) {
                let contractType = result.contractType;
                let typeLabel = contractType;
                if (contractType) {
                    switch (contractType) {
                        case "MERS_API_INTEGRATION":
                            typeLabel = "MERS Automated Document Request";
                            break;
                        case "DOCUMENT_BUILDER":
                            typeLabel = "Document Builder";
                            break;
                        case "DOC_BUILDER_RON":
                            typeLabel = "Document Builder RON";
                            break;
                        case "ERECORDING":
                            typeLabel = "eRecording";
                            break;
                        case "ESIGN_EVENT":
                            typeLabel = "eSign Events";
                            break;
                        case "LENDER":
                            typeLabel = "Lender";
                            break;
                        case "LIEN_RELEASE":
                            typeLabel = "Automated Document Request";
                            break;
                        case "MSA":
                        case "msa":
                            typeLabel = "Services";
                            break;
                        case "NOTARY":
                            typeLabel = "Notary";
                            break;
                        case "PAPER_RECORDING":
                            typeLabel = "Recording PLUS";
                            break;
                        case "SETTLEMENT":
                            typeLabel = "Settlement Agent";
                            break;
                        case "SUBSCRIPTION":
                            typeLabel = "Subscription";
                            break;
                        case "TRUSTEE":
                            typeLabel = "Trustee";
                            break;
                        default:
                            break;
                    }
                }
                let contractLabel = "You have not yet accepted the " + typeLabel + " Agreement";
                if (result.contractCount && result.contractCount > 1) {
                    contractLabel += " for " + result.contractCount + " organizations";
                } else if (result.orgID) {
                    let name = this.sessionService.getOrganizationName(result.orgID);
                    if (name) {
                        name = this.escapeHTML(name);
                        contractLabel += " for " + name;
                    }
                }
                contractLabel += ". Click here to view.";
                let contractUrl = "/invitations/accept-license/" + result.contractID;

                this.bannerNotificationService.push({
                    type: BannerNotificationType.WARNING,
                    category: BannerNotificationCategory.ACCEPT_CONTRACT,
                    message: contractLabel,
                    destinationUrl: "/invitations",
                    clickHandler: () => {
                        this.router.navigate([contractUrl], {
                            relativeTo: this.route
                        });
                    },
                    closeHandler: () => {}
                });

                // watch in case that contract is accepted later
                this.organizationSubscriptionService.subscribeToAcceptContract(result.orgID)
                    .pipe(takeUntil(this._$ngOnDestroy))
                    .subscribe((msg: any) => {
                        this.recheckCategory(BannerNotificationCategory.ACCEPT_CONTRACT);
                    });
            }
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.ACCEPT_CONTRACT);
        });
    }

    checkLicenseFeeDefaults() {
        this.removeBannerOfCategory(BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT);

        let orgIDs: string[] = this.sessionService.getOrganizationIDsWithPermission("organization_accounting");
        if (!orgIDs || !orgIDs.length || this.sessionService.isSuperUser()) {
            // need to indicate that we checked for it
            this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT);
            return;
        }

        this.paymentAccountsService.getMissingLicenseDefaultPaymentInfo(orgIDs).subscribe((missingInfo: ProductsMissingDefaultLicenseAccount[]) => {
            //check SUBMITTER
            let submitterMissing: ProductsMissingDefaultLicenseAccount = missingInfo.find(info => {
                return info.products.includes(Product.SUBMITTER);
            });
            if (!!submitterMissing) {
                let destURL: string = "/submitter/payments/manage";
                this.bannerNotificationService.push({
                    type: BannerNotificationType.WARNING,
                    category: BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT,
                    message: "A default payment account for the eRecording License Fee is missing. Click here to set a default.",
                    destinationUrl: destURL,
                    clickHandler: () => {
                        this.router.navigate([destURL + "/" + submitterMissing.organizationID], {
                            relativeTo: this.route
                        });
                    },
                    closeHandler: () => {}
                });
            }
            //check SUBMITTER_SIGNING
            let signingMissing: ProductsMissingDefaultLicenseAccount = missingInfo.find(info => {
                return info.products.includes(Product.SUBMITTER_SIGNING);
            });
            if (!!signingMissing) {
                let destURL: string = "/submitter/payments/manage";
                this.bannerNotificationService.push({
                    type: BannerNotificationType.WARNING,
                    category: BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT,
                    message: "A default payment account for the Document Builder License Fee is missing. Click here to set a default.",
                    destinationUrl: destURL,
                    clickHandler: () => {
                        this.router.navigate([destURL + "/" + signingMissing.organizationID], {
                            relativeTo: this.route
                        });
                    },
                    closeHandler: () => {}
                });
            }
            //check ESIGN_EVENTS
            let esignMissing: ProductsMissingDefaultLicenseAccount = missingInfo.find(info => {
                return info.products.includes(Product.ESIGN_EVENT);
            });
            if (!!esignMissing) {
                let destURL: string = "/sign-event/payments/accounts";
                this.bannerNotificationService.push({
                    type: BannerNotificationType.WARNING,
                    category: BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT,
                    message: "A default payment account for the eSign Events License Fee is missing. Click here to set a default.",
                    destinationUrl: destURL,
                    clickHandler: () => {
                        this.router.navigate([destURL + "/" + esignMissing.organizationID], {
                            relativeTo: this.route
                        });
                    },
                    closeHandler: () => {}
                });
            }
        });

        this.bannerNotificationService.markCategoryChecked(BannerNotificationCategory.NO_LICENSE_FEE_DEFAULT);
    }

    escapeHTML(value: string): string {
        if (!value) {
            return value;
        }
        let safe = value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        return safe;
    }

    /*
    addTestBanners() {
        this.bannerNotificationService.push({
            type: BannerNotificationType.WARNING,
            category: BannerNotificationCategory.CUSTOM,
            message: "Warning",
            destinationUrl: "hi",
            clickHandler: () => {},
            closeHandler: () => {}
        });
        this.bannerNotificationService.push({
            type: BannerNotificationType.DANGER,
            category: BannerNotificationCategory.CUSTOM,
            message: "Danger",
            destinationUrl: "hi",
            clickHandler: () => {},
            closeHandler: () => {}
        });
        this.bannerNotificationService.push({
            type: BannerNotificationType.INFO,
            category: BannerNotificationCategory.CUSTOM,
            message: "Info",
            destinationUrl: "hi",
            clickHandler: () => {},
            closeHandler: () => {}
        });
        this.bannerNotificationService.push({
            type: BannerNotificationType.SUCCESS,
            category: BannerNotificationCategory.CUSTOM,
            message: "Success",
            destinationUrl: "hi",
            clickHandler: () => {},
            closeHandler: () => {}
        });
    }
    */
}
