import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Icons } from "../../../interfaces";

@Component({
    selector: "sf-modal-header",
    templateUrl: "./modal-header.component.html",
    styleUrls: ["./modal-header.component.scss"]
})
export class ModalHeaderComponent {
    @Input()
    title: string;
    @Input()
    dismissValue: any;
    @Input()
    titleIcon?: IconProp;
    @Input()
    showDismiss = true;
    @Input()
    helpLink?: {
        url?: string;
        tooltip?: string;
    };

    icons: Icons = {};

    constructor(public modal: NgbActiveModal) {}

    showHelp() {
        let url: string = location.origin + this.helpLink.url;
        window.open(url, "_blank");
    }
}
