import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { UserOrgService } from "./user-org.service";
import { OrganizationEntitlement } from "../interfaces/organization.interface";
import { Product } from "../enums/products";
import { SessionService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class OrganizationAttributesService {
    private _orgAttrsCache: {
        [orgID: string]: Observable<OrganizationEntitlement[]>;
    } = {};

    constructor(
        private _userOrgService: UserOrgService,
        private sessionService: SessionService
    ) {
        this.sessionService.sessionResetEvent.subscribe(() => {
            this.clearCache();
        });
    }

    clearCache() {
        this._orgAttrsCache = {};
    }

    clearCacheForOrg(orgID: string) {
        this._orgAttrsCache[orgID] = undefined;
    }

    hasProduct(orgID: string, product: Product): Observable<boolean> {
        return this._getOrgProducts(orgID).pipe(hasProduct(product));
    }

    hasAnyProduct(orgID: string, products: Product[]): Observable<boolean> {
        return this._getOrgProducts(orgID).pipe(
            map((entitlements) => {
                for (let entitlement of entitlements) {
                    if (!entitlement.product) {
                        continue;
                    }

                    if (products.includes(entitlement.product)) {
                        return true;
                    }
                }

                return false;
            })
        );
    }

    hasProducts(orgID: string, products: Product[]): Observable<boolean> {
        return this._getOrgProducts(orgID).pipe(
            map((entitlements) => {
                let hasProducts: Product[] = [];
                for (let entitlement of entitlements) {
                    if (!entitlement.product) {
                        continue;
                    }

                    if (
                        products.includes(entitlement.product) &&
                        !entitlement.isSuspended
                    ) {
                        hasProducts.push(entitlement.product);
                    }
                }

                return products.length === hasProducts.length;
            })
        );
    }

    getProducts(
        orgID: string,
        products: Product[]
    ): Observable<{ [product: string]: boolean }> {
        // the following line tries to do everything in one statement
        return this._getOrgProducts(orgID).pipe(
            map((entitlements) => {
                let foundProducts: { [product: string]: boolean } = {};
                for (let product of products) {
                    let foundEntitlement = entitlements.find((entitlement) => {
                        // don't worry if it's suspended
                        return (
                            entitlement.product &&
                            entitlement.product === product &&
                            entitlement.status !== "DISABLED"
                        );
                    });

                    foundProducts[product] = !!foundEntitlement;
                }

                return foundProducts;
            })
        );
    }

    private _getOrgProducts(
        orgID: string
    ): Observable<OrganizationEntitlement[]> {
        if (!this._orgAttrsCache[orgID]) {
            this._orgAttrsCache[orgID] = this._userOrgService
                .getOrganizationEntitlements(orgID)
                .pipe(shareReplay(1));
        }

        return this._orgAttrsCache[orgID];
    }
}

function hasProduct(product: Product) {
    return (productObs: Observable<OrganizationEntitlement[]>) =>
        productObs.pipe(
            map(
                (products) =>
                    products.findIndex(
                        (entitlement) =>
                            entitlement.product &&
                            entitlement.product === product &&
                            !entitlement.isSuspended
                    ) !== -1
            )
        );
}
