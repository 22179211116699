import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertModalComponent } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class TrayModuleDescriptionDialogService {
    /** Private Variables **/
    private _DESCRIPTIONS: any = {
        "print-to-simplifile": `<p>Print to Simplifile allows you to create eRecording packages in Simplifile without having to leave the windows application that creates or stores your recordable documents.</p>
        <p>Instead of saving your documents to your desktop, logging in to Simplifile, creating an eRecording package, finding your downloaded documents, and uploading them to the package, you can simply print each document to a simulated printer that creates the package for you and pushes your document to the new package.</p>
        `,
        "scan-to-simplifile": `Scan to Simplifile allows you to scan documents directly into Simplifile without switching to another tool to scan and switching back to Simplifile to upload the scanned documents`,
        "print-and-download": `Package Download and Print (Enhanced) makes it easier to print documents, as well as download documents and reports individually instead of merging them into a single ZIP file.`
    };

    private _TITLES: any = {
        "print-to-simplifile": "Print to Simplifile",
        "scan-to-simplifile": "Scan to Simplifile",
        "print-and-download": "Print and Download (Enhanced)"
    };

    constructor(private modalService: NgbModal) {}

    open(serviceName: string) {
        const modal = this.modalService.open(AlertModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = this._TITLES[serviceName];

        modalInstance.message = this._DESCRIPTIONS[serviceName];
    }
}
