import { Component, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-hidden-field",
    templateUrl: "./hidden-field.component.html",
    styleUrls: ["./hidden-field.component.scss"]
})
export class HiddenFieldComponent extends InputComponent implements OnInit {
    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
    }
}
