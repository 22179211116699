import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-past-due-credit-card-payment-dialog",
    templateUrl: "./past-due-credit-card-payment-dialog.component.html",
    styleUrls: ["./past-due-credit-card-payment-dialog.component.scss"]
})
export class PastDueCreditCardPaymentDialogComponent implements OnInit {
    /** Inputs **/
    @Input() packageID: string;
    @Input() feeTotal: string;
    @Input() creditCardLabel: string;
    @Input() selectedOrgID: string;
    @Input() hasManageAccountPermission: boolean;
    @Input() isFailedLicense: boolean;

    /**Public Variables**/
    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Retry Payment",
            disabled: false,
            callback: this._retryPayment.bind(this)
        };
        if (!this.isFailedLicense && this.packageID) {
            this.secondary = {
                text: "Package Details",
                disabled: false,
                callback: this.viewPackageDetails.bind(this)
            };
        }
        this.tertiary = {
            text: "Close",
            disabled: false,
            callback: this._abort.bind(this)
        };
    }

    /** Public Methods **/
    viewPackageDetails() {
        const url: string = this.packageID
            ? `/sf/ui/submitter/package/${this.packageID}/details`
            : "";
        if (url) {
            window.open(url, "_blank");
        }
    }

    handleMiddleClick(event: any, isPackageDetails: boolean) {
        if (event.button === 1) {
            if (isPackageDetails) {
                this.viewPackageDetails();
            } else {
                this.goToManageAccounts();
            }
        }
    }

    goToManageAccounts() {
        const url: string = this.selectedOrgID
            ? `/sf/ui/submitter/payments/manage/${this.selectedOrgID}`
            : "";
        if (url) {
            window.open(url, "_blank");
        }
    }

    /** Private Methods **/
    private _abort() {
        this._activeModal.close(false);
    }

    private _retryPayment() {
        this._activeModal.close(true);
    }
}
