<sf-modal-header title={{title}}></sf-modal-header>

<div class="modal-body">
<!--     Do not remove this hidden button, it is essential for chat to run. -->
    <button hidden id={{this.liveAgentButtonId}}>Hidden Button</button>
<!--     Do not remove this hidden button, it is essential for chat to run. -->

    <!-- This view is for loading/waiting for something to send -->
    <div *ngIf="currentView === view.loadingView" class="d-flex flex-column" style="margin-top: 10px">
        <div class="d-flex flex-row justify-content-center font-weight-bold">
            <fa-icon icon="spinner" [spin]="true"></fa-icon> {{loadingViewText}}
        </div>
        <div *ngIf="loadingViewSubText" class="d-flex flex-row justify-content-center" style="margin-top: 10px">
            {{loadingViewSubText}}
        </div>
    </div>
    <!-- This view is for if the connection fails for any reason -->
    <div *ngIf="currentView === view.generalError" class="d-flex justify-content-center flex-column m-2">
        <span class="text-center mb-1">{{this.errorMessage}}</span>
        <span class="text-center">Call Now: Our Technical Support team is available Monday - Friday</span>
        <span class="text-center">from 6:00 am to 6:00 pm MT at 1-800-460-5657.</span>
    </div>
    <!-- If the connection is successful show options -->
        <div *ngIf="currentView === view.chooseSupportType">
            <div class="d-flex flex-row">
                <div [ngbTooltip]="this.chatTooltip">
                    <button class="btn support-type-button case-chat-buttons"
                            [ngClass]="{'chat-selected': chatDetailsSelected, 'chat-not-selected': !chatDetailsSelected}"
                            [disabled]="!chatInitInfo.IsChatSupportOpen"
                            (click)="chooseSupportType(supportType.chat)"
                    >
                        <div class="d-flex justify-content-center flex-column">
                            <fa-icon class="fa-3x case-chat-icons support-type-spacing" icon="comments" [fixedWidth]="true"></fa-icon>
                            <span class="font-weight-bold support-type-spacing pb-1">Chat with Us</span>
                            <span class="support-type-spacing font-weight-normal mt-2">Available Monday - Friday from 6:00am to 6:00pm MT</span>
                            <span class="support-type-spacing font-weight-normal font-italic">(Fastest option!)</span>
                        </div>
                    </button>
                </div>
                <div>
                    <button class="btn support-type-button case-chat-buttons"
                            [ngClass]="{'case-selected': caseDetailsSelected, 'case-not-selected': !caseDetailsSelected}"
                            (click)="chooseSupportType(supportType.case)"
                    >
                        <div class="d-flex justify-content-center flex-column">
                            <fa-icon class="fa-3x case-chat-icons support-type-spacing mt-2" icon="ticket-alt" [fixedWidth]="true"></fa-icon>
                            <span class="font-weight-bold">Open a Support</span>
                            <span class="font-weight-bold support-type-spacing">Ticket</span>
                            <span class="support-type-spacing font-weight-normal">For after hours or lower priority issues</span>
                            <span class="support-type-spacing font-weight-normal">Available 24/7, seven days a week</span>
                        </div>
                    </button>
                </div>
                <div>
                    <div  class="phone-button">
                        <div class="d-flex justify-content-center flex-column">
                            <fa-icon class="fa-3x phone-icon phone-spacing pt-3" icon="phone" [fixedWidth]="true"></fa-icon>
                            <span class="phone-spacing font-weight-bold">Give Us a Call</span>
                            <span class="phone-spacing pt-2">1 (800) 460-5657</span>
                            <span class="phone-spacing text-center pt-2">Available Monday - Friday from 6:00am to 6:00pm MT</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Chat Section -->
    <form [formGroup]="chatForm" *ngIf="currentView === view.chatDetails">
        <div *ngIf="!isRecipient">
            <div class="btn-group btn-group-toggle d-flex flex-row topic-button-group mb-2" role="group">
                <button [ngClass]="{'erecord-selected': topicErecordSet, 'erecord-not-selected': !topicErecordSet}"
                        class="btn topic-button d-flex flex-column align-items-center mr-2"
                        (click)="setTopicArea(topicArea.erecording, view.chatDetails)"
                >
                    <div class="topic-icon">
                        <fa-icon class="fa-2x topic-icon" icon="file-alt" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        eRecording
                    </div>
                </button>
                <button [ngClass]="{'capc-not-selected': !topicCapcSet, 'capc-selected': topicCapcSet}"
                        class="btn topic-button d-flex flex-column align-items-center mr-2"
                        (click)="setTopicArea(topicArea.capc, view.chatDetails)"
                >
                    <div>
                        <fa-icon class="fa-2x topic-icon" icon="door-closed" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        Pre-Close and
                    </div>
                    <div>
                        Post-Close
                    </div>
                </button>
                <button [ngClass]="{'esign-not-selected': !topicEsignSet, 'esign-selected': topicEsignSet}"
                        class="btn topic-button d-flex flex-column align-items-center"
                        (click)="setTopicArea(topicArea.esign, view.chatDetails)"
                >
                    <div>
                        <fa-icon class="fa-2x topic-icon" icon="file-signature" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        eSign Events
                    </div>
                </button>
            </div>
            <div *ngIf="this.topicAreaSelected === 'esign'" class="d-flex flex-row align-items-center mt-3 ">
                <label for="liveEvent" class="font-weight-bold mr-4">Are  you currently facilitating a live IPEN or RON event?</label>
                <sf-select
                        [options]="this.liveEventOptions"
                        [isSingleSelect]="true"
                        [hasSelectAll]="false"
                        [hasFilter]="false"
                        trackBy="name"
                        labelBy="name"
                        [required]="true"
                        container="body"
                        formControlName="liveEventSelection"
                        id="liveEvent"
                        class="liveEvent-dropdown"
                >
                </sf-select>
            </div>
            <div *ngIf="this.topicAreaSelected === 'capc'" class="d-flex flex-column mt-3">
                <div class="d-flex flex-row">
                    <label for="loanNumberChat" class="font-weight-bold mr-2 pt-1">What is the loan number associated with your inquiry?</label>
                    <input
                            type="text"
                            class="form-control editable mt-1 loan-number"
                            id="loanNumberChat"
                            formControlName="loanNumber"
                            name="loanNumber"
                            maxlength="200"
                    >
                </div>
                <div class="d-flex flex-row justify-content-end mt-1">
                    <label for="loanNumberChatCheckbox" class="font-weight-bold mr-2">I don't know the loan number.</label>
                    <input
                            type="checkbox"
                            class="form-control editable mt-1"
                            id="loanNumberChatCheckbox"
                            formControlName="loanNumberCheckbox"
                            name="loanNumberCheckbox"
                    >
                </div>
            </div>
            <div *ngIf="this.topicAreaSelected === 'erecording'">
                <div class="font-weight-bold mt-3 mb-2">Where are you eRecording?</div>
                <sf-select
                        [options]="this.states"
                        [isSingleSelect]="true"
                        [hasSelectAll]="false"
                        [hasFilter]="true"
                        placeholder="-- Select a State --"
                        trackBy="abbrev"
                        labelBy="name"
                        [required]="true"
                        [additionalPropertiesToFilter]="['abbrev']"
                        ngbTooltip="Click here to select a state"
                        container="body"
                        formControlName="stateSelection"
                >
                </sf-select>
            </div>
        </div>
        <div class="mt-3">
            <label class="font-weight-bold">How can we help you? Please provide any details that might help us understand your issue.</label>
            <textarea
                    class="form-control h-50"
                    type="text"
                    name="customerDescription"
                    formControlName="customerDescription"
                    maxlength="15000"
            >
            </textarea>
        </div>
        <div *ngIf="chatForm?.controls?.customerDescription?.value?.length >= 15000" class="font-italic mt-1 text-limit">Text limit 15000 characters</div>
    </form>

    <!-- Case Section -->
    <form [formGroup]="caseForm" *ngIf="currentView === view.caseDetails">
        <div class="d-flex flex-row">
            <div class="d-flex flex-column mr-3 w-50">
                <label class="font-weight-bold">First Name</label>
                <div>
                    <input
                            type="text"
                            class="form-control editable"
                            id="firstName"
                            formControlName="firstName"
                            name="firstName"
                            placeholder="First Name"
                            maxlength="200"
                    >
                </div>
            </div>
            <div class="d-flex flex-column w-50">
                <label class="font-weight-bold">Last Name</label>
                <div>
                    <input
                            type="text"
                            class="form-control editable"
                            id="lastName"
                            formControlName="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            maxlength="200"
                    >
                </div>
            </div>
        </div>
        <div class="d-flex flex-row mt-2 mb-2 pb-2 border-bottom">
            <div class="d-flex flex-column mr-3 phone-input-spacing">
                <label class="font-weight-bold">Phone</label>
                <div>
                    <input
                            type="text"
                            class="form-control editable"
                            id="phone"
                            formControlName="phone"
                            name="phone"
                            placeholder="Phone"
                            maxlength="200"
                    >
                </div>
            </div>
            <div class="d-flex flex-column email-input-spacing">
                <label class="font-weight-bold">Email</label>
                <div>
                    <input
                            type="text"
                            class="form-control editable"
                            id="email"
                            formControlName="email"
                            name="email"
                            placeholder="Email"
                            maxlength="200"
                    >
                </div>
            </div>
        </div>

        <div *ngIf="!isRecipient">
            <div class="btn-group btn-group-toggle d-flex flex-row topic-button-group mb-2">
                <button [ngClass]="{'erecord-selected': topicErecordSet, 'erecord-not-selected': !topicErecordSet}"
                        class="btn topic-button d-flex flex-column align-items-center mr-2"
                        (click)="setTopicArea(topicArea.erecording, view.caseDetails)"
                >
                    <div class="topic-icon">
                        <fa-icon class="fa-2x topic-icon" icon="file-alt" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        eRecording
                    </div>
                </button>
                <button [ngClass]="{'capc-not-selected': !topicCapcSet, 'capc-selected': topicCapcSet}"
                        class="btn topic-button d-flex flex-column align-items-center mr-2"
                        (click)="setTopicArea(topicArea.capc, view.caseDetails)"
                >
                    <div>
                        <fa-icon class="fa-2x topic-icon" icon="door-closed" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        Pre-Close and Post-Close
                    </div>
                </button>
                <button [ngClass]="{'esign-not-selected': !topicEsignSet, 'esign-selected': topicEsignSet}"
                        class="btn topic-button d-flex flex-column align-items-center"
                        (click)="setTopicArea(topicArea.esign, view.caseDetails)"
                >
                    <div>
                        <fa-icon class="fa-2x topic-icon" icon="file-signature" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        eSign Events
                    </div>
                </button>
            </div>
            <div *ngIf="this.topicAreaSelected === 'esign'" class="d-flex flex-row align-items-center mt-3 ">
                <label for="liveEventCase" class="font-weight-bold mr-4">Are  you currently facilitating a live IPEN or RON event?</label>
                <sf-select
                        [options]="this.liveEventOptions"
                        [isSingleSelect]="true"
                        [hasSelectAll]="false"
                        [hasFilter]="false"
                        trackBy="name"
                        labelBy="name"
                        [required]="true"
                        container="body"
                        formControlName="liveEventSelection"
                        id="liveEventCase"
                        class="liveEvent-dropdown"
                >
                </sf-select>
            </div>
            <div *ngIf="this.topicAreaSelected === 'capc'" class="d-flex flex-column mt-3 ">
                <div class="d-flex flex-row">
                    <label for="loanNumberCase" class="font-weight-bold mr-2 pt-1">What is the loan number associated with your inquiry?</label>
                    <input
                            type="text"
                            class="form-control editable mt-1 loan-number"
                            id="loanNumberCase"
                            formControlName="loanNumber"
                            name="loanNumber"
                            maxlength="200"
                    >
                </div>
                <div class="d-flex flex-row justify-content-end mt-1">
                    <label for="loanNumberCaseCheckbox" class="font-weight-bold mr-2">I don't know the loan number.</label>
                    <input
                            type="checkbox"
                            class="form-control editable mt-1"
                            id="loanNumberCaseCheckbox"
                            formControlName="loanNumberCheckbox"
                            name="loanNumberCheckbox"
                    >
                </div>
            </div>
            <div *ngIf="this.topicAreaSelected === 'erecording'" class="d-flex flex-row mt-3 mb-2">
                <div class="font-weight-bold m-auto pr-2">Where are you eRecording?</div>
                <sf-select
                        [options]="this.states"
                        [isSingleSelect]="true"
                        [hasSelectAll]="false"
                        [hasFilter]="true"
                        placeholder="-- Select a State --"
                        trackBy="abbrev"
                        labelBy="name"
                        [required]="true"
                        [additionalPropertiesToFilter]="['abbrev']"
                        ngbTooltip="Click here to select a state"
                        container="body"
                        formControlName="stateSelection"
                        class="state-dropdown"
                >
                </sf-select>
            </div>
        </div>
        <div>
            <div *ngIf="caseForm?.controls?.subject?.value?.length >= 255" class="font-italic mt-1 mb-1 text-limit">Text limit 255 characters</div>
            <div>
                <label class="font-weight-bold">How can we help you? Please provide any details that might help us understand your issue.</label>
                <label class="font-weight-bold"></label>
                <textarea
                        class="form-control"
                        formControlName="customerDescription"
                        name="customerDescription"
                        maxlength="15000"
                        >
                </textarea>
            </div>
            <div *ngIf="caseForm?.controls?.customerDescription?.value?.length >= 15000" class="font-italic mt-1 text-limit">Text limit 15000 characters</div>
        </div>
        <div class="font-weight-bold mt-3 mb-2">How urgent is your issue?</div>
        <sf-select
                [options]="this.severityOptions"
                [isSingleSelect]="true"
                [hasSelectAll]="false"
                [hasFilter]="false"
                placeholder="Select the Severity"
                trackBy="name"
                labelBy="name"
                [required]="true"
                ngbTooltip="Click here to select the severity"
                container="body"
                formControlName="severity"
        >
        </sf-select>
        <div class="font-italic mt-2 small" *ngIf="showSeverityMessage && chatInitInfo.IsChatSupportOpen">
            To prevent delays on High priority requests, please consider submitting your case or inquiry via Phone Support by calling (800) 460-5657.
        </div>
        <div class="font-italic mt-2 small" *ngIf="showSeverityMessage && !chatInitInfo.IsChatSupportOpen">
            To prevent delayed response to your High priority request, Please consider reaching out via phone during our standard support hours Monday-Friday from 6:00am to 6:00pm MT
        </div>

        <div class="mt-3 d-flex flex-row justify-content-start">
            <div class="d-flex">
                <div class="d-flex flex-column flex-shrink-0">
                    <label for="fileUpload" class="btn btn-secondary font-weight-bold clickable mb-0">
                          Upload Optional Attachment <fa-icon [icon]="['far', 'upload']" [fixedWidth]="true"></fa-icon>
                    <input type="file"
                             id="fileUpload"
                             [multiple]="true"
                             [accept]="acceptFileTypes"
                             [hidden]="true"
                             (change)="handleFileInput($event)"
                             #input
                             (click)="openFilePicker($event, input)"
                     />
                    </label>
                </div>
                <div class="d-flex flex-column flex-grow-1 selected-files-display">
                    <!-- Summary view with the option to expand / Show this view for multiple files -->
                    <div *ngIf="this.caseAttachments.length > 1 && !this.expandCaseAttachmentsList">
                        <span ngbTooltip="Show selected files" class="clickable" (click)="expandCaseAttachmentsList = true">{{this.caseAttachments.length + " files selected"}}</span>
                        <fa-icon ngbTooltip="Remove attachments" class="clickable" [icon]="['far', 'trash-alt']" [fixedWidth]="true" (click)="clearCaseAttachments()"></fa-icon>
                    </div>
                    <!-- Expanded view with one file per line / Show this view for a single file -->
                    <div *ngIf="this.caseAttachments.length === 1 || this.expandCaseAttachmentsList">
                        <div *ngFor="let attachment of caseAttachments">
                            {{attachment.name}}
                            <fa-icon ngbTooltip="Remove attachment" class="clickable" [icon]="['far', 'trash-alt']" [fixedWidth]="true" (click)="removeCaseAttachment(attachment)"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>

    <!-- Sending Case Complete -->
    <div *ngIf="currentView === view.sendingCaseComplete" class="d-flex justify-content-center font-weight-bold" style="margin-top: 10px">
        Your ticket has been successfully created and will be reviewed in the order it was received.
    </div>

    <!-- Chat Initialized -->
    <div *ngIf="currentView === view.chatInitialized" style="margin-top: 10px">
        <div class="d-flex font-weight-bold justify-content-center mb-3">You are ready to begin chat!</div>
        <div class="d-flex justify-content-center">This dialog will close automatically in 5 seconds.</div>
    </div>

</div>

<!-- Footer Section -->
<div class="d-flex justify-content-start modal-footer">
    <div *ngIf="currentView === view.chooseSupportType">
        <div *ngIf="this.chatDetailsSelected">
            <button class="btn btn-primary m-0"
                    (click)="selectSupportMethod(view.chatDetails)"
            >Open Chat</button>
        </div>
        <div *ngIf="this.caseDetailsSelected">
            <button class="btn btn-primary m-0"
                    (click)="selectSupportMethod(view.caseDetails)"
            >
                Open Ticket</button>
        </div>
    </div>
    <!--    Buttons above are to select chat or case / Buttons below are to initiate the chat/case -->
    <div>
        <div [ngbTooltip]="createRequiredTooltip()">
            <button class="btn btn-primary m-0"
                    (click)="postCaseForNewChat()"
                    [disabled]="!chatForm.valid"
                    *ngIf="currentView === view.chatDetails"
            >Begin Chat</button>
        </div>
        <div [ngbTooltip]="createRequiredTooltip()">
            <button class="btn btn-primary m-0"
                    (click)="postCaseForNewCase()"
                    [disabled]="!caseForm.valid || highSeverityAfterHours"
                    *ngIf="currentView === view.caseDetails"
            >Submit</button>
        </div>
    </div>

    <button *ngIf="currentView === view.caseDetails || currentView === view.chatDetails" class="btn btn-secondary m-1" (click)="backButton()">Back</button>

    <button class="btn btn-secondary m-0" (click)="activeModal.dismiss()">
        <!-- Change the word for the Cancel/Close button depending on the view -->
        <ng-container [ngSwitch]="currentView">
            <ng-container *ngSwitchCase="view.sendingCaseComplete">Close</ng-container>
            <ng-container *ngSwitchCase="view.chatInitialized">Close</ng-container>
            <ng-container *ngSwitchDefault><span (click)="closeChat()">Cancel</span></ng-container>
        </ng-container>
    </button>
</div>

