import { Injectable } from "@angular/core";
import { getPath } from "../helpers/object";

@Injectable({
    providedIn: "root"
})
export class ErecordConfigService {
    constructor() {}

    get(path: string): any {
        return getPath(sf.falconSettings, path, null);
    }
}
