import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import {
    RpcClientService,
    SocketService,
    SubscriptionBaseService
} from "@sf/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class UserSubscriptionService extends SubscriptionBaseService {
    private profileChangedSubject$ = new Subject<any>();
    public profileChanged = this.profileChangedSubject$.asObservable();

    constructor(
        protected _socket: SocketService,
        private _rpc: RpcClientService
    ) {
        super(_socket);
        this.product = "userorg";
        this.namespace = "";
    }

    subscribeToUpdates(userID: string): Observable<any> {
        this.namespace = "userorg_user_changed";
        return this.on("update", userID);
    }

    profileWasChanged() {
        this.profileChangedSubject$.next(null);
    }

}
