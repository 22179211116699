import {
    Component,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    Input,
    ViewChild,
    ElementRef,
    TemplateRef,
    forwardRef,
    OnDestroy,
    ChangeDetectorRef
} from "@angular/core";
import { dayjs } from "../../plugins/dayjs/index";
import * as isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

import {
    NgbDate,
    NgbDateAdapter,
    NgbInputDatepicker,
    NgbDateParserFormatter,
    NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { DateAdapterService, DateParserFormatterService } from "../../services";
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    NG_VALIDATORS,
    Validator,
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import { noop } from "../../../helpers/utils";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { deepEqual } from "../../../helpers/object";

/**
 * Component that displays an input with a button for opening a calendar to pick a date
 *
 * DatePickerComponent has a slightly different API than the orignal AngularJS sf-date-picker
 *
 * Notable changes include:
 *  * sf-date-picker accepted many different types of dates. DatePickerComponent ONLY supports Dayjs objects. This is intentional behavior.
 *  * attach-to-body is now the [container] input binding. To duplicate previous behavior, set [container]="'body'"
 *  * The customClass binding that took a function that provided a custom class to style days has been replaced with the [dayTemplate] input binding.
 * It takes a TemplateRef that replaces the component used to render days.
 *  * The dateDisabled binding that took a function used to configure disabled dates has an updated interface. See [dateDisabledProvider] for more information.
 */
@Component({
    selector: "sf-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
    providers: [
        DateAdapterService,
        DateParserFormatterService,
        { provide: NgbDateAdapter, useExisting: DateAdapterService },
        {
            provide: NgbDateParserFormatter,
            useExisting: DateParserFormatterService
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ]
})
export class DatePickerComponent
    implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator
{
    private _ngOnDestroy: Subject<void> = new Subject();

    /* I/O */
    dateForm: FormGroup;

    /**
     * The input value for the date picker
     */
    @Input()
    date: dayjs.Dayjs;
    /**
     * (Optional - Default: "MM/DD/YYYY") The display format for the date and the first format used when attempting to parse
     * the format. See [Day.js parsing documentation](https://day.js.org/docs/en/parse/parse) for
     * more information.
     */
    @Input()
    stringFormat: string;
    /**
     * (Optional - Default: see DateParserFormatterService for a list) List of alternate formats to parse when a user enters
     * text into the datepicker field.
     */
    @Input()
    altFormats: string[];
    /**
     * (Optional - Default: true) Whether to use Day.js strict parsing when parsing user input.
     */
    @Input()
    strictParsing: boolean;
    /**
     * (Optional) The minimum date allowed to be entered into the date field
     */
    @Input()
    minDate: dayjs.Dayjs;
    /**
     * (Optional) The maximum date allowed to be entered into the date field
     */
    @Input()
    maxDate: dayjs.Dayjs;
    /**
     * (Optional) A function that takes in the current date and the current display month and year
     * and returns whether or not the particular date should be disabled
     */
    @Input()
    dateDisabledProvider: (
        date: dayjs.Dayjs,
        currentDisplay: { year: number; month: number }
    ) => boolean;
    /**
     * (Optional - Default: false) Whether or not the date picker is a required input in the field
     */
    @Input()
    required: boolean;
    /**
     * (Optional - Default: false) Whether the date picker is disabled
     */
    @Input()
    disabled: boolean;
    /**
     * (Optional) The tabindex to use on the input of the date picker
     */
    @Input()
    tabindex: number;
    /**
     * (Optional) Text to display in the input field of the date picker
     */
    @Input()
    placeholder: string;
    /**
     * (Optional) CSS Selector for the element to attach the popup calendar to. Used for making
     * sure the calendar appears above the correct elements when inside a modal. Currenlty only
     * supports 'body' as a value.
     */
    @Input()
    container: "body" | undefined;
    /**
     * (Optional) TemplateRef for an alternate template to use for the display of days in the
     * calendar.
     */
    @Input()
    dayTemplate: TemplateRef<any>;
    /*
     * To support <label for="????"> focus
     */
    @Input()
    inputId?: string = (
        Date.now() + Math.floor(Math.random() * 100)
    ).toString();
    /**
     * (Optional) Set name attribute to a random value so autofill doesn't popup
     */
    @Input()
    datePickerName?: string;
    /**
     * (Optional) Set date picker in date range highlight mode (currently only used finanical-reports.component.ts)
     */
    @Input()
    highlightMode?: boolean;

    /**
     * (Optional) Pass in from date (start date) for highlight mode only (currently only used finanical-reports.component.ts)
     */
    @Input()
    fromDate?: dayjs.Dayjs | null;

    /**
     * (Optional) Pass in to date (end date) for highlight mode only (currently only used finanical-reports.component.ts)
     */
    @Input()
    toDate?: dayjs.Dayjs | null;

    /**
     * (Optional) Allow datepicker to update on change instead of the default blur
     */
    @Input()
    updateOn?: "change" | "blur";

    /**
     * Outputs the current date as a Dayjs object when the selected date changes
     */
    @Output()
    dateChange: EventEmitter<dayjs.Dayjs> = new EventEmitter();
    /**
     * Outputs the current input value when the input cannot successfully be parsed
     */
    @Output()
    dateChangeError: EventEmitter<string> = new EventEmitter();
    /**
     * Emits an event when the input is blurred
     */
    @Output()
    blur: EventEmitter<null> = new EventEmitter();
    /**
     * Emits an event when the input is focused
     */
    @Output()
    focus: EventEmitter<null> = new EventEmitter();
    /**
     * Emits an event when there is a max date or min date error
     */
    @Output()
    minMaxDateError: EventEmitter<string> = new EventEmitter();
    /**
     * Emits an event when in highlight mode and a date is selected
     */
    @Output()
    highlightStartEndDateChange: EventEmitter<any> = new EventEmitter();

    /* Private Variables */
    private _onChange: (_: any) => void = noop;
    private _onTouched: () => void = noop;
    private _isValidDate = false;

    /* Public Variables */
    internalDate: dayjs.Dayjs;
    startDate: { year: number; month: number };
    hoveredDate: dayjs.Dayjs | null = null;
    selectedDayTemplate: TemplateRef<any>;
    minDateParsed: NgbDateStruct = null;
    maxDateParsed: NgbDateStruct = null;
    focused: boolean = false;

    /* View Children */
    @ViewChild("datePicker")
    datePicker: NgbInputDatepicker;
    @ViewChild("dateInput")
    dateInput: ElementRef<HTMLInputElement>;
    @ViewChild("standardDayTemplate", { static: true })
    standardDayTemplate: TemplateRef<any>;
    @ViewChild("highlightRangeDayTemplate", { static: true })
    highlightRangeDayTemplate: TemplateRef<any>;

    constructor(
        private dateAdapter: DateAdapterService,
        private dateParserFormatter: DateParserFormatterService,
        private _cdr: ChangeDetectorRef
    ) {
        this.isDateDisabled = this.isDateDisabled.bind(this);
    }

    /* Public Functions */

    ngOnInit() {
        let validators = [];
        if (this.required) {
            validators.push(Validators.required);
        }
        this.dateForm = new FormGroup({
            date: new FormControl(
                {
                    value:
                        this.date && this.date.startOf
                            ? this.date.startOf("day")
                            : null,
                    disabled: this.disabled
                },
                { updateOn: this.updateOn || "blur", validators }
            )
        });

        this.dateParserFormatter.setStrictParsing(this.strictParsing);
        this.dateParserFormatter.setStringFormat(this.stringFormat);
        this.dateParserFormatter.setAltStringFormats(this.altFormats);
        this._setInternalDate(this.date);
        this._setPlaceholder(this.placeholder);
        this._setDayTemplate(this.dayTemplate);
        this._setMaxAndMinDates();
        this.dateForm
            .get("date")
            .valueChanges.pipe(
                takeUntil(this._ngOnDestroy),
                filter((newDate) => {
                    if (dayjs.isDayjs(this.startDate)) {
                        // If the prev date is a dayjs compare to the new date.
                        return !this.startDate.isSame(
                            newDate as dayjs.Dayjs,
                            "day"
                        );
                    } else if (dayjs.isDayjs(newDate)) {
                        // If the prev date is not a dayjs, and the new date is, it has changed.
                        return true;
                    } else {
                        // If neither are dayjs, compare to each other.
                        return this.startDate !== newDate;
                    }
                })
            )
            .subscribe((date) => {
                this._isValidDate = true;
                if (typeof date === "string" || date instanceof String) {
                    this.dateChangeError.emit(date.toString());
                    this._isValidDate = false;
                    this._onChange(null);
                    return;
                }

                this._checkMinMaxLimits();
                this._onDateSelect(date);
            });
    }

    ngOnDestroy(): void {
        this._ngOnDestroy.next();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.date &&
            ((changes.date.currentValue &&
                !(changes.date.currentValue as dayjs.Dayjs).isSame(
                    changes.date.previousValue,
                    "day"
                )) ||
                !changes.date.currentValue) &&
            !changes.date.isFirstChange()
        ) {
            this.dateForm.get("date").setValue(changes.date.currentValue);
            this._setInternalDate(changes.date.currentValue);

            // may need to replace this._setInternalDate(changes.date.currentValue); with the following two lines
            // this._checkMinMaxLimits();
            // this._onDateSelect(changes.date.currentValue);
        }
        if (changes.placeholder && !changes.placeholder.isFirstChange()) {
            this._setPlaceholder(changes.placeholder.currentValue);
        }
        if (changes.dayTemplate && !changes.dayTemplate.isFirstChange()) {
            this._setDayTemplate(changes.dayTemplate.currentValue);
        }
        if (changes.strictParsing && !changes.strictParsing.isFirstChange()) {
            this.dateParserFormatter.setStrictParsing(
                changes.strictParsing.currentValue
            );
        }
        if (changes.stringFormat && !changes.stringFormat.isFirstChange()) {
            this.dateParserFormatter.setStringFormat(
                changes.stringFormat.currentValue
            );
        }
        if (changes.altFormats && !changes.altFormats.isFirstChange()) {
            this.dateParserFormatter.setAltStringFormats(
                changes.altFormats.currentValue
            );
        }
        if (changes.disabled && !changes.disabled.isFirstChange()) {
            if (
                changes.disabled.currentValue !== changes.disabled.previousValue
            ) {
                if (changes.disabled.currentValue === true) {
                    this.dateForm.get("date").disable();
                } else {
                    this.dateForm.get("date").enable();
                }
            }
        }
        if (
            (changes.minDate &&
                ((changes.minDate.currentValue &&
                    !(changes.minDate.currentValue as dayjs.Dayjs).isSame(
                        changes.minDate.previousValue,
                        "day"
                    )) ||
                    !changes.minDate.currentValue) &&
                !changes.minDate.isFirstChange()) ||
            (changes.maxDate &&
                ((changes.maxDate.currentValue &&
                    !(changes.maxDate.currentValue as dayjs.Dayjs).isSame(
                        changes.maxDate.previousValue,
                        "day"
                    )) ||
                    !changes.maxDate.currentValue) &&
                !changes.maxDate.isFirstChange())
        ) {
            this._setMaxAndMinDates();
        }
    }

    clearDate() {
        if (this.disabled) {
            return;
        }
        this.dateForm.get("date").setValue(null);
        this.dateInput.nativeElement.focus();
    }

    onFocus() {
        this.focused = true && !this.required;
        this.focus.emit();
    }

    onBlur() {
        this._onTouched();
        this.focused = false;
        this.blur.emit();
    }

    isDateDisabled(
        date: NgbDate,
        current: { year: number; month: number }
    ): boolean {
        if (!this.dateDisabledProvider) {
            return false;
        }
        let dayjsDate = this.dateAdapter.toModel(date);
        return this.dateDisabledProvider(dayjsDate, current);
    }

    togglePicker() {
        if (this.disabled) {
            return;
        }
        if (this.datePicker.isOpen()) {
            this.onBlur();
        }
        this.datePicker.toggle();
    }

    /* ControlValueAccessor Implementation */
    writeValue(date: dayjs.Dayjs | null) {
        this.dateForm.get("date").setValue(date, { emitEvent: false });
        this.dateForm.get("date").markAsPristine();
        this._setInternalDate(date);

        // may need to replace this._setInternalDate(date); with the following two lines
        // this._checkMinMaxLimits();
        // this._onDateSelect(date);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.dateForm.disable({ emitEvent: false });
        } else {
            this.dateForm.enable({ emitEvent: false });
        }
    }

    // returns null when valid else the validation object
    // in this case we're checking if the json parsing has
    // passed or failed from the onChange method
    public validate(c: FormControl) {
        return this._isValidDate || c.pristine
            ? null
            : {
                  dateParseError: {
                      valid: false
                  }
              };
    }

    // Methods to be used only in highlight mode
    setHighlightRange(date: any) {
        date = this.convertToDayJsDate(date);
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (
            this.fromDate &&
            !this.toDate &&
            date &&
            date.isSameOrAfter(this.fromDate) // Allow selecting end date to be the same as start date
        ) {
            this.toDate = date;
        } else if (
            this.fromDate &&
            !this.toDate &&
            date &&
            date.isBefore(this.fromDate)
        ) {
            this.toDate = this.fromDate;
            this.fromDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }

        let startEndDateObj: any = {
            fromDate: this.fromDate ? this.fromDate : null,
            toDate: this.toDate ? this.toDate : null
        };
        this.highlightStartEndDateChange.emit(startEndDateObj);
    }

    setHoverDate(date: any) {
        this.hoveredDate = this.convertToDayJsDate(date);
    }

    convertToDayJsDate(date: NgbDate) {
        return this.dateAdapter.toModel(date);
    }

    isHovered(date: NgbDate) {
        let dayjsDate = this.convertToDayJsDate(date);
        return (
            (this.fromDate &&
                !this.toDate &&
                this.hoveredDate &&
                dayjsDate.isAfter(this.fromDate) &&
                dayjsDate.isBefore(this.hoveredDate)) ||
            (this.fromDate &&
                !this.toDate &&
                this.hoveredDate &&
                dayjsDate.isBefore(this.fromDate) &&
                dayjsDate.isAfter(this.hoveredDate))
        );
    }

    isInside(date: NgbDate) {
        // called after date selection
        let dayjsDate = this.convertToDayJsDate(date);
        if (this.fromDate && this.toDate) {
            return (
                this.toDate &&
                dayjsDate.isAfter(this.fromDate) &&
                dayjsDate.isBefore(this.toDate)
            );
        }
    }

    isRangeStart(date: NgbDate) {
        let dayjsDate = this.convertToDayJsDate(date);
        if (this.fromDate && !this.hoveredDate) {
            return dayjsDate.isSame(this.fromDate);
        } else if (this.fromDate && this.hoveredDate && this.toDate) {
            return (
                dayjsDate.isSame(this.fromDate) &&
                (this.hoveredDate.isSameOrBefore(this.fromDate) ||
                    this.hoveredDate.isSameOrAfter(this.fromDate))
            );
        } else if (this.fromDate && this.hoveredDate) {
            return (
                dayjsDate.isSame(this.fromDate) &&
                this.hoveredDate.isSameOrAfter(this.fromDate)
            );
        }
    }

    isRangeEnd(date: NgbDate) {
        let dayjsDate = this.convertToDayJsDate(date);
        if (this.toDate) {
            return this.toDate && dayjsDate.isSame(this.toDate);
        } else if (this.fromDate && this.hoveredDate) {
            return (
                dayjsDate.isSame(this.fromDate) &&
                this.hoveredDate.isBefore(this.fromDate)
            );
        }
    }

    /* Private Functions */
    private _checkMinMaxLimits() {
        if (this.minDate || this.maxDate) {
            let dateField = this.dateForm.get("date");
            if (
                dateField.invalid &&
                dateField.errors.hasOwnProperty("ngbDate")
            ) {
                // has min date
                if (dateField.errors.ngbDate.hasOwnProperty("minDate")) {
                    this._isValidDate = false;
                    this.minMaxDateError.emit(
                        "Date must not be before 04/08/2008, nor before " +
                            this.minDate?.format("MM/DD/YYYY")
                    );
                }
                // has max date
                else if (dateField.errors.ngbDate.hasOwnProperty("maxDate")) {
                    this._isValidDate = false;
                    this.minMaxDateError.emit(
                        "Date cannot be after " +
                            this.maxDate?.format("MM/DD/YYYY")
                    );
                } else {
                }
            } else {
                this._isValidDate = true;
                this.minMaxDateError.emit(""); // TODO: is this emit really needed?
            }
        }
    }

    private _onDateSelect(date: dayjs.Dayjs | null) {
        this._setInternalDate(date, true);
        this._onChange(date);
        this._onTouched();
        this.dateChange.emit(date);
    }

    private _setDayTemplate(dayTemplate: TemplateRef<any>) {
        if (dayTemplate) {
            this.selectedDayTemplate = dayTemplate;
            return;
        }
        if (this.highlightMode) {
            this.selectedDayTemplate = this.highlightRangeDayTemplate;
            return;
        }
        this.selectedDayTemplate = this.standardDayTemplate;
    }

    private _setPlaceholder(placeholder: string) {
        if (placeholder) {
            this.placeholder = placeholder;
            return;
        }
        this.placeholder = this.dateParserFormatter.getStringFormat();
    }

    private _setInternalDate(date: dayjs.Dayjs | undefined, force?: boolean) {
        if ((date !== undefined && date !== null) || force) {
            this.startDate =
                date && date.year
                    ? { year: date.year(), month: date.month() + 1 }
                    : null;
        }
    }

    private _setMaxAndMinDates() {
        let minOrMaxDateChanged = false;

        const updatedMinDateParsed = this.minDate
            ? {
                  year: this.minDate.year(),
                  month: this.minDate.month() + 1,
                  day: this.minDate.date()
              }
            : null;

        if (!deepEqual(this.minDateParsed, updatedMinDateParsed)) {
            this.minDateParsed = updatedMinDateParsed;
            minOrMaxDateChanged = true;
        }

        const updatedMaxDateParsed = this.maxDate
            ? {
                  year: this.maxDate.year(),
                  month: this.maxDate.month() + 1,
                  day: this.maxDate.date()
              }
            : null;

        if (!deepEqual(this.maxDateParsed, updatedMaxDateParsed)) {
            this.maxDateParsed = updatedMaxDateParsed;
            minOrMaxDateChanged = true;
        }

        if (minOrMaxDateChanged) {
            this._cdr.detectChanges();
        }
    }
}
