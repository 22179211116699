<header class="page-header-wrapper">
    <div class="d-flex">
        <div class="flex-grow-1">
            <ng-container #pageHeader></ng-container>
        </div>
        <div>
            <ng-container #userCenter></ng-container>
        </div>
    </div>
    <div class="row" *ngIf="showHeader">
        <div class="col-md-12">
            <hr/>
        </div>
    </div>
</header>

