import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
    CopyTextService,
    getAllRouteData,
    getLinkWithChangedParams,
    getParamsFromRoute,
    scheduleMicroTask,
    SelectableItem
} from "@sf/common";
import { PageHeaderComponent } from "@sf/headers";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CurrentRoleService, RoleService, UserRole } from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-admin-role-header-with-selector",
    templateUrl: "./admin-role-header-with-selector.component.html",
    styleUrls: ["./admin-role-header-with-selector.component.scss"]
})
export class AdminRoleHeaderWithSelectorComponent implements OnInit, OnDestroy, PageHeaderComponent {
    pageData: any;
    _onDestroy$: Subject<void> = new Subject();

    /* Public Variables */
    selectedRole: Partial<UserRole> = {
        id: null
    };
    public selectedRoleID: string = null;

    /** Private Variables */
    showSaveChanges = false;
    updatingCurrentRole = false;

    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy Role ID"
        },
        {
            option: "copyName",
            label: "Copy Role Name"
        }
    ];

    constructor(
            private currentRoleService: CurrentRoleService,
            private _route: ActivatedRoute,
            private router: Router,
            private roleService: RoleService,
            private copyService: CopyTextService
    ) {
    }

    ngOnInit() {
        // look for route data that could influence selection -----
        let routeData = getAllRouteData(this._route);

        // get the selected role
        this.pickInitialRole();

        // update the display if the role name changes (by somebody editing it)
        this.currentRoleService.currentRole$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((role: UserRole) => {
                if (role) {
                    this.selectedRole = role;
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    pickInitialRole() {
        let initialRole: UserRole = null;
        let currentRole: UserRole =
                this.currentRoleService.getCurrentRole();

        // look for roleID in URL
        let params = getParamsFromRoute(this._route, ["roleID"]);
        if (params.roleID && params.roleID !== "_") {
            // a roleID was specified in the URL, so it takes priority
            if (currentRole && params.roleID == currentRole.id) {
                initialRole = currentRole;
            } else {
                // temporarily use a partial Role object
                initialRole = <UserRole>{
                    id: params.roleID
                };
                this.updateCurrentRoleID(params.roleID);
            }
            this.selectedRole = initialRole;
            this.selectedRoleID = params.roleID;
            return; // done
        }

        // try the 'current' rold
        initialRole = currentRole;
        if (initialRole) {
            this.selectedRole = initialRole;
            return; //done
        }
    }

    updateCurrentRoleID(roleID: string) {
        this.updatingCurrentRole = true;
        this.roleService.getRole(roleID).subscribe((role: UserRole) => {
            this.currentRoleService.updateCurrentRole(role);
            this.updatingCurrentRole = false;
        });
    }

    navigateToRoleID(roleID: string) {
        let extras: NavigationExtras = {
            relativeTo: this._route,
            queryParamsHandling: "preserve"
        };
        let link = getLinkWithChangedParams(
                this._route,
                {
                    roleID: roleID
                },
                true
        );
        if (roleID && !link.includes(roleID)) {
            link = link.map((part) =>
                    part.includes("./role") ? part + "/" + roleID : part
            );
        }
        scheduleMicroTask(() => {
            this.router.navigate(link, extras);
        });
    }

    // called from HTML
    setSelectedRole(selectedRole: UserRole) {
        if (this.updatingCurrentRole) {
            // ignore this call if we are looking up a role to make it current
            return;
        }
        this.currentRoleService.updateCurrentRole(selectedRole);
        if (selectedRole) {
            this.selectedRole = selectedRole;
            this.navigateToRoleID(selectedRole.id);
        } else {
            this.selectedRole = {
                id: null
            };
        }
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                    event.clientX +
                    document.body.scrollLeft +
                    document.documentElement.scrollLeft;
            this.contextMenuY =
                    event.clientY +
                    document.body.scrollTop +
                    document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedRole) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(this.selectedRole.name);
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedRole.id);
                    break;
            }
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }
}
