import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
    RpcClientService,
    SocketService,
    SubscriptionBaseService
} from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class ActivitySubscriptionService extends SubscriptionBaseService {
    constructor(
        protected _socket: SocketService,
        private _rpc: RpcClientService
    ) {
        super(_socket);
        this.product = "userorg";
        this.namespace = "";
    }

    subscribeToUpdates(id: string): Observable<any> {
        this.namespace = "activityLogged";
        return this.on("update", id);
    }
}
