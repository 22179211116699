import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class UserOrgApiTokenService {
    constructor(private _rpcClient: RpcClientService) {}

    getApiTokensForUser(orgIDs: string[], userID: string): Observable<any[]> {
        return this._rpcClient.makeRequest("api", "getApiTokensForUser", {
            orgIDs: orgIDs,
            userID: userID
        });
    }

    sendAPITokenEmail(apiToken: any, isReset: boolean): Observable<null> {
        return this._rpcClient.makeRequest("api", "sendAPITokenEmail", {
            userID: apiToken.username,
            orgID: apiToken.orgID,
            apiKeyID: apiToken.apiKeyID,
            isReset: isReset
        });
    }

    getOrgUserCountWithApi(orgID: string, integrationTypes: string[]) {
        return this._rpcClient.makeRequest("api", "getOrgUserCountWithApi", {
            orgID: orgID,
            productIDs: integrationTypes
        });
    }

    getOrgUserCountWithAPIDefinition(orgID: string, apiKeyID: string) {
        return this._rpcClient.makeRequest(
            "api",
            "getOrgUserCountWithAPIDefinition",
            {
                orgID: orgID,
                apiKeyID: apiKeyID
            }
        );
    }

    createAPIToken(
        orgID: string | any,
        keyID?: string,
        username?: string
    ): Observable<any> {
        if (orgID.apiKeyID) {
            keyID = orgID.apiKeyID;
        }
        if (orgID.username) {
            username = orgID.username;
        }
        if (orgID.orgID) {
            orgID = orgID.orgID;
        }
        return this._rpcClient.makeRequest("api", "createAPIToken", {
            orgID: orgID,
            apiKeyID: keyID,
            username: username
        });
    }
}
