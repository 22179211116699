<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async" [ngStyle]="columnLayout">
    <label *ngIf="field.showLabel" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <sf-select
            placeholder="Select a value..."
            [options]="options$ | async"
            labelBy="label"
            trackBy="option"
            [hasClearButton]="true"
            [formControl]="control"
            [showSelectedToggle]="isMultiSelect"
            [filterDebounce]="50"
            [hasFilter]="true"
            [hasSelectAll]="isMultiSelect"
            [isSingleSelect]="!isMultiSelect"
            [limitDropDownItems]="limit"
            [handleFilterExternally]="handleFilterExternally"
            (filter)="filter($event)"
        ></sf-select>
        <sf-form-error *ngIf="control.invalid && !control.pristine" [control]="control" [label]="field.label"></sf-form-error>
    </div>
</div>
