import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class FileUploadService {
    constructor(private http: HttpClient) {}

    public uploadWithForm(file: any, data: any, path: string): Observable<any> {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("data", JSON.stringify(data));

        return this.http.post<any>(path, formData, { headers }).pipe((res) => {
            return res;
        });
    }

    public uploadWithPostUploadAction(
        file: any,
        documentHandle: string,
        postUploadAction: string,
        path: string
    ): Observable<any> {
        let headers = new HttpHeaders();

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("documentHandle", documentHandle);
        formData.append("removeAllPagesBeforeUpload", "true");
        formData.append("postUploadAction", "addPagesToDocument");

        return this.http.post<any>(path, formData, { headers }).pipe((res) => {
            return res;
        });
    }
}
