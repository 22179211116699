export enum UIFieldType {
    CONTAINER = "CONTAINER",
    PARTY_NAME = "PARTY_NAME",
    LISTTABLE = "LISTTABLE",

    CURRENCY = "CURRENCY",
    DATE = "DATE",
    DATERANGE = "DATERANGE",
    DATERANGE_SIMPLE = "DATERANGE_SIMPLE",
    NUMERIC = "NUMERIC",
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    ZIP = "ZIP",
    PHONE_NUMBER = "PHONE_NUMBER",
    HIDDEN = "HIDDEN",
    TIME = "TIME",
    TIMESTAMP = "TIMESTAMP",
    STATE = "STATE",
    UPLOAD = "UPLOAD",

    CHECKBOX = "CHECKBOX",
    RADIO = "RADIO",
    SELECT = "SELECT",
    MULTISELECT = "MULTISELECT"
}
