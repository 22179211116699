import { Component, Input, OnInit } from "@angular/core";
import {
    EsignEventResult,
    UniversalResult
} from "../../universal-search.interfaces";
import { UniversalSearchComponentService } from "../universal-search-component.service";
import { UniversalSearchResultComponent } from "../universal-search-result/universal-search-result.component";

@Component({
    selector: "sf-ee-result",
    templateUrl: "./ee-result.component.html",
    styleUrls: ["./ee-result.component.scss"]
})
export class EeResultComponent
    extends UniversalSearchResultComponent
    implements OnInit
{
    item: EsignEventResult;

    ngOnInit(): void {
        this.item = this.result as EsignEventResult;
    }
}
