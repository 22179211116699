import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UserDiagnosticsService {
    constructor(private _rpcClient: RpcClientService) {}

    getUserDiagnosticAccessLogs(
        username: string,
        start: any,
        end: any,
        limit: number
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getUserDiagnosticAccessLogs",
            {
                username: username,
                limit: limit || 100,
                start: start,
                end: end
            }
        );
    }

    getUserMetrics(
        username: string,
        start: any,
        end: any,
        limit: number,
        group: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getUserMetrics", {
            username: username,
            limit: limit || 100,
            start: start,
            end: end,
            group: group
        });
    }
}
