import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Contract, ContractData } from "../interfaces/contract-data.interface";
import { ContractType } from "../enums/contract-type.enum";
import { Product } from "../enums/products";
import { RpcClientService } from "@sf/common";
import { ContractPricingTemplate } from "../interfaces/contract-pricing-template.interface";
import { dayjs } from "@sf/common";

export interface ContractExtended extends Contract {
    expirationDateDayjs: string;
    expirationDateTime: string;
    executedDateDayjs: string;
    executedDateTime: string;
    effectiveDateDayjs: string;
    effectiveDateTime: string;
    documentType: string;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class ContractService {
    constructor(
        private rpcService: RpcClientService
    ) {}

    getOrganizationForContract(contractID: string) {
        return this.rpcService.makeRequest("userorg", "getOrganizationForContract", {
            contractID: contractID
        });
    }

    getContractFeeAmount(contractID: string, organizationID: string, feeType: string) {
        return this.rpcService.makeRequest("userorg", "getContractFeeAmount", {
            contractID: contractID,
            organizationID: organizationID,
            feeType: feeType
        });
    }

    getSelectablePaymentTerms(): any {
        return [
            {
                label: "Net 1 (Due upon receipt)",
                id: "NET1"
            }, {
                label: "Net 2",
                id: "NET2"
            }, {
                label: "Net 5",
                id: "NET5"
            }, {
                label: "Net 7",
                id: "NET7"
            }, {
                label: "Net 15",
                id: "NET15"
            }, {
                label: "Net 30",
                id: "NET30"
            }, {
                label: "Custom",
                id: "CUSTOM"
            }
        ];
    }

    getSelectableBillingCycles(productID?: string): any {
        let cycles = [
            {
                label: "Daily",
                id: "DAILY"
            }, {
                label: "Semi Monthly",
                id: "SEMI_MONTHLY"
            }, {
                label: "Monthly",
                id: "MONTHLY"
            }
        ];
        return cycles;
    }

    getContractDataForExpired(startDate: string, endDate: string, contractType: string): Observable<ContractData[]> {
        return this.rpcService.makeRequest("userorg", "getContractDataForExpired", {
            startDate: startDate,
            endDate: endDate,
            contractType: contractType
        });
    }

    findContract(orgID: string, contractType: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "findContract", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    getActiveOrFutureContract(orgID: string, contractType: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "getActiveOrFutureContract", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    getFutureContract(orgID: string, contractType: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "getFutureContract", {
            organizationID: orgID,
            contractType: contractType
        });
    } //testing

    getPendingContract(orgID: string, contractType: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "getPendingContract", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    getContractHistory(orgID: string): Observable<Contract[]> {
        return this.rpcService.makeRequest("userorg", "getContractHistory", {
            organizationID: orgID
        });
    }

    getOneContract(contractID: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "getOneContract", {
            contractID: contractID
        });
    }

    getSubsequentContract(orgID: string, contractType: string): Observable<Contract> {
        return this.rpcService.makeRequest("userorg", "getSubsequentContract", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    getContractTemplate(enrollmentCode: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getContractTemplate", { "registrationCode": enrollmentCode });
    }

    getPricingTemplates(): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getAllContractTemplates", {});
    }

    getActivePricingTemplates(isActive: boolean): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getContractTemplatesByStatus", { enabled: isActive});
    }

    savePricingTemplate(template: ContractPricingTemplate) {
        return this.rpcService.makeRequest("userorg", "saveContractTemplate", {
            contractTemplate: template
        });
    }

    getStandardContractTemplate(contractType: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getStandardContractTemplate", { contractType: contractType });
    }

    hasAnyContractToSign(username?: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "hasAnyContractToSign", {
            username: username
        });
    }

    saveContract(orgID: string, contract: Contract, details?: string): Observable<string> {
        let payload: any = {
            organizationID: orgID,
            contract: contract
        };
        if (details) {
            payload.details = details;
            return this.rpcService.makeRequest("userorg", "saveContractWithDetails", payload);
        } else {
            return this.rpcService.makeRequest("userorg", "saveContract", payload);
        }
    }

    getContractSignerNames(orgID: string, contractType: string) {
        return this.rpcService.makeRequest("userorg", "getContractSignerNames", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    cancelContractApprovalRequest(orgID: string, contractType: string) {
        return this.rpcService.makeRequest("userorg", "cancelContractApprovalRequest", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    cancelFutureContract(orgID: string, contractType: string, contractID: string): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "cancelFutureContract", {
            organizationID: orgID,
            contractType: contractType,
            contractID: contractID
        });
    }

    getPreviousContract(orgID: string, contractType: string) {
        return this.rpcService.makeRequest("userorg", "getPreviousContract", {
            organizationID: orgID,
            contractType: contractType
        });
    }

    uploadContractFile(orgID: string, contractID: string, contractType: string, licenseFileData: Observable<any>) {
        return this.rpcService.makeRequest("userorg", "uploadContractPDF", {
            orgID: orgID,
            contractID: contractID,
            contractType: contractType,
            licenseFileData: licenseFileData
        });
    }

    getFeesForOrgList(orgIDs: string[]) {
        return this.rpcService.makeRequest("userorg", "getFeesForOrgList", {
            organizationIDs: orgIDs
        });
    }

    checkLicenseTemplate(contractType: string) {
        return this.rpcService.makeRequest("esign", "checkLicenseTemplate", {
            contractType: contractType
        });
    }

    requestContractApproval(orgID: string, contractType: string, usernames: string[]) {
        return this.rpcService.makeRequest("userorg", "requestContractApproval", {
            organizationID: orgID,
            contractType: contractType,
            usernames: usernames
        });
    }

    requestContractApprovalLegalName(orgID: string, contractType: string, usernames: string[], legalName: string) {
        return this.rpcService.makeRequest("userorg", "requestContractApprovalLegalName", {
            organizationID: orgID,
            contractType: contractType,
            usernames: usernames,
            legalName: legalName
        });
    }

    updateSubmissionFeeAndRequestApproval(organizationID: string,
            newFee: number,
            usernames: string[],
            newPaperFee: number,
            newInStateErecordFee: number,
            newOutOfStateErecordFee: number,
            newInStatePaperFee: number,
            newOutOfStatePaperFee: number,
            newEmbeddedEmployeeFee: number) {
        return this.rpcService.makeRequest("userorg", "updateSubmissionFeeAndRequestApproval", {
            organizationID: organizationID,
            newFee: newFee,
            usernames: usernames,
            newPaperFee: newPaperFee,
            newInStateErecordFee: newInStateErecordFee,
            newOutOfStateErecordFee: newOutOfStateErecordFee,
            newInStatePaperFee: newInStatePaperFee,
            newOutOfStatePaperFee: newOutOfStatePaperFee,
            newEmbeddedEmployeeFee: newEmbeddedEmployeeFee
        });
    }

    disableContractTemplates(enrollmentCodes: string[]) {
        return this.rpcService.makeRequest("userorg", "disableContractTemplate", {
            registrationCodes: enrollmentCodes
        });
    }

    enableContractTemplates(enrollmentCodes: string[]) {
        return this.rpcService.makeRequest("userorg", "enableContractTemplate", {
            registrationCodes: enrollmentCodes
        });
    }

    removeContractTemplate(template: any): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "removeContractTemplate", {
            contractTemplate: template
        });
    }

    getRequiredFeeList(contractType: string, recordingPlusEnabled: boolean): string[] {
        let feeList: string[] = [];

        // submitter
        if (contractType == "ERECORDING") {
            feeList.push("LICENSE_FEE");
            feeList.push("RENEWAL_FEE");
            feeList.push("SUBMISSION_FEE");
        } else if (contractType == "SETTLEMENT") {
            feeList.push("SETTLEMENT_LOAN_CREATE_FEE");
            feeList.push("SETTLEMENT_LOAN_ACCEPT_FEE");
            feeList.push("DISBURSEMENT_FEE");
            feeList.push("VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE");
            feeList.push("REVSHARE_FEE");
            feeList.push("SETTLEMENT_RENEWAL_FEE");
        } else if (contractType == "LENDER") {
            feeList.push("LENDER_LOAN_CREATE_FEE");
            feeList.push("LENDER_LOAN_ACCEPT_FEE");
            feeList.push("LENDER_ANNUAL_FEE");
        } else if (contractType == "DOCUMENT_BUILDER") {
            feeList.push("DOCUMENT_BUILDER_LICENSE_FEE");
            feeList.push("DOCUMENT_BUILDER_RENEWAL_FEE");
            feeList.push("DOCUMENT_BUILDER_DOCUMENT_FEE");
        } else if (contractType == "TRUSTEE") {
            feeList.push("TRUSTEE_DOCUMENT_FEE");
        } else if (contractType == "LIEN_RELEASE") {
            feeList.push("LIEN_RELEASE_FEE");
        } else if (contractType == "SUBSCRIPTION") {
            feeList.push("SUBSCRIPTION_MINIMUM");
        } else if (contractType == "PAPER_RECORDING") {
            if (recordingPlusEnabled) {
                feeList.push("INSTATE_ERECORD_SUBMISSION_FEE");
                feeList.push("OUTOFSTATE_ERECORD_SUBMISSION_FEE");
                feeList.push("INSTATE_PAPER_SUBMISSION_FEE");
                feeList.push("OUTOFSTATE_PAPER_SUBMISSION_FEE");
                feeList.push("PAPER_MAIL_FEE");
                feeList.push("EMBEDDED_EMPLOYEE_FEE");
            } else {
                feeList.push("PAPER_SUBMISSION_FEE");
            }
        } else if (contractType == "ESIGN_EVENT") {
            feeList.push("ESE_LICENSE_FEE");
            feeList.push("ESE_RENEWAL_FEE");
            feeList.push("ESE_NO_ENOTARIZATION");
            feeList.push("ESE_IPEN");
            feeList.push("ESE_RON");
        } else if (contractType == "DOC_BUILDER_RON") {
            feeList.push("DOCUMENT_BUILDER_RON_FEE");
        }


        return feeList;
    }

    getAllFees(): string[] {
        return [
            // e-record
            "LICENSE_FEE",
            "RENEWAL_FEE",
            "SUBMISSION_FEE",
            "SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE",

            // settlement
            "SETTLEMENT_LOAN_CREATE_FEE",
            "SETTLEMENT_LOAN_ACCEPT_FEE",
            "DISBURSEMENT_FEE",
            "VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE",
            "REVSHARE_FEE",
            "SETTLEMENT_RENEWAL_FEE",

            // lender
            "LENDER_LOAN_CREATE_FEE",
            "LENDER_LOAN_ACCEPT_FEE",
            "LENDER_ANNUAL_FEE",

            // document builder
            "DOCUMENT_BUILDER_LICENSE_FEE",
            "DOCUMENT_BUILDER_RENEWAL_FEE",
            "DOCUMENT_BUILDER_DOCUMENT_FEE",

            // trustee
            "TRUSTEE_DOCUMENT_FEE",

            // mers integration / lien release
            "LIEN_RELEASE_FEE",

            // Subscription
            "SUBSCRIPTION_MINIMUM",

            // paper recording
            "PAPER_SUBMISSION_FEE",
            "INSTATE_ERECORD_SUBMISSION_FEE",
            "OUTOFSTATE_ERECORD_SUBMISSION_FEE",
            "INSTATE_PAPER_SUBMISSION_FEE",
            "OUTOFSTATE_PAPER_SUBMISSION_FEE",
            "PAPER_MAIL_FEE",
            "EMBEDDED_EMPLOYEE_FEE",

            // Esign Events
            "ESE_LICENSE_FEE",
            "ESE_RENEWAL_FEE",
            "ESE_NO_ENOTARIZATION",
            "ESE_IPEN",
            "ESE_RON",

            // Doc Builder RON
            "DOCUMENT_BUILDER_RON_FEE"
        ];
    }

    generateEnrollmentCode() {
        return this.rpcService.makeRequest("userorg", "getNewRegistrationCode", {});
    }

    msaNotUpdated(orgID: string, reason: string) {
        return this.rpcService.makeRequest("userorg", "msaNotUpdated", {
            organizationID: orgID,
            reason: reason
        });
    }

    getContractDocumentUrlForContractID(contractID: string) {
        return this.rpcService.makeRequest("userorg", "getContractDocumentUrlForContractID", {
            contractID: contractID
        });
    }

    getLicenseOverrideSetting(): Observable<boolean> {
        return this.rpcService.makeRequest("userorg", "getLicenseOverrideSetting", {})
    }

    getMinimumContractFees(orgID: string, contractType: string): Observable<any> {
        return this.rpcService.makeRequest("userorg", "getMinimumContractFees", {
            organizationID: orgID,
            contractType: contractType
        })
    }

    serviceForContractType(contractType: ContractType): Product {
        switch (contractType) {
            case ContractType.SERVICE_AMENDMENT:
            case ContractType.ERECORDING:
                return Product.SUBMITTER;
            case ContractType.PAPER_RECORDING:
                return Product.SUBMITTER_PAPER;
            case ContractType.SETTLEMENT:
                return Product.LICENSED_COLLABORATOR_SETTLEMENT;
            case ContractType.LENDER:
                return Product.LICENSED_COLLABORATOR_LENDER;
            case ContractType.DOCUMENT_BUILDER:
                return Product.SUBMITTER_SIGNING;
            case ContractType.TRUSTEE:
                return Product.TRUSTEE;
            case ContractType.LIEN_RELEASE:
                return Product.MERS_API_INTEGRATION;
            case ContractType.LENDER_VENDOR:
                return Product.LENDER_VENDOR;
            case ContractType.NOTARY_VENDOR:
                return Product.NOTARY_VENDOR;
            case ContractType.ESIGN_EVENTS_VENDOR:
                return Product.ESIGN_EVENTS_VENDOR;
            case ContractType.RECIPIENT_VENDOR:
                return Product.RECIPIENT_VENDOR;
            case ContractType.SUBMITTER_VENDOR:
                return Product.SUBMITTER_VENDOR;
            case ContractType.ESIGN_EVENT:
                return Product.ESIGN_EVENT;
            case ContractType.SUBSCRIPTION:
                return Product.SUBSCRIPTION;
            case ContractType.MSA:
            default:
                return null;
        }
    }

    contractTypeForService(product: Product): ContractType {
        switch (product) {
            case Product.SUBMITTER:
                return ContractType.ERECORDING;
            case Product.SUBMITTER_PAPER:
                return ContractType.PAPER_RECORDING;
            case Product.LICENSED_COLLABORATOR_SETTLEMENT:
                return ContractType.SETTLEMENT;
            case Product.LICENSED_COLLABORATOR_LENDER:
                return ContractType.LENDER;
            case Product.SUBMITTER_SIGNING:
                return ContractType.DOCUMENT_BUILDER;
            case Product.TRUSTEE:
                return ContractType.TRUSTEE;
            case Product.MERS_API_INTEGRATION:
                return ContractType.LIEN_RELEASE;
            case Product.LENDER_VENDOR:
                return ContractType.LENDER_VENDOR;
            case Product.NOTARY_VENDOR:
                return ContractType.NOTARY_VENDOR;
            case Product.ESIGN_EVENTS_VENDOR:
                return ContractType.ESIGN_EVENTS_VENDOR;
            case Product.RECIPIENT_VENDOR:
                return ContractType.RECIPIENT_VENDOR;
            case Product.SUBMITTER_VENDOR:
                return ContractType.SUBMITTER_VENDOR;
            case Product.ESIGN_EVENT:
                return ContractType.ESIGN_EVENT;
            case Product.SUBSCRIPTION:
                return ContractType.SUBSCRIPTION;
            default:
                return null;
        }
    }

    addContractDateDayjs(item: ContractExtended) {
        let expirationDayjs: dayjs.Dayjs;
        let executedDayjs: dayjs.Dayjs;
        let effectiveDayjs: dayjs.Dayjs;

        if (!item) {
            return;
        }

        if (item.executedDate) {
            executedDayjs = dayjs(item.executedDate);
            item.executedDateDayjs = executedDayjs.format("MM/DD/YYYY");
            item.executedDateTime = executedDayjs.format("LT");
        } else {
            item.executedDateDayjs = "";
        }
        if (item.expirationDate) {
            expirationDayjs = dayjs(item.expirationDate);
            item.expirationDateDayjs = expirationDayjs.format("MM/DD/YYYY");
            item.expirationDateTime = expirationDayjs.format("LT");
        } else {
            item.expirationDateDayjs = "";
        }
        if (item.effectiveDate) {
            effectiveDayjs = dayjs(item.effectiveDate);
            item.effectiveDateDayjs = effectiveDayjs.format("MM/DD/YYYY");
            item.effectiveDateTime = effectiveDayjs.format("LT");
        } else {
            item.effectiveDateDayjs = "";
        }

        switch (item.contractType) {
            case "MSA":
                item.documentType = "Master Service Agreement";
                break;
            case "SERVICE_AMENDMENT":
                item.documentType = "eRecording Modification";
                break;
            case "NAME_CHANGE_AMENDMENT":
                item.documentType = "Name Change Amendment";
                break;
            case "ERECORDING":
                item.documentType = "eRecording Service Addendum";
                break;
            case "PAPER_RECORDING":
                item.documentType = "Recording PLUS Service Addendum";
                break;
            case "SETTLEMENT":
                item.documentType = "Settlement Service Addendum";
                break;
            case "LENDER":
                item.documentType = "Lender Service Addendum";
                break;
            case "DOCUMENT_BUILDER":
                item.documentType = "Document Builder Service Addendum";
                break;
            case "DOC_BUILDER_RON":
                item.documentType = "Doc Builder RON Service Addendum";
                break;
            case "TRUSTEE":
                item.documentType = "Trustee Service Addendum";
                break;
            case "LIEN_RELEASE":
                item.documentType = "Automated Document Request Addendum";
                break;
            case "SUBSCRIPTION":
                item.documentType = "Subscription Addendum";
                break;
            case "ESIGN_EVENT":
                item.documentType = "eSign Event Service Addendum";
                break;
            case "LENDER_VENDOR":
                item.documentType = "Lender Vendor Service Addendum";
                break;
            case "NOTARY_VENDOR":
                item.documentType = "Notary Vendor Service Addendum";
                break;
            case "ESIGN_EVENTS_VENDOR":
                item.documentType = "eSign Events Vendor Service Addendum";
                break;
            case "RECIPIENT_VENDOR":
                item.documentType = "Recipient Vendor Service Addendum";
                break;
            case "SUBMITTER_VENDOR":
                item.documentType = "Submitter Vendor Service Addendum";
                break;
            default:
                item.documentType = "Unknown";
                break;
        }
    }
}
