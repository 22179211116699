import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "@sf/userorg/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";

@Component({
    selector: "sf-unregister-authenticator-dialog",
    templateUrl: "./unregister-authenticator-dialog.component.html",
    styleUrls: ["./unregister-authenticator-dialog.component.scss"]
})
export class UnregisterAuthenticatorDialogComponent implements OnInit {
    @ViewChild("password") passwordElement: ElementRef;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private userService: UserService,
        private activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.primary = {
            text: "Continue",
            disabled: false,
            callback: this.continue.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        setTimeout(() => {
            this.passwordElement.nativeElement.focus();
        }, 300);
    }

    continue() {
        let password = this.passwordElement.nativeElement.value;
        this.userService
            .unregisterAuthenticatorApp(password)
            .subscribe((result) => {
                if (result) {
                    this.activeModal.close("success");
                }
            });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
