import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-text-field",
    templateUrl: "./text-field.component.html",
    styleUrls: ["./text-field.component.scss"]
})
export class TextFieldComponent
    extends InputComponent
    implements OnInit, AfterViewInit
{
    @ViewChild("textField") textField: ElementRef;
    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        if (this.field.placeholder && this.textField) {
            this.textField.nativeElement.attr(
                "placeholder",
                this.field.placeholder
            );
        }
    }
}
