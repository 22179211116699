import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class KindOfInstrumentBusinessTypeGuard implements CanActivate {
    constructor(private _sessionService: SessionService) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (
            this._sessionService.hasAnyPermissionInAnyOrg([
                "admin_manage_business_type_koi_categories"
            ])
        ) {
            return true;
        } else {
            return false;
        }
    }
}
