<sf-modal-header [title]="this.title"></sf-modal-header>
<div class="modal-body">
    <p>The following is a list of requirements that need to be completed in order to submit documents electronically to {{recipientName}}.</p>
    <div>
        <div *ngFor="let setting of submitterSettings" class="submitter-setting-item">
            <sf-recipient-submitter-setting [setting]="setting" [submitterValues]="submitterValues">
            </sf-recipient-submitter-setting>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
