import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { BaseModalComponent } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl } from "@angular/forms";
import { CustomFormService } from "../../custom-form.service";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { take } from "rxjs/operators";

@Component({
    selector: "sf-custom-currency-field",
    templateUrl: "./custom-currency-field.component.html",
    styleUrls: ["./custom-currency-field.component.scss"]
})
export class CustomCurrencyFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    originalAmount: string;
    confirmModal: BaseModalComponent;

    @ViewChild("confirmAmountDialog")
    confirmAmountDialog: TemplateRef<any>;
    @ViewChild("amount")
    amountInput: ElementRef;

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService,
        private _modalService: NgbModal
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.formControl$.pipe(take(1)).subscribe((control: FormControl) => {
            this._addCommasOnLoad(control);
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    confirmAmount(control: FormControl) {
        const CONFIRM_THRESHOLD = 1000000;
        if (Number(control.value.replace(/,/g, "")) >= CONFIRM_THRESHOLD) {
            this.originalAmount = control.value;
            control.reset();

            this.confirmModal =
                this._modalService.open(BaseModalComponent).componentInstance;
            this.confirmModal.title = "Confirm Currency Amount";
            this.confirmModal.template = this.confirmAmountDialog;
            this.confirmModal.primary = {
                text: "Confirm",
                disabled: true,
                callback: () => {
                    control.setValue(this.originalAmount);
                    this.formatAsValidCurrency(control);
                    return true;
                }
            };
            this.confirmModal.secondary = {
                text: "Enter Another Value",
                callback: () => {
                    this.amountInput.nativeElement.focus();
                    return true;
                }
            };
        }
    }

    handleConfirmInputChange(confirmAmountInput: HTMLInputElement) {
        this._addCommasInput(confirmAmountInput);
        let inputValue: number = parseFloat(
            confirmAmountInput.value.split(",").join("")
        );
        this.confirmModal.primary.disabled =
            inputValue !== parseFloat(this.originalAmount.split(",").join(""));
    }

    addCommasControl(control: FormControl, emitEvent: boolean = true) {
        control.setValue(this._addCommas(control.value), {
            emitEvent: emitEvent
        });
    }

    formatAsValidCurrency(control: FormControl) {
        if (!control.value.includes(".")) {
            control.setValue(control.value + ".00");
        } else if (control.value.charAt(control.value.length - 1) === ".") {
            control.setValue(control.value + "00");
        } else if (control.value.charAt(control.value.length - 2) === ".") {
            control.setValue(control.value + "0");
        }
    }

    private _addCommasOnLoad(control: FormControl) {
        let currencyParts = control.value.split(".");
        currencyParts[0] = currencyParts[0]
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        control.setValue(currencyParts.join("."));
    }

    private _addCommasInput(amountInput: HTMLInputElement) {
        amountInput.value = this._addCommas(amountInput.value);
    }

    private _addCommas(amount: string): string {
        return !amount.includes(".")
            ? amount.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : amount;
    }
}
