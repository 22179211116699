/**
 * Migrated from sfNews.service.js
 */
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { RpcClientService } from "../rpc-client.service";
import { tap } from "rxjs/operators";

export type Priority = "information" | "outage" | "closure";
export type Message = {
    priority?: Priority;
    messageDate: Date;
    messageText: string;
};

@Injectable({
    providedIn: "root"
})
export class NewsService {
    private _messageCount: number | null = null;
    private _submitterMessageCount: number | null = null;

    constructor(private _rpc: RpcClientService) {}

    updateMessageReadCount(): void {
        this._messageCount = null;
    }

    getAllMessages(): Observable<Message[]> {
        return this._rpc.makeRequest("erecord", "getMessages");
    }

    getMessageTooltip(priority: Priority): string {
        if (priority === "information")
            return "General product announcements or other interesting news";
        if (priority === "outage")
            return "A vendor, recipient, or Simplifile may be offline or experiencing performance problems";
        if (priority === "closure")
            return "This recipient will be closing at a different time than they normally do";
    }

    getRecipientMessages(
        recipientID: string,
        byPriority: boolean = false
    ): Observable<Message[]> {
        return this._rpc.makeRequest("erecord", "getRecipientMessages", {
            recipientID,
            byPriority
        });
    }

    getMessagesForRecipient(recipientID: string): Observable<Message[]> {
        return this._rpc.makeRequest("erecord", "getMessagesForRecipient", {
            recipientID: recipientID
        });
    }

    getUnreadMessagesCountForRecipientOfPackage(
        packageID: string
    ): Observable<number> {
        return this._rpc.makeRequest(
            "erecord",
            "getUnreadMessagesCountForRecipientOfPackage",
            {
                packageID
            }
        );
    }

    getSubmitterMessageCenterMessages(): Observable<Message[]> {
        return this._rpc.makeRequest(
            "erecord",
            "getSubmitterMessageCenterMessages"
        );
    }

    getUnreadMessagesCount(): Observable<number> {
        if (this._messageCount !== null) {
            return of(this._messageCount);
        }
        return this._rpc
            .makeRequest<number>("erecord", "getUnreadMessagesCount")
            .pipe(tap((count) => (this._messageCount = count)));
    }

    getUnreadMessagesCountForSubmitter(): Observable<number> {
        if (this._submitterMessageCount !== null) {
            return of(this._submitterMessageCount);
        }
        return this._rpc
            .makeRequest<number>(
                "erecord",
                "getUnreadMessagesCountForSubmitter"
            )
            .pipe(
                tap((count: number) => (this._submitterMessageCount = count))
            );
    }
}
