import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { UIESignEventNotary } from "../interfaces/sign-event.interface";

export interface UIEEOrganizationSettings {
    disableNotaryIDPromptsForIPEN: boolean;
    disableNotaryPromptForFees: boolean;
    disableSessionTagging: boolean;
    disableAppointmentReminderEmails: boolean;
    disableTaggingTemplates: boolean;
    demoRONEvents: boolean;
    demoIPENEvents: boolean;
    waitBetweenDemoSteps: number;
    disableNotaryNotesAndFeesDialog: boolean;
    sendFirstNonSignedEmailReminder: boolean;
    firstNonSignedEmailReminderDays: number; // 1-9
    sendSecondNonSignedEmailReminder: boolean;
    secondNonSignedEmailReminderDays: number; // 1-9
    sendThirdNonSignedEmailReminder: boolean; // Day of closing
    enableERecording: boolean;
    requireUniqueEventName: boolean;
    enableFidelityRequirements: boolean;
    daysToRetainDocumentsAfterCompletion: number;
    ronAllowPersonallyKnownVerification: boolean;
    autoEnableRonAllowPersonallyKnownVerificationForNewNotaries: boolean;
    disableRonAllowPersonallyKnownVerificationForThirdPartyNotaries: boolean;
    defaultAllEventsToRonAllowPersonallyKnownVerification: boolean;
    ronAllowPersonallyKnownVerificationNotaries: string[];
    assignWitnessesWorkflowSetting: string;
    // allowFeeLevelInvoicing: boolean;
    // allowLicenseFeeInvoicing: boolean;
    disableThirdPartyTaggingChanges: boolean;
    sharedTemplateOrganizations: string[]; // Org IDs
}
@Injectable({
    providedIn: "root"
})
export class AdminSettingsESignEventService {
    constructor(private _rpcClient: RpcClientService) {}

    findAllNotariesForOrg(
        organizationID: string
    ): Observable<UIESignEventNotary[]> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "findAllNotariesForOrg",
            {
                organizationID
            }
        );
    }

    getEEOrganizationSettings(
        organizationID: string
    ): Observable<UIEEOrganizationSettings> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "getEEOrganizationSettings",
            {
                organizationID
            }
        );
    }

    saveEEOrganizationSettings(
        organizationID: string,
        settings: UIEEOrganizationSettings
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "saveEEOrganizationSettings",
            {
                organizationID,
                settings
            }
        );
    }
}
