import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "sf-echeck-refund-address-confirmation-dialog",
    templateUrl: "./echeck-refund-address-confirmation-dialog.component.html",
    styleUrls: ["./echeck-refund-address-confirmation-dialog.component.css"]
})
export class EcheckRefundAddressConfirmationDialogComponent implements OnInit {
    @Input() payments: any[];

    modalTitle: string = "Physical Check Refund";
    primaryButton: ModalButton;
    secondaryButton: ModalButton;

    orgIDs: string[] = [];
    private _orgAddresses: string[] = [];

    constructor(
        private _userorgService: UserOrgService,
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.primaryButton = {
            text: "Confirm",
            responseValue: true
        };
        this.secondaryButton = {
            text: "Cancel",
            responseValue: false
        };

        //loop through payments and get unique org ids
        if (this.payments && this.payments.length > 0) {
            this.payments.forEach((payment: any) => {
                if (this.orgIDs.indexOf(payment.organizationID) === -1) {
                    this.orgIDs.push(payment.organizationID);
                }
            });
        }

        //get organization address info for each org id
        this.orgIDs.forEach((orgID) => {
            this._userorgService.getOrganization(orgID).subscribe((org) => {
                let address: string =
                    "<h4>" +
                    org.name +
                    " (" +
                    orgID +
                    ")</h4>" +
                    org.address.streets[0] +
                    "<br/>";
                if (org.address.streets[1]) {
                    address += org.address.streets[1] + "<br/>";
                }
                address +=
                    org.address.city +
                    ", " +
                    org.address.state +
                    " " +
                    org.address.zipCode;
                this._orgAddresses.push(address);
            });
        });
    }

    getOrganizationInfo(orgID: string): SafeHtml {
        let index: number = this.orgIDs.indexOf(orgID);
        return this._sanitizer.bypassSecurityTrustHtml(
            this._orgAddresses[index]
        );
    }
}
