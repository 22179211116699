import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {
    NgbActiveModal,
    NgbModal,
    NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";
import { SpinnerService } from "@sf/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { County } from "../../interfaces/county.interface";
import { GrowlService } from "@sf/common";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AbstractCountiesDataService } from "../../services/abstract-counties-data.service";

@Component({
    selector: "sf-print-scale-dialog",
    templateUrl: "./print-scale-dialog.component.html",
    styleUrls: ["./print-scale-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class PrintScaleDialogComponent implements OnInit {
    @Input()
    orgID: string;
    @Input()
    counties: County[];

    @Output()
    printScaleUpdated = new EventEmitter();

    printScaleForm: FormGroup;

    title: string;
    applyAll: boolean;
    primary: ModalButton;
    secondary: ModalButton;
    tooltipMessage: string;

    printScale: number;

    @ViewChild("tooltip", { static: true }) tooltip: NgbTooltip;

    constructor(
        private modalService: NgbModal,
        private spinnerService: SpinnerService,
        private _dataService: AbstractCountiesDataService,
        private growlService: GrowlService,
        private _activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.title = "Print Scale";
        this.tooltipMessage = "Please enter a whole number between 1 and 100";
        this.printScale =
            this.counties.length === 1 ? this.counties[0].printScale : 100;

        // Reactive Form
        this.printScaleForm = new FormGroup({
            printScaleValue: new FormControl(null, [
                Validators.required,
                Validators.min(1),
                Validators.max(100)
            ]),
            applyAll: new FormControl(null)
        });

        // Set default values
        this.printScaleForm.setValue({
            printScaleValue: this.printScale,
            applyAll: false
        });

        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.savePrintScale.bind(this)
        };
        this.secondary = { text: "Cancel" };

        // Activate tooltip & disable submit for invalid printScale values
        this.printScaleForm
            .get("printScaleValue")
            .statusChanges.subscribe((value: string) => {
                if (value === "INVALID") {
                    this.tooltip.open();
                    this.primary.disabled = true;
                } else {
                    this.tooltip.close();
                    this.primary.disabled = false;
                }
            });
    }

    savePrintScale() {
        this.spinnerService.startSpinner();
        of(this.printScaleForm.value)
            .pipe(
                switchMap((value) => {
                    if (value.applyAll) {
                        return this._dataService.setAllCountiesPrintScale(
                            this.orgID,
                            value.printScaleValue
                        );
                    } else {
                        return this._dataService.setCountyPrintScale(
                            this.orgID,
                            this.counties.map((c) => c.id),
                            value.printScaleValue
                        );
                    }
                })
            )
            .subscribe(() => {
                this.printScaleUpdated.emit({
                    applyAll: this.printScaleForm.value.applyAll,
                    counties: this.counties,
                    printScale: this.printScaleForm.value.printScaleValue
                });
                this._activeModal.dismiss();
                this.spinnerService.stopSpinner();
                this.growlService.success("Print scale saved.");
            });
    }

    isValidPrintScale() {
        let printScale = this.printScaleForm.get("printScaleValue").value;
        return (
            Number.isInteger(printScale) && printScale <= 100 && printScale > 0
        );
    }
}
