import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "sf-customer-survey-essay",
    templateUrl: "./customer-survey-essay.component.html",
    styleUrls: ["./customer-survey-essay.component.scss"]
})
export class CustomerSurveyEssayComponent {
    /** I/O **/
    @Input()
    placeholder?: string;
    @Output()
    commentSubmitted = new EventEmitter<string>();

    /** Private Variables **/

    /** Public Variables **/
    comment = "";

    /** View Children **/

    constructor() {}

    /** Lifecycle Hooks **/

    /** Public Methods **/

    /**  Private Methods  **/
}
