import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { dayjs } from "@sf/common";
import {
    GrowlService,
    ModalButton,
    SelectableItem,
    SelectableItemWithID,
    SfValidators
} from "@sf/common";
import { LicenseUploadData } from "../license-upload-common/license-upload-interfaces";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    BillingCycleType,
    Contract,
    ContractFeeType,
    ContractService,
    ContractType,
    PaymentTerms,
    RenewalFrequencyType
} from "@sf/userorg/common";
import { EnrollmentCodeSelectDialogComponent } from "../enrollment-code-select-dialog/enrollment-code-select-dialog.component";
import { LicenseUtilityService } from "../../services/license-utility.service";
import { SafeHtml } from "@angular/platform-browser";

// prettier-ignore
@Component({
    selector: "sf-esign-event-upload-dialog",
    templateUrl: "./esign-event-upload-dialog.component.html",
    styleUrls: ["./esign-event-upload-dialog.component.scss"]
})
export class EsignEventUploadDialogComponent implements OnInit {
    @Input() orgID: string;
    @Input() isActive: boolean;

    currentIndex = 0;
    stepCount = 2;
    warning: SafeHtml = "";
    nextButton: ModalButton;
    backButton: ModalButton;
    existingContract: Contract = null;

    maxDate: dayjs.Dayjs = dayjs().add(1, "year").startOf("day");
    maxSignDate? = dayjs();
    minSignDate: dayjs.Dayjs;
    maxEffectiveDate: dayjs.Dayjs = this.maxDate;
    primary: ModalButton;
    secondary: ModalButton;
    title = "eSign Events Addendum Upload";
    expirationDayjs: dayjs.Dayjs;
    effectiveDayjs: dayjs.Dayjs;
    today = dayjs();

    showPricingTiers = false;
    selectableRenewalFrequencies: SelectableItem[];
    selectableBillingCycles: SelectableItemWithID[];
    selectablePaymentTerms: SelectableItemWithID[];

    dialogData: LicenseUploadData = {
        executedDate: null,
        effectiveDate: null,
        signatureName: null,
        signatureTitle: null,
        expirationDate: null,
        licenseFee: null,
        renewalFee: null,
        eNotarizationFee: null,
        iPenFee: null,
        ronFee: null,
        renewalFrequency: RenewalFrequencyType.MONTHLY,
        billingCycle: BillingCycleType.MONTHLY,
        paymentTerms: PaymentTerms.NET15,
        file: null,
        freeSubmissions: 0
    };

    @ViewChild("file") file: any;

    constructor(
            private activeModal: NgbActiveModal,
            private growlService: GrowlService,
            private modalService: NgbModal,
            private licenseService: LicenseUtilityService,
            private contractService: ContractService
    ) {}

    ngOnInit() {
        this.setMinSignDate();

        // Modal buttons
        this.primary = {
            text: "Upload",
            disabled: false,
            callback: this.ok.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.selectableRenewalFrequencies = [
            {
                label: "Yearly",
                option: "YEARLY"
            },
            {
                label: "Monthly",
                option: "MONTHLY"
            }
        ];

        this.selectableBillingCycles =
            this.contractService.getSelectableBillingCycles("ESIGN_EVENT");
        this.selectablePaymentTerms =
            this.contractService.getSelectablePaymentTerms();
        this.effectiveDayjs = dayjs();

        this.contractService.getActiveOrFutureContract(this.orgID, ContractType.ESIGN_EVENT)
            .subscribe((currentContract: Contract) => {
                if (currentContract && currentContract.contractID) {
                    this.existingContract = currentContract;
                    if (currentContract.contractFees) {
                        // copy fees from currently existing contract to the dialog
                        Object.values(currentContract.contractFees).forEach((fee) => {
                            if (fee.feeType == "ESE_LICENSE_FEE") {
                                this.dialogData.licenseFee = fee.contractFeeTiers[0].feeAmount;
                                this.dialogData.licenseFee =
                                        this.licenseService.roundOff(this.dialogData.licenseFee, 2);
                            } else if (fee.feeType == "ESE_RENEWAL_FEE") {
                                this.dialogData.renewalFee = fee.contractFeeTiers[0].feeAmount;
                                this.dialogData.renewalFee =
                                        this.licenseService.roundOff(this.dialogData.renewalFee, 2);
                            } else if (fee.feeType == "ESE_IPEN") {
                                this.dialogData.iPenFee = fee.contractFeeTiers[0].feeAmount;
                                this.dialogData.iPenFee = this.licenseService.roundOff(this.dialogData.iPenFee, 2);
                            } else if (fee.feeType == "ESE_NO_ENOTARIZATION") {
                                this.dialogData.eNotarizationFee = fee.contractFeeTiers[0].feeAmount;
                                this.dialogData.eNotarizationFee =
                                        this.licenseService.roundOff(this.dialogData.eNotarizationFee, 2);
                            } else if (fee.feeType == "ESE_RON") {
                                this.dialogData.ronFee = fee.contractFeeTiers[0].feeAmount;
                                this.dialogData.ronFee = this.licenseService.roundOff(this.dialogData.ronFee, 2);
                            }
                        });
                    }
                }
            });

        this.nextButton = {
            text: "Next",
            hidden: false,
            callback: this.nextTab.bind(this)
        };
        this.backButton = {
            text: "Back",
            hidden: false,
            callback: this.backTab.bind(this)
        };
        this.enableDisable();
    }

    setMinSignDate(): void {
        let period: dayjs.UnitType = "year";
        if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
            period = "month";
        }
        this.minSignDate = dayjs().subtract(1, period).add(1, "day");
    }

    enableDisable() {
        this.nextButton.hidden = this.currentIndex > 0;
        this.backButton.hidden = this.currentIndex == 0;
        this.primary.hidden = this.currentIndex == 0;
    }

    nextTab() {
        this.warning = this.validateFirstPage();

        if (this.warning) {
            return;
        }

        this.warning = null;
        this.currentIndex++;
        this.enableDisable();
    }

    backTab() {
        this.currentIndex--;
        this.enableDisable();
    }

    openFileExplorer() {
        this.file.nativeElement.click();
    }

    onFileAdded() {
        let files = Array.from(this.file.nativeElement.files);
        if (!files || !files.length) {
            return; // probably they had previously selected a file, and now they canceled
        }
        // only one file should be selected - but just in case, take the last one
        let file: File = null;
        files.forEach((fileItem: File) => {
            file = fileItem;
        });

        // check file extension
        let filename = file.name;
        let fileExtPattern = /\.[0-9a-z]+$/i;
        let fileExt = fileExtPattern.exec(file.name);
        let invalidExtension = (!fileExt);
        if (fileExt) {
            let lowerExt = fileExt.toString().toLowerCase();
            if (!lowerExt.includes(".pdf") && !lowerExt.includes(".tif") && !lowerExt.includes(".tiff")) {
                invalidExtension = true;
            }
        }
        if (invalidExtension) {
            this.growlService.error("Only PDF and TIF files are allowed.");
            return;
        }

        this.dialogData.file = file;
    }

    ok() {
        this.warning = this.validateSecondPage();
        if (this.warning) {
            return;
        }

        //set time on executed date
        let now: dayjs.Dayjs = dayjs();
        let then: dayjs.Dayjs = dayjs(this.dialogData.executedDate);
        now = now.set("date", then.get("date")).set("month", then.get("month")).set("year", then.get("year"));
        this.dialogData.executedDate = now.toISOString();

        this.activeModal.close(this.dialogData);
    }

    cancel() {
        this.activeModal.close(null);
    }

    blurSignatureDate() {
        if (this.dialogData.executedDate) {
            //always set expiration date based on signature date
            let period: dayjs.UnitType = "year";
            if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
                period = "month";
            }
            this.expirationDayjs = dayjs(this.dialogData.executedDate).add(1, period);
            this.dialogData.expirationDate = this.licenseService.formatDayjs(this.expirationDayjs);

            //set maximum effective date to expiration date - 1 day
            this.maxEffectiveDate = dayjs(this.expirationDayjs).subtract(1, "day");
        }
    }

    effectiveDateChanged(newDate: dayjs.Dayjs) {
        this.dialogData.effectiveDate = this.licenseService.formatDayjs(newDate);
    }

    selectRenewalFrequency($event: any) {
        let newCycle = $event.$selection;
        this.dialogData.renewalFrequency = newCycle.option;

        this.setMinSignDate();
        this.blurSignatureDate();
    }

    selectBillingCycle($event: any) {
        let newCycle = $event.$selection;
        this.dialogData.billingCycle = newCycle.id;
    }

    selectPaymentTerm($event: any) {
        let newTerm = $event.$selection;
        this.dialogData.paymentTerms = newTerm.id;
    }

    changeEnrollmentCode() {
        this.showEnrollmentCodes();
    }

    removeEnrollmentCode() {
        this.dialogData.pricingTemplate = null;
    }

    showEnrollmentCodes() {
        const modalRef = this.modalService.open(EnrollmentCodeSelectDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = "ESIGN_EVENT";

        modalRef.result.then((pricingTemplate: any) => {
            if (pricingTemplate) {
                this.dialogData.pricingTemplate = pricingTemplate;

                this.dialogData.licenseFee =
                        pricingTemplate.contractTemplateFees.ESE_LICENSE_FEE.contractFeeTiers[0].feeAmount;
                this.dialogData.renewalFee =
                        pricingTemplate.contractTemplateFees.ESE_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                this.dialogData.eNotarizationFee =
                        pricingTemplate.contractTemplateFees.ESE_NO_ENOTARIZATION.contractFeeTiers[0].feeAmount;
                this.dialogData.iPenFee = pricingTemplate.contractTemplateFees.ESE_IPEN.contractFeeTiers[0].feeAmount;
                this.dialogData.ronFee = pricingTemplate.contractTemplateFees.ESE_RON.contractFeeTiers[0].feeAmount;

                this.dialogData.freeSubmissions = pricingTemplate.freeSubmissions;
                this.dialogData.renewalFrequency = pricingTemplate.renewalFrequency;

                if (pricingTemplate.billingCycleType) {
                    this.dialogData.billingCycle = pricingTemplate.billingCycleType;
                } else {
                    this.dialogData.billingCycle = BillingCycleType.MONTHLY;
                }
                if (pricingTemplate.paymentTerms) {
                    this.dialogData.paymentTerms = pricingTemplate.paymentTerms;
                } else {
                    this.dialogData.paymentTerms = PaymentTerms.NET15;
                }
            }
        }, () => {
            // nothing on cancel
        });
    }

    validateFirstPage(): string {
        let message = null;

        if (!message && (!this.dialogData.signatureName || this.dialogData.signatureName.trim().length == 0)) {
            message = "Signer Name is required";
        }
        if (!message && !this.dialogData.executedDate) {
            message = "Signature Date is required";
        }
        if (!message && !this.dialogData.effectiveDate) {
            message = "Effective Date is required";
        }
        if (!message && !this.dialogData.renewalFrequency) {
            message = "Renewal Frequency is required";
        }
        if (!message) {
            if (!this.maxSignDate.isSameOrAfter(this.dialogData.executedDate)) {
                message = "Signature date cannot be in the future";
            }
        }
        if (!message) {
            if (!this.minSignDate.isSameOrBefore(this.dialogData.executedDate)) {
                message = "Signature date is before the allowed minimum date";
            }
        }
        if (!message) {
            if (this.dialogData.effectiveDate && dayjs().startOf("day").isAfter(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the past";
            } else if (!this.isActive && this.dialogData.effectiveDate && dayjs().isBefore(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the future, since the product is not active yet.";
            }
        }
        if (!message) {
            if (dayjs(this.dialogData.effectiveDate).isBefore(this.dialogData.executedDate)) {
                message = "Effective date cannot be before the signature date";
            } else if (dayjs(this.dialogData.effectiveDate).isSameOrAfter(this.dialogData.expirationDate)) {
                message = "Effective date must be before the expiration date";
            }
        }

        return message;
    }

    validateSecondPage(): string {
        let message = null;

        if (typeof this.dialogData.ronFee == "undefined" || this.dialogData.ronFee == null) {
            message = "RON Fee is required";
        }
        if (typeof this.dialogData.iPenFee == "undefined" || this.dialogData.iPenFee == null) {
            message = "IPEN Fee is required";
        }
        if (typeof this.dialogData.eNotarizationFee == "undefined" || this.dialogData.eNotarizationFee == null) {
            message = "No E-Notarization Fee is required";
        }
        if (typeof this.dialogData.renewalFee == "undefined" || this.dialogData.renewalFee == null) {
            message = "Renewal License Fee is required";
        }
        if (typeof this.dialogData.licenseFee == "undefined" || this.dialogData.licenseFee == null) {
            message = "Initial License Fee is required";
        }

        if (message) {
            return message;
        }

        if (!SfValidators.isPositiveIntegerString(this.dialogData.freeSubmissions)) {
            message = "Free Transactions must be a positive whole number or zero";
        }
        if (!SfValidators.isPositiveFloatString(this.dialogData.ronFee)) {
            message = "RON Fee must be a positive number";
        }
        if (!SfValidators.isPositiveFloatString(this.dialogData.iPenFee)) {
            message = "IPEN Fee must be a positive number";
        }
        if (!SfValidators.isPositiveFloatString(this.dialogData.eNotarizationFee)) {
            message = "No E-Notarization Fee must be a positive number";
        }
        if (!SfValidators.isPositiveFloatString(this.dialogData.renewalFee)) {
            message = "Renewal License Fee must be a positive number";
        }
        if (!SfValidators.isPositiveFloatString(this.dialogData.licenseFee)) {
            message = "Initial License Fee must be a positive number";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.paymentTerms) {
            message = "Payment term is required";
        }
        if (!this.dialogData.billingCycle) {
            message = "Billing cycle is required";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.file) {
            message = "Select a file to upload";
        }

        this.dialogData.ronFee = this.licenseService.roundOff(this.dialogData.ronFee, 2);
        this.dialogData.iPenFee = this.licenseService.roundOff(this.dialogData.iPenFee, 2);
        this.dialogData.eNotarizationFee = this.licenseService.roundOff(this.dialogData.eNotarizationFee, 2);
        this.dialogData.renewalFee = this.licenseService.roundOff(this.dialogData.renewalFee, 2);
        this.dialogData.licenseFee = this.licenseService.roundOff(this.dialogData.licenseFee, 2);

        if (!SfValidators.testFee(this.dialogData.ronFee)) {
            return "Invalid RON Notary Fee format";
        }
        if (!SfValidators.testFee(this.dialogData.iPenFee)) {
            return "Invalid IPEN Notary Fee format";
        }
        if (!SfValidators.testFee(this.dialogData.eNotarizationFee)) {
            return "Invalid No e-notarization Fee format";
        }
        if (!SfValidators.testFee(this.dialogData.renewalFee)) {
            return "Invalid Renewal License Fee format";
        }
        if (!SfValidators.testFee(this.dialogData.licenseFee)) {
            return "Invalid Initial License Fee format";
        }

        return message;
    }
}
