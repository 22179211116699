/**
 * This is an 'enhanced' organization selector
 * It always allows selecting multiple organizations
 * It is not a 'drop-down', since it is always dropped down
 * It works for super-users or customer users, based on the 'allOptions' input
 */
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import {
    removeByValue,
    SessionService,
    SortUtilitiesService,
    unionBy
} from "@sf/common";
import { Organization, SelectableOrganization } from "@sf/userorg/common";
import { OrganizationService } from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-userorg-organization-multi-selector",
    templateUrl: "./organization-multi-selector.component.html",
    styleUrls: ["./organization-multi-selector.component.scss"]
})
export class OrganizationMultiSelectorComponent implements OnInit, OnChanges {
    @Input() initialSelection: string[]; // array of organization IDs
    @Input() allOptions: SelectableOrganization[]; // if empty (and user is super user), allows searching for any organization
    @Input() options: SelectableOrganization[]; // (optional) array of initially selectable organizations
    @Input() lock: boolean; // if true, displays only a header, and no selections
    @Input() selectableOrgServices: string[]; // array of services ids to filter search results (optional)
    @Input() ignoreDisabled: boolean;   // ignore disabled organizations

    @Output() changed: EventEmitter<{
        $selection: string[];
    }> = new EventEmitter(); // sends selected org IDs
    @Output() changedObjects: EventEmitter<{
        $selection: SelectableOrganization[];
    }> = new EventEmitter(); // sends selected org objects
    @Output() filterCleared: EventEmitter<boolean> = new EventEmitter(); // sends true when filter cleared
    @Output() selectedOrgsCleared: EventEmitter<boolean> = new EventEmitter(); // sends true when org selector cleared button clicked if on the billing reports page

    isSuperUser = false;
    searchDelay: any = null;

    showingResults = true;
    isTyping = false;
    searching = true;

    /** Public Variables **/
    organizationsList: SelectableOrganization[] = [];
    filteredOrganizations: SelectableOrganization[] = [];
    searchText = { text: "" };
    selectedViewOption = "all";
    selectedOrganizations: SelectableOrganization[] = []; // array of organization objects

    @ViewChildren("checkbox") checkboxes: QueryList<ElementRef>;

    constructor(
            private sessionService: SessionService,
            private organizationService: OrganizationService
    ) {}

    ngOnInit() {
        this.isSuperUser = this.sessionService.isSuperUser();

        if (!this.allOptions || !this.allOptions.length) {
            this.allOptions = this.options;
        }

        this.searchText.text = "";
        this.newOrganizationsReceived(this.options, this.allOptions);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            if (!this.allOptions || !this.allOptions.length) {
                this.allOptions = this.options;
            }
            this.searchText.text = "";
            this.newOrganizationsReceived(this.options, this.allOptions);
        }
        if (changes.initialSelection && changes.initialSelection.currentValue) {
            if (!changes.initialSelection.currentValue.length) {
                this.clearSelection();
            }
        }
    }

    setInitialSelection() {
        if (this.initialSelection) {
            this.initialSelection.forEach((orgID) => {
                let org = this.findDisplayedOrganization(orgID);
                if (org) {
                    this.addSelectedItem(org);
                }
            });
        }
    }

    headerToggle() {
        // nothing
    }

    calculateTooltip(org: SelectableOrganization): string {
        let orgLabel = org.label;
        let orgId = org.id;
        let parenId = "(" + orgId + ")";
        let tooltip = orgLabel + " " + parenId; //default tooltip
        if (orgLabel.includes(parenId)) {
            let newLabel = orgLabel.replace(parenId, "");
            tooltip = newLabel + " " + parenId;
        }
        tooltip += " ";
        if (org.city) {
            tooltip += org.city;
        }
        if (org.city && org.state) {
            tooltip += ", ";
        }
        if (org.state) {
            tooltip += org.state;
        }

        return tooltip;
    }

    clearSelection() {
        if (this.lock) {
            return; // can't change
        }
        if (!this.selectedOrganizations.length) {
            return; // already clear
        }
        this.selectedOrganizations.length = 0;
        this.organizationsList.forEach((org: SelectableOrganization) => {
            org.selected = false;
        });
        window.setTimeout(() => {
            this.selectedViewOption = "all"; // back
        }, 1);
        this.changed.emit({ $selection: [] });
        this.changedObjects.emit({ $selection: [] });
        this.selectedOrgsCleared.emit(true);
    }

    onSearchChange(/*event*/) {
        this.filterTextChanged();
    }

    // get the 'selection' text to display
    selectionText(): string {
        let selText: string;
        if (
            !this.selectedOrganizations ||
            this.selectedOrganizations.length == 0
        ) {
            selText = "No org selected";
        } else if (this.selectedOrganizations.length == 1) {
            selText = this.selectedOrganizations[0].label;
        } else {
            selText =
                "" +
                this.selectedOrganizations.length +
                " orgs selected";
        }
        return selText;
    }

    addSelectedItem(selectedItem: SelectableOrganization) {
        let isInSelected = this.findSelectedOrganization(selectedItem.id);
        if (!isInSelected) {
            selectedItem.selected = true;
            this.selectedOrganizations.push(selectedItem);
        }
    }

    removeSelectedItem(selectedItem: SelectableOrganization) {
        if (!selectedItem) {
            return;
        }

        let toRemove = this.findSelectedOrganization(selectedItem.id);
        if (toRemove) {
            removeByValue(this.selectedOrganizations, toRemove);
        }
    }

    private getSelectedItem(checkbox: any): SelectableOrganization {
        if (!checkbox) {
            return null;
        }
        let itemValue = checkbox.value;
        let itemLabel: string = null;

        let siblings = checkbox.parentElement.children;
        let sibling = null;
        for (let i = 0; i < siblings.length; i++) {
            sibling = siblings[i];
            if (sibling.localName == "span") {
                itemLabel = sibling.innerText;
                if (itemLabel) {
                    itemLabel = itemLabel.trim();
                }
            }
        }
        let result: SelectableOrganization = {
            id: itemValue,
            label: itemLabel,
            selected: false,
            city: "",
            state: ""
        };
        return result;
    }

    // called whenever a checkbox is checked
    checkChange(event: any) {
        let checkbox = event.target;
        let selectedItem: SelectableOrganization =
            this.getSelectedItem(checkbox);

        window.setTimeout(() => {
            let isSelected = this.alreadySelected(selectedItem.id);
            if (checkbox.checked) {
                // add to selected list if it's not already there
                if (!isSelected) {
                    selectedItem.selected = true;
                    this.selectedOrganizations.push(selectedItem);
                }
                this.addSelectedItem(selectedItem);
            } else {
                // de-select from the displayed list, if it's still visible
                let organization = this.findDisplayedOrganization(
                    selectedItem.id
                );
                if (organization) {
                    organization.selected = false; // uncheck it
                }
                // remove from model if it's already there
                if (isSelected) {
                    removeByValue(this.selectedOrganizations, selectedItem);
                }
                this.removeSelectedItem(selectedItem);
            }

            let selectedIDs: string[] = [];
            this.selectedOrganizations.map((org: SelectableOrganization) => {
                selectedIDs.push(org.id);
            });
            this.changed.emit({ $selection: selectedIDs });
            this.changedObjects.emit({
                $selection: this.selectedOrganizations
            });
        });
    }

    alreadySelected(orgID: string): boolean {
        let match = this.selectedOrganizations.find((v) => {
            return v.id == orgID;
        });
        return match != null;
    }

    findDisplayedOrganization(organizationID: string): SelectableOrganization {
        if (this.organizationsList) {
            for (let i = 0; i < this.organizationsList.length; i++) {
                let organization = this.organizationsList[i];
                if (organizationID == organization.id) {
                    return organization;
                }
            }
        }
        return null;
    }

    findSelectedOrganization(organizationID: string): SelectableOrganization {
        if (this.selectedOrganizations) {
            for (let i = 0; i < this.selectedOrganizations.length; i++) {
                let organization = this.selectedOrganizations[i];
                if (organizationID == organization.id) {
                    return organization;
                }
            }
        }
        return null;
    }

    syncModel(allOrganizationData: SelectableOrganization[]) {
        this.selectedOrganizations.forEach((selectedOrganization: SelectableOrganization) => {
            let organization = this.findDisplayedOrganization(selectedOrganization.id);
            if (organization) {
                organization.selected = true;
                this.addSelectedItem(organization);
            } else {
                this.removeSelectedItem(organization);
            }
        });
    }

    cancelSearch() {
        this.searchText.text = "";
        this.newOrganizationsReceived(this.options, this.allOptions);
        this.filterCleared.emit(true);
    }

    filterOrgs() {
        if (this.isSuperUser) {
            this.filteredOrganizations = this.organizationsList;
            return;
        }

        let escaped = this.getEscapedSearchFilter(); // trim the search string

        if (!escaped || escaped == "") {
            this.filteredOrganizations = this.organizationsList;
        } else {
            this.filteredOrganizations = [];
            let max = this.organizationsList.length;
            if (max > 100) {
                max = 100;
            }
            let ri = new RegExp(escaped, "i"); // get the regex ready - ignore case.
            for (let i = 0; i < max; i++) {
                let item: SelectableOrganization = this.organizationsList[i];
                if (ri.test(item.label) || ri.test(item.id)) {
                    // Matched. Add to the list
                    this.filteredOrganizations.push(item);
                } else if (item.city) {
                    if (ri.test(item.city)) {
                        // Matched. Add to the list
                        this.filteredOrganizations.push(item);
                    }
                } else if (item.state) {
                    if (ri.test(item.state)) {
                        // Matched. Add to the list
                        this.filteredOrganizations.push(item);
                    }
                } else if (item.address) {
                    if (
                        ri.test(item.address.city) ||
                        ri.test(item.address.state) ||
                        ri.test(item.address.zipCode)
                    ) {
                        // Matched. Add to the list
                        this.filteredOrganizations.push(item);
                    }
                } else if (item.contact) {
                    if (ri.test(item.contact.firstLast)) {
                        // Matched. Add to the list
                        this.filteredOrganizations.push(item);
                    }
                }
            }
        }
    }

    filterTextChanged() {
        if (this.isSuperUser) {
            let escaped = this.getEscapedSearchFilter();

            this.isTyping = true;
            if (this.searchDelay) {
                clearTimeout(this.searchDelay);
            }
            this.searchDelay = window.setTimeout(() => {
                this.showingResults = true;
                this.isTyping = false;
                this.searching = true;

                if (escaped == "") {
                    this.cancelSearch();
                } else {
                    this.organizationService.searchOrganizations(escaped, 50, this.selectableOrgServices)
                        .subscribe((foundOrgs: Organization[]) => {
                            let selectableOrgs: SelectableOrganization[] = [];
                            foundOrgs.map((org) => {
                                let sorg: SelectableOrganization = this.makeSelectableOrganization(org);
                                if (sorg) {
                                    selectableOrgs.push(sorg);
                                }
                            });
                            this.newOrganizationsReceived(selectableOrgs, []);
                        });
                }
            }, escaped == "" ? 0 : 350);
        } else {
            this.filterOrgs();
        }
        window.setTimeout(() => {
            this.selectedViewOption = "all"; // back
        }, 400);
    }

    getEscapedSearchFilter(): string {
        if (this.searchText == null || this.searchText.text == null) {
            return "";
        }
        //let escaped = this.searchText.text.replace(/[[\]{}()*+?\\^$|#]/g, "");
        let escaped = this.searchText.text.trim();
        return escaped;
    }

    //
    syncChecks(allOrganizationData: SelectableOrganization[]) {
        if (allOrganizationData) {
            allOrganizationData.forEach((org: SelectableOrganization) => {
                org.selected = this.alreadySelected(org.id);
            });
        }
    }

    makeSelectableOrganization(org: Organization): SelectableOrganization {
        if (this.ignoreDisabled && !org.isEnabled) {
            return null;
        }
        let city = "";
        let state = "";
        if (org.address) {
            city = org.address.city;
            state = org.address.state;
        }
        let selectableOrg: SelectableOrganization = {
            id: org.id,
            label: org.name + " (" + org.id + ")",
            selected: false,
            city: city,
            state: state
        };
        return selectableOrg;
    }

    newOrganizationsReceived(
        organizationData: SelectableOrganization[],
        allOrganizationData: SelectableOrganization[]
    ): SelectableOrganization[] {
        let newOrgs: SelectableOrganization[] = allOrganizationData;
        if (organizationData && allOrganizationData) {
            newOrgs = unionBy(
                organizationData,
                allOrganizationData,
                "id"
            );
        } else if (!newOrgs) {
            newOrgs = organizationData;
        }

        if (!newOrgs) {
            // still starting up, or nothing to display
            return[];
        }

        this.syncChecks(newOrgs);

        // now sort by name (case insensitive)
        newOrgs = newOrgs.sort((a, b) => {
            return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
        });

        this.organizationsList = newOrgs;
        this.filterOrgs();

        this.syncModel(newOrgs);
        this.setInitialSelection();

        return newOrgs;
    }
}
