<div *ngIf="showingRecentItems">
    <table>
        <tr>
            <td class="type-icon pr-1">
                <fa-icon icon="file-alt"></fa-icon>
            </td>
            <td style="width: 100%">
                <span class="float-left truncate-name" tooltipClass="tooltip-interaction-not">
                    <button *ngIf="!!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" (click)="uscService.goToContact(item)" sfTooltipOnOverflow="{{item.name}}" container="body" [openDelay]="100">
                        {{item.name}}
                    </button>
                </span>
            </td>
            <td style="width: 100%">
                <span class="float-right truncate">
                    <button *ngIf="!!item.statusString" id="btn-status" class="btn btn-link sf-btn-link truncate" tooltipClass="tooltip-interaction-not" [ngClass]="{'recordedStatus': item.status == 'Recorded'}"
                        (click)="uscService.goToPackageDetails(item)" [ngbTooltip]="uscService.getDocumentTooltip(item)" placement="left" [openDelay]="400" container="body">
                        {{item.statusString}}
                    </button>
                </span>
            </td>
            <td class="no-wrap pl-1">
                <span ngbDropdown container="body" class="float-right">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-package-details" ngbDropdownItem (click)="uscService.goToPackageDetails(item)" class="search-menu-item">
                            <fa-icon icon="file-alt" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Package Details
                        </button>
                        <button id="menu-package-admin" ngbDropdownItem (click)="uscService.goToPackages(item, 'admin')" class="search-menu-item">
                            <fa-icon icon="file-alt" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Package Admin
                        </button>
                        <button id="menu-transactions" ngbDropdownItem (click)="uscService.goToTransactions(item, query)" class="search-menu-item">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Transactions
                        </button>
                        <button id="menu-view-as-user" ngbDropdownItem (click)="uscService.becomeUser(item)" class="search-menu-item">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;View as User
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                <div class="lessImportant">
                    <span class="float-left truncate" sfTooltipOnOverflow="{{item.submitterName}}" [openDelay]="100" container="body">
                        {{item.submitterName}}&nbsp;
                    </span>
                    <span class="float-right truncate" sfTooltipOnOverflow="{{item.recipientName}}" [openDelay]="100" container="body">
                        {{item.recipientName}}&nbsp;
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">&nbsp;</td>
        </tr>
    </table>
</div>

<div *ngIf="!showingRecentItems">
    <table>
        <tr>
            <td style="width: 100%" class="pl-1">
                <span class="float-left truncate-name" tooltipClass="tooltip-interaction-not">
                    <button *ngIf="!!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" sfTooltipOnOverflow="{{ item.name }}" [openDelay]="100" (click)="uscService.goToContact(item)" container="body">
                        {{ item.name }}
                    </button>
                </span>
            </td>
            <td style="width: 100%">
                <span class="float-right">
                    <button *ngIf="!!item.statusString" id="btn-status" class="btn btn-link sf-btn-link truncate" tooltipClass="tooltip-interaction-not" (click)="uscService.goToPackageDetails(item)"
                            [ngClass]="{recordedStatus: item.status == 'Recorded'}" container="body"
                            [ngbTooltip]="uscService.getDocumentTooltip(item)" placement="left" [openDelay]="400">
                        {{ item.statusString }}
                    </button>
                </span>
            </td>
            <td class="no-wrap pl-1">
                <span ngbDropdown container="body" class="float-right">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-package-details" ngbDropdownItem (click)="uscService.goToPackageDetails(item)" class="search-menu-item">
                            <fa-icon icon="file-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Package Details
                        </button>
                        <button id="menu-package-admin" ngbDropdownItem (click)="uscService.goToPackages(item, 'admin')" class="search-menu-item">
                            <fa-icon icon="file-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Package Admin
                        </button>
                        <button id="menu-transactions" ngbDropdownItem (click)="uscService.goToTransactions(item)" class="search-menu-item">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Transactions
                        </button>
                        <button id="menu-view-as-user" ngbDropdownItem (click)="uscService.becomeUser(item)" class="search-menu-item">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;View as User
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pl-1">
                <div class="lessImportant">
                    <span class="float-left truncate" tooltipClass="tooltip-interaction-not" sfTooltipOnOverflow="{{item.submitterName}}" [openDelay]="100" container="body">
                        {{ item.submitterName }}&nbsp;
                    </span>
                    <span class="float-right truncate" tooltipClass="tooltip-interaction-not" sfTooltipOnOverflow="{{item.recipientName}}" [openDelay]="100" container="body">
                        {{ item.recipientName }}&nbsp;
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">
                &nbsp;
            </td>
        </tr>
    </table>
</div>
