import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SessionService, UserSettingsService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class ProjectSelectorService {
    private _projectOrgIdBS = new BehaviorSubject<string>(null);
    private _projectIdsBS = new BehaviorSubject<Array<string>>(null);
    private _projectIdsAndOrgIdBS = new BehaviorSubject<{
        projectIds?: Array<string>;
        orgId?: string;
    }>({});
    private _projectsInOrgBS = new BehaviorSubject<
        Array<{ id: string; name: string }>
    >(null);

    private _selectedProjectIds: Array<string> = null;
    private _currentProjectId: string = null;

    projectOrgId$ = this._projectOrgIdBS.asObservable();
    projectIds$ = this._projectIdsBS.asObservable();
    projectIdsAndOrgId$ = this._projectIdsAndOrgIdBS.asObservable();
    projectsInOrg$ = this._projectsInOrgBS.asObservable();

    constructor(
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService
    ) {}

    updateProjectOrgId(orgId: string) {
        this._projectOrgIdBS.next(orgId);
    }

    updateProjectIds(projectIds: Array<string>) {
        this._selectedProjectIds = projectIds;
        this._projectIdsBS.next(projectIds);
    }

    updateProjectIdsAndOrgId(projectIds: Array<string>, orgId: string) {
        this._selectedProjectIds = projectIds;
        this._projectIdsAndOrgIdBS.next({ projectIds, orgId: orgId });
    }

    updateProjectsInOrg(projectsInOrg: Array<{ id: string; name: string }>) {
        this._projectsInOrgBS.next(projectsInOrg);
    }

    getCurrentProjectId(): string {
        return (
            this._currentProjectId ??
            this.userSettingsService.getUserSetting("CURRENT_PROJECT")
        );
    }

    setCurrentProjectId(projectId: string) {
        this._currentProjectId = projectId;
    }

    getSelectedProjectIds(): Array<string> {
        return this._selectedProjectIds;
    }
}
