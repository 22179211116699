<div class="sf-unmask">
    <sf-modal-header title="Manage Review Folders"></sf-modal-header>
    <form [formGroup]="foldersForm" class="modal-body">
        <div class="row">
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="name" placeholder="Folder name"/>
            </div>
            <div class="col-sm-4 sf-mask" *ngIf="organizations.length > 1">
                <sf-select placeholder="Select organization..." [options]="organizations" labelBy="name" trackBy="id"
                           formControlName="organizationId" [isSingleSelect]="true" [hasFilter]="true">
                </sf-select>
            </div>
            <div class="col-sm-4">
                <button class="btn btn-primary folder-btn" (click)="createNewFolder()"
                        [disabled]="disableCreate">Create New Folder
                </button>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-bordered table-striped">
                    <tr><th>Name</th><th *ngIf="organizations.length > 1">Organization</th><th># of Docs</th><th colspan="2">Actions</th></tr>
                    <tr *ngFor="let folder of folders">
                        <td>{{folder.name}}</td>
                        <td *ngIf="organizations.length > 1" class="sf-mask">{{folder.organizationName}}</td>
                        <td class="count-cell">{{folder.documentCount}}</td>
                        <td class="action-cell" title="Rename folder" (click)="renameFolder(folder);">
                            <fa-icon [icon]="['far', 'pencil-alt']"></fa-icon>
                        </td>
                        <td class="action-cell" [ngClass]="{'disabled': folder.documentCount}" title="Delete folder" (click)="deleteFolder(folder);">
                            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </form>
    <sf-modal-footer [primary]="primary"></sf-modal-footer>
</div>

<ng-template #renameModal>
    <div>Name</div><input type='text' class="form-control" [(ngModel)]="rename"/>
</ng-template>

