<div class="message-date">
    <fa-icon
            *ngIf="message.priority"
            [class]="message.priority"
            [icon]="priorityIcon"
            [ngbTooltip]="getTooltip(message.priority)"
            placement="right"
            [attr.aria-hidden]="true"
    ></fa-icon>&nbsp;
    <span [attr.aria-hidden]="true">{{ message.messageDate | date: "MMMM d, yyyy" }}</span>
    <div [attr.aria-label]="ariaLabel" class="message-text" [innerHTML]="message.messageText"></div>
</div>
