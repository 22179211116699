import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencyInputComponent } from "./components";
import { SearchComponent } from "./components";
import { SimpleFilterComponent } from "./components";
import { StateSelectorComponent } from "./components";
import {
    ReactiveFormsModule,
    FormsModule as AngularFormsModule
} from "@angular/forms";
import { SelectModule } from "../select/select.module";
import { SfCommonModule } from "../common.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FlexibleListDirective } from "./directives/flexible-list.directive";
import { PhoneInputDirective } from "./directives/phone-input.directive";
import { UrlInputDirective } from "./directives/url-input.directive";
import { ZipInputDirective } from "./directives/zip-input.directive";

@NgModule({
    declarations: [
        CurrencyInputComponent,
        SearchComponent,
        SimpleFilterComponent,
        StateSelectorComponent,
        FlexibleListDirective,
        PhoneInputDirective,
        UrlInputDirective,
        ZipInputDirective
    ],
    imports: [
        CommonModule,
        AngularFormsModule,
        ReactiveFormsModule,
        SelectModule,
        SfCommonModule,
        FontAwesomeModule
    ],
    exports: [
        CurrencyInputComponent,
        SearchComponent,
        SimpleFilterComponent,
        StateSelectorComponent,
        FlexibleListDirective,
        PhoneInputDirective,
        UrlInputDirective,
        ZipInputDirective
    ]
})
export class SfFormsModule {}
