import { ConditionalType } from "../enums/conditional.enum";
import {
    Conditionals,
    DynamicField,
    FieldState,
    FieldStateWrapper,
    OverrideDynamicField
} from "./index";
import { AbstractControl } from "@angular/forms";
import {
    ConditionalChange,
    FieldFormState,
    FieldViewState,
    ROOT_FIELD_KEY,
    StateOperation
} from "../../index";
import { Observable } from "rxjs";

export interface Conditional {
    type: ConditionalType;
    field: string[];
    fullPath: string;
    override: OverrideDynamicField;
    value?: string | number | boolean | null;
    expression?: string;
    outsideCondition?: Observable<any>;
    onConditionAction?: (value: any) => boolean;
}

export interface ConditionalExpression extends Conditional {
    expression: string;
}

export interface ConditionalRule {
    readonly conditional: Conditional;
    readonly fieldsToApply: any[];

    execute(values: any): VisibilityChange[];
    addField(fieldPath: any[]): void;
}

export interface Visibility {
    visible?: boolean;
    disabled?: boolean;
}

export interface VisibilityChange {
    fullPath: any[];
    visibility: Visibility;
}

export interface FieldVisibility {
    name: string;
    visibility: Visibility;
}

export interface GroupFieldVisibility extends FieldVisibility {
    fields: { [name: string]: FieldVisibility };
}

export interface ArrayFieldVisibility extends FieldVisibility {
    fields: FieldVisibility[];
}

export interface Token {
    type: "operator" | "expression";
}

export interface OperatorToken extends Token {
    type: "operator";
    value: "OR" | "AND";
}

export interface ExpressionToken extends Token {
    type: "expression";
    path: string[];
    operand: "equal" | "not_equal" | "lt" | "lte" | "gt" | "gte";
    value: string;
}

export enum ConditionUpdateType {
    View = "viewState",
    Form = "formState",
    Field = "fieldState",
    Conditionals = "conditionals"
}

export type ConditionalOperator = (
    fieldId: string,
    currentValue: any,
    control: AbstractControl,
    currentState: {
        viewState: FieldViewState;
        formState: FieldFormState;
    }
) => StateChange;

// export type ViewStateOperator = (
//     fieldId: string,
//     value: any,
//     state: FieldViewState
// ) => StateChange;
//
// export type FormStateOperator = (
//     fieldId: string,
//     control: AbstractControl,
//     state: FieldFormState
// ) => StateChange;

export interface ConditionalOperation {
    changeToApply?: ConditionalChange;
    operator?: ConditionalOperator;
    undoOperator?: ConditionalOperator;
}

export interface ChangeOperation extends ConditionalOperation {
    changeToApply: ConditionalChange;
}

// export interface FormOperation extends ConditionalOperation {
//     updateType: ConditionUpdateType.Form;
//     operator: FormStateOperator;
//     undoOperator: FormStateOperator;
// }
//
// export interface ViewStateOperation extends ConditionalOperation {
//     updateType: ConditionUpdateType.View;
//     operator: ViewStateOperator;
//     undoOperator: ViewStateOperator;
// }

export interface ViewStateChange {
    [path: string]: Partial<FieldViewState>;
}

export interface FormStateChange {
    [path: string]: Partial<FieldFormState>;
}

export interface StateChange {
    [ConditionUpdateType.Form]?: FormStateChange;
    [ConditionUpdateType.View]?: ViewStateChange;
    [ConditionUpdateType.Field]?: FieldStateWrapper;
    [ConditionUpdateType.Conditionals]?: Conditionals;
}

export interface NewConditionalRule {
    match: (fieldValue: any, viewState?: Partial<FieldViewState>) => boolean;
    fullPath: string;
    fieldsToApply: string[];
    operation: ConditionalOperation;
    outsideCondition?: Observable<any>;
}

export interface Conditions {
    [ruleIdentifier: string]: NewConditionalRule[];
}

export interface ConditionRule {
    match(value: any, viewState?: Partial<FieldViewState>): boolean;
}
