import { Injectable } from "@angular/core";
import {
    RpcClientService,
    SocketService,
    SubscriptionBaseService
} from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UserorgSubscriptionService extends SubscriptionBaseService {
    constructor(
        protected _socket: SocketService,
        private _rpc: RpcClientService
    ) {
        super(_socket);
        this.product = "userorg";
        this.namespace = "";
    }

    subscribeToCountsChanged(orgID: string): Observable<any> {
        this.namespace = "userorg_counts_changed";
        return this.on("update", orgID);
    }
}
