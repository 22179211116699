import { Component, Input } from "@angular/core";
import { SessionService } from "@sf/common";
import { Router } from "@angular/router";

@Component({
    selector: "sf-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"]
})
export class LogoComponent {
    @Input() linkEnabled = true;

    constructor(
        private sessionService: SessionService,
        private router: Router
    ) {}

    private getHomeUrl(): string {
        let url = this.sessionService.getHomePageUrl();
        let normalizedUrl = url.replace("/sf/ui", "");
        if (!normalizedUrl.startsWith("/")) {
            normalizedUrl = `/${normalizedUrl}`;
        }
        return normalizedUrl;
    }

    clickLogo() {
        let normalizedUrl = this.getHomeUrl();
        let fixed = normalizedUrl.replace("/app/", "/"); // if starts with /app
        this.router.navigate([fixed]);
    }

    auxclickLogo() {
        let normalizedUrl = this.getHomeUrl();
        let fixed = normalizedUrl.replace("/app/", "/"); // if starts with /app
        let url = "/sf/ui" + fixed;
        window.open(url, "_blank");
    }
}
