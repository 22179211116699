import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PersistedMemoryService, RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { NotaryProblemsDialogComponent } from "../dialogs/notary-problems-dialog/notary-problems-dialog.component";
import {
    SIGN_EVENT_STORAGE_KEY,
    SignEventStorage
} from "../interfaces/esign-closing.interface";
import {
    ESignEventNotarizationType,
    UINotarySetup,
    UINotaryTypesSetup
} from "../interfaces/sign-event.interface";

@Injectable({
    providedIn: "root"
})
export class ESignEventStartupService {
    /** Lifecycle Hooks **/
    constructor(
        private _modalService: NgbModal,
        private _persistedMemoryService: PersistedMemoryService,
        private _router: Router,
        private _rpcClient: RpcClientService
    ) {}

    /** Public Methods **/

    checkAndStartEvent(eSignEventID: string): void {
        this.checkNotarySetup(eSignEventID).subscribe(
            (notarySetup: UINotarySetup) => {
                this._startEventAfterCheck(notarySetup, eSignEventID);
            }
        );
    }

    checkAndStartEventFromCAPC(loanID: string, eSignEventID: string): void {
        this.checkNotarySetupCAPC(loanID).subscribe(
            (notarySetup: UINotarySetup) => {
                this._startEventAfterCheck(notarySetup, eSignEventID);
            }
        );
    }

    checkNotarySetup(eSignEventID: string): Observable<UINotarySetup> {
        return this._rpcClient.makeRequest("esign_event", "checkNotarySetup", {
            eSignEventID
        });
    }

    checkNotarySetupCAPC(loanID: string): Observable<UINotarySetup> {
        return this._rpcClient.makeRequest("capc", "checkNotarySetup", {
            loanID
        });
    }

    preCheckNotarySetup(
        eSignEventID: string,
        username: string,
        notarizationType: ESignEventNotarizationType
    ): Observable<UINotarySetup> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "preCheckNotarySetup",
            {
                eSignEventID,
                username,
                notarizationType
            }
        );
    }

    preCheckNotaryTypesSetup(
        eSignEventID: string,
        username: string
    ): Observable<UINotaryTypesSetup> {
        return this._rpcClient.makeRequest(
            "esign_event",
            "preCheckNotaryTypesSetup",
            {
                eSignEventID,
                username
            }
        );
    }

    removeVideoRoomFromStorage() {
        const eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        if (eventStorage?.videoRoom || eventStorage?.participantToken) {
            eventStorage.videoRoom = null;
            eventStorage.participantToken = null;
            this._persistedMemoryService.set(
                SIGN_EVENT_STORAGE_KEY,
                eventStorage
            );
        }
    }

    private _startEventAfterCheck(
        notarySetup: UINotarySetup,
        eSignEventID: string
    ) {
        if (
            notarySetup.setupVerificationPassed &&
            notarySetup.stateVerificationPassed
        ) {
            const url = `/sign-event/event/${eSignEventID}/closing/terms`;
            this.removeVideoRoomFromStorage();
            this._router.navigateByUrl(url);
        } else {
            const notaryProblemsDialog = this._modalService.open(
                NotaryProblemsDialogComponent,
                { backdrop: "static" }
            ) as NgbModalRef;
            let notaryProblemsDialogInstance =
                notaryProblemsDialog.componentInstance;
            notaryProblemsDialogInstance.notaryStatus = notarySetup;
        }
    }
}
