import { SortUtilitiesService } from "@sf/common";
import { Injectable } from "@angular/core";
import { SpinnerService } from "@sf/common";
import { OrganizationService } from "@sf/userorg/common";
import { AdminLoginModalComponent } from "../dialogs/admin-login-modal/admin-login-modal.component";
import { RecentOrganizationsService } from "./recent-organizations.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminUserorgService } from "./admin-userorg.service";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class AdminLoginAsService {
    constructor(
        private organizationService: OrganizationService,
        private adminUserorgService: AdminUserorgService,
        private modalService: NgbModal,
        private _recentOrgsService: RecentOrganizationsService,
        private spinnerService: SpinnerService
    ) {
    }

    loginToOrganization(orgID: string, orgName: string, packageID?: string) {
        this.spinnerService.startSpinner();
        this.organizationService.getAllUsersByOrganization(orgID).subscribe((users: any) => {
            this.spinnerService.stopSpinner();
            users.map((user: any) => {
                user.label = user.name.first + " " + user.name.last;
                if (user.isAdmin) {
                    user.label += " (Administrator)";
                }
            });
            users.sort((a: any, b: any) => {
                if (a.isAdmin && !b.isAdmin) {
                    return -1;
                }
                if (b.isAdmin && !a.isAdmin) {
                    return 1;
                }
                let compareResult = SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                return compareResult;
            });

            const modalRef = this.modalService.open(AdminLoginModalComponent);
            const modalInstance = modalRef.componentInstance;
            modalInstance.orgUsers = users;
            modalInstance.orgID = orgID;
            modalInstance.orgName = orgName;

            modalRef.result.then((username: any) => {
                if (username) {
                    // add to recent organizations
                    if (this._recentOrgsService) {
                        this._recentOrgsService.pushRecentOrganizationID(orgID);
                    }
                    // add to recent user will happen in the function below
                    this.adminUserorgService.loginAs(username, false, !!packageID, packageID);
                }
            }, () => {
                // nothing
            });
        }, () => {
            this.spinnerService.stopSpinner();
        });
    }
}
