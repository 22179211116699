import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SfValidators } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";

@Component({
    selector: "sf-chat-user-prompt-dialog",
    templateUrl: "./chat-user-prompt-dialog.component.html",
    styleUrls: ["./chat-user-prompt-dialog.component.scss"]
})
export class ChatUserPromptDialogComponent implements OnInit {
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() email: string;

    chatForm: FormGroup;
    primary: ModalButton;
    secondary: ModalButton;
    warning: string = null;
    params = {
        firstName: "",
        lastName: "",
        email: ""
    };

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal
    ) {
        this.chatForm = formBuilder.group({
            firstName: ["", [Validators.required]],
            lastName: ["", [Validators.required]],
            email: ["", [Validators.required, SfValidators.emailValidator]]
        });
    }

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.doCancel.bind(this)
        };

        this.chatForm.patchValue({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
        });
    }

    doPrimary() {
        this.warning = this.validate();
        if (!this.warning) {
            this.activeModal.close(this.params);
        }
    }

    validate(): string {
        let message = null;

        this.params.firstName = this.chatForm.controls.firstName.value;
        this.params.lastName = this.chatForm.controls.lastName.value;
        this.params.email = this.chatForm.controls.email.value;

        if (!this.params.email) message = "Please enter your email address";
        if (!this.params.lastName) message = "Please enter your last name";
        if (!this.params.firstName) message = "Please enter your first name";

        if (!message && this.chatForm.controls.email.errors) {
            message = "Enter a valid Email Address";
        }

        if (!message && this.chatForm.invalid) {
            message = "Please correct input errors";
        }

        return message;
    }

    doCancel() {
        this.activeModal.close(false);
    }
}
