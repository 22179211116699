import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import {
    CreateTestPackagesParams,
    CreateTestPackagesResponse
} from "../interfaces/create-test-packages.interface";
import { CreateTestPackagesWithTransactionsParams } from "../interfaces/create-test-packages-with-transactions-params.interface";
import { SampleDocStatus } from "../interfaces/sample-doc-status.interface";
import { County } from "@sf/submitter/common";

@Injectable({
    providedIn: "root"
})
export class AdminTestPackagesService {
    constructor(private _rpcClient: RpcClientService) {}

    getSampleDocumentsStatus(): Observable<SampleDocStatus> {
        return this._rpcClient.makeRequest("admin", "getSampleDocumentsStatus");
    }

    createTestPackages(
        createTestPackageModel: CreateTestPackagesParams
    ): Observable<CreateTestPackagesResponse> {
        return this._rpcClient.makeRequest("admin", "createTestPackages", {
            createTestPackageModel
        });
    }

    createTestPackagesWithTransactions(
        createTestPackagesWithTransactionsModel: CreateTestPackagesWithTransactionsParams
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "createTestPackagesWithTransactions",
            { createTestPackagesWithTransactionsModel }
        );
    }

    getSubmitterRecipients(
        submitterID: string,
        state: string,
        bEnabledOnly: boolean
    ): Observable<County[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getSubmitterRecipients",
            {
                submitterID,
                state,
                bEnabledOnly
            }
        );
    }
}
