<ngb-progressbar
    type="success"
    [class.no-animations]="!(isLoading$ | async) && ((loader$ | async) === 0 || (loader$ | async) === 100 )"
    [class.should-hide]="!(isLoading$ | async)"
    [value]="(isLoading$ | async) ? (loader$ | async) : 0"
    height="4px"
></ngb-progressbar>
<p class="sr-only" role="status" aria-live="polite" aria-atomic="true">
    {{ loadingMessage$ | async }}
</p>
