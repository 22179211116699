import { Pipe, PipeTransform } from "@angular/core";
import { Name } from "../interfaces/name.interface";

@Pipe({
    name: "eeName"
})
export class ESignEventNamePipe implements PipeTransform {
    transform(name: Name, format: "full" | "firstLast" = "full"): string {
        if (!name) {
            return "";
        }
        let nameParts: string[] = [];
        if (name.unparsed) {
            nameParts = [name.unparsed];
        } else if (format === "full") {
            nameParts = [
                name.prefix,
                name.first,
                name.middle,
                name.last,
                name.suffix
            ];
        } else if (format === "firstLast") {
            nameParts = [name.first, name.last];
        }
        return nameParts.filter((part) => !!part).join(" ");
    }
}
