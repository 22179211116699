import { Injectable } from "@angular/core";
import { FileUploadService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PdfTextExtractionService {
    constructor(private _fileUploadService: FileUploadService) {}

    extractTextFromFile(file: File): Observable<any> {
        return this._fileUploadService.uploadWithForm(
            file,
            {},
            "/sf/esign_event/extractTextFromPdf"
        );
    }
}
