import { ApplicationRef, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import {
    LiveConfigService,
    NavigationModule,
    PageNotFoundModule
} from "@sf/common";
import { DefaultRouteGuard } from "./default-route.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SfCommonModule } from "@sf/common";

import { AppRoutingModule } from "./app-routing.module";
import { UserorgModule } from "@sf/userorg/main";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { httpInterceptorProviders } from "./http-interceptors";
import { AppBootstrapService } from "./bootstrap/app-bootstrap.service";
import { Auth } from "@sf/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BOOTSTRAP_SERVICE, ENVIRONMENT } from "@sf/common";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { CustomBannerNotificationComponent } from "./test/common/custom-banner-notification/custom-banner-notification.component";
import { Router, UrlSerializer } from "@angular/router";
import { Location } from "@angular/common";
import { GlobalErrorHandler } from "./global-error-handler.service";
import { CustomUrlSerializer } from "./custom-url-serializer.service";
import { SupportBannerModule } from "@sf/admin/common";
import { RightContentLeftNavComponent } from "./layout/right-content-left-nav/right-content-left-nav.component";
import { HeadersModule } from "@sf/headers";
import { LayoutModule } from "@sf/layout";
import { LeftNavComponent } from "./layout/left-nav/left-nav.component";
import { ElliemaeResourceCenterNavComponent } from "./layout/left-nav/elliemae-resource-center-nav/elliemae-resource-center-nav.component";
import { CapcFeedbackComponent } from "./layout/left-nav/capc-feedback/capc-feedback.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { switchMap } from "rxjs/operators";
import { ChatSalesforceNavComponent } from "./layout/left-nav/chat-salesforce-nav/chat-salesforce-nav.component";
import { ChatModule } from "@sf/chat";
import { OverlayModule } from "@angular/cdk/overlay";
import { A11yModule } from "@angular/cdk/a11y";

@NgModule({
    declarations: [
        AppComponent,
        CustomBannerNotificationComponent,
        CustomBannerNotificationComponent,
        RightContentLeftNavComponent,
        LeftNavComponent,
        ElliemaeResourceCenterNavComponent,
        CapcFeedbackComponent,
        ChatSalesforceNavComponent
    ],
    imports: [
        BrowserModule,
        SfCommonModule,
        FormsModule,
        AppRoutingModule,
        NgbModule,
        ChatModule,
        UserorgModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: false
        }),
        PageNotFoundModule,
        SupportBannerModule,
        HeadersModule,
        LayoutModule,
        NavigationModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        OverlayModule,
        A11yModule
    ],
    exports: [RightContentLeftNavComponent],
    providers: [
        DefaultRouteGuard,
        httpInterceptorProviders,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        },
        {
            provide: ENVIRONMENT,
            useValue: environment
        },
        {
            provide: APP_BOOTSTRAP_SERVICE,
            useClass: AppBootstrapService
        }
    ]
})
export class AppModule {
    constructor(private _appBootstrap: AppBootstrapService) {}

    ngDoBootstrap(app: ApplicationRef) {
        this._appBootstrap
            .authenticate()
            .pipe(switchMap((auth: Auth) => this._appBootstrap.bootstrap(auth)))
            .subscribe(() => app.bootstrap(AppComponent));
    }
}
