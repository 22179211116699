import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NewOrganizationComponent } from "./pages/new-organization/new-organization.component";
import { SelfSignupComponent } from "./pages/self-signup/self-signup.component";
import { NewUserComponent } from "./pages/new-user/new-user.component";
import { NewServiceComponent } from "./pages/new-service/new-service.component";
import { AcceptLicenseComponent } from "./pages/accept-license/accept-license.component";
import { DeferAcceptComponent } from "./pages/defer-accept/defer-accept.component";
import { ApiTokenAcceptComponent } from "./pages/api-token-accept/api-token-accept.component";
import { ServiceNonuserComponent } from "./pages/service-nonuser/service-nonuser.component";
import { BlankLayoutComponent } from "@sf/layout";
import { CancelInvitationComponent } from "./pages/cancel-invitation/cancel-invitation.component";
import { AcceptInviteComponent } from "./pages/accept-invite/accept-invite.component";

const routes: Routes = [
    {
        path: "new-organization",
        data: {
            layout: false
        },
        children: [
            {
                path: ":tokenID",
                component: NewOrganizationComponent
            }
        ]
    },
    {
        path: "new-service",
        data: {
            layout: false
        },
        children: [
            {
                path: ":tokenID",
                component: NewServiceComponent
            }
        ]
    },
    {
        path: "service-nonuser",
        data: {
            layout: false
        },
        children: [
            {
                path: ":tokenID",
                component: ServiceNonuserComponent
            }
        ]
    },
    {
        path: "new-user",
        data: {
            layout: false
        },
        children: [
            {
                path: ":tokenID",
                component: NewUserComponent
            }
        ]
    },
    {
        path: "defer-accept",
        data: {
            layout: false
        },
        children: [
            {
                path: ":tokenID",
                component: DeferAcceptComponent
            }
        ]
    },
    {
        path: "accept-license",
        data: {
            layout: false
        },
        children: [
            {
                path: ":contractID",
                component: AcceptLicenseComponent
            }
        ]
    },
    {
        path: "self-signup",
        data: {
            layout: false
        },
        component: SelfSignupComponent
    },
    {
        path: "api-token-accept",
        data: {
            layout: BlankLayoutComponent
        },
        children: [
            {
                path: ":tokenID",
                component: ApiTokenAcceptComponent
            }
        ]
    },
    {
        path: "cancel-invitation",
        data: {
            layout: BlankLayoutComponent
        },
        children: [
            {
                path: ":tokenID",
                component: CancelInvitationComponent
            }
        ]
    },
    {
        path: "accept-invite",
        data: {
            layout: BlankLayoutComponent
        },
        children: [
            {
                path: ":tokenID",
                component: AcceptInviteComponent
            }
        ]
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvitationsRoutingModule {}
