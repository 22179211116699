<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        <fa-icon *ngIf="icons.titleIcon" [icon]="icons.titleIcon" class="mr-2"></fa-icon>{{ title }}
        <button *ngIf="helpLink"
                class="btn help-btn"
                (click)="showHelp()"
                aria-label="Help"
                ngbTooltip="{{helpLink.tooltip}}"
                placement="right"
                >
            <fa-icon
                class="help-icon"
                [icon]="['far', 'question-circle']"
                [fixedWidth]="true"
                [size]="'xs'"
                >
            </fa-icon>
        </button>
    </h4>
    <button
        *ngIf="showDismiss && !modal.working"
        class="btn"
        aria-label="Close"
        (click)="modal.dismiss(dismissValue ? dismissValue : 'exit')"
    >
        <fa-icon
            [icon]="['far', 'times']"
            class="close"
            [size]="'xs'"
            [fixedWidth]="true"
        ></fa-icon>
    </button>
    <fa-icon
            *ngIf="modal.working"
            [icon]="['fad', 'spinner']"
            class="close spinner"
            [size]="'xs'"
            [spin]="true"
            [fixedWidth]="true"
    ></fa-icon>
</div>
