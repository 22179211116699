<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="like-table row">
    <!-- LEFT SIDE -->
    <div class="left-side">
        <div class="head-text">
            {{wizardSteps[currentStep].title}}
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <div>Please correct the following errors:</div>
            <ul>
                <li *ngFor="let message of formErrorMessages">
                    <span [innerHTML]="message"></span>
                </li>
            </ul>
        </div>
        <!-- STEP 0 - organization information -->
        <form [formGroup]="companyForm" *ngIf="currentStep==0">
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="companyLegalName">Legal Name of Entity/Organization</label>
                    <input type="text" id="companyLegalName" name="companyLegalName" placeholder="Required"
                        class="form-control" (change)="updatePreferredName()" (keyup)="updatePreferredName()"
                        (paste)="pasteLegalName($event)"
                        formControlName="companyLegalName" [required]="true"/>
                </div>
                <div class="form-group col-md-6">
                    <label for="companyPreferredName">Preferred Name of Entity/Organization</label>
                    <input type="text" id="companyPreferredName" name="companyPreferredName"
                        (paste)="pastePreferredName($event)"
                        class="form-control" formControlName="companyPreferredName"/>
                </div>
            </div>
            <!--
            <div *ngIf="companyForm.controls.businessType && companyForm.controls.businessType.value" class="form-group col-md-6">
                <label for="businessType">Business Type</label>
                <sf-select
                    id="businessType"
                    formControlName="businessType"
                    placeholder="Select business type..."
                    [options]="businessTypes"
                    trackBy="id"
                    labelBy="label"
                    [hasClearButton]="true"
                    [hasFilter]="true"
                    [isSingleSelect]="true"
                    [required]="true"
                    >
                </sf-select>
            </div>
            -->
            <div class="row">
                <div class="form-group col-md-3">
                    <label for="phone">Organization Phone</label>
                    <input type="tel" id="phone" name="phone" placeholder="Required"
                        class="form-control" sfPhoneInput
                        formControlName="phone" [required]="true"/>
                </div>
                <div class="form-group col-md-3">
                    <label for="extension">Phone Extension</label>
                    <input type="text" id="extension" name="extension" placeholder=""
                        class="form-control"
                        formControlName="extension"/>
                </div>
                <div class="form-group col-md-6">
                    <label for="website">Organization Website</label>
                    <input type="text" id="website" name="website" placeholder=""
                        class="form-control"
                        formControlName="website"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="street">Street Address</label>
                    <input type="text" id="street" name="street" placeholder="Required"
                        class="form-control"
                        (paste)="pasteStreet1($event)"
                        formControlName="street" [required]="true"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="street2">Street Address 2</label>
                    <input type="text" id="street2" name="street2" placeholder=""
                        class="form-control"
                        (paste)="pasteStreet2($event)"
                        formControlName="street2"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="city">City</label>
                    <input type="text" id="city" name="city" placeholder="Required"
                        class="form-control"
                        (paste)="pasteCity($event)"
                        formControlName="city" [required]="true"/>
                </div>
                <div class="form-group col-md-3">
                    <label for="state">State</label>
                    <!-- There is a hidden input element at the end of this form used to populate the sf-select with the browser's autofill address. -->
                    <div class="select-container">
                        <sf-state-selector id="stateSelect" name="stateSelect" placeholder="Required"
                            [selectedStateID]="companyForm.controls.state.value"
                            [canClearSelectedState]="true"
                            (select)="stateChange($event)"
                            [required]="true"></sf-state-selector>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label for="zipCode">ZIP Code</label>
                    <input type="text" id="zipCode" name="zipCode" placeholder="Required"
                        class="form-control" maxlength="12"
                        formControlName="zipCode" [required]="true"/>
                </div>
            </div>
            <div *ngIf="!!invalidAddressMessage" class="alert alert-danger invalid-address">
                <div [innerHTML]="invalidAddressMessage" class="invalid-address-message"></div>
                <div *ngIf="!verifiedAddress && showAddressCheckbox" class="">
                    <label>
                        <input type="checkbox" formControlName="checkAddress"/>
                        The address I entered is correct
                    </label>
                </div>
                <div *ngIf="verifiedAddress">
                    <label>
                        <input type="radio" name="whichAddress" formControlName="whichAddress" value="recommended"/>
                        Use the recommended address
                    </label>
                </div>
                <div *ngIf="verifiedAddress">
                    <label>
                        <input type="radio" name="whichAddress" formControlName="whichAddress" value="entered"/>
                        Use the address I entered
                    </label>
                </div>
            </div>
            <div class="button-row">
                <button class="btn btn-primary" (click)="submitCurrentForm()">Continue</button>
                <button class="btn btn-link" (click)="doCancel()">Cancel Invitation</button>
            </div>
        </form>
        <!-- STEP 1 - user information -->
        <form [formGroup]="personalForm" *ngIf="currentStep==1">
            <div class="row" *ngIf="emailMatchesUser">
                <div class="form-group col-md-12">
                    <span>Already a Simplifile user? </span>
                    <a [href]="buildLoginUrl()">Click here</a>
                    <span> to log in.</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" name="firstName" placeholder="Required"
                        class="form-control" maxlength="255"
                        formControlName="firstName" [required]="true"/>
                </div>
                <div class="form-group col-md-4">
                    <label for="middleName">Middle Name</label>
                    <input type="text" id="middleName" name="middleName" placeholder=""
                        class="form-control" maxlength="255"
                        formControlName="middleName"/>
                </div>
                <div class="form-group col-md-4">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Required"
                        class="form-control" maxlength="255"
                        formControlName="lastName" [required]="true"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="title">Job Title</label>
                    <input type="text" id="title" name="title" placeholder="Required"
                        class="form-control"
                        formControlName="title" [required]="true"/>
                </div>
                <div class="form-group col-md-6">
                    <label for="email">Email Address</label>
                    <input type="text" id="email" name="email" placeholder="Required"
                        class="form-control"
                        formControlName="email" [required]="true"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="userID">Choose your Username</label>
                    <input type="text" id="userID" name="userID" placeholder="Required"
                        class="form-control" (focus)="focusUsername()" (blur)="blurUsername()" maxlength="55"
                        formControlName="userID" [required]="true"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="password">Create a Password</label>
                    <input type="password" id="password" name="password" placeholder="Required"
                        class="form-control" (focus)="focusPassword()" (blur)="blurPassword()"
                        formControlName="password" [required]="true"/>
                </div>
                <div class="form-group col-md-6">
                    <label for="confirm">Confirm your Password</label>
                    <input type="password" id="confirm" name="confirm" placeholder="Required"
                        class="form-control" (focus)="focusConfirm()" (blur)="blurConfirm()"
                        formControlName="confirm" [required]="true"/>
                </div>
            </div>
            <div class="button-row">
                <button *ngIf="!mustSignContract" class="btn btn-primary" (click)="submitCurrentForm()">Finish</button>
                <button *ngIf="mustSignContract" class="btn btn-primary" (click)="submitCurrentForm()">Continue</button>
                <button class="btn btn-secondary" (click)="goToStep(0)">Back</button>
            </div>
        </form>
        <!-- STEP 2 - sign license -->
        <div *ngIf="currentStep==2">
            <div class="sub-head-text">
                <span>{{organizationName}}</span>
            </div>
            <sf-sign-license [contractId]="contractID" (actionTaken)="licenseAction($event)"></sf-sign-license>
        </div>
        <!-- STEP 3 - make payment -->
        <sf-pay-license-fee *ngIf="currentStep==3"
            [contractId]="contractID"
            [tokenID]="tokenID"
            [organizationID]="organizationID"
            [serviceName]="serviceName"
            [licenseFeeAmount]="licenseFeeAmount"
            (actionTaken)="paymentAction($event)">
        </sf-pay-license-fee>
        <!-- STEP 4 - done -->
        <div *ngIf="currentStep==4">
            <div class="button-row">
                <button class="btn btn-primary" (click)="goToLogin()">Log In</button>
            </div>
        </div>
    </div>
    <!-- RIGHT SIDE -->
    <div class="right-side">
        <div *ngIf="!isInUsernameField && !isInPasswordField && !isInConfirmField">
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==0), 'done-step':(currentStep>0)}">
                <span (click)="stepClick(0)">
                    <span class="badge">1</span>
                    <span>Your Organization</span>
                </span>
            </div>
            <div class="step-connector"></div>
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==1), 'done-step':(currentStep>1)}">
                <span (click)="stepClick(1)">
                    <span class="badge">2</span>
                    <span>Personal Account</span>
                </span>
            </div>
            <div *ngIf="mustSignContract">
                <div class="step-connector"></div>
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==2), 'done-step':(currentStep>2)}">
                    <span (click)="stepClick(2)">
                        <span class="badge">3</span>
                        <span>Accept Agreement</span>
                    </span>
                </div>
            </div>
            <div *ngIf="mustSignContract && licenseFeeAmount">
                <div class="step-connector"></div>
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==3), 'done-step':(currentStep>3)}">
                    <span (click)="stepClick(3)">
                        <span class="badge">4</span>
                        <span>License Fee</span>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="isInUsernameField && availableUsernames.length" class="preload-holder">
            <div class="preload-header">Here are some usernames that are available:</div>
            <div class="preload-picker">
                <div *ngFor="let option of availableUsernames">
                    <button class="btn btn-link sf-btn-link"
                        (mousedown)="clickUsernameOption(option)">{{option}}</button>
                    <!-- must use (mousedown) here -->
                </div>
            </div>
        </div>
        <div *ngIf="isInPasswordField || isInConfirmField" class="preload-holder">
            <div class="preload-header">Password requirements</div>
            <div class="preload-picker pwd-requirements">
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordHas9Characters" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordHas9Characters" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Must have 9 or more characters</span>
                </div>
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordHas3of4" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordHas3of4" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Must have at least 3 of the following:</span>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasNumber" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasNumber" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A number</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasLowerCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasLowerCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A lower-case character</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasUpperCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasUpperCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> An upper-case character</span>
                    </div>
                </div>
                <div class="pwd-requirement pwd-requirement-indented">
                    <div class="password-indent"> </div>
                    <div class="password-indented">
                        <fa-icon *ngIf="!passwordHasSpecialChar" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                            [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                        <fa-icon *ngIf="passwordHasSpecialChar" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                        <span> A special character (!@#$?+...)</span>
                    </div>
                </div>
                <div class="pwd-requirement">
                    <fa-icon *ngIf="!passwordsMatch" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                    <fa-icon *ngIf="passwordsMatch" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                    <span> Passwords must match</span>
                </div>
            </div>
        </div>
        <div class="chat">
            <div class="titler">Questions?</div>
            <div>Call us at <a href="tel:800.460.5657">800.460.5657</a> and press option 2.</div>
            <div>We are available from 6 a.m. to 6 p.m. MT.</div>
        </div>
    </div>
</div>
