import {
    Component,
    ComponentRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Type
} from "@angular/core";
import { ComponentPortal } from "@angular/cdk/portal";
import { InputComponent } from "../../components";
import { CdkPortalOutletAttachedRef } from "@angular/cdk/portal/portal-directives";
import { FieldState } from "../../interfaces/dynamic-form-state";
import { DynamicFormStore } from "../../services/dynamic-form-store";
import { COMPONENT_FROM_FIELD } from "../../helpers/fields.helpers";
import { first } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
    selector: "sf-array-field-item-wrapper",
    templateUrl: "./array-field-item-wrapper.component.html",
    styleUrls: ["./array-field-item-wrapper.component.scss"]
})
export class ArrayFieldItemWrapperComponent implements OnInit {
    @Input() field: FieldState;
    @Input() controlPath: string;
    @Input() disabled$: Observable<boolean>;
    @Input() useColumnLayout: boolean;

    componentPortal: ComponentPortal<InputComponent>;

    constructor(
        private _store: DynamicFormStore,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>
    ) {}

    ngOnInit() {
        this.componentPortal = new ComponentPortal(
            this._getComponentFromField(this.field)
        );
    }

    attached(componentRef: CdkPortalOutletAttachedRef) {
        const component = componentRef as ComponentRef<InputComponent>;
        const instance = component.instance;
        instance.useColumnLayout = this.useColumnLayout;
        instance.field = this.field;
        instance.controlPath = this.controlPath;
    }

    remove() {
        this._store.removeFieldFromArray(this.field.fullPath, this.controlPath);
    }
}
