import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { RoleService, UserOrgService } from "@sf/userorg/common";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { SortUtilitiesService } from "@sf/common";
import { SelectablePermission, UserPermission } from "@sf/userorg/common";
import { RecentPermissionsService } from "../../services/recent-permissions.service";

// prettier-ignore
@Component({
    selector: "sf-permission-search-selector",
    templateUrl: "./permission-search-selector.component.html",
    styleUrls: ["./permission-search-selector.component.scss"]
})
export class PermissionSearchSelectorComponent implements OnInit, OnChanges, OnDestroy {
    /* Inputs */
    @Input() selectedPermissionID: string; // (optional) (single select) permissionID initially selected
    @Input() autoInitialSelect: boolean; // initially select an item - determined by selectedPermissionID, else first item in list
    @Input() searchLimit: number; // (optional - default is 25) max number of permissions to show in list
    @Input() canClearSelectedPermission: boolean; // whether to show an 'X' to clear selection
    @Input() hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input() required: boolean = null; // if required in form
    @Input() placeholder: string; // (optional) placeholder - will be defaulted
    @Input() windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'

    /* Outputs */
    @Output() select: EventEmitter<any> = new EventEmitter<any>(); // passes a Permission

    /** PRIVATE **/
    private _onDestroy: Subject<void> = new Subject<void>();

    /** PUBLIC **/
    permissions: UserPermission[] = [];
    selectedPermission: UserPermission = null;
    selectableItems: SelectablePermission[] = [];

    constructor(
            private _userOrgService: UserOrgService,
            private roleService: RoleService,
            private recentPermissionsService: RecentPermissionsService,
            private _router: Router,
            private _activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = "Select Permission...";
        }
        if (!this.windowClass) {
            this.windowClass = "not-full-width";
        }
        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        this.populatePermissions({}).subscribe(() => {
            this.selectSelectedItem();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedPermissionID && changes.selectedPermissionID.currentValue
                && changes.selectedPermissionID.currentValue !== changes.selectedPermissionID.previousValue) {
            //do nothing
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getPermissions(event?: any): Subscription {
        return this.populatePermissions(event).subscribe(() => {
            // nothing
        });
    }

    selectSelectedItem() {
        // figure out which permission should be initially selected
        let currentItem: SelectablePermission = null;
        if (this.selectedPermissionID) {
            currentItem = this.selectableItems.find((permission: SelectablePermission) => {
                return permission.id == this.selectedPermissionID;
            });
        }

        if (!currentItem && this.selectedPermissionID) {
            // we get here if the permission with the selected ID is not in the list of selectable permissions,
            // so get the permission and add it to the list
            this.roleService.getPermission(this.selectedPermissionID)
                .subscribe((results: UserPermission) => {
                    if (!this.permissions) {
                        this.permissions = [results];
                    } else {
                        this.permissions.splice(0, 0, results);
                    }
                    // need new array here
                    let selectablePermission = this._makeSelectablePermission(results);
                    let newSelectionList = [selectablePermission]; // need to create new array for watchers
                    this.selectableItems = newSelectionList.concat(this.selectableItems);
                    this.handlePermissionSelection({
                        $selection: selectablePermission
                    });
                });
        } else if (this.autoInitialSelect && this.permissions.length > 0) {
            if (this.selectedPermissionID) {
                // select the permission with the selected ID
                this.handlePermissionSelection({
                    $selection: {
                        id: this.selectedPermissionID,
                        label: null
                    }
                });
            } else {
                // select the first permission in the list
                this.handlePermissionSelection({
                    $selection: {
                        id: this.selectableItems[0].id,
                        label: this.selectableItems[0].label
                    }
                });
            }
        } else if (this.selectedPermissionID) {
            // normal case - if we have a selected ID, select that one
            this.handlePermissionSelection({
                $selection: {
                    id: this.selectedPermissionID,
                    label: null
                }
            });
        } else {
            // this.selectedPermissionID is not set, but never happens
        }
    }

    populatePermissions(event?: any): Observable<UserPermission[]> {
        let searchValue = event.$searchValue;

        // if permission entered search criteria
        if (searchValue !== null && searchValue !== "" && searchValue !== undefined) {
            // do a search
            return this.roleService.searchPermissions(searchValue, this.searchLimit)
                       .pipe(tap((results: UserPermission[]) => {
                           //this.permissions = this._sortPermissions(results);
                           this.permissions = results;
                           this.selectableItems = this.permissions.map(this._makeSelectablePermission, this);

                           if (this.selectedPermission) {
                               let foundSelection = this.selectableItems.find((permission) => {
                                   return permission.id === this.selectedPermission.permissionID;
                               });
                               if (!foundSelection) {
                                   this.selectableItems.push(this._makeSelectablePermission(this.selectedPermission));
                               }
                           }
                       }));
        }

        //use most recently accessed permissions
        return this.recentPermissionsService.getPastRecentPermissions().pipe(tap((results: UserPermission[]) => {
            //this.permissions = this._sortPermissions(results);
            this.permissions = results;
            this.selectableItems = this.permissions.map(this._makeSelectablePermission, this);
        }));
    }

    private _sortPermissions(unsorted: UserPermission[]): UserPermission[] {
        let sorted: UserPermission[] = unsorted.sort((permission1, permission2) => {
            return SortUtilitiesService.stringSortCompareInsensitive(permission1.label, permission2.label);
        });
        return sorted;
    }

    handlePermissionSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            let selection: SelectablePermission = selectionObject;
            let permission: UserPermission = null;
            if (selection && selection.id) {
                permission = this.findPermissionInList(selection.id);
            }
            this.selectedPermission = permission; // may be null, which is fine
            if (this.select) {
                this.select.emit(permission);
            }
        }
    }

    private findPermissionInList(permissionID: string): UserPermission {
        let permission: UserPermission = this.permissions.find((localPermission) => {
            return localPermission.permissionID === permissionID;
        });
        return permission;
    }

    private _makeSelectablePermission(u: UserPermission): SelectablePermission {
        return {
            id: u.permissionID,
            label: u.permissionID,
            selected: false
        };
    }
}
