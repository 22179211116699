import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    Observable,
    of,
    ReplaySubject,
    Subscription
} from "rxjs";
import { UserOrgService, UserorgSubscriptionService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { PaymentAccountsService } from "@sf/userorg/common";
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap
} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class PaymentNavTickersService {
    private readonly _missingCount$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    private readonly _pendingCount$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    private readonly _expiringCardCount$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    private readonly _expiredCardCount$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    private readonly _permissableOrgs: string[] = [];
    private readonly _accountingOrgs: string[] = [];

    missingPaymentCount$: Observable<number | null> = null;
    expiringCardCount$: Observable<number | null> = null;
    expiredCardCount$: Observable<number | null> = null;
    pendingPaymentCount$: Observable<number | null> = null;
    isDelinquent$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private _userOrgService: UserOrgService,
        private _sessionService: SessionService,
        private _paymentAccountsService: PaymentAccountsService,
        private _userorgSubscriptionService: UserorgSubscriptionService
    ) {
        //initialize subject
        this.isDelinquent$.next(false);

        this._sessionService.sessionResetEvent.subscribe(() => {
            this.refreshMissingPaymentCount();
        });

        this._permissableOrgs =
            this._sessionService.getOrganizationIDsWithPermission(
                "organization_payment_approval"
            );
        this._accountingOrgs =
            this._sessionService.getOrganizationIDsWithPermission(
                "organization_accounting"
            );

        this.missingPaymentCount$ = this._missingCount$.pipe(
            switchMap(() => this._userOrgService.getOrganizationActionCount()),
            map((results: any) => {
                return results.paymentAccount > 0
                    ? results.paymentAccount
                    : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.pendingPaymentCount$ = this._pendingCount$.pipe(
            switchMap((clearCache: boolean) =>
                this._paymentAccountsService.getPendingPaymentCounts(
                    this._permissableOrgs,
                    clearCache
                )
            ),
            map((countInfo: any) => {
                this.isDelinquent$.next(countInfo.isDelinquent);
                return countInfo &&
                    countInfo.hasOwnProperty("pendingCount") &&
                    countInfo.pendingCount > 0
                    ? countInfo.pendingCount
                    : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.expiringCardCount$ = this._expiringCardCount$.pipe(
            switchMap(() => {
                if (
                    this._sessionService.hasPermissionInAnyOrg(
                        "organization_accounting"
                    )
                )
                    return this._paymentAccountsService.getExpiringCreditCards(
                        this._accountingOrgs
                    );
                else return of(null);
            }),
            map((expCards: any[]) => {
                return expCards && expCards.length ? expCards.length : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.expiredCardCount$ = this._expiredCardCount$.pipe(
            switchMap(() => {
                if (
                    this._sessionService.hasPermissionInAnyOrg(
                        "organization_accounting"
                    )
                )
                    return this._paymentAccountsService.getExpiredCreditCards(
                        this._accountingOrgs
                    );
                else return of(null);
            }),
            map((expCards: any[]) => {
                return expCards && expCards.length ? expCards.length : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        if (this._permissableOrgs) {
            this._permissableOrgs.forEach((orgID) => {
                this._userorgSubscriptionService
                    .subscribeToCountsChanged(orgID)
                    .subscribe((msg: any) => {
                        let data: any = JSON.parse(msg.data);
                        switch (data.type) {
                            case "paymentAccountAdded":
                                this.refreshMissingPaymentCount();
                                break;
                            case "pendingPaymentsUpdated":
                                this.refreshPendingPaymentCount();
                                break;
                        }
                    });
            });
        }
    }

    refreshMissingPaymentCount() {
        this._missingCount$.next(true);
    }

    refreshPendingPaymentCount() {
        this._pendingCount$.next(true); //this is used to determine if cache should be cleared
    }

    refreshCardCounts() {
        this._expiringCardCount$.next(false);
        this._expiredCardCount$.next(false);
    }
}
