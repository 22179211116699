<div
        *ngIf="status"
        [ngClass]="['status', hasMoreInfo ? 'cursor-pointer' : '', 'd-inline-flex']"
        (click)="onStatusClick()"
        ngbTooltip="{{statusIcon.tooltip}}"
        [placement]="tooltipPlacement"
        container="body">
    <div [ngClass]="['icon-wrapper', statusIcon.iconStyling]">
        <fa-icon [icon]="statusIcon.icon"></fa-icon>
    </div>
    <div class="content"> <ng-content></ng-content> </div>
    <div [ngClass]="['status-text', hasMoreInfo ? 'link' : '']">{{status}}</div>
</div>
