import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { BehaviorSubject, Observable } from "rxjs";
import { FeeChangeData } from "../interfaces/fee-change-data.interface";

export interface PriceIncreaseDefinition {
    definitionID: string;
    name: string;
    startDate: Date;
    endDate: Date;
    contractType: string; // ContractType
    contractFeeType: string; // ContractFeeType
    enabled: boolean;
    createdDate: Date;
}

export interface PriceIncrease {
    organizationID: string;
    priceIncreaseDefinitionID: string;
    oldContractID: string;
    newContractID: string;
    oldFeeAmount: number;
    newFeeAmount: number;
    notifiedDate: Date;
    acceptedDate: Date;
    errorMessage: string;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class PriceIncreaseService {

    constructor(
            private _rpcClient: RpcClientService
    ) {
    }

    getAllDefinitions(): Observable<PriceIncreaseDefinition[]> {
        return this._rpcClient.makeRequest("userorg", "getAllDefinitions");
    }

    saveDefinition(definition: PriceIncreaseDefinition): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "saveDefinition", {
            definition: definition
        });
    }

    setDefinitionStatus(definitionID: string, enabled: boolean): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "setDefinitionStatus", {
            definitionID: definitionID,
            enabled: enabled
        });
    }

    deleteDefinition(definitionID: string): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "deleteDefinition", {
            definitionID: definitionID
        });
    }

    getDefinitionWithStats(definitionID: string): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "getDefinitionWithStats", {
            definitionID: definitionID
        });
    }

    getAllDefinitionsWithStats(): Observable<PriceIncreaseDefinition[]> {
        return this._rpcClient.makeRequest("userorg", "getAllDefinitionsWithStats");
    }

    getPriceIncreaseByNewContractID(contractID: string): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "getPriceIncreaseByNewContractID", {
            contractID: contractID
        });
    }

    addOrganizationToDefinition(definitionID: string, organizationID: string): Observable<PriceIncreaseDefinition> {
        return this._rpcClient.makeRequest("userorg", "addOrgToPriceIncrease", {
            definitionID: definitionID,
            organizationID: organizationID
        });
    }

    addOrganizationsToDefinition(definitionID: string, organizationIDs: string[]): Observable<number> {
        return this._rpcClient.makeRequest("userorg", "addOrgsToPriceIncrease", {
            definitionID: definitionID,
            organizationIDs: organizationIDs
        });
    }

    getUnacceptedPriceIncreasesForDefinition(definitionID: string): Observable<PriceIncrease[]> {
        return this._rpcClient.makeRequest("userorg", "getUnacceptedPriceIncreasesForDefinition", {
            definitionID: definitionID
        });
    }

    removeOrgsFromPriceIncrease(definitionID: string, orgIDs: string[]): Observable<number> {
        return this._rpcClient.makeRequest("userorg", "removeOrgsFromPriceIncrease", {
            definitionID: definitionID,
            organizationIDs: orgIDs
        });
    }

}
