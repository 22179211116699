import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { RpcClientService } from "@sf/common";
import { MarginSetting } from "../interfaces/margin-setting";
import { RecipientImportData } from "../interfaces/recipient-import-data";
import { Organization } from "@sf/userorg/common";
import { CurrentOrganizationService } from "@sf/userorg/common";
import { OrganizationService } from "@sf/userorg/common";
import { filter } from "rxjs/operators";
import { CustomFieldValue, DataField } from "@sf/custom-form";

@Injectable({
    providedIn: "root"
})
export class RecipientConfigService {
    /** Public Variables **/
    currentRecipientOrg$: Observable<Organization>;

    private _currentRecipient: any;
    private readonly _currentRecipientSubject: BehaviorSubject<any>;
    private _saveChanges$: Subject<void>;
    private _xmlDiffChange$: Subject<any>;

    constructor(
        private _currentOrgService: CurrentOrganizationService,
        private _organizationService: OrganizationService,
        private _rpcClient: RpcClientService
    ) {
        this._currentRecipient = {};
        this._currentRecipientSubject = new BehaviorSubject<any>(
            this._currentRecipient
        );
        this._saveChanges$ = new Subject<void>();
        this._xmlDiffChange$ = new Subject<any>();
        this.currentRecipientOrg$ =
            _currentOrgService.currentOrganization$.pipe(
                filter(
                    (org: Organization) =>
                        org &&
                        _organizationService.orgHasService(org, "recipient")
                )
            );
    }

    getRecipients(searchString: string) {
        return this._rpcClient.makeRequest("admin", "getRecipients", {
            searchString
        });
    }

    getCurrentRecipient$(): BehaviorSubject<any> {
        return this._currentRecipientSubject;
    }

    getCustomFormData(recipientID: string, searchTerm: string) {
        return this._rpcClient.makeRequest("erecord", "getCustomFormData", {
            recipientID: recipientID,
            searchTerm: searchTerm ? searchTerm : "",
            startIndex: 0,
            range: 100
        });
    }

    getCustomForms(
        recipientID: string,
        searchTerm: string,
        startIndex?: number,
        searchBy?: string,
        sortOrder?: string
    ) {
        if (!startIndex) startIndex = 0;
        return this._rpcClient.makeRequest("erecord", "getCustomForms", {
            recipientID: recipientID,
            searchTerm: searchTerm ? searchTerm : "",
            startIndex: startIndex,
            range: 100,
            searchBy: searchBy ? searchBy : null,
            sortOrder: sortOrder
        });
    }

    getSingleCustomForm(customFormID: string) {
        return this._rpcClient.makeRequest("erecord", "getCustomForm", {
            customFormID: customFormID
        });
    }

    getAllCustomFieldData() {
        return this._rpcClient.makeRequest(
            "erecord",
            "getAllCustomFieldData",
            {}
        );
    }

    getAllMDDData() {
        return this._rpcClient.makeRequest("erecord", "getAllMDDData", {});
    }

    getDocumentCustomValuesByForm(
        documentID: string,
        formID: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getDocumentCustomValuesByForm",
            { documentID: documentID, customFormID: formID },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    setCustomField(dataField: DataField) {
        return this._rpcClient.makeRequest("erecord", "setCustomField", {
            customField: dataField
        });
    }

    getPartyNameParsed(
        value: string,
        documentID: string,
        customFormID: string,
        customFieldID: string,
        sequenceID: number,
        forcedPartyType?: string // "Organization"; // "Individual";
    ) {
        return this._rpcClient.makeRequest("erecord", "getPartyNameParsed", {
            value: value,
            documentID: documentID,
            customFormID: customFormID,
            customFieldID: customFieldID,
            sequenceID: sequenceID,
            forcedPartyType: forcedPartyType
        });
    }

    deleteFields(fieldIDs: string[]) {
        return this._rpcClient.makeRequest("erecord", "deleteCustomFields", {
            customFieldIDs: fieldIDs
        });
    }

    deleteForm(formID: string) {
        return this._rpcClient.makeRequest("erecord", "deleteCustomForms", {
            customFormIDs: [formID]
        });
    }

    deleteForms(formIDs: string[]) {
        return this._rpcClient.makeRequest("erecord", "deleteCustomForms", {
            customFormIDs: formIDs
        });
    }

    saveCustomForm(saveFormData: any) {
        return this._rpcClient.makeRequest("erecord", "setCustomForm", {
            form: saveFormData
        });
    }

    setDocTypeForms(recipientID: string, docTypes: any[], message: string) {
        return this._rpcClient.makeRequest("erecord", "setDocTypeForms", {
            recipientID: recipientID,
            docTypes: docTypes,
            importNotes: message
        });
    }

    getRecipientTypeIcon(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getRecipientTypeIcon", {
            recipientID
        });
    }

    getPinkBoxInstructions(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getPinkBoxInstructions", {
            recipientID
        });
    }

    setPinkBoxInstructions(recipientID: string, instructions: any, notes: any) {
        return this._rpcClient.makeRequest("admin", "setPinkBoxInstructions", {
            recipientID,
            uiRecipientInstructionsModels: instructions,
            importNotes: notes
        });
    }

    setCurrentRecipient(selectedRecipient: any) {
        this._currentRecipient = selectedRecipient;
        this._currentRecipientSubject.next(this._currentRecipient);
    }

    saveChanges() {
        this._saveChanges$.next();
    }

    get saveChanges$(): Observable<void> {
        return this._saveChanges$.asObservable();
    }

    getCustomProperties(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getCustomProperties", {
            recipientID
        });
    }

    saveCustomProperties(recipientID: string, properties: any) {
        return this._rpcClient.makeRequest("admin", "saveCustomProperties", {
            recipientID,
            properties
        });
    }

    getOpenHours(recID: string) {
        return this._rpcClient.makeRequest("admin", "getOpenHours", {
            recID
        });
    }

    getTimeZone(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getTimeZone", {
            recipientID
        });
    }

    setOpenHours(data: any) {
        return this._rpcClient.makeRequest("admin", "setOpenHours", data);
    }

    setScheduleModifications(scheduleModel: any) {
        return this._rpcClient.makeRequest(
            "admin",
            "setScheduleModifications",
            { scheduleModel }
        );
    }

    getScheduleModifications(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getScheduleModifications",
            { recipientID }
        );
    }

    addClosedDay(closedDayModel: any) {
        return this._rpcClient.makeRequest("admin", "addClosedDay", {
            closedDayModel
        });
    }

    deleteClosedDay(closedDayName: string) {
        return this._rpcClient.makeRequest("admin", "deleteClosedDay", {
            closedDayName
        });
    }

    getClosedDayUsage(closedDayName: string) {
        return this._rpcClient.makeRequest("admin", "getClosedDayUsage", {
            closedDayName
        });
    }

    getRecipientHistory(recipientID: string, eventHistory: boolean) {
        return this._rpcClient.makeRequest("admin", "getRecipientHistory", {
            recipientID,
            eventHistory
        });
    }

    getRecipientOrganizationSettings(orgID: string) {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRecipientOrganizationSummary",
            { orgID }
        );
    }

    getRecipientFipsCodes(orgID: string) {
        return this._rpcClient.makeRequest("admin", "getRecipientFipsCodes", {
            recipientID: orgID
        });
    }

    setRecipientFipsCodes(orgID: string, fips: string[]) {
        return this._rpcClient.makeRequest("admin", "setRecipientFipsCodes", {
            recipientID: orgID,
            countyFips: fips
        });
    }

    saveRecipientClass(orgID: string, recipientClass: string, comment: string) {
        return this._rpcClient.makeRequest("userorg", "saveRecipientClass", {
            orgID,
            recipientClass,
            comment
        });
    }

    saveVendor(orgID: string, vendor: string, comment: string) {
        return this._rpcClient.makeRequest("userorg", "saveVendor", {
            orgID,
            vendor,
            comment
        });
    }

    setRecipientOrganizationSettings(
        orgID: string,
        settings: any,
        comment: string
    ) {
        return this._rpcClient.makeRequest(
            "userorg",
            "setRecipientOrganizationLite",
            { orgID, settings, comment }
        );
    }

    getEnvironments() {
        return this._rpcClient.makeRequest("admin", "getEnvironments", {});
    }

    getXMLDiffs(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getXMLDiffs", {
            recipientID: recipientID
        });
    }

    getRecipientDiffConfiguration(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecipientDiffConfiguration",
            {
                recipientID: recipientID
            }
        );
    }

    getPkgXMLDiffs(recipientID: string, count: number, docTypes: any[]) {
        return this._rpcClient.makeRequest("admin", "getPkgXMLDiffs", {
            recipientID: recipientID,
            count: count,
            docTypes: docTypes
        });
    }

    getPkgHelperDiffs(recipientID: string, count: number, helperType: string) {
        return this._rpcClient.makeRequest("admin", "getPkgHelperDiffs", {
            recipientID: recipientID,
            count: count,
            helperType: helperType
        });
    }

    getPkgXMLDiff(recipientID: string, packageID: string) {
        return this._rpcClient.makeRequest("admin", "getPkgXMLDiff", {
            recipientID: recipientID,
            packageID: packageID
        });
    }

    getPkgXMLPropertyDiffs(
        recipientID: string,
        propertyName: string,
        operator: string,
        value: string,
        count: number
    ) {
        return this._rpcClient.makeRequest("admin", "getPkgXMLPropertyDiffs", {
            recipientID: recipientID,
            propertyName: propertyName,
            operator: operator,
            value: value == null ? "" : value,
            count: count
        });
    }

    getPkgXMLSubmitterDiffs(
        recipientID: string,
        ampersand: boolean,
        street2: boolean,
        count: number
    ) {
        return this._rpcClient.makeRequest("admin", "getPkgXMLSubmitterDiffs", {
            recipientID: recipientID,
            ampersand: ampersand,
            street2: street2,
            count: count
        });
    }

    diffXML(packageID: string) {
        return this._rpcClient.makeRequest("admin", "diffXML", {
            packageID: packageID
        });
    }

    diffPkgXML(
        packageID: string,
        class1: string,
        xsl1: string,
        class2: string,
        xsl2: string,
        ignored: string,
        compareRoot: string,
        wholeDocument: boolean
    ) {
        return this._rpcClient.makeRequest("admin", "diffPkgXML", {
            packageID: packageID,
            handlerClass1: class1,
            xsl1: xsl1,
            handlerClass2: class2,
            xsl2: xsl2,
            ignore: ignored,
            compareRoot: compareRoot,
            wholeDocument: wholeDocument
        });
    }

    sendXmlDiff(diff: any) {
        this._xmlDiffChange$.next(diff);
    }

    get xmlDiff$(): Observable<undefined> {
        return this._xmlDiffChange$.asObservable();
    }

    getRecipientTownships(recipientID: string) {
        return this._rpcClient.makeRequest("erecord", "getRecipientTownships", {
            recipientID: recipientID
        });
    }

    getRecipientOrganizationConfig(recipientId: string, group: string) {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRecipientOrganizationConfig",
            {
                recipientId,
                group
            }
        );
    }

    setRecipientOrganizationConfig(
        recipientId: string,
        settings: any,
        comment: string
    ) {
        return this._rpcClient.makeRequest(
            "userorg",
            "setRecipientOrganizationConfig",
            { recipientId, settings, comment }
        );
    }

    getRecipientConfigOptions() {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRecipientConfigOptions"
        );
    }

    getRecipientPrintQueueVariables(orgID: string) {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRecipientPrintQueueVariables",
            { orgID }
        );
    }

    setRecipientPrintPaperSize(orgID: string, paperSize: string) {
        return this._rpcClient.makeRequest(
            "userorg",
            "setRecipientPrintPaperSize",
            { orgID, paperSize }
        );
    }

    getRejectionReasons(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getRejectionReasons", {
            recipientID
        });
    }

    setRejectionReasons(recipientID: string, instructions: any, notes: any) {
        return this._rpcClient.makeRequest("admin", "setRejectionReasons", {
            recipientID,
            rejectionReasons: instructions,
            importNotes: notes
        });
    }

    getMargins(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getMargins", {
            recipientID
        });
    }

    getRecipientDocTypeList(recipientID: string, includeHelpers: boolean) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientDocTypeList",
            {
                recipientID,
                includeHelpers
            }
        );
    }

    getRecipientHelperTypeList(recipientID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientHelperTypeList",
            {
                recipientID
            }
        );
    }

    getRecipientFormFieldsList(recipientID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getCustomFieldsByRecipient",
            {
                recipientID
            }
        );
    }

    getOtherDocumentIDs(
        documentID: string,
        overrideDefaultErrorHandling?: boolean
    ): Observable<{ [key: string]: string }> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getOtherDocumentIDs",
            {
                documentID
            },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    getAutoCompleteSuggestions(
        documentID: string,
        formID: string,
        pageAnalysisID?: string
    ) {
        let params: any = { documentID: documentID, customFormID: formID };
        if (pageAnalysisID) {
            params.pageAnalysisID = pageAnalysisID;
        }
        return this._rpcClient.makeRequest(
            "erecord",
            "getAutoCompleteSuggestions",
            params
        );
    }

    getDocumentGroupInfo(documentID: string, customFieldIDs: string[]) {
        return this._rpcClient.makeRequest("erecord", "getDocumentGroupInfo", {
            documentID,
            customFieldIDs
        });
    }

    setCommonParties(documentID: string, formID: string, partyList: any[]) {
        return this._rpcClient.makeRequest("erecord", "setCommonParties", {
            documentID: documentID,
            customFormID: formID,
            commonParties: partyList
        });
    }

    getRecipientBookTypeList(
        recipientID: string,
        documentID: string,
        overrideDefaultErrorHandling?: boolean
    ) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientBookTypeList",
            {
                recipientID: recipientID,
                documentID: documentID
            },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    getStateList() {
        return this._rpcClient.makeRequest("erecord", "getStateList", {});
    }

    setMargins(
        recipientID: string,
        marginSettings: MarginSetting[],
        importNotes: string
    ) {
        return this._rpcClient.makeRequest("admin", "setMargins", {
            recipientID,
            marginSettings,
            importNotes
        });
    }

    getCustomText(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getCustomText", {
            recipientID
        });
    }

    setCustomText(recipientID: string, customText: any, importNotes: string) {
        return this._rpcClient.makeRequest("admin", "setCustomText", {
            recipientID,
            customText,
            importNotes
        });
    }

    getRecipientImport(data: RecipientImportData) {
        return this._rpcClient.makeRequest("admin", "getRecipientImport", data);
    }

    setRecipientImport(data: any) {
        return this._rpcClient.makeRequest("admin", "setRecipientImport", data);
    }

    syncAllRecipients(getDisabled: boolean) {
        return this._rpcClient.makeRequest("admin", "scheduleManualSyncAll", {
            defaultConfigTrue: false,
            getDisabled: getDisabled
        });
    }

    importNewRecipient(newRecipientID: string, recipientDump: string) {
        return this._rpcClient.makeRequest("admin", "importNewRecipient", {
            newRecipientID,
            recipientDump
        });
    }

    getRecipientKOIList(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getObjectRecipientKOIList",
            {
                recipientID
            }
        );
    }

    setRecipientKOIList(
        recipientID: string,
        koilist: any[],
        helperDocList: any[],
        notes: string,
        updateIntermediates: boolean
    ) {
        return this._rpcClient.makeRequest("admin", "setRecipientKOIList", {
            recipientID: recipientID,
            docTypes: koilist,
            helperDocs: helperDocList,
            importNotes: notes,
            updateIntermediates: updateIntermediates
        });
    }

    getSubmitterSettings(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getSubmitterSettings", {
            recipientID
        });
    }

    saveSubmitterSettings(
        recipientID: string,
        submitterSettings: any[],
        importNotes: string
    ) {
        return this._rpcClient.makeRequest("admin", "saveSubmitterSettings", {
            recipientID,
            submitterSettings,
            importNotes
        });
    }

    getPrintAndUploadQueueItems(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getPrintAndUploadQueueItems",
            {
                recipientID
            }
        );
    }

    deletePrintOrUploadQueueItems(recipientID: string, queueItemIDs: string[]) {
        return this._rpcClient.makeRequest(
            "admin",
            "deletePrintOrUploadQueueItems",
            {
                recipientID,
                queueItemIDs
            }
        );
    }

    getZipCodesMissingRecipient() {
        return this._rpcClient.makeRequest(
            "admin",
            "getZipCodesMissingRecipient",
            {}
        );
    }

    getZipCodesForRecipient(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "getZipCodesForRecipient", {
            recipientID
        });
    }

    setRecipientZipCodes(recipientID: string, zipCodes: string[]) {
        return this._rpcClient.makeRequest("admin", "setRecipientZipCodes", {
            recipientID,
            zipCodes
        });
    }

    copyIndexingData(
        copyFromDocId: string,
        copyToDocId: string,
        customFormID: string
    ): Observable<CustomFieldValue[]> {
        return this._rpcClient.makeRequest("erecord", "copyIndexingData", {
            copyFrom: copyFromDocId,
            copyTo: copyToDocId,
            customFormID
        });
    }

    getXMLStamps(recipientID: string): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getXMLStamps", {
            recipientID
        });
    }

    validateStampXML(recipientID: string, xml: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "validateStampXML", {
            recipientID,
            xml
        });
    }

    generateStamp(
        recipientID: string,
        xml: string,
        documentType: string,
        page: number,
        index: number,
        runtimeValues: any
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "generateStamp", {
            recipientID,
            xml,
            documentType,
            page,
            index,
            runtimeValues
        });
    }

    setXMLStamps(
        recipientID: string,
        xml: string,
        importNotes: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "setXMLStamps", {
            recipientID,
            xml,
            importNotes
        });
    }

    getRecipientImportExportCSV(recipientID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecipientImportExportCSV",
            {
                recipientID
            }
        );
    }
}
