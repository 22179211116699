import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { ChatOverlayComponent } from "../components/chat-overlay/chat-overlay.component";

export const CHAT_CONTAINER_DATA = new InjectionToken<OverlayRef>(
    "CHAT_CONTAINER_DATA"
);

@Injectable({
    providedIn: "root"
})
export class ChatOverlayService {
    private _overlayRef: OverlayRef;

    constructor(private _overlay: Overlay) {}

    open() {
        if (!this._overlayRef) {
            const overlayConfig = new OverlayConfig({
                hasBackdrop: false,
                positionStrategy: this._overlay
                    .position()
                    .global()
                    .bottom("20px")
                    .right("20px"),
                panelClass: "chat-overlay"
            });

            this._overlayRef = this._overlay.create(overlayConfig);
            const injector = Injector.create({
                providers: [
                    { provide: CHAT_CONTAINER_DATA, useValue: this._overlayRef }
                ]
            });
            const portal = new ComponentPortal(
                ChatOverlayComponent,
                null,
                injector
            );

            this._overlayRef.attach(portal);
        }

        return this._overlayRef.overlayElement;
    }

    close() {
        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._overlayRef = null;
        }
    }
}
