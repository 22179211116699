import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class OrganizationArchiveService {
    constructor(private _rpcClient: RpcClientService) {}

    canArchiveOrganization(orgID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("capc", "canArchiveOrganization", {
            orgID: orgID
        });
    }

    archiveOrganization(
        orgID: string,
        reason: string,
        details: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "archiveOrganization", {
            organizationID: orgID,
            reason: reason,
            details: details
        });
    }

    unarchiveOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "unarchiveOrganization", {
            organizationID: orgID
        });
    }
}
