<div class="sf-unmask">
    <sf-modal-header title="Change Password"></sf-modal-header>
    <div class="sf-change-password-modal modal-body">
        <form [formGroup]="changePasswordForm">
            <div *ngIf="!passwordWasChanged">
                <div class="row main-area">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="currentPassword">Current Password</label>
                            <input type="password" id="currentPassword" formControlName="currentPassword"
                                   autocomplete="new-password" class="form-control sf-exclude" autofocus>
                        </div>
                        <div class="form-group">
                            <label for="newPassword">New Password</label>
                            <input type="password" id="newPassword" formControlName="newPassword"
                                   autocomplete="new-password" class="form-control sf-exclude" (focus)="passwordFocused = true"
                                   (blur)="passwordFocused = false">
                        </div>
                        <div class="form-group">
                            <label for="confirmNewPassword">Confirm New Password</label>
                            <input type="password" id="confirmNewPassword" formControlName="confirmPassword"
                                   autocomplete="new-password" class="form-control sf-exclude" (focus)="passwordFocused = true"
                                   (blur)="passwordFocused = false">
                        </div>
                    </div>
                    <div class="col-md-7 side-show">
                        <b>Password Requirements</b>
                        <div class="pwd-requirements">
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                         class="inactive"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && !passwordLengthMet" [fixedWidth]="true" icon="times-circle"
                                         class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && passwordLengthMet" [fixedWidth]="true" icon="check-circle"
                                         class="pwd-met"></fa-icon>
                                <span> Must have {{pwdLength}} or more characters</span>
                            </div>
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                         class="inactive"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && !passwordHas3of4" [fixedWidth]="true" icon="times-circle"
                                         class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && passwordHas3of4" [fixedWidth]="true" icon="check-circle"
                                         class="pwd-met"></fa-icon>
                                <span *ngIf="!blockExtendedChars"> Must have at least 3 of the following:</span>
                                <span *ngIf="blockExtendedChars"> Must have all of the following:</span>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"></div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                             class="inactive"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && !passwordHasNumber" [fixedWidth]="true" icon="times-circle"
                                             class="pwd-unmet" [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && passwordHasNumber" [fixedWidth]="true" icon="check-circle"
                                             class="pwd-met"></fa-icon>
                                    <span> A number</span>
                                </div>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"></div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                             class="inactive"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && !passwordHasLowerCase" [fixedWidth]="true" icon="times-circle"
                                             class="pwd-unmet" [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && passwordHasLowerCase" [fixedWidth]="true" icon="check-circle"
                                             class="pwd-met"></fa-icon>
                                    <span> A lower-case character</span>
                                </div>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"></div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                             class="inactive"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && !passwordHasUpperCase" [fixedWidth]="true" icon="times-circle"
                                             class="pwd-unmet" [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && passwordHasUpperCase" [fixedWidth]="true" icon="check-circle"
                                             class="pwd-met"></fa-icon>
                                    <span> An upper-case character</span>
                                </div>
                            </div>
                            <div *ngIf="!blockExtendedChars" class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"></div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                             class="inactive"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && !passwordHasSpecialChar" [fixedWidth]="true" icon="times-circle"
                                             class="pwd-unmet" [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordFocused && passwordHasSpecialChar" [fixedWidth]="true" icon="check-circle"
                                             class="pwd-met"></fa-icon>
                                    <span> A special character (!@#$?+...)</span>
                                </div>
                            </div>
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordFocused" [fixedWidth]="true" icon="ellipsis-h"
                                         class="inactive"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && !passwordsMatch" [fixedWidth]="true" icon="times-circle"
                                         class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordFocused && passwordsMatch" [fixedWidth]="true" icon="check-circle"
                                         class="pwd-met"></fa-icon>
                                <span> Passwords must match</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="passwordWasChanged" class="main-area">
                Your password has been changed successfully.
            </div>
            <!-- This is just to allow the Enter key to work for convenience -->
            <input type="submit" class="hidden-submit" (click)="handleEnter()"/>
        </form>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary" *ngIf="!passwordWasChanged"></sf-modal-footer>
    <sf-modal-footer [primary]="logoutButton" *ngIf="passwordWasChanged"></sf-modal-footer>
</div>
