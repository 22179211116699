<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>
<div *ngIf="loaded && !loadFailed" class="content like-table">
    <div class="head-text">Simplifile API Token</div>
    <div class="sub-title">{{orgName}} &nbsp;|&nbsp; {{integrationName}} Integration</div>
    <div *ngIf="!buttonClicked">
        <div>
            Click the View Token button below to display your token. Please note, you may only view this token once <br/>
            and you should treat this token as you would a password.
        </div>
        <div class="top-gap">
            <button class="btn btn-primary" (click)="viewToken()">View Token</button>
        </div>
    </div>
    <table *ngIf="buttonClicked">
        <tbody>
        <tr>
            <td colspan="2">
                <span>Your API token is displayed below. Please note that you may only view this token once, </span>
                <span>and that you should treat this token as you would a password.</span>
            </td>
        </tr>
        <tr class="small-gap">
        <tr *ngIf="!!tokenEncoded">
            <td colspan="2">
                <span>You should copy and save both of the tokens below. </span>
                <span>They are both the same token, but in different formats. The first format works most of the time, </span>
                <span>but the second format is required in some situations.</span>
            </td>
        </tr>
        <tr class="small-gap">
        <tr class="interaction">
            <td class="">{{tokenText}}</td>
            <td class="button-cell">
                <button class="btn btn-primary" (click)="copyToken()">Copy Token</button>
            </td>
        </tr>
        <tr class="gap">
        <tr *ngIf="!!tokenEncoded">
            <td colspan="2">
                <span>If the token above does not enable access, please try the URL-encoded token below. </span>
            </td>
        </tr>
        <tr class="really-small-gap">
        <tr *ngIf="!!tokenEncoded && showUrlEncode" class="interaction">
            <td class="">{{tokenEncoded}}</td>
            <td class="button-cell">
                <button class="btn btn-primary" (click)="copyUrlToken()">Copy Token</button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
