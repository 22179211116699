<div class="modal-footer sf-unmask">
    <div class="left-group">
        <button *ngIf="secondary" type="button" class="btn btn-secondary" [id]="secondary.id ? secondary.id : 'btn-secondary'"
            [disabled]="secondary.disabled" [hidden]="secondary.hidden"
            (click)="buttonClick(secondary, 'secondary')">{{secondary.text ? secondary.text : 'Cancel'}}
        </button>
    </div>

    <div class="right-group">
        <button *ngIf="backButton" type="button" class="btn btn-secondary" [id]="backButton.id ? backButton.id : 'btn-back'"
            [disabled]="backButton.disabled" [hidden]="backButton.hidden"
            (click)="buttonClick(backButton, 'backButton')">{{backButton.text ? backButton.text : 'Other'}}
        </button>
        <div class="" *ngIf="!backButton">
            <ng-content></ng-content>
        </div>
        <button *ngIf="nextButton" type="button" class="btn btn-secondary" [id]="nextButton.id ? nextButton.id : 'btn-next'"
            [disabled]="nextButton.disabled" [hidden]="nextButton.hidden"
            (click)="buttonClick(nextButton, 'nextButton')">{{nextButton.text ? nextButton.text : 'Other'}}
        </button>
        <div class="" *ngIf="!nextButton">
            <ng-content></ng-content>
        </div>
        <button *ngIf="primary" type="button" class="btn btn-primary" [id]="primary.id ? primary.id : 'btn-primary'"
            [disabled]="primary.disabled"
            [hidden]="primary.hidden || !nextButton.hidden" (click)="buttonClick(primary, 'primary')">{{primary.text}}
        </button>
    </div>
</div>
