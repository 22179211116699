<sf-modal-header title="License Fee Paid"></sf-modal-header>
<div class="modal-body">
    <div class="message success-message">
        <span>Payment successful!</span>
    </div>
    <div class="sector">
        <span>Your <b>{{licenseFeeAmount | currency}}</b> license fee was successfully paid, and a receipt has been emailed to you. </span>
        <span>Please log in to Simplifile to begin eRecording.</span>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary">
</sf-modal-footer>
