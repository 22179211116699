import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutWrapperComponent } from "./layout-wrapper/layout-wrapper.component";
import { BaseLayoutComponent } from "./base-layout/base-layout.component";
import { RouterModule } from "@angular/router";
import { BannersComponent } from "./banners/banners.component";
import { CustomerSurveysModule } from "@sf/customer-surveys";
import { GrowlsComponent } from "./growls/growls.component";
import { NavigationModule } from "@sf/common";
import { UserComponent } from "./left-nav/user/user.component";
import { LogoComponent } from "./left-nav/logo/logo.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SfCommonModule } from "@sf/common";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { TOASTR_CONFIG } from "@sf/common";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { BlankLayoutComponent } from "./blank-layout/blank-layout.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomerSurveysModule,
        NavigationModule,
        FontAwesomeModule,
        SfCommonModule,
        ReactiveFormsModule,
        NgbTooltipModule,
        ToastrModule.forRoot(TOASTR_CONFIG)
    ],
    declarations: [
        LayoutWrapperComponent,
        BaseLayoutComponent,
        BannersComponent,
        GrowlsComponent,
        LogoComponent,
        UserComponent,
        BlankLayoutComponent
    ],
    exports: [
        BannersComponent,
        GrowlsComponent,
        BlankLayoutComponent,
        LogoComponent,
        UserComponent
    ]
})
export class LayoutModule {}
