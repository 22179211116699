import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { dayjs, SessionOrganization, SortUtilitiesService } from "@sf/common";
import "dayjs/plugin/relativeTime";
import {
    getPath,
    GrowlService,
    RpcClientService,
    SelectableItemWithID,
    SessionService
} from "@sf/common";
import { User } from "../interfaces/user.interface";
import {
    Address,
    Organization,
    OrganizationEntitlement
} from "../interfaces/organization.interface";
import { UserDetailsUser } from "../interfaces/user-details.interface";
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap
} from "rxjs/operators";
import { TrialUser } from "../interfaces/trial-user";
import { CurrentOrganizationService } from "./current-organization.service";

export interface OrgStatusDetails {
    lastSubmissionDate: Date;
    missingPaymentAccount: boolean;
    contractAcceptancePending: boolean;
    signers: string[];
    submissionFee: number;
    hasNoCounties: boolean;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class UserOrgService {
    private readonly _userActionCount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _userActionCountDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _orgActionCount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _orgActionTickerCount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _currentUserUserCount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    userActionCount$: Observable<number|null> = null;
    userActionCountDetails$: Observable<any|null> = null;
    orgActionCount$: Observable<number|null> = null;
    orgActionTickerCount$: Observable<number|null> = null;
    currentUserUserCount$: Observable<number|null> = null;

    constructor(
            private sessionService: SessionService,
            private _rpcClient: RpcClientService,
            private _growlService: GrowlService,
            private _currentOrgService: CurrentOrganizationService
    ) {
        this.sessionService.sessionResetEvent.subscribe(() => {
            this.clearCachedOrgsAndUsers();
        });

        this.userActionCountDetails$ = this._userActionCountDetails$.pipe(
                switchMap(() => this._rpcClient.makeRequest<any>("search", "getUserActionCount"))
        );

        this.userActionCount$ = this._userActionCount$.pipe(
            switchMap(() => this.userActionCountDetails$),
            map((results: any) => {
                let sum: number = results.lockedOut + results.approvals;
                return sum > 0 ? sum : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.currentUserUserCount$ = this._currentUserUserCount$.pipe(
            switchMap(() =>
                this._rpcClient.makeRequest("search", "getAssociatedUserCount")
            ),
            map((results: number) => {
                return results;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.orgActionTickerCount$ = this._orgActionTickerCount$.pipe(
            switchMap(() => this.getOrganizationActionCount()),
            map((orgCounts: any) => {
                let sum: number =
                    orgCounts.contractAccept +
                    orgCounts.suspendedService +
                    orgCounts.paymentAccount +
                    orgCounts.missingCounty;
                return sum > 0 ? sum : null;
            }),
            distinctUntilChanged((newVal: number, oldVal: number) => {
                if (newVal && oldVal) {
                    return newVal === oldVal;
                }
                return newVal === null && oldVal === null;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.orgActionCount$ = this._orgActionCount$.pipe(
            switchMap(() =>
                this._rpcClient.makeRequest(
                    "userorg",
                    "getOrganizationActionCount"
                )
            ),
            map((orgCounts: any) => {
                return orgCounts;
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    getOrganizationActionCount(): Observable<any> {
        return this.orgActionCount$;
    }

    getOrganizationsPageList(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getEnhancedOrganizationsForCurrentUser", {
            options: {
                includeHierarchy: true,
                includeServices: true,
                includeContractInfo: false,
                includeStatusInfo: true
            }
        });
    }

    getRecipientVendorCounts(): Observable<number> {
        return this._rpcClient.makeRequest("userorg", "getRecipientVendorCounts");
    }

    getOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganization", {
            orgID: orgID
        });
    }

    getOrganizations(orgIDs: string[]): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganizations", {
            orgIDs: orgIDs
        });
    }

    getUser(userID: string): Observable<User> {
        return this._rpcClient.makeRequest("userorg", "getBasicUser", {
            username: userID
        });
    }

    getUsers(usernames: string[]): Observable<User[]> {
        return this._rpcClient.makeRequest("userorg", "getBasicUsers", {
            usernames: usernames
        });
    }

    getSalespersons(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getSalespersons");
    }

    getAccountManagers(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAccountManagers");
    }

    buildDisplayableServices(item: any, services: any[]) {
        let hasCAPC: boolean = false, hasSettlement: boolean = false, hasLender: boolean = false;

        let displayableServices: any[] = [];
        services.forEach((service: any) => {
            if (this.isDisplayableService(service.id)) {
                displayableServices.push(service);
            }
            if (service.id == "capc") {
                hasCAPC = true;
            }
            if (service.id == "settlement") {
                hasSettlement = true;
            }
            if (service.id == "lender") {
                hasLender = true;
            }
            if (service.isSuspended) {
                item.hasSuspendedService = true;
            }
        });
        // if no services added, see if we should add 'e-record'
        if (displayableServices.length == 0) {
            services.forEach((service: any) => {
                if (service.id == "erecord") {
                    displayableServices.push(service);
                }
            });
        }

        displayableServices = displayableServices.sort((a, b) => {
            return SortUtilitiesService.stringSortCompareInsensitive(a.shortLabel, b.shortLabel);
        });

        //remove capc if settlement or lender present
        if (hasCAPC && (hasSettlement || hasLender)) {
            displayableServices = displayableServices.filter((o: any) => {
                return o.id != "capc";
            });
        }

        let sortableServices: string = "";
        displayableServices.forEach((service: any) => {
            sortableServices += service.shortLabel;
        });

        item.displayableServices = displayableServices;
        item.sortableServices = sortableServices;
    }

    isDisplayableService(serviceID: string): boolean {
        let displayable: string[] = [
            "subscription",
            "submitter",
            "recipient",
            "submitter_signing",
            "submitter_paper",
            "capc",
            "doc_builder",
            "admin",
            "settlement",
            "lender",
            "vendor",
            "trustee",
            "notary",
            "esign_event",
            "doc_builder_ron"
        ];
        return displayable.includes(serviceID);
    }

    orgHasDisplayableService(org: any, serviceID: string): boolean {
        let has: boolean = false;
        org.displayableServices?.forEach((service: any) => {
            if (service.id == serviceID) {
                has = true;
            }
        });
        return has;
    }

    syncOrgToSalesforce(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "syncAccountToSalesforce", {
            organizationID: orgID
        });
    }

    verifyOrgAddress(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "verifyOrgAddress", {
            orgID: orgID
        });
    }

    getAvailableUsernames(firstName: string, lastName: string, email: string, userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAvailableUsernames", {
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: userID
        });
    }

    checkUserCanLogin(userID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "checkUserCanLogin", {
            username: userID
        });
    }

    /**
     * get ALL user settings from the back end.
     * You can then pick out the ones you are interested in,
     * Or call getUserSetting below.
     * @param userID
     */
    getAllUserSettings(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getUserSettings", {
            username: userID
        });
    }

    getUserSetting(userID: string, settingName: string): Observable<any> {
        settingName = settingName.toUpperCase();
        return new Observable((subscriber) => {
            this.getAllUserSettings(userID).subscribe((settings) => {
                const value = getPath(settings, settingName);
                subscriber.next(value);
                subscriber.complete();
            });
        });
    }

    setUserSetting(userID: string, settingName: string, value: any) {
        settingName = settingName.toUpperCase();
        this._rpcClient.makeRequest("userorg", "setUserSetting", {
            username: userID,
            settingName: settingName,
            value: value
        }).subscribe();
    }

    /**
     * update multiple user settings at once
     * @param userID
     * @param settings
     */
    saveUserSettings(userID: string, settings: any): Observable<null> {
        return this._rpcClient.makeRequest("userorg", "saveUserSettings", {
            username: userID,
            settings: settings
        });
    }

    getRecentLoginCount(username: string, sinceDaysAgo: number): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getRecentLoginCount", {
            username: username,
            sinceDaysAgoCount: sinceDaysAgo
        });
    }

    getUserDetails(): Observable<{ user: UserDetailsUser }> {
        return this._rpcClient.makeRequest("userorg", "getUserDetails");
    }

    doesUserRequireSecondFactor(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "doesUserRequireSecondFactor");
    }

    getUserDefaultOrganization(userID: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getUserDefaultOrganizationID", { userID: userID });
    }

    getAllSubmitterOrganizationsForUser(userID: string): Observable<Organization[]> {
        return this._rpcClient.makeRequest("userorg", "getAllSubmitterOrganizationsForUser", { username: userID });
    }

    getImmediateOrganizationsForUser(userID: string): Observable<Organization[]> {
        return this._rpcClient.makeRequest("userorg", "getImmediateOrganizationsForUser", { username: userID });
    }

    getOrganizationConfiguration(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationConfiguration", { organizationID: orgID });
    }

    setOrganizationConfiguration(orgID: string, settings: any): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setOrganizationConfiguration", {
            organizationID: orgID,
            settings: settings
        });
    }

    getOrganizationConfigurations(orgIDs: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationConfigurations", { organizationIDs: orgIDs });
    }

    saveUser(user: User): Observable<null> {
        return this._rpcClient.makeRequest("userorg", "saveUser", user);
    }

    setUserDefaultOrganization(userID: string, orgID: string): Observable<null> {
        return this._rpcClient.makeRequest("userorg", "setUserDefaultOrganization", {
            userID: userID,
            orgID: orgID == "" ? null : orgID
        });
    }

    // Allows current user to change personal default organization without admin permissions
    setMyDefaultOrganization(orgID: string): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setMyDefaultOrganization", { orgID: orgID });
    }

    canUserLogin(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "canUserLogin", {
            username: userID
        });
    }

    buildUserFullName(user: User) {
        if (!user.name) {
            user.name = {
                first: "",
                middle: "",
                last: ""
            };
        }
        let firstLast: string = "";
        if (user.name.first) {
            firstLast += user.name.first;
        }
        if (user.name.last) {
            if (firstLast.length) {
                firstLast += " ";
            }
            firstLast += user.name.last;
        }
        user.firstLastName = firstLast;
        user.name.firstLast = firstLast;
    }

    isUsernameAvailable(username: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "isUsernameAvailable", {
            usernameString: username
        });
    }

    clearCachedOrgsAndUsers() {
        this._orgActionCount$.next(true);
        this._currentUserUserCount$.next(true);
        this._userActionCount$.next(true);
        this._orgActionTickerCount$.next(true);
    }

    getOrganizationSecurity(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationSecurity", { organizationID: orgID });
    }

    setOrganizationSecuritySettings(orgID: string, settings: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "setOrganizationSecuritySettings", {
            organizationID: orgID,
            policySettings: settings
        });
    }

    countUsersWithThisEmailAddress(email: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "countUsersWithThisEmailAddress", { emailString: email });
    }

    buildUserStatus(user: any) {

        user.statusNumber = 99; // default
        user.statusTooltip = "";
        if (user.isArchived) {
            user.status = "Archived";
            user.statusClass = "archived-badge";
            user.statusNumber = 98;
            user.statusTooltip = "User is archived.";
        } else if (user.isSuspended) {
            user.status = "Suspended";
            user.statusClass = "locked-badge";
            user.statusNumber = 1;
            user.statusTooltip = "User is suspended from logging in.";
        } else if (user.isLockedOut) {
            user.status = "Locked";
            user.statusClass = "locked-badge";
            user.statusNumber = 1;
            user.statusTooltip = "User is locked out.  Click to unlock.";
        } else if (user.pending) {
            user.status = "Pending";
            if (user.tokenType == "AUTHORIZE_NEW_USER") {
                user.status = "Needs Approval";
                user.statusClass = "pending-action-badge";
                user.statusNumber = 2;
                user.statusTooltip = "Needs administrator action.";
            } else if (user.tokenType == "INVITE_USER") {
                user.status = "Invitation Sent";
                user.statusClass = "pending-badge";
                user.statusNumber = 3;
                user.statusTooltip = "An email invitation has been sent to the user and is pending their approval.";
            } else {
                user.statusClass = "pending-badge";
                user.statusNumber = 3;
                user.statusTooltip = "Waiting for acceptance.";
            }
        } else if (!user.organizations || !user.organizations.length) {
            user.status = "Disabled";
            user.statusClass = "disabled-badge";
            user.statusNumber = 97;
            user.statusTooltip = "Not part of any organization";
        } else {
            this._determineActivity(user);
        }
    }

    private _determineActivity(user: any) {
        let createdDayjs = user.createdDate ? dayjs(user.createdDate) : dayjs(user.modifiedDate);
        let createdDiffDays = dayjs().diff(createdDayjs, "days");
        let lastLoginDayjs = dayjs(user.lastSuccessfulLogin);
        let lastLoginDiffDays = dayjs().diff(lastLoginDayjs, "days");

        user.lastLoginDayjs = lastLoginDayjs.format('MMMM DD, YYYY');
        user.lastLoginFromNow = lastLoginDayjs.fromNow();
        if (!user.lastSuccessfulLogin) {
            delete user.lastLoginFromNow;
            if (createdDiffDays > 30) {
                user.status = "Inactive";
                user.statusClass = "inactive-badge";
                user.statusNumber = 5;
                user.lastLoginDayjs = "Never";
            } else {
                user.status = "Active";
                user.statusClass = "active-badge";
                user.statusNumber = 4;
                user.lastLoginDayjs = "Never (new user)";
            }
        } else {
            if (lastLoginDiffDays > 180) {
                user.status = "Inactive";
                user.statusClass = "inactive-badge";
                user.statusNumber = 5;
            } else {
                user.status = "Active";
                user.statusClass = "active-badge";
                user.statusNumber = 4;
            }
        }
        user.statusTooltip = "Last login: " + user.lastLoginDayjs;
    }

    getUserCountForCurrentUser(): Observable<number> {
        return this.currentUserUserCount$;
    }

    getUserActionCount(): Observable<number> {
        return this.userActionCount$;
    }

    getUserActionCountDetails(): Observable<any> {
        return this.userActionCountDetails$;
    }

    getUsersForCurrentUser(canEditUsersOnly?: boolean, includePending?: boolean, maxResults?: number): Observable<any> {
        return this._rpcClient.makeRequest("search", "getAssociatedUsers", {
            enhancedResults: includePending,
            canEditUsersOnly: canEditUsersOnly ? canEditUsersOnly : false,
            maxResults: maxResults
        });
    }

    getUsersForCurrentUserByQuery(query: string, canEditUsersOnly?: boolean, includePending?: boolean, maxResults?: number) {
        return this._rpcClient.makeRequest("search", "getAssociatedUsersByQuery", {
            query: query,
            enhancedResults: includePending,
            canEditUsersOnly: canEditUsersOnly ? canEditUsersOnly : false,
            maxResults: maxResults
        });
    }

    // updateReviewFolders(organizationId: string, organizationName: string) {
    //     return this.angularJSService.updateReviewFolders(
    //         organizationId,
    //         organizationName
    //     );
    // }

    isEmailAddressAvailable(email: string, username: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "isEmailAddressAvailable", {
            email: email,
            username: username
        });
    }

    getUsersByOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getBasicUsersByOrganization", { orgID: orgID });
    }

    getAllUsersByOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAllUsersByOrganization", { orgID: orgID });
    }

    getUserLandingPages(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getUserLandingPages");
    }

    getOrganizationEntitlements(orgID: string): Observable<OrganizationEntitlement[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationEntitlements", { orgID: orgID });
    }

    // only call this if you want PROSPECT and DISABLED services
    getAllOrganizationEntitlements(orgID: string): Observable<OrganizationEntitlement[]> {
        return this._rpcClient.makeRequest("userorg", "getAllOrganizationEntitlements", { orgID: orgID });
    }

    updateOrganizationEntitlement(orgID: string, entitlement: OrganizationEntitlement): Observable<Organization> {
        return this._rpcClient.makeRequest("userorg", "updateOrganizationEntitlement", {
            orgID: orgID,
            newEntitlement: entitlement
        });
    }

    cloneObj(src: any): any {
        if (!src) {
            return null;
        }
        return JSON.parse(JSON.stringify(src));
    }

    filterUsersByPermission(usernames: string[], organizationIds: string[], permission: string): Observable<string[]> {
        return this._rpcClient.makeRequest("userorg", "filterUsersByPermission", {
            usernames: usernames,
            organizationIDs: organizationIds,
            permission: permission
        });
    }

    filterUsersByPermissions(usernames: string[], organizationIDs: string[], permissions: string[],
            reportType: string): Observable<string[]> {
        return this._rpcClient.makeRequest("userorg", "filterUsersByPermissions", {
            usernames: usernames,
            organizationIDs: organizationIDs,
            permissions: permissions,
            reportType: reportType
        });
    }

    findUsersByEmail(email: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "findUsersByEmail", {
            email: email
        });
    }

    // can be a single email address string, or a comma-separated list of email addresses
    validateSimplifileEmailDomain(emailOrEmails: string): boolean {
        let emails: any[] = [];
        let commaSepVals: string[] = emailOrEmails.split(",");

        commaSepVals.forEach((val: string) => {
            emails = emails.concat(val.split("\n"));
        });

        let ok = emails.every((email: string) => {
            let lemail = email.toLowerCase();
            return (lemail.endsWith("@simplifile.com") || lemail.endsWith("@theice.com") ||
                    lemail.endsWith("@elliemae.com") || lemail.endsWith("@ice.com"));
        });

        return ok;
    }

    validateEmail(emailOrEmails: any) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(emailOrEmails);
    }

    getOrganizationSecurityPolicies(orgIDs: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationSecurityPolicies", { organizationIDs: orgIDs });
    }

    createApiUser(params: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "createApiUser", params);
    }

    anyOrganizationHasFinanceCenterEnabled(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "anyOrganizationHasFinanceCenterEnabled",
                { organizationIDs: orgIDs });
    }

    // convert a type ID to a type name
    getBusinessTypeLabel(businessTypeID: string): string {
        let types = this.getSelectableBusinessTypes();
        let lowerID = businessTypeID.toLowerCase();
        let foundType = types.find((busType) => {
            return busType.id.toLowerCase() == lowerID;
        });
        if (!foundType) {
            return "";
        }
        return foundType.label;
    }

    /*
    // convert an array of type IDs to a single comma-separated string of type names
    getBusinessTypeLabels(businessTypeIDs: string[]): string {
        if (!businessTypeIDs) {
            return "";
        }
        let types = this.getSelectableBusinessTypes();
        let result = "";
        businessTypeIDs.forEach((typeID) => {
            let lowerID = typeID.toLowerCase();
            let foundType = types.find((busType) => {
                return busType.id.toLowerCase() == lowerID;
            });
            if (foundType) {
                if (result.length) {
                    result += ", ";
                }
                result += foundType.label;
            }
        });
        return result;
    }
    */

    // convert an array of type IDs to an array of ids
    getBusinessTypeIDsArray(businessTypeIDs: string[]): string[] {
        if (!businessTypeIDs) {
            return [];
        }
        let types = this.getSelectableBusinessTypes();
        let result: any = [];
        businessTypeIDs.forEach((typeID) => {
            let lowerID = typeID.toLowerCase();
            let foundType = types.find((busType) => {
                return busType.id.toLowerCase() == lowerID;
            });
            if (foundType) {
                result.push(foundType.id);
            }
        });
        return result;
    }

    getSelectableBusinessTypes(): SelectableItemWithID[] {
        return [
            {
                id: "aggregator",
                label: "Aggregator"
            }, {
                id: "appraiser",
                label: "Appraiser"
            }, {
                id: "attorney",
                label: "Attorney/Law Firm"
            }, {
                id: "bank",
                label: "Bank"
            }, {
                id: "borrower",
                label: "Borrower"
            }, {
                id: "bpo",
                label: "BPO"
            }, {
                id: "builder",
                label: "Building Contractor/Trades"
            }, {
                id: "county_assessor",
                label: "County Assessor"
            }, {
                id: "county_auditor",
                label: "County Auditor"
            }, {
                id: "county",
                label: "County Clerk"
            }, {
                id: "county_clerk_receiver",
                label: "County Clerk: Secondary Receiver"
            }, {
                id: "county_engineer",
                label: "County Engineer"
            }, {
                id: "county_preview",
                label: "County Preview"
            }, {
                id: "county_public_trustee",
                label: "County Public Trustee"
            }, {
                id: "county_treasurer",
                label: "County Treasurer"
            }, {
                id: "credit_union",
                label: "Credit Union"
            }, {
                id: "escrow",
                label: "Escrow"
            }, {
                id: "government",
                label: "Government"
            }, {
                id: "home_builder",
                label: "Home Builder"
            }, {
                id: "homeowner_condo",
                label: "HOA / Condo Association"
            }, {
                id: "lender",
                label: "Lender"
            }, {
                id: "lien_filer",
                label: "Lien Filer"
            }, {
                id: "servicer",
                label: "Loan/Mortgage Servicer"
            }, {
                id: "mortgage_broker",
                label: "Mortgage Broker"
            }, {
                id: "notary",
                label: "Notary"
            }, {
                id: "utilities",
                label: "Oil/Gas/Utilities"
            }, {
                id: "qc_reviewer",
                label: "QC Reviewer"
            }, {
                id: "real_estate_broker_buyer",
                label: "Real Estate Broker: Buyer"
            }, {
                id: "real_estate_broker_seller",
                label: "Real Estate Broker: Seller"
            }, {
                id: "real_estate_investor",
                label: "Real Estate Investor"
            }, {
                id: "real_estate_office",
                label: "Real Estate Office"
            }, {
                id: "seller",
                label: "Seller"
            }, {
                id: "processor_settlement",
                label: "Settlement"
            }, {
                id: "timeshare",
                label: "Timeshare"
            }, {
                id: "title",
                label: "Title Company"
            }, {
                id: "title_underwriter",
                label: "Title Underwriter"
            }, {
                id: "vendor",
                label: "Vendor"
            }, {
                id: "witness",
                label: "Witness"
            }, {
                id: "other_miscellaneous",
                label: "Other/Miscellaneous"
            }
        ];
    }

    currentUserMissingPaymentAccount(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "currentUserMissingPaymentAccount");
    }

    currentOrgMissingPaymentAccount(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "currentOrgMissingPaymentAccount", {
            orgID: orgID
        });
    }

    isPositiveIntegerString(n: any) {
        if (typeof n == "undefined" || n == null) {
            return false;
        }
        if (typeof n == "number") {
            return Number.isSafeInteger(n) && n >= 0;
        }
        n = n.trim();
        return this.isAllDigits(n);
    }

    isAllDigits(n: any) {
        if (n.length == 0) {
            return false;
        }
        for (let i = 0; i < n.length; i++) {
            if (!this.isDigit(n.substr(i, 1))) {
                return false;
            }
        }
        return true;
    }

    isDigit(char: any) {
        return Boolean([true, true, true, true, true, true, true, true, true, true][char]);
    }

    isFloatString(n: string) {
        if (typeof n == "undefined" || n == null) {
            return false;
        }
        if (typeof n == "number") {
            return !!(isFinite(n) && !Number.isNaN(n));
        }
        let it = n.trim();
        it = this.removeNegativeSign(it);
        return this.isAllDigitOrDecimal(it);
    }

    removeNegativeSign(it: any) {
        if (it.length == 0) {
            return it;
        }
        if (it.substr(0, 1) == "-") {
            it = it.substr(1);
        }
        return it;
    }

    isAllDigitOrDecimal(n: any) {
        if (n.length == 0) {
            return false;
        }
        let foundDecimal: boolean = false;
        for (let i = 0; i < n.length; i++) {
            let c = n.substr(i, 1);
            if (c == ".") {
                if (foundDecimal) {
                    return false;
                } else {
                    foundDecimal = true;
                }
            } else if (!this.isDigit(c) && c != ".") {
                return false;
            }
        }
        let parsed = parseFloat(n);
        return !isNaN(parsed) && isFinite(parsed);
    }

    verifyAddress(address: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "verifyAddress", {
            address: address
        });
    }

    getOrganizationStatusDetails(orgID: string): Observable<OrgStatusDetails> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationStatusDetails", { organizationID: orgID });
    }

    findApiSubscriptionsByOrganizationID(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("api", "findApiSubscriptionsByOrganizationID", { orgID: orgID });
    }

    getStats(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getStats", {
            orgID: orgID
        });
    }

    deleteApiSubscription(label: string, productID: string, orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("api", "deleteApiSubscription", {
            apiKeyID: label,
            id: productID,
            organizationID: orgID
        });
    }

    markOrganizationSample(orgID: string, sample: boolean): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "markOrganizationSample", {
            organizationID: orgID,
            sample: sample
        });
    }

    unarchiveOrganization(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "unarchiveOrganization", {
            organizationID: orgID
        });
    }

    enableOrganization(orgID: string, enable: boolean, reason: string, disabledReason: string,
            notifyAdminIDs: string[]) {
        return this._rpcClient.makeRequest("userorg", "enableOrganization", {
            organizationID: orgID,
            enable: enable,
            reason: reason,
            disabledReason: disabledReason,
            notifyUsers: notifyAdminIDs
        });
    }

    userCanEditUser(username: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "userCanEditUser", {
            username: username
        });
    }

    verifyContactInfo(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "verifyContactInfo");
    }

    canSeeHierarchyPage(): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "canSeeHierarchyPage");
    }

    getUsersInOrganizations(orgIDs: string[]): Observable<User[]> {
        return this._rpcClient.makeRequest("userorg", "getUsersInOrganizations", { organizationIDs: orgIDs });
    }

    getDocumentCountByPeriod(orgID: string, product: string, period: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getDocumentCountByPeriod", {
            organizationID: orgID,
            product: product,
            period: period
        });
    }

    updateOrganizationSalesTax(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "updateOrganizationSalesTaxRate", { organizationID: orgID });
    }

    grantTemporaryAdminPermissionsToUser(username: string, expireDateTime: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "grantTemporaryAdminPermissionsToUser", {
            username: username,
            expireDateTime: expireDateTime
        });
    }

    revokeTemporaryAdminPermissions(username: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "revokeTemporaryAdminPermissions", { username: username });
    }

    getHomePageURL(): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getHomePageURL");
    }

    uploadProfilePicture(licenseFileData: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "uploadProfilePicture", {
            licenseFileData
        });
    }

    uploadOrganizationProfilePicture(fileIDString: string, fileType: string, orgID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "uploadOrganizationProfilePicture", {
            fileIDString: fileIDString,
            fileType: fileType,
            orgID: orgID
        });
    }

    areUserSecurityQuestionsRequired(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "isUserSecurityQuestionRequired", {
            userID
        });
    }

    getSecurityQuestionList(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getSecurityQuestionList");
    }

    getPhoneSupportQuestionList(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getPhoneSupportQuestionList");
    }

    saveSecurityAnswers(userID: string, password: string, questionAnswerMap: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "saveUserSecurityAnswers", {
            userID,
            password,
            questionAnswerMap
        });
    }

    savePhoneSupportAnswer(userID: string, password: string, questionAnswerMap: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "savePhoneSupportAnswer", {
            userID,
            password,
            questionAnswerMap
        });
    }

    setMyLandingPage(landingPage: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "setMyLandingPage", {
            landingPage
        });
    }

    setUserDetails(user: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "setUserDetails", user);
    }

    getRemoteAddress(): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getRemoteAddress");
    }

    getOrganizationSalesRep(organizationID: string, productID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationSalesRep", {
            organizationID: organizationID,
            productID: productID
        });
    }

    /*
    userHasSecurityAnswers(userID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "userHasSecurityAnswers", {
            userID: userID
        });
    }
    */

    /*
    userHasPhoneSupportAnswer(userID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "userHasPhoneSupportAnswer", {
            userID: userID
        });
    }
    */

    getOrganizationListJustServices(): Observable<Organization[]> {
        return this._rpcClient.makeRequest("userorg", "getEnhancedOrganizationsForCurrentUser", {
            options: {
                includeHierarchy: false,
                includeServices: true,
                includeContractInfo: false,
                includeStatusInfo: false
            }
        });
    }

    syncUserToSalesforce(userID: string, isBillingContact: boolean): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "syncUserToSalesforce", {
            userID: userID,
            isBillingContact: isBillingContact
        });
    }

    getTrusteesProviders(orgIDs: string[]): Observable<{ value: string; label: string }[]> {
        return this._rpcClient.makeRequest<{ value: string; label: string }[]>("userorg", "getTrusteesProviders", {
            organizationIDs: orgIDs
        });
    }

    getTrusteesServicers(orgIDs: string[]): Observable<{ value: string; label: string }[]> {
        return this._rpcClient.makeRequest<{ value: string; label: string }[]>("userorg", "getTrusteesServicers", {
            organizationIDs: orgIDs
        });
    }

    getDocumentBuilderSetupGuideOrganizations(): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getDocumentBuilderSetupGuideOrganizations", {});
    }

    getVerifiedContactsByAddressIDs(verifiedAddressIDs: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getVerifiedContactsByAddressIDs", {
            verifiedAddressIDs
        });
    }

    /* // Not used
    getLiveConfigValueForUser(userID: string, sectionID: string, optionID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getLiveConfigValueForUser", {
            userID,
            sectionID,
            optionID
        });
    }
    */

    setLiveConfigValueForUser(userID: string, sectionID: string, optionID: string, value: string): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setLiveConfigValueForUser", {
            userID,
            sectionID,
            optionID,
            value
        });
    }

    hasProductThatRequiresTwoFactor(orgID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "hasProductThatRequiresTwoFactor", { orgID });
    }

    findServicesInTrialMode(): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "findServicesInTrialMode", { });
    }

    getSalesforceAccessURLByType(url: string, type: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getSalesforceAccessURLByType", {url: url, type: type});
    }

    getSalesforceAccessURLByData(type: string, data: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getSalesforceAccessURLByData", {type: type, data: data});
    }

    findServicesInTrialModeByProduct(product: string): Observable<{organizationId: string, organizationName: string, date: string}[]> {
        return this._rpcClient.makeRequest("userorg", "findServicesInTrialModeByProduct", { product });
    }

    findTrialUsers(organizationId: string): Observable<TrialUser[]> {
        return this._rpcClient.makeRequest("userorg", "findTrialUsers", { organizationId });
    }

    resetTrialCount(userId: string): Observable<number> {
        return this._rpcClient.makeRequest("userorg", "resetTrialCount", { userId });
    }

    hasAccessToServiceLevelIndicatorsReport(username: string): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "hasAccessToServiceLevelIndicatorsReport", { username });
    }

    // handle response from getOrganizationStatusDetails()
    canISignContractStatusDetails(response: any, orgID: string): boolean {
        if (!response || !response.contractAcceptancePending) {
            return false;
        }
        let isSuperUser = this.sessionService.hasPermission("admin_organization_management", "SIMPFL");
        if (isSuperUser) {
            return false;
        }

        let isSigner = false;
        let signers: string[] = response.signers;
        if (signers && signers.length) {
            let username = this.sessionService.getUsername();
            isSigner = (signers.includes(username));
        } else {
            // any admin can sign
            if (this.sessionService.hasPermission("organization_admin", orgID)) {
                isSigner = true;
            }
        }

        // must be in list, or list must be empty
        return isSigner;
    }

    getUserAddressByUserId(userId: string): Observable<Address> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "getUserAddressByUserId", { userId });
        }
        else {
            return this.getUserAddress();
        }
    }

    getUserAddress(): Observable<Address> {
        return this._rpcClient.makeRequest("userorg", "getUserAddress", {});
    }

    setUserAddressByUserId(address: Address, userId: string): Observable<void> {
        if (userId) {
            return this._rpcClient.makeRequest("userorg", "setUserAddressByUserId", {
                streets: address.streets,
                city: address.city,
                state: address.state,
                zipCode: address.zipCode,
                userId: userId
            });
        }
        else {
            return this.setUserAddress(address);
        }
    }

    setUserAddress(address: Address): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setUserAddress", address);
    }

    getCurrentMonthSubscriptionRevenue(orgID: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getCurrentMonthSubscriptionRevenue", {
            organizationID: orgID
        });
    }

    getChildrenWithSubscription(orgID: string): Observable<string[]> {
        return this._rpcClient.makeRequest("userorg", "getChildrenWithSubscription", {
            organizationID: orgID
        });
    }

    getParentsWithSubscription(orgID: string): Observable<string[]> {
        return this._rpcClient.makeRequest("userorg", "getParentsWithSubscription", {
            organizationID: orgID
        });
    }

    goToResourceCenter(): void {
        //check if user has email before continuing
        let userId: string = this.sessionService.getUserID();
        this.getUser(this.sessionService.getUserID()).subscribe(result => {
            if (result.hasSharedEmail) {
                this._growlService.error("Your email address of '" + result.email +
                        "' is shared with another user. " +
                        "A unique email address is required to access the Resource Center.",
                        "Shared email Address");
                return;
            }
            if (!!result.email) {
                let url: string = location.origin + "/sf/EMResourceCenter.jsp";
                let currentOrg: Organization = this._currentOrgService.getCurrentOrganization();
                if (currentOrg) {
                    url += "?oid=" + encodeURIComponent(currentOrg.id) + "&oname=" + encodeURIComponent(currentOrg.name);
                } else {
                    let orgs: SessionOrganization[] = this.sessionService.getAllOrganizations();
                    if (orgs.length == 1) {
                        url += "?oid=" + encodeURIComponent(orgs[0].id) + "&oname=" + encodeURIComponent(orgs[0].name);
                    }
                }
                let child: Window = window.open(url, "_blank");
                if (!child || typeof child == "undefined") {
                    this._growlService.warning("Your browser may have prevented access to the Resource Center. Check for a blocked " +
                            "pop-up notification, or modify your browser's security settings to allow the pop-up."
                    );
                }
            } else {
                this._growlService.error("Access to Resource Center requires an email address for the user");
            }
        });
    }

    getOrganizationIdentities(orgID: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationIdentities", {organizationID: orgID});
    }

    getOrganizationIdentitiesByType(orgID: string, type: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationIdentities", {organizationID: orgID, type: type});
    }

    deleteOrganizationIdentity(orgID: string, type: string, externalID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "deleteOrganizationIdentity", {organizationID: orgID, type: type, externalID: externalID});
    }

    getOrganizationIdentityTypes(): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationIdentityTypes");
    }

    saveOrganizationIdentity(orgID: string, type: string, value: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "saveOrganizationIdentity", {organizationID: orgID, type: type, externalID: value});
    }
}
