import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Organization } from "@sf/userorg/common";
import { ProductExtended, UserorgActivationService } from "@sf/userorg/common";
import { RoleService } from "@sf/userorg/common";
import { ModalButton, SortUtilitiesService } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { GrowlService } from "@sf/common";

interface AdministratorUser {
    name: string;
    id: string;
    email: string;
    selected: boolean;
}

// prettier-ignore
@Component({
    selector: "sf-organization-service-activation-dialog",
    templateUrl: "./organization-service-activation-dialog.component.html",
    styleUrls: ["./organization-service-activation-dialog.component.scss"]
})
export class OrganizationServiceActivationDialogComponent implements OnInit {
    @Input() title: string;
    @Input() organization: Organization;
    @Input() product: ProductExtended;
    @Input() action: string;    // "activate" or "deactivate"

    @ViewChild("reasonDetailsArea")
    reasonDetailsArea: ElementRef;

    activating = false;
    requestSent = false;
    selected = {};
    currentIndex = 0;
    loanCount = 0;
    supportedProduct = false;
    isLenderProduct = false;
    showAffectedUsers = false;
    showDeletedUsers = false;
    showAdministrators = false;
    sendNotification = true;
    disabledReason: any = null;
    reasonDetails: string = null;

    disabledReasons = [
        { id: "COUNTY_REQUEST", label: "County Request" },
        { id: "CUSTOMER_REQUEST", label: "Customer Request" },
        { id: "INACTIVITY", label: "Inactivity" },
        { id: "PAYMENT_ISSUES", label: "Payment Issues" },
        { id: "UNPAID_LICENSE_FEE", label: "Unpaid License Fee" },
        { id: "OTHER", label: "Other" }
    ];

    loadingAdmins = true;
    loadingLoans = false;
    primary: ModalButton;
    secondary: ModalButton;

    reasonDetailsRequired = false;
    affectedUserNames: string[] = [];
    deletedUserNames: string[] = [];
    administratorUsers: AdministratorUser[] = [];

    constructor(
        private roleService: RoleService,
        private activeModal: NgbActiveModal,
        private growlService: GrowlService,
        private activationService: UserorgActivationService
    ) {}

    ngOnInit() {
        this.activating = this.action != "deactivate";

        // Modal buttons
        this.primary = {
            text: this.activating ? "Activate" : "Deactivate",
            disabled: true,
            callback: this.initiateRequest.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.roleService
            .getUsersWithPermissions(this.organization.id)
            .pipe(first())
            .subscribe((response: any) => {
                this.loadingAdmins = false;
                let affectedProductIDs = this.buildAffectedProductIDList(this.product);
                let users: any[] = [];
                if (response.users) {
                    users = response.users.sort((a: any, b: any) => {
                        return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                    });
                }
                let administrators: any = {};
                let affectedUsers: any = {};
                let deletedUsers: any = {};

                users.forEach((user) => {
                    // will some users be affected by removing a service?
                    if (!this.activating) {
                        let hasAffectedRole = false;
                        let hasUnaffectedRole = false;
                        user.roles.forEach((role: any) => {
                            if (affectedProductIDs.includes(role.productID)) {
                                hasAffectedRole = true;
                            } else {
                                hasUnaffectedRole = true;
                            }
                        });
                        if (hasAffectedRole) {
                            if (hasUnaffectedRole) {
                                affectedUsers[user.name] = user;
                            } else {
                                deletedUsers[user.name] = user;
                            }
                        }
                    }
                    // is this user an admin for this org?
                    user.permissions.forEach((permission: any) => {
                        if (
                            permission.name == "organization_admin" ||
                            permission.name == "submitter_admin" ||
                            permission.name == "organization_users"
                        ) {
                            administrators[user.name] = user;
                        }
                    });
                });

                // convert to arrays for ngFor
                Object.values(affectedUsers).forEach((user: any) => {
                    this.affectedUserNames.push(user.name);
                });

                Object.values(deletedUsers).forEach((user: any) => {
                    this.deletedUserNames.push(user.name);
                });

                // note that administrators is an object - not array
                Object.values(administrators).forEach((user: any) => {
                    this.administratorUsers.push({
                        name: user.name,
                        id: user.id,
                        email: user.email,
                        selected: true
                    });
                });
            });

        if (this.isSupportedProduct(this.product.productID)) {
            this.supportedProduct = true;
            this.primary.disabled = false;

            if (
                this.product.productID == "post_closing_lender" ||
                this.product.productID == "collaboration_lender" ||
                this.product.productID == "capc"
            ) {
                this.isLenderProduct = true;
            }
        }

        if (!this.activating) {
            this.supportedProduct = true;
            this.primary.disabled = false;

            if (this.isLenderProduct) {
                // TODO: should this be moved to a 'service'?
                /*
                this.loadingLoans = true;
                lenderResource.send('getLoanBucketCounts', {
                    'params': {
                        // We have to pass an array of orgIDs
                        organizationIDs: [this.organization.id], userID: sf.session.userId
                    }
                }, 'capc').then((resp) => {
                    this.loadingLoans = false;
                    let bucketCounts = resp.counts;
                    let totalCount = 0;
                    forEach(bucketCounts, (count) => {
                        if (count && !isNaN(count)) {
                            totalCount += count;
                        }
                    });
                    this.loanCount = totalCount;
                });
                */
            }
        }
    }

    getSelectedAdministratorCount(): number {
        let result = 0;
        this.administratorUsers.forEach((user) => {
            if (user.selected) {
                result++;
            }
        });
        return result;
    }

    handleSelectReason($event: any) {
        let selection: any = $event.$selection;
        this.reasonDetailsRequired = selection.id == "OTHER";
        this.disabledReason = selection;
        this.reasonDetailsArea.nativeElement.focus();
    }

    isSupportedProduct(productID: string) {
        let supportedProducts = this.activationService.getEditableProducts(this.organization.id);
        return supportedProducts.includes(productID);
    }

    addAffectedProduct(product: ProductExtended, productIDs: string[]) {
        productIDs.push(product.productID);
        if (product.childProducts) {
            product.childProducts.forEach((child: ProductExtended) => {
                this.addAffectedProduct(child, productIDs);
            });
        }
    }

    buildAffectedProductIDList(product: ProductExtended) {
        let productIDs: string[] = [];
        this.addAffectedProduct(product, productIDs);
        return productIDs;
    }

    initiateRequest() {
        if (!this.activating && !this.disabledReason) {
            this.growlService.error("Select a reason for deactivating.");
            return;
        }

        if (this.reasonDetailsRequired && !this.reasonDetails) {
            this.growlService.error("Add details about the reason for deactivating.");
            return;
        }

        let notifyAdmins: string[] = [];
        if (this.sendNotification) {
            this.administratorUsers.forEach((user) => {
                if (user.selected) {
                    notifyAdmins.push(user.id);
                }
            });
        }

        this.requestSent = true;

        if (this.activating) {
            this.activationService
                .activateProduct(this.organization, this.product, this.action, false, notifyAdmins, null)
                .subscribe((results: any) => {
                    this.product.status = "ACTIVE";
                    this.activeModal.close(true);
                }, (err: any) => {
                    log.error("error", err);
                    this.requestSent = false;
                });
        } else {
            // let reason = this.disabledReason.label;
            // if (this.disabledReason.id == "OTHER") {
            //     reason = this.reasonDetails;
            // } else if (this.reasonDetails) {
            //     reason += " - " + this.reasonDetails;
            // }
            this.activationService
                .deactivateProduct(this.organization, this.product, notifyAdmins, this.disabledReason.id, this.reasonDetails)
                .subscribe((results: any) => {
                    this.product.status = "DISABLED";
                    this.activeModal.close(true);
                }, (err: any) => {
                    log.error("error", err);
                    this.requestSent = false;
                });
        }
    }

    cancel() {
        this.activeModal.close(false);
    }
}
