import { Injectable } from "@angular/core";
import { getPath, setPath } from "../helpers/object";

@Injectable({
    providedIn: "root"
})
export class LiveConfigService {
    constructor() {}

    get(path: string) {
        return getPath(sf.liveConfig, path, null);
    }

    set(path: string, val: any) {
        return setPath(sf.liveConfig, path, val);
    }
}
