import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenService } from "@sf/userorg/common";
import { UserOrgService } from "@sf/userorg/common";
import { InvitationSharedService } from "../../services/invitation-shared.service";
import { SpinnerService } from "@sf/common";
import { UserorgActivationService } from "@sf/userorg/common";
import { SfValidators } from "@sf/common";
import { takeUntil } from "rxjs/operators";
import { CancelInvitationDialogComponent } from "../../dialogs/cancel-invitation-dialog/cancel-invitation-dialog.component";
import { ConfirmationModalComponent } from "@sf/common";

interface WizardStep {
    title: string;
}

interface SignerData {
    firstName: string;
    middleName?: string;
    lastName: string;
    title: string;
    email: string;
    phone?: string;
    extension?: string;
}

declare const window: any;

@Component({
    selector: "sf-service-nonuser",
    templateUrl: "./service-nonuser.component.html",
    styleUrls: ["./service-nonuser.component.scss"]
})
export class ServiceNonuserComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy: Subject<void>;
    private _userDetails: any = {
        first: "",
        last: "",
        email: ""
    };

    /* Public Variables */
    loaded = false;
    loadFailed: string = null;
    tokenID: string = null;
    currentStep = 0;
    contractID: string = null;
    personalForm: FormGroup;
    formErrorMessages: string[] = [];
    organizationName = "";
    fullName = "";
    signerData: SignerData = null;

    wizardSteps: WizardStep[] = [
        {
            title: "Acceptance Requested"
        },
        {
            title: "Confirm Contact Info"
        },
        {
            title: "Accept Simplifile License Agreement"
        },
        {
            title: "Enrollment Complete"
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private userorgService: UserOrgService,
        private invitationSharedService: InvitationSharedService,
        private spinnerService: SpinnerService,
        private userorgActivationService: UserorgActivationService
    ) {
        this.personalForm = formBuilder.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            title: ["", Validators.required],
            email: ["", [Validators.required, SfValidators.emailValidator]]
            /*
            phone: [
                "",
                [
                    Validators.required, SfValidators.phoneValidator
                ]
            ],
            extension: ["", [Validators.maxLength(9), SfValidators.numericValidator]],
            */
        });
    }

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.preloadForms(this.tokenID);
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    private preloadForms(tokenID: string) {
        this.tokenService.getNonUserServiceInvitation(tokenID).subscribe(
            (invitation: any) => {
                if (invitation) {
                    this.loaded = true;

                    // for chat
                    this._userDetails.first = invitation.firstName;
                    this._userDetails.last = invitation.lastName;
                    this._userDetails.email = invitation.email;

                    this.fullName =
                        invitation.firstName + " " + invitation.lastName;
                    this.organizationName = invitation.orgName;

                    this.contractID = invitation.contractID;

                    this.personalForm.patchValue({
                        firstName: invitation.firstName,
                        lastName: invitation.lastName,
                        title: invitation.title,
                        email: invitation.email
                    });
                }
            },
            () => {
                // error
                this.loadFailed =
                    "Your invitation was not found. Please try clicking again on the link in the email message you received.";
                this.loaded = true;
            }
        );
    }

    goToStep(step: number) {
        // the timeout seems to be required for Edge browser
        window.setTimeout(() => {
            this.currentStep = step;
        }, 10);
    }

    stepClick(step: number) {
        if (this.currentStep > 1) {
            // can't go back
            return;
        }

        if (step == this.currentStep) {
            // staying on same step
            return;
        }

        if (step > this.currentStep) {
            // going forward - need to submit form
            this.submitCurrentForm();
            return;
        }

        // going backward
        this.formErrorMessages = [];
        this.goToStep(step);
    }

    doCancel() {
        const modalRef = this.modalService.open(
            CancelInvitationDialogComponent
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.tokenID = this.tokenID;
        modalRef.result.then(
            (result: any) => {
                if (result) {
                    this.invitationCanceled(result);
                }
            },
            () => {
                // nothing
            }
        );
    }

    invitationCanceled(action: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Invitation Canceled";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1,
            callback: this.goToLogin.bind(this)
        };
        modalInstance.hideSecondary = true;
        modalInstance.message =
            "Thank you. Your invitation has been successfully canceled";
        if (action == "allInvites") {
            modalInstance.message +=
                ", and you will not receive future invitations from Simplifile. You may receive other types of emails from Simplifile.";
        } else {
            modalInstance.message += ".";
        }
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    submitCurrentForm() {
        switch (this.currentStep) {
            case 0:
                this.goToStep(1);
                break;
            case 1:
                this.confirmData();
                break;
            case 2:
                this.goToStep(3);
                break;
            default:
                break;
        }
    }

    confirmData() {
        let firstName: string = this.personalForm.controls.firstName.value;
        //let middleName: string = this.personalForm.controls.middleName.value;
        let lastName: string = this.personalForm.controls.lastName.value;
        let email: string = this.personalForm.controls.email.value;
        let title: string = this.personalForm.controls.title.value;

        this.formErrorMessages =
            this.invitationSharedService.validateNewUserForm(
                this.personalForm,
                true, // title
                false, // phone
                true,
                true,
                true
            );

        if (this.formErrorMessages.length) {
            return;
        }

        this.signerData = {
            firstName: firstName,
            lastName: lastName,
            title: title,
            email: email
            //phone: phone;
            //extension: extension;
        };

        this.spinnerService.startSpinner();
        this.tokenService
            .updateNonUserServiceInvitation(this.tokenID, this.signerData)
            .subscribe(
                () => {
                    this.spinnerService.stopSpinner();
                    this.goToStep(2);
                },
                () => {
                    this.spinnerService.stopSpinner();
                    // go on
                }
            );
    }

    licenseAction(event: string) {
        if (event == "skip") {
            this.goToLogin();
        } else {
            this.goToStep(3);
        }
    }
}
