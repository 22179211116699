import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class StripeService {
    constructor(private _rpc: RpcClientService) {}

    getStripeKey() {
        return this._rpc.makeRequest<string>(
            "payment",
            "getStripePublicKey",
            {}
        );
    }

    getCardSetupIntent(orgID: string) {
        return this._rpc.makeRequest<string>(
            "payment",
            "initiateNewCreditCard",
            { orgID: orgID }
        );
    }

    initializeInvitationLicenseFeePayment(tokenID: string, contractID: string) {
        return this._rpc.makeRequest<string>(
            "payment",
            "initializeInvitationLicenseFeePayment",
            { tokenID: tokenID, contractID: contractID }
        );
    }

    isOrganizationEnabledForCreditCard(orgID: string) {
        return this._rpc.makeRequest<boolean>(
            "payment",
            "isOrganizationEnabledForCreditCard",
            { orgID: orgID }
        );
    }

    cancelPaymentIntent(intentID: string) {
        return this._rpc.makeRequest<boolean>(
            "payment",
            "cancelPaymentIntent",
            { paymentIntentID: intentID }
        );
    }
}
