import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { PrintOptions } from "../interfaces/printOptions.interface";
import { PostViaFormService } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { map, take } from "rxjs/operators";
import { TrayappPrintDialogComponent } from "./trayapp-print-dialog.component";
import { WindowRefService } from "@sf/common";
import { ErecordTrayService } from "../tray-service/erecord-tray.service";

@Injectable({
    providedIn: "root"
})
export class PrintService {
    private _hasPDFViewer = false;

    constructor(
        private _postViaFormService: PostViaFormService,
        private _trayService: ErecordTrayService,
        private _modalService: NgbModal,
        private _windowRef: WindowRefService
    ) {
        try {
            this._hasPDFViewer =
                // @ts-ignore
                !this._windowRef.nativeWindow["bowser"].msedge &&
                // @ts-ignore
                (this._windowRef.nativeWindow["bowser"].firefox ||
                    !!this._windowRef.nativeWindow.navigator.mimeTypes.namedItem(
                        "application/pdf"
                    ));
        } catch (e: any) {}
    }

    printPages(
        pageIDs: string[],
        documentID: string,
        allowAnyRecordedDoc: boolean
    ): Observable<any> {
        let eSignDocumentId;
        let url, parameters;
        parameters = {
            pageIDs: pageIDs,
            documentIDs: [documentID],
            asAttachment: !this._hasPDFViewer,
            allowAnyRecordedDoc: allowAnyRecordedDoc
        };

        let ids = documentID.split(".");
        if (ids.length > 1) {
            if (ids[0] === "esign") {
                // this is an ESignDocument
                url = `/sf/submitter/esign/images/${ids[1]}.pdf?download=${!this
                    ._hasPDFViewer}`;
                eSignDocumentId = ids[1];
            } else if (ids[1] === "esign") {
                // this is an ESignDocument package
                url = `/sf/submitter/esign/images/package/${
                    ids[0]
                }.pdf?download=${!this._hasPDFViewer}`;
                eSignDocumentId = ids[0];
            } else {
                // this is an SF2 document
                url = `/sf/submitter/images/${ids[0]}/${
                    ids[1]
                }.pdf?download=${!this._hasPDFViewer}`;
            }
        } else {
            url = "/sf/services/printDownload/printPages";
        }

        if (this._trayService.usePrint() && eSignDocumentId) {
            return this.trayAppPrint([eSignDocumentId], false);
        } else {
            return this._openPrintPreview(url, parameters);
        }
    }

    trayAppPrint(
        eSignDocumentIds: string[],
        printManifest: boolean
    ): Observable<any> {
        const modalRef = this._modalService.open(TrayappPrintDialogComponent);
        const modalComponent = modalRef.componentInstance;

        return modalComponent.printModeUpdated.asObservable().pipe(
            take(1),
            map((printMode: string) => {
                if (printMode === "trayapp") {
                    const printOptions = {
                        eSignDocumentIDs: eSignDocumentIds.join(","),
                        printManifest: !!printManifest
                    };
                    return this._trayService.print(printOptions);
                } else {
                    return this.printESignDocuments(
                        eSignDocumentIds,
                        printManifest,
                        true
                    );
                }
            })
        );
    }

    printESignDocuments(
        documentIDs: string[],
        printManifest = false,
        bypassTrayService = false
    ): Observable<any> {
        if (this._trayService.usePrint() && !bypassTrayService) {
            this.trayAppPrint(documentIDs, printManifest)?.subscribe();
        } else {
            const parameters = {
                documentIds: documentIDs,
                printManifest: !!printManifest,
                useXhrPost: true
            };

            return this._openPrintPreview(
                `/sf/submitter/esign/images/documents.pdf?download=${!this
                    ._hasPDFViewer}`,
                parameters,
                true
            );
        }
    }

    printESignPackage(packageID: string): Observable<any> {
        const parameters = {
            useXhrPost: true
        };

        return this._openPrintPreview(
            `/sf/submitter/esign/images/package/${packageID}.pdf?download=${!this
                ._hasPDFViewer}`,
            parameters,
            true
        );
    }

    printCoverLetters(documentIDs: string[]) {
        const parameters = {
            documentIds: documentIDs
        };
        return this._openPrintPreview(
            "/sf/submitter/esign/coverLetters.pdf",
            parameters,
            true,
            "coverLetters"
        );
    }

    print(printOptions: PrintOptions): Observable<void> {
        return this._openPrintPreview("/sf/services/printDownload/printPackages", {
            ...printOptions,
            asAttachment: !this._hasPDFViewer
        });
    }

    printStamps(packageIDs: any[]): Observable<void> {
        return this._openPrintPreview(
            "/sf/services/print/generatePDFforStamps",
            {
                packageIDs: packageIDs,
                asAttachment: !this._hasPDFViewer
            }
        );
    }

    printDocumentStamps(documentIds: string[]): Observable<void> {
        return this._openPrintPreview(
            "/sf/services/print/generatePDFforStamps",
            {
                documentIDs: documentIds,
                asAttachment: !this._hasPDFViewer
            }
        );
    }

    printESignBundles(
        submitterId: string,
        bundles: string[]
    ): Observable<void> {
        const parameters = {
            submitterId: submitterId,
            bundles: bundles
        };
        const bundle = bundles[0];
        return this._openPrintPreview(
            "/sf/submitter/esign/bundles.pdf",
            parameters,
            true,
            bundle
        );
    }

    printNotarySealImage(userID: string): Observable<any> {
        return this._openPrintPreview(
            "/sf/submitter/esign/getNotarySealImage.pdf",
            { userID, download: false },
            true
        );
    }

    private _openPrintPreview(
        url: string,
        parameters: any,
        multipart = false,
        myTarget?: string
    ): Observable<void> {
        let target = myTarget ?? "newWindow";
        // Comment from sfPrintService.js:
        // Chrome and Firefox allow us to display a PDF in a new window.
        // I was not able to figure out a way to do the same thing with a PDF viewer in IE
        // Changed this so if we are using a browser with a pdf viewer, it automatically opens
        // it in a new window and if it can it opens the system print dialog.
        // Otherwise it just downloads the file.
        if (this._hasPDFViewer) {
            this._postViaFormService.post(
                url,
                parameters,
                target,
                multipart,
                false
            );
        } else {
            this._postViaFormService.post(
                url,
                parameters,
                null,
                multipart,
                true
            );
        }
        return of();
    }
}
