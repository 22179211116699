import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalsModule } from "@sf/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterModule } from "@angular/router";
import { TrayRelinkModalComponent } from "./components/tray-relink-modal/tray-relink-modal.component";
import { TrayUpdateModalComponent } from "./components/tray-update-modal/tray-update-modal.component";
import { TrayDownloadModalComponent } from "./components/tray-download-modal/tray-download-modal.component";

@NgModule({
    imports: [
        CommonModule,
        ModalsModule,
        NgbModule,
        FontAwesomeModule,
        RouterModule
    ],
    declarations: [
        TrayRelinkModalComponent,
        TrayUpdateModalComponent,
        TrayDownloadModalComponent
    ]
})
export class TrayModule {}
