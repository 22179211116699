import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {
    Navigation,
    NavItem,
    NavListResolver
} from "../interfaces/navigation.interface";
import { Observable } from "rxjs";

export abstract class NavListResolverService implements NavListResolver {
    protected _navigation: Navigation = {
        parent: null,
        items: []
    };

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<Observable<Navigation>>
        | Observable<Navigation>
        | Navigation {
        return this.navigation;
    }

    get navigation(): Navigation {
        return this._navigation;
    }

    set navigation(navigation: Navigation) {
        this._navigation = navigation;
    }
}
