import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ROOT_FIELD_KEY, RootField, ViewState } from "../../interfaces";

import { DynamicFormStore } from "../../services/dynamic-form-store";
import { FormViewerConditions } from "../../services/form-viewer.conditions";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import {
    debounceTime,
    mapTo,
    share,
    switchMap,
    takeUntil,
    withLatestFrom
} from "rxjs/operators";
import { DynamicFormGroup } from "../../controls";

@Component({
    selector: "sf-dynamic-form",
    templateUrl: "./dynamic-form.component.html",
    styleUrls: ["./dynamic-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormViewerConditions, DynamicFormStore]
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
    protected _destroy = new Subject<void>();

    @Input() form: DynamicFormGroup;
    @Input() definition: RootField;
    @Input() externalValue: any;
    @Input() useColumnLayout: boolean;
    @Output() currentFormGroup: EventEmitter<FormGroup> = new EventEmitter();
    @Output() dirtyValuesOnly: EventEmitter<any> = new EventEmitter();
    @Output() visibleValuesOnly: EventEmitter<any> = new EventEmitter();
    private _valuesSubscription: Subscription;

    constructor(
        protected _formFacade: DynamicFormStore,
        protected _conditions: FormViewerConditions
    ) {}

    ngOnInit() {
        this._formFacade.loadFormDefinition({
            definition: this.definition,
            value: this.externalValue
        });
        this.loadConditions();
        this._formFacade.formGroup$
            .pipe(takeUntil(this._destroy))
            .subscribe((formGroup) => this.currentFormGroup.emit(formGroup));
    }

    ngOnDestroy() {
        this._destroy.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition && !changes.definition.isFirstChange()) {
            if (this._valuesSubscription) {
                this._valuesSubscription.unsubscribe();
            }
            this._formFacade.loadFormDefinition({
                definition: this.definition
            });
        }
        if (changes.externalValue) {
            this._formFacade.loadExternalValue(
                changes.externalValue.currentValue
            );
        }
    }

    loadConditions() {
        this._formFacade.conditions$
            .pipe(takeUntil(this._destroy))
            .subscribe((conditions) => this._conditions.load(conditions));
        const formGroup$ = this._formFacade.formGroup$.pipe(
            switchMap((formGroup) =>
                formGroup.valueChanges.pipe(mapTo(formGroup))
            ),
            debounceTime(200),
            share()
        );
        formGroup$.pipe(takeUntil(this._destroy)).subscribe((formGroup) => {
            this.dirtyValuesOnly.emit(this._getDirtyValues(formGroup));
        });
        formGroup$
            .pipe(
                withLatestFrom(this._formFacade.viewState$),
                takeUntil(this._destroy)
            )
            .subscribe(([formGroup, viewState]) => {
                this.visibleValuesOnly.emit(
                    this._getVisibleValues(formGroup, viewState)
                );
            });
    }

    private _getDirtyValues(form: FormGroup | FormArray) {
        let values: any = {};

        if (form instanceof FormArray) {
            values = [];
        }

        for (let key of Object.keys(form.controls)) {
            let control = form.get(key);
            if (control.dirty) {
                if (
                    control instanceof FormGroup ||
                    control instanceof FormArray
                ) {
                    values[key] = this._getDirtyValues(control);
                } else {
                    values[key] = control.value;
                }
            }
        }

        return values;
    }

    private _getVisibleValues(
        control: AbstractControl,
        viewState: ViewState,
        path: string = ROOT_FIELD_KEY
    ): any {
        const parentView = viewState[path];

        if (control instanceof FormArray) {
            const values = [];
            for (let i = 0; i < control.controls.length; i++) {
                const currentPath = parentView.arrayFields[i];
                if (parentView.visibleFieldsMap[currentPath]) {
                    values.push(
                        this._getVisibleValues(
                            control.controls[i],
                            viewState,
                            currentPath
                        )
                    );
                }
            }

            return values;
        } else if (control instanceof FormGroup) {
            let values: any = {};
            for (let key of Object.keys(control.controls)) {
                let currentPath = path + "." + key;
                if (path === ROOT_FIELD_KEY) {
                    currentPath = key;
                }

                if (parentView.visibleFieldsMap[currentPath]) {
                    values[key] = this._getVisibleValues(
                        control.controls[key],
                        viewState,
                        currentPath
                    );
                }
            }

            return values;
        }

        return control.value;
    }
}
