import { Component, OnInit } from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-currency-field",
    templateUrl: "./currency-field.component.html",
    styleUrls: ["./currency-field.component.scss"]
})
export class CurrencyFieldComponent extends InputComponent implements OnInit {
    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
    }
}
