import { Component, Input, OnInit } from "@angular/core";
import { User } from "@sf/userorg/common";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-archive-user-dialog",
    templateUrl: "./archive-user-dialog.component.html",
    styleUrls: ["./archive-user-dialog.component.scss"]
})
export class ArchiveUserDialogComponent implements OnInit {
    @Input() user: User;

    displayName: string;

    primary: ModalButton;
    secondary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Archive",
            disabled: false,
            callback: this.resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        let name = "";
        if (this.user.name.first)
            name += this.user.name.first + " ";
        if (this.user.name.middle)
            name += this.user.name.middle + " ";
        if (this.user.name.last)
            name += this.user.name.last;
        if (this.user.id)
            name += " (" + this.user.id + ")";

        this.displayName = name;

        // Modal buttons
        this.primary = {
            text: "Archive User",
            disabled: false,
            callback: this.resolve.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
    }

    dismiss() {
        this.activeModal.close(false);
    }

    resolve() {
        this.activeModal.close(true);
    }
}
