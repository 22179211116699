<div class="logo-part">
    <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
</div>
<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded && loadFailed">
    <div class="alert alert-warning">
        {{loadFailed}}
    </div>
</div>

<ng-template #tooltipContent>
    <div>{{organizationFullName}}</div>
    <div>{{organizationPhone}}</div>
    <div>{{organizationStreet}}</div>
    <div>{{organizationStreet2}}</div>
    <div>{{organizationCityStateZip}}</div>
</ng-template>

<div *ngIf="loaded && !loadFailed" class="like-table row">
    <!-- LEFT SIDE -->
    <div class="left-side">
        <div class="head-text">
            {{wizardSteps[currentStep].title}}
        </div>
        <div class="sub-head-text">
            <span>
                {{organizationName}}&nbsp;
                <button class="btn btn-link sf-btn-link" [ngbTooltip]="tooltipContent" placement="right"
                        aria-label="Organization details">
                    <fa-icon icon="info-circle"></fa-icon>
                </button>
            </span>
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <div>Please correct the following errors:</div>
            <ul>
                <li *ngFor="let message of formErrorMessages">
                    <span>{{message}}</span>
                </li>
            </ul>
        </div>
        <!-- STEP 0 - sign license -->
        <div *ngIf="currentStep==0">
            <sf-sign-license [contractId]="contractID" (actionTaken)="licenseAction($event)"></sf-sign-license>
        </div>
        <!-- STEP 1 - license fee -->
        <sf-pay-license-fee *ngIf="currentStep==1"
            [contractId]="contractID"
            [tokenID]="tokenID"
            [organizationID]="organizationID"
            [serviceName]="serviceName"
            [licenseFeeAmount]="licenseFeeAmount"
            (actionTaken)="paymentAction($event)">
        </sf-pay-license-fee>
    </div>
    <!-- RIGHT SIDE -->
    <div class="right-side">
        <div>
            <div class="wizard-step" [ngClass]="{'current-step':(currentStep==0), 'done-step':(currentStep>0)}">
                <span (click)="stepClick(0)">
                    <span class="badge">1</span>
                        <span>Accept Agreement</span>
                </span>
            </div>
            <div *ngIf="hasLicenseFee">
                <div class="step-connector"></div>
                <div class="wizard-step" [ngClass]="{'current-step':(currentStep==1), 'done-step':(currentStep>1)}">
                    <span (click)="stepClick(1)">
                        <span class="badge">2</span>
                        <span>License Fee</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="chat">
            <div class="titler">Questions?</div>
            <div>Call us at <a href="tel:800.460.5657">800.460.5657</a> and press option 3.</div>
            <div>We are available from 6 a.m. to 6 p.m. MT.</div>
        </div>
    </div>
</div>
