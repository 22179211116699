export enum ContractType {
    MSA = "MSA",
    SERVICE_AMENDMENT = "SERVICE_AMENDMENT",
    NAME_CHANGE_AMENDMENT = "NAME_CHANGE_AMENDMENT",
    ERECORDING = "ERECORDING",
    PAPER_RECORDING = "PAPER_RECORDING",
    SETTLEMENT = "SETTLEMENT",
    LENDER = "LENDER",
    DOCUMENT_BUILDER = "DOCUMENT_BUILDER",
    TRUSTEE = "TRUSTEE",
    LENDER_VENDOR = "LENDER_VENDOR",
    RECIPIENT_VENDOR = "RECIPIENT_VENDOR",
    SUBMITTER_VENDOR = "SUBMITTER_VENDOR",
    NOTARY_VENDOR = "NOTARY_VENDOR",
    ESIGN_EVENTS_VENDOR = "ESIGN_EVENTS_VENDOR",
    ESIGN_EVENT = "ESIGN_EVENT",
    DOC_BUILDER_RON = "DOC_BUILDER_RON",
    LIEN_RELEASE = "LIEN_RELEASE",
    SUBSCRIPTION = "SUBSCRIPTION"
}
