<div class="input-group" [formGroup]="dateForm" [ngClass]="{'focused':focused}">
    <input type="text" class="form-control dp-input"
        #dateInput
        formControlName="date"
        [id]="inputId"
        ngbDatepicker
        #datePicker="ngbDatepicker"
        [autoClose]="highlightMode ? 'outside' : true"
        [displayMonths]="highlightMode ? 2 : 1"
        [dayTemplate]="selectedDayTemplate"
        [startDate]="startDate"
        [markDisabled]="isDateDisabled"
        (blur)="onBlur()"
        (focus)="onFocus()"
        [minDate]="minDateParsed"
        [maxDate]="maxDateParsed"
        [container]="container"
        [tabindex]="tabindex"
        [firstDayOfWeek]="7"
        [placeholder]="placeholder"
        autocomplete="none"
        [name]="datePickerName"
        (dateSelect)="highlightMode ? setHighlightRange($event) : null"
        attr.aria-describedby="{{inputId}}-description"
    />
    <div class="input-group-append">
        <fa-icon [icon]="['far','times']"
             *ngIf="!!dateForm.controls.date && !disabled"
             [fixedWidth]="true"
             class="clear-date"
             (click)="clearDate()"
             title="Clear date"
         ></fa-icon>
        <button class="btn btn-secondary hide-calendar" type="button" (click)="togglePicker()" (blur)="onBlur()" [disabled]="disabled" aria-label="Choose Date">
            <fa-icon [icon]="['far','calendar-alt']"
                 class="hide-calendar"
            ></fa-icon>
        </button>
    </div>
    <span id="{{inputId}}-description" [hidden]="true" aria-hidden="true">date format: month, day, year</span>
</div>

<ng-template
    #standardDayTemplate
    let-date="date"
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">
    <span class="custom-day"
        [class.focused]="focused"
        [class.selected]="selected"
        [class.not-current-month]="date.month !== currentMonth"
        [class.day-disabled]="disabled">
        {{ date.day }}
    </span>
</ng-template>

<ng-template
    #highlightRangeDayTemplate
    let-date="date"
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">
    <span class="custom-day-highlight"
          [class.focused]="focused"
          [class.selected]="selected"
          [class.not-current-month]="date.month !== currentMonth"
          [class.day-disabled]="disabled"
          [class.faded]="isHovered(date) || isInside(date)"
          [class.range-start]="isRangeStart(date)"
          [class.range-end]="isRangeEnd(date)"
          (mouseenter)="setHoverDate(date)"
          (mouseleave)="setHoverDate(null)">
        {{ date.day }}
    </span>
</ng-template>
