import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BannerNotification } from "../interfaces/banner-notification";
import { BannerNotificationCategory } from "../enums/banner-notification-category.enum";

/**
 * This service is mostly used by NotificationBannerComponent.
 * Other components can use the recheckCategory or removeBannerOfCategory methods below.
 */
// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class BannerNotificationService {
    private bannersAddQueueSubject$ = new Subject<BannerNotification>();
    private bannersRemoveQueueSubject$ = new Subject<BannerNotificationCategory>();
    private bannerCategoryCheckedSubject$ = new Subject<BannerNotificationCategory>();
    private categoryRecheckQueueSubject$ = new Subject<BannerNotificationCategory>();
    private bannerProgressBar$ = new Subject<number>()

    private activeBanners: BannerNotification[] = [];
    private categoriesChecked: BannerNotificationCategory[] = [];

    // only the NotificationBanner component uses these directly
    public bannerAddQueue = this.bannersAddQueueSubject$.asObservable();
    public bannerRemoveQueue = this.bannersRemoveQueueSubject$.asObservable();

    public bannerCategoryChecked = this.bannerCategoryCheckedSubject$.asObservable();
    public bannerRecheckQueue = this.categoryRecheckQueueSubject$.asObservable();
    public bannerProgressBar = this.bannerProgressBar$.asObservable();

    constructor() {
    }

    /**
     * Add a notification banner to the top of the page
     */
    push(notification: BannerNotification) {
        this.bannersAddQueueSubject$.next(notification);

        this.activeBanners.push(notification);
    }

    /**
     * Call this if a change was made that maybe should require a banner to be removed.
     * Like maybe you added a payment account or signed a contract.
     * It will re-run the checks and remove the banner if it should be removed.
     */
    recheckCategory(category: BannerNotificationCategory) {
        return this.categoryRecheckQueueSubject$.next(category);
    }

    /**
     * remove a notification banner
     * Call this if you're sure a banner should be removed.
     * Like if you added a payment account or signed a contract.
     */
    removeBannerOfCategory(category: BannerNotificationCategory) {
        this.bannersRemoveQueueSubject$.next(category);

        let index = this.findBannerOfCategory(category);
        if (index > -1) {
            this.activeBanners.splice(index, 1);
        }
    }

    findBannerOfCategory(category: BannerNotificationCategory): number {
        let index: number = this.activeBanners.findIndex((banner) => {
            return banner.category == category;
        });
        return index;
    }

    getAllBanners(): BannerNotification[] {
        return this.activeBanners;
    }

    markCategoryChecked(category: BannerNotificationCategory) {
        this.categoriesChecked.push(category);
        this.bannerCategoryCheckedSubject$.next(category);
    }

    wasCategoryChecked(category: BannerNotificationCategory): boolean {
        return this.categoriesChecked.includes(category);
    }

    updateBannerProgressBar(value: number) {
        this.bannerProgressBar$.next(value);
    }
}
