import { Component, Inject, OnInit } from "@angular/core";
import {
    APP_BOOTSTRAP_SERVICE,
    AppBootstrapService,
    SocketService,
    Auth,
    dayjs
} from "@sf/common";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, switchMap, take } from "rxjs/operators";
import { RecipientRpcService } from "@sf/recipient/common";
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from "@angular/forms";

const softAuthUrl = "/sf/services/auth/login";

enum DenialReason {
    SIGNATURE = "Signature not clear/missing",
    ORGANIZATION_NAME = "Organization name does not match legal name of organization",
    MOU_VERSION = "Incorrect MOU version",
    OTHER = "Other"
}

const otherDenialReasonValidator: ValidatorFn = (fg: FormGroup) => {
    return fg.value.denialReason === DenialReason.OTHER
        ? Validators.required(fg.get("otherDenialReason"))
        : null;
};

@Component({
    selector: "sf-mou-authentication",
    templateUrl: "./mou-authentication.component.html",
    styleUrls: ["./mou-authentication.component.scss"]
})
export class MouAuthenticationComponent implements OnInit {
    errorMessage: string;
    showApprovalDialog = false;
    showDenial = false;
    needsApproval: boolean;
    mouDocumentID: string;
    approvalUser: string;
    approvalStatus: string;
    approvalDate: string;
    approvalAction: string;
    submitterName: string;
    denialReasons: string[];
    denialReasonEnum: typeof DenialReason = DenialReason;
    denialForm = new FormGroup(
        {
            denialReason: new FormControl(null, Validators.required),
            otherDenialReason: new FormControl(null)
        },
        { validators: [otherDenialReasonValidator] }
    );

    constructor(
        @Inject(APP_BOOTSTRAP_SERVICE)
        private _appBootstrap: AppBootstrapService,
        private _http: HttpClient,
        private _route: ActivatedRoute,
        private _router: Router,
        private _socketService: SocketService,
        private _recipientRpcService: RecipientRpcService
    ) {}

    ngOnInit(): void {
        const params = this._route.snapshot.queryParamMap;
        // const token = params.keys[0]; // token as first key
        const token = params.get("token"); // token as a value
        this.approvalAction = params.get("action");

        if (token) {
            const data = { tokenID: token };
            this._http.post(softAuthUrl, data).subscribe({
                next: (data: any) => {
                    // Copied this from login.component.ts
                    this._socketService.authMessages
                        .pipe(
                            filter((auth: Auth) => auth.data.authenticated),
                            switchMap((auth: Auth) =>
                                this._appBootstrap.bootstrapAfterLogin(auth)
                            ),
                            take(1)
                        )
                        .subscribe(() => {
                            this._handleMOUApproval(data.mouPackageID);
                        });
                    this._socketService.reconnect();
                },
                error: (data) => (this.errorMessage = data.error.errorMessage)
            });
        } else {
            this.errorMessage = "No authorization token.";
        }
    }

    confirmDenial() {
        this.denialReasons =
            this.denialForm.value.denialReason === this.denialReasonEnum.OTHER
                ? [this.denialForm.value.otherDenialReason]
                : [this.denialForm.value.denialReason];
        this._recipientRpcService
            .rejectDocument(
                "SOFT_AUTH_MOU",
                this.mouDocumentID,
                this.denialReasons,
                "MOU122"
            )
            .subscribe(() => {
                this.showDenial = true;
            });
    }

    private _handleMOUApproval(mouPackageID: string) {
        this._recipientRpcService
            .mouNeedsSoftAuthReview(mouPackageID)
            .subscribe((data: any) => {
                this.mouDocumentID = data.mouDocumentID;
                this.needsApproval = data.needsApproval;
                if (data.needsApproval) {
                    this.submitterName = data.submitterName;
                    if (this.approvalAction === "approve") {
                        this._recipientRpcService
                            .acceptDocument("REVIEW", data.mouDocumentID)
                            .subscribe(() => {
                                this.showApprovalDialog = true;
                            });
                    } else {
                        this.showApprovalDialog = true;
                    }
                } else {
                    this.approvalUser = data.user;
                    this.approvalStatus =
                        data.status === "EVENT_ACCEPTED"
                            ? "approved"
                            : "denied";
                    this.approvalDate = dayjs(data.timestamp).format(
                        "MM/DD/YYYY"
                    );
                    this.showApprovalDialog = true;
                }
            });
    }
}
