import { Component, Input, OnInit } from "@angular/core";
import { PostViaFormService, SessionService } from "@sf/common";
import { GrowlService } from "@sf/common";
import { dayjs } from "@sf/common";
import { ModalButton } from "@sf/common";
import { OpenDocumentService } from "@sf/common";
import {
    PackageStatusDetails,
    PackageStatusErrorEvent,
    PackageStatusValidationError,
    PackageStatusVoidedEvent
} from "../../interfaces/package-status-details.interface";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganizationService } from "@sf/userorg/common";
import { SharedDocStatusConstants } from "../../enums/shared-doc-status-constants.enum";
import { SubmitterPackagesService } from "../../services/submitter-packages.service";
import { SubmitterPackageRemoteIdService } from "../../services/submitter-package-remote-id.service";
import { RejectionSuggestion } from "../../interfaces/rejection-suggestion";
import { SubmitterDocument } from "../../interfaces/submitter-document.interface";
import { DocumentRejectionSuggestionService } from "../../services/document-rejection-suggestion.service";
import { PreChatDialogComponent } from "@sf/chat";

interface PackageDetails {
    id: string;
    statusDate: number;
    recipientID: string;
}

@Component({
    selector: "sf-submitter-package-status-rejected-dialog",
    templateUrl: "./submitter-package-status-rejected-dialog.component.html",
    styleUrls: ["./submitter-package-status-rejected-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class SubmitterPackageStatusRejectedDialogComponent implements OnInit {
    private PACKAGE_STATUS = SharedDocStatusConstants;
    @Input()
    package: PackageDetails;
    @Input()
    statusDetails: PackageStatusDetails;
    @Input()
    documentID?: string;

    singleDocumentID: any = false;
    title: any = null;
    statusMessage: any = null;
    documents: any[] = [];
    documentsAll: any[] = [];
    hasErrors: any;
    packageIsReleaseToRecord: boolean;
    packageStatusString: any;
    packageStatus: any;
    packageState: any;
    primary: ModalButton;
    secondary: ModalButton;
    remoteId: string;
    usesCustomForms: boolean;
    customRejectionInstructions: string[];
    useStatusIcons: boolean =
        sf.liveConfig.SubmitterMenuSettings.useStatusIcons;

    constructor(
        private _submitterPackagesService: SubmitterPackagesService,
        private _postViaFormService: PostViaFormService,
        private _growlService: GrowlService,
        private _openDocumentService: OpenDocumentService,
        private _modalService: NgbModal,
        private _documentRejectionSuggestionService: DocumentRejectionSuggestionService,
        private _organizationService: OrganizationService,
        private _sessionService: SessionService
    ) {}

    ngOnInit(): void {
        this.primary = {
            text: "OK",
            disabled: false
        };
        this.secondary = {
            text: "Print",
            callback: (): void => {
                this._print();
            }
        };

        this.usesCustomForms = this.statusDetails.usesCustomForms;

        let documents: any = {};
        let hasErrors = false;
        let combined: PackageStatusErrorEvent[] = [
            ...this.statusDetails.validationErrors,
            ...this.statusDetails.voidedEvents
        ].filter(Boolean);
        combined.forEach((item: PackageStatusErrorEvent) => {
            if (!documents[item.id]) {
                documents[item.id] = {};
            }
            // item.uiState = "submitter.package.docViewerRedirect";
            // item.stateParams = {
            //     packageID: this.package.id, // This is necessary for the submitter.package state
            //     documentID: item.id,
            //     usesCustomForms: usesCustomForms
            // };
            Object.assign({}, documents[item.id], item);

            if (
                (item as PackageStatusValidationError).validationErrors
                    ?.length ||
                (item as PackageStatusVoidedEvent).voidedEvents?.length
            ) {
                hasErrors = true;
                // TODO: This doesn't look like it was finished. We are not using uiState or stateParam
                // TODO: properties in the migrated component, but we probably still want to be implementing
                // TODO: the same behavior so we are routing correctly.
                // if (
                //     [
                //         this.PACKAGE_STATUS.DATA_ENTRY,
                //         this.PACKAGE_STATUS.DATA_REVIEW,
                //         this.PACKAGE_STATUS.SIGN
                //     ].indexOf(item.status) != -1
                // ) {
                //     let document = documents[item.id];
                //     if (item.isCustomFormsTemplate) {
                //         // ESignDocument created from a template - go to custom forms data entry page
                //         document.uiState = "data-entry.data-entry";
                //         document.stateParams = {
                //             documentId: item.eSignDocumentID,
                //             packageId: "esign"
                //         };
                //     } else {
                //         // Either a print driver doc or a quail template doc - go to package details
                //         document.uiState = "submitter.package.details";
                //         document.stateParams = { packageID: this.package.id };
                //     }
                // }
            }
        });

        this.singleDocumentID = !!this.documentID;
        let filtered = combined.filter((doc) => doc.id === this.documentID);

        this.documents = !this.singleDocumentID ? combined : filtered;
        this.documentsAll = combined;
        this.packageState = this.statusDetails.packageState;
        this.packageStatus = this.statusDetails.packageStatus;
        this.packageStatusString = this.statusDetails.packageStatusString;
        this.packageIsReleaseToRecord =
            this.statusDetails.packageIsReleaseToRecord;
        this.title = this._getTitle(
            this.packageState,
            this.packageStatusString,
            this.packageIsReleaseToRecord
        );
        this.statusMessage = this.getStatusMessage(
            this.packageState,
            this.packageIsReleaseToRecord
        );
        // if the package is in READY but not released to record, then usually, the only errors on documents
        // are "The package has not been marked with the "Release to Record" option", which we should suppress.
        // We don't remove it from PackageHandler.java because other places still rely on that method of validation
        const hideErrors =
            !this.packageIsReleaseToRecord &&
            this.packageStatus === this.PACKAGE_STATUS.READY;
        this.hasErrors = hasErrors && !hideErrors;
        this.remoteId = SubmitterPackageRemoteIdService.getRemoteId(
            this.statusDetails
        );

        for (const doc of this.documents) {
            doc.rejectionSuggests$ =
                this._documentRejectionSuggestionService.getSuggestionsForDocumentRejections(
                    doc.id,
                    doc.validationErrors
                );
        }
        for (const doc of this.documents) {
            doc.rejectionSuggests$.subscribe((suggestion: any) => {
                for (const item of suggestion) {
                    this._documentRejectionSuggestionService
                        .saveSuggestionRejectionFeedbackSeen(
                            item.rejectionReasonID,
                            item.suggestionID,
                            true
                        )
                        .subscribe((r: any) => {});
                }
            });
        }

        this._organizationService
            .getCustomProperty(
                this.package.recipientID,
                "customRejectionInstructions"
            )
            .subscribe((data: any) => {
                if (data) {
                    this.customRejectionInstructions = data.split("|");
                }
            });
    }

    _shouldAllowPrint(packageStatus: any) {
        let printableStatuses = [
            this.PACKAGE_STATUS.DRAFT,
            this.PACKAGE_STATUS.REJECTED,
            this.PACKAGE_STATUS.BILLED_REJECTED
        ];
        if (printableStatuses.indexOf(packageStatus) > -1) {
            return true;
        } else {
            return false;
        }
    }

    sendFeedback(suggestion: RejectionSuggestion, feedback: string) {
        this._documentRejectionSuggestionService
            .saveSuggestionRejectionFeedback(
                suggestion.rejectionReasonID,
                suggestion.suggestionID,
                feedback
            )
            .subscribe(
                () => {
                    this._growlService.success(
                        "Your feedback has been recorded."
                    );
                    suggestion.feedbackRecorded = true;
                },
                () => {
                    this._growlService.error(
                        "Unable to record your feedback at this time. Please try again later"
                    );
                }
            );
    }

    getStatusMessage(packageStatus: any, releaseToRecord: boolean) {
        let statusMessages = [
            {
                status: this.PACKAGE_STATUS.DATA_ENTRY,
                message: "Data entry is incomplete."
            },
            {
                status: this.PACKAGE_STATUS.SIGN,
                message: "Document has not been signed by all required parties."
            },
            {
                status: this.PACKAGE_STATUS.DATA_REVIEW,
                message:
                    "The document must be removed from the review folder before it is eligible to be submitted"
            },
            {
                status: this.PACKAGE_STATUS.DRAFT,
                message:
                    "One or more documents in the package are not ready for submitting. All packages start in this state until all the documents are ready for submission."
            },
            {
                status: this.PACKAGE_STATUS.CONVERTED,
                message:
                    "The package contains helper documents that have not been completed."
            },
            {
                status: this.PACKAGE_STATUS.READY,
                message: "The package is ready to be submitted to the county."
            },
            {
                status: this.PACKAGE_STATUS.SENT,
                message: "The package has been sent to the county for review."
            },
            {
                status: this.PACKAGE_STATUS.SENT_REMOTE,
                message: "The package has been sent to the county for review."
            },
            {
                status: this.PACKAGE_STATUS.RECEIVED,
                message:
                    "The package has been received by the county and will be reviewed shortly."
            },
            {
                status: this.PACKAGE_STATUS.REVIEWING,
                message: "The package is being reviewed by the county."
            },
            {
                status: this.PACKAGE_STATUS.ACCEPTED,
                message:
                    "The package has been accepted by the county and is awaiting review and/or recording."
            },
            {
                status: this.PACKAGE_STATUS.REJECTED,
                message: "Please make corrections and re-submit."
            },
            {
                status: this.PACKAGE_STATUS.BILLED_REJECTED,
                message:
                    "This package has been rejected and has a rejection fee."
            },
            {
                status: this.PACKAGE_STATUS.RECORDED,
                message:
                    "The package has been recorded by the county and returned to Simplifile."
            },
            {
                status: this.PACKAGE_STATUS.MAINTENANCE,
                message:
                    "The package has encountered an error and is being worked on by Simplifile staff."
            },
            {
                status: this.PACKAGE_STATUS.VOIDED,
                message: "The package has been voided."
            },
            {
                status: this.PACKAGE_STATUS.UNKNOWN,
                message: "The status of this package is unknown."
            },
            {
                status: this.PACKAGE_STATUS.FINALIZING,
                message:
                    "The package has been recorded by the county but not returned to Simplifile yet. This may happen because the stamps have not been applied to the documents by the county yet or because the fees have not been calculated by the county. Typically packages will only stay in this state for 24 to 48 hours."
            }
        ];
        let statusMessage: string = statusMessages.find(
            (msg) => msg.status === packageStatus
        )?.message;
        if (!releaseToRecord && packageStatus === this.PACKAGE_STATUS.READY) {
            statusMessage =
                "This package can be submitted to the county once it is released for recording by an authorized user.";
        }
        return statusMessage;
    }

    _getTitle(
        packageStatus: any,
        packageStatusString: any,
        releaseToRecord: boolean
    ) {
        switch (packageStatus) {
            case this.PACKAGE_STATUS.DRAFT:
            case this.PACKAGE_STATUS.REJECTED:
                return "Rejected";
            case this.PACKAGE_STATUS.BILLED_REJECTED:
                return "Rejected Fee";
            case this.PACKAGE_STATUS.RECORDED:
                return packageStatusString;
            case this.PACKAGE_STATUS.READY:
                if (!releaseToRecord) {
                    return "Ready when released for recording";
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            default:
                return packageStatus;
        }
    }

    viewReport() {
        let target = "html";
        let asDownload = false;

        let reportOptions = {
            packageID: this.package.id,
            reportType: "package_summary",
            startDate: dayjs
                .unix(this.package.statusDate / 1000)
                .format("MM-DD-YYYY")
        };
        this._postViaFormService.post(
            "/sf/services/reports/generateReport",
            reportOptions,
            target,
            false,
            asDownload
        );
    }

    _print() {
        let dialogContents = document.getElementById(
            "sf-submitter-rejected-status-dialog"
        );
        dialogContents.querySelectorAll("a").forEach((anchor) => {
            anchor.removeAttribute("href");
        });

        let css =
            '* {font-family: "Verdana", sans-serif; font-size: 15px;} .section { margin-top: 15px;} .document-info {display: flex;}' +
            ".doc-status-col {\n" +
            "        margin-left: 8px;\n" +
            "        margin-right:8px;\n" +
            "        padding-left: 20px;\n" +
            "    }" +
            ".font-14 {\n" +
            "        font-size: 14px;\n" +
            "    }" +
            ".second-row-text-indent {\n" +
            "        padding-left: 20px; text-indent: -20px;\n" +
            "    }" +
            ".doc-name-title {\n" +
            "        margin-bottom: 0;\n" +
            "        margin-top: 0;\n" +
            "    }" +
            ".doc-type {\n" +
            "        margin-top: 4px;\n" +
            "        margin-bottom: 8px;\n" +
            "    }" +
            "sf-pill {" +
            "display: block;" +
            "border: 1px solid black;" +
            "border-radius: 20px;" +
            "padding: 5px;" +
            "}" +
            ".sf-submitter-rejected-status-dialog {max-width: 500px;}";
        let style = document.createElement("style");
        style.appendChild(document.createTextNode(css));

        let printEl = document.createElement("div");
        printEl.appendChild(style);
        printEl.appendChild(dialogContents.cloneNode(true));

        let newWindow = window.open("", "_blank");
        newWindow.document.write(printEl.innerHTML);
        newWindow.document.close();
        newWindow.print();
    }

    jsonStringify(data: any) {
        return JSON.stringify(data);
    }

    openDocument(document: SubmitterDocument): void {
        this.closeModal();
        this._openDocumentService.openDocument(document.id);
    }

    closeModal(): void {
        this._modalService.dismissAll();
    }

    openChatModal() {
        const modalRef = this._modalService.open(PreChatDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        // pass the organization Ids for the user
        modalInstance.userOrganizationIDs = this._sessionService.getOrgIds();

        // pass the username and orgID
        modalInstance.username = this._sessionService.getUsername();
        modalInstance.organizationID =
            this._sessionService.getDefaultOrganizationID();
        modalInstance.isRecipient =
            this._sessionService.hasProductInAnyOrg("recipient");
        modalInstance.initUrl = window.location.href;
    }
}
