import { Component, TemplateRef } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
    selector: "sf-template-renderer",
    template: `
        <ng-container
            *ngTemplateOutlet="template; context: templateContext"
        ></ng-container>
    `
})
export class TemplateRendererComponent implements ICellRendererAngularComp {
    template: TemplateRef<any>;
    templateContext: { $implicit: any; params: any };

    refresh(params: any): boolean {
        this.templateContext = {
            $implicit: params.data,
            params: params
        };
        return true;
    }

    agInit(params: ICellRendererParams): void {
        // @ts-ignore
        this.template = params["ngTemplate"];
        this.refresh(params);
    }
}
