import {
    ConfirmationModalComponent,
    dayjs,
    getPath,
    GrowlService,
    SessionService
} from "@sf/common";
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import "dayjs/plugin/relativeTime";
import { LockoutService } from "../../services/lockout.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenService } from "../../services/token.service";
import { UserOrgService } from "../../services/user-org.service";
import { UserSubscriptionService } from "../../services/user-subscription.service";
import { User } from "../../interfaces/user.interface";
import { Organization } from "../../interfaces/organization.interface";
import { first, sampleTime, takeUntil } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import { UserSecurityEnhanced } from "../../interfaces/user-security-enhanced.interface";
import { CurrentUserService } from "../../services/current-user.service";
import { Subject, Subscription } from "rxjs";

interface UserExt extends User {
    lastLoginAttempt?: any;
    lastSuccessfulLogin?: any;
    lastLoginAttemptDayjs?: string;
    lastSuccessfulLoginDayjs?: string;
    organizations?: any[];
    attempts?: number;
    isLockedOut: boolean;
    isSuspended: boolean;
}

const sortBy = (key: any) => {
    return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

// prettier-ignore
@Component({
    selector: "sf-user-security",
    templateUrl: "./user-security.component.html",
    styleUrls: ["./user-security.component.scss"]
})
export class UserSecurityComponent implements OnInit, OnChanges, OnDestroy {
    @Input() username: string;
    @Input() isLoggedIn: boolean;
    @Input() loggingOut: boolean;
    @Input() userSecurityEnhanced: UserSecurityEnhanced;

    /** Private Vars **/
    private _orgConfigs: any;
    private _onDestroy$: Subject<void> = new Subject<void>();
    private updater: Subscription = null;

    /** Public Vars **/
    user: UserExt = null;
    isSuperUser = false;
    canSuspend = false;
    allSubmitterOrgs: Organization[] = [];
    selected: any = {
        defaultOrg: {
            id: null
        }
    };
    mustChangePassword = false; // FORCE_PASSWORD_CHANGE
    externalEmployeeID: string; // EXTERNAL_EMPLOYEE_ID
    loginCount = 0;
    firstTrackedLoginDaysAgo = 0;
    userCanLogin: boolean = false;
    showExternalID: boolean = false;
    showLastAttempt: boolean = false;
    showLastLogin: boolean = false;
    dataLoaded: boolean = false;

    constructor(
            //private encryptionService: EncryptionService,
            //private logger: LoggerService,
            private sessionService: SessionService,
            private userOrgService: UserOrgService,
            private userLockoutService: LockoutService,
            private userService: UserService,
            private currentUserService: CurrentUserService,
            private growlService: GrowlService,
            private userOrgTokenService: TokenService,
            private modalService: NgbModal,
            private route: ActivatedRoute,
            private router: Router,
            private userSubscriptionService: UserSubscriptionService
    ) {}

    ngOnInit() {
        //this.logger.debug("user-security: ngOnInit starting");
        //subscribe to user unlock event
        this.userLockoutService.userUnlocked$.subscribe(
            (userUnlocked: boolean) => {
                if (userUnlocked) {
                    this._successfulUnlock();
                }
            }
        );
        this.isSuperUser =
            this.sessionService.isSuperUser() &&
            !this.sessionService.isLoggedInAs();
        this.canSuspend = this.sessionService.hasPermission("admin_suspend_user", "SIMPFL");

        this._reload();
        this.subscribeToChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.username && !changes.username.firstChange) {
            this.username = changes.username.currentValue;
            //this.logger.debug("user-security: User changed to " + this.username);
            this._reload();
            this.subscribeToChanges();
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new user
            this.updater.unsubscribe();
        }
        this.updater = this.userSubscriptionService.subscribeToUpdates(this.username)
            .pipe(
                    takeUntil(this._onDestroy$),
                    sampleTime(5000)
            )
            .subscribe((msg: any) => {
                //this.logger.debug("User-security: Realtime update to " + this.username);
                this._reload();
            });
    }

    private _reload() {
        //reset flags
        this.showExternalID = false;
        this.selected.defaultOrg.id = null;
        this.dataLoaded = false;
        this._orgConfigs = [];
        this.user = null;
        this.allSubmitterOrgs = [];

        this.userOrgService.checkUserCanLogin(this.username)
            .pipe(first())
            .subscribe((result: boolean) => {
                this.userCanLogin = result;
            });
        this.userOrgService.getAllUserSettings(this.username)
            .pipe(first())
            .subscribe((settings: any) => {
                this.mustChangePassword = getPath(settings, "FORCE_PASSWORD_CHANGE");
                this.externalEmployeeID = getPath(settings, "EXTERNAL_EMPLOYEE_ID");
            });
        this.userOrgService.getRecentLoginCount(this.username, 30)
            .pipe(first())
            .subscribe((result: any) => {
                this.loginCount = result.count;
                this.firstTrackedLoginDaysAgo = result.firstDaysAgo;
            });

        // default organization
        this.userOrgService.getUserDefaultOrganization(this.username)
            .subscribe((orgID: string) => {
                this.userOrgService.getAllSubmitterOrganizationsForUser(this.username)
                    .subscribe((orgList: Organization[]) => {
                        if (orgList) {
                            orgList = orgList.concat().sort(sortBy("name"));
                            orgList.forEach((org) => {
                                if (org.id === orgID) {
                                    this.selected.defaultOrg = org;
                                }
                                if (org.isEnabled) {
                                    this.allSubmitterOrgs.push(org);
                                }
                            });
                        }
                    });
            });

        this._loadUser();
    }

    private _loadUser(): void {
        this.userOrgService.getUser(this.username).subscribe(
            (user: UserExt) => {
                if (user) {
                    this.userOrgService.buildUserFullName(user);

                    this.showLastAttempt = false;
                    let lastAttemptDayjs: dayjs.Dayjs = null;
                    if (user.lastLoginAttempt) {
                        lastAttemptDayjs = dayjs(user.lastLoginAttempt);
                        this.showLastAttempt = true;
                        user.lastLoginAttemptDayjs = lastAttemptDayjs.fromNow();
                    }
                    this.showLastLogin = false;
                    let lastLoginDayjs: dayjs.Dayjs = null;
                    if (user.lastSuccessfulLogin) {
                        lastLoginDayjs = dayjs(user.lastSuccessfulLogin);
                        this.showLastLogin = true;
                        user.lastSuccessfulLoginDayjs = lastLoginDayjs.fromNow();
                    }
                    if (lastAttemptDayjs && lastLoginDayjs) {
                        // if last attempt date is the same as the last login date, then don't show attempt date
                        if (
                            lastAttemptDayjs.diff(
                                lastLoginDayjs,
                                "minutes"
                            ) == 0
                        ) {
                            this.showLastAttempt = false;
                        }
                    }

                    this.userOrgService.buildUserStatus(user);
                    this.user = user;
                    this.currentUserService.updateCurrentUser(this.user);   // update header

                    // any org using 'external system'?
                    let orgIDs: string[] = [];
                    if (user.organizations) {
                        this.user.organizations.forEach((org) => {
                            orgIDs.push(org.id);
                        });
                    }
                    this.userOrgService.getOrganizationConfigurations(orgIDs)
                        .subscribe((orgConfigs: any) => {
                            this._orgConfigs = orgConfigs;
                            orgIDs.forEach((orgID) => {
                                if (this._orgConfigs[orgID]["External Systems"].enable_external_employee_id.value === "true") {
                                    this.showExternalID = true;
                                }
                            });
                        });
                }
                this.dataLoaded = true;
            },
            () => {
                this.dataLoaded = true;
            }
        );
    }

    private _externalEmployeeIDValid(): boolean {
        let valid = true;
        this.user.organizations.forEach((org) => {
            if (this._orgConfigs[org.id]["External Systems"].enable_external_employee_id.value == "true") {
                let regex = this._orgConfigs[org.id]["External Systems"].external_employee_id_regex.value;
                if (regex) {
                    try {
                        let expr = new RegExp(regex);
                        if (!expr.test(this.externalEmployeeID)) {
                            this.growlService.error(
                                    "External employee ID doesn't match regular expression: " + regex + " - " + org.id,
                                    null, { timeOut: 0 });
                            valid = false;
                        }
                    }
                    catch (e) {
                        //don't care if regex is invalid
                    }
                }
            }
        });

        return valid;
    }

    private _successfulUnlock(): void {
        this.user.isLockedOut = false;
        this.user.attempts = 0;
    }

    /** PUBLIC FUNCTIONS **/

    askLogUserOut() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = "Log User Out";
        modalInstance.message = "This will log out user '<b>" + this.username + "</b>'. Are you sure you want to do this?";

        modalInstance.primary = {
            text: "Log Out",
            callback: () => {
                modalRef.close();
                this.logUserOut();
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Cancel",
            callback: () => {
                modalRef.close();
            }
        };
    }

    logUserOut() {
        if (this.userSecurityEnhanced) {
            this.userSecurityEnhanced.logUserOut();
        }
    }

    suspendUser() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = "Suspend User";
        modalInstance.message = "Are you sure you want to suspend <b>" + this.user.firstLastName + " (" + this.user.id + ")" + "</b>?" +
            "<br/><br/>Suspending the user will keep all roles intact, but the user will not be able to log in until they are unsuspended.";

        modalInstance.primary = {
            text: "Suspend",
            callback: () => {
                modalRef.close();
                this.userService.suspendUser(this.username).subscribe((result: boolean) => {
                    this._reload();
                    this.growlService.success("User successfully suspended.");
                });
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Cancel",
            callback: () => {
                modalRef.close();
            }
        };
    }

    unsuspendUser() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.title = "Unsuspend User";
        modalInstance.message = "Are you sure you want to unsuspend <b>" + this.user.firstLastName + " (" + this.user.id + ")" + "</b>?" +
            "<br/><br/>Unsuspending the user will allow the user to log in.";

        modalInstance.primary = {
            text: "Unsuspend",
            callback: () => {
                modalRef.close();
                this.userService.unsuspendUser(this.username).subscribe((result: boolean) => {
                    this._reload();
                    this.growlService.success("User successfully unsuspended.");
                });
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Cancel",
            callback: () => {
                modalRef.close();
            }
        };
    }

    /*
    clickTokens() {
        let userKey = this.encryptionService.scrambleEncode(this.username);
        let url = `/admin/user/${userKey}/tokens`;
        this.router.navigateByUrl(url);
    }
    */

    save(): void {
        if (this._externalEmployeeIDValid()) {
            this.userOrgService.saveUser(this.user).subscribe(() => {
                this.userOrgService.setUserSetting(this.username, "FORCE_PASSWORD_CHANGE", this.mustChangePassword);
                this.userOrgService.setUserSetting(this.username, "EXTERNAL_EMPLOYEE_ID", this.externalEmployeeID);
                this.growlService.success("User settings saved.");
            });
        }
    }

    // Removed per PS-6835, but put back per PS-7610
    defaultOrgSelected(
        selection: Organization,
        isChanged: boolean
    ): void {
        if (isChanged) {
            let id: string = selection ? selection.id : "";
            this.sessionService.setDefaultOrganizationID(id);
            this.userOrgService.setUserDefaultOrganization(this.username, id)
                .subscribe(() => {
                    this.growlService.success("User settings saved.");
                });
        }
    }

    public clearLoginLock(): void {
        this.userLockoutService.doUserUnlock(this.user, !this.isSuperUser).then((result) => {
            // nothing
        });
    }

    public resetPassword() {
        this.userOrgTokenService.sendPasswordResetEmail(this.username)
            .subscribe((url: string) => {
                if (url) {
                    this.growlService.success("Password reset URL: " + url, null, { disableTimeOut: true });
                }
                this.growlService.success("A 'password reset' email message was sent.");
            });
    }

    public viewReport() {
        const reportType = "loginAttemptsReport";
        let reportUrl = `/sf/ui/admin/reports/runner/${reportType}?q="username":"${this.username}"`;
        window.open(reportUrl, "_blank");
    }
}
