import { UIESignEventAddress } from "./sign-event.interface";
import { Name } from "./name.interface";

export interface EEAcceptanceStatus {
    type: ParticipantAcceptanceStatus;
    date: DateString;
    revision: string;
}

export enum ParticipantAcceptanceStatus {
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
    NOT_REQUIRED = "NOT_REQUIRED"
}

export const STEP_COMPLETED_ACCEPTANCE_STATUSES = [
    ParticipantAcceptanceStatus.ACCEPTED,
    ParticipantAcceptanceStatus.NOT_REQUIRED
];

export enum ParticipantState {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export enum PersonallyKnownStatus {
    NOT_ALLOWED = "NOT_ALLOWED",
    ALLOWED = "ALLOWED",
    ACCEPTED = "ACCEPTED",
    DENIED = "DENIED"
}

export interface EEVerificationStatus {
    type: "PASSED" | "FAILED";
    date: DateString;
    participantMessage?: string;
    notaryMessage?: string;
}

export interface UIRoomSessionParticipant {
    eSignEventParticipantID: string;
    participantSid: string;
    participantIdentity: string;
}

export interface UIRoomParticipantMetadata {
    hasIdVerificationAddress: boolean;
    hasIdIssueDate: boolean;
    hasIdExpireDate: boolean;
    kbaLastStatus: EEVerificationStatus;
    kbaFirstRelevantFailureDate: DateString;
    kbaLocked?: boolean;
    kbaUnlockDate?: DateString;
    kbaNotaryMessage?: string;
    kbaSuccessiveFailureAttempts: number;
    kbaLockoutHours: number;
    kbaAttemptsRemaining: number;
    idLastStatus: EEVerificationStatus;
    idFirstRelevantFailureDate: DateString;
    idSuccessiveFailureAttempts: number;
    hasConfirmedInterpreterDetails: boolean;
}

export interface UIRoomParticipantInfo {
    idVerificationAddress: UIESignEventAddress;
    idName: Name;
    idDateOfBirth: String;
}

export interface EEIdVerificationData {
    idVerificationReferenceID: string;
    idVerificationDate: string;
}

export interface UIRoomParticipant {
    eSignEventParticipantID: string;
    lastLogin: EEVerificationStatus;
    eConsentStatus: EEAcceptanceStatus;
    privacyPolicyStatus: EEAcceptanceStatus;
    termsStatus: EEAcceptanceStatus;
    cameraVerificationStatus: EEVerificationStatus;
    microphoneVerificationStatus: EEVerificationStatus;
    speakersVerificationStatus: EEVerificationStatus;
    kbaVerificationStatus: EEVerificationStatus;
    idVerificationStatus: EEVerificationStatus;
    notaryIdInspectionStatus: EEVerificationStatus;
    idVerificationUploaded?: boolean;
    idVerificationProvider?: boolean;
    kbaConversationID: string;
    kbaAttempts: EEVerificationStatus[];
    idConversationID: string;
    idAttempts: EEVerificationStatus[];
    idVerificationUrl?: string;
    idVerificationReferenceID?: string;
    idVerificationReferenceIDs?: EEIdVerificationData[];
    idExpireDate?: string;
    idIssuingAuthority?: string;
    idIssuingAuthorityIDNumber?: string;
    idVerificationAddress?: UIESignEventAddress;
    idName?: Name;
    idDateOfBirth?: string;
    signatureWasDrawn?: boolean;
    metadata: UIRoomParticipantMetadata;
    currentUser: boolean;
    signingNonWitnessComplete: boolean;
    signingWitnessComplete: boolean;
    presenceOptional?: boolean;
    loginAttemptsRemaining: number;
    idVerificationAttemptsRemaining: number;
    alternateInfoAttemptsRemaining: number;
    documentAddedFlag?: boolean;
    documentTagsChangedFlag?: boolean;
    directingAttorney?: boolean;
    barNumber?: string;
    ronAllowPersonallyKnown?: PersonallyKnownStatus;
    signerScreenShare?: string[];
    canViewSignerScreens?: string[];
}

export interface UIRoomSharedDeviceState {
    id: string;
    activeParticipant: string;
    participantState: Map<string, ParticipantState>;
}

export interface UIRoomData {
    roomSid: string;
    name: string;
    uiRoomSessionParticipants: UIRoomSessionParticipant[];
    uiRoomSharedDeviceState: UIRoomSharedDeviceState;
}

export enum AuthStep {
    AUTH = "sign-event-auth",
    CONSENT = "consent",
    T_AND_C = "terms",
    VIDEO_SETUP = "video-setup",
    INTERPRETER_DETAILS = "interpreter-details",
    ATTORNEY_DETAILS = "attorney-details",
    STATE_REQUIREMENTS = "state-requirements",
    ID_VERIFICATION = "id-verification",
    PERSONAL_INFORMATION = "personal-information",
    KBA = "kba",
    LOBBY = "participant-room"
}
