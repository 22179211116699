import { Component, Injector, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChatUserPromptDialogComponent } from "../../modals/chat-user-prompt-dialog/chat-user-prompt-dialog.component";

@Component({
    selector: "sf-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
    formErrorMessages: string[] = [];

    private _userDetails: any = {
        first: "",
        last: "",
        email: "",
        department: ""
    };

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {}

    clickBack() {
        let newUrl = "/login";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }
}
