import {
    HttpClient,
    HttpEventType,
    HttpHeaders,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DocumentInfo } from "../interfaces/sign-event-documents.interface";

@Injectable({
    providedIn: "root"
})
export class SigningEventUploadDocumentService {
    constructor(private _http: HttpClient) {}

    public upload(
        files: Array<DocumentInfo>,
        eSignEventID: string
    ): { [key: string]: { progress: Observable<number> } } {
        const url = window.location.href.includes("tagging/package")
            ? `/sf/lender/ui/package/${eSignEventID}/document`
            : `/sf/esign_event/ui/event/${eSignEventID}/document`;
        // this will be the resulting map
        const status: { [key: string]: { progress: Observable<number> } } = {};

        files.forEach((uFile) => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            const data: any = {
                notaryPresenceRequired: uFile.notaryPresenceRequired,
                eRecordable: uFile.eRecordable,
                stackingOrder: uFile.stackingOrder
            };

            formData.append("file", uFile.file, uFile.name);
            formData.append("data", JSON.stringify(data));

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest("POST", url, formData, {
                reportProgress: true,
                headers: new HttpHeaders({ Accept: "application/json" })
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates

            this._http.request(req).subscribe((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                    // calculate the progress percentage
                    const percentDone = Math.round(
                        (100 * event.loaded) / event.total
                    );
                    // pass the percentage into the progress-stream
                    progress.next(percentDone);
                } else if (event.type === HttpEventType.ResponseHeader) {
                    // server has responded.
                    if (
                        event.status &&
                        (event.status === 413 || event.status === 400)
                    ) {
                        progress.error(event);
                        progress.complete();
                    }
                } else if (event.type === HttpEventType.Response) {
                    if (event.body.status === 400) {
                        // There was a valid successful response, but it contains a different error message from business logic
                        progress.error({ statusText: event.body.message });
                    }
                    // The upload is complete
                    progress.complete();
                }
            });
            // Save every progress-observable in a map of all observables
            status[uFile.name] = {
                progress: progress.asObservable()
            };
        });
        // return the map of progress.observables
        return status;
    }
}
