<div class="header" (contextmenu)="showContextMenu($event)">
    <sf-context-menu [x]="contextMenuX" [y]="contextMenuY" [shown]="contextMenuVisible"
        [options]="contextMenuItems" (select)="handleContextMenuItem($event)"></sf-context-menu>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <div class="d-flex flex-column flex-md-row">
                <div class="header-selector">
                    <div class="title" *ngIf="pageData.pageTitle">
                        <h1>{{pageData.pageTitle}}</h1>
                    </div>
                    <div class="d-flex flex-column flex-md-row select-holder">
                        <sf-user-search-selector
                            [hideBorder]="true"
                            [selectedUserID]="selectedUserID"
                            [autoInitialSelect]="true"
                            (select)="setSelectedUser($event)"
                        ></sf-user-search-selector>
                        <div *ngIf="selectedUser && selectedUser.status"
                            class="status-info sf-status-badge-container"
                            id="userStatusInfo">
                            <button class="btn btn-link badge badge-link {{selectedUser.statusClass}}"
                                (click)="onStatusClick()"
                                [ngbTooltip]="selectedUser.statusTooltip"
                                placement="top">
                                {{selectedUser.status}}
                            </button>
                        </div>
                        <div *ngIf="selectedUserID" class="loginAs" id="userLoginAs">
                            <button class="btn btn-link" (click)="becomeUser()" aria-label="Log in as this user"
                                    ngbTooltip="Log in as this user" placement="right">
                                <fa-icon icon="sign-in-alt"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
