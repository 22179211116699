import { Injectable, TemplateRef } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { BaseModalComponent } from "./base-modal.component";
import { TypedNgbModalRef } from "../../interfaces";

@Injectable({
    providedIn: "root"
})
export class BaseModal {
    constructor(private _modal: NgbModal) {}

    /**
     * Create an instance of BaseModalComponent with the provided template and context.
     * @param templateRef
     * @param modalOptions
     * @param templateContext
     */
    open<TemplateContext, ComponentType>(
        templateRef: TemplateRef<TemplateContext>,
        modalOptions: NgbModalOptions,
        ...templateContext: TemplateContext extends void | undefined | null
            ? []
            : [TemplateContext]
    ): TypedNgbModalRef<BaseModalComponent<TemplateContext>> {
        const modalRef = this._modal.open(BaseModalComponent, modalOptions);
        const component =
            modalRef.componentInstance as BaseModalComponent<TemplateContext>;
        component.template = templateRef;
        component.templateContext = templateContext[0];
        return modalRef;
    }
}
