import { Injectable } from "@angular/core";
import { PackageStatusDetails } from "../interfaces/package-status-details.interface";
import { SubmitterPackage } from "../interfaces/submitter-package.interface";

@Injectable({
    providedIn: "root"
})
export class SubmitterPackageRemoteIdService {
    public static readonly BATCH_ID_NOT_ASSIGNED = "Not yet assigned";
    public static readonly SUNRISE_SYSTEM = "SUNRISE";
    public static readonly PALM_BEACH_COUNTY = "Palm Beach County";

    constructor() {}

    public static getRemoteId(
        packageData: SubmitterPackage | PackageStatusDetails
    ) {
        if (
            packageData.recipientSystem === this.SUNRISE_SYSTEM ||
            packageData.recipientName === this.PALM_BEACH_COUNTY ||
            (packageData as SubmitterPackage).recipient ===
                this.PALM_BEACH_COUNTY
        ) {
            if ((packageData as SubmitterPackage).batchId) {
                return (packageData as SubmitterPackage).batchId;
            }
            if ((packageData as PackageStatusDetails).batchID) {
                return (packageData as PackageStatusDetails).batchID;
            }
            return this.BATCH_ID_NOT_ASSIGNED;
        }
        if ((packageData as SubmitterPackage).recipientPackageID) {
            return (packageData as SubmitterPackage).recipientPackageID;
        }
        if ((packageData as PackageStatusDetails).remoteID) {
            return (packageData as PackageStatusDetails).remoteID;
        }
        return null;
    }
}
