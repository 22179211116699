import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { AsyncSubject, BehaviorSubject, Observable, Subject } from "rxjs";
import { dayjs } from "@sf/common";
import { first, take } from "rxjs/operators";
import {
    CreditCardAccount,
    PaymentAccount,
    ProductsMissingDefaultLicenseAccount
} from "../interfaces/payment-account.interface";

@Injectable({
    providedIn: "root"
})
export class PaymentAccountsService {
    private _getEcheckBankAccountMinutes: number = 120; // 2 hour cache
    private _getEcheckBankAccount$: Subject<string[]>;
    private _getEcheckBankAccountTime: dayjs.Dayjs = null;
    private _getEcheckBankAccountCache: string[] = null;
    private _processingBankAccounts: boolean = false;

    private _getPendingPaymentCountsMinutes: number = 10;
    private _getPendingPaymentCounts$: Subject<any>;
    private _getPendingPaymentCountsTime: dayjs.Dayjs = null;
    private _getPendingPaymentCountsCache: any = null;
    private _processingPaymentCounts: boolean = false;

    constructor(private _rpcClientService: RpcClientService) {}

    getPaymentAccounts(
        organizationIds: string[],
        activeOnly: boolean
    ): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getPaymentAccounts",
            {
                organizationIDs: organizationIds,
                activeOnly: activeOnly
            }
        );
    }

    getPaymentAccountsForOrgs(
        organizationIds: string[],
        activeOnly: boolean
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getBankAccountsDetailedForOrgs",
            {
                organizationIDs: organizationIds,
                activeOnly: activeOnly
            }
        );
    }

    archivePaymentAccount(
        organizationId: string,
        bankAccountId: string
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "archivePaymentAccount",
            {
                organizationID: organizationId,
                paymentAccountID: bankAccountId
            }
        );
    }

    unarchivePaymentAccount(bankAccountId: string): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "unarchivePaymentAccount",
            {
                paymentAccountID: bankAccountId
            }
        );
    }

    getDefaultPaymentOptions(
        organizationId: string,
        product: string
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getDefaultPaymentOptions",
            {
                organizationID: organizationId,
                product: product
            }
        );
    }

    getTransactionCategoriesForProducts(
        organizationId: string,
        products: string[]
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getTransactionCategoriesForProducts",
            {
                organizationID: organizationId,
                products: products
            }
        );
    }

    saveDefaultPaymentOption(
        organizationId: string,
        product: string,
        feeType: string,
        accountType: string,
        accountId: string
    ): Observable<string> {
        return this._rpcClientService.makeRequest(
            "payment",
            "saveDefaultPaymentOption",
            {
                organizationID: organizationId,
                product: product,
                feeType: feeType,
                accountType: accountType,
                paymentAccountID: accountId
            }
        );
    }

    addBankAccount(
        newBankAccount: any,
        validationData: any
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest("payment", "addBankAccount", {
            newBankAccount: newBankAccount,
            validationData: validationData
        });
    }

    addCreditCardAccount(creditCardAccount: PaymentAccount): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "addCreditCardAccount",
            {
                uiCreditCardAccount: creditCardAccount
            }
        );
    }

    getCreditCardDetails(
        paymentAccountID: string,
        orgID: string
    ): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getCreditCardDetails",
            {
                paymentAccountID: paymentAccountID,
                organizationID: orgID
            }
        );
    }

    updateBankAccount(
        bankAccount: any,
        validationData: any
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "updateBankAccount",
            {
                bankAccount: bankAccount,
                validation: validationData
            }
        );
    }

    updateCreditCardAccount(creditCardAccount: any): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "updateCreditCardAccount",
            {
                creditCardAccount: creditCardAccount
            }
        );
    }

    updateInvoiceAccount(invoiceAccount: any): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "updateInvoiceAccount",
            {
                invoiceAccount: invoiceAccount
            }
        );
    }

    updateAccountLabel(
        organizationId: string,
        bankAccountId: string,
        accountLabel: string,
        accountName?: string
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "updateAccountLabel",
            {
                organizationID: organizationId,
                paymentAccountID: bankAccountId,
                accountLabel: accountLabel,
                accountName: accountName
            }
        );
    }

    importCheckForDuplicate(
        organizationId: string,
        bankAccountId: string
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "checkForImportedDuplicates",
            {
                organizationID: organizationId,
                bankAccountID: bankAccountId
            }
        );
    }

    importPaymentAccounts(
        organizationId: string,
        bankAccountIds: string[]
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "importPaymentAccounts",
            {
                organizationID: organizationId,
                bankAccountIDs: bankAccountIds
            }
        );
    }

    hasEcheckBankAccount(
        organizationIDs: string[],
        clearCache?: boolean
    ): Observable<string[]> {
        //if processing, return current obs
        if (this._processingBankAccounts) {
            return this._getEcheckBankAccount$;
        }

        //is cache expired?
        if (this._getEcheckBankAccountTime || clearCache) {
            let now: dayjs.Dayjs = dayjs();
            if (
                now.diff(this._getEcheckBankAccountTime, "minutes") >=
                this._getEcheckBankAccountMinutes
            ) {
                this._getEcheckBankAccountCache = null;
            }
        }

        //get result, from cache or back-end
        if (this._getEcheckBankAccountCache) {
            return new BehaviorSubject<string[]>(
                this._getEcheckBankAccountCache
            );
            // this._getEcheckBankAccount$.next(this._getEcheckBankAccountCache);
            // this._getEcheckBankAccount$.complete();
        } else {
            this._getEcheckBankAccount$ = new AsyncSubject<string[]>();
            this._processingBankAccounts = true;
            this._rpcClientService
                .makeRequest("payment", "hasEcheckBankAccount", {
                    orgIDs: organizationIDs
                })
                .pipe(first())
                .subscribe((results: string[]) => {
                    this._getEcheckBankAccountCache = results;
                    this._getEcheckBankAccountTime = dayjs();
                    this._processingBankAccounts = false;
                    this._getEcheckBankAccount$.next(results);
                    this._getEcheckBankAccount$.complete();
                });
        }

        return this._getEcheckBankAccount$;
    }

    getPendingPaymentCounts(
        organizationIDs: string[],
        clearCache?: boolean
    ): Observable<any> {
        //make sure we have org ids to start with
        if (
            !organizationIDs ||
            (organizationIDs && organizationIDs.length == 0)
        ) {
            return new BehaviorSubject({});
        }

        //if processing, return current obs
        if (this._processingPaymentCounts) {
            return this._getPendingPaymentCounts$;
        }

        //is cache expired?
        if (this._getPendingPaymentCountsTime || clearCache) {
            let now: dayjs.Dayjs = dayjs();
            if (
                now.diff(this._getPendingPaymentCountsTime, "minutes") >=
                    this._getPendingPaymentCountsMinutes ||
                clearCache // added clearCache here to allow ticker to update when past due payment is approved
            ) {
                this._getPendingPaymentCountsCache = null;
            }
        }

        //get result, from cache or back-end
        if (this._getPendingPaymentCountsCache) {
            return new BehaviorSubject<any>(this._getPendingPaymentCountsCache);
            // this._getPendingPaymentCounts$.next(
            //     this._getPendingPaymentCountsCache
            // );
            // this._getPendingPaymentCounts$.complete();
        } else {
            this._getPendingPaymentCounts$ = new AsyncSubject<any>();
            this._processingPaymentCounts = true;
            this._rpcClientService
                .makeRequest("payment", "getPendingPaymentCountsNew", {
                    orgIDs: organizationIDs,
                    clearOpenBalanceInfoCache: true // Get updated counts if payment account switches PS-11153
                })
                .pipe(take(1))
                .subscribe((counts: any) => {
                    this._getPendingPaymentCountsCache = counts;
                    this._getPendingPaymentCountsTime = dayjs();
                    this._processingPaymentCounts = false;
                    this._getPendingPaymentCounts$.next(counts);
                    this._getPendingPaymentCounts$.complete();
                });
        }

        return this._getPendingPaymentCounts$;
    }

    getEditablePackagesUsingPaymentAccount(
        organizationID: string,
        feeType: string,
        oldBankAccountID: string
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getEditablePackagesUsingPaymentAccount",
            {
                organizationID: organizationID,
                feeType: feeType,
                bankAccountID: oldBankAccountID
            }
        );
    }

    setDefaultAccountOnPackages(
        organizationID: string,
        feeType: string,
        bankAccountID: string,
        packageIDs: string[]
    ): Observable<any[]> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "setDefaultAccountOnPackages",
            {
                organizationID: organizationID,
                feeType: feeType,
                bankAccountID: bankAccountID,
                packageIDs: packageIDs
            }
        );
    }

    getSimplifileDrawDownBankAccountID(
        organizationID: string
    ): Observable<any> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getSimplifileDrawDownBankAccountID",
            {
                organizationID: organizationID
            }
        );
    }

    getSimplifileInvoiceAccountID(organizationID: string): Observable<any> {
        return this._rpcClientService.makeRequest(
            "erecord",
            "getSimplifileInvoiceAccountID",
            {
                organizationID: organizationID
            }
        );
    }

    isPaymentAccountUsed(
        organizationID: string,
        paymentAccountID: string
    ): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "isPaymentAccountUsed",
            {
                organizationID: organizationID,
                paymentAccountID: paymentAccountID
            }
        );
    }

    getExpiredCreditCard(orgIDs: string[]): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getExpiredCreditCard",
            {
                organizationIDs: orgIDs
            }
        );
    }

    getExpiringCreditCard(orgIDs: string[]): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getExpiringCreditCard",
            {
                organizationIDs: orgIDs
            }
        );
    }

    getExpiringCreditCards(orgIDs: string[]): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getExpiringCreditCards",
            {
                organizationIDs: orgIDs
            }
        );
    }

    getExpiredCreditCards(orgIDs: string[]): Observable<any> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getExpiredCreditCards",
            {
                organizationIDs: orgIDs
            }
        );
    }

    getCreditCardAccounts(
        orgID: string,
        activeOnly: boolean
    ): Observable<CreditCardAccount[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getCreditCardAccounts",
            {
                organizationID: orgID,
                activeOnly: activeOnly
            }
        );
    }

    addSamplePaymentAccounts(accountInfo: any[]): Observable<boolean> {
        return this._rpcClientService.makeRequest(
            "payment",
            "addSamplePaymentAccounts",
            {
                accountInfoArray: accountInfo
            }
        );
    }

    getMissingLicenseDefaultPaymentInfo(
        orgIDs: string[]
    ): Observable<ProductsMissingDefaultLicenseAccount[]> {
        return this._rpcClientService.makeRequest(
            "payment",
            "getMissingLicenseDefaultPaymentInfo",
            { organizationIDs: orgIDs }
        );
    }
}
