import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PendingPaymentsNewService {
    constructor(private _rpcClient: RpcClientService) {}

    getUserHasApprovedEchecks(
        orgIDArray: string[],
        username: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "getUserHasApprovedEchecks",
            { orgIDs: orgIDArray, username: username }
        );
    }

    // Called from Data Service
    getOrganizationsAvailableRefundAccounts(
        orgIDArray: string[]
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "payment",
            "getOrganizationsAvailableRefundAccounts",
            { orgIDs: orgIDArray }
        );
    }

    setPackagePaymentAccounts(
        id: string,
        newAccountID: string,
        ACCOUNT_TYPES: string[]
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "erecord",
            "setPackagePaymentAccounts",
            {
                id: id,
                newAccountID,
                ACCOUNT_TYPES
            },
            true
        );
    }

    hasEcheckBankAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasEcheckBankAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }

    hasACHBankAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasACHBankAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }

    hasCreditCardAccountInAnyOrg(orgIDs: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "payment",
            "hasCreditCardAccountInAnyOrg",
            { orgIDs: orgIDs }
        );
    }

    getPaymentAccountTypes(
        orgIDs: string[],
        activeOnly: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "payment",
            "getPaymentAccountTypes",
            { organizationIDs: orgIDs, activeOnly: activeOnly }
        );
    }

    getAllPendingPayments(
        orgIDArray: string[],
        paymentMethods: string[]
    ): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "getAllPendingPayments", {
            orgIDs: orgIDArray,
            paymentMethods: paymentMethods
        });
    }

    setLicenseAccount(
        organizationID: string,
        contractID: string,
        newPaymentAccountID: string,
        oldPaymentAccountID: string,
        amount: string,
        userID: string,
        comment: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest("erecord", "setLicenseAccount", {
            organizationID: organizationID,
            contractID: contractID,
            newPaymentAccountID: newPaymentAccountID,
            oldPaymentAccountID: oldPaymentAccountID,
            amount: amount,
            userID: userID,
            comment: comment
        });
    }

    deleteApprovedEcheck(eCheckID: string): Observable<boolean> {
        return this._rpcClient.makeRequest("payment", "deleteApprovedEcheck", {
            echeckID: eCheckID
        });
    }
}
