<sf-modal-header [title]="modalTitle"></sf-modal-header>
<div class="modal-body packages-using-account-archived-dialog sf-unmask">
    <div>
        <p>
            The payment account <b>{{account.viewLabel}}</b> cannot be removed because the following package(s) are using
            it for one or more fee types:
        </p>
    </div>
    <br/>
    <div id="pkgGrid" [ngStyle]="gridDivStyle">
        <ag-grid-angular
                class="ag-grid ag-theme-sf package-grid-overrides"
                [rowData]="packages"
                [gridOptions]="gridOptions"
        ></ag-grid-angular>
    </div>
</div>
<sf-modal-footer [primary]="primaryButton"></sf-modal-footer>

<ng-template #packageNameTemplate let-data>
    <a [routerLink]="['/submitter/package', data.id, 'details']" class="package-name-link" target="_blank">{{data.name}}</a>
</ng-template>
