import { Component, Input, OnInit } from "@angular/core";
import { sortBy } from "@sf/common";
import { HelpControlItem } from "../../interfaces/help-control.interface";
import { UserOrgService } from "@sf/userorg/common";

@Component({
    selector: "sf-help-control",
    templateUrl: "./help-control.component.html",
    styleUrls: ["./help-control.component.scss"]
})
export class HelpControlComponent {
    private _additionalOptions: HelpControlItem[] = [];
    private _documentationLink =
        sf.liveConfig.DocumentationLinks[
            "documentationUrl_DEFAULT_DOCUMENTATION_LINK"
        ];

    @Input() helpLink: string;
    @Input() set documentationLink(documentationLink: string) {
        this._documentationLink = documentationLink ?? this._documentationLink;
    }
    get documentationLink(): string {
        return this._documentationLink;
    }
    @Input() set additionalOptions(additionalOptions: HelpControlItem[]) {
        this._additionalOptions = additionalOptions?.sort(sortBy("order"));
    }
    get additionalOptions(): HelpControlItem[] {
        return this._additionalOptions;
    }

    docLink: string;

    constructor(private _userOrgService: UserOrgService) {}

    goToResourceCenter(): void {
        this._userOrgService.goToResourceCenter();
    }
}
