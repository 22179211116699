import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { STATUS, SUB_STATUS } from "../constants/package-status.enum";
import { DOC_STATUS } from "../constants/document-status.enum";
import { PackageStatus } from "../constants/package-status";
import { TitleCasePipe } from "@angular/common";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

interface StatusIcon {
    icon?: [IconPrefix, IconName];
    altIcon?: string;
    iconStyling: string;
    tooltip: string;
    hasMoreInfo: boolean;
}

@Component({
    selector: "sf-status-icon",
    templateUrl: "./status-icon.component.html",
    styleUrls: ["./status-icon.component.scss"],
    providers: [TitleCasePipe]
})
export class StatusIconComponent implements OnInit {
    @Input() set status(status: string) {
        if (status) {
            if (status.toLowerCase() === "needs poa") {
                this._status = "Needs POA";
            } else if (status.toLowerCase() === "sentremote") {
                this._status = "SentRemote";
            } else if (status.toLowerCase() === "mou complete") {
                this._status = "MOU Complete";
            } else {
                this._status = this.titleCasePipe.transform(status);
            }
        } else {
            this._status = status;
        }
    }

    get status() {
        return this._status;
    }

    @Input() set isPackage(isPackage: boolean) {
        if (isPackage) {
            this._entity = "package";
        } else {
            this._entity = "document";
        }
    }

    @Input()
    hasMoreInfoOverride: boolean;

    @Input()
    tooltipPlacement: string = "right";

    @Output()
    statusClick = new EventEmitter();

    public statusIcon: StatusIcon;
    public hasMoreInfo: boolean = false;
    public _status: string;

    private _entity: string = "package";
    private moreDetailsTooltip: string = "Click here for more details";
    private _statusIcons: { [key: string]: StatusIcon };

    constructor(private titleCasePipe: TitleCasePipe) {}

    ngOnInit() {}

    ngOnChanges() {
        this._initializeStatusIcon();
    }

    _initializeStatusIcon() {
        this._statusIcons = {
            [STATUS.RECORDED]: {
                icon: ["far", "thumbs-up"],
                iconStyling: "recorded",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [DOC_STATUS.RECORDING]: {
                icon: ["far", "ellipsis-h"],
                iconStyling: "recording",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.DRAFT]: {
                icon: ["far", "edit"],
                iconStyling: "draft",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.SENT]: {
                icon: ["far", "truck"],
                iconStyling: "sent",
                tooltip: "The package has been sent to the county for review",
                hasMoreInfo: false
            },
            [STATUS.REJECTED]: {
                icon: ["far", "thumbs-down"],
                iconStyling: "rejected",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.READY]: {
                icon: ["far", "check"],
                iconStyling: "ready",
                tooltip: `The ${this._entity} is ready to be submitted to the county`,
                hasMoreInfo: false
            },
            [STATUS.VOIDED]: {
                icon: ["far", "times"],
                iconStyling: "voided",
                tooltip: "The package has been voided.",
                hasMoreInfo: true
            },
            [SUB_STATUS.MAINTENANCE]: {
                icon: ["far", "wrench"],
                iconStyling: "maintenance",
                tooltip: `The ${this._entity} has encountered an error and is being worked on by Simplifile staff`,
                hasMoreInfo: false
            },
            [SUB_STATUS.FINALIZING]: {
                icon: ["far", "thumbs-up"],
                iconStyling: "finalizing",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.REVIEW]: {
                icon: ["far", "eye"],
                iconStyling: "review",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.DATA_ENTRY]: {
                icon: ["far", "text"],
                iconStyling: "data-entry",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.RECEIVED]: {
                icon: ["far", "truck"],
                iconStyling: "received",
                tooltip:
                    "The package has been received by the county and will be reviewed shortly",
                hasMoreInfo: false
            },
            [STATUS.BILLED_REJECTED]: {
                icon: ["far", "credit-card"],
                iconStyling: "billed-rejected",
                tooltip:
                    "This package has been rejected and has a rejection fee.",
                hasMoreInfo: false
            },
            [STATUS.SIGN]: {
                icon: ["far", "signature"],
                iconStyling: "sign",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.REVIEWING]: {
                icon: ["far", "eye"],
                iconStyling: "reviewing",
                tooltip: "The package is being reviewed by the county.",
                hasMoreInfo: false
            },
            [STATUS.ACCEPTED]: {
                icon: ["far", "check"],
                iconStyling: "accepted",
                tooltip: `The ${this._entity} has been accepted by the county and is awaiting review and/or recording.`,
                hasMoreInfo: false
            },
            [DOC_STATUS.NEEDS_POA]: {
                icon: ["far", "file-contract"],
                iconStyling: "needs-poa",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            // Double spelling of canceled will be investigated in --> https://jira.elliemae.io/browse/SUB-29262
            [STATUS.CANCELED]: {
                icon: ["far", "ban"],
                iconStyling: "canceled",
                tooltip: "",
                hasMoreInfo: false
            },
            [this.titleCasePipe.transform(PackageStatus.CANCELLED)]: {
                icon: ["far", "ban"],
                iconStyling: "canceled",
                tooltip: "",
                hasMoreInfo: false
            },
            [STATUS.INITIALIZE]: {
                icon: ["far", "ellipsis-h"],
                iconStyling: "initialize",
                tooltip: this.moreDetailsTooltip,
                hasMoreInfo: true
            },
            [STATUS.REMOVED]: {
                icon: ["far", "times"],
                iconStyling: "removed",
                tooltip: "The package has been removed.",
                hasMoreInfo: false
            },
            [STATUS.SENT_REMOTE]: {
                icon: ["far", "caret-square-right"],
                iconStyling: "sent-remote",
                tooltip: "The package has been sent to the county for review.",
                hasMoreInfo: false
            },
            [DOC_STATUS.CONVERTED]: {
                icon: ["far", "arrow-right"],
                iconStyling: "converted",
                tooltip: `The ${this._entity} contains helper documents that have not been completed.`,
                hasMoreInfo: false
            },
            [DOC_STATUS.UNKNOWN]: {
                icon: ["far", "question"],
                iconStyling: "unknown",
                tooltip: "The status of this package is unknown.",
                hasMoreInfo: false
            },
            [DOC_STATUS.REVIEWED]: {
                icon: ["far", "eye"],
                iconStyling: "reviewed",
                tooltip: "The package has been reviewed by the county.",
                hasMoreInfo: false
            },
            [STATUS.MOU_COMPELTE]: {
                icon: ["far", "thumbs-up"],
                iconStyling: "mou-complete",
                tooltip: "The MOU is complete.",
                hasMoreInfo: false
            }
        };

        if (this._status) {
            this.statusIcon = this._statusIcons[this._status];
            if (this.hasMoreInfoOverride !== undefined) {
                this.hasMoreInfo = this.hasMoreInfoOverride;
            } else if (
                this.statusClick.observers.length === 0 &&
                this.statusIcon.hasMoreInfo
            ) {
                this.hasMoreInfo = false;
                if (this.statusIcon.tooltip === this.moreDetailsTooltip) {
                    this.statusIcon.tooltip = "";
                }
            } else {
                this.hasMoreInfo = this.statusIcon.hasMoreInfo;
            }

            if (this._status === "SentRemote") {
                this._status = "Sent Remote";
            }
        }
    }

    onStatusClick() {
        if (this.hasMoreInfo) {
            this.statusClick.emit();
        }
    }
}
