export enum BannerNotificationType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    DANGER = "danger",
    PRIMARY = "primary",
    SECONDARY = "secondary",
    LIGHT = "light",
    DARK = "dark"
}
