import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectableItem } from "../../interfaces";

@Component({
    selector: "sf-context-menu",
    templateUrl: "./context-menu.component.html",
    styleUrls: ["./context-menu.component.scss"]
})
export class ContextMenuComponent implements OnInit {
    @Input()
    x: number;
    @Input()
    y: number;
    @Input()
    shown: boolean;
    @Input()
    options: SelectableItem[];
    @Output()
    select: EventEmitter<any> = new EventEmitter(); // when an org is selected - passes an 'org' parameter when called

    constructor() {}

    ngOnInit() {}

    handleContextMenuItem(item: SelectableItem) {
        this.select.emit(item.option);
    }
}
