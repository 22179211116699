import { Observable } from "rxjs";
import { Type } from "@angular/core";
import { NavBadgeComponent } from "../components/nav-badge/nav-badge.component";

export type BadgeValue = number | string;

export interface NavItemBadge {
    value: Observable<BadgeValue> | BadgeValue;
    component?: Type<NavBadgeComponent>;
    type?: Observable<BadgeType> | BadgeType;
    extras?: Observable<any> | any;
    label?: Observable<BadgeValue> | BadgeValue;
}

export enum BadgeType {
    Action = "action", // Lender Action Required
    Default = "default",
    Error = "error",
    Info = "info",
    Light = "light",
    Pending = "pending", // Lender Pending
    Success = "success",
    Warning = "warning"
}
