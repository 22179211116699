<div class="sf-spinner-service">
    <div class="sf-spinner">
        <div
            class="spinner"
            role="progressbar"
            aria-label="Please wait"
            aria-describedby="sfSpinnerMessage"
            style="
                position: relative;
                width: 0px;
                z-index: 3000;
                left: 0px;
                top: 0px;
            "
            tabindex="-1"
            #spinner
        >
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -1s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(0deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(0deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.91667s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(30deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(30deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.83333s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(60deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(60deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.75000s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(90deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(90deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.66667s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(120deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(120deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.58333s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(150deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(150deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.50000s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(180deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(180deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.41667s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(210deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(210deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.33333s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(240deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(240deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.25000s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(270deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(270deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.16667s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(300deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(300deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
            <div
                style="
                    position: absolute;
                    top: -4px;
                    transform: translate3d(0px, 0px, 0px);
                    opacity: 0.25;
                    animation: 1s linear -0.08333s infinite normal none running
                        spinner-line-fade-default;
                "
            >
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgb(0, 0, 0) 0px 0px 4px;
                        transform-origin: left center;
                        transform: rotate(330deg) translate(30px, 0px);
                        border-radius: 4px;
                        top: 2px;
                    "
                ></div>
                <div
                    style="
                        position: absolute;
                        width: 24px;
                        height: 8px;
                        background: rgb(0, 0, 0);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
                        transform-origin: left center;
                        transform: rotate(330deg) translate(30px, 0px);
                        border-radius: 4px;
                    "
                ></div>
            </div>
        </div>
    </div>

    <div
        class="h3 sf-spinner-service__message"
        id="sfSpinnerMessage"
        *ngIf="message"
        [innerHtml]="message"
        aria-live="polite"
    ></div>
</div>
