import { Component, Input, OnInit } from "@angular/core";
import { IconsService } from "../icons/icons.service";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Priority, Message } from "../services/news/news.service";
import { DatePipe } from "@angular/common";

@Component({
    selector: "sf-message",
    templateUrl: "./message.component.html",
    styleUrls: ["./message.component.scss"],
    providers: [DatePipe]
})
export class MessageComponent implements OnInit {
    /** I/O **/
    @Input() message: Message;

    /** Private Variables **/

    /** Public Variables **/
    priorityIcon: IconDefinition;
    ariaLabel = "";

    /** View Children **/

    constructor(
        private _iconsService: IconsService,
        private _datePipe: DatePipe
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        if (this.message.priority === "information") {
            this.priorityIcon = this._iconsService.get("infoCircle");
        } else if (this.message.priority === "outage") {
            this.priorityIcon = this._iconsService.get("exclamationTriangle");
        } else if (this.message.priority === "closure") {
            this.priorityIcon = this._iconsService.get("clock");
        }

        this.ariaLabel = this._getAriaLabel(this.message);
    }

    /** Public Methods **/
    getTooltip(priority: Priority): string {
        if (priority === "information")
            return "General product announcements or other interesting news";
        if (priority === "outage")
            return "A vendor, recipient, or Simplifile may be offline or experiencing performance problems";
        if (priority === "closure")
            return "This recipient will be closing at a different time than they normally do";
    }

    private _getAriaLabel(message: Message): string {
        let description = "";
        const date = this._datePipe.transform(
            message.messageDate,
            "MMMM d, yyyy"
        );

        if (message.priority === "information")
            description += "Information for ";
        if (message.priority === "outage") description += "Outage for ";
        if (message.priority === "closure") description += "Closure for ";

        description += date + ".";
        return description;
    }

    /**  Private Methods  **/
}
