import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
    CopyTextService,
    getAllRouteData,
    getLinkWithChangedParams,
    getParamsFromRoute,
    scheduleMicroTask,
    SelectableItem
} from "@sf/common";
import { PageHeaderComponent } from "@sf/headers";
import { Subject, Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { sampleTime, takeUntil } from "rxjs/operators";
import {
    CurrentOrganizationService,
    Organization,
    OrganizationService,
    OrganizationSubscriptionService
} from "@sf/userorg/common";
import { AdminOrganizationService } from "../../services/admin-organization.service";
import { AdminLoginAsService } from "../../services/admin-login-as.service";

interface ExtendedOrganization extends Organization {
    statusTooltip?: string;
    statusCode?: string;
}

// prettier-ignore
@Component({
    selector: "sf-admin-org-header-with-selector",
    templateUrl: "./admin-org-header-with-selector.component.html",
    styleUrls: ["./admin-org-header-with-selector.component.scss"]
})
export class AdminOrgHeaderWithSelectorComponent implements OnInit, OnDestroy, PageHeaderComponent {
    pageData: any;
    private _onDestroy$: Subject<void> = new Subject();
    private updater: Subscription = null;

    /* Public Variables */
    selectedOrganization: Partial<ExtendedOrganization> = {
        id: null
    };
    selectableOrgServices: string[] = [];
    urlIsAbsolute: boolean;

    /** Private Variables */
    showSaveChanges = false;
    updatingCurrentOrg = false;

    contextMenuVisible = false;
    contextMenuX: number;
    contextMenuY: number;
    contextMenuItems: SelectableItem[] = [
        {
            option: "copyID",
            label: "Copy Organization ID"
        },
        {
            option: "copyName",
            label: "Copy Organization Name"
        }
    ];

    constructor(
            private currentOrganizationService: CurrentOrganizationService,
            private _route: ActivatedRoute,
            private router: Router,
            private adminOrganizationService: AdminOrganizationService,
            private adminLoginAsService: AdminLoginAsService,
            private organizationService: OrganizationService,
            private copyService: CopyTextService,
            private organizationSubscriptionService: OrganizationSubscriptionService
    ) {
    }

    ngOnInit() {
        // look for route data that could influence selection -----
        let routeData = getAllRouteData(this._route);

        if (routeData.selectableOrgServices) {
            this.selectableOrgServices = routeData.selectableOrgServices;
        }

        // should be seldom used
        if (typeof routeData.showSave === "boolean") {
            this.showSaveChanges = routeData.showSave;
        }

        // get the selected organization
        this.pickInitialOrg();

        // update the display if the organization status or name changes (by somebody editing it)
        //delay initializing this so that it doesn't interfere with the pickInitialOrg call above
        window.setTimeout(() => {
            this.currentOrganizationService.currentOrganization$
                    .pipe(takeUntil(this._onDestroy$))
                    .subscribe((org) => {
                        if (org) {
                            this.selectedOrganization = org;
                            this.subscribeToChanges();
                        }
                    });
        }, 500);

    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new organization
            this.updater.unsubscribe();
        }
        this.updater = this.organizationSubscriptionService.subscribeToUpdates(this.selectedOrganization.id)
                .pipe(
                        takeUntil(this._onDestroy$),
                        sampleTime(1000)
                )
                .subscribe((msg: any) => {
                    this.setSelectedOrg(this.selectedOrganization as Organization);
                });
    }

    pickInitialOrg() {
        let initialOrg: Organization = null;
        let currentOrg: Organization =
                this.currentOrganizationService.getCurrentOrganization();

        // look for orgID in URL
        let params = getParamsFromRoute(this._route, ["orgID"]);
        if (params.orgID && params.orgID !== "_") {
            // an orgID was specified in the URL, so it takes priority
            if (currentOrg && params.orgID == currentOrg.id) {
                initialOrg = currentOrg;
            } else {
                // temporarily use a partial Organization object
                initialOrg = <Organization>{
                    id: params.orgID
                };
                this.updateCurrentOrgID(params.orgID);
            }
            this.selectedOrganization = initialOrg;
            return; // done
        }

        // try the 'current' org
        initialOrg = currentOrg;
        if (initialOrg) {
            this.selectedOrganization = initialOrg;
            return; //done
        }
    }

    updateCurrentOrgID(orgID: string) {
        this.updatingCurrentOrg = true;
        this.organizationService.getOrganization(orgID).subscribe((org: Organization) => {
            this.currentOrganizationService.updateCurrentOrganization(org);
            this.updatingCurrentOrg = false;
            if (org.childIDs) {
                if (org.childIDs.length > 0) {
                    this.currentOrganizationService.hasChildOrganizations$.next(true);
                } else {
                    this.currentOrganizationService.hasChildOrganizations$.next(false);
                }
            } else {
                this.currentOrganizationService.hasChildOrganizations$.next(false);
            }

        });
    }

    navigateToOrgID(orgID: string) {
        let extras: NavigationExtras = {
            relativeTo: this._route,
            queryParamsHandling: "preserve"
        };
        let link = getLinkWithChangedParams(
                this._route,
                {
                    orgID
                },
                true
        );
        if (orgID && !link.includes(orgID)) {
            link = link.map((part) =>
                    part.includes("./organization") ? part + "/" + orgID : part
            );
        }
        scheduleMicroTask(() => {
            this.router.navigate(link, extras);
        });
    }

    // called from HTML
    setSelectedOrg(selectedOrg: Organization) {
        if (this.updatingCurrentOrg) {
            // ignore this call if we are looking up an org to make it current
            return;
        }
        // there are two steps to setting current org, because org status is missing at first
        if (selectedOrg) {
            if (!this.selectedOrganization || selectedOrg.id != this.selectedOrganization.id) {
                this.navigateToOrgID(selectedOrg.id);
            }
            this.updateCurrentOrgID(selectedOrg.id);
            this.selectedOrganization = selectedOrg;
        } else {
            this.selectedOrganization = {
                id: null
            };
        }
        this.currentOrganizationService.updateCurrentOrganization(selectedOrg);
    }

    // sends a notification that the 'save' button was clicked
    saveCurrentOrg() {
        this.currentOrganizationService.saveCurrentOrganization();
    }

    statusClick(org: Partial<ExtendedOrganization>) {
        let newUrl = "";
        if (org.archived || !org.enabled || org.hasSuspendedService) {
            newUrl = "/admin/organization/" + org.id + "/config/services/";
        } else if (org.statusCode == "contract_pending") {
            //newUrl = "/invitations/accept-license/" + org.pendingContract;
            newUrl = "/admin/organization/" + org.id + "/config/services/";
        } else if (org.statusCode == "missing_account") {
            newUrl = "/admin/organization/" + org.id + "/config/accounts/";
        } else if (org.statusCode == "missing_counties") {
            newUrl = "/admin/organization/" + org.id + "/submitter/counties";
        } else {
            newUrl = "/admin/organization/" + org.id + "/config/services/";
        }
        if (!this.sameUrl(newUrl)) {
            this.router.navigateByUrl(newUrl);
        }
    }

    sameUrl(url: string): boolean {
        let newUrl = url;
        if (newUrl.endsWith("/")) {
            newUrl = newUrl.substring(0, newUrl.length - 1);
        }
        let oldUrl = this.router.url;
        if (oldUrl.endsWith("/")) {
            oldUrl = oldUrl.substring(0, oldUrl.length - 1);
        }
        return newUrl == oldUrl;
    }

    becomeUser() {
        this.adminLoginAsService.loginToOrganization(
                this.selectedOrganization.id,
                this.selectedOrganization.name
        );
    }

    showContextMenu(event: any) {
        if (event.pageX || event.pageY) {
            this.contextMenuX = event.pageX;
            this.contextMenuY = event.pageY;
        } else if (event.clientX || event.clientY) {
            this.contextMenuX =
                    event.clientX +
                    document.body.scrollLeft +
                    document.documentElement.scrollLeft;
            this.contextMenuY =
                    event.clientY +
                    document.body.scrollTop +
                    document.documentElement.scrollTop;
        }
        this.contextMenuVisible = true;
        event.preventDefault();
        event.stopPropagation();
    }

    handleContextMenuItem(option: string) {
        this.contextMenuVisible = false;
        if (this.selectedOrganization) {
            switch (option) {
                case "copyName":
                    this.copyService.copy(this.selectedOrganization.name);
                    break;
                case "copyID":
                default:
                    this.copyService.copy(this.selectedOrganization.id);
                    break;
            }
        }
    }

    showHelp() {
        this._detectIfUrlIsAbsolute(this.pageData.helpLink);

        if (this.urlIsAbsolute) {
            window.open(this.pageData.helpLink, "_blank");
        } else {
            let url: string = location.origin + this.pageData.helpLink;
            window.open(url, "_blank");
        }
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:click", ["$event"])
    public documentClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    /**
     * close context menu when clicking on page
     */
    @HostListener("document:contextmenu", ["$event"])
    public documentRClick(event: Event): void {
        this.contextMenuVisible = false;
    }

    _detectIfUrlIsAbsolute(url: string) {
        let firstFour = url.substring(0, 4);
        if (firstFour === "http") {
            this.urlIsAbsolute = true;
        } else {
            this.urlIsAbsolute = false;
        }
    }
}
