import { Component } from "@angular/core";
import { InputComponent } from "../../components";
import { DynamicFormStore } from "../../services/dynamic-form-store";

@Component({
    selector: "sf-date-field",
    templateUrl: "./date-field.component.html",
    styleUrls: ["./date-field.component.scss"]
})
export class DateFieldComponent extends InputComponent {
    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
    }

    checkRequired(): boolean {
        if (this.field.defaults.formState.validators.length > 0) {
            return this.field.defaults.formState.validators.some(
                (validator) => validator.identifier === "required"
            );
        }
        return false;
    }
}
