import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ElementRef,
    ViewChild,
    HostBinding,
    AfterViewInit,
    ChangeDetectorRef,
    forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Store } from "redux";

import * as sfSelectActions from "./common/sf-select.action-creators";
import { SfSelectSelectors } from "./common/sf-select.selectors";
import { SfSelectItem, SfSelectView } from "./common/sf-select.interface";
import { SfScrollIntoViewService } from "./common/sf-scroll-into-view.service";
import { SfSelectUtils } from "./common/sf-select.utilities";
import { SfSelectValidateComponentService } from "./common/sf-select-validate-component.service";
import { SfSelectReducerService } from "./common/sf-select-reducer.service";
import { SfSelectMaintainPositionDirective } from "./common/sf-select-maintain-position.directive";
import { ReduxService } from "./external-libraries/redux.service";
import { IconsService } from "../icons/icons.service";
import { Icons } from "../interfaces";
import { deepEqual } from "../helpers/object";
import { debounce } from "../helpers/utils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type InputObject =
    | { [trackById: string]: string }[]
    | { [trackById: string]: string };

@Component({
    selector: "sf-select",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        "[class.disabled]": "view.isDisabled",
        "[class.readonly]": "view.isReadonly",
        "[class.ng-invalid]":
            "!view.isValid && view.isRequired && !view.isDisabled && !view.isReadonly",
        "[class.ng-dirty]":
            "view.isDirty && view.isRequired && !view.isDisabled && !view.isReadonly",
        "[attr.aria-expanded]": "view.isOpen",
        role: "combobox",
        "aria-readonly": "true",
        "[attr.aria-label]": "view.msDisplay || placeholder || name",
        "[attr.aria-controls]": "'sf-select-two__list-' + selectorId",
        "(click)": "toggleOpen($event)",
        "(keydown)": "handleKeydown($event)",
        "(blur)": "_ngModelOnTouched()"
    },
    providers: [
        SfSelectValidateComponentService,
        SfSelectReducerService,
        SfScrollIntoViewService,
        SfSelectSelectors,
        SfSelectUtils,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent
    implements
        OnInit,
        OnDestroy,
        OnChanges,
        AfterViewInit,
        ControlValueAccessor
{
    /* Private Variables */
    private _lastReportedSelection: InputObject | "NOT_INTITIALIZED" =
        "NOT_INTITIALIZED";
    private _unregisterFromStore: Function = () => {};
    private _initialTabindex: number = null;
    private _ngModelOnChange: Function = () => {};
    private _ngModelOnTouched: Function = () => {};

    /* Public Variables */
    selectStore: Store<any> = undefined;
    view: SfSelectView = {} as SfSelectView;
    api = {
        setFilterValue: this.setFilterValue.bind(this),
        toggleOpen: this.toggleOpen.bind(this),
        openDropdown: this.openDropdown.bind(this),
        closeDropdown: this.closeDropdown.bind(this)
    };
    selectorId: any = null;
    popupMinWidth = 100;
    filterAction: (event: Event) => void = () => {};
    icons: Icons = {};

    /* Inputs */
    @HostBinding("tabindex")
    @Input()
    tabindex: number;
    @Input()
    options: any[];
    @Input()
    placeholder: string;
    @Input()
    name: string;
    @Input()
    dropdownClass: string;
    @Input()
    filterPlaceholder: string;
    @Input()
    trackBy: string;
    @Input()
    labelBy: string;
    @Input()
    groupBy: string;
    @Input()
    bindAsHtml: boolean;
    @Input()
    bottomMetadataKey: string;
    @Input()
    rightMetadataKey: string;
    @Input()
    rightMetadataIsIcon: boolean;
    @Input()
    showIconInDisplay: boolean;
    @Input()
    disableBy: string;
    @Input()
    titleBy: string;
    @Input()
    titleGroupBy: string;
    @Input()
    isSingleSelect: boolean;
    @Input()
    hasSelectAll: boolean;
    @Input()
    hasFilter: boolean;
    @Input()
    filterIcon: IconProp;
    @Input()
    required: boolean = null; // see sfSelectActions.setIsRequired function - the null value here is to support both a 'required' attribute and a 'required="true"' attribute both when using a form control and when not using a form control
    @Input()
    selectedOptions: Array<string | number>;
    @Input()
    selectedOption: string | number;
    @Input()
    withFooter: boolean;
    @Input()
    footerActionLeftIcon: IconProp;
    @Input()
    footerActionLeftLabel: string;
    @Input()
    footerActionRightIcon: IconProp;
    @Input()
    footerActionRightLabel: string;
    @Input()
    showSelectedToggle: boolean;
    @Input()
    hasClearButton: boolean;
    @Input()
    alwaysCallOnSelect: boolean;
    @Input()
    isDisabled: boolean;
    @Input()
    isReadonly: boolean;
    @Input()
    filterDebounce: number;
    @Input()
    handleFilterExternally: boolean;
    @Input()
    additionalPropertiesToFilter: string[];
    @Input()
    maxPopupHeight: number;
    @Input()
    minPopupWidth: number;
    @Input()
    showRecentSelections: boolean;
    @Input()
    limitDropDownItems: number;
    @Input()
    recentlySelectedOptions: any[];
    @Input()
    showMainListWithRecents: boolean;

    /* Outputs */
    @Output()
    select: EventEmitter<{
        $selection: any;
        $isSelectionChanged: boolean;
    }> = new EventEmitter();
    @Output()
    filter: EventEmitter<{
        $selection: any[];
        $searchValue: string;
    }> = new EventEmitter();
    @Output()
    registerApi: EventEmitter<{
        $api: {
            setFilterValue: (value: string) => void;
            toggleOpen: Function;
            openDropdown: Function;
            closeDropdown: Function;
        };
    }> = new EventEmitter();
    @Output()
    footerActionLeft: EventEmitter<{ $selection: any[] }> = new EventEmitter();
    @Output()
    footerActionRight: EventEmitter<{ $selection: any[] }> = new EventEmitter();
    @Output()
    open = new EventEmitter();
    @Output()
    close = new EventEmitter();

    /* View Children */
    @ViewChild("searchBoxClearButton")
    searchBoxClearButton: ElementRef<HTMLDivElement>;
    @ViewChild("searchBoxInput")
    searchBoxInput: ElementRef<HTMLInputElement>;
    @ViewChild("selectButton")
    selectButton: ElementRef<HTMLDivElement>;
    @ViewChild(SfSelectMaintainPositionDirective)
    maintainPosition: SfSelectMaintainPositionDirective;
    @ViewChild("popupList")
    popupList: ElementRef<HTMLDivElement>;

    constructor(
        private _sfSelectSelectors: SfSelectSelectors,
        private _sfScrollIntoView: SfScrollIntoViewService,
        private _sfSelectUtils: SfSelectUtils,
        private _sfSelectValidateComponent: SfSelectValidateComponentService,
        private _sfSelectReducer: SfSelectReducerService,
        private _changeDetector: ChangeDetectorRef,
        private _rootEl: ElementRef,
        private _redux: ReduxService,
        private _iconsService: IconsService
    ) {
        this._onStoreUpdate = this._onStoreUpdate.bind(this);
    }

    ngOnInit() {
        this.icons.clear = this._iconsService.get("times");
        this.icons.downArrow = this._iconsService.get("caretDown");
        this.selectorId = this._sfSelectUtils.guid();
        this._initialTabindex = this.tabindex;
        this.filterPlaceholder = this.filterPlaceholder || "";
        this.placeholder = this.placeholder || "";
        this._sfSelectValidateComponent.validateComponent(this);

        this.registerApi.emit({ $api: this.api });

        this.filterAction = this._getFilterActionDebounced();

        this.selectStore = this._redux.createStore(
            this._sfSelectReducer.reducer
        );

        // provide initial values to store before subscribing
        this.selectStore.dispatch(sfSelectActions.setItems(this.options));
        this.selectStore.dispatch(sfSelectActions.setIdKey(this.trackBy));
        this.selectStore.dispatch(sfSelectActions.setLabelKey(this.labelBy));
        this.selectStore.dispatch(sfSelectActions.setGroupIdKey(this.groupBy));
        this.selectStore.dispatch(
            sfSelectActions.setBindAsHtml(this.bindAsHtml)
        );
        this.selectStore.dispatch(
            sfSelectActions.setBottomMetadataKey(this.bottomMetadataKey)
        );
        this.selectStore.dispatch(
            sfSelectActions.setRightMetadataKey(this.rightMetadataKey)
        );
        this.selectStore.dispatch(
            sfSelectActions.setRightMetadataIsIcon(this.rightMetadataIsIcon)
        );
        this.selectStore.dispatch(
            sfSelectActions.setShowIconInDisplay(this.showIconInDisplay)
        );
        this.selectStore.dispatch(
            sfSelectActions.setDisabledKey(this.disableBy)
        );
        this.selectStore.dispatch(
            sfSelectActions.setIsSingleSelect(this.isSingleSelect)
        );
        this.selectStore.dispatch(sfSelectActions.setHasFilter(this.hasFilter));
        this.selectStore.dispatch(
            sfSelectActions.setFilterIcon(this.filterIcon)
        );
        this.selectStore.dispatch(
            sfSelectActions.setHandleFilterExternally(
                this.handleFilterExternally
            )
        );
        this.selectStore.dispatch(
            sfSelectActions.setHasFooter(this.withFooter)
        );
        this.selectStore.dispatch(
            sfSelectActions.setHasSelectAll(this.hasSelectAll)
        );
        this.selectStore.dispatch(sfSelectActions.setIsRequired(this.required));
        this.selectStore.dispatch(
            sfSelectActions.setIsDisabled(this.isDisabled)
        );
        this.selectStore.dispatch(
            sfSelectActions.setIsReadonly(this.isReadonly)
        );
        this.selectStore.dispatch(
            sfSelectActions.setShowSelectedToggle(this.showSelectedToggle)
        );
        this.selectStore.dispatch(
            sfSelectActions.setSelectedItems(
                this.selectedOptions || this.selectedOption
            )
        );
        this.selectStore.dispatch(
            sfSelectActions.setAdditionalPropertiesToFilter(
                this.additionalPropertiesToFilter
            )
        );
        this.selectStore.dispatch(sfSelectActions.setTitleKey(this.titleBy));
        this.selectStore.dispatch(
            sfSelectActions.setGroupTitleKey(this.titleGroupBy)
        );
        this.selectStore.dispatch(
            sfSelectActions.setMaxPopupHeight(this.maxPopupHeight)
        );
        this.selectStore.dispatch(
            sfSelectActions.setShowRecentSelections(this.showRecentSelections)
        );
        this.selectStore.dispatch(
            sfSelectActions.setRecentlySelectedItems(
                this.recentlySelectedOptions
            )
        );
        this.selectStore.dispatch(
            sfSelectActions.setShowMainListWithRecents(
                this.showMainListWithRecents
            )
        );
        this.selectStore.dispatch(
            sfSelectActions.setLimitDropdownItems(this.limitDropDownItems)
        );

        this._lastReportedSelection = this._sfSelectSelectors.getOutput(
            this.selectStore.getState()
        );

        // subscribe to store
        this._unregisterFromStore = this.selectStore.subscribe(
            this._onStoreUpdate
        );

        this._onStoreUpdate();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this._unregisterFromStore();
    }

    ngOnChanges(changesObj: SimpleChanges) {
        if (!this.selectStore) {
            return;
        }

        if (
            changesObj.filterPlaceholder &&
            !changesObj.filterPlaceholder.currentValue
        ) {
            this.filterPlaceholder = "";
        }
        if (changesObj.placeholder && !changesObj.placeholder.currentValue) {
            this.placeholder = "";
        }
        if (
            changesObj.options &&
            !deepEqual(
                changesObj.options.previousValue,
                changesObj.options.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setItems(changesObj.options.currentValue)
            );
            this._lastReportedSelection = "NOT_INITIALIZED" as any;
        }
        if (
            changesObj.groupBy &&
            !deepEqual(
                changesObj.groupBy.previousValue,
                changesObj.groupBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setGroupIdKey(changesObj.groupBy.currentValue)
            );
        }
        if (
            changesObj.labelBy &&
            !deepEqual(
                changesObj.labelBy.previousValue,
                changesObj.labelBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setLabelKey(changesObj.labelBy.currentValue)
            );
        }
        if (
            changesObj.trackBy &&
            !deepEqual(
                changesObj.trackBy.previousValue,
                changesObj.trackBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setIdKey(changesObj.trackBy.currentValue)
            );
        }
        if (
            changesObj.bindAsHtml &&
            !deepEqual(
                changesObj.bindAsHtml.previousValue,
                changesObj.bindAsHtml.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setBindAsHtml(
                    changesObj.bindAsHtml.currentValue
                )
            );
        }
        if (
            changesObj.bottomMetadataKey &&
            !deepEqual(
                changesObj.bottomMetadataKey.previousValue,
                changesObj.bottomMetadataKey.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setBottomMetadataKey(
                    changesObj.bottomMetadataKey.currentValue
                )
            );
        }
        if (
            changesObj.rightMetadataKey &&
            !deepEqual(
                changesObj.rightMetadataKey.previousValue,
                changesObj.rightMetadataKey.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setRightMetadataKey(
                    changesObj.rightMetadataKey.currentValue
                )
            );
        }
        if (
            changesObj.rightMetadataIsIcon &&
            !deepEqual(
                changesObj.rightMetadataIsIcon.previousValue,
                changesObj.rightMetadataIsIcon.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setRightMetadataIsIcon(
                    changesObj.rightMetadataIsIcon.currentValue
                )
            );
        }
        if (
            changesObj.showIconInDisplay &&
            !deepEqual(
                changesObj.showIconInDisplay.previousValue,
                changesObj.showIconInDisplay.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setShowIconInDisplay(
                    changesObj.showIconInDisplay.currentValue
                )
            );
        }
        if (
            changesObj.disableBy &&
            !deepEqual(
                changesObj.disableBy.previousValue,
                changesObj.disableBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setDisabledKey(
                    changesObj.disableBy.currentValue
                )
            );
        }
        if (
            changesObj.selectedOptions &&
            !deepEqual(
                this.selectStore.getState().selectedItems,
                changesObj.selectedOptions.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setSelectedItems(
                    changesObj.selectedOptions.currentValue
                )
            );
            this._lastReportedSelection = this._getCurrentSelection();
        }
        if (
            changesObj.selectedOption &&
            !deepEqual(
                this.selectStore.getState().selectedItems[0],
                changesObj.selectedOption.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setSelectedItems(
                    changesObj.selectedOption.currentValue
                )
            );
            this._lastReportedSelection = this._getCurrentSelection();
        }
        if (
            changesObj.isDisabled &&
            !deepEqual(
                changesObj.isDisabled.previousValue,
                changesObj.isDisabled.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setIsDisabled(
                    changesObj.isDisabled.currentValue
                )
            );
        }
        if (
            changesObj.isReadonly &&
            !deepEqual(
                changesObj.isReadonly.previousValue,
                changesObj.isReadonly.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setIsReadonly(
                    changesObj.isReadonly.currentValue
                )
            );
        }
        if (
            changesObj.isRequired &&
            !deepEqual(
                changesObj.isRequired.previousValue,
                changesObj.isRequired.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setIsRequired(
                    changesObj.isRequired.currentValue
                )
            );
        }
        if (
            changesObj.withFooter &&
            !deepEqual(
                changesObj.withFooter.previousValue,
                changesObj.withFooter.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setHasFooter(changesObj.withFooter.currentValue)
            );
        }
        if (
            changesObj.showSelectedToggle &&
            !deepEqual(
                changesObj.showSelectedToggle.previousValue,
                changesObj.showSelectedToggle.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setShowSelectedToggle(
                    changesObj.showSelectedToggle.currentValue
                )
            );
        }
        if (
            changesObj.filterIcon &&
            !deepEqual(
                changesObj.filterIcon.previousValue,
                changesObj.filterIcon.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setFilterIcon(
                    changesObj.filterIcon.currentValue
                )
            );
        }
        if (
            changesObj.handleFilterExternally &&
            !deepEqual(
                changesObj.handleFilterExternally.previousValue,
                changesObj.handleFilterExternally.currentValue
            )
        ) {
            this.filterAction = this._getFilterActionDebounced();
            this.selectStore.dispatch(
                sfSelectActions.setHandleFilterExternally(
                    changesObj.handleFilterExternally.currentValue
                )
            );
        }
        if (
            changesObj.additionalPropertiesToFilter &&
            !deepEqual(
                changesObj.additionalPropertiesToFilter.previousValue,
                changesObj.additionalPropertiesToFilter.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setAdditionalPropertiesToFilter(
                    changesObj.additionalPropertiesToFilter.currentValue
                )
            );
        }
        if (
            changesObj.titleBy &&
            !deepEqual(
                changesObj.titleBy.previousValue,
                changesObj.titleBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setTitleKey(changesObj.titleBy.currentValue)
            );
        }
        if (
            changesObj.titleGroupBy &&
            !deepEqual(
                changesObj.titleGroupBy.previousValue,
                changesObj.titleGroupBy.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setGroupTitleKey(
                    changesObj.titleGroupBy.currentValue
                )
            );
        }
        if (
            changesObj.maxPopupHeight &&
            !deepEqual(
                changesObj.maxPopupHeight.previousValue,
                changesObj.maxPopupHeight.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setMaxPopupHeight(
                    changesObj.maxPopupHeight.currentValue
                )
            );
        }
        if (
            changesObj.showRecentSelections &&
            !deepEqual(
                changesObj.showRecentSelections.previousValue,
                changesObj.showRecentSelections.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setShowRecentSelections(
                    changesObj.showRecentSelections.currentValue
                )
            );
        }
        if (
            changesObj.recentlySelectedOptions &&
            !deepEqual(
                this.selectStore.getState().recentlySelectedItems,
                changesObj.recentlySelectedOptions.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setRecentlySelectedItems(
                    changesObj.recentlySelectedOptions.currentValue
                )
            );
        }
        if (
            changesObj.showMainListWithRecents &&
            !deepEqual(
                changesObj.showMainListWithRecents.previousValue,
                changesObj.showMainListWithRecents.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setShowMainListWithRecents(
                    changesObj.showMainListWithRecents.currentValue
                )
            );
        }
        if (
            changesObj.limitDropDownItems &&
            !deepEqual(
                changesObj.limitDropDownItems.previousValue,
                changesObj.limitDropDownItems.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setLimitDropdownItems(
                    changesObj.limitDropDownItems.currentValue
                )
            );
        }

        if (
            changesObj.hasSelectAll &&
            !deepEqual(
                changesObj.hasSelectAll.previousValue,
                changesObj.hasSelectAll.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setHasSelectAll(
                    changesObj.hasSelectAll.currentValue
                )
            );
        }

        if (
            changesObj.isSingleSelect &&
            !deepEqual(
                changesObj.isSingleSelect.previousValue,
                changesObj.isSingleSelect.currentValue
            )
        ) {
            this.selectStore.dispatch(
                sfSelectActions.setIsSingleSelect(
                    changesObj.isSingleSelect.currentValue
                )
            );
        }
    }

    /* Begin ControlValueAccessor Implementation */
    writeValue(newSelection: string | string[]) {
        let sel: string[];
        if (Array.isArray(newSelection)) {
            sel = newSelection;
        } else if (!!newSelection) {
            sel = [newSelection];
        } else {
            sel = [];
        }
        this.selectStore.dispatch(sfSelectActions.setSelectedItems(sel));
        this._lastReportedSelection = this._getCurrentSelection();
    }

    registerOnChange(fn: Function) {
        this._ngModelOnChange = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.selectStore.dispatch(sfSelectActions.setIsDisabled(isDisabled));
    }

    registerOnTouched(fn: Function) {
        this._ngModelOnTouched = fn;
    }

    /* End ControlValueAccessor Implementation */

    // TODO: The type of item messes with the type in the template.
    ngForTrackBy(index: number, item: any) {
        return item.$$msId;
    }

    ngForTrackByGroup(index: number, group: { id: string | number }) {
        return group.id;
    }

    private _onStoreUpdate() {
        let currentState = this.selectStore.getState();
        setTimeout(() => {
            this.view = this._sfSelectSelectors.getView(currentState);

            if (this.view.isDisabled || this.view.isReadonly) {
                this._makeUnselectable();
                this._changeDetector.markForCheck();
                return;
            }

            this._makeSelectable();

            // Single-select scroll into view
            if (
                this.view.isSingleSelect &&
                this.view.isOpen &&
                this.view.activeItemId
            ) {
                if (this.view.previousItemId) {
                    this._sfScrollIntoView.scrollItemIntoView(
                        "msAnchor" +
                            this.selectorId +
                            this.view.hashedActiveItemId,
                        "div.sf-select-two__list"
                    );
                } else {
                    this._sfScrollIntoView.scrollItemIntoView(
                        "msAnchor" + this.selectorId + "msTopItem",
                        "div.sf-select-two__list"
                    );
                }
            }
            // Multi-select scroll into view
            if (!this.view.isSingleSelect && this.view.isOpen) {
                if (
                    this.view.currentSelection.length &&
                    !this.view.activeItemId
                ) {
                    let visibleAnchorId =
                        this._sfScrollIntoView.findFirstVisibleItem(
                            this.selectorId,
                            this.view.currentSelection,
                            "div.sf-select-two__list"
                        );
                    this._sfScrollIntoView.scrollItemIntoView(
                        visibleAnchorId ??
                            `msAnchor${this.selectorId}${this.view.currentSelection[0].$$msHashedId}`,
                        "div.sf-select-two__list"
                    );
                } else if (this.view.activeItemId && this.view.previousItemId) {
                    this._sfScrollIntoView.scrollItemIntoView(
                        "msAnchor" +
                            this.selectorId +
                            this.view.hashedActiveItemId,
                        "div.sf-select-two__list"
                    );
                } else {
                    this._sfScrollIntoView.scrollItemIntoView(
                        "msAnchor" + this.selectorId + "msTopItem",
                        "div.sf-select-two__list"
                    );
                }
            }

            this._changeDetector.markForCheck();
            this.maintainPosition.refresh();
        });
    }

    private _getCurrentSelection() {
        let currentState = this.selectStore.getState();
        return this._sfSelectSelectors.getOutput(currentState);
    }

    clearFilter($event: any) {
        if (
            $event.type === "click" ||
            ($event.type === "keydown" && $event.which === 13)
        ) {
            $event.preventDefault();
            $event.stopPropagation();
            this.filter.emit({
                $selection: this._getCurrentSelection(),
                $searchValue: ""
            });
            this.selectStore.dispatch(sfSelectActions.setItemFilter(""));
            this._setFilterElementValue("");
        }
        if ($event.type === "keydown" && $event.which === 13) {
            this.searchBoxInput.nativeElement.focus();
        }
    }

    toggleActiveItemSelection() {
        this.selectStore.dispatch(sfSelectActions.toggleActiveItemSelection());
    }

    toggleFilteredItemSelection() {
        this.selectStore.dispatch(
            sfSelectActions.toggleFilteredItemSelection()
        );
    }

    toggleItemSelection($$msId: string | number) {
        this.selectStore.dispatch(sfSelectActions.toggleItemSelection($$msId));
        if (this.view.isSingleSelect) {
            this.toggleOpen();
        }
    }

    toggleGroupSelection(groupId: string | number) {
        this.selectStore.dispatch(
            sfSelectActions.toggleGroupSelection(groupId)
        );
    }

    clearSelection() {
        this.selectStore.dispatch(sfSelectActions.clearSelection());
        if (!this.view.isOpen) {
            this._outputSelection();
        }
    }

    selectAllClicked() {
        if (this.view.showOnlySelected) {
            this.clearSelection();
            return;
        }
        this.toggleFilteredItemSelection();
    }

    setFilterValue(value: string) {
        this.selectStore.dispatch(sfSelectActions.setItemFilter(value));
        this._setFilterElementValue(value);
    }

    private _getFilterActionDebounced() {
        return debounce(
            ($event: any) => {
                if ([37, 38, 39, 40, 13, 9].indexOf($event.which) === -1) {
                    this._handleFilter($event.target.value);
                }
            },
            typeof this.filterDebounce === "undefined"
                ? 250
                : this.filterDebounce
        );
    }

    toggleShowOnlySelected() {
        this.selectStore.dispatch(sfSelectActions.toggleShowOnlySelected());
    }

    setShowOnlySelected(value: boolean) {
        this.selectStore.dispatch(sfSelectActions.setShowOnlySelected(value));
    }

    openDropdown($event?: Event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (this.view.isOpen) {
            return;
        }
        if (this.searchBoxInput) {
            this.searchBoxInput.nativeElement.focus();
        } else {
            this.popupList.nativeElement.focus();
        }
        this.selectStore.dispatch(sfSelectActions.openDropdown());
        this.open.emit();
    }

    closeDropdown($event?: Event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (!this.view.isOpen) {
            return;
        }
        this._outputSelection();
        this._rootEl.nativeElement.focus();
        this.selectStore.dispatch(sfSelectActions.closeDropdown());
        this.close.emit();
    }

    toggleOpen($event?: Event) {
        if (this.view.isDisabled || this.view.isReadonly) {
            return;
        }
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (!this.view.isOpen) {
            this.openDropdown();
        }
        if (this.view.isOpen) {
            this.closeDropdown();
        }
    }

    private _outputSelection() {
        let currentSelection = this._getCurrentSelection();
        let isSelectionChanged = !deepEqual(
            currentSelection,
            this._lastReportedSelection
        );
        if (this.alwaysCallOnSelect || isSelectionChanged) {
            this.select.emit({
                $selection: currentSelection,
                $isSelectionChanged: isSelectionChanged
            }); // output new selection
            if (Array.isArray(currentSelection)) {
                this._ngModelOnChange(
                    currentSelection.map((selection) => selection[this.trackBy])
                );
            } else if (currentSelection) {
                this._ngModelOnChange(currentSelection[this.trackBy]);
            } else {
                this._ngModelOnChange(null);
            }
            this._lastReportedSelection = currentSelection;
        }
    }

    handleKeydown($event: any) {
        const filterInputIsTarget =
            $event.target &&
            $event.target.id === "ms-two-input-" + this.selectorId;
        // on escape
        if ($event.which === 27 && this.view.isOpen) {
            this.toggleOpen($event);
            return;
        }
        // on arrow down/right when open
        if ($event.which === 40 && this.view.isOpen) {
            $event.preventDefault();
            this.popupList.nativeElement.focus();
            if (this.view.isSingleSelect && this.view.nextItemId) {
                // automatically mark single the active item as selected when in single select mode
                this.selectStore.dispatch(
                    sfSelectActions.toggleItemSelection(this.view.nextItemId)
                );
                return;
            }
            this.selectStore.dispatch(
                sfSelectActions.setActiveItem(this.view.nextItemId)
            );
            return;
        }
        // on arrow down/right when closed
        if ($event.which === 40 && !this.view.isOpen) {
            $event.preventDefault();
            this.toggleOpen();
            if (!this.view.currentSelection.length) {
                if (this.view.isSingleSelect) {
                    // Select first item in list if nothing selected when opened w/down arrow
                    this.selectStore.dispatch(
                        sfSelectActions.toggleItemSelection(
                            this.view.nextItemId
                        )
                    );
                } else {
                    this.selectStore.dispatch(
                        sfSelectActions.setActiveItem(this.view.nextItemId)
                    );
                    // Remove focus from the filter input
                    this.popupList.nativeElement.focus();
                }
            }
            return;
        }
        // on arrow up/left  & open
        if (this.view.isOpen && $event.which === 38) {
            $event.preventDefault();
            if (!this.view.previousItemId) {
                this.searchBoxInput.nativeElement.focus();
            }
            if (this.view.isSingleSelect && !this.view.previousItemId) {
                // don't set an empty active item if in single select mode
                return;
            }
            if (this.isSingleSelect && this.view.previousItemId) {
                // automatically mark single the active item as selected when in single select mode
                this.selectStore.dispatch(
                    sfSelectActions.toggleItemSelection(
                        this.view.previousItemId
                    )
                );
                return;
            }
            this.selectStore.dispatch(
                sfSelectActions.setActiveItem(this.view.previousItemId)
            );
            return;
        }
        // on enter or space on the clear button
        if (
            ($event.which === 13 || $event.which === 32) &&
            !this.view.isOpen &&
            $event.target.className.indexOf(
                "sf-select-two-field__clear-button"
            ) > -1
        ) {
            $event.preventDefault();
            this.clearSelection();
            return;
        }
        // on space or enter when open and isSingleSelect is false
        if (
            ($event.which === 13 || $event.which === 32) &&
            this.view.isOpen &&
            !this.view.isSingleSelect
        ) {
            // space or enter when opened
            if (filterInputIsTarget) {
                return;
            }
            $event.preventDefault();
            this.toggleActiveItemSelection();
            return;
        }
        // on space when open and isSingleSelect is true
        if (
            $event.which === 32 &&
            this.view.isOpen &&
            this.view.isSingleSelect
        ) {
            if (filterInputIsTarget) {
                return;
            }
            this.toggleOpen($event);
            return;
        }
        // on enter when open and isSingleSelect is true
        if (
            $event.which === 13 &&
            this.view.isOpen &&
            this.view.isSingleSelect
        ) {
            this.toggleOpen($event);
            return;
        }
        // on space or enter when closed
        if (($event.which === 13 || $event.which === 32) && !this.view.isOpen) {
            $event.preventDefault();
            this.toggleOpen();
            return;
        }
        // on tab with metakey when target is the filter clear box
        if (
            $event.shiftKey &&
            $event.which === 9 &&
            this.view.isOpen &&
            $event.target.id === "ms-two-clear-filter-" + this.selectorId
        ) {
            $event.preventDefault();
            $event.stopPropagation();
            this.searchBoxInput.nativeElement.focus();
            return;
        }
        // on tab when inside filter and filter has value
        if (
            $event.which === 9 &&
            this.view.isOpen &&
            filterInputIsTarget &&
            this.view.itemFilter
        ) {
            $event.preventDefault();
            $event.stopPropagation();
            this.searchBoxClearButton.nativeElement.focus();
            return;
        }
        // on tab when inside filter and filter does not have value
        if (
            $event.which === 9 &&
            this.view.isOpen &&
            filterInputIsTarget &&
            !this.view.itemFilter
        ) {
            this.toggleOpen();
            return;
        }
        // on tab otherwise
        if ($event.which === 9 && this.view.isOpen && !filterInputIsTarget) {
            this.toggleOpen();
            return;
        }
        // on any other alpha-numeric key
        if (
            !$event.metaKey &&
            !$event.ctrlKey &&
            $event.key &&
            $event.key.length === 1
        ) {
            this.searchBoxInput.nativeElement.focus();
            if (!this.view.hasFilter) {
                this.selectStore.dispatch(sfSelectActions.setHasFilter(true));
            }
            if (!this.view.hasFilter && this.view.isOpen) {
                this._handleFilter($event.key);
                this._setFilterElementValue($event.key);
            }
            if (!this.view.isOpen) {
                this.toggleOpen($event);
                this._handleFilter($event.key);
                this._setFilterElementValue($event.key);
            }
        }
    }

    clearButtonClicked($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.view.isDisabled || this.view.isReadonly) {
            return;
        }
        this.clearSelection();
        this._ngModelOnTouched();
        this.selectStore.dispatch(sfSelectActions.setIsDirty(true));
    }

    callFooterActionLeft() {
        let currentState = this.selectStore.getState();
        let selection = this._sfSelectSelectors.getOutput(currentState);
        this.toggleOpen();
        this.footerActionLeft.emit({ $selection: selection });
    }

    callFooterActionRight() {
        let currentState = this.selectStore.getState();
        let selection = this._sfSelectSelectors.getOutput(currentState);
        this.toggleOpen();
        this.footerActionRight.emit({ $selection: selection });
    }

    private _handleFilter(query: string) {
        this.selectStore.dispatch(sfSelectActions.setItemFilter(query));
        this.filter.emit({
            $selection: this._getCurrentSelection(),
            $searchValue: query
        });
    }

    private _makeUnselectable() {
        this.tabindex = -1;
    }

    private _makeSelectable() {
        this.tabindex = this._initialTabindex > -1 ? this._initialTabindex : 0;
    }

    private _setFilterElementValue(value: string) {
        this.searchBoxInput.nativeElement.value = value;
        // This is for IE on Windows 7
        if (value.length) {
            setTimeout(() => {
                this.searchBoxInput.nativeElement.setSelectionRange(
                    value.length,
                    value.length
                );
            }, 10);
        }
    }
}
