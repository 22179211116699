import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, of } from "rxjs";
import { ApiCustomization } from "../interfaces/api-customization";
import { RpcClientService } from "@sf/common";
import {
    ApiCustomizationRuleParameters,
    PackageCustomizations
} from "../interfaces/package-customizations";
import { switchMap } from "rxjs/operators";
import { SaveApiDefinitionResult } from "../interfaces/api-definition";
import { UiApiNotificationEditorInfo } from "../interfaces/ui-api-notification-editor-info";
import { UiApiNotificationEditorTestResult } from "../interfaces/ui-api-notification-editor-test-result";
import { SubmitterApiDefinition } from "../interfaces/submitter-api-definition";

@Injectable({
    providedIn: "root"
})
export class AdminApiService {
    private _findLenderOrg: { [key: string]: any } = {};
    private _findLenderOrgSubject: BehaviorSubject<any> = new BehaviorSubject(
        null
    );
    constructor(protected _rpcClientService: RpcClientService) {}

    private _makeApiRequest<T>(method: string, data?: any): Observable<T> {
        return this._rpcClientService.makeRequest("api", method, data);
    }

    getAllSubmitterApiKeys(): Observable<string[]> {
        return this._makeApiRequest("getAllSubmitterApiKeys", {});
    }

    getSubmitterApiDefinitionByKey(key: string): Observable<any> {
        return this._makeApiRequest("getSubmitterApiDefinitionByKey", { key });
    }

    setSubmitterApiDefinition(
        definition: any,
        ignoreWarnings: boolean
    ): Observable<SaveApiDefinitionResult> {
        return this._makeApiRequest("setSubmitterAPIDefinition", {
            definition: definition,
            ignoreValidateErrors: ignoreWarnings
        });
    }

    repairUnbalancedErecordMappingRules(definition: any): Observable<any> {
        return this._makeApiRequest("repairUnbalancedErecordMappingRules", {
            definition
        });
    }

    deleteSubmitterApiDefinition(key: string): Observable<void> {
        return this._makeApiRequest("deleteSubmitterApiDefinition", { key });
    }

    getSubmitterAPIDefinitionHistory(apiKeyID: string): Observable<any[]> {
        return this._makeApiRequest("getSubmitterAPIDefinitionHistory", {
            apiKeyID
        });
    }

    /**
     * Deprecated: moved to ApiTokenService
     */
    findApiSubscriptionsByOrganizationID(orgID: string): Observable<void> {
        return this._makeApiRequest("findApiSubscriptionsByOrganizationID", {
            orgID
        });
    }

    getIntegrationApiTypes(apiType: string) {
        return this._makeApiRequest("getIntegrationApiTypes", {
            type: apiType
        });
    }

    getNonVendorIntegrationApiTypes(apiType: string) {
        return this._makeApiRequest("getNonVendorIntegrationApiTypes", {
            type: apiType
        });
    }

    getVendorIntegrationApiTypes(apiType: string) {
        return this._makeApiRequest("getVendorIntegrationApiTypes", {
            type: apiType
        });
    }

    getApiKeyList() {
        return this._makeApiRequest("getApiKeyList", {});
    }

    getEarliestApiLogDate() {
        return this._makeApiRequest("getEarliestApiLogDate", {});
    }

    searchApiLogs(
        data: any,
        limit: number,
        offset: number,
        startDate: Date,
        endDate: Date
    ) {
        return this._makeApiRequest("searchApiLogs", {
            searchLog: data,
            limit: limit,
            offset: offset,
            startDate: startDate,
            endDate: endDate
        });
    }

    basicApiLogSearch(searchString: string, limit: number, offset: number) {
        return this._makeApiRequest("basicApiLogSearch", {
            searchString: searchString,
            limit: limit,
            offset: offset
        });
    }

    createApiSubscription(
        apiKeyId: string,
        organizationId: string,
        key: string,
        userName: string,
        password: string,
        url: string,
        productID: string,
        routingNumber: string,
        integrationType: string,
        secondaryIntegrationType: string
    ) {
        return this._makeApiRequest("createApiSubscription", {
            uiApiOrgSubscription: {
                apiKeyID: apiKeyId,
                organizationID: organizationId,
                serviceKey: key,
                serviceUsername: userName,
                servicePassword: password,
                vendorURL: url,
                productID: productID,
                routingNumber: routingNumber,
                integrationType: integrationType,
                secondaryIntegrationType: secondaryIntegrationType
            }
        });
    }

    deleteApiSubscription(
        apiKeyId: string,
        productId: string,
        organizationId: string
    ) {
        return this._makeApiRequest("deleteApiSubscription", {
            uiApiOrgSubscription: {
                apiKeyID: apiKeyId,
                id: productId,
                organizationID: organizationId
            }
        });
    }

    getAllCustomizationIDs(): Observable<string[]> {
        return this._makeApiRequest("getAllCustomizationIDs", {});
    }

    getCustomizationByID(id: string): Observable<ApiCustomization> {
        return this._makeApiRequest("getCustomizationByID", { id });
    }

    getCustomizationHistoryByID(id: string): Observable<ApiCustomization[]> {
        return this._makeApiRequest("getCustomizationHistoryByID", { id });
    }

    setApiCustomization(
        apiCustomization: ApiCustomization
    ): Observable<true | { error: string }> {
        return this._makeApiRequest("setApiCustomization", {
            apiCustomization
        });
    }

    getPackageCustomizations(
        sPackageID: string
    ): Observable<PackageCustomizations[]> {
        return this._makeApiRequest("getApiCustomizationOption", {
            sPackageID
        });
    }

    getApiCustomizationPackageInfo(
        sPackageID: string
    ): Observable<ApiCustomizationRuleParameters> {
        return this._makeApiRequest<ApiCustomizationRuleParameters>(
            "getApiCustomizationPackageInfo",
            {
                sPackageID
            }
        );
    }

    generateApiCustomer(customer: {
        username: string;
        password: string;
        companyName: string;
        state: string;
    }): Observable<string> {
        return this._makeApiRequest("generateApiCustomer", customer);
    }

    private _makeCapcRequest<T>(method: string, data?: any): Observable<T> {
        return this._rpcClientService.makeRequest("capc", method, data);
    }

    //findCAPCOrgSettingStructure() - that returns the UI org settings structure
    findCAPCOrgSettingStructure(): Observable<any> {
        return this._makeCapcRequest("findCAPCOrgSettingStructure", {});
    }

    //findCAPCOrgJSONSettings(OrganizationID capcOrgId) - finds the JSON settings section of the CAPC settings
    findCAPCOrgJSONSettings(_capcOrgId: string): Observable<any> {
        return this._makeCapcRequest("findCAPCOrgJSONSettings", {
            capcOrgId: _capcOrgId
        });
    }
    //saveCAPCOrgSettings(OrganizationID capcOrgId, APIKeyID apiKeyID, LenderOrg.OrgSettingsSection section,
    // UICAPCOrgSettings uicapcOrgSettings) - saves the section of the capc org settings
    saveCAPCOrgSettings(
        _capcOrgId: string,
        _apiKeyID: string,
        _section: string,
        _uicapcOrgSettings: any
    ): Observable<any> {
        return this._makeCapcRequest("saveCAPCOrgSettings", {
            capcOrgId: _capcOrgId,
            apiKeyID: _apiKeyID,
            section: _section,
            uiCapcOrgSettings: _uicapcOrgSettings
        });
    }

    //call for a non superuser to get the settings for a capc org
    findLenderOrg(_capcOrgId: string): Observable<any> {
        return this._findLenderOrgSubject.pipe(
            switchMap(() => {
                if (this._findLenderOrg[_capcOrgId] === undefined) {
                    this._makeCapcRequest("findLenderOrg", {
                        lenderOrgId: _capcOrgId
                    }).subscribe((org: any) => {
                        this._findLenderOrg[_capcOrgId] = org;
                        this._findLenderOrgSubject.next(org);
                    });
                    return EMPTY;
                } else {
                    return of(this._findLenderOrg[_capcOrgId]);
                }
            })
        );
    }

    // saveCAPCOrgJSONSettings(OrganizationID capcOrgId, List<CAPCOrgApiServiceSettings> capcOrgApiServiceSettings) -
    // saves the CAPC JSON settings.
    saveCAPCOrgJSONSettings(
        _capcOrgId: string,
        _settings: any
    ): Observable<any> {
        return this._makeCapcRequest("saveCAPCOrgJSONSettings", {
            capcOrgId: _capcOrgId,
            capcOrgApiServiceSettings: _settings
        });
    }
    // findCAPCOrg(OrganizationID capcOrgId, APIKeyID apiKeyID, LenderOrg.OrgSettingsSection section) -
    // finds the org section - if APIKeyID is passed in, it will get the integration org section
    findCAPCOrg(
        _capcOrgId: string,
        _apiKeyID: string,
        _section: string
    ): Observable<any> {
        let data: any = {};
        data.capcOrgId = _capcOrgId;
        data.section = _section;
        if (_apiKeyID !== "") {
            data.apiKeyID = _apiKeyID;
        }
        return this._makeCapcRequest("findCAPCOrg", data);
    }

    isValidJSON(data: string): boolean {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
        return true;
    }

    getApiKeySubmitterCount() {
        return this._makeApiRequest("getApiKeySubmitterCount", {});
    }

    getSubmittersByApiKey(data: any) {
        return this._makeApiRequest("getSubmittersByApiKey", data);
    }
    deleteApiKey(data: any) {
        return this._makeApiRequest("deleteApiKey", { key: data.key });
    }

    setApiKey(data: any) {
        const dataToSend = data;
        dataToSend.disabled = dataToSend.disabled ?? false;
        dataToSend.service = dataToSend.service ?? "";
        dataToSend.devSubmitterID = dataToSend.devSubmitter ?? "";
        dataToSend.charset = dataToSend.charset ?? "";
        return this._makeApiRequest("setApiKey", dataToSend);
    }

    encryptApiDefinitionValue(plainText: string): Observable<string> {
        return this._makeApiRequest("encryptApiDefinitionValue", {
            plainText
        });
    }

    decryptApiDefinitionPath(
        apiKeyID: string,
        apiDefinitionPath: string
    ): Observable<string> {
        return this._makeApiRequest("decryptApiDefinitionPath", {
            key: apiKeyID,
            apiDefinitionPath: apiDefinitionPath
        });
    }

    getEncryptedFieldPaths(apiKeyID: string): Observable<string[]> {
        return this._makeApiRequest("getEncryptedFieldPaths", {
            key: apiKeyID
        });
    }

    getNotificationOptions(): Observable<UiApiNotificationEditorInfo> {
        return this._makeApiRequest("getNotificationOptions");
    }

    testSettings(
        apiDefinition: SubmitterApiDefinition,
        portionIdentifier: string
    ): Observable<UiApiNotificationEditorTestResult> {
        return this._makeApiRequest("testSettings", {
            apiDefinition: apiDefinition,
            portionIdentifier: portionIdentifier
        });
    }
}
