<div class="test-app">

    Go to Angular A |
    <a routerLink="/angular_b">Go to Angular B</a> |
    <a routerLink="/angularjs_a">Go to AngularJS A</a> |
    <a routerLink="/angularjs_b">Go to AngularJS B</a>
    <h2>Angular A!</h2>

    <!-- ngbDropdown -->
    <div ngbDropdown class="d-inline-block">
        <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>{{ dropdownData.label }}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <section>
                <div class="action-header">Group 1</div>
                <button
                    class="btn"
                    *ngFor="let item of dropdownData.items; first as isFirst"
                    ngbDropdownItem
                    [class.disabled]="item.disabled"
                ><fa-icon [icon]="[item.iconSet, item.icon]"></fa-icon> {{ item.label }}</button>
            </section>
        </div>
    </div>
    <br /><br />

    <!-- sf-date-picker -->
    <div>Date Picker Date: {{datePickerDate | json}}</div>
    <div><sf-date-picker [(date)]="datePickerDate" stringFormat="MM/DD/YYYY"></sf-date-picker></div>

    <div>Date Picker Date Using NgModel: {{datePickerDate2 | json}}</div>
    <div><sf-date-picker [(ngModel)]="datePickerDate2" stringFormat="MM-DD-YYYY" [disabled]="true"></sf-date-picker></div>
    <br/>

    <!-- sf-search -->
    <div>
        <span>Search Text: </span><span>{{searchModel.text}}</span>
    </div>
    <div>
        <sf-search [model]="searchModel" (onChange)="searchChanged($event.$selection)" [placeholder]="searchPlaceholder" [enable]="true" [showResults]="true"></sf-search>
    </div>
    <br/>
    <div>
        <span>Debounced Search Text: </span><span>{{debouncedSearchModel.text}}</span>
    </div>
    <div>
        <sf-search [model]="debouncedSearchModel" (onChange)="searchChanged($event.$selection)" [placeholder]="searchPlaceholder"
                   [enable]="true" [debounceTime]="1000" [showResults]="true"></sf-search>
    </div>
    <br/>

    <!-- sf-ui-grid-cell-text-overflow -->
    <!--<div>-->
        <!--<sf-ui-grid-cell-text-overflow [text]="gridCellText" [drawnWidth]="gridCellDrawnWidth" dialogTitle="Dialog!"-->
                                       <!--[rowIsOverflowed]="gridRowIsOverflowed"-->
                                       <!--(rowIsOverflowedChanged)="rowIsOverflowedChanged($event)"></sf-ui-grid-cell-text-overflow>-->
        <!--Is Overflowed: {{gridRowIsOverflowed}}-->
    <!--</div>-->

    <!-- ng-bootstrap toggle-switch -->
    <hr/>
    <div>
        <label>ng-bootstrap toggle-switch: {{toggleModel}}</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="toggleModel" (ngModelChange)="updateSelection({$value: $event})">
            <label class="btn-secondary" ngbButtonLabel>
                <input type="radio" ngbButton value="first" /> First
            </label>
            <label class="btn-secondary" ngbButtonLabel>
                <input type="radio" ngbButton value="second" /> Second
            </label>
            <label class="btn-secondary" ngbButtonLabel>
                <input type="radio" ngbButton value="third" /> Third
            </label>
        </div>
    </div>
    <br />
    <div>
        <label>Primary/Secondary buttons: Enabled</label>
        <div>
            <button class="btn btn-primary">Primary</button>
            &nbsp;
            <button class="btn btn-secondary">Secondary</button>
        </div>
        <label>Primary/Secondary buttons: Disabled</label>
        <div>
            <button disabled="true" class="btn btn-primary">Primary</button>
            &nbsp;
            <button disabled="true" class="btn btn-secondary">Secondary</button>
        </div>
    </div>
    <br />

    <!-- sf-simple-filter -->
    <h4>sfSimpleFilter</h4>
    <div>
        <sf-simple-filter
            [filterOptions]="simpleFilterOptions"
            [placeHolder]="simpleFilterPlaceHolder">
        </sf-simple-filter>
        <p>filterOptions parameter: {{simpleFilterOptions | json}}</p>
    </div>
    <hr/>

    <!-- sf-report-execution -->
<!--    <h4>sfReportExecution</h4>-->
<!--    <div>-->
<!--        <sf-report-execution-->
<!--            [reportData]="sfReportExecutionData">-->
<!--        </sf-report-execution>-->
<!--    </div>-->
<!--    <hr/>-->

    <!-- sf-report-table -->
<!--    <h4>sfReportTable</h4>-->
<!--    <div>-->
<!--        <sf-report-table-old-->
<!--            [reportData]="sfReportTableReportData"-->
<!--            (onRegisterApi)="sfReportTableOnRegisterApi($event)"-->
<!--            [height]="sfReportTableHeight"-->
<!--            [width]="sfReportTableWidth"-->
<!--            [autoHeight]="sfReportTableAutoHeight">-->
<!--        </sf-report-table-old>-->
<!--    </div>-->
<!--    <hr/>-->

    <!-- sf-feedback -->
    <!--<h4>sfFeedback</h4>-->
    <!--<div class="sf-feedback-sandbox-div">-->
        <!--<sf-feedback></sf-feedback>-->
    <!--</div>-->
    <!--<hr/>-->

    <!-- sf-select -->
    <h4>sfSelect</h4>
    <div>
        <sf-select
            [name]="'sfSelectTest'"
            [placeholder]="sfSelectData.placeholder"
            [options]="sfSelectData.options"
            [trackBy]="sfSelectData.trackBy"
            [labelBy]="sfSelectData.labelBy"
            [groupBy]="sfSelectData.groupBy"
            (select)="onSelectSfSelect($event)"
            [hasFilter]="true"
            [filterIcon]="['far', 'filter']"
            (filter)="onFilter($event)"
            (registerApi)="onFilter($event)"
            with-footer="true"
        ></sf-select>
        <div>Selection changed? {{isSelectionChanged}}</div>
        <ul>
            <li *ngFor="let item of selectedItems">{{item.label}}</li>
        </ul>
    </div>

    <!-- sf-date-range-picker -->
    <h4>sfDateRangePicker</h4>
    <div>
        <form #templateForm="ngForm">
            {{templateForm.form.valid}}
            <sf-date-range-picker
                    name="dateRange"
                    [singleRow]="true"
                    [(ngModel)]="test"
                    [required]="true"
            ></sf-date-range-picker>
        </form>
        <form [formGroup]="reactiveForm">
            {{reactiveForm.valid}}
            <sf-date-range-picker
                    [singleRow]="true"
                    formControlName="dateRangeControl"
            ></sf-date-range-picker>
        </form>

    </div>

    <!-- Codemirror -->
    <h4>Codemirror</h4>
    <div #codeMirrorContainer class="codemirror"></div>


    <!-- binding -->
    <h3>bindingInputValue:</h3>
    <textarea rows="15" cols="70" [(ngModel)]='bindingInputValue' (blur)="bindingInputValue">
  </textarea>
    <br>
    <button (click)="convertToAngular()">Convert ng1 Bindings to ng2</button>
    <br>
    <br>

    <button class="test-button">Test Button</button>

    <sf-tour (afterInit)="setTour($event)" (afterTour)="afterTour()" tourName="test-tour">
        <sf-tour-step #step
                      title="Welcome"
                      text="Welcome! We are excited that you are using Simplifile for collaboration. Please click <b>NEXT</b> to start your first tour."
        ></sf-tour-step>
        <sf-tour-step
                title="Navigation"
                text="Use the links on the left to display loans in different statuses. "
                attachTo=".test-button"
        ></sf-tour-step>
        <sf-tour-step
                title="Navigation"
                text="Use the links on the left to display loans in different statuses. "
                attachTo="section.sidebar"
        ></sf-tour-step>
        <sf-tour-step title="Organizations"
                  text="Click <b>ORGANIZATIONS</b> to view or manage your organizations."
                  attachTo=".test-button"></sf-tour-step>
        <sf-tour-step title="Users"
                  text="Click <b>USERS</b> to create new users and update your existing users' settings."
                  attachTo="nav right"></sf-tour-step>
        <sf-tour-step title="Reports"
                  text="Click <b>REPORTS</b> to access and create loan data reports."
                  attachTo="nav right"></sf-tour-step>
        <sf-tour-step title="Help &amp; Support"
                  text="Click <b>HELP & SUPPORT</b> to find Support contact information, access helpful guides, and view video tutorials."
                  attachTo="nav right"></sf-tour-step>
        <sf-tour-step title="User Menu"
                  text="Click your name to  view and edit your personal settings page."
                  attachTo="div.profile-wrapper right"></sf-tour-step>
        <sf-tour-step
                title="Finally"
                text="As you use Simplifile, please let us know if you see anything that could work better or be easier to find. Your feedback directly impacts the development of this application. Thank you!"
                backText="Once Again!"
                [backAction]="backTourAction"
                nextText="Got It!"
                [nextAction]="nextTourAction"
        ></sf-tour-step>
    </sf-tour>
</div>

