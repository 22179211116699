import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganizationService } from "@sf/userorg/common";
import { DOC_STATUS } from "../constants/document-status.enum";

@Component({
    selector: "sf-package-status-modal",
    templateUrl: "./package-status-modal.component.html",
    styleUrls: ["./package-status-modal.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class PackageStatusModalComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    statusMessage: string;
    @Input()
    hasErrors: boolean;
    @Input()
    documents: any[];
    @Input()
    openPrintWindow: (status: string, packageID: string) => void;
    @Input()
    shouldAllowPrint: (packageID: string) => boolean;
    @Input()
    packageID: string;
    @Input()
    packageStatus: string;
    @Input()
    recipientID: string;

    primary: ModalButton;
    secondary: ModalButton;

    rejected = DOC_STATUS.REJECTED;
    customRejectionInstructions: string[];

    public useStatusIcons: boolean =
        sf.liveConfig.SubmitterMenuSettings.useStatusIcons;

    constructor(
        private _activeModal: NgbActiveModal,
        private _organizationService: OrganizationService
    ) {}

    ngOnInit() {
        this.primary = { text: "OK" };
        if (this.shouldAllowPrint(this.packageID)) {
            this.secondary = {
                text: "Print",
                callback: () =>
                    this.openPrintWindow(this.packageStatus, this.packageID)
            };
        }
        this._organizationService
            .getCustomProperty(this.recipientID, "customRejectionInstructions")
            .subscribe((data: any) => {
                if (data) {
                    this.customRejectionInstructions = data.split("|");
                }
            });
    }

    close() {
        this._activeModal.close();
    }
}
