import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SecurityQuestionsService {
    /**
     * NOTE: this service exists because the page it is used on is Angular, and this is to prevent any AngularJS
     * dependencies that aren't loaded. Once everything is migrated, this service could be deprecated and the calls
     * refactored.
     */
    constructor(private _rpcClient: RpcClientService) {}

    userHasSecurityAnswers(userID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "userorg",
            "userHasSecurityAnswers",
            {
                userID: userID
            }
        );
    }

    userHasPhoneSupportAnswer(userID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "userorg",
            "userHasPhoneSupportAnswer",
            {
                userID: userID
            }
        );
    }

    getSecurityQuestionList(): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getSecurityQuestionList"
        );
    }

    getPhoneSupportQuestionList(): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getPhoneSupportQuestionList"
        );
    }

    saveSecurityAnswers(
        userID: string,
        password: string,
        questionAnswerMap: any
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "saveUserSecurityAnswers",
            {
                userID,
                password,
                questionAnswerMap
            }
        );
    }

    savePhoneSupportAnswer(
        userID: string,
        password: string,
        questionAnswerMap: any
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "savePhoneSupportAnswer",
            {
                userID,
                password,
                questionAnswerMap
            }
        );
    }
}
