import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DownloadService } from "@sf/common";

declare const window: any;

@Component({
    selector: "sf-license-fee-paid-dialog",
    templateUrl: "./license-fee-paid-dialog.component.html",
    styleUrls: ["./license-fee-paid-dialog.component.scss"]
})
export class LicenseFeePaidDialogComponent implements OnInit {
    @Input() licenseFeeAmount: number;
    @Input() pdfReceipt64: any;

    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private downloadService: DownloadService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Log In",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        this.secondary = {
            text: "Print Receipt",
            disabled: false,
            callback: this.printReceipt.bind(this)
        };
        this.tertiary = {
            text: "Save Receipt",
            disabled: false,
            callback: this.downloadReceipt.bind(this)
        };

        if (window.bowser.msie) {
            // dumb IE can't see pdf
            this.secondary.hidden = true;
        }
    }

    doPrimary() {
        this.activeModal.close(true);
        this.goToLogin();
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    downloadReceipt() {
        this.downloadService.download64(
            this.pdfReceipt64,
            "application/pdf",
            "simplifile-receipt.pdf"
        );
    }

    printReceipt() {
        //let blob = new Blob([this.pdfReceipt], { type: "application/pdf" });
        let blob = this.downloadService.b64toBlob(
            this.pdfReceipt64,
            "application/pdf"
        );

        let url = URL.createObjectURL(blob);
        let preview = window.open(url, "_blank");
        preview.focus();
    }
}
