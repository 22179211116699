import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    ConfigurationService,
    ConfirmationModalComponent,
    dayjs,
    deepEqual,
    GrowlService,
    SelectableItem,
    SessionOrganization,
    SessionService,
    SfValidators,
    SpinnerService,
    UserSettingsService
} from "@sf/common";
import {
    Address,
    ImageUpdateType,
    LockoutService,
    Phone,
    TokenService,
    UserDetails,
    UserDetailsUser,
    UserOrgService,
    UserService,
    UserSubscriptionService
} from "@sf/userorg/common";
import { PersonalSettingsService } from "../../services/personal-settings.service";
import { ActivatedRoute } from "@angular/router";
import { UploadProfilePictureDialogComponent } from "../../dialogs/upload-profile-picture-dialog/upload-profile-picture-dialog.component";
import { RemoveProfilePictureDialogComponent } from "../../dialogs/remove-profile-picture-dialog/remove-profile-picture-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditPersonalContactAddressSettingsPersonalDialogComponent } from "../../dialogs/edit-personal-contact-address-settings-personal-dialog/edit-personal-contact-address-settings-personal-dialog.component";
import { UnregisterAuthenticatorDialogComponent } from "../../dialogs/unregister-authenticator-dialog/unregister-authenticator-dialog.component";
import { QrCodeDialogComponent } from "../../dialogs/qr-code-dialog/qr-code-dialog.component";
import { AddMobilePhoneDialogComponent } from "../../dialogs/add-mobile-phone-dialog/add-mobile-phone-dialog.component";
import { VerifyPhoneNumberDialogComponent } from "../../dialogs/verify-phone-number-dialog/verify-phone-number-dialog.component";
import { Subject } from "rxjs";
import { ChangePasswordSettingsPersonalDialogComponent } from "../../dialogs/change-password-settings-personal-dialog/change-password-settings-personal-dialog.component";
import { EditSecurityQuestionsSettingsPersonalDialogComponent } from "../../dialogs/edit-security-questions-settings-personal-dialog/edit-security-questions-settings-personal-dialog.component";
import { EditSupportQuestionSettingsPersonalDialogComponent } from "../../dialogs/edit-support-question-settings-personal-dialog/edit-support-question-settings-personal-dialog.component";
import { UiRecipient } from "@sf/submitter/common";
import { EditEmailDialogComponent } from "../../dialogs/edit-email-dialog/edit-email-dialog.component";

interface LocalUser extends UserDetailsUser {
    phoneExtension?: string;
    mobilePhoneVerifiedDate?: string;
    removeImage?: boolean; // hack for profile image remove
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-settings-personal",
    templateUrl: "./settings-personal.component.html",
    styleUrls: ["./settings-personal.component.scss"]
})
export class SettingsPersonalComponent implements OnInit, OnDestroy {
    private _onDestroy: Subject<void> = new Subject<void>();

    user: LocalUser;
    originalUser: LocalUser;

    isSharedEmail: boolean = false;
    isSuperUser: boolean;
    selectedPrefix: string;
    allowSecondaryJobTitle = false;
    allowNameVariations = false;
    showDefaultRecipient = false;
    showDefaultOrg = false;
    showHomePage = false;
    totpRegistered = false;
    securityQuestionsRequired: boolean;
    supportQuestionRequired: boolean;
    otherNames: string;
    currentLandingPage: string;
    landingPages: SelectableItem[] = [];
    landingPageSelectorApi: any;
    defaultOrgID: string;
    useDefaultOrgForBankingExportSettings: boolean;
    defaultRecipientID: string;
    recipientSelectorData: SelectableItem[];    // was RecipientSelectorData
    accessibleSubmitterOrgIDs: string[];
    selectableSubmitterOrgs: SelectableItem[] = [];

    prefixOptions: SelectableItem[] = [
        { option: "", label: "(No Prefix)" },
        { option: "Mr", label: "Mr" },
        { option: "Mrs", label: "Mrs" },
        { option: "Miss", label: "Miss" },
        { option: "Ms", label: "Ms" },
        { option: "Mx", label: "Mx" },
        { option: "Dr", label: "Dr" }
    ];

    constructor(
            private personalSettingsService: PersonalSettingsService,
            private userOrgService: UserOrgService,
            private spinnerService: SpinnerService,
            private userOrgTokenService: TokenService,
            private userService: UserService,
            private sessionService: SessionService,
            private modalService: NgbModal,
            private userSettingsService: UserSettingsService,
            private lockoutService: LockoutService,
            private growlService: GrowlService,
            private configurationService: ConfigurationService,
            private userSubscriptionService: UserSubscriptionService,
            private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isSuperUser = this.sessionService.isSuperUser();

        this.readUserData();

        // Home Page / Landing Page
        if (this.isSuperUser) {
            this.landingPages = [];
            this.showHomePage = false;
        } else {
            this.userOrgService.getUserLandingPages()
                .subscribe((results: any) => {
                    if (results) {
                        this.currentLandingPage = results.currentLandingPage;
                        this.landingPages = results.userLandingPages;
                        if (!this.currentLandingPage) {
                            // don't make user think they need to select this option deliberately
                            this.currentLandingPage = "DEFAULT";
                        }
                    }
                });
            this.showHomePage = true;
        }

        // Security Questions and Support Question
        this.userOrgService.areUserSecurityQuestionsRequired(this.sessionService.getUserID())
            .subscribe((req: boolean) => {
                this.securityQuestionsRequired = req;
            });
        this.lockoutService.isPhoneSupportAnswerRequired(this.sessionService.getUserID())
            .subscribe((req: boolean) => {
                this.supportQuestionRequired = req;
            });

        // Submitter-specific stuff
        const hasSubmitterProduct = this.sessionService.hasProductInAnyOrg("submitter");
        if (!this.isSuperUser && hasSubmitterProduct) {
            // Default Organization
            this.showDefaultOrg = true;
            this.defaultOrgID = this.sessionService.getDefaultOrganizationID();
            this.useDefaultOrgForBankingExportSettings = this.userSettingsService.getUserSetting(
                    "USE_DEFAULT_ORG_FOR_BANKING_EXPORT_SETTINGS");

            // Default Recipient
            this.showDefaultRecipient = this.sessionService.hasPermissionInAnyOrg("submitter_package_create");
            this.defaultRecipientID = this.userSettingsService.getUserSetting("DEFAULT_RECIPIENT_ID");
            this.reloadCounties();

            // List of Submitter organizations that are selectable for Default Organization
            let orgList: SessionOrganization[] = this.sessionService.getOrganizationsWithProduct("submitter");
            if (orgList) {
                this.selectableSubmitterOrgs = [];
                this.selectableSubmitterOrgs.push({
                    option: "",
                    label: "(No Default Organization)"
                });
                orgList.forEach((org) => {
                    this.selectableSubmitterOrgs.push({
                        option: org.id,
                        label: org.name + " (" + org.id + ")"
                    });
                });
            }

            // Weird rare Secondary Title thing
            this.accessibleSubmitterOrgIDs = this.sessionService.getOrganizationIDsWithProduct("submitter");
            this.personalSettingsService.getSubmitterOrganizationsSettings(this.accessibleSubmitterOrgIDs)
                .subscribe((settings: any) => {
                    this.allowSecondaryJobTitle = false;
                    Object.keys(settings).forEach((key: string) => {
                        if (settings[key].submitterConfiguration?.configurationMap?.allow_secondary_title) {
                            this.allowSecondaryJobTitle = true;
                        }
                    });
                });
        }

        // Name Variations for 'print driver' users
        this.allowNameVariations = this.sessionService.hasAnyPermissionInAnyOrg([
            "third_party_documents_print_driver"
        ]);
        if (this.allowNameVariations) {
            this.personalSettingsService.getUserMapping(this.sessionService.getUserID())
                .subscribe((results: any) => {
                    if (results && results.names && results.names.length) {
                        this.otherNames = results.names.join("\r\n");
                    }
                });
        }

        // enablers for Mobile Phone and Authenticator App
        this.userService.isRegisteredForTOTP()
            .subscribe((registered: boolean) => {
                this.totpRegistered = registered;
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    readUserData() {
        // Get all the normal User data
        this.userOrgService.getUserDetails().subscribe((userDetails: UserDetails) => {
            this.user = userDetails.user;
            this.setUserSettings();
            this.checkForDuplicateEmail();
        });
    }

    setUserSettings() {
        this.selectedPrefix = "";
        if (this.user.name.prefix) {
            let fp = this.prefixOptions.find((p) => {
                return p.option == this.user.name.prefix;
            });
            if (fp) {
                this.selectedPrefix = fp.option;
            }
        }

        this.user.phoneString = this.phoneStringFromPhone(this.user.phone, false);
        if (this.user.phone) {
            this.user.phoneExtension = this.user.phone.extension;
        }
        this.user.mobilePhoneString = this.phoneStringFromPhone(this.user.mobilePhone, false);

        this.user.emailVerifiedDate = this.userSettingsService.getUserSetting("EMAIL_VERIFIED_DATE");
        if (this.user.emailVerifiedDate?.toString() == "Never") {
            this.user.emailVerifiedDate = null;
        }
        this.getMobilePhoneVerifiedDate();

        // hack for profile image remove option
        this.user.removeImage = false;

        this.originalUser = JSON.parse(JSON.stringify(this.user));
    }

    reloadCounties() {
        if (!this.showDefaultRecipient || this.isSuperUser || !this.defaultOrgID) {
            this.recipientSelectorData = null;
            return;
        }

        this.personalSettingsService.getAllAvailableCountiesBySubmitter(this.defaultOrgID)
            .subscribe((counties: UiRecipient[]) => {
                if (counties) {
                    this.recipientSelectorData = [];
                    this.recipientSelectorData.push({
                        option: "",
                        label: "(No Default County)"
                    });
                    let foundCounty = false;
                    counties.forEach((county) => {
                        let label = county.name;
                        if (county.state) {
                            label += (", " + county.state);
                        }
                        this.recipientSelectorData.push({
                            option: county.id,
                            label: label
                        });
                        if (this.defaultRecipientID == county.id) {
                            foundCounty = true;
                        }
                    });
                    if (!foundCounty && this.defaultRecipientID) {
                        this.defaultRecipientID = "";
                        this.saveDefaultCounty("");
                    }
                }
            });
    }

    phoneStringFromPhone(phone: Phone, includeExtension: boolean): string {
        if (!phone) {
            return "";
        }
        let str = phone.areaCode + "." + phone.prefix + "." + phone.suffix;
        if (includeExtension && phone.extension) {
            str += " x" + phone.extension;
        }
        return str;
    }

    phoneFromPhoneString(text: string): Phone {
        if (!text) {
            return null;
        }
        let numbers = SfValidators.getNumericDigits(text);
        if (!numbers || numbers.length != 10) {
            return null;
        }
        return {
            areaCode: numbers.substr(0, 3),
            prefix: numbers.substr(3, 3),
            suffix: numbers.substr(6, 4)
        };
    }

    checkForDuplicateEmail() {
        if (!this.user || !this.user.email)
            return;

        this.userOrgService.countUsersWithThisEmailAddress(this.user.email)
            .subscribe((count: number) => {
                this.isSharedEmail = count > 1;
            });
    }

    save() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }

        let errorMessage = this.validate();
        if (errorMessage) {
            this.growlService.error(errorMessage);
            return;
        }

        //trim whitespace
        this.user.name.firstName = this.user.name.firstName.trim();
        this.user.name.middleName = this.user.name.middleName.trim();
        this.user.name.lastName = this.user.name.lastName.trim();
        this.user.title = this.user.title.trim();
        this.user.phone = this.phoneFromPhoneString(this.user.phoneString);
        this.user.phoneString = this.phoneStringFromPhone(this.user.phone, true);
        if (this.user.phone && this.user.phoneExtension) {
            this.user.phone.extension = this.user.phoneExtension;
        }
        this.user.mobilePhone = this.phoneFromPhoneString(this.user.mobilePhoneString);

        // check for changes
        if (deepEqual(this.user, this.originalUser)) {
            // no change
            return;
        }

        this.userOrgService.setUserDetails(this.user)
            .subscribe((url: string) => {
                if (url) {
                    this.growlService.success(url, "Email Verification URL:", { disableTimeOut: true });
                }
                this.growlService.success("Settings saved.");
                this.user.removeImage = false;
                this.readUserData();
                this.originalUser = JSON.parse(JSON.stringify(this.user));
                this.userSubscriptionService.profileWasChanged();
            }, (error: string) => {
                //this.growlService.error(error);   // error already displayed
            });
    }

    saveOtherNames() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const nameVariations = !this.otherNames ? [] : this.otherNames?.split("\n");
        this.personalSettingsService.saveUserMapping(this.user.userID, nameVariations).subscribe(() => {
            this.growlService.success("Settings saved.");
        });
    }

    validate(): string {
        if (!this.user.name.firstName) {
            return "First name is required.";
        }
        if (!this.user.name.lastName) {
            return "Last name is required.";
        }
        if (!this.user.phoneString && !this.user.mobilePhoneString) {
            return "Phone number or Mobile Phone number is required.";
        }
        if (!this.user.email) {
            return "Email address is required.";
        }
        if (!this.user.title) {
            return "Job title is required.";
        }

        if (this.user.phoneExtension) {
            if (!this.user.phoneString) {
                return "You must have a phone number to have a phone extension";
            }
            if (!this.userOrgService.isPositiveIntegerString(this.user.phoneExtension)) {
                return "Extension must be numbers only.";
            }
        }
        if (this.user.phoneString) {
            const original = this.user.phoneString;
            if (original) {
                let numbers = SfValidators.getNumericDigits(original);
                if (numbers.length != 10) {
                    return `Phone number must contain exactly 10 digits: ${original}`;
                }
            }
        }

        if (this.isSuperUser) {
            if (!this.userOrgService.validateSimplifileEmailDomain(this.user.email)) {
                return "Simplifile organization users should have a simplifile.com, theice.com, ice.com, or elliemae.com email address.";
            }
        }

        if (SfValidators.isAllWhitespace(this.user.name.firstName)) {
            return "First name cannot be whitespace";
        }
        if (SfValidators.isAllWhitespace(this.user.name.middleName)) {
            return "Middle name cannot be whitespace";
        }
        if (SfValidators.isAllWhitespace(this.user.name.lastName)) {
            return "Last name cannot be whitespace";
        }
        if (SfValidators.isAllWhitespace(this.user.title)) {
            return "Title cannot be whitespace";
        }

        return null;
    }

    // -- PROFILE section stuff

    setPrefix(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let prefixItem: SelectableItem = event.$selection;
            let prefix: string = null;
            if (prefixItem.option) {
                prefix = prefixItem.option;
            }
            if (!prefix) {
                prefix = null;
            }
            this.user.name.prefix = prefix;
            this.selectedPrefix = this.user.name.prefix;
            this.save();
        }
    }

    clickUploadProfilePic(context: string) {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(UploadProfilePictureDialogComponent, {
            backdrop: "static",
            size: "lg"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.imageUpdateItem = {
            imageType: ImageUpdateType.USER,
            imageURL: this.user.imageURL,
            dialogTitle: "Upload Profile Picture",
            dialogText: "Click the Choose File button to select your photo. The file size cannot be larger than two megabytes.",
            isIE: window.bowser.name.search(/Internet Explorer/i) >= 0,
            isSafari: window.bowser.name.search(/Safari/i) >= 0
        };

        modalInstance.imageURL = this.user.imageURL;

        modalRef.result.then((imageUpdateItem) => {
            if (imageUpdateItem) {
                this.user.imageURL = imageUpdateItem.imageURL;
                this.user.removeImage = false;
                this.save();
            }
        }, () => {
            // cancel
        });
    }

    clickRemoveProfilePic() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(RemoveProfilePictureDialogComponent, {
            backdrop: "static",
            size: "sm"
        });

        const modalInstance = modalRef.componentInstance;

        modalInstance.dialogTitle = "Remove Profile Picture";
        modalInstance.dialogText = "Are you sure you want to remove your profile picture?";

        modalRef.result.then((removeProfilePicture: boolean) => {
            this.user.imageURL = null;
            this.user.removeImage = true;
            this.save();
        }, () => {
            // cancel
        });
    }

    // -- CONTACT section stuff

    getMobilePhoneVerifiedDate() {
        let mpvd = this.userSettingsService.getUserSetting("MOBILE_PHONE_VERIFIED_DATE");
        if (mpvd && mpvd.toString() && mpvd.toString() != "Never") {
            this.user.mobilePhoneVerifiedDate = mpvd.toString();
        }
    }

    clickEditPersonalContactAddress() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(EditPersonalContactAddressSettingsPersonalDialogComponent, {
            backdrop: "static",
            windowClass: "edit-address-dialog"
        });

        const modalInstance = modalRef.componentInstance;

        modalInstance.addressData = this.user.address;

        modalRef.result.then((address: Address) => {
            if (address) {
                this.user.address = address;
                this.save();
            }
        }, () => {
            // cancel
        });
    }

    verifyEmail() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(EditEmailDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.username = this.user.username;

        modalRef.result.then((results: any) => {
            if (results) {
                this.readUserData();
            }
        }, () => {
            // cancel
        });
    }

    registerAuthenticator() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        if (this.totpRegistered) {
            const modal = this.modalService.open(ConfirmationModalComponent);
            const modalInstance = modal.componentInstance;
            modalInstance.unmaskModal = true;
            modalInstance.title = "Re-register";
            modalInstance.primary = {
                text: "Continue",
                responseValue: 1
            };
            modalInstance.secondary = {
                text: "Cancel",
                responseValue: 0
            };
            modalInstance.tertiary = {
                text: "Unregister",
                responseValue: -1
            };
            modalInstance.message =
                    "You are already registered with an authenticator. Do you want to re-register? " +
                    "<br/>If you continue, your previous registration will become invalid.";

            modal.result.then((result) => {
                if (result == 1) {
                    this.registerAuthenticatorDialog();
                } else if (result == -1) {
                    this.unregisterAuthenticatorDialog();
                }
            }, () => {
                // cancel
            });
        } else {
            this.registerAuthenticatorDialog();
        }
    }

    unregisterAuthenticatorDialog() {
        const modalRef = this.modalService.open(UnregisterAuthenticatorDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;

        modalRef.result.then((results: any) => {
            if (results) {
                this.growlService.success("You are now unregistered from authenticator apps.");
                this.totpRegistered = false;
            }
        }, () => {
            // cancel
        });
    }

    registerAuthenticatorDialog() {
        const modalRef = this.modalService.open(QrCodeDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;

        modalRef.result.then((results: any) => {
            if (results) {
                this.growlService.success("You are now registered for authenticator apps.");
                this.totpRegistered = true;
            }
        }, () => {
            // cancel
        });
    }

    editMobilePhone() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(AddMobilePhoneDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.phoneNumber = this.user.mobilePhoneString;

        modalRef.result.then((results: any) => {
            if (results) {
                let newNumber = results.number;
                if (!newNumber || newNumber == "delete") {
                    this.user.mobilePhoneString = null;
                    this.smudgeMobilePhoneNumber();
                } else if (this.user.mobilePhoneString != newNumber) {
                    // update the number
                    this.user.mobilePhoneString = newNumber;
                    this.smudgeMobilePhoneNumber();
                    // now verify it
                    this.verifyNewMobilePhone(newNumber, results.destination);
                } else {
                    this.verifyNewMobilePhone(newNumber, results.destination);
                }
            }
        }, () => {
            // cancel
        });
    }

    smudgeMobilePhoneNumber() {
        // number is not verified (yet) now
        this.userSettingsService.setUserSetting("MOBILE_PHONE_VERIFIED_DATE", "Never");
        this.user.mobilePhoneVerifiedDate = null;
        this.save();
    }

    verifyNewMobilePhone(numberToTest: string, destination: string) {
        const modalRef = this.modalService.open(VerifyPhoneNumberDialogComponent, {
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.phoneNumber = numberToTest;
        modalInstance.destination = destination;

        modalRef.result.then((newNumber: string) => {
            if (newNumber) {
                this.user.mobilePhoneString = newNumber;
                let mom: dayjs.Dayjs = dayjs();
                this.user.mobilePhoneVerifiedDate = mom.format("YYYY-MM-DD");
                this.successMessage("Mobile Phone Verified", "Success! " +
                        "Your mobile phone number is now verified and can be used as an authentication option to log into Simplifile.");
            }
        }, () => {
            // cancel
        });
    }

    successMessage(title: string, message: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;
        modalInstance.unmaskModal = true;
        modalInstance.title = title;
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
        modalInstance.message = message;
    }

    // -- APP section

    clickChangePassword() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        this.spinnerService.startSpinner();
        this.userService.isTwoFactorAuthRequired().subscribe((twoFactorRequired: boolean) => {
            this.spinnerService.stopSpinner();
            if (twoFactorRequired) {
                this.userOrgTokenService.sendPasswordResetEmail(null)
                    .subscribe((url: string) => {
                        if (url) {
                            this.growlService.success("Password reset URL: " + url, null, { disableTimeOut: true });
                        }
                        this.growlService.success(
                                "A 'password reset' email message was just sent to you. Follow the instructions in that email.",
                                null, { timeOut: 10000 });
                    });
            } else {
                const modalRef = this.modalService.open(ChangePasswordSettingsPersonalDialogComponent, {
                    size: "lg",
                    backdrop: "static"
                });
            }
        }, () => {
            this.spinnerService.stopSpinner();
        });
    }

    clickEditSecurityQuestions() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(EditSecurityQuestionsSettingsPersonalDialogComponent, {
            size: "lg",
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;

        modalInstance.userID = this.user.userID;
    }

    clickEditSupportQuestion() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        const modalRef = this.modalService.open(EditSupportQuestionSettingsPersonalDialogComponent, {
            size: "lg",
            backdrop: "static"
        });

        const modalInstance = modalRef.componentInstance;

        modalInstance.userID = this.user.userID;
    }

    registerLandingPageApi($api: any) {
        this.landingPageSelectorApi = $api;
    }

    setUseDefaultOrgForBankingExportSettings() {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        setTimeout(() => {
            let useDefaultOrgForBankingExportSettings: boolean = this.useDefaultOrgForBankingExportSettings;
            this.userSettingsService.setUserSetting("USE_DEFAULT_ORG_FOR_BANKING_EXPORT_SETTINGS",
                    useDefaultOrgForBankingExportSettings);
            this.growlService.success("Settings saved.");
        }, 100);
    }

    chooseDefaultRecipient(event: any) {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        if (event.hasOwnProperty("$selection")) {
            let defaultItem: SelectableItem = event.$selection;
            let county = "";
            if (defaultItem) {
                county = defaultItem.option;
            }
            if (!county) {
                county = "";
            }
            let newDefaultRecipientID = county;
            this.saveDefaultCounty(newDefaultRecipientID);
        }
    }

    saveDefaultCounty(countyID: string) {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        if (!countyID) {
            countyID = "";
        }
        this.configurationService.setUserSetting(this.sessionService.getUsername(), "default_recipient_id", countyID)
            .subscribe(() => {
                this.userSettingsService.setUserSetting("DEFAULT_RECIPIENT_ID", countyID);
                this.defaultRecipientID = countyID;
                this.growlService.success("Settings saved.");
            });
    }

    chooseDefaultOrg(event: any) {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        if (event.hasOwnProperty("$selection")) {
            let defaultItem: SelectableItem = event.$selection;
            let defaultOrgID = "";
            if (defaultItem) {
                defaultOrgID = defaultItem.option;
            }
            if (!defaultOrgID) {
                defaultOrgID = "";
            }
            this.userOrgService.setMyDefaultOrganization(defaultOrgID)
                .subscribe((result: any) => {
                    this.sessionService.setDefaultOrganizationID(defaultOrgID);
                    this.defaultOrgID = defaultOrgID;
                    this.reloadCounties();
                    this.growlService.success("Settings saved.");
                });
        }
    }

    chooseHomePage(event: any) {
        if (this.sessionService.isSystemUser()) {
            // just don't do anything
            return;
        }
        if (event.hasOwnProperty("$selection")) {
            let newLandingPageItem: SelectableItem = event.$selection;
            let newLandingPageOption = newLandingPageItem.option;
            this.userOrgService.setMyLandingPage(newLandingPageOption)
               .subscribe((newURL: string) => {
                   // Landing page is saved on the backend, but we must update sessionService.userSettings to match
                   //this.userSettingsService.setUserSetting("LANDING_PAGE", newLandingPageOption);
                   if (newURL) {
                       // URL needs to start with "/sf/ui"
                       if (!newURL.startsWith("/sf/ui")) {
                           newURL = "/sf/" + newURL;
                       }
                       this.sessionService.setHomePageUrl(newURL);
                   }
                   this.currentLandingPage = newLandingPageOption;
                   this.growlService.success("Settings saved.");
               });
        }
    }
}
