import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from "@angular/common/http";

import { Observable } from "rxjs";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.body instanceof FormData || req.url.includes("/sf/longpoll/")) {
            return next.handle(req);
        }
        const newRequest = req.clone({
            body: {
                data: req.body,
                socketid:
                    !!sf.session && !!sf.session.socketId
                        ? sf.session.socketId
                        : ""
            }
        });

        return next.handle(newRequest);
    }
}
