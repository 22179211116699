import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalButton } from "@sf/common";
import { Location } from "@angular/common";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-private-event-dialog",
    templateUrl: "./private-event-dialog.component.html",
    styleUrls: ["./private-event-dialog.component.scss"]
})
export class PrivateEventDialogComponent implements OnInit {
    static readonly MODAL_OPTIONS: NgbModalOptions = {
        backdrop: "static",
        keyboard: false
    };

    @Input()
    eventName: string;
    @Input()
    eventStatus: string;

    primary: ModalButton;
    onWorkflowPage: boolean = false;

    constructor(
        private _location: Location,
        private _router: Router,
        private _modalService: NgbModal
    ) {}

    ngOnInit(): void {
        const path = this._location.path();
        this.onWorkflowPage = path.includes("/workflow");
        if (this.onWorkflowPage) {
            this.primary = {
                text: "Exit Event",
                callback: () => {
                    const status = this.eventStatus?.toLowerCase() || "draft";
                    this._router.navigateByUrl(`/sign-event/events/${status}`);
                    this._modalService.dismissAll();
                    return true;
                }
            };
        } else {
            this.primary = {
                text: "Close"
            };
        }
    }
}
