import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LazyLoadedResource } from "../navigation/interfaces/lazy-loaded-resource";

@Injectable({
    providedIn: "root"
})
export class FabricCanvasService implements LazyLoadedResource {
    private _fabric: any;
    constructor() {}

    get fabric(): any {
        return this._fabric;
    }

    lazyLoadResource(): Observable<boolean> {
        return new Observable((observer) => {
            if (typeof (window as any).fabric === "undefined") {
                import("fabric").then(
                    (m) => {
                        this._fabric = m.fabric;
                        this._fabric.Object.prototype.cornerColor = "#FF0000";
                        this._fabric.Object.prototype.borderColor = "#FF0000";
                        this._fabric.Object.prototype.transparentCorners =
                            false;
                        this._fabric.Canvas.prototype.controlsAboveOverlay =
                            true;

                        this._fabric.BaseBrush.prototype.strokeLineCap =
                            "round";
                        this._fabric.BaseBrush.prototype.strokeLineJoin =
                            "round";
                        this._fabric.BaseBrush.prototype.color = "black";
                        this._fabric.BaseBrush.prototype.width = 50;
                        this._fabric.Object.prototype.objectCaching = false;
                        this._fabric.enableGLFiltering = false;
                        observer.next(true);
                        observer.complete();
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
            } else {
                this._fabric = (window as any).fabric;
                this._fabric.Object.prototype.cornerColor = "#FF0000";
                this._fabric.Object.prototype.borderColor = "#FF0000";
                this._fabric.Object.prototype.transparentCorners = false;
                this._fabric.Canvas.prototype.controlsAboveOverlay = true;

                this._fabric.BaseBrush.prototype.strokeLineCap = "round";
                this._fabric.BaseBrush.prototype.strokeLineJoin = "round";
                this._fabric.BaseBrush.prototype.color = "black";
                this._fabric.BaseBrush.prototype.width = 50;
                this._fabric.Object.prototype.objectCaching = false;
                this._fabric.enableGLFiltering = false;
                observer.next(true);
                observer.complete();
            }
        });
    }
}
