import { Injectable } from "@angular/core";
import { TrayModulesService, TrayService } from "@sf/tray";
import { SessionService, UserSettingsService } from "@sf/common";
import { catchError } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { UtilitiesRequestFormatterService } from "../services/utilities-request-formatter.service";
import { UserSettingsConstants } from "../constants/user-settings-constants";

@Injectable({
    providedIn: "root"
})
export class ErecordTrayService {
    // TODO: This can be a static getter once trayModulesService has been fully migrated
    public get SUBMITTER_VERSION() {
        return this._trayModulesService.MODULES.ENHANCED_PRINT_AND_DOWNLOAD
            .VERSION;
    }

    constructor(
        private _trayModulesService: TrayModulesService,
        private _trayService: TrayService,
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService,
        private _submitterUtilitiesRequestFormatter: UtilitiesRequestFormatterService
    ) {}

    /**
     * Returns true or false depending on whether the user is allowed to use the tray app
     * @returns {boolean}
     */
    allowUse() {
        return this._trayModulesService.isEnabled(
            this._trayModulesService.MODULES.ENHANCED_PRINT_AND_DOWNLOAD
        );
    }

    /**
     * Returns true or false depending on whether the tray is enabled and linked
     * @returns {boolean}
     */
    trayEnabled() {
        return this._trayService.trayEnabled();
    }

    /**
     * Returns true or false depending on whether the tray is enabled and the use print setting is true
     * @returns {boolean}
     */
    usePrint() {
        return (
            this.trayEnabled() &&
            this.allowUse() &&
            this.userSettingsService.getUserSetting(
                UserSettingsConstants.USE_SUBMITTER_SIMPLIFILE_SERVICES_PRINT
            )
        );
    }

    /**
     * Returns true or false depending on whether the tray is enabled and the use download setting is true
     * @returns {boolean}
     */
    useDownload() {
        return (
            this.trayEnabled() &&
            this.allowUse() &&
            this.userSettingsService.getUserSetting(
                UserSettingsConstants.USE_SUBMITTER_SIMPLIFILE_SERVICES_DOWNLOAD
            )
        );
    }

    /**
     * Sets whether or not the user should use the tray app to print
     * @param {boolean} usePrint
     */
    setUsePrint(usePrint: boolean): Observable<any> {
        return of(
            this.userSettingsService.setUserSetting(
                UserSettingsConstants.USE_SUBMITTER_SIMPLIFILE_SERVICES_PRINT,
                usePrint
            )
        );
    }

    /**
     * Sets whether or not the user should use the tray app to download
     * @param {boolean} useDownload
     */
    setUseDownload(useDownload: boolean): Observable<any> {
        return of(
            this.userSettingsService.setUserSetting(
                UserSettingsConstants.USE_SUBMITTER_SIMPLIFILE_SERVICES_DOWNLOAD,
                useDownload
            )
        );
    }

    /**
     * Data structure for a print request
     * @typedef PrintOptions
     * @property {string[]} packageIDs
     * @property {string[]} documentIDs
     * @property {boolean} includeDocs
     * @property {boolean} includeHelpers
     * @property {boolean} pkgFeeReport
     * @property {boolean} docFeeReport
     * @property {boolean} useCustomPageRange
     * @property {string} customPageRange
     */

    /**
     * Sends a request to the tray app to print with the given print options
     * @param {PrintOptions} printOptions
     * @returns {Promise}
     */
    print(printOptions: any) {
        return this._trayService
            .sendMessage({
                module: "submitter",
                method: "print",
                dependencies: {
                    submitter: this.SUBMITTER_VERSION
                },
                data: this._submitterUtilitiesRequestFormatter.createPrintRequest(
                    printOptions
                )
            })
            .pipe(catchError((err) => this._cleanErrors(err)));
    }

    /**
     * Data structure for a download request
     * @typedef DownloadOptions
     * @property {boolean} includeDocs
     * @property {boolean} includeHelpers
     * @property {string} fileName
     * @property {"pdf", "tiff"} outputType
     * @property {string} fileNameFormat
     * @property {string[]} documentIDs
     * @property {string[]} packageIDs
     * @property {boolean} combineDocs
     * @property {boolean} combineDocFeeReports
     * @property {boolean} combinePackagesIntoOneFolder
     * @property {boolean} pkgFeeReport
     * @property {boolean} pkgFeeReportsWithDoc
     * @property {boolean} docFeeReport
     * @property {boolean} docFeeReportsWithDoc
     */

    /**
     * Sends a request to the tray app to download with the given download options
     * @param {DownloadOptions} downloadOptions
     * @returns {Promise}
     */
    download(downloadOptions: any) {
        return this._trayService
            .sendMessage({
                module: "submitter",
                method: "download",
                dependencies: {
                    submitter:
                        this._trayModulesService.MODULES
                            .ENHANCED_PRINT_AND_DOWNLOAD.VERSION
                },
                data: this._submitterUtilitiesRequestFormatter.createDownloadRequest(
                    downloadOptions
                )
            })
            .pipe(catchError((err) => this._cleanErrors(err)));
    }

    /**
     * Process the rejection error and returns an error with the cleaned data
     * @param {{data: *, method: string, module: string}} rejection
     * @returns {Promise<any>}
     */
    private _cleanErrors(rejection: any) {
        return throwError(rejection.data);
    }
}
