import { Injectable } from "@angular/core";
import { SessionOrganization, SessionService } from "@sf/common";
import { Navigation, NavItem } from "@sf/common";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { transformLinkWithParams } from "@sf/common";
import { TrayModulesService } from "@sf/tray";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class SettingsPersonalNavResolverService {
    navigation$: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(
        null
    );

    constructor(
        private _sessionService: SessionService,
        private _trayModulesService: TrayModulesService
    ) {}

    getPages(
        isSuperUser: boolean,
        route: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot
    ): Observable<Observable<Navigation>> {
        this._getNavigation(isSuperUser, route);

        return of(this.navigation$);
    }

    private _getNavigation(
        isSuperUser: boolean,
        route: ActivatedRouteSnapshot
    ) {
        let parent: NavItem = this._getParentNavItem(isSuperUser);
        let items: NavItem[] = [];

        if (isSuperUser) {
            items.push({
                label: "Profile",
                link: transformLinkWithParams(["/admin/settings/personal"], route)
            });
        } else {
            items.push({
                label: "Profile",
                link: transformLinkWithParams(["/settings/personal"], route)
            });
        }

        items.push(
            {
                label: "Roles",
                link: transformLinkWithParams(["/settings/roles"], route)
            },
            {
                label: "Notifications",
                link: transformLinkWithParams(
                    ["/settings/notifications"],
                    route
                )
            }
        );

        if (!isSuperUser) {
            this._getNotaryPages(items, route);

            if (
                this._sessionService.hasProductInAnyOrg("submitter") ||
                this._sessionService.hasProductInAnyOrg("recipient")
            ) {
                this._getErecordPages(items, route);
            }

            if (this._sessionService.hasProductInAnyOrg("submitter")) {
                this._getSubmitterPages(items, route);
            }
            if (this._sessionService.hasAnyProductInAnyOrg(["lender", "settlement"])) {
                this._getLenderPages(items, route);
            }
            if (this._sessionService.hasProductInAnyOrg("vendor")) {
                this._getVendorPages(items, route);
            }
            if (this._sessionService.hasProductInAnyOrg("recipient")) {
                this._getRecipientPages(items, route);
            }
            this._getGeneralPages(items, route);
        }

        let navigation: Navigation = {
            parent,
            items
        };
        this.navigation$.next(navigation);
    }

    private _getParentNavItem(isSuperUser: boolean): NavItem {
        let parent: NavItem = {
            label: "",
            link: []
        };

        if (isSuperUser) {
            parent.label = "Main Menu";
            parent.link.push("/admin/mainmenu");
        } else if (this._sessionService.hasProductInAnyOrg("recipient")) {
            parent.label = "Recipient";
            parent.link.push("/recipient");
        } else if (this._sessionService.hasPermissionInAnyOrg("erecord_view_document")) {
            if (!this._sessionService.hasAnyPermissionInAnyOrg([
                "submitter_package_viewer"
            ]) && this._sessionService.hasAnyPermissionInAnyOrg([
                "submitter_signing_poa_admin"
            ])) {
                parent.label = "Needs POA";
                parent.link.push("/signing/needs-poa-list");
            } else if (this._sessionService.isAnyOrganizationSetUp()) {
                parent.label = "Packages";
                parent.link.push("/submitter/packages");
            } else if (this._sessionService.hasAnyPermissionInAnyOrg([
                "organization_accounting", "admin_payment_account_management"
            ])) {
                parent.label = "Payment Accts";
                parent.link.push("/submitter/payments/manage");
            } else {
                // default here
                parent.label = "Packages";
                parent.link.push("/submitter/packages");
            }
        } else if (!this._sessionService.hasPermissionInAnyOrg("submitter_package_viewer") &&
                this._sessionService.hasPermissionInAnyOrg("submitter_reports")) {
            parent.label = "Reports";
            parent.link.push("/submitter/reporting/reports");
        } else if (this._sessionService.hasAnyPermissionInAnyOrg([
            "capc_view_loan_info", "capc_view_document"
        ])) {
            parent.label = "Loans";
            // Per comments from Lender team on PS-8455 that this should go to the user default home page
            let parentLink: string = this._sessionService.getHomePageUrl();
            // parentLink should start with '/sf/ui/' - need to strip that off
            parentLink = parentLink.substring(6);
            parent.link.push(parentLink);
        } else if (this._sessionService.hasPermissionInAnyOrg("esign_event_view_event")) {
            parent.label = "Esign Events";
            parent.link.push("/sign-event/events/draft");
        } else if (this._sessionService.hasAnyPermissionInAnyOrg(["vendor_admin"])) {
            parent.label = "Dashboard";
            parent.link.push("/vendor/dashboard");
        } else if (this._sessionService.hasAnyPermissionInAnyOrg(["vendor_admin"]) &&
                this._sessionService.hasAnyPermissionInAnyOrg(["vendor_reports"])) {
            parent.label = "Reports";
            parent.link.push("/vendor/reports/revenue-share");
        } else if (this._sessionService.hasAnyPermissionInAnyOrg([
            "organization_info", "organization_accounting"
        ])) {
            if (this._sessionService.hasProductInAnyOrg("lender")) {
                parent.label = "Organizations";
                parent.link.push("/lender/organizations");
            } else if (this._sessionService.hasProductInAnyOrg("vendor")) {
                parent.label = "Organizations";
                parent.link.push("/vendor/organizations");
            } else {
                parent.label = "Organizations";
                parent.link.push("/submitter/organizations");
            }
        } else if (this._sessionService.hasAnyPermissionInAnyOrg([
            "signing", "trustee"
        ])) {
            if (this._sessionService.hasAnyPermissionInAnyOrg([
                "submitter_signing_dashboard",
                "submitter_signing_package_signer",
                "submitter_signing_package_witness",
                "submitter_signing_package_notary",
                "trustee_signer",
                "trustee_notary",
                "submitter_signing_data_entry",
                "submitter_signing_poa_admin",
                "submitter_signing_review_management",
                "trustee_reviewer",
                "submitter_signing_management",
                "trustee_admin",
                "third_party_documents_manage_unsignable",
                "third_party_documents_upload_analyzed_document",
                "submitter_signing_manage_print_queue",
                "submitter_signing_print_checks"
            ])) {
                parent.label = "Signing";
                parent.link.push("/signing/dashboard");
            } else {
                parent.label = "Organizations";
                parent.link.push("/submitter/organizations");
            }
        } else if (this._sessionService.hasAnyPermissionInAnyOrg([
            "third_party_documents_print_driver"
        ])) {
            parent = null;
        }

        return parent;
    }

    private _getNotaryPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        if (
            this._sessionService.hasAnyPermissionInAnyOrg([
                "organization_notary_credentials_add"
            ])
        ) {
            items.push({
                label: "Notary",
                link: transformLinkWithParams(
                    ["/settings/notary-credentials"],
                    route
                ),
                children: []
            });
            if (
                this._sessionService.hasAnyPermissionInAnyOrg([
                    "submitter_signing_package_notary",
                    "trustee_notary",
                    "capc_signing_package_notary",
                    "esign_event_notary",
                    "notary_user"
                ])
            ) {
                // notary should have been the last one added.
                items[items.length - 1].children.push({
                    label: "Notary Journal",
                    link: transformLinkWithParams(
                        ["/settings/notary-journal"],
                        route
                    )
                });
            }
        }
    }

    private _getErecordPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        // add pages that only apply to 'submitter' and 'recipient' users
        if (this._sessionService.hasAnyPermissionInAnyOrg([
            "organization_signature_click_sign_add", "submitter_signature_digital_add", "recipient_add_modify_signature"
        ])) {
            let link = "/settings/signature";
            items.push({
                label: "Signature",
                link: transformLinkWithParams([link], route)
            });
        }
    }

    private _getSubmitterPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        // none at this time
    }

    // add pages that only apply to 'lender' users
    private _getLenderPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        // There was code in sfSettingsPageService.js that added the ORGANIZATIONS and HELP & SUPPORT pages (lender versions)
        // Not sure if that is still needed since those pages are available on the main left nav
    }

    // add pages that only apply to 'vendor' users
    private _getVendorPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        // none at this time
    }

    // add pages that only apply to 'recipient' users
    private _getRecipientPages(
        items: NavItem[],
        route: ActivatedRouteSnapshot
    ) {
        // none at this time
    }

    private _getGeneralPages(items: NavItem[], route: ActivatedRouteSnapshot) {
        if (this._trayModulesService.isAnyModuleEnabled() || this._trayModulesService.allowDownload()) {
            items.push({
                label: "Utilities",
                link: ["/settings/tray-setup"],
                children: []
            });
            if (this._sessionService.hasAnyPermissionInAnyOrg([
                "third_party_documents_print_driver"
            ])) {
                const index: number = items.findIndex((item: any) => item.label === "Utilities");
                if (index !== -1) {
                    items[index].children.push({
                        label: "Print to Simplifile Status",
                        link: transformLinkWithParams(["/settings/tray-print-driver-status"], route)
                    });
                }
            }
        }

        let orgs: SessionOrganization[] = this._sessionService.getAllOrganizations();
        let onlyLender: boolean = true, onlySubmitter: boolean = true, onlyRecipient: boolean = true;
        orgs.forEach((org: SessionOrganization) => {
            onlyLender = onlyLender && org.activeServices.findIndex((service) => service.id == "lender") != -1;
            onlySubmitter = onlySubmitter && org.activeServices.findIndex((service) => service.id == "submitter") != -1;
            onlyRecipient = onlyRecipient && org.activeServices.findIndex((service) => service.id == "recipient") != -1;
        });
        if (onlySubmitter && !onlyLender && !onlyRecipient) {
            let helpUrl =
                !this._sessionService.hasPermissionInAnyOrg(
                    "submitter_package_viewer"
                ) &&
                this._sessionService.hasPermissionInAnyOrg("signing")
                    ? "/signing/help"
                    : "/submitter/help";
        }
    }
}
