import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { UserPermission } from "../interfaces/user-permission.interface";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class CurrentPermissionService {
    private _currentPermission: BehaviorSubject<UserPermission> = new BehaviorSubject(null);

    public currentPermission$ = this._currentPermission.pipe(
            distinctUntilChanged((newPermission: UserPermission, oldPermission: UserPermission) => {
                // don't send notice unless it's a new permission, or name changed, or status changed
                return !this.permissionChanged(newPermission, oldPermission);
            }),
            shareReplay(1)
    );

    constructor() {}

    getCurrentPermission(): UserPermission | null {
        return this._currentPermission.getValue();
    }

    // Convenience method for ID
    getCurrentPermissionID(): string {
        let permission = this._currentPermission.getValue();
        if (!permission) {
            return null;
        }
        return permission.permissionID;
    }

    updateCurrentPermission(permission: UserPermission): void {
        let currentPermissionNow = this.getCurrentPermission();
        if (this.permissionChanged(currentPermissionNow, permission)) {
            if (permission && !permission.permissionID) {
                log.error("Invalid call to updateCurrentPermission with partial permission");
            }
            this._currentPermission.next(permission);
        }
    }

    permissionChanged(currentPermission: UserPermission, newPermission: UserPermission): boolean {
        if (!currentPermission && !newPermission) {
            // both null
            return false;
        }
        if (!currentPermission || !newPermission) {
            // one is null
            return true;
        }
        if (currentPermission.permissionID !== newPermission.permissionID) {
            // different id means different permission
            return true;
        }
        if (currentPermission.label && newPermission.label) {
            if (currentPermission.label != newPermission.label) {
                return true;
            }
        } else if (newPermission.label) {
            return true;    // adding name
        }
        return false;
    }
}
