<sf-modal-header title="Remove User Roles"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="loading">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
        <span> Loading...</span>
    </div>
    <div class="" *ngIf="!loading && organizations && organizations.length">
        <div *ngIf="isSuperUser" class="extra-bottom">
            <span>Are you sure you want to disable the user </span>
            <b class="sf-mask">{{userFirstLast}} ({{userID}})</b>
            <span> by removing all their Role Assignments?</span>
        </div>
        <div *ngIf="!isSuperUser" class="extra-bottom">
            <span>Are you sure you want to remove the user </span>
            <b class="sf-mask">{{userFirstLast}} ({{userID}})</b>
            <span> by removing all their Role Assignments?</span>
        </div>
        <div class="scroll-section">
            <div *ngFor="let organization of organizations">
                <div style="margin-left: 24px">
                    <b>Organization: </b>
                    <span>{{organization.name}} ({{organization.id}})</span>
                </div>
                <div style="margin-left: 24px" *ngFor="let role of organization.assignments">
                    <b>Role: </b>
                    <span>{{role.name}}</span>
                </div>
                <br/>
            </div>
        </div>
    </div>
    <div *ngIf="!loading && (!organizations || !organizations.length)">
        <div class="extra-bottom">
            <span>Either this user doesn't have any roles, or you don't have permission to remove them.</span>
        </div>
        <div class="extra-bottom sf-mask">
            <b>{{userFirstLast}}</b>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
