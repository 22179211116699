import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class OpenDocumentService {
    private _documentViewer: string = `document/viewer`;
    // private _document: string = "document";

    constructor(private router: Router) {}

    /**
     * Open the doc viewer
     * @param documentID
     * @param replaceLocation - true if navigation is coming from a temporary redirect page. This will replace that
     *                          page in the history so that the browser back button will not take the user there.
     */
    openDocument(documentID: string): void {
        this.router.navigateByUrl(`${this._documentViewer}/${documentID}`, {
            replaceUrl: true
        });
    }

    getDocumentLink(documentID: string): string {
        return `/sf/ui/${this._documentViewer}/${documentID}`;
    }
}
