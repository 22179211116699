import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ModalButton } from "@sf/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-trayapp-print-dialog",
    templateUrl: "./trayapp-print-dialog.component.html",
    styleUrls: ["./trayapp-print-dialog.component.scss"]
})
export class TrayappPrintDialogComponent implements OnInit {
    @Output() printModeUpdated = new EventEmitter<string>();

    printModeForm: FormGroup;
    primary: ModalButton;

    constructor(
        private _fb: FormBuilder,
        private _activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.primary = {
            text: "OK",
            callback: this._updatePrintMode.bind(this)
        };

        this.printModeForm = this._fb.group({
            printMode: this._fb.control("browser", [])
        });
    }

    private _updatePrintMode() {
        this.printModeUpdated.emit(this.printModeForm.get("printMode").value);
        this._activeModal.close();
    }
}
