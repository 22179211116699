import { Component, Input, OnInit } from "@angular/core";
import { ContractService } from "@sf/userorg/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalButton } from "@sf/common";
import { dayjs } from "@sf/common";

interface EnrollmentCode {
    [key: string]: any; // hack to allow code['templateName']
    contractType: string;
    templateName: string;
    registrationCode: string;
    contractTemplateFees: any;
}

/**
 * This is for super-users to select an enrollment code
 */
// prettier-ignore
@Component({
    selector: "sf-enrollment-code-select-dialog",
    templateUrl: "./enrollment-code-select-dialog.component.html",
    styleUrls: ["./enrollment-code-select-dialog.component.scss"]
})
export class EnrollmentCodeSelectDialogComponent implements OnInit {
    @Input()
    contractType: string; // example: "ERECORDING"

    templates: EnrollmentCode[] = null;
    filteredTemplates: EnrollmentCode[] = null;
    isLoading = true;
    sortKey = "";
    sortAscending = true;
    query = {
        text: ""
    };
    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private contractService: ContractService
    ) {}

    ngOnInit() {
        this.primary = {
            text: "Invite",
            disabled: false,
            callback: this.closeDialog.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.closeDialog.bind(this)
        };

        this.submitSearch();
    }

    submitSearch() {
        this.isLoading = true;
        this.contractService.getPricingTemplates().subscribe(
            (results: EnrollmentCode[]) => {
                results.forEach(
                    (template) =>
                        (template.dateCreated = dayjs(template.dateCreated))
                );
                results = results.filter((result) => {
                    return (
                        result.contractType == this.contractType &&
                        result.enabled
                    );
                });
                this.templates = results;
                this.resort();
                this.isLoading = false;
            },
            () => {
                // handle errors
                this.isLoading = false;
            }
        );
    }

    doFilter() {
        let text: string = "";
        if (this.query && this.query.text) {
            text = this.query.text;
        }
        if (text) {
            text = text.toLowerCase();
            this.filteredTemplates = this.templates.filter((template) => {
                let vals =
                    "" + template.templateName + template.registrationCode;
                vals = vals.toLowerCase();
                return vals.indexOf(text) != -1;
            });
        } else {
            // just copy
            this.filteredTemplates = this.templates.slice();
        }
    }

    initialList() {
        this.query = {
            text: ""
        };
        this.doFilter();
    }

    resort() {
        if (this.sortKey) {
            this.templates = this.templates.sort((a, b) => {
                let vala: string = a[this.sortKey];
                let valb: string = b[this.sortKey];
                let response: number = vala.localeCompare(valb);
                if (!this.sortAscending) response = -response;
                return response;
            });
        } else {
            this.templates = this.templates.sort(
                (a, b) => b.dateCreated - a.dateCreated
            );
        }
        this.doFilter();
    }

    clickItem(template: EnrollmentCode) {
        this.activeModal.close(template);
    }

    hasData() {
        return this.templates && this.templates.length > 0;
    }

    changeSort(field: string) {
        if (field == this.sortKey) {
            // reverse sort order
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortKey = field;
            this.sortAscending = true;
        }
        this.resort();
    }

    closeDialog() {
        this.activeModal.close(null);
    }

    hasTiers(template: EnrollmentCode) {
        switch (template.contractType) {
            case "ERECORDING":
                return (
                    template.contractTemplateFees.SUBMISSION_FEE &&
                    template.contractTemplateFees.SUBMISSION_FEE
                        .contractFeeTiers.length > 1
                );
            case "DOCUMENT_BUILDER":
                return (
                    template.contractTemplateFees
                        .DOCUMENT_BUILDER_DOCUMENT_FEE &&
                    template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE
                        .contractFeeTiers.length > 1
                );
            default:
                break;
        }

        return false;
    }

    getTierTooltip(template: EnrollmentCode) {
        switch (template.contractType) {
            case "ERECORDING":
                return this.generateTierTooltip(
                    template.contractTemplateFees.SUBMISSION_FEE
                );
            case "DOCUMENT_BUILDER":
                return this.generateTierTooltip(
                    template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE
                );
            default:
                break;
        }

        return null;
    }

    generateTierTooltip(contractFee: any): string {
        let tooltip: string = contractFee.thresholdResetPeriod + "<br/>";
        let index = 0;
        contractFee.contractFeeTiers.forEach((tier: any) => {
            tooltip =
                tooltip + "$" + tier.feeAmount + " / " + tier.tierThreshold;
            if (index < contractFee.contractFeeTiers.length - 1) {
                tooltip += "<br/>";
            }
            index++;
        });

        return tooltip;
    }
}
