import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class BrightcoveService {
    constructor() {}

    public injectBrightcoveScript() {
        const script = document.createElement("script");
        script.src =
            "https://players.brightcove.net/5805274531001/experience_5eda8e9b0b3c8700213de5c9/live.js";
        script.async = true;
        document.body.appendChild(script);
    }
}
