import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ModalButton } from "../../interfaces/modal-button";

@Component({
    selector: "sf-basic-modal",
    templateUrl: "./base-modal.component.html",
    styleUrls: ["./base-modal.component.scss"],
    host: { "[class.sf-unmask]": "unmaskModal" }
})
export class BaseModalComponent<TemplateContextType = any> implements OnInit {
    @Input()
    title: string;
    @Input()
    message: string;
    @Input()
    template: TemplateRef<TemplateContextType>;
    @Input()
    primary: ModalButton;
    @Input()
    secondary: ModalButton;
    @Input()
    tertiary: ModalButton;
    @Input()
    titleIcon?: IconProp;
    @Input()
    templateContext?: TemplateContextType;
    @Input()
    bodyStyles: {
        [p: string]: any;
    } | null = null;
    @Input()
    showDismiss = true;
    @Input()
    unmaskModal?: boolean = false;

    constructor() {}

    ngOnInit() {
        if (!this.message && !this.template) {
            throw Error("Either message or template MUST be defined");
        } else if (this.message && this.template) {
            throw Error(
                "Only ONE content variable can be defined.  Only set message OR template, not both."
            );
        }

        if (!this.primary && !this.secondary) {
            throw Error("At least one button MUST be configured!");
        }

        (this.templateContext as any) = {
            $implicit: this.templateContext
        };
    }
}
