<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div class="explain" *ngIf="!invitation">
        This will send an invitation to '{{organization.name}}' to enroll for the '{{product.label}}' service.
    </div>
    <div class="explain" *ngIf="invitation">
        This will resend an invitation to '{{organization.name}}' to enroll for the '{{product.label}}' service.<br/>
        Any previous invitations will be canceled and new emails sent to selected users.
    </div>
    <div class="explain spinner" *ngIf="loading">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </div>
    <div *ngIf="!loading && !organization.enabled" class="explain">
        This organization is disabled, so an invitation can't be sent.
    </div>
    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <div *ngIf="organization.enabled && product.productID!='submitter_vendor' && product.productID!='recipient_vendor' && product.productID!='lender_vendor'"
            class="row bottom-space">
        <div class="col-sm-6">
            Enrollment Code (Optional):
        </div>
        <div class="col-sm-6">
            <span *ngIf="!selectedEnrollmentTemplate">
                <button class="btn btn-link sf-btn-link" (click)="showEnrollmentCode()">
                    Add Enrollment Code
                </button>
            </span>
            <span *ngIf="!!selectedEnrollmentTemplate">
                <b> {{selectedEnrollmentTemplate.registrationCode}} </b>
                <button type="button" class="btn btn-secondary btn-small" (click)="changeEnrollmentCode()">
                    Change
                </button>
                <button class="btn btn-secondary btn-small" (click)="removeEnrollmentCode()">
                    Remove
                </button>
            </span>
        </div>
    </div>

    <div *ngIf="organization.enabled">
        <!-- submitter e-record -->
        <div *ngIf="product.productID=='submitter'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="licenseFeeSubmitter">Initial License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="licenseFeeSubmitter" [(ngModel)]="licenseFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="renewalFeeSubmitter">Renewal License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="renewalFeeSubmitter" [(ngModel)]="renewalFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="submissionFee">Submission Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="submissionFee" [(ngModel)]="submissionFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="freeSubmissions">Free Submissions:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="freeSubmissions" class="form-control input-short"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        [(ngModel)]="freeSubmissions" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- document builder -->
        <div *ngIf="product.productID=='submitter_signing'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="licenseFeeSigning">Initial License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="licenseFeeSigning" [(ngModel)]="licenseFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="renewalFeeSigning">Renewal License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="renewalFeeSigning" [(ngModel)]="renewalFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="documentFee">Document Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="documentFee" [(ngModel)]="documentFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="freeDocuments">Free Documents:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="freeDocuments" [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control input-short" [(ngModel)]="freeSubmissions" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- doc builder RON -->
        <div *ngIf="product.productID=='doc_builder_ron'">
            <div class="row">
                <div class="col-sm-6">
                    <label for="ronEventFee">Document Builder RON Event Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="ronEventFee" [(ngModel)]="ronEventFee"
                           [disabled]="!!selectedEnrollmentTemplate" required
                           class="form-control money-input" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- esign_event -->
        <div *ngIf="product.productID=='esign_event'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="licenseFeeEsignevent">Initial License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="licenseFeeEsignevent" [(ngModel)]="licenseFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="renewalFeeEsignevent">Renewal License Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="renewalFeeEsignevent" [(ngModel)]="renewalFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="notarizationFeeEsignevent">eSign Only Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="notarizationFeeEsignevent" [(ngModel)]="notarizationFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="ipenFeeEsignevent">IPEN Notary Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="ipenFeeEsignevent" [(ngModel)]="ipenFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="ronFeeEsignevent">RON Notary Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="ronFeeEsignevent" [(ngModel)]="ronFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="freeEvents">Free Transactions:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="freeEvents" class="form-control input-short"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        [(ngModel)]="freeSubmissions" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- trustee -->
        <div *ngIf="product.productID=='trustee'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="documentFeeTrustee">Document Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="documentFeeTrustee" [(ngModel)]="documentFee"
                        [disabled]="!!selectedEnrollmentTemplate" required
                        class="form-control money-input" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- lien release -->
        <div *ngIf="product.productID=='mers_api_integration'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="lienReleaseFee">Automated Document Request Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="lienReleaseFee" [(ngModel)]="lienReleaseFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- subscription -->
        <div *ngIf="product.productID=='subscription'" class="">
            <div class="row">
                <div class="col-sm-6">
                    <label for="subscriptionMinimum">Monthly Minimum:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="subscriptionMinimum" [(ngModel)]="subscriptionMinimum"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- paper recording -->
        <div *ngIf="product.productID=='submitter_paper'">
            <div class="row">
                <div class="col-sm-6">
                    <label for="inStateErecordSubmissionFee">In-State eRecording Submission Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="inStateErecordSubmissionFee" [(ngModel)]="inStateErecordSubmissionFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="outOfStateErecordSubmissionFee">Out of State eRecording Submission Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="outOfStateErecordSubmissionFee" [(ngModel)]="outOfStateErecordSubmissionFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="inStatePaperSubmissionFee">In-State Paper Submission Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="inStatePaperSubmissionFee" [(ngModel)]="inStatePaperSubmissionFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="outOfStatePaperSubmissionFee">Out of State Paper Submission Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="outOfStatePaperSubmissionFee" [(ngModel)]="outOfStatePaperSubmissionFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="paperMailFee">Mail Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="paperMailFee" [(ngModel)]="paperMailFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="embeddedEmployeeFee">Embedded Employee Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="embeddedEmployeeFee" [(ngModel)]="embeddedEmployeeFee"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input" maxlength="10"/>
                </div>
            </div>
        </div>

        <!-- vendor -->
        <div *ngIf="product.productID=='submitter_vendor' || product.productID=='recipient_vendor' || product.productID=='lender_vendor'">
            <div class="row">
                <div class="col-sm-6">
                    <label>Revenue Share Option:</label>
                </div>
                <div class="col-sm-6">
                    <sf-select class=""
                        [selectedOption]="selectedShareOption.id"
                        [options]="selectableShareOptions"
                        (select)="selectShareOption($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select an option..."
                        [required]="true">
                    </sf-select>
                </div>
            </div>
            <div *ngIf="selectedShareOption.id == 'PERCENT'" class="row">
                <div class="col-sm-6">
                    <label for="feepercent">Submission Fee Percentage:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control money-input" maxlength="8" id="feepercent"
                        [(ngModel)]="submissionFee" required/>
                    <span>%</span>
                </div>
            </div>
            <div *ngIf="selectedShareOption.id == 'PERCENT'" class="row">
                <div class="col-sm-6">
                    <label for="exfeepercent">Exclusive Submission Fee Percentage:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control money-input" maxlength="8" id="exfeepercent"
                        [(ngModel)]="exclusiveSubmissionFee" required/>
                    <span>%</span>
                </div>
            </div>
            <div *ngIf="selectedShareOption.id == 'FLAT'" class="row">
                <div class="col-sm-6">
                    <label for="feeflat">Per Document Flat Amount:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" class="form-control money-input" maxlength="10" id="feeflat"
                        [(ngModel)]="submissionFee" required/>
                </div>
            </div>
            <div *ngIf="selectedShareOption.id == 'FLAT'" class="row">
                <div class="col-sm-6">
                    <label for="exfeeflat">Exclusive Per Document Flat Amount:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" class="form-control money-input" maxlength="10" id="exfeeflat"
                        [(ngModel)]="exclusiveSubmissionFee" required/>
                </div>
            </div>
        </div>

        <!-- licensed collaborator -->
        <div *ngIf="product.productID=='licensed_collaborator_lender' || product.productID=='licensed_collaborator_settlement'">
            <div class="row">
                <div class="col-sm-6">
                    <label for="loanCreateFee">Loan Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="loanCreateFee" [(ngModel)]="loanCreateFee" maxlength="10"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="renewalFee2">Renewal Fee:</label>
                </div>
                <div class="col-sm-6">
                    <span>$</span>
                    <input type="text" id="renewalFee2" [(ngModel)]="renewalFee" maxlength="10"
                            [disabled]="!!selectedEnrollmentTemplate" required
                            class="form-control money-input"/>
                </div>
            </div>
            <div *ngIf="product.productID=='licensed_collaborator_settlement'">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="disbursementFee">Disbursement Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="disbursementFee" [(ngModel)]="disbursementFee" maxlength="10"
                                [disabled]="!!selectedEnrollmentTemplate" required
                                class="form-control money-input"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="verifiedInsecureFee">Verified w/o Secure Disbursement Fee:</label>
                    </div>
                    <div class="col-sm-6">
                        <span>$</span>
                        <input type="text" id="verifiedInsecureFee" [(ngModel)]="verifiedInsecureFee" maxlength="10"
                                [disabled]="true"
                                class="form-control money-input"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="freeLoans">Free Loans:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="freeLoans" class="form-control input-short" required
                            [disabled]="!!selectedEnrollmentTemplate" [(ngModel)]="freeSubmissions" maxlength="8"/>
                </div>
            </div>
        </div>

        <div *ngIf="product.productID != 'subscription'" class="row">
            <div class="col-sm-6">
                <label *ngIf="product.productID != 'submitter' && product.productID != 'submitter_paper'">Billing Cycle:</label>
                <label *ngIf="product.productID == 'submitter' || product.productID == 'submitter_paper'">Submission Fees Billing Cycle:</label>
            </div>
            <div class="col-sm-6">
                <sf-select
                        [selectedOption]="billingCycle"
                        [options]="selectableBillingCycles"
                        (select)="selectBillingCycle($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a billing cycle..."
                        [isDisabled]="!!selectedEnrollmentTemplate && !!(selectedEnrollmentTemplate.billingCycleType)">
                </sf-select>
            </div>
        </div>
        <div *ngIf="product.productID == 'submitter' || product.productID == 'submitter_paper'" class="row">
            <div class="col-sm-6">
                <label>Recording Fees Billing Cycle:</label>
            </div>
            <div class="col-sm-6">
                <sf-select
                        [selectedOption]="recordingFeeBillingCycle"
                        [options]="selectableBillingCycles"
                        (select)="selectRecordingFeeBillingCycle($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a billing cycle...">
                </sf-select>
            </div>
        </div>
        <div *ngIf="product.productID != 'subscription'" class="row">
            <div class="col-sm-6">
                <label>Payment Terms:</label>
            </div>
            <div class="col-sm-6">
                <sf-select
                        [selectedOption]="paymentTerm"
                        [options]="selectablePaymentTerms"
                        (select)="selectPaymentTerm($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a payment term..."
                        [isDisabled]="!!selectedEnrollmentTemplate && !!(selectedEnrollmentTemplate.paymentTerms)">
                </sf-select>
            </div>
        </div>
        <div class="row" *ngIf="product.productID=='esign_event'">
            <div class="col-sm-6 input-label">License Renewal Frequency:</div>
            <div class="col-sm-6">
                <sf-select class="standard-spacing"
                        [selectedOption]="renewalFrequency"
                        [options]="selectableRenewalFrequencies"
                        (select)="selectRenewalFrequency($event)"
                        trackBy="option" labelBy="label"
                        [isSingleSelect]="true"
                        placeholder="Select a renewal frequency..."
                        [isDisabled]="!!selectedEnrollmentTemplate && !!selectedEnrollmentTemplate.renewalFrequency">
                </sf-select>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <label for="salesforceID">Salesforce Opportunity ID
                    <button class="btn btn-link sf-btn-link"
                            ngbTooltip="Enter the 18-character Salesforce Opportunity ID if this customer exists in Salesforce, so a duplicate account and opportunity are not created">
                        <fa-icon icon="info-circle"></fa-icon>
                    </button>
                </label>
            </div>
            <div class="col-sm-6">
                <input type="text" id="salesforceID" [(ngModel)]="salesforceID" maxlength="20"
                        class="form-control"/>
            </div>
        </div>

        <div *ngIf="adminUsers.length">
            <div class="top-space">
                Select one or more administrators in the organization to receive the invitation email:
            </div>
            <div class="explain-2 user-selector">
                <ul class="selector-bottom">
                    <li *ngFor="let user of adminUsers">
                        <label>
                            <input type="checkbox" value="{{user.username}}" [(ngModel)]="user.checked"/>
                            <span>&nbsp;{{user.label}}</span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="!adminUsers.length" class="top-space">
            <span>This organization does not have any administrator users.
                Add an administrator role to a user first, or choose to send to a non-user.</span>
        </div>
    </div>
    <div *ngIf="organization.enabled && !invitation" class="top-space">
        <button class="btn btn-link sf-btn-link" (click)="chooseNonUser()">Send invitation to a non-user</button>
    </div>
</div>
<sf-modal-footer *ngIf="!requestSent" [primary]="primary" [secondary]="secondary"
    [tertiary]="tertiary"></sf-modal-footer>
<div *ngIf="requestSent" class="bottom-spinner">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
</div>
