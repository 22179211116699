<sf-modal-header title="Simplifile Utilities Needs to Re-link"></sf-modal-header>
<div class="modal-body">
    <p>
        The Simplifile Utilities application is not currently linked to this browser
        or is not running. Please click below to relink the application.
    </p>

    <h4>
        Link Simplifile Utilities
    </h4>
    <button class="btn btn-secondary" (click)="performLink()" [attr.disabled]="performingLinking ? '' : null">
        Relink Application <fa-icon [icon]="['fad', 'spinner']" [spin]="true" *ngIf="performingLinking"></fa-icon>
    </button>

    <h6 class="mt-4"><em>App not installed?</em></h6>
    <a [href]="trayInstallerUrl">
        <fa-icon [icon]="['far', 'download']"></fa-icon> Download Installer
    </a>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
