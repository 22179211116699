import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AddressVerificationService {
    constructor(private _rpcClient: RpcClientService) {}

    verifyAddress(address: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "verifyAddress", {
            address: address
        });
    }

    isPOBox(address: any): boolean {
        if (this.poCompare(address.street)) {
            return true;
        }
        if (this.poCompare(address.street1)) {
            return true;
        }
        if (this.poCompare(address.street2)) {
            return true;
        }
        return false;
    }

    private poCompare(street: string): boolean {
        if (street) {
            let upperStreet = street.toUpperCase();
            if (
                upperStreet.startsWith("PO BOX") ||
                upperStreet.startsWith("P.O. BOX")
            ) {
                return true;
            }
        }
        return false;
    }
}
