<sf-modal-header title="Customer Approval"></sf-modal-header>
<div class="modal-body">
    <div class="explain">{{explainText}}</div>
    <div class="explain spinner" *ngIf="loading">
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
    </div>
    <div *ngIf="!loading && (!adminUsers || !adminUsers.length)" class="explain">
        Since this organization does not have any administrators yet,
        any users in the organization that have an 'admin' role will be prompted to approve the license
        when they log in.
    </div>
    <div *ngIf="adminUsers && adminUsers.length">
        {{selectPrompt}}
    </div>
    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <div class="user-selector new-section" *ngIf="adminUsers && adminUsers.length">
        <ul class="selector-bottom">
            <li *ngFor="let user of adminUsers">
                <label>
                    <input *ngIf="!singleInvite" type="checkbox" name="admin" value="{{user.username}}"
                        [(ngModel)]="user.checked"/>
                    <input *ngIf="singleInvite" type="radio" name="admin" value="{{user.username}}"
                        [(ngModel)]="selectedUser.username"/>
                    <span>&nbsp;{{user.label}}</span>
                </label>
            </li>
        </ul>
    </div>
    <div class="new-section">
        <button class="btn btn-link sf-btn-link" (click)="chooseNonUser()">Send invitation to a non-user</button>
    </div>
    <div class="new-section">
        <span>Additional Details (Internal Only)</span>
        <textarea name="changeDetails" id="changeDetails" [(ngModel)]="changeDetails" class="form-control" required
                rows="3" style="resize: none;"></textarea>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary" ></sf-modal-footer>
