import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable()
export class TransformResponseInterceptor implements HttpInterceptor {
    private _in401Navigation = false;

    constructor() {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === 401 &&
                    !this._in401Navigation &&
                    !req.url.includes("log-metrics")
                ) {
                    // this keeps multiple 401 navigations from being processed at the same time
                    this._in401Navigation = true;
                    if (
                        window.location.search === "?restricted=true" ||
                        window.location.search.includes("isepc")
                    ) {
                        // LS-12873 viewing the app in encompass  don't redirect with a way to log back in.
                        log.getLogger(LoggerType.FALCON).debug(
                            "Invalid authentication (transform) - redirect to Encompass Login"
                        );
                        window.location.assign(
                            "/sf/ui/login?err=restrictedExpired&app=Encompass"
                        );
                    } else {
                        let redirect = encodeURIComponent(
                            window.location.pathname.replace(/sf\//, "") +
                                (window.location.search
                                    ? window.location.search
                                    : "")
                        );
                        log.getLogger(LoggerType.FALCON).warn(
                            "Invalid authentication (transform) - redirect to: " +
                                redirect
                        );
                        window.location.assign(
                            "/sf/ui/login?fr=" + redirect + "&err=expired"
                        );
                    }
                }
                return throwError(error);
            }),
            map((event: HttpEvent<any>) => {
                if (
                    !req.url.includes("sf/uploadFile") &&
                    !req.url.includes("uploadDocument")
                ) {
                    if (
                        event instanceof HttpResponse &&
                        event.body.success === true
                    ) {
                        if (
                            event.body.data !== null &&
                            typeof event.body.data !== "undefined"
                        ) {
                            return event.clone({ body: event.body.data });
                        }

                        return event.clone({ body: null });
                    } else if (
                        event instanceof HttpResponse &&
                        event.status === 200 &&
                        event.body.success === false
                    ) {
                        throw new HttpErrorResponse({
                            error: event.body,
                            headers: event.headers,
                            status: event.status,
                            statusText: event.statusText,
                            url: event.url
                        });
                    }
                }
                return event;
            })
        );
    }
}
