import { Injectable, Injector, Type } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from "@angular/router";
import { combineLatest, Observable } from "rxjs";
import { LazyLoadedResource } from "../interfaces/lazy-loaded-resource";

@Injectable({
    providedIn: "root"
})
export class LazyLoadResourceResolverService implements Resolve<any> {
    constructor(private _injector: Injector) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any[]> {
        const resources: Type<LazyLoadedResource>[] = route.data.lazyResources
            ? route.data.lazyResources
            : [];

        const resolvedResources = resources.map((resource) =>
            this._injector.get(resource)
        );

        return combineLatest(
            resolvedResources.map((resolvedResource) =>
                resolvedResource.lazyLoadResource()
            )
        );
    }
}
