import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GrowlService, SessionService } from "@sf/common";
import { ConfirmationModalComponent } from "@sf/common";
import { TokenService } from "@sf/userorg/common";
import { SpinnerService } from "@sf/common";
import { AcceptLicenseService } from "../../services/accept-license.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AcceptLicenseDialogComponent } from "../../dialogs/accept-license-dialog/accept-license-dialog.component";
import { LicenseAcceptedDialogComponent } from "../../dialogs/license-accepted-dialog/license-accepted-dialog.component";
import { PrintService } from "@sf/erecord";
import { PrintLicenseDialogComponent } from "../../dialogs/print-license-dialog/print-license-dialog.component";
import { SendLicenseDialogComponent } from "../../dialogs/send-license-dialog/send-license-dialog.component";
import { UserOrgService } from "@sf/userorg/common";
import { SignContractResponse } from "../../interfaces/sign-contract-response";

declare const window: any;

interface SignerData {
    firstName: string;
    middleName?: string;
    lastName: string;
    title: string;
    email: string;
    phone?: string;
    extension?: string;
}

// prettier-ignore
@Component({
    selector: "sf-sign-license",
    templateUrl: "./sign-license.component.html",
    styleUrls: ["./sign-license.component.scss"]
})
export class SignLicenseComponent implements OnInit, OnDestroy, OnChanges {
    @Input() contractId: string;
    @Input() signerData: SignerData;
    @Output() actionTaken = new EventEmitter<string>();

    /* public variables */
    loaded = false;
    loadedContractID: string = null;
    licenseContents: SafeHtml = "No contract template found.";
    isUpgrade = false;
    isModifiedContract = false;
    isMSA = false;
    canSkip = false;
    signContractInfo: SignContractResponse = null;

    constructor(
        private modalService: NgbModal,
        private spinnerService: SpinnerService,
        private sessionService: SessionService,
        private acceptLicenseService: AcceptLicenseService,
        private tokenService: TokenService,
        private userorgService: UserOrgService,
        private sanitizer: DomSanitizer,
        private growlService: GrowlService,
        private printService: PrintService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.contractId && changes.contractId.currentValue &&
                changes.contractId.currentValue !== changes.contractId.previousValue) {
            this.loadContract();
        }
    }

    ngOnInit() {
        this.loadContract();
    }

    ngOnDestroy() {
    }

    loadContract() {
        if (!this.contractId) {
            // nothing to load - yet
            this.licenseContents = "It is taking longer than expected to build your contract document.";
            setTimeout(() => {
               this.loaded = true;
            }, 10000);
            return;
        }
        if (this.contractId == this.loadedContractID) {
            return;     // same as what we have
        }

        this.loadedContractID = this.contractId;
        this.acceptLicenseService.getSignContractInfo(this.contractId)
            .subscribe((response: SignContractResponse) => {
                this.signContractInfo = response;
                if (response) {
                    this.licenseContents = this.sanitizer.bypassSecurityTrustHtml(response.html);

                    if (this.signContractInfo.contractType == "MSA") {
                        this.isMSA = true;
                        if (this.signContractInfo.oldStyleContractType) {
                            if (this.signContractInfo.oldStyleContractType == this.signContractInfo.addendumType) {
                                this.isModifiedContract = true;
                            } else {
                                this.isUpgrade = true;
                            }
                        } else if (this.signContractInfo.hasCapc) {
                            this.isUpgrade = true;
                        } else if (this.signContractInfo.userHasOtherOrg) {
                            this.isUpgrade = true;
                        }
                    } else {
                        if (this.signContractInfo.contractSigned) {
                            this.isModifiedContract = true;
                        } else {
                            this.isUpgrade = true;
                        }
                    }

                    if (this.signContractInfo.contractSigned) {
                        this.isModifiedContract = true;
                    }

                    if (this.isMSA || response.userLoggedIn) {
                        this.canSkip = true;
                    }
                } else {
                    this.licenseContents = "This license agreement no longer exists.";
                }
                this.loaded = true;
            }, () => {
                this.licenseContents = "Something went wrong. This agreement may have been accepted already.";
                this.loaded = true;
            });
    }

    isSuperUser(message: string) {
        if (this.sessionService.isLoggedInAs() || this.sessionService.hasPermission("organization_login", "SIMPFL")) {
            this.errorMessage("Not Allowed", message);
            return true;
        }
        return false;
    }

    skipForNow() {
        sf.metricLog.recordMetric("onboarding", "skipAgreement", 1, false);
        this.actionTaken.emit("skip");
    }

    errorMessage(title: string, message: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = title;
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
        modalInstance.message = message;
    }

    checkAcceptance() {
        if (this.isSuperUser("A Simplifile employee cannot accept a legal agreement on behalf of a customer.")) {
            return;
        }
        const modalRef = this.modalService.open(AcceptLicenseDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalRef.result.then((result: any) => {
            if (result) {
                sf.metricLog.recordMetric("onboarding", "acceptAgreement", 1, false);
                this.acceptAgreement();
            }
        }, () => {
            // nothing
        });
    }

    acceptAgreement() {
        if (this.isSuperUser("A Simplifile employee cannot accept a legal agreement on behalf of a customer.")) {
            return;
        }
        this.spinnerService.startSpinner();
        window.setTimeout(() => {
            this.acceptLicenseService
                .acceptContract(this.signContractInfo.organizationID, this.signContractInfo.eSignDocumentID,
                    this.signContractInfo.addendumDocumentID ? this.signContractInfo.addendumDocumentID :
                        this.signContractInfo.contractID)
                .subscribe((response: any) => {
                    this.spinnerService.stopSpinner();

                    // tell whether primary button should be 'login' or 'continue'
                    let primaryButtonLabel = this.signContractInfo.userLoggedIn ? "Continue" : "Log In";
                    let addUserData: any = null;
                    if (this.signerData) {
                        primaryButtonLabel = "Continue";
                        addUserData = {
                            organizationID: this.signContractInfo.organizationID,
                            roleIDs: ["SO0001"],
                            email: this.signerData.email,
                            firstName: this.signerData.firstName,
                            lastName: this.signerData.lastName,
                            title: this.signerData.title,
                            phone: this.signerData.phone
                        };
                    }

                    const modalRef = this.modalService.open(LicenseAcceptedDialogComponent);
                    const modalInstance = modalRef.componentInstance;
                    modalInstance.documentID = this.signContractInfo.eSignDocumentID;
                    modalInstance.addendumDocumentID = this.signContractInfo.addendumDocumentID;
                    modalInstance.primaryButtonText = primaryButtonLabel;
                    modalInstance.addUserData = addUserData;
                    modalRef.result.then((result: any) => {
                        this.actionTaken.emit("accept");
                    }, () => {
                        this.actionTaken.emit("accept");
                    });
                    this.spinnerService.stopSpinner();
                }, () => {
                    this.spinnerService.stopSpinner();
                    this.growlService.error("Something went wrong on our end trying to mark this license signed");
                });
        });
    }

    checkPrintDocument() {
        const modalRef = this.modalService.open(PrintLicenseDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalRef.result.then((result: any) => {
            if (result == true) {
                this.printDocument();
            }
        }, () => {
            // nothing
        });
    }

    printDocument() {
        this.spinnerService.startSpinner();
        this.acceptLicenseService
            .getWetSignContractInfo(this.contractId, this.signContractInfo.addendumContractID)
            .subscribe((result: any) => {
                this.spinnerService.stopSpinner();
                sf.metricLog.recordMetric("onboarding", "printAgreement", 1, false);
                /*
        let documentIDs = [this.signContractInfo.eSignDocumentID];
        if (this.signContractInfo.addendumDocumentID) {
            documentIDs.push(this.signContractInfo.addendumDocumentID);
        }
        */
                let documentIDs = [result.eSignDocumentID];
                if (result.addendumDocumentID) {
                    documentIDs.push(result.addendumDocumentID);
                }
                this.printService.printESignDocuments(documentIDs, null);
            }, () => {
                this.spinnerService.stopSpinner();
            });
        this.spinnerService.stopSpinner();
    }

    // email the MSA
    sendToSigner() {
        if (this.isSuperUser("A Simplifile employee cannot perform this action.")) {
            return;
        }
        const modalRef = this.modalService.open(SendLicenseDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalRef.result.then((newData: any) => {
            if (newData) {
                newData.contractID = this.contractId;
                newData.addendumID = this.signContractInfo.addendumContractID;
                // there might not be an addendum, but an ID is required
                if (!newData.addendumID) {
                    newData.addendumID = "";
                }
                newData.organizationID = this.signContractInfo.organizationID;
                sf.metricLog.recordMetric("onboarding", "sendAgreement", 1, false);
                this.tokenService.emailMSA(newData).subscribe((url: string) => {
                    this.postEmail(newData, url);
                }, (err: any) => {
                    this.errorMessage("Error", err.error ? err.error.errorMessage : err.errorMessage);
                });
            }
        }, () => {
            // nothing
        });
    }

    postEmail(sendData: any, url: string) {
        let message = "<p>An invitation email has been sent to " + sendData.email + "</p>";

        if (url) {
            message += "<p>Invitation URL: " + url + "</p>";
        }

        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Agreement Sent";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
        modalInstance.message = message;
        modal.result.then((result: any) => {
            this.actionTaken.emit("skip");
        }, () => {
            this.actionTaken.emit("skip");
        });
    }
}
