<div class="layout-grid">
    <sf-banners [isSupportBanner]="true"></sf-banners>
    <div class="d-flex sf-main-layout overflow-auto {{containerClass}}">
        <sf-left-nav></sf-left-nav>
        <div class="sf-row {{rightContentClass}}">
            <main id="main">
                <sf-banners></sf-banners>
                <div class="sf-main">
                    <sf-growls></sf-growls>
                    <sf-header-wrapper></sf-header-wrapper>
                    <router-outlet></router-outlet>
                </div>
            </main>
        </div>
    </div>
</div>
