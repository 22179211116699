import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationCreationService {
    constructor(
            private rpcService: RpcClientService
    ) { }

    createNewOrganization(orgData: any): Observable<any> {
        return this.rpcService.makeRequest("userorg", "createNewOrganization", orgData);
    }

    cloneOrganization(existingID: string, baseOrgID: string, orgData: any): Observable<any> {
        return this.rpcService.makeRequest("userorg", "createNewOrganizationBasedOn", {
            existingID: existingID,
            baseOrgID: baseOrgID,
            requestMap: orgData
        });
    }
}
