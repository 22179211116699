import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { from, Observable, Subject } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup
} from "@angular/forms";
import * as clone from "clone";
import { RejectionReason } from "./rejection-reason.interface";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { RejectionReasonsService } from "./rejection-reasons.service";
import { ChangeNotesModalComponent } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "@sf/common";
import { GrowlService } from "@sf/common";

@Component({
    selector: "rejection-reasons",
    templateUrl: "./rejection-reasons.component.html",
    styleUrls: ["./rejection-reasons.component.scss"]
})
export class RejectionReasonsComponent implements OnChanges {
    /** I/O **/
    @Input()
    recipientID: string;

    /** Private Variables **/

    /** Public Variables **/
    rejectionReasonsForm: FormGroup = this._fb.group({
        reasons: this._fb.array([])
    });
    editing: boolean = false;

    rejectionReasons$: Subject<RejectionReason[]> = new Subject();

    reasons$: Observable<RejectionReason[]> = this.rejectionReasons$.pipe(
        tap((rejectionReasons) => {
            this.rejectionReasonsFormArray.clear();
            for (let rejectionReason of rejectionReasons) {
                let newRejectionReason = clone(rejectionReason);
                this.rejectionReasonsFormArray.push(
                    new FormGroup({
                        reason: new FormControl(newRejectionReason.reason),
                        customText: new FormControl(
                            newRejectionReason.customText
                        )
                    })
                );
            }
        })
    );

    vm$ = this.reasons$.pipe(map(([rejectionReasons]) => [rejectionReasons]));

    get rejectionReasonsFormArray(): FormArray {
        return this.rejectionReasonsForm.get("reasons") as FormArray;
    }

    constructor(
        private _fb: FormBuilder,
        private _recipientService: RejectionReasonsService,
        private _modalService: NgbModal,
        private _sessionService: SessionService,
        private _growlService: GrowlService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.recipientID.currentValue &&
            changes.recipientID.currentValue !==
                changes.recipientID.previousValue
        ) {
            this._getRejectionReasons();
        }
    }

    addRejectionReason(): void {
        this.rejectionReasonsFormArray.push(
            new FormGroup({
                reason: new FormControl(),
                customText: new FormControl("")
            })
        );
    }

    setEditing(value: boolean): void {
        this.editing = value;
    }

    deleteRejectionReason(index: number): void {
        this.rejectionReasonsFormArray.removeAt(index);
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        const dir = event.currentIndex > event.previousIndex ? 1 : -1;

        const from = event.previousIndex;
        const to = event.currentIndex;

        const temp = this.rejectionReasonsFormArray.at(from);
        for (let i = from; i * dir < to * dir; i = i + dir) {
            const current = this.rejectionReasonsFormArray.at(i + dir);
            this.rejectionReasonsFormArray.setControl(i, current);
        }
        this.rejectionReasonsFormArray.setControl(to, temp);
    }

    save() {
        if (this._sessionService.isSuperUser()) {
            this._getNotes();
        } else {
            this._saveConfirmed();
        }
    }

    /** Private Methods **/

    private _getRejectionReasons() {
        let getCall: Observable<RejectionReason[]>;
        if (this._sessionService.isSuperUser()) {
            getCall = this._recipientService.getAdminRejectionReasons(
                this.recipientID
            );
        } else {
            getCall = this._recipientService.getRejectionReasons();
        }
        getCall.subscribe((rejectionReasons: RejectionReason[]) => {
            this.rejectionReasons$.next(rejectionReasons);
        });
    }

    private _getNotes() {
        const changeNotesConfirmationModal = this._modalService.open(
            ChangeNotesModalComponent
        );

        const modalInstance = changeNotesConfirmationModal.componentInstance;

        from(changeNotesConfirmationModal.result)
            .pipe(filter((result) => result === "save"))
            .subscribe(() => {
                this._saveConfirmed(
                    modalInstance.notesForm.controls.notes.value
                );
            });
    }

    private _saveConfirmed(notes?: string) {
        let saveReasons: RejectionReason[] = [];
        let dupCount: number = 0;
        let dupMessage: string = "";
        this.rejectionReasonsFormArray.controls.forEach(
            (reasonToSave: AbstractControl, formIndex: number) => {
                let index = saveReasons.findIndex((reason) => {
                    return reason.reason === reasonToSave.value.reason;
                });
                if (index === -1) {
                    saveReasons.push(reasonToSave.value);
                } else {
                    dupCount++;
                }
            }
        );
        if (dupCount) {
            dupMessage += `You have entered ${
                dupCount > 1 ? dupCount : "a"
            } duplicate rejection reason${
                dupCount > 1 ? "s" : ""
            }. The duplicate${dupCount > 1 ? "s" : ""} will be removed now.`;
        }
        let saveCall: Observable<any>;
        if (this._sessionService.isSuperUser()) {
            saveCall = this._recipientService.setAdminRejectionReasons(
                this.recipientID,
                saveReasons,
                notes
            );
        } else {
            saveCall = this._recipientService.setRejectionReasons(saveReasons);
        }

        saveCall.subscribe(() => {
            this._growlService.success(
                "Rejection Reasons Updated Successfully. " + dupMessage
            );
            this._getRejectionReasons();
        });
    }
}
