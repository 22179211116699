import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "@sf/common";
import { SessionService } from "@sf/common";
import { takeUntil } from "rxjs/operators";
import { ContractService } from "@sf/userorg/common";
import { ChatUserPromptDialogComponent } from "@sf/login";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenService } from "@sf/userorg/common";

interface WizardStep {
    title: string;
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-accept-license",
    templateUrl: "./accept-license.component.html",
    styleUrls: ["./accept-license.component.scss"]
})
export class AcceptLicenseComponent implements OnInit, OnDestroy {
    /* Private Variables */
    private _ngOnDestroy: Subject<void>;
    private _userDetails: any = {
        first: "",
        last: "",
        email: ""
    };

    /* Public Variables */
    loaded = false;
    loadFailed: string = null;
    currentStep = 0;
    contractID: string = null;
    formErrorMessages: string[] = [];
    serviceName = "";
    organizationID: string;
    organizationName = "";
    organizationFullName = "";
    organizationPhone = "";
    organizationStreet = "";
    organizationStreet2 = "";
    organizationCityStateZip = "";
    hasLicenseFee = false;
    licenseFeeAmount: number;
    tokenID: string;

    zChat: any = null;
    zChatStatus = "offline";
    zChatDepartments: any = null;

    wizardSteps: WizardStep[] = [
        {
            title: "Simplifile License Agreement"
        },
        {
            title: "License Fee"
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private spinnerService: SpinnerService,
        private contractService: ContractService,
        private tokenService: TokenService,
        private modalService: NgbModal,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.contractID = params.get("contractID");
                this.preloadForms(this.contractID);
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    private preloadForms(contractID: string) {
        this.contractService.getOrganizationForContract(contractID)
            .subscribe((organization: any) => {
                if (organization) {
                    this.organizationID = organization.id;
                    this.organizationName = organization.name;
                    this.organizationFullName = organization.name + " (" + organization.id + ")";
                    this.organizationPhone = organization.phone;
                    this.organizationStreet = organization.street;
                    this.organizationStreet2 = organization.street2;
                    this.organizationCityStateZip = organization.cityStateZip;
                }
                this.loaded = true;

                // Note: this page is only used for accepting MSA (and possibly added addendum)

                if (this.organizationID) {
                    this.tokenService.findInvitationToAccept(this.organizationID)
                        .subscribe((tokenID: string) => {
                            if (tokenID) {
                                this.tokenID = tokenID;

                                // we only get the license fee amount if there was a token
                                this.contractService
                                    .getContractFeeAmount(contractID, this.organizationID, "LICENSE_FEE")
                                    .subscribe((amount: number) => {
                                        this.licenseFeeAmount = amount;
                                        this.hasLicenseFee = this.notZero(amount);
                                    });
                            }
                        });
                }
            }, () => {
                this.loaded = true;
            });
    }

    private goToStep(step: number) {
        // the timeout seems to be required for Edge browser
        window.setTimeout(() => {
            this.currentStep = step;
        }, 10);
    }

    stepClick(step: number) {
        // there is only 1 step here
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    goToHomePage() {
        let homePage = this.sessionService.getHomePageUrl();
        if (homePage && !this.sessionService.isSystemUser()) {
            window.location = homePage;
        } else {
            this.goToLogin();
        }
    }

    licenseAction(event: string) {
        if (event == "accept") {
            if (this.hasLicenseFee) {
                this.goToStep(1);
            } else {
                this.goToHomePage();
            }
        } else if (event == "skip") {
            if (this.sessionService.hasAnyUsableProductInAnyOrg()) {
                this.goToHomePage();
            } else {
                this.goToLogin();
            }
        }
    }

    notZero(amount: any): boolean {
        if (!amount) {
            return false;
        }
        let amountNumber: number = 0;
        if (typeof amount == "string") {
            amountNumber = parseFloat(amount);
        } else if (typeof amount == "number") {
            amountNumber = amount;
        }
        return amountNumber > 0;
    }

    paymentAction(event: string) {
        this.goToHomePage();
    }

    handleChatDepartmentUpdate(event: any) {
        if (event && event.name == "Sales") {
            let deptID = this.zChatDepartments[event.name].id;
            if (event.id == deptID) {
                this.zChatStatus = event.status;
            }
        }
    }

    startChat() {
        const modalRef = this.modalService.open(ChatUserPromptDialogComponent);
        const modalInstance = modalRef.componentInstance;

        modalInstance.firstName = this._userDetails.first;
        modalInstance.lastName = this._userDetails.last;
        modalInstance.email = this._userDetails.email;

        modalRef.result.then((result: any) => {
            if (result) {
                this._userDetails.first = result.firstName;
                this._userDetails.last = result.lastName;
                this._userDetails.email = result.email;

            }
        }, () => {
            // nothing
        });
    }
}
