import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { UserRole } from "../interfaces/user-role.interface";
import { UserPermission } from "../interfaces/user-permission.interface";
import { Organization } from "../interfaces/organization.interface";

@Injectable({
    providedIn: "root"
})
export class RoleService {
    constructor(private _rpcClient: RpcClientService) {}

    canModifyRoleAssignments(orgID: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "userorg",
            "canModifyRoleAssignments",
            { orgID: orgID }
        );
    }

    addAssignmentsToOrganization(
        userIDs: string[],
        roleIDs: string[],
        orgID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "addAssignmentsToOrganization",
            {
                usernames: userIDs,
                orgID: orgID,
                roleIDs: roleIDs
            }
        );
    }

    addAssignmentsToUser(
        userID: string,
        roleIDs: string[],
        orgIDs: string[]
    ): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "addAssignmentsToUser", {
            username: userID,
            orgIDs: orgIDs,
            roleIDs: roleIDs
        });
    }

    removeRoleAssignments(
        assignmentsToRemove: any[],
        orgID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "removeRoleAssignments", {
            assignments: assignmentsToRemove,
            orgID: orgID
        });
    }

    removeAssignmentsFromUser(
        assignmentsToRemove: any[],
        username: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "removeAssignmentsFromUser",
            { assignments: assignmentsToRemove, username: username }
        );
    }

    getAssignmentsForUser(userID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAssignmentsForUser", {
            username: userID
        });
    }

    getRoleAssignments(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getRoleAssignments", {
            orgID: orgID
        });
    }

    getUserRolesAssignableToAll(
        orgIDs: string[],
        apiRolesOnly?: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getUserRolesAssignableToAll",
            {
                orgIDs: orgIDs,
                apiRolesOnly: apiRolesOnly
            }
        );
    }

    getUserRoles(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getUserRoles", {
            orgID: orgID
        });
    }

    currentUserHasPendingRoles(): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "userorg",
            "currentUserHasPendingRoles"
        );
    }

    public getUsersWithPermissions(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getUsersWithPermissions",
            { orgID: orgID }
        );
    }

    acceptInvitation(orgID: string, roleID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "acceptInvitation", {
            orgID: orgID,
            roleID: roleID
        });
    }

    rejectInvitation(orgID: string, roleID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "rejectInvitation", {
            orgID: orgID,
            roleID: roleID
        });
    }

    getAvailableProducts(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAvailableProducts", {
            orgID: orgID
        });
    }

    getRole(roleID: string): Observable<UserRole> {
        return this._rpcClient.makeRequest("userorg", "getRole", {
            roleID: roleID
        });
    }

    getOrganizationRoles(orgID: string): Observable<UserRole[]> {
        return this._rpcClient.makeRequest("userorg", "getOrganizationRoles", {
            orgID: orgID
        });
    }

    getOrganizationsWithRole(roleID: string): Observable<Organization[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getOrganizationsWithRole",
            {
                roleID: roleID
            }
        );
    }

    getAllRoles(): Observable<UserRole[]> {
        return this._rpcClient.makeRequest("userorg", "getAllRoles", {});
    }

    search(query: string, limit: number = 25): Observable<UserRole[]> {
        return this._rpcClient.makeRequest("userorg", "searchRoles", {
            query,
            limit
        });
    }

    searchPermissions(
        query: string,
        limit: number = 25
    ): Observable<UserPermission[]> {
        return this._rpcClient.makeRequest("userorg", "searchPermissions", {
            query,
            limit
        });
    }

    getUsersWithRole(roleID: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getUsersWithRole", {
            roleID
        });
    }

    getUsersWithPermission(permissionID: string): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getUsersWithPermission",
            {
                permissionID
            }
        );
    }

    getAllPermissions(): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getAllPermissions", {});
    }

    getRolesWithPermission(permissionID: string): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRolesWithPermission",
            { permissionID: permissionID }
        );
    }

    // requestOrgPermissions() {
    //     return this.angularJSService.requestOrgPermissions();
    // }

    getPermissionsForOrganization(orgID: string): Observable<UserPermission[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getPermissionsForOrganization",
            {
                orgID: orgID
            }
        );
    }

    getPermission(permissionID: string): Observable<UserPermission> {
        return this._rpcClient.makeRequest("userorg", "getPermission", {
            permissionID: permissionID
        });
    }

    getPermissions(permissionIDs: string[]): Observable<UserPermission> {
        return this._rpcClient.makeRequest("userorg", "getPermissions", {
            permissionIDs: permissionIDs
        });
    }

    deleteRole(orgID: string, role: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "deleteRole", {
            organizationID: orgID,
            roleID: role.roleID
        });
    }

    saveRole(role: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "saveRole", {
            roleData: role
        });
    }

    saveRolePermission(
        orgID: string,
        role: any,
        permissionID: string,
        enabled: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "saveRolePermission", {
            organizationID: orgID,
            roleID: role.roleID,
            permissionID: permissionID,
            enabled: enabled
        });
    }
}
