import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class PostViaFormService {
    private _renderer: Renderer2;
    private _form: HTMLFormElement;

    constructor(private _rendererFactory: RendererFactory2) {
        this._renderer = this._rendererFactory.createRenderer(null, null);
    }

    post(
        url: string,
        parameters: any,
        target: string,
        multipart: boolean,
        asDownload: boolean
    ): void {
        // Reset form, if necessary
        if (this._form) {
            this._renderer.removeChild(document.body, this._form);
        }
        this._form = this._renderer.createElement("form");
        this._renderer.setAttribute(
            this._form,
            "id",
            "sfPostViaFormServicePostForm"
        );
        this._renderer.appendChild(document.body, this._form);

        this._renderer.removeAttribute(this._form, "enctype");

        if (asDownload) {
            //NOTE: this method doesn't work if the filetype can be displayed in the browser window
            //if the response has the header: 'Content-Disposition': 'inline;', the browser
            //will try to display the file inside the hidden iframe. You must make sure that
            //instead of 'inline;', 'Content-Disposition' is set to 'attachment;'
            target = "hidden-download-iframe";
        }

        if (multipart) {
            this._renderer.setAttribute(
                this._form,
                "enctype",
                "multipart/form-data"
            );
        }

        this._renderer.setAttribute(this._form, "method", "post");
        this._renderer.setAttribute(this._form, "action", url);

        if (target) {
            this._renderer.setAttribute(this._form, "target", target);
        }

        for (const property in parameters) {
            if (parameters.hasOwnProperty(property)) {
                const value = parameters[property];
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) {
                        this._renderer.appendChild(
                            this._form,
                            this._createInput(property, value[i])
                        );
                    }
                } else {
                    this._renderer.appendChild(
                        this._form,
                        this._createInput(property, value)
                    );
                }
            }
        }

        this._form.submit();
    }

    private _createInput(property: string, value: string): HTMLInputElement {
        let input = this._renderer.createElement("input");

        this._renderer.setAttribute(input, "type", "hidden");
        this._renderer.setAttribute(input, "name", property);
        this._renderer.setAttribute(input, "value", value);

        return input;
    }
}
