<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <fa-icon icon="spinner" [spin]="true" *ngIf="loading"></fa-icon>
    <div id="receipt-data-dialog" *ngIf="receiptData">
        <div class="description">
            Package
            <a
                *ngIf="!preventDetailLink"
                [routerLink]="
                    ['/submitter/package/' + receiptData.id + '/details']
                "
                (click)="closeModal()"
                class="sf-mask"
            >
                {{ receiptData.name }}
            </a>
            <span *ngIf="preventDetailLink" class="sf-mask">{{ receiptData.name }}</span>
            {{ statusDescription }}
        </div>
        <div class="section">
            <div class="sf-mask"><b class="sf-unmask">Package:</b> {{ receiptData.name }}</div>
            <div><b>County:</b> {{ receiptData.recipientName }}</div>
            <div><b>Submitter:</b> {{ receiptData.submitterName }}</div>
            <div class="sf-mask">
                <b class="sf-unmask">Submitting User:</b> {{ receiptData.submissionUserName }}
            </div>
        </div>
        <div class="section">
            <div>
                <b
                    ><i
                        >Total Charges:
                        {{ receiptData.feeTotals.total | currency }}</i
                    ></b
                >
            </div>
            <div *ngIf="shouldShowFee(receiptData.feeTotals.recording)">
                <b>Total Recording Fees:</b>
                {{ receiptData.feeTotals.recording | currency }}
            </div>
            <div *ngIf="shouldShowFee(receiptData.feeTotals.tax)">
                <b>Total Taxes:</b> {{ receiptData.feeTotals.tax | currency }}
            </div>
            <div *ngIf="shouldShowFee(receiptData.feeTotals.documentBuilder)">
                <b>Total Document Builder Fees:</b>
                {{ receiptData.feeTotals.documentBuilder }}
            </div>
            <div *ngIf="shouldShowFee(receiptData.feeTotals.submission)">
                <b>Total Simplifile Submission Fees:</b>
                {{ receiptData.feeTotals.submission | currency }}
            </div>
            <div *ngIf="shouldShowFee(receiptData.feeTotals.salesTax)">
                <b>Total Sales Tax:</b>
                {{ receiptData.feeTotals.salesTax | currency }}
            </div>
        </div>
        <div *ngFor="let document of receiptData.documents" class="section">
            <div class="sf-mask">
                <a
                    *ngIf="documentLinks.has(document.id)"
                    tabindex="0"
                    (click)="closeModal()"
                    href="{{ documentLinks.get(document.id) }}"
                >
                    <i>{{ document.name }} ({{ document.type }})</i>
                </a>
                <span *ngIf="!documentLinks.has(document.id)">
                    <i>{{ document.name }} ({{ document.type }})</i>
                </span>
            </div>
            <div class="sf-mask"><b class="sf-unmask">Recording Number:</b> {{ document.recordingNumber }}</div>
            <div>
                <b>Recording Date:</b>
                {{
                    document.recordingDate
                        | formatTime
                            : receiptData.recipientTimeZone
                            : "MM/DD/YYYY h:mm A zz"
                            : true
                }}
            </div>
            <div *ngIf="document.feeAmounts.length">
                <div *ngFor="let feeItem of document.feeAmounts">
                    <b>{{ feeItem.description }}:</b>
                    {{ feeItem.amount | currency }}
                </div>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer
    [primary]="primaryButton"
    [secondary]="printButton"
></sf-modal-footer>
