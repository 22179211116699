<div class="form-group row" [formGroup]="controlParent$ | async" *ngIf="formControl$ | async as control" ngbDropdown #dateDropDown="ngbDropdown">
    <label [for]="field.id+'_custom_date'" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <sf-date-picker [inputId]="field.id+'_custom_date'"
                        [required]="checkRequired()"
                        [formControl]="control"
                        [datePickerName]="randomName"
                        (focus)="showHighlight();dateDropDown.open()"
                        (blur)="hideHighlight()"
                        ></sf-date-picker></div>
    <div ngbDropdownMenu *ngIf="dateMatches.length">
        <button ngbDropdownItem *ngFor="let date of dateMatches" (click)="assignValue(control, date);dateDropDown.close()">{{date}}</button>
    </div>
    <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                   [control]="control"
                   [label]="field.label">
    </sf-form-error>
</div>
