/**
 * format as phone number on blur
 * We do not want to ignore any characters typed by the user
 * You should use SfValidators.phoneValidator for any phone number inputs
 * Use SfValidators.getNumericDigits to get just the 10-digit numeric characters
 * see new-organization.component.ts (and .html) for example
 */
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output
} from "@angular/core";
import { SfValidators } from "../../validators/sf-validators";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[sfPhoneInput]"
})
export class PhoneInputDirective {
    private element: HTMLInputElement;
    @Output() ngModelChange = new EventEmitter();

    @HostListener("blur", ["$event"]) blurHandler() {
        // format the input text as a phone number
        this.element.value = SfValidators.formatPhone(this.element.value);

        // update model, for template forms
        this.ngModelChange.emit(this.element.value);

        // update the form, for reactive forms
        if (this.ngControl) {
            this.ngControl.control.patchValue(this.element.value);
        }
    }

    constructor(private el: ElementRef, private ngControl: NgControl) {
        this.element = this.el.nativeElement as HTMLInputElement;
    }
}
