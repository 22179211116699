<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <form [formGroup]="printScaleForm">
        <!-- print scale -->
        <div class="form-group">
            <input
                name="printScale"
                formControlName="printScaleValue"
                class="form-control print-scale__input"
                type="number"
                min="1"
                max="100"
                required
                [ngbTooltip]="tooltipMessage"
                [autoClose]="'inside'"
                triggers="manual"
                container="body"
                placement="right"
                #tooltip="ngbTooltip"
                aria-label="Set Print Scale"
            />&nbsp;%
        </div>

        <!-- Apply to all -->
        <div class="form-group form-check">
            <input
                class="form-check-input"
                type="checkbox"
                formControlName="applyAll"
                id="applyAll"
            />
            <label class="form-check-label" for="applyAll"
                >Apply to all counties</label
            >
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
