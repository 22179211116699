import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { SubmitterDefaultFilenameService } from "../../services/submitter-default-filename.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GrowlService } from "@sf/common";
import { FilenameFormatTableStructureElement } from "../../interfaces/submitter-print-download.interface";

@Component({
    selector: "sf-submitter-default-filename-dialog",
    templateUrl: "./submitter-default-filename-dialog.component.html",
    styleUrls: ["./submitter-default-filename-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class SubmitterDefaultFilenameDialogComponent implements OnInit {
    @Input()
    orgId: string;

    title: string;
    primary: ModalButton;
    secondary: ModalButton;

    separatorList: any;
    structure: string;
    currentStructure: string;
    currentParsedStructure: { [key: string]: any } = {
        separator: "-" // Default separator
    };
    tableStructure: FilenameFormatTableStructureElement[];
    structurePreview: string;

    constructor(
        private _activeModal: NgbActiveModal,
        private _defaultFilenameService: SubmitterDefaultFilenameService,
        private _growlService: GrowlService
    ) {}

    ngOnInit(): void {
        this.title = "Change Default File Name Format";
        this.primary = {
            text: "Save",
            callback: this.saveStructure.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            callback: this.cancel.bind(this)
        };

        this.separatorList = this._defaultFilenameService
            .getSeparatorsAsList()
            .filter((separator) => separator.name !== "comma");
        this._defaultFilenameService
            .getStructure(this.orgId)
            .subscribe((structure: any) => {
                this.structure = structure;
                this._updateStructure(structure);
            });
    }

    saveStructure() {
        this._defaultFilenameService
            .saveStructure(this.currentStructure, this.orgId)
            .subscribe(
                () => {
                    this._activeModal.close(this.currentStructure);
                },
                () => {
                    this._growlService.error(
                        "Failed to save default file name"
                    );
                }
            );
    }

    cancel() {
        this._activeModal.close(this.structure);
    }

    private _updateStructure(newStructure: string) {
        this.currentStructure = newStructure;
        this.currentParsedStructure =
            this._defaultFilenameService.parseStructure(newStructure);
        this.tableStructure = this._createTableStructure(
            this.currentParsedStructure
        );
        this.structurePreview = this._createPreviewString(
            newStructure,
            this.currentParsedStructure.separator
        );
    }

    toggleElement(elementName: any) {
        let newStructure;
        if (this.currentParsedStructure[elementName].include) {
            newStructure = this._defaultFilenameService.removeElement(
                this.currentStructure,
                elementName
            );
        } else {
            const endPosition =
                SubmitterDefaultFilenameDialogComponent._getEndPosition(
                    this.currentParsedStructure
                );
            newStructure = this._defaultFilenameService.addElement(
                this.currentStructure,
                elementName,
                endPosition
            );
        }
        this._updateStructure(newStructure);
    }

    toggleSelectAll() {
        let newStructure;
        if (
            this._defaultFilenameService.hasAllElements(this.currentStructure)
        ) {
            newStructure = this._defaultFilenameService.removeAllElements(
                this.currentStructure
            );
        } else {
            newStructure = this._defaultFilenameService.addAllElements(
                this.currentStructure
            );
        }
        this._updateStructure(newStructure);
    }

    moveElement(elementName: string, direction: string) {
        let currentPosition = this.currentParsedStructure[elementName].position;
        let newPosition;

        if (currentPosition === -1) {
            newPosition =
                SubmitterDefaultFilenameDialogComponent._getEndPosition(
                    this.currentParsedStructure
                );
        } else {
            if (direction === "up") {
                newPosition = currentPosition - 1;
            }
            if (direction === "down") {
                newPosition = currentPosition + 1;
            }
        }

        const newStructure = this._defaultFilenameService.setPosition(
            this.currentStructure,
            elementName,
            newPosition
        );
        this._updateStructure(newStructure);
    }

    handleSeparatorSelection($selection: any) {
        const newStructure = this._defaultFilenameService.setSeparator(
            this.currentStructure,
            $selection.name
        );
        this._updateStructure(newStructure);
    }

    private static _getEndPosition(parsedStructure: { [key: string]: any }) {
        const positions = Object.entries(parsedStructure)
            .filter(([key]) => key !== "separator")
            .map(([, value]) => value.position);
        return Math.max(...positions) + 1;
    }

    private _createTableStructure(parsedStructure: {
        [key: string]: any;
    }): FilenameFormatTableStructureElement[] {
        return Object.entries(parsedStructure)
            .map(([key, val]: [string, any]) => {
                if (key === "separator" || key === "hasAllElements") {
                    return;
                }
                return {
                    name: key,
                    displayName: key.replace(/([A-Z])/g, " $1").trim(),
                    include: val.include,
                    position: val.position
                };
            })
            .filter(Boolean)
            .sort((a, b) => {
                if (a.position === b.position) {
                    if (a.name.toUpperCase() < b.name.toUpperCase()) {
                        return -1; // sort a before b
                    }
                    if (a.name.toUpperCase() > b.name.toUpperCase()) {
                        return 1; // sort a after b
                    }
                    return 0; //no specific sort because names are the same
                }
                if (a.position === -1) {
                    return 1; // sort a after b
                }
                if (b.position === -1) {
                    return -1; // sort a before b
                }
                return a.position > b.position ? 1 : -1;
            });
    }

    private _createPreviewString(
        newStructure: string,
        separator: string
    ): string {
        let structurePreview = newStructure;
        const separatorCharacter =
            this._defaultFilenameService.getSeparatorValue(separator);
        if (structurePreview?.startsWith(separatorCharacter)) {
            structurePreview = structurePreview.substring(1);
        }
        return structurePreview;
    }
}
