import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { ContractType } from "@sf/userorg/common";
import { dayjs } from "@sf/common";

import {
    UITransaction,
    UITransactionItem
} from "../interfaces/transaction.interface";
import { Dayjs } from "dayjs";
import { TransactionAuditRecord } from "../interfaces/transaction-audit-record";

type PaymentMethodOption = { type: string; name: string };
export type BankAccountOption = { id: string; label: string; type: string };

export interface RenewContractResponse {
    organizationID: string;
    organizationName: string;
    contractType: ContractType;
    newExpirationDate: DateString;
    transactionItemList: UITransactionItem[];
    error: boolean;
    errorMsg: string;
}

@Injectable({
    providedIn: "root"
})
export class AdminAccountingService {
    constructor(private _rpcClient: RpcClientService) {}

    getPaymentMethods(): Observable<PaymentMethodOption[]> {
        return this._rpcClient.makeRequest("admin", "getPaymentMethods");
    }

    getActivePaymentAccountsForOrg(
        organizationID: string
    ): Observable<BankAccountOption[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getActivePaymentAccountsForOrg",
            {
                organizationID
            }
        );
    }

    createTransaction(params: any): Observable<{ transactionId: string }> {
        return this._rpcClient.makeRequest("admin", "createTransaction", {
            orgID: params.organization,
            paymentMethod: params.paymentMethod,
            paymentAccountID: params.bankAccount,
            amount: params.amount,
            nachaDesc: params.nachaDescription,
            comments: params.comments,
            hasSurcharge: params.showSurcharge
        });
    }

    getPackageTransactionItemsByPackage(
        packageID: string
    ): Observable<{ transactions: UITransaction[] }> {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageTransactionItemsByPackage",
            { packageID }
        );
    }

    getPackageTransactionItemsByDocumentID(
        documentID: string
    ): Observable<{ transactions: UITransaction[] }> {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageTransactionItemsByDocumentID",
            { documentID }
        );
    }

    getPackageTransactionItemsByTransactionID(
        transactionID: string
    ): Observable<{ transactions: UITransaction[] }> {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageTransactionItemsByTransactionID",
            { transactionID },
            true
        );
    }

    getPackageTransactionItemsByOrg(
        organizationID: string,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs
    ): Observable<{ transactions: UITransaction[] }> {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageTransactionItemsByOrg",
            {
                organizationID,
                startDate,
                endDate
            }
        );
    }

    deleteTransaction(transactionID: string, reason: string) {
        return this._rpcClient.makeRequest("admin", "deleteTransaction", {
            transactionID: transactionID,
            deleteReason: reason
        });
    }

    updateTransactionItems(
        transactionItemModels: UITransactionItem[],
        reason: string
    ) {
        return this._rpcClient.makeRequest("admin", "updateTransactionItem", {
            transactionItemModels: transactionItemModels,
            changeReason: reason
        });
    }

    getAllOpenBalances(start: dayjs.Dayjs, end: dayjs.Dayjs): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getAllOpenBalances", {
            start: start ? start.format("L") : null,
            end: end ? end.format("L") : null
        });
    }

    resetLastContact(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest("admin", "resetLastContact", {
            organizationID: orgID
        });
    }

    getEcheckSummary(searchCriteria: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getEcheckSummary", {
            startDate: searchCriteria.startDate,
            endDate: searchCriteria.endDate,
            orgID: searchCriteria.orgId,
            checkNum: searchCriteria.checkNum,
            packageName: searchCriteria.pkgName,
            batchID: searchCriteria.batchId
        });
    }

    scheduleNSFFees(paymentParams: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "scheduleNSFFees", {
            bankAccountID: paymentParams.bankAccountID,
            isCollectNSFFee: paymentParams.nsfChecked,
            isAutoReprocess: paymentParams.isAutoReprocess,
            reason: paymentParams.reason,
            echeckID: paymentParams.echeckID,
            transactionItemID: paymentParams.transactionItemID,
            origBankAccountID: paymentParams.origBankAccountID,
            origTransactionID: paymentParams.origTransactionID
        });
    }

    getPendingRefunds(): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getPendingRefunds", {});
    }

    getApprovedRefunds(): Observable<any> {
        return this._rpcClient.makeRequest("admin", "getApprovedRefunds", {});
    }

    approveRefund(refund: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "approveRefund", {
            transactionItemID: refund.id
        });
    }

    processRefund(refund: any): Observable<any> {
        return this._rpcClient.makeRequest("admin", "processRefund", {
            transactionItemID: refund.id
        });
    }

    createManualCCRefund(
        orgID: string,
        paymentAccountID: string,
        transactionItemID: string,
        amount: string,
        comment: string,
        isSurcharged: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest("admin", "createManualCCRefund", {
            orgID: orgID,
            paymentAccountID: paymentAccountID,
            transactionItemID: transactionItemID,
            amount: amount,
            comment: comment,
            isSurcharged: isSurcharged
        });
    }

    renewContracts(
        organizationIDs: string[],
        contractType: ContractType,
        schedulePayments: boolean
    ): Observable<RenewContractResponse[]> {
        return this._rpcClient.makeRequest("userorg", "renewContracts", {
            organizationIDs,
            contractType,
            schedulePayments
        });
    }

    getTransactionAuditRecords(
        transactionID: string,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs
    ): Observable<TransactionAuditRecord[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getTransactionAuditRecords",
            {
                transactionID: transactionID,
                startDate: startDate!! ? startDate.toDate() : null,
                endDate: endDate!! ? endDate.toDate() : null
            }
        );
    }

    getTransactionCategories(): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getTransactionCategories",
            {}
        );
    }
}
