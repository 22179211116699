import { AgRendererComponent } from "@ag-grid-community/angular";
import {
    IAfterGuiAttachedParams,
    ICellRendererParams
} from "@ag-grid-community/core";
import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { CellAction } from "../../interfaces";
import { WindowRefService } from "../../services";

@Component({
    selector: "sf-actions-cell-renderer",
    templateUrl: "./actions-cell-renderer.component.html",
    styleUrls: ["./actions-cell-renderer.component.scss"],
    host: { "[class.sf-unmask]": "unmaskActions" }
})
export class ActionsCellRendererComponent
    implements OnInit, AgRendererComponent
{
    /** Private Variables **/
    private _actionCallback: (action: string, data: any) => void;

    /** Public Variables **/
    isMenu: boolean;
    hideActionFlag: string;
    nameProperty: string;
    actions: CellAction[];
    rowData: any;
    actionDisabled: any;
    @ViewChild("dropDown")
    dropdown: NgbDropdown;
    @ViewChildren("action")
    actionBtns: QueryList<ElementRef>;
    uniqueID: string;
    unmaskActions: boolean = false;

    /** Lifecycle Hooks **/

    constructor(private _windowRef: WindowRefService) {
        this.uniqueID = (_windowRef.nativeWindow.crypto as any).randomUUID();
    }

    ngOnInit() {}

    /** Public Methods **/

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

    agInit(params: any) {
        if ("unmaskActions" in params) {
            this.unmaskActions = params.unmaskActions;
        }
        this.isMenu = params.isMenu;
        this.hideActionFlag = params.hideActionFlag;
        this.nameProperty = params.nameProperty;
        this._actionCallback = params.actionCallback;
        if (params.getActions) {
            this.actions = params.getActions(params.data);
        } else {
            this.actions = params.actions;
        }
        this._initData(params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        this._initData(params.data);
        return true;
    }

    doAction(action: CellAction) {
        this._closeDropdown();
        this._actionCallback(action.name, this.rowData);
    }

    handleKeydown(event: KeyboardEvent) {
        const key: string = event.key;
        if (key === "Enter" || key === " " || key === "ArrowDown") {
            this.toggleDropdown();
            event.preventDefault();
        }
    }

    toggleDropdown() {
        if (this.dropdown) {
            if (this.dropdown.isOpen()) {
                this.dropdown.close();
            } else {
                this.dropdown.open();
                setTimeout(() => {
                    this.actionBtns
                        .find((actionBtn) => !actionBtn.nativeElement.disabled)
                        ?.nativeElement.focus();
                });
            }
        }
    }

    /** Private Methods **/

    private _initData(data: any): void {
        this.rowData = data;
        this.actionDisabled = {};
        this.actions.forEach((action: CellAction) => {
            let disabled: boolean;
            if (typeof action.disabled === "function") {
                disabled = action.disabled(this.rowData);
            } else if (typeof action.disabled === "boolean") {
                disabled = action.disabled;
            } else {
                disabled = false;
            }
            this.actionDisabled[action.name] = disabled;
        });
    }

    private _closeDropdown() {
        if (this.dropdown?.isOpen()) {
            this.dropdown.close();
        }
    }
}
