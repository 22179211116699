<sf-modal-header title="Memo"></sf-modal-header>
<div class="modal-body">
    <div class="input-error row" *ngIf="memoForm.get('memo').errors">Memo cannot contain URLs - remove or modify text appearing like a URL.</div>
    <div>{{dialogText}}</div>
    <br>
    <form [formGroup]="memoForm">
        <div class="form-group" >
            <label for="memo">Memo</label>
            <input [ngClass]="{ 'memo-missing': addMemoMissingClass}"
                   [ngStyle]="memoMissingStyle"
                   type="text" id="memo" class="form-control"
                   maxlength="70" placeholder="Memo is required" formControlName="memo">
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
