<div class="sf-unmask">
    <sf-modal-header [title]="title"></sf-modal-header>
    <div class="modal-body">
        <div class="input-error row" [ngClass]="{'on': warning}">
            {{warning}}
        </div>
        <div *ngIf="message">
            {{message}}
            <br/><br/>
        </div>
        <form [formGroup]="editForm">
            <div class="form-group">
                <label for="street">Street Address</label>
                <input aria-label="Street Address Line 1"  type="text" formControlName="street1" id="street"
                       class="form-control sf-mask" maxlength="255"/>
                <input aria-label="Street Address Line 2" type="text" class="form-control street2 sf-mask" formControlName="street2" maxlength="255"/>
            </div>

            <div class="row">
                <div class="form-group col-sm-5">
                    <label for="city">City</label>
                    <input id="city" type="text" formControlName="city"  class="form-control sf-mask" maxlength="30"/>
                </div>

                <div class="form-group col-sm-4">
                    <label for="state">State</label>
                    <div class="select-container">
                        <sf-state-selector
                                class="sf-mask"
                                id="state"
                                [selectedStateID]="editForm.controls.state.value"
                                [canClearSelectedState]="true"
                                (select)="stateChanged($event)"
                        >
                        </sf-state-selector>
                    </div>
                </div>

                <div class="form-group col-sm-3">
                    <label for="zip">ZIP Code</label>
                    <input id="zip" type="text" formControlName="zipCode" name="zipCode" class="form-control sf-mask"
                           maxlength="10"/>
                </div>
            </div>

            <div *ngIf="invalidAddressMessage" class="row outer-validation">
                <div class="inner-validation">
                    <span class="sf-mask" [innerHTML]="invalidAddressMessage"></span>
                    <div *ngIf="showAddressCheckbox">
                        <label>
                            <input type="checkbox" formControlName="addressIsCorrect"/>
                            <span> The address I entered is correct</span>
                        </label>
                    </div>
                    <div *ngIf="showAddressRadios">
                        <label>
                            <input type="radio" formControlName="addressRadio" value="recommended"/>
                            <span> Use the recommended address</span>
                        </label>
                    </div>
                    <div *ngIf="showAddressRadios">
                        <label>
                            <input type="radio" formControlName="addressRadio" value="entered"/>
                            <span> Use the address entered above</span>
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
