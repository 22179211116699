<sf-modal-header title="Sales Executive"></sf-modal-header>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4" *ngIf="salesperson.imageURL">
            <img [src]="salesperson.imageURL" alt="Salesperson photo" class="profile-image"/>
        </div>
        <div class="col-md-8">
            <div><b>{{salesperson.firstLastName}}</b></div>
            <div>{{salesperson.title}}</div>
            <div>{{salesperson.phoneText}}</div>
            <div>
                <a class="tooltip-interaction" href="{{'mailto:' + salesperson.email}}"
                        ngbTooltip="Send email">
                    <span>{{salesperson.email}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
