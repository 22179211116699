import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { UIHistoryEventModel } from "@sf/userorg/common";
import { Observable } from "rxjs";
import { UIEditMessageModel } from "../interfaces/ui-edit-message-model.interface";

const ADMIN = "admin";

@Injectable({
    providedIn: "root"
})
export class AdminMessagesService {
    constructor(private _rpc: RpcClientService) {}

    /** Public Methods **/
    getMessageForEditing(messageId: string): Observable<UIEditMessageModel> {
        return this._rpc.makeRequest(ADMIN, "getMessageForEditing", {
            messageId: messageId
        });
    }

    saveMessage(message: UIEditMessageModel): Observable<string> {
        return this._rpc.makeRequest(ADMIN, "saveMessage", {
            message: message
        });
    }

    getMessageHistory(messageId: string): Observable<UIHistoryEventModel[]> {
        return this._rpc.makeRequest(ADMIN, "getMessageHistory", {
            messageId: messageId
        });
    }
}
