import { Injectable } from "@angular/core";
import { SessionService } from "../services/session.service";
import { UserSettingsService } from "../services/user-settings.service";

@Injectable({
    providedIn: "root"
})
export class TourService {
    /** Private Variables **/
    private _currTourName: string;

    /** Public Variables **/

    constructor(
        private userSettingsService: UserSettingsService,
        private _session: SessionService
    ) {}

    /** Public Methods **/

    // The logic for shouldShowTour was taken from sfTourService.js
    shouldShowTour(
        tourUserSettingName: string,
        checkHasLoggedIn: boolean = true
    ): boolean {
        //don't show if super-user, or logged in as another user
        if (this._session.isSuperUser() || this._session.isLoggedInAs()) {
            return false;
        }

        //don't show if they've seen the tour already
        if (
            this.userSettingsService.getUserSetting(tourUserSettingName) ===
            false
        ) {
            return false;
        }

        //don't show for new users, but set tour flag, so they don't see it next time logging in
        if (checkHasLoggedIn && !this._session.hasLoggedInBefore()) {
            this.userSettingsService.setUserSetting(tourUserSettingName, false);
            return false;
        }

        return true;
    }

    startTour(
        tour: any,
        tourName: string,
        delay: number = 1000,
        forceStart: boolean = false
    ) {
        // Only show one tour at a time, if it hasn't been seen, or we want to force it.
        if (
            !this._currTourName &&
            (forceStart || this.shouldShowTour(tourName))
        ) {
            this._currTourName = tourName;
            if (delay > 0) {
                setTimeout(() => {
                    tour.startTour();
                }, delay);
            } else {
                tour.startTour();
            }
        }
    }

    closeTour() {
        if (this._currTourName) {
            this.userSettingsService.setUserSetting(this._currTourName, false);
            this._currTourName = null;
        }
    }
    /**  Private Methods  **/
}
