import { Injectable } from "@angular/core";
import { SigningGroup } from "../interfaces/signing-group";

@Injectable({
    providedIn: "root"
})
export class SigningDocumentService {
    private _returnState: { url: string; label: string };
    private _selectedDocuments: any[] = [];
    private _associatedDocumentIds: string[] = [];
    private _notaryPassword: string;
    private _signatureURL: string;
    private _signSingleDocument: boolean;
    private _autoAssignGroup: SigningGroup;
    private _autoAssignDocumentIds: string[];

    constructor() {}

    setSelectedDocuments(documents: any[]) {
        this._selectedDocuments = [].concat(documents);
        this._associatedDocumentIds = [];
    }

    replaceSelectedDocument(findId: string, replaceId: string) {
        let document = this._selectedDocuments.find(
            (document) => document.id === findId
        );
        if (document) {
            document.documentId = replaceId;
        }
        if (!this._associatedDocumentIds.includes(findId)) {
            this._associatedDocumentIds.push(replaceId);
        }
    }

    isAssociatedDocument(documentId: string): boolean {
        return this._associatedDocumentIds.includes(documentId);
    }

    getSelectedDocuments(): any[] {
        return [].concat(this._selectedDocuments);
    }

    getSigningPassword(): string {
        return this._notaryPassword;
    }

    setSigningPassword(password: string) {
        this._notaryPassword = password;
    }

    getSignatureURL(): string {
        return this._signatureURL;
    }

    setSignatureURL(url: string) {
        this._signatureURL = url;
    }

    getReturnState(): { url: string; label: string } {
        return this._returnState;
    }

    setReturnState(returnState: { url: string; label: string }) {
        this._returnState = returnState;
    }

    setSignSingleDocument(signSingleDocument: boolean) {
        this._signSingleDocument = signSingleDocument;
    }

    getSignSingleDocument() {
        return this._signSingleDocument;
    }

    setAutoAssignGroup(autoAssignGroup: SigningGroup) {
        this._autoAssignGroup = autoAssignGroup;
    }

    getAutoAssignGroup() {
        return this._autoAssignGroup;
    }

    getAutoAssignDocumentIds() {
        return this._autoAssignDocumentIds;
    }

    setAutoAssignDocumentIds(documentIds: string[]) {
        this._autoAssignDocumentIds = documentIds;
    }
}
