import {
    Component,
    Type,
    ComponentFactoryResolver,
    Inject,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    Input,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    COMPONENT_FROM_FIELD,
    DynamicFormStore,
    FieldState,
    InputComponent
} from "@sf/dynamic-form-viewer";
import { scan, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { CustomGroupFieldComponent } from "../custom-group-field/custom-group-field.component";
import { CustomArrayFieldComponent } from "../custom-array-field/custom-array-field.component";
import { CustomButtonFieldComponent } from "../custom-button-field/custom-button-field.component";
import { UserType } from "../../interfaces/user-type.enum";
import { ErecordDocumentDetails } from "@sf/document/erecord-viewer/common";

@Component({
    selector: "sf-custom-root-field",
    templateUrl: "./custom-root-field.component.html",
    styleUrls: ["./custom-root-field.component.scss"]
})
export class CustomRootFieldComponent
    extends InputComponent
    implements OnInit, OnChanges, OnDestroy
{
    private _destroy$ = new Subject<void>();

    @Input() docLeftData?: ErecordDocumentDetails;
    @Input() formId?: string;
    @Input() orgID?: string;
    @Input() userType?: UserType;
    @Input() onSave?: Observable<any>;

    @ViewChild("groupHost", { read: ViewContainerRef, static: true })
    groupHost: ViewContainerRef;

    constructor(
        protected _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        protected _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore
    ) {
        super(_facade);
    }

    ngOnInit(): void {
        this._facade
            .getRootVisibleFields()
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.id),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) =>
                this.loadComponents(current, previous)
            );
    }

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        // this.groupHost.clear();
        let nextIndex = 0;
        let currentFields = controls.map((field) => field.id);

        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this.groupHost.remove(index);
                previous.splice(index, 1);
            }
        }

        for (let field of controls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                this._createNewComponent(field, nextIndex);
            }
            nextIndex++;
        }
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentType = this._getComponentFromField(field);
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                componentType
            );

        const component = this.groupHost.createComponent<InputComponent>(
            componentFactory,
            index
        );
        const instance = component.instance;

        instance.field = field;
        instance.controlPath = field.path;
        if (instance instanceof CustomButtonFieldComponent) {
            instance.docLeftData = this.docLeftData;
            instance.onSave = this.onSave;
        }
        if (instance instanceof CustomGroupFieldComponent) {
            instance.isInnerGroup = true;
        }
        if (instance instanceof CustomArrayFieldComponent) {
            instance.formId = this.formId;
            instance.docLeftData = this.docLeftData;
            instance.orgID = this.orgID;
            instance.userType = this.userType;
            instance.onSave = this.onSave;
        }
    }
}
