import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "sf-dont-close-dialog",
    templateUrl: "./dont-close-dialog.component.html",
    styleUrls: ["./dont-close-dialog.component.scss"]
})
export class DontCloseDialogComponent implements OnInit {
    @Input() message: string;
    @Input() title: string;

    constructor(
    ) {
    }

    ngOnInit(): void {
    }
}
