import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../services/login.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationModalComponent } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QueryStringService } from "@sf/common";

interface SecurityQuestion {
    id: string;
    question: string;
}

interface ChangePasswordValues {
    uniqueEmail: boolean;
    securityQuestions?: SecurityQuestion[];
    passwordLength: string;
    blockExtendedChars: boolean;
}

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
    resetForm: FormGroup;
    questionsForm: FormGroup;

    formErrorMessages: string[] = [];
    showChecked = false;
    needsSecurityQuestions = false;
    fpid: string = null;
    uniqueEmail: boolean = true;
    processing = false;
    currentInputIndex = -1;
    blockExtendedChars = false;

    securityQuestions: SecurityQuestion[] = [
        {
            id: null,
            question: null
        },
        {
            id: null,
            question: null
        },
        {
            id: null,
            question: null
        }
    ];
    securityAnswer0: string = null;
    securityAnswer1: string = null;
    securityAnswer2: string = null;

    validToken = false;
    passwordLengthMet = false;
    pwdLength: number = 9; //default
    passwordHas3of4 = false;
    passwordHasNumber = false;
    passwordHasLowerCase = false;
    passwordHasUpperCase = false;
    passwordHasSpecialChar = false;
    passwordsMatch = false;

    constructor(
            private formBuilder: FormBuilder,
            private router: Router,
            private queryStringService: QueryStringService,
            private route: ActivatedRoute,
            private modalService: NgbModal,
            private loginService: LoginService
    ) {
        this.questionsForm = formBuilder.group({
            showAnswers: [false],
            securityAnswer0: [""],
            securityAnswer1: [""],
            securityAnswer2: [""]
        });
        this.resetForm = formBuilder.group({
            username: [""],
            newPassword: [""],
            confirm: [""]
        });
    }

    ngOnInit() {
        this.fpid = this.queryStringService.getQueryParam("fpid");

        this.focusToPassword();
        this.catchFormChanges();

        this.loginService.getChangePasswordValues(this.fpid)
            .subscribe((response: ChangePasswordValues) => {
                // this method should not return an archived user
                if (!response) {
                    this.navigateAfterConfirm("login/forgot-password", "Invalid or expired 'change password' link");
                    return;
                }

                this.validToken = true;
                this.pwdLength = parseInt(response.passwordLength);
                this.uniqueEmail = response.uniqueEmail;
                this.needsSecurityQuestions = !!response.securityQuestions;
                this.blockExtendedChars = !!response.blockExtendedChars;
                if (response.securityQuestions) {
                    this.securityQuestions = response.securityQuestions;
                }
            }, (errorResult: any) => {
                this.navigateAfterConfirm("login/forgot-password", "Invalid or expired 'change password' link");
            });
    }

    catchFormChanges() {
        this.questionsForm.get("showAnswers").valueChanges
            .subscribe((val) => {
                this.showChecked = !!this.questionsForm.get("showAnswers").value;
            });
        this.resetForm.get("newPassword").valueChanges
            .subscribe((val) => {
                let pwd = this.resetForm.controls.newPassword.value;
                let confirm = this.resetForm.controls.confirm.value;

                this.passwordLengthMet = pwd && pwd.length >= this.pwdLength;
                this.passwordHasNumber = /[0-9]/.test(pwd);
                this.passwordHasLowerCase = /[a-z]/.test(pwd);
                this.passwordHasUpperCase = /[A-Z]/.test(pwd);
                this.passwordHasSpecialChar = /[^a-zA-Z0-9]/.test(pwd);

                let countOfFour = 0;
                if (this.passwordHasNumber) {
                    countOfFour++;
                }
                if (this.passwordHasLowerCase) {
                    countOfFour++;
                }
                if (this.passwordHasUpperCase) {
                    countOfFour++;
                }
                if (this.passwordHasSpecialChar) {
                    countOfFour++;
                }
                this.passwordHas3of4 = countOfFour >= 3;

                this.passwordsMatch = confirm === pwd;
            });
        this.resetForm.get("confirm").valueChanges
            .subscribe((val) => {
                let pwd = this.resetForm.controls.newPassword.value;
                let confirm = this.resetForm.controls.confirm.value;
                this.passwordsMatch = confirm === pwd;
            });
    }

    focusToPassword() {
        this.focusElement("password");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (theField) {
                theField.focus();
            }
        }, 200);
    }

    getInputType(index: number) {
        if (this.showChecked) return "text";
        if (index == this.currentInputIndex) return "text";
        return "password";
    }

    handleBlur(index: number) {
        this.currentInputIndex = -1;
    }

    handleFocus(index: number) {
        this.currentInputIndex = index;
    }

    submitQuestionsForm() {
        this.formErrorMessages = [];

        this.securityAnswer0 = this.questionsForm.controls.securityAnswer0.value;
        this.securityAnswer1 = this.questionsForm.controls.securityAnswer1.value;
        this.securityAnswer2 = this.questionsForm.controls.securityAnswer2.value;

        if (!this.securityAnswer0 || !this.securityAnswer1 || !this.securityAnswer2) {
            this.formErrorMessages.push("Please answer all the questions");
            return;
        }

        this.processing = true;
        this.loginService.validateSecurityAnswers(this.fpid, this.securityQuestions[0].id, this.securityAnswer0,
                    this.securityQuestions[1].id, this.securityAnswer1, this.securityQuestions[2].id,
                    this.securityAnswer2)
            .subscribe((results: any) => {
                this.processing = false;
                if (results) {
                    this.needsSecurityQuestions = false;
                    this.focusToPassword();
                } else {
                    this.formErrorMessages.push("At least one of your answers was incorrect.");
                }
            }, (errorResult: any) => {
                this.processing = false;
                let message = "Could not validate your answers.";
                if (errorResult && errorResult.error && errorResult.error.errorMessage) {
                    message = errorResult.error.errorMessage;
                }
                if (message == "Too many failed login attempts.") {
                    message = "You have been locked out for too many failed attempts";
                }
                this.formErrorMessages.push(message);
            });
    }

    submitResetForm() {
        this.formErrorMessages = [];

        let username: string = this.resetForm.controls.username.value;
        let password: string = this.resetForm.controls.newPassword.value;
        let confirm: string = this.resetForm.controls.confirm.value;

        if (!password) {
            this.formErrorMessages.push("Please enter your new password");
        } else if (!confirm) {
            this.formErrorMessages.push("Please confirm your new password");
        } else if (password != confirm) {
            this.formErrorMessages.push("The two passwords do not match");
        }
        if (!this.formErrorMessages.length) {
            if (!this.passwordLengthMet || !this.passwordHas3of4 || !this.passwordsMatch) {
                this.formErrorMessages.push("Password does not meet requirements");
            }
        }
        if (this.includesInvalidExtendedChars(password)) {
            this.formErrorMessages.push("The password you have entered contains invalid characters. " +
                    "Please use only uppercase and lowercase letters or the digits 0-9.");
        }
        if (!this.uniqueEmail && !username) {
            this.formErrorMessages.push("Please enter your username");
        }
        if (this.formErrorMessages.length) {
            return;
        }

        this.processing = true;
        this.loginService.changePasswordWithSecurityQuestions(this.fpid, this.securityQuestions[0].id,
                this.securityAnswer0, this.securityQuestions[1].id, this.securityAnswer1, this.securityQuestions[2].id,
                this.securityAnswer2, username, password)
            .subscribe((results: any) => {
                this.processing = false;
                if (results) {
                    this.navigateAfterConfirm("login", "Your password has been changed");
                } else {
                    this.formErrorMessages.push("Reset failed.");
                }
            },
            (errorResult: any) => {
                this.processing = false;
                this.resetForm.patchValue({
                    newPassword: "",
                    confirm: ""
                });
                let message = "Reset Failed";
                if (errorResult && errorResult.error && errorResult.error.errorMessage) {
                    message = errorResult.error.errorMessage;
                }
                if (message == "Too many failed login attempts.") {
                    message = "You have been locked out for too many failed attempts";
                }
                this.formErrorMessages.push(message);
                this.focusToPassword();
            });
    }

    includesInvalidExtendedChars(pwd: string): boolean {
        // nothing is illegal normally
        if (!this.blockExtendedChars) {
            return false;
        }
        return this.loginService.passwordIncludesExtendedChar(pwd);
    }

    navigateAfterConfirm(newPage: string, message?: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Change Password";
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
        modalInstance.message = message;

        modal.result.then((result: any) => {
            this.goToPage(newPage);
        }, () => {
            this.goToPage(newPage);
        });
    }

    clickBack() {
        this.goToPage("login");
    }

    goToPage(newPage: string) {
        let newUrl = "/" + newPage;
        this.router.navigate([newUrl], { relativeTo: this.route });
    }
}
