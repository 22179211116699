import { Injectable } from "@angular/core";
import { SessionOrganization } from "../interfaces/session-organization.interface";
import { Session } from "../interfaces/session.interface";
import { Subject } from "rxjs";
import { getPath, setPath } from "../helpers/object";
import { LoggerType } from "../enums/logger-type.enum";

declare const sf: any;

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class SessionService {
    protected _session: Session;
    protected _allOrganizations: SessionOrganization[];

    constructor() {
        if (typeof sf != "undefined" && sf) {
            this._session = sf.session;
        }
    }

    // this is used as an event to notify when the session is reloaded
    private sessionResetSubject$ = new Subject<void>();
    public sessionResetEvent = this.sessionResetSubject$.asObservable();

    /**
     * CAUTION! ONLY call this if you have just re-read the session from the back end
     * @param session
     */
    setSession(session: Session) {
        this._session = session;
        sf._session = session;
        this._allOrganizations = null; // force reload
        this.sessionResetSubject$.next();
    }

    isValid(): boolean {
        return (!!this._session);
    }

    /**
     * Session ID
     */
    getSessionID(): string {
        return getPath(this._session, "sessionId");
    }

    /**
     * Env is QA or dev or prod
     */
    getEnv(): string {
        return getPath(this._session, "env");
    }

    /**
     * submitter or lender etc
     */
    getDefaultApp(): string {
        return getPath(this._session, "defaultApp");
    }

    /**
     *
     */
    getSocketID(): string {
        return getPath(this._session, "socketId");
    }

    /**
     * Current logged in user
     */
    getUserID(): string {
        return getPath(this._session, "userId");
    }

    /**
     * Current logged in user
     */
    getUsername(): string {
        return getPath(this._session, "username");
    }

    /**
     *
     */
    getFirstLast(): string {
        return getPath(this._session, "firstLast");
    }

    // Note that "userSettings" is accessed through user-settings.service.ts

    /**
     * Current user's username that's been 'scrambled'
     */
    getScrambledUsername(): string {
        return getPath(this._session, "scrambledUsername");
    }

    /**
     * Get the username of the root user
     * Will return different from getUsername only if a superuser is logged in as another user
     * @returns {string}
     */
    getRootUser(): string {
        return getPath(this._session, "rootUsername");
    }

    isSystemUser(): boolean {
        return getPath(this._session, "systemUser");
    }

    /**
     * Determine if we should be talking to the simplifile tray / desktop application
     * @returns {boolean}
     */
    useTray(): boolean {
        return getPath(this._session, "useTray");
    }

    /**
     * Determine if the current user is a super-user logged in as another user
     * @returns {boolean}
     */
    isLoggedInAs(): boolean {
        const rootUser = this.getRootUser();
        const loggedInUser = this.getUsername();
        return !!rootUser && rootUser !== loggedInUser;
    }

    /**
     * Determine if the current user is a superuser running the "admin" app
     * @returns {boolean}
     */
    isSuperUser(): boolean {
        return (
            !!this._session &&
            this.getDefaultApp() === "admin" &&
            this.hasOrganization("SIMPFL")
        );
    }

    /**
     *
     */
    hasLoggedInBefore(): boolean {
        return getPath(this._session, "hasLoggedInBefore");
    }

    /**
     * Check if user has a permission in an organization
     * @param {string} permission - permission to check for - ex: "create_package"
     *                            (The permission names are lower-case of what is found in ProductPermission.java)
     * @param {string} orgID - organization id
     */
    hasPermission(permission: string, orgID: string) {
        if (!this._session) {
            return false;
        }

        if (!orgID || orgID.length != 6) {
            throw new Error("Invalid organization ID: " + orgID);
        }

        return this.hasAnyPermission(
            this._permissionStringToList(permission),
            orgID
        );
    }

    /**
     * Check if the user has ANY of a list of permissions
     * @param {*} permissions - array of permissions to check
     * @param {string} orgID - organization id
     */
    hasAnyPermission(permissions: string[], orgID: string): boolean {
        this._checkLoggedInForMethod("hasAnyPermission");

        if (orgID.length != 6) {
            throw new Error("Invalid organization ID: " + orgID);
        }

        this._logIfVerbose("hasAnyPermission: " + permissions + " for " + orgID);

        let orgList: SessionOrganization[] = [];
        const orgs = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );
        if (orgID) {
            // find the matching organization
            orgList = orgs.filter((org) => org.id === orgID);
        } else {
            // get all organizations
            orgList = [...orgs];
        }

        return this._hasAnyPermissionInOrgList(permissions, orgList);
    }

    /**
     * Check if user has ANY of a list of permissions in any of the orgs the user belongs to
     * @param permissions
     */
    hasAnyPermissionInAnyOrg(permissions: string[]): boolean {
        this._checkLoggedInForMethod("hasAnyPermissionInAnyOrg");

        this._logIfVerbose("hasAnyPermissionInAnyOrg: " + permissions);

        // get all organizations
        let orgList = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );

        return this._hasAnyPermissionInOrgList(permissions, orgList);
    }

    /**
     * Check for a specific permission in ALL of the user's organizations
     * @param {string} permission - permission to check for - ex: "create_package"
     */
    hasPermissionInAnyOrg(permission: string): boolean {
        this._checkLoggedInForMethod("hasPermissionInAnyOrg");

        this._logIfVerbose("hasPermissionInAnyOrg: " + permission);

        const orgList = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );

        return this._hasAnyPermissionInOrgList(
            this._permissionStringToList(permission),
            orgList
        );
    }

    /**
     * Check for a permission in a list of provided permissions
     *  shortcut for calling 'includes'
     * @param permission
     * @param list
     */
    hasPermissionInList(permission: string, list: string[]): boolean {
        return list?.includes(permission);
    }

    /**
     * get a list of organization IDs where user has a specific permission
     * @param permission
     */
    getOrganizationIDsWithPermission(permission: string): string[] {
        this._checkLoggedInForMethod("getOrganizationIDsWithPermission");

        if (permission.indexOf(".") >= 0) {
            throw new Error("Invalid permission with dot: " + permission);
        }

        const permissionOrgs: string[] = [];
        for (const org of this._session.organizations) {
            if (
                org.permissions.includes(permission) &&
                !permissionOrgs.includes(org.id)
            ) {
                permissionOrgs.push(org.id);
            }
        }

        return permissionOrgs;
    }

    /**
     * Get a list of organization IDs (that the user belongs to) that have a specified product/service
     * @param productID
     */
    getOrganizationIDsWithProduct(productID: string): string[] {
        this._checkLoggedInForMethod("getOrganizationIDsWithProduct");

        const productLower = productID.toLowerCase();
        const productOrgs: string[] = [];
        for (const org of this._session.organizations) {
            const foundProduct = org.activeServices.find(
                (activeService) => activeService.id === productLower
            );
            if (foundProduct) {
                productOrgs.push(org.id);
            }
        }

        return productOrgs;
    }

    /**
     * Get a list of organizations (that the user belongs to) that have a specified product/service
     * @param productID
     */
    getOrganizationsWithProduct(productID: string): SessionOrganization[] {
        this._checkLoggedInForMethod("getOrganizationIDsWithProduct");

        const productLower = productID.toLowerCase();
        const productOrgs: SessionOrganization[] = [];
        for (const org of this._session.organizations) {
            const foundProduct = org.activeServices.find(
                    (activeService) => activeService.id === productLower
            );
            if (foundProduct) {
                productOrgs.push(org);
            }
        }

        return productOrgs;
    }

    /**
     * only for super-users
     */
    hasPermissionToGrantTemporaryAdminPermissions(): boolean {
        return (
            this.isSuperUser() &&
            this.hasAnyPermission(
                [
                    "admin_superuser_role_management",
                    "admin_grant_temporary_permissions"
                ],
                "SIMPFL"
            ) &&
            !this.hasPermission(
                "admin_temporary_permissions_override",
                "SIMPFL"
            )
        );
    }

    /**
     * Check if user has a role for a specified product/service in a specified org
     * @param orgID
     * @param product
     */
    hasProduct(orgID: string, product: string): boolean {
        this._checkLoggedInForMethod("hasProduct");

        if (!orgID || orgID.length != 6) {
            throw new Error("Invalid organization ID: " + orgID);
        }

        // find the matching organization - maybe multiple instances?
        const orgList = this._session.organizations.filter(
            (org) => org.id === orgID
        );

        this._logIfVerbose("hasProduct: " + product + " for " + orgID);

        // search for product
        return this._hasProductInOrgList(product, orgList);
    }

    /**
     * Check if any organization the user belongs to has a specified product/service
     * Does not check whether the user has a role for that product/service.
     * @param product
     */
    hasProductInAnyOrg(product: string): boolean {
        this._checkLoggedInForMethod("hasProductInAnyOrg");

        const orgList = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );

        this._logIfVerbose("hasProductInAnyOrg: " + product);

        // search for product
        return this._hasProductInOrgList(product, orgList);
    }

    /**
     * Check if any organization the user belongs to has one of a list of specified products/services
     * Does not check whether the user has a role for that product/service.
     * @param products - array of product IDs
     */
    hasAnyProductInAnyOrg(products: string[]): boolean {
        this._checkLoggedInForMethod("hasAnyProductInAnyOrg");

        const orgList = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );

        this._logIfVerbose("hasAnyProductInAnyOrg: " + products);

        if (!products) return false;

        // search for products
        let foundProduct = products.find((product) => {
            return this._hasProductInOrgList(product, orgList);
        });
        return !!foundProduct;
    }

    /**
     * see if user belongs (has a role) to any organization that has a product other than the 'organization' product
     */
    hasAnyUsableProductInAnyOrg(): boolean {
        if (!this._session) {
            return false;
        }
        let orgList = this._session.organizations;
        if (!orgList) {
            return false;
        }

        let foundOrg = orgList.find((org) => {
            let orgHasProduct = false;
            if (org.activeServices) {
                let foundOrgWithProduct = org.activeServices.find((product) => {
                    return product.id != "organization";
                });
                orgHasProduct = !!foundOrgWithProduct;
            }
            return orgHasProduct;
        });
        return !!foundOrg;
    }

    /**
     * see if user belongs (has a role) to an organization
     * @param orgID
     */
    hasOrganization(orgID: string): boolean {
        return !!this._findOrganization(orgID);
    }

    /**
     *
     */
    getOrgIds(): string[] {
        const orgList = getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        );
        const orgIDs = orgList.map((org) => org.id);
        return orgIDs;
    }

    /**
     * Probably only use this if you are building a selection list of organization names
     * returns list of organization objects where the current user has the specified permission
     */
    getAllOrganizationsWithPermission(
        permission: string
    ): SessionOrganization[] {
        this._checkLoggedInForMethod("getAllOrganizationsWithPermission");

        if (!permission || permission.indexOf(".") >= 0) {
            throw new Error("Invalid permission: " + permission);
        }

        // clever but unreadable code follows
        const permissionOrgs = this._session.organizations.reduce(
            (prev: { ids: string[]; orgs: SessionOrganization[] }, current) => {
                if (
                    current.permissions.includes(permission) &&
                    !prev.ids.includes(current.id)
                ) {
                    prev.ids.push(current.id);
                    prev.orgs.push(current);
                }

                return prev;
            },
            {
                ids: [],
                orgs: []
            }
        );

        return permissionOrgs.orgs;
    }

    /**
     * Returns a SessionOrganization object
     * In many cases it might be better to call getOrganizationName instead, if you just need the name
     */
    getPartialOrganization(orgID: string): SessionOrganization {
        return this._findOrganization(orgID);
    }

    /**
     * returns the name of an organization that the user belongs to
     * returns null if the user doesn't belong to the organization
     * @param orgID
     */
    getOrganizationName(orgID: string): string {
        const foundOrg = this._findOrganization(orgID);
        if (!foundOrg) {
            return null;
        }
        return foundOrg.name;
    }

    /**
     * Get all organizations the user belongs to
     * For super-users, the Simplifile org will be excluded
     */
    getAllOrganizations(): SessionOrganization[] {
        if (this._allOrganizations) {
            return this._allOrganizations;
        }
        // this part only done once
        // to exclude the Simplifile org and any corrupted orgs
        this._allOrganizations = this._session.organizations.filter(
            (org) => org.id && org.id !== "SIMPFL"
        );
        return this._allOrganizations;
    }

    /**
     * how many orgs the user belongs to -- excluding SIMPFL
     */
    getAllOrganizationCount(): number {
        return this.getAllOrganizations().length;
    }

    // NOTE that we intentionally left out 'active organization' (activeOrganization) calls
    // Use CurrentOrganizationService instead

    /**
     * get the user's 'default' org
     */
    getDefaultOrganizationID(): string {
        return getPath(this._session, "defaultOrganizationId");
    }

    /**
     * set the user's 'default' org
     * DANGER: This is not persisted!
     * @param orgID
     */
    setDefaultOrganizationID(orgID: string): void {
        setPath(this._session, "defaultOrganizationId", orgID);
    }

    /**
     * Basically checks if any org has a payment account
     */
    isAnyOrganizationSetUp(): boolean {
        if (!this._session) {
            return false;
        }
        return !!getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        ).find((org) => org.isSetUp);
    }

    /**
     * only call this if you make a change to an organization that you know makes it 'set up'
     * Nothing is persisted
     * @param orgID
     */
    setOrganizationSetUp(orgID: string): void {
        const org = this._findOrganization(orgID);
        if (org) {
            org.isSetUp = true;
        }
    }

    /**
     * This should always return true, or user couldn't log in
     */
    isAnyOrganizationEnabled(): boolean {
        if (!this._session) {
            return false;
        }
        return !!getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        ).find((org) => org.isEnabled);
    }

    /**
     *
     * @param orgID
     */
    isOrganizationEnabled(orgID: string): boolean {
        const org = this._findOrganization(orgID);
        return org && org.isEnabled;
    }

    /**
     *
     */
    getHomePageUrl(): string {
        return getPath(this._session, "homePageUrl");
    }

    /**
     * not persisted
     * @param url
     */
    setHomePageUrl(url: string) {
        if (url) {
            setPath(this._session, "homePageUrl", url);
        }
    }

    hasValidPaymentOrg(permission: string): boolean {
        let orgs: SessionOrganization[] = this.getAllOrganizationsWithPermission(permission);
        let validOrgs: SessionOrganization[] = orgs.filter((org: SessionOrganization) => {
            let validOrg: boolean = false;
            validOrg = validOrg || this.hasProduct(org.id, "submitter");
            validOrg = validOrg || this.hasProduct(org.id, "capc");
            validOrg = validOrg || this.hasProduct(org.id, "esign_event");
            validOrg = validOrg || this.hasProduct(org.id, "notary");
            validOrg = validOrg || this.hasProduct(org.id, "trustee");
            return validOrg;
        });
        return validOrgs.length && validOrgs.length > 0;
    }

    /**
     * return user's profile picture
     */
    getImageUrl(): string {
        return getPath(this._session, "imageURL");
    }

    private _checkLoggedInForMethod(method: string) {
        if (!this._session) {
            throw new Error("Can't call " + method + " when not logged in");
        }
    }

    private _logIfVerbose(message: string): void {
        if (this._session && this._session.verboseLogging) {
            console.dir(message);
        }
    }

    private _permissionStringToList(permission: string): string[] {
        if (permission.indexOf("&&") >= 0) {
            return permission.replace(/ /g, "").split("&&");
        } else {
            return permission.replace(/ /g, "").split("||");
        }
    }

    private _hasPermissionInOrgList(
        permission: string,
        orgList: SessionOrganization[]
    ) {
        if (permission.indexOf(".") >= 0) {
            log.getLogger(LoggerType.FALCON).error("Found dot in permission : " + permission);
        }
        const orgThatHas = orgList.find((org) =>
            org.permissions.includes(permission)
        );
        return !!orgThatHas;
    }

    private _hasAnyPermissionInOrgList(
        permissions: string[],
        orgList: SessionOrganization[]
    ) {
        for (let i = 0; i < permissions.length; i++) {
            if (this._hasPermissionInOrgList(permissions[i], orgList)) {
                return true;
            }
        }
        return false;
    }

    private _hasProductInOrgList(
        product: string,
        orgList: SessionOrganization[]
    ) {
        if (!product || !orgList) {
            return false;
        }
        const productLower = product.toLowerCase();
        const foundOrg = orgList.find((org) => {
            const foundProduct = org.activeServices.find(
                (activeService) =>
                    activeService.id === productLower &&
                    activeService.status !== "DISABLED"
            );

            return !!foundProduct;
        });
        return !!foundOrg;
    }

    private _findOrganization(orgID: string): SessionOrganization {
        return getPath<SessionOrganization[]>(
            this._session,
            "organizations"
        ).find((org) => org.id === orgID);
    }
}
