import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";
import { map } from "rxjs/operators";

@Component({
    selector: "sf-loading-bar",
    templateUrl: "./loading-bar.component.html",
    styleUrls: ["./loading-bar.component.scss"]
})
export class LoadingBarComponent implements OnInit {
    isLoading$ = this._loader.isLoading$;
    loadingMessage$ = this.isLoading$.pipe(
        map((isLoading) => {
            return isLoading ? "Loading..." : "Loaded";
        })
    );
    loader$ = this._loader.progress$;
    constructor(private _loader: LoaderService) {}

    ngOnInit() {}
}
