import { Component } from "@angular/core";
import { ITooltipAngularComp } from "@ag-grid-community/angular";
import { ITooltipParams } from "@ag-grid-community/core";

export interface tooltipLine {
    display?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    align?: string;
}
/*
    This is how to define your grid to take advantage of this component:
    ngOnInit {
        this.frameworkComponents = { customTooltip: AgGridTooltipComponent };
    }
    ...
    colDef = {
        tooltipComponent: "customTooltip",
        tooltipValueGetter: this._getTooltip,
        tooltipComponentParams: {
            backColor: "blue",
            color: "lime"
        }
    }

    // Returns a mixed array of simple strings and/or tooltipLine objects
    // Normal text, align: center is the default for each text string in the array
    _getTooltip(params: any)) {
        let tooltip = [];
        let row = params.data;

        if (row) {
            tooltip.push({display: row.name, bold: true});
            tooltip.push(row.phone);
            tooltip.push({ display: "Left", align: "left"});
            tooltip.push({ display: "Right", align: "right", italic: true });
            tooltip.push({ display: "Center", align: "center" });
        }
        return tooltip;
 */

@Component({
    selector: "tooltip-component",
    templateUrl: "./ag-grid-tooltip.component.html",
    styleUrls: ["./ag-grid-tooltip.component.scss"]
})
export class AgGridTooltipComponent implements ITooltipAngularComp {
    lines: any[];
    private params!: { backColor?: string; color?: string } & ITooltipParams;
    public data!: any;
    public backColor!: string;
    public color!: string;

    agInit(
        params: { backColor?: string; color?: string } & ITooltipParams
    ): void {
        this.params = params;

        this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
        this.backColor = this.params.backColor || "black";
        this.color = this.params.color || "white";
        this.lines = params.value;
    }
}
