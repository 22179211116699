import { Injectable } from "@angular/core";
import { RpcClientService } from "./rpc-client.service";
import { LoggerService } from "./logger.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { LoggerType } from "../enums/logger-type.enum";

declare global {
    interface Window {
        owa_baseSecUrl: any;
        owa_cmds: any;
        _paq: any;
    }
}
window["owa_cmds"] = window["owa_cmds"] || [];
window["_paq"] = window["_paq"] || [];

@Injectable({
    providedIn: "root"
})
export class AnalyticsService {
    private currentUrl = "";
    private _matomoInitialized = false;
    private owa_baseUrl: string;
    private _owaInitialized = false;
    private _matomoUrl = "";
    private _matomoSiteId = "";
    private _owaUrl = "";
    private _owaSiteId = "";
    private _locationChange_initialized = false;

    constructor(
        private _rpcClient: RpcClientService,
        private _logger: LoggerService,
        private _http: HttpClient,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        console.log("AnalyticsService");
        this._getConfig();
    }

    private _getConfig() {
        this.makeRequest("getAnalyticsConfig").subscribe(
            (response: any) => {
                this._matomoUrl = response["matomoUrl"];
                this._matomoSiteId = response["matomoSiteId"];
                this._owaUrl = response["owaUrl"];
                this._owaSiteId = response["owaSiteId"];
                this._init();
            },
            (e) => {
                return this._logger.error(e);
            }
        );
    }

    private _init() {
        this._matomoInit();
        this._owaInit();
        if (
            !this._locationChange_initialized &&
            (this._matomoInitialized || this._owaInitialized)
        ) {
            this._router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map((event) => [event, this._activatedRoute])
                )
                .subscribe((event) => this._locationChange(event));
            this._locationChange_initialized = true;
        }
    }

    private _locationChange(event: Array<any>) {
        try {
            let navigationEnd = event[0];
            let activatedRoute = event[1];
            let url = navigationEnd.url;
            let pageType = AnalyticsService._getPageType(url);
            let pageTitle = AnalyticsService._getPageTitle(url);
            let referrerUrl = this.currentUrl;
            this.currentUrl = navigationEnd.url;
            if (referrerUrl != this.currentUrl) {
                log.getLogger(LoggerType.FALCON).debug(
                    "locationChange " + pageType + " " + pageTitle
                );
                this._matomoLocationChange(
                    referrerUrl,
                    AnalyticsService._sanitizeUrl(this.currentUrl),
                    pageType,
                    pageTitle
                );
                this._owaLocationChange(pageType, pageTitle);
            }
        } catch (error) {
            log.getLogger(LoggerType.FALCON).debug(error);
        }
    }

    private _matomoInit() {
        if (this._matomoSiteId && this._matomoUrl) {
            try {
                /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                this._matomoPush("trackPageView", []);
                this._matomoPush("enableLinkTracking", []);
                let u = this._matomoUrl;
                this._matomoPush("setTrackerUrl", [u + "matomo.php"]);
                this._matomoPush("setSiteId", [this._matomoSiteId]);
                // this._matomoPush("setUserId", [this.userName]);
                let d = document,
                    g = d.createElement("script"),
                    s = d.getElementsByTagName("script")[0];
                g.type = "text/javascript";
                g.async = true;
                g.defer = true;
                g.src = u + "matomo.js";
                s.parentNode.insertBefore(g, s);
                this._matomoInitialized = true;
                log.getLogger(LoggerType.FALCON).debug("Matomo initialized");
            } catch (e) {
                log.getLogger(LoggerType.FALCON).debug(
                    "error " + e + " initializing Matomo"
                );
            }
        }
        return this._matomoInitialized;
    }

    private _matomoPush(action: string, args: any[]) {
        // log.getLogger(LoggerType.FALCON).debug("_matomoPush " + action + " " + args);
        window["_paq"].push([action].concat(args));
    }

    private _matomoLocationChange(
        referrerUrl: string,
        currentUrl: string,
        pageType: string,
        pageTitle: string
    ) {
        if (this._matomoInitialized) {
            // log.getLogger(LoggerType.FALCON).debug("_matomoLocationChange " + pageType + " " + pageTitle);
            this._matomoPush("setReferrerUrl", [referrerUrl]);
            this._matomoPush("setCustomUrl", [currentUrl]);
            this._matomoPush("setDocumentTitle", [pageType + "/" + pageTitle]);
            this._matomoPush("setGenerationTimeMs", [0]); // FUTURE measure time to load page and set it here
            this._matomoPush("enableHeartBeatTimer", [30]);
            // this._matomoPush("deleteCustomVariables", ["page"]);
            // this._matomoPush("setCustomVariable", [1, "organization", this.orgName, "visit"]);
            // this._matomoPush("setCustomVariable", [2, "organizationtype", this.orgType, "visit"]);
            // this._matomoPush("setCustomVariable", [3, "userrole", this.userRole, "visit"]);
            // this._matomoPush("setCustomVariable", [4, "activatedservices", this.activatedServices, "visit"]);
            // this._matomoPush("setCustomVariable", [5, "service", this.service, "page"]);
            // this._matomoPush("setCustomVariable", [6, "tenure", this.tenure, "visit"]);
            this._matomoPush("trackPageView", []);
            this._matomoPush("enableLinkTracking", [true]);
        }
    }

    private _owaInit() {
        if (this._owaSiteId && this._owaUrl) {
            try {
                this.owa_baseUrl = this._owaUrl;
                // this._owaPush("setDebug", [true]);
                this._owaPush("setLoggerEndpoint", [this.owa_baseUrl]);
                this._owaPush("setSiteId", [this._owaSiteId]);
                this._owaPush("trackPageView", []);
                this._owaPush("trackClicks", []);
                this._owaPush("trackDomStream", []);
                let _owa = document.createElement("script");
                _owa.type = "text/javascript";
                _owa.async = true;
                this.owa_baseUrl =
                    "https:" === document.location.protocol
                        ? window.owa_baseSecUrl ||
                          this.owa_baseUrl.replace(/http:/, "https:")
                        : this.owa_baseUrl;
                _owa.src =
                    this.owa_baseUrl +
                    "modules/base/js/owa.tracker-combined-min.js";
                let _owa_s = document.getElementsByTagName("script")[0];
                _owa_s.parentNode.insertBefore(_owa, _owa_s);
                this._owaInitialized = true;
                log.getLogger(LoggerType.FALCON).debug(
                    "Open Web Analytics initialized"
                );
            } catch (e) {
                log.getLogger(LoggerType.FALCON).debug(
                    "error " + e + "initializing Open Web Analytics"
                );
            }
        }
        return this._owaInitialized;
    }

    private _owaPush(action: string, args: any[]) {
        if (window["owa_cmds"] != undefined) {
            // log.getLogger(LoggerType.FALCON).debug("_owaPush " + action + " " + args);
            window["owa_cmds"].push([action].concat(args));
        }
    }

    private _owaLocationChange(pageType: string, pageTitle: string) {
        if (this._owaInitialized) {
            // log.getLogger(LoggerType.FALCON).debug("_owaLocationChange " + pageType + " " + pageTitle);
            this._owaPush("setPageType", [pageType]);
            this._owaPush("setPageTitle", [pageTitle]);
            // this._owaPush("setCustomVar", [1, "organization", this.orgName,"visitor"]);
            // this._owaPush("setCustomVar", [2, "organizationtype", this.orgType, "visitor"]);
            // this._owaPush("setCustomVar", [3, "userrole", this.userRole, "visitor"]);
            // this._owaPush("setCustomVar", [4, "activatedservices", this.activatedServices, "visitor"]);
            // this._owaPush("setCustomVar", [5, "service", this.service, "page"]);
            // this._owaPush("setCustomVar", [6, "tenure", this.tenure, "visitor"]);
            this._owaPush("trackPageView", []);
            this._owaPush("trackClicks", []);
            this._owaPush("trackDomStream", []);
        }
    }

    // Analytics engines aggregate based on the url. Some of our endpoints are REST and contain variable data,
    // such as uuids, organization ids, etc. The variable data causes analytics to treat identical urls that
    // have different variable values as different pages, throwing off the page counts.
    // This method tries to sanitize urls by replacing variable parts with something generic.
    // FUTURE we may be able to get something like this from the Angular router. Need to investigate.
    static _sanitizeUrl(url: string) {
        // replace guid and org id patterns with empty strings
        let guidPattern =
            /[0-9a-fA-F]{8}\-?[0-9a-fA-F]{4}\-?[0-9a-fA-F]{4}\-?[0-9a-fA-F]{4}\-?[0-9a-fA-F]{12}/g;
        let orgIDPattern = /\/[A-Z]{3}[0-9A-Z]{3}(\/|$)/g;
        let legacyPattern = /\/legacy\?url=(~2Fsf~2F|%2Fsf%2F)(.*?)(&|$).*/i;
        let classificationExtractionPattern =
            /\/(classification|extraction)\/.*\/(.*)/;
        let userPattern = /\/(.*?)\/user\/.*\/(.*)/;
        let paramPattern = /\?.*$/;
        return url
            .replace(guidPattern, "*")
            .replace(orgIDPattern, "/*/")
            .replace(legacyPattern, "/legacy/$2")
            .replace(paramPattern, "")
            .replace(userPattern, "/$1/user/*/$2")
            .replace(classificationExtractionPattern, "/$1/*/$2")
            .replace(/~2F/gi, "/")
            .replace(/%2F/gi, "/") // unescape some things
            .replace(/\/$/, ""); // remove trailing slash
    }

    static _getPageType(url: string) {
        let pathname = this._sanitizeUrl(url);
        return pathname.split("/")[0];
    }

    static _getLegacyPageTitle(url: string) {
        let location = this._sanitizeUrl(url);
        return location
            .replace(/~2F/gi, "/")
            .replace(/^.*\/ui\/legacy\/{1,2}/, "")
            .replace("/sf", "")
            .replace(/&.*$/, "");
    }

    // FUTURE extract better page titles from DOM or Angular. Ideally, get what is displayed in the browser tab.
    static _getPageTitle(url: string) {
        if (AnalyticsService._getPageType(url) === "legacy")
            return AnalyticsService._getLegacyPageTitle(url);
        else {
            let pathname = this._sanitizeUrl(url);
            return pathname.substring(pathname.indexOf("/") + 1);
        }
    }

    public trackAction(group: string, metric: string, value: any) {
        if (this._matomoInitialized)
            this._matomoPush("trackEvent", [group, metric, "", value]);
        if (this._owaInitialized)
            this._owaPush("trackAction", [group, metric, "", value]);
    }

    private makeRequest(method: string, data?: any): Observable<any> {
        if (data)
            return this._http.post("/sf/userorg/log-metrics/" + method, data);
        else return this._http.get("/sf/userorg/log-metrics/" + method);
    }
}
