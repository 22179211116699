<div class="form-group row" *ngIf="formControl$ | async as control" [formGroup]="controlParent$ | async">
    <label [for]="field.id+'_custom_text'" class="col-md-2">{{field.label}}</label>
    <div class="col-md" [ngbTooltip]="field.toolTip" [ngClass]="{'value-wrong':control.invalid}" placement="top-left">
        <fa-icon *ngIf="field.showToolTipIcon" class="tooltip-icon" [icon]="['far', 'exclamation-circle']"></fa-icon>
        <input *ngIf="!field.placeholder"
               class="form-control"
               [id]="field.id+'_custom_text'"
               type="text"
               (click)="focus$.next($any($event).target.value)"
               (focus)="showHighlight();focus$.next($any($event).target.value)"
               (blur)="hideHighlight()"
               [name]="randomName"
               [focusFirst]="false"
               [autocomplete]="field.id+'_custom_text'"
               [ngbTypeahead]="typeAhead"
               (input)="isPhoneType ? onPhoneInput($event, control) : null"
               [formControl]="control">
        <input *ngIf="!!field.placeholder"
               class="form-control"
               [id]="field.id+'_custom_text'"
               type="text"
               (click)="focus$.next($any($event).target.value)"
               (focus)="showHighlight();focus$.next($any($event).target.value)"
               (blur)="hideHighlight()"
               [name]="randomName"
               [focusFirst]="false"
               [autocomplete]="field.id+'_custom_text'"
               [ngbTypeahead]="typeAhead"
               (input)="isPhoneType ? onPhoneInput($event, control) : null"
               [formControl]="control"
               [placeholder]="field.placeholder">
        <sf-form-error *ngIf="control.invalid && (!control.pristine || (control.pristine && !control.errors['required']))"
                       [control]="control"
                       [label]="field.label">
        </sf-form-error>
    </div>
</div>
