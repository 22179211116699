<sf-modal-header title="Print Documents" class="modal-title"></sf-modal-header>
<div class="modal-body">
    <form [formGroup]="printModeForm">
        <div>
            <label>
                <input type="radio" name="printMode" value="browser" formControlName="printMode"/>
                Use browser print tool
            </label>
        </div>
        <div>
            <label>
                <input type="radio" name="printMode" value="trayapp" formControlName="printMode"/>
                Use enhanced Simplifile print tool
            </label>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
