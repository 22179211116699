import { Component, TemplateRef } from "@angular/core";

export interface DocumentBuilderGuideCard {
    id?:
        | DocumentBuilderGuideTask
        | DocumentBuilderSignerGuideTask
        | DocumentBuilderNotaryGuideTask
        | DocumentBuilderDataEntryGuideTask;
    title: string;
    description?: string;
    actionLabel?: string;
    status?: "" | "complete" | "skipped";
    action?: Function;
    videoId?: string;
    completeMessage?: string;
    component?: any;
    hideOnComplete?: boolean;
}

export enum DocumentBuilderGuideType {
    ADMIN = "ADMIN",
    SIGNER = "SIGNER",
    NOTARY = "NOTARY",
    DATA_ENTRY = "DATA_ENTRY"
}

export enum DocumentBuilderGuideTask {
    ADD_USERS = "ADD_USERS",
    CONFIGURATION = "CONFIGURATION",
    COMMON_PARTIES = "COMMON_PARTIES",
    // TEMPLATE_GROUPS = "TEMPLATE_GROUPS",
    REPORTS = "REPORTS",
    ENTITY_TYPE = "ENTITY_TYPE"
}

export enum DocumentBuilderSignerGuideTask {
    SIGNATURE = "SIGNATURE",
    TITLE = "TITLE",
    SECONDARY_TITLE = "SECONDARY_TITLE",
    SAMPLE_DOCUMENTS = "SAMPLE_DOCUMENTS",
    NOTARY_METHOD = "NOTARY_METHOD",

    NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS"
}

export enum DocumentBuilderNotaryGuideTask {
    SETUP = "SETUP",
    SAMPLE_DOCUMENTS = "SAMPLE_DOCUMENTS",
    NOTARY_METHOD = "NOTARY_METHOD"
}

export enum DocumentBuilderDataEntryGuideTask {
    DATA_ENTRY = "DATA_ENTRY"
}
