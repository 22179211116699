import { Component, Input, OnInit } from "@angular/core";
import { NavItem } from "../../interfaces/navigation.interface";
import { IconsService } from "../../../icons/icons.service";
import { Icons } from "../../../interfaces";

@Component({
    selector: "sf-nav-parent-link",
    templateUrl: "./nav-parent-link.component.html",
    styleUrls: ["./nav-parent-link.component.scss"]
})
export class NavParentLinkComponent implements OnInit {
    @Input()
    item: NavItem;

    icons: Icons = {};

    constructor(private iconService: IconsService) {}

    ngOnInit() {
        this.icons.angle = this.iconService.get("angle-left");
    }
}
