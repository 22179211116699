// NOTE: Any changes made to this enum will also need to change on the backend KoiCategory enum.
export enum KoiCategory {
    AFFIDAVIT = "Affidavit",
    AGREEMENT = "Agreement",
    ASSIGNMENT = "Assignment",
    DEED = "Deed",
    JUDGMENT = "Judgment",
    LIEN = "Lien",
    MAP_PLAT_SURVEY = "Map Plat Survey",
    MODIFICATION = "Modification",
    MORTGAGE = "Mortgage",
    MORTGAGE_DEED_OF_TRUST = "Mortgage Deed of Trust",
    MULTI_TITLE = "Multi-Title",
    NOTICE = "Notice",
    OTHER = "Other",
    POWER_OF_ATTORNEY = "Power of Attorney",
    RELEASE = "Release",
    //RELEASE_OF_LIEN = "Release of lien",
    SUBORDINATIONS = "Subordinations",
    SUBSTITUTIONS = "Substitutions",
    UCC = "UCC"
}
