<sf-modal-header title="Select One or More Users"></sf-modal-header>
<div class="modal-body selector-height">
    <sf-userorg-user-multi-selector
        id="userSelector" name="userSelector"
        [lock]="false"
        [initialSelection]="initialSelection"
        [options]="userOptions"
        [allOptions]="allUserOptions"
        (changedObjects)="usersChanged($event)">
    </sf-userorg-user-multi-selector>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
