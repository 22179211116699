import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { Observable, Subject, timer } from "rxjs";
import { debounce, distinctUntilChanged } from "rxjs/operators";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

let sfSearch_inputID = 0;

const KEY_CODES = {
    ENTER: 13,
    ESCAPE: 27
};

@Component({
    selector: "sf-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
    /* Public Variables */
    inputID = "";

    /* Inputs */
    @Input()
    model: { text: string };
    @Input()
    placeholder: string;
    @Input()
    enable: boolean;
    @Input()
    icon: IconProp = ["far", "search"];
    @Input()
    showResults: boolean;
    @Input()
    displayInlineDialog: boolean;
    @Input()
    debounceTime: number = 0; // zero is default value, gets overridden if user specifies [debounceTime]="xxx"

    /* Outputs */
    @Output()
    onChange: Observable<{ $selection: string }>;
    @Output()
    onGetFocus: EventEmitter<undefined> = new EventEmitter();
    @Output()
    cancelEvent: EventEmitter<undefined> = new EventEmitter();
    @Output()
    onKeystroke: EventEmitter<KeyboardEvent> = new EventEmitter();

    /* View Children */
    @ViewChild("searchInput")
    searchInput: ElementRef;

    /* Private Variables */
    private _changeSubject: Subject<{ $selection: string }> = new Subject();

    /** Lifecycle Hooks **/
    constructor() {
        // @Outputs have to initialized or the browser hangs. That is why we have to use the debounce/timer pair instead
        // of the simpler debounceTime(), because debounceTime() would be called before the @Input debounceTime was set.
        this.onChange = this._changeSubject.pipe(
            debounce(() => timer(this.debounceTime)),
            distinctUntilChanged()
        );
    }

    ngOnInit() {
        this.placeholder =
            this.placeholder || "Filter by package name, county, doc type, etc";

        sfSearch_inputID++; // to keep inputs unique on pages
        this.inputID = "sfSearchInput" + sfSearch_inputID;
    }

    handleKey(event: any) {
        if (event.keyCode === KEY_CODES.ESCAPE) {
            this.cancelSearch();
        } else if (
            event.keyCode === KEY_CODES.ENTER &&
            (!this.model || !this.model.text || this.model.text.length < 2)
        ) {
            this.cancelSearch();
        } else {
            this.emitChange();
        }
    }

    cancelSearch() {
        if (this.model) {
            this.model.text = "";
        }
        this.cancelEvent.emit();
        this.emitChange();
        window.setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    focusInput() {
        this.searchInput.nativeElement.focus();
    }

    onBlur() {
        // Timeout so there is time to process clicks on a link in the search results
        window.setTimeout(() => {
            this.showResults = false;
        }, 100);
    }

    onFocus() {
        this.showResults = true;
        this.focusInput();
        this.onGetFocus.emit();
    }

    onClick() {
        this.showResults = true;
    }

    emitChange() {
        this._changeSubject.next({ $selection: this.model.text });
    }
}
