import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {
    ConfirmationModalComponent,
    GrowlService,
    SelectableItemWithID,
    SelectComponent,
    SessionService,
    UserSettingsService
} from "@sf/common";
import { AnswersList, UserOrgService } from "@sf/userorg/common";
import { SecurityQuestionsService } from "../../services/security-questions.service";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-security-questions",
    templateUrl: "./security-questions.component.html",
    styleUrls: ["./security-questions.component.scss"]
})
export class SecurityQuestionsComponent implements OnInit, AfterViewInit {
    //@ViewChildren("securityQuestion") securityQuestionSelects: QueryList<SelectComponent>;
    @ViewChildren("securityAnswer") securityAnswerInputs: QueryList<ElementRef>;
    @ViewChild("supportQuestion") supportQuestionSelect: SelectComponent;
    @ViewChild("supportAnswer") supportAnswerInput: ElementRef;

    private _userID: string;
    private MIN_ANSWER_LENGTH: number = 4;
    private MAX_PROMPT_COUNT = 3;

    userPassword: string;
    numQuestions: number[] = [0, 1, 2, 3, 4]; //5 total question prompts
    selectedQuestionIDs: string[] = ["", "", "", "", ""];
    selectedSupportID: string = "";
    showSecuritySection: boolean = false;
    showSecurityAnswers: boolean = false;
    showSupportSection: boolean = false;
    showSupportAnswer: boolean = false;
    warning = "";
    promptCount = 0;
    canSkip = false;

    answersList: AnswersList[] = [];
    allQuestionList: SelectableItemWithID[];
    questionListEach: SelectableItemWithID[][];
    allSupportList: SelectableItemWithID[];
    supportList: SelectableItemWithID[];

    constructor(
            private _sessionService: SessionService,
            private _questionsService: SecurityQuestionsService,
            private _growlService: GrowlService,
            private userOrgService: UserOrgService,
            private userSettingsService: UserSettingsService,
            private modalService: NgbModal,
            private _router: Router) {
    }

    ngOnInit(): void {
        this._userID = this._sessionService.getUsername();
        this.questionListEach = [];
        this._questionsService.getSecurityQuestionList()
            .subscribe((list: any) => {
                this.allQuestionList = [];
                Object.keys(list).forEach((key: string) => {
                    this.allQuestionList.push({
                        id: key,
                        label: list[key]
                    });
                });
                for (let i: number = 0; i < 5; i++) {
                    this.questionListEach[i] = this.userOrgService.cloneObj(this.allQuestionList);
                }
            });
        this._questionsService.getPhoneSupportQuestionList()
            .subscribe((list: any) => {
                this.supportList = [];
                Object.keys(list).forEach((key: string) => {
                    this.supportList.push({
                        id: key,
                        label: list[key]
                    });
                });
                this.allSupportList = this.userOrgService.cloneObj(this.supportList);
            });
        this.numQuestions.forEach((value) => {
            this.answersList.push({
                questionID: "",
                answer: ""
            });
        });
        this._questionsService.userHasSecurityAnswers(this._userID)
            .pipe(first())
            .subscribe((result: boolean) => {
                this.showSecuritySection = !result; //if user has answers, don't show
            });
        this._questionsService.userHasPhoneSupportAnswer(this._userID)
            .pipe(first())
            .subscribe((result: boolean) => {
                this.showSupportSection = !result; //if user has answer, don't show
            });
        let prompts = this.userSettingsService.getUserSetting("TIMES_ASKED_FOR_SECURITY_QUESTIONS");
        if (typeof prompts == "number") {
            this.promptCount = prompts as number;
        } else {
            this.promptCount = 1;
        }
        if (this.promptCount <= this.MAX_PROMPT_COUNT) {
            this.canSkip = true;
        }
    }

    ngAfterViewInit() {
    }

    toggleSecurityAnswers() {
        this.securityAnswerInputs.forEach((input: ElementRef) => {
            input.nativeElement.type = this.showSecurityAnswers ? "text" : "password";
        });
    }

    securityQuestionSelect(event: any, index: number) {
        if (event.$isSelectionChanged && event.$selection) {
            let selectedID = event.$selection.id;
            this.selectedQuestionIDs[index] = selectedID;

            for (let i = 0; i < 5; i++) {
                this.questionListEach[i] = [];
                this.allQuestionList.forEach((question: SelectableItemWithID) => {
                    if (question.id == this.selectedQuestionIDs[i] || !this.selectedQuestionIDs.includes(question.id)) {
                        this.questionListEach[i].push(question);
                    }
                });
            }
        }
    }

    onSecurityFocus(event: FocusEvent) {
        let input: HTMLInputElement = <HTMLInputElement>event.target;
        if (input.type === "password") {
            input.type = "text";
        }
    }

    onSecurityBlur(event: FocusEvent) {
        let input: HTMLInputElement = <HTMLInputElement>event.target;
        if (input.type === "text" && !this.showSecurityAnswers) {
            input.type = "password";
        }
        if (!!input.value) {
            if (input.value.trim().length < this.MIN_ANSWER_LENGTH) {
                input.classList.add("ng-invalid", "ng-touched");
                this._growlService.error("Answers must be at least 4 characters long.");
                input.focus();
            } else {
                input.classList.remove("ng-invalid");
            }
        }
    }

    toggleSupportAnswer() {
        this.supportAnswerInput.nativeElement.type = this.showSupportAnswer ? "text" : "password";
    }

    selectSupportQuestion(event: any) {
        if (event.$isSelectionChanged) {
            this.selectedSupportID = event.$selection.id;
        }
    }

    onSupportFocus(event: FocusEvent) {
        let input: HTMLInputElement = <HTMLInputElement>event.target;
        if (input.type === "password") {
            input.type = "text";
        }
    }

    onSupportBlur(event: FocusEvent) {
        let input: HTMLInputElement = <HTMLInputElement>event.target;
        if (input.type === "text" && !this.showSupportAnswer) {
            input.type = "password";
        }
        if (!!input.value) {
            if (input.value.trim().length < this.MIN_ANSWER_LENGTH) {
                input.classList.add("ng-invalid", "ng-touched");
                this._growlService.error("Answers must be at least 4 characters long.");
                input.focus();
                setTimeout(() => {
                    this.supportQuestionSelect.api.closeDropdown(); //in case it's open
                }, 100);
            } else {
                input.classList.remove("ng-invalid");
            }
        }
    }

    checkSecurityKeyInput(event: KeyboardEvent) {
    }

    checkSupportKeyInput(event: KeyboardEvent) {
    }

    checkKeyInput(event: KeyboardEvent) {
    }

    hasAllData(): string {
        if (this.showSecuritySection) {
            //have all question ids??
            let ok = this.selectedQuestionIDs.every((value) => {
                return !!value;
            });
            if (!ok) {
                return "Please select all of the required security questions";
            }

            //if needed, have all answers??
            ok = this.securityAnswerInputs.toArray().every((item) => {
                return (!!item.nativeElement.value && item.nativeElement.value.trim().length >= this.MIN_ANSWER_LENGTH);
            });
            if (!ok) {
                return "Please provide answers to all the questions";
            }
        }

        if (this.showSupportSection) {
            if (!this.selectedSupportID) {
                return "Please select a phone support question";
            }
            if (!this.supportAnswerInput.nativeElement.value ||
                    this.supportAnswerInput.nativeElement.value.trim().length < this.MIN_ANSWER_LENGTH) {
                return "Please provide a phone support answer";
            }
        }

        if (!this.userPassword) {
            return "Please enter your current password";
        }

        return null;
    }

    doLater() {
        const modalInstance = this.modalService.open(ConfirmationModalComponent).componentInstance;

        modalInstance.title = "Skip";
        modalInstance.primary = {
            text: "Continue",
            callback: () => {
                let url: string = this._sessionService.getHomePageUrl();
                if (url.startsWith("/sf/ui")) {
                    url = url.substring(6);
                }
                this._router.navigate([url]);
                return true;
            }
        };
        modalInstance.secondary = {
            text: "Go Back"
        };
        let moreSkips = this.MAX_PROMPT_COUNT - this.promptCount;
        modalInstance.message = "Security questions are required. <br/>" +
                "You can skip this process " +
                moreSkips + " more time" + (moreSkips != 1 ? "s" : "") + ".";
    }

    save() {
        this.warning = this.hasAllData();
        if (this.warning) {
            return;
        }
        if (this.showSecuritySection) {
            let questionAnswerMap: any = {};
            this.selectedQuestionIDs.forEach((value, index) => {
                let answer = this.securityAnswerInputs.toArray()[index].nativeElement.value;
                if (answer) {
                    answer = answer.trim();
                }
                questionAnswerMap[value] = answer;
            });
            this._questionsService.saveSecurityAnswers(this._userID, this.userPassword, questionAnswerMap)
                .pipe(first())
                .subscribe(() => {
                    this._growlService.success("Security questions saved.");
                    if (this.showSupportSection) {
                        this.saveSupport();
                    }
                });
        } else {
            this.saveSupport();
        }
    }

    saveSupport() {
        let questionAnswerMap: any = {};
        let answer = this.supportAnswerInput.nativeElement.value;
        if (answer) {
            answer = answer.trim();
        }
        questionAnswerMap[this.selectedSupportID] = answer;
        this._questionsService.savePhoneSupportAnswer(this._userID, this.userPassword, questionAnswerMap)
            .pipe(first())
            .subscribe(() => {
                this._growlService.success("Support question saved.");

                let url: string = this._sessionService.getHomePageUrl();
                if (url.startsWith("/sf/ui")) {
                    url = url.substring(6);
                }
                this._router.navigate([url]);
            });
    }
}
