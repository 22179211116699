import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { LeftNavConfigService, PersistedMemoryService } from "@sf/common";
import {
    trigger,
    transition,
    state,
    animate,
    style,
    AnimationEvent
} from "@angular/animations";
import { CdkOverlayOrigin } from "@angular/cdk/overlay";

@Component({
    selector: "sf-left-nav",
    templateUrl: "./left-nav.component.html",
    styleUrls: ["./left-nav.component.scss"],
    animations: [
        trigger("navAnimation", [
            state(
                "expanded",
                style({
                    width: "180px"
                })
            ),
            state(
                "collapsed",
                style({
                    width: "30px"
                })
            ),
            transition("* => *", [animate("300ms ease-out")])
        ])
    ]
})
export class LeftNavComponent implements AfterViewInit {
    @ViewChild("trigger", { static: false }) trigger: CdkOverlayOrigin;
    capcActive = this._leftNavConfig.capcActive;
    logoLinkEnabled = this._leftNavConfig.logoLinkEnabled;
    userOptionsEnabled = this._leftNavConfig.userOptionsEnabled;
    disableAnimations = true;
    collapsed = false;
    menuCollapsed = false;
    isNavPanelOpen = false;
    overlayTriggerOrigin: CdkOverlayOrigin;
    menuTooltip = "Open navigation menu";
    collapsedTooltip = "Show navigation menu";
    expandedTooltip = "Hide navigation menu";

    public enableSalesforceChat: boolean =
        sf.liveConfig.PlatformServicesSettings.enableSalesforceChat;

    constructor(
        private _leftNavConfig: LeftNavConfigService,
        private _sessionStorage: PersistedMemoryService
    ) {
        this.collapsed = this._sessionStorage.get("navMenuCollapsed") ?? false;
        this.menuCollapsed = this.collapsed;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.overlayTriggerOrigin = this.trigger;
            this.disableAnimations = false;
        });
    }

    toggleLeftNavState() {
        this.collapsed = !this.collapsed;
        this._sessionStorage.set("navMenuCollapsed", this.collapsed);
    }

    // When expanding the menu, show the full-nav when animation starts
    captureStartEvent($event: AnimationEvent) {
        if (!$event.disabled && $event.toState === "expanded") {
            this.menuCollapsed = !this.menuCollapsed;
        }
    }

    // When collapsing the menu, don't show the collapsed menu buttons until the animation finishes
    captureDoneEvent($event: AnimationEvent) {
        if (!$event.disabled && $event.toState === "collapsed") {
            this.menuCollapsed = !this.menuCollapsed;
        }
    }
}
