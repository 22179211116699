import { Component, Injector, Input, OnInit } from "@angular/core";
import { ModalButton, unionBy } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Organization, SelectableOrganization } from "@sf/userorg/common";
import { RoleService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { UserOrgService } from "@sf/userorg/common";
import { first } from "rxjs/operators";

// prettier-ignore
@Component({
    selector: "sf-organization-multi-select-dialog",
    templateUrl: "./organization-multi-select-dialog.component.html",
    styleUrls: ["./organization-multi-select-dialog.component.scss"]
})
export class OrganizationMultiSelectDialogComponent implements OnInit {
    @Input() initialSelection: string[]; // organization IDs
    @Input() onlyAdminOrgs: boolean; // if true, only display orgs where user has admin rights
    @Input() ignoreDisabled: boolean;   // ignore disabled organizations
    @Input() organizationOptions: SelectableOrganization[]; // (optional) list of organizations to choose from

    selectedOrgs: SelectableOrganization[];
    allOrganizationOptions: SelectableOrganization[] = null;
    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private userorgRolesService: RoleService,
        private userOrgService: UserOrgService,
        private injector: Injector,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.submit.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        if (!this.organizationOptions) {
            if (this.sessionService.isSuperUser()) {
                if (this.initialSelection) {
                    this.userOrgService.getOrganizations(this.initialSelection)
                        .pipe(first())
                        .subscribe((orgs: any) => {
                            this.mergeToOrganizationOptions(orgs, true);
                        });
                }
            } else {
                let userID = this.sessionService.getUserID();

                this.userorgRolesService.getAssignmentsForUser(userID)
                    .pipe(first())
                    .subscribe((response: any) => {
                        let newOptions: SelectableOrganization[] = [];
                        // response.organizations is a map, not an array
                        Object.values(response.organizations).forEach((org: any) => {
                            if (!this.onlyAdminOrgs || org.canModify) {
                                let selectableOrg: SelectableOrganization = {
                                    id: org.id,
                                    label: this.getOrganizationName(org),
                                    selected: false,
                                    assignments: org.assignments
                                };
                                newOptions.push(selectableOrg);
                            }
                        });
                        this.organizationOptions = newOptions;
                    });
            }
        }
    }

    mergeToOrganizationOptions(orgs: Organization[], selected: boolean) {
        let newOptions: SelectableOrganization[] = [];
        orgs.forEach((org: Organization) => {
            let selectableOrg: SelectableOrganization = {
                id: org.id,
                label: this.getOrganizationName(org),
                selected: selected
                //assignments: org.assignments
            };
            newOptions.push(selectableOrg);
        });
        if (!this.organizationOptions) {
            this.organizationOptions = newOptions;
        } else {
            this.organizationOptions = unionBy(
                this.organizationOptions,
                newOptions,
                "id"
            );
        }
    }

    getOrganizationName(org: any): string {
        return org.name + " (" + org.id + ")";
    }

    orgsChanged(event: any) {
        if (event.$selection) {
            this.selectedOrgs = event.$selection;
        }
    }

    submit() {
        this.activeModal.close(this.selectedOrgs);
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
