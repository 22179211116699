<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div *ngIf="!invitePending" class="extra-bottom">
        <span>Would you like to invite this organization to sign up for the '{{parentProduct.label}}' service?</span>
    </div>
    <div *ngIf="invitePending" class="extra-bottom">
        <span>This organization has been invited to the '{{parentProduct.label}}' service. You will have to wait for
        them to accept that invitation before you can invite them to the '{{product.label}}' service.</span>
    </div>
    <div class="extra-bottom" *ngIf="!sameProduct">
        <span>Organizations need the '{{parentProduct.label}}' service before the '{{product.label}}' service can be activated.</span>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
