import { Injectable } from "@angular/core";
import { DateRangePreset } from "../interfaces/date-range-preset.interface";
import { dayjs } from "../plugins/dayjs/index";

@Injectable({
    providedIn: "root"
})
export class DatePresetService {
    /** Private Variables **/
    private _todayDate = dayjs();
    private _lastWeek = this._todayDate.clone().subtract(7, "days");
    private _nextWeek = this._todayDate.clone().add(7, "days");
    private _lastMonth = this._todayDate.clone().subtract(1, "month");
    private _nextMonth = this._todayDate.clone().add(1, "month");
    private _lastYear = this._todayDate.clone().subtract(1, "year");
    private _nextYear = this._todayDate.clone().add(1, "year");

    private _datePresets = [
        {
            id: "today",
            text: "Today",
            start: this._todayDate.clone().startOf("day"),
            end: this._todayDate.clone().endOf("day")
        },
        {
            id: "yesterday",
            text: "Yesterday",
            start: this._todayDate.clone().subtract(1, "day").startOf("day"),
            end: this._todayDate.clone().subtract(1, "day").endOf("day")
        },
        {
            id: "this_week",
            text: "This Week",
            start: this._todayDate.clone().startOf("week"),
            end: this._todayDate.isBefore(
                this._todayDate.clone().endOf("week"),
                "day"
            )
                ? this._todayDate
                : this._todayDate.clone().endOf("week")
        },
        {
            id: "last_week",
            text: "Last Week",
            start: this._lastWeek.clone().startOf("week"),
            end: this._lastWeek.clone().endOf("week")
        },
        {
            id: "this_month",
            text: "This Month",
            start: this._todayDate.clone().startOf("month"),
            end: this._todayDate.isBefore(
                this._todayDate.clone().endOf("month")
            )
                ? this._todayDate
                : this._todayDate.clone().endOf("month")
        },
        {
            id: "last_month",
            text: "Last Month",
            start: this._lastMonth.clone().startOf("month"),
            end: this._lastMonth.clone().endOf("month")
        },
        {
            id: "this_year",
            text: "This Year",
            start: this._todayDate.clone().startOf("year"),
            end: this._todayDate.isBefore(this._todayDate.clone().endOf("year"))
                ? this._todayDate
                : this._todayDate.clone().endOf("year")
        },
        {
            id: "last_year",
            text: "Last Year",
            start: this._lastYear.clone().startOf("year"),
            end: this._lastYear.clone().endOf("year")
        },
        {
            id: "past_week",
            text: "Past Week",
            start: this._todayDate.clone().subtract(7, "days"),
            end: this._todayDate.clone()
        },
        {
            id: "past_month",
            text: "Past Month",
            start: this._todayDate.clone().subtract(30, "days"),
            end: this._todayDate.clone()
        }
    ];

    private _allDatePresets = [...this._datePresets].concat([
        {
            id: "none",
            text: "None",
            start: null,
            end: null
        },
        {
            id: "custom",
            text: "Custom",
            start: null,
            end: null
        },
        {
            id: "tomorrow",
            text: "Tomorrow",
            start: this._todayDate.clone().add(1, "day").startOf("day"),
            end: this._todayDate.clone().add(1, "day").endOf("day")
        },
        {
            id: "earlier_this_week",
            text: "Earlier This Week",
            start: this._todayDate.clone().startOf("week"),
            end: this._todayDate.clone()
        },
        {
            id: "this_whole_week",
            text: "This Week", // since this has the same text as one of the standard presets, never use both
            start: this._todayDate.clone().startOf("week"),
            end: this._todayDate.clone().endOf("week")
        },
        {
            id: "later_this_week",
            text: "Later This Week",
            start: this._todayDate.clone(),
            end: this._todayDate.clone().endOf("week")
        },
        {
            id: "next_week",
            text: "Next Week",
            start: this._nextWeek.clone().startOf("week"),
            end: this._nextWeek.clone().endOf("week")
        },
        {
            id: "earlier_this_month",
            text: "Earlier This Month",
            start: this._todayDate.clone().startOf("month"),
            end: this._todayDate.clone()
        },
        {
            id: "this_whole_month",
            text: "This Month", // since this has the same text as one of the standard presets, never use both
            start: this._todayDate.clone().startOf("month"),
            end: this._todayDate.clone().endOf("month")
        },
        {
            id: "later_this_month",
            text: "Later This Month",
            start: this._todayDate.clone(),
            end: this._todayDate.clone().endOf("month")
        },
        {
            id: "next_month",
            text: "Next Month",
            start: this._nextMonth.clone().startOf("month"),
            end: this._nextMonth.clone().endOf("month")
        },
        {
            id: "earlier_this_year",
            text: "Earlier This Year",
            start: this._todayDate.clone().startOf("year"),
            end: this._todayDate.clone()
        },
        {
            id: "this_whole_year",
            text: "This Year", // since this has the same text as one of the standard presets, never use both
            start: this._todayDate.clone().startOf("year"),
            end: this._todayDate.clone().endOf("year")
        },
        {
            id: "later_this_year",
            text: "Later This Year",
            start: this._todayDate.clone(),
            end: this._todayDate.clone().endOf("year")
        },
        {
            id: "next_year",
            text: "Next Year",
            start: this._nextYear.clone().startOf("year"),
            end: this._nextYear.clone().endOf("year")
        }
    ]);

    constructor() {}

    getPresets(): DateRangePreset[] {
        return [...this._datePresets];
    }

    getDatePresets(prevBusinessDate?: dayjs.Dayjs) {
        const presets = this.getPresets();

        if (prevBusinessDate) {
            presets.unshift({
                id: "prevBusinessDay",
                text: "Previous Business Day",
                start: prevBusinessDate,
                end: prevBusinessDate
            });
        }

        return presets;
    }

    getSpecificDatePresets(presetIdArray: string[]): DateRangePreset[] {
        const presets: DateRangePreset[] = [];

        presetIdArray.forEach((presetId) => {
            if (this._datePresets.find((p) => p.id === presetId))
                presets.push(this._datePresets.find((p) => p.id === presetId));
            else if (this._allDatePresets.find((p) => p.id === presetId))
                presets.push(
                    this._allDatePresets.find((p) => p.id === presetId)
                );
        });

        return presets;
    }

    getPreset(
        start: dayjs.Dayjs,
        end: dayjs.Dayjs,
        list?: DateRangePreset[]
    ): DateRangePreset {
        if (!list) {
            list = [...this._datePresets];
        }
        return list.find((p) => {
            return p.start.isSame(start, "day") && p.end.isSame(end, "day");
        });
    }
}
