import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationItemsComponent } from "./components/navigation-items/navigation-items.component";
import { SelectModule } from "../select/select.module";
import { RouterModule } from "@angular/router";
import { NavBadgeComponent } from "./components/nav-badge/nav-badge.component";
import { NavItemComponent } from "./components/nav-item/nav-item.component";
import { NavItemLinkComponent } from "./components/nav-item-link/nav-item-link.component";
import { NavParentLinkComponent } from "./components/nav-parent-link/nav-parent-link.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReactiveFormsModule } from "@angular/forms";
import { SfCommonModule } from "../common.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        SfCommonModule,
        SelectModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        NavigationItemsComponent,
        NavBadgeComponent,
        NavItemComponent,
        NavItemLinkComponent,
        NavParentLinkComponent
    ],
    exports: [NavigationItemsComponent]
})
export class NavigationModule {}
