import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "userSelector"
})
export class UserSelectorPipe implements PipeTransform {
    transform(
        input: {
            thisOrg?: boolean;
            firstLast?: string;
            label: string;
            username?: string;
            option: string;
        }[],
        search?: {
            text: string;
        }
    ): any {
        //don't filter if we have nothing
        if (!input) return input;

        //initialize return value
        let out: any[] = [];

        //check search value, if nothing return all, else use to test data
        if (!search || !search.text || !search.text.length) {
            input.forEach((item) => {
                if (item.thisOrg) out.push(item);
            });
        } else {
            let escaped: string = search.text.replace(
                /[[\]{}()*+?\\^$|#]/g,
                "\\$&"
            );
            let regEx: RegExp = new RegExp(escaped, "i");
            input.forEach((item) => {
                let data: string =
                    (item.firstLast ? item.firstLast : item.label) +
                    (item.username ? item.username : item.option);
                if (regEx.test(data)) out.push(item);
            });
        }

        return out;
    }
}
