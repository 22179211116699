<div>
    <div class="wrapper">
        <label>
            <span>Notify me when documents are available to sign, according to the following rules:</span>
        </label>
    </div>
    <form [formGroup]="signingSettingsForm" class="wrapper">
        <div class="wrapper">
            <label>
                <input type="checkbox"  id="NOTIFICATION_SIGNING_DOCUMENTS" formControlName="NOTIFICATION_SIGNING_DOCUMENTS" class="mr-2" />
                <span>Notify me when there are </span>
                <input type="text" class="input-short" *ngIf="signingSettingsForm.get('NOTIFICATION_SIGNING_DOCUMENTS').value" id="NOTIFICATION_SIGNING_DOCUMENTS_COUNT" formControlName="NOTIFICATION_SIGNING_DOCUMENTS_COUNT" />
                <input type="text" class="input-short" disabled="disabled" *ngIf="!signingSettingsForm.get('NOTIFICATION_SIGNING_DOCUMENTS').value"/>
                <span> documents assigned to me</span>
            </label>
            <div class="alert alert-danger" *ngIf="signingSettingsForm.get('NOTIFICATION_SIGNING_DOCUMENTS_COUNT')?.errors?.min">Notification document count must be at least 1.</div>
        </div>
        <div class="wrapper">
            <label>
                <input type="checkbox" formControlName="NOTIFICATION_SIGNING_ELAPSED" class="mr-2"/>
                <span>Notify me after
                       <input type="text" class="input-short" *ngIf="signingSettingsForm.get('NOTIFICATION_SIGNING_ELAPSED').value" formControlName="NOTIFICATION_SIGNING_ELAPSED_TIME"/>
                       <input type="text" class="input-short" disabled="disabled" *ngIf="!signingSettingsForm.get('NOTIFICATION_SIGNING_ELAPSED').value"/>
                       &nbsp;minutes have passed since the last document was assigned to me</span>
            </label>
            <div class="alert alert-danger" *ngIf="signingSettingsForm.get('NOTIFICATION_SIGNING_ELAPSED').value && signingSettingsForm.get('NOTIFICATION_SIGNING_ELAPSED_TIME').errors?.min">Notification must be at least 5 minutes.</div>
        </div>
    </form>
</div>
