import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CustomButtonRefreshService {
    private _refreshPages$: BehaviorSubject<void> = new BehaviorSubject(null);

    refreshPages$: Observable<void> = this._refreshPages$.asObservable();
    constructor() {}

    triggerPagesRefresh() {
        this._refreshPages$.next();
    }
}
