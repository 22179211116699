import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { GlobalSearchResult, GroupedResults } from "./global-search.interfaces";
import { debounceTime, map, startWith, takeUntil, tap } from "rxjs/operators";
import { CdkConnectedOverlay, ConnectedPosition } from "@angular/cdk/overlay";
import { GlobalSearchResultDirective } from "./global-search-result.directive";

@Component({
    selector: "sf-global-search",
    templateUrl: "./global-search.component.html",
    styleUrls: ["./global-search.component.scss"]
})
export class GlobalSearchComponent<T extends GlobalSearchResult>
    implements AfterViewInit, OnChanges, OnDestroy
{
    private _results$ = new BehaviorSubject<GroupedResults<T>[]>(undefined);
    private _queryChange$ = new Subject<string>();
    private _destroy$ = new Subject<void>();

    @Input() results: GroupedResults<T>[];
    @Input() ariaLabel = "Search";
    @Input() placeholder = "Search...";
    @Input() allowTabSelection = false;
    @Input() debounceTime = 300;
    @Input() busy = false;
    @Input() showExpandMore = false;
    @Input() closeOnSelect: boolean = true;
    @Input() searchClickHandler: Function;

    @Output("onQueryChange") updateResults = new EventEmitter<string>();
    @Output("onSelect") select = new EventEmitter<T>();
    @Output("onGroupSelect") groupSelect = new EventEmitter<string>();
    @Output("onExpandResults") expandResults = new EventEmitter<string>();

    @ViewChild("searchInput") searchInput: ElementRef<any>;
    @ViewChild("overlay") overlay: CdkConnectedOverlay;
    @ViewChildren("panelContent", { read: ViewContainerRef })
    panelContentRef: QueryList<ViewContainerRef>;
    @ViewChild("resultWrapper") resultWrapperRef: TemplateRef<any>;
    @ViewChild("groupSection") groupSection: TemplateRef<any>;
    @ViewChild("noResults") noResultsRef: TemplateRef<any>;
    @ContentChild(GlobalSearchResultDirective)
    searchResult: GlobalSearchResultDirective;

    @HostListener("keydown.tab", ["$event"])
    checkFocus(event: Event) {
        this._checkIfFocusIsInComponent();
    }

    query = "";
    isOpen = false;
    active: string;
    private _activeIndex: number;
    positions: ConnectedPosition[] = [
        {
            originX: "end",
            originY: "bottom",
            overlayX: "end",
            overlayY: "top"
        }
    ];

    constructor(private _cdr: ChangeDetectorRef, private _el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this._results$.next(changes.results.currentValue);
            this.updateSelection();
        }
    }

    ngAfterViewInit() {
        const panel$: Observable<ViewContainerRef> =
            this.panelContentRef.changes.pipe(
                map((queryList) => {
                    return queryList?.first;
                }),
                startWith(undefined),
                takeUntil(this._destroy$)
            );
        combineLatest([this._results$, panel$])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([results, panelRef]) => {
                if (panelRef) {
                    this._renderResults(results, panelRef);
                }
            });

        this._queryChange$
            .pipe(
                debounceTime(this.debounceTime),
                tap((query) => {
                    if (query && !this.isOpen) {
                        this.isOpen = true;
                    }
                }),
                takeUntil(this._destroy$)
            )
            .subscribe((query) => {
                this.updateResults.emit(query);
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    handleEscape() {
        if (this.isOpen) {
            this.close();
        } else {
            this.query = "";
            this.updateQuery(this.query);
        }
    }

    handleEnter(event: Event) {
        if (!this.busy) {
            event.preventDefault();
            if (!this.active) {
                this.close();
            } else if (this.active.startsWith("group-expand")) {
                this.getMoreResults(
                    event,
                    this.active.replace("group-expand-", "")
                );
            } else {
                this.selectOption(event, this.active);
            }
        }
    }

    handleEnterOnOption(event: Event, id: string) {
        if (!this.busy) {
            const target = event.target;
            const shouldSelect = target === document.getElementById(id);
            if (shouldSelect) {
                this.selectOption(event, id);
            }
        }
    }

    handleEnterOnExpand(event: Event, id: string) {
        if (!this.busy) {
            const target = event.target;
            const shouldSelect =
                target === document.getElementById(`group-expand-${id}`);
            if (shouldSelect) {
                this.getMoreResults(event, id);
            }
        }
    }

    handleEnterOnHeader(event: Event, id: string) {
        const target = event.target;
        const shouldSelect = target === document.getElementById(`group-${id}`);
        if (shouldSelect) {
            this.selectGroup(event, id);
        }
    }

    handleArrowDown() {
        if (!this.busy) {
            if (!this.isOpen) {
                this.isOpen = true;
                this.updateSelection("first");
            } else {
                this.updateSelection("next");
            }
        }
    }

    handleAltArrowDown() {
        this.isOpen = true;
    }

    handleArrowUp() {
        if (!this.busy) {
            if (!this.isOpen) {
                this.isOpen = true;
                this.updateSelection("last");
            } else {
                this.updateSelection("previous");
            }
        }
    }

    exit(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.searchInput.nativeElement.focus();
    }

    handleBlur(event: Event) {
        return; //not sure why this is needed from the main input field? but it interferes with scrollbars
        // this.active = undefined;
        // this._checkIfFocusIsInComponent();
    }

    handleClickOff() {
        if (!this._el.nativeElement.contains(document.activeElement)) {
            this.isOpen = false;
        }
    }

    private _checkIfFocusIsInComponent() {
        setTimeout(() => {
            if (!this._el.nativeElement.contains(document.activeElement)) {
                this.isOpen = false;
                this._cdr.detectChanges();
            }
        });
    }

    private _renderResults(
        groups: GroupedResults<T>[],
        panel: ViewContainerRef
    ) {
        panel.clear();
        if (!groups) {
            panel.createEmbeddedView(this.noResultsRef);
        } else {
            groups.forEach((g) => {
                panel.createEmbeddedView(this.groupSection, {
                    ...g,
                    optionTemplate: this.searchResult.templateRef
                });
            });
        }
        this._cdr.detectChanges();
    }

    close() {
        this.isOpen = false;
        this.active = undefined;
        this.searchInput.nativeElement.focus();
    }

    reset() {
        this.query = "";
        this.updateQuery(this.query);
        this.searchInput.nativeElement.focus();
    }

    updateQuery(query: string) {
        this._queryChange$.next(query);
    }

    updateSelection(item?: "next" | "previous" | "first" | "last") {
        const selectableIds = this._results$.getValue()?.flatMap((g) => {
            const resultIds = g.results.map((r) => r.id);
            if (this.showExpandMore && g.results.length < g.totalResults) {
                return [...resultIds, `group-expand-${g.id}`];
            }
            return resultIds;
        });

        if (!selectableIds) {
            return;
        }
        const currentIndex =
            this._activeIndex ??
            selectableIds.findIndex((r) => {
                return r === this.active;
            });
        let nextIndex = 0;
        switch (item) {
            case "next":
                nextIndex = currentIndex + 1;
                if (nextIndex === selectableIds.length) {
                    nextIndex = 0;
                }
                break;
            case "previous":
                nextIndex = currentIndex - 1;
                if (nextIndex < 0) {
                    nextIndex = selectableIds.length - 1;
                }
                break;
            case "first":
                nextIndex = 0;
                break;
            case "last":
                nextIndex = selectableIds.length - 1;
                break;
            case undefined:
                nextIndex = currentIndex;
                break;
        }
        this._activeIndex = nextIndex;
        this.active = selectableIds[nextIndex];
        const element = document.getElementById(this.active);
        element?.scrollIntoView({
            block: "nearest"
        });
    }

    selectOption(event: Event, id: string) {
        this.select.emit(
            this._results$
                .getValue()
                ?.flatMap((g) => g.results)
                .find((r) => r.id === id)
        );
        if (this.closeOnSelect) this.close();
    }

    selectGroup(event: Event, id: string) {
        this.groupSelect.emit(id);
    }

    getMoreResults(event: Event, groupId: string) {
        this.expandResults.emit(groupId);
    }

    onSearchClick(event: Event) {
        if (this.searchClickHandler) this.searchClickHandler();
        if (!this.query) this.isOpen = true; //open recent items
    }
}
