import { Routes } from "@angular/router";
import { TestComponent } from "./test/test.component";
import { DefaultRouteGuard } from "./default-route.guard";
// import { LoadAccompanyingAngularJSModuleServiceGuard } from "@sf/angularjs/main";
import { RightContentLeftNavComponent } from "./layout/right-content-left-nav/right-content-left-nav.component";
import { PageNotFoundComponent } from "@sf/common";
import { PageNotFoundGuard } from "@sf/common";
import { SfSelectTestComponent } from "@sf/common";
import { BlankLayoutComponent, LayoutWrapperComponent } from "@sf/layout";
import { LoadRecipientPagesGuardService } from "./guards/load-recipient-pages-guard.service";
import { IsSuperUserGuard } from "./guards/is-super-user.guard";
import { ConsumerPortalGuard } from "./guards/consumer-portal.guard";

export const routes: Routes = [
    {
        path: "",
        canActivate: [DefaultRouteGuard],
        component: RightContentLeftNavComponent
    },
    {
        path: "epc",
        loadChildren: () => import("@sf/epc-main").then((m) => m.EpcModule)
    },
    {
        path: "login",
        loadChildren: () => import("@sf/login").then((m) => m.LoginModule)
    },
    {
        path: "sign-event/external",
        loadChildren: () =>
            import("@sf/sign-event/external").then(
                (m) => m.SignEventExternalModule
            )
    },
    {
        path: "consumer-portal",
        canActivate: [ConsumerPortalGuard],
        loadChildren: () =>
            import("@sf/submitter-consumer-portal").then(
                (m) => m.ConsumerPortalModule
            )
    },
    {
        path: "",
        component: LayoutWrapperComponent,
        data: {
            layout: RightContentLeftNavComponent
        },
        canActivate: [DefaultRouteGuard],
        children: [
            {
                path: "reporting",
                loadChildren: () =>
                    import("@sf/reporting/main").then((m) => m.ReportingModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["admin", "submitter", "lender"]
                }
            },
            {
                path: "admin",
                loadChildren: () =>
                    import("@sf/admin/main").then((m) => m.AdminModule),
                canLoad: [IsSuperUserGuard],
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: [
                        "admin",
                        "custom-form",
                        "doc-container",
                        "docViewer",
                        "lender",
                        "recipient",
                        "submitter"
                    ]
                }
            },
            {
                path: "extract",
                loadChildren: () =>
                    import("@sf/extract").then((m) => m.ExtractModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter", "recipient", "docViewer"]
                }
            },
            {
                path: "invitations",
                loadChildren: () =>
                    import("@sf/invitations").then((m) => m.InvitationsModule),
                data: {
                    layout: BlankLayoutComponent
                }
            },
            {
                path: "recipient",
                loadChildren: () =>
                    import("@sf/recipient/main").then((m) => m.RecipientModule),
                canLoad: [LoadRecipientPagesGuardService],
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["recipient"]
                }
            },
            {
                path: "settings",
                loadChildren: () =>
                    import("@sf/settings").then((m) => m.SettingsModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter", "lender"]
                }
            },
            // {
            //     path: "submitter/packages",
            //     component: TestComponent
            // },
            {
                path: "submitter",
                loadChildren: () =>
                    import("@sf/submitter/main").then((m) => m.SubmitterModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: [
                        "submitter",
                        "custom-form",
                        "doc-container",
                        "docViewer"
                    ]
                }
            },
            {
                path: "submitter/packages",
                loadChildren: () =>
                    import("@sf/submitter/main").then(
                        (m) => m.PackageListModule
                    ),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter"]
                }
            },
            {
                path: "lender",
                loadChildren: () =>
                    import("@sf/lender/main").then(
                        (m) => m.LenderWithRoutingModule
                    ),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["lender", "submitter"] // "submitter" should be removed as soon as the "Race state" is fixed/removed
                }
            },
            {
                path: "trustee",
                loadChildren: () =>
                    import("@sf/trustee").then((m) => m.TrusteeModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter"]
                }
            },
            {
                path: "document",
                loadChildren: () =>
                    import("./document/document.module").then(
                        (m) => m.DocumentModule
                    ),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["docViewer"]
                }
            },
            {
                path: "vendor",
                loadChildren: () =>
                    import("@sf/vendor").then((m) => m.VendorModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["vendor"]
                }
            },
            {
                path: "not-found",
                children: [
                    {
                        path: "",
                        component: PageNotFoundComponent
                    },
                    {
                        path: ":badUrl",
                        component: PageNotFoundComponent
                    }
                ]
            },
            {
                path: "signing",
                loadChildren: () =>
                    import("@sf/esign/main").then((m) => m.SigningModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter", "docViewer"]
                }
            },
            {
                path: "sign-mou",
                loadChildren: () =>
                    import("@sf/esign/main").then(
                        (m) => m.EsignDocumentViewerModule
                    ),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["submitter"]
                }
            },
            {
                path: "api-docs",
                loadChildren: () =>
                    import("@sf/api-docs-new").then((m) => m.ApiDocsNewModule),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    modules: ["api-docs-new"]
                }
            },
            {
                path: "sign-event",
                loadChildren: () =>
                    import("@sf/sign-event/main").then(
                        (m) => m.SignEventMainModule
                    ),
                // canActivate: [LoadAccompanyingAngularJSModuleServiceGuard],
                data: {
                    // TODO: Once DocBuilder is migrated to Angular we should be able to get rid of "submitter" below.
                    modules: ["sign-event", "submitter"]
                }
            },
            {
                path: "tagging",
                loadChildren: () =>
                    import(
                        "../../../../libs/signature-tagging/src/lib/consolidated-tagging"
                    ).then((m) => m.ConsolidatedTaggingModule)
            },
            {
                path: "legacy",
                loadChildren: () =>
                    import("@sf/legacy").then((m) => m.LegacyModule)
            },
            {
                path: "sf-select",
                component: SfSelectTestComponent
            },
            // THIS MUST BE THE LAST ROUTE IN THIS SECTION
            // https://angular.io/guide/router#setting-up-wildcard-routes
            {
                path: "**",
                component: PageNotFoundComponent,
                canActivate: [PageNotFoundGuard]
            }
        ]
    },
    {
        path: "angular_a",
        component: RightContentLeftNavComponent,
        children: [
            {
                path: "",
                component: TestComponent
            }
        ]
    }

    // {
    //     path: "",
    //     loadChildren: () =>
    //         import("@sf/angularjs/main").then((m) => m.AngularJSModule)
    // }
];
