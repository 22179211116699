import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    AfterViewInit
} from "@angular/core";
import { DynamicFormStore, InputComponent } from "@sf/dynamic-form-viewer";
import { merge, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-custom-party-name-field",
    templateUrl: "./custom-party-name-field.component.html",
    styleUrls: ["./custom-party-name-field.component.scss"]
})
export class CustomPartyNameFieldComponent
    extends InputComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @Output()
    onPartySelection?: EventEmitter<any> = new EventEmitter();

    @Input()
    autoCompleteData?: Observable<any[]>;
    autoCompleteDataSub: any;
    typeAhead: (text$: Observable<string>) => Observable<any[]>;
    loadingTypeahead: boolean = false;
    focus$ = new Subject<string>();

    @ViewChild("partyNameInput") partyNameInput: ElementRef;

    constructor(protected _formFacade: DynamicFormStore) {
        super(_formFacade);
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.autoCompleteData) {
            this.autoCompleteDataSub = this.autoCompleteData.subscribe(
                (data) => {
                    this.loadingTypeahead = true;
                    setTimeout(() => {
                        this.loadingTypeahead = false;
                        this.typeAhead = (text$: Observable<string>) => {
                            let debouncedText$ = text$.pipe(
                                debounceTime(200),
                                distinctUntilChanged()
                            );
                            const inputFocus$ = this.focus$;
                            return merge(debouncedText$, inputFocus$).pipe(
                                map(this._partyMatch.bind(null, data.sort()))
                            );
                        };
                    });
                }
            );
        }
    }

    ngAfterViewInit() {
        if (this.field.defaults.fieldViewState.parent === "*") {
            setTimeout(() => {
                this.partyNameInput.nativeElement.focus();
            });
        }
    }

    ngOnDestroy() {
        if (this.autoCompleteDataSub) this.autoCompleteDataSub.unsubscribe();
    }

    private _partyMatch(parties: any[], text: string) {
        let _filter = (val: any) => {
            if (val.auto_complete_name) {
                return (
                    val.auto_complete_name
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) > -1
                );
            }
            return val.toLowerCase().indexOf(text.toLowerCase()) > -1;
        };
        return !text ? parties : parties.filter(_filter);
    }

    onSelection(selectedParty: NgbTypeaheadSelectItemEvent) {
        if (this.onPartySelection) {
            setTimeout(() => {
                this.onPartySelection.emit(selectedParty.item);
            });
        }
    }
}
