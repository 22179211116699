import { normalize, NormalizedSchema, schema } from "normalizr";
import { AbstractControl } from "@angular/forms";
import * as hash from "object-hash";
import { IsEqualRule } from "../rules/is-equal-rule";
import { IsEqualAndVisibleRule } from "../rules/is-equal-and-visible-rule";
import { ContainsRule } from "../rules/contains-rule";
import { ContainsAndVisibleRule } from "../rules/contains-and-visible-rule";
import { NotNullRule } from "../rules/not-null-rule";
import { NotEmptyRule } from "../rules/not-empty-rule";
import { NotEmptyAndVisibleRule } from "../rules/not-empty-and-visible-rule";
import { ExpressionRule } from "../rules/expression-rule";
import { IsNullRule } from "../rules/is-null-rule";
import { FunctionRule } from "../rules/function-rule";
import {
    ArrayField,
    DynamicField,
    GroupField,
    SelectField
} from "../interfaces/dynamic-field.interface";
import {
    Conditional,
    ConditionalOperation,
    ConditionRule,
    Conditions,
    ConditionUpdateType,
    NewConditionalRule,
    StateChange
} from "../interfaces/conditional.interface";
import {
    FieldFormState,
    FieldState,
    FieldStateWrapper,
    FieldViewState,
    FormState,
    FormViewerValidator,
    ROOT_FIELD_KEY,
    RootFieldState,
    ViewState
} from "../interfaces/dynamic-form-state";
import { ConditionalType, FieldType } from "../enums";
import { generateRandomString } from "@sf/common";
import {
    applyValidatorsToControl,
    parseValidators
} from "./build-form.helpers";

const field: any = new schema.Entity(
    "fields",
    {},
    {
        idAttribute: (value: any, parent: any, key: string) =>
            value.fullPath.join("."),
        processStrategy: (value, parent, key) => {
            if (
                value.type === FieldType.ARRAY ||
                value.type === FieldType.SORTABLE
            ) {
                const repeatableFields = Array.isArray(value.repeatableFields)
                    ? value.repeatableFields
                    : [value.repeatableFields];
                const fieldEntities = normalize(repeatableFields, fields);
                const fieldState = parseFieldStateMap(
                    {},
                    fieldEntities.entities.fields
                );
                return {
                    ...value,
                    repeatableFieldState: {
                        fieldState,
                        viewState: getDefaultFieldViewState(fieldState),
                        formState: getDefaultFormState(fieldState),
                        conditionals: parseConditionals(fieldEntities)
                    }
                };
            }

            return {
                ...value
            };
        }
    }
);
const fields = new schema.Array(field);
field.define({ fields });

export type DynamicFieldEntities = NormalizedSchema<
    { [key: string]: { [p: string]: DynamicField } | undefined },
    string[]
>;

export function parseFields(dynamicFields: DynamicField[]) {
    return normalize(dynamicFields, fields);
}

export function parseFieldState(
    fieldEntities: DynamicFieldEntities
): FieldStateWrapper {
    const fieldStateMap: FieldStateWrapper = {
        [ROOT_FIELD_KEY]: getRootDefault(fieldEntities)
    };

    return parseFieldStateMap(fieldStateMap, fieldEntities.entities.fields);
}

export function parseFieldStateMap(
    initialFieldMap: FieldStateWrapper,
    fields: { [p: string]: DynamicField }
) {
    const fieldStateMap: FieldStateWrapper = {
        ...initialFieldMap
    };
    for (const field in fields) {
        fieldStateMap[field] = {
            ...fields[field],
            fullPath: field,
            conditionals: [],
            defaults: generateDefaults(field, fields)
        };
    }

    return fieldStateMap;
}

export function parseConditionals(
    fieldEntities: DynamicFieldEntities
): Conditions {
    const conditionals: any = {
        // [ROOT_FIELD_KEY]: this._getRootDefault(fieldEntities)
    };

    for (const field in fieldEntities.entities.fields) {
        if (fieldEntities.entities.fields[field].conditionals) {
            generateConditionals(
                fieldEntities.entities.fields[field].conditionals,
                field,
                conditionals
            );
        }
    }

    const conditions: Conditions = {};

    for (const fieldPath in conditionals) {
        conditions[fieldPath] = Object.values(conditionals[fieldPath]);
    }

    return conditions;
}

export function getDefaultFieldViewState(
    fieldState: FieldStateWrapper
): ViewState {
    const startingFieldViewState: { [path: string]: FieldViewState } = {};
    for (const fieldName in fieldState) {
        startingFieldViewState[fieldName] =
            fieldState[fieldName].defaults.fieldViewState;
    }

    return startingFieldViewState;
}

export function getDefaultFormState(fieldState: FieldStateWrapper): FormState {
    const startingFieldViewState: FormState = {};
    for (const fieldName in fieldState) {
        startingFieldViewState[fieldName] = (
            fieldState[fieldName] as FieldState
        ).defaults.formState;
    }

    return startingFieldViewState;
}

function getRootDefault(
    fieldEntities: NormalizedSchema<
        { [p: string]: { [p: string]: DynamicField } | undefined },
        string[]
    >
): RootFieldState {
    return {
        type: FieldType.ROOT,
        defaults: {
            fieldViewState: {
                visible: true,
                visibleFieldsMap: getVisibleFieldsMapForGroup(
                    fieldEntities.result,
                    fieldEntities.entities.fields
                )
            }
        }
    };
}

function getVisibleFieldsMapForGroup(
    fieldIds: string[],
    fields: { [p: string]: DynamicField },
    expanded = true
): {
    [path: string]: boolean;
} {
    return fieldIds.reduce(
        (
            visibleFields: {
                [path: string]: boolean;
            },
            fieldId
        ) => {
            visibleFields[fieldId] = expanded
                ? !!fields[fieldId].viewState.visible
                : false;

            return visibleFields;
        },
        {}
    );
}

function getArrayFields(
    fieldIds: string[],
    fields: { [p: string]: DynamicField }
) {
    return fieldIds;
}

function generateDefaults(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): {
    value: any;
    fieldViewState: FieldViewState | null;
    formState: FieldFormState;
    label: string;
} {
    return {
        value: fields[fieldName].defaultValue,
        fieldViewState: generateFieldViewStateDefault(fieldName, fields),
        formState: generateDefaultFormState(fieldName, fields),
        label: fields[fieldName].defaultLabel
    };
}

function generateFieldViewStateDefault(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): FieldViewState | null {
    const field = fields[fieldName];
    switch (field.type) {
        case FieldType.GROUP:
            return generateDefaultGroupViewState(fieldName, fields);
        case FieldType.ARRAY:
        case FieldType.SORTABLE:
            return generateDefaultArrayViewState(fieldName, fields);
        case FieldType.SELECT:
        case FieldType.MULTISELECT:
            return generateDefaultSelectViewState(fieldName, fields);
        default:
            return generateDefaultFieldViewState(fieldName, fields);
    }
    return null;
}

function generateDefaultGroupViewState(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): FieldViewState | null {
    const field = fields[fieldName] as GroupField;
    return {
        ...field.viewState,
        parent: getParentPath(fieldName),
        visibleFieldsMap: getVisibleFieldsMapForGroup(
            field.fields as unknown as string[],
            fields,
            field.expandable ? !!field.viewState.expanded : true
        )
    };
}

function generateDefaultFormState(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): FieldFormState {
    return {
        ...fields[fieldName].formState
    };
}

function generateDefaultArrayViewState(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): FieldViewState | null {
    const field = fields[fieldName] as ArrayField;
    return {
        ...field.viewState,
        parent: getParentPath(fieldName),
        arrayFields: field.fields as unknown as string[],
        visibleFieldsMap: getVisibleFieldsMapForGroup(
            field.fields as unknown as string[],
            fields,
            field.expandable ? !!field.viewState.expanded : true
        )
    };
}

function getParentPath(fieldName: string) {
    const path = fieldName.split(".");
    path.splice(path.length - 1);
    let parent = path.join(".");
    return path.length ? parent : ROOT_FIELD_KEY;
}

function generateDefaultSelectViewState(
    fieldName: string,
    fields: { [p: string]: DynamicField }
) {
    const field = fields[fieldName] as SelectField;

    return {
        ...field.viewState,
        parent: getParentPath(fieldName)
    };
}

function generateDefaultFieldViewState(
    fieldName: string,
    fields: { [p: string]: DynamicField }
): FieldViewState {
    const field = fields[fieldName];
    const path = fieldName.split(".");
    path.splice(path.length - 1, 1);
    let parent = path.join(".");
    if (path.length === 0) {
        parent = ROOT_FIELD_KEY;
    }
    return {
        ...field.viewState,
        parent
    };
}

function generateConditionals(
    conditionals: Conditional[],
    fieldPath: string,
    conditionalMap: {
        [path: string]: {
            [hash: string]: NewConditionalRule;
        };
    }
): void {
    for (const conditional of conditionals) {
        const entity = {
            type: conditional.type,
            value: conditional.value,
            visible: !!conditional.override.viewState?.visible,
            disabled: !!conditional.override.formState?.disabled,
            expression: conditional.expression,
            required: !!conditional.override.formState?.validators?.find(
                (validator) => validator.identifier === "required"
            ),
            outsideCondition: conditional.outsideCondition
                ? generateRandomString(5)
                : null
        };
        const entityHash = hash.MD5(JSON.stringify(entity));
        let pathKey: string = "";
        if (conditional.outsideCondition) {
            pathKey = "-outside-";
        } else {
            pathKey = conditional.fullPath;
        }

        if (!conditionalMap[pathKey]) {
            conditionalMap[pathKey] = {};
        }
        if (!conditionalMap[pathKey][entityHash]) {
            conditionalMap[pathKey][entityHash] = {
                match: getMatchMethodForConditional(conditional),
                fieldsToApply: [fieldPath],
                fullPath: conditional.fullPath,
                operation: getOperationForConditional(conditional),
                outsideCondition: conditional.outsideCondition
            };
        } else {
            conditionalMap[pathKey][entityHash].fieldsToApply.push(fieldPath);
        }
    }
}

function getMatchMethodForConditional(conditional: Conditional) {
    let rule: ConditionRule = null;
    switch (conditional.type) {
        case ConditionalType.EQUAL:
            rule = new IsEqualRule(conditional.value);
            break;
        case ConditionalType.EQUALANDVISIBLE:
            rule = new IsEqualAndVisibleRule(conditional.value);
            break;
        case ConditionalType.CONTAINS:
            rule = new ContainsRule(conditional.value);
            break;
        case ConditionalType.CONTAINSANDVISIBLE:
            rule = new ContainsAndVisibleRule(conditional.value);
            break;
        case ConditionalType.NOTNULL:
            rule = new NotNullRule();
            break;
        case ConditionalType.NOTEMPTY:
            rule = new NotEmptyRule();
            break;
        case ConditionalType.NOTEMPTYANDVISIBLE:
            rule = new NotEmptyAndVisibleRule();
            break;
        case ConditionalType.EXPRESSION:
            rule = new ExpressionRule(
                conditional.expression,
                conditional.field
            );
            break;
        case ConditionalType.FUNCTION:
            rule = new FunctionRule(conditional.onConditionAction);
            break;
        case ConditionalType.ISNULL:
        default:
            rule = new IsNullRule();
            break;
    }
    return (value: any, viewState?: FieldViewState) => {
        return rule.match(value, viewState);
    };
}

function getOperationForConditional(
    conditional: Conditional
): ConditionalOperation {
    if (!conditional.override) {
        return null;
    }
    return {
        operator: (
            fieldId: string,
            currentValue: any,
            control: AbstractControl,
            currentState: {
                viewState: FieldViewState;
                formState: FieldFormState;
            }
        ): StateChange => {
            const stateChange: StateChange = {
                [ConditionUpdateType.Form]: {},
                [ConditionUpdateType.View]: {}
            };
            if (conditional.override.formState) {
                stateChange[ConditionUpdateType.Form] = {
                    [fieldId]: {
                        ...conditional.override.formState
                    }
                };
                if (conditional.override.formState.validators) {
                    const existing = currentState.formState.validators.reduce(
                        (existingMap, currentValue) => {
                            existingMap[currentValue.identifier] = true;

                            return existingMap;
                        },
                        {} as { [path: string]: boolean }
                    );
                    const validators: FormViewerValidator[] = [
                        ...currentState.formState.validators
                    ];
                    for (const validator of conditional.override.formState
                        .validators) {
                        if (!existing[validator.identifier]) {
                            validators.push(validator);
                            existing[validator.identifier] = true;
                        }
                    }
                    const [syncValidators, asyncValidators] =
                        parseValidators(validators);
                    applyValidatorsToControl(
                        control,
                        syncValidators,
                        asyncValidators
                    );
                    stateChange[ConditionUpdateType.Form][fieldId].validators =
                        validators;
                }
                if (
                    typeof conditional.override.formState.disabled === "boolean"
                ) {
                    if (
                        !conditional.override.formState.disabled &&
                        control.disabled
                    ) {
                        control.enable();
                    } else if (
                        conditional.override.formState.disabled &&
                        !control.disabled
                    ) {
                        control.disable();
                    }
                    control.markAsPristine();
                }
            }
            if (conditional.override.viewState) {
                stateChange[ConditionUpdateType.View] = {
                    [fieldId]: conditional.override.viewState
                };
                if (
                    typeof conditional.override.viewState.visible === "boolean"
                ) {
                    stateChange[ConditionUpdateType.View][
                        currentState.viewState.parent
                    ] = {
                        visibleFieldsMap: {
                            [fieldId]: conditional.override.viewState.visible
                        }
                    };
                }
            }

            return stateChange;
        },
        undoOperator: (
            fieldId: string,
            currentValue: any,
            control: AbstractControl,
            currentState: {
                viewState: FieldViewState;
                formState: FieldFormState;
            }
        ): StateChange => {
            const stateChange: StateChange = {
                [ConditionUpdateType.Form]: {},
                [ConditionUpdateType.View]: {}
            };
            if (conditional.override.formState) {
                stateChange[ConditionUpdateType.Form][fieldId] = {};
                for (let key of Object.keys(conditional.override.formState)) {
                    if (
                        typeof (conditional.override.formState as any)[key] ===
                        "boolean"
                    ) {
                        (stateChange[ConditionUpdateType.Form][fieldId] as any)[
                            key
                        ] = !(conditional.override.formState as any)[key];
                    }
                }
                if (conditional.override.formState.validators) {
                    const previous =
                        conditional.override.formState.validators.reduce(
                            (existingMap, currentValue) => {
                                existingMap[currentValue.identifier] = true;

                                return existingMap;
                            },
                            {} as { [path: string]: boolean }
                        );
                    const validators = [...currentState.formState.validators];
                    let j = validators.length;
                    for (let i = 0; i < j; i) {
                        const currentValidator = validators[i];
                        if (previous[currentValidator.identifier]) {
                            validators.splice(i, 1);
                            j--;
                        } else {
                            i++;
                        }
                    }
                    const [syncValidators, asyncValidators] =
                        parseValidators(validators);
                    applyValidatorsToControl(
                        control,
                        syncValidators,
                        asyncValidators
                    );
                    stateChange[ConditionUpdateType.Form][fieldId].validators =
                        validators;
                }
                if (
                    typeof conditional.override.formState.disabled === "boolean"
                ) {
                    if (
                        conditional.override.formState.disabled &&
                        control.disabled
                    ) {
                        control.enable();
                    } else if (
                        !conditional.override.formState.disabled &&
                        !control.disabled
                    ) {
                        control.disable();
                    }
                    control.markAsPristine();
                }
            }
            if (conditional.override.viewState) {
                stateChange[ConditionUpdateType.View][fieldId] = {};
                for (let key of Object.keys(conditional.override.viewState)) {
                    if (
                        typeof (conditional.override.viewState as any)[key] ===
                        "boolean"
                    ) {
                        (stateChange[ConditionUpdateType.View][fieldId] as any)[
                            key
                        ] = !(conditional.override.viewState as any)[key];
                    }
                }
                if (
                    typeof conditional.override.viewState.visible === "boolean"
                ) {
                    stateChange[ConditionUpdateType.View][
                        currentState.viewState.parent
                    ] = {
                        visibleFieldsMap: {
                            [fieldId]: !conditional.override.viewState.visible
                        }
                    };
                }
            }

            return stateChange;
        }
    };
}
