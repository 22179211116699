<button
    class="btn btn-link"
    [ngbPopover]="helpPopoverContent"
    triggers="click"
    [autoClose]="true"
    popoverClass="control-popover"
    aria-label="Help"
><fa-icon
    [icon]="['far', 'question-circle']"
    class="p-1"
    ngbTooltip="Help"
    ></fa-icon></button>

<ng-template #helpPopoverContent>
    <ul>
        <li *ngIf="helpLink"><a [href]="helpLink" target="_blank">About&nbsp;This&nbsp;Page</a></li>
        <li><a [href]="documentationLink" target="_blank">Documentation</a></li>
        <li><button class="btn btn-link" (click)="goToResourceCenter()">Resource&nbsp;Center</button></li>
        <li *ngFor="let option of additionalOptions">
            <a *ngIf="option.link" class="text-nowrap" [href]="option.link" [attr.target]="option.newTab ? '_blank' : ''">{{option.text}}</a>
            <button *ngIf="option.action" class="btn btn-link text-nowrap" (click)="option.action()">{{option.text}}</button>
        </li>
    </ul>
</ng-template>
