import { Injectable } from "@angular/core";
import { dayjs } from "../../date-and-time/plugins/dayjs/index";

@Injectable({
    providedIn: "root"
})
export class SortUtilitiesService {
    constructor() {}

    static stringSortCompare(one: string, two: string) {
        if (!one && !two) {
            return 0;
        }
        if (!one) {
            return -1;
        }
        if (!two) {
            return 1;
        }
        if (one < two) {
            return -1;
        }
        if (one > two) {
            return 1;
        }
        return 0;
    }

    static numberSortCompare(one: number, two: number) {
        if (one == undefined && two == undefined) {
            return 0;
        }
        if (one == undefined) {
            return -1;
        }
        if (two == undefined) {
            return 1;
        }
        if (one < two) {
            return -1;
        }
        if (one > two) {
            return 1;
        }
        return 0;
    }

    static dateSortCompare(one: string, two: string) {
        if (!one && !two) {
            return 0;
        }
        if (!one) {
            return -1;
        }
        if (!two) {
            return 1;
        }

        let a = dayjs(one).format("XX");
        let b = dayjs(two).format("XX");
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    static stringSortCompareInsensitive(one: string, two: string) {
        if (!one && !two) {
            return 0;
        }
        if (!one) {
            return -1;
        }
        if (!two) {
            return 1;
        }
        let oneLower = one.toLowerCase();
        let twoLower = two.toLowerCase();
        if (oneLower < twoLower) {
            return -1;
        }
        if (oneLower > twoLower) {
            return 1;
        }
        return 0;
    }

    static booleanSortCompare(a: boolean, b: boolean) {
        if (a && b) {
            return 0;
        }
        if (a) {
            return 1;
        }
        return -1;
    }

    static intSortCompare(one: number, two: number) {
        if (one < two) {
            return -1;
        }
        if (one > two) {
            return 1;
        }
        return 0;
    }
}
