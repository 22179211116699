import { Component, Input, OnInit } from "@angular/core";
import {
    ContractService,
    Organization,
    ProductExtended,
    RoleService,
    UserorgActivationService
} from "@sf/userorg/common";
import {
    GrowlService,
    ModalButton,
    SelectableItem,
    SelectableItemWithID,
    SortUtilitiesService
} from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { EnrollmentCodeSelectDialogComponent } from "@sf/userorg/main";
import { Router } from "@angular/router";

interface AdministratorUser {
    name: string;
    id: string;
    email: string;
    selected: boolean;
}

// prettier-ignore
@Component({
    selector: "sf-organization-service-trial-dialog",
    templateUrl: "./organization-service-trial-dialog.component.html",
    styleUrls: ["./organization-service-trial-dialog.component.scss"]
})
export class OrganizationServiceTrialDialogComponent implements OnInit {
    @Input() title: string;
    @Input() organization: Organization;
    @Input() product: ProductExtended;
    @Input() action: string;    // "trial"

    selectableRenewalFrequencies: SelectableItem[];
    selectableBillingCycles: SelectableItemWithID[];
    selectablePaymentTerms: SelectableItemWithID[];

    activating = false;
    requestSent = false;
    selected = {};
    currentIndex = 0;
    supportedProduct = false;
    showAffectedUsers = false;
    showDeletedUsers = false;
    showAdministrators = false;
    sendNotification = true;
    enrollmentCode = "";
    isEnrollmentCodeVisible = false;

    freeSubmissions = 0;
    renewalFrequency = "YEARLY";
    billingCycle = "MONTHLY";
    recordingFeeBillingCycle = "DAILY";
    paymentTerm = "NET15";
    warning = "";

    // for erecording
    licenseFee = "";
    renewalFee = "";
    submissionFee = "";

    // for vendor
    exclusiveSubmissionFee = "";
    selectedShareOption: SelectableItemWithID = null;
    selectableShareOptions = [
        {
            label: "Submission Fee Percentage",
            id: "PERCENT"
        },
        {
            label: "Per Document Flat",
            id: "FLAT"
        }
    ];

    // for docbuilder and trustee
    documentFee = "";

    // for lien release
    lienReleaseFee = "";

    // for subscription
    subscriptionMinimum = "";

    // for lender
    loanCreateFee = "";
    loanAcceptFee = "";

    // for settlement
    disbursementFee = "";
    revShareFee = "";
    verifiedInsecureFee = "";

    // for esign_event
    ipenFee = "";
    notarizationFee = "";
    ronFee = "";

    // for paper
    inStateErecordFee = "";
    outOfStateErecordFee = "";
    inStatePaperFee = "";
    outOfStatePaperFee = "";
    paperMailFee = "";
    embeddedEmployeeFee = "";

    loadingAdmins = true;
    primary: ModalButton;
    secondary: ModalButton;

    affectedUserNames: string[] = [];
    deletedUserNames: string[] = [];
    administratorUsers: AdministratorUser[] = [];

    constructor(
            private activeModal: NgbActiveModal,
            private router: Router,
            private contractService: ContractService,
            private modalService: NgbModal,
            private roleService: RoleService,
            private growlService: GrowlService,
            private activationService: UserorgActivationService
    ) {}

    ngOnInit() {
        this.activating = (this.action == "trial");

        // Modal buttons
        this.primary = {
            text: this.activating ? "Activate" : "Deactivate",
            disabled: true,
            callback: this.initiateRequest.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.roleService
            .getUsersWithPermissions(this.organization.id)
            .pipe(first())
            .subscribe((response: any) => {
                this.loadingAdmins = false;
                let affectedProductIDs = this.buildAffectedProductIDList(this.product);
                let users: any[] = [];
                if (response.users) {
                    users = response.users.sort((a: any, b: any) => {
                        return SortUtilitiesService.stringSortCompareInsensitive(a.name, b.name);
                    });
                }
                let administrators: any = {};
                let affectedUsers: any = {};
                let deletedUsers: any = {};

                users.forEach((user) => {
                    // will some users be affected by removing a service?
                    if (!this.activating) {
                        let hasAffectedRole = false;
                        let hasUnaffectedRole = false;
                        user.roles.forEach((role: any) => {
                            if (affectedProductIDs.includes(role.productID)) {
                                hasAffectedRole = true;
                            } else {
                                hasUnaffectedRole = true;
                            }
                        });
                        if (hasAffectedRole) {
                            if (hasUnaffectedRole) {
                                affectedUsers[user.name] = user;
                            } else {
                                deletedUsers[user.name] = user;
                            }
                        }
                    }
                    // is this user an admin for this org?
                    user.permissions.forEach((permission: any) => {
                        if (
                                permission.name == "organization_admin" ||
                                permission.name == "submitter_admin" ||
                                permission.name == "organization_users"
                        ) {
                            administrators[user.name] = user;
                        }
                    });
                });

                // convert to arrays for ngFor
                Object.values(affectedUsers).forEach((user: any) => {
                    this.affectedUserNames.push(user.name);
                });

                Object.values(deletedUsers).forEach((user: any) => {
                    this.deletedUserNames.push(user.name);
                });

                // note that administrators is an object - not array
                Object.values(administrators).forEach((user: any) => {
                    this.administratorUsers.push({
                        name: user.name,
                        id: user.id,
                        email: user.email,
                        selected: true
                    });
                });
            });

        this.selectableBillingCycles = this.contractService.getSelectableBillingCycles(this.product.productID);
        this.selectablePaymentTerms = this.contractService.getSelectablePaymentTerms();

        if (this.isSupportedProduct(this.product.productID)) {
            this.supportedProduct = true;
            this.primary.disabled = false;
        }

        if (!this.activating) {
            this.supportedProduct = true;
            this.primary.disabled = false;
        }

        setTimeout(() => {
            this.showEnrollmentCode();
        }, 200)
    }

    getSelectedAdministratorCount(): number {
        let result = 0;
        this.administratorUsers.forEach((user) => {
            if (user.selected) {
                result++;
            }
        });
        return result;
    }

    isSupportedProduct(productID: string) {
        let supportedProducts = this.activationService.getEditableProducts(this.organization.id);
        return supportedProducts.includes(productID);
    }

    showEnrollmentCode() {
        let contractType = "INVALID";
        if (this.product.productID == "licensed_collaborator_lender") {
            contractType = "LENDER";
        } else if (this.product.productID == "licensed_collaborator_settlement") {
            contractType = "SETTLEMENT";
        } else if (this.product.productID == "submitter") {
            contractType = "ERECORDING";
        } else if (this.product.productID == "submitter_signing") {
            contractType = "DOCUMENT_BUILDER";
        } else if (this.product.productID == "esign_event") {
            contractType = "ESIGN_EVENT";
        } else if (this.product.productID == "trustee") {
            contractType = "TRUSTEE";
        } else if (this.product.productID == "mers_api_integration") {
            contractType = "LIEN_RELEASE";
        } else if (this.product.productID == "subscription") {
            contractType = "SUBSCRIPTION";
        } else if (this.product.productID == "submitter_paper") {
            contractType = "PAPER_RECORDING";
        }

        const modalRef = this.modalService.open(EnrollmentCodeSelectDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = contractType;

        modalRef.result.then((template: any) => {
            if (template) {
                this.enrollmentCode = template.registrationCode;
                this.product.trialRegistrationCode = template.registrationCode;
                if (template.renewalFrequency) {
                    this.renewalFrequency = template.renewalFrequency;
                }
                if (template.contractTemplateFees) {
                    if (template.contractTemplateFees.LICENSE_FEE) {
                        this.licenseFee = template.contractTemplateFees.LICENSE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.RENEWAL_FEE) {
                        this.renewalFee = template.contractTemplateFees.RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.SUBMISSION_FEE) {
                        this.submissionFee = template.contractTemplateFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE) {
                        this.exclusiveSubmissionFee =
                                template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE) {
                        this.licenseFee =
                                template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE) {
                        this.renewalFee =
                                template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE) {
                        this.documentFee =
                                template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.SETTLEMENT_RENEWAL_FEE) {
                        this.renewalFee =
                                template.contractTemplateFees.SETTLEMENT_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE) {
                        this.loanCreateFee =
                                template.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.SETTLEMENT_LOAN_ACCEPT_FEE) {
                        this.loanAcceptFee =
                                template.contractTemplateFees.SETTLEMENT_LOAN_ACCEPT_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.DISBURSEMENT_FEE) {
                        this.disbursementFee =
                                template.contractTemplateFees.DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.REVSHARE_FEE) {
                        this.revShareFee = template.contractTemplateFees.REVSHARE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE) {
                        this.verifiedInsecureFee =
                                template.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.LENDER_ANNUAL_FEE) {
                        this.renewalFee = template.contractTemplateFees.LENDER_ANNUAL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.LENDER_LOAN_CREATE_FEE) {
                        this.loanCreateFee =
                                template.contractTemplateFees.LENDER_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.LENDER_LOAN_ACCEPT_FEE) {
                        this.loanAcceptFee =
                                template.contractTemplateFees.LENDER_LOAN_ACCEPT_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.TRUSTEE_DOCUMENT_FEE) {
                        this.documentFee = template.contractTemplateFees.TRUSTEE_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.LIEN_RELEASE_FEE) {
                        this.lienReleaseFee = template.contractTemplateFees.LIEN_RELEASE_FEE.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.SUBSCRIPTION_MINIMUM) {
                        this.subscriptionMinimum = template.contractTemplateFees.SUBSCRIPTION_MINIMUM.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.ESE_LICENSE_FEE) {
                        this.licenseFee = template.contractTemplateFees.ESE_LICENSE_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.ESE_RENEWAL_FEE) {
                        this.renewalFee = template.contractTemplateFees.ESE_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.ESE_NO_ENOTARIZATION) {
                        this.notarizationFee =
                                template.contractTemplateFees.ESE_NO_ENOTARIZATION.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.ESE_IPEN) {
                        this.ipenFee = template.contractTemplateFees.ESE_IPEN.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.ESE_RON) {
                        this.ronFee = template.contractTemplateFees.ESE_RON.contractFeeTiers[0].feeAmount;
                    }

                    if (template.contractTemplateFees.PAPER_SUBMISSION_FEE) {
                        this.submissionFee = template.contractTemplateFees.PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE) {
                        this.inStateErecordFee = template.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE) {
                        this.outOfStateErecordFee = template.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE) {
                        this.inStatePaperFee = template.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE) {
                        this.outOfStatePaperFee = template.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.PAPER_MAIL_FEE) {
                        this.paperMailFee = template.contractTemplateFees.PAPER_MAIL_FEE.contractFeeTiers[0].feeAmount;
                    }
                    if (template.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE) {
                        this.embeddedEmployeeFee = template.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE.contractFeeTiers[0].feeAmount;
                    }
                }
                this.freeSubmissions = template.freeSubmissions;
                this.billingCycle = template.billingCycleType;
                this.paymentTerm = template.paymentTerms;
                this.isEnrollmentCodeVisible = true;
            }
        }, () => {
            // cancel
        });
    }

    changeEnrollmentCode() {
        this.showEnrollmentCode();
    }

    selectShareOption($event: any) {
        let newOption: SelectableItemWithID = $event.$selection;
        this.selectedShareOption = newOption;
    }

    selectRenewalFrequency($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.renewalFrequency = cycle.option;
    }

    selectBillingCycle($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.billingCycle = cycle.id;
    }

    selectRecordingFeeBillingCycle($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.recordingFeeBillingCycle = cycle.id;
    }

    selectPaymentTerm($event: any) {
        let term: SelectableItemWithID = $event.$selection;
        this.paymentTerm = term.id;
    }

    addAffectedProduct(product: ProductExtended, productIDs: string[]) {
        productIDs.push(product.productID);
        if (product.childProducts) {
            product.childProducts.forEach((child: ProductExtended) => {
                this.addAffectedProduct(child, productIDs);
            });
        }
    }

    buildAffectedProductIDList(product: ProductExtended) {
        let productIDs: string[] = [];
        this.addAffectedProduct(product, productIDs);
        return productIDs;
    }

    initiateRequest() {
        if (this.activating && !this.enrollmentCode) {
            this.warning = "An Enrollment Code is required";
            return;
        }

        let notifyAdmins: string[] = [];
        if (this.sendNotification) {
            this.administratorUsers.forEach((user) => {
                if (user.selected) {
                    notifyAdmins.push(user.id);
                }
            });
        }

        this.requestSent = true;

        if (this.activating) {
            this.activationService
                .activateProduct(this.organization, this.product, this.action, false, notifyAdmins, null)
                .subscribe((results: any) => {
                    this.product.status = "ACTIVE";
                    this.activeModal.close(true);
                }, (err: any) => {
                    log.error("error", err);
                    this.requestSent = false;
                });
        } else {
            this.activationService
                .deactivateProduct(this.organization, this.product, notifyAdmins, null, null)
                .subscribe((results: any) => {
                    this.product.status = "DISABLED";
                    this.activeModal.close(true);
                }, (err: any) => {
                    log.error("error", err);
                    this.requestSent = false;
                });
        }
    }

    cancel() {
        this.activeModal.close(false);
    }
}
