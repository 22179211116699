import { Component, Input } from "@angular/core";

@Component({
    selector: "sf-video-control",
    templateUrl: "./video-control.component.html",
    styleUrls: ["./video-control.component.scss"]
})
export class VideoControlComponent {
    @Input() videoLink: string;
    @Input() videoTooltip: string;
}
