import { Component, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UINotarySetup } from "../../interfaces/sign-event.interface";
import { Router } from "@angular/router";
import { StatesService } from "@sf/common";

@Component({
    selector: "sf-notary-problems-dialog",
    templateUrl: "./notary-problems-dialog.component.html",
    styleUrls: ["./notary-problems-dialog.component.scss"]
})
export class NotaryProblemsDialogComponent implements OnInit {
    notaryStatus: UINotarySetup;

    primary: ModalButton;
    notaryState: string;
    title = "Notary Setup Error";

    constructor(
        private _activeModal: NgbActiveModal,
        private _router: Router,
        private _states: StatesService
    ) {}

    ngOnInit(): void {
        this.notaryState = "Unknown";
        this.primary = {
            text: "OK",
            responseValue: 1
        };
        if (!this.notaryStatus.stateVerificationPassed) {
            this.notaryState = this._states
                .getAllStatesPlusDC()
                .find(
                    (st) => st.abbrev === this.notaryStatus.notaryState
                )?.name;
        }
    }

    goNotarySetup() {
        this._activeModal.close();
        this._router.navigateByUrl("/settings/notary-credentials");
    }
}
