import { Injectable } from "@angular/core";
import {
    ConditionalOperator,
    Conditions,
    ConditionUpdateType,
    NewConditionalRule,
    StateChange
} from "../interfaces/conditional.interface";
import {
    filter,
    map,
    pairwise,
    reduce,
    startWith,
    switchMap,
    take
} from "rxjs/operators";
import { combineLatest, concat, EMPTY, Observable, Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import { deepMergeOverrideArrays } from "@sf/common";
import { DynamicFormStore } from "./dynamic-form-store";
import { FieldViewState, FormState, ViewState } from "../interfaces";

export interface FieldStateChange {
    [field: string]: {
        undo: ConditionalOperator[];
        change: ConditionalOperator[];
    };
}

@Injectable()
export class FormViewerConditions {
    private _fieldSubscriptions: {
        [path: string]: Subscription;
    } = {};
    private _fieldRules: {
        [path: string]: NewConditionalRule[];
    } = {};

    form$ = this._facade.formGroup$.pipe(filter((formGroup) => !!formGroup));

    constructor(private _facade: DynamicFormStore) {}

    load(conditions: Conditions) {
        const currentKeys = Object.keys(this._fieldSubscriptions);

        this.remove(currentKeys);
        for (const fieldPath in conditions) {
            if (conditions.hasOwnProperty(fieldPath)) {
                if (fieldPath === "-outside-") {
                    for (let c = 0; c < conditions[fieldPath].length; ++c) {
                        this._subscribeToOutsideCondition(
                            fieldPath,
                            conditions[fieldPath][c],
                            c
                        );
                    }
                } else {
                    this._subscribeToField(fieldPath, conditions[fieldPath]);
                }
            }
        }
    }

    remove(paths: string[]) {
        for (let path of paths) {
            if (this._fieldSubscriptions[path]) {
                this._fieldSubscriptions[path].unsubscribe();
                delete this._fieldSubscriptions[path];
            }
        }
    }

    private _subscribeToOutsideCondition(
        fieldPath: string,
        outsideConditionRule: NewConditionalRule,
        ruleIndex: number
    ) {
        this._fieldSubscriptions[fieldPath + ruleIndex] =
            outsideConditionRule.outsideCondition
                .pipe(
                    switchMap((outsideCondition: any) => {
                        const fieldStateChanges: FieldStateChange = {};

                        for (const field of outsideConditionRule.fieldsToApply) {
                            this._addFieldStateIfNotExists(
                                field,
                                fieldStateChanges
                            );
                            if (outsideConditionRule.match(outsideCondition)) {
                                fieldStateChanges[field]["change"].push(
                                    outsideConditionRule.operation.operator
                                );
                            } else {
                                fieldStateChanges[field]["undo"].push(
                                    outsideConditionRule.operation.undoOperator
                                );
                            }
                        }

                        const operations: Observable<StateChange>[] = [];
                        for (let field of outsideConditionRule.fieldsToApply) {
                            if (
                                (fieldStateChanges[field]["undo"] &&
                                    fieldStateChanges[field]["undo"].length >
                                        0) ||
                                (fieldStateChanges[field]["change"] &&
                                    fieldStateChanges[field]["change"].length >
                                        0)
                            ) {
                                operations.push(
                                    combineLatest([
                                        this._facade.getFormStateForField(
                                            field
                                        ),
                                        this._facade.getViewStateForField(
                                            field
                                        ),
                                        this._facade.getFormControl(field)
                                    ]).pipe(
                                        take(1),
                                        map(
                                            ([
                                                formState,
                                                viewState,
                                                control
                                            ]) => {
                                                let newState =
                                                    this._generateState(
                                                        formState as any,
                                                        viewState as any,
                                                        control as any,
                                                        fieldStateChanges,
                                                        field,
                                                        ""
                                                    );
                                                return newState;
                                            }
                                        )
                                    )
                                );
                            }
                        }

                        if (operations.length > 0) {
                            return concat(...operations).pipe(
                                reduce((current, stateChange) => {
                                    return deepMergeOverrideArrays(
                                        current,
                                        stateChange
                                    );
                                }, {} as StateChange)
                            );
                        }

                        return EMPTY;
                    })
                )
                .subscribe((update) => {
                    this._facade.performOperation(update);
                });
    }

    private _subscribeToField(fieldPath: string, rules: NewConditionalRule[]) {
        this._fieldRules[fieldPath] = rules;
        this._fieldSubscriptions[fieldPath] = this._facade
            .getFormControl(fieldPath)
            .pipe(
                switchMap((control: FormControl) => {
                    if (!control) {
                        return EMPTY;
                    }
                    return control.valueChanges.pipe(
                        startWith(control.value),
                        startWith(null)
                    );
                }),
                pairwise(),
                map(([prev, value]) => {
                    // this "map" Determine if changes need to be made,
                    // and if the changes need to execute the operator
                    // or the undo operator
                    const fieldStateChanges: {
                        [field: string]: {
                            undo: ConditionalOperator[];
                            change: ConditionalOperator[];
                        };
                    } = {};
                    for (const rule of rules) {
                        const operation = rule.operation;

                        if (rule.match(prev) && !rule.match(value)) {
                            for (const field of rule.fieldsToApply) {
                                this._addFieldStateIfNotExists(
                                    field,
                                    fieldStateChanges
                                );
                                fieldStateChanges[field]["undo"].push(
                                    operation.undoOperator
                                );

                                // check dependant fields too
                                this._checkChildFieldState(
                                    field,
                                    fieldStateChanges
                                );
                                this._performChildFieldOperations(
                                    fieldStateChanges
                                );
                            }
                        } else if (rule.match(value) && !rule.match(prev)) {
                            for (const field of rule.fieldsToApply) {
                                this._addFieldStateIfNotExists(
                                    field,
                                    fieldStateChanges
                                );
                                fieldStateChanges[field]["change"].push(
                                    operation.operator
                                );

                                // check dependant fields too
                                this._checkChildFieldState(
                                    field,
                                    fieldStateChanges
                                );
                                this._performChildFieldOperations(
                                    fieldStateChanges
                                );
                            }
                        }
                    }

                    return {
                        fieldStateChanges,
                        currentValue: value
                    };
                }),
                switchMap(({ fieldStateChanges, currentValue }) => {
                    // this "switchmap" builds the StateChange object
                    const operations: Observable<StateChange>[] = [];
                    for (let field of Object.keys(fieldStateChanges).sort()) {
                        if (
                            fieldStateChanges.hasOwnProperty(field) &&
                            (fieldStateChanges[field].undo.length > 0 ||
                                fieldStateChanges[field].change.length > 0)
                        ) {
                            operations.push(
                                combineLatest([
                                    this._facade.getFormStateForField(field),
                                    this._facade.getViewStateForField(field),
                                    this._facade.getFormControl(field)
                                ]).pipe(
                                    take(1),
                                    map(([formState, viewState, control]) => {
                                        return this._generateState(
                                            formState as any,
                                            viewState as any,
                                            control as any,
                                            fieldStateChanges,
                                            field,
                                            currentValue
                                        );
                                    })
                                )
                            );
                        }
                    }

                    if (operations.length > 0) {
                        return concat(...operations).pipe(
                            reduce((current, stateChange) => {
                                return deepMergeOverrideArrays(
                                    current,
                                    stateChange
                                );
                            }, {} as StateChange)
                        );
                    }

                    return EMPTY;
                })
            )
            .subscribe((update) => {
                this._facade.performOperation(update);
            });
    }

    private _checkChildFieldState(
        fieldPath: string,
        fieldStateChanges: {
            [fieldPath: string]: {
                undo: ConditionalOperator[];
                change: ConditionalOperator[];
                value?: any;
            };
        }
    ) {
        // get the field FieldViewState that WILL occur after the list of fieldStateChanges are all applied
        combineLatest([
            this._facade.getFormStateForField(fieldPath),
            this._facade.getViewStateForField(fieldPath),
            this._facade.getFormControl(fieldPath)
        ])
            .pipe(take(1))
            .subscribe(([formState, viewState, control]) => {
                let newViewState: Partial<FieldViewState> = this._generateState(
                    formState as any,
                    viewState as any,
                    control as any,
                    fieldStateChanges,
                    fieldPath,
                    (control as FormControl).value
                )[ConditionUpdateType.View][fieldPath];

                // base all rule matches on the future state of the given field
                let rules = this._fieldRules[fieldPath];
                if (rules) {
                    for (const rule of rules) {
                        const operation = rule.operation;
                        let value;
                        this._facade
                            .getFormControl(fieldPath)
                            .pipe(take(1))
                            .subscribe((control) => {
                                value = control.value;

                                let ruleMatch = rule.match(value, newViewState);
                                for (const field of rule.fieldsToApply) {
                                    this._addFieldStateIfNotExists(
                                        field,
                                        fieldStateChanges
                                    );
                                    fieldStateChanges[field].value = (
                                        control as FormControl
                                    ).value;
                                    fieldStateChanges[field][
                                        ruleMatch ? "change" : "undo"
                                    ].push(
                                        ruleMatch
                                            ? operation.operator
                                            : operation.undoOperator
                                    );

                                    // recursively check dependant fields too
                                    this._checkChildFieldState(
                                        field,
                                        fieldStateChanges
                                    );
                                }
                            });
                    }
                }
            });
    }

    private _performChildFieldOperations(childFieldStateChanges: {
        [fieldPath: string]: {
            undo: ConditionalOperator[];
            change: ConditionalOperator[];
            value?: any;
        };
    }) {
        const operations: Observable<StateChange>[] = [];
        for (let field of Object.keys(childFieldStateChanges).sort()) {
            if (
                childFieldStateChanges.hasOwnProperty(field) &&
                (childFieldStateChanges[field].undo.length > 0 ||
                    childFieldStateChanges[field].change.length > 0)
            ) {
                operations.push(
                    combineLatest([
                        this._facade.getFormStateForField(field),
                        this._facade.getViewStateForField(field),
                        this._facade.getFormControl(field)
                    ]).pipe(
                        take(1),
                        map(([formState, viewState, control]) => {
                            return this._generateState(
                                formState as any,
                                viewState as any,
                                control as any,
                                childFieldStateChanges,
                                field,
                                childFieldStateChanges[field].value
                            );
                        })
                    )
                );
            }
        }

        concat(...operations)
            .pipe(
                reduce((current, stateChange) => {
                    return deepMergeOverrideArrays(current, stateChange);
                }, {} as StateChange)
            )
            .subscribe((update) => {
                this._facade.performOperation(update);
            });
    }

    private _generateState(
        formState: FormState,
        viewState: ViewState,
        control: FormControl,
        fieldStateChanges: FieldStateChange,
        field: string,
        currentValue: any
    ): StateChange {
        let currentState = {
            [ConditionUpdateType.Form]: {
                [field]: {
                    ...formState
                }
            },
            [ConditionUpdateType.View]: {
                [field]: {
                    ...viewState
                }
            }
        };

        let hasFormChange = false;
        let hasViewChange = false;

        for (const operator of [
            ...fieldStateChanges[field].undo,
            ...fieldStateChanges[field].change
        ]) {
            const change = operator(field, currentValue, control, {
                formState: currentState[ConditionUpdateType.Form][field] as any,
                viewState: currentState[ConditionUpdateType.View][field] as any
            });
            if (change[ConditionUpdateType.Form]) {
                hasFormChange = true;
            }
            if (change[ConditionUpdateType.View]) {
                hasViewChange = true;
            }
            currentState = deepMergeOverrideArrays(currentState, change);
        }

        if (!hasFormChange) {
            delete currentState[ConditionUpdateType.Form];
        }

        if (!hasViewChange) {
            delete currentState[ConditionUpdateType.View];
        }

        return currentState as StateChange;
    }

    private _addFieldStateIfNotExists(
        field: string,
        fieldStateChanges: {
            [field: string]: {
                undo: ConditionalOperator[];
                change: ConditionalOperator[];
            };
        }
    ) {
        if (!fieldStateChanges[field]) {
            fieldStateChanges[field] = {
                undo: [],
                change: []
            };
        }
    }
}
