import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import {
    dayjs,
    GrowlService,
    ModalButton,
    SelectableItemWithID,
    SfValidators
} from "@sf/common";
import {
    EnrollmentCodeSelectDialogComponent,
    LicenseUploadData,
    LicenseUtilityService
} from "@sf/userorg/main";
import {
    BillingCycleType,
    ContractFee,
    ContractService,
    PaymentTerms,
    RenewalFrequencyType
} from "@sf/userorg/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Component({
    selector: "sf-docbuilder-ron-upload-dialog",
    templateUrl: "./docbuilder-ron-upload-dialog.component.html",
    styleUrls: ["./docbuilder-ron-upload-dialog.component.scss"]
})
export class DocbuilderRonUploadDialogComponent implements OnInit {
    @Input() isActive: boolean;

    currentIndex = 0;
    stepCount = 2;
    warning: SafeHtml = "";
    nextButton: ModalButton;
    backButton: ModalButton;

    maxDate: dayjs.Dayjs = dayjs().add(1, "year").startOf("day");
    maxSignDate? = dayjs();
    minSignDate: dayjs.Dayjs;
    maxEffectiveDate: dayjs.Dayjs = this.maxDate;
    primary: ModalButton;
    secondary: ModalButton;
    title = "Document Builder RON Addendum Upload";

    expirationDayjs: dayjs.Dayjs;
    effectiveDayjs: dayjs.Dayjs;
    today = dayjs();

    showPricingTiers = false;
    selectableBillingCycles: SelectableItemWithID[];
    selectablePaymentTerms: SelectableItemWithID[];

    dialogData: LicenseUploadData = {
        executedDate: null,
        effectiveDate: null,
        signatureName: null,
        signatureTitle: null,
        expirationDate: null,
        ronEventFee: null,
        billingCycle: BillingCycleType.MONTHLY,
        paymentTerms: PaymentTerms.NET15,
        file: null
    };

    @ViewChild("file") file: any;

    constructor(
        private activeModal: NgbActiveModal,
        private growlService: GrowlService,
        private modalService: NgbModal,
        private licenseService: LicenseUtilityService,
        private contractService: ContractService
    ) {}

    ngOnInit() {
        this.setMinSignDate();

        // Modal buttons
        this.primary = {
            text: "Upload",
            disabled: false,
            callback: this.ok.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.selectableBillingCycles =
            this.contractService.getSelectableBillingCycles("DOC_BUILDER_RON");
        this.selectablePaymentTerms =
            this.contractService.getSelectablePaymentTerms();
        this.effectiveDayjs = dayjs();

        this.nextButton = {
            text: "Next",
            hidden: false,
            callback: this.nextTab.bind(this)
        };
        this.backButton = {
            text: "Back",
            hidden: false,
            callback: this.backTab.bind(this)
        };
        this.enableDisable();
    }

    setMinSignDate(): void {
        let period: dayjs.UnitType = "year";
        if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
            period = "month";
        }
        this.minSignDate = dayjs().subtract(1, period).add(1, "day");
    }

    enableDisable() {
        this.nextButton.hidden = this.currentIndex > 0;
        this.backButton.hidden = this.currentIndex == 0;
        this.primary.hidden = this.currentIndex == 0;
    }

    nextTab() {
        this.warning = this.validateFirstPage();

        if (this.warning) {
            return;
        }

        this.warning = null;
        this.currentIndex++;
        this.enableDisable();
    }

    backTab() {
        this.currentIndex--;
        this.enableDisable();
    }

    openFileExplorer() {
        this.file.nativeElement.click();
    }

    onFileAdded() {
        let files = Array.from(this.file.nativeElement.files);
        if (!files || !files.length) {
            return; // probably they had previously selected a file, and now they canceled
        }
        // only one file should be selected - but just in case, take the last one
        let file: File = null;
        files.forEach((fileItem: File) => {
            file = fileItem;
        });

        // check file extension
        let filename = file.name;
        let fileExtPattern = /\.[0-9a-z]+$/i;
        let fileExt = fileExtPattern.exec(file.name);
        let invalidExtension = (!fileExt);
        if (fileExt) {
            let lowerExt = fileExt.toString().toLowerCase();
            if (!lowerExt.includes(".pdf") && !lowerExt.includes(".tif") && !lowerExt.includes(".tiff")) {
                invalidExtension = true;
            }
        }
        if (invalidExtension) {
            this.growlService.error("Only PDF and TIF files are allowed.");
            return;
        }

        this.dialogData.file = file;
    }

    ok() {
        this.warning = this.validateSecondPage();
        if (this.warning) {
            return;
        }

        //set time on executed date
        let now: dayjs.Dayjs = dayjs();
        let then: dayjs.Dayjs = dayjs(this.dialogData.executedDate);
        now = now.set("date", then.get("date")).set("month", then.get("month")).set("year", then.get("year"));
        this.dialogData.executedDate = now.toISOString();

        this.activeModal.close(this.dialogData);
    }

    cancel() {
        this.activeModal.close(null);
    }

    blurSignatureDate() {
        if (this.dialogData.executedDate) {
            //always set expiration date based on signature date
            let period: dayjs.UnitType = "year";
            if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
                period = "month";
            }
            this.expirationDayjs = dayjs(this.dialogData.executedDate).add(1, period);
            this.dialogData.expirationDate = this.licenseService.formatDayjs(this.expirationDayjs);

            //set maximum effective date to expiration date - 1 day
            this.maxEffectiveDate = dayjs(this.expirationDayjs).subtract(1, "day");
        }
    }

    effectiveDateChanged(newDate: dayjs.Dayjs) {
        this.dialogData.effectiveDate =
            this.licenseService.formatDayjs(newDate);
    }

    selectBillingCycle($event: any) {
        let newCycle = $event.$selection;
        this.dialogData.billingCycle = newCycle.id;
    }

    selectPaymentTerm($event: any) {
        let newTerm = $event.$selection;
        this.dialogData.paymentTerms = newTerm.id;
    }

    changeEnrollmentCode() {
        this.showEnrollmentCodes();
    }

    removeEnrollmentCode() {
        this.dialogData.pricingTemplate = null;
    }

    showEnrollmentCodes() {
        const modalRef = this.modalService.open(
            EnrollmentCodeSelectDialogComponent,
            {
                backdrop: "static"
            }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = "DOC_BUILDER_RON";

        modalRef.result.then(
            (pricingTemplate: any) => {
                if (pricingTemplate) {
                    this.dialogData.pricingTemplate = pricingTemplate;
                    this.dialogData.ronEventFee =
                        pricingTemplate.contractTemplateFees.DOCUMENT_BUILDER_RON_FEE.contractFeeTiers[0].feeAmount;

                    if (pricingTemplate.billingCycleType) {
                        this.dialogData.billingCycle =
                            pricingTemplate.billingCycleType;
                    } else {
                        this.dialogData.billingCycle = BillingCycleType.MONTHLY;
                    }
                    if (pricingTemplate.paymentTerms) {
                        this.dialogData.paymentTerms =
                            pricingTemplate.paymentTerms;
                    } else {
                        this.dialogData.paymentTerms = PaymentTerms.NET15;
                    }
                }
            },
            () => {
                // nothing on cancel
            }
        );
    }

    validateFirstPage(): string {
        let message = null;

        if (!message && (!this.dialogData.signatureName || this.dialogData.signatureName.trim().length == 0)) {
            message = "Signer Name is required";
        }
        if (!this.dialogData.executedDate) {
            message = "Signature Date is required";
        }
        if (!message && !this.dialogData.effectiveDate) {
            message = "Effective Date is required";
        }
        if (!message) {
            if (!this.maxSignDate.isSameOrAfter(this.dialogData.executedDate)) {
                message = "Signature date cannot be in the future";
            }
        }
        if (!message) {
            if (!this.minSignDate.isSameOrBefore(this.dialogData.executedDate)) {
                message = "Signature date is before the allowed minimum date";
            }
        }
        if (!message) {
            if (this.dialogData.effectiveDate && dayjs().startOf("day").isAfter(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the past";
            } else if (!this.isActive && this.dialogData.effectiveDate &&
                    dayjs().isBefore(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the future, since the product is not active yet.";
            }
        }
        if (!message) {
            if (dayjs(this.dialogData.effectiveDate).isBefore(this.dialogData.executedDate)) {
                message = "Effective date cannot be before the signature date";
            } else if (dayjs(this.dialogData.effectiveDate).isSameOrAfter(this.dialogData.expirationDate)) {
                message = "Effective date must be before the expiration date";
            }
        }

        return message;
    }

    validateSecondPage() {
        let message = null;

        if (typeof this.dialogData.ronEventFee == "undefined" || this.dialogData.ronEventFee == null) {
            message = "Document Builder RON Fee is required";
        }

        if (message) {
            return message;
        }
        //test

        if (!SfValidators.isPositiveFloatString(this.dialogData.ronEventFee)) {
            message = "Document Builder RON Fee must be a positive number";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.paymentTerms) {
            message = "Payment term is required";
        }
        if (!this.dialogData.billingCycle) {
            message = "Billing cycle is required";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.file) {
            message = "Select a file to upload";
        }

        this.dialogData.ronEventFee = this.licenseService.roundOff(this.dialogData.ronEventFee, 2);
        if (!SfValidators.testFee(this.dialogData.ronEventFee)) {
            return "Invalid Document Builder RON Fee format"
        }

        return message;
    }

    addPricingTiers() {
        this.showPricingTiers = !this.showPricingTiers;
    }

    updateContractFee(updatedContractFee: ContractFee) {
        this.dialogData.documentFee = updatedContractFee;
        if (updatedContractFee.contractFeeTiers.length <= 1) {
            this.showPricingTiers = false;
        }
    }
}
