import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PreChatDialogComponent } from "./dialogs/pre-chat-dialog/pre-chat-dialog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ModalsModule, SelectModule } from "@sf/common";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChatUploadFileTypeConstants } from "./constants/chat-upload-file-type.constants";
import { ChatOverlayComponent } from "./components/chat-overlay/chat-overlay.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { ChatOverlayService } from "./services/chat-overlay.service";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        NgbModalModule,
        ModalsModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        SelectModule,
        OverlayModule,
        DragDropModule
    ],
    providers: [ChatUploadFileTypeConstants, ChatOverlayService],
    declarations: [PreChatDialogComponent, ChatOverlayComponent],
    exports: [PreChatDialogComponent]
})
export class ChatModule {}
