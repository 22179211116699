import {
    Directive,
    ElementRef,
    Renderer2,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    NgZone,
    ChangeDetectorRef,
    ApplicationRef,
    HostListener,
    Input
} from "@angular/core";
import { NgbTooltip, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";

@Directive({
    selector: "[sfTooltipOnOverflow]"
})
// @ts-ignore
export class TooltipOnOverflowDirective extends NgbTooltip {
    // NOTE: because this extends another class, if the base class changes in any way (constructor or behavior) it could
    // require updating this directive
    @Input()
    get sfTooltipOnOverflow() {
        return this.ngbTooltip;
    }
    set sfTooltipOnOverflow(data) {
        this.ngbTooltip = data;
    }

    constructor(
        private _elementRef: ElementRef<HTMLElement>,
        _renderer: Renderer2,
        _injector: Injector,
        _viewContainerRef: ViewContainerRef,
        _config: NgbTooltipConfig,
        _ngZone: NgZone,
        _changeDetector: ChangeDetectorRef,
        _appRef: ApplicationRef
    ) {
        // @ts-ignore
        super(
            _elementRef,
            _renderer,
            _injector,
            _viewContainerRef,
            _config,
            _ngZone,
            document,
            _changeDetector,
            _appRef
        );
    }

    @HostListener("mouseenter")
    check(): void {
        this.disableTooltip =
            this._elementRef.nativeElement.scrollWidth >
            this._elementRef.nativeElement.offsetWidth
                ? false
                : true;
    }
}
