import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CamelCaseToLowercasePipe } from "./camel-case-to-lowercase.pipe";
import { FormatPhonePipe } from "./format-phone.pipe";
import { HighlightPipe } from "./highlight.pipe";
import { NoSanitizePipe } from "./no-sanitize.pipe";
import { OxfordCommaPipe } from "./oxford-comma.pipe";
import { TrackByPropertyPipe } from "./track-by-property.pipe";
import { TruncatePipe } from "./truncate.pipe";
import { FilterByValuePipe } from "./filter-by-value.pipe";
import { MapFromIdPipe } from "./map-from-id.pipe";
import { StringReplacePipe } from "./string-replace.pipe";

@NgModule({
    declarations: [
        CamelCaseToLowercasePipe,
        FormatPhonePipe,
        HighlightPipe,
        NoSanitizePipe,
        OxfordCommaPipe,
        TrackByPropertyPipe,
        TruncatePipe,
        FilterByValuePipe,
        MapFromIdPipe,
        StringReplacePipe
    ],
    imports: [CommonModule],
    exports: [
        CamelCaseToLowercasePipe,
        FormatPhonePipe,
        HighlightPipe,
        NoSanitizePipe,
        OxfordCommaPipe,
        TrackByPropertyPipe,
        TruncatePipe,
        FilterByValuePipe,
        MapFromIdPipe,
        StringReplacePipe
    ]
})
export class CommonPipesModule {}
