import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenService } from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-cancel-invitation-dialog",
    templateUrl: "./cancel-invitation-dialog.component.html",
    styleUrls: ["./cancel-invitation-dialog.component.scss"]
})
export class CancelInvitationDialogComponent implements OnInit {
    @Input()
    tokenID: string;

    cancelForm: FormGroup;
    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private tokenService: TokenService
    ) {
        this.cancelForm = formBuilder.group({
            whatInvites: ["justOne"]
        });
    }

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.cancelInvitation.bind(this)
        };
        this.secondary = {
            text: "Take me back",
            disabled: false,
            callback: this.abort.bind(this)
        };
    }

    cancelInvitation() {
        let action = this.cancelForm.controls.whatInvites.value;
        if (action == "allInvites") {
            this.primary.disabled = true;
            this.tokenService
                .rejectInvitationAndUnsubscribe(this.tokenID)
                .subscribe(() => {
                    this.activeModal.close(action);
                }, () => {
                    this.primary.disabled = false;
                });
        } else {
            this.primary.disabled = true;
            this.tokenService
                .rejectInvitationToken(this.tokenID)
                .subscribe(() => {
                    this.activeModal.close(action);
                }, () => {
                    this.primary.disabled = false;
                });
        }
    }

    abort() {
        this.activeModal.close("");
    }
}
