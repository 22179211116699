import { getPath } from "./object";
import { deepMergeWithArrays, isNullOrEmpty } from "./utils";

export const sortBy = (key: string) => {
    return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const sortByFunc = (func: any) => {
    return (a: any, b: any) =>
        func(a) > func(b) ? 1 : func(b) > func(a) ? -1 : 0;
};

export const sortByKeys = (keys: string[], order?: string[]) => {
    return (a: any, b: any) =>
        keys.reduce((result: number, key: string, index: number) => {
            let direction = order && order[index] === "desc" ? -1 : 1;
            return (
                result ||
                (isNullOrEmpty(a[key]) || a[key] > b[key]
                    ? direction
                    : isNullOrEmpty(b[key]) || b[key] > a[key]
                    ? -direction
                    : 0)
            );
        }, 0);
};

export const sortByPath = (path: string) => {
    return (a: any, b: any) =>
        getPath(a, path) > getPath(b, path)
            ? 1
            : getPath(b, path) > getPath(a, path)
            ? -1
            : 0;
};

// For use with ES6 Array.sort for array of strings
export function stringComparator(a: any, b: any): number {
    return a.localeCompare(b, undefined, {
        sensitivity: "base"
    });
}

export function uniq(array: Array<any>) {
    return !isNullOrEmpty(array)
        ? array.filter((value, index, self) => self.indexOf(value) === index)
        : array;
}

export function uniqBy(array: Array<any>, key: string) {
    return !isNullOrEmpty(array)
        ? array.filter(
              (value1, index, self) =>
                  index ===
                  self.findIndex((value2) => value2[key] === value1[key])
          )
        : array;
}

export function uniqByFunc(array: Array<any>, func: any) {
    return !isNullOrEmpty(array) && func !== null && func !== undefined
        ? array.filter(
              (value1, index, self) =>
                  index ===
                  self.findIndex((value2) => func(value2) === func(value1))
          )
        : array;
}

export function union(array1: Array<any>, array2: Array<any>) {
    let use1 = !isNullOrEmpty(array1) ? array1 : [];
    let use2 = !isNullOrEmpty(array2) ? array2 : [];
    return uniq(use1.concat(use2));
}

export function unionBy(array1: Array<any>, array2: Array<any>, key: string) {
    let use1 = !isNullOrEmpty(array1) ? array1 : [];
    let use2 = !isNullOrEmpty(array2) ? array2 : [];
    return uniqBy(use1.concat(use2), key);
}

export function removeByValue(src: Array<any>, match: any) {
    if (isNullOrEmpty(src) || isNullOrEmpty(match)) return;
    const idx = src.indexOf(match);
    if (idx > -1) {
        src.splice(idx, 1);
    }
}

export function removeByTemplate(src: Array<any>, template: any): any {
    if (isNullOrEmpty(src) || isNullOrEmpty(template)) return undefined;
    let properties = Object.getOwnPropertyNames(template);
    let found = src.findIndex((item) => {
        let match = true;
        for (let prop of properties) {
            if (item[prop] !== template[prop]) {
                match = false;
                break;
            }
        }
        return match;
    });
    if (found > -1) {
        src.splice(found, 1);
    }
    return found;
}

export function difference<T>(array1: Array<T>, array2: Array<T>) {
    return [array1, array2].reduce((a, b) => a?.filter((c) => !b?.includes(c)));
}

// func returns a value calculated from each array item and compares that value
export function differenceByFunc(
    array1: Array<any>,
    array2: Array<any>,
    func: any
) {
    if (isNullOrEmpty(array1)) return array2;
    if (isNullOrEmpty(array2)) return array1;
    const s = new Set(array2.map(func));
    return array1.filter((itm) => !s.has(func(itm)));
}

export function filterByTemplate(src: Array<any>, template: any): Array<any> {
    if (isNullOrEmpty(src)) return src;
    let properties = Object.getOwnPropertyNames(template);
    let filtered = src.filter((item) => {
        let match = true;
        for (let prop of properties) {
            if (item[prop] !== template[prop]) {
                match = false;
                break;
            }
        }
        return match;
    });
    return filtered;
}

export function findByTemplate(src: Array<any>, template: any): any {
    if (isNullOrEmpty(src)) return undefined;
    let properties = Object.getOwnPropertyNames(template);
    let found = src.find((item) => {
        let match = true;
        for (let prop of properties) {
            if (item[prop] !== template[prop]) {
                match = false;
                break;
            }
        }
        return match;
    });
    return found;
}

//
export function deepMergeArrays<T>(
    target: T[],
    source: Partial<T>[],
    options: any = {}
): T[] {
    return deepMergeWithArrays(target, source as T[], {
        ...options
    });
}

export function intersection(array1: any[], array2: any[]): any[] {
    return array1.filter((value) => {
        return array2.indexOf(value) > -1;
    });
}

export function intersectionBy(
    array1: any[],
    array2: any[],
    key: string
): any[] {
    return array1.filter((value1) => {
        return !!array2.find((value2) => value1[key] === value2[key]);
    });
}

export function pullAt(arr: any[], idxs: number[]) {
    return idxs
        .reverse()
        .map((idx) => arr.splice(idx, 1)[0])
        .reverse();
}

export function chunk(arrToChunk: any[], size: number): any[][] {
    if (isNullOrEmpty(arrToChunk)) return arrToChunk;
    if (isNullOrEmpty(size)) return arrToChunk;
    return arrToChunk.reduce((arr, item, idx) => {
        return idx % size === 0
            ? [...arr, [item]]
            : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
    }, []);
}

export function groupBy<T>(array: Array<T>, key: keyof T) {
    return array.reduce((r, v) => {
        const keyValue: string = v[key] as any;
        if (!r[keyValue]) {
            r[keyValue] = [];
        }
        r[keyValue].push(v);
        return r;
    }, {} as Record<string, T[]>);
}
