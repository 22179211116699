// NOTE: Any changes made to this enum will also need to change on the backend BusinessType enum.
export enum BusinessType {
    AGGREGATOR = "Aggregator",
    APPRAISER = "Appraiser",
    ATTORNEY = "Attorney/Law Firm",
    BANK = "Bank",
    BORROWER = "Borrower",
    BPO = "BPO",
    BUILDER = "Builder",
    COUNTY = "County Clerk",
    COUNTY_ASSESSOR = "County Assessor",
    COUNTY_AUDITOR = "County Auditor",
    COUNTY_CLERK_RECEIVER = "County Clerk: Secondary Receiver",
    COUNTY_ENGINEER = "County Engineer",
    COUNTY_PREVIEW = "County Preview",
    COUNTY_PUBLIC_TRUSTEE = "County Public Trustee",
    COUNTY_TREASURER = "County Treasurer",
    CREDIT_UNION = "Credit Union",
    ESCROW = "Escrow",
    GOVERNMENT = "Government",
    HOME_BUILDER = "Home Builder",
    HOMEOWNER_CONDO = "HOA / Condo Association",
    LENDER = "Lender",
    LIEN_FILER = "Lien Filer",
    MORTGAGE_BROKER = "Mortgage Broker",
    NOTARY = "Notary",
    OTHER_MISCELLANEOUS = "Other/Miscellaneous",
    QC_REVIEWER = "QC Reviewer",
    REAL_ESTATE_BROKER_BUYER = "Real Estate Broker: Buyer",
    REAL_ESTATE_BROKER_SELLER = "Real Estate Broker: Seller",
    REAL_ESTATE_INVESTOR = "Real Estate Investor",
    REAL_ESTATE_OFFICE = "Real Estate Office",
    SELLER = "Seller",
    SERVICER = "Loan/Mortgage Servicer",
    PROCESSOR_SETTLEMENT = "Settlement",
    SIMPLIFILE = "Simplifile",
    TIMESHARE = "Timeshare",
    TITLE = "Title Company",
    TITLE_UNDERWRITER = "Title Underwriter",
    UTILITIES = "Oil/Gas/Utilities",
    VENDOR = "Vendor",
    WITNESS = "Witness"
}
