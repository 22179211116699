import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
    dayjs,
    GrowlService,
    ModalButton,
    SelectableItemWithID,
    SfValidators
} from "@sf/common";
import { LicenseUploadData } from "../license-upload-common/license-upload-interfaces";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    BillingCycleType,
    ContractService,
    PaymentTerms,
    RenewalFrequencyType
} from "@sf/userorg/common";
import { LicenseUtilityService } from "../../services/license-utility.service";
import { SafeHtml } from "@angular/platform-browser";

// prettier-ignore
@Component({
    selector: "sf-vendor-license-upload-dialog",
    templateUrl: "./vendor-license-upload-dialog.component.html",
    styleUrls: ["./vendor-license-upload-dialog.component.scss"]
})
export class VendorLicenseUploadDialogComponent implements OnInit {
    @Input() isActive: boolean;
    @Input() contractType: string;
    @Input() showExclusiveFee: boolean;

    currentIndex = 0;
    stepCount = 2;
    warning: SafeHtml = "";
    nextButton: ModalButton;
    backButton: ModalButton;

    maxDate: dayjs.Dayjs = dayjs().add(1, "year").startOf("day");
    maxSignDate = dayjs();
    minSignDate: dayjs.Dayjs;
    maxEffectiveDate: dayjs.Dayjs = this.maxDate;
    primary: ModalButton;
    secondary: ModalButton;
    title = "Vendor Addendum Upload";
    expirationDayjs: dayjs.Dayjs;
    effectiveDayjs: dayjs.Dayjs;
    today = dayjs();

    selectedShareOption: SelectableItemWithID = {
        id: "",
        label: ""
    };
    selectedBillingCycle: SelectableItemWithID = null;
    selectedPaymentTerm: SelectableItemWithID = null;
    selectableShareOptions: SelectableItemWithID[];
    selectableBillingCycles: SelectableItemWithID[];
    selectablePaymentTerms: SelectableItemWithID[];

    dialogData: LicenseUploadData = {
        executedDate: null,
        effectiveDate: null,
        signatureName: null,
        signatureTitle: null,
        expirationDate: null,
        licenseFee: null,
        renewalFee: null,
        documentFee: null,
        freeSubmissions: null,
        billingCycle: BillingCycleType.MONTHLY,
        paymentTerms: PaymentTerms.NET15,
        file: null
    };

    @ViewChild("file") file: any;

    constructor(
            private activeModal: NgbActiveModal,
            private growlService: GrowlService,
            private modalService: NgbModal,
            private licenseService: LicenseUtilityService,
            private contractService: ContractService
    ) {}

    ngOnInit() {
        this.setMinSignDate();

        // Modal buttons
        this.primary = {
            text: "Upload",
            disabled: false,
            callback: this.ok.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.cancel.bind(this)
        };

        this.selectableBillingCycles =
            this.contractService.getSelectableBillingCycles(this.contractType);
        this.selectablePaymentTerms =
            this.contractService.getSelectablePaymentTerms();
        this.selectableShareOptions = this.getSelectableShareOptions();
        this.effectiveDayjs = dayjs();

        this.nextButton = {
            text: "Next",
            hidden: false,
            callback: this.nextTab.bind(this)
        };
        this.backButton = {
            text: "Back",
            hidden: false,
            callback: this.backTab.bind(this)
        };
        this.enableDisable();
    }

    setMinSignDate(): void {
        let period: dayjs.UnitType = "year";
        if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
            period = "month";
        }
        this.minSignDate = dayjs().subtract(1, period).add(1, "day");
    }
    enableDisable() {
        this.nextButton.hidden = this.currentIndex > 0;
        this.backButton.hidden = this.currentIndex == 0;
        this.primary.hidden = this.currentIndex == 0;
    }

    nextTab() {
        this.warning = this.validateFirstPage();

        if (this.warning) {
            return;
        }

        this.warning = null;
        this.currentIndex++;
        this.enableDisable();
    }

    backTab() {
        this.currentIndex--;
        this.enableDisable();
    }

    openFileExplorer() {
        this.file.nativeElement.click();
    }

    onFileAdded() {
        let files = Array.from(this.file.nativeElement.files);
        if (!files || !files.length) {
            return; // probably they had previously selected a file, and now they canceled
        }
        // only one file should be selected - but just in case, take the last one
        let file: File = null;
        files.forEach((fileItem: File) => {
            file = fileItem;
        });

        // check file extension
        let filename = file.name;
        let fileExtPattern = /\.[0-9a-z]+$/i;
        let fileExt = fileExtPattern.exec(file.name);
        let invalidExtension = (!fileExt);
        if (fileExt) {
            let lowerExt = fileExt.toString().toLowerCase();
            if (!lowerExt.includes(".pdf") && !lowerExt.includes(".tif") && !lowerExt.includes(".tiff")) {
                invalidExtension = true;
            }
        }
        if (invalidExtension) {
            this.growlService.error("Only PDF and TIF files are allowed.");
            return;
        }

        this.dialogData.file = file;
    }

    ok() {
        this.warning = this.validateSecondPage();
        if (this.warning) {
            return;
        }

        //set time on executed date
        let now: dayjs.Dayjs = dayjs();
        let then: dayjs.Dayjs = dayjs(this.dialogData.executedDate);
        now = now.set("date", then.get("date")).set("month", then.get("month")).set("year", then.get("year"));
        this.dialogData.executedDate = now.toISOString();

        //no expiration date for Vendors
        this.dialogData.expirationDate = null;
        this.activeModal.close(this.dialogData);
    }

    cancel() {
        this.activeModal.close(null);
    }

    blurSignatureDate() {
        if (this.dialogData.executedDate) {
            //always set expiration date based on signature date
            let period: dayjs.UnitType = "year";
            if (this.dialogData.renewalFrequency == RenewalFrequencyType.MONTHLY) {
                period = "month";
            }
            this.expirationDayjs = dayjs(this.dialogData.executedDate).add(1, period);
            this.dialogData.expirationDate = null;

            //set maximum effective date to expiration date - 1 day
            this.maxEffectiveDate = dayjs(this.expirationDayjs).subtract(1, "day");
        }
    }

    effectiveDateChanged(newDate: dayjs.Dayjs) {
        this.dialogData.effectiveDate = this.licenseService.formatDayjs(newDate);
    }

    selectShareOption($event: any) {
        let newOption = $event.$selection;
        this.selectedShareOption = newOption;
        this.dialogData.shareOption = newOption.id;
    }

    selectBillingCycle($event: any) {
        let newCycle = $event.$selection;
        this.selectedBillingCycle = newCycle;
        this.dialogData.billingCycle = newCycle.id;
    }

    selectPaymentTerm($event: any) {
        let newTerm = $event.$selection;
        this.selectedPaymentTerm = newTerm;
        this.dialogData.paymentTerms = newTerm.id;
    }

    getSelectableShareOptions() {
        let options = [
            {
                label: "We Pay Percentage of Fee",
                id: "PERCENTAGE"
            },
            {
                label: "We Pay Flat Amount per document",
                id: "FLAT_FEE"
            },
            {
                label: "We Keep Flat Amount per document",
                id: "KEEP"
            }
        ];
        return options;
    }

    validateFirstPage(): string {
        let message = null;

        if (!message && (!this.dialogData.signatureName || this.dialogData.signatureName.trim().length == 0)) {
            message = "Signer Name is required";
        }
        if (!message && !this.dialogData.executedDate) {
            message = "Signature Date is required";
        }
        if (!message && !this.dialogData.effectiveDate) {
            message = "Effective Date is required";
        }
        if (!message) {
            if (!this.maxSignDate.isSameOrAfter(this.dialogData.executedDate)) {
                message = "Signature date cannot be in the future";
            }
        }
        if (!message) {
            if (!this.minSignDate.isSameOrBefore(this.dialogData.executedDate)) {
                message = "Signature date is before the allowed minimum date";
            }
        }
        if (!message) {
            if (this.dialogData.effectiveDate && dayjs().startOf("day").isAfter(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the past";
            } else if (!this.isActive && this.dialogData.effectiveDate && dayjs().isBefore(this.dialogData.effectiveDate)) {
                message = "Effective date cannot be in the future, since the product is not active yet.";
            }
        }
        if (!message) {
            if (dayjs(this.dialogData.effectiveDate).isBefore(this.dialogData.executedDate)) {
                message = "Effective date cannot be before the signature date";
            }
        }

        return message;
    }

    validateSecondPage() {
        let message = null;

        if (this.selectedShareOption.id != "KEEP") {
            if (this.showExclusiveFee) {
                if (typeof this.dialogData.exclusiveSubmissionFee == "undefined" || this.dialogData.exclusiveSubmissionFee ==
                        null) {
                    if (!this.selectedShareOption || this.selectedShareOption.id == "FLAT_FEE") {
                        message = "Exclusive Per Document Flat Amount is required";
                    } else {
                        message = "Exclusive Fee Percentage is required";
                    }
                }
            }
            if (typeof this.dialogData.nSubmissionFee == "undefined" || this.dialogData.nSubmissionFee == null) {
                if (!this.selectedShareOption || this.selectedShareOption.id == "FLAT_FEE") {
                    message = "Per Document Flat Amount is required";
                } else {
                    message = "Fee Percentage is required";
                }
            }

            if (message) {
                return message;
            }

            if (this.showExclusiveFee) {
                if (!SfValidators.isPositiveFloatString(this.dialogData.exclusiveSubmissionFee)) {
                    if (!this.selectedShareOption || this.selectedShareOption.id == "FLAT_FEE") {
                        message = "Exclusive Per Document Flat Amount must be a positive number";
                    } else {
                        message = "Exclusive Fee Percentage must be a positive number";
                    }
                }
            }
            if (!SfValidators.isPositiveFloatString(this.dialogData.nSubmissionFee)) {
                if (!this.selectedShareOption || this.selectedShareOption.id == "FLAT_FEE") {
                    message = "Per Document Flat Amount must be a positive number";
                } else {
                    message = "Fee Percentage must be a positive number";
                }
            }
        }
        if (this.selectedShareOption.id == "KEEP") {
            if (typeof this.dialogData.nSubmissionFee == "undefined" || this.dialogData.nSubmissionFee == null) {
                return "Amount We Keep per Document is required";
            }
            if (!SfValidators.isPositiveFloatString(this.dialogData.nSubmissionFee)) {
                return "Amount We Keep per Document must be a positive number";
            }
            this.dialogData.exclusiveSubmissionFee = this.dialogData.nSubmissionFee;
        }

        if (!this.dialogData.shareOption) {
            message = "Share Option is required";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.paymentTerms) {
            message = "Payment term is required";
        }
        if (!this.dialogData.billingCycle) {
            message = "Billing cycle is required";
        }

        if (message) {
            return message;
        }

        if (!this.dialogData.file) {
            message = "Select a file to upload";
        }

        this.dialogData.nSubmissionFee = this.licenseService.roundOff(this.dialogData.nSubmissionFee, 3);
        this.dialogData.exclusiveSubmissionFee = this.licenseService.roundOff(this.dialogData.exclusiveSubmissionFee, 3);

        if (this.selectedShareOption.id == "FLAT_FEE") {
            if (!SfValidators.testVendorFee(this.dialogData.nSubmissionFee)) {
                return "Invalid Per Document Flat Amount Fee format";
            }
            if (this.showExclusiveFee) {
                if (!SfValidators.testVendorFee(this.dialogData.exclusiveSubmissionFee)) {
                    return "Invalid Exclusive Per Document Flat Amount Fee format";
                }
            }
        }

        return message;
    }
}
