<sf-modal-header title="Chat with our Support Team"></sf-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <form [formGroup]="chatForm">
        <div class="row">
            <div class="form-group col-md-12">
                <input type="text" id="firstName" name="firstName" placeholder="Your First Name"
                    class="form-control" autofocus
                    formControlName="firstName"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <input type="text" id="lastName" name="lastName" placeholder="Your Last Name"
                    class="form-control"
                    formControlName="lastName"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <input type="text" id="email" name="email" placeholder="Your Email Address"
                    class="form-control"
                    formControlName="email"/>
            </div>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary">
</sf-modal-footer>
