import {
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { ActivatedRoute, ResolveEnd, Router } from "@angular/router";
import { getAllRouteDataFromSnapshot } from "@sf/common";
import { Subject } from "rxjs";
import { filter, map, startWith, takeUntil } from "rxjs/operators";
import { BaseLayoutComponent } from "../base-layout/base-layout.component";

@Component({
    selector: "sf-layout-wrapper",
    templateUrl: "./layout-wrapper.component.html",
    styleUrls: ["./layout-wrapper.component.scss"]
})
export class LayoutWrapperComponent implements OnInit, OnDestroy {
    @ViewChild("viewport", { read: ViewContainerRef, static: true })
    viewport: ViewContainerRef;

    private _ngOnDestroy = new Subject<void>();
    private _currentLayout: any;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _router: Router,
        private _route: ActivatedRoute
    ) {}

    ngOnInit() {
        this._router.events
            .pipe(
                filter((event) => event instanceof ResolveEnd),
                map((event: ResolveEnd) => {
                    return getAllRouteDataFromSnapshot(event.state.root).layout;
                }),
                startWith(
                    getAllRouteDataFromSnapshot(this._route.snapshot).layout
                ),
                takeUntil(this._ngOnDestroy)
            )
            .subscribe((layout) => {
                if (layout === this._currentLayout) {
                    return;
                }
                this._currentLayout = layout;

                if (layout === false) {
                    this._attachLayout(BaseLayoutComponent);
                } else if (layout && layout.name) {
                    this._attachLayout(layout);
                }
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    private _attachLayout<T>(layout: Type<T>) {
        if (this.viewport.length > 0) {
            this.viewport.clear();
        }

        const component =
            this._componentFactoryResolver.resolveComponentFactory(layout);

        return this.viewport.createComponent(component);
    }
}
