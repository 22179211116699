<div *ngIf="showingRecentItems">
    <table class="parent-hidden-data">
        <tr>
            <td class="type-icon pr-1">
                <fa-icon icon="user"></fa-icon>
            </td>
            <td style="width: 100%">
                <span class="float-left truncate-name">
                    <button *ngIf="!item.isArchived && !!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate"
                            [openDelay]="100"
                            sfTooltipOnOverflow="{{item.name.first}} {{item.name.last}} ({{item.username}})"
                            container="body"
                            (click)="uscService.goToContact(result)">
                        {{item.name.first}} {{item.name.last}} ({{item.username}})
                    </button>
                    <i *ngIf="item.isArchived && !!item.name" class="truncate"
                       [openDelay]="100" container="body"
                       sfTooltipOnOverflow="{{item.name.first}} {{item.name.last}} ({{item.username}})">
                        {{item.name.first}} {{item.name.last}} ({{item.username}})
                    </i>
                </span>
                <span *ngIf="!item.isArchived" class="hidden-data">
                    <button id="btn-log-in-as" class="btn btn-link sf-btn-link" (click)="uscService.becomeUser(result)" aria-label="Actions">
                        &nbsp;<fa-icon icon="sign-in-alt"></fa-icon>
                    </button>
                </span>
                <span *ngIf="item.isArchived" class="lessImportant">
                    &nbsp;<fa-icon icon="archive" tooltipClass="tooltip-interaction-not"
                             ngbTooltip="User is archived" placement="right"></fa-icon>
                </span>
            </td>
            <td style="width: 100%">
                <span *ngIf="item.statusNumber !== 1 && item.statusNumber !== 98"
                      class="hidden-data float-right badge {{item.statusClass}}"
                      tooltipClass="tooltip-interaction-not"
                      ngbTooltip="{{item.statusTooltip}}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-roles" class="btn btn-link sf-btn-link" (click)="uscService.goToRoles(result)">
                        {{item.status}}
                    </button>
                </span>
                <span *ngIf="item.statusNumber == 98"
                      class="hidden-data float-right badge {{item.statusClass}}"
                      tooltipClass="tooltip-interaction-not"
                      ngbTooltip="{{item.statusTooltip}}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-activity-logs" class="btn btn-link sf-btn-link" (click)="uscService.goToActivityLogs(result)">
                        {{item.status}}
                    </button>
                </span>
                <span *ngIf="item.isSuspended"
                      class="hidden-data float-right badge {{item.statusClass}}"
                      tooltipClass="tooltip-interaction-not"
                      ngbTooltip="Suspended from logging in" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-security" class="btn btn-link sf-btn-link" (click)="uscService.goToSecurity(result)">
                        {{item.status}}
                    </button>
                </span>
                <span *ngIf="item.isLockedOut"
                      class="hidden-data float-right badge {{item.statusClass}}"
                      tooltipClass="tooltip-interaction-not"
                      ngbTooltip="Click to unlock" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-unlock-user" class="btn btn-link sf-btn-link" (click)="uscService.unlockUser(result)">
                        {{item.status}}
                    </button>
                </span>
            </td>
            <td class="no-wrap pl-1">
                <span ngbDropdown container="body" class="float-right">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div *ngIf="!item.isArchived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-log-in-as" ngbDropdownItem sfAutoFocus (click)="uscService.becomeUser(result)">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Log in as User
                        </button>
                        <button id="menu-roles" ngbDropdownItem (click)="uscService.goToAssignments(result)">
                            <fa-icon icon="user" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;User Roles
                        </button>
                        <button id="menu-contact" ngbDropdownItem (click)="uscService.goToContact(result)">
                            <fa-icon icon="info-circle" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Contact Info
                        </button>
                        <button id="menu-settings" ngbDropdownItem (click)="uscService.goToSettings(result)">
                            <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;User Settings
                        </button>
                        <button id="menu-security" ngbDropdownItem (click)="uscService.goToSecurity(result)">
                            <fa-icon icon="key" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Security
                        </button>
                        <button id="menu-activity-logs" ngbDropdownItem (click)="uscService.goToActivityLogs(result)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Activity Logs
                        </button>
                    </div>
                    <div *ngIf="item.isArchived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-activity-logs" ngbDropdownItem (click)="uscService.goToActivityLogs(result)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-unarchive" ngbDropdownItem (click)="uscService.doUnarchive(result)">
                            <fa-icon icon="archive" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Unarchive User
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                <div *ngIf="item.organizations.length == 1" class="lessImportant">
                    <span class="float-left truncate" [openDelay]="100"
                          sfTooltipOnOverflow="{{item.organizations[0].name}}">
                        {{item.organizations[0].name}}&nbsp;
                    </span>
                    <span class="float-right truncate" [openDelay]="100"
                          sfTooltipOnOverflow="{{item.organizations[0].address.city}}, {{item.organizations[0].address.state}}">
                        {{item.organizations[0].address.city}}, {{item.organizations[0].address.state}}&nbsp;
                    </span>
                </div>
                <div *ngIf="item.organizations.length > 1" class="lessImportant">
                    <span class="float-left">
                        <button class="btn btn-link sf-btn-link"
                                (click)="uscService.goToAssignments(result)">{{item.organizations.length}}
                            Organizations
                        </button>
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">&nbsp;</td>
        </tr>
    </table>
</div>

<div *ngIf="!showingRecentItems">
    <table class="parent-hidden-data">
        <tr>
            <td style="width: 100%" class="pl-1">
                <span>
                    <span class="truncate-name" tooltipClass="tooltip-interaction-not">
                        <button *ngIf="!item.isArchived && !!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" [openDelay]="100"
                                sfTooltipOnOverflow="{{item.name.first}} {{ item.name.last }} ({{item.username}})"
                                container="body"
                                (click)="uscService.goToContact(item)">
                            {{ item.name.first }} {{ item.name.last }} ({{item.username}})
                        </button>
                        <i *ngIf="item.isArchived && !!item.name" class="truncate" [openDelay]="100" container="body"
                                sfTooltipOnOverflow="{{item.name.first}} {{ item.name.last }} ({{item.username}})">
                            {{ item.name.first }} {{ item.name.last }} ({{item.username}})
                        </i>
                    </span>
                    <span *ngIf="!item.isArchived" class="hidden-data">
                        <button id="btn-log-in-as" class="btn btn-link sf-btn-link" (click)="uscService.becomeUser(item)" aria-label="Actions">
                            &nbsp;<fa-icon icon="sign-in-alt"></fa-icon>
                        </button>
                    </span>
                    <span *ngIf="item.isArchived" class="lessImportant">
                        &nbsp;<fa-icon icon="archive" ngbTooltip="User is archived" placement="right"></fa-icon>
                    </span>
                </span>
            </td>
            <td style="width: 100%">
                <span *ngIf="item.statusNumber !== 1 && item.statusNumber !== 98" class="hidden-data float-right badge {{item.statusClass}}"
                        tooltipClass="tooltip-interaction-not" ngbTooltip="{{ item.statusTooltip }}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-roles" class="btn btn-link sf-btn-link" (click)="uscService.goToRoles(item)">
                        {{ item.status }}
                    </button>
                </span>
                <span *ngIf="item.statusNumber == 98" class="hidden-data float-right badge {{item.statusClass}}"
                        tooltipClass="tooltip-interaction-not" ngbTooltip="{{ item.statusTooltip }}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-status" class="btn btn-link sf-btn-link" (click)="uscService.goToActivityLogs(item)">
                        {{ item.status }}
                    </button>
                </span>
                <span *ngIf="item.isSuspended" class="hidden-data float-right badge {{item.statusClass}}" tooltipClass="tooltip-interaction-not"
                        ngbTooltip="Suspended from logging in" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-security" class="btn btn-link sf-btn-link" (click)="uscService.goToSecurity(item)">
                        {{ item.status }}
                    </button>
                </span>
                <span *ngIf="item.isLockedOut" class="hidden-data float-right badge {{item.statusClass}}" tooltipClass="tooltip-interaction-not"
                        ngbTooltip="Click to unlock" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-unlock-user" class="btn btn-link sf-btn-link" (click)="uscService.unlockUser(item)">
                        {{ item.status }}
                    </button>
                </span>
            </td>
            <td class="no-wrap p-1">
                <span ngbDropdown container="body" class="float-right">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div *ngIf="!item.isArchived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-log-in-as" ngbDropdownItem (click)="uscService.becomeUser(item)">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Log in as User
                        </button>
                        <button id="menu-roles" ngbDropdownItem (click)="uscService.goToAssignments(item)">
                            <fa-icon icon="user" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;User Roles
                        </button>
                        <button id="menu-contact" ngbDropdownItem (click)="uscService.goToContact(item)">
                            <fa-icon icon="info-circle" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Contact Info
                        </button>
                        <button id="menu-settings" ngbDropdownItem (click)="uscService.goToSettings(item)">
                            <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;User Settings
                        </button>
                        <button id="menu-security" ngbDropdownItem (click)="uscService.goToSecurity(item)">
                            <fa-icon icon="key" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Security
                        </button>
                        <button id="menu-activity-logs" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                    </div>
                    <div *ngIf="item.isArchived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-activity-logs" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-unarchive" ngbDropdownItem (click)="uscService.doUnarchive(item)">
                            <fa-icon icon="archive" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Unarchive User
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pl-1">
                <div *ngIf="item.organizations.length == 1" class="lessImportant">
                    <span class="float-left truncate" tooltipClass="tooltip-interaction-not" [openDelay]="100"
                          container="body"
                          sfTooltipOnOverflow="{{item.organizations[0].name}}">
                        {{ item.organizations[0].name }}&nbsp;
                    </span>
                    <span class="float-right truncate" tooltipClass="tooltip-interaction-not" [openDelay]="100" container="body"
                            sfTooltipOnOverflow="{{item.organizations[0].address.city}}, {{item.organizations[0].address.state}}">
                        {{item.organizations[0].address.city}}, {{item.organizations[0].address.state}}&nbsp;
                    </span>
                </div>
                <div *ngIf="item.organizations.length > 1" class="lessImportant">
                    <span class="float-left">
                        <button class="btn btn-link sf-btn-link" (click)="uscService.goToAssignments(item)">
                            {{ item.organizations.length }} Organizations
                        </button>
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">
                &nbsp;
            </td>
        </tr>
    </table>
</div>
