import { Component, Input, OnInit } from "@angular/core";
import {
    ModalButton,
    SelectableItem,
    SelectableItemWithID,
    SfValidators,
    SortUtilitiesService
} from "@sf/common";
import {
    ContractPricingTemplate,
    ContractService,
    Organization,
    OrganizationEntitlement,
    ProductExtended,
    TokenService,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { EnrollmentCodeSelectDialogComponent } from "../enrollment-code-select-dialog/enrollment-code-select-dialog.component";
import { SendLicenseDialogComponent } from "@sf/invitations";
import { LicenseUtilityService } from "../../services/license-utility.service";

// prettier-ignore
@Component({
    selector: "sf-organization-service-invite-dialog",
    templateUrl: "./organization-service-invite-dialog.component.html",
    styleUrls: ["./organization-service-invite-dialog.component.scss"]
})
export class OrganizationServiceInviteDialogComponent implements OnInit {
    @Input() title: string;
    @Input() organization: Organization;
    @Input() product: ProductExtended;
    @Input() invitation: any;

    requestSent: boolean = false;
    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;

    selectableRenewalFrequencies: SelectableItem[];
    selectableBillingCycles: SelectableItemWithID[];
    selectablePaymentTerms: SelectableItemWithID[];

    adminUsers: any[] = [];
    loading = true;
    warning: string = null;
    processing = false;
    selectedEnrollmentTemplate: ContractPricingTemplate = null;
    freeSubmissions = 0;
    renewalFrequency = "YEARLY";
    billingCycle = "DAILY";
    recordingFeeBillingCycle = "DAILY";
    paymentTerm = "NET1";
    salesforceID: string = "";
    existingProduct: OrganizationEntitlement;
    minimumFees: any;
    noAdminWarning = "This organization does not have any administrator users. " +
            "Add an administrator role to a user first, or choose to send to a non-user.";

    // for erecording
    licenseFee: number;
    renewalFee: number;
    submissionFee: number;

    // for vendor
    exclusiveSubmissionFee: number;
    selectedShareOption: SelectableItemWithID = null;
    selectableShareOptions = [
        {
            label: "Submission Fee Percentage",
            id: "PERCENT"
        },
        {
            label: "Per Document Flat",
            id: "FLAT"
        }
    ];

    // for docbuilder and trustee
    documentFee: number;

    // for lien release
    lienReleaseFee: number;

    // for subscription
    subscriptionMinimum: number;

    //for docubuilder RON
    ronEventFee: number;

    // for recording plus
    inStateErecordSubmissionFee: number;
    outOfStateErecordSubmissionFee: number;
    inStatePaperSubmissionFee: number;
    outOfStatePaperSubmissionFee: number;
    paperMailFee: number;
    embeddedEmployeeFee: number;

    // for lender
    loanCreateFee: number;
    loanAcceptFee: number;
    //renewalFee = "";	// defined above

    // for settlement
    //loanCreateFee = "";	// defined above
    //loanAcceptFee = "";	// defined above
    disbursementFee: number;
    revShareFee: number;
    //renewalFee = "";	// defined above
    verifiedInsecureFee: number;

    // for esign_event
    ipenFee: number;
    notarizationFee: number;
    ronFee: number;

    constructor(
        private activeModal: NgbActiveModal,
        private router: Router,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private contractService: ContractService,
        private activationService: UserorgActivationService,
        private userorgService: UserOrgService,
        private licenseService: LicenseUtilityService
    ) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Send",
            disabled: false,
            callback: this.doPrimary.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        this.tertiary = {
            text: "Add User Roles",
            disabled: false,
            hidden: true,
            callback: this.goToAssignments.bind(this)
        };

        this.selectableRenewalFrequencies = [
            {
                label: "Yearly",
                option: "YEARLY"
            },
            {
                label: "Monthly",
                option: "MONTHLY"
            }
        ];

        // esign-events hack start
        if (this.product.productID == "esign_event") {
            this.renewalFrequency = "MONTHLY";
        }
        // hack end

        this.selectableBillingCycles = this.contractService.getSelectableBillingCycles(this.product.productID);
        this.selectablePaymentTerms = this.contractService.getSelectablePaymentTerms();

        // get list of all admin users in the organization
        if (!this.organization.enabled) {
            this.loading = false;
        } else {
            this.activationService.getOrganizationAdminUsers(this.organization.id).subscribe((admins: any[]) => {
                this.loading = false;
                admins.forEach((admin) => {
                    admin.value = admin.username;
                    admin.label = admin.name + " (" + admin.username + ")";
                    this.adminUsers.push(admin);
                });
                if (this.adminUsers.length) {
                    this.primary.disabled = false;
                    this.adminUsers = this.adminUsers.sort((a, b) => {
                        return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                    });
                } else {
                    this.tertiary.hidden = false;   // 'add roles' button
                }

                if (this.invitation) {
                    this.initFromInvitation();
                }
            });
        }

        this.userorgService.getAllOrganizationEntitlements(this.organization.id).subscribe((entitlements) => {
            if (entitlements) {
                this.existingProduct = entitlements.find((ent) => {
                    return (ent.product.toLowerCase() == this.product.productID.toLowerCase());
                });
                if (this.existingProduct) {
                    this.salesforceID = this.existingProduct.salesforceOpportunityID;
                    if (this.existingProduct.trialRegistrationCode && this.existingProduct.status == "TRIAL") {
                        this.lookupEnrollmentCode(this.product.trialRegistrationCode);
                    }
                }
            }
        });

        if (this.product.productID !== "submitter" && this.product.productID !== "submitter_paper") {
            this.billingCycle = "MONTHLY";
            this.paymentTerm = "NET15";
        }
        if (this.product.productID == "subscription") {
            this.billingCycle = "MONTHLY";
            this.paymentTerm = "NET1";
        }

        if (this.product.productID == "submitter_vendor" || this.product.productID == "recipient_vendor" ||
                this.product.productID == "lender_vendor" || this.product.productID == "notary_vendor" ||
                this.product.productID == "esign_events_vendor") {
            this.setShareOption("FLAT"); // default selection
        }

        this.contractService.getMinimumContractFees(this.organization.id, this.computeContractType())
            .subscribe((result: any) => {
                this.minimumFees = result;
            });

        // default fees
        this.freeSubmissions = 0;
        if (this.product.productID == "licensed_collaborator_lender") {
            this.renewalFee = 250.00;
            this.loanCreateFee = 15.00;
            this.loanAcceptFee = 15.00;
        }
        if (this.product.productID == "licensed_collaborator_settlement") {
            this.renewalFee = 250.00;
            this.loanCreateFee = 5.00;
            this.loanAcceptFee = 5.00;
            this.disbursementFee = 60.00;
            this.revShareFee = 15.00;
            this.verifiedInsecureFee = 400.00;
        } else if (this.product.productID == "esign_event") {
            this.licenseFee = 99.00;
            this.renewalFee = 99.00;
            this.notarizationFee = 2.00;
            this.ipenFee = 25.00;
            this.ronFee = 25.00;
            this.paymentTerm = "NET30";
        } else if (this.product.productID == "mers_api_integration") {
            this.lienReleaseFee = 1.00;
        } else if (this.product.productID == "subscription") {
            this.subscriptionMinimum = 50.00;
        }
    }

    initFromInvitation() {
        let invite = this.invitation.invitation;
        this.renewalFrequency = invite.renewalFrequency;
        this.billingCycle = invite.billingCycle;
        this.recordingFeeBillingCycle = invite.recordingFeeBillingCycle;
        this.disbursementFee = invite.disbursementFee;
        this.documentFee = invite.documentFee;
        this.freeSubmissions = invite.freeSubmissions;
        this.licenseFee = invite.licenseFee;
        this.loanCreateFee = invite.loanCreateFee;
        this.loanAcceptFee = invite.loanCreateFee;
        this.paymentTerm = invite.paymentTerm;
        this.renewalFee = invite.renewalFee;
        this.revShareFee = invite.revShareFee;
        this.submissionFee = invite.submissionFee;
        this.exclusiveSubmissionFee = invite.exclusiveSubmissionFee;
        this.verifiedInsecureFee = invite.verifiedWithoutSecureDisbursementFee;
        this.inStateErecordSubmissionFee = invite.inStateErecordSubmissionFee;
        this.outOfStateErecordSubmissionFee = invite.outOfStateErecordSubmissionFee;
        this.inStatePaperSubmissionFee = invite.inStatePaperSubmissionFee;
        this.outOfStatePaperSubmissionFee = invite.outOfStatePaperSubmissionFee;
        this.paperMailFee = invite.paperMailFee;
        this.embeddedEmployeeFee = invite.embeddedEmployeeFee;
        this.ronEventFee = invite.ronEventFee;

        if (invite.enrollmentCode) {
            this.lookupEnrollmentCode(invite.enrollmentCode);
        }

        //check recipients of previous invitation
        let recipients = this.invitation.recipients;
        this.adminUsers.forEach((user) => {
            let found = recipients.find((recipient: any) => {
                return recipient.id == user.username;
            });
            if (found) {
                user.checked = true;
            }
        });
    }

    setShareOption(id: string) {
        this.selectedShareOption = this.selectableShareOptions.find(
            (option) => {
                return option.id == id;
            }
        );
    }

    selectShareOption($event: any) {
        let newOption: SelectableItemWithID = $event.$selection;
        this.selectedShareOption = newOption;
    }

    selectRenewalFrequency($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.renewalFrequency = cycle.option;
    }

    selectBillingCycle($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.billingCycle = cycle.id;
    }

    selectRecordingFeeBillingCycle($event: any) {
        let cycle: SelectableItemWithID = $event.$selection;
        this.recordingFeeBillingCycle = cycle.id;
    }

    selectPaymentTerm($event: any) {
        let term: SelectableItemWithID = $event.$selection;
        this.paymentTerm = term.id;
    }

    goToAssignments() {
        this.dismiss();
        let userRolePage =
            "/admin/organization/" + this.organization.id + "/config/users";
        this.router.navigateByUrl(userRolePage);
    }

    doPrimary() {
        if (!this.adminUsers.length) {
            this.warning = this.noAdminWarning;
            return;
        }

        this.validate();

        if (this.warning) {
            return;
        }

        this.save();
    }

    validate() {
        this.warning = null;

        //  SUBMITTER
        if (this.product.productID == "submitter") {
            if (typeof this.submissionFee == "undefined" || this.submissionFee == null || !this.submissionFee) {
                this.warning = "Submission Fee is required";
            }
            if (typeof this.exclusiveSubmissionFee == "undefined" || this.exclusiveSubmissionFee == null) {
                this.exclusiveSubmissionFee = this.submissionFee;
            } // no warning - make same as submission fee
            if (typeof this.renewalFee == "undefined" || this.renewalFee == null) {
                this.warning = "Renewal Fee is required";
            }
            if (typeof this.licenseFee == "undefined" || this.licenseFee == null) {
                this.warning = "Initial License Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.submissionFee)) {
                this.warning = "Submission Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.exclusiveSubmissionFee)) {
                this.exclusiveSubmissionFee = this.submissionFee;
            } // no warning - make same as submission fee
            if (!SfValidators.isPositiveFloatString(this.renewalFee)) {
                this.warning = "Renewal Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.licenseFee)) {
                this.warning = "Initial License Fee must be a valid number";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveIntegerString(this.freeSubmissions)) {
                this.warning = "Free Submissions must be a positive number or zero";
            }

            let minimumLicenseFee = this.minimumFees?.LICENSE_FEE?.contractFeeTiers[0].feeAmount;
            //let fee: number = SfValidators.getNumberFromString(this.renewalFee);
            this.licenseFee = this.licenseService.roundOff(this.licenseFee, 2);
            if (this.licenseFee < minimumLicenseFee) {
                this.warning = "Initial License Fee must not be less than $" + minimumLicenseFee;
                return;
            }
            if (!SfValidators.testFee(this.licenseFee)) {
                this.warning = "Invalid Initial License Fee format";
                return;
            }
            this.renewalFee = this.licenseService.roundOff(this.renewalFee, 2);
            if (this.renewalFee < minimumLicenseFee) {
                this.warning = "Renewal Fee must not be less than $" + minimumLicenseFee;
                return;
            }
            if (!SfValidators.testFee(this.renewalFee)) {
                this.warning = "Invalid License Renewal Fee format";
                return;
            }
            this.submissionFee = this.licenseService.roundOff(this.submissionFee, 2);
            if (!SfValidators.testFee(this.submissionFee)) {
                this.warning = "Invalid Submission Fee format";
                return;
            }
        // DOC_BUILDER
        } else if (this.product.productID == "submitter_signing") {
            if (typeof this.documentFee == "undefined" || this.documentFee == null || !this.documentFee) {
                this.warning = "Document Fee is required";
            }
            if (typeof this.renewalFee == "undefined" || this.renewalFee == null) {
                this.warning = "Renewal Fee is required";
            }
            if (typeof this.licenseFee == "undefined" || this.licenseFee == null) {
                this.warning = "Initial License Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.documentFee)) {
                this.warning = "Document Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.renewalFee)) {
                this.warning = "Renewal Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.licenseFee)) {
                this.warning = "Initial License Fee must be a valid number";
            }

            if (!SfValidators.isPositiveIntegerString(this.freeSubmissions)) {
                this.warning = "Free Documents must be a positive number or zero";
            }
            if (this.warning) return;

            this.licenseFee = this.licenseService.roundOff(this.licenseFee, 2);
            if (!SfValidators.testFee(this.licenseFee)) {
                this.warning = "Invalid Initial License Fee format";
                return;
            }
            this.renewalFee = this.licenseService.roundOff(this.renewalFee, 2);
            if (!SfValidators.testFee(this.renewalFee)) {
                this.warning = "Invalid License Renewal Fee format";
                return;
            }
            this.documentFee = this.licenseService.roundOff(this.documentFee, 2);
            if (!SfValidators.testFee(this.documentFee)) {
                this.warning = "Invalid Document Fee format";
                return;
            }
        // DB RON
        } else if (this.product.productID == 'doc_builder_ron') {
            if (typeof this.ronEventFee == "undefined" || this.ronEventFee == null || !this.ronEventFee) {
                this.warning = "Document Builder RON Event Fee is required";
            }
            if (!SfValidators.isPositiveFloatString(this.ronEventFee)) {
                this.warning = "RON Fee must be a valid number";
            }
            if (this.warning) return;

            this.ronEventFee = this.licenseService.roundOff(this.ronEventFee, 2);
            if (!SfValidators.testFee(this.ronEventFee)) {
                this.warning = "Invalid Document Builder RON Event Fee format";
                return;
            }
        // ESIGN EVENTS
        } else if (this.product.productID == "esign_event") {
            if (typeof this.renewalFee == "undefined" || this.renewalFee == null) {
                this.warning = "Renewal Fee is required";
            }
            if (typeof this.licenseFee == "undefined" || this.licenseFee == null) {
                this.warning = "Initial License Fee is required";
            }
            if (typeof this.ipenFee == "undefined" || this.ipenFee == null) {
                this.warning = "IPEN Fee is required";
            }
            if (typeof this.notarizationFee == "undefined" || this.notarizationFee == null) {
                this.warning = "No Notarization Fee is required";
            }
            if (typeof this.ronFee == "undefined" || this.ronFee == null) {
                this.warning = "RON Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.ronFee)) {
                this.warning = "RON Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.notarizationFee)) {
                this.warning = "No Notarization Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.ipenFee)) {
                this.warning = "IPEN Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.renewalFee)) {
                this.warning = "Renewal Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.licenseFee)) {
                this.warning = "Initial License Fee must be a valid number";
            }
            if (!SfValidators.isPositiveIntegerString(this.freeSubmissions)) {
                this.warning = "Free Events must be a positive number or zero";
            }
            if (this.warning) return;

            this.licenseFee = this.licenseService.roundOff(this.licenseFee, 2);
            if (!SfValidators.testFee(this.licenseFee)) {
                this.warning = "Invalid Initial License Fee format";
                return;
            }
            this.renewalFee = this.licenseService.roundOff(this.renewalFee, 2);
            if (!SfValidators.testFee(this.renewalFee)) {
                this.warning = "Invalid License Renewal Fee format";
                return;
            }
            this.notarizationFee = this.licenseService.roundOff(this.notarizationFee, 2);
            if (!SfValidators.testFee(this.notarizationFee)) {
                this.warning = "Invalid eSign Only Fee format";
                return;
            }
            this.ipenFee = this.licenseService.roundOff(this.ipenFee, 2);
            if (!SfValidators.testFee(this.ipenFee)) {
                this.warning = "Invalid IPEN Notary Fee format";
                return;
            }
            this.ronFee = this.licenseService.roundOff(this.ronFee, 2);
            if (!SfValidators.testFee(this.ronFee)) {
                this.warning = "Invalid RON Notary Fee format";
                return;
            }
        // TRUSTEE
        } else if (this.product.productID == "trustee") {
            if (typeof this.documentFee == "undefined" || this.documentFee == null) {
                this.warning = "Document Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.documentFee)) {
                this.warning = "Document Fee must be a valid number";
            }
            if (this.warning) return;

            this.documentFee = this.licenseService.roundOff(this.documentFee, 2);
            if (!SfValidators.testFee(this.documentFee)) {
                this.warning = "Invalid Document Fee format";
                return;
            }
        // LIEN RELEASE
        } else if (this.product.productID == "mers_api_integration") {
            if (typeof this.lienReleaseFee == "undefined" || this.lienReleaseFee == null) {
                this.warning = "Automated Document Request Fee is required";
            }
            if (this.warning) {
                return;
            }
            if (!SfValidators.isPositiveFloatString(this.lienReleaseFee)) {
                this.warning = "Automated Document Request Fee must be a valid number";
            }
            if (this.warning) return;

            this.lienReleaseFee = this.licenseService.roundOff(this.lienReleaseFee, 2);
            if (!SfValidators.testFee(this.lienReleaseFee)) {
                this.warning = "Invalid Automated Document Request Fee format";
                return;
            }
        // SUBSCRIPTION
        } else if (this.product.productID == "subscription") {
            if (typeof this.subscriptionMinimum == "undefined" || this.subscriptionMinimum == null) {
                this.warning = "Subscription Monthly Minimum is required";
            }
            if (this.warning) {
                return;
            }
            if (!SfValidators.isPositiveFloatString(this.subscriptionMinimum)) {
                this.warning = "Subscription Monthly Minimum must be a valid number";
            }
            if (this.warning) return;

            this.subscriptionMinimum = this.licenseService.roundOff(this.subscriptionMinimum, 2);
            if (!SfValidators.testFee(this.subscriptionMinimum)) {
                this.warning = "Invalid Monthly Minimum format";
                return;
            }
        // PAPER RECORDING
        } else if (this.product.productID == "submitter_paper") {
            if (typeof this.inStateErecordSubmissionFee == "undefined" || this.inStateErecordSubmissionFee == null) {
                this.warning = "In-State eRecording Submission Fee is required";
            }
            if (typeof this.outOfStateErecordSubmissionFee == "undefined" || this.outOfStateErecordSubmissionFee == null) {
                this.warning = "Out of State eRecording Submission Fee is required";
            }
            if (typeof !this.inStatePaperSubmissionFee == "undefined" || this.inStatePaperSubmissionFee == null) {
                this.warning = "In-State Paper Submission Fee is required";
            }
            if (typeof this.outOfStatePaperSubmissionFee == "undefined" || this.outOfStatePaperSubmissionFee == null) {
                this.warning = "Out of State Paper Submission Fee is required";
            }
            if (typeof this.paperMailFee == "undefined" || this.paperMailFee == null) {
                this.warning = "Mail Fee is required";
            }
            if (typeof this.embeddedEmployeeFee == "undefined" || this.embeddedEmployeeFee == null) {
                this.warning = "Embedded Employee Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.inStateErecordSubmissionFee)) {
                this.warning = "In-State eRecording Submission Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.outOfStateErecordSubmissionFee)) {
                this.warning = "Out of State eRecording Submission Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.inStatePaperSubmissionFee)) {
                this.warning = "In-State Paper Submission Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.outOfStatePaperSubmissionFee)) {
                this.warning = "Out of State Paper Submission Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.paperMailFee)) {
                this.warning = "Mail Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.embeddedEmployeeFee)) {
                this.warning = "Embedded Employee Fee must be a valid number";
            }
            if (this.warning) return;

            this.inStateErecordSubmissionFee = this.licenseService.roundOff(this.inStateErecordSubmissionFee, 2);
            if (!SfValidators.testFee(this.inStateErecordSubmissionFee)) {
                this.warning = "Invalid In-State eRecording Submission Fee format";
                return;
            }
            this.outOfStateErecordSubmissionFee = this.licenseService.roundOff(this.outOfStateErecordSubmissionFee, 2);
            if (!SfValidators.testFee(this.outOfStateErecordSubmissionFee)) {
                this.warning = "Invalid Out of State eRecording Submission Fee format";
                return;
            }
            this.inStatePaperSubmissionFee = this.licenseService.roundOff(this.inStatePaperSubmissionFee, 2);
            if (!SfValidators.testFee(this.inStatePaperSubmissionFee)) {
                this.warning = "Invalid In-State Paper Submission Fee format";
                return;
            }
            this.outOfStatePaperSubmissionFee = this.licenseService.roundOff(this.outOfStatePaperSubmissionFee, 2);
            if (!SfValidators.testFee(this.outOfStatePaperSubmissionFee)) {
                this.warning = "Invalid Out of State Paper Submission Fee format";
                return;
            }
            this.paperMailFee = this.licenseService.roundOff(this.paperMailFee, 2);
            if (!SfValidators.testFee(this.paperMailFee)) {
                this.warning = "Invalid Mail Fee format";
                return;
            }
            this.embeddedEmployeeFee = this.licenseService.roundOff(this.embeddedEmployeeFee, 2);
            if (!SfValidators.testFee(this.embeddedEmployeeFee)) {
                this.warning = "Invalid Embedded Employee Fee format";
                return;
            }
        // VENDOR
        } else if (this.product.productID == "submitter_vendor" || this.product.productID == "recipient_vendor" ||
                this.product.productID == "lender_vendor" || this.product.productID == "notary_vendor" ||
                this.product.productID == "esign_events_vendor") {
            if (typeof this.exclusiveSubmissionFee == "undefined" || this.exclusiveSubmissionFee == null) {
                if (this.selectedShareOption.id == "FLAT") {
                    this.warning = "Exclusive Per Document Flat Amount is required";
                } else {
                    this.warning = "Exclusive Submission Fee Percentage is required";
                }
            }
            if (typeof this.submissionFee == "undefined" || this.submissionFee == null) {
                if (this.selectedShareOption.id == "FLAT") {
                    this.warning = "Per Document Flat Amount is required";
                } else {
                    this.warning = "Submission Fee Percentage is required";
                }
            }
            if (this.warning) {
                return;
            }
            if (!SfValidators.isPositiveFloatString(this.exclusiveSubmissionFee)) {
                if (this.selectedShareOption.id == "FLAT") {
                    this.warning = "Exclusive Per Document Flat Amount must be a valid number";
                } else {
                    this.warning = "Exclusive Submission Fee Percentage must be a valid number";
                }
            }
            if (!SfValidators.isPositiveFloatString(this.submissionFee)) {
                if (this.selectedShareOption.id == "FLAT") {
                    this.warning = "Per Document Flat Amount must be a valid number";
                } else {
                    this.warning = "Submission Fee Percentage must be a valid number";
                }
            }
            if (this.warning) return;

            if (this.selectedShareOption.id == "FLAT") {
                this.submissionFee = this.licenseService.roundOff(this.submissionFee, 2);
                if (!SfValidators.testFee(this.submissionFee)) {
                    this.warning = "Invalid Per Document Flat Amount format"
                    return;
                }
                this.exclusiveSubmissionFee = this.licenseService.roundOff(this.exclusiveSubmissionFee, 2);
                if (!SfValidators.testFee(this.exclusiveSubmissionFee)) {
                    this.warning = "Invalid Exclusive Per Document Flat Amount format";
                    return;
                }
            }
        // LIC COLLAB LENDER
        } else if (this.product.productID == "licensed_collaborator_lender") {
            if (typeof this.renewalFee == "undefined" || this.renewalFee == null) {
                this.warning = "Renewal Fee is required";
            }
            /*
            if (isUndefined(this.loanAcceptFee) || this.loanAcceptFee == null)
                this.warning = "Loan Acceptance Fee is required";
            */
            if (typeof this.loanCreateFee == "undefined" || this.loanCreateFee == null) {
                this.warning = "Loan Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.renewalFee)) {
                this.warning = "Renewal Fee must be a valid number";
            }
            /*
            if (!sfUserorgService.isPositiveFloatString(this.loanAcceptFee))
                this.warning = "Loan Acceptance Fee must be a valid number";
            */
            if (!SfValidators.isPositiveFloatString(this.loanCreateFee)) {
                this.warning = "Loan Fee must be a valid number";
            }

            if (!SfValidators.isPositiveIntegerString(this.freeSubmissions)) {
                this.warning = "Free Loans must be a positive number or zero";
            }
            if (this.warning) return;

            this.loanCreateFee = this.licenseService.roundOff(this.loanCreateFee, 2);
            if (!SfValidators.testFee(this.loanCreateFee)) {
                this.warning = "Invalid Loan Fee format";
                return;
            }
            this.renewalFee = this.licenseService.roundOff(this.renewalFee, 2);
            if (!SfValidators.testFee(this.renewalFee)) {
                this.warning = "Invalid Renewal Fee format";
                return;
            }
        // LIC COLLAB SETTLEMENT
        } else if (this.product.productID == "licensed_collaborator_settlement") {
            if (typeof this.verifiedInsecureFee == "undefined" || this.verifiedInsecureFee == null) {
                this.warning = "Verified w/o Secure Disbursement Fee is required";
            }
            if (typeof this.disbursementFee == "undefined" || this.disbursementFee == null) {
                this.warning = "Disbursement Fee is required";
            }
            if (typeof this.renewalFee == "undefined" || this.renewalFee == null) {
                this.warning = "Renewal Fee is required";
            }
            /*
            if (isUndefined(this.revShareFee) || this.revShareFee == null)
                this.warning = "Revenue Share Fee is required";
            */
            /*
            if (isUndefined(this.loanAcceptFee) || this.loanAcceptFee == null)
                this.warning = "Loan Acceptance Fee is required";
            */
            if (typeof this.loanCreateFee == "undefined" || this.loanCreateFee == null) {
                this.warning = "Loan Fee is required";
            }
            if (this.warning) {
                return;
            }

            if (!SfValidators.isPositiveFloatString(this.verifiedInsecureFee)) {
                this.warning = "Verified w/o Secure Disbursement Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.disbursementFee)) {
                this.warning = "Disbursement Fee must be a valid number";
            }
            if (!SfValidators.isPositiveFloatString(this.renewalFee)) {
                this.warning = "Renewal Fee must be a valid number";
            }
            /*
            if (!sfUserorgService.isPositiveFloatString(this.revShareFee))
                this.warning = "Revenue Share Fee must be a valid number";
            */
            /*
            if (!sfUserorgService.isPositiveFloatString(this.loanAcceptFee))
                this.warning = "Loan Acceptance Fee must be a valid number";
            */
            if (!SfValidators.isPositiveFloatString(this.loanCreateFee)) {
                this.warning = "Loan Fee must be a valid number";
            }

            if (!SfValidators.isPositiveIntegerString(this.freeSubmissions)) {
                this.warning = "Free Loans must be a positive number or zero";
            }
            if (this.warning) return;

            this.loanCreateFee = this.licenseService.roundOff(this.loanCreateFee, 2);
            if (!SfValidators.testFee(this.loanCreateFee)) {
                this.warning = "Invalid Loan Fee format";
                return;
            }
            this.renewalFee = this.licenseService.roundOff(this.renewalFee, 2);
            if (!SfValidators.testFee(this.renewalFee)) {
                this.warning = "Invalid Renewal Fee format";
                return;
            }
            this.disbursementFee = this.licenseService.roundOff(this.disbursementFee, 2);
            if (!SfValidators.testFee(this.disbursementFee)) {
                this.warning = "Invalid Disbursement Fee format";
                return;
            }
            this.verifiedInsecureFee = this.licenseService.roundOff(this.verifiedInsecureFee, 2);
            if (!SfValidators.testFee(this.verifiedInsecureFee)) {
                this.warning = "Invalid Verified w/o Secure Disbursement Fee format";
                return;
            }
        }

        if (this.warning) {
            return;
        }

        if (!this.renewalFrequency) {
            this.warning = "Please select a Renewal Frequency";
            return;
        }
        if (!this.billingCycle) {
            this.warning = "Please select a billing cycle";
            return;
        }
        if (!this.paymentTerm) {
            this.warning = "Please select a payment term";
            return;
        }
        if (this.salesforceID) {
            if (!this.salesforceID.trim()) {
                this.warning = "Salesforce ID should be numbers and letters only";
                return;
            }
            if (this.salesforceID.trim().length != 18) {
                this.warning = "Salesforce ID should be exactly 18 characters long";
                return;
            }
        }
    }

    buildParams(): any {
        let params: any = {
            orgID: this.organization.id,
            productID: this.product.productID,
            usernames: null,
            licenseFee: this.licenseFee ? this.licenseFee : "",
            renewalFee: this.renewalFee ? this.renewalFee : "",
            submissionFee: this.submissionFee ? this.submissionFee : "",
            exclusiveSubmissionFee: this.exclusiveSubmissionFee ? this.exclusiveSubmissionFee : "",
            documentFee: this.documentFee ? this.documentFee : "",
            loanCreateFee: this.loanCreateFee ? this.loanCreateFee : "",
            loanAcceptFee: this.loanCreateFee ? this.loanCreateFee : "", // note same value intentional
            disbursementFee: this.disbursementFee ? this.disbursementFee : "",
            revShareFee: this.revShareFee ? this.revShareFee : "",
            verifiedInsecureFee: this.verifiedInsecureFee ? this.verifiedInsecureFee : "",
            ipenFee: this.ipenFee ? this.ipenFee : "",
            notarizationFee: this.notarizationFee ? this.notarizationFee : "",
            ronFee: this.ronFee ? this.ronFee : "",
            inStateErecordSubmissionFee: this.inStateErecordSubmissionFee ? this.inStateErecordSubmissionFee : "",
            outOfStateErecordSubmissionFee: this.outOfStateErecordSubmissionFee ? this.outOfStateErecordSubmissionFee : "",
            inStatePaperSubmissionFee: this.inStatePaperSubmissionFee ? this.inStatePaperSubmissionFee : "",
            outOfStatePaperSubmissionFee: this.outOfStatePaperSubmissionFee ? this.outOfStatePaperSubmissionFee : "",
            paperMailFee: this.paperMailFee ? this.paperMailFee : "",
            embeddedEmployeeFee: this.embeddedEmployeeFee ? this.embeddedEmployeeFee : "",
            freeSubmissions: this.freeSubmissions,
            enrollmentCode: (this.selectedEnrollmentTemplate ? this.selectedEnrollmentTemplate.registrationCode : ""),
            renewalFrequency: this.renewalFrequency,
            billingCycleID: this.billingCycle,
            recordingFeeBillingCycleID: this.recordingFeeBillingCycle,
            paymentTermID: this.paymentTerm,
            salesforceID: this.salesforceID,
            ronEventFee: this.ronEventFee ? this.ronEventFee : "",
            lienReleaseFee: this.lienReleaseFee ? this.lienReleaseFee : "",
            subscriptionMinimum: this.subscriptionMinimum ? this.subscriptionMinimum : ""
        };
        return params;
    }

    save() {
        let selectedUsers: any[] = [];
        this.adminUsers.forEach((user) => {
            if (user.checked) {
                selectedUsers.push(user.username);
            }
        });
        if (this.adminUsers.length && selectedUsers.length == 0) {
            this.warning = "Select at least one user";
            return;
        }

        // Send the invitation
        this.processing = true;
        this.primary.disabled = true;
        let params = this.buildParams();
        params.usernames = selectedUsers;

        if (this.invitation) {
            //back-end call to invite barfs if any params are undefined (we set these from initial invitation)
            Object.entries(params).forEach(([key, value]: [key: any, value: any]) => {
                if (value === undefined) {
                    params[key] = "";
                }
            });
            this.tokenService.cancelServiceInvitation(params.orgID, params.productID).subscribe(() => {
                this.tokenService.inviteToService(params).subscribe((url: string) => {
                    this.activeModal.close({ url: url });
                }, () => {
                    // error will have been reported already
                    this.processing = false;
                    this.primary.disabled = false;
                });
            });
        } else {
            this.tokenService.inviteToService(params).subscribe((url: string) => {
                this.activeModal.close({ url: url });
            }, () => {
                // error will have been reported already
                this.processing = false;
                this.primary.disabled = false;
            });
        }
    }

    computeContractType(): string {
        let contractType = "INVALID";
        if (this.product.productID == "licensed_collaborator_lender") {
            contractType = "LENDER";
        } else if (this.product.productID == "licensed_collaborator_settlement") {
            contractType = "SETTLEMENT";
        } else if (this.product.productID == "submitter") {
            contractType = "ERECORDING";
        } else if (this.product.productID == "submitter_signing") {
            contractType = "DOCUMENT_BUILDER";
        } else if (this.product.productID == "esign_event") {
            contractType = "ESIGN_EVENT";
        } else if (this.product.productID == "trustee") {
            contractType = "TRUSTEE";
        } else if (this.product.productID == "mers_api_integration") {
            contractType = "LIEN_RELEASE";
        } else if (this.product.productID == "subscription") {
            contractType = "SUBSCRIPTION";
        } else if (this.product.productID == "submitter_paper") {
            contractType = "PAPER_RECORDING";
        } else if (this.product.productID == "doc_builder_ron") {
            contractType = "DOC_BUILDER_RON";
        }

        return contractType;
    }

    lookupEnrollmentCode(code: string) {
        this.contractService.getContractTemplate(code).subscribe((template: ContractPricingTemplate) => {
            if (template) {
                this.selectedEnrollmentTemplate = template;
                this.loadFieldsFromTemplate(template);
            }
        });
    }

    showEnrollmentCode() {
        let contractType = this.computeContractType();

        const modalRef = this.modalService.open(EnrollmentCodeSelectDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = contractType;

        modalRef.result.then((template: ContractPricingTemplate) => {
            if (template) {
                this.selectedEnrollmentTemplate = template;
                this.loadFieldsFromTemplate(template);
            }
        }, () => {
            // nothing on cancel
        });
    }

    loadFieldsFromTemplate(template: ContractPricingTemplate) {
        if (template.renewalFrequency) {
            this.renewalFrequency = template.renewalFrequency;
        }
        if (template.contractTemplateFees) {
            if (template.contractTemplateFees.LICENSE_FEE) {
                this.licenseFee = template.contractTemplateFees.LICENSE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.RENEWAL_FEE) {
                this.renewalFee = template.contractTemplateFees.RENEWAL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.SUBMISSION_FEE) {
                this.submissionFee = template.contractTemplateFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE) {
                this.exclusiveSubmissionFee = template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE) {
                this.licenseFee = template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE) {
                this.renewalFee = template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE) {
                this.documentFee = template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.SETTLEMENT_RENEWAL_FEE) {
                this.renewalFee = template.contractTemplateFees.SETTLEMENT_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE) {
                this.loanCreateFee = template.contractTemplateFees.SETTLEMENT_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.SETTLEMENT_LOAN_ACCEPT_FEE) {
                this.loanAcceptFee = template.contractTemplateFees.SETTLEMENT_LOAN_ACCEPT_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.DISBURSEMENT_FEE) {
                this.disbursementFee = template.contractTemplateFees.DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.REVSHARE_FEE) {
                this.revShareFee = template.contractTemplateFees.REVSHARE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE) {
                this.verifiedInsecureFee = template.contractTemplateFees.VERIFIED_WITHOUT_SECURE_DISBURSEMENT_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.LENDER_ANNUAL_FEE) {
                this.renewalFee = template.contractTemplateFees.LENDER_ANNUAL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.LENDER_LOAN_CREATE_FEE) {
                this.loanCreateFee = template.contractTemplateFees.LENDER_LOAN_CREATE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.LENDER_LOAN_ACCEPT_FEE) {
                this.loanAcceptFee = template.contractTemplateFees.LENDER_LOAN_ACCEPT_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.TRUSTEE_DOCUMENT_FEE) {
                this.documentFee = template.contractTemplateFees.TRUSTEE_DOCUMENT_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.LIEN_RELEASE_FEE) {
                this.lienReleaseFee = template.contractTemplateFees.LIEN_RELEASE_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.SUBSCRIPTION_MINIMUM) {
                this.subscriptionMinimum = template.contractTemplateFees.SUBSCRIPTION_MINIMUM.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.ESE_LICENSE_FEE) {
                this.licenseFee = template.contractTemplateFees.ESE_LICENSE_FEE.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.ESE_RENEWAL_FEE) {
                this.renewalFee = template.contractTemplateFees.ESE_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.ESE_NO_ENOTARIZATION) {
                this.notarizationFee = template.contractTemplateFees.ESE_NO_ENOTARIZATION.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.ESE_IPEN) {
                this.ipenFee = template.contractTemplateFees.ESE_IPEN.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.ESE_RON) {
                this.ronFee = template.contractTemplateFees.ESE_RON.contractFeeTiers[0].feeAmount;
            }

            if (template.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE) {
                this.inStateErecordSubmissionFee = template.contractTemplateFees.INSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE) {
                this.outOfStateErecordSubmissionFee = template.contractTemplateFees.OUTOFSTATE_ERECORD_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE) {
                this.inStatePaperSubmissionFee = template.contractTemplateFees.INSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE) {
                this.outOfStatePaperSubmissionFee = template.contractTemplateFees.OUTOFSTATE_PAPER_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.PAPER_MAIL_FEE) {
                this.paperMailFee = template.contractTemplateFees.PAPER_MAIL_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE) {
                this.embeddedEmployeeFee = template.contractTemplateFees.EMBEDDED_EMPLOYEE_FEE.contractFeeTiers[0].feeAmount;
            }
            if (template.contractTemplateFees.DOCUMENT_BUILDER_RON_FEE) {
                this.ronEventFee = template.contractTemplateFees.DOCUMENT_BUILDER_RON_FEE.contractFeeTiers[0].feeAmount;
            }
        }
        this.freeSubmissions = template.freeSubmissions;
        this.billingCycle = template.billingCycleType;
        this.paymentTerm = template.paymentTerms;
    }

    changeEnrollmentCode() {
        this.showEnrollmentCode();
    }

    removeEnrollmentCode() {
        this.selectedEnrollmentTemplate = null;
    }

    dismiss() {
        this.activeModal.close(null);
    }

    chooseNonUser() {
        this.validate();
        if (this.warning) {
            return;
        }

        const modalRef = this.modalService.open(SendLicenseDialogComponent);
        const modalInstance = modalRef.componentInstance;
        modalInstance.cancelText = "Back";

        modalRef.result.then((newData: any) => {
            if (newData) {
                let params = this.buildParams();
                params.firstName = newData.firstName;
                params.lastName = newData.lastName;
                params.title = newData.title;
                params.email = newData.email;
                this.primary.disabled = true;
                this.tokenService.inviteToServiceNonUser(params).subscribe((url: string) => {
                    this.activeModal.close({ url: url });
                }, () => {
                    // error will have been reported already
                    this.processing = false;
                    this.primary.disabled = false;
                });
            }
        }, () => {
            // nothing
        });
    }
}
