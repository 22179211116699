import { Component, TemplateRef } from "@angular/core";
import { IHeaderAngularComp } from "@ag-grid-community/angular";
import { IHeaderParams } from "@ag-grid-community/core";

@Component({
    selector: "sf-header-template-renderer",
    template: ` <ng-container *ngTemplateOutlet="template"></ng-container> `
})
export class HeaderTemplateRendererComponent implements IHeaderAngularComp {
    template: TemplateRef<any>;

    agInit(params: IHeaderParams): void {
        // @ts-ignore
        this.template = params["ngTemplate"];
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }
}
