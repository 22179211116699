import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationInterceptor } from "@sf/common";
import { TransformResponseInterceptor } from "@sf/common";
import { LoggingInterceptor } from "@sf/common";
import { SignEventTimeoutInterceptor } from "./interceptors/sign-event-timeout.interceptor";

export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenticationInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: SignEventTimeoutInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TransformResponseInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggingInterceptor,
        multi: true
    }
];
