<sf-modal-header [title]="modalTitle"></sf-modal-header>
<div class="modal-body sf-submitter-show-unaffected-packages-dialog">
    <div>
        <p>The following packages were not updated:</p>
    </div>
    <div class="unaffected__message-list">
        <div class="message-list__message" *ngFor="let msg of unaffectedPackageMessages">
            <div class="message__name">{{msg.packageName}}</div>
            <div class="message__recipient">{{msg.packageRecipient}}</div>
            <div class="message__reason">{{msg.reason}}</div>
            <hr *ngIf="unaffectedPackageMessages.length > 0" class="message__bottom-line">
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primaryButton"></sf-modal-footer>
