export enum BannerNotificationCategory {
    PAYMENT_ACCOUNTS = "payment_accounts",
    ESIGN_SETUP = "esign_setup",
    NOTARY_SETUP = "notary_setup",
    CRITICAL = "critical",
    SUSPENDED_SERVICE = "suspended_service",
    ROLE_INVITATION = "role_invitation",
    ACCEPT_CONTRACT = "accept_contract",
    PENDING_PAYMENTS = "pending_payments",
    CUSTOM = "custom",
    CREDIT_CARD_EXPIRED = "credit_card_expired",
    CREDIT_CARD_EXPIRING = "credit_card_expiring",
    DOCUMENT_BUILDER_GUIDE = "document_builder_guide",
    SECOND_FACTOR_MOBILE_PHONE = "second_factor_mobile_phone",
    SERVICE_TRIAL = "service_trial",
    SIGNING_EVENT_CREATED = "signing_event_created",
    EE_NOTARY_ARRIVED = "ee_notary_arrived",
    INTERNET_EXPLORER = "internet_explorer",
    NO_LICENSE_FEE_DEFAULT = "no_license_fee_default",
    MISSING_LEGAL_ENTITY_INFO = "missing_legal_entity_info",
    PROGRESS_BAR = "progress_bar",
    CSV_UPLOAD_INFO = "csv_upload_info",
    PENDING_TRUSTEE_SERVICER = "pending_trustee_servicer"
}
