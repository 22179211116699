import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    COMPONENT_FROM_FIELD,
    DynamicFormStore,
    FieldState,
    FieldType,
    FieldViewState,
    InputComponent
} from "@sf/dynamic-form-viewer";
import { CustomArrayFieldItemWrapperComponent } from "../custom-array-field-item-wrapper/custom-array-field-item-wrapper.component";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
    debounceTime,
    filter,
    pluck,
    scan,
    startWith,
    switchMap,
    take,
    takeUntil
} from "rxjs/operators";
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonPartyDialogComponent } from "../../dialog/common-party-dialog/common-party-dialog.component";
import { UserType } from "../../interfaces/user-type.enum";
import { CustomFormService } from "../../custom-form.service";
import {
    DocLeftEventsService,
    ErecordDocumentDetails
} from "@sf/document/erecord-viewer/common";
import { GrowlService } from "@sf/common";

@Component({
    selector: "sf-custom-array-field",
    templateUrl: "./custom-array-field.component.html",
    styleUrls: ["./custom-array-field.component.scss"]
})
export class CustomArrayFieldComponent
    extends InputComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    private _destroy$ = new Subject<void>();
    currentLength: number = 0;
    private _initialLoad: boolean = true;
    maxReached: boolean = false;
    lastIsBlank: boolean = false;
    otherDocuments: { name: string; id: string }[];
    autoCompleteSub = new BehaviorSubject<any[]>([]);
    autoComplete$: Observable<string[]>;
    hasPartyName: boolean = false;
    enableAdvancedCommonParties: boolean = false;
    previousLength: number = 0;
    expanded: boolean = true;
    doingCopy: boolean = false;
    expandableSetSubjects: any = {};

    @Input()
    formId: string;
    @Input()
    docLeftData: ErecordDocumentDetails;
    @Input()
    orgID: string;
    @Input()
    userType: UserType;
    @Input()
    onSave: Observable<any>;

    @ViewChild("arrayHost", { read: ViewContainerRef })
    arrayHost: ViewContainerRef;
    showOptions: boolean = false;
    allowAdd: boolean = true;
    fields$: Observable<FieldState[]>;
    documentId: string;
    lastGroupIsPristine: boolean = false;
    lastGroupUntouched: boolean = true;
    private _latestFieldStates: FieldState[] = [];
    private _formGroup: any;
    private _addItemPending: boolean = false;
    private _onLeaveCallBack: () => void;
    private _filteredAutoCompleteData: any;

    disabled$: Observable<boolean>;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        @Inject(COMPONENT_FROM_FIELD)
        private _getComponentFromField: (
            field: FieldState
        ) => Type<InputComponent>,
        protected _facade: DynamicFormStore,
        private cd: ChangeDetectorRef,
        private _customFormService: CustomFormService,
        private _modalService: NgbModal,
        private _growlService: GrowlService,
        private _eventService: DocLeftEventsService
    ) {
        super(_facade);
    }

    ngOnInit() {
        super.ngOnInit();

        this._eventService
            .getEvent$("leavePage")
            .pipe(takeUntil(this._destroy$))
            .subscribe((callback: () => void) => {
                this._checkLastGroup();

                if (this.lastIsBlank) {
                    callback();
                } else {
                    if (
                        !this.lastIsBlank &&
                        !this.maxReached &&
                        !this.lastGroupUntouched
                    ) {
                        this._onLeaveCallBack = callback;
                        this._addItem();
                    } else {
                        callback();
                    }
                }
            });

        this.onSave.pipe(takeUntil(this._destroy$)).subscribe(() => {
            if (this._onLeaveCallBack) {
                this._onLeaveCallBack();
            }
        });

        this.documentId = this.docLeftData?.id;
        this.enableAdvancedCommonParties =
            sf.liveConfig.DocViewerSettings.enableAdvancedCommonPartySettings ??
            false;

        this.disabled$ = this.formControl$.pipe(
            takeUntil(this._destroy$),
            filter((control) => !!control),
            pluck("disabled"),
            startWith(true)
        );

        this._formFacade.formGroup$
            .pipe(
                takeUntil(this._destroy$),
                switchMap((formGroup: any) => {
                    this._formGroup = formGroup;
                    this.lastGroupIsPristine =
                        this._formGroup.controls[this.field.id].controls
                            .length > 1;
                    return formGroup.valueChanges;
                }),
                debounceTime(500)
            )
            .subscribe((values: any) => {
                this._checkLastGroup();
            });

        this._formFacade
            .getFormStateForField(this.field.id)
            .subscribe((formState: any) => {
                this.allowAdd = !formState.disabled;
            });

        this.autoComplete$ = this.autoCompleteSub.asObservable();
        if (this.documentId) {
            // change this to observe something from higher up
            this._customFormService
                .getAutoCompleteSuggestions(this.documentId, this.formId)
                .subscribe((data: any) => {
                    this._handleAutoCompleteSuggestions(data);
                });

            this._customFormService
                .getOcrSuggestionUpdates$(this.docLeftData.id)
                .pipe(takeUntil(this._destroy$))
                .subscribe((ocrSuggestions: any) => {
                    if (this.documentId) {
                        this._customFormService
                            .getAutoCompleteSuggestions(
                                this.docLeftData.id,
                                this.formId,
                                ocrSuggestions.pageAnalysisID
                            )
                            .subscribe((autoCompleteData: any) => {
                                this._handleAutoCompleteSuggestions(
                                    autoCompleteData
                                );
                            });
                    }
                });
        }

        this._formFacade
            .getViewStateForField(this.field.fullPath)
            .pipe(takeUntil(this._destroy$))
            .subscribe((viewState: FieldViewState) => {
                this.expanded = viewState.expanded;
            });
    }

    private _handleAutoCompleteSuggestions(data: any) {
        let label = this._toCamelCase(this.field.id);
        if (label.indexOf("[]") > -1) {
            label = label.split("[]")[0];
        }
        if (data.hasOwnProperty(label)) {
            let autoCompleteData = (data[label] as any[]).map((autoData) => {
                return autoData.auto_complete_name;
            });
            let filteredAutoCompleteData: string[] = [];
            autoCompleteData.forEach((option: string) => {
                if (
                    filteredAutoCompleteData.indexOf(option) === -1 &&
                    option !== ""
                ) {
                    filteredAutoCompleteData.push(option);
                }
            });
            if (this.enableAdvancedCommonParties) {
                this._filteredAutoCompleteData = this._filterAutoCompleteData(
                    data[label]
                );
            }
            this.autoCompleteSub.next(filteredAutoCompleteData);
        }
    }

    private _filterAutoCompleteData(value: any) {
        if (value && Array.isArray(value)) {
            let itemMap: any = {};
            for (let i = 0; i < value.length; ++i) {
                let item = value[i];
                if (itemMap[item.auto_complete_name]) {
                    let keys1 = Object.keys(itemMap[item.auto_complete_name]);
                    let keys2 = Object.keys(item);

                    // if there are duplicates, save the item that has more attributes. Presumably, it will be the more accurate one
                    if (keys2.length > keys1.length) {
                        itemMap[item.auto_complete_name] = item;
                    }
                } else {
                    itemMap[item.auto_complete_name] = item;
                }
            }
            return itemMap;
        } else {
            return value;
        }
    }

    private _toCamelCase(str: string) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) => {
                return index == 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, "");
    }

    ngAfterViewInit() {
        // TODO: this should also be false, if the form is in read-only mode
        this.showOptions =
            !!this.documentId &&
            (this.userType === UserType.SUBMITTER ||
                this.userType === UserType.RECIPIENT ||
                this.userType === UserType.SUPER_USER);

        if (this.showOptions) {
            this._customFormService
                .getOtherDocumentIDs(this.documentId)
                .subscribe((docs: any) => {
                    this.otherDocuments = this._convertDocsInPackage(docs);
                });

            this._hasPartyNameField().subscribe((hasParty: boolean) => {
                this.hasPartyName = hasParty;
            });
        }

        this._formFacade
            .getVisibleFieldsForField(this.field.fullPath)
            .pipe(
                scan(
                    (previous, curr) => [
                        previous[1].map((field) => field.id),
                        curr
                    ],
                    [[], []]
                ),
                takeUntil(this._destroy$)
            )
            .subscribe(([previous, current]) => {
                if (previous.length === current.length) {
                    if (
                        (current.length === 0 && this._initialLoad) ||
                        !this._visibleFieldStatesChanged(previous, current)
                    ) {
                        return;
                    }
                }

                let adjustment: number = this.maxReached ? 0 : 1;
                this.previousLength =
                    this.currentLength > 0
                        ? this.currentLength - adjustment
                        : 0;
                this.currentLength = current.length;
                this.loadComponents(current, previous);
            });

        this._checkIfMaxIsReachOnLoad();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._eventService.onUnsubscribe("leavePage");
    }

    loadComponents(controls: FieldState[], previous: string[]) {
        this.cd.detach();
        this._latestFieldStates = controls;
        let currentFields: string[] = [];
        let ctrlCount = controls.length;
        let added: boolean = false;
        let expansions: { path: string; expanded: boolean }[] = [];

        added = this._loadComponentsAddStep(
            currentFields,
            expansions,
            controls,
            previous
        );

        // get rid of fields tht are no longer there
        this._loadComponentsRemoveStep(currentFields, previous, ctrlCount);
        this._initialLoad = false;

        this._processAdd(added, ctrlCount - 2, null, () => {
            if (expansions.length) {
                this._formFacade.setMultipleExpanded(expansions);
            }

            this.cd.reattach();
            this.cd.markForCheck();
        });
    }

    copyFieldsFromOtherDoc(otherDocID: string) {
        let controls: FormGroup[];

        return this.formControl$
            .pipe(
                take(1),
                switchMap((control) => {
                    controls = (control as unknown as FormArray)
                        .controls as FormGroup[];
                    let keys = Object.keys(
                        this.field.repeatableFieldState.fieldState
                    );
                    let fieldIDList: string[] = [];

                    for (let fieldStateID of keys) {
                        if (fieldStateID !== "*") {
                            fieldIDList.push(
                                this.field.repeatableFieldState.fieldState[
                                    fieldStateID
                                ].id
                            );
                        }
                    }

                    return of(fieldIDList);
                }),
                switchMap((fieldIDList: string[]) => {
                    return this._customFormService.getDocumentGroupInfo(
                        otherDocID,
                        fieldIDList
                    );
                })
            )
            .subscribe(
                (
                    result: {
                        customFieldID: string;
                        customValueID: string;
                        sequenceID: number;
                        valueText: string;
                    }[]
                ) => {
                    // let group = controls[formArrayIndex] as FormGroup;
                    // group.patchValue({ [fieldStateID]: unParsed });
                    setTimeout(() => {
                        if (result && result.length > 0) {
                            this.doingCopy = true;
                            this.cd.detach();
                            this._formFacade.clearArray(this.controlPath);
                            this._partyParseResult(result, controls);
                            this.cd.reattach();
                            this.cd.markForCheck();
                            this.doingCopy = false;
                        } else {
                            this._growlService.warning("Nothing to copy");
                        }
                    });
                }
            );
    }

    commonPartyDialog() {
        const modalRef = this._modalService.open(CommonPartyDialogComponent);
        const modalInstance = modalRef.componentInstance;

        modalInstance.orgID = this.orgID;
        modalInstance.formId = this.formId;
        modalInstance.userType = this.userType;
        modalInstance.enableAdvancedCommonParties =
            this.enableAdvancedCommonParties;

        modalInstance.possibleParties = [];
        modalInstance.commonPartySave = ((commonPartyList: string[]) => {
            this._commonPartySave(commonPartyList);
            modalRef.close();
        }).bind(this);

        combineLatest([this.autoComplete$, this.formControl$])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([parties, control]) => {
                // potential parties (from other data)
                let controls: AbstractControl[] = (
                    control as unknown as FormArray
                ).controls;
                let objectParties = [];
                for (let i = 0; i < controls.length; ++i) {
                    let group = controls[i] as FormGroup;
                    for (let fieldID of Object.keys(group.controls)) {
                        let field = group.controls[fieldID];
                        if (
                            field.value &&
                            this.field.repeatableFieldState.fieldState[
                                "*." + fieldID
                            ].type === FieldType.PARTYPARSER
                        ) {
                            // don't include parties that are already in the common party list
                            if (parties.indexOf(field.value) === -1) {
                                objectParties.push({
                                    value: field.value,
                                    editing: false
                                });
                            }
                        }
                    }
                }
                modalInstance.possibleParties = objectParties;
            });
    }

    private _commonPartySave(commonPartyList: any[]) {
        this._customFormService
            .setCommonParties(this.documentId, this.formId, commonPartyList)
            .subscribe((result: any) => {
                let autoCompleteData = (
                    result[this.field.label.toLowerCase()] as any[]
                ).map((autoData) => {
                    return autoData.auto_complete_name;
                });

                let filteredAutoCompleteData: string[] = [];
                autoCompleteData.forEach((option: string) => {
                    if (filteredAutoCompleteData.indexOf(option) === -1) {
                        filteredAutoCompleteData.push(option);
                    }
                });
                this.autoCompleteSub.next(filteredAutoCompleteData);
            });
    }

    private _loadComponentsAddStep(
        currentFields: string[],
        expansions: any[],
        controls: FieldState[],
        previous: string[]
    ) {
        let added: boolean = false;
        let ctrlCount = controls.length;
        let nextIndex = 0;

        this.formControl$
            .pipe(take(1))
            .subscribe(this._lastHasValues.bind(this));

        for (let field of controls) {
            currentFields.push(field.id);
            if (!previous.includes(field.id)) {
                added = true;
                this._createNewComponent(field, nextIndex);
            }

            let isLast: boolean = nextIndex === ctrlCount - 1;
            if (!isLast || field.expandable) {
                let expandableChanged: boolean = field.expandable === isLast;
                if (expandableChanged) {
                    field.expandable = !isLast;
                    this.expandableSetSubjects[field.fullPath].next(
                        field.expandable
                    );
                }
            }

            expansions.push({
                path: field.fullPath,
                // the first time these components are loaded, they need to be expanded so the
                // collapsed message text can be generated
                expanded: true
            });

            nextIndex++;
        }

        return added && !this._initialLoad && !this.doingCopy;
    }

    private _loadComponentsRemoveStep(
        currentFields: string[],
        previous: string[],
        ctrlCount: number
    ) {
        for (let visibleControl of [...previous]) {
            if (!currentFields.includes(visibleControl)) {
                let index = previous.indexOf(visibleControl);
                this.arrayHost.remove(index);
                previous.splice(index, 1);

                // add a blank group after the previous set of fields was erased to make it look like
                // the user now has a blank form available to fill out. Only needed if the user USED to be
                // at the max amount before deleting the previous fields
                if (this.maxReached && this.expanded) {
                    this._addBlankGroup();
                }
            }
        }
        if (ctrlCount < this.field.maxLength || !this.field.maxLength) {
            this.maxReached = false;
        }
    }

    private _lastHasValues(control: FormControl) {
        let controlVals: any = control.value[control.value.length - 1];

        for (let key in controlVals) {
            if (controlVals[key] && controlVals[key] !== "") {
                this.lastIsBlank = false;
                return;
            }
        }
        this.lastIsBlank = true;
    }

    private _checkLastGroup() {
        let arrayControls = this._formGroup.controls[this.field.id].controls;
        this.formControl$
            .pipe(take(1))
            .subscribe(this._lastHasValues.bind(this));
        this.lastGroupIsPristine = this.lastIsBlank && arrayControls.length > 1;
        this.lastGroupUntouched =
            !arrayControls[arrayControls.length - 1].touched;
    }

    private _createNewComponent(field: FieldState, index: number) {
        const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
                CustomArrayFieldItemWrapperComponent
            );

        const component = this.arrayHost.createComponent(
            componentFactory,
            index
        );
        const instance = component.instance;
        instance.autoCompleteData = this.autoComplete$;
        instance.field = field;
        this.expandableSetSubjects[field.fullPath] = new Subject<any>();
        instance.onExpandableSet =
            this.expandableSetSubjects[field.fullPath].asObservable();
        instance.controlPath = field.fullPath;
        instance.disabled$ = this.disabled$;
        instance.docLeftData = this.docLeftData;
        instance.onSave = this.onSave;
        (instance as CustomArrayFieldItemWrapperComponent).onPartySelection
            .pipe(takeUntil(this._destroy$))
            .subscribe((selection) => {
                if (this.enableAdvancedCommonParties) {
                    this.formControl$.pipe(take(1)).subscribe((control) => {
                        let controls: AbstractControl[] = (
                            control as unknown as FormArray
                        ).controls;

                        let toParse: any[] = [];
                        let keys: string[] = Object.keys(
                            this._filteredAutoCompleteData[selection]
                        );
                        for (let k = 0; k < keys.length; ++k) {
                            if (keys[k] !== "auto_complete_name") {
                                let parseItem =
                                    this._filteredAutoCompleteData[selection][
                                        keys[k]
                                    ];
                                parseItem.sequenceID = controls.length - 1;
                                toParse.push(parseItem);
                            }
                        }

                        this._partyParseResult(
                            toParse,
                            controls as FormGroup[]
                        );
                    });
                } else {
                    this._addItem();
                }
            });
        let instancePath = field.fullPath;
        (instance as CustomArrayFieldItemWrapperComponent).onPartyTypeChanged
            .pipe(takeUntil(this._destroy$))
            .subscribe((partyTypeResult: any) => {
                // get the new index that this component will be at
                for (let i = 0; i < this._latestFieldStates.length; ++i) {
                    if (instancePath === this._latestFieldStates[i].fullPath) {
                        this.processPartyTypeSwitch(partyTypeResult, i);
                        break;
                    }
                }
            });
    }

    processPartyTypeSwitch(partyTypeResult: any, formArrayIndex: number) {
        this._processAdd(true, formArrayIndex, partyTypeResult);
    }

    private _convertDocsInPackage(docInPackageArray: any) {
        let index = 0;
        let otherDocsInPackageArray: any[] = [];
        for (let key in docInPackageArray) {
            otherDocsInPackageArray[index] = {
                id: key,
                name: docInPackageArray[key]
            };
            index++;
        }

        return otherDocsInPackageArray;
    }

    private _visibleFieldStatesChanged(
        previous: string[],
        current: FieldState[]
    ) {
        if (previous.length !== current.length) {
            return true;
        }

        for (let i = 0; i < previous.length; ++i) {
            if (previous[i] !== current[i].path) {
                return true;
            }
        }

        return false;
    }

    private _processAdd(
        added: boolean,
        formArrayIndex: number,
        forcedPartyType?: string,
        callback?: () => void
    ) {
        return this.formControl$.pipe(take(1)).subscribe((control) => {
            let controls: AbstractControl[] = (control as unknown as FormArray)
                .controls;
            if (!added) {
                if (callback) {
                    callback();
                }
                return;
            }

            // if an organization is selected from the party type
            if (forcedPartyType && forcedPartyType === "Organization") {
                let group = controls[formArrayIndex] as FormGroup;
                let unParsed = this._getUnparsedValueFromIndividual(
                    group,
                    this._latestFieldStates[formArrayIndex]
                );

                for (let fieldStateID of Object.keys(group.controls)) {
                    let path =
                        this._latestFieldStates[formArrayIndex].id +
                        "." +
                        fieldStateID;
                    this._formFacade
                        .getStateForField(path)
                        .pipe(take(1))
                        .subscribe((fieldState: FieldState) => {
                            // the newly created string (from the other fields) assign to the unparsedField
                            if (fieldState.type === FieldType.NAMEUNPARSED) {
                                group.patchValue({ [fieldStateID]: unParsed });
                            }
                        });
                }

                if (callback) {
                    callback();
                }
            }
            // if an individual is selected from the party type,
            // or if a value is returned from the parser from a group being added
            else {
                let group: FormGroup = controls[formArrayIndex] as FormGroup;
                let partyNameField: FieldState = null;
                let valueToParse: string;

                // find the party name field, AND find the value to parse
                for (let fieldStateID of Object.keys(
                    this.field.repeatableFieldState.fieldState
                )) {
                    let field =
                        this.field.repeatableFieldState.fieldState[
                            fieldStateID
                        ];

                    // get the value to parse from the unparsedField
                    // (if selected from the field type, otherwise use the party_name field)
                    if (
                        field.type === FieldType.NAMEUNPARSED &&
                        forcedPartyType &&
                        forcedPartyType === "Individual"
                    ) {
                        valueToParse = group.controls[field.path].value;
                    }
                    if (field.type === FieldType.PARTYPARSER) {
                        partyNameField = field;
                    }
                }

                // send the value to the backend parser, if there is party parsing
                if (partyNameField) {
                    if (!valueToParse) {
                        valueToParse = group
                            ? group.controls[partyNameField.path].value
                            : null;
                    }
                    // if there is still nothing to parse, then do nothing
                    if (valueToParse) {
                        this._customFormService
                            .getPartyNameParsed(
                                valueToParse,
                                this.documentId,
                                this.formId,
                                partyNameField.id,
                                formArrayIndex,
                                forcedPartyType // "Organization"; // "Individual";
                            )
                            .subscribe((result: any[]) => {
                                this._partyParseResult(
                                    result,
                                    controls as FormGroup[]
                                );
                                if (callback) {
                                    callback();
                                }
                            });
                    }
                } else if (callback) {
                    callback();
                }
            }
        });
    }

    // apply the parsed results to the fields
    private _partyParseResult(result: any[], controls: FormGroup[]) {
        let patchValues: any[] = [];
        let highestPatchValue: number = 0;
        for (let i = 0; i < result.length; ++i) {
            if (!patchValues[result[i].sequenceID]) {
                patchValues[result[i].sequenceID] = {};
            }

            let value = result[i].valueText;
            if (result[i].customValueID && result[i].customValueID.length > 0) {
                value = result[i].customValueID;
            }
            if (highestPatchValue < result[i].sequenceID) {
                highestPatchValue = result[i].sequenceID;
            }
            let fieldPath = result[i].customField.fieldPath;
            if (fieldPath && fieldPath != "") {
                fieldPath =
                    (fieldPath.indexOf("[].") > -1
                        ? fieldPath.split(".")[1]
                        : fieldPath) +
                    "#" +
                    result[i].customFieldID;
            } else {
                fieldPath = result[i].customFieldID;
            }
            patchValues[result[i].sequenceID][fieldPath] = value;
        }

        for (let i = 0; i <= highestPatchValue + 1; ++i) {
            if (
                controls.length === i &&
                (this.field.maxLength === null ||
                    controls.length < this.field.maxLength)
            ) {
                this._addBlankGroup();
            }
        }

        for (let p = 0; p < controls.length; p++) {
            if (patchValues[p]) {
                controls[p].patchValue(patchValues[p]);
            }
        }

        this.formControl$
            .pipe(take(1))
            .subscribe(this._lastHasValues.bind(this));
        if (this.field.maxLength === controls.length && !this.lastIsBlank) {
            this._setMax();
        }
    }

    // gather all of the values from a group, and return them in a single string
    private _getUnparsedValueFromIndividual(
        group: FormGroup,
        groupFieldState: FieldState
    ) {
        let unparsedValue = "";
        let unparsedID = "";

        this._formFacade
            .getVisibleFieldsForField(groupFieldState.id)
            .pipe(take(1))
            .subscribe((fields: FieldState[]) => {
                for (let i = 0; i < fields.length; ++i) {
                    let field = fields[i];
                    switch (field.type) {
                        case FieldType.SELECT:
                        case FieldType.MULTISELECT:
                        case FieldType.CHECKBOX:
                        case FieldType.DATE:
                        case FieldType.GROUP:
                        case FieldType.TIME:
                        case FieldType.RADIO:
                        case FieldType.PARTYTYPE:
                        case FieldType.PARTYPARSER:
                        case FieldType.ARRAY:
                        case FieldType.HIDDEN:
                            // ignore these fields
                            break;
                        default:
                            if (field.type === FieldType.NAMEUNPARSED) {
                                unparsedID = field.id;
                            }
                            if (
                                field.id.toLowerCase().lastIndexOf("name") > -1
                            ) {
                                unparsedValue += group.value[field.path]
                                    ? group.value[field.path] + " "
                                    : "";
                            }
                            break;
                    }
                }
            });

        return unparsedValue;
    }

    // this function is to allow the user to "quickly" hit the add button.
    // At times the button will not be enabled until a half second after data has been entered
    addItemClick() {
        if (this._addItemPending) {
            return;
        }
        if (this.lastIsBlank) {
            this._addItemPending = true;
            setTimeout(() => {
                this._addItemPending = false;
                if (!this.lastIsBlank) {
                    this._addItem();
                }
            }, 350);
        } else if (!this.lastIsBlank) {
            this._addItem();
        }
    }

    private _addItem() {
        if (
            this.currentLength < this.field.maxLength ||
            !this.field.maxLength
        ) {
            this._addBlankGroup();
        } else {
            this._setMax(true);
        }
    }

    toggleExpanded() {
        this._formFacade.setExpanded(this.field.fullPath, !this.expanded);

        if (this.expanded) {
            this._initialLoad = false;
            this._checkIfMaxIsReachOnLoad();
        } else {
            this._initialLoad = true;
        }
    }

    private _checkIfMaxIsReachOnLoad() {
        // IMPORTANT
        // Needs to be in a timeout to allow the form to load first
        setTimeout(() => {
            if (
                this.field.maxLength &&
                this.currentLength >= this.field.maxLength &&
                !this.lastIsBlank
            ) {
                this._setMax();
            }

            // because of the timeout, we need to force Angular to re-render
            this.cd.markForCheck();
        });
    }

    private _hasPartyNameField(): Observable<boolean> {
        return this.formControl$.pipe(take(1)).pipe(
            switchMap((control) => {
                let keys = Object.keys(
                    this.field.repeatableFieldState.fieldState
                );

                for (let fieldStateID of keys) {
                    let field =
                        this.field.repeatableFieldState.fieldState[
                            fieldStateID
                        ];
                    if (field.type === FieldType.PARTYPARSER) {
                        return of(true);
                    }
                }
                return of(false);
            })
        );
    }

    private _setMax(added?: boolean) {
        this.maxReached = true;
        this._formFacade
            .getFieldsForArray(this.field.fullPath)
            .pipe(take(1))
            .subscribe((fields: any[]) => {
                let field = fields[this.currentLength - 1];
                field.expandable = true;
                if (this.expandableSetSubjects[field.fullPath]) {
                    this.expandableSetSubjects[field.fullPath].next(
                        field.expandable
                    );
                }

                this._processAdd(added, this.currentLength - 1, null, () => {
                    setTimeout(() => {
                        this._formFacade.setMultipleExpanded([
                            {
                                path: field.fullPath,
                                expanded: true
                            }
                        ]);
                    });
                });
            });
    }

    private _allValuesEmpty(values: any): boolean {
        for (let key in values) {
            if (values[key] && values[key] !== "") {
                return false;
            }
        }
        return true;
    }

    private _addBlankGroup() {
        this._formFacade.addFieldToArray(this.field.fullPath, this.controlPath);
    }
}
