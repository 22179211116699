import { Injectable } from "@angular/core";
import { Socket2Service } from "@sf/common";
import { GrowlService } from "@sf/common";
import { EMPTY, Observable, throwError } from "rxjs";
import { isObjectEmpty } from "@sf/common";
import { BatchUploadResponse } from "@sf/erecord";
import { catchError, switchMap, take } from "rxjs/operators";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from "@angular/common/http";

interface ObservablesList {
    [key: string]: Observable<any>;
}

interface BatchResponse {
    documentAnalysisId?: string;
    message?: string;
    warning?: string;
    error?: string;
}

export enum BatchMessageSource {
    BRECKENRIDGE = "breckenridgeBatchMessage",
    CENTRAL_BANK = "centralBankBatchMessage"
}


@Injectable({
    providedIn: "root"
})
export class SubmitterBatchService {
    constructor(
        private _http: HttpClient,
        private _socket: Socket2Service,
        private _growlService: GrowlService
    ) {}

    private allObservables: ObservablesList = {};

    // used by AngularJS page sfSubmitterPackageListGrid.component.js
    getBreckenridgeBatch$(documentAnalysisID: string): Observable<any> {
        return this._getBatchResponses$(
            documentAnalysisID,
            BatchMessageSource.BRECKENRIDGE
        );
    }

    showBatchStartedGrowl() {
        this._growlService.info(
            "This process will take some time. You'll see a notification when it's finished.",
            "Batch Upload Started",
            { timeOut: 5000 }
        );
    }

    postBatch(
        formData: FormData,
        headers: HttpHeaders,
        source: BatchMessageSource
    ): Observable<any> {
        return this._http
            .post<BatchUploadResponse>(
                "/sf/submitter/page-analysis/uploadDocument",
                formData,
                {
                    headers,
                    observe: "response"
                }
            )
            .pipe(
                take(1),
                switchMap((response) => {
                    if (response.status !== 200) {
                        this._growlService.error(
                            "There was an error uploading the file"
                        );
                    } else if (response.body.documentAnalysisId) {
                        return this._getBatchResponses$(
                            response.body.documentAnalysisId,
                            source
                        );
                    }

                    return EMPTY;
                })
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 413) {
                        this._growlService.error(
                            "Exceeded maximum file upload size. Please reduce the file size."
                        );
                    } else {
                        this._growlService.error("Upload failed.");
                    }
                    return throwError(err);
                })
            );
    }

    private _getBatchResponses$(
        documentAnalysisID: string,
        source: BatchMessageSource
    ): Observable<any> {
        let observable = this._socket.on("erecord", source, {
            documentAnalysisID
        });
        observable.subscribe((response) => {
            this._showGrowls(response);
            this._closeBatch$(response.documentAnalysisId);
        });
        this._addToAllObservables(documentAnalysisID, observable);
        return observable;
    }

    _showGrowls(response: BatchResponse) {
        if (!!response.message) {
            this._growlService.success(
                response.message,
                "Batch Upload Finished",
                { timeOut: 0, extendedTimeOut: 2000, enableHtml: true }
            );
        }
        if (!!response.warning) {
            this._growlService.warning(
                response.warning,
                "Batch Upload Warning",
                { timeOut: 0, extendedTimeOut: 0, enableHtml: true }
            );
        }
        if (!!response.error) {
            this._growlService.warning(response.error, "Batch Upload Failed", {
                timeOut: 0,
                extendedTimeOut: 0,
                enableHtml: true
            });
        }
    }

    _addToAllObservables(
        documentAnalysisID: string,
        observable: Observable<any>
    ) {
        if (!this.allObservables[documentAnalysisID]) {
            this.allObservables[documentAnalysisID] = observable;
        }
    }

    _closeBatch$(documentAnalysisID: string) {
        delete this.allObservables[documentAnalysisID];
        if (isObjectEmpty(this.allObservables)) {
            this._socket.close();
        }
    }
}
