import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "./rpc-client.service";

export interface APIIntegration {
    apiKeyID: string;
    id: string;
    integrationType: string;
    organizationID: string;
    disabled?: boolean;
    secondaryIntegrationType?: string;
}

@Injectable({
    providedIn: "root"
})
export class ApiTokenService {
    constructor(protected _rpcClientService: RpcClientService) {}

    findApiSubscriptionsByOrganizationID(
        orgID: string
    ): Observable<APIIntegration[]> {
        return this._rpcClientService.makeRequest(
            "api",
            "findApiSubscriptionsByOrganizationID",
            { orgID: orgID }
        );
    }

    getOrgUserCountWithAPIDefinition(orgID: string, apiKeyID: string) {
        return this._rpcClientService.makeRequest(
            "api",
            "getOrgUserCountWithAPIDefinition",
            {
                orgID: orgID,
                apiKeyID: apiKeyID
            }
        );
    }

    getOrgUsersWithAPIDefinitionTokens(orgID: string, apiKeyID: string) {
        return this._rpcClientService.makeRequest(
            "api",
            "getOrgUsersWithAPIDefinitionTokens",
            {
                orgID: orgID,
                apiKeyID: apiKeyID
            }
        );
    }

    getNewAPITokenForUsers(
        apiKeyID: string,
        usernames: string[],
        orgID: string
    ) {
        return this._rpcClientService.makeRequest(
            "api",
            "getNewAPITokenForUsers",
            {
                apiKeyID: apiKeyID,
                usernames: usernames,
                orgID: orgID
            }
        );
    }

    getOrgUserCountWithApi(orgID: string, integrationTypes: string[]) {
        return this._rpcClientService.makeRequest(
            "api",
            "getOrgUserCountWithApi",
            {
                orgID: orgID,
                productIDs: integrationTypes
            }
        );
    }

    getApiDefinitionTokensForUser(orgIDs: string[], userID: string) {
        return this._rpcClientService.makeRequest(
            "api",
            "getApiDefinitionTokensForUser",
            {
                userID: userID,
                organizationIDs: orgIDs
            }
        );
    }

    getApiTokensForUser(orgIDs: string[], userID: string) {
        return this._rpcClientService.makeRequest(
            "api",
            "getApiTokensForUser",
            {
                orgIDs: orgIDs,
                userID: userID
            }
        );
    }

    cancelPendingTokenRequests(
        apiKeyID: string,
        emails: string[],
        organizationID: string
    ) {
        return this._rpcClientService.makeRequest(
            "api",
            "cancelPendingTokenRequests",
            {
                apiKeyID: apiKeyID,
                emails: emails,
                orgID: organizationID
            }
        );
    }

    deleteAPITokens(
        apiKeyID: string,
        userIDs: string[],
        organizationID: string
    ) {
        return this._rpcClientService.makeRequest("api", "deleteAPITokens", {
            apiKeyID: apiKeyID,
            usernames: userIDs,
            orgID: organizationID
        });
    }

    sendAPITokenRequestEmail(
        apiKeyID: string,
        userIDs: string[],
        organizationID: string,
        isReset: boolean
    ) {
        return this._rpcClientService.makeRequest(
            "api",
            "sendAPITokenRequestEmail",
            {
                apiKeyID: apiKeyID,
                usernames: userIDs,
                orgID: organizationID,
                isReset: isReset
            }
        );
    }
}
