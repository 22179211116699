import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class EncryptionService {
    private BYTE_SIZE = 8;
    private FIRST_ROTATE = 3;
    private SECOND_ROTATE = 2;

    constructor() {}

    scrambleEncode(someString: string): string {
        let mess = this.scramble(someString);
        let b64 = btoa(mess);
        // Note: trust that there is a reason we did this and didn't use any 'standard' URL encoding routines
        let result = b64
            .replace(/\+/g, "-")
            .replace(/\//g, ",")
            .replace(/=/g, "_");
        return result;
    }

    decodeUnscramble(userID: string): string {
        // Note: trust that there is a reason we did this and didn't use any 'standard' URL encoding routines
        let mess = userID
            .replace(/-/g, "+")
            .replace(/,/g, "/")
            .replace(/_/g, "=");
        let decoded = atob(mess);
        let result = this.unscramble(decoded);
        return result;
    }

    private scramble(unscrambled: string): string {
        let byteArray = unscrambled.split("");
        let iLastByte = byteArray.length - 1;
        let iMiddleByte = Math.floor((iLastByte + 1) / 2);
        for (let iByte = 0; iByte < iMiddleByte; iByte++) {
            let iSwapByte = iLastByte - iByte;
            let b1 = byteArray[iByte];
            let b2 = byteArray[iSwapByte];
            byteArray[iByte] = this.rotateLeft(b2, this.SECOND_ROTATE);
            byteArray[iSwapByte] = this.rotateRight(b1, this.FIRST_ROTATE);
        }
        return byteArray.join("");
    }

    private unscramble(scrambled: string): string {
        let byteArray = scrambled.split("");
        let iLastByte = byteArray.length - 1;
        let iMiddleByte = Math.floor((iLastByte + 1) / 2);
        for (let iByte = 0; iByte < iMiddleByte; iByte++) {
            let iSwapByte = iLastByte - iByte;
            let b1 = byteArray[iByte];
            let b2 = byteArray[iSwapByte];
            byteArray[iByte] = this.rotateLeft(b2, this.FIRST_ROTATE);
            byteArray[iSwapByte] = this.rotateRight(b1, this.SECOND_ROTATE);
        }
        return byteArray.join("");
    }

    private rotateLeft(b: string, iRotateAmount: number) {
        let howMuch = this.BYTE_SIZE - iRotateAmount;
        let num = b.charCodeAt(0);
        let anded = num & 0xff;
        let shift1 = (anded << iRotateAmount) & 0xff;
        let shift2 = anded >>> howMuch;
        let rotated = (shift1 + shift2) & 0xff;
        let result = String.fromCharCode(rotated);
        return result;
    }

    private rotateRight(b: string, iRotateAmount: number) {
        let howMuch = this.BYTE_SIZE - iRotateAmount;
        let num = b.charCodeAt(0);
        let anded = num & 0xff;
        let shift1 = anded >>> iRotateAmount;
        let shift2 = (anded << howMuch) & 0xff;
        let rotated = (shift1 + shift2) & 0xff;
        let result = String.fromCharCode(rotated);
        return result;
    }
}
