import { Component, ElementRef, Input } from "@angular/core";

@Component({
    selector: "sf-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent {
    @Input()
    title: string;
    @Input()
    type: string;
    @Input()
    noClose: boolean;

    /* Public Variables */

    constructor(private _elementRef: ElementRef) {}

    close($event: any) {
        this._elementRef.nativeElement.parentNode.removeChild(
            this._elementRef.nativeElement
        );
        $event.stopPropagation();
    }
}
