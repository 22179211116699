<div class="header" (contextmenu)="showContextMenu($event)">
    <sf-context-menu [x]="contextMenuX" [y]="contextMenuY" [shown]="contextMenuVisible"
        [options]="contextMenuItems" (select)="handleContextMenuItem($event)"></sf-context-menu>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <div class="d-flex flex-column flex-md-row">
                <div class="header-selector">
                    <div class="title" *ngIf="pageData.pageTitle">
                        <h1 style="display: inline-block">{{pageData.pageTitle}}</h1>
                    </div>
                    <div class="d-flex flex-column flex-md-row select-holder">
                        <sf-organization-search-selector
                            [hideBorder]="true"
                            [selectedOrgID]="selectedOrganization.id"
                            [selectableOrgServices]="selectableOrgServices"
                            [autoInitialSelect]="false"
                            [canClearSelectedOrg]="false"
                            [changedOrganizationName]="selectedOrganization.name"
                            (select)="setSelectedOrg($event)">
                        </sf-organization-search-selector>
                        <div *ngIf="selectedOrganization.id && selectedOrganization.status"
                            class="status-info sf-status-badge-container"
                            id="statusInfo">
                            <button class="btn btn-link badge badge-link {{selectedOrganization.statusClass}}"
                                (click)="statusClick(selectedOrganization)"
                                [ngbTooltip]="selectedOrganization.disabledReason ? selectedOrganization.disabledReason : selectedOrganization.statusTooltip"
                                placement="top">
                                {{selectedOrganization.status}}
                            </button>
                        </div>
                        <div *ngIf="selectedOrganization.id && selectedOrganization.name" class="loginAs" id="orgLoginAs">
                            <button class="btn btn-link" (click)="becomeUser()" aria-label="Log in to organization"
                                    ngbTooltip="Log in as a user in this organization" placement="right">
                                <fa-icon icon="sign-in-alt"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 save-changes align-self-center" *ngIf="showSaveChanges && !!selectedOrganization && selectedOrganization.id">
                    <button class="btn btn-primary save-changes" (click)="saveCurrentOrg()">Save Changes</button>
                </div>
            </div>
        </div>
    </div>
</div>
