import { Observable } from "rxjs";

export interface FilterPanelSortOption {
    id: string;
    value: string;
    title: string;
    label: string;
    direction: string;
}

export enum FilterPanelPositionStrategy {
    GLOBAL = "global",
    CONNECTED = "connected"
}

export interface FilterPanelProperties {
    blockClickAway?: boolean;
    currentSort?: FilterPanelSortOption;
    noFilterDescription?: string;
    panelClass?: string;
    tooltipClass?: string;
    sortOptions?: FilterPanelSortOption[];
    showBackdrop?: boolean;
    isValidated?: Observable<boolean>;
    positionStrategy?: FilterPanelPositionStrategy;
}
