<div
    class="overlay-content"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
    [cdkDragFreeDragPosition]="dragPosition"
    #drag="cdkDrag"
>
    <div class="header">
        <span>ICE Mortgage Technology</span>
        <button class="btn btn-link" (click)="toggleChatWindow()">
            <fa-icon [icon]="['far', (minimized ? 'square' : 'minus')]"></fa-icon>
        </button>
        <button class="btn btn-link" (click)="closeChat()">
            <fa-icon [icon]="['far', 'times']"></fa-icon>
        </button>
    </div>
    <iframe
        name="sfChatWindow"
        [ngClass]="{'minimized': minimized, 'maximized': minimized === false }"
        #chatWindow
    ></iframe>
</div>
