import { ConditionRule, FieldViewState } from "../interfaces";

export class ContainsRule implements ConditionRule {
    constructor(private _value: any) {}
    match(value: any, viewState?: FieldViewState): boolean {
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; ++i) {
                if (this._value === value[i]) return true;
            }
            return false;
        } else if (typeof value === "string") {
            return value.indexOf(this._value) != -1;
        } else {
            return this._value === value;
        }
    }
}
