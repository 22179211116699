import { GlobalConfig } from "ngx-toastr";

export const TOASTR_CONFIG: Partial<GlobalConfig> = {
    positionClass: "inline",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    resetTimeoutOnDuplicate: true,
    tapToDismiss: false
};
