import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { filter, shareReplay, switchMap } from "rxjs/operators";
import { SignEventNotificationService } from "../services/sign-event-notification.service";

@Injectable({
    providedIn: "root"
})
export class TaggingSharedPermissionService {
    canModifyDocuments$: Observable<boolean>;
    canTagDocuments$: Observable<boolean>;

    constructor(private _notificationService: SignEventNotificationService) {
        this.canModifyDocuments$ = this.hasPermission(
            "esign_event_modify_document"
        );
        this.canTagDocuments$ = this.hasPermission("esign_event_tag_document");
    }

    hasPermission(_permission: string): Observable<boolean> {
        return this._notificationService.eventPermissions$.pipe(
            filter((permissions) => !!permissions),
            switchMap((permissions: any[]) =>
                of(permissions.includes(_permission))
            ),
            shareReplay(1)
        );
    }
}
