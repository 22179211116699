import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanDeactivate,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { DocumentBuilderGuideService } from "../services/document-builder-guide.service";
import { SigningSetupService } from "../services/signing-setup.service";

@Injectable({
    providedIn: "root"
})
export class SigningSetupGuard implements CanActivate, CanDeactivate<any> {
    constructor(
        private documentBuilderGuideService: DocumentBuilderGuideService,
        private signingSetupService: SigningSetupService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        setTimeout(() => {
            this.signingSetupService.checkSigningSetupWithBanners();
            this.documentBuilderGuideService.showDocumentBuilderGuide();
            this.signingSetupService.checkLegalEntitySetup();
            this.signingSetupService.checkPendingServicers();
        }, 1500);
        return true;
    }

    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.signingSetupService.checkSigningSetupWithBanners();
        return true;
    }
}
