import { Component, OnInit } from "@angular/core";

@Component({
    selector: "sf-self-signup",
    templateUrl: "./self-signup.component.html",
    styleUrls: ["./self-signup.component.scss"]
})
export class SelfSignupComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
