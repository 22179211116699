import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    DocTypeDataObject,
    DocTypeFrequencyObject
} from "../interfaces/doc-type.interface";
import { RecipientInformation } from "../interfaces/recipient-information.interface";
import { AddCountyResponse } from "../interfaces/add-county-response.interface";
import { EstimatedRecordingTime } from "../interfaces/estimated-recording-time";
import { OpenForSubmissionRecipient } from "../interfaces/open-for-submission-recipient";
import { RpcClientService } from "@sf/common";
import { RegisteredRecipient } from "../interfaces/registered-recipient.interface";
import { GetCountyRegistrationSettingsResponse } from "../interfaces/countyRegistrationSettings.interface";
import { StateWithCounties } from "../interfaces/countyData.interface";
import { County } from "../interfaces/county.interface";
import { RecipientPackageQueue } from "../interfaces/recipient-package-queue.interface";
import { RecipientQueuePackage } from "../interfaces/recipient-queue-package.interface";
import { UiRecipient } from "../interfaces/ui-recipient";
import { UiDocumentTemplate } from "../interfaces/ui-document-template";
import { UiTownship } from "../interfaces/ui-township";
import { RecipientWorkflow } from "../interfaces/recipient-workflow.interface";

@Injectable({
    providedIn: "root"
})
export class SubmitterRecipientService {
    private ERECORD = "erecord";
    private USERORG = "userorg";
    private SEARCH = "search";

    constructor(private _rpcClient: RpcClientService) {}

    getSubmitterRecipients(
        submitterID: string,
        state: string,
        bEnabledOnly: boolean
    ): Observable<County[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterRecipients",
            {
                submitterID: submitterID,
                state: state,
                bEnabledOnly: bEnabledOnly
            }
        );
    }

    getRecipientKOIs(recipientID: string): any {
        return this._rpcClient.makeRequest(this.ERECORD, "getRecipientKOIs", {
            recipientID: recipientID
        });
    }

    getRecipientKOIsFiltered(
        recipientID: string,
        submitterID: string
    ): Observable<DocTypeDataObject[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientKOIsFiltered",
            {
                recipientID: recipientID,
                submitterID: submitterID
            }
        );
    }

    getRecipientKOIHelpers(
        recipientID: string,
        koi: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientKOIHelpers",
            {
                recipientID: recipientID,
                sKindOfInstrument: koi
            }
        );
    }

    // unique for each recipient-submitter combination
    getSubmitterMostUsedKOIs(
        recipientID: string,
        submitterID: string
    ): Observable<DocTypeFrequencyObject> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterMostUsedKOIs",
            {
                recipientID: recipientID,
                submitterID: submitterID
            }
        );
    }

    getRecipientTownships(recipientID: string): Observable<UiTownship[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientTownships",
            {
                recipientID: recipientID
            }
        );
    }

    getRecipientWorkflow(packageID: string): Observable<RecipientWorkflow[]> {
        return this._rpcClient.makeRequest<RecipientWorkflow[]>(
            this.ERECORD,
            "getRecipientWorkflow",
            {
                packageID: packageID
            }
        );
    }

    getUnassociatedCounties(orgID: string): Observable<County[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getUnassociatedCounties",
            {
                orgID: orgID
            }
        );
    }

    //  public JSONObject getRecipientCoffi(OrganizationID recipientID) {
    getRecipientCoffi(recipientId: string): Observable<Object> {
        return this._rpcClient.makeRequest(this.USERORG, "getRecipientCoffi", {
            recipientID: recipientId
        });
    }

    getUnassociatedCounty(
        orgID: string,
        recipientID: string
    ): Observable<County> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getUnassociatedCounty",
            {
                orgID: orgID,
                recipientID: recipientID
            }
        );
    }

    removeCounties(orgID: string, countyIDs: string[]): Observable<string[]> {
        return this._rpcClient.makeRequest(this.USERORG, "removeCounties", {
            submitterID: orgID,
            recipientIDs: countyIDs
        });
    }

    addCounty(
        submitterID: string,
        recipientID: string,
        params?: { [key: string]: any },
        overrideDefaultErrorHandling?: boolean
    ): Observable<AddCountyResponse> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "addCounty",
            {
                submitterID: submitterID,
                recipientID: recipientID,
                params: params
            },
            overrideDefaultErrorHandling ? overrideDefaultErrorHandling : false
        );
    }

    addAllCounties(
        submitterID: string,
        state: string
    ): Observable<AddCountyResponse[]> {
        return this._rpcClient.makeRequest(this.USERORG, "addAllCounties", {
            submitterID: submitterID,
            state: state
        });
    }

    setPrintScale(
        orgID: string,
        recipientIDs: string[],
        value: number
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.USERORG, "setPrintScale", {
            orgID: orgID,
            countyIDs: recipientIDs,
            value: value
        });
    }

    setAllPrintScale(orgID: string, value: number): Observable<void> {
        return this._rpcClient.makeRequest(this.USERORG, "setAllPrintScale", {
            orgID: orgID,
            value: value
        });
    }

    getSubmitterPrintScale(orgID: string): Observable<number> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmitterPrintScale",
            {
                orgID: orgID
            }
        );
    }

    setSubmitterPrintScale(orgID: string, printScale: any): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "setSubmitterPrintScale",
            {
                orgID: orgID,
                printScale: printScale
            }
        );
    }

    getEstimatedRecordingTimes(
        recipientID: string
    ): Observable<EstimatedRecordingTime> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getEstimatedRecordingTimes",
            {
                recipientID: recipientID
            }
        );
    }

    getRegisteredRecipientsForOrgs(
        orgIDs: string[]
    ): Observable<RegisteredRecipient[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegisteredRecipientsForOrgs",
            {
                orgIDs: orgIDs
            }
        );
    }

    getRegisteredRecipients(orgID: string): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegisteredRecipients",
            {
                orgID: orgID
            }
        );
    }

    getRegisteredAndEnabledRecipients(orgID: string): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegisteredAndEnabledRecipients",
            {
                orgID: orgID
            }
        );
    }

    getRegisteredRecipientsByState(orgID: string, state: any): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegisteredRecipients",
            {
                orgID: orgID,
                state: state
            }
        );
    }

    getRegisteredRecipientsCountByState(
        orgID: string
    ): Observable<StateWithCounties[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegisteredRecipientsCountByState",
            {
                orgID: orgID
            }
        );
    }

    getRecipientConfiguration(id: string): Observable<{ [key: string]: any }> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientConfiguration",
            {
                recipientID: id
            }
        );
    }

    getRecipientCustomProperties(id: string): any {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientCustomProperties",
            {
                id: id
            }
        );
    }

    isOpen(recipientIDs: string[]): Observable<OpenForSubmissionRecipient[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "isOpenForSubmission",
            {
                recipientIDs: recipientIDs
            }
        );
    }

    getRegistrationSettingDefinitions(
        recipientID: string,
        submitterID: string
    ): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRegistrationSettingDefinitions",
            {
                recipientID: recipientID,
                submitterID: submitterID
            }
        );
    }

    getAvailableDocumentTemplates(
        submitterID: string,
        recipientID: string,
        projectID: string
    ): Observable<any[]> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getAvailableDocumentTemplates",
            {
                submitterID: submitterID,
                recipientID: recipientID,
                projectID: projectID
            }
        );
    }

    getSF2Templates(
        submitterID: string,
        recipientID: string,
        projectID: string
    ): Observable<UiDocumentTemplate[]> {
        return this._rpcClient.makeRequest(this.ERECORD, "getSF2Templates", {
            submitterID: submitterID,
            recipientID: recipientID,
            projectID: projectID
        });
    }

    getRecipientSettings(submitterID: string, recipientID: string): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getRecipientSettings",
            {
                orgID: submitterID,
                recipientID: recipientID
            }
        );
    }

    getSubmittersWithRecipient(submitterID: string, recipientID: string): any {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getSubmittersWithRecipient",
            {
                orgID: submitterID,
                recipientID: recipientID
            }
        );
    }

    getRecipientCustomStrings(recipientID: string): any {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientCustomStrings",
            {
                recipientID: recipientID
            }
        );
    }

    getRecipientInformation(
        recipientID: string
    ): Observable<RecipientInformation> {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientInformation",
            {
                recipientID: recipientID
            }
        );
    }

    getRecipientQueueConfiguration(recipientID: string): any {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientQueueConfiguration",
            {
                recipientID: recipientID
            }
        );
    }

    getRecipientPackageQueue(
        recipientId: string,
        userId: string,
        filterBy?: string
    ): Observable<{ packages: RecipientPackageQueue[] }> {
        let data: any = {
            recipientID: recipientId,
            userId: userId
        };

        if (data) data["filterBy"] = filterBy;

        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientPackageQueue",
            data
        );
    }

    recipientQueuePackageSearch(
        recipientId: string,
        userId: string,
        filterBy?: string
    ): Observable<RecipientQueuePackage[]> {
        let data = filterBy
            ? { recipientId, userId, filterBy }
            : { recipientId, userId };
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "recipientQueuePackageSearch",
            data
        );
    }

    getRecipientRecordedDocuments(recipientID: string): any {
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecipientRecordedDocuments",
            {
                recipientID: recipientID
            }
        );
    }

    searchAssociatedAndOneClickCounties(
        orgID: string,
        query: string,
        limit: number,
        includeDisabled: boolean
    ): any {
        return this._rpcClient.makeRequest(
            this.SEARCH,
            "searchAssociatedAndOneClickCounties",
            {
                orgID: orgID,
                query: query,
                limit: limit,
                includeDisabled: includeDisabled
            }
        );
    }

    getRecentRecipients(
        submitterID: string,
        limit: number
    ): Observable<UiRecipient[]> {
        if (!limit) {
            limit = 10;
        }
        return this._rpcClient.makeRequest(
            this.ERECORD,
            "getRecentRecipients",
            {
                submitterID: submitterID,
                limit: limit
            }
        );
    }

    getAllAvailableCounties(submitterID: string): Observable<UiRecipient[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getAllAvailableCountiesBySubmitter",
            {
                submitterID: submitterID
            }
        );
    }

    getAllAvailableCountiesBySubmitters(
        submitterIDs: string[]
    ): Observable<UiRecipient[]> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getAllAvailableCountiesBySubmitters",
            {
                submitterIDs: submitterIDs
            }
        );
    }

    getCountyRegistrationSettings(
        submitterID: string,
        recipientID: string,
        params?: any
    ): Observable<GetCountyRegistrationSettingsResponse> {
        return this._rpcClient.makeRequest(
            this.USERORG,
            "getCountyRegistrationSettings",
            {
                submitterID: submitterID,
                recipientID: recipientID,
                params: params || {}
            }
        );
    }

    titleCompanyCheck7AM(
        recipientID: string,
        erds: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "titleCompanyCheck7AM", {
            recipientID,
            erds
        });
    }

    getSubmitterRecipientRegistration(
        submitterID: string,
        recipientID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getSubmitterRecipientRegistration",
            {
                submitterID,
                recipientID
            }
        );
    }

    getOrganizationAdminUsers(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getOrganizationAdminUsers",
            {
                orgID
            }
        );
    }

    sendElectronicSignatureNeededEmail(
        submitterID: string,
        recipientID: string,
        userID: string,
        emailAddress: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "sendElectronicSignatureNeededEmail",
            {
                submitterID,
                recipientID,
                userID,
                emailAddress
            }
        );
    }

    createMOUPackage(
        submitterID: string,
        recipientID: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "createMOUPackage", {
            submitterID,
            recipientID
        });
    }

    getMOUPackage(submitterID: string, recipientID: string): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "getMOUPackage", {
            submitterID,
            recipientID
        });
    }

    downloadMOU(
        submitterID: string,
        recipientID: string,
        mouFileType: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("erecord", "downloadMOU", {
            submitterID,
            recipientID,
            mouFileType
        });
    }
}
