import { Pipe, PipeTransform } from "@angular/core";
import { STATUS, SUB_STATUS } from "../enums/package-status.enum";
import { DOC_STATUS } from "../enums/document-status.enum";
import { SubmitterPackage } from "../interfaces/submitter-package.interface";

const filters: any = {
    reviewing: [{ status: DOC_STATUS.REVIEWING }],
    received: [{ status: STATUS.RECEIVED }],
    "data entry": [
        { statusString: DOC_STATUS.DATA_ENTRY },
        { state: DOC_STATUS.DATA_ENTRY },
        { packageState: DOC_STATUS.DATA_ENTRY }
    ],
    review: [
        { statusString: DOC_STATUS.DATA_REVIEW },
        { state: DOC_STATUS.DATA_REVIEW },
        { packageState: DOC_STATUS.DATA_REVIEW }
    ],
    sign: [
        { statusString: DOC_STATUS.SIGN },
        { state: DOC_STATUS.SIGN },
        { packageState: DOC_STATUS.SIGN }
    ],
    "needs poa": [
        { statusString: DOC_STATUS.NEEDS_POA },
        { state: DOC_STATUS.NEEDS_POA },
        { packageState: DOC_STATUS.NEEDS_POA }
    ],
    rejected: [
        { statusString: "Draft (Rejected)" },
        { status: STATUS.REJECTED }
    ],
    "billed rejected": [{ statusString: STATUS.BILLED_REJECTED }],
    draft: [{ status: STATUS.DRAFT }],
    ready: [{ state: STATUS.READY }, { packageState: STATUS.READY }],
    reviewed: [
        { state: DOC_STATUS.REVIEWED },
        { packageState: DOC_STATUS.REVIEWED }
    ],
    accepted: [{ statusString: STATUS.ACCEPTED }],
    recording: [{ statusString: DOC_STATUS.RECORDING }],
    recorded: [{ state: STATUS.RECORDED }, { packageState: STATUS.RECORDED }],
    finalizing: [
        { state: SUB_STATUS.FINALIZING },
        { packageState: SUB_STATUS.FINALIZING }
    ],
    maintenance: [
        { state: SUB_STATUS.MAINTENANCE },
        { packageState: SUB_STATUS.MAINTENANCE }
    ],
    voided: [{ state: STATUS.VOIDED }, { packageState: STATUS.VOIDED }],
    unknown: [
        { state: DOC_STATUS.UNKNOWN },
        { packageState: DOC_STATUS.UNKNOWN }
    ],
    removed: [{ state: STATUS.REMOVED }, { packageState: STATUS.REMOVED }],
    canceled: [
        { state: DOC_STATUS.CANCELED },
        { packageState: DOC_STATUS.CANCELED }
    ],
    initialize: [
        { state: DOC_STATUS.INITIALIZE },
        { packageState: DOC_STATUS.INITIALIZE }
    ],
    sent: [
        { state: STATUS.SENT },
        { packageState: STATUS.SENT },
        { state: STATUS.SENT_REMOTE },
        { packageState: STATUS.SENT_REMOTE }
    ]
};

@Pipe({
    name: "sfStatus"
})
export class SubmitterStatusPipe implements PipeTransform {
    transform(pkg: SubmitterPackage): string {
        // make sure package isn't null or undefined
        if (pkg) {
            let status: string;
            for (status in filters) {
                if (filters.hasOwnProperty(status)) {
                    let statusArr: any[] = filters[status],
                        sIdx = 0,
                        sLen = statusArr.length;

                    for (sIdx; sIdx < sLen; sIdx++) {
                        let prop: any = statusArr[sIdx],
                            key: string;

                        for (key in prop) {
                            if (
                                prop.hasOwnProperty(key) &&
                                pkg[key] &&
                                pkg[key] === prop[key]
                            ) {
                                return status;
                            }
                        }
                    }
                }
            }
        }
        return null;
    }
}
