import {
    CreditCardType,
    PaymentAccountType
} from "../enums/payment-account-type.enum";
import { Product } from "../enums/products";

// THIS NEEDS TO MATCH UI
export interface PaymentAccount {
    active: boolean;
    label: string;
    organizationID: string;
    paymentAccountID: string;
    paymentAccountType: PaymentAccountType;

    //these are used to generate view data for each account
    viewLabel?: string;
    viewType?: string;
}

export interface BankAccount extends PaymentAccount {
    accountLabel: string;
    accountNumber: string;
    accountType: PaymentAccountType;
    bankAccountID: string;
    bankName: string;
    expiration?: any; //LegalEase only
    isActive: boolean;
    nameOnBankAccount: string;
    routingNumber: string;
    signatory: {
        companyName: string;
        name: string;
        phoneNumber: string;
        title: string;
    };

    // accountLabel: string;
    // accountName?: string;
    // accountNumber: string;
    // accountType: PaymentAccountType;
    // bankAccountID: string;
    // bankName: string;
    // expiration?: any;
    // isActive: boolean;
    // isEnabled: boolean;
    // isRejected: boolean;
    // nameOnBankAccount: string;
    // organizationID: string;
    // routingNumber: string;
    // signatory?: any;
    //
    // voidedCheckID?: string;
}

export interface CreditCardAccount extends PaymentAccount {
    brand: string;
    cardType: CreditCardType;
    expMonth: number;
    expYear: number;
    nameOnCard: string;
    token: string;
    fingerprint?: string;
    zipCode?: string;
    invalid?: boolean; //display thing
    group?: string; //display thing
}

export interface InvoiceAccount extends PaymentAccount {
    label: string;
}

export enum CreditCardStatus {
    GOOD,
    EXPIRING,
    EXPIRED
}

export interface ProductsMissingDefaultLicenseAccount {
    organizationID: string;
    products: Product[];
}
