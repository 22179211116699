import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { OpenDocumentService } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubmitterPackage } from "../../interfaces/submitter-package.interface";
import { PackageReceiptData } from "../../interfaces/receipt-data.interface";
import { SubmitterStatusPipe } from "../../pipes/submitter-status.pipe";
import { SubmitterPackagesService } from "../../services/submitter-packages.service";

@Component({
    selector: "sf-submitter-receipt-data-dialog",
    templateUrl: "./submitter-receipt-data-dialog.component.html",
    styleUrls: ["./submitter-receipt-data-dialog.component.scss"],
    host: { "[class.sf-unmask]": "true" }
})
export class SubmitterReceiptDataDialogComponent implements OnInit {
    @Input()
    pkg: SubmitterPackage;
    @Input()
    preventDetailLink: boolean = false;

    title: string;
    primaryButton: ModalButton;
    printButton: ModalButton;
    loading: boolean = false;
    statusDescription: string = "";
    receiptData: PackageReceiptData;
    documentLinks: Map<string, string> = new Map<string, string>();

    private readonly _dialogTitleLookup: { [key: string]: string } = {
        recorded: "Package Recorded",
        finalizing: "Package Finalizing"
    };
    private readonly _statusDescriptionLookup: { [key: string]: string } = {
        recorded: " has been recorded and returned to Simplifile.",
        finalizing:
            " has been recorded but not returned to Simplifile yet. This " +
            "may happen because the stamps have not been applied to the documents " +
            "by the recipient yet or because the fees have not been calculated. " +
            "Typically packages will only stay in this state for 24 to 48 hours."
    };

    constructor(
        private _statusPipe: SubmitterStatusPipe,
        private _packagesService: SubmitterPackagesService,
        private _openDocumentService: OpenDocumentService,
        private _modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.title = "Package Receipt Data";
        this.primaryButton = {
            text: "OK"
        };
        this.printButton = {
            text: "Print",
            callback(): boolean {
                SubmitterReceiptDataDialogComponent._print();
                return true; // close dialog
            }
        };

        const status = this._statusPipe.transform(this.pkg);
        if (status) {
            const title = this._dialogTitleLookup[status.toLowerCase()];
            if (!title) {
                console.error(
                    `Encountered unexpected package status '${status}'. Acceptable statuses are ['${Object.keys(
                        this._dialogTitleLookup
                    ).join("', '")}']`
                );
                this.title = "Package Receipt Data";
            } else {
                this.title = title;
                this.statusDescription =
                    this._statusDescriptionLookup[status.toLowerCase()];
            }
        } else {
            console.error("Failed to calculate package status.");
        }

        this.loading = true;
        this._packagesService.getPackageReceiptData(this.pkg.id).subscribe(
            (receiptData: PackageReceiptData) => {
                this.receiptData = receiptData;
                for (const doc of receiptData.documents) {
                    const link = this.getDocumentLink(doc.id);
                    this.documentLinks.set(doc.id, link);
                }
            },
            () => {
                this.loading = false;
            },
            () => {
                this.loading = false;
            }
        );
    }

    getDocumentLink(id: string): string {
        return this._openDocumentService.getDocumentLink(id);
    }

    shouldShowFee(feeAmount: string) {
        return !!feeAmount && parseFloat(feeAmount) > 0;
    }

    private static _print() {
        const printEl = document.createElement("div");

        const css =
            '* {font-family: "Verdana", sans-serif; font-size: 15px;} .section { margin-top: 15px;}';
        const style = document.createElement("style");
        style.appendChild(document.createTextNode(css));
        printEl.appendChild(style);

        const dialogContents = document.getElementById("receipt-data-dialog");
        printEl.appendChild(dialogContents.cloneNode(true));

        printEl.querySelectorAll("a").forEach((anchor) => {
            anchor.removeAttribute("href");
        });

        const newWindow = window.open("", "_blank");
        newWindow.document.write(printEl.innerHTML);
        newWindow.document.close();
        newWindow.print();
    }

    closeModal(): void {
        this._modalService.dismissAll();
    }
}
