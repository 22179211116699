import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DocumentRefService {
    constructor() {}

    get nativeDocument(): Document {
        return document;
    }
}
