import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";

@Component({
    selector: "sf-growls",
    templateUrl: "./growls.component.html",
    styleUrls: ["./growls.component.scss"]
})
export class GrowlsComponent implements OnInit {
    @ViewChild(ToastContainerDirective, { static: true })
    toastContainer: ToastContainerDirective;

    constructor(private _toasterService: ToastrService) {}

    ngOnInit(): void {
        this._toasterService.overlayContainer = this.toastContainer;
    }
}
