import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: "root"
})
export class PageNotFoundGuard implements CanActivate {
    constructor(
        private _router: Router,
        @Inject(DOCUMENT) private _document: Document
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): UrlTree {
        return this._router.createUrlTree([
            "not-found",
            `${this._document.location.origin}/sf/ui${state.url}`
        ]);
    }
}
