import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { CustomForm } from "../../interfaces/custom-form.interface";
import { UserType } from "../../interfaces/user-type.enum";
import { CustomFormFieldTransformerNewService } from "../../services/custom-form-field-transformer-new.service";
import {
    ArrayField,
    DynamicFormGroup,
    RootField
} from "@sf/dynamic-form-viewer";
import { switchMap, take } from "rxjs/operators";
import { CustomFormService } from "../../custom-form.service";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "sf-minimal-custom-form-viewer",
    templateUrl: "./minimal-custom-form-viewer.component.html",
    styleUrls: ["./minimal-custom-form-viewer.component.scss"]
})
export class MinimalCustomFormViewerComponent implements OnInit {
    @Input()
    orgID: string;
    @Input()
    documentID: string;
    @Input()
    customFormID: string;
    @Input()
    userType: UserType;
    @Output() formValidity = new EventEmitter();

    customForm: CustomForm = null;
    dynamicForm: DynamicFormGroup = null;
    definition: RootField;
    transforming: boolean = false;
    documentData: any = {
        id: "",
        packageData: {
            docLeftEditable: true
        }
    };
    formGroup: FormGroup;

    constructor(
        private _cfTransformer: CustomFormFieldTransformerNewService,
        private _customFormService: CustomFormService
    ) {}

    ngOnInit(): void {
        this.documentData.id = this.documentID;
        this._customFormService
            .getSingleCustomForm(this.customFormID)
            .pipe(
                switchMap((form: string) => {
                    this.customForm = JSON.parse(form);
                    this.transforming = true;
                    return this._cfTransformer.transform$(
                        this.customForm,
                        this.orgID,
                        this.documentData, //details,
                        null //this._documentState
                    );
                }),
                take(1)
            )
            .subscribe((newDynamicForm: DynamicFormGroup) => {
                this.transforming = false;
                this.dynamicForm = newDynamicForm;
                this.definition = {
                    fields: (this.dynamicForm.dynamicField as ArrayField)
                        .fields,
                    initialValue: null
                };
            });
    }

    setCurrentGroup(event: FormGroup) {
        this.formGroup = event;
        this.formGroup.setValue(this.formGroup.value);
    }

    visibleValues(event: any) {
        let formValidity = true;
        for (const visibleValue in event) {
            if (this.formGroup.controls[visibleValue].valid === false) {
                formValidity = false;
                break;
            }
        }
        this.formValidity.emit(formValidity);
    }

    onFormSaved(event: any) {}
}
