import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertModalComponent } from "@sf/common";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private _hasCurrentError = false;

    _modalService: NgbModal;

    constructor(private injector: Injector) {
        super();
    }

    handleError(error: any): void {
        if (this._testAndHandleChunkLoadError(error)) {
            return;
        }

        super.handleError(error);
    }

    private _testAndHandleChunkLoadError(error: any) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (!this._hasCurrentError && chunkFailedMessage.test(error.message)) {
            this._hasCurrentError = true;
            this._modalService = this.injector.get(NgbModal);
            const modal = this._modalService.open(AlertModalComponent);
            const modalInstance = modal.componentInstance;

            modalInstance.title = "We're getting better all the time";
            modalInstance.message = `We just deployed a few tweaks, but you'll need to refresh your browser to get the latest and greatest. Click "Reload" and we'll take care of it for you.`;
            modalInstance.primary = {
                text: "Reload"
            };

            modal.result.then(() => {
                this._hasCurrentError = false;
                window.location.reload();
            });

            return true;
        }
        return false;
    }
}
