import { Injectable } from "@angular/core";
import { isString } from "../../helpers/string";
import { difference, union, uniq } from "../../helpers/array";
import { isEmpty } from "../../helpers/object";
import { isNumber } from "../../helpers/number";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Injectable()
export class SfSelectReducerService {
    reducer = sfSelectReducer;

    constructor() {}
}

export interface SfSelectState {
    items: any[];
    selectedItems: (string | number)[];
    activeItem: string;
    groupIdKey: string;
    idKey: string;
    labelKey: string;
    titleKey: string;
    disabledKey: string;
    groupTitleKey: string;
    bindAsHtml: boolean;
    bottomMetadataKey: string;
    rightMetadataKey: string;
    rightMetadataIsIcon: boolean;
    isOpen: boolean;
    isSingleSelect: boolean;
    additionalPropertiesToFilter: string[];
    showOnlySelected: boolean;
    hasFilter: boolean;
    filterIcon: IconProp;
    hasSelectAll: boolean;
    handleFilterExternally: boolean;
    hasFooter: boolean;
    isRequired: boolean;
    isDirty: boolean;
    isDisabled: boolean;
    isReadonly: boolean;
    showSelectedToggle: boolean;
    additionalFilterProperties: string[];
    showIconInDisplay: boolean;
    maxPopupHeight: number;
    showRecentSelections: boolean;
    recentlySelectedItems: any[];
    showMainListWithRecents: boolean;
    itemFilter: string;
    limitDropDownItems: number;
}

export interface SfSelectAction {
    type: string;
    payload?: any;
}

const defaultState: SfSelectState = {
    items: [],
    selectedItems: [],
    activeItem: "",
    groupIdKey: undefined,
    idKey: "id",
    labelKey: "label",
    titleKey: undefined,
    disabledKey: undefined,
    groupTitleKey: undefined,
    bindAsHtml: false,
    bottomMetadataKey: undefined,
    rightMetadataKey: undefined,
    rightMetadataIsIcon: false,
    isOpen: false,
    isSingleSelect: false,
    additionalPropertiesToFilter: [],
    showOnlySelected: false,
    hasFilter: true,
    filterIcon: ["far", "filter"] as IconProp,
    hasSelectAll: true,
    handleFilterExternally: false,
    hasFooter: false,
    itemFilter: "",
    isRequired: false,
    isDirty: false,
    isDisabled: false,
    isReadonly: false,
    showSelectedToggle: false,
    additionalFilterProperties: [],
    showIconInDisplay: false,
    maxPopupHeight: 240,
    showRecentSelections: false,
    recentlySelectedItems: [],
    showMainListWithRecents: false,
    limitDropDownItems: undefined
};

function sfSelectReducer(
    state: SfSelectState = defaultState,
    action: SfSelectAction
): SfSelectState {
    if (!state.items) {
        state = { ...state, ...{ items: [] } };
    }
    if (!state.selectedItems) {
        state = { ...state, ...{ selectedItems: [] } };
    }
    if (!state.labelKey) {
        state = { ...state, ...{ labelKey: "label" } };
    }
    if (!state.idKey) {
        state = { ...state, ...{ idKey: "id" } };
    }

    switch (action.type) {
        case "SET_ITEMS":
            let items = action.payload || [];
            let clonedItems = items.map((item: any) => {
                return { ...item };
            });
            return { ...state, ...{ items: clonedItems } };

        case "SET_SELECTED_ITEMS": {
            let selectedItems = [];
            if (isString(action.payload)) {
                selectedItems = [action.payload];
            }
            if (Array.isArray(action.payload)) {
                selectedItems = action.payload;
            }
            if (isNumber(action.payload)) {
                selectedItems = [action.payload];
            }
            if (state.isSingleSelect && selectedItems.length > 1) {
                selectedItems = [selectedItems[0]];
            }
            return { ...state, ...{ selectedItems: selectedItems } };
        }

        case "TOGGLE_ITEM_SELECTION": {
            let newSelectedItems;
            if (
                state.selectedItems.includes(action.payload) &&
                !state.isSingleSelect
            ) {
                newSelectedItems = state.selectedItems.filter(
                    (item) => item !== action.payload
                );
                return { ...state, ...{ selectedItems: newSelectedItems } };
            }
            if (state.isSingleSelect) {
                return {
                    ...state,
                    ...{
                        selectedItems: [action.payload],
                        activeItem: action.payload
                    }
                };
            }
            newSelectedItems = [...state.selectedItems, action.payload];
            return {
                ...state,
                ...{
                    selectedItems: newSelectedItems,
                    activeItem: action.payload
                }
            };
        }

        case "TOGGLE_ACTIVE_ITEM_SELECTION": {
            let newSelectedItems;
            let respectGroups = !!state.groupIdKey;
            let groupIds = uniq(
                state.items.map((item) => item[state.groupIdKey])
            );
            let activeItem = state.activeItem;

            if (!activeItem) {
                return state;
            }
            if (activeItem === "msSelectAllItem" && state.isSingleSelect) {
                return state;
            }
            if (activeItem === "msSelectAllItem" && !state.isSingleSelect) {
                return _toggleFilteredItemSelection(state);
            }
            if (groupIds.includes(activeItem) && state.isSingleSelect) {
                return state;
            }
            if (groupIds.includes(activeItem) && !state.isSingleSelect) {
                return _toggleGroupSelection(state, activeItem);
            }
            if (
                state.selectedItems.includes(activeItem) &&
                !state.isSingleSelect
            ) {
                newSelectedItems = state.selectedItems.filter(
                    (item) => item !== activeItem
                );
                return { ...state, ...{ selectedItems: newSelectedItems } };
            }
            if (state.isSingleSelect) {
                return { ...state, ...{ selectedItems: [activeItem] } };
            }
            newSelectedItems = [...state.selectedItems, activeItem];
            return { ...state, ...{ selectedItems: newSelectedItems } };
        }

        case "TOGGLE_GROUP_SELECTION": {
            if (state.isSingleSelect) {
                return state;
            }
            return _toggleGroupSelection(state, action.payload);
        }

        case "TOGGLE_ALL_ITEM_SELECTION": {
            let allItemsSelected: boolean,
                enabledItemIds: any[],
                selectedDisabledItemIds: any[],
                newSelectedItems: any[];
            if (state.disabledKey) {
                enabledItemIds = state.items
                    .filter(function (i: any) {
                        return !i[state.disabledKey];
                    })
                    .map((item) => item[state.idKey]);
                allItemsSelected =
                    difference(enabledItemIds, state.selectedItems).length ===
                    0;
                if (allItemsSelected) {
                    selectedDisabledItemIds = state.items
                        .filter((i: any) => i[state.disabledKey] === true)
                        .filter((i: any) => {
                            return state.selectedItems.includes(i[state.idKey]);
                        })
                        .map((item) => item[state.idKey]);
                    return {
                        ...state,
                        ...{
                            selectedItems: selectedDisabledItemIds
                        }
                    };
                }
                return {
                    ...state,
                    ...{
                        selectedItems: union(
                            state.selectedItems,
                            enabledItemIds
                        )
                    }
                };
            }
            allItemsSelected =
                difference(
                    state.items.map((item) => item[state.idKey]),
                    state.selectedItems
                ).length === 0;
            if (allItemsSelected) {
                newSelectedItems = [];
                return { ...state, ...{ selectedItems: newSelectedItems } };
            }
            newSelectedItems = state.items.map((item) => item[state.idKey]);
            return { ...state, ...{ selectedItems: newSelectedItems } };
        }

        case "TOGGLE_FILTERED_ITEM_SELECTION": {
            return _toggleFilteredItemSelection(state);
        }

        case "CLEAR_SELECTION": {
            return {
                ...state,
                ...{
                    activeItem: undefined,
                    selectedItems: []
                }
            };
        }

        case "SET_ACTIVE_ITEM": {
            return { ...state, ...{ activeItem: action.payload } };
        }

        case "SET_ITEM_FILTER": {
            if (
                state.isSingleSelect &&
                !isEmpty(action.payload) &&
                !state.handleFilterExternally
            ) {
                let filteredItems = state.items;
                let selectedItems = state.selectedItems;
                let additionalPropertiesToFilter: any[];
                if (Array.isArray(state.additionalPropertiesToFilter)) {
                    additionalPropertiesToFilter = [
                        ...state.additionalPropertiesToFilter,
                        state.labelKey,
                        state.titleKey
                    ];
                } else {
                    additionalPropertiesToFilter = [
                        state.labelKey,
                        state.titleKey
                    ];
                }

                if (action.payload) {
                    filteredItems = filteredItems.filter((i: any) => {
                        let filterString = additionalPropertiesToFilter
                            .reduce((result: any, prop: any) => {
                                if (i.hasOwnProperty(prop)) {
                                    return result + i[prop];
                                }
                                return result;
                            }, "")
                            .toLowerCase();
                        const enabled = state.disabledKey
                            ? !i[state.disabledKey]
                            : true;
                        return (
                            filterString.includes(
                                action.payload.toLowerCase()
                            ) && enabled
                        );
                    });

                    selectedItems = filteredItems.length
                        ? [filteredItems[0][state.idKey]]
                        : state.selectedItems;
                }

                return {
                    ...state,
                    ...{
                        itemFilter: action.payload,
                        selectedItems: selectedItems,
                        activeItem: undefined
                    }
                };
            }

            return {
                ...state,
                ...{
                    itemFilter: action.payload,
                    activeItem: undefined
                }
            };
        }

        case "SET_LABEL_KEY":
            return { ...state, ...{ labelKey: action.payload } };

        case "SET_GROUP_ID_KEY":
            return { ...state, ...{ groupIdKey: action.payload } };

        case "SET_BOTTOM_METADATA_KEY":
            return { ...state, ...{ bottomMetadataKey: action.payload } };

        case "SET_RIGHT_METADATA_KEY":
            return { ...state, ...{ rightMetadataKey: action.payload } };

        case "SET_RIGHT_METADATA_IS_ICON":
            return {
                ...state,
                ...{
                    rightMetadataIsIcon: !!action.payload
                }
            };

        case "SET_DISABLED_KEY":
            return { ...state, ...{ disabledKey: action.payload } };

        case "SET_ID_KEY":
            return { ...state, ...{ idKey: action.payload } };

        case "SET_TITLE_KEY":
            return { ...state, ...{ titleKey: action.payload } };

        case "SET_GROUP_TITLE_KEY":
            return { ...state, ...{ groupTitleKey: action.payload } };

        case "SET_IS_SINGLE_SELECT": {
            let newSelectedItems: any[] = [];
            if (
                action.payload &&
                state.selectedItems &&
                state.selectedItems.length > 0
            ) {
                newSelectedItems = [state.selectedItems[0]];
            }
            return {
                ...state,
                ...{
                    isSingleSelect: action.payload,
                    selectedItems: newSelectedItems
                }
            };
        }
        case "SET_HAS_FILTER":
            return { ...state, ...{ hasFilter: action.payload } };
        case "SET_FILTER_ICON":
            let filterIcon = action.payload || ["far", "filter"];
            return { ...state, ...{ filterIcon: filterIcon } };
        case "SET_HAS_FOOTER":
            let hasFooter = action.payload || false;
            return { ...state, ...{ hasFooter: hasFooter } };
        case "SET_HAS_SELECT_ALL":
            return { ...state, ...{ hasSelectAll: action.payload } };
        case "TOGGLE_SHOW_ONLY_SELECTED":
            return {
                ...state,
                ...{
                    showOnlySelected: !state.showOnlySelected
                }
            };
        case "SET_SHOW_ONLY_SELECTED":
            return { ...state, ...{ showOnlySelected: !!action.payload } };
        case "OPEN_DROPDOWN":
            let newActiveItem: any;
            if (
                state.isSingleSelect &&
                state.selectedItems &&
                state.selectedItems.length
            ) {
                newActiveItem = state.selectedItems[0];
            }
            return {
                ...state,
                ...{
                    isOpen: true,
                    activeItem: newActiveItem
                }
            };
        case "CLOSE_DROPDOWN":
            return {
                ...state,
                ...{
                    isOpen: false,
                    activeItem: undefined,
                    isDirty: true
                }
            };
        case "SET_IS_REQUIRED":
            let isRequired = action.payload || false;
            return { ...state, ...{ isRequired: isRequired } };
        case "SET_IS_DIRTY":
            let isDirty = action.payload || false;
            return { ...state, ...{ isDirty: isDirty } };
        case "SET_IS_DISABLED":
            let isDisabled = action.payload || false;
            return { ...state, ...{ isDisabled: isDisabled } };
        case "SET_IS_READONLY":
            let isReadonly = action.payload || false;
            return { ...state, ...{ isReadonly: isReadonly } };
        case "SET_SHOW_SELECTED_TOGGLE": {
            let showSelectedToggle = action.payload || false;
            return {
                ...state,
                ...{
                    showSelectedToggle: showSelectedToggle
                }
            };
        }
        case "SET_ADDITIONAL_PROPERTIES_TO_FILTER": {
            let additionalPropertiesToFilter = action.payload;
            if (!Array.isArray(additionalPropertiesToFilter)) {
                additionalPropertiesToFilter = [];
            }
            return {
                ...state,
                ...{
                    additionalPropertiesToFilter: additionalPropertiesToFilter
                }
            };
        }
        case "SET_SHOW_ICON_IN_DISPLAY":
            let showIconInDisplay = action.payload || false;
            return {
                ...state,
                ...{
                    showIconInDisplay: showIconInDisplay
                }
            };
        case "SET_BIND_AS_HTML":
            let bindAsHtml = action.payload || false;
            return { ...state, ...{ bindAsHtml: bindAsHtml } };
        case "SET_MAX_POPUP_HEIGHT":
            let maxPopupHeight = Math.max(240, action.payload || 0);
            return { ...state, ...{ maxPopupHeight: maxPopupHeight } };
        case "SET_SHOW_RECENT_SELECTIONS":
            let showRecentSelections = action.payload || false;
            return {
                ...state,
                ...{
                    showRecentSelections: showRecentSelections
                }
            };
        case "SET_RECENTLY_SELECTED_ITEMS":
            let recentlySelectedItems = action.payload || [];
            return {
                ...state,
                ...{
                    recentlySelectedItems: recentlySelectedItems
                }
            };
        case "SET_SHOW_MAIN_LIST_WITH_RECENTS":
            let showMainListWithRecents = action.payload || false;
            return {
                ...state,
                ...{
                    showMainListWithRecents: showMainListWithRecents
                }
            };
        case "HANDLE_FILTER_EXTERNALLY":
            let handleFilterExternally = action.payload || false;
            return {
                ...state,
                ...{
                    handleFilterExternally: handleFilterExternally
                }
            };
        case "SET_LIMIT_DROPDOWN_ITEMS":
            let limitDropDownItems = action.payload || null;
            return {
                ...state,
                limitDropDownItems
            };
        default:
            return state;
    }
}

function _toggleFilteredItemSelection(state: SfSelectState) {
    let newSelectedItems;
    let filteredItems = _getFilteredItemIdList(state);
    let allVisibleItemsSelected = filteredItems.every(function (i: any) {
        return state.selectedItems.includes(i);
    });
    if (allVisibleItemsSelected) {
        newSelectedItems = difference(state.selectedItems, filteredItems);
        return { ...state, ...{ selectedItems: newSelectedItems } };
    }
    newSelectedItems = uniq([...state.selectedItems, ...filteredItems]);
    return { ...state, ...{ selectedItems: newSelectedItems } };
}

function _getFilteredItemIdList(state: SfSelectState) {
    return state.items
        .filter(function (i: any) {
            if (i.disabled) {
                return false;
            }
            if (!state.itemFilter || state.handleFilterExternally) {
                return true;
            }
            return i[state.labelKey]
                .toLowerCase()
                .includes(state.itemFilter.toLowerCase());
        })
        .map((item) => item[state.idKey]);
}

function _toggleGroupSelection(state: SfSelectState, groupId: string) {
    let newSelectedItems;
    let itemsInGroup = state.items.filter(
        (item) => item[state.groupIdKey] === groupId
    );

    if (state.disabledKey) {
        itemsInGroup = itemsInGroup.filter((i: any) => {
            return !i[state.disabledKey];
        });
    }

    itemsInGroup = itemsInGroup.map((item) => item[state.idKey]);

    let allItemsSelected = itemsInGroup.every(function (i: any) {
        return state.selectedItems.includes(i);
    });

    if (allItemsSelected) {
        let filteredSelection = state.selectedItems.filter(function (i: any) {
            return !itemsInGroup.includes(i);
        });

        return { ...state, ...{ selectedItems: filteredSelection } };
    }
    newSelectedItems = uniq([...itemsInGroup, ...state.selectedItems]);

    return {
        ...state,
        ...{
            selectedItems: newSelectedItems,
            activeItem: groupId
        }
    };
}
