import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

export interface InvitationRecipient {
    name: string;
    id: string; // userid
    date: Date; // creation date
}

export interface PendingInvitation {
    service: string;
    recipients: InvitationRecipient;
    tokenID: string;
    invitation: any;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class TokenService {
    TOKEN_CONTROLLER_PATH = "/sf/userorg/token/";

    constructor(
            private _rpcClient: RpcClientService,
            private _http: HttpClient
    ) {
    }

    loginWithToken(tokenID: string, tokenTypes: string[]) {
        return this.makeControllerRequest("loginWithToken", {
            tokenID: tokenID,
            tokenTypes: tokenTypes
        });
    }

    verifyEmail(tokenID: string) {
        return this.makeControllerRequest("verifyEmail", {
            tokenID: tokenID
        });
    }

    findServiceInvitationToAccept() {
        return this._rpcClient.makeRequest("userorg", "findServiceInvitationToAccept", {});
    }

    findInvitationToAccept(orgID: string) {
        return this._rpcClient.makeRequest("userorg", "findInvitationToAccept", { organizationID: orgID });
    }

    getInvitationByID(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getInvitationByID", {
            tokenID: tokenID
        });
    }

    getOrganizationInvitation(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getOrganizationInvitation", { tokenID: tokenID });
    }

    getUserInvitation(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getUserInvitation", {
            tokenID: tokenID
        });
    }

    getServiceInvitation(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getServiceInvitation", {
            tokenID: tokenID
        });
    }

    getServiceInvitationContract(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getServiceInvitationContract", { tokenID: tokenID });
    }

    getSignMSAInvitation(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getSignMSAInvitation", {
            tokenID: tokenID
        });
    }

    getNonUserServiceInvitation(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "getNonUserServiceInvitation", { tokenID: tokenID });
    }

    getAPITokenInvitation(tokenID: string, includeGuid: boolean) {
        return this._rpcClient.makeRequest("userorg", "getAPITokenInvitation", {
            tokenID: tokenID,
            includeGuid: includeGuid
        });
    }

    acceptOrganizationInvitation(tokenID: string, data: any) {
        return this._rpcClient.makeRequest("userorg", "acceptOrganizationInvitation", {
            tokenID: tokenID,
            data: data
        });
    }

    acceptServiceInvitationNoContract(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "acceptServiceInvitationNoContract", { tokenID: tokenID });
    }

    acceptUserInvitation(tokenID: string, data: any) {
        return this._rpcClient.makeRequest("userorg", "acceptUserInvitation", {
            tokenID: tokenID,
            data: data
        });
    }

    acceptUserRolesInvitation(tokenID: string, userID: string) {
        return this._rpcClient.makeRequest("userorg", "acceptUserRolesInvitation", {
            tokenID: tokenID,
            userID: userID
        });
    }

    updateOrganizationInvitation(tokenID: string, data: any) {
        return this._rpcClient.makeRequest("userorg", "updateOrganizationInvitation", {
            tokenID: tokenID,
            data: data
        });
    }

    updateSignatureInvitation(tokenID: string, data: any) {
        return this._rpcClient.makeRequest("userorg", "updateSignatureInvitation", {
            tokenID: tokenID,
            data: data
        });
    }

    updateNonUserServiceInvitation(tokenID: string, data: any) {
        return this._rpcClient.makeRequest("userorg", "updateNonUserServiceInvitation", {
            tokenID: tokenID,
            data: data
        });
    }

    rejectInvitationToken(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "rejectInvitationToken", {
            tokenID: tokenID
        });
    }

    rejectInvitationAndUnsubscribe(tokenID: string) {
        return this._rpcClient.makeRequest("userorg", "rejectInvitationAndUnsubscribe", { tokenID: tokenID });
    }

    emailMSA(params: any) {
        return this._rpcClient.makeRequest("userorg", "emailMSA", params);
    }

    inviteOrganization(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "inviteOrganization", invitation);
    }

    inviteToService(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "inviteToService", invitation);
    }

    inviteToServiceNonUser(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "inviteToServiceNonUser", invitation);
    }

    activateTrialService(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "activateTrialService", invitation);
    }

    inviteUser(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "inviteUser", invitation);
    }

    inviteUsers(invitation: any) {
        return this._rpcClient.makeRequest("userorg", "inviteUsers", invitation);
    }

    // username can be blank
    rejectPendingUser(tokenID: string, email: string, tokenEmail: string, username: string) {
        let params = {
            email: email,
            tokenEmail: tokenEmail,
            username: username,
            tokenID: tokenID
        };
        return this._rpcClient.makeRequest("userorg", "rejectPendingUser", params);
    }

    // username can be blank
    approvePendingUser(tokenID: string, email: string, tokenEmail: string, username: string) {
        let params = {
            email: email,
            tokenEmail: tokenEmail,
            username: username,
            tokenID: tokenID
        };
        return this._rpcClient.makeRequest("userorg", "approvePendingUser", params);
    }

    reInviteUser(tokenID: string, email: string) {
        return this._rpcClient.makeRequest("userorg", "reInviteUser", {
            tokenID: tokenID,
            email: email
        });
    }

    cancelInvitation(tokenID: string, email: string, tokenType: string) {
        return this._rpcClient.makeRequest("userorg", "cancelInvitation", {
            tokenID: tokenID,
            email: email,
            tokenType: tokenType
        });
    }

    sendPasswordResetEmail(username: string) {
        return this._rpcClient.makeRequest("userorg", "sendPasswordResetEmail", {
            username: username
        });
    }

    getPendingServiceInvitations(orgID: string) {
        return this._rpcClient.makeRequest("userorg", "getPendingServiceInvitations", {
            organizationID: orgID
        });
    }

    cancelServiceInvitation(orgID: string, productID: string) {
        return this._rpcClient.makeRequest("userorg", "cancelServiceInvitation", {
            organizationID: orgID,
            productID: productID
        });
    }

    getPendingInvitations(options: any): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getPendingInvitations", options);
    }

    inviteApprovedUser(tokenID: string, email: string, username: string) {
        let params = {
            tokenID: tokenID,
            emailAddress: email,
            username: username
        }
        return this._rpcClient.makeRequest("userorg", "inviteApprovedUser", params);
    }

    searchInvitations(criteria: any) {
        return this._rpcClient.makeRequest("userorg", "searchInvitations", {
            search: criteria
        });
    }

    resendInvitation(tokenID: any, email: string) {
        return this._rpcClient.makeRequest("userorg", "resendInvitation", {
            tokenID: tokenID,
            email: email
        });
    }

    recordLicenseFeePayment(tokenID: string, contractID: string, ccAccount: any, amount: number) {
        return this._rpcClient.makeRequest("userorg", "recordLicenseFeePayment", {
            tokenID: tokenID,
            contractID: contractID,
            uiCreditCardAccount: ccAccount,
            amount: amount
        });
    }

    recordLicenseFeePaymentExistingCard(tokenID: string, contractID: string, cardAccountID: string,
            organizationID: string) {
        return this._rpcClient.makeRequest("userorg", "recordLicenseFeePaymentExistingCard", {
            tokenID: tokenID,
            contractID: contractID,
            cardAccountID: cardAccountID,
            organizationID: organizationID
        });
    }

    sendPendingServiceReminderEmail(orgID: string, fName: string, lName: string, email: string) {
        return this._rpcClient.makeRequest("userorg", "sendPendingServiceReminderEmail", {
            organizationID: orgID,
            firstName: fName,
            lastName: lName,
            email: email
        });
    }

    sendEmailAddressVerificationRequest(username: string) {
        return this._rpcClient.makeRequest("userorg", "sendEmailAddressVerificationRequest", {
            username: username
        });
    }

    private makeControllerRequest(method: string, data?: any): Observable<any> {
        if (!data) {
            data = {};
        }
        return this._http.post(this.TOKEN_CONTROLLER_PATH + method, data);
    }
}
