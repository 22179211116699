export enum STATUS {
    ACCEPTED = "Accepted",
    BILLED_REJECTED = "Billed Rejected",
    CANCELED = "Canceled",
    DATA_ENTRY = "Data Entry",
    DRAFT = "Draft",
    INITIALIZE = "Initialize",
    READY = "Ready",
    RECEIVED = "Received",
    RECORDED = "Recorded",
    REJECTED = "Rejected",
    REMOVED = "Removed",
    DATA_REVIEW = "Review",
    REVIEWING = "Reviewing",
    REVIEW = "Review",
    SENT = "Sent",
    SENT_REMOTE = "SentRemote",
    SIGN = "Sign",
    VOIDED = "Voided",
    MOU_COMPELTE = "MOU Complete"
}

export enum SUB_STATUS {
    IN_TRANSIT = "In Transit",
    FINALIZING = "Finalizing",
    MAINTENANCE = "Maintenance"
}

export enum STATE {
    DRAFT = "Draft",
    READY = "Ready",
    SENT = "Sent",
    SENT_REMOTE = "SentRemote",
    ACCEPTED = "Accepted",
    REJECTED = "Rejected",
    BILLED_REJECTED = "Billed Rejected",
    FINALIZING = "Finalizing",
    RECORDED = "Recorded",
    MAINTENANCE = "Maintenance",
    VOIDED = "Voided",
    REMOVED = "Removed",
    CANCELLED = "Cancelled",
    MOU_COMPLETE = "MOU Complete"
}
