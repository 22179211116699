import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "../../services/session.service";
import { GrowlService } from "../../growl/growl.service";

@Injectable({
    providedIn: "root"
})
export class PermissionDeniedGuard implements CanActivate {
    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _growlService: GrowlService
    ) {}

    canActivate(
        nextRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const permissions: string[] = nextRoute.data?.selectableOrgPermissions;
        if (!permissions?.length) return true;

        for (let p in permissions) {
            if (this._sessionService.hasPermissionInAnyOrg(permissions[p])) {
                return true;
            }
        }

        this._growlService.error(
            "You have attempted to access a restricted resource. Please contact your Simplifile administrator if you require access.",
            "Permission Denied",
            { disableTimeOut: true }
        );
        return false;
    }
}
