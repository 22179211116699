import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ENVIRONMENT } from "./environment";
import {
    FaIconLibrary,
    FontAwesomeModule
} from "@fortawesome/angular-fontawesome";
import { HttpClientTestingModule } from "@angular/common/http/testing";
import { ICONS } from "./icons/icons.constant";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterTestingModule } from "@angular/router/testing";
import { OverlayModule } from "@angular/cdk/overlay";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { GrowlDisplayService } from "./growl/growl-display.service";
import { ToastrModule } from "ngx-toastr";
import { TOASTR_CONFIG } from "./growl/toastr.config";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FontAwesomeModule,
        HttpClientTestingModule,
        RouterTestingModule,
        NgbModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(TOASTR_CONFIG)
    ],
    providers: [
        {
            provide: ENVIRONMENT,
            useValue: { production: false }
        }
    ],
    exports: [FontAwesomeModule, NgbModule, FormsModule, ReactiveFormsModule]
})
export class IntegrationTestingModule {
    constructor(
        private _iconsLibrary: FaIconLibrary,
        private _growlDisplayService: GrowlDisplayService
    ) {
        _iconsLibrary.addIcons(...Object.values(ICONS));
        (window as any).log = {
            info: () => {},
            debug: () => {},
            error: () => {},
            warning: () => {},
            getLevel: () => {},
            setLevel: () => {}
        };

        // Setup for Ag-Grid
        LicenseManager.setLicenseKey(
            "Using_this_AG_Grid_Enterprise_key_( AG-049184 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Intercontinental Exchange, Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 106 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 21 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 October 2024 )____[v2]_MTcyOTAzMzIwMDAwMA==5e7b99009226ba315c30912e3a6749a7"
        );

        ModuleRegistry.registerModules([
            ClientSideRowModelModule,
            ClipboardModule,
            RangeSelectionModule,
            RowGroupingModule,
            SetFilterModule
        ]);

        /** Not using this currently because it breaks other tests :/
        // "destroyAfterEach" clears out the DOM after each test - this keeps tests from interfering with each other
        // Borrowed from this blog post:
        // https://dev.to/this-is-angular/improving-angular-tests-by-enabling-angular-testing-module-teardown-38kh
        getTestBed().resetTestEnvironment();
        getTestBed().initTestEnvironment(
                BrowserDynamicTestingModule,
                platformBrowserDynamicTesting(),
                {teardown: {destroyAfterEach: true}}
        )
        */
    }
}
