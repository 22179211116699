import { DynamicControl, DynamicField, FieldVisibility } from "../interfaces";
import { FieldType } from "../enums/field-type.enum";
import { Type } from "@angular/core";
import { InputComponent } from "../components";
import { Subject } from "rxjs";
import { AbstractControl } from "@angular/forms";

export abstract class AbstractDynamicFormControl
    extends AbstractControl
    implements DynamicControl {
    type: FieldType;
    isVisible: boolean;
    dynamicField: DynamicField;

    abstract disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;
    abstract enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;

    checkForChanges$ = new Subject<void>();

    setIsVisible(isVisible: boolean) {
        this.isVisible = isVisible;
    }

    setDynamicField(field: DynamicField) {
        this.dynamicField = field;
    }

    setComponent(component: Type<InputComponent>) {
        this.dynamicField.component = component;
    }

    checkVisibility(viewState: FieldVisibility) {
        if (viewState.visibility.disabled !== this.disabled) {
            if (
                viewState.visibility.disabled ||
                (this.parent && this.parent.disabled)
            ) {
                this.disable({ onlySelf: true });
            } else {
                this.enable({ onlySelf: true });
            }
        }

        this.setIsVisible(viewState.visibility.visible);
    }

    checkForChanges() {
        this.checkForChanges$.next();
    }
}
