import { Injectable } from "@angular/core";
import { SessionService, SortUtilitiesService } from "@sf/common";
import { SessionOrganization } from "@sf/common";
import { UserOrgService } from "./user-org.service";

interface OrgSelection {
    value: string; // id
    option: string; // id
    title: string; // name
    label: string; // name
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class SharedOrgListService {
    constructor(
            private _sessionService: SessionService,
            private userOrgService: UserOrgService
    ) {}

    getOrgOptionList(
        allowNonSetupOrgs: boolean,
        requiredProduct?: string
    ): OrgSelection[] {
        let organizations: SessionOrganization[] = this.userOrgService.cloneObj(
                this._sessionService.getAllOrganizations());

        // standard historical filter = must be enabled, or recipient org
        let filteredOrgs: SessionOrganization[] = organizations.filter(
            (org: SessionOrganization) => {
                let hasRecipient: boolean = this._sessionService.hasProduct(
                    org.id,
                    "recipient"
                );
                return hasRecipient || org.isEnabled;
            }
        );

        // exclude non-set-up orgs unless parameter allows it
        // default is to not allow, if no parameter passed
        if (!allowNonSetupOrgs) {
            filteredOrgs = filteredOrgs.filter((org: SessionOrganization) => {
                let hasRecipient: boolean = this._sessionService.hasProduct(
                    org.id,
                    "recipient"
                );
                return hasRecipient || org.isSetUp;
            });
        }

        // optional filter to exclude orgs that don't have a specified product
        if (requiredProduct) {
            filteredOrgs = filteredOrgs.filter((org: SessionOrganization) => {
                return this._sessionService.hasProduct(org.id, requiredProduct);
            });
        }

        let selectionObjs: OrgSelection[] = filteredOrgs.map((org: SessionOrganization) => {
            return this.getSelectionObj(org);
        });

        selectionObjs = selectionObjs.sort((a, b) => {
            return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
        });

        return selectionObjs;
    }

    getSelectionObj(org: SessionOrganization): any {
        return {
            value: org.id,
            option: org.id,
            title: org.name,
            label: org.name
        };
    }
}
