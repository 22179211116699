import { Component, Input, OnInit } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";

@Component({
    selector: "sf-alert-modal",
    templateUrl: "../base-modal/base-modal.component.html",
    styleUrls: ["../base-modal/base-modal.component.scss"]
})
export class AlertModalComponent extends BaseModalComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.primary) {
            this.primary = {
                text: "OK",
                responseValue: "primary"
            };
        }
        super.ngOnInit();
    }
}
