<sf-tabbed-modal-header [title]="modalTitle" [currentStep]="currentIndex+1" [stepCount]="stepCount"></sf-tabbed-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        <span [innerHTML]="warning"></span>
    </div>

    <!-- step 0 -->
    <form *ngIf="currentIndex == 0" name="addUserForm" [formGroup]="addUserForm">
        <div class="row bottom-gap" *ngIf="!fixedOrg">
            <div class="form-group col-sm-9">
                <div>
                    <label class="bold">Organization(s)</label>
                </div>
                <div>
                    <button class="btn btn-secondary org-selector" (click)="orgSelectDialog()">
                        {{selectedOrgsTitle}}
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="row top-gap">
            <div class="form-group col-sm-9">
                <div class="email-title"
                >
                    <label *ngIf="!bulkAdd" for="addUserEmail" class="bold">Email Address</label>
                    <label *ngIf="bulkAdd" for="bulkEmail" class="bold">Email Addresses</label>
                    <label *ngIf="!isEditMode && !isCreateUserMode">
                        <input type="checkbox" formControlName="bulkAdd" (change)="bulkChanged()"/>
                        <span> Add multiple users</span>
                    </label>
                </div>
                <input type="text" *ngIf="!bulkAdd" formControlName="emailSingle" name="email"
                    maxlength="255" id="addUserEmail" class="form-control sf-mask"/>
                <textarea *ngIf="bulkAdd" formControlName="emailMulti" name="emails"
                    id="bulkEmail" class="form-control sf-mask"></textarea>
                <div *ngIf="bulkAdd" class="form-group col-sm-12 sep-info">
                    <small>* Separate email addresses with spaces or commas</small>
                </div>
            </div>
        </div>

        <div class="row top-gap role-container">
            <div class="form-group col-sm-9 selector-height">
                <label class="bold">Role(s)</label>
                <sf-userorg-role-multi-selector
                    [orgIDs]="addData.selectedOrgIDs" required
                    (changed)="rolesChanged($event)"
                    [initialSelection]="addData.selectedRoleIDs"
                    [apiRolesOnly]="isCreateUserMode"
                    [showFilter]="false"></sf-userorg-role-multi-selector>
            </div>
        </div>
    </form>

    <!-- Step 1 - Basic Info -->
    <form *ngIf="currentIndex == 1" name="basicInfoForm" [formGroup]="basicInfoForm">
        <div class="row" *ngIf="userInstructions">
            <div class="form-group col-sm-12 add-data-message" [ngClass]="{'orange': !!existingUserCount}">
                <i>{{userInstructions}}</i>
            </div>
        </div>

        <div class="basic-section">
            <div *ngIf="loadingPage2">
                <fa-icon icon="spinner" [spin]="true"></fa-icon>
                <span> Loading...</span>
            </div>
            <div *ngIf="!loadingPage2">
                <div class="row" *ngIf="isCreateUserMode">
                    <div class="form-group col-sm-4">
                        <label for="addUserUsername">Username for New User</label>
                        <input type="text" id="addUserUsername" maxlength="55" formControlName="username" class="form-control sf-mask">
                    </div>

                    <div class="form-group col-sm-5" *ngIf="availableUsernames">
                        <label>&nbsp;</label>
                        <sf-select class=""
                            [options]="availableUsernames"
                            [isSingleSelect]="true"
                            (select)="selectedAvailableUsername($event)"
                            trackBy="label" labelBy="label"
                            placeholder="Available Usernames">
                        </sf-select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-4">
                        <label for="addUserFirstName">First Name</label>
                        <input type="text" id="addUserFirstName" formControlName="firstName" class="form-control sf-mask"
                            maxlength="82" [readonly]="existingUserCount">
                    </div>

                    <div class="form-group col-sm-4">
                        <label for="middle">Middle Name</label>
                        <input type="text" formControlName="middleName" class="form-control sf-mask" id="middle"
                            maxlength="82" [readonly]="existingUserCount">
                    </div>

                    <div class="form-group col-sm-4">
                        <label for="last">Last Name</label>
                        <input type="text" formControlName="lastName" class="form-control sf-mask" id="last"
                            maxlength="82" [readonly]="existingUserCount">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-8">
                        <label for="title">Job Title</label>
                        <input type="text" formControlName="title" class="form-control" maxlength="82"
                                id="title" [readonly]="existingUserCount">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-4">
                        <label for="phone">Phone Number</label>
                        <input type="text" sf-phone-input formControlName="phone" class="form-control sf-mask" name="phone"
                            id="phone" maxlength="20" [readonly]="existingUserCount">
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="ext">Extension</label>
                        <input type="text" formControlName="extension" class="form-control sf-mask" maxlength="9"
                            id="ext" [readonly]="existingUserCount">
                    </div>
                </div>

                <div class="row" *ngIf="!isCreateUserMode && allowUsernameDefinition && !existingUserCount">
                    <div class="form-group col-sm-3">
                        <label for="username">Username</label>
                        <input type="text" name="username" formControlName="username" class="form-control sf-mask"
                                id="username" maxlength="55">
                    </div>
                </div>

                <div *ngIf="isSuperUser" class="row">
                    <div class="form-group col-sm-5">
                        <label>External ID Type</label>
                        <sf-select
                                id="typeSelector" name="typeSelector"
                                [options]="externalIDTypeOptions"
                                [isSingleSelect]="true"
                                labelBy="label" trackBy="id"
                                placeholder="Select a Type..."
                                [hasClearButton]="true"
                                (select)="externalIDTypeChanged($event)">
                        </sf-select>
                    </div>
                    <div class="form-group col-sm-5">
                        <label for="externalID">External ID</label>
                        <input type="text" id="externalID" formControlName="externalID" class="form-control"/>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<sf-tabbed-modal-footer [primary]="primary" [secondary]="secondary" [nextButton]="nextButton"
    [backButton]="backButton"></sf-tabbed-modal-footer>
