<sf-modal-header title="License Accepted"></sf-modal-header>
<div class="modal-body">
    <div class="sector">
        <span>You have successfully accepted your Simplifile license agreement! </span>
        <span>A signed copy of the agreement has been emailed to you, or you can </span>
        <button class="btn btn-link sf-btn-link" (click)="printAgreement()">click here</button>
        <span> to print.</span>
    </div>
    <div *ngIf="!!addUserData" class="sector">
        <span>To create your own Simplifile user account, click on 'Create a User Account' below.</span>
    </div>
    <div class="message-area">
        <div class="message" *ngFor="let message of messages">
            {{message}}
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary">
</sf-modal-footer>