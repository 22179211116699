import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
    PersistentDataStack,
    PersistentDataStackService,
    RpcClientService
} from "@sf/common";
import { UserRole, CurrentRoleService } from "@sf/userorg/common";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class RecentRolesService {
    private _roleStack: PersistentDataStack;

    constructor(
            private _rpc: RpcClientService,
            private currentRoleService: CurrentRoleService,
            private persistentDataStackService: PersistentDataStackService
    ) {
        this._roleStack = this.persistentDataStackService.createNewStack("sfAdminRoleStack", 9);

        // when the 'current' role changes, push it on our 'recent' list
        this.currentRoleService.currentRole$.subscribe((role) => {
            if (role) {
                this.pushRecentRoleID(role.id);
            }
        });
    }

    getPastRecentRoles(): Observable<UserRole[]> {
        return this.getPastRecentRoleIDs(10)
                   .pipe(mergeMap((rolenames) => this._rpc.makeRequest<UserRole[]>("userorg", "getRoles", {
                       roleIDs: rolenames
                   })));
    }

    getPastRecentRoleIDs(count?: number): Observable<string[]> {
        return from(this._roleStack.last(count) as Promise<string[]>);
    }

    pushRecentRoleID(roleID: string): void {
        // an '@' sign is illegal and messes us up - shouldn't ever happen though
        if (!roleID.includes("@")) {
            this._roleStack.push(roleID);
        }
    }

    getLastRecentRoleID(): Observable<string> {
        return from(this._roleStack.peek() as Promise<string>);
    }

    pullRecentRoleIDs(roleIDs: string[]): void {
        this._roleStack.pullMultiple(roleIDs);
    }
}
