<div class="logo">
    <div class="logo-back">
        <button class="btn btn-link" (click)="clickBack()">
            <fa-icon icon="chevron-left"></fa-icon>
            <span> Back to Login</span>
        </button>
    </div>
</div>
<div class="login-container">
    <div class="head-text">
        Additional Authentication
    </div>

    <div class="explain">
        Additional authentication is needed before you can log in.
    </div>

    <!-- ERROR MESSAGES -->
    <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
        <ul *ngIf="formErrorMessages.length > 1">
            <li *ngFor="let message of formErrorMessages">
                <span [innerHTML]="message"></span>
            </li>
        </ul>
        <div *ngIf="formErrorMessages.length == 1">
            <span [innerHTML]="formErrorMessages[0]"></span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 padded" [ngClass]="{'boxed': !codeSent}">
            <form>
                <div class="step-no">Step 1</div>
                <div class="instruct">Receive the Authorization Code</div>
                <div class="ask">Where should we send your authorization code?</div>
                <div class="boom-box">
                    <div>
                        <label>
                            <input type="radio" name="destination" value="email" [(ngModel)]="destination"
                                    [checked]="true" (change)="destinationChanged('email')" autofocus/>
                            <span> Email &nbsp; {{emailAddress}}</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="hasMobilePhone && mobileAllowed">
                            <input type="radio" name="destination" value="sms" [(ngModel)]="destination"
                                    [disabled]="!hasMobilePhone" (change)="destinationChanged('sms')"/>
                            <span> Text &nbsp; {{mobileNumber}}</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="!hasMobilePhone && mobileAllowed"
                                ngbTooltip="Add your mobile phone number to your user profile to use this as an authentication method">
                            <input type="radio" name="destination" [disabled]="true"/>
                            <span> Text Message</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="hasMobilePhone && mobileAllowed && voiceAllowed">
                            <input type="radio" name="destination" value="voice" [(ngModel)]="destination"
                                    [disabled]="!hasMobilePhone" (change)="destinationChanged('voice')"/>
                            <span> Call &nbsp; {{mobileNumber}}</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="!hasMobilePhone && mobileAllowed"
                                ngbTooltip="Add your mobile phone number to your user profile to use this as an authentication method">
                            <input type="radio" name="destination" [disabled]="true"/>
                            <span> Phone Call</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="totpAllowed && totpSecret">
                            <input type="radio" name="destination" value="totp" [(ngModel)]="destination"
                                    [disabled]="!totpSecret" (change)="destinationChanged('totp')"/>
                            <span> Authenticator App</span>
                        </label>
                    </div>
                    <div>
                        <label *ngIf="totpAllowed && !totpSecret"
                            ngbTooltip="You can enable this by registering an authenticator app in your user profile">
                            <input type="radio" name="destination" [disabled]="true"/>
                            <span> Authenticator App</span>
                        </label>
                    </div>
                </div>
                <div class="">
                    <button [disabled]="sending || validating || waiting || destination == 'totp'" class="btn"
                        [ngClass]="{'btn-primary': !codeSent, 'btn-secondary': codeSent}"
                        (click)="sendCode()">
                        <span *ngIf="!sending && !codeSent">Send Code</span>
                        <span *ngIf="!sending && codeSent">Send A New Code</span>
                        <fa-icon *ngIf="sending" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                    </button>
                </div>
                <div *ngIf="codeSent && howSent" id="msgs-success-parent" class="messages top-gap">
                    <div id="msgs-success" class="">
                        <div *ngIf="waiting" class='content-yay'>
                            <div class='text'>{{howSent}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="codeSent" class="small top-gap">
                    <i>{{sendResultText}}</i>
                </div>
            </form>
        </div>

        <div class="col-md-1"></div>

        <div *ngIf="codeSent || codeOnFile" class="col-md-5 padded" [ngClass]="{'boxed': codeSent}">
            <form [formGroup]="loginForm">
                <div class="step-no">Step 2</div>
                <div class="instruct">Enter the Authorization Code</div>
                <div>
                    <div *ngIf="invalidCode" id="msgs-error-parent" class="messages">
                        <div id="msgs-error" class="">
                            <div class='content-alert'>
                                <div class='text'>Invalid Code</div>
                            </div>
                        </div>
                    </div>
                    <label for="code">Your Authorization Code:</label>
                    <input type="text" id="code" name="code" formControlName="code" maxlength="15"
                        class="form-control" autocomplete="off" autofocus
                        [ngClass]="{'invalid-field': invalidCode}"/>
                    <button [disabled]="sending || validating" class="btn top-gap"
                        [ngClass]="{'btn-secondary': !codeSent, 'btn-primary': codeSent}"
                        (click)="submitCode()">
                        <span *ngIf="!validating">Verify and Log In</span>
                        <fa-icon *ngIf="validating" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="help">
        <span>Need assistance? &nbsp;</span>
        <button class="btn btn-link sf-btn-link" (click)="clickContact()">Contact Support</button>
    </div>
</div>
