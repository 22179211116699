import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

export interface UIEmailDownloadDocumentModel {
    documentID: string;
    documentName: string;
    type: string;
    status: string;
    recordingDate: number;
    pageCount: number;
    entryNumber: string;
}

export interface UIEmailDownloadPackageModel {
    packageID: string;
    packageName: string;
    packageStatus: string;
    stampedDate: number;
    packageStatusString: string;
    packageState: string;
    submitterID: string;
    submitterName: string;
    submitterUserName: string;
    recipientID: string;
    recipientName: string;
    recipientNameWithState: string;
    recipientState: string;
    recipientTimeZone: string;
    documents: UIEmailDownloadDocumentModel[];
}

export interface LoginResult {
    status: string;
    uiemailDownloadPackageModel: UIEmailDownloadPackageModel;
}

@Injectable({
    providedIn: "root"
})
export class EmailPackageDownloadLinkService {
    constructor(private _httpClient: HttpClient) {}

    authenticateAndGetUIEmailDownloadPackageModel(
        token: string,
        overwrite: boolean
    ): Observable<LoginResult> {
        return this._httpClient.get(
            "/sf/submitter/nuDownload/login?token=" +
                token +
                "&overwrite=" +
                overwrite
        ) as Observable<LoginResult>;
    }
}
