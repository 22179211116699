<sf-modal-header title="Accept License"></sf-modal-header>
<div class="modal-body">
    <form [formGroup]="acceptForm">
        <div class="accept-checkbox">
            <label>
                <input type="checkbox" id="accept-checkbox" formControlName="documentWasRead"/>
                <span> I have read and agree to the terms of this license agreement.</span>
            </label>
        </div>
    </form>
    <div class="message-area">
        <div class="message" *ngFor="let message of messages">
            {{message}}
        </div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>