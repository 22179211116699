import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "./rpc-client.service";

@Injectable({
    providedIn: "root"
})
export class DoNotInviteService {
    constructor(private _rpcClient: RpcClientService) {}

    isUserEmailBlocked(username: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "isUserEmailBlocked", {
            username: username
        });
    }

    isMyEmailBlocked(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "isMyEmailBlocked", {});
    }

    blockUserEmail(username: string, block: boolean): Observable<boolean> {
        return this._rpcClient.makeRequest("userorg", "blockUserEmail", {
            username: username,
            block: block
        });
    }

    blockMyEmail(block: any): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "blockMyEmail", {
            block: block
        });
    }

    deleteBlockedEmails(emails: string[]): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "deleteBlockedEmails", {
            emailAddresses: emails
        });
    }

    deleteBlockedEmail(email: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "deleteBlockedEmail", {
            emailAddress: email
        });
    }

    findDoNotInvites(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "findDoNotInvites", {});
    }

    addBlockedEmail(emailAddress: string): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "addBlockedEmail", {
            emailAddress: emailAddress
        });
    }
}
