import {
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mapTo, takeUntil } from "rxjs/operators";
import { SimpleHeaderComponent } from "../simple-header/simple-header.component";
import { PageHeaderComponent } from "../../interfaces/page-header-component.interface";
import { PageData } from "../../interfaces/page-data.interface";
import { UserCenterComponent } from "../../interfaces/user-center-component.interface";
import { LiveConfigService } from "@sf/common";
import { SimpleUserCenterComponent } from "../../user-center/simple-user-center/simple-user-center.component";

@Component({
    selector: "sf-header-wrapper",
    templateUrl: "./header-wrapper.component.html",
    styleUrls: ["./header-wrapper.component.scss"]
})
export class HeaderWrapperComponent implements OnInit, OnDestroy {
    @ViewChild("pageHeader", { read: ViewContainerRef, static: true })
    pageHeaderRef: ViewContainerRef;
    @ViewChild("userCenter", { read: ViewContainerRef, static: true })
    userCenterRef: ViewContainerRef;

    private _$ngOnDestroy = new Subject<void>();

    public showHeader = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _liveConfigService: LiveConfigService
    ) {}

    /** Lifecycle Hooks **/

    ngOnInit() {
        // Traverse ActivatedRoute tree until we find the route that has the pageData we need
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                mapTo(this._activatedRoute),
                map((route) => {
                    return this._mergePageData(route);
                }),
                takeUntil(this._$ngOnDestroy)
            )
            .subscribe((pageData: PageData) => {
                this.showHeader = pageData.pageHeaderComponent !== null;
                this._createPageHeader(pageData);
                this._createUserCenter(pageData);
            });

        const routeData = this._mergePageData(this._activatedRoute);
        this.showHeader = routeData.pageHeaderComponent !== null;
        this._createPageHeader(routeData);

        if (window.location.search.indexOf("restricted=true") == -1) {
            this._createUserCenter(routeData);
        }
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
    }

    private _createPageHeader(pageData: PageData) {
        this.pageHeaderRef.clear();

        if (pageData.pageHeaderComponent !== null) {
            const component =
                pageData.pageHeaderComponent || SimpleHeaderComponent;

            const componentFactory =
                pageData.pageHeaderFactory ??
                this._componentFactoryResolver.resolveComponentFactory(
                    component
                );

            const componentRef =
                this.pageHeaderRef.createComponent(componentFactory);
            const headerInstance = componentRef.instance as PageHeaderComponent;

            headerInstance.pageData = pageData;
        } else {
            this.pageHeaderRef.clear();
        }
    }

    private _createUserCenter(pageData: PageData) {
        this.userCenterRef.clear();

        if (pageData.userCenterComponent !== null) {
            const component =
                pageData.userCenterComponent || SimpleUserCenterComponent;
            const componentFactory =
                pageData.userCenterFactory ??
                this._componentFactoryResolver.resolveComponentFactory(
                    component
                );
            const componentRef =
                this.userCenterRef.createComponent(componentFactory);
            const userCenterInstance =
                componentRef.instance as UserCenterComponent;

            userCenterInstance.pageData = pageData;
        } else {
            this.userCenterRef.clear();
        }
    }

    private _mergePageData(route: ActivatedRoute) {
        let currentRoute = route;
        let currentRouteData = currentRoute.snapshot.data;

        while (currentRoute.firstChild) {
            currentRouteData = {
                ...currentRouteData,
                ...currentRoute.firstChild.snapshot.data
            };
            currentRoute = currentRoute.firstChild;
        }

        return currentRouteData;
    }
}
