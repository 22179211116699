import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./pages/login/login.component";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginService } from "./services/login.service";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ForgotUsernameComponent } from "./pages/forgot-username/forgot-username.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { UserLockedDialogComponent } from "./modals/user-locked-dialog/user-locked-dialog.component";
import {
    DateAndTimeModule,
    ModalsModule,
    SelectModule,
    SfCommonModule
} from "@sf/common";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { SecondFactorComponent } from "./pages/second-factor/second-factor.component";
import { LoanAuthenticationComponent } from "./pages/loan-authentication/loan-authentication.component";
import { EmailPackageDownloadLinkComponent } from "./pages/email-package-download-link/email-package-download-link.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { ChatUserPromptDialogComponent } from "./modals/chat-user-prompt-dialog/chat-user-prompt-dialog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MustChangePasswordComponent } from "./pages/must-change-password/must-change-password.component";
import { SecurityQuestionsComponent } from "./pages/security-questions/security-questions.component";
import { ScNotaryOnboardComponent } from "./pages/sc-notary-onboard/sc-notary-onboard.component";
import { MouAuthenticationComponent } from "./pages/mou-authentication/mou-authentication.component";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        SelectModule,
        LoginRoutingModule,
        AgGridModule,
        ModalsModule,
        DateAndTimeModule,
        FontAwesomeModule,
        SfCommonModule
    ],
    providers: [LoginService],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ForgotUsernameComponent,
        ContactComponent,
        ChangePasswordComponent,
        MustChangePasswordComponent,
        UserLockedDialogComponent,
        SecondFactorComponent,
        LoanAuthenticationComponent,
        EmailPackageDownloadLinkComponent,
        ChatUserPromptDialogComponent,
        SecurityQuestionsComponent,
        ScNotaryOnboardComponent,
        MouAuthenticationComponent
    ]
})
export class LoginModule {}
