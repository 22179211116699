<sf-modal-header title="Archive User"></sf-modal-header>
<div class="modal-body">
    <div class="extra-bottom">
        <span>Are you sure you want to archive this user?</span>
    </div>
    <div class="extra-bottom">
        <b>{{displayName}}</b>
    </div>
    <div class="extra-bottom">
        <span>Archived users are not deleted, but they will not appear in search results, and all their roles will be removed.</span>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>