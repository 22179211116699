import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { SelectableItemWithID, SpinnerService } from "@sf/common";
import { loadStripe } from "@stripe/stripe-js/pure";
import {
    CreditCardAccount,
    CreditCardType,
    PaymentAccountType,
    StripeService,
    TokenService
} from "@sf/userorg/common";
import { LicenseFeePaidDialogComponent } from "../../dialogs/license-fee-paid-dialog/license-fee-paid-dialog.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PaymentAccountsService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { DontCloseDialogComponent } from "../../dialogs/dont-close-dialog/dont-close-dialog.component";
import { ConfirmationModalComponent } from "@sf/common";
import { IconName } from "@fortawesome/fontawesome-svg-core";

// prettier-ignore
@Component({
    selector: "sf-pay-license-fee",
    templateUrl: "./pay-license-fee.component.html",
    styleUrls: ["./pay-license-fee.component.scss"]
})
export class PayLicenseFeeComponent implements OnInit, OnChanges {
    @Input()
    contractId: string;
    @Input()
    tokenID: string;
    @Input()
    organizationID: string;
    @Input()
    serviceName: string;
    @Input()
    licenseFeeAmount: number;
    @Output()
    actionTaken = new EventEmitter<string>();

    private stripe: any;
    private stripeSecret: string = null;
    private creditCardClientSecret: string = null;
    private processingModal: NgbModalRef = null;

    paymentMethod = "card";
    cardOption = "new";
    existingCards: SelectableItemWithID[];
    selectedCardID: string = null;
    creditCardName = "";
    creditCardNumberField: any;
    creditCardImage: IconName = null;
    creditCardBrand: string = null;
    creditCardType: CreditCardType = null;
    creditCardNumberError = "";
    creditCardDateError = "";
    creditCardCvcError = "";
    creditCardZip = "";
    cardAgreementAccepted = false;
    creditCardWarning: string = null;
    canPayErecord = true;
    showCreditCardAlert = false;
    timeoutTimer: any = null;

    constructor(
        private modalService: NgbModal,
        private stripeService: StripeService,
        private spinnerService: SpinnerService,
        private sessionService: SessionService,
        private tokenService: TokenService,
        private paymentService: PaymentAccountsService
    ) {}

    ngOnInit(): void {
        // get the key necessary for credit cards
        this.stripeService.getStripeKey().subscribe((key: string) => {
            this.stripeSecret = key;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.organizationID && changes.organizationID.currentValue) {
            // see if org can pay for packages with credit card
            this.stripeService.isOrganizationEnabledForCreditCard(this.organizationID)
                .subscribe((enabled: boolean) => {
                    this.canPayErecord = enabled;
                    // hide or show the banner about paying for packages with card
                    this.checkShowAlert();
                }, () => {
                    // nothing
                });

            // see if they have existing credit card
            this.existingCards = [];
            if (this.sessionService.hasPermission('organization_accounting', this.organizationID)) {
                this.paymentService.getPaymentAccounts([this.organizationID], true)
                    .subscribe((accounts) => {
                        if (accounts) {
                            let orgAccounts: any = accounts.paymentAccountsMap[this.organizationID];
                            if (orgAccounts && orgAccounts.creditCardAccounts) {
                                orgAccounts.creditCardAccounts.forEach((card: CreditCardAccount) => {
                                    this.existingCards.push({
                                        label: card.label + " (Exp. " + card.expMonth + "/" + card.expYear + ")",
                                        id: card.paymentAccountID
                                    });
                                });
                            }
                            if (this.existingCards && this.existingCards.length) {
                                this.cardOption = "old";
                                this.selectedCardID = this.existingCards[0].id;
                            } else {
                                // no credit cards -- prompt for card
                                this.initStripeFields();
                            }
                        } else {
                            // no accounts -- prompt for card
                            this.initStripeFields();
                        }
                    }, () => {
                        // assume no existing credit cards - prompt for card
                        this.initStripeFields();
                    });
            } else {
                this.initStripeFields();
            }

            // what Service are they paying the license fee for?
            if (!this.serviceName) {
                this.serviceName = "";
            }

            // we have to time out the session after 30 minutes
            this.resetTimeoutTimer();
        }
    }

    resetTimeoutTimer() {
        if (this.timeoutTimer) {
            clearTimeout(this.timeoutTimer);
        }
        this.timeoutTimer = setTimeout(() => {
            window.location.assign("/sf/ui/login?err=expired");
        }, 1785000); // almost 30 minutes
    }

    toTitleCase(strOld: string): string {
        let strNew = strOld.toLowerCase().split(" ");
        for (let i = 0; i < strNew.length; i++) {
            strNew[i] = strNew[i].charAt(0).toUpperCase() + strNew[i].slice(1);
        }
        return strNew.join(" ");
    }

    changeCardOption(option: string) {
        this.cardOption = option;
        if (option == "new") {
            this.initStripeFields();
        }
    }

    /*
    selectPaymentMethod(method: string) {
        this.paymentMethod = method;
        if (method == "card") {
            this.initStripeFields();
        }
        this.checkShowAlert();
    }
    */

    checkShowAlert() {
        if (this.paymentMethod == "ach") {
            this.showCreditCardAlert = false;
        } else if (this.canPayErecord) {
            this.showCreditCardAlert = false;
        } else {
            this.showCreditCardAlert = true;
        }
    }

    /**
     * Don't call this unless the credit card input fields are displayed
     */
    private initStripeFields() {
        if (!this.stripeSecret) {
            // get the key necessary for credit cards
            this.stripeService.getStripeKey().subscribe((key: string) => {
                this.stripeSecret = key;
                this.initStripeWithKey();
            });
        } else {
            this.initStripeWithKey();
        }
    }

    private initStripeWithKey() {
        if (!this.stripe) {
            this.spinnerService.startSpinner();
            loadStripe(this.stripeSecret).then((result: any) => {
                this.spinnerService.stopSpinner();
                this.stripe = result;
                this.loadStripeFields();
            });
            this.resetSetupIntent();
        } else {
            this.loadStripeFields();
        }
    }

    private resetSetupIntent() {
        this.spinnerService.startSpinner();
        this.stripeService.initializeInvitationLicenseFeePayment(this.tokenID, this.contractId)
            .subscribe((result: string) => {
                this.creditCardClientSecret = result;
                this.spinnerService.stopSpinner();
                this.resetTimeoutTimer();
            }, (error: any) => {
                this.spinnerService.stopSpinner();
                this.showError("Something went wrong. Try again later or contact Support.");
            });
    }

    /**
     * You have to call this every time you show or re-show the input fields
     * They may get removed from the DOM by some actions
     */
    private loadStripeFields() {
        let elements: any = this.stripe.elements();
        let cardStyle = {
            base: {
                color: "#555",
                fontFamily: "Simplifile Font, Helvetica Neue, Helvetica, Arial, sans serif",
                fontSize: "15px",
                "::placeholder": {
                    color: "#BC917C"
                }
            }
            /*
            invalid: {
                backgroundColor: "#FFE5E2"
            }
            */
        };
        this.creditCardNumberError = "Credit card number is required";
        this.creditCardDateError = "Expiration date is required";
        this.creditCardCvcError = "CVC is required";

        setTimeout(() => {
            this.creditCardNumberField = elements.create("cardNumber", {
                style: cardStyle
            });
            this.creditCardNumberField.mount("#cardNumberElement");
            let cardExpiry = elements.create("cardExpiry", {
                style: cardStyle
            });
            cardExpiry.mount("#cardExpiryElement");
            let cardCvc = elements.create("cardCvc", { style: cardStyle });
            cardCvc.mount("#cardCvcElement");

            this.creditCardNumberField.on("change", (error: any) => {
                this.creditCardNumberError = null;
                this.creditCardBrand = error.brand;
                switch (this.creditCardBrand) {
                    case "visa":
                        this.creditCardImage = "cc-visa";
                        break;
                    case "mastercard":
                        this.creditCardImage = "cc-mastercard";
                        break;
                    case "amex":
                        this.creditCardImage = "cc-amex";
                        break;
                    case "discover":
                        this.creditCardImage = "cc-discover";
                        break;
                    case "diners":
                        this.creditCardImage = "cc-diners-club";
                        break;
                    case "jcb":
                        this.creditCardImage = "cc-jcb";
                        break;
                    case "unionpay":
                    default:
                        this.creditCardImage = null;
                        break;
                }
                if (error) {
                    if (error.message) {
                        this.creditCardNumberError = error.message;
                    } else if (error.empty) {
                        this.creditCardNumberError = "Credit card number is required";
                    } else if (!error.complete) {
                        this.creditCardNumberError = "Credit card number is not complete";
                    } else {
                        // we're good
                        //this.creditCardWarning = null;
                    }
                }
            });
            cardExpiry.on("change", (error: any) => {
                this.creditCardDateError = null;
                if (error) {
                    if (error.message) {
                        this.creditCardDateError = error.message;
                    } else if (error.empty) {
                        this.creditCardDateError = "Credit card expiration is required";
                    } else if (!error.complete) {
                        this.creditCardDateError = "Credit card expiration is invalid";
                    } else {
                        // good
                        //this.creditCardWarning = null;
                    }
                }
            });
            cardCvc.on("change", (error: any) => {
                this.creditCardCvcError = null;
                if (error) {
                    if (error.message) {
                        this.creditCardCvcError = error.message;
                    } else if (error.empty) {
                        this.creditCardCvcError = "Credit card CVC is required";
                    } else if (!error.complete) {
                        this.creditCardCvcError = "Credit card CVC is invalid";
                    } else {
                        // good
                        //this.creditCardWarning = null;
                    }
                }
            });
        }, 0);
    }

    handleCardAgreeCheckbox(event: any) {
        this.cardAgreementAccepted = event.currentTarget.checked;
    }

    validateCreditCard(): string {
        if (!this.creditCardName) {
            return "Name on Card is required";
        }
        if (!this.creditCardZip) {
            return "Card Zip Code is required";
        }
        if (this.creditCardZip.length != 5 && this.creditCardZip.length != 10) {
            return "Card Zip Code is invalid";
        }
        if (this.creditCardNumberError) {
            return this.creditCardNumberError;
        }
        if (this.creditCardDateError) {
            return this.creditCardDateError;
        }
        if (this.creditCardCvcError) {
            return this.creditCardCvcError;
        }
        if (!this.cardAgreementAccepted) {
            return "Please check the checkbox to accept the terms.";
        }
        if (!this.creditCardClientSecret) {
            return "Credit card system busy. Please try again in a minute.";
        }

        return null;
    }

    payWithExistingCard() {
        this.spinnerService.startSpinner();
        this.tokenService.recordLicenseFeePaymentExistingCard(this.tokenID, this.contractId, this.selectedCardID,
                this.organizationID)
            .subscribe((response: any) => {
                // successful
                this.spinnerService.stopSpinner();
                this.showPaymentSuccessful(response.pdfReceipt64);
            }, (response: any) => {
                // error
                this.spinnerService.stopSpinner();
                this.showError("Something went wrong. Please try again, or try a different card, or contact Support at 800.460.5657");
            });
    }

    showError(message: string) {
        const modal = this.modalService.open(ConfirmationModalComponent);
        const modalInstance = modal.componentInstance;

        modalInstance.title = "Error";
        modalInstance.message = message;
        modalInstance.primary = {
            text: "OK",
            responseValue: 1
        };
        modalInstance.hideSecondary = true;
    }

    showProcessing() {
        this.processingModal = this.modalService.open(DontCloseDialogComponent,
            {
                backdrop: "static"
            }
        );
        const modalInstance = this.processingModal.componentInstance;

        modalInstance.title = "Processing...";
        modalInstance.message = "Please wait while we process your payment. Don't close your browser.";
    }

    hideProcessiong() {
        this.processingModal.close(false);
    }

    submitPayment() {
        if (this.cardOption != "new" && this.selectedCardID) {
            this.payWithExistingCard();
            return;
        }
        this.creditCardWarning = this.validateCreditCard();
        if (this.creditCardWarning) {
            this.showCreditCardAlert = false; // this warning causes confusion at this point
            return;
        }
        this.checkShowAlert();
        this.showProcessing();
        this.stripe.confirmCardSetup(this.creditCardClientSecret, {
                payment_method: {
                    card: this.creditCardNumberField,
                    billing_details: {
                        name: this.creditCardName,
                        address: {
                            postal_code: this.creditCardZip
                        }
                    }
                }
            })
            .then((result: any) => {
                if (result.error) {
                    this.hideProcessiong();
                    this.creditCardWarning = result.error.message;
                } else {
                    // card has been set up! Now let back end charge the fee.
                    this.finalizeCardPayment(result.setupIntent);
                }
            }, (error: any) => {
                this.hideProcessiong();
                this.creditCardWarning = "Unable to verify credit card";
            });
    }

    // create card account in Simplifile and make the charge.
    // create transaction and send email with receipt
    finalizeCardPayment(setupIntent: any) {
        let ccAccount: CreditCardAccount = {
            active: true,
            brand: this.creditCardBrand,
            cardType: this.creditCardType,
            expMonth: null,
            expYear: null,
            label: this.creditCardName + " (" + this.toTitleCase(this.creditCardBrand) + ")",
            nameOnCard: this.creditCardName,
            organizationID: this.organizationID,
            paymentAccountID: null,
            paymentAccountType: PaymentAccountType.CREDIT_CARD,
            token: setupIntent.payment_method
            //zipCode: this.creditCardZip
        };
        this.tokenService.recordLicenseFeePayment(this.tokenID, this.contractId, ccAccount, this.licenseFeeAmount)
            .subscribe((response: any) => {
                // successful
                this.hideProcessiong();
                this.showPaymentSuccessful(response.pdfReceipt64);
            }, (response: any) => {
                // error
                this.hideProcessiong();
                // we need to create a new intent, since the card number was valid
                this.resetSetupIntent();
            });
    }

    showPaymentSuccessful(pdfReceipt64: any) {
        const modalRef = this.modalService.open(LicenseFeePaidDialogComponent, {
            backdrop: "static"
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.licenseFeeAmount = this.licenseFeeAmount;
        modalInstance.pdfReceipt64 = pdfReceipt64;

        modalRef.result.then((result: any) => {
            this.done();
        }, () => {
            this.done();
        });
    }

    submitCurrentForm() {
        this.submitPayment();
    }

    cardSelected($event: any) {
        if ($event && $event.$selection) {
            this.selectedCardID = $event.$selection.id;
        }
    }

    dontPayCreditCard() {
        this.done();
    }

    done() {
        this.actionTaken.emit("done");
    }
}
