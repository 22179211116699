<sf-modal-header [showDismiss]="false" [title]="title"></sf-modal-header>

<div class="modal-body">
    <fa-icon
        *ngIf="loading"
        icon="spinner"
        [spin]="true"
        size="2x"
    ></fa-icon>
    <textarea
        *ngIf="!loading"
        id="notes"
        class="form-control"
        [(ngModel)]="eEligibilitySettings.e_eligibility_notes"
        cols="200"
        rows="8"
    ></textarea>
</div>

<sf-modal-footer
    [primary]="saveButton"
    [secondary]="cancelButton"
></sf-modal-footer>
