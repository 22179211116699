<button
    class="btn btn-link"
    [ngbPopover]="userPopoverContent"
    triggers="click"
    [autoClose]="'outside'"
    popoverClass="control-popover"
    placement="bottom-right"
    [attr.aria-label]="'Open ' + first + ' ' + last + '\'s user actions'"
>
    <div class="sf-exclude"
        ngbTooltip="{{first}}&nbsp;{{last}}"
    >
        <svg
            *ngIf="!this.profilePicture"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            aria-hidden="true"
        >
            <circle cx="50%" cy="50%" r="40" />
            <text
                    x="50%"
                    y="50%"
                    style="font: normal 28px sans-serif; letter-spacing: 4px;"
                    text-anchor="middle"
                    stroke="#FFF"
                    stroke-width="4px"
                    dy=".35em"
                    dx=".1em"
            >{{this.userInitials}}</text>
        </svg>
        <img
            *ngIf="this.profilePicture"
            [src]="this.profilePicture"
            [alt]="first + ' ' + last + '\'s profile picture'"
        />
    </div>
</button>

<ng-template #userPopoverContent>
    <ul>
        <li *ngFor="let l of links" >
            <button class="btn btn-link" [routerLink]="l.link">{{l.label}}</button>
        </li>
        <li><button class="btn btn-link" (click)="logout()">Logout</button></li>
    </ul>
</ng-template>
