import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    SimpleChanges
} from "@angular/core";
import { dayjs } from "../../plugins/dayjs/index";
import { DatePresetService } from "../../services";
import { DateRangePreset, DateRangeSelectApi } from "../../interfaces";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from "@angular/forms";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    mapTo,
    shareReplay,
    startWith,
    takeUntil,
    tap
} from "rxjs/operators";
import { merge, Observable, Subject } from "rxjs";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { SelectApi } from "../../interfaces/sf-select-api.interface";
import { noop } from "rxjs";
import { MetricLoggerService } from "../../../services/metric-logger.service";
import { LoggerType } from "../../../enums/logger-type.enum";

@Component({
    selector: "sf-date-range-picker",
    templateUrl: "./date-range-picker.component.html",
    styleUrls: ["./date-range-picker.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        }
    ]
})
export class DateRangePickerComponent
    implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
    /** I/O **/
    @Input()
    defaultPreset?: string;
    @Input()
    presets?: DateRangePreset[];
    @Input()
    maxDate?: dayjs.Dayjs;
    @Input()
    minDate?: dayjs.Dayjs;
    @Input()
    clearRange?: any;
    @Input()
    defaultStartDate?: dayjs.Dayjs;
    @Input()
    defaultEndDate?: dayjs.Dayjs;
    @Input()
    startPlaceholder?: string;
    @Input()
    startLabel?: string;
    @Input()
    endPlaceholder?: string;
    @Input()
    endLabel?: string;
    @Input()
    rangeOnly?: any;
    @Input()
    rangeLabel?: string;
    @Input()
    rangePlaceholder?: string;
    @Input()
    startingTabIndex?: any;
    @Input()
    singleRow?: any;
    @Input()
    allowClear?: boolean;
    @Input()
    showDates?: boolean;
    @Input()
    inputId?: string;
    @Input()
    container: "body" | undefined;

    // Used for Submitter Package List filter panel. See SUB-25109
    @Input()
    dateFieldsRequired?: boolean;

    @Input()
    updateOn?: "change" | "blur";

    @Output()
    changeEvent: EventEmitter<any> = new EventEmitter();
    @Output()
    registerApi: EventEmitter<{
        $api: DateRangeSelectApi;
    }> = new EventEmitter();

    /** View Children **/
    @ViewChild("dateFormRef", { static: false })
    public dateFormRef: FormGroupDirective;
    @ViewChild("inputStartDateTooltip") startDateTooltip: NgbTooltip;
    @ViewChild("inputEndDateTooltip") endDateTooltip: NgbTooltip;

    /** Private Variables **/
    private _onDestroy$: Subject<void> = new Subject();

    /** Public Variables **/
    datePresets: DateRangePreset[] = [];
    datePreset: any = null;
    startDate: dayjs.Dayjs = null;
    endDate: dayjs.Dayjs = null;
    dateForm: FormGroup;
    dateFormChanges$: Observable<FormGroup>;
    dateRangeValues$: Observable<string>;
    dateFieldValues$: Observable<string[]>;
    dateFormValues$: Observable<DateRangePreset>;
    startMaxDate$: Observable<dayjs.Dayjs>;
    endMinDate$: Observable<dayjs.Dayjs>;

    startingTabIndex2: any;
    startingTabIndex3: any;
    disabled = false;
    control: FormControl;
    private _onChange: (_: any) => void = noop;
    private _onTouched: () => void = noop;

    get presetFormGroup(): FormGroup {
        return this.dateForm.controls.preset as FormGroup;
    }

    get dateFormGroup(): FormGroup {
        return this.dateForm.controls.dates as FormGroup;
    }

    constructor(
        private _datePresetService: DatePresetService,
        private _loggerService: MetricLoggerService
    ) {}

    /** Lifecycle Hooks **/
    ngOnInit() {
        let dateFieldValidators = [];
        if (this.dateFieldsRequired) {
            dateFieldValidators.push(Validators.required);
        }

        this.dateForm = new FormGroup({
            preset: new FormGroup({
                value: new FormControl(null)
            }),
            dates: new FormGroup({
                start: new FormControl(null, dateFieldValidators),
                end: new FormControl(null, dateFieldValidators)
            })
        });

        this.dateFormChanges$ = this.dateForm.valueChanges.pipe(
            shareReplay({ refCount: true, bufferSize: 1 })
        );
        this.dateRangeValues$ = this.dateForm
            .get("preset.value")
            .valueChanges.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
        this.dateFieldValues$ = merge(
            this.dateForm.get("dates.start").valueChanges,
            this.dateForm.get("dates.end").valueChanges
        ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
        this.dateFormValues$ = this.dateFormChanges$.pipe(
            startWith(this.dateForm.value),
            map(() => this.dateForm.value)
        );
        this.startMaxDate$ = this.dateFormValues$.pipe(
            map((values) => {
                return values.end || this.maxDate;
            }),
            shareReplay(1)
        );
        this.endMinDate$ = this.dateFormValues$.pipe(
            map((values) => {
                return values.start || this.minDate;
            }),
            shareReplay(1)
        );

        this.datePresets = this._datePresetService.getPresets();

        if (this.presets) {
            this.datePresets = this.presets;
        }

        if (typeof this.showDates !== "boolean") {
            this.showDates = true;
        }

        if (this.defaultPreset) {
            this.datePreset = this.defaultPreset;
            this._updateDatesFromPreset(this.datePreset);
        }

        if (!this.startingTabIndex) {
            this.startingTabIndex = 0;
            this.startingTabIndex2 = 0;
            this.startingTabIndex3 = 0;
        } else {
            this.startingTabIndex2 = this.startingTabIndex + 1;
            this.startingTabIndex3 = this.startingTabIndex + 2;
        }

        // Need to detect whether this was triggered by the range picker or date fields
        merge(
            this.dateRangeValues$.pipe(distinctUntilChanged(), mapTo("range")),
            this.dateFieldValues$.pipe(mapTo("date"))
        )
            .pipe(
                debounceTime(250), // Ignore subsequent requests triggered by programmatic changes to field values
                map((origin) => {
                    if (origin === "range") {
                        const newPreset = {
                            ...this._updateDatesFromPreset(
                                this.dateForm.get("preset.value").value
                            )
                        };

                        const dateChange = {
                            start:
                                newPreset.start ??
                                this.dateForm.get("dates.start").value,
                            end:
                                newPreset.end ??
                                this.dateForm.get("dates.end").value,
                            preset: newPreset.id
                        };

                        // Update tooltips if date fields are required
                        if (this.dateFieldsRequired) {
                            this._updateTooltipsByRange(dateChange);
                        }

                        return dateChange;
                    } else {
                        const presetFromDates = this._getPresetFromDates(
                            this.dateForm.get("dates.start").value,
                            this.dateForm.get("dates.end").value
                        );
                        const dateChange = {
                            preset: presetFromDates.id,
                            start: this.dateForm.get("dates.start").value,
                            end: this.dateForm.get("dates.end").value
                        };

                        // Update tooltips if date fields are required
                        if (this.dateFieldsRequired) {
                            this._updateTooltipsByValues(dateChange);
                            this.validateRequired(
                                this.dateFormGroup.controls.start
                            );
                            this.validateRequired(
                                this.dateFormGroup.controls.end
                            );
                        }
                        return dateChange;
                    }
                }),
                tap((date) => {
                    if (
                        this.showDates &&
                        date.preset !== this.dateForm.get("preset.value").value
                    ) {
                        this.dateForm
                            .get("preset.value")
                            .setValue(date.preset, {
                                emitEvent: false,
                                onlySelf: true
                            });
                    }
                }),
                takeUntil(this._onDestroy$)
            )
            .subscribe((dates) => {
                this.datesChanged(dates);
            });

        this._setDefaultEndStart();
    }

    ngOnDestroy() {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    /** Public Methods **/

    // Pass through the sfSelect api object
    selectRegisterApi($event: { $api: SelectApi }) {
        this.registerApi.emit({
            $api: {
                ...$event.$api,
                setDateRange: this._setDateRange.bind(this),
                resetState: this._resetState.bind(this)
            } as DateRangeSelectApi
        });
    }

    datesChanged(dates: any) {
        let change: any = {
            start: dates.start,
            end: dates.end,
            preset: dates.preset
        };
        this.changeEvent.emit(change);

        if (this.dateFieldsRequired) {
            change = {
                start: dates.start ? dates.start.startOf("day") : null,
                end: dates.end ? dates.end.endOf("day") : null
            };
            if (dates.preset !== "custom") {
                change.preset = dates.preset;
            }
        }

        this.presetFormGroup.updateValueAndValidity();
        this.dateForm.get("dates").updateValueAndValidity();

        if (change.preset !== "custom" && change.preset !== "none") {
            this._loggerService
                .getLogger(LoggerType.FALCON)
                .recordMetric("date-range-picker", change.preset, 1);
        } else if (change.start && change.end) {
            this._loggerService
                .getLogger(LoggerType.FALCON)
                .recordMetric(
                    "date-range-picker",
                    change.end.diff(change.start, "day"),
                    1
                );
        }

        if (this._onChange) {
            this._onChange(change);
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.disabled) {
            this.presetFormGroup.disable();
            this.dateForm.disable();
        } else {
            this.presetFormGroup.enable();
            this.dateForm.enable();
        }
    }

    writeValue(dates: {
        start?: dayjs.Dayjs;
        end?: dayjs.Dayjs;
        preset?: string;
    }): void {
        if (dates != null) {
            let preset: DateRangePreset;
            if (dates.preset) {
                preset = this.datePresets.find(
                    (datePreset) => datePreset.id === dates.preset
                );
            } else if (dates.start && dates.end) {
                preset = this._getPresetFromDates(dates.start, dates.end);
            }

            if (preset && preset.id !== "custom") {
                this.dateForm.patchValue(
                    {
                        preset: {
                            value: preset.id
                        },
                        dates: {
                            start: preset.start,
                            end: preset.end
                        }
                    },
                    { emitEvent: false }
                );
                this.startDate = preset.start;
                this.endDate = preset.end;
                this.datePreset = preset.id;
            } else if (dates.start || dates.end) {
                this.dateForm.patchValue(
                    {
                        preset: {
                            value: "custom"
                        },
                        dates: {
                            start: dates.start,
                            end: dates.end
                        }
                    },
                    { emitEvent: false }
                );
                this.startDate = dates.start;
                this.endDate = dates.end;
            }
        }

        if (
            dates === null ||
            dates === undefined ||
            (!dates.preset && !dates.start && !dates.end)
        ) {
            this.dateForm.patchValue(
                {
                    preset: {
                        value: null
                    },
                    dates: {
                        start: null,
                        end: null
                    }
                },
                { emitEvent: false }
            );
            this.dateForm.markAsPristine();
        }
    }

    validateRequired(control: AbstractControl) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator?.required) {
                this._setRequired();
            }
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Check for required validator
        let valid: ValidationErrors | null = null;
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator?.required) {
                this._setRequired();
                if (
                    !control.value?.preset ||
                    !control.value?.start ||
                    !control.value?.end
                ) {
                    valid = { invalid: true };
                }
            }
        }
        if (
            !this.dateFormGroup.pristine &&
            this.dateFormGroup.status === "INVALID"
        ) {
            valid = { invalid: true };
        }

        return valid;
    }

    /**  Private Methods  **/
    private _setRequired() {
        this.presetFormGroup.controls["value"].addValidators(
            Validators.required
        );
        this.dateFormGroup.controls["start"].addValidators(Validators.required);
        this.dateFormGroup.controls["end"].addValidators(Validators.required);
    }

    private _setDateRange(
        start: dayjs.Dayjs,
        end: dayjs.Dayjs,
        preset: string
    ) {
        this.startDate = start;
        this.endDate = end;
        this.datePreset = preset;

        this._updateDatesFromPreset(this.datePreset);
        this.writeValue({ start, end, preset });
    }

    private _setDefaultEndStart() {
        let set = false;
        let preset;
        if (this.defaultEndDate) {
            this.endDate = this.defaultEndDate;
            set = true;
        }
        if (this.defaultStartDate) {
            this.startDate = this.defaultStartDate;
            set = true;
        }
        if (set) {
            if (!this.defaultPreset && this.endDate && this.startDate) {
                preset = this._datePresetService.getPreset(
                    this.startDate,
                    this.endDate,
                    this.datePresets
                );
                if (preset) {
                    this.datePreset = preset ? preset.id : null;
                }
            }
        }
    }

    private _updateDatesFromPreset(preset: string | Partial<DateRangePreset>) {
        if (typeof preset === "string") {
            preset = this.datePresets.find(
                (datePreset) => datePreset.id === preset
            );
        }

        if (preset) {
            this.dateForm.patchValue(
                {
                    preset: {
                        value: preset.id
                    },
                    dates: {
                        start: preset.start,
                        end: preset.end
                    }
                },
                { emitEvent: false, onlySelf: true }
            );
        }

        return preset;
    }

    private _getPresetFromDates(
        start: dayjs.Dayjs,
        end: dayjs.Dayjs
    ): DateRangePreset {
        if (start && end) {
            for (let preset of this.datePresets) {
                if (
                    start.isSame(preset.start, "day") &&
                    end.isSame(preset.end, "day")
                ) {
                    return preset;
                }
            }
        }

        return {
            id: "custom",
            text: "Custom",
            start: null,
            end: null
        };
    }

    private _resetState() {
        this.startDateTooltip.close();
        this.endDateTooltip.close();
    }

    private _updateTooltipsByRange(dateChange: {
        preset: string;
        start: dayjs.Dayjs;
        end: dayjs.Dayjs;
    }) {
        if (dateChange.preset === "custom") {
            if (!dateChange.start) {
                this.startDateTooltip.open();
            } else {
                this.startDateTooltip.close();
            }

            if (!dateChange.end) {
                this.endDateTooltip.open();
            } else {
                this.endDateTooltip.close();
            }
        } else {
            this.startDateTooltip.close();
            this.endDateTooltip.close();
        }
    }

    private _updateTooltipsByValues(dateChange: {
        preset: string;
        start: dayjs.Dayjs;
        end: dayjs.Dayjs;
    }) {
        if (!dateChange.start && !dateChange.end) {
            this.startDateTooltip.close();
            this.endDateTooltip.close();
            this.dateForm.get("dates").get("start").markAsPristine();
            this.dateForm.get("dates").get("end").markAsPristine();
            this.dateForm
                .get("preset")
                .get("value")
                .setValue("none", { emitEvent: false });
        } else {
            if (!dateChange.start) {
                this.startDateTooltip.open();
                this.dateForm.get("dates").get("start").markAsDirty();
            } else {
                this.startDateTooltip.close();
            }

            if (!dateChange.end) {
                this.endDateTooltip.open();
                this.dateForm.get("dates").get("end").markAsDirty();
            } else {
                this.endDateTooltip.close();
            }
        }
    }
}
