import {
    AbstractControl,
    AbstractControlOptions,
    AsyncValidatorFn,
    FormGroup,
    ValidatorFn
} from "@angular/forms";
import { FieldType } from "../enums/field-type.enum";
import {
    DynamicField,
    DynamicFormGroupControl,
    FieldVisibility,
    GroupField,
    GroupFieldVisibility
} from "../interfaces";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { applyMixins } from "@sf/common";
import { AbstractDynamicFormControl } from "./abstract-dynamic-form-control";
import { map } from "rxjs/operators";
import { Type } from "@angular/core";
import { InputComponent } from "../components";

export class DynamicFormGroup
    extends FormGroup
    implements AbstractDynamicFormControl, DynamicFormGroupControl
{
    type = FieldType.GROUP;
    isVisible = true;
    fieldOrder: string[] = [];
    dynamicField: DynamicField;
    expanded: boolean;
    expandable: boolean;
    collapsedMessage: string | (() => string);
    private _viewChanged: BehaviorSubject<null> = new BehaviorSubject(null);

    setIsVisible: (isVisible: boolean) => void;
    setComponent: (component: Type<InputComponent>) => void;
    checkVisibility: (viewState: FieldVisibility) => void;
    checkForChanges: () => void;
    checkForChanges$ = new Subject<void>();

    constructor(
        controls: {
            [key: string]: AbstractControl;
        },
        validatorOrOpts?:
            | ValidatorFn
            | ValidatorFn[]
            | AbstractControlOptions
            | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        for (let key in this.controls) {
            if (this.controls.hasOwnProperty(key)) {
                this.fieldOrder.push(key);
            }
        }
    }

    get visibleControls(): Observable<AbstractControl[]> {
        return this._viewChanged.pipe(
            map(() => {
                return this.getVisibleControls();
            })
        );
    }

    setExpandability(field: GroupField) {
        this.expanded = field.expanded;
        this.expandable = field.expandable;
        this.collapsedMessage =
            typeof field.collapsedMessage === "function"
                ? field.collapsedMessage.bind(this)
                : field.collapsedMessage;
    }

    setDynamicField(field: GroupField) {
        this.dynamicField = field;
    }

    onVisibilityChange(viewState: FieldVisibility) {
        if (viewState.visibility) {
            this.checkVisibility(viewState);
        }

        let emitVisibilityChange = false;
        for (let controlName in this.controls) {
            if (this.controls.hasOwnProperty(controlName)) {
                if (
                    (this.controls[controlName] as any).isVisible !==
                    (viewState as GroupFieldVisibility).fields[controlName]
                        .visibility.visible
                ) {
                    emitVisibilityChange = true;
                }

                if (this.controls[controlName]) {
                    (this.controls[controlName] as any).onVisibilityChange(
                        (viewState as GroupFieldVisibility).fields[controlName]
                    );
                }
            }
        }

        if (emitVisibilityChange) {
            this._viewChanged.next(null);
        }
    }

    getVisibleControls(): AbstractControl[] {
        return this.getOrderedControls().filter((control: any) => {
            return control.isVisible;
        });
    }

    getOrderedControls(): AbstractControl[] {
        const controls = [];

        for (let controlName of this.fieldOrder) {
            controls.push(this.controls[controlName]);
        }

        return controls;
    }

    registerControl(name: string, control: AbstractControl): AbstractControl {
        if (!this.controls[name]) {
            this.fieldOrder.push(name);
        }
        return super.registerControl(name, control);
    }

    removeControl(name: string): void {
        const index = this.fieldOrder.indexOf(name, 0);
        if (index > -1) {
            this.fieldOrder.splice(index, 1);
        }
        super.removeControl(name);
    }
}
applyMixins(DynamicFormGroup, [AbstractDynamicFormControl]);
