import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PortalModule } from "@angular/cdk/portal";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
    NgbTimepickerModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { DynamicFormComponent } from "./components";
import { TextFieldComponent } from "./fields/text-field/text-field.component";
import { SelectFieldComponent } from "./fields/select-field/select-field.component";
import { CheckboxFieldComponent } from "./fields/checkbox-field/checkbox-field.component";
import { ArrayFieldComponent } from "./fields/array-field/array-field.component";
import { GroupFieldComponent } from "./fields/group-field/group-field.component";
import { CurrencyFieldComponent } from "./fields/currency-field/currency-field.component";
import { DateFieldComponent } from "./fields/date-field/date-field.component";
import { DateRangeFieldComponent } from "./fields/date-range-field/date-range-field.component";
import { NumericFieldComponent } from "./fields/numeric-field/numeric-field.component";
import { RadioFieldComponent } from "./fields/radio-field/radio-field.component";
import { ArrayFieldItemWrapperComponent } from "./fields/array-field-item-wrapper/array-field-item-wrapper.component";
import { HiddenFieldComponent } from "./fields/hidden-field/hidden-field.component";
import { DynamicFormBuilder } from "./services/dynamic-form-builder";
import { DYNAMIC_FORM_BUILDER } from "./interfaces";
import { COMPONENT_FROM_FIELD } from "./helpers";
import { getComponentFromField } from "./helpers";
import { SelectModule } from "@sf/common";
import { DateAndTimeModule } from "@sf/common";
import { RootFieldComponent } from "./fields/root-field/root-field.component";
import { FormErrorComponent } from "./components/form-error/form-error.component";
import { TimeFieldComponent } from "./fields/time-field/time-field.component";
import { SortableFieldComponent } from "./fields/sortable-field/sortable-field.component";
import { SfCommonModule } from "@sf/common";
import { RemoveRowButtonComponent } from "./fields/sortable-field/remove-row-button/remove-row-button.component";
import { ParagraphFieldComponent } from "./fields/paragraph-field/paragraph-field.component";
import { UploadFieldComponent } from "./fields/upload-field/upload-field.component";

@NgModule({
    declarations: [
        DynamicFormComponent,
        TextFieldComponent,
        SelectFieldComponent,
        CheckboxFieldComponent,
        ArrayFieldComponent,
        GroupFieldComponent,
        CurrencyFieldComponent,
        DateFieldComponent,
        DateRangeFieldComponent,
        NumericFieldComponent,
        RadioFieldComponent,
        ArrayFieldItemWrapperComponent,
        HiddenFieldComponent,
        RootFieldComponent,
        FormErrorComponent,
        TimeFieldComponent,
        SortableFieldComponent,
        RemoveRowButtonComponent,
        ParagraphFieldComponent,
        UploadFieldComponent
    ],
    imports: [
        CommonModule,
        PortalModule,
        ReactiveFormsModule,
        SelectModule,
        FontAwesomeModule,
        NgbTimepickerModule,
        DateAndTimeModule,
        NgbTooltipModule,
        SfCommonModule
    ],
    exports: [RootFieldComponent, DynamicFormComponent, FormErrorComponent],
    providers: [
        {
            provide: DYNAMIC_FORM_BUILDER,
            useExisting: DynamicFormBuilder
        },
        {
            provide: COMPONENT_FROM_FIELD,
            useValue: getComponentFromField
        }
    ]
})
export class DynamicFormViewerModule {}
