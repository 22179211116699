import { Component, Input, OnInit } from "@angular/core";
import {
    ConfirmationModalComponent,
    GrowlService,
    ModalButton,
    SessionService
} from "@sf/common";
import { first } from "rxjs/operators";
import {
    BankAccount,
    Organization,
    OrganizationService,
    PaymentAccount,
    PaymentAccountsService,
    PaymentAccountType
} from "@sf/userorg/common";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";

interface SelectListItem {
    option: string;
    label: string;
}

interface OrganizationAccounts {
    org: SelectListItem;
    accounts: BankAccount[];
}

@Component({
    selector: "sf-import-payment-account-dialog",
    templateUrl: "./import-payment-account-dialog.component.html",
    styleUrls: ["./import-payment-account-dialog.component.scss"]
})
export class ImportPaymentAccountDialogComponent implements OnInit {
    @Input()
    organizationId: string; //should be the org that accounts would be imported into

    //for reference in HTML
    get paymentAccountType() {
        return PaymentAccountType;
    }

    /** PRIVATE **/
    private _orgList: SelectListItem[] = [];
    private _selectedItems: SelectListItem[];
    private _selectedAccounts: PaymentAccount[];

    /** PUBLIC **/
    modalTitle: string;
    primaryButton: ModalButton;
    secondaryButton: ModalButton;
    isSuperUser: boolean;
    selectedOrgIDs: string[] = null;
    orgList: SelectListItem[];
    organizationAccounts: OrganizationAccounts[] = [];
    orgsLoading: boolean = true;
    accountsLoading: boolean = false;
    warning: string;
    validatingAccount: boolean = false;
    allowFrostAccounts: boolean = false;

    constructor(
        private _sessionService: SessionService,
        private _orgService: OrganizationService,
        private _paymentAccountService: PaymentAccountsService,
        private _modalService: NgbModal,
        private _growlService: GrowlService,
        private _activeModal: NgbActiveModal
    ) {
        this.modalTitle = "Import Payment Accounts";
        this.primaryButton = {
            text: "Import Accounts",
            callback: this._importAccounts.bind(this)
        };
        this.secondaryButton = {
            text: "Cancel"
        };
        this.isSuperUser = this._sessionService.isSuperUser();
    }

    ngOnInit() {
        //see if org allows legal ease (frost) accounts
        this._orgService
            .getOrganization(this.organizationId)
            .subscribe((org: Organization) => {
                if (org?.address?.state == "TX") this.allowFrostAccounts = true;
            });

        //as a super-user, can only get org list from hierarchy...if there is one; if not, there won't be a list to select from
        if (this.isSuperUser) {
            //get hierarchy for given org
            this._orgService
                .getOrganizationTree(this.organizationId)
                .pipe(first())
                .subscribe((tree: any) => {
                    if (tree) {
                        if (tree.root.id !== this.organizationId) {
                            this._orgList.push({
                                option: tree.root.id,
                                label: tree.root.name
                            });
                        }
                        if (
                            tree.root.children &&
                            tree.root.children.length > 0
                        ) {
                            tree.root.children.forEach((child: any) => {
                                this._getListFromChild(child);
                            });
                        }
                        this._setOptionsList();
                    } else {
                        //nothing but existing org, and it cannot import from itself
                        this.primaryButton.hidden = true;
                    }
                    this.orgsLoading = false;
                });
        } else {
            //get associated orgs for user, minus given org - checking permission
            this._sessionService
                .getAllOrganizations()
                .filter((org) => {
                    return this._sessionService.hasPermission(
                        "organization_accounting",
                        org.id
                    );
                })
                .filter((org) => {
                    return org.id != this.organizationId;
                })
                .forEach((org) => {
                    this._orgList.push({ option: org.id, label: org.name });
                });
            if (this._orgList && this._orgList.length > 0) {
                this._setOptionsList();
            } else {
                //nothing but existing org, and it cannot import from itself
                this.primaryButton.hidden = true;
            }
            this.orgsLoading = false;
        }

        this._selectedItems = [];
        this._selectedAccounts = [];
    }

    /** PRIVATE **/

    private _setOptionsList() {
        //set select list options to sorted list
        this.orgList = this._orgList.sort(
            (option1: SelectListItem, option2: SelectListItem): number => {
                return option1.label.localeCompare(option2.label);
            }
        );
    }
    private _getListFromChild(childOrg: any) {
        if (childOrg.id && childOrg.id !== this.organizationId) {
            this._orgList.push({ option: childOrg.id, label: childOrg.name });
        }
        if (childOrg.children && childOrg.children.length > 0) {
            childOrg.children.forEach((child: any) => {
                this._getListFromChild(child);
            });
        }
    }

    private _getBankAccounts() {
        this.accountsLoading = true;
        this.organizationAccounts = [];

        // OLD WAY - one at a time
        // const obs = this._selectedItems.map((org) => {
        //     return this._paymentAccountService
        //         .getPaymentAccounts(org.option, true)
        //         .pipe(
        //             map((accounts: PaymentAccount[]) => {
        //                 return { org, accounts };
        //             })
        //         );
        // });
        //
        // forkJoin(...obs).subscribe((results: OrganizationAccounts[]) => {
        //     this.organizationAccounts = results;
        //     this.accountsLoading = false;
        // });

        //NEW WAY - all at once
        this._paymentAccountService
            .getPaymentAccountsForOrgs(this.selectedOrgIDs, true)
            .pipe(first())
            .subscribe((results: any[]) => {
                //the select list items has the org name in it already, so we build up the same data as before, matching on the org id
                results.forEach((orgInfo: any) => {
                    orgInfo.org = this._selectedItems.find(
                        (value: SelectListItem) => {
                            return value.option == orgInfo.org;
                        }
                    );
                });
                this.organizationAccounts = results;
                this.accountsLoading = false;
            });
    }

    private _validateSelections() {
        this.warning = null;
        if (this._selectedItems.length == 0) {
            this.warning = "Select organization(s) to import from";
            return;
        }
        if (this._selectedAccounts.length == 0) {
            this.warning = "Select account(s) to import";
            return;
        }
    }

    private _importAccounts() {
        this._validateSelections();
        if (this.warning) return;

        let bankAccountIDs: string[] = this._selectedAccounts.map(
            (account: PaymentAccount) => {
                if (account.paymentAccountType == PaymentAccountType.BANK)
                    return (account as BankAccount).bankAccountID;
                else return account.paymentAccountID;
            }
        );
        this._paymentAccountService
            .importPaymentAccounts(this.organizationId, bankAccountIDs)
            .pipe(first())
            .subscribe(
                (success: boolean) => {
                    if (success) {
                        this._growlService.success(
                            bankAccountIDs.length +
                                " payment accounts imported."
                        );
                        this._closeDialog(false);
                    }
                },
                (error) => {
                    this._growlService.error(error.error.errorMessage, null, {
                        disableTimeOut: true
                    });
                }
            );
    }

    private _validateAccount(
        account: PaymentAccount,
        checkbox: HTMLInputElement
    ) {
        let accountID: string =
            account.paymentAccountType == PaymentAccountType.BANK
                ? (account as BankAccount).bankAccountID
                : account.paymentAccountID;
        //set flag to disable other checkboxes
        this.validatingAccount = true;
        this._paymentAccountService
            .importCheckForDuplicate(this.organizationId, accountID)
            .pipe(first())
            .subscribe(
                (result: any) => {
                    if (!!result) {
                        this._showConfirmDuplicateDialog(account, checkbox);
                    } else {
                        //wasn't a duplicate, add to accounts to import
                        this._selectedAccounts.push(account);
                        this.validatingAccount = false;
                    }
                },
                (error) => {
                    this.validatingAccount = false;
                }
            );
    }

    private _showConfirmDuplicateDialog(
        account: PaymentAccount,
        checkbox: HTMLInputElement
    ) {
        let accountLabel: string =
            account.paymentAccountType == PaymentAccountType.BANK
                ? (account as BankAccount).accountLabel
                : account.label;
        const modalRef: NgbModalRef = this._modalService.open(
            ConfirmationModalComponent,
            { backdrop: "static" }
        );
        const modalInstance = modalRef.componentInstance;
        modalInstance.title = "Duplicate Payment Account";
        // modalInstance.primary = {
        //     text: action + " Account"
        // };
        // modalInstance.secondary = {
        //     text: "Cancel"
        // };
        modalInstance.message =
            "<b>" +
            accountLabel +
            "</b><br/><br/>" +
            "is already setup as a payment account for this organization.<br/>" +
            "Are you sure you want to import and duplicate this account?";

        modalRef.result.then(
            (result: any) => {
                if (result === "primary") {
                    this._selectedAccounts.push(account);
                } else {
                    checkbox.checked = false;
                }
                this.validatingAccount = false;
            },
            () => {
                // cancel
            }
        );
    }

    /**
     * Handles dismissing the active dialog - can be executed as needed, or when secondary button is clicked
     * @param dismiss - boolean; if true, the dialog is dismissed rather than closed
     * @private
     */
    private _closeDialog(dismiss: boolean) {
        if (dismiss) {
            this._activeModal.dismiss();
        } else {
            this._activeModal.close();
        }
    }

    /** PUBLIC **/

    handleOrgSelection(event: any) {
        if (event.$isSelectionChanged && event.$selection) {
            this._selectedItems = event.$selection;
            this.selectedOrgIDs = this._selectedItems.map(
                (selection: SelectListItem) => selection.option
            );
            this._selectedAccounts = []; //reset this list when orgs change
            this._getBankAccounts();
        }
        if (this.warning) this.warning = null;
    }

    handleAccountClick($event: MouseEvent, account: PaymentAccount) {
        let checkbox: HTMLInputElement = $event.target as HTMLInputElement;
        let id1: string =
            account.paymentAccountType == PaymentAccountType.BANK
                ? (account as BankAccount).bankAccountID
                : account.paymentAccountID;
        //remove it first, if needed - then add if checked (handles unchecking)
        if (this._selectedAccounts.length > 0) {
            this._selectedAccounts = this._selectedAccounts.filter(
                (acct: PaymentAccount) => {
                    let id2: string =
                        acct.paymentAccountType == PaymentAccountType.BANK
                            ? (acct as BankAccount).bankAccountID
                            : acct.paymentAccountID;
                    return id1 !== id2;
                }
            );
        }
        if (checkbox.checked) {
            this._validateAccount(account, checkbox);
        }
        if (this.warning) this.warning = null;
    }

    getAccountTypeDesc(type: PaymentAccountType): string {
        switch (type) {
            case PaymentAccountType.CHECKING:
                return "ACH";
            case PaymentAccountType.E_CHECK:
                return "eCheck";
            case PaymentAccountType.GENERAL_LEDGER:
                return "General Ledger";
            case PaymentAccountType.SAVINGS:
                return "Savings Account";
            case PaymentAccountType.FROST_BANK:
                return "LegalEase";
        }
    }
}
