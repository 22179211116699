import { Component, OnInit } from "@angular/core";

@Component({
    selector: "sf-blank-layout",
    templateUrl: "./blank-layout.component.html",
    styleUrls: ["./blank-layout.component.scss"]
})
export class BlankLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
