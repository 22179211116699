import { startCase } from "@sf/common";
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ColDef, GridApi, GridOptions } from "@ag-grid-community/core";
import { DatePresetService } from "@sf/common";
import { OrganizationHistoryService } from "../../services/organization-history.service";
import { TemplateRendererComponent } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseModalComponent } from "@sf/common";
import { DateRangePreset } from "@sf/common";

interface HistoryEvent {
    id: number;
    date: string;
    details: string;
    event: string;
    organization: string;
    user: string;
    userID: string;
}

interface HistoryFilter {
    events: string[];
    eventOptions: any[];
    users: string[];
    userOptions: any[];
    details: string;
}

const presetIds: string[] = [
    "today",
    "yesterday",
    "this_week",
    "last_week",
    "next_week",
    "this_month",
    "last_month",
    "this_year",
    "last_year",
    "custom"
];

@Component({
    selector: "sf-organization-history",
    templateUrl: "./organization-history.component.html",
    styleUrls: ["./organization-history.component.scss"]
})
export class OrganizationHistoryComponent implements OnInit {
    /** Public Variables **/
    orgID: string = null;
    history: HistoryEvent[] = [];
    historyCount: number = null;
    presets: DateRangePreset[] = null;
    dateSelections: DateRangePreset = null;
    gridApi: GridApi;
    gridOptions: GridOptions;
    columnDefs: ColDef[];
    textDate: Date = null;
    filter: HistoryFilter = {
        events: null,
        eventOptions: null,
        users: null,
        userOptions: null,
        details: null
    };

    /** Private Variables **/
    private _ngOnDestroy$: Subject<void> = new Subject();
    private _modalInstance: any;

    /** I/O **/
    @Input()
    refreshHistory$: Subject<any> = new Subject();

    /** View Children **/
    @ViewChild("eventTemplate") eventTemplate: TemplateRef<any>;
    @ViewChild("cellTemplate") cellTemplate: TemplateRef<any>;
    @ViewChild("dateTemplate") dateTemplate: TemplateRef<any>;
    @ViewChild("dateModal") dateModalTemplate: TemplateRef<any>;
    @ViewChild("filterModal") filterModalTemplate: TemplateRef<any>;

    constructor(
        private _route: ActivatedRoute,
        private _datePresetService: DatePresetService,
        private _organizationHistoryService: OrganizationHistoryService,
        private _modalService: NgbModal
    ) {}

    ngOnInit() {
        this.gridOptions = this._getGridOptions();
        this.columnDefs = this._getColDefs();
        this.presets = this._getPresets();

        this._route.paramMap
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe((params) => {
                this.orgID = params.get("orgID");
                this.refreshHistory();
            });

        this.refreshHistory$
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe(() => {
                this.refreshHistory();
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy$.next();
    }

    /** Public Methods **/

    refreshHistory() {
        this.filter.eventOptions = [];
        this.filter.events = [];
        this.filter.userOptions = [];
        this.filter.users = [];
        this._organizationHistoryService
            .getOrganizationHistory(
                this.orgID,
                this.dateSelections.start.toDate(),
                this.dateSelections.end.toDate(),
                this.filter.events,
                this.filter.users,
                this.filter.details,
                null
            )
            .subscribe((result: any) => {
                this.history = result.historyEvents;
                this.historyCount = result.historyCount;
                let temp: any[] = [];
                //set options for sf select of unique events and users
                this.history.map((obj: any) => {
                    if (temp.indexOf(obj.event) === -1) {
                        temp.push(obj.event);
                        this.filter.eventOptions.push({
                            id: obj.event,
                            label: startCase(obj.event)
                        });
                    }
                    if (temp.indexOf(obj.user) === -1) {
                        temp.push(obj.user);
                        this.filter.userOptions.push({
                            id: obj.user,
                            label: obj.user
                        });
                    }
                });
            });
    }

    onGridReady(event: any) {
        this.gridApi = event.api;
        this.gridOptions.api.setColumnDefs(this._getColDefs());
    }

    showFilter() {
        this._modalInstance =
            this._modalService.open(BaseModalComponent).componentInstance;
        this._modalInstance.title = "Filter";
        this._modalInstance.template = this.filterModalTemplate;
        this._modalInstance.primary = {
            text: "Save",
            callback: this._saveFilter.bind(this)
        };
        this._modalInstance.secondary = {
            text: "Clear",
            callback: this._clearFilter.bind(this)
        };
    }

    clickDate(item: Date) {
        this.textDate = item;
        this._modalInstance =
            this._modalService.open(BaseModalComponent).componentInstance;
        this._modalInstance.title = "Event Date and Time";
        this._modalInstance.template = this.dateModalTemplate;
        this._modalInstance.secondary = { text: "Close", responseValue: -1 };
    }

    displayEvent(event: string) {
        return startCase(event);
    }

    /** Private Methods **/

    private _getPresets(): DateRangePreset[] {
        let presets = this._datePresetService.getSpecificDatePresets(presetIds);
        presets.forEach((preset: DateRangePreset) => {
            if (preset.id == "this_year") this.dateSelections = preset;
        });
        return presets;
    }

    private _saveFilter(): boolean {
        this._organizationHistoryService
            .getOrganizationHistory(
                this.orgID,
                this.dateSelections.start.toDate(),
                this.dateSelections.end.toDate(),
                this.filter.events,
                this.filter.users,
                this.filter.details,
                null
            )
            .subscribe((result: any) => {
                this.history = result.historyEvents;
                this.historyCount = result.historyCount;
            });
        return true;
    }

    private _clearFilter(): boolean {
        this.filter.events = [];
        this.filter.users = [];
        this.filter.details = null;
        return this._saveFilter();
    }

    private _getColDefs(): ColDef[] {
        return [
            {
                headerName: "Event",
                field: "event",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.eventTemplate
                },
                headerTooltip: "Event Type",
                minWidth: 100,
                width: 300
            },
            {
                headerName: "Date",
                field: "date",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.dateTemplate
                },
                headerTooltip: "Event Date",
                minWidth: 50,
                width: 300
            },
            {
                headerName: "User",
                field: "user",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.cellTemplate
                },
                headerTooltip: "User",
                minWidth: 50,
                width: 300
            },
            {
                headerName: "Details",
                field: "details",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.cellTemplate
                },
                cellStyle: { wordBreak: "normal" },
                flex: 1,
                wrapText: true,
                autoHeight: true,
                headerTooltip: "Event Details",
                minWidth: 200,
                width: 900
            }
        ];
    }

    private _getGridOptions(): GridOptions {
        return {
            headerHeight: 30,
            suppressCellFocus: true,
            defaultColDef: {
                sortable: true
            }
        };
    }
}
