import { Injectable } from "@angular/core";
import { Metric } from "../interfaces/metric.interface";
import { LoggerService } from "./logger.service";
import { ExternalPageService } from "./external-page.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoggerType } from "../enums/logger-type.enum";

export const LOCAL_STORAGE_LAST_SENT_TIME_NAME = "sfMetricsLastSentTimestamp";
export const LOCAL_STORAGE_METRICS_NAME = "sfMetrics";

@Injectable({
    providedIn: "root"
})
export class MetricLoggerService {
    static loggers: any = {};
    LOGMETRICS_PATH = "/sf/userorg/log-metrics/";
    private _sendThreshold = 10000;
    private _staleThreshold = 60000;
    private _metrics: Metric[] = [];
    private _lastSentTime: number;

    // used to keep track of which singleton instance of the LoggerService this instance of MetricLoggerService is using
    private _currentLogger: LoggerService;

    // IMPORTANT: don't place _currentLogger in the constructor, angular gets angry
    constructor(
        private _logger: LoggerService,
        private _externalPageService: ExternalPageService,
        private _http: HttpClient
    ) {
        this._loadLastSentTimeFromStorage();
        this._loadMetricsFromStorage();
    }

    record(
        group: string,
        metric: string,
        value: number,
        allowQueue?: boolean
    ): void {
        if (allowQueue == undefined) allowQueue = true;
        this._getCurrentLogger().debug(
            "recording metric:",
            group,
            metric,
            value
        );
        let now = new Date().getTime();

        if (!this._getLastSentTime()) {
            this._setLastSentTime(now);
        }

        let elapsedTime = now - this._getLastSentTime();

        if (elapsedTime < this._sendThreshold) {
            // The data is premature and should be incubated
            this._recordMetricLocally(group, metric, value);
            if (!allowQueue) this._sendStats();
        } else if (elapsedTime < this._staleThreshold) {
            // The data is ripe and should be sent
            this._recordMetricLocally(group, metric, value);
            this._sendStats();
        } else {
            // The data is stale and should be removed
            this._getCurrentLogger().debug("Stale stats data, removing...");
            this._clearMetrics();
            this._setLastSentTime(now);
            this._recordMetricLocally(group, metric, value);
            if (!allowQueue) this._sendStats();
        }
    }

    recordMetric(
        group: string,
        metric: string,
        value: number,
        allowQueue?: boolean
    ): void {
        if (allowQueue == undefined) allowQueue = true;
        this.record(group, metric, value, allowQueue);
    }

    getLogger(loggerName: LoggerType) {
        if (!MetricLoggerService.loggers[loggerName]) {
            MetricLoggerService.loggers[loggerName] = new MetricLoggerService(
                this._logger,
                this._externalPageService,
                this._http
            );

            MetricLoggerService.loggers[loggerName].setLogger(loggerName);
        }
        return MetricLoggerService.loggers[loggerName];
    }

    setLogger(loggerName: LoggerType) {
        this._currentLogger = LoggerService.loggers[loggerName]
            ? LoggerService.loggers[loggerName]
            : this._logger.getLogger(loggerName);
        this._currentLogger.setLogger(loggerName);
    }

    private _getCurrentLogger() {
        return this._currentLogger ? this._currentLogger : this._logger;
    }

    private _loadLastSentTimeFromStorage() {
        let lastSentTimeString = "";
        if (this._sessionStorageAvailable()) {
            lastSentTimeString = sessionStorage.getItem(
                LOCAL_STORAGE_LAST_SENT_TIME_NAME
            );
        }
        let lastSentTimeInt = parseInt(lastSentTimeString, 10);
        if (isFinite(lastSentTimeInt)) {
            this._setLastSentTime(lastSentTimeInt);
        } else {
            this._setLastSentTime(new Date().getTime());
        }
    }

    private _loadMetricsFromStorage() {
        let rawMetricsString = "{}";
        if (this._sessionStorageAvailable()) {
            rawMetricsString = sessionStorage.getItem(
                LOCAL_STORAGE_METRICS_NAME
            );
        }

        let metrics: any = [];
        try {
            JSON.parse(rawMetricsString);
        } catch (_exception) {}
        if (!!metrics && Array.isArray(metrics)) {
            this._setMetrics(metrics);
        } else {
            this._setMetrics([]);
        }
    }

    private _sessionStorageAvailable() {
        const test = "test";
        try {
            sessionStorage.setItem(test, test);
            sessionStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    private _setMetrics(metrics: any[]) {
        this._metrics = metrics;
        if (this._sessionStorageAvailable() && metrics && metrics.length > 0) {
            sessionStorage.setItem(
                LOCAL_STORAGE_METRICS_NAME,
                JSON.stringify(this._metrics)
            );
        }
    }

    private _clearMetrics() {
        this._metrics = [];
        if (this._sessionStorageAvailable()) {
            sessionStorage.removeItem(LOCAL_STORAGE_METRICS_NAME);
        }
    }

    private _getMetrics() {
        return this._metrics;
    }

    private _getLastSentTime(): number {
        return this._lastSentTime;
    }

    private _setLastSentTime(lastSentTime: number): void {
        this._lastSentTime = lastSentTime;
        if (this._sessionStorageAvailable()) {
            sessionStorage.setItem(
                LOCAL_STORAGE_LAST_SENT_TIME_NAME,
                JSON.stringify(this._lastSentTime)
            );
        }
    }

    private _sendStats() {
        if (sf.session && !this._externalPageService.isCurrentPageExternal()) {
            const metrics = this._getMetrics();
            this.makeRequest("logMetrics", metrics).subscribe(() => {
                this._clearMetrics();
            });
            this._setLastSentTime(new Date().getTime());
        }
    }

    private _getVerificationToken() {
        if (
            location.pathname.match(
                /invitations\/(new-user|new-organization|defer-accept|self-signup|accept-license|new-service)/
            ) != null
        )
            return location.pathname.split("/").pop();
        else if (location.href.match(/AcceptInvite\.action\?token=/) != null)
            return new URL(location.href).searchParams.get("token");
        else return null;
    }

    private _recordMetricLocally(group: string, metric: string, value: number) {
        // Look for an existing metric
        const metrics = this._getMetrics();
        const existingMetric = metrics.find(function (l) {
            return l.group === group && l.metric === metric;
        });

        if (existingMetric) {
            existingMetric.count++;
            existingMetric.total += value;

            if (value > existingMetric.max) {
                existingMetric.max = value;
            }
        } else {
            let m = {
                group: group,
                metric: metric,
                max: value,
                count: 1,
                total: value,
                verificationToken: this._getVerificationToken() // for invitations
            };
            metrics.push(m);
        }

        this._setMetrics(metrics);

        // To log to analytics engines (Piwik, Matomo, OWA, etc.), uncomment the line below.
        // if (sf.analytics) sf.analytics.trackAction(group, metric, value);
    }

    private makeRequest(method: string, data?: any): Observable<any> {
        if (!data) {
            data = {};
        }
        return this._http.post(this.LOGMETRICS_PATH + method, data);
    }
}
