<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <div class="sf-submitter-default-file-name-dialog">
        <table class="sf-grid sf-grid-full-width table">
            <thead>
                <tr>
                    <td class="sf-grid-cell sf-grid-item-select-cell">
                        <input
                            type="checkbox"
                            id="selectAllElements"
                            class="form-check-input"
                            (click)="toggleSelectAll()"
                            [(ngModel)]="currentParsedStructure.hasAllElements"
                        />
                        <label
                            for="selectAllElements"
                            class="form-check-label"
                        >
                            <fa-icon icon="square" *ngIf="!currentParsedStructure.hasAllElements"></fa-icon>
                            <fa-icon icon="check-square" *ngIf="currentParsedStructure.hasAllElements"></fa-icon>
                        </label>
                    </td>
                    <td class="sf-grid-cell">Include</td>
                    <td class="sf-grid-cell">Edit Order</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of tableStructure; let i = index">
                    <td class="sf-grid-cell sf-grid-item-select-cell">
                        <input
                            type="checkbox"
                            id="selectRow{{ i }}"
                            class="item-select"
                            (click)="toggleElement(element.name)"
                            [(ngModel)]="element.include"
                        />
                        <label for="selectRow{{ i }}">
                            <fa-icon icon="square" *ngIf="!element.include"></fa-icon>
                            <fa-icon icon="check-square" *ngIf="element.include"></fa-icon>
                        </label>
                    </td>
                    <td class="sf-grid-cell">
                        {{ element.displayName }}
                    </td>
                    <td class="sf-grid-cell">
                        <div class="arrow-group">
                            <button
                                class="btn btn-link sf-btn-link arrow"
                                [disabled]="
                                    i === 0 ||
                                    !element.include ||
                                    !tableStructure[i - 1].include
                                "
                                (click)="moveElement(element.name, 'up')"
                            >
                                <fa-icon
                                    [icon]="['far', 'chevron-up']"
                                ></fa-icon>
                            </button>
                            <button
                                class="btn btn-link sf-btn-link arrow"
                                [disabled]="
                                    i === tableStructure.length - 1 ||
                                    !element.include ||
                                    !tableStructure[i + 1].include
                                "
                                (click)="moveElement(element.name, 'down')"
                            >
                                <fa-icon
                                    [icon]="['far', 'chevron-down']"
                                ></fa-icon>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <h4>Separators</h4>
        <sf-select
            [options]="separatorList"
            name="separatorsSelector"
            [selectedOption]="currentParsedStructure.separator"
            trackBy="name"
            labelBy="name"
            [isSingleSelect]="true"
            (select)="handleSeparatorSelection($event.$selection)"
        >
        </sf-select>

        <h4>Preview</h4>
        <div class="file-name-preview">{{ structurePreview }}</div>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
