import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { SessionService } from "./session.service";
import { Observable } from "rxjs";
import { isString } from "../helpers/string";
import { LoggerType } from "../enums/logger-type.enum";

@Injectable({
    providedIn: "root"
})
export class PersistentDataStackService {
    constructor(
        private configurationService: ConfigurationService,
        private sessionService: SessionService
    ) {}

    createNewStack(namespace: string, size: number) {
        return new PersistentDataStack(
            namespace,
            size,
            this.configurationService,
            this.sessionService
        );
    }
}

export class PersistentDataStack {
    private configurationService: ConfigurationService;
    private sessionService: SessionService;
    private readonly namespace: string;
    private readonly username: string;
    private _dataStack: DataStack;
    private initializer: Observable<string> = null;

    constructor(
        namespace: string,
        stackSize: number,
        configurationService: ConfigurationService,
        sessionService: SessionService
    ) {
        this._dataStack = new DataStack(stackSize);
        this.namespace = namespace;
        this.configurationService = configurationService;

        this.username =
            sessionService.getRootUser() || sessionService.getUsername();
        this.initializer = this.configurationService.getUserSettingCustom(
            this.username,
            this.namespace
        );
        this.initializer.subscribe((storedData: string) => {
            //log.warn("Stack read for " + namespace + " returned: " + storedData);
            if (storedData) {
                let data = JSON.parse(storedData);
                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        this._dataStack.push(item);
                    });
                } else {
                    // _dataStack will be empty, so nothing fatal, but show error message
                    log.getLogger(LoggerType.FALCON).error(
                        "PersistentStackService: Non-array data was saved"
                    );
                }
            }
            this.initializer = null;
        });
    }

    /**
     * Add a new entry to the stack
     */
    push(value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        this.realPush(value);
                        resolve();
                    }, 0);
                });
            } else {
                this.realPush(value);
                resolve();
            }
        });
    }

    private realPush(value: string): void {
        if (this._dataStack.getCount() == 0) {
            // it may be empty, but may be bug
            log.getLogger(LoggerType.FALCON).warn(
                "Stack " + this.namespace + " push " + value + " on empty stack"
            );
        }
        let changed = this._dataStack.push(value);
        if (changed) {
            this.saveStack();
        }
    }

    /**
     * remove a value from the stack
     * @param value
     */
    pull(value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        this.realPull(value);
                        resolve();
                    }, 0);
                });
            } else {
                this.realPull(value);
                resolve();
            }
        });
    }

    private realPull(value: string): void {
        let changed = this._dataStack.pull(value);
        //console.log("Pull: changed=" + (changed ? "true" : "false"));
        if (changed) {
            this.saveStack();
        }
    }

    /**
     * remove multiple values
     * @param values
     */
    pullMultiple(values: string[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        this.realPullMultiple(values);
                        resolve();
                    }, 0);
                });
            } else {
                this.realPullMultiple(values);
                resolve();
            }
        });
    }

    private realPullMultiple(values: string[]): void {
        let changed = this._dataStack.pullMultiple(values);
        if (changed) {
            return this.saveStack();
        }
    }

    /**
     * Get the most recent <count> items from the stack
     * count is optional. If missing, it gets all.
     */
    last(count?: number): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        let items = this.realLast(count);
                        resolve(items);
                    }, 0);
                });
            } else {
                let items = this.realLast(count);
                resolve(items);
            }
        });
    }

    private realLast(count: number): string[] {
        return this._dataStack.last(count);
    }

    /**
     * Get the most recently added item
     */
    peek(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        let item = this.realPeek();
                        resolve(item);
                    }, 0);
                });
            } else {
                let item = this.realPeek();
                resolve(item);
            }
        });
    }

    private realPeek(): string {
        return this._dataStack.peek();
    }

    /**
     * Get the item count
     */
    getCount(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            if (this.initializer) {
                this.initializer.subscribe(() => {
                    window.setTimeout(() => {
                        let count = this.realGetCount();
                        resolve(count);
                    }, 0);
                });
            } else {
                let count = this.realGetCount();
                resolve(count);
            }
        });
    }

    private realGetCount(): number {
        return this._dataStack.getCount();
    }

    private saveStack(): void {
        let data = this._dataStack.getData();
        let json = JSON.stringify(data);
        //log.warn("saveStack for " + this.namespace + " called with: " + json);
        this.configurationService
            .setUserSettingCustom(this.username, this.namespace, json)
            .subscribe(() => {
                //log.warn("saveStack for " + this.namespace + " finished");
            });
    }
}

/**
 *
 */
class DataStack {
    defaultSize = 10;
    _maxItems: number;
    _data: string[] = [];

    constructor(maxItems: number) {
        if (!maxItems || isNaN(maxItems) || maxItems < 1) {
            maxItems = this.defaultSize;
        }
        this._maxItems = maxItems;
    }

    /**
     * Add a new entry to the stack
     */
    push(value: any) {
        if (typeof value != "string" || !isString(value)) {
            log.getLogger(LoggerType.FALCON).warn(
                "Data must be of type string"
            );
            return false;
        }

        // see if value is already in the stack
        //let index = _data.indexOf(value);
        let index = this._data.indexOf(value); //use this method of getting index in case value is not a simple string
        if (index > -1) {
            if (index == this._data.length - 1) {
                return false; // already at the 'most recent' spot
            } else {
                // remove the existing item
                // because we will add it again at the end, to indicate more recent usage
                this._data.splice(index, 1);
            }
        }

        // add to end of stack
        this._data.push(value);

        // see if stack is too big now
        if (this._data.length > this._maxItems) {
            // clip off the oldest items
            let cutoffPoint = this._data.length - this._maxItems; // should always be 1
            this._data = this._data.slice(cutoffPoint);
        }

        return true;
    }

    /**
     * remove an entry from the stack
     */
    pull(value: string) {
        if (typeof value != "string" || !isString(value)) {
            log.getLogger(LoggerType.FALCON).warn(
                "Data must be of type string"
            );
            return false;
        }

        // see if value is in the stack
        //let index = _data.indexOf(value);
        let index = this._data.indexOf(value); //use this method of getting index in case value is not a simple string
        if (index > -1) {
            // remove it
            this._data.splice(index, 1);
        }

        return true;
    }

    /**
     * remove multiple entries from the stack
     */
    pullMultiple(values: string[]): boolean {
        let result = true;
        values.forEach((value) => {
            let localResult = this.pull(value);
            if (!localResult) {
                result = false;
            }
        });
        return result;
    }

    /**
     * Get the most recently added <count> items
     * Note that the most recently added items are at the bottom of the stack
     * If no 'count' parameter provided, it gets the whole stack
     */
    last(count?: number) {
        if (!count || isNaN(count) || count > this._data.length) {
            count = this._data.length;
        } else if (count < 0) {
            count = 0;
        }

        let cutPoint = 0;
        if (count < this._data.length) {
            cutPoint = this._data.length - count;
        }
        let part = this._data.slice(cutPoint); // always makes a copy

        // reverse the copied part of the stack, so that most recent items are on the top
        return part.reverse();
    }

    /**
     * Get the most recently added item
     */
    peek() {
        return this._data[this._data.length - 1];
    }

    /**
     * Get a copy of the whole stack
     */
    getData() {
        return this._data.slice(0);
    }

    /**
     * get the number of items
     */
    getCount(): number {
        return this._data.length;
    }
}
