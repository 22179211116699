import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { dayjs, RpcClientService } from "@sf/common";
import { StampData } from "../interfaces/stamp-data.interface";
import { UISupportToolsTemplateItem } from "../interfaces/ui-support-tools-template-item.interface";

@Injectable({
    providedIn: "root"
})
export class SupportService {
    private currentRecipient: any;
    private readonly currentRecipientSubject: BehaviorSubject<any>;

    constructor(private _rpcClient: RpcClientService) {
        this.currentRecipientSubject = new BehaviorSubject<any>(
            this.currentRecipient
        );
    }

    getCurrentRecipient$(): BehaviorSubject<any> {
        return this.currentRecipientSubject;
    }

    setCurrentRecipient(selectedRecipient: any) {
        this.currentRecipient = selectedRecipient;
        this.currentRecipientSubject.next(this.currentRecipient);
    }

    getRecipients(searchString: string) {
        return this._rpcClient.makeRequest("admin", "getRecipients", {
            recipient: searchString
        });
    }

    getSubmitters(searchString: string) {
        return this._rpcClient.makeRequest("admin", "getSubmitters", {
            submitter: searchString
        });
    }

    getOrganization(orgID: string) {
        return this._rpcClient.makeRequest("userOrg", "getOrganization", {
            orgID: orgID
        });
    }

    getRecipientInfo(orgID: string) {
        return this._rpcClient.makeRequest("admin", "getRecipientInfo", {
            recipientID: orgID
        });
    }

    getRecipientMessages(orgID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getRecipientMessagesFromID",
            {
                recipientID: orgID
            }
        );
    }

    getUsersByOrganization(orgID: string) {
        return this._rpcClient.makeRequest(
            "userOrg",
            "getBasicUsersByOrganization",
            {
                orgID: orgID
            }
        );
    }

    getPackages(searchParams: any) {
        return this._rpcClient.makeRequest("admin", "getAdminPackages", {
            search: searchParams
        });
    }

    doPackageAction(
        packageIDs: string[],
        action: string,
        recipientID: string,
        changeReason: string,
        submitterID?: string
    ) {
        let data: any = {
            packageIDs: packageIDs,
            action: action,
            recipientID: recipientID
        };
        if (changeReason) {
            data.changeReason = changeReason;
        }
        if (submitterID) {
            data.submitterUserID = submitterID;
        }
        return this._rpcClient.makeRequest("admin", "doPackageAction", data);
    }

    doPackageFunction(recipientFunction: string, packageID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "doRecipientPackageFunction",
            {
                packageID: packageID,
                recipientFunction: recipientFunction
            }
        );
    }

    doPackageFunctionByRecipient(
        recipientID: string,
        recipientFunction: string,
        packageID: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "doPackageFunctionByRecipient",
            {
                recipientID,
                packageID,
                recipientFunction
            }
        );
    }

    voidPackage(
        packageID: string,
        addVoidStamp: boolean,
        zeroFees: boolean,
        schedulePayments: boolean,
        sendEmails: boolean,
        changeReason: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "voidPackage",
            {
                packageID: packageID,
                addVoidStamp: addVoidStamp,
                zeroFees: zeroFees,
                schedulePayments: schedulePayments,
                sendEmails: sendEmails,
                reason: changeReason
            },
            false,
            true
        );
    }

    voidDocument(
        documentIDs: any[],
        addVoidStamp: boolean,
        zeroFees: boolean,
        schedulePayments: boolean,
        sendEmails: boolean,
        changeReason: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "voidDocument",
            {
                documentIDs: documentIDs,
                addVoidStamp: addVoidStamp,
                zeroFees: zeroFees,
                schedulePayments: schedulePayments,
                sendEmails: sendEmails,
                reason: changeReason
            },
            false,
            true
        );
    }

    doDocumentAction(documentIDs: any[], action: string, recipientID: string) {
        return this._rpcClient.makeRequest("admin", "doDocumentAction", {
            documentIDs: documentIDs,
            action: action,
            recipientID: recipientID
        });
    }

    changeDocumentState(documentID: string, state: string, reason?: string) {
        return this._rpcClient.makeRequest("admin", "changeDocumentState", {
            documentID: documentID,
            documentState: state,
            reason: ""
        });
    }

    toggleVisited(packageID: string, recipientID: string, position: number) {
        return this._rpcClient.makeRequest("admin", "toggleVisited", {
            packageID: packageID,
            recipientID: recipientID,
            position: position
        });
    }

    getSubmittersWithRecipient(recipientID: string) {
        return this._rpcClient.makeRequest(
            "userOrg",
            "getSubmittersWithRecipient",
            {
                orgID: null,
                recipientID: recipientID
            }
        );
    }

    getPackageHistory(packageID: string, asCSV?: boolean) {
        return this._rpcClient.makeRequest("admin", "getPackageHistory", {
            packageID: packageID,
            asCSV: asCSV
        });
    }

    getPackageAdminDetails(packageID: string) {
        return this._rpcClient.makeRequest("admin", "getPackageAdminDetails", {
            packageID: packageID
        });
    }

    getPackageInfo(backendCall: string, packageID: string) {
        return this._rpcClient.makeRequest("admin", backendCall, {
            packageID: packageID
        });
    }

    getBulkPackageActions() {
        return this._rpcClient.makeRequest(
            "admin",
            "getBulkPackageActions",
            {}
        );
    }

    getAllPackageActions() {
        return this._rpcClient.makeRequest("admin", "getAllPackageActions", {});
    }

    addHistoryEvent(
        packageID: string,
        notes?: string,
        eventType?: string,
        timestamp?: string
    ) {
        return this._rpcClient.makeRequest("admin", "addHistoryEvent", {
            packageID: packageID,
            notes: notes,
            eventType: eventType,
            timestamp: timestamp
        });
    }

    addDocumentHistoryEvent(
        documentID: string,
        notes?: string,
        eventType?: string,
        timestamp?: string
    ) {
        return this._rpcClient.makeRequest("admin", "addDocumentHistoryEvent", {
            documentID: documentID,
            notes: notes,
            eventType: eventType,
            timestamp: timestamp
        });
    }

    updatePackageField(packageID: string, fieldID: string, value: string) {
        return this._rpcClient.makeRequest("admin", "updatePackageField", {
            packageID: packageID,
            fieldID: fieldID,
            value: value
        });
    }

    updateDocumentField(documentID: string, fieldID: string, value: string) {
        return this._rpcClient.makeRequest("admin", "updateDocumentField", {
            documentID: documentID,
            fieldID: fieldID,
            value: value
        });
    }

    setPackageProperties(packageID: string, properties: any[]) {
        return this._rpcClient.makeRequest("admin", "setPackageProperties", {
            packageID: packageID,
            propertiesList: properties
        });
    }

    getBulkDocumentActions() {
        return this._rpcClient.makeRequest(
            "admin",
            "getBulkDocumentActions",
            {}
        );
    }

    addBlankPage(documentID: string, letterOrLegal: string) {
        return this._rpcClient.makeRequest("admin", "addBlankPage", {
            documentID: documentID,
            letterOrLegal: letterOrLegal
        });
    }

    getPackageHistoryEventTypes() {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageHistoryEventTypes",
            {}
        );
    }

    getDocumentHistory(documentID: string, asCSV?: boolean) {
        return this._rpcClient.makeRequest("admin", "getDocumentHistory", {
            documentID: documentID,
            asCSV: asCSV
        });
    }

    getPackageAdminDocumentDetails(packageID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageAdminDocumentDetails",
            { packageID: packageID }
        );
    }

    getActivePaymentAccounts(organizationID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getActivePaymentAccounts",
            { organizationID: organizationID }
        );
    }

    getIsPaymentEditable(
        packageID: string,
        feeType: any
    ): Observable<{ isPackagePaymentEditable: boolean }> {
        return this._rpcClient.makeRequest("erecord", "isPaymentEditable", {
            id: packageID,
            feeType: feeType
        });
    }

    updatePackagePaymentAccount(
        packageID: string,
        type: string,
        paymentAccountID: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "updatePackagePaymentAccount",
            {
                packageID: packageID,
                type: type,
                paymentAccountID: paymentAccountID
            }
        );
    }

    getJobHistory(packageID: string) {
        return this._rpcClient.makeRequest("admin", "getJobHistory", {
            packageID: packageID
        });
    }

    updateImages(documentID: any, file: any) {}

    deletePage(documentID: string, pageID: string) {
        return this._rpcClient.makeRequest("admin", "deletePage", {
            documentID: documentID,
            pageID: pageID
        });
    }

    getDocumentStamps(documentID: string) {
        return this._rpcClient.makeRequest("admin", "getDocumentStamps", {
            documentID: documentID
        });
    }

    editStamp(stamp: StampData, stampVars?: any[]) {
        let saveData: any = {
            documentID: stamp.documentID,
            pageID: stamp.pageID,
            annotationID: stamp.annotationID,
            newPageNum: stamp.pageNum,
            applyDate: stamp.applyDate
        };
        if (stampVars != null) {
            saveData.stampVars = stampVars;
        }
        if (stamp.referenceDocID && stamp.referenceDocID.length) {
            saveData.referenceDocumentID = stamp.referenceDocID;
        }

        return this._rpcClient.makeRequest("admin", "editStamp", saveData);
    }

    deleteStamp(documentID: string, pageID: string, annotationID: string) {
        return this._rpcClient.makeRequest("admin", "deleteStamp", {
            documentID: documentID,
            pageID: pageID,
            annotationID: annotationID
        });
    }

    addCustomValue(
        documentID: string,
        customFieldID: string,
        valueText: string
    ) {
        return this._rpcClient.makeRequest("admin", "addDocumentCustomValue", {
            documentID: documentID,
            customFieldID: customFieldID,
            valueText: valueText
        });
    }

    saveCustomValue(
        documentID: string,
        customFieldID: string,
        valueText: string,
        sequenceID: number
    ) {
        return this._rpcClient.makeRequest("admin", "editDocumentCustomValue", {
            documentID: documentID,
            customFieldID: customFieldID,
            valueText: valueText,
            sequenceID: sequenceID
        });
    }

    deleteCustomValue(
        documentID: string,
        customFieldID: string,
        customValueID: string,
        sequenceID: number
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "removeDocumentCustomValue",
            {
                documentID: documentID,
                customFieldID: customFieldID,
                customValueID: customValueID,
                sequenceID: sequenceID
            }
        );
    }

    getDocumentFeeVars(documentID: string) {
        return this._rpcClient.makeRequest("admin", "getDocumentFeeVars", {
            documentID: documentID
        });
    }

    getDocumentStampVars(documentID: string) {
        return this._rpcClient.makeRequest("admin", "getDocumentStampVars", {
            documentID: documentID
        });
    }

    getSpecificStampVars(
        documentID: string,
        pageID: string,
        pageNum: number,
        stampID: string
    ) {
        return this._rpcClient.makeRequest("admin", "getStampVars", {
            documentID: documentID,
            pageID: pageID,
            pageNum: pageNum,
            stampID: stampID
        });
    }

    removeQueueItem(queueItemID: string) {
        return this._rpcClient.makeRequest("admin", "removeQueueItem", {
            queueItemID: queueItemID
        });
    }

    getAllCustomFieldData() {
        return this._rpcClient.makeRequest(
            "erecord",
            "getAllCustomFieldData",
            {}
        );
    }

    getQueueItems(documentID: string) {
        return this._rpcClient.makeRequest("admin", "getQueueItems", {
            documentID: documentID
        });
    }

    getPackageSubmitterUsers(packageID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageSubmitterUsers",
            {
                packageID: packageID
            }
        );
    }

    getXML(
        packageID: string,
        priaChoice?: string,
        handler?: string,
        xsl?: string
    ) {
        return this._rpcClient.makeRequest("erecord", "getRequestData", {
            packageID: packageID,
            priaChoice: priaChoice,
            handlerClass: handler,
            xsl: xsl
        });
    }

    getXmlForSelectedRecipient(
        packageID: string,
        recipientID: string,
        priaChoice?: string,
        handlerClass?: string,
        xsl?: string
    ) {
        return this._rpcClient.makeRequest("erecord", "getRequestData", {
            packageID,
            priaChoice,
            handlerClass,
            xsl,
            recipientID
        });
    }

    getJobQueueVars() {
        return this._rpcClient.makeRequest("admin", "getJobQueueVars", {});
    }

    getAllVendors() {
        return this._rpcClient.makeRequest("admin", "getAllVendors", {});
    }

    getJobQueuesByVendor(
        vendor: string,
        jobMethods: string[],
        assignment: string,
        getErrorsOnly: boolean,
        openCountiesOnly: boolean
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getJobQueueErrorsByVendor",
            { vendor, jobMethods, assignment, getErrorsOnly, openCountiesOnly }
        );
    }

    getJobQueuesByStates(
        states: string[],
        jobMethods: string[],
        assignment: string,
        getErrorsOnly: boolean,
        openCountiesOnly: boolean
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getJobQueueErrorsByStates",
            {
                states,
                jobMethods,
                assignment,
                getErrorsOnly,
                openCountiesOnly
            }
        );
    }

    getRecipientJobQueueErrors(
        organizationIDs: string[],
        jobMethods: string[],
        assignment: string,
        getErrorsOnly: boolean,
        openCountiesOnly: boolean
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecipientJobQueueErrors",
            {
                organizationIDs,
                jobMethods,
                assignment,
                getErrorsOnly,
                openCountiesOnly
            }
        );
    }

    toggleJob(jobIDs: number[], column: string, newValue: boolean) {
        return this._rpcClient.makeRequest("admin", "toggleJobs", {
            jobIDs,
            column,
            newValue
        });
    }

    getJobMessages(jobID: number) {
        return this._rpcClient.makeRequest("admin", "getJobMessages", {
            jobID
        });
    }

    setJobNotes(jobIDs: number[], notes: string) {
        return this._rpcClient.makeRequest("admin", "setJobNotes", {
            jobIDs,
            notes
        });
    }

    deleteJobs(jobIDs: number[]) {
        return this._rpcClient.makeRequest("admin", "deleteJobs", {
            jobIDs
        });
    }

    setCountyNotes(recipientID: string, notes: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "addRecipientJobQueueNotes",
            {
                recipientID,
                notes
            }
        );
    }

    setDocumentRecordingInfo(
        packageID: string,
        docs: any,
        sendEmails: boolean,
        markDocumentsUnretrieved: boolean,
        refreshStamps: boolean,
        schedulePayments: boolean,
        addToUpload: boolean,
        addToPrint: boolean,
        messageToSubmitter: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "setDocumentRecordingInfo",
            {
                packageID: packageID,
                documentRecordingInfo: docs,
                sendEmails: sendEmails,
                markDocumentsUnretrieved: markDocumentsUnretrieved,
                refreshStamps: refreshStamps,
                schedulePayments: schedulePayments,
                addToUpload: addToUpload,
                addToPrint: addToPrint,
                messageToSubmitter: messageToSubmitter
            }
        );
    }
    saveJobQueueErrorHandler(handler: any) {
        const data: any = {
            handlerName: handler.handlerName,
            recipientID: handler.recipientID,
            recipientClass: handler.recipientClass,
            maxErrorCount: handler.maxErrorCount,
            jobMethod: handler.jobMethod,
            errorMessage: handler.errorMessage,
            action: handler.action,
            active: handler.active,
            rejectMessage: handler.rejectMessage,
            createdBy: handler.createdBy
        };
        if (handler.handlerID > -1) {
            data.handlerID = handler.handlerID;
        }
        return this._rpcClient.makeRequest(
            "admin",
            "saveJobQueueErrorHandler",
            data
        );
    }
    getJobQueueErrorHandlers() {
        return this._rpcClient.makeRequest(
            "admin",
            "getJobQueueErrorHandlers",
            {}
        );
    }
    reorderPages(documentID: string, pagesToMove: any) {
        return this._rpcClient.makeRequest("admin", "reorderPages", {
            documentID: documentID,
            pagesToMove: pagesToMove
        });
    }
    getPersistentUnrecordedPackages() {
        return this._rpcClient.makeRequest("admin", "getLatentPackages", {});
    }
    markPackageNotLatent(packageIDs: string[]) {
        return this._rpcClient.makeRequest("admin", "markPackageNotLatent", {
            packageIDs
        });
    }
    assignLatentPackage(packageIDs: string[]) {
        return this._rpcClient.makeRequest("admin", "assignLatentPackage", {
            packageIDs
        });
    }
    unassignLatentPackage(packageIDs: string[]) {
        return this._rpcClient.makeRequest("admin", "unassignLatentPackage", {
            packageIDs
        });
    }

    runPackageEstimationForState(state: string) {
        return this._rpcClient.makeRequest("admin", "runPackageEstimation", {
            state
        });
    }

    runPackageEstimationForCounty(recipientID: string) {
        return this._rpcClient.makeRequest("admin", "runPackageEstimation", {
            recipientID
        });
    }

    getPackageEstimationData(recipientID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "getPackageEstimationData",
            {
                recipientID
            }
        );
    }

    updatePackageStats(recipientID: string, days: number) {
        return this._rpcClient.makeRequest("admin", "updatePackageStats", {
            recipientID: recipientID,
            days: days
        });
    }

    updatePackageStatsForState(state: string, days: number) {
        return this._rpcClient.makeRequest("admin", "updatePackageStats", {
            state: state,
            days: days
        });
    }

    addJobAssignments(jobIDs: number[]) {
        return this._rpcClient.makeRequest("admin", "addJobAssignments", {
            jobIDs
        });
    }

    addJobAssignmentsOtherUsers(jobIDs: number[], userID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "addJobAssignmentsOtherUsers",
            {
                jobIDs,
                userID
            }
        );
    }

    assignLatentPackageToOtherUser(packageIDs: string[], userID: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "assignLatentPackageToOtherUser",
            {
                packageIDs,
                userID
            }
        );
    }

    removeJobAssignments(jobIDs: number[]) {
        return this._rpcClient.makeRequest("admin", "removeJobAssignments", {
            jobIDs
        });
    }

    moveUpDocument(documentID: string) {
        return this._rpcClient.makeRequest("admin", "moveUpDocument", {
            documentID
        });
    }

    moveDownDocument(documentID: string) {
        return this._rpcClient.makeRequest("admin", "moveDownDocument", {
            documentID
        });
    }

    getBriefPackageDetails(packageIDs: string[]) {
        return this._rpcClient.makeRequest("admin", "getBriefPackageDetails", {
            packageIDs
        });
    }

    getRecipientUsers(recipientID: string) {
        return this._rpcClient.makeRequest("userorg", "getOrganizationUsers", {
            orgID: recipientID
        });
    }

    doRefreshStampsData(
        documentID: string,
        recipientID: string,
        userID?: string
    ) {
        let data: any = {
            documentID: documentID,
            recipientID: recipientID
        };
        if (userID) {
            data.userID = userID;
        }
        return this._rpcClient.makeRequest(
            "admin",
            "doRefreshStampsData",
            data
        );
    }

    recordFromXML(packageID: string, xml: string) {
        return this._rpcClient.makeRequest("admin", "recordFromXML", {
            packageID,
            xml
        });
    }

    // need to make a UISupportToolsInterface for this
    getSupportTemplates(
        toolType: string,
        templateType: string
    ): Observable<UISupportToolsTemplateItem[]> {
        return this._rpcClient.makeRequest("admin", "getSupportTemplates", {
            toolType: toolType, // submitter or county
            templateType: templateType
        });
    }

    getSupportTemplateByID(
        templateID: string,
        packageID?: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("admin", "getSupportTemplateByID", {
            templateID: templateID,
            packageID: packageID
        });
    }

    getRecipientConfiguration(packageID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "admin",
            "getRecipientConfiguration",
            {
                packageID: packageID
            }
        );
    }

    getRemotePackageStatusResponse(
        packageID: string,
        recipientPackageID: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getRemotePackageStatusResponse",
            {
                packageID,
                recipientPackageID
            }
        );
    }

    getRemotePackageCompleteResponse(
        packageID: string,
        recipientID: string,
        recipientPackageID: string,
        remoteStatus: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getRemotePackageCompleteResponse",
            {
                packageID,
                recipientID,
                recipientPackageID,
                remoteStatus
            }
        );
    }

    parseRemotePackageCompleteResponse(
        packageID: string,
        recipientID: string,
        completeResponseID: string,
        remoteStatus: string
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "parseRemotePackageCompleteResponse",
            {
                packageID,
                recipientID,
                completeResponseID,
                remoteStatus
            }
        );
    }

    retrievePackageFinal(packageID: string, recipientFunction: string) {
        return this._rpcClient.makeRequest(
            "admin",
            "doRecipientPackageFunction",
            {
                packageID,
                recipientFunction
            }
        );
    }

    getCachedResponse(responseID: string) {
        return this._rpcClient.makeRequest("admin", "getCachedResponse", {
            responseID
        });
    }

    getLogEntryDetailsByOrgID(
        orgID: string,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs
    ) {
        return this._rpcClient.makeRequest(
            "admin",
            "getLogEntryDetailsByOrgID",
            {
                orgID,
                startDate,
                endDate,
                showExceptions: true,
                showMessages: true,
                showEnvironments: true,
                isGrouped: true
            }
        );
    }

    pushQuickStartRefreshQueues(recipientID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "pushQuickStartRefreshQueues",
            {
                recipientID
            }
        );
    }

    getPackageGenerateXMLOptions(packageID: string) {
        return this._rpcClient.makeRequest(
            "erecord",
            "getPackageGenerateXMLOptions",
            {
                packageID
            }
        );
    }

    searchRemotePackage(
        recipientID: string,
        searchValue: string,
        searchType: string,
        fromDate: string,
        toDate: string
    ) {
        return this._rpcClient.makeRequest("admin", "searchPackageByDate", {
            recipientID,
            searchValue,
            searchType,
            fromDate,
            toDate
        });
    }
}
