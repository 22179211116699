import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
//
// import { dayjs } from "@sf/common";
import { dayjs } from "../plugins/dayjs/index";

@Injectable()
export class DateAdapterService extends NgbDateAdapter<dayjs.Dayjs> {
    constructor() {
        super();
    }

    fromModel(value: dayjs.Dayjs | string): NgbDateStruct {
        if (!value) {
            return null;
        }
        if (typeof value === "string" || value instanceof String) {
            return null;
        }
        if (!value.isValid()) {
            return null;
        }
        return {
            day: value.get("date" as any),
            month: value.get("month") + 1,
            year: value.get("year")
        };
    }

    toModel(date: NgbDateStruct | string) {
        if (!date) {
            return null;
        }
        if (typeof date === "string" || date instanceof String) {
            return null;
        }
        return dayjs()
            .startOf("day")
            .set("year", date.year)
            .set("month", date.month - 1)
            .set("date", date.day)
            .utc(true);
    }
}
