<div class="logo">
    <div class="logo-back">
        <button class="btn btn-link" (click)="clickBack()">
            <fa-icon icon="chevron-left"></fa-icon>
            <span> Login</span>
        </button>
    </div>
</div>
<div class="login-container">
    <div class="head-text">
        Password Reset
    </div>
    <!-- ERROR MESSAGES -->
    <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
        <ul *ngIf="formErrorMessages.length > 1">
            <li *ngFor="let message of formErrorMessages">
                <span [innerHTML]="message"></span>
            </li>
        </ul>
        <div *ngIf="formErrorMessages.length == 1">
            <span [innerHTML]="formErrorMessages[0]"></span>
        </div>
    </div>
    <div *ngIf="validToken">
        <div *ngIf="needsSecurityQuestions" class="questions-pane">
            <form [formGroup]="questionsForm">
                <div class="explainer">
                    In order to protect your security, please provide the answers to the questions below.
                </div>

                <div class="row">
                    <div class="col-md-7">
                        <label for="q1">
                            {{securityQuestions[0].question}}
                        </label>
                    </div>
                    <div class="col-md-5">
                        <input [type]="getInputType(0)" id="q1" class="form-control" formControlName="securityAnswer0"
                                (focus)="handleFocus(0)" (blur)="handleBlur(0)" autofocus/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <label for="q2">
                            {{securityQuestions[1].question}}
                        </label>
                    </div>
                    <div class="col-md-5">
                        <input [type]="getInputType(1)" id="q2" class="form-control" formControlName="securityAnswer1"
                                (focus)="handleFocus(1)" (blur)="handleBlur(1)"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <label for="q3">
                            {{securityQuestions[2].question}}
                        </label>
                    </div>
                    <div class="col-md-5">
                        <input [type]="getInputType(2)" id="q3" class="form-control" formControlName="securityAnswer2"
                                (focus)="handleFocus(2)" (blur)="handleBlur(2)"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7"></div>
                    <div class="col-md-5">
                        <label class="lonely-checkbox">
                            <input type="checkbox" formControlName="showAnswers"/>
                            <span>&nbsp;Show Answers</span>
                        </label>
                    </div>
                </div>
                <div class="button-row">
                    <button [disabled]="processing" class="btn btn-primary" (click)="submitQuestionsForm()">
                        <span *ngIf="!processing">Submit</span>
                        <fa-icon *ngIf="processing" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                    </button>
                </div>
            </form>
        </div>

        <div *ngIf="!needsSecurityQuestions" class="reset-pane">
            <form [formGroup]="resetForm">
                <div class="row">
                    <!-- LEFT SIDE -->
                    <div class="left-side col-lg-6 col-md-6">
                        <div class="explainer">
                            Please enter your new password.
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <input type="password" id="password" name="password" placeholder="New Password"
                                        aria-label="Password" class="form-control" formControlName="newPassword"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <input type="password" id="confirm" name="confirm" placeholder="Confirm New Password"
                                        aria-label="Confirm password" class="form-control" formControlName="confirm"/>
                            </div>
                        </div>
                        <div class="row" *ngIf="!uniqueEmail">
                            <div class="form-group col-md-12">
                                <input type="text" id="username" name="username" placeholder="Your Username"
                                        aria-label="Your username"
                                        class="form-control" formControlName="username"/>
                            </div>
                        </div>
                        <div class="button-row">
                            <button [disabled]="processing" class="btn btn-primary" (click)="submitResetForm()">
                                <span *ngIf="!processing">Submit</span>
                                <fa-icon *ngIf="processing" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <!-- RIGHT SIDE -->
                    <div class="right-side col-lg-6 col-md-6">
                        <div class="preload-header">Password requirements</div>
                        <div class="preload-picker pwd-requirements">
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordLengthMet" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordLengthMet" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                <span> Must have {{pwdLength}} or more characters</span>
                            </div>
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordHas3of4" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordHas3of4" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                <span *ngIf="!blockExtendedChars"> Must have at least 3 of the following:</span>
                                <span *ngIf="blockExtendedChars"> Must have all of the following:</span>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"> </div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordHasNumber" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                                        [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordHasNumber" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                    <span> A number</span>
                                </div>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"> </div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordHasLowerCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                                        [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordHasLowerCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                    <span> A lower-case character</span>
                                </div>
                            </div>
                            <div class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"> </div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordHasUpperCase" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                                        [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordHasUpperCase" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                    <span> An upper-case character</span>
                                </div>
                            </div>
                            <div *ngIf="!blockExtendedChars" class="pwd-requirement pwd-requirement-indented">
                                <div class="password-indent"> </div>
                                <div class="password-indented">
                                    <fa-icon *ngIf="!passwordHasSpecialChar" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"
                                        [ngClass]="{'pwd-na':(passwordHas3of4)}"></fa-icon>
                                    <fa-icon *ngIf="passwordHasSpecialChar" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                    <span> A special character (!@#$?+...)</span>
                                </div>
                            </div>
                            <div class="pwd-requirement">
                                <fa-icon *ngIf="!passwordsMatch" [fixedWidth]="true" icon="times-circle" class="pwd-unmet"></fa-icon>
                                <fa-icon *ngIf="passwordsMatch" [fixedWidth]="true" icon="check-circle" class="pwd-met"></fa-icon>
                                <span> Passwords must match</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
