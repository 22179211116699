<sf-tabbed-modal-header [title]="modalTitle" [currentStep]="currentIndex+1"
    [stepCount]="tabCount"></sf-tabbed-modal-header>
<div class="modal-body">
    <div class="input-error row" [ngClass]="{'on': warning}">
        {{warning}}
    </div>

    <form *ngIf="currentIndex == 0" name="sfOrgCreationForm" [formGroup]="organizationForm">
        <!--Org Information-->
        <div class="row">
            <div class="form-group col-sm-12 extra-bottom"
                *ngIf="creatingNotCloning">
                <label class="radio-group-label">Initial Service</label>
                <sf-select id="initialService"
                    placeholder="Select initial service..."
                    [options]="serviceOptions"
                    trackBy="option"
                    labelBy="label"
                    [isSingleSelect]="true"
                    [required]="true"
                    [selectedOption]="organization.initialService"
                    (select)="selectInitialService($event)">
                </sf-select>
                <!--
                <div>
                    <label class="radio-inline" *ngFor="let serviceOption of serviceOptions">
                        <input type="radio" [value]="serviceOption.option" name="initialService"
                            formControlName="initialService" class="">
                        <span><small> {{serviceOption.label}}</small></span>
                    </label>
                </div>
                -->
            </div>
        </div>

        <div class="row">
            <div *ngIf="!creatingNotCloning" class="form-group col-sm-12">
                <label>Based on Organization &nbsp;
                    <fa-icon icon="question-circle" class="tooltip-interaction"
                            ngbTooltip="Your new organization's information will be based on this organization."
                            placement="right"></fa-icon>
                </label>
                <div>
                    <sf-organization-filter-selector id="baseOrg2" windowClass="org-selector"
                            [selectableOrgPermissions]="['organization_admin']"
                            [autoInitialSelect]="false"
                            [selectedOrgID]="organization.baseOrgID"
                            [required]="true"
                            [canClearSelectedOrg]="true"
                            placeholder="Select an organization..."
                            (select)="baseOrgSelected($event)">
                    </sf-organization-filter-selector>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12">
                <label>Parent Organization &nbsp;
                    <fa-icon icon="question-circle" class="tooltip-interaction"
                            ngbTooltip="Users in the parent organization will automatically have roles in child organizations"
                            placement="right"></fa-icon>
                </label>
                <!-- org selector will get inserted here dynamically, because it depends on the situation -->
                <div #parentSelector></div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12">
                <label for="legalName">Legal Name of New Entity/Organization &nbsp;
                    <fa-icon icon="question-circle" class="tooltip-interaction"
                        ngbTooltip="Your new organization's legal name will be the same as the selected 'based on' organization. To change this, please contact Simplifile Support."
                        placement="right"></fa-icon>
                </label>
                <input type="text" class="form-control" name="legalName" id="legalName" autofocus
                        placeholder="Legal Name of Organization"
                        (blur)="copyLegalName()"
                        formControlName="legalName" maxlength="82"/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12">
                <label for="prefName">Preferred Name of New Entity/Organization &nbsp;
                    <fa-icon icon="question-circle" class="tooltip-interaction"
                            ngbTooltip="Your organization's preferred name is what you want your organization called."
                            placement="right"></fa-icon>
                </label>
                <input type="text" class="form-control" name="prefName" id="prefName" autofocus
                        placeholder="Enter Preferred Name of Organization" sf-focus-me="true"
                        formControlName="name" maxlength="82"/>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12" style="width: 540px">
                <label for="businessTypes">Business Type &nbsp;
                    <fa-icon icon="question-circle" class="tooltip-interaction"
                            ngbTooltip="The type of business your organization is in."
                            placement="right"></fa-icon>
                </label>
                <sf-select id="businessTypes"
                        [options]="businessTypes"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true"
                        [required]="true"
                        [selectedOption]="organization.businessTypes[0]"
                        (select)="selectBusinessType($event)"
                        placeholder="Select business type...">
                </sf-select>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-5">
                <label for="orgPhone">Phone</label>
                <input type="text" sf-phone-input class="form-control" name="orgPhone" id="orgPhone"
                    placeholder="Enter Phone" maxlength="20"
                    formControlName="phone">
            </div>
            <div class="form-group col-sm-3">
                <label for="orgPhoneExt">Extension</label>
                <input type="text" class="form-control" name="orgPhoneExt" id="orgPhoneExt"
                    placeholder="" maxlength="9"
                    formControlName="extension">
            </div>
        </div>
        <!--End Org Information-->
    </form>

    <form *ngIf="currentIndex == 1" name="addressForm" [formGroup]="addressForm">
        <!--Org Address-->
        <div class="row">
            <div class="form-group col-sm-12">
                <label for="orgStreet1">Street Address</label>
                <input type="text" class="form-control" name="orgStreet1" id="orgStreet1"
                    placeholder="Enter Street Address" autofocus maxlength="255"
                    formControlName="street">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12">
                <label for="orgStreet2">Street Address 2 (Optional)</label>
                <input type="text" class="form-control" name="orgStreet2" id="orgStreet2"
                    placeholder="Enter Street Address 2" maxlength="255"
                    formControlName="street2">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xs-8">
                <label for="orgCity">City</label>
                <input type="text" class="form-control" name="orgCity" id="orgCity" placeholder="Enter City"
                    maxlength="30" formControlName="city">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xs-6">
                <label for="state">State</label>
                <sf-state-selector id="orgState" placeholder="Select a State..."
                    [selectedStateID]="this.organization.basicInfo.address.state"
                    [required]="true"
                    (select)="stateSelected($event)"></sf-state-selector>
            </div>
            <div class="form-group col-xs-6">
                <label for="orgZipCode">ZIP Code</label>
                <input type="text" sf-zip-input class="form-control" name="orgZipCode" id="orgZipCode"
                    placeholder="Enter ZIP Code" maxlength="12"
                    formControlName="zipCode">
            </div>
        </div>

        <div *ngIf="invalidAddressMessage" class="row outer-validation">
            <div class="inner-validation">
                <span [innerHTML]="invalidAddressMessage"></span>
                <label *ngIf="showCheckbox">
                    <input type="checkbox" formControlName="checkAddress"/>
                    <span> The address I entered is correct</span>
                </label>
                <label *ngIf="showRadios">
                    <input type="radio" name="whichAddress" formControlName="whichAddress" value="recommended"/>
                    <span> Use the recommended address</span>
                </label>
                <label *ngIf="showRadios">
                    <input type="radio" name="whichAddress" formControlName="whichAddress" value="entered"/>
                    <span> Use the address entered above</span>
                </label>
            </div>
        </div>
        <!--End Org Address-->
    </form>

    <form *ngIf="currentIndex == 2" name="contactForm" [formGroup]="contactForm">
        <!--Contact Information-->
        <div class="row">
            <div>
                <legend>Primary Contact Person</legend>
            </div>
        </div>

        <div *ngIf="organization.baseOrgID">
            <div class="little-bottom">
                <i>Select a user in your organization or enter their details below</i>
            </div>
            <div class="clearfix">
                <div class="row col-xs-8 sf-mask">
                    <sf-select id="user-assignment"
                        [options]="userOptions" (select)="selectUserContact($event)"
                        trackBy="id" labelBy="label"
                        [isSingleSelect]="true" placeholder="Select a user (optional)...">
                    </sf-select>
                </div>
            </div>
            <hr/>
        </div>

        <div class="row">
            <div class="form-group col-xs-4">
                <label for="contactFirst">First Name</label>
                <input type="text" name="contactFirst" id="contactFirst" class="form-control sf-mask"
                    placeholder="First Name" size="25" maxlength="82"
                    formControlName="firstName">
            </div>
            <div class="form-group col-xs-4">
                <label for="middleName">Middle</label>
                <input id="middleName" type="text" class="form-control sf-mask" placeholder="" maxlength="82"
                    formControlName="middleName">
            </div>
            <div class="form-group col-xs-4">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="text" name="contactLast" class="form-control sf-mask" placeholder="Last Name" size="25"
                    maxlength="82" formControlName="lastName">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xs-12">
                <label for="title">Job Title</label>
                <input id='title' type="text" class="form-control sf-mask" placeholder="Enter Title"
                    maxlength="82" formControlName="title">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xs-5">
                <label for="phone">Phone</label>
                <input id="phone" type="text" sf-phone-input class="form-control sf-mask" name="contactPhone"
                    placeholder="Enter Phone"
                    maxlength="20" formControlName="phone">
            </div>
            <div class="form-group col-xs-3">
                <label for="extension">Extension</label>
                <input id="extension" type="text" class="form-control sf-mask" name="contactPhoneExt"
                    placeholder="" maxlength="9"
                    formControlName="extension">
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xs-12">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control sf-mask" name="contactEmail" placeholder="Enter Email"
                    maxlength="255" formControlName="email">
            </div>
        </div>
    </form>

    <form *ngIf="currentIndex == 3" name="feesForm">
        <div class="row">
            <div>
                <legend>Verify Service Fees</legend>
            </div>
        </div>
        <div *ngIf="!presentSubmitterFees && !presentDocBuilderFees && !presentTrusteeFees && !presentLienReleaseFees && !presentPaperFees && !presentSettlementFees && !presentLenderFees && !presentEsignEventFees && !presentSubscriptionFees">
            <i>No fees are required at this time</i>
        </div>
        <div *ngIf="presentSubmitterFees || presentDocBuilderFees || presentTrusteeFees || presentLienReleaseFees || presentPaperFees || presentSettlementFees || presentLenderFees || presentEsignEventFees || presentSubscriptionFees"
            class="fee-container">
            <div class="extra-bottom">
                <i>Please verify the service fees below.</i>
            </div>
            <div *ngIf="presentSubscriptionFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeSubscription"
                                    name="includeSubscription"/>
                            <h4>Subscription</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="subscriptionMinimum">Monthly Subscription Minimum:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="subscriptionMinimum">{{subscriptionMinumum | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentSubmitterFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [ngModel]="organization.includeSubmitter"
                                    name="includeSubmitter"
                                    (click)="submitterClicked()"/>
                            <h4>Submitter eRecording</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="submitterLicenseFee">Initial License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="submitterLicenseFee">{{submitterLicenseFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="submitterRenewalFee">Renewal License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="submitterRenewalFee">{{submitterRenewalFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="submitterSubmissionFee">Submission Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="submitterSubmissionFee">{{submitterSubmissionFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentPaperFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includePaper"
                                    name="includePaper"
                                    [disabled]="!organization.includeSubmitter"/>
                            <h4>Recording PLUS</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="instateerecordfee">In-State eRecord Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="instateerecordfee">{{inStateErecordFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="outstateerecordfee">Out-of-State eRecord Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="outstateerecordfee">{{outOfStatePaperFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="instatepaperfee">In-State Paper Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="instatepaperfee">{{inStatePaperFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="outstatepaperfee">Out-of-State Paper Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="outstatepaperfee">{{outOfStatePaperFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="papermailfee">Paper Mail Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="papermailfee">{{paperMailFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="embeddedemployeefee">Embedded Employee Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="embeddedemployeefee">{{embeddedEmployeeFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentDocBuilderFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [ngModel]="organization.includeDocBuilder"
                                    name="includeDocBuilder"
                                    (click)="docBuilderClicked()"
                                    [disabled]="!organization.includeSubmitter"/>
                            <h4>Document Builder</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="docBuilderLicenseFee">Initial License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="docBuilderLicenseFee">{{docBuilderLicenseFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="docBuilderRenewalFee">Renewal License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="docBuilderRenewalFee">{{docBuilderRenewalFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="docBuilderDocumentFee">Document Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="docBuilderDocumentFee">{{docBuilderDocumentFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentDocBuilderRonFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeDocBuilderRon"
                                    name="includeDocBuilderRon"
                                    [disabled]="!organization.includeDocBuilder"/>
                            <h4>Document Builder RON</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="docBuilderRonFee">RON Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="docBuilderRonFee">{{docBuilderRonFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentLienReleaseFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeLienRelease"
                                    name="includeLienRelease"
                                    [disabled]="!organization.includeDocBuilder"/>
                            <h4>Automated Document Request</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="lienReleaseFee">Automated Document Request Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="lienReleaseFee">{{lienReleaseFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentTrusteeFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeTrustee"
                                    name="includeTrustee"/>
                            <h4>Trustee</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="trusteeSubmissionFee">Submission Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="trusteeSubmissionFee">{{trusteeDocumentFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentSettlementFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeSettlement"
                                    name="includeSettlement"/>
                            <h4>Licensed Collaborator (Settlement)</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="settlementRenewalFee">Renewal Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="settlementRenewalFee">{{settlementRenewalFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="settlementLoanFee">Loan Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="settlementLoanFee">{{settlementLoanFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="settlementDisbursementFee">Disbursement Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="settlementDisbursementFee">{{settlementDisbursementFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="settlementInsecureFee">Verified w/o Secure Disbursement Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="settlementInsecureFee">{{settlementInsecureFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="settlementRevShareFee">Revenue Share Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="settlementRevShareFee">{{settlementRevShareFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentLenderFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeLender"
                                    name="includeLender"/>
                            <h4>Licensed Lender</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="lenderRenewalFee">Renewal Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="lenderRenewalFee">{{lenderRenewalFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="lenderLoanFee">Loan Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="lenderLoanFee">{{lenderLoanFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="presentEsignEventFees">
                <div class="former">
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="organization.includeEsignEvent"
                                    name="includeEsignEvent"/>
                            <h4>eSign Events</h4>
                        </label>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="esignEventLicenseFee">Initial License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="esignEventLicenseFee">{{esignEventLicenseFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="esignEventRenewalFee">Renewal License Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="esignEventRenewalFee">{{esignEventRenewalFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="esignEventNotarizationFee">No e-Notarization Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="esignEventNotarizationFee">{{esignEventNotarizationFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="esignEventIpenFee">IPEN Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="esignEventIpenFee">{{esignEventIpenFee | currency}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-1">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="esignEventRonFee">RON Fee:</label>
                        </div>
                        <div class="form-group col-sm-4">
                            <span id="esignEventRonFee">{{esignEventRonFee | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<sf-tabbed-modal-footer [primary]="primary" [secondary]="secondary" [nextButton]="nextButton"
    [backButton]="backButton"></sf-tabbed-modal-footer>
