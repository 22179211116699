<div *ngIf="shown == true" class="context-menu"
    [ngStyle]="{'left.px': x, 'top.px': y}">
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let menuItem of options">
            <button class="btn btn-link sf-btn-link" (click)="handleContextMenuItem(menuItem)">{{menuItem.label}}</button>
        </li>
    </ul>
</div>
<!--
<div *ngIf="contextMenuVisible == true" class="context-menu" [ngStyle]="{'left.px': x, 'top.px': y}" ngbDropdown role="menu">
    <div class="btn-group" role="group" ngbDropdownMenu>
        <button class="dropdown-item" (click)="handleContextMenuItem('copy')">
            <fa-icon [icon]="['far', 'copy']" [fixedWidth]="true"></fa-icon>
            <span>&nbsp;Copy Organization ID</span>
        </button>
    </div>
</div>
-->
