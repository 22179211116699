import { Injectable } from "@angular/core";
import { DefaultUrlSerializer, UrlTree } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class CustomUrlSerializer extends DefaultUrlSerializer {
    constructor() {
        super();
    }

    /**
     * By default, `parse` will return a `UrlTree` that contains an empty `UrlSegment` if the url ends with a slash.
     * When using a wildcard route, this causes something like `Router.navigateByUrl("admin/mainmenu/")` to match the
     * wildcard instead of admin/mainmenu.
     *
     * This custom `parse` simply strips any trailing slashes and passes that to the default implementation.
     */
    parse(url: string): UrlTree {
        return super.parse(url.replace(/\/$/, ""));
    }
}
