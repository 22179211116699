enum PendingPaymentStatusTooltip {
    APPROVE_PAYMENT = "Approve payment",
    EDIT_PAYMENT = "Payment can be edited until processed",
    PAYMENT_PROCESSING = "Payment is being processed and can no longer be edited",
    READY = "Payment will be processed at end of day",
    REFUND = "Request refund",
    SAVE = "Save",
    SECOND_APPROVAL = "Waiting for second approval"
}

export const PendingPaymentStatus = {
    APPROVE_1: {
        tooltip: PendingPaymentStatusTooltip.APPROVE_PAYMENT,
        checkNumberAndMemoEnabled: true,
        paymentButtonEnabled: true,
        type: "APPROVE_1",
        iconSpin: false,
        sortOrder: 1
    },
    APPROVE_2: {
        tooltip: PendingPaymentStatusTooltip.APPROVE_PAYMENT,
        checkNumberAndMemoEnabled: true,
        paymentButtonEnabled: true,
        type: "APPROVE_2",
        iconSpin: false,
        sortOrder: 1
    },
    APPROVED_NOT_PRINTED: {
        tooltip: PendingPaymentStatusTooltip.EDIT_PAYMENT,
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: true,
        type: "APPROVED_NOT_PRINTED",
        iconSpin: false,
        sortOrder: 5
    },
    EDIT_PAYMENT: {
        tooltip: PendingPaymentStatusTooltip.SAVE,
        checkNumberAndMemoEnabled: true,
        paymentButtonEnabled: true,
        type: "EDIT_PAYMENT",
        iconSpin: false,
        sortOrder: 5
    },
    NO_ACTION: {
        tooltip: "",
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: false,
        type: "NO_ACTION",
        sortOrder: 99
    },
    PAYMENT_PROCESSING: {
        tooltip: PendingPaymentStatusTooltip.PAYMENT_PROCESSING,
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: false,
        type: "PAYMENT_PROCESSING",
        iconSpin: false,
        sortOrder: 98
    },
    PROCESSING: {
        tooltip: "",
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: false,
        type: "PROCESSING",
        iconSpin: true,
        sortOrder: 5
    },
    READY: {
        tooltip: PendingPaymentStatusTooltip.READY,
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: false,
        type: "READY",
        iconSpin: false,
        sortOrder: 98
    },
    REFUND: {
        tooltip: "Request Refund",
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: true,
        type: "REFUND",
        iconSpin: false,
        sortOrder: 3
    },
    WAITING: {
        tooltip: PendingPaymentStatusTooltip.SECOND_APPROVAL,
        checkNumberAndMemoEnabled: false,
        paymentButtonEnabled: false,
        type: "WAITING",
        iconSpin: false,
        sortOrder: 4
    }
};
