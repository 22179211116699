import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalButton, SfValidators } from "@sf/common";
import { AbstractControl, NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    ContractFee,
    ContractFeeThresholdResetPeriod,
    ContractFeeTier
} from "@sf/userorg/common";
import { LicenseUtilityService } from "../../services/license-utility.service";

interface ContractFeeEx extends ContractFee {
    feeAmountString?: string;
}

interface ContractFeeTierEx extends ContractFeeTier {
    feeAmountString?: string;
}

@Component({
    selector: "sf-edit-contract-pricing-tiers-dialog",
    templateUrl: "./edit-contract-pricing-tiers-dialog.component.html",
    styleUrls: ["./edit-contract-pricing-tiers-dialog.component.scss"]
})
export class EditContractPricingTiersDialogComponent implements OnInit {
    @Input()
    contractFee: ContractFeeEx;
    @Input()
    decimalPlaces: number;

    @ViewChild("tierForm") tierForm: NgForm;

    tierResetOptions: any[] = [
        { id: ContractFeeThresholdResetPeriod.INDEFINITE, label: "Indefinite" },
        { id: ContractFeeThresholdResetPeriod.MONTH, label: "Monthly" },
        { id: ContractFeeThresholdResetPeriod.QUARTER, label: "Quarterly" },
        { id: ContractFeeThresholdResetPeriod.YEAR, label: "Yearly" }
    ];
    selectedResetOption: ContractFeeThresholdResetPeriod = null;
    quantityLabel: string = "Threshold";
    amountLabel: string = "Fee Amount";
    maxTiers: number = 5;
    error: string = null;
    pricingTiers: ContractFeeTierEx[] = null;
    initializing: boolean = false;
    feeCopy: ContractFeeEx = null;
    primaryButton: ModalButton;
    secondaryButton: ModalButton;
    modalTitle: string = null;
    tiersToShow: number = 1;

    constructor(
        private licenseService: LicenseUtilityService,
        private _activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        //set title
        this.modalTitle = "Edit Pricing Tiers";

        //setup buttons
        this.primaryButton = {
            text: "Confirm",
            callback: this._confirm.bind(this)
        };

        this.secondaryButton = {
            text: "Cancel",
            callback: this._cancel.bind(this)
        };

        //if no contract fee - need to initialize it to prevent HTML errors
        if (!this.contractFee) {
            this.contractFee = {
                contractFeeTiers: [],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE
            };
        }

        //copy contract fee, modify the copy instead (for cancel purposes)
        this.feeCopy = JSON.parse(JSON.stringify(this.contractFee));

        //this is just for easier reference
        this.pricingTiers = this.feeCopy.contractFeeTiers;

        //make sure we have all tiers to prevent console errors (HTML not being able to reference tier elements)
        while (this.pricingTiers.length < this.maxTiers) {
            this.pricingTiers.push({
                tierThreshold: null,
                feeAmount: null
            });
        }

        //make sure first threshold is zero
        this.pricingTiers[0].tierThreshold = 0;

        //determine which tiers to show
        this.pricingTiers.forEach((tier: ContractFeeTierEx, index: number) => {
            if (tier.tierThreshold != null && tier.feeAmount != null)
                this.tiersToShow = index + 1;
        });

        this.initFeeStrings();

        //see if initializing
        if (
            this.pricingTiers.length == 1 &&
            this.pricingTiers[0].feeAmount == 0
        )
            this.initializing = true;

        //figure out duration selection
        let selectedOption = this.tierResetOptions.find((resetOption) => {
            return resetOption.id === this.feeCopy.thresholdResetPeriod;
        });

        //set selected option
        if (selectedOption) this.selectedResetOption = selectedOption.id;
        else this.selectedResetOption = this.tierResetOptions[0].id;
    }

    showDelete(index: number) {
        return this.tiersToShow > 1 && index != 0;
    }

    showAdd(index: number) {
        return this.tiersToShow == index + 1 && index < this.maxTiers - 1;
    }

    addTier(index: number) {
        this._validateTiers();
        if (!this.error) {
            this.tiersToShow++;
            this._focusElement("tierThreshold" + (index + 1));
        }
    }

    deleteTier(index: number) {
        this.tiersToShow--;
        let tiers: ContractFeeTierEx[] = this.pricingTiers.filter(
            (tier: ContractFeeTierEx, idx: number) => {
                return idx != index;
            }
        );
        while (tiers.length < this.maxTiers) {
            tiers.push({ tierThreshold: null, feeAmount: null });
        }
        this.pricingTiers = tiers;
        if (this.error) this._validateTiers();
    }

    handleResetSelect(event: any) {
        if (!event.$isSelectionChanged) return;
        this.selectedResetOption = event.$selection.id;
    }

    initFeeStrings() {
        this.feeCopy.contractFeeTiers.forEach((tier: ContractFeeTierEx) => {
            let thing: any = tier.feeAmount;
            if (typeof thing == "number") {
                thing = thing.toFixed(3);
            }
            tier.feeAmountString = this.licenseService.roundOff(
                thing,
                this.decimalPlaces
            );
        });
    }

    convertPricingTiers(removeFeeString?: boolean) {
        this.feeCopy.contractFeeTiers.forEach((tier: ContractFeeTierEx) => {
            if (tier.tierThreshold) {
                tier.tierThreshold = parseInt(String(tier.tierThreshold), 10);
            }
            let feeString = tier.feeAmountString;
            tier.feeAmount = parseFloat(feeString);
            if (!isNaN(tier.feeAmount)) {
                tier.feeAmountString = this.licenseService.roundOff(
                    tier.feeAmountString,
                    this.decimalPlaces
                );
            }
            if (removeFeeString) delete tier.feeAmountString;
        });
    }

    /** PRIVATE FUNCTIONS **/

    private _focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            if (!theField) {
                console.log("Didn't find: " + elementID);
            }
            if (theField) theField.focus();
        }, 200);
    }

    private _confirm() {
        this._validateTiers();
        if (!this.error) {
            this.feeCopy.thresholdResetPeriod = this.selectedResetOption;
            this.convertPricingTiers(true);
            let validTiers: ContractFeeTierEx[] = this.pricingTiers.filter(
                (tier: ContractFeeTierEx) => {
                    return tier.tierThreshold != null && tier.feeAmount != null;
                }
            );
            this.feeCopy.contractFeeTiers = validTiers;
            this._activeModal.close(this.feeCopy);
        }
    }

    private _cancel() {
        this._activeModal.dismiss();
    }

    private _validateTiers() {
        //reset validity
        this.error = null;

        this.convertPricingTiers();

        let thresholdCtrl: AbstractControl;
        let prevThresholdCtrl: AbstractControl;
        let amountCtrl: AbstractControl;
        let prevAmountCtrl: AbstractControl;

        // loop through first to clear any previous errors
        Object.keys(this.tierForm.controls).every((key: string) => {
            if (key.startsWith("tierThreshold")) {
                thresholdCtrl = this.tierForm.controls[key];
                thresholdCtrl.setErrors(null); // clear
            }
            if (key.startsWith("tierAmount")) {
                amountCtrl = this.tierForm.controls[key];
                amountCtrl.setErrors(null); // clear
            }
            return true; //continue loop
        });

        // loop again to set new errors
        let countT: number = 0;
        let countA: number = 0;
        Object.keys(this.tierForm.controls).every((key: string) => {
            //don't validate what isn't shown
            if (key.startsWith("tierThreshold") && countT < this.tiersToShow) {
                if (countT == 0) {
                    thresholdCtrl = this.tierForm.controls[key];
                } else {
                    prevThresholdCtrl = thresholdCtrl;
                    thresholdCtrl = this.tierForm.controls[key];
                }
                if (thresholdCtrl.errors) {
                    if (thresholdCtrl.errors.required) {
                        this.error = "Tier threshold is required";
                    }
                    if (thresholdCtrl.errors.invalidNumber) {
                        this.error = "Tier threshold is not a positive integer";
                    }
                    if (thresholdCtrl.errors.previous) {
                        this.error =
                            "Tier threshold must be greater than the previous threshold";
                    }
                    this._focusElement(key);
                    return false; //break loop
                }
                if (
                    !SfValidators.isPositiveIntegerString(thresholdCtrl.value)
                ) {
                    this.error = "Tier threshold is not a positive integer";
                    thresholdCtrl.setErrors({ invalidNumber: true });
                    this._focusElement(key);
                    return false; //break loop
                }
                if (countT > 0) {
                    if (
                        this.ensureNumber(thresholdCtrl.value) <=
                        this.ensureNumber(prevThresholdCtrl.value)
                    ) {
                        thresholdCtrl.setErrors({ previous: true });
                        this.error =
                            "Tier threshold must be greater than the previous threshold";
                        this._focusElement(key);
                        return false; //break loop
                    } else {
                        if (
                            thresholdCtrl.errors &&
                            thresholdCtrl.errors.previous
                        )
                            delete thresholdCtrl.errors.previous;
                    }
                }
                countT++;
            }
            //don't validate what isn't shown
            if (key.startsWith("tierAmount") && countA < this.tiersToShow) {
                if (countA == 0) {
                    amountCtrl = this.tierForm.controls[key];
                } else {
                    prevAmountCtrl = amountCtrl;
                    amountCtrl = this.tierForm.controls[key];
                }
                if (amountCtrl.errors) {
                    if (amountCtrl.errors.required) {
                        this.error = "Tier amount is required";
                    }
                    if (amountCtrl.errors.invalidNumber) {
                        this.error =
                            "Tier amount must be greater than or equal to 0";
                    }
                    if (amountCtrl.errors.previous) {
                        this.error =
                            "Tier amount must be less than the previous amount";
                    }
                    this._focusElement(key);
                    return false; //break loop
                }
                if (!SfValidators.isPositiveFloatString(amountCtrl.value)) {
                    this.error =
                        "Tier amount must be greater than or equal to 0";
                    amountCtrl.setErrors({ invalidNumber: true });
                    this._focusElement(key);
                    return false; //break loop
                }
                if (!SfValidators.testFee(amountCtrl.value)) {
                    this.error = "Invalid fee format!";
                    amountCtrl.setErrors({ invalidNumber: true });
                    this._focusElement(key);
                    return false; //break loop
                }
                if (countA > 0) {
                    if (
                        this.ensureNumber(amountCtrl.value) >=
                        this.ensureNumber(prevAmountCtrl.value)
                    ) {
                        amountCtrl.setErrors({ previous: true });
                        this.error =
                            "Tier amount must be less than the previous amount";
                        this._focusElement(key);
                        return false; //break loop
                    } else {
                        if (amountCtrl.errors && amountCtrl.errors.previous) {
                            delete amountCtrl.errors.previous;
                        }
                    }
                }
                countA++;
            }
            return true; //continue loop
        });
    }

    ensureNumber(value: any): number {
        if (typeof value == "number") {
            return value;
        }
        if (typeof value == "string") {
            return parseFloat(value);
        }
        return 0;
    }
}
