<div class="sf-userorg-suspension-notification-dialog">
    <sf-modal-header [title]="title"></sf-modal-header>

    <div class="modal-body">
        <div>
            <div style="padding-bottom: 5px">
                <span><b>The following services are suspended:</b></span>
            </div>
            <div style="max-height: 150px; overflow-y: auto; border: 1px solid black; padding: 10px 0px">
                <div *ngFor="let organization of organizationData">
                    <ng-container *ngIf="organization.hasSuspendedService">
                        <div *ngFor="let service of organization.activeServices">
                            <div *ngIf="service.status === 'SUSPENDED'" style="padding: 5px 15px">
                                <span>The <b>{{service.label}}</b> service for <b>{{organization.name}}</b> has been suspended due to: <span class="sf-mask">{{service.statusDetails}}</span>.</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="isAdmin">
            <br/>
            <span *ngIf="hasPendingPayments">To enable your service, click on "Review Pending Payments" below to review and approve your payments.</span>
            <span *ngIf="allowHelp && !hasPendingPayments">
                <span>For assistance, click on "Get Help" below,</span>
                <span *ngIf="!hasInvoicingIssue"> or <b>call 800.460.5657</b></span>
                <span *ngIf="hasInvoicingIssue"> or email <b>ar-simplifile@ice.com</b></span>
                <span> to resolve.</span>
            </span>
            <span *ngIf="!allowHelp && !hasPendingPayments">Please call Simplifile Support at 800.460.5657 to resolve.</span>
            <br/>
        </div>

        <div *ngIf="!isAdmin">
            <br/>
            <span>For assistance contact your organization administrator, click on "Get Help" below, or call Simplifile Support
            at 800.460.5657 to resolve.</span>
            <br/><br/>
            <div style="padding-bottom: 5px">
                <span><b>Organization Administrators:</b></span>
            </div>
            <div style="max-height: 100px; overflow-y: auto; border:1px solid black; padding: 10px 0px">
                <div *ngFor="let adminUser of adminUsers" style="padding: 5px 15px">
                    <span class="sf-mask"><b>{{adminUser.name}}</b></span>
                    <br/>
                    <span class="sf-mask">{{adminUser.phone}}</span>
                    <br/>
                    <span class="sf-mask"><a href="mailto:{{adminUser.email}}" target="_blank">{{adminUser.email}}</a></span>
                </div>
            </div>
            <br/>
        </div>
    </div>

    <sf-modal-footer [primary]="primaryButton" [secondary]="secondaryButton" [tertiary]="tertiaryButton"></sf-modal-footer>
</div>
