<sf-modal-header title="{{title}}"></sf-modal-header>
<div class="modal-body">
    <div class="explain">
        Enter the details below that were agreed upon in the signed Name Change Amendment.
    </div>
    <div class="row">
        <div class="col-sm-4 input-label">Signature Date:</div>
        <div class="col-sm-6">
            <sf-date-picker class="upload-date standard-spacing" [(ngModel)]="dialogData.executedDate"
                    [required]="true" [maxDate]="maxSignDate"></sf-date-picker>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 input-label">
            <label for="signer">Signer Name:</label>
        </div>
        <div class="col-sm-6">
            <input type="text" id="signer" class="form-control standard-spacing" maxlength="68" [(ngModel)]="dialogData.signatureName"
                    [required]="true"/>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 input-label">
            <label for="title">Signer Title:</label>
        </div>
        <div class="col-sm-6">
            <input type="text" id="title" class="form-control standard-spacing" maxlength="68" [(ngModel)]="dialogData.signatureTitle"/>
        </div>
    </div>
    <div class="row top-space">
        <div class="col-sm-4">
            <button class="btn btn-secondary" (click)="openFileExplorer()">Choose File</button>
        </div>
        <div class="col-sm-7">{{dialogData.file ? dialogData.file.name : ""}}</div>
    </div>
    <input type="file" accept="application/pdf, image/tiff"
            #file style="display: none;" (change)="onFileAdded()" single />
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
