import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: "sf-remove-row-button",
    templateUrl: "./remove-row-button.component.html",
    styleUrls: ["./remove-row-button.component.scss"]
})
export class RemoveRowButtonComponent implements OnInit {
    @Output() remove: EventEmitter<string> = new EventEmitter();
    fullPath: string;

    constructor() {}

    ngOnInit(): void {}

    clicked() {
        this.remove.emit(this.fullPath);
    }
}
