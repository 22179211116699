import { InjectionToken } from "@angular/core";
import { ArrayField, DynamicField, GroupField } from "./index";
import { DynamicFormArrayControl, DynamicFormGroupControl } from "./index";
import { AbstractControl } from "@angular/forms";

export const DYNAMIC_FORM_BUILDER = new InjectionToken("DYNAMIC_FORM_BUILDER");

export interface FormBuilder {
    build(fields: DynamicField[]): DynamicFormGroupControl;
    group(field: GroupField): DynamicFormGroupControl;
    array(field: ArrayField): DynamicFormArrayControl;
    field(field: DynamicField): AbstractControl;
}
