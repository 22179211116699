import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { TokenService, UserorgActivationService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";

declare const window: any;

// prettier-ignore
@Component({
    selector: "sf-accept-invite",
    templateUrl: "./accept-invite.component.html",
    styleUrls: ["./accept-invite.component.scss"]
})
export class AcceptInviteComponent implements OnInit, OnDestroy {
    private _ngOnDestroy: Subject<void>;

    loaded = false;
    loadFailed: string = null;
    tokenID: string;
    tokenType: string;
    loginRequired = false;

    constructor(
            private route: ActivatedRoute,
            private router: Router,
            private tokenService: TokenService,
            private sessionService: SessionService,
            private userorgActivationService: UserorgActivationService
    ) {
    }

    ngOnInit(): void {
        this._ngOnDestroy = new Subject();

        this.route.paramMap
            .pipe(takeUntil(this._ngOnDestroy))
            .subscribe((params) => {
                this.tokenID = params.get("tokenID");
                this.initialize();
            });
    }

    ngOnDestroy() {
        this._ngOnDestroy.next();
    }

    initialize() {
        this.loaded = false;

        this.tokenService.loginWithToken(this.tokenID, [
                "INVITE_USER",
                "INVITE_SERVICE",
                "INVITE_ORGANIZATION",
                "EMAIL_VERIFICATION",
                "SIGN_MSA"
        ])
            .subscribe((response: any) => {
                this.loaded = true;
                this.tokenType = response.tokenType;
                this.loginRequired = response.requiresLogin;
                if (this.loginRequired) {
                    // 'service' invitation to a specific user requires login
                    setTimeout(() => {
                        this.goToLogin();
                    }, 2000);
                } else {
                    this.refreshSession();
                }
            }, (response: any) => {
                this.loaded = true;
                this.loadFailed = response.error.errorMessage;
            });
    }

    goToLogin() {
        window.location = "/sf/ui/login";
    }

    // need to reset in case a session already existed
    refreshSession() {
        this.userorgActivationService.updateUserSession()
            .subscribe((session: any) => {
                if (session && session.session) {
                    this.sessionService.setSession(session.session);
                }
            }, () => {
            });
    }

}
