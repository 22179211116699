import { Component, Injector, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RoleService } from "@sf/userorg/common";
import { UserOrgService } from "@sf/userorg/common";
import { SessionService } from "@sf/common";
import { SelectableUser, User } from "@sf/userorg/common";
import { first } from "rxjs/operators";

@Component({
    selector: "sf-user-multi-select-dialog",
    templateUrl: "./user-multi-select-dialog.component.html",
    styleUrls: ["./user-multi-select-dialog.component.scss"]
})
export class UserMultiSelectDialogComponent implements OnInit {
    @Input() initialSelection: string[]; // user IDs
    @Input() userOptions: SelectableUser[]; // (optional) list of users to choose from

    selectedUsers: SelectableUser[];
    allUserOptions: SelectableUser[] = null;
    primary: ModalButton;
    secondary: ModalButton;

    constructor(
        private activeModal: NgbActiveModal,
        private userorgRolesService: RoleService,
        private userOrgService: UserOrgService,
        private injector: Injector,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "OK",
            disabled: false,
            callback: this.submit.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };

        if (!this.userOptions) {
            if (this.sessionService.isSuperUser()) {
                if (this.initialSelection) {
                    this.userOrgService
                        .getUsers(this.initialSelection)
                        .pipe(first())
                        .subscribe((users: User[]) => {
                            this.mergeToUserOptions(users, true);
                        });
                }
            } else {
                let userID = this.sessionService.getUserID();

                this.userOrgService
                    .getUsersForCurrentUser()
                    .subscribe((response: User[]) => {
                        if (response) {
                            this.mergeToUserOptions(response, false);
                        }
                    });
            }
        }
    }

    mergeToUserOptions(users: User[], selected: boolean) {
        let newOptions: SelectableUser[] = [];
        users.forEach((user: User) => {
            let selectableUser: SelectableUser = {
                id: user.id,
                label: this.getUserFullName(user),
                selected: selected
                //assignments: user.assignments
            };
            newOptions.push(selectableUser);
        });
        if (!this.userOptions) {
            this.userOptions = newOptions;
        } else {
            // we may never get here
            newOptions.forEach((option) => {
                let found = this.userOptions.find((exu) => {
                    return exu.id == option.id;
                });
                if (!found) {
                    this.userOptions.push(option);
                }
            });
        }
    }

    getUserFullName(user: any): string {
        let name = user.firstLastName;
        if (!name && user.name) {
            name = user.name.firstLast;
            if (!name) {
                name = user.name.first + " " + user.name.last;
            }
        }
        if (!name) {
            name = user.id;
        } else {
            name += " (" + user.id + ")";
        }
        return name;
    }

    usersChanged(event: any) {
        if (event.$selection) {
            this.selectedUsers = event.$selection;
        }
    }

    submit() {
        this.activeModal.close(this.selectedUsers);
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
