import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    dayjs,
    GrowlService,
    ModalButton,
    SfValidators,
    SpinnerService,
    UserSettingsService
} from "@sf/common";
import {
    TokenService,
    UserDetails,
    UserDetailsUser,
    UserOrgService
} from "@sf/userorg/common";

// prettier-ignore
@Component({
    selector: "sf-edit-email-dialog",
    templateUrl: "./edit-email-dialog.component.html",
    styleUrls: ["./edit-email-dialog.component.scss"]
})
export class EditEmailDialogComponent implements OnInit {
    @Input() username: string;

    primary: ModalButton;
    secondary: ModalButton;
    warning: string;
    newEmail: string;
    user: UserDetailsUser;
    isSharedEmail = false;
    processing = false;
    prettyVerifiedDate: string;

    constructor(
            private spinnerService: SpinnerService,
            private userOrgTokenService: TokenService,
            private growlService: GrowlService,
            private userSettingsService: UserSettingsService,
            private activeModal: NgbActiveModal,
            private userOrgService: UserOrgService
    ) {
    }

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Save",
            disabled: false,
            callback: this.send.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        this.readUserData();
    }

    readUserData() {
        // Get all the normal User data
        this.userOrgService.getUserDetails()
            .subscribe((userDetails: UserDetails) => {
                this.user = userDetails.user;
                this.checkForDuplicateEmail();
                this.user.emailVerifiedDate = this.userSettingsService.getUserSetting("EMAIL_VERIFIED_DATE");
                if (this.user.emailVerifiedDate?.toString() == "Never") {
                    this.user.emailVerifiedDate = null;
                } else {
                    let mom: dayjs.Dayjs = dayjs(this.user.emailVerifiedDate);
                    this.prettyVerifiedDate = mom.format("M/D/YYYY");
                }
            });
    }

    checkForDuplicateEmail() {
        if (!this.user || !this.user.email)
            return;

        this.userOrgService.countUsersWithThisEmailAddress(this.user.email)
            .subscribe((count: number) => {
                this.isSharedEmail = count > 1;
            });
    }

    requestVerify() {
        this.userOrgTokenService.sendEmailAddressVerificationRequest(this.user.username)
            .subscribe((url: string) => {
                let message = "An email message has been sent";
                this.growlService.success(message);
                if (url) {
                    this.growlService.success(url, "Email Verification URL:", { disableTimeOut: true });
                }
            });
    }

    send() {
        this.warning = null;
        let entry = this.newEmail;
        if (entry) {
            entry = entry.trim();
        }
        if (!entry) {
            this.warning = "Email address cannot be blank";
            return;
        }
        if (!SfValidators.EMAIL_REGEXP.test(entry)) {
            this.warning = "Enter a valid email address";
            return;
        }

        this.processing = true;
        this.user.unverifiedEmail = this.newEmail;
        this.userOrgService.setUserDetails(this.user)
            .subscribe((url: string) => {
                if (url) {
                    this.growlService.success(url, "Email Verification URL:", { disableTimeOut: true });
                }
                this.growlService.success("Email address change request received.");
                this.activeModal.close(true);
            }, (error: string) => {
                this.processing = false;
                //this.growlService.error(error);   // error already displayed
            });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
