import { Component, Input, OnInit } from "@angular/core";
import { ModalButton, SfValidators } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "@sf/login";

// prettier-ignore
@Component({
    selector: "sf-add-mobile-phone-dialog",
    templateUrl: "./add-mobile-phone-dialog.component.html",
    styleUrls: ["./add-mobile-phone-dialog.component.scss"]
})
export class AddMobilePhoneDialogComponent implements OnInit {
    @Input() phoneNumber: string;
    @Input() addRemindLater: boolean;

    initialPhoneNumber: string;
    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;
    warning: string;
    destination = "sms";
    voiceSupported = false;

    constructor(
            private activeModal: NgbActiveModal,
            private loginService: LoginService
    ) {}

    ngOnInit(): void {
        // Modal buttons
        this.primary = {
            text: "Send Verification Code",
            disabled: false,
            callback: this.send.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            disabled: false,
            callback: this.dismiss.bind(this)
        };
        if (this.addRemindLater) {
            this.tertiary = {
                text: "Remind me later",
                disabled: false,
                callback: this.remindLater.bind(this)
            };
        }
        this.initialPhoneNumber = this.phoneNumber;

        this.loginService.secondFactorCanMakeVoiceCalls().subscribe((supported: boolean) => {
            this.voiceSupported = supported;
        })

        this.focusElement("number");
    }

    focusElement(elementID: string) {
        window.setTimeout(() => {
            let theField = document.getElementById(elementID);
            theField.focus();
        }, 200);
    }

    numberChanged() {
        if (this.phoneNumber && this.phoneNumber.length) {
            this.primary.text = "Send Verification Code";
        } else if (this.initialPhoneNumber && this.initialPhoneNumber.length) {
            this.primary.text = "Delete Phone Number";
        } else {
            this.primary.text = "Send Verification Code";
        }
    }

    destinationChanged(destination: string) {
        this.destination = destination;
    }

    send() {
        this.warning = null;
        let justNumbers = SfValidators.getNumericDigits(this.phoneNumber);
        if (this.initialPhoneNumber && this.initialPhoneNumber.length && !justNumbers) {
            this.activeModal.close("delete");
            return;
        }
        if (!justNumbers || justNumbers.length != 10 || !SfValidators.PHONE_REGEXP.test(justNumbers)) {
            this.warning = "Mobile phone number must contain exactly 10 digits";
            return;
        }
        let phone = SfValidators.formatPhone(this.phoneNumber);
        this.activeModal.close({
            number: phone,
            destination: this.destination
        });
    }

    remindLater() {
        this.activeModal.close({
            number: "later"
        });
    }

    dismiss() {
        this.activeModal.close(null);
    }
}
