import { Injectable } from "@angular/core";
import { SocketService } from "./socket.service";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { Auth } from "../interfaces/auth.interface";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private _auth$: BehaviorSubject<Auth>;

    constructor(private _socket: SocketService) {}

    get auth() {
        if (!this._auth$) {
            this._auth$ = new BehaviorSubject<Auth>(null);

            this._socket.authMessages.subscribe((auth) => {
                this._auth$.next(auth);
            });
        }

        return this._auth$.pipe(filter((auth) => auth !== null));
    }

    pushAuth(auth: Auth) {
        this._auth$.next(auth);
    }
}
