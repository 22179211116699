<div class="logo">
    <div class="logo-back">
        <button class="btn btn-link" (click)="clickBack()">
            <fa-icon icon="chevron-left"></fa-icon>
            <span> Back to Login</span>
        </button>
    </div>
</div>
<div class="login-container">
    <h1 class="head-text">
        Contact Simplifile Support
    </h1>
    <!-- ERROR MESSAGES -->
    <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
        <ul *ngIf="formErrorMessages.length > 1">
            <li *ngFor="let message of formErrorMessages">
                <span [innerHTML]="message"></span>
            </li>
        </ul>
        <div *ngIf="formErrorMessages.length == 1">
            <span [innerHTML]="formErrorMessages[0]"></span>
        </div>
    </div>
    <div class="explain extra-bottom">
        We're here to help. If you're having issues logging in or need any other help,
        feel free to call us.
    </div>
    <div class="explain double-extra-bottom">
        <span>Call </span>
        <a href="tel:800.460.5657" autofocus>800.460.5657</a>
    </div>
</div>
