import { Component, OnDestroy, OnInit } from "@angular/core";
import { InputAutoCompleteComponent } from "../../components/input-auto-complete/input-auto-complete.component";
import { DynamicFormStore } from "@sf/dynamic-form-viewer";
import { CustomFormService } from "@sf/custom-form";
import { ErecordHighlightService } from "@sf/document/erecord-viewer/common";
import { FormControl } from "@angular/forms";

@Component({
    selector: "custom-phone-number-field",
    templateUrl: "./custom-phone-number-field.component.html",
    styleUrls: ["./custom-phone-number-field.component.scss"]
})
export class CustomPhoneNumberFieldComponent
    extends InputAutoCompleteComponent
    implements OnInit, OnDestroy
{
    constructor(
        protected _formFacade: DynamicFormStore,
        protected _customFormService: CustomFormService,
        protected _highlightService: ErecordHighlightService
    ) {
        super(_formFacade, _customFormService, _highlightService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showHighlight() {
        super.showHighlight();
    }

    hideHighlight() {
        super.hideHighlight();
    }
    onInput(event: Event, control: FormControl) {
        CustomPhoneNumberFieldComponent.handlePhoneNumberInput(
            event,
            control,
            this.field.phoneValueFormat
        );
    }

    static handlePhoneNumberInput(
        event: Event,
        control: FormControl,
        format: string
    ) {
        const inputEvent = event as InputEvent;
        const target = event.target as HTMLInputElement;
        let nextCursorPosition = target.selectionEnd;
        let newValue = target.value;
        let oldValue = control.value;
        let shouldRepositionCursor = nextCursorPosition !== newValue.length;
        let isBackspace = inputEvent.inputType === "deleteContentBackward";
        let isDelete = inputEvent.inputType === "deleteContentForward";
        let shouldHandleSpecialCharacterDeletions =
            format.toLowerCase() !== "none";

        // handle deletions
        if (
            shouldHandleSpecialCharacterDeletions &&
            (isBackspace || isDelete)
        ) {
            let deletedCharacter: string;
            let deletedIndex = 0;
            for (let i = 0; i < newValue.length; i++) {
                if (newValue.charAt(i) !== oldValue.charAt(i)) {
                    deletedCharacter = control.value[i];
                    deletedIndex = i;
                    break;
                }
            }
            if (!deletedCharacter) {
                // old value is longer than new value, so the deleted character is the last character in the old value
                deletedCharacter = control.value[control.value.length - 1];
                deletedIndex = control.value.length - 1;
            }
            // if the deleted character is not a number we need to remove the next number (if exists)
            while (!!deletedCharacter.match(/\D/)) {
                if (isBackspace) {
                    deletedIndex = Math.max(0, deletedIndex - 1);
                    nextCursorPosition = Math.max(0, nextCursorPosition - 1);
                }
                // if deleting we stay at the same index and just delete one more character
                deletedCharacter = newValue[deletedIndex];

                newValue =
                    newValue.substring(0, deletedIndex) +
                    newValue.substring(deletedIndex + 1);
            }
        }

        // format the phone number
        switch (format.toLowerCase()) {
            case "parenthesis":
                newValue = this.formatWithParenthesis(newValue);
                break;
            case "dash":
                newValue = this.formatWithDash(newValue);
                break;
            case "period":
                newValue = this.formatWithPeriod(newValue);
                break;
            case "parenthesis_ext":
                newValue = this.formatWithParenthesis(newValue, true);
                break;
            case "dash_ext":
                newValue = this.formatWithDash(newValue, true);
                break;
            case "period_ext":
                newValue = this.formatWithPeriod(newValue, true);
                break;
            case "e164":
                newValue = this.formatWithE164(newValue);
                break;
            case "none":
            default:
                break;
        }

        // set the new value
        control.setValue(newValue);

        if (shouldRepositionCursor) {
            target.setSelectionRange(nextCursorPosition, nextCursorPosition);
        }
    }

    static formatWithParenthesis(
        value: string,
        includeExtension: boolean = false
    ): string {
        // remove any non-numeric characters
        let newVal = value.replace(/\D/g, "");
        // format
        if (newVal.length === 0) {
            newVal = "";
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, "($1)");
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
            );
        } else if (includeExtension) {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/,
                "($1) $2-$3 x$4"
            );
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
            );
        }

        return newVal;
    }

    static formatWithPeriod(
        value: string,
        includeExtension: boolean = false
    ): string {
        // remove any non-numeric characters
        let newVal = value.replace(/\D/g, "");
        // format
        if (newVal.length === 0) {
            newVal = "";
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, "$1");
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "$1.$2");
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1.$2.$3");
        } else if (includeExtension) {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/,
                "$1.$2.$3 x$4"
            );
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1.$2.$3");
        }
        return newVal;
    }

    static formatWithDash(
        value: string,
        includeExtension: boolean = false
    ): string {
        // remove any non-numeric characters
        let newVal = value.replace(/\D/g, "");
        // format
        if (newVal.length === 0) {
            newVal = "";
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, "$1");
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "$1-$2");
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
        } else if (includeExtension) {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})(\d*)/,
                "$1-$2-$3 x$4"
            );
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
        }
        return newVal;
    }

    static formatWithE164(value: string): string {
        // temporarily remove leading +1, if exists
        // remove any non-numeric characters
        let newVal = value.replace(/^\+1\s/, "").replace(/\D/g, "");
        // format
        if (newVal.length === 0) {
            newVal = "";
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, "+1 $1");
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "+1 $1-$2");
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "+1 $1-$2-$3"
            );
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "+1 $1-$2-$3"
            );
        }
        return newVal;
    }
}
