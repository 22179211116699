import { Injectable } from "@angular/core";
import { FeeDetailsModalComponent } from "./fee-details-modal/fee-details-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { from, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class SharedFeesDialogService {
    private _modalRef: NgbModalRef;

    constructor(private _modal: NgbModal) {}

    showFeeDetailsDialog(options: {
        documentID: string;
        animation?: boolean;
        forceShowEstimatedFees?: boolean;
        locked?: boolean;
        docViewer?: boolean;
    }): Observable<{ feeTotal: number; recipientFeeData: any }> {
        // Close the existing modal, if there is one.
        // For superusers navigating between document fee details from the new package admin page.
        this._modalRef?.close();

        this._modalRef = this._modal.open(FeeDetailsModalComponent, {
            size: "lg",
            animation: options.animation
        });
        const component = this._modalRef
            .componentInstance as FeeDetailsModalComponent;
        component.navigableDocumentIDList = [options.documentID];
        component.forceShowEstimatedFees = options.forceShowEstimatedFees;
        component.locked = options.locked;
        component.docViewer = options.docViewer;

        if (document.getElementById("rightFrame") !== null) {
            document.getElementById("rightFrame").style.visibility = "hidden";
        }

        return from(this._modalRef.result).pipe(
            tap(() => {
                if (document.getElementById("rightFrame") !== null)
                    document.getElementById("rightFrame").style.visibility =
                        "visible";
            }),
            catchError((error: any) => {
                if (error.error) {
                    console.log(`Error:${error?.error}`);
                }
                return of(error);
            })
        );
    }
}
