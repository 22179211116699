import { SocketService } from "./socket.service";
import { filter, finalize, share, switchMap, take, tap } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { SocketMessage } from "../interfaces/socket-message.interface";
import { Injectable } from "@angular/core";

@Injectable()
export abstract class SubscriptionBaseService {
    protected _isConnected = false;
    private _listeners: {
        [path: string]: Observable<SocketMessage>;
    } = {};
    protected product: string;
    protected namespace: string;
    protected options: any = {};

    protected constructor(protected _socket: SocketService) {
        this._socket.isConnected.subscribe((isConnected) => {
            this._isConnected = isConnected;
        });
        this._socket.onReconnect.subscribe(() => {
            if (Object.keys(this._listeners).length > 0) {
                for (let path of Object.keys(this._listeners)) {
                    if (this._listeners.hasOwnProperty(path)) {
                        this._socket.send(
                            "subscribe",
                            null,
                            path,
                            this.options,
                            this.product
                        );
                    }
                }
            }
        });
    }

    on<T>(filterMethod: string, path?: string): Observable<T>;
    on(filterMethod: string, path?: string): Observable<Object> {
        path = this._getPath(path);
        if (!this._listeners[path]) {
            this._listeners[path] = this._getSubscriptionForPath(path).pipe(
                filter((message) => {
                    // filter responses based on which method is sent back from the backend
                    return message.method === filterMethod;
                }),
                finalize(() => {
                    this.off(path);
                }),
                share()
            );
        }

        return this._listeners[path];
    }

    onAll<T>(path?: string): Observable<T>;
    onAll(path?: string): Observable<Object> {
        path = this._getPath(path);
        return this._getSubscriptionForPath(path);
    }

    send<T>(method: string, data: any, path?: string): Observable<T>;
    send(method: string, data: any, path?: string): Observable<Object> {
        this._checkForErrors();

        const requestId = Math.floor(Math.random() * 1000000000);

        let subscription = this._socket.subscriptionMessages.pipe(
            filter((message) => message.requestId === requestId),
            take(1),
            switchMap((message) =>
                message.error ? throwError(message.error) : of(message.data)
            )
        );

        this._socket.send(
            "send",
            method,
            this._getPath(path),
            data,
            this.product,
            requestId
        );

        return subscription;
    }

    private _getSubscriptionForPath(path?: string): Observable<SocketMessage> {
        if (!this._listeners[path]) {
            this._listeners[path] = this._socket.on(path).pipe(
                finalize(() => {
                    this.off(path);
                }),
                share()
            );

            this._socket.send("subscribe", null, path, this.options);
        }

        return this._listeners[path];
    }

    private _checkForErrors() {
        if (!this.namespace) {
            throw new Error(
                "You must specify a namespace for your subscription by setting it at the class level."
            );
        }

        if (!this.product) {
            throw new Error(
                "You must specify a product for your subscription by setting it at the class level."
            );
        }
    }

    private _getPath(path?: string): string {
        this._checkForErrors();

        if (path) {
            return `${this.namespace}/${path}`;
        }

        return this.namespace;
    }

    private off(path: string) {
        if (this._listeners[path]) {
            this._socket.send("unsubscribe", null, path, this.options);
            delete this._listeners[path];
        }
    }
}
