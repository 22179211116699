import { Injectable } from "@angular/core";
import { RpcClientService } from "./rpc-client.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ConfigurationService {
    private ADMIN = "admin";

    constructor(private _rpcClient: RpcClientService) {}

    getEnvironment(): Observable<string> {
        return this._rpcClient.makeRequest(this.ADMIN, "getEnvironment", {});
    }

    getFalconSettings(): Observable<{ [key: string]: string | boolean }> {
        return this._rpcClient.makeRequest(this.ADMIN, "getFalconSettings", {});
    }

    getUserSetting(username: string, settingName: string): Observable<any> {
        return this._rpcClient.makeRequest(this.ADMIN, "getUserSetting", {
            username,
            settingName
        });
    }

    setUserSetting(
        username: string,
        settingName: string,
        value: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.ADMIN, "setUserSetting", {
            username,
            settingName,
            value
        });
    }

    getUserSettingCustom(
        username: string,
        settingName: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(this.ADMIN, "getUserSettingCustom", {
            username,
            settingName
        });
    }

    setUserSettingCustom(
        username: string,
        settingName: string,
        value: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(this.ADMIN, "setUserSettingCustom", {
            username,
            settingName,
            value
        });
    }
}
